import { fromPromise, Observable } from 'apollo-link'
import { onError } from '@apollo/client/link/error'
import { Sentry } from '@/lib/Sentry'
import store from '@/store/store'
import { locStore } from '@/lib/LocalStorageWrapper'

export let isRefreshing = false
export let pendingRequests: Function[] = []

export const setIsRefreshing = (value: boolean) => {
  isRefreshing = value
}

export const addPendingRequest = (pendingRequest: Function) => {
  pendingRequests.push(pendingRequest)
}

export const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback())
  pendingRequests = []
}

const getNewToken = async () => {
  await store.dispatch('auth/renewAccessToken')
}

// @ts-ignore
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  // @ts-ignore IT DOES EXIST WHEN WE WANT IT TO, WHEN 401
  if ((networkError?.statusCode ?? 0) === 401) { // if it is an authentication error
    if (!isRefreshing) {
      setIsRefreshing(true)

      return fromPromise(
        getNewToken().catch(() => {
          resolvePendingRequests()
          setIsRefreshing(false)

          locStore.clear()

          // Cache shared with main client instance
          return forward(operation)
        })
      ).flatMap(() => {
        resolvePendingRequests()
        setIsRefreshing(false)

        return forward(operation)
      })
    } else {
      return fromPromise(
        new Promise((resolve) => {
          addPendingRequest(() => resolve())
        })
      ).flatMap(() => {
        return forward(operation)
      })
    }
  } else if ((graphQLErrors?.length ?? 0) > 0 || networkError) { // if other error
    if (graphQLErrors) {
      const x: { [key: number]: any } = {}
      graphQLErrors.forEach((g, index) => { x[index] = g?.message })
      Sentry.setContext('graphQLErrors', x)
    }
    if (networkError) {
      Sentry.setContext('networkError', { stringer: JSON.stringify(networkError) })
    }
    Sentry.setContext('Operation-Variables', operation.variables)
    Sentry.captureMessage(`Received an error doing ${operation.operationName}`)
  }
  return null
})

export default errorLink
