import { GridKbShortCut } from '@/models/ExtraAgGridRelatedModels'
import { CellKeyDownEvent, RowNode } from 'ag-grid-community'
import {
  AddAddressToClient,
  AddCarrierToClient,
  AddContactToClient,
  AddCountryOfOrigin,
  AddToPurchaseRMA,
  AddToSaleRMA,
  AllocateParts, CheckInOrOutItems,
  CreatePurchaseOrderFromItem,
  CreatePurchaseRMA,
  CreateQuoteFromItem,
  CreateSaleRMA,
  CreateSalesOrderFromItem,
  ItemsAreReceived,
  LockOrUnlock,
  MarkAsLost,
  MarkAsTested, MultiDeletePurchasedItems, MultiEditCountry,
  MultiEditLocation, MultiReceivePurchasedItems,
  UnAllocateParts
} from '@/lib/agGridContextMenuConfigurations'
import { ContextMenuCallback } from '@/models/GuiPartsModels'
import {
  CheckIfUserCanLockOrUnlock, CheckRowsForCheckInOrOut, CheckRowsForSingleUniqueValueAmongNulls,
  CheckRowsForValueInColumn,
  GetContextMenuLockButtonDetails
} from '@/lib/helpers'
import { fields } from '@/lib/agGridColumnConfiguration'
import store from '@/store/store'
import { BREAKDOWN_STATUS, PURCHASE_ORDER_STATUS, RouterQueryParamsForBackend } from '@/models/ExtraBackendModels'
import router from '@/router/router'
import { ViewPurchaseTransaction, ViewSaleTransaction, ViewSystem } from '@/lib/routerHelper'
import { IPassAndReasonObject } from '@/models/errorAndValidationModels'
import {
  Breakdown_BreakDown,
  Inventory_Item, Inventory_System,
  Purchases_Purchase,
  Sales_Sale
} from '@/models/generated/graphql/ErpBackend.ts'
import { ViewBreakdownTask } from '@/lib/routeHelp'
import { MarkPaymentAsPosted } from '@/api/graphql/Constants/Accounting'
import { PREP_SALE_FOR_EDIT } from '@/composition/UseSale'
import { PREP_PURCHASE_FOR_EDIT } from '@/composition/UsePurchase'

export const KB_SHORTCUT__MASTER_BUY_SELL_HISTORY: GridKbShortCut[] = [
  {
    key: 'p',
    handler: (params: CellKeyDownEvent) => {
      const item: Inventory_Item = params.node.data
      CreatePurchaseOrderFromItem(item)
    },
    rules: () => {
      /* Need to check for multiples selected */
      return true
    },
    invalidMessage: () => ''
  },
  {
    key: 's',
    handler: (params: CellKeyDownEvent) => {
      const item: Inventory_Item = params.node.data
      CreateSalesOrderFromItem(item)
    },
    rules: () => {
      return true
    },
    invalidMessage: () => ''
  },
  {
    key: 'q',
    handler: (params: CellKeyDownEvent) => {
      const item: Inventory_Item = params.node.data
      CreateQuoteFromItem(item)
    },
    rules: () => {
      return true
    },
    invalidMessage: () => ''
  }
]

/* INVENTORY */
export const KB_SHORTCUT__INVENTORY_ITEMS = (userInitials: string, callback: ContextMenuCallback): GridKbShortCut[] => {
  return [
    {
      key: 'l',
      handler: params => LockOrUnlock(params.api.getSelectedNodes(), GetContextMenuLockButtonDetails(params.api.getSelectedNodes()).lock, callback),
      rules: params => CheckIfUserCanLockOrUnlock(params.api.getSelectedNodes(), userInitials),
      invalidMessage: () => 'Select rows with the same lock status!'
    },
    {
      key: 'c',
      handler: params => {
        CheckInOrOutItems(params, params.node.data.checked_out_by !== null)
      },
      rules: params => CheckRowsForCheckInOrOut(params.api.getSelectedNodes()),
      invalidMessage: () => 'Make sure selected items hold the same "checked out/in" value'
    },
    {
      key: 't',
      handler: params => store.dispatch('grid/prepareToPrintTags', { app: 'topbar', component: 'print-tags', data: params.api.getSelectedNodes(), props: { 'max-width': 600 } }),
      rules: params => params.api.getSelectedNodes().length > 0,
      invalidMessage: () => 'Select rows to print tags!'
    },
    {
      key: 'h',
      handler: params => callback({ functionToRun: 'showBSHistory', params: params.node.data }),
      rules: () => true,
      invalidMessage: () => ''
    },
    {
      key: 'p',
      handler: params => ViewPurchaseTransaction({ id: params.node.data.purchases_items_details!.transaction.id }),
      rules: () => true,
      invalidMessage: () => ''
    },
    {
      key: 's',
      handler: params => ViewSaleTransaction({ id: params.node.data.sales_items_details!.transaction.id }),
      rules: params => params.node.data.sales_items_Details !== null,
      invalidMessage: () => 'This item has not yet been sold/allocated.'
    }
  ]
}

export const KB_SHORTCUT__INVENTORY_SYSTEMS = (params: CellKeyDownEvent, userInitials: string, callback: ContextMenuCallback): GridKbShortCut[] => {
  const system: Inventory_System = params.node.data
  const hasPermissionsToAudit: boolean = true
  return [
    ...KB_SHORTCUT__INVENTORY_ITEMS(userInitials, callback),
    {
      key: 'a',
      handler: params => router.push({ name: 'pre-breakdown-audit', params: { id: `${system.id}` } }),
      rules: params => hasPermissionsToAudit && params.api.getSelectedNodes().length === 1,
      invalidMessage: (): string => {
        if (!hasPermissionsToAudit) return 'You do not have sufficient permissions to audit a system.'
        else return 'Select only one system to audit.'
      }
    },
    {
      key: 'b',
      handler: params => {
        const system: Inventory_System = params.node.data
        let order: Breakdown_BreakDown | undefined
        if (system.break_down_orders) {
          order = system.break_down_orders.find(b => b.id !== BREAKDOWN_STATUS.COMPLETED)
          if (order) ViewBreakdownTask(order.id)
        }
      },
      rules: params => {
        const system: Inventory_System = params.node.data
        let order: Breakdown_BreakDown | undefined
        if (system.break_down_orders) {
          order = system.break_down_orders.find(b => b.id !== BREAKDOWN_STATUS.COMPLETED)
          if (order) return true
        }
        return false
      },
      invalidMessage: () => 'System is not currently on an active breakdown task.'
    }
  ]
}

export const KB_SHORTCUT__INVENTORY_HISTORY = (userInitials: string): GridKbShortCut[] => {
  const callback: ContextMenuCallback = () => ''
  return [
    ...KB_SHORTCUT__INVENTORY_ITEMS(userInitials, callback).filter(e => e.key === 'p' || e.key === 's')
  ]
}

export const KB_SHORTCUT__INVENTORY_MASTER = (params: CellKeyDownEvent, callback: Function): GridKbShortCut[] => {
  return [
    {
      key: 'e',
      handler: () => callback().editPart(params.node.data),
      rules: () => true,
      invalidMessage: () => ''
    }
  ]
}

/* PURCHASES */
export const KB_SHORTCUT__PURCHASE_ORDERS = (params: CellKeyDownEvent, callback: ContextMenuCallback): GridKbShortCut[] => {
  const order: Purchases_Purchase = params.node.data
  return [
    {
      key: 'r',
      handler: params => {
        const event: KeyboardEvent = params.event as KeyboardEvent
        if (!event.metaKey && !event.ctrlKey) {
          const id = params.node.data.id
          const routeQuery: RouterQueryParamsForBackend = { id: id }
          router.push({ name: 'receive', params: routeQuery })
        }
      },
      rules: params => {
        const purchase: Purchases_Purchase = params.node.data
        const purchaseStatus: string = purchase.status.id
        return Number(purchaseStatus) > PURCHASE_ORDER_STATUS.TO_APPROVE && Number(purchaseStatus) < PURCHASE_ORDER_STATUS.RECEIVED
      },
      invalidMessage: (params) => {
        const purchase: Purchases_Purchase = params.node.data
        const purchaseStatus: string = purchase.status.id
        const needsToBeApproved: boolean = Number(purchaseStatus) < PURCHASE_ORDER_STATUS.APPROVED
        if (needsToBeApproved) return 'This purchase needs to be approved before receiving!'
        else return 'This purchase is already received!'
      }
    },
    {
      key: 'e',
      handler: params => PREP_PURCHASE_FOR_EDIT({ id: order.id, statusId: order.status.id }),
      rules: params => {
        const purchase: Purchases_Purchase = params.node.data
        const purchaseStatus: string = purchase.status.id
        return Number(purchaseStatus) < PURCHASE_ORDER_STATUS.APPROVED
      },
      invalidMessage: (params) => {
        const purchase: Purchases_Purchase = params.node.data
        const purchaseStatus: string = purchase.status.id
        const needsToBeApproved: boolean = Number(purchaseStatus) >= PURCHASE_ORDER_STATUS.APPROVED
        if (needsToBeApproved) return 'Cannot alter purchase after approval!'
        else return ''
      }
    },
    {
      key: 'a',
      handler: params => {
        const id = params.node.data.id
        callback({ functionToRun: 'addItems', params: id })
      },
      rules: () => {
        const hasPermissionsToAddParts: boolean = true
        return hasPermissionsToAddParts
      },
      invalidMessage: () => 'You do not have sufficient permissions to add items to an order'
    },
    {
      key: 'v',
      handler: params => {
        const id = params.node.data.id
        callback({ functionToRun: 'viewContract', params: id })
      },
      rules: () => {
        const hasPermissionsToViewContract: boolean = true
        return hasPermissionsToViewContract
      },
      invalidMessage: () => ''
    }
  ]
}

export const KB_SHORTCUT__PURCHASE_DETAILS = (orderId: string, clientId: string, callback: ContextMenuCallback): GridKbShortCut[] => {
  return [
    {
      key: 'r',
      handler: (params) => CreatePurchaseRMA(params.api.getSelectedNodes(), orderId),
      rules: params => {
        const selectedNodes = params.api.getSelectedNodes()
        return CheckRowsForValueInColumn(selectedNodes, fields.transactionItems.prmaID, (prmaId) => prmaId === undefined)
      },
      invalidMessage: () => 'This item is already on an RMA.'
    },
    {
      key: 't',
      handler: (params) => AddToPurchaseRMA(params.api.getSelectedNodes(), clientId),
      rules: params => {
        const selectedNodes = params.api.getSelectedNodes()
        return CheckRowsForValueInColumn(selectedNodes, fields.transactionItems.prmaID, (prmaId) => prmaId === undefined)
      },
      invalidMessage: () => 'This item is already on an RMA.'
    },
    {
      key: 'c',
      handler: (params) => AddCountryOfOrigin(params.api.getSelectedNodes(), callback),
      rules: params => ItemsAreReceived(params.api.getSelectedNodes()),
      invalidMessage: () => 'Items need to be received before marking Country of Origin'
    },
    {
      key: 'm',
      handler: params => MarkAsTested(params.api.getSelectedNodes(), callback),
      rules: params => ItemsAreReceived(params.api.getSelectedNodes()),
      invalidMessage: () => 'Items need to be received before marking as tested.'
    }
  ]
}

export const KB_SHORTCUT__PURCHASES_RMA = (): GridKbShortCut[] => {
  return [
    // {
    //   key: 'm',
    //   handler: params => UpdateRmaStatus(params, UPDATE_PURCHASE_RMA_STATUS, params.node.data.id, params.node.data.status.id + 1),
    //   rules: params => params.node.data.status.status_type !== 'FINAL',
    //   invalidMessage: () => 'This RMA is already closed'
    // }
    {
      key: 'p',
      handler: params => ViewPurchaseTransaction({ id: params.node.data.pt.id }),
      rules: () => true,
      invalidMessage: () => ''
    }
  ]
}

export const KB_SHORTCUT__PURCHASES_RMA_NESTED = (): GridKbShortCut[] => []

export const KB_SHORTCUT__WORK_ORDERS = (params: CellKeyDownEvent, callback: ContextMenuCallback): GridKbShortCut[] => {
  const nodes: RowNode[] = params.api.getSelectedNodes()
  const oneIsSelected: boolean = nodes.length === 1
  const hasPermissions: boolean = true
  return [
    {
      key: 'a',
      handler: params => callback({ functionToRun: 'prepAddProductDialog', params: params.node.data }),
      rules: params => hasPermissions && oneIsSelected,
      invalidMessage: () => hasPermissions ? oneIsSelected ? '' : 'Select only one row!' : 'You do not have permission to add an item to a work order!'
    },
    {
      key: 'v',
      handler: params => {
        callback({ functionToRun: 'viewContract', params: params.node.data.id })
      },
      rules: params => oneIsSelected,
      invalidMessage: () => oneIsSelected ? '' : 'Select only one row!'
    }
  ]
}

export const KB_SHORTCUT__WORK_ORDERS_NESTED = (): GridKbShortCut[] => []

/* SALES */
export const KB_SHORTCUT__SALES_ORDERS = (params: CellKeyDownEvent, callback: ContextMenuCallback): GridKbShortCut[] => {
  const order: Sales_Sale = params.node.data
  return [
    {
      key: 'a',
      handler: params => callback({ functionToRun: 'addParts', params: params.node.data }),
      rules: (params) => params.node.data.status.id !== '86',
      invalidMessage: () => 'Cannot add parts to a sale that has been booked!'
    },
    {
      key: 'e',
      handler: () => PREP_SALE_FOR_EDIT({ id: order.id, statusId: order.status.id }),
      rules: () => true,
      invalidMessage: () => ''
    },
    {
      key: 'v',
      handler: params => callback({ functionToRun: 'viewContract', params: params.node.data.id }),
      rules: () => true,
      invalidMessage: () => ''
    },
    {
      key: 'c',
      handler: params => callback({ functionToRun: 'copyST', params: params.node.data.id }),
      rules: () => true,
      invalidMessage: () => ''
    },
    {
      key: 't',
      handler: params => {
        const sale: Sales_Sale = params.node.data
        const trackingNumber: string = sale.shipment_order!.tracking_number
        callback({ functionToRun: 'copyTracking', params: trackingNumber })
      },
      rules: params => {
        const sale: Sales_Sale = params.node.data
        return sale.shipment_order !== null && sale.shipment_order?.tracking_number !== ''
      },
      invalidMessage: () => 'There is not a tracking number to copy yet.'
    }
  ]
}

export const KB_SHORTCUT__SALE_DETAILS = (orderId: string, clientId: string, callback: ContextMenuCallback): GridKbShortCut[] => {
  return [
    {
      key: 'r',
      handler: params => CreateSaleRMA(params.api.getSelectedNodes(), orderId),
      rules: params => {
        const selectedNodes = params.api.getSelectedNodes()
        const allItemsAreAllocated = CheckRowsForValueInColumn(selectedNodes, 'item', (params) => params !== null)
        const itemsCanBeRMAd = CheckRowsForValueInColumn(selectedNodes, 'item.rma_item_details', (params) => params === null)
        return allItemsAreAllocated && itemsCanBeRMAd
      },
      invalidMessage: (params: CellKeyDownEvent): string => {
        const selectedNodes = params.api.getSelectedNodes()
        const allItemsAreAllocated = CheckRowsForValueInColumn(selectedNodes, 'item', (params) => params !== null)
        if (!allItemsAreAllocated) {
          return 'Items need to be allocated and sold before "RMA-ing" them'
        }
        return 'Items can only be on one RMA at a time.'
      }
    },
    {
      key: 't',
      handler: params => AddToSaleRMA(params.api.getSelectedNodes(), clientId, callback),
      rules: params => {
        const selectedNodes = params.api.getSelectedNodes()
        const allItemsAreAllocated = CheckRowsForValueInColumn(selectedNodes, 'item', (params) => params !== null)
        const itemsCanBeRMAd = CheckRowsForValueInColumn(selectedNodes, 'item.rma_item_details', (params) => params === null)
        return allItemsAreAllocated && itemsCanBeRMAd
      },
      invalidMessage: (params: CellKeyDownEvent): string => {
        const selectedNodes = params.api.getSelectedNodes()
        const allItemsAreAllocated = CheckRowsForValueInColumn(selectedNodes, 'item', (params) => params !== null)
        if (!allItemsAreAllocated) {
          return 'Items need to be allocated and sold before "RMA-ing" them'
        }
        return 'Items can only be on one RMA at a time.'
      }
    },
    {
      key: 'a',
      handler: params => AllocateParts(params.node.data, params.api.getSelectedNodes(), callback),
      rules: (params: CellKeyDownEvent) => {
        const selectedNodes = params.api.getSelectedNodes()
        return CheckRowsForValueInColumn(selectedNodes, 'item', (params) => params === null)
      },
      invalidMessage: () => 'Select rows that have the same allocation status to avoid errors!'
    },
    {
      key: 'u',
      handler: params => UnAllocateParts(params.api.getSelectedNodes(), orderId, callback),
      rules: (params: CellKeyDownEvent) => {
        const nodes = params.api.getSelectedNodes()
        return CheckRowsForValueInColumn(nodes, 'item', (params) => params !== null)
      },
      invalidMessage: () => 'Items can only be allocated if they are <b>not</b> allocated'
    },
    {
      key: 'l',
      handler: params => MarkAsLost(params),
      rules: (params: CellKeyDownEvent) => {
        const nodes = params.api.getSelectedNodes()
        return CheckRowsForValueInColumn(nodes, 'item', (params) => params !== null)
      },
      invalidMessage: () => 'Items can only be marked as lost once they are allocated'
    }
  ]
}

export const KB_SHORTCUT__SALES_RMA = (params: CellKeyDownEvent, component: any): GridKbShortCut[] => [
  {
    key: 's',
    handler: params => ViewSaleTransaction({ id: params.node.data.id }),
    rules: () => true,
    invalidMessage: () => ''
  },
  {
    key: 'v',
    handler: params => component.viewContract(params.node.data.id),
    rules: () => params.node.data.shipment_order,
    invalidMessage: () => {
      if (params.node.data.shipment_order) return ''
      else return 'Shipment has not been created.'
    }
  }
]

export const KB_SHORTCUT__SALES_RMA_NESTED = (): GridKbShortCut[] => []

export const KB_SHORTCUT__QUOTES = (callback: ContextMenuCallback): GridKbShortCut[] => {
  return [
    {
      key: 'a',
      handler: params => callback({ functionToRun: 'addParts', params: params.node.data }),
      rules: params => true, // do permissions here
      invalidMessage: () => 'You do not have permission to add parts to a quote'
    },
    {
      key: 's',
      handler: params => callback({ functionToRun: 'convertToSale', params: { params: params } }),
      rules: params => true, // more permissions,
      invalidMessage: () => 'You do not have permission to create a sale'
    },
    {
      key: 'v',
      handler: params => callback({ functionToRun: 'viewPdf', params: { id: params.node.data.id } }),
      rules: () => true,
      invalidMessage: () => ''
    }
  ]
}

export const KB_SHORTCUT__QUOTES_NESTED = (): GridKbShortCut[] => []

/* CLIENTS */

export const KB_SHORTCUT__CLIENTS = (params: CellKeyDownEvent, callback: ContextMenuCallback): GridKbShortCut[] => {
  callback({ dataKey: 'selectedClient', data: params.node.data })
  return [
    {
      key: 'c',
      handler: () => AddContactToClient(callback),
      rules: () => {
        const hasPermissions: boolean = true
        return hasPermissions
      },
      invalidMessage: () => 'You do not have permissions to add a contact.'
    },
    {
      key: 'a',
      handler: () => AddAddressToClient(callback),
      rules: () => {
        const hasPermissions: boolean = true
        return hasPermissions
      },
      invalidMessage: () => 'You do not have permissions to add an address.'
    },
    {
      key: 'r',
      handler: () => AddCarrierToClient(callback),
      rules: () => {
        const hasPermissions: boolean = true
        return hasPermissions
      },
      invalidMessage: () => 'You do not have permissions to add a carrier account.'
    },
    {
      key: 'e',
      handler: () => store.dispatch('grid/changeDialog', { app: 'clients', component: 'changeClientType' }),
      rules: () => {
        const hasPermissions: boolean = true
        return hasPermissions
      },
      invalidMessage: () => 'You do not have permissions to edit client type.'
    }
  ]
}

/* BUILD UP */

export const KB_SHORTCUT__BUILD_UP = (params: CellKeyDownEvent, callback: ContextMenuCallback): GridKbShortCut[] => {
  return [
    {
      key: 's',
      handler: params => ViewSystem({ id: params.node.data.id }),
      rules: () => true,
      invalidMessage: () => ''
    },
    {
      key: 'a',
      handler: params => callback({ functionToRun: 'prepareBuildUpAssignment', params: { params: params } }),
      rules: params => {
        const hasPermissionsToAssignBuildUp: boolean = true
        return hasPermissionsToAssignBuildUp
      },
      invalidMessage: () => 'You do not have permissions to assign a build up task'
    },
    {
      key: 'm',
      handler: params => callback({ functionToRun: 'assignBuildUp', params: { user: store.state.profile.user.id, buildUpId: params.node.data.id } }),
      rules: params => {
        const hasPermissionsToAssignBuildUp: boolean = true
        return hasPermissionsToAssignBuildUp
      },
      invalidMessage: () => 'You do not have permissions to assign a build up task'
    },
    {
      key: 'r',
      handler: params => callback({ functionToRun: 'buildUpIsReady', params: { params: params } }),
      rules: params => {
        const hasPermissionsToChangeBuildUpStatus: boolean = true
        return hasPermissionsToChangeBuildUpStatus
      },
      invalidMessage: () => 'You do not have permissions to change build up status'
    },
    {
      key: 'c',
      handler: params => callback({ functionToRun: 'completeBuildUp', params: { params: params } }),
      rules: params => {
        const hasPermissionsToChangeBuildUpStatus: boolean = true
        return hasPermissionsToChangeBuildUpStatus
      },
      invalidMessage: () => 'You do not have permissions to change build up status'
    }
  ]
}

export const KB_SHORTCUT__BUILD_UP_NESTED = (params: CellKeyDownEvent, callback: ContextMenuCallback): GridKbShortCut[] => {
  return [
    {
      key: 'n',
      handler: params => callback({ functionToRun: 'markBuildUpItem', params: { params: params } }),
      rules: () => true,
      invalidMessage: () => 'You do not have permissions to change build up item status'
    },
    {
      key: 'b',
      handler: params => callback({ functionToRun: 'markBuildUpItem', params: { params: params, back: true } }),
      rules: () => true,
      invalidMessage: () => 'You do not have permissions to change build up item status'
    }
  ]
}

/* BreakDown */

export const KB_SHORTCUT__BREAK_DOWN = (params: CellKeyDownEvent, callback: ContextMenuCallback): GridKbShortCut[] => {
  const hasPermissionsToEditBreakdown: boolean = true
  const nodes: RowNode[] = params.api.getSelectedNodes()
  const breakdown: Breakdown_BreakDown = params.node.data
  const userId: number = store.state.profile.user.id
  return [
    {
      key: 'b',
      handler: params => {
        const routeQuery: RouterQueryParamsForBackend = {
          filters: [{ key: 'item__broke_down_items__break_down__id', value: breakdown.id }]
        }
        router.push({ name: 'receive', query: routeQuery, params: { id: breakdown.system.purchases_items_details?.transaction.id ?? '' } })
      },
      rules: () => hasPermissionsToEditBreakdown && nodes.length === 1,
      invalidMessage: () => {
        if (!hasPermissionsToEditBreakdown) return 'You do not have permissions to continue/start a breakdown'
        else return 'Select a node to continue'
      }
    },
    {
      key: 'a',
      handler: params => callback({ functionToRun: 'prepareBreakDownAssignment', params: { params: params } }),
      rules: () => hasPermissionsToEditBreakdown && nodes.length === 1,
      invalidMessage: () => {
        if (!hasPermissionsToEditBreakdown) return 'You do not have permissions to continue/start a breakdown'
        else return 'Select a node to continue'
      }
    },
    {
      key: 'm',
      handler: params => callback({ functionToRun: 'assignBreakDown', params: { user: userId, id: breakdown.id } }),
      rules: () => hasPermissionsToEditBreakdown && nodes.length === 1,
      invalidMessage: () => {
        if (!hasPermissionsToEditBreakdown) return 'You do not have permissions to continue/start a breakdown'
        else return 'Select a node to continue'
      }
    },
    {
      key: 'c',
      handler: params => callback({ functionToRun: 'completeBreakdown', params: { id: breakdown.id } }),
      rules: () => hasPermissionsToEditBreakdown && nodes.length === 1,
      invalidMessage: () => {
        if (!hasPermissionsToEditBreakdown) return 'You do not have permissions to continue/start a breakdown'
        else return 'Select a node to continue'
      }
    }
  ]
}

export const KB_SHORTCUT__BREAK_DOWN_NESTED = (params: CellKeyDownEvent, callback: ContextMenuCallback): GridKbShortCut[] => {
  const hasPermissionsToEditBreakdown: boolean = true
  const nodes: RowNode[] = params.api.getSelectedNodes()
  return [
    {
      key: 'n',
      handler: params => callback({ functionToRun: 'markBreakDownItem', params: { params: params } }),
      rules: () => hasPermissionsToEditBreakdown && nodes.length === 1,
      invalidMessage: () => {
        if (!hasPermissionsToEditBreakdown) return 'You do not have permissions to continue/start a breakdown'
        else return 'Select a node to continue'
      }
    },
    {
      key: 'b',
      handler: params => callback({ functionToRun: 'markBreakDownItem', params: { params: params, back: true } }),
      rules: () => hasPermissionsToEditBreakdown && nodes.length === 1,
      invalidMessage: () => {
        if (!hasPermissionsToEditBreakdown) return 'You do not have permissions to continue/start a breakdown'
        else return 'Select a node to continue'
      }
    }
  ]
}

/* RECEIVING */

export const KB_SHORTCUT__RECEIVING = (params: CellKeyDownEvent): GridKbShortCut[] => {
  const nodes = params.api.getSelectedNodes()
  const locationCheck: IPassAndReasonObject = CheckRowsForSingleUniqueValueAmongNulls(nodes, 'item.location', 'name')
  const countryCheck: IPassAndReasonObject = CheckRowsForSingleUniqueValueAmongNulls(nodes, 'item.country_of_origin', 'code')
  // test if this works!!!
  return [
    {
      key: 'l',
      handler: params => {
        MultiEditLocation(params, nodes, locationCheck.data)
      },
      rules: () => nodes.length > 1 && locationCheck.pass,
      invalidMessage: () => locationCheck.reason
    },
    {
      key: 'l',
      altKey: true,
      handler: () => {
        for (const node of nodes) {
          node.setDataValue('location', null)
        }
      },
      rules: () => nodes.length > 0,
      invalidMessage: () => 'Need to select a row!'
    },
    {
      key: 'c',
      handler: params => MultiEditCountry(params, nodes, countryCheck.data),
      rules: () => nodes.length > 1 && countryCheck.pass,
      invalidMessage: () => countryCheck.reason
    },
    {
      key: 'c',
      altKey: true,
      handler: () => {
        for (const node of nodes) {
          node.setDataValue('countryOfOrigin', null)
        }
      },
      rules: () => nodes.length > 0,
      invalidMessage: () => 'Need to select a row!'
    },
    {
      key: 'r',
      handler: () => MultiReceivePurchasedItems(nodes, true),
      rules: () => nodes.length > 0,
      invalidMessage: () => 'Need to select a row!'
    },
    {
      key: 'r',
      altKey: true,
      handler: () => {
        for (const node of nodes) {
          node.setDataValue('receive', false)
        }
      },
      rules: () => nodes.length > 0,
      invalidMessage: () => 'Need to select a row!'
    },
    {
      key: 'd',
      handler: () => MultiDeletePurchasedItems(nodes, true),
      rules: () => nodes.length > 0,
      invalidMessage: () => 'Need to select a row!'
    },
    {
      key: 'd',
      altKey: true,
      handler: () => {
        for (const node of nodes) {
          node.setDataValue('delete', false)
        }
      },
      rules: () => nodes.length > 0,
      invalidMessage: () => 'Need to select a row!'
    }
  ]
}

/* ACCOUNTING */

export const KB_SHORTCUT__ACCOUNTING_GL = (params: CellKeyDownEvent, callback: ContextMenuCallback): GridKbShortCut[] => {
  const nodes = params.api.getSelectedNodes()
  return [

  ]
}

export const KB_SHORTCUT__ACCOUNTING_AR = (params: CellKeyDownEvent, callback: ContextMenuCallback): GridKbShortCut[] => {
  const hasPermissionsToEditArInvoices: boolean = true
  const nodes = params.api.getSelectedNodes()
  return [
    {
      key: 's',
      handler: params => ViewSaleTransaction({ id: params.node.data.order.id }),
      rules: () => true,
      invalidMessage: () => ''
    },
    {
      key: 'v',
      handler: params => callback({ functionToRun: 'prepareToVoidArInvoices', params: { params: params } }),
      rules: () => hasPermissionsToEditArInvoices && nodes.length > 0,
      invalidMessage: () => {
        if (!hasPermissionsToEditArInvoices) return 'You do not have permissions to add a credit to AR Invoice'
        return 'Select only one row.'
      }
    },
    // {
    //   key: 'c',
    //   handler: params => callback({ functionToRun: 'addCreditToArInvoice', params: { params: params } }),
    //   rules: () => hasPermissionsToEditArInvoices && nodes.length === 1,
    //   invalidMessage: () => {
    //     if (!hasPermissionsToEditArInvoices) return 'You do not have permissions to add a credit to AR Invoice'
    //     return 'Select only one row.'
    //   }
    // },
    // {
    //   key: 'i',
    //   handler: params => callback({ functionToRun: 'reissueInvoice', params: { params: params } }),
    //   rules: () => hasPermissionsToEditArInvoices && nodes.length === 1,
    //   invalidMessage: () => {
    //     if (!hasPermissionsToEditArInvoices) return 'You do not have permissions to add a credit to AR Invoice'
    //     return 'Select only one row.'
    //   }
    // },
    {
      key: 'r',
      handler: params => callback({ functionToRun: 'showAddPaymentToInvoiceDialog', params: { params: params } }),
      rules: () => hasPermissionsToEditArInvoices && nodes.length === 1,
      invalidMessage: () => {
        if (!hasPermissionsToEditArInvoices) return 'You do not have permissions to add a credit to AR Invoice'
        return 'Select only one row.'
      }
    }
  ]
}

export const KB_SHORTCUT__ACCOUNTING_AR_NESTED = (params: CellKeyDownEvent, callback: ContextMenuCallback): GridKbShortCut[] => {
  const nodes = params.api.getSelectedNodes()
  const canMarkAsPosted = true
  return [
    {
      key: 'm',
      handler: params => MarkPaymentAsPosted(params.node.data.id, params.node.data.is_posted, 'AR'),
      rules: params => canMarkAsPosted,
      invalidMessage: () => 'You do not have permissions to mark posted'
    }
  ]
}

export const KB_SHORTCUT__ACCOUNTING_AP = (params: CellKeyDownEvent, callback: ContextMenuCallback): GridKbShortCut[] => {
  const hasPermissionsToEditApInvoices: boolean = true
  const nodes = params.api.getSelectedNodes()
  return [
    {
      key: 'c',
      handler: params => callback({ functionToRun: 'applyCredit', params: { params: params } }),
      rules: () => hasPermissionsToEditApInvoices && nodes.length === 1,
      invalidMessage: () => {
        if (!hasPermissionsToEditApInvoices) return 'You do not have permissions to add a credit to AR Invoice'
        return 'Select only one row.'
      }
    },
    {
      key: 'r',
      handler: params => callback({ functionToRun: 'showAddPaymentToInvoiceDialog', params: { params: params } }),
      rules: () => hasPermissionsToEditApInvoices && nodes.length === 1,
      invalidMessage: () => {
        if (!hasPermissionsToEditApInvoices) return 'You do not have permissions to add a credit to AR Invoice'
        return 'Select only one row.'
      }
    },
    {
      key: 'v',
      handler: params => callback({ functionToRun: 'prepareToVoidApInvoices', params: { params: params } }),
      rules: () => hasPermissionsToEditApInvoices && nodes.length === 1,
      invalidMessage: () => {
        if (!hasPermissionsToEditApInvoices) return 'You do not have permissions to add a credit to AR Invoice'
        return 'Select only one row.'
      }
    },
    {
      key: 'p',
      handler: params => ViewPurchaseTransaction({ id: params.node.data.order.id }),
      rules: () => true,
      invalidMessage: () => ''
    }
  ]
}

export const KB_SHORTCUT__ACCOUNTING_AP_NESTED = (params: CellKeyDownEvent, callback: ContextMenuCallback) => KB_SHORTCUT__ACCOUNTING_AR_NESTED(params, callback)

export const KB_SHORTCUT__ACCOUNTING_COA = (params: CellKeyDownEvent, callback: ContextMenuCallback): GridKbShortCut[] => {
  const hasPermissionsToEditSubAccounts: boolean = true
  const nodes = params.api.getSelectedNodes()
  return [
    {
      key: 'a',
      handler: params => callback({ functionToRun: 'prepareToAddSubAccountNumber', params: { params: params } }),
      rules: () => hasPermissionsToEditSubAccounts && nodes.length === 1, // permissions here,
      invalidMessage: () => {
        if (!hasPermissionsToEditSubAccounts) return 'You do not have permissions to edit sub accounts.'
        else return 'You must select one and only one to add a sub account.'
      }
    },
    {
      key: 'e',
      handler: params => callback({ functionToRun: 'prepareToEdit', params: { params: params } }),
      rules: () => hasPermissionsToEditSubAccounts && nodes.length === 1,
      invalidMessage: () => 'You may not have proper permissions, or have more than one selected.'
    }
  ]
}

export const KB_SHORTCUT__ACCOUNTING_COA_NESTED = (params: CellKeyDownEvent, callback: ContextMenuCallback): GridKbShortCut[] => {
  const hasPermissionsToEditSubAccounts: boolean = true
  const nodes = params.api.getSelectedNodes()
  return [
    {
      key: 'e',
      handler: params => callback({ functionToRun: 'prepareToEdit', params: { params: params } }),
      rules: () => hasPermissionsToEditSubAccounts && nodes.length === 1,
      invalidMessage: () => 'You may not have proper permissions, or have more than one selected.'
    }
  ]
}
