<template>
  <v-autocomplete
    clearable
    label="E-Store"
    placeholder="Optional"
    data-cy="ecommerce-select"
    menu-props="offsetY"
    :value="store"
    :items="stores"
    @change="emit"
  />
</template>

<script>
export default {
  name: 'E-CommerceSelection',
  props: {
    store: {
      type: String,
      required: true
    },
    errors: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      stores: ['AMAZON', 'EBAY', 'COMPANY WEB-STORE']
    }
  },
  methods: {
    emit (event) {
      this.$emit('updated', { value: event || '' })
    }
  }
}
</script>

<style scoped>

</style>
