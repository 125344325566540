// vuex store for data related to sales
const state = {
  account: 0,
  billTo: 0,
  blindClient: '',
  blindContact: '',
  client: {},
  contact: {},
  contractComment: '',
  continueId: 0,
  deliverBy: '',
  dropShip: 'NOT_BLIND',
  internalComment: '',
  localPickup: 'NOT_PICKUP',
  parts: [],
  priority: '',
  quote: 0,
  rep: 0,
  returnAddress: 0,
  shipFrom: 0,
  shipOrderId: 0,
  shipTo: 0,
  shippingService: 0,
  terms: 0,
  wePay: false
}

const mutations = {
  /**
   * Updating any part of a sale
   * @param state
   * @param payload
   */
  update (state, payload) {
    // just so I don't forget this little ternary crap is because I want to be able to use 's'
    // and no 's' variables to set sale data (and probably part data on purchases too) and this
    // is the fastest way to do it
    const key = (payload.key === 'parts' || payload.key === 'terms' || payload.key === 'returnAddress' || payload.key.charAt(payload.key.length - 1) !== 's')
      ? payload.key
      : payload.key.substr(0, payload.key.length - 1)
    state[key] = payload.value
  },

  /**
   * Used to align cached sale data with vuex sale object
   * @param state
   * @param {Object} payload
   */
  load (state, payload) {
    for (const [key, value] of Object.entries(payload)) {
      state[key] = value
    }
  },

  /**
   * Mutation to reset the sale object
   * @param state
   */
  reset (state) {
    state.account = 0
    state.billTo = 0
    state.blindClient = {}
    state.blindContact = {}
    state.client = {}
    state.contact = {}
    state.contractComment = ''
    state.continueId = 0
    state.deliverBy = ''
    state.dropShip = 'NOT_BLIND'
    state.internalComment = ''
    state.localPickup = 'NOT_PICKUP'
    state.parts = []
    state.priority = ''
    state.quote = 0
    state.rep = 0
    state.returnAddress = 0
    state.shipFrom = 0
    state.shipOrderId = 0
    state.shipTo = 0
    state.shippingService = 0
    state.terms = 0
    state.wePay = false
  }
}

const actions = {
  /**
   * Action to update any part of a sale
   * @param commit
   * @param payload
   */
  update ({ commit }, payload) {
    commit('update', payload)
  },

  /**
   * Loads a sale from a currently not completed order
   * @param commit
   * @param dispatch
   * @param payload
   */
  load ({ commit, dispatch }, payload) {
    commit('load', payload)
    dispatch('start')
  },

  /**
   * Action to reset the sale object
   * @param commit
   */
  reset ({ commit }) {
    commit('reset')
    commit('data/resetAutofillLists', null, { root: true })
  },

  /**
   * Bring up the sale order form
   * @param dispatch
   */
  start ({ dispatch }) {
    dispatch('grid/changeSheet', { app: 'topbar', component: 'newSale' }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
