import { apolloClient as apollo } from '@/api/graphql/apollo'
import router from '@/router/router'
import {
  keycloak, processToken,
  URL_KEYCLOAK_REDIRECT_LOGOUT,
  URL_KEYCLOAK_REDIRECT_TENANT
} from '@/api/keycloak'
import { LOCAL_STORAGE_KEYS, locStore } from '@/lib/LocalStorageWrapper'

const state = {
  tokens: { refresh: null, access: null },
  tenants: [],

  authenticated: false,
  loggingIn: false,
  loggingOut: false,
  changingPassword: false,

  lastActiveUrlName: ''
}

const getters = {
  tenantStrings: state => {
    return state.tenants.map(t => {
      return t.replace('/Tenants/', '')
    }) ?? []
  },

  tokensAreSet: state => {
    return state.tokens.refresh !== null && state.tokens.access !== null
  },

  tenantsForSwitcher: state => {
    return state.tenants.map(t => {
      return (t ?? '').replace('/Tenants/', '')?.toLowerCase()
    }).filter(t => {
      return t?.toLowerCase() !== locStore.get('tenant')?.toLowerCase()
    }) ?? []
  }
}

const mutations = {
  setLastActiveUrl (state, { name }) {
    state.lastActiveUrlName = name
    locStore.set(LOCAL_STORAGE_KEYS.lastActiveUrl, name, true)
  },

  updateState (state, { key, value }) {
    state[key] = value
  },

  changeLoggingInStatus (state, payload) {
    state.loggingIn = payload.bool
  },

  setTokens (state, { refresh, access }) {
    if (refresh) {
      state.tokens.refresh = refresh
      locStore.set(LOCAL_STORAGE_KEYS.refreshToken, refresh)
    }
    if (access) {
      state.tokens.access = access
      locStore.set(LOCAL_STORAGE_KEYS.accessToken, access)
    }
  },

  logout (state) {
    state.tokens.refresh = null
    state.tokens.access = null
    state.loggingOut = false

    state.authenticated = false
    apollo.stop()
    apollo.clearStore()
      .catch(e => console.log(e))
    locStore.clear()
  },

  removeCachedLoginCredentials () {
    locStore.resetTokens()
  }
}

const actions = {
  changeLoggingInStatus ({ commit }, payload) {
    commit('changeLoggingInStatus', payload)
  },

  updateState ({ commit }, { key, value }) {
    commit('updateState', { key: key, value: value })
  },

  /**
   * Login method that dispatches everything else
   * @param commit
   * @param dispatch
   * @param payload
   * @return {Promise<boolean>}
   */
  async keycloakLogin ({ commit, dispatch }) {
    keycloak.login({
      redirectUri: URL_KEYCLOAK_REDIRECT_TENANT
    })
  },

  async tenantLogin ({ commit, dispatch }, { tenant }) {
    return new Promise((resolve, reject) => {
      commit('changeLoggingInStatus', { bool: true })
      locStore.set(LOCAL_STORAGE_KEYS.tenant, tenant)
      dispatch('profile/getLoginData', null, { root: true })
        .then(() => {
          resolve()
        })
        .catch(() => {
          commit('changeLoggingInStatus', { bool: false })
          reject(new Error('nope'))
        })
    })
  },

  /**
   * Commits tokens to localstorage
   * @param commit
   * @param dispatch
   * @param payload
   */
  setTokens ({ commit, dispatch }, payload) {
    commit('setTokens', payload)
  },

  /**
   * Dispatched when the access token is expired and a simple refresh
   * without reloading all the data is needed
   * @param dispatch
   * @param commit
   * @param {Object} payload
   * @param {number} payload.minVal
   * @return {Promise<Object>}
   */
  async renewAccessToken ({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      const refreshToken = locStore.get(LOCAL_STORAGE_KEYS.refreshToken)
      if (refreshToken) keycloak.refreshToken = refreshToken
      keycloak.updateToken(payload?.minVal ?? -1)
        .then((response) => {
          resolve(keycloak.token)
        }).catch(() => {
          reject(new Error('Could not do it'))
        })
    })
  },

  async attemptAutoLogin ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('changeLoggingInStatus', { bool: true })
      const refreshToken = locStore.get('refreshToken')
      const tenant = locStore.get('tenant')
      const access = locStore.get('accessToken')
      if (refreshToken && tenant && access) {
        commit('setTokens', { refresh: refreshToken, access: access })
        keycloak.token = access
        keycloak.refreshToken = refreshToken
        dispatch('renewAccessToken', { minVal: 0 })
          .then(() => {
            dispatch('profile/getLoginData', null, { root: true })
              .then(() => {
                resolve()
              })
              .catch(() => {
                commit('changeLoggingInStatus', { bool: false })
                reject(new Error('nope'))
              })
          })
      } else if (refreshToken && access) {
        keycloak.token = access
        keycloak.refreshToken = refreshToken
        keycloak.login({
          redirectUri: URL_KEYCLOAK_REDIRECT_TENANT
        })
      } else {
        commit('changeLoggingInStatus', { bool: false })
        dispatch('logout', { expire: false })
      }
    })
  },

  /**
   * Logs out current user and if expire = true send a message to user notifying expired token
   * @param commit
   * @param dispatch
   * @param state
   * @param {Boolean} expire
   * @returns {Promise<void>}
   */
  logout ({ commit, dispatch, state }, { expire = false }) {
    if (!state.loggingOut) {
      state.loggingOut = true
      if (expire) {
        dispatch('notifications/createSnackbar', {
          message: 'Your login has expired. Redirecting to login.',
          color: 'error',
          canClose: false
        },
        { root: true })
      }
      if (['login', 'tenant'].indexOf(router.currentRoute.name) === -1) {
        commit('setLastActiveUrl', { name: router.currentRoute.name })
      } else {
        commit('setLastActiveUrl', { name: null })
      }
      dispatch('grid/resetDialog', null, { root: true })
      dispatch('grid/resetSheet', null, { root: true })
      commit('removeCachedLoginCredentials')
      try {
        commit('logout')
        keycloak.logout({ redirectUri: URL_KEYCLOAK_REDIRECT_LOGOUT })
      } catch (e) {
        console.log(e)
        router.push({ name: 'login' })
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
