<template>
  <div>
    <v-container pa-0 pb-4 fluid>
      <v-layout row wrap align-space-between justify-center fill-height>
        <!-- Billing and Summary Cards -->
        <v-flex xs12 lg3 xl3 order-xs3 order-lg1 :class="{ 'pt-4 pl-1 ': $vuetify.breakpoint.mdAndDown, 'pr-1': $vuetify.breakpoint.lgAndUp }">
          <v-layout v-bind="orderAndBillingBinding" nowrap>
            <!-- Billing -->
            <v-flex xs12 v-if="data.shipping">
              <v-card elevation="12" id="billing-card">
                <v-card-title class="billHeader"><h2 class="orderHeader">Billing</h2></v-card-title>
                <v-container py-4>
                  <address-copy v-if="hasBillTo" :address="data.shipping.billToAddress"/>
                </v-container>
                <v-footer>
                  <v-layout row nowrap justify-space-between align-center>
                    <v-flex xs3 ml-3>
                      <span v-if="data.money.terms">Terms: {{ data.money.terms }}</span>
                    </v-flex>
                  </v-layout>
                </v-footer>
              </v-card>
            </v-flex>
            <!-- Summary -->
            <v-flex xs12 :class="{ 'tpadtop': $vuetify.breakpoint.lgAndUp, 'pl-2': orderAndBillingBinding.row }">
              <v-card elevation="12" :height="summaryHeight">
                <v-card-title class="summaryHeader"><h2 class="orderHeader">Order Summary</h2></v-card-title>
                <v-card-text class="pa-0 pt-2">
                  <v-container grid-list-xl>
                    <v-layout column justify-space-around>
                      <v-flex pb-4>
                        <v-layout row justify-space-between>
                          <h3 class="orderText">SubTotal:</h3>
                          <span class="orderText">$ {{ format(itemsTotal) }}</span>
                        </v-layout>
                      </v-flex>
                      <v-flex pb-4>
                        <v-layout row justify-space-between>
                          <h3 class="orderText">Shipping:</h3>
                          <span class="orderText">$ {{ getShippingCost() }}</span>
                        </v-layout>
                      </v-flex>
                      <v-flex>
                        <v-layout row justify-space-between>
                          <h3 class="orderText">Tax:</h3>
                          <span class="orderText">$ {{ tax }}</span>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
                <v-footer absolute>
                    <v-layout row justify-space-between class="px-4">
                      <h2 class="orderText">Total:</h2>
                      <h2 class="orderText">$ {{ superTotal }}</h2>
                    </v-layout>
                </v-footer>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
        <!-- Shipping -->
        <v-flex xs12 md6 lg5 xl4 pl-1 order-xs1 order-lg2>
          <v-card elevation="12" v-if="data.shipping" height="500px">
            <v-card-title class="brown lighten-4" style="max-height: 55px;">
              <v-layout justify-start align-start row nowrap>
                  <h2>Shipping</h2>
                <v-spacer></v-spacer>
                <h3 class="blind" v-if="isBlind">BLIND</h3>
                <h3 class="pickup" v-if="data.shipping.pickupType !== 'NOT_PICKUP'">{{ data.shipping.method.method }}</h3>
              </v-layout>
            </v-card-title>
            <v-card-text>
              <!-- Ship To -->
              <v-container pb-4>
                <v-layout column>
                  <v-flex>
                    <v-layout row align-start justify-space-around v-if="hasShipTo">
                      <v-flex xs12 pb-3 align-self-start>
                        <h2 class="primary--text">To:</h2>
                      </v-flex>
                      <contact-card v-if="hasShipToContact" :contact="data.shipping.toContact"/>
                    </v-layout>
                  </v-flex>
                  <!-- Contact -->
                  <v-flex shrink>
                    <address-copy :address="data.shipping.toAddress"/>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-divider/>
              <!-- Ship From -->
              <v-container pb-5>
                <v-layout column v-if="hasShipFrom">
                  <v-flex>
                    <v-layout row align-start justify-space-around>
                      <v-flex xs12 pb-3 align-self-start>
                        <h2 class="brown--text">From:</h2>
                      </v-flex>
                      <contact-card v-if="hasShipFromContact" :contact="data.shipping.fromContact"/>
                    </v-layout>
                  </v-flex>
                  <!-- Contact -->
                  <v-flex xs6 lg7>
                    <address-copy :address="data.shipping.fromAddress"/>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <!-- Ship Method -->
            <v-footer absolute>
              <v-layout row nowrap justify-space-between align-center>
                <v-flex xs5 ml-2>
                  <v-icon small>fal fa-truck</v-icon>
                  <span style="margin-left: 5px;" v-html="data.shipping.method.method"/>
                </v-flex>
                <v-flex xs5 ml-2 style="overflow-x: hidden; text-overflow: ellipsis; white-space: nowrap;" v-if="data.shipping.method.tracking">
                  <v-icon small>fal fa-barcode-read</v-icon>
                  <span style="margin-left: 5px;" v-if="data.shipping">{{ data.shipping.method.tracking }}</span>
                </v-flex>
              </v-layout>
            </v-footer>
          </v-card>
          <v-container v-else>
            <v-layout row justify-center align-center>
              <v-btn color="primary" @click="$emit('edit-order')">Edit Order to Create Shipment</v-btn>
            </v-layout>
          </v-container>
        </v-flex>
        <!-- Items -->
        <v-flex xs12 md6 lg4 pl-2 order-xs2 order-lg3 :class="{ 'pt-4': $vuetify.breakpoint.smAndDown }">
          <v-card elevation="12" height="500px">
            <v-card-title class="itemHeader">
              <v-layout row justify-start nowrap>
                <v-flex xs5>
                  <span style="font-weight: bold; font-size: 18px;" class="orderColor pr-2">Product Summary</span>
                </v-flex>
                <v-spacer/>
                <v-flex shrink>
                  <h2 class="orderColor text-xs-right">{{ `${data.count} ${(data.count !== 1 ? 'Items' : 'Item')}` }}</h2>
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-progress-linear background-color="white" query :indeterminate="loading" color="blue" class="ma-0"/>
            <v-container pa-0>
              <v-list
                class="py-0 itemScroll"
                two-line
                avatar
              >
                <template
                  v-for="(item, index) in items"
                >
                  <div :key="index" class="tile-count">
                    <v-list-tile
                      :key="index"
                    >
                      <v-list-tile-avatar><strong>{{ item.quantity }}</strong><v-icon small style="margin-left: 8px;">fal fa-times</v-icon></v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title><strong>{{ item.pn }}</strong></v-list-tile-title>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-list-tile-action-text>{{ item.price.pretty }} ea</v-list-tile-action-text>
                        <v-flex mt-2 align-self-end>
                          <h3>${{ format(item.price.amount * item.quantity) }}</h3>
                        </v-flex>
                      </v-list-tile-action>
                    </v-list-tile>
                  </div>
                  <v-divider :key="`divider-${index}`"/>
                </template>
              </v-list>
            </v-container>
            <v-footer absolute>
              <v-layout class="totalTile" justify-space-between row nowrap align-center>
                <v-flex xs6 ml-3>
                  <h3>Item Total:</h3>
                </v-flex>
                <v-flex xs6 mr-3>
                  <h3 class="text-xs-right">${{ format(itemsTotal) }}</h3>
                </v-flex>
              </v-layout>
            </v-footer>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog
      v-model="showDialog"
      lazy
      :width="width"
      persistent
    >
      <change-shipping
        v-if="dialog === 'changeShipping'"
        :shipment-id="`${shipOrderId}`"
        :order="`${data.id}`"
        :order-type="orderType"
        @save="refreshShipping"
        @cancel="cancelDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import moneyFormatter from '../mixins/moneyFormatter'
import changeShipping from '../dialogs/changeShipping'
import mutateShipments from '@/api/graphql/mutationsJS/mutateShipments'
import addresses from '../cards/addresses'
import contact from '../cards/contact'
import { GetShippingDetails } from '@/lib/fieldDataGetters'

export default {
  name: 'orderSummary',
  components: {
    'change-shipping': changeShipping,
    'address-copy': addresses,
    'contact-card': contact
  },
  mixins: [moneyFormatter, mutateShipments],
  props: {
    data: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    itemsTotal () {
      let total = 0
      if (this.items !== undefined) {
        for (const item of this.items) {
          total = total + (item.price.amount * item.quantity)
        }
        return total
      } return '0.00'
    },

    shipOrderId () {
      return this.data?.shipping?.id ?? 0
    },

    superTotal () {
      if (this.data.money) {
        if (this.data?.shipping?.cost && this.data?.money?.tax) {
          return this.format(this.data?.shipping.cost + this.data.money.tax + this.itemsTotal)
        } else if (this.data?.shipping?.cost) {
          return this.format(this.data?.shipping.cost + this.itemsTotal)
        } else if (this.data.money.tax) {
          return this.format(this.data.money.tax + this.itemsTotal)
        } else {
          return this.format(this.itemsTotal)
        }
      } return ''
    },

    /* Shipping Computed Values for Template */
    needsMoreShippingInfo () {
      if (!this.data?.shipping) return true
      return !this.data.shipping || !this.hasShipTo || !this.shipFrom || !this.hasShipToContact || (!this.hasShipFromContact && this.data?.shipping?.blind !== 'DOUBLE_BLIND')
    },

    isBlind () {
      const blind = this.data?.shipping?.blind
      if (blind) return blind !== 'NOT_BLIND'
      else return false
    },

    isPickup () {
      return this.data?.shipping?.pickupType !== 'NOT_PICKUP'
    },

    editShippingMethodIsDisabled () {
      return this.data?.shipping?.cost.amount > 0 ?? false
    },

    hasShipTo () {
      return !!this.data?.shipping?.toAddress ?? false
    },

    hasShipToContact () {
      return !!this.data?.shipping?.toContact ?? false
    },

    hasShipFrom () {
      return !!this.data?.shipping?.fromAddress ?? false
    },

    hasShipFromContact () {
      return !!this.data?.shipping?.fromContact ?? false
    },

    hasBillTo () {
      return !!this.data?.shipping?.billToAddress ?? false
    },

    /* End Shipping Computed Variables */

    tax () {
      if (this.data.money) {
        return this.format(this.data.money?.tax) ?? '$ 0.00'
      } else {
        return 'Pending'
      }
    },

    saving: {
      get () {
        return this.$store.state.data.saving
      },
      set (value) {
        this.$store.dispatch('data/changeSaving', { bool: value })
      }
    },

    isMD () {
      return this.$vuetify.breakpoint.md
    },

    orderAndBillingBinding () {
      const binding = {}
      binding.column = !this.isMD
      binding.row = this.isMD
      return binding
    },

    isSaleSummary () {
      return this.$route.fullPath.includes('sale')
    },

    orderType () {
      return this.isSaleSummary ? 'sale' : 'purchase'
    }
  },
  data () {
    return {
      width: '400',
      showDialog: false,
      dialog: '',
      summaryHeight: ''
    }
  },
  methods: {
    changeShipping () {
      this.width = '80%'
      this.showDialog = true
      this.dialog = 'changeShipping'
    },

    refreshShipping () {
      this.$emit('refresh-shipping')
    },

    getShippingCost () {
      return this.data?.shipping?.cost ? this.format(this.data.shipping.cost) : 'Pending'
    },

    cancelDialog () {
      this.$store.dispatch('notifications/resetAlert')
      this.showDialog = false
      this.saving = false
      this.dialog = ''
    }
  },
  mounted () {
    this.$nextTick(() => {
      const el = document.getElementById('billing-card')
      this.summaryHeight = (el?.offsetHeight ?? 0) + 'px'
    })
  }
}
</script>

<style scoped>
  .billHeader {
    background-color: #C8E6C9;
  }

  .itemHeader {
    background-color: #BBDEFB;
  }

  .summaryHeader {
    background-color: #D1C4E9;
  }

  .totalTile {
    background-color: #F5F5F5;
  }

  .itemScroll {
    max-height: 380px;
    overflow-y: auto;
  }

  .blind {
    background-color: #FFD54F;
    color: #37474F;
    padding-left: 4px;
    padding-right: 4px;
    align-content: center;
    padding-top: 3px;
    border-radius: 4px;
  }

  .pickup {
    background-color: #E57373;
    color: white;
    padding-left: 4px;
    padding-right: 4px;
    align-content: center;
    padding-top: 3px;
    border-radius: 4px;
  }

  .tpadtop {
    padding-top: 14px;
  }
</style>
