import moment, { Moment } from 'moment'

const format = 'YYYY-MM-DD'
const offset = moment().utcOffset()

export interface NaturalLanguageDate {
  text: string,
  startValue: () => string,
  endValue: () => string
}

function formatIt (date: Moment) {
  return date.format(format)
}

const todayFormatted: string = formatIt(moment())

export const naturalLanguageDatesForReports: NaturalLanguageDate[] = [
  // filter out used stuff
  {
    text: 'Yesterday',
    startValue: () => formatIt(moment().subtract(1, 'day')),
    endValue: () => ''
  },
  {
    text: 'This week',
    startValue: () => formatIt(moment().startOf('week')),
    endValue: () => todayFormatted
  },
  {
    text: 'This month',
    startValue: () => formatIt(moment().startOf('month')),
    endValue: () => todayFormatted
  },
  {
    text: 'This year',
    startValue: () => formatIt(moment().startOf('year')),
    endValue: () => todayFormatted
  },
  {
    text: 'Last 3 months',
    startValue: () => formatIt(moment().subtract(3, 'months')),
    endValue: () => todayFormatted
  },
  {
    text: 'This current calendar quarter',
    startValue: () => formatIt(moment().startOf('quarter')),
    endValue: () => todayFormatted
  },
  {
    text: 'Last calendar quarter',
    startValue: () => formatIt(moment().startOf('quarter').subtract(1, 'quarter')),
    endValue: () => formatIt(moment().endOf('quarter').subtract(1, 'quarter'))
  }
]

export function GetDatesBetweenTwoDates (a: string, b: string): string[] {
  const dates = []
  const flipIt = moment(a).isAfter(moment(b))

  const currDate = moment(flipIt ? b : a).startOf('day')
  const lastDate = moment(flipIt ? a : b).startOf('day')

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone())
  }

  return dates.map(d => d.format('YYYY-MM-DD'))
}

export function GetEarliestDateFromList (dates: string[]): string {
  const moments = dates.map(d => moment(d))
  return formatIt(moment.min(moments))
}

export function GetLatestDateFromList (dates: string[]): string {
  const moments = dates.map(d => moment(d))
  return formatIt(moment.max(moments))
}

export function GetDifferenceBetweenTwoDates (a: string, b: string): number {
  return moment(a).diff(moment(b))
}

export function GetDatesFromStringSplitDates (s: string): string[] {
  if (s.indexOf(' - ') > -1) {
    const [a, b] = s.split(' - ')
    return [a, ...GetDatesBetweenTwoDates(a, b), b]
  }
  return [s]
}

export function GetTimeZonedDateTime () {
  return moment().utcOffset(offset).format()
}

export function GetUtcDateTime (date: string | undefined = undefined) {
  return moment(date).utc(false)
}

export function ConvertDateToDateTimeRange (date: string): { start: string, end: string } {
  return {
    start: moment(date).startOf('day').utc(false).format(),
    end: moment(date).endOf('day').utc(false).format()
  }
}

export function GetSecondsBetweenTimes (a: Moment, b: Moment): string {
  return b.subtract(a.seconds(), 's').format('s')
}
