import gql from 'graphql-tag'
import errorHandling from '../errorHandling'
import { FRAGMENT__BASIC_ITEM as BasicItem, FRAGMENT__BASIC_PART as BasicPart } from '@/api/graphql/Constants/Inventory'
import { FRAGMENT__PURCHASED_ITEM as PurchaseItem } from '@/api/graphql/Constants/Orders'

export default {
  mixins: [errorHandling],
  methods: {
    /**
     * Create a purchase item
     * @param {Object} item
     * @return {Promise<void>}
     */
    async createPurchasedItem (item) {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`mutation CreatePurchaseItem ($input: Create__Purchases_PurchasedItems__Input!) {
            item: Create__Purchases_PurchasedItems (input: $input) {
              ...PurchasedItem
            }
          }
          ${PurchaseItem}
          ${BasicPart}
          ${BasicItem}
          `,
          variables: { input: item }
        })
        return response.data.item
      } catch (error) {
        throw new Error(this.errorHandler(error))
      }
    }
  }
}
