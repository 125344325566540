import router from '@/router/router'
import { AgGridQueryType, RouteQueryParamsForAgGrid } from '@/models/ExtraAgGridRelatedModels'
import { RouterQueryParamsForBackend } from '@/models/ExtraBackendModels'

export function ViewBreakdownTask (id: string) {
  RoutePushWithIdParameter(id, 'breakdown', 'id', 'equals')
}

export function RoutePushWithIdParameter (id: string, name: string, field: string, type: AgGridQueryType) {
  if (router.currentRoute.name !== name && id !== router?.currentRoute?.query?.value) {
    router.push({ name: name, query: { field: field, type: type, value: `${id}` } })
  }
}

export function RoutePushWithDynamicQuery (name: string, query: RouteQueryParamsForAgGrid) {
  if (router.currentRoute.name !== name || query.value !== router?.currentRoute?.query?.value) {
    router.push({ name: name, query: query })
  }
}
