<template>
  <v-menu
    offset-y
    left
    lazy
    transition="scale-transition"
    origin="top right"
    :dark="isDarkTheme"
    :close-on-content-click="false"
    min-width="430px"
  >
    <template #activator="{ on: menu }">
      <v-btn
        class="mx-0"
        flat
        v-on="menu"
        @click="unreadNotifications = false"
      >
        <v-icon
          color="white"
          class="fa-comment-alt"
          :class="{ notify: unreadNotifications, 'fal': !hasNotifications, 'fas': hasNotifications }"
        />
      </v-btn>
    </template>
    <v-list
      two-line
    >
      <v-subheader>
        <span><b>{{ notifications.length }} notification{{ notifications.length === 1 ? '' : 's' }}</b></span>
        <v-spacer></v-spacer>
        <v-btn :disabled="!hasNotifications" small flat color="primary" @click="dismissAll">dismiss all</v-btn>
      </v-subheader>
      <v-divider/>
      <div
        style="max-height: 290px; overflow-y: auto;"
      >
        <div
          v-for="(note, index) in notifications"
          :key="note.text"
        >
          <notification
            :details="note"
            @clicked="notificationHandler(note, index)"
            @read="removeNotification(index)"
          />
          <v-divider v-if="index < notifications.length - 1"/>
        </div>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import notification from '@/components/notifications/notification'
import { PushWithDynamicQuery } from '@/lib/routerHelper'
export default {
  name: 'NotificationContainer',
  components: {
    'notification': notification
  },
  computed: {
    notifications () {
      return this.$store.state.notifications.notifications
    },

    hasNotifications () {
      return this.notifications.length > 0
    },

    unreadNotifications: {
      get () {
        return this.$store.state.notifications.hasUnreadNotifications
      },
      set (value) {
        this.$store.dispatch('notifications/changeUnreadNotifications', { bool: value })
      }
    },

    theme () {
      return this.$store.state.profile.theme
    },

    isDarkTheme () {
      return this.theme === 'dark'
    }
  },
  methods: {
    notificationHandler (notification) {
      const route = notification.route
      const query = notification.query
      PushWithDynamicQuery({ name: route, query: query })
    },

    removeNotification (index) {
      this.notifications.splice(index, 1)
    },

    dismissAll () {
      this.notifications.splice(0, this.notifications.length)
    }
  }
}
</script>
