




















































































































































































































































































































































































































































































































/*
 * This Bad Boy is an experiment in trying out not only the composition api for a large component
 * such as this but also a new methodology in organizing code (new to this app).
 *
 * The premise:
 *  The composition functions hold state and the logic of HOW to set that state
 *  The custom logic surrounding what happens when the state changes is determined in the
 *  very small and easy to read child components and watchers (might not be small) inside this component
 */
import {
  computed,
  defineComponent, provide,
  reactive,
  toRefs
} from '@vue/composition-api'
import { CheckClientPO } from '@/api/graphql/Constants/Orders'
import companies from '../autocompletes/companies.vue'
import contacts from '../autocompletes/contacts.vue'
import addPartsTemplate from '../templates/addPartsTemplate.vue'
import dynamicFieldEntry from '../templates/dynamicFieldEntry.vue'
import carrierServices from '@/components/autocompletes/carrierServices.vue'
import formTemplate from '@/components/forms/formTemplate.vue'
import liveCommentArea from '@/components/templates/liveCommentArea.vue'
import BlindDetails from '@/components/forms/BlindDetails.vue'
import ClientQuotes from '@/components/autocompletes/ClientQuotes.vue'
import AddressesAutocomplete from '@/components/autocompletes/AddressesAutocomplete.vue'
import CarrierAccountsAutocomplete from '@/components/autocompletes/CarrierAccountsAutocomplete.vue'
import users from '@/components/autocompletes/users.vue'
import TermsAutofill from '@/components/autocompletes/TermsAutofill.vue'
import store from '@/store/store'
import CancelButton from '@/components/buttons/CancelButton.vue'
import { useSale } from '@/composition/UseSale'
import { ApolloClients } from '@vue/apollo-composable'
import { apolloClient } from '@/api/graphql/apollo'

// @ts-ignore
export default defineComponent({
  name: 'NewSale',
  components: {
    'add-part-set': addPartsTemplate,
    'addresses': AddressesAutocomplete,
    'blind-details': BlindDetails,
    'cancel-button': CancelButton,
    'carrier-accounts': CarrierAccountsAutocomplete,
    'carrier-services': carrierServices,
    'client-quotes': ClientQuotes,
    'companies': companies,
    'contacts': contacts,
    'dynamic-field-entry': dynamicFieldEntry,
    'live-comments-area': liveCommentArea,
    'new-data-form': formTemplate,
    'terms': TermsAutofill,
    // @ts-ignore
    'users': users
    // 'email-multi-select': EmailMultiSelect,
  },
  setup () {
    provide(ApolloClients, {
      default: apolloClient
    })
    const orderToEdit = computed(() => store.state.data.editOrder)
    const orderId = computed(() => orderToEdit.value.id)
    const orderStatus = computed(() => orderToEdit.value.statusId)
    /* Component Specific */
    const localComponentStuff = reactive({
      tabs: 0,
      shipmentOrderId: '',
      succeeded: false,
      failed: false
    })
    const footerMessage = computed(() => {
      if (orderForm.clientOrderNumber.message) {
        return orderForm.clientOrderNumber.message
      } else if (hasErrors.value) {
        return 'Order requires corrections/more data, fields are marked in red above.'
      } else if (localComponentStuff.succeeded) {
        return 'Success!'
      } else if (orderId.value) {
        return `Editing: ST -- ${orderId.value}`
      } else {
        return 'New Sale'
      }
    })
    const footerColor = computed(() => {
      if (orderForm.clientOrderNumber.message) return 'warning'
      if (hasErrors.value) return 'warning'
      if (localComponentStuff.succeeded) return 'success'
      return 'indigo'
    })
    const close = () => {
      store.dispatch('grid/resetSheet')
    }

    const callClientPoChecker = async () => {
      if (!orderId.value) {
        orderForm.clientOrderNumber.message = await CheckClientPO(orderForm.clientOrderNumber.value, orderForm.client.value)
      }
    }

    const {
      order: orderForm,
      hasOrderErrors,
      shipment: shippingForm,
      hasShippingErrors,
      filters,
      isBlind,
      blind: blindForm,
      hasBlindErrors,
      checkBlind: CheckClientContactValue,
      parts: partsForm,
      hasPartErrors,
      hasErrors,
      extra,
      loadingExistingOrder,
      saving,
      processing,
      submit,
      onCreated,
      onFailure,
      input
    } = useSale(orderId)

    onCreated(() => {
      localComponentStuff.succeeded = true
    })
    onFailure((e) => {
      localComponentStuff.failed = true
    })

    /* Input Objects */
    return {
      orderId,
      ...toRefs(localComponentStuff),
      hasErrors,
      isBlind,
      orderForm,
      callClientPoChecker,
      hasOrderErrors,
      shippingForm,
      filters,
      hasShippingErrors,
      footerMessage,
      footerColor,
      CheckClientContactValue,
      blindForm,
      hasBlindErrors,
      extra,
      saving,
      processing,
      loadingExistingOrder,
      partsForm,
      hasPartErrors,
      input,
      close,
      submit,
      orderStatus
    }
  }
})
