<template>
  <div>
    <v-container>
      <v-layout row wrap justify-center>
        <v-flex xs3>
          <choice-list :config="subTypeConfig"></choice-list>
        </v-flex>
      </v-layout>
    </v-container>
    <standard-actions
      @save="save"
      @cancel="cancel"
      @close="$emit('close')"
      :save-button="{ name: 'Submit', disabled: !canProceed }"
      :cancel-button="{ name: 'Back' }"
    ></standard-actions>
  </div>
</template>

<script>
import standardActions from '../standardActions'
import choices from '../../autocompletes/choices'

export default {
  name: 'storage',
  components: {
    'standard-actions': standardActions,
    'choice-list': choices
  },
  computed: {
    canProceed () {
      return this.subTypeConfig.id > -1
    }
  },
  data () {
    return {
      subTypeConfig: {
        id: 0,
        label: 'Sub Type',
        appName: 'parts',
        modelName: 'disk_drive',
        fieldName: 'sub_type'
      }
    }
  },
  methods: {
    save () {
      const details = {
        sub_type_id: this.subTypeConfig.id
      }
      this.$emit('save', details)
    },

    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
