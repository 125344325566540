import gql from 'graphql-tag'
import errorHandling from '../errorHandling'
import { UPDATE_INVENTORY_ITEM } from '@/api/graphql/Constants/Inventory'

export default {
  mixins: [errorHandling],
  computed: {
    user () {
      return this.$store.state.profile.user
    }
  },
  methods: {
    async lockItem (id, comment) {
      try {
        await this.$apollo.mutate({
          mutation: gql`mutation LockItem ($comment: String!){
            Update__Inventory_Item (input: { id: ${id}, locked: true, lock_reason: $comment }) {
              id
            }
          }`,
          variables: { comment: comment }
        })
      } catch (error) {
        throw new Error(this.errorHandler(error))
      }
    },

    async unLockItem (id) {
      try {
        await this.$apollo.mutate({
          mutation: gql`mutation UnlockItem {
            item: Update__Inventory_Item (input: { id: ${id}, locked: false, lock_reason: " " }) {
              id
            }
          }`
        })
      } catch (error) {
        throw new Error(this.errorHandler(error))
      }
    },

    async updateItem (item) {
      try {
        const response = await this.$apollo.mutate({
          mutation: UPDATE_INVENTORY_ITEM,
          variables: { input: [item] }
        })
        return response.data.item
      } catch (error) {
        throw new Error(this.errorHandler(error))
      }
    }
  }
}
