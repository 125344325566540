import gql from 'graphql-tag'

/* FRAGMENTS */

export const FRAGMENT__USER_MINIMAL = gql`
  fragment UserMinimal on Users_User {
    id
    initials
  }
`

export const FRAGMENT__USERS_FULL = gql`
    fragment FullUser on Users_User {
      id
      dateJoined: date_joined
      department: departments_m2m {
        id
        department {
          id
          name
          roles {
            id
            role {
              id
              name
            }
          }
        }
      }
      email
      firstName: first_name
      fullName: full_name
      initials
      isActive: is_active
      isStaff: is_staff
      isSuperUser: is_superuser
      lastLogin: last_login
      lastName: last_name
      subs: subordinates {
        id
        user {
          id
          initials
        }
      }
      team: teams_m2m {
        id
        member {
          id
          initials
        }
      }
      username
    }
`

/* HELPERS */

/* QUERIES */

export const GET_USERS__INPUT = gql`
  query GetUsersFull ($input: ManyQueryInput!) {
    users: users_users (input: $input) {
      ...FullUser
    }
  }
  ${FRAGMENT__USERS_FULL}
`

export const GET_USERS_FOR_AUTOFILL = gql`
  query GetUsersForAutofill {
    users: users_users (input: { limit: 2000, order_by: "first_name" }) {
      id
      title: full_name
      subtitle: initials
      profile {
        id
        color
      }
    }
  }
`

export const GET_USERS_ORDER_BY_INITIALS__AUTOFILL = gql`
    query UsersForAutofill {
      reps: users_users (input: { limit: 1000, order_by: "initials" }) {
        id
        title: initials
        subtitle: full_name
      }
    }
`

/* MUTATIONS */

export const UPDATE_USER_PROFILE = gql`
  mutation Update_User_Profile ($input: Update__Profile_Profile__Input!) {
    Update__Profile_Profile (input: $input) {
      id
      commission_percentage
    }
  }
`
