



































import { ComputedRef, defineComponent, ref, Ref, computed, watch, provide } from '@vue/composition-api'
import { ApolloClients, useMutation } from '@vue/apollo-composable'
import tAlert from '@/components/notifications/tAlert.vue'
import PartsAutofill from '@/components/autocompletes/PartsAutofill.vue'
import {
  LINK_SUB_PART_ONE_WAY,
  LINK_SUB_PART_TWO_PAY,
  MASTER_PART_SUB_PARTS
} from '@/api/graphql/Constants/Inventory'
import { apolloClient } from '@/api/graphql/apollo'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default defineComponent({
  name: 'LinkSubPart',
  components: {
    't-alert': tAlert,
    'part-autofill': PartsAutofill,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  props: {
    part: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    provide(ApolloClients, {
      default: apolloClient
    })
    const message: Ref<string> = ref('')
    const messageType: Ref<string> = ref('info')

    const success: ComputedRef<boolean> = computed(() => messageType.value === 'success')

    const isTwoWay: Ref<boolean> = ref(false)
    const sub = ref({ id: 0, pn: '' })
    const subMessage: Ref<string> = ref('')
    const subIsSelected: ComputedRef<boolean> = computed(() => sub.value.id > 0)
    watch(sub, () => { subMessage.value = '' })
    function setSubMessage () {
      subMessage.value = `Select a sub part to link to ${props.part.pn}`
    }

    const queryVariablesForCache = {
      variables: {
        input: {
          annotations: [],
          excludes: [],
          filters: [{ key: 'alt_part_for_part__id', value: props.part.id }],
          limit: 51,
          offset: 0,
          order_by: []
        }
      }
    }
    const { mutate: linkPart, loading: isLinkingPart, onError: onLinkError, onDone: onLinked } = useMutation(
      isTwoWay.value ? LINK_SUB_PART_TWO_PAY : LINK_SUB_PART_ONE_WAY,
      () => ({
        variables: {
          part: props.part.id,
          sub: sub.value.id
        },
        update: (cache, { data: { sub } }) => {
          try {
            const data: any = cache.readQuery({ query: MASTER_PART_SUB_PARTS, ...queryVariablesForCache })
            if (data.sub_parts.length > 0) {
              data.sub_parts.push(sub)
              cache.writeQuery({ query: MASTER_PART_SUB_PARTS, ...queryVariablesForCache, data })
            } else {
              cache.writeQuery({ query: MASTER_PART_SUB_PARTS, ...queryVariablesForCache, data: { sub_parts: [sub] } })
            }
          } catch (error) {
            emit('success', { name: 'link-sub-part' })
          }
        }
      })
    )
    onLinked(() => {
      message.value = 'Linked successfully!'
      messageType.value = 'success'
    })
    onLinkError(() => {
      message.value = 'Unable to link part, we must have messed up!'
      messageType.value = 'error'
    })

    return {
      message,
      messageType,
      success,
      isTwoWay,
      sub,
      subMessage,
      subIsSelected,
      setSubMessage,
      linkPart,
      isLinkingPart
    }
  }
})
