<template>
    <v-card data-cy="form-template-card">
      <!-- Panels -->
      <v-container
        fluid
        data-cy="form-container"
        class="form-container"
        id="form-template"
      >
        <v-expansion-panel
          expand
          v-model="panels"
        >
          <v-expansion-panel-content
            v-for="( panel, index ) in panelSlots"
            :disabled="!_shownPanels[panel]"
            :key="panel"
          >
            <template #header>
              <div style="margin-bottom: 20px;">
                <div style="width: max-content;">
                  <div
                    :class="`form-shadow form-shadow--${getClassSuffix(panel)}`"
                  >
                    <h3
                      :class="`form-title form-title--${getClassSuffix(panel)}`"
                    ><slot :name="`header${index}`"/>
                    </h3>
                  </div>
                </div>
              </div>
            </template>
            <template #actions>
              <v-icon color="error" v-if="errors[panel]">fal fa-exclamation-circle</v-icon>
              <v-icon small v-else>fal fa-chevron-down</v-icon>
            </template>
            <slot :name="panel"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-container>

      <!-- Actions -->
      <v-container fluid style="background-color: #dfdfdf;" py-2>
        <v-layout row wrap justify-space-between align-center>
          <v-flex xs12 sm6>
            <v-layout row justify-start align-center>
              <!-- Expander -->
              <div>
                <v-btn
                  v-if="$vuetify.breakpoint.smAndDown"
                  icon
                  @click="expandAll"
                >
                  <v-icon>far fa-expand-arrows-alt</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  @click="expandAll"
                >Expand All</v-btn>
              </div>
              <!-- Collapse All -->
              <div>
                <v-btn
                  v-if="$vuetify.breakpoint.smAndDown"
                  icon
                  @click="collapseAll"
                >
                  <v-icon>far fa-compress-arrows-alt</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  @click="collapseAll"
                >Collapse All</v-btn>
              </div>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm6>
            <v-layout row justify-end align-center>
              <slot name="dataActions"></slot>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>

      <!-- Footer -->
      <slot name="footer">
        <v-footer data-cy="new-data-form-footer" :color="succeeded ? 'success' : hasErrors ? 'error' : 'info'">
          <v-layout row justify-center align-center>
            <slot name="footerMessage">
              <h3 :class="{ 'white--text': !succeeded, 'grey--text text--darken-2': succeeded }">{{ footerMessage }}</h3>
            </slot>
          </v-layout>
        </v-footer>
      </slot>

      <!-- Dialogs -->
      <slot name="dialogs"></slot>
    </v-card>
</template>

<script>
export default {
  name: 'FormTemplate',
  props: {
    errors: {
      type: Object,
      required: true
    },
    succeeded: {
      type: Boolean,
      required: false,
      default: false
    },
    failed: {
      type: Boolean,
      required: false,
      default: false
    },
    footerMessage: {
      type: String,
      required: false
    },
    shownPanels: {
      type: Object,
      required: false
    }
  },
  computed: {
    _shownPanels () {
      const shownPanels = {}
      if (this.shownPanels === undefined) {
        for (const slot in this.$slots) {
          if (slot.includes('panel')) {
            shownPanels[slot] = true
          }
        }
      } else {
        for (const slot in this.$slots) {
          if (slot.includes('panel') && this.shownPanels[slot] === undefined) {
            shownPanels[slot] = true
          } else if (slot.includes('panel')) {
            shownPanels[slot] = this.shownPanels[slot]
          }
        }
      }
      return shownPanels
    },

    panelSlots () {
      const slotters = []
      for (const slot in this.$slots) {
        if (slot.includes('panel')) {
          slotters.push(slot)
        }
      }
      return slotters
    },

    hasErrors () {
      for (const error in this.errors) {
        if (this.errors[error]) return true
      }
      return this.failed
    }
  },
  watch: {
    shownPanels: {
      deep: true,
      handler: function (value) {
        for (const panel in this.shownPanels) {
          if (this.shownPanels[panel]) {
            this.panels.splice(panel.replace('panel', ''), 1, true)
          } else {
            this.panels.splice(panel.replace('panel', ''), 1, false)
          }
        }
      }
    }
  },
  data () {
    return {
      panels: []
    }
  },
  methods: {
    isActiveCard (index) {
      if (this.panels.length >= index - 1) {
        return this.panels[index] === true
      } return false
    },

    getClassSuffix (panel) {
      if (this._shownPanels[panel]) {
        if (this.errors[panel]) return 'error'
        if (this.panels[panel.replace('panel', '')]) return 'active'
        else return 'inactive'
      } else {
        return 'disabled'
      }
    },

    expandAll () {
      this.panels = [...Array(this.panelSlots.length).keys()].map(_ => {
        return this._shownPanels[`panel${_}`]
      })
    },

    collapseAll () {
      this.panels = []
    }
  },
  mounted () {
    if (this.panels.length === 0) {
      this.panels.push(true)
    }
  }
}
</script>

<style>
  #form-template {
    /*scroll-behavior: smooth;*/
  }

  .v-expansion-panel__header:hover {
    background-color: hsl(205, 87%, 96%);
  }
</style>
