<template>
  <v-dialog
    ref="datePicker"
    v-model="showDatePicker"
    :close-on-content-click="false"
    lazy
    offset-y
    transition="scale-transition"
    max-width="290px"
    max-height="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="localDate"
        v-on="on"
        persistent-hint
        readonly
        clearable
        data-cy="date-picker-text"
        :label="label"
        :hint="hint"
        :disabled="disabled"
        :error-messages="errors"
      >
        <template #prepend>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-icon data-cy="date-picker-today" v-on="on" color="purple" @click="dateIsToday">today</v-icon>
            </template>
            <span>Click for today!</span>
          </v-tooltip>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="pickedDate"
      no-title
      scrollable
      :max="maxDate"
      :min="minDate"
    >
      <v-spacer></v-spacer>
      <v-btn flat color="info" @click="showDatePicker = false">Cancel</v-btn>
      <v-btn flat color="info" @click="saveDate()">OK</v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import moment from 'moment'
export default {
  name: 'DatePicker2',
  props: {
    date: {
      required: true,
      type: String
    },
    maxDate: {
      required: false,
      type: String,
      default: ''
    },
    minDate: {
      required: false,
      type: String,
      default: ''
    },
    label: {
      required: true,
      type: String
    },
    hint: {
      type: String,
      required: false,
      default: 'YYYY-MM-DD'
    },
    errors: {
      required: true,
      type: String
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  computed: {
    localDate: {
      get () {
        return this.date
      },
      set (value) {
        this.$emit('updated', { value: value || '', hint: value ? moment(value).format('MMMM Do YYYY') : 'Empty value will clear this field', dateTime: moment(value).format() })
      }
    }
  },
  data () {
    return {
      pickedDate: null,
      showDatePicker: false
    }
  },
  methods: {
    saveDate () {
      this.localDate = this.pickedDate
      this.showDatePicker = false
    },

    dateIsToday () {
      this.localDate = moment().format('YYYY-MM-DD')
    }
  }
}
</script>
