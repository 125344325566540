<template>
  <v-autocomplete
    v-model="part"
    :attach="attach"
    :search-input.sync="search"
    :items="parts"
    :loading="loading"
    :filter="partsFilter"
    label="Part Number"
    :error-messages="error"
    :rules="rules"
    item-text="pn"
    :id="id"
    return-object
    append-icon="fal fa-times"
    @click:append="clear"
    >
    <template v-slot:message="{ message, key }">
      <span :key="key" v-html="message"/>
    </template>
    <template
      v-slot:item="data"
      >
      <v-list-tile-content data-cy="part-selection-content">
        <v-list-tile-title> {{ data.item.pn }} </v-list-tile-title>
      <v-list-tile-sub-title> {{ data.item.description + ' | ' + data.item.type }}</v-list-tile-sub-title>
      </v-list-tile-content>
    </template>
    <template
      v-slot:no-data
      >
      <v-list-tile v-if="loading">
        <v-list-tile-title>
          Loading your parts!
        </v-list-tile-title>
      </v-list-tile>
      <v-list-tile v-else-if="showHint">
        <v-list-tile-title>
          Start entering a part number...
        </v-list-tile-title>
      </v-list-tile>
      <v-list-tile v-else>
        <v-list-tile-title>
          No parts found.
        </v-list-tile-title>
      </v-list-tile>
    </template>
  </v-autocomplete>
</template>

<script>
import commonAutofillFilters from '../mixins/commonAutofillFilters'
import { MASTER_PARTS, PARTS__FILTER } from '@/api/graphql/Constants/Inventory'
export default {
  name: 'PartsAutofill',
  mixins: [commonAutofillFilters],
  props: {
    selected: {
      type: Object,
      required: true
    },
    attach: {
      type: Boolean,
      required: false,
      default: true
    },
    error: {
      type: String,
      required: false,
      default: ''
    },
    id: {
      type: String,
      required: false,
      default: 'part-field'
    }
  },
  computed: {
    part: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('updated', { value: value })
      }
    },
    showHint () {
      return (this.search?.length ?? 0) < 3
    }
  },
  watch: {
    search (newValue) {
      if (newValue) {
        if ((newValue.length ?? '') >= 3) {
          this.loading = true
          this.$apollo.query({
            query: PARTS__FILTER,
            variables: {
              filters: [{ key: 'pn__istartswith', value: newValue }]
            }
          }).then(response => {
            if (response.data.parts) {
              this.parts.push(...response.data.parts)
            }
          }).finally(() => {
            this.loading = false
          })
        }
      }
    },
    parts () {
      this.skip = true
    }
  },
  data () {
    return {
      rules: [e => !!e || 'Select a part'],
      search: '',
      searched: false,
      parts: [],
      skip: true,
      loading: false
    }
  },
  methods: {
    clear () {
      this.search = ''
      this.$emit('updated', { value: { id: '', pn: '' } })
      this.parts.splice(0, this.parts?.length ?? 0)
      this.searched = false
    }
  },
  beforeMount () {
    if (this.selected.id) {
      this.$apollo.query({
        query: MASTER_PARTS,
        variables: {
          input: {
            filters: [{ key: 'id', value: this.selected.id }]
          }
        }
      }).then(({ data: { parts } }) => {
        this.parts.push(...parts)
      })
    }
  }
}
</script>
