<template>
  <v-card>
    <v-card-title style="background-color: #424242; color: white;">
      <h3>Fill out blind address details</h3>
    </v-card-title>
    <v-container grid-list-xl>
      <form action="" autocomplete="off">
        <v-layout row wrap justify-center align-center>
          <!-- Contact Phone -->
          <v-flex xs12>
            <v-layout justify-center>
              <v-flex shrink>
                <v-text-field
                  v-model="phone"
                  label="Contact Phone Number"
                  placeholder="Required by carriers"
                  prepend-icon="far fa-phone"
                  counter="20"
                  hint="It can be your own phone number if N/A"
                  persistent-hint
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- Street 1 -->
          <v-flex xs6 lg3 pl-2>
            <v-text-field
              data-cy="street1"
              v-model="street1"
              counter="100"
              box
              label="Street 1"
            />
          </v-flex>
          <!-- Street 2 -->
          <v-flex xs6 lg3 pl-2>
            <v-text-field
              data-cy="street2"
              v-model="street2"
              counter="100"
              box
              label="Street 2"
            />
          </v-flex>
          <!-- City -->
          <v-flex xs6 lg3 pl-2>
            <v-text-field
              data-cy="city"
              v-model="city"
              box
              label="City"
            />
          </v-flex>
          <!-- State -->
          <v-flex xs6 lg3 pl-2>
            <v-text-field
              data-cy="state"
              v-model="state"
              box
              label="State"
            />
          </v-flex>
          <!-- Country -->
          <v-flex xs6 lg3 pl-2>
            <countries
              data-cy="country"
              :country="country"
              @updated="country = $event.value"/>
          </v-flex>
          <!-- ZipCode -->
          <v-flex xs6 lg3 pl-2>
            <v-text-field
              data-cy="zip"
              v-model="zip"
              box
              label="ZipCode"
            />
          </v-flex>
          <v-flex xs12>
            <span id="validate-text" class="primary--text bold">We'll use whatever you enter here as the address.</span>
          </v-flex>
        </v-layout>
      </form>
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <cancel-button class="pr-3" @click="$emit('close')"/>
      <submit-button :loading="false" :disabled="streetsTooLong" @click="save"/>
    </v-card-actions>
  </v-card>
</template>

<script>

import countries from '@/components/autocompletes/countries'
import { VERIFY_ADDRESS } from '@/api/graphql/Constants/Addresses'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import CancelButton from '@/components/buttons/CancelButton.vue'

export default {
  name: 'blindDetails',
  components: {
    'countries': countries,
    'submit-button': SubmitButton,
    'cancel-button': CancelButton
  },
  computed: {
    streetsTooLong () {
      return this.street1.length > 100 || this.street2.length > 100
    }
  },
  data () {
    return {
      phone: '',

      street1: '',
      street2: '',
      city: '',
      state: '',
      country: '',
      zip: ''

    }
  },
  methods: {
    async save (company, contact) {
      const mutation = {
        mutation: VERIFY_ADDRESS,
        variables: {
          input: {
            ...(company && { company: company }),
            ...(contact && { name: contact }),
            street1: this.street1,
            street2: this.street2,
            city: this.city,
            state: this.state,
            country: this.country,
            phone: this.phone,
            zip: this.zip,
            verify: ['delivery']
          }
        }
      }
      const response = await this.$apollo.mutate(mutation)
      const easypostId = response.data?.verified?.id
      if (response) {
        this.$emit('save', {
          street_1: this.street1,
          street_2: this.street2,
          city: this.city,
          state: this.state,
          country: this.country,
          phone: this.phone,
          easypost_id: easypostId,
          zip_code: this.zip
        })
      }
    }
  }
}
</script>
