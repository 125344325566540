<template>
  <v-autocomplete
    v-model="localClient"
    ref="autocomplete"
    :search-input.sync="search"
    :items="clients"
    :loading="$apollo.loading"
    :filter="clientFilter"
    :error-messages="error"
    :id="elId"
    attach
    :disabled="disabled"
    label="Client"
    item-text="name"
    :item-disabled="disableItemFunction"
    return-object
    append-icon="fal fa-times"
    @click:append="reset"
  >
    <!-- Message Slot -->
    <template v-slot:message="{ message, key }">
      <span :key="key" v-html="message"/>
    </template>
    <!-- Item Slot -->
    <template v-slot:item="{ item }">
      <slot name="item" :client="item">
        <slot name="status" v-bind:client="item"></slot>
        <v-list-tile-content>
          <v-list-tile-title>{{ item.name }}</v-list-tile-title>
          <v-list-tile-sub-title>{{ item.short_name }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </slot>
    </template>
    <!-- No-Data slot -->
    <template
      v-slot:no-data
    >
      <v-list-tile v-if="$apollo.loading">
        <v-list-tile-title>
          Loading your clients!
        </v-list-tile-title>
      </v-list-tile>
      <v-list-tile v-else-if="searchLength < 3">
        <v-list-tile-title>
          Enter at least three characters for auto-fill
        </v-list-tile-title>
      </v-list-tile>
      <v-list-tile v-else>
        <v-list-tile-title>
          No client found.
        </v-list-tile-title>
      </v-list-tile>
    </template>
    <!-- Prepend-Item slot -->
    <template v-slot:prepend-item v-if="searchLength > 2 && clients.length === 0 && $apollo.loading === false">
      <slot name="prepend-item" v-bind:reference="reference"/>
    </template>
  </v-autocomplete>
</template>

<script>
import { GET_CLIENTS__LIST } from '@/api/graphql/Constants/Clients'
export default {
  name: 'companies',
  props: {
    client: {
      type: Object,
      required: true
    },
    contactOwner: {
      type: [Number, String],
      required: false,
      default: 0
    },
    elId: {
      type: String,
      required: false,
      default: ''
    },
    error: {
      type: String,
      required: true
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    localClient: {
      get () {
        return this.client
      },
      set (value) {
        this.$emit('updated', { value: value })
      }
    },

    searchLength () {
      return this.search?.length ?? 0
    }
  },
  watch: {
    search (newValue, oldValue) {
      newValue = newValue === null ? '' : newValue
      oldValue = oldValue === null ? '' : oldValue
      if (newValue.length < oldValue.length) {
        this.searched = false
      }
      // this.search = newValue
      if (newValue.length > 2 && (!this.searched || this.clients.length === 0)) {
        this.skip = false
        if (newValue === 'ALL') {
          this.search = ''
        }
        this.searched = true
      }
    },
    clients () {
      this.skip = true
    },
    contactOwner: async function (value) {
      this.skip = true
      this.searched = true
      if (value !== this.localClient.id && value) {
        const index = this.clients.findIndex(c => {
          return c.id === value
        })
        if (index === -1) {
          const response = await this.$apollo.query({
            query: GET_CLIENTS__LIST,
            variables: {
              input: {
                filters: [{ key: 'id', value: value }]
              }
            }
          })
          this.clients = response.data.clients_clients
          this.localClient = this.clients?.[0] ?? { id: 0, name: '' }
        }
      }
    }
  },
  data () {
    return {
      clients: [],
      search: '',
      searched: false,
      skip: true,
      reference: null,
      parentIsNewSale: false
    }
  },
  apollo: {
    clients: {
      query: GET_CLIENTS__LIST,
      variables () {
        return {
          input: {
            filters: [
              { key: 'name__icontains', value: this.search, or: { key: 'short_name__icontains', value: this.search } },
              { key: 'is_our_client', value: true }
            ]
          }
        }
      },
      skip () {
        return this.skip
      },
      update: data => data.clients_clients
    }
  },
  methods: {
    clientFilter (item, query) {
      const fullName = item.name.toLowerCase()
      const code = item.short_name.toLowerCase()
      const search = query.toLowerCase()
      return fullName.indexOf(search) > -1 || code.indexOf(search) > -1
    },

    disableItemFunction (item) {
      if (this.parentIsNewSale) return !item.allow_sales
      return false
    },

    reset () {
      this.search = ''
      this.clients = []
      this.localClient = {}
    }
  },
  async beforeMount () {
    if (this.localClient?.id && this.clients.length === 0) {
      const id = this.localClient.id
      const response = await this.$apollo.query({
        query: GET_CLIENTS__LIST,
        variables: {
          input: {
            filters: [{ key: 'id', value: id }]
          }
        }
      })
      const returnedClients = response.data.clients_clients
      if (returnedClients) {
        this.clients = returnedClients
        this.localClient = returnedClients[0]
      }
    }
  },
  mounted () {
    this.reference = this.$refs.autocomplete
    this.$nextTick(() => {
      this.parentIsNewSale = document.querySelector('[data-cy="footer-sale"]') !== null
    })
  }
}
</script>
