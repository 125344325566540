







































import { computed, defineComponent, provide } from '@vue/composition-api'
import { ApolloClients, useQuery, UseQueryReturn, useResult, UseResultReturn } from '@vue/apollo-composable'
import { apolloClient } from '@/api/graphql/apollo'
import { GET_QUOTES_FROM_CUSTOMER_ID } from '@/api/graphql/Constants/Quotes'
import { GetQuotesQuery, GetQuotesQueryVariables, Quotes_Quote } from '@/models/generated/graphql/ErpBackend'
export default defineComponent({
  name: 'ClientQuotes',
  props: {
    clientId: {
      type: String,
      required: true,
      default: ''
    },
    object: {
      type: Object,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    hint: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup (props, { emit }) {
    provide(ApolloClients, {
      default: apolloClient
    })

    const localQuote = computed({
      get () {
        return props.object
      },
      set (value) {
        if (value) emit('updated', { value: value })
        else emit('updated', { value: {} })
      }
    })

    const {
      result,
      loading
    }: UseQueryReturn<GetQuotesQuery, GetQuotesQueryVariables> = useQuery(
      GET_QUOTES_FROM_CUSTOMER_ID,
      () => ({
        id: props.clientId
      }),
      () => ({
        enabled: !!props.clientId
      })
    )

    /**
     * @type Array<{ id: string, contact: { id: string, full_name: string }}>
     */
    const quotes = useResult(result, [], data => data.quotes)
    function getSubTitle (quote: Quotes_Quote) {
      return `${quote.contact?.full_name ?? 'N/A'}`
    }

    return {
      localQuote,
      loading,
      quotes,
      getSubTitle
    }
  }
})
