<template>
  <div>
    <v-container>
      <v-layout row wrap justify-space-between>
        <v-flex xs3>
          <choice-list :config="securityConfig"></choice-list>
        </v-flex>
        <v-flex xs3 offset-xs1>
          <v-text-field
            v-model="antennaCount"
            label="No. of Antennas"
            placeholder="Optional"
          ></v-text-field>
        </v-flex>
        <v-flex xs3 offset-xs1>
          <v-checkbox
            v-model="externalAntennas"
            label="Antennas are external?"
          ></v-checkbox>
        </v-flex>
        <v-flex xs3>
          <v-text-field
            v-model="lanPorts"
            label="No. of LAN Ports"
            placeholder="Required if > 1"
          ></v-text-field>
        </v-flex>
        <v-flex xs3 offset-xs1>
          <choice-list :config="poeConfig"></choice-list>
        </v-flex>
        <v-flex xs3 offset-xs1>
          <v-text-field
            v-model="maxThroughput"
            label="Max Throughput"
            suffix="Mbps"
          ></v-text-field>
        </v-flex>
        <v-flex xs3>
          <v-checkbox
            v-model="mountingGearIncluded"
            label="Includes Mounting Gear?"
          ></v-checkbox>
        </v-flex>
        <v-flex xs3 offset-xs1>
          <choice-list :config="wifiStandardConfig"></choice-list>
        </v-flex>
      </v-layout>
    </v-container>
    <slot name="actions">
      <standard-actions
        @save="save"
        @cancel="cancel"
        @close="$emit('close')"
        :save-button="{ name: 'Submit', disabled: !canProceed }"
        :cancel-button="{ name: 'Back' }"
      />
    </slot>
  </div>
</template>

<script>
import standardActions from '../standardActions'
import choices from '../../autocompletes/choices'

export default {
  name: 'accessPoint',
  components: {
    'standard-actions': standardActions,
    'choice-list': choices
  },
  computed: {
    canProceed () {
      return this.securityConfig.id && this.poeConfig.id && this.maxThroughput &&
        this.wifiStandardConfig.id
    }
  },
  data () {
    return {
      securityConfig: {
        id: 0,
        label: 'Wireless Security',
        appName: 'parts',
        modelName: 'access_point',
        fieldName: 'security'
      },

      antennaCount: 0,
      externalAntennas: false,
      lanPorts: 1,
      poeConfig: {
        id: 0,
        label: 'POE Type',
        appName: 'parts',
        modelName: 'access_point',
        fieldName: 'poe_type'
      },

      maxThroughput: null,
      mountingGearIncluded: false,
      wifiStandardConfig: {
        id: 0,
        label: 'Newest Wifi Standard',
        appName: 'parts',
        modelName: 'access_point',
        fieldName: 'wifi_standards'
      }
    }
  },
  methods: {
    save () {
      this.$emit('save', {
        antennas: this.antennaCount,
        external_antennas: this.externalAntennas,
        lan_ports: this.lanPorts,
        max_throughput_mbps: this.maxThroughput,
        poe_type_id: this.poeConfig.id,
        security_id: this.securityConfig.id,
        wifi_standards_id: this.wifiStandardConfig.id
      })
    },

    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
