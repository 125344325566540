<template>
  <v-card>
    <v-card-title style="background-color: #D7CCC8; color: hsl(0, 0%, 30%)"><h3>Create new carrier account</h3></v-card-title>
    <v-container grid-list-xl>
      <v-form ref="carrierAccount" @submit.prevent="">
        <v-layout row wrap justify-start align-center>
          <v-flex xs5 lg2>
            <v-text-field
              v-model="name"
              label="Nickname"
              hint="Ex: Company FedEx Account"
              persistent-hint
              box
              data-cy="carrier-account-name"
            />
          </v-flex>
          <v-flex xs3 lg2>
            <standard-autofill
              data-cy="carrier-name"
              :id="carrier"
              :items="carriers"
              :config="carrierConfig"
              :error="errors.carrier"
              @updated="carrier = $event.value"
            />
          </v-flex>
          <v-flex xs5 lg2>
            <v-text-field
              v-model="number"
              label="Account Number"
              box
              data-cy="account-number"
              :rules="[ e => !!e || 'This field is required' ]"
            />
          </v-flex>
          <v-flex xs5 lg2>
            <v-text-field
              v-model="numberConfirm"
              label="Confirm No."
              box
              data-cy="account-number-confirm"
              :rules="[ e => e === number || 'This field must match Account Number' ]"
            />
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>
    <v-card-actions>
      <v-layout row justify-start align-center>
        <!-- Alert -->
        <t-alert :message="message" :type="type"/>
        <v-spacer></v-spacer>
        <!-- Cancel -->
        <cancel-button class="pr-3" :success="type === 'success'" @click="clear"/>
        <!-- Submit -->
        <submit-button :disabled="type === 'success'" :loading="saving" @click="submit"/>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import standard2 from '../autocompletes/standard2'
import { GET_ALL_CARRIERS__ALIASED as CARRIERS } from '@/api/graphql/Constants/Shipments'
import mutateCarrierAccounts from '../../api/graphql/mutationsJS/mutateCarrierAccounts'
import tAlert from '@/components/notifications/tAlert'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import CancelButton from '@/components/buttons/CancelButton.vue'

export default {
  name: 'AddShippingAccount',
  mixins: [mutateCarrierAccounts],
  components: {
    'standard-autofill': standard2,
    't-alert': tAlert,
    'submit-button': SubmitButton,
    'cancel-button': CancelButton
  },
  props: {
    client: {
      type: Number || String,
      default: 0
    }
  },
  data () {
    return {
      name: '',
      number: '',
      numberConfirm: '',
      carrier: 0,
      carriers: [],
      carrierConfig: {
        label: 'Carrier',
        name: 'carrier'
      },

      errors: {
        name: '',
        number: '',
        numberConfirm: '',
        carrier: ''
      },

      saving: false,
      message: '',
      type: 'info',
      color: ''
    }
  },
  apollo: {
    carriers: {
      query: CARRIERS
    }
  },
  methods: {
    clear () {
      this.name = ''
      this.number = ''
      this.numberConfirm = ''
      this.carrier = ''
      this.message = ''
      this.type = 'info'
      this.$emit('cancel')
    },

    async submit () {
      if (this.checkForErrors()) {
        this.saving = true
        try {
          const account = await this.createClientCarrierAccount({
            clientId: this.client,
            name: this.name,
            number: this.number,
            shipper: this.carrier
          })
          this.type = 'success'
          this.message = 'Success!'
          this.saving = false
          this.$emit('success', account)
        } catch (error) {
          this.saving = false
          this.message = error.message
          this.type = 'error'
        }
      } else {
      }
    },

    checkForErrors () {
      let canContinue = false
      if (this.$refs.carrierAccount.validate()) {
        canContinue = true
      }
      if (!this.carrier) {
        this.errors.carrier = 'This field is required'
        canContinue = false
      }
      return canContinue
    }
  }
}
</script>
