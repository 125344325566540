import * as S from '@sentry/vue'
import { Severity } from '@sentry/types/dist/severity'

const isLocalHost = () => window.location.host.includes('localhost')

interface Breadcrumb {
  type?: string;
  level?: Severity;
  event_id?: string;
  category?: string;
  message?: string;
  data?: {
    [key: string]: any;
  };
  timestamp?: number;
}

export const Sentry = {
  addBreadcrumb (crumb: Breadcrumb) {
    S.addBreadcrumb(crumb)
  },

  captureMessage (message: string, override: boolean = false): void {
    override && S.captureMessage(message)
  },

  captureEvent (error: Error, override: boolean = false): void {
    override && S.captureEvent(error)
  },

  captureException (error: Error, override: boolean = false): void {
    override && S.captureException(error)
  },

  resetScope () {
    S.configureScope(() => new S.Scope())
  },

  setContext (name: string, data: {[key: string]: any } | null): void {
    S.setContext(name, data)
  },

  setUser (email: string, name: string) {
    S.setUser({ email: email, username: name })
  }

}
