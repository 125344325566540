import gql from 'graphql-tag'
import errorHandling from '../errorHandling'

/**
 * @typedef {String} EasyPostId the easy post ID of the supplied object
 */

export default {
  mixins: [errorHandling],
  computed: {
    user () {
      return this.$store.state.profile.user
    }
  },
  methods: {
    /**
     * Add a box to a shipment order
     * @param {Object} box the shipment object
     * @return {Promise<void>}
     */
    async addBoxToShipment ({ box }) {
      try {
        const parcel = await this.createEasyPostParcel({ height: box.parcel_height_in, length: box.parcel_length_in, weight: box.parcel_weight_oz, width: box.parcel_width_in })
        const epShip = await this.createEasyPostShipment({
          carrierAccounts: [(this.order.account?.easypost_id ?? '')],
          toAddressId: this.order.shipTo.easypost_id,
          fromAddressId: this.order.shipFrom.easypost_id,
          parcelId: parcel.id,
          isReturnShipment: false,
          customsInfoId: box.easypost_customs_info_id ?? '',
          options: box.options,
          reference: this.reference
        })
        delete box.easypost_customs_info_id
        delete box.options
        box.easypost_id = epShip.id
        const response = await this.$apollo.mutate({
          mutation: gql`mutation AddBoxToOrder ($input: Create__Shipping_Shipment__Input!) {
            box: Create__Shipping_Shipment (input: $input) {
              id
              length: parcel_length_in
              width: parcel_width_in
              height: parcel_height_in
              weight: parcel_weight_oz
              easypost_id
              box_number
            }
          }`,
          variables: { input: box }
        })
        return response.data.box
      } catch (error) {
        throw new Error(this.errorHandler(error))
      }
    },

    /**
     * Adds an EP predefined parcel to a shipment order
     * @param box
     * @returns {Promise<Object>}
     */
    async addEasyPostPredefinedBoxToShipment ({ box }) {
      try {
        const parcel = await this.createEasyPostPredefinedParcel({ predefined: box.predefined_parcel, weight: box.parcel_weight_oz })
        const epShip = await this.createEasyPostShipment({
          carrierAccounts: [(this.order.account?.easypost_id ?? '')],
          toAddressId: this.order.shipTo.easypost_id,
          fromAddressId: this.order.shipFrom.easypost_id,
          parcelId: parcel.id,
          isReturnShipment: false,
          customsInfoId: box.easypost_customs_info_id ?? '',
          options: box.options,
          reference: this.reference
        })
        box.easypost_id = epShip.id
        delete box.easypost_customs_info_id
        delete box.options
        const response = await this.$apollo.mutate({
          mutation: gql`mutation AddEasyPostPredefinedBoxToShipment ($input: Create__Shipping_Shipment__Input!) {
            box: Create__Shipping_Shipment (input: $input) {
              id
              length: parcel_length_in
              width: parcel_width_in
              height: parcel_height_in
              weight: parcel_weight_oz
              predefined: predefined_parcel
            }
          }`,
          variables: { input: box }
        })
        return response.data.box
      } catch (error) {
        const fixError = await this.errorHandler(error)
        if (fixError.retry) this.addEasyPostPredefinedBoxToShipment({ box: box })
        else throw new Error('Could not create box.')
      }
    },

    async deleteBoxFromShipment ({ id }) {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`mutation DeleteBoxFromShipment ($id: ID!) {
            Delete__Shipping_Shipment (id: $id) {
              id
            }
          }`,
          variables: { id: id }
        })
        return response.data
      } catch (error) {
        throw new Error('Could not delete shipment')
      }
    },

    /**
     * Create a shipment order
     * @param {Object} shipment
     * @return {Promise<Object>} shipment the shipment order
     */
    async createShipmentOrder (shipment) {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`mutation CreateShipment ($input: Create__Shipping_ShipmentOrder__Input!){
            shipment: Create__Shipping_ShipmentOrder (input: $input) {
              id
            }
          }`,
          variables: { input: shipment }
        })
        return response.data.shipment
      } catch (error) {
        throw new Error(this.errorHandler(error))
      }
    },

    async updateShipmentOrderStatus (id, status, assignee = this.user.id) {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`mutation UpdateShipmentOrderStatus ($id: ID!, $status: ID!, $assignee: ID!) {
            shipment: Update__Shipping_ShipmentOrder (input: { id: $id, status_id: $status, assigned_to_id: $assignee }) {
              id
            }
          }`,
          variables: { id: id, status: status, assignee: assignee }
        })
        return response.data.shipment
      } catch (error) {
        throw new Error(this.errorHandler(error))
      }
    },

    /**
     * Creates an order with EasyPost
     * @param {EasyPostId} toAddress the EasyPostId of the toAddress
     * @param {EasyPostId} fromAddress the EasyPostId of the fromAddress
     * @param {EasyPostId} purchaseAddress the EasyPostId of the purchaseAddress
     * @param {EasyPostId} returnAddress the EasyPostId of the returnAddress
     * @param {Boolean} isReturnShipment whether or not this is a shipment for a return
     * @param {Array<EasyPostId>} shipments array of shipment EasyPost ids
     * @deprecated
     * @returns {Promise<String || Number>}
     */
    async createEasyPostOrder ({
      fromAddress,
      isReturnShipment,
      purchaseAddress,
      returnAddress = '',
      shipments,
      toAddress
    }) {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`mutation CreateEasyPostOrder ($input: EasyPost_OrderInput!) {
            order: EasyPost_MakeOrder (input: $input) {
              id
              rates {
                carrier
                deliveryDate: delivery_date
                isGuaranteedDeliveryDate: delivery_date_guaranteed
                id
                listRate: list_rate
                mode
                rate
                retailRate: retail_rate
                service
              }
              # messages {
              #  carrier
              #  message
              #  type
              # }
              # to_address {
              #   id
              #   verifications {
              #     zip4 {
              #       errors {
              #         code
              #         field
              #         message
              #       }
              #       success
              #     }
              #   }
             #  }
             #  from_address {
             #    id
             #    verifications {
             #      zip4 {
             #        errors {
             #          code
             #          field
             #          message
             #        }
             #        success
             #      }
             #    }
             #  }
            }
          }`,
          variables: {
            input: {
              buyer_address: purchaseAddress,
              from_address: fromAddress,
              is_return: isReturnShipment,
              ...(returnAddress && { return_address: returnAddress }),
              shipments: shipments,
              to_address: toAddress
            }
          }
        })
        return response.data.order
      } catch (error) {
        throw new Error('Could not create shipment with carrier.')
      }
    },

    /**
     * Buys and easypost order
     * @param easyPostOrderId
     * @param carrier
     * @param service
     * @deprecated
     * @returns {Promise<*>}
     */
    async buyEasyPostOrder ({ easyPostOrderId, carrier, service }) {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`mutation BuyEasyPostOrder ($id: EasyPostID!, $carrier: String!, $service: String!) {
            order: EasyPost_BuyOrder(id: $id, service: $service, carrier: $carrier) {
              id
              shipments {
                postage_label {
                  id
                  label_zpl_url
                }
                tracking_code
              }
            }
          }`,
          variables: {
            id: easyPostOrderId,
            carrier: carrier,
            service: service
          }
        })
        return response.data.order
      } catch (error) {
        throw new Error(error)
      }
    },

    async refundEasyPostOrder ({ id }) {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`
            mutation RefundEasyPostOrder ($id: EasyPostID!) {
              order: EasyPost_RefundShipment (id: $id) {
                id
              }
            }
          `,
          variables: {
            id: id
          }
        })
        return response.data.order
      } catch (error) {
        throw new Error(error)
      }
    },

    /**
     * Creates an easy post shipment
     * @param {Array<EasyPostId>} carrierAccounts
     * @param {EasyPostId} toAddressId
     * @param {EasyPostId} fromAddressId
     * @param {EasyPostId} parcelId
     * @param {Boolean} isReturnShipment
     * @param {EasyPostId} customsInfoId
     * @param {String} reference
     * @param {Object} options
     * @returns {Promise<Object>} The EasyPost_Shipment
     */
    async createEasyPostShipment ({
      carrierAccounts = [],
      toAddressId,
      fromAddressId,
      parcelId,
      isReturnShipment,
      customsInfoId = '',
      options = false,
      reference = ''
    }) {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`mutation CreateEasyPostShipment ($input: EasyPost_MakeShipmentInput!) {
            shipment: EasyPost_MakeShipment(input: $input) {
              id
              to_address {
                verifications {
                  delivery {
                    errors {
                      message
                    }
                  }
                }
              }
              from_address {
                verifications {
                  delivery {
                    errors {
                      message
                    }
                  }
                }
              }
              rates {
                carrier
                carrierAccount: carrier_account_id
                deliveryDate: delivery_date
                isGuaranteedDeliveryDate: delivery_date_guaranteed
                id
                listRate: list_rate
                mode
                rate
                retailRate: retail_rate
                service
              }
              postage_label {
                id
                label_zpl_url
              }
              forms {
                id
                submitted_electronically
                form_type
                form_url
                object
              }
            }
          }`,
          variables: {
            input: {
              ...(carrierAccounts && { carrier_accounts: carrierAccounts }),
              from_address: fromAddressId,
              to_address: toAddressId,
              parcel: parcelId,
              is_return: isReturnShipment,
              ...(customsInfoId && { customs_info: customsInfoId }),
              ...(options && { options: options }),
              reference: reference
            }
          }
        })
        return response.data.shipment
      } catch (error) {
        throw new Error(this.errorHandler(error))
      }
    },

    /**
     * Create an easy post parcel with a custom box
     * @param {Object} obj
     * @param {String} obj.predefined
     * @param {Number} obj.weight must be a float
     * @returns {Promise<EasyPostId>}
     */
    async createEasyPostPredefinedParcel ({ predefined, weight }) {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`mutation CreateEasyPostPredefinedParcel ($input: EasyPost_MakeParcelInput!) {
            parcel: EasyPost_MakeParcel(input: $input) {
              id
            }
          }`,
          variables: {
            input: {
              weight: weight,
              predefined_package: predefined
            }
          }
        })
        return response.data.parcel
      } catch (error) {
        throw new Error(this.errorHandler(error))
      }
    },

    /**
     * Create an easy post parcel with a custom box
     * @param {Object} obj
     * @param {Number} obj.height must be a Float
     * @param {Number} obj.length must be a float
     * @param {Number} obj.weight must be a float
     * @param {Number} obj.width must be a float
     * @returns {Promise<EasyPostId>}
     */
    async createEasyPostParcel ({ length, width, height, weight }) {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`mutation CreateEasyPostParcel ($input: EasyPost_MakeParcelInput!) {
            parcel: EasyPost_MakeParcel(input: $input) {
              id
            }
          }`,
          variables: {
            input: {
              height: height,
              length: length,
              weight: weight,
              width: width
            }
          }
        })
        return response.data.parcel
      } catch (error) {
        throw new Error(this.errorHandler(error))
      }
    },

    /**
     * Creates an EasyPost customs info object
     * @param customsInfo
     * @returns {Promise<String>} id the id of the customs info object
     */
    async createEasyPostCustomsInfo ({ customsInfo }) {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`mutation CreateEasyPostCustomsInfo ($input: EasyPost_CustomsInfoInput!) {
            info: EasyPost_MakeCustomsInfo (input: $input) {
              id
            }
          }`,
          variables: { input: customsInfo }
        })
        return response.data.info.id
      } catch (error) {
        throw new Error(error)
      }
    }
  }
}
