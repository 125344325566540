


























import { computed, ComputedRef, defineComponent, provide, Ref, ref, watch } from '@vue/composition-api'
import { ApolloClients, useQuery, useResult } from '@vue/apollo-composable'
import { apolloClient } from '@/api/graphql/apollo'
import gql from 'graphql-tag'
export default defineComponent({
  name: 'EmailMultiSelect',
  props: {
    emails: {
      type: Array as () => string[],
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    clientId: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    provide(ApolloClients, {
      default: apolloClient
    })
    const localEmails: Ref<string[]> = computed({
      get () {
        return props.emails
      },
      set (value) {
        emit('updated', { value: value })
      }
    })
    const search = ref('')
    const enableQuery: ComputedRef<boolean> = computed(() => props.clientId !== '')

    const { result, loading } = useQuery(
      gql`
        query GetEmails ($id: Any!) {
            clients_contacts (input: { filters: [{ key: "owner__id", value: $id }]}) {
              id
              email
              full_name
            }
            users_users (input: {}) {
              id
              email
              full_name
            }
        }
      `,
      () => ({
        id: props.clientId
      }),
      () => ({
        enabled: enableQuery.value
      })
    )

    const potentialEmails = useResult(result, [], data => {
      return data.clients_contacts.concat(data.users_users)
    })

    function Clear () {
      localEmails.value.splice(0, localEmails.value.length)
    }

    function ClearSearch () {
      search.value = ''
    }

    return {
      localEmails,
      loading,
      search,
      potentialEmails,
      Clear,
      ClearSearch
    }
  }
})
