// vuex store for data related to setting up rmas

import { IKeyValuePayload, IRmaItem, IRmaState } from '@/models/vuexStateModels'
import { ActionTree, Module, MutationTree } from 'vuex'

const state: IRmaState = {
  transactionId: '',
  contactId: '',
  type: '',
  clientId: '',

  items: []
}

const mutations: MutationTree<IRmaState> = {
  /**
   * Set a key on the sales rma details
   * @param state
   * @param {object} payload
   * @param {String} payload.key
   * @param {*} payload.value
   */
  update (state: IRmaState, payload: IKeyValuePayload) {
    const key: string = payload.key
    state[key] = payload.value
  },

  /**
   * Add a part to state.items
   * @param state
   * @param {Object} payload
   * @param {Object} payload.part
   */
  addItems (state: IRmaState, payload) {
    const item: IRmaItem = payload.item
    state.items.push(item)
  },

  /**
   * Reset all details pertaining to the RMA
   * @param state
   */
  reset (state: IRmaState) {
    state.transactionId = ''
    state.contactId = ''
    state.type = ''
    state.clientId = ''
    state.items = []
  }
}

const actions: ActionTree<IRmaState, any> = {

  /**
   * Starts off the process of entering in a new rma
   * @param commit
   * @param dispatch
   * @param {Object} payload
   */
  start ({ commit, dispatch }, payload) {
    const rma = `${payload.type === 'purchase' ? 'prma' : 'srma'}`
    commit('grid/setSheet', { app: 'topbar', component: rma }, { root: true })
    for (const key in payload) {
      commit('update', { key: key, value: payload[key] })
    }
  },

  update ({ commit }, payload) {
    for (const key in payload) {
      commit('update', { key: key, value: payload[key] })
    }
  },

  reset ({ commit }) {
    commit('reset')
  }
}

export const rma: Module<IRmaState, any> = {
  namespaced: true,
  state,
  mutations,
  actions
}
