<template>
  <ag-grid-vue
   class="ag-grid-traqsys"
   :class="{ 'grid-extended': !extended, 'grid-pulled': extended }"
   :gridOptions="gridOptions"
   detailRowHeight="660"
   :master-detail="nestedGrid"
   :detailCellRenderer="detailCellRenderer"
   :frameworkComponents="frameworkComponents"
   :statusBar="statusBar"
   @cell-key-down="$emit('cell-key-down', $event)"
  >
  </ag-grid-vue>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import { GridEvents } from '@/store/gridEvents'
import moneyFormatter from '../mixins/moneyFormatter'
import saveConfigButton from '../renderers/saveConfigStatusBarButton'
import columns from '../mixins/columns'
import partNumberCell from '../renderers/partNumberCell'
import openAllocated from '../renderers/openAllocated'
import commentRenderer from '../renderers/commentRenderer'
import masterDetailsRenderer from '@/components/renderers/masterDetailsRenderer'
import orderDetailsRenderer from '@/components/renderers/orderDetailsRenderer'
import clientDetailsRenderer from '@/components/renderers/clientDetailsRenderer'
import { COLUMN_TYPES } from '@/lib/agGridColumnConfiguration'
import { GetFilters, GetOrdering } from '@/lib/queryParser'
import DateFilter from '@/components/renderers/DateFilter'
export default {
  name: 'GridWrapper',
  mixins: [moneyFormatter, columns],
  components: {
    AgGridVue
  },
  props: {
    editableNodes: {
      required: false,
      type: Array,
      default: () => []
    },
    contextMenuItems: {
      required: true,
      type: Function
    },
    query: {
      required: true
    },
    nestedQuery: {
      required: false
    },
    filter: {
      type: Array,
      required: true
    },
    nestedFilters: {
      required: false,
      type: Function
    },
    nestedAnnotations: {
      required: false,
      type: Array
    },
    annotations: {
      type: Array,
      required: false,
      default: () => []
    },
    columns: {
      required: true,
      type: Array
    },
    nestedColumns: {
      required: false,
      type: Array
    },
    componentName: {
      required: true,
      type: String
    },
    nestedGrid: {
      required: false,
      type: Boolean,
      default: false
    },
    nestedGridDataFunction: {
      required: false,
      type: Function
    },
    nestedContextMenuItems: {
      required: false,
      type: Function
    },
    isRowMasterFunction: {
      required: false,
      type: Function,
      default: function () {
        return true
      }
    },
    gridMode: {
      required: false,
      type: String,
      default: 'read'
    }
  },
  computed: {
    extended: {
      get () {
        return this.$store.state.grid.extended
      },
      set () {
        this.$store.dispatch('grid/switchExtended')
      }
    },

    refresh: {
      get () {
        return this.$store.state.data.refresh
      },
      set (value) {
        this.$store.dispatch('data/changeRefresh', { bool: value })
      }
    },

    user () {
      return this.$store.state.profile.user
    },

    rowToExpand: {
      get () {
        return this.$store.state.grid.rowToExpand
      },
      set (value) {
        if (!value) {
          this.$store.dispatch('grid/resetRowToExpand')
        } else {
          this.$store.dispatch('grid/changeRowToExpand', { id: value })
        }
      }
    },

    columnConfig () {
      return this.$store.getters['profile/tableConfig'](this.componentName)
    },

    columnConfigDne () {
      return this.$store.getters['profile/tableConfigDoesNotExist'](this.componentName)
    },

    nestedColumnConfig () {
      return this.$store.getters['profile/tableConfig'](`${this.componentName}-detail`)
    },

    columnConfigId () {
      return this.$store.getters['profile/tableConfigId'](this.componentName)
    }
  },
  watch: {
    '$route': function (value) {
      this.filterChangeHandler()
    },

    editableNodes: function () {
      this.gridApi.redrawRows()
    },

    refresh: function (value, old) {
      if (value && !old) {
        this.gridOptions.api.purgeServerSideCache()
        this.gridOptions.api.setFilterModel(null)
        if (this.$route.query.field) this.$router.push({ name: this.$route.name })
        this.refresh = false
      }
    },

    rowToExpand: function (value) {
      this.checkRowExpander()
    },

    filter: function () {
      this.gridOptions.api.purgeServerSideCache()
    },

    columnConfig: function (value, oldValue) {
      if (value.length > 0 && oldValue.length === 0 && this.columnApi && !value.every(c => c.hide === true)) {
        this.columnApi.setColumnState(value)
      }
      // this.dataSourceIsSet ? this.gridOptions.api.purgeServerSideCache() : this.gridApi.setServerSideDatasource(this.ServerSideDataSource(false))
    }
  },
  data () {
    return {
      badRequestCount: 0,
      dataSourceIsSet: false,
      openedRows: [],
      nestedGridParentId: 0,
      skip: true,
      params: {},
      postLoaded: false,
      nestedPostLoaded: false,
      needToWait: false,

      // grid setup
      gridOptions: {
        allowDragFromColumnsToolPanel: true,
        animateRows: true,
        cacheBlockSize: 35,
        maxConcurrentDatasourceRequests: 2,
        columnDefs: this.columns,
        columnTypes: COLUMN_TYPES,
        defaultColDef: {
          cellClass: () => this.$store.getters['data/settings'].showColumnBorders ? 'column-borders' : '',
          enableValue: false,
          enableRowGroup: false,
          enablePivot: false,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset', 'apply'],
            filterOptions: [
              'Starts With',
              'Ends With',
              'Contains',
              'Is'
            ]
          },
          floatingFilter: true,
          menuTabs: ['generalMenuTab'],
          sortable: true,
          resizable: true
        },
        detailCellRendererParams: null,
        editType: 'singleCell',
        enableRangeSelection: true,
        floatingFiltersHeight: 40,
        getContextMenuItems: this.contextMenuItems,
        getRowNodeId: (data) => {
          return data.id
        },
        headerHeight: 40,
        isRowMaster: this.isRowMasterFunction,
        onCellClicked: (event) => {
          if (event.node.data) {
            this.$emit('setComments', event.node.data)
            this.$emit('setMasterObject', event.node.data)
          }
        },
        onDragStopped: (params) => {
          const columnState = params.columnApi.getColumnState()
          this.postLoaded && this.$store.dispatch('profile/updateTableConfig', { table: this.componentName, config: columnState })
        },
        onColumnPinned: (params) => {
          const columnState = params.columnApi.getColumnState()
          this.postLoaded && this.$store.dispatch('profile/updateTableConfig', { table: this.componentName, config: columnState })
        },
        onDisplayedColumnsChanged: (params) => {
          const columnState = params.columnApi.getColumnState()
          this.postLoaded && this.$store.dispatch('profile/updateTableConfig', { table: this.componentName, config: columnState })
        },
        onRowDoubleClicked: (params) => {
          params.node.setExpanded(!params.node.expanded)
        },
        onGridReady: (params) => {
          this.gridApi = params.api
          this.columnApi = params.columnApi
          this.$emit('ready', params)
          if (this.columnConfig.length > 0 && !this.columnConfig.every(c => c.hide === true)) {
            params.columnApi.setColumnState(this.columnConfig)
          }
          const setColumnLayout = columns => {
            return columns.sort((a, b) => {
              if (a.headerName.toLowerCase() < b.headerName.toLowerCase()) return -1
              if (a.headerName.toLowerCase() > b.headerName.toLowerCase()) return 1
              return 0
            })
          }
          const columnToolPanel = this.gridApi.getToolPanelInstance('columns')
          columnToolPanel.setColumnLayout(setColumnLayout(this.columns))
          if (this.columnConfig || this.columnConfigDne) {
            params.api.setServerSideDatasource(this.ServerSideDataSource(false))
          } else if (this.columnConfig.length === 0 && this.columnApi) {
            const columnState = this.columnApi.getColumnState()
            this.postLoaded && this.$store.dispatch('profile/updateTableConfig', { table: this.componentName, config: columnState })
              .then(() => {
                this.dataSourceIsSet ? this.gridOptions.api.purgeServerSideCache() : params.api.setServerSideDatasource(this.ServerSideDataSource(false))
              })
          }
          const columnState = params.columnApi.getColumnState()
          const allHidden = columnState.every(c => c.hide === true)
          if (allHidden) {
            columnState.forEach(c => { c.hide = false })
            params.columnApi.setColumnState(columnState)
            this.columnConfigId && this.$store.dispatch('profile/deleteTableConfig', { index: this.columnConfigId })
          }
          setTimeout(() => {
            this.postLoaded = true
          }, 2000)
          const toolpanel = params.api.getToolPanelInstance('columns')
          toolpanel.setColumnLayout(this.columns.sort((a, b) => a.headerName - b.headerName))
        },
        onFirstDataRendered: params => {
          if (this.needToApplyOutsideFilter) {
            this.filterChangeHandler()
          }
          if (this.$route.params.id) {
            setTimeout(() => {
              this.expandRow({ id: this.$route.params.id })
            }, 1000)
          }
        },
        rowData: null,
        rowDeselection: true,
        rowHeight: 35,
        rowModelType: 'serverSide',
        rowSelection: 'multiple',
        sideBar: {
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true
              }
            }
          ]
        },
        sortingOrder: ['asc', 'desc'],
        suppressMultiRangeSelection: true,
        suppressPropertyNamesCheck: true,
        tooltipShowDelay: 0
      },
      // end gridOptions

      nestedGridOptions: {
        allowDragFromColumnsToolPanel: true,
        animateRows: true,
        cacheBlockSize: 35,
        cacheOverflowSize: 30,
        maxConcurrentDatasourceRequests: 2,
        columnDefs: this.nestedColumns,
        columnTypes: COLUMN_TYPES,
        defaultColDef: {
          enableValue: false,
          enableRowGroup: false,
          enablePivot: false,
          sortable: true,
          resizable: true
        },
        enableRangeSelection: true,
        getContextMenuItems: this.nestedContextMenuItems,
        getRowNodeId: (data) => {
          if (data.id) {
            return data.id
          } else {
            if (data.__typename === 'Quotes_QuoteParts') {
              return data.part.id
            }
          }
        },
        headerHeight: 35,
        isRowMaster: false,
        masterDetail: false,
        onCellKeyDown: (event) => {
          this.$emit('cell-key-down', event)
        },
        onColumnPinned: (params) => {
          const columnState = params.columnApi.getColumnState()
          this.nestedPostLoaded && this.$store.dispatch('profile/updateTableConfig', { table: `${this.componentName}-detail`, config: columnState })
        },
        onDisplayedColumnsChanged: (params) => {
          const columnState = params.columnApi.getColumnState()
          this.nestedPostLoaded && this.$store.dispatch('profile/updateTableConfig', { table: `${this.componentName}-detail`, config: columnState })
        },
        onDragStopped: (params) => {
          const columnState = params.columnApi.getColumnState()
          this.nestedPostLoaded && this.$store.dispatch('profile/updateTableConfig', { table: `${this.componentName}-detail`, config: columnState })
        },
        onGridReady: (params) => {
          this.$emit('nestedReady', params)
          this.nestedGridApi = params.api
          this.nestedColumnApi = params.columnApi
          if (this.nestedColumnConfig) {
            params.columnApi.setColumnState(this.nestedColumnConfig)
          }
          params.api.setServerSideDatasource(this.ServerSideDataSource(true))
          setTimeout(() => {
            this.nestedPostLoaded = true
          }, 2000)
        },
        rowData: null,
        rowHeight: 30,
        rowModelType: 'serverSide',
        rowSelection: 'multiple',
        sideBar: {
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true
              }
            }
          ]
        },
        sortingOrder: ['asc', 'desc'],
        statusBar: {
          statusPanels: [
            { statusPanel: 'agSelectedRowCountComponent', align: 'left' }
          ]
        }
      },
      // end of nested grid options

      gridApi: null, // grid api
      columnApi: null, // columns api,
      nestedGridApi: null,
      nestedColumnApi: null,
      detailCellRenderer: null,
      frameworkComponents: null,
      statusBar: null,
      startRow: 0,
      endRow: 0,

      needToApplyOutsideFilter: false
    }
  },
  methods: {
    updateRowData (data) {
      let updater = []
      if (typeof data.constructor === typeof Array) {
        updater.push(data)
      } else {
        updater = data
      }
      this.gridApi.updateRowData({ update: updater })
      this.gridApi.redrawRows(updater)
    },

    /* updateNodeData ({ id, data }) {
      const node = this.gridOptions.api.getRowNode(id)
      node.setData(data)
    }, */

    checkRowExpander () {
      if (this.rowToExpand && this.componentName === 'salesOrders') {
        this.gridApi.forEachNode(node => {
          if (node.data?.id === this.rowToExpand && !node.expanded) {
            node.setExpanded(true)
          }
        })
      }
    },

    ServerSideDataSource (nested) {
      const query = nested ? this.nestedQuery : this.query
      const filter = nested ? this.nestedFilters(this.nestedGridParentId) : this.filter
      const columns = nested ? this.nestedColumns : this.columns
      const annotations = nested ? this.nestedAnnotations : this.annotations
      return {
        getRows: async params => {
          const request = params.request
          const filters = GetFilters(request.filterModel, filter, columns)
          const observer = await this.$apollo.watchQuery({
            query: query,
            variables: {
              input: {
                limit: request.endRow + 1,
                offset: request.startRow,
                filters: filters.filters,
                excludes: filters.excludes,
                order_by: GetOrdering(request, columns),
                ...(annotations && { annotations: annotations })
              }
            },
            fetchPolicy: 'cache-and-network'
          })
          observer.subscribe({
            next: response => {
              if (response.data) {
                const rows = response.data[Object.keys(response.data)[0]]

                // determine last row to size scrollbar and last block size correctly
                let lastRow = -1
                const cacheBlockSize = nested ? this.nestedGridOptions.cacheBlockSize : this.gridOptions.cacheBlockSize
                if (rows.length <= cacheBlockSize) {
                  lastRow = params.request.startRow + rows.length
                }
                // pass results to grid
                this.badRequestCount = 0
                params.successCallback(rows, lastRow)
                // params.api.hideOverlay()
              }
            },
            error: e => {
              this.$store.dispatch('notifications/createSnackbar', {
                message: e.message,
                color: 'error',
                timeout: 4000
              })
            }
          })
        }
      }
    },

    expandRow ({ id }) {
      const node = this.gridApi.getRowNode(Number(id))
      if (node !== null) {
        node.setExpanded(true)
      } else {
        setTimeout(() => {
          this.expandRow({ id: id })
        }, 500)
      }
    },

    refetch (nested) {
      nested ? this.nestedGridApi.purgeServerSideCache() : this.gridApi.purgeServerSideCache()
    },

    filterChangeHandler () {
      const query = this.$route.query
      if (this.gridApi && query.field) {
        const filter = this.gridApi.getFilterInstance(query.field)
        filter.setModel({
          type: query.type,
          filter: query.value
        })
        this.gridApi.onFilterChanged()
        this.needToApplyOutsideFilter = false
      } else {
        this.needToApplyOutsideFilter = true
      }
    }
  },
  beforeMount () {
    // custom detail cell renderer options
    const path = this.$route.fullPath
    this.frameworkComponents = {
      'DateFilter': DateFilter
    }
    if (path.includes('clients')) {
      this.detailCellRenderer = 'clientDetailsRenderer'
      this.frameworkComponents = {
        ...this.frameworkComponents,
        'clientDetailsRenderer': clientDetailsRenderer,
        'saveConfigButton': saveConfigButton
      }
    } else if (path.includes('/orders')) {
      this.detailCellRenderer = 'orderDetailsRenderer'
      this.frameworkComponents = {
        ...this.frameworkComponents,
        'orderDetailsRenderer': orderDetailsRenderer,
        'openAllocated': openAllocated,
        'commentRenderer': commentRenderer
      }
    } else if (path.includes('master')) {
      this.detailCellRenderer = 'masterDetailsRenderer'
      this.frameworkComponents = {
        ...this.frameworkComponents,
        'masterDetailsRenderer': masterDetailsRenderer
      }
    } else {
      this.frameworkComponents = {
        ...this.frameworkComponents,
        saveConfigButton: saveConfigButton,
        partNumberCell: partNumberCell
      }
    }
    this.statusBar = {
      statusPanels: [
        // { statusPanel: 'saveConfigButton', align: 'left' },
        { statusPanel: 'agSelectedRowCountComponent', align: 'left' }
      ]
    }
    this.nestedGridOptions.frameworkComponents = {
      saveConfigButton: saveConfigButton,
      partNumberCell: partNumberCell
    }
    this.nestedGridOptions.statusBar = this.statusBar
    if (this.$route.query.value) this.filterChangeHandler()
  },
  mounted () {
    // this is used when there isn't a custom nested grid
    if (this.nestedGrid) {
      this.gridOptions.detailCellRendererParams = {
        detailGridOptions: this.nestedGridOptions,
        getDetailRowData: params => {
          this.nestedGridParentId = params.data.id
        }
      }
    }
    GridEvents.$on('refetch-nested', () => this.refetch(true))
    GridEvents.$on('refetch', () => this.refetch(false))
    GridEvents.$on('updateRowData', this.updateRowData)
  },
  beforeDestroy () {
    GridEvents.$off('updateRowData', this.updateRowData)
    GridEvents.$off('refetch', () => this.refetch(false))
    GridEvents.$off('refetch-nested', () => this.refetch(true))
    // GridEvents.$off('updateNoeData', this.updateNodeData)
    /* for (const column of this.columns) {
      this.columnApi.getColumn(column.field).removeEventListener('movingChanged', function (event) {
      })
      this.columnApi.getColumn(column.field).removeEventListener('visibleChanged', function (event) {
      })
    } */
  }
}
</script>
