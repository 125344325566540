

























































































































































































import { computed, ComputedRef, defineComponent, provide, reactive, ref, Ref, watch } from '@vue/composition-api'
import { ApolloClients, useMutation } from '@vue/apollo-composable'
import { IUpdatedEvent, IVuetifyDatatableHeader } from '@/models/VuetifyModels'
import { UPDATE_CLIENT } from '@/api/graphql/Constants/Clients'
import tAlert from '@/components/notifications/tAlert.vue'
import TermsAutofill from '@/components/autocompletes/TermsAutofill.vue'
import { apolloClient } from '@/api/graphql/apollo'
import store from '@/store/store'
import {
  Clients_Client,
  Clients_Terms,
  Update__Clients_Client__Input,
  UpdateClientDetailsMutation, UpdateClientDetailsMutationVariables
} from '@/models/generated/graphql/ErpBackend.ts'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
export default defineComponent({
  name: 'ClientFinancialInformation',
  components: {
    't-alert': tAlert,
    'terms-autofill': TermsAutofill,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  props: {
    clientInformation: {
      type: Object as () => Clients_Client,
      required: true
    }
  },
  setup (props) {
    provide(ApolloClients, {
      default: apolloClient
    })
    const message: Ref<string> = ref('')
    const type: Ref<string> = ref('info')
    const dialogAlert = reactive({
      message: '',
      type: 'info'
    })

    const client: ComputedRef<Clients_Client> = computed(() => props.clientInformation)
    const lock: Ref<boolean> = ref(true)
    const allowSalesOverride: Ref<boolean> = ref(client.value.allow_sales_override ?? false)
    const taxExemptNumber: Ref<string> = ref(client.value.tax_exempt_number)
    const isTaxExempt: Ref<boolean> = ref(client.value.is_tax_exempt)
    const federalId: Ref<string> = ref(client.value.federal_tax_id)
    const stateId: Ref<string> = ref(client.value.state_tax_id)
    const is1099: Ref<boolean> = ref(client.value.is_1099)
    const clientDefaultPtTerms: Ref<string> = ref(client.value.default_pt_terms?.id ?? '')
    const clientDefaultStTerms: Ref<string> = ref(client.value.default_st_terms?.id ?? '')

    const { mutate: UpdateClientDefaultTerms, onError: onUpdateDefaultFailure } =
      useMutation<UpdateClientDetailsMutation, UpdateClientDetailsMutationVariables>(
        UPDATE_CLIENT
      )
    onUpdateDefaultFailure(() => {
      store.dispatch('notifications/createSnackbar', {
        message: 'Action failed, you most likely do not have proper permissions',
        color: 'error'
      })
    })

    const terms: Ref<Clients_Terms[]> = ref(client.value.terms ?? [])
    const headers: IVuetifyDatatableHeader[] = [
      { text: 'Name', value: 'name' },
      { text: 'Description', value: 'description' },
      { text: 'ST Default', value: 'stDefault' },
      { text: 'PT Default', value: 'ptDefault' },
      { text: 'Actions', value: '', align: 'right' }
    ]

    const detailsAreDifferent: ComputedRef<boolean> = computed(() => {
      if (taxExemptNumber.value !== client.value.tax_exempt_number) return true
      if (isTaxExempt.value !== client.value.is_tax_exempt) return true
      if (federalId.value !== client.value.federal_tax_id) return true
      if (stateId.value !== client.value.state_tax_id) return true
      if (allowSalesOverride.value !== client.value.allow_sales_override) return true
      return is1099.value !== client.value.is_1099
    })

    function ResetClientDetails () {
      allowSalesOverride.value = client.value.allow_sales_override ?? false
      taxExemptNumber.value = client.value.tax_exempt_number
      isTaxExempt.value = client.value.is_tax_exempt
      federalId.value = client.value.federal_tax_id
      stateId.value = client.value.state_tax_id
      is1099.value = client.value.is_1099
      lock.value = !lock.value
    }

    const updateClientInput: Update__Clients_Client__Input = reactive({
      allow_sales_override: computed(() => allowSalesOverride.value),
      is_1099: computed(() => is1099.value),
      federal_tax_id: computed(() => federalId.value),
      state_tax_id: computed(() => stateId.value),
      is_tax_exempt: computed(() => isTaxExempt.value),
      tax_exempt_number: computed(() => taxExemptNumber.value),
      id: client.value.id
    })

    const { mutate: SaveClientDetails, loading, onError: onSavingError, onDone: onSavingSuccess } = useMutation(
      UPDATE_CLIENT,
      () => ({
        variables: {
          input: updateClientInput
        }
      })
    )
    onSavingError(() => {

    })
    onSavingSuccess(() => {

    })

    const termsError: Ref<string> = ref('')
    const termsToAdd: Ref<string> = ref('')
    const addTermsInput: Update__Clients_Client__Input = reactive({
      id: client.value.id,
      terms: []
    })

    function AddTerms () {
      if (termsToAdd.value) {
        addTermsInput.terms = [...terms.value.map(t => t.id), termsToAdd.value]
        UpdateTermsMutation()
      } else {
        termsError.value = 'Select a value to submit.'
      }
    }
    const removeTerm = (term: Clients_Terms): void => {
      addTermsInput.terms = [...terms.value.map(t => t.id).filter(t => t !== term.id)]
      UpdateTermsMutation()
    }
    function UpdateTermsToAdd (event: IUpdatedEvent) {
      termsToAdd.value = event.value
      termsError.value = ''
    }

    const {
      mutate: UpdateTermsMutation,
      loading: isAddingTerms,
      onError: addingFailed,
      onDone: addingSucceeded
    } =
      useMutation(
        UPDATE_CLIENT,
        () => ({
          variables: {
            input: addTermsInput
          }
        })
      )
    const addedTerms = ref(false)
    addingFailed(() => {
      message.value = 'Could not add terms to client'
      type.value = 'error'
    })
    addingSucceeded((result) => {
      addedTerms.value = true
      dialogAlert.message = 'Success'
      dialogAlert.type = 'success'
      terms.value = result?.data.Update__Clients_Client.terms ?? []
    })

    const isDefaultPtTerms = (term: Clients_Terms): boolean => {
      return term.id === clientDefaultPtTerms.value
    }

    const isDefaultStTerms = (term: Clients_Terms): boolean => {
      return term.id === clientDefaultStTerms.value
    }

    const isADefault = (term: Clients_Terms): boolean => {
      return (isDefaultStTerms(term) || isDefaultPtTerms(term))
    }

    const dialog: Ref<boolean> = ref(false)
    watch(dialog, value => {
      if (!value) {
        termsToAdd.value = ''
        termsError.value = ''
      }
    })

    const setDefaultStTerms = (termsId: string): void => {
      UpdateClientDefaultTerms({ input: { id: client.value.id, default_st_terms_id: termsId } })
      clientDefaultStTerms.value = termsId
    }

    const setDefaultPtTerms = (termsId: string): void => {
      UpdateClientDefaultTerms({ input: { id: client.value.id, default_pt_terms_id: termsId } })
      clientDefaultPtTerms.value = termsId
    }

    return {
      addedTerms,
      allowSalesOverride,
      message,
      type,
      dialogAlert,
      lock,
      taxExemptNumber,
      isTaxExempt,
      federalId,
      stateId,
      is1099,
      terms,
      headers,
      detailsAreDifferent,
      SaveClientDetails,
      loading,
      ResetClientDetails,
      isAddingTerms,
      AddTerms,
      UpdateTermsToAdd,
      termsToAdd,
      termsError,
      dialog,
      clientDefaultPtTerms,
      clientDefaultStTerms,
      isDefaultPtTerms,
      isDefaultStTerms,
      isADefault,
      setDefaultPtTerms,
      setDefaultStTerms,
      removeTerm
    }
  }
})
