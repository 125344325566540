import { CellKeyDownEvent } from 'ag-grid-community'
import { GridKbShortCut } from '@/models/ExtraAgGridRelatedModels'
import store from '@/store/store'
import { MatchesKeyboardEvent } from '@/lib/helpers'

export function GridKbEventHandler (params: CellKeyDownEvent, master: GridKbShortCut[], detail: GridKbShortCut[]): void {
  const e: Event = params.event!
  const list: GridKbShortCut[] = (params.node.master ?? false) || detail.length === 0 ? master : detail
  for (const shortcut of list) {
    if (MatchesKeyboardEvent(e as KeyboardEvent, shortcut.key, shortcut.altKey)) {
      if (shortcut.rules(params)) shortcut.handler(params)
      else {
        store.dispatch('notifications/createSnackbar', {
          message: shortcut.invalidMessage(params),
          color: 'warning',
          timeout: 5000
        })
      }
    }
  }
}
