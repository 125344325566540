import Vue from 'vue'
import Vuex from 'vuex'
import grid from './modules/grid'
import notifications from './modules/notifications'
import profile from './modules/profile'
import data from './modules/data'
import orders from './modules/orders'
import { rma } from './modules/rma'
import sale from './modules/sales'
import purchase from './modules/purchase'
import auth from './modules/auth'
import { quotes } from '@/store/modules/quotes'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    grid,
    notifications,
    profile,
    data,
    orders,
    rma,
    sale,
    purchase,
    auth,
    quotes
  }
})

if (module.hot) {
  module.hot.accept(
    [
      './modules/grid',
      './modules/profile',
      './modules/notifications',
      './modules/data',
      './modules/orders',
      './modules/rma',
      './modules/sales',
      './modules/purchase',
      './modules/auth',
      './modules/quotes.ts'
    ], () => {
      const grid = require('./modules/grid')
      const profile = require('./modules/profile')
      const notifications = require('./modules/notifications')
      const data = require('./modules/data')
      const orders = require('./modules/orders')
      const rma = require('./modules/rma')
      const sale = require('./modules/sales')
      const purchase = require('./modules/purchase')
      const auth = require('./modules/auth')
      const quotes = require('./modules/quotes')

      store.hotUpdate({
        modules: {
          quotes,
          auth,
          grid,
          profile,
          notifications,
          data,
          orders,
          rma,
          sale,
          purchase
        }
      })
    })
}

export default store
