<template>
  <v-card>
    <v-card-title class="title green white--text">Set Country of Origin/Manufacturer</v-card-title>
    <v-container>
      <v-layout row nowrap justify-center>
        <v-flex xs12>
          <countries-list :country="country" :label="'Country of Origin'" @updated="setCountry"/>
        </v-flex>
      </v-layout>
    </v-container>
    <standard-actions
      @save="save"
      @cancel="cancel"
      @close="cancel"
      :save-button="{ name: 'Submit', disabled: country === null }"
    />
  </v-card>
</template>

<script>
import countries from '../autocompletes/countries'
import standardActions from '../templates/standardActions'

export default {
  name: 'addCoO',
  components: {
    'countries-list': countries,
    'standard-actions': standardActions
  },
  data () {
    return {
      country: ''
    }
  },
  methods: {
    save () {
      if (this.country) {
        this.$emit('save', this.country)
      }
    },

    cancel () {
      this.$emit('cancel')
      this.$store.dispatch('grid/resetDialog')
    },

    setCountry (event) {
      this.country = event.value
    }
  }
}
</script>
