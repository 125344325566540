import { ManyQueryInput } from '@/models/generated/graphql/ErpBackend'

const Query = require('graphql-query-builder')

export class QueryBuilder {
  query: any

  constructor (name: string) {
    this.query = new Query(name)
  }

  setAlias (alias: string): void {
    this.query.setAlias(alias)
  }

  setInput (input: ManyQueryInput): void {
    this.query.filter(input)
  }

  setFields (fields: Array<string | object>): void {
    this.query.find(fields)
  }

  toString (): string {
    return this.query.toString()
  }
}
