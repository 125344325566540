

























































import { defineComponent, computed, ref, Ref, ComputedRef, provide, watch } from '@vue/composition-api'
import { apolloClient } from '@/api/graphql/apollo'
import { ApolloClients, useQuery, UseQueryReturn } from '@vue/apollo-composable'
import {
  Address_Address, AddressFullFragment,
  GetAddress_FullQuery,
  QueryAddress_AddressesArgs, QueryFilter
} from '@/models/generated/graphql/ErpBackend'
import { GET_ADDRESSES__FULL } from '@/api/graphql/Constants/Addresses'
import newAddress from '@/components/templates/newAddress.vue'
import { Sentry } from '@/lib/Sentry'

/*
 * Situations for blind stuff:
 * Blind/ Not Blind
 * * sale:
 * * * shipFrom: client / company
 * * * shipTo: any / client
 * * * billTo: client
 * * purchase:
 * * * shipFrom: OPTIONAL -> company / client
 * * * shipTo: client / company
 * * * billTo: company
 */

export default defineComponent({
  name: 'AddressesAutocomplete',
  components: {
    'new-address': newAddress
  },
  props: {
    object: {
      type: Object,
      required: true
    },
    filter: {
      type: Object as () => QueryFilter,
      validator: (prop: { key: string, value: any } | null) => {
        if (prop === null) return true // means search for anything
        else if (prop?.key !== undefined && prop?.value !== undefined) return true
        return false
      }
    },
    error: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    provide(ApolloClients, {
      default: apolloClient
    })
    const dialog = ref(false)
    const closeDialog = () => {
      dialog.value = false
    }
    const addAddress = (address: Address_Address) => {
      addresses.value.push(address)
      emit('updated', { value: address })
    }

    const search = ref('')
    watch(search, (n, o) => {
      if (!n && o) {
        if (!props.filter) addresses.value = []
        doNotSearch.value = false
        emit('updated', { value: {} })
      }
      if (n?.length >= 3 && o?.length < 3) doNotSearch.value = false
      if (n?.length < 3 && o?.length >= 3) doNotSearch.value = true
    })

    const clientId: ComputedRef<string | null> = computed(() => {
      if (props.filter?.key?.includes('client__id')) {
        return props.filter?.value
      } else return null
    })

    const localObject: ComputedRef = computed({
      get () {
        return props.object
      },
      set (value) {
        emit('updated', { value: value || {} })
        if (value) doNotSearch.value = true
      }
    })
    const addresses: Ref<AddressFullFragment[]> = ref([])
    watch(() => props.filter, () => {
      addresses.value = []
      emit('updated', { value: {} })
    })

    const doNotSearch = ref(false)
    const searchForAnything: ComputedRef<boolean> = computed(() => !props.filter && !doNotSearch.value)
    const filter = computed(() => [{ key: 'street_1__contains', value: search?.value ?? '' }])

    // @ts-ignore
    const { loading, onResult, onError }: UseQueryReturn<GetAddress_FullQuery, QueryAddress_AddressesArgs> = useQuery(
      GET_ADDRESSES__FULL,
      () => ({
        input: {
          filters: props.filter || filter.value
        }
      }),
      () => ({
        enabled: props.filter !== null || (searchForAnything.value && search?.value?.length > 2)
      })
    )
    onError(error => {
      console.log(error)
      Sentry.captureEvent(error)
    })
    onResult((result) => {
      addresses.value = result?.data?.address_addresses ?? []
      if (addresses.value.length === 1) {
        emit('updated', { value: addresses.value[0] })
      }
    })

    const getTitle = (address: Address_Address): string => {
      return address?.client?.name ?? address?.company?.name ?? ''
    }

    const getSubtitle = (address: Address_Address): string => {
      return `${address.street_1} ${address.city} ${address.state}, ${address.country.code} ${address.zip_code}`
    }

    const clearEventHandler = () => {
      search.value = ''
      emit('updated', { value: null })
    }

    return {
      dialog,
      doNotSearch,
      closeDialog,
      addAddress,
      clientId,
      clearEventHandler,
      search,
      localObject,
      loading,
      getTitle,
      getSubtitle,
      addresses,
      searchForAnything
    }
  }
})
