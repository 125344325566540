import moment from 'moment'
const dateFormatter = {
  methods: {
    formatDate (date) {
      return date ? moment(date).format('L') : ''
    }
  }
}

export default dateFormatter
