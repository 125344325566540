




























































































import { defineComponent, watch, onMounted, toRefs, computed, ref } from '@vue/composition-api'
import NewBlindAddress from './NewBlindAddress.vue'
import {
  Address_Address,
  Clients_Client,
  Clients_Contact,
  Create__Address_Address__Input,
  EasyPost_MakeAddressInput, GetAddress_FullQuery,
  GetAddress_FullQueryVariables,
  ManyQueryInput,
  Query,
  ShipmentOrderDetailsFragment
} from '@/models/generated/graphql/ErpBackend'
import { shortNameHash } from '@/lib/shortNameHash'
import { QueryOptions } from 'apollo-client'
import { GET_CLIENTS__LIST } from '@/api/graphql/Constants/Clients'
import { apolloClient as apollo } from '@/api/graphql/apollo'
import { GET_ADDRESSES__FULL, VERIFY_ADDRESS } from '@/api/graphql/Constants/Addresses'
import { TranslateEasyPostAddressToGQLInput, TranslateGqlAddressToString } from '@/lib/Addresses'
import { GET_SHIPMENT_ORDER_DETAILS } from '@/api/graphql/Constants/Shipments'
import { GET_CONTACTS__LIST } from '@/api/graphql/Constants/Contacts'
import { BlindForm } from '@/composition/UseBlindShipping'
export default defineComponent({
  name: 'BlindDetails',
  components: {
    'new-blind-address': NewBlindAddress
  },
  props: {
    blindForm: {
      type: Object as () => BlindForm,
      required: true
    },
    hasErrors: {
      type: Boolean,
      required: true
    },
    shipmentOrderId: {
      type: String,
      required: true,
      default: ''
    }
  },
  // @ts-ignore
  setup (props, { refs, emit }) {
    const form = props.blindForm // reactive from parent component
    const { hasErrors, shipmentOrderId } = toRefs(props) // refs from parent component
    const validating = ref(false)
    const dialog = ref(false)
    const useCleanAddress = ref(false)

    watch(hasErrors, value => {
      if (!value) {
        // @ts-ignore
        refs.blind.validate()
        emit('validity-updated', { value: true, form: form })
      } else {
        emit('validity-updated', { value: false })
      }
    })

    const hasClientOrContact = computed(() => !!form.client.value || !!form.contact.value)
    const readyToValidate = computed(() => hasClientOrContact.value && !!form.address.value)
    const validationInfoMessage = computed(() => {
      if (!form.address.value && hasClientOrContact.value) return 'Now enter an address!'
      if (form.address.value.length < 10 && form.address.value.length !== 0) return 'You can copy and paste!'
      if (!!form.address.value && !hasClientOrContact.value) return 'Now enter a client and/or contact!'
      return 'Enter a client and/or contact, then an address!'
    })

    /**
     * Creates a valid shortname for the entered client or an id from one already in the database
     * Is called recursively until a valid one is found
     * @param seed
     */
    const getValidShortName = async (seed: number = 0): Promise<void> => {
      const clientMatches = (clients: Clients_Client[]): string => {
        for (const client of clients) {
          if (client.name.toLowerCase() === form.client.value.toLowerCase()) {
            return client.id
          }
        }
        return ''
      }

      emit('check')
      if (form.client.value) {
        const shortName = shortNameHash(form.client.value, seed)
        const query: QueryOptions = {
          query: GET_CLIENTS__LIST,
          variables: {
            input: {
              filters: [
                {
                  key: 'short_name',
                  value: shortName,
                  or: {
                    key: 'name__iexact',
                    value: form.client.value.toLowerCase()
                  }
                }]
            }
          }
        }
        const checkResponse = await apollo.query(query)
        const clients = checkResponse.data?.clients_clients ?? []
        if (clients.length === 0) { // if no clients where found
          form.client.shortName = shortName // it is a new client and use short name when creating
        } else {
          const id = clientMatches(clients)
          if (clients.length > 0 && id) { // if a client was found and it matches
            form.client.id = id // set the client id to that client
          } else return getValidShortName(++seed)
        }
      }
    }

    /**
     * Queries db for all contacts from the given client (if one is given) then searches them for a match
     * to the given contact value
     */
    const checkIfContactExists = async (): Promise<void> => {
      emit('check')
      if (form.client.id && form.contact.value) {
        const query: QueryOptions = {
          query: GET_CONTACTS__LIST,
          variables: {
            input: {
              filters: [
                {
                  key: 'owner_id',
                  value: form.client.id
                }
              ]
            }
          }
        }
        const response = await apollo.query<Query, ManyQueryInput>(query)
        const contacts: Clients_Contact[] = response.data?.clients_contacts ?? []
        if (contacts.length > 0) {
          contacts.forEach(c => {
            if (form.contact.value.toLowerCase() === c.full_name.toLowerCase()) {
              form.contact.id = c.id
            }
          })
        } else {
          const split = form.contact.value.split(' ')
          const first = split[0]
          let last
          if (split.length === 1) {
            last = first
          } else {
            last = split.pop()
          }
          form.contact.first = first
          form.contact.last = last ?? 'last'
        }
      }
    }

    const handleNewBlindAddress = (address: Create__Address_Address__Input) => {
      form.address.input = address
      form.address.invalid = false
      form.address.validated = true
      useCleanAddress.value = true
      form.address.value = `${address.street_1}\n${address.street_2}${address.street_2 ? '\n' : ''}${address.city} ${address.state}, ${address.country} ${address.zip_code}`
      dialog.value = false
    }

    /**
     * Gets address info from google
     */
    const getAddress = async () => {
      if (form.address.id) {
        form.address.validated = true
      } else if (form.address.value && form.address.hasChanged) {
        validating.value = true
        form.address.hasChanged = false
        form.address.validated = false
        form.address.invalid = false
        // @ts-ignore
        // eslint-disable-next-line no-undef
        const autocompleteService = new google.maps.places.AutocompleteService()
        autocompleteService.getQueryPredictions({
          input: form.address.value
        }, receivePredictions)
      }
    }

    /**
     * Receives predictions from google
     **/
    const receivePredictions = async (predictions: Array<any>, status: string) => {
      if (status === 'OK' && predictions.length > 0) {
        const placeId = predictions[0].place_id
        // @ts-ignore
        // eslint-disable-next-line no-undef
        const placesService = new google.maps.places.PlacesService(document.createElement('div'))
        placesService.getDetails({
          placeId: placeId,
          fields: ['address_components']
        }, getPlaceInfo)
      } else {
        form.address.invalid = true
        validating.value = false
      }
    }

    /**
     * Receive place info from google and find the resulting address from easypost in the db
     * @param response
     * @param status
     */
    const getPlaceInfo = async (response: any, status: string) => {
      if (status === 'OK') {
        const place: EasyPost_MakeAddressInput = { street1: '', street2: '', city: '', state: '', country: '', zip: '' }
        const ac = response.address_components
        const streetLine1Components = []
        const streetLine2Components = []
        const cityComponents = []
        const stateComponents = []
        const politicalComponents = []
        const zipComponents = []
        for (const c of ac) { // for component of address components
          if (c.types[0] === 'subpremise') {
            streetLine2Components.push(`${c.long_name}`)
          } else if (c.types[0] === 'street_number' || c.types[0] === 'route') {
            streetLine1Components.push(`${c.long_name}`)
          } else if (c.types.indexOf('sublocality') > -1) {
            streetLine2Components.push(`${c.long_name}`)
          } else if (c.types[0] === 'postal_town' || c.types[0] === 'locality') {
            cityComponents.push(`${c.long_name}`)
          } else if (c.types[0] === 'administrative_area_level_1' || c.types[0] === 'political') {
            stateComponents.push(`${c.long_name}`)
          } else if (c.types.indexOf('country') > -1) {
            politicalComponents.push(`${c.short_name}`)
          } else if (c.types.indexOf('postal_code') > -1) {
            zipComponents.push(`${c.long_name}`)
          }
        }

        if (politicalComponents.indexOf('United Kingdom') > -1) {
          place.street1 = [...streetLine2Components, ...streetLine1Components].join(' ')
        } else {
          place.street1 = streetLine1Components.join(' ')
          place.street2 = streetLine2Components.join(' ')
        }
        place.city = cityComponents.join(' ')
        place.state = stateComponents.join(' ')
        place.country = politicalComponents.join(' ')
        place.zip = zipComponents.join(' ')

        try {
          const response = await apollo.mutate({
            mutation: VERIFY_ADDRESS,
            variables: {
              input: place
            }
          })
          const address = response.data?.verified
          if (useCleanAddress.value) {
            form.address.value = `${address.street1} \n${address.street2}\n${address.city}, ${address.state} ${address.country} ${address.zip}`
          }
          form.address.validated = true
          form.address.input = TranslateEasyPostAddressToGQLInput(address)
          // now check to see if it already exists
          const query: QueryOptions<GetAddress_FullQueryVariables> = {
            query: GET_ADDRESSES__FULL,
            variables: {
              input: {
                filters: [
                  {
                    key: 'street_1',
                    value: form.address.input.street_1,
                    and: {
                      key: 'zip_code',
                      value: form.address.input.zip_code,
                      and: {
                        key: 'country',
                        value: form.address.input.country
                      }
                    }
                  }
                ]
              }
            }
          }
          const existsCheck = await apollo.query<GetAddress_FullQuery>(query)
          const existingAddress = existsCheck.data?.address_addresses?.[0] ?? undefined
          if (existingAddress) {
            form.address.id = existingAddress.id
          }
        } catch (error) {
          form.address.invalid = true
        } finally {
          validating.value = false
        }
      } else {
        form.address.invalid = true
        validating.value = false
      }
    }

    const blindAddressChangeHandler = () => {
      form.address.validated = false
      form.address.invalid = false
      form.address.hasChanged = true
    }

    onMounted(async () => {
      if (shipmentOrderId.value) {
        const query: QueryOptions = {
          query: GET_SHIPMENT_ORDER_DETAILS,
          variables: { id: shipmentOrderId.value }
        }
        const response = await apollo.query(query)
        const order: ShipmentOrderDetailsFragment = response.data?.order
        if (response.data.order) {
          // we need to grab the ship to client and contact if available
          const client: string = order.shipTo?.client?.name ?? ''
          const clientId: string = order.shipTo?.client?.id ?? ''
          const shortName: string = order.shipTo?.client?.short_name ?? ''
          const contactName: string = order.shipToContact?.full_name ?? ''
          const address: string = TranslateGqlAddressToString((order.shipTo as Address_Address))

          form.client.value = client
          form.client.id = clientId
          form.client.shortName = shortName
          form.contact.value = contactName
          form.address.value = address
          form.address.parsed = order.shipTo as Address_Address
          form.address.id = order.shipTo?.id ?? ''
          if (form.address.value) {
            form.address.validated = true
          }
        }
      }
    })

    return {
      dialog,
      useCleanAddress,
      emit,
      form,
      getAddress,
      handleNewBlindAddress,
      blindAddressChangeHandler,
      getValidShortName,
      checkIfContactExists,
      validationInfoMessage,
      validating,
      readyToValidate
    }
  }
})
