import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'
import topbar from '@/components/bars/topbar.vue'
import sidebar from '@/components/bars/sidebar.vue'
import login from '@/views/Login.vue'
import { PERMISSION_TABS } from '@/router/props/permissionsProps'
import { LOCAL_STORAGE_KEYS, locStore } from '@/lib/LocalStorageWrapper'
import { freshDeskWidget } from '@/lib/freshDesk.ts'

Vue.use(VueRouter)

const useHash = navigator.userAgent.includes('Electron')

const router = new VueRouter({
  mode: useHash ? 'hash' : 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      components: {
        content: login
      }
    },
    {
      path: '/sso',
      name: 'sso',
      components: {
        content: () => import('@/views/silentSsoCheck')
      }
    },
    {
      path: '/tenant',
      name: 'tenant',
      components: {
        content: () => {
          return import('@/views/TenantSelection')
        }
      }
    },
    {
      path: '/inventory',
      redirect: 'inventory/item'
    },
    {
      path: '/purchases',
      redirect: 'purchases/orders'
    },
    {
      path: '/sales',
      redirect: 'sales/orders'
    },
    {
      path: '/clients',
      redirect: '/clients/client'
    },
    {
      path: '/playground/',
      name: 'playground',
      components: {
        content: () => import('@/views/Playground'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/inventory/item',
      name: 'item',
      jumpName: 'items',
      components: {
        content: () => import('@/views/inventory/parts'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/inventory/system/:id?',
      name: 'system',
      jumpName: 'systems',
      components: {
        content: () => import('@/views/inventory/systems'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    /*
    {
      path: '/inventory/supplies',
      name: 'supplies',
      components: {
        content: resolve => {
          require.ensure(['@/views/inventory/supplies.vue'], () => {
            resolve(require('@/views/inventory/supplies.vue'))
          })
        },
        sideBar: sidebar,
        topBar: topbar
      }
    },
*/
    /*
    {
      path: '/inventory/internal',
      name: 'internal',
      components: {
        content: resolve => {
          require.ensure(['@/views/inventory/internal.vue'], () => {
            resolve(require('@/views/inventory/internal.vue'))
          })
        },
        sideBar: sidebar,
        topBar: topbar
      }
    },
*/
    /*
    {
      path: '/inventory/software',
      name: 'software',
      components: {
        content: resolve => {
          require.ensure(['@/views/inventory/software.vue'], () => {
            resolve(require('@/views/inventory/software.vue'))
          })
        },
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/inventory/service',
      name: 'service',
      components: {
        content: resolve => {
          require.ensure(['@/views/inventory/services.vue'], () => {
            resolve(require('@/views/inventory/services.vue'))
          })
        },
        sideBar: sidebar,
        topBar: topbar
      }
    },
*/
    {
      path: '/inventory/history',
      name: 'history',
      jumpName: 'history',
      components: {
        content: () => import('@/views/inventory/history'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/inventory/master/:id?/:nested?', // nested is either summary (to be made yet), bom, purchases, sales, quotes
      name: 'master',
      jumpName: 'master',
      components: {
        content: () => import('@/views/inventory/master'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/purchases/orders/:id?/:nested?', // nested is either product or summary
      name: 'purchaseOrders',
      jumpName: 'purchases',
      components: {
        content: () => import('@/views/purchasing/pts'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/receiving/orders/:id',
      name: 'receive',
      components: {
        content: () => import('@/components/grids/receiving'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/purchases/rma/:id?',
      name: 'prma',
      jumpName: 'purchase rmas',
      components: {
        content: () => import('@/views/purchasing/rma'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/purchases/workorders/:id?',
      name: 'workOrders',
      jumpName: 'work orders',
      components: {
        content: () => import('@/views/purchasing/workorders'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/sales/orders/:id?/:nested?', // nested is either product or summary
      name: 'saleOrders',
      jumpName: 'sales',
      components: {
        content: () => import('@/views/sales/sts'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/sales/rma/:id?',
      name: 'srma',
      jumpName: 'sales rmas',
      components: {
        content: () => import('@/views/sales/rma'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/sales/quotes/:id?',
      name: 'quotes',
      jumpName: 'quotes',
      components: {
        content: () => import('@/views/sales/quotes'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/clients/client/:id?/:nested?', // nested can be contacts, address, accounts, purchases, or sales
      name: 'client',
      jumpName: 'clients',
      components: {
        content: () => import('@/views/clients/clients'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/accounting',
      redirect: '/accounting/ledger'
    },
    {
      path: '/accounting/ledger',
      name: 'ledger',
      components: {
        content: () => import('@/views/accounting/gl'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/accounting/payable',
      name: 'ap',
      components: {
        content: () => import('@/views/accounting/payable'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/accounting/ap-prepayments',
      name: 'ap prepay',
      components: {
        content: () => import('@/views/accounting/ApPrepayments'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/accounting/receivable',
      name: 'ar',
      components: {
        content: () => import('@/views/accounting/receivable'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/accounting/ar-prepayments',
      name: 'ar prepay',
      components: {
        content: () => import('@/views/accounting/ArPrepayments'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/accounting/accounts',
      name: 'accounts',
      components: {
        content: () => import('@/views/accounting/ChartOfAccounts'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/accounting/summary',
      name: 'summary',
      components: {
        content: () => import('@/views/accounting/summary'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/tech',
      name: 'tech',
      components: {
        content: () => import('@/views/roles/testing'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/logs',
      name: 'logs',
      components: {
        content: () => import('@/views/logging/logs'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/shipping',
      name: 'shipping',
      jumpName: 'shipping',
      components: {
        content: () => import('@/views/roles/shipping'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/processing',
      name: 'processing',
      jumpName: 'processing',
      components: {
        content: () => import('@/views/roles/processing'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/assembly',
      redirect: 'assembly/buildup'
    },
    {
      path: '/assembly/buildup/:id?',
      name: 'buildup',
      jumpName: 'buildup',
      components: {
        content: () => import('@/views/roles/buildup'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/assembly/buildup/new/:id?',
      name: 'new-build-up',
      jumpName: 'new build up',
      components: {
        content: () => import('@/views/roles/new-build-up'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/assembly/breakdown',
      name: 'breakdown',
      jumpName: 'breakdown',
      components: {
        content: () => import('@/views/roles/breakdown'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/assembly/breakdown/new/:id?',
      name: 'new-break-down',
      components: {
        content: () => import('@/views/roles/new-break-down'),
        sideBar: sidebar,
        topBar: topbar
      }
    },
    {
      path: '/assembly/prebreakdownaudit/new/:id',
      name: 'pre-breakdown-audit',
      components: {
        content: () => import('@/views/roles/pre-breakdown-audit'),
        sideBar: sidebar,
        topBar: topbar
      },
      props: { content: true }
    },
    {
      path: '/settings',
      name: 'settings',
      jumpName: 'settings',
      components: {
        content: () => import('@/views/settings/settings'),
        sideBar: sidebar,
        topBar: topbar
      },
      children: [
        {
          path: 'account-management',
          name: 'accountManagement',
          jumpName: 'account management',
          component: () => import('@/views/settings/accountManagement')
        },
        {
          path: 'user-management',
          name: 'user-management',
          jumpName: 'User Management',
          component: () => import('@/views/settings/permissions'),
          props: {
            tabs: PERMISSION_TABS,
            component: 'user-management'
          }
        },
        {
          path: 'departments',
          name: 'departments',
          jumpName: 'departments',
          component: () => import('@/views/settings/permissions'),
          props: {
            tabs: PERMISSION_TABS,
            component: 'department-management'
          }
        },
        {
          path: 'roles',
          name: 'roles',
          jumpName: 'roles',
          component: () => import('@/views/settings/permissions'),
          props: {
            tabs: PERMISSION_TABS,
            component: 'role-management'
          }
        },
        {
          path: 'permissions',
          name: 'permissions',
          jumpName: 'permissions',
          component: () => import('@/views/settings/permissions'),
          props: {
            tabs: PERMISSION_TABS,
            component: 'permission-management'
          }
        },
        {
          path: 'company-settings',
          name: 'company-settings',
          jumpName: 'company settings',
          component: () => import('@/views/settings/companySettings')
        },
        {
          path: 'logistics',
          name: 'logistics',
          jumpName: 'logistics',
          component: () => import('@/views/settings/logistics')
        }
      ]
    },
    // {
    //   path: '/',
    //   redirect: 'tenant'
    // },
    {
      path: '*',
      redirect: 'tenant'
    }
  ]
})

router.beforeEach((to, from, next) => {
  // if going to an auth required route
  // path: "/tenant", hash: "#state=f9f65071-5efb-48d9-b287-48c51bdc3de3&sessio…1b2d2e3c4c24.74

  // if (to.path.includes('/tenant&state')) {
  //   const hash = to.path.replace('/tenant&', '#')
  //   const newTo = { name: 'tenant', hash: hash }
  //   return router.replace(newTo)
  // }

  if (to.name === 'test') {
    return next()
  }

  if (freshDeskWidget?.isPassedExpireTime()) {
    freshDeskWidget.reAuthenticate()
  }
  const access = locStore.get(LOCAL_STORAGE_KEYS.accessToken)
  const refresh = locStore.get(LOCAL_STORAGE_KEYS.refreshToken)
  const tenant = locStore.get(LOCAL_STORAGE_KEYS.tenant)
  const isLocal = window.location.host.includes('localhost')
  const tenantIsLocal = tenant?.toLowerCase() === 'local'
  const localInitials = locStore.get(LOCAL_STORAGE_KEYS.localInitials)
  const authenticated = ((!!access || !!refresh) && !!refresh && !!tenant) || (isLocal && tenantIsLocal && localInitials)
  const noProfile = store.state.profile.user.id === 0
  // if route is not login or reset

  if (tenantIsLocal && noProfile) { // this is only for connecting to local database
    store.dispatch('profile/getLoginData')
  }

  if (!to.path.includes('login') && !to.path.includes('tenant') && !to.path.includes('sso')) {
    // check if authenticated
    if (!authenticated) {
      if (isLocal) {
        next({ name: 'tenant' })
      } else {
        store.dispatch('auth/logout', { expire: false })
        next({ name: 'login' })
      }
      // else carry on
    } else {
      next()
    }
    // else going to login: carry on
  } else {
    next()
  }
})

export default router
