<template>
    <v-card>
      <v-card-title class="light-blue lighten-3">
        <h3 class="grey--text text--darken-3">Buildup Part Details</h3>
      </v-card-title>
      <v-card-text>
        <v-container pa-0 grid-list-xl>
          <v-layout row wrap justify-start align-center>
            <v-flex>
<!--              <standard-autofill-->
<!--                :id="partID"-->
<!--                :load="loadingParts"-->
<!--                :items="parts"-->
<!--                :disabled="isEditing"-->
<!--                :config="partConfig"-->
<!--                @updated="partID = $event.value"-->
<!--              ></standard-autofill>-->
              <parts-autocomplete
                :selected="part"
                @updated="part = $event.value"
              />
            </v-flex>
            <v-flex>
              <v-text-field
                v-model.number="minCount"
                label="Minimum Count"
                box
                :rules="[ e => e > -1 || 'Must be number >= 0']"
              />
            </v-flex>
            <v-flex>
              <v-text-field
                v-model.number="maxCount"
                label="Maximum Count"
                box
                :rules="maxRules"
              />
            </v-flex>
            <v-flex shrink>
              <v-select
                v-model="partType"
                label="Select a part type"
                :items="partTypes"
                menu-props="offset-y"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <t-alert :message="message" :type="type" />
        <v-spacer></v-spacer>
        <cancel-button :success="succeeded" @click="cancel"/>
        <submit-button :disabled="succeeded || !canSave" :loading="saving" @click="save"/>
      </v-card-actions>
    </v-card>
</template>

<script>
import { CREATE_BOM_PARTS, GET_BOM_PARTS__FULL, UPDATE_BOM_PART } from '@/api/graphql/Constants/Assembly'
import tAlert from '@/components/notifications/tAlert'
import { GET_PARTS_FOR_AUTOFILL } from '@/api/graphql/Constants/Inventory'
import parts from '@/components/autocompletes/PartsAutofill'
import { GridEvents } from '@/store/gridEvents.js'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default {
  name: 'bomPart',
  components: {
    't-alert': tAlert,
    'parts-autocomplete': parts,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    isEditing () {
      return this.data?.id > 0 ?? false
    },

    canSave () {
      if (this.isEditing) {
        return this.minCount !== this.data.minCount || this.maxCount !== this.data.maxCount || this.partType !== this.data.type
      } else {
        return this.minCount !== '' && this.maxCount !== '' && this.part.id
      }
    },

    succeeded () {
      return this.type === 'success'
    }
  },
  data () {
    return {
      minCount: '',
      maxCount: '',
      maxRules: [
        e => e >= 0 || 'Must be greater than -1',
        e => e >= this.minCount || 'Must be >= minimum'
      ],

      /* Part Selection Data */
      part: { pn: '', id: 0 },
      parts: [],
      loadingParts: false,
      partConfig: {
        label: 'Select a part'
      },

      /* Part Type Selection Data */
      partType: '',
      partTypes: [
        'AccessPoint',
        'Battery',
        'Bezel',
        'Bracket',
        'CPU',
        'Cable',
        'Cache',
        'DiskDrive',
        'ExpansionCard',
        'Fan',
        'GPU',
        'Heatsink',
        'IOModule',
        'Memory',
        'Motherboard',
        'NetworkCard',
        'Phone',
        'PowerSupply',
        'Rails',
        'Router',
        'Server',
        'Storage',
        'StorageEnclosure',
        'Supplies',
        'Switch'
      ],

      saving: false,

      message: '',
      type: 'info'
    }
  },
  apollo: {
    parts: {
      query: GET_PARTS_FOR_AUTOFILL,
      variables: {
        filters: [
          {
            key: 'server__isnull',
            value: true
          }
        ]
      },
      watchLoading (isLoading) {
        this.loadingParts = isLoading
      }
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },

    save () {
      if (this.isEditing) {
        this.saveEditions()
      } else {
        this.create()
      }
    },

    resultHandler ({ message, type }) {
      this.message = message
      this.type = type
      this.saving = false
    },

    saveEditions () {
      this.saving = true
      const id = this.data.id
      this.$apollo.mutate({
        mutation: UPDATE_BOM_PART,
        variables: {
          id: id,
          type: this.partType,
          max: this.maxCount,
          min: this.minCount
        }
      }).then(() => {
        this.resultHandler({ message: 'Successfully edited part.', type: 'success' })
      }).catch(error => {
        this.resultHandler({ message: error, type: 'error' })
      })
    },

    create () {
      this.saving = true
      const id = this.data.bomID
      this.$apollo.mutate({
        mutation: CREATE_BOM_PARTS,
        variables: {
          id: id,
          type: this.partType,
          max: this.maxCount,
          min: this.minCount,
          part_id: this.part.id
        }
      }).then(({ data: { Create__Parts_BOMParts } }) => {
        try {
          const data = this.$apollo.getClient().readQuery({
            query: GET_BOM_PARTS__FULL,
            variables: {
              input: {
                limit: 36,
                offset: 0,
                filters: [
                  {
                    key: 'bom__bom_for__id',
                    value: this.data.bomForID
                  }
                ],
                excludes: [],
                order_by: [],
                annotations: []
              }
            }
          })
          const index = data.parts_bom_parts.findIndex(p => p.id === id)
          if (data.parts_bom_parts.length > 0 && index > -1) {
            data.parts_bom_parts.splice(index, 1, Create__Parts_BOMParts)
          } else {
            data.parts_bom_parts.push(Create__Parts_BOMParts)
          }
          this.$apollo.getClient().writeQuery({
            query: GET_BOM_PARTS__FULL,
            variables: {
              input: {
                limit: 36,
                offset: 0,
                filters: [
                  {
                    key: 'bom__bom_for__id',
                    value: this.data.bomForID
                  }
                ],
                excludes: [],
                order_by: [],
                annotations: []
              }
            },
            data: data
          })
        } catch {
          GridEvents.$emit('refetch-master-nested')
        }
        this.resultHandler({ message: 'Successfully created part.', type: 'success' })
      }).catch(error => {
        this.resultHandler({ message: error, type: 'error' })
      })
    }
  },
  beforeMount () {
    if (this.isEditing) {
      this.part.id = this.data.partID
      this.part.pn = this.data.partPn
      this.maxCount = this.data.maxCount
      this.minCount = this.data.minCount
      this.partType = this.data.type
    }
  }
}
</script>

<style scoped>

</style>
