import gql from 'graphql-tag'
import { FRAGMENT__MONEY } from '@/api/graphql/Constants/TERMS'
import { FRAGMENT__ADDRESS__GRID } from '@/api/graphql/Constants/Addresses'

/* FRAGMENTS */

export const FRAGMENT__QUOTE_PART = gql`
  fragment QuotePart on Quotes_QuoteParts {
    id
    creator {
      id
      initials
    }
    part {
      id
      pn
      description
    }
    price_per_part { ...Money }
    qty
    total { ...Money }
    quote {
      id
      creator {
        id
        initials
      }
    }
  }
  ${FRAGMENT__MONEY}
`

export const FRAGMENT__QUOTE_FOR_SALE_IMPORT = gql`
  fragment QuoteForSaleImport on Quotes_Quote {
    id
    creator {
      id
      initials
      full_name
    }
    client {
      id
      name
      short_name
      credit_limit { ...Money }
      internal_rep {
        id
        initials
      }
    }
    contact {
      id
      full_name
      email
      owner {
        id
        name
      }
    }
    contract_comment
    internal_comment
    parts {
      ...QuotePart
    }
  }
  ${FRAGMENT__QUOTE_PART}
  ${FRAGMENT__MONEY}
`

export const FRAGMENT__QUOTE_FOR_PDF = gql`
  fragment QuoteForPdf on Quotes_Quote {
    ...QuoteForSaleImport
    ship_to_address {
      ...AddressGrid
    }
    ship_from_address {
      ...AddressGrid
    }
    creation_date
  }
  ${FRAGMENT__QUOTE_FOR_SALE_IMPORT}
  ${FRAGMENT__ADDRESS__GRID}
`

export const FRAGMENT__QUOTE = gql`
    fragment Quote on Quotes_Quote {
      id
      attached_sales {
        id
        estimated_total { ...Money }
        rep {
          id
          initials
        }
      }
      client {
        id
        name
      }
      contact {
        id
        full_name
        email
      }
      internal_comment
      contract_comment
      creation_date
      creator {
        id
        initials
      }
      total_price { ...Money }
    }
    ${FRAGMENT__MONEY}
`

/* HELPERS */

/* QUERIES */

export const GET_QUOTES_FROM_CUSTOMER_ID = gql`
  query GetQuotes ($id: Any!) {
    quotes: quotes_quotes (input: { filters: [{ key: "client__id", value: $id }] }) {
      id
      contact {
        id
        full_name
      }
    }
  }
`

export const GRID_GET_QUOTE_PARTS_FOR_QUOTE = gql`
  query QuotePartsForQuote ($input: ManyQueryInput!) {
    quote_parts: quotes_quote_parts (input: $input) {
      ...QuotePart
    }
  }
  ${FRAGMENT__QUOTE_PART}
`

export const GET_QUOTE_FOR_SALE_IMPORT = gql`
  query GetQuoteForSaleImport ($id: ID!) {
    quote: quotes_quote (input: { id: $id }) {
      ...QuoteForSaleImport
    }
  }
  ${FRAGMENT__QUOTE_FOR_SALE_IMPORT}
  ${FRAGMENT__QUOTE_PART}
`

export const GET_QUOTES = gql`
  query quotes ($input: ManyQueryInput!) {
    quotes: quotes_quotes (input: $input) {
      ...Quote
      annotations {
        name
        value
      }
    }
  }
  ${FRAGMENT__QUOTE}
`

export const GET_QUOTE__PDF = gql`
  query QuoteForPdf ($id: ID!) {
    quotes_quote(input: {id: $id}) {
      ...QuoteForPdf
    }
  }
  ${FRAGMENT__QUOTE_FOR_PDF}
`

/* MUTATIONS */

export const CREATE_QUOTE_PARTS = gql`
  mutation CreateQuotePart ($items: [Create__Quotes_QuoteParts__Input!]!) {
    parts: Create__Quotes_QuoteParts (input: $items) {
      ...QuotePart
    }
  }
  ${FRAGMENT__QUOTE_PART}
`

export const UPDATE_QUOTE__COMMENT_ALIAS = gql`
    mutation UpdateQuoteForComment ($input: Update__Quotes_Quote__Input!) {
      comment: Update__Quotes_Quote (input: $input) {
        id
      }
    }
`
