import { computed, ComputedRef, reactive } from '@vue/composition-api'
import { INewPurchasePart, INewQuotePart, INewSalePart } from '@/models/GuiPartsModels'
import {
  Create__Purchases_PurchasedItems__Input,
  QuotePartFragment,
  Quotes_QuoteParts
} from '@/models/generated/graphql/ErpBackend'
import { FormAction } from '@/composition/UseOrderInformation'
import { GetDjangoMoneyCompatibleInput } from '@/lib/moneyHelpers'

type TPartsConstraint = INewSalePart | INewQuotePart | INewPurchasePart

export interface PartForm<TParts> {
  [key: string]: any
  parts: {
    value: TParts[],
    message: string,
    validator: (action: FormAction) => void
  }
}

export function TranslateQuotePartsToNewSaleParts (qParts: Array<Quotes_QuoteParts | QuotePartFragment>): INewSalePart[] {
  const parts: INewSalePart[] = []
  for (const p of qParts) {
    while (p.qty > 0) {
      parts.push({
        id: p.part?.id ?? '',
        pn: p.part?.pn ?? '',
        quantity: 1,
        soldFor: Number(p.price_per_part?.amount ?? '0'),
        pt: null,
        item: null,
        hover: false
      })
      p.qty--
    }
  }
  return parts
}

export function getNewPurchasePartsInput (pParts: INewPurchasePart[], order: string): Create__Purchases_PurchasedItems__Input[] {
  const parts: Create__Purchases_PurchasedItems__Input[] = []
  for (const part of pParts) {
    parts.push({
      current_cost: GetDjangoMoneyCompatibleInput(part.cost),
      original_cost: GetDjangoMoneyCompatibleInput(part.cost),
      line_number: parts.length + 1,
      part_id: `${part.id}`,
      transaction_id: order
    })
  }
  return parts
}

export interface UsePartsForOrderReturn<T> {
  form: PartForm<T>
  hasErrors: ComputedRef<boolean>
}

export function usePartsForOrder <TParts extends TPartsConstraint>
(validation: (parts: TParts[], action: FormAction) => void): UsePartsForOrderReturn<TParts> {
  const form: PartForm<TParts> = reactive({
    parts: {
      value: [],
      message: '',
      validator: (action: FormAction) => validation(form.parts.value, action)
    }
  })

  const hasErrors = computed(() => {
    for (const key of Object.keys(form)) {
      if (form[key].message) return true
    }
    return false
  })

  return {
    form,
    hasErrors
  }
}
