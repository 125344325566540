// module to store a lot of re-used functions

import moment from 'moment'
import lodash from 'lodash'

export function matchKeyUpTo (event, key, altKey) {
  return (event.key === key || event.code === `Key${key.toUpperCase()}`)
}

function getOneNodeData (params) {
  // returns the single node.data (no more node[0].data needed)
  const selectedNodes = params.getSelectedNodes()
  if (selectedNodes.length !== 1) {
    return false
  } else {
    return selectedNodes[0].data
  }
}

function countDaysFrom (dateValue) {
  const currentDate = moment() // create moment object from today
  const date = moment(dateValue) // create moment object from given Date
  const difference = (Math.abs(currentDate.diff(date, 'days'))) // get difference between the two
  if (difference <= 30) { // if last thirty days
    return ''
  } else if (difference <= 60) { // if 60 days .....
    return 'level1-days-away'
  } else if (difference <= 90) { // otherwise return this
    return 'level2-days-away'
  } else {
    return 'level3-days-away'
  }
}

export function formatDate (date) {
  return date ? moment(date).format('L') : moment().format('L')
}

export function getDateToday () {
  return moment().format()
}

function format (money) {
  return (Number(money)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export function renderMoney (money) {
  if (money) {
    return format(money)
  } else {
    return '$ 0.00'
  }
}

export function isToday (date) {
  return moment(date).isSame(moment(), 'day')
}

export function dateIsSameOrBefore ({ dateA, dateB }) {
  return moment(dateA).isSameOrBefore(dateB)
}

// GRID CELL RENDERER FUNCTIONS

function dateCellRenderer (params) {
  return params.value ? moment(params.value).format('L') : ''
}

function checkCellRenderer (params) {
  return params.value ? '<i class="fas fa-check-circle grid-icon-font grid-icon"></i>' : ' '
}

// END GRID CELL RENDERER FUNCTIONS

function canChangeMode (currentMode, futureMode) {
  if (currentMode !== 'read' && currentMode !== futureMode) {
    this.$store.dispatch('notifications/createSnackbar', {
      message: 'Please save/cancel your changes before editing different types of data.',
      top: true,
      canClose: true,
      timeout: 0,
      color: 'warning'
    })
    return false
  } else {
    return true
  }
}

function changeCells (mode, futureMode, editableNodes, selectedNodes, columns) {
  const returnObject = {}
  if (this.canChangeMode(mode, futureMode)) {
    returnObject.mode = futureMode

    for (let i = 0; i < selectedNodes.length; i++) {
      for (let j = 0; j < columns.length; j++) {
        editableNodes.push(selectedNodes[i].data.id + ' ' + columns[j])
      }
    }
    returnObject.nodes = editableNodes
    return returnObject
  }
}

function canCheckInOut (selectedNodes) {
  if (selectedNodes.length === 1) {
    return true
  } else {
    return checkAllSame({ column: 'checked_out_by', nodes: selectedNodes })
  }
}

function checkLockStatus (selectedNodes, initials) {
  // returns a string object for the button name
  const x = selectedNodes.length > 1 ? 's' : ''
  const buttonName = selectedNodes[0].data.locked ? 'Unlock Part' + x : 'Lock Part' + x
  const buttonClass = ['bold']
  const returnObject = { name: buttonName, class: buttonClass, pass: true, locking: !selectedNodes[0].data.locked }

  if (selectedNodes.length > 1) {
    // check that pt_rep is the same and locked is the same throughout
    let rep = ''
    let locked = ''
    let nextLocked = ''
    for (let i = 0; i < selectedNodes.length - 1; i++) {
      rep = selectedNodes[i].data.purchases_items_details.transaction.rep.initials
      locked = selectedNodes[i].data.locked
      nextLocked = selectedNodes[i + 1].data.locked
      if (rep !== initials || locked !== nextLocked) {
        returnObject.pass = false
        return returnObject
      }
    }
  } else {
    if (selectedNodes[0].data.purchases_items_details.transaction.rep.initials !== initials) {
      returnObject.pass = false
    }
    returnObject.name = buttonName
    returnObject.class = buttonClass
  }
  return returnObject
}

function isBuiltUp (params) {
  // params: { api, allowMultiple, data }
  let pass = true
  if (params.allowMultiple) {
    pass = checkAllSame({ column: 'build_up_number', nodes: params.api.getSelectedNodes() }) && typeof params.data.build_up_number === typeof 2
  } else {
    const data = getOneNodeData(params.api)
    pass = typeof data.build_up_number === typeof 2
  }
  return pass
}

function isBrokenDown (params) {
  return typeof params.node.data.break_down_number === typeof 2
}

/**
 * Check an array to see if all the items have a similar key
 * @param {string} column
 * @param {RowNode[]} nodes
 * @returns {boolean}
 */
function checkAllSame ({ column, nodes }) {
  if (nodes.length === 1) {
    return true
  }
  for (let i = 0; i < nodes.length - 1; i++) {
    let node = {}
    let leftNode = {}
    if (nodes[i].data) {
      node = nodes[i].data
      leftNode = nodes[i + 1].data
    } else {
      node = nodes[i]
      leftNode = nodes[i + 1]
    }
    const value = lodash.get(node, column)
    const leftValue = lodash.get(leftNode, column)
    if (value !== leftValue) {
      return false
    }
  }
  return true
}

export function getRandomNumber ({ max }) {
  return Math.floor(Math.random() * Math.floor(max))
}

export function getRandomBoolean () {
  return Math.random() >= 0.5
}

export default {
  getOneNodeData: getOneNodeData,
  countDaysFrom: countDaysFrom,
  dateCellRenderer: dateCellRenderer,
  checkCellRenderer: checkCellRenderer,
  canChangeMode: canChangeMode,
  checkLockStatus: checkLockStatus,
  changeCells: changeCells,
  isBuiltUp: isBuiltUp,
  isBrokenDown: isBrokenDown,
  canCheckInOut: canCheckInOut,
  checkAllSame: checkAllSame
}
