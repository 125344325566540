<template>
  <div>
    <div v-if="alternateParts.length === 0">
      <b class="pr-2">{{ localPart.pn }}</b>
      <v-progress-circular
        v-if="isLoadingAltParts"
        indeterminate
        width="2"
        size="20"
        color="primary"
      />
    </div>
    <div v-else class="pb-2">
      <v-select
        browser-autocomplete="off"
        :items="alternateParts"
        :placeholder="localPart.pn"
        :hint="hint"
        persistent-hint
        item-text="pn"
        return-object
        @change="updated"
      >
        <template #prepend>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-icon v-on="on" color="purple">fal fa-question-circle</v-icon>
            </template>
            <v-layout column></v-layout>
            <v-flex>Select a part from this list to show on the order instead of the part listed.</v-flex>
            <v-flex>This is useful when your client calls a part something different than you do or has a list of subs that they accept.</v-flex>
          </v-tooltip>
        </template>
        <template #selection>
          <b>{{ alternatePart.pn }}</b>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import { GET_CLIENT_ALT_PARTS_FOR_PART } from '@/api/graphql/Constants/Clients'
export default {
  name: 'salesPartNumber',
  props: {
    part: {
      type: Object,
      required: true
    }
  },
  computed: {
    localPart: {
      get () {
        return this.part
      },
      set (value) {
        this.$emit('updated', { value: value })
      }
    },
    clientId () {
      return this.$store.state?.sale?.client?.id ?? 0
    }
  },
  watch: {
    alternateParts: function (value) {
      if (value.length > 0) {
        this.$emit('cacheAltParts', { value: value })
      }
    }
  },
  data () {
    return {
      isLoadingAltParts: false,
      alternatePart: { id: 0, pn: '' },
      alternateParts: [],
      hint: ''
    }
  },
  apollo: {
    alternateParts: {
      query: GET_CLIENT_ALT_PARTS_FOR_PART,
      variables () {
        return {
          clientId: this.clientId, partId: this.part.id
        }
      },
      update (data) {
        return data.clients_client.alt_parts
      },
      skip () {
        return this.clientId === 0
      },
      watchLoading (isLoading) {
        this.isLoadingAltParts = isLoading
      }
    }
  },
  methods: {
    updated (event) {
      this.alternatePart = event
      if (this.alternatePart.pn !== this.part.pn) {
        this.hint = `Inventory part is: ${this.part.pn}`
      } else this.hint = ''
      this.$emit('updated', { value: event })
    }
  },
  mounted () {
    if (this.part.altPart) {
      this.updated(this.part.altPart)
    }
    if (this.part.altParts) {
      this.alternateParts.push(...this.part.altParts)
    } else {
      // this.isLoadingAltParts = true
      // setTimeout(() => {
      //   this.isLoadingAltParts = false
      //   if (this.part.pn === 'W347K') {
      //     this.alternateParts.push(
      //       this.part,
      //       { id: 1, pn: '12345678910111213141516' },
      //       { id: 2, pn: '34232r' }
      //     )
      //   }
      // }, 2000)
    }
  }
}
</script>

<style scoped>

</style>
