<template>
  <new-data-form
    :errors="{
      panel0: companyErrors,
      panel1: contactErrors,
      panel2: errors.easypost_id.value
    }"
    :succeeded="succeeded"
    :failed="failed"
  >
    <template #header0>COMPANY</template>
    <template #panel0>
      <v-container grid-list-xl data-cy="new-company">
        <v-layout row wrap justify-start align-center>
          <!-- Company Name -->
          <v-flex xs5 lg3>
            <v-text-field
              data-cy="company-name"
              v-model="companyName"
              box
              label="Company Name"
              :error-messages="errors.companyName.value"
              @change="update('companyName', $event)"
            />
          </v-flex>
          <!-- Client Code -->
          <v-flex xs3 lg2>
            <v-text-field
              data-cy="company-short-name"
              v-model="companyShortName"
              box
              label="Client Code"
              counter="10"
              mask="AAAAAAAAAA"
              @blur="checkShortName()"
              :success-messages="checkSuccessMessage"
              :error-messages="checkFailMessage || errors.companyShortName.value"
            >
              <template #append-outer>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-fade-transition leave-absolute>
                      <v-progress-circular
                        v-if="checking"
                        color="info"
                        indeterminate
                      >
                      </v-progress-circular>
                      <v-icon
                        @click="checkShortName"
                        v-on="on"
                        v-else
                        :color="checkerColor"
                      >fal fa-check-circle</v-icon>
                    </v-fade-transition>
                  </template>
                  <span>{{ checkSuccess ? 'Validated!' : 'Click here to validate!' }}</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-flex>
          <!-- Rep -->
          <v-flex shrink>
            <users
              :user-id="repId"
              @updated="repId = $event.value"
            >
              <template #label>Rep</template>
            </users>
          </v-flex>
          <!-- Default ST Terms -->
          <v-flex shrink>
            <standard-autofill
              data-cy="st-terms-id"
              :id="stTermsId"
              :items="terms"
              :load="loadingTerms"
              :config="stTermsConfig"
              @updated="stTermsId = $event.value"
            />
          </v-flex>
          <!-- Default PT Terms -->
          <v-flex shrink>
            <standard-autofill
              data-cy="pt-terms-id"
              :id="ptTermsId"
              :items="terms"
              :load="loadingTerms"
              :config="ptTermsConfig"
              @updated="ptTermsId = $event.value"
            />
          </v-flex>
          <!-- Client Type -->
          <v-flex shrink>
            <choices
              data-cy="type-id"
              :id="typeId"
              :config="typeConfig"
              :error="errors.typeId.value"
              @updated="update($event.key, $event.value)"
            />
          </v-flex>
          <!-- Credit Limit -->
          <v-flex xs3>
            <currency
              data-cy="credit-limit"
              label="Credit Limit"
              :value="creditLimit"
              :error-messages="errors.creditLimit.value"
              @input="update('creditLimit', $event)"
            />
          </v-flex>
          <v-flex shrink>
            <e-commerce-select errors="''" :store="store" @updated="update('store', $event.value)"/>
          </v-flex>
        </v-layout>
      </v-container>
    </template>

    <template #header1>CONTACT</template>
    <template #panel1>
      <v-layout row>
        <v-expand-transition>
          <v-checkbox
            v-if="store"
            v-model="skipContact"
            label="Skip Entering Contact"
            color="error"
            class="ml-5"
            data-cy="skip-contact"
          ></v-checkbox>
        </v-expand-transition>
        <v-slide-x-reverse-transition>
          <span v-if="skipContact" class="warning--text bold">Anything entered will not be saved</span>
        </v-slide-x-reverse-transition>
      </v-layout>
      <v-container grid-list-xl data-cy="new-contact" id="contact-input-container">
        <v-layout row wrap justify-start align-center>
          <!-- First Name -->
          <v-flex xs3>
            <v-text-field
              v-model="firstName"
              data-cy="first-name"
              box
              label="First Name"
              :error-messages="errors.firstName.value"
              @change="update('firstName', $event)"
            />
          </v-flex>
          <!-- Last Name -->
          <v-flex xs3>
            <v-text-field
              data-cy="last-name"
              v-model="lastName"
              box
              label="Last Name"
              :error-messages="errors.lastName.value"
              @change="update('lastName', $event)"
            />
          </v-flex>
          <!-- Email -->
          <v-flex xs4>
            <v-text-field
              data-cy="email"
              v-model.trim="email"
              box
              label="Email"
              placeholder="Optional"
              :error-messages="errors.email.value"
              @change="update('email', $event)"
            />
          </v-flex>
          <!-- Phone Number -->
          <v-flex xs3>
            <v-text-field
              data-cy="phone"
              v-model="phone"
              box
              label="Phone Number"
              placeholder="Optional"
            ></v-text-field>
          </v-flex>
          <!-- Main Checkboxes -->
<!--          <v-flex xs3>-->
<!--            <v-checkbox-->
<!--              v-model="contactMainBilling"-->
<!--              label="Default bill to"-->
<!--              color="info"-->
<!--            ></v-checkbox>-->
<!--          </v-flex>-->
<!--          <v-flex xs3>-->
<!--            <v-checkbox-->
<!--              v-model="contactMainRemit"-->
<!--              label="Default remit to"-->
<!--              color="info"-->
<!--            ></v-checkbox>-->
<!--          </v-flex>-->
<!--          <v-flex xs3>-->
<!--            <v-checkbox-->
<!--              v-model="contactMainShip"-->
<!--              label="Default ship to"-->
<!--              color="info"-->
<!--            ></v-checkbox>-->
<!--          </v-flex>-->
        </v-layout>
      </v-container>
    </template>

    <template #header2>ADDRESS</template>
    <template #panel2>
      <v-layout row>
        <v-expand-transition>
          <v-checkbox
            v-if="store"
            v-model="skipAddress"
            label="Skip Entering Address"
            color="error"
            class="ml-5"
            data-cy="skip-address"
          ></v-checkbox>
        </v-expand-transition>
        <v-slide-x-reverse-transition>
          <span v-if="skipAddress" class="warning--text bold">Anything entered will not be saved</span>
        </v-slide-x-reverse-transition>
      </v-layout>
      <new-address
        data-cy="new-address"
        :owner="0"
        :event-only="true"
        :cancelName="'Back'"
        :address="address"
        @updated="updateAddress"
        @validated="addressValidated"
        @no-validate="validationError = true"
      />
      <span v-if="errors.easypost_id.value || validationError" class="error--text">{{ errors.easypost_id.value }}</span>
    </template>

    <template #dataActions>
      <!-- Close -->
      <cancel-button :success="succeeded" @click="cancel"/>
      <submit-button :loading="saving" :disabled="succeeded" @click="submit"/>
    </template>

    <template #footer>
      <v-footer :color="footerColor" data-cy="new-data-form-footer">
        <v-layout row justify-center align-center>
          <h3 :class="{ 'white--text': !succeeded, 'grey--text text--darken-2': succeeded }">{{ footerMessage }}</h3>
        </v-layout>
      </v-footer>
    </template>
  </new-data-form>
</template>

<script>
import newAddress from '@/components/templates/newAddress'
import standard2 from '@/components/autocompletes/standard2'
import choices2 from '@/components/autocompletes/choices2'
import { ValidateSingleLevelObject } from '@/lib/validation'
import formTemplate from './formTemplate'
import { GET_USERS_FOR_AUTOFILL } from '@/api/graphql/Constants/Users'
import { TERMS_FOR_AUTOCOMPLETE } from '@/api/graphql/Constants/TERMS'
import { CREATE_CLIENT, GET_CLIENTS__LIST, UPDATE_CLIENT } from '@/api/graphql/Constants/Clients'
import { CREATE_ADDRESS, VERIFY_ADDRESS } from '@/api/graphql/Constants/Addresses'
import { CREATE_CONTACT } from '@/api/graphql/Constants/Contacts'
import users from '@/components/autocompletes/users'
import Currency from '@/components/fields/Currency'
import CancelButton from '@/components/buttons/CancelButton'
import ECommerceSelection from '@/components/autocompletes/ECommerceSelection'
import { GetDjangoMoneyCompatibleInput } from '@/lib/moneyHelpers'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import { Sentry } from '@/lib/Sentry.ts'
export default {
  name: 'newClient',
  components: {
    'new-address': newAddress,
    'standard-autofill': standard2,
    'choices': choices2,
    'new-data-form': formTemplate,
    'users': users,
    'currency': Currency,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton,
    'e-commerce-select': ECommerceSelection
  },
  computed: {
    footerColor () {
      if (this.hasErrors) return 'warning'
      if (this.succeeded) return 'success'
      if (this.failed) return 'error'
      return 'light-blue darken-4'
    },

    companyErrors () {
      const errors = ['companyName', 'companyShortName', 'creditLimit', 'typeId']
      for (const error of errors) {
        if (this.errors[error].value) return true
      }
      return false
    },

    contactErrors () {
      const errors = ['firstName', 'lastName', 'email']
      for (const error of errors) {
        if (this.errors[error].value) return true
      }
      return false
    },

    hasErrors () {
      return this.companyErrors || this.contactErrors || this.validationError || this.checkFail ||
        this.errors.easypost_id.value !== '' || (!this.easypost_id && !this.skipAddress)
    },

    clientInput () {
      return {
        credit_limit: GetDjangoMoneyCompatibleInput(this.creditLimit),
        internal_rep_id: this.repId || undefined,
        is_our_client: true,
        name: this.companyName || undefined,
        ecommerce_store: this.store || undefined,
        rating: 5,
        short_name: this.companyShortName || undefined,
        type_id: this.typeId || undefined,
        website: this.website || undefined,
        default_pt_terms_id: this.ptTermsId || undefined,
        default_st_terms_id: this.stTermsId || undefined
      }
    },

    contactInput () {
      return {
        email: this.email || undefined,
        first_name: this.firstName || undefined,
        last_name: this.lastName || undefined,
        owner_id: this.newClientId || undefined,
        phone: this.phone || undefined
      }
    },

    addressInput () {
      return {
        building_name: this.address.nickName || undefined,
        city: this.address.city || undefined,
        client_id: this.newClientId || undefined,
        country: this.address.country || undefined,
        default_contact_id: this.newContactId || undefined,
        residential: this.address.residential,
        state: this.address.state || undefined,
        street_1: this.address.street1 || undefined,
        street_2: this.address.street2 || undefined,
        zip_code: this.address.zip || undefined,
        easypost_id: this.easypost_id
      }
    }
  },
  watch: {
    hasErrors: function (value) {
      if (value === false) this.footerMessage = 'New Account'
    },

    skipContact: function (value) {
      const inputs = document.getElementById('contact-input-container').getElementsByTagName('input')
      if (value) {
        inputs.forEach(e => { e.disabled = true })
      } else {
        inputs.forEach(e => { e.disabled = false })
      }
    },

    skipAddress: function (value) {
      const inputs = document.getElementById('address-input-container').getElementsByTagName('input')
      const button = document.getElementById('toggle-google')
      const checkbox = document.querySelector('[data-cy="residential"]')
      if (value) {
        inputs.forEach(e => { e.disabled = true })
        button.disabled = true
        checkbox.className += ' v-input--is-disabled'
      } else {
        inputs.forEach(e => { e.disabled = false })
        button.disabled = false
        checkbox.className = checkbox.className.replace(' v-input--is-disabled', '')
      }
    }
  },
  data () {
    return {
      // panel data
      footerMessage: 'New Account',
      failed: false,
      saving: false,
      succeeded: false,

      emailReg: /[A-Z0-9._%+-]+@[A-Z0-9.-]+[.]+.[a-z]{1,4}/igm,
      newClientId: '',
      newContactId: '',
      newAddressEasypostId: '',

      // ERRORS
      errors: {
        companyName: { value: '', validator: e => e ? '' : 'Company name is required.' },
        companyShortName: { value: '', validator: () => this.checkSuccess ? '' : 'Must have a valid short name' },
        creditLimit: { value: '', validator: e => e ? '' : 'Must be > 0' },
        typeId: { value: '', validator: e => e ? '' : 'This is required.' },
        firstName: { value: '', validator: e => e || this.skipContact ? '' : 'This is required' },
        lastName: { value: '', validator: e => e || this.skipContact ? '' : 'This is required' },
        email: { value: '', validator: e => this.emailReg.test(e) || this.skipContact || e === '' ? '' : 'Enter a valid email' },
        easypost_id: { value: '', validator: e => e || this.skipAddress ? '' : 'Validate the address to continue' }
      },

      // COMPANY INFO
      companyName: '',
      companyShortName: '',
      store: '',
      // checker
      checking: false,
      checkSuccess: false,
      checkSuccessMessage: '',
      checkFail: false,
      checkFailMessage: '',
      checkerColor: '',
      // end checker
      // REP
      repId: 0,
      reps: [],
      loadingReps: false,
      // end rep
      typeId: 0,
      typeConfig: {
        label: 'Client Type',
        appName: 'clients',
        modelName: 'client',
        fieldName: 'type',
        name: 'typeId'
      },
      creditLimit: 1000,
      // TERMS
      terms: [],
      stTermsId: 0,
      ptTermsId: 0,
      stTermsConfig: {
        label: 'Default ST Terms'
      },
      ptTermsConfig: {
        label: 'Default PT Terms'
      },
      loadingTerms: false,

      // CONTACT DATA
      skipContact: false,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      contactMainBilling: false,
      contactMainRemit: false,
      contactMainShip: false,

      // ADDRESS
      skipAddress: false,
      address: {
        city: '',
        client: '',
        company: '',
        country: '',
        name: '',
        nickName: '',
        phone: '',
        residential: false,
        state: '',
        street1: '',
        street2: '',
        strict: '',
        zip: ''
      },
      easypost_id: '',
      validationError: false
    }
  },
  apollo: {
    reps: {
      query: GET_USERS_FOR_AUTOFILL,
      update: data => data.users,
      watchLoading (isLoading) {
        this.loadingReps = isLoading
      }
    },
    terms: {
      query: TERMS_FOR_AUTOCOMPLETE,
      update (data) {
        const defaultTermsForNewbies = data.terms.find(t => t.default_terms_for_new_client !== null)
        if (defaultTermsForNewbies) {
          const id = defaultTermsForNewbies.default_terms_for_new_client.id
          this.ptTermsId = id
          this.stTermsId = id
        }
        return data.terms
      },
      watchLoading (isLoading) {
        this.loadingTerms = isLoading
      }
    }
  },
  methods: {
    update (key, value) {
      this[key] = value
      if (this.errors[key]) {
        this.errors[key].value = this.errors[key].validator(value)
      }
    },

    async createClientObject (client) {
      try {
        const newClientResponse = await this.$apollo.mutate({
          mutation: CREATE_CLIENT,
          variables: { input: client }
        })
        return newClientResponse.data?.Create__Clients_Client
      } catch (error) {
        throw new Error(error.message)
      }
    },

    async createContactObject (contact) {
      try {
        const contactResponse = await this.$apollo.mutate({
          mutation: CREATE_CONTACT,
          variables: { input: contact }
        })
        return contactResponse.data.Create__Clients_Contact
      } catch (e) {
        throw new Error(e.message)
      }
    },

    async createAddressObject (address) {
      try {
        const addressResponse = await this.$apollo.mutate({
          mutation: CREATE_ADDRESS,
          variables: { input: address }
        })
        return addressResponse.data.Create__Address_Address
      } catch (e) {
        throw new Error(e.message)
      }
    },

    async updateClient ({ contact, address }) {
      try {
        const clientUpdateObject = {
          id: this.newClientId,
          default_billing_contact_id: contact?.id || undefined,
          default_remit_contact_id: contact?.id || undefined,
          default_ship_to_contact_id: contact?.id || undefined,
          default_ship_to_address_id: address?.id || undefined,
          default_ship_from_address_id: address?.id || undefined,
          default_billing_address_id: address?.id || undefined,
          default_return_to_address_id: address?.id || undefined,
          terms: [this.ptTermsId, this.stTermsId]
        }
        await this.$apollo.mutate({
          mutation: UPDATE_CLIENT,
          variables: { input: clientUpdateObject }
        })
      } catch (error) {
        throw new Error(error.message)
      }
    },

    async submit () {
      ValidateSingleLevelObject(this.errors, this)
      if (!this.hasErrors) {
        this.saving = true
        try {
          Sentry.setContext('client_input', this.clientInput)
          Sentry.setContext('address_input', this.addressInput)
          Sentry.addBreadcrumb({ category: 'skips', message: `Skip Contact: ${this.skipContact}, Skip Address: ${this.skipAddress}`, type: 'info' })
          Sentry.addBreadcrumb({ category: 'client', message: 'Verified address', type: 'info' })
          // NEED TO VERIFY ADDRESS
          // create client
          const newClient = await this.createClientObject(this.clientInput)
          Sentry.addBreadcrumb({ category: 'client', message: 'Made client', type: 'info' })
          Sentry.setContext('contact_input', this.contactInput)
          this.newClientId = newClient.id
          // new contact
          let newContact
          if (!this.skipContact) {
            newContact = await this.createContactObject(this.contactInput)
            this.newContactId = newContact.id
            Sentry.addBreadcrumb({ category: 'client', message: 'Made contact', type: 'info' })
          }
          // create address
          let newAddress
          if (!this.skipAddress) newAddress = await this.createAddressObject(this.addressInput)
          Sentry.addBreadcrumb({ category: 'client', message: 'Made address', type: 'info' })
          // update client with terms and default contact
          await this.updateClient({ client: newClient, contact: newContact, address: newAddress })
          Sentry.addBreadcrumb({ category: 'client', message: 'Updated client', type: 'info' })
          this.resultHandler({ message: 'Success' })
        } catch (error) {
          Sentry.captureEvent(error)
          this.resultHandler({ message: error, type: 'error' })
        }
      } else {
        this.footerMessage = 'Fix the errors to submit!'
      }
    },

    resultHandler ({ message, type = 'success' }) {
      this.saving = false
      if (type === 'success') {
        this.succeeded = true
        this.$store.dispatch('data/changeRefresh', { bool: true })
      }
      this.footerMessage = message
      this.type = type
    },

    addressValidated (event) {
      this.easypost_id = event.easypost_id
      this.errors.easypost_id.value = ''
      this.validationError = false
      delete event.easypost_id
      this.updateAddress(event)
    },

    updateAddress (event) {
      for (const key in event) {
        this.address[key] = event[key]
      }
    },

    cancel () {
      this.$store.dispatch('grid/resetSheet')
    },

    async checkShortName () {
      if (this.companyShortName.length > 0) {
        try {
          this.checking = true
          const response = await this.$apollo.query({
            query: GET_CLIENTS__LIST,
            variables: {
              input: {
                filters: [{ key: 'short_name', value: this.companyShortName }]
              }
            }
          })
          this.checking = false
          this.checkSuccess = response.data.clients_clients.length === 0
          if (this.checkSuccess) {
            this.checkSuccessMessage = 'Validated'
            this.errors.companyShortName.value = ''
          } else {
            this.errors.companyShortName.value = 'Already exists.'
          }
          this.checkerColor = this.checkSuccess ? 'success' : 'error'
        } catch (error) {
          this.checkFailMessage = 'Error has occurred.'
        }
      }
    }
  },
  mounted () {
    Sentry.resetScope()
  }
}
</script>
