<template>
  <v-container fluid pb-1 fill-height style="min-height: 365px;">
    <v-progress-linear v-if="$apollo.loading && fields.length === 0" indeterminate/>
    <v-layout row nowrap justify-center>
      <!-- Field Search -->
      <v-flex xs4 pr-2 mr-4 style="min-width: 301px; min-height: 336px;">
        <v-layout column justify-start nowrap>
          <!-- Search Field -->
          <v-flex xs12>
            <v-text-field
              v-model="search"
              label="Search fields"
              :hint="hint"
              :readonly="readOnly"
              :disabled="shownFields.length === 0"
              outline
              autofocus
              clearable
              :success="onlyOne"
              id="searcher"
              @keydown.tab.prevent="stageIt"
              @keydown.enter="stageIt"
            >
              <template v-slot:message="{ message, key }">
                <span :key="key" v-html="message"/>
              </template>
            </v-text-field>
          </v-flex>
          <v-slide-x-transition group leave-absolute>
            <!-- Matched Fields -->
            <v-flex xs12 v-if="!staging" key="search">
              <v-layout column nowrap align-start justify-start class="field-container">
                <v-flex xs12 v-for="(item, index) in shownFields" :key="index" py-1 pl-2>
                  <v-card hover class="clickable" @click="stageIt(item)">
                    <v-card-title>
                      <h5>{{ item.name }}</h5>
                    </v-card-title>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
            <!-- Staging Area -->
            <v-flex xs12 v-else key="stage">
              <v-container fluid px-0>
                <v-layout column nowrap align-center>
                  <v-flex xs12>
                    <h5>{{ staged.name }}</h5>
                  </v-flex>
                  <v-flex xs12>
                    <v-form ref="stage" @submit.prevent="">
                      <date-picker
                        v-if="staged.type.includes('Date')"
                        label=""
                        :date="datePickerDate"
                        :errors="datePickerErrors"
                        @updated="updateDate"
                      />
                      <currency
                        v-else-if="staged.type === 'Price'"
                        data-cy="dynamic-staging-currency"
                        :autofocus="true"
                        :solo="true"
                        @enter="submitStaged()"
                        @input="editPrice(staged, -1, $event)"
                      />
                      <live-comment-area
                        v-else-if="staged.field.includes('comment')"
                        id="new-order-live-comment"
                        save-button-id="submit-staged"
                        :comment="staged.value"
                        :readonly="false"
                        @updateComment="staged.value = $event"
                      >
                        <template #label>
                          <span>{{ staged.name }}</span>
                        </template>
                      </live-comment-area>
                      <v-text-field
                        v-else
                        solo
                        autofocus
                        :rules="[ e => e.length > 0 || 'Must not be empty' ]"
                        @keyup.enter="submitStaged()"
                        @input="updateStaged"
                      />
                    </v-form>
                  </v-flex>
                  <!-- Buttons -->
                  <v-flex xs12>
                    <v-layout row nowrap justify-center>
                      <v-flex xs6>
                        <v-btn
                          small
                          color="error"
                          @click="clearStaged()"
                        >
                          <v-icon small>fal fa-times</v-icon>
                        </v-btn>
                      </v-flex>
                      <v-flex x6>
                        <v-btn
                          small
                          color="#51EC9C"
                          id="submit-staged"
                          @click="submitStaged()"
                        >
                          <v-icon small>fal fa-check</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-flex>
          </v-slide-x-transition>
        </v-layout>
      </v-flex>
      <!-- Selected Field Container -->
      <v-flex xs8>
        <v-card color="blue-grey lighten-5">
          <v-card-title>
            <h4>Selected Fields</h4>
          </v-card-title>
          <v-container grid-list-xl style="max-height: 300px; overflow-y: auto;">
            <v-scale-transition tag="v-layout" class="row wrap justify-start align-start" group>
              <!-- Selected Fields -->
              <v-flex shrink v-for="(item, index) in selected" :key="item.field" pr-4>
                <v-layout row justify-start align-start>
                  <v-flex>
                    <v-switch
                      v-if="item.type === 'Boolean'"
                      v-model="item.value"
                      data-cy="dynamic-selected-switch"
                      :label="item.name"
                      readonly
                    />
                    <date-picker
                      v-else-if="item.type.includes('Date')"
                      data-cy="dynamic-selected-date"
                      :date="item.text"
                      :label="item.name"
                      :hint="item.hint"
                      :errors="datePickerErrors"
                      @updated="editDate(item, index, $event)"
                    />
                    <v-text-field
                      v-else-if="item.type === 'Price'"
                      v-currency
                      data-cy="dynamic-selected-currency"
                      :value="item.text"
                      :label="item.name"
                      @input="editPrice(item, index, $event)"
                    />
                    <v-text-field
                      v-else
                      v-model="item.text"
                      box
                      persistent-hint
                      data-cy="dynamic-selected-text"
                      :label="item.name"
                      :hint="item.hint"
                    />
                  </v-flex>
                  <v-flex xs1 pl-0>
                    <v-layout justify-start>
                      <v-btn
                        icon
                        small
                        @click="remove(index)"
                      >
                        <v-icon small color="error">fal fa-minus</v-icon>
                      </v-btn>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-scale-transition>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import datePicker from '../autocompletes/datePicker2'
import liveCommentArea from '@/components/templates/liveCommentArea'
import { GET_FIELDS } from '@/api/graphql/Constants/Company'
import Currency from '@/components/fields/Currency'
import { GetDjangoMoneyCompatibleInput } from '@/lib/moneyHelpers'

export default {
  name: 'dynamicFieldEntry',
  components: {
    'date-picker': datePicker,
    'live-comment-area': liveCommentArea,
    'currency': Currency
  },
  props: {
    inUseFields: {
      type: Array,
      required: true
    },
    selected: {
      type: Array,
      required: true
    },
    allFields: {
      type: Array,
      required: true
    }
  },
  computed: {
    onlyOne: function () {
      return this.shownFields.length === 1
    }

    // average: function () {
    //   let total = 0
    //   let count = 0
    //   for (const field of this.allFields) {
    //     total = total + field.used
    //     count++
    //   }
    //   return total / count
    // }
  },
  watch: {
    search: function (newValue) {
      if (this.staging) {
        if (newValue) {
          if (newValue.indexOf(':') === -1) {
            this.staging = false
            this.staged = {}
            this.getShownFields(newValue.toLowerCase())
          } else {
            this.hint = 'Now enter your data and press <strong>enter</strong>!'
            const valueIndex = newValue.indexOf(':')
            const value = newValue.substring(valueIndex + 1, newValue.length)
            this.staged.value = value
            this.staged.text = value
          }
        } else {
          this.staging = false
          this.staged = {}
          this.getShownFields('')
        }
      } else {
        this.hint = 'Press <strong>TAB</strong> or <strong>ENTER</strong> to auto-complete and enter data'
        if (newValue) {
          const recentValue = newValue.charAt(newValue.length - 1)
          if (recentValue === ':') {
            if (this.shownFields.length > 0) {
              this.staging = true
              this.staged = this.shownFields[0]
              this.search = this.shownFields[0].name + ':'
            }
          } else {
            this.getShownFields(newValue.toLowerCase())
          }
        } else {
          this.getShownFields('')
        }
      }
    },
    selected: function () {
      this.getShownFields(this.search)
      this.getShownRecents()
    },
    staged: {
      handler: function (value) {
        if (value.type) {
          if (value.type) {
            this.processType(value.type)
          }
        }
      },
      deep: true
    },
    allFields: function (value) {
      if (value?.length > 0) {
        this.getShownFields('')
      }
    }
  },
  data () {
    return {
      shownRecents: [],
      staging: false,
      staged: {},

      datePickerDate: '',
      datePickerConfig: {
        minDate: '',
        maxDate: '',
        label: ''
      },
      datePickerErrors: '',

      types: [
        'DateTime',
        'String',
        'Date',
        'Price'
      ],

      fields: [],
      shownFields: [],
      showRecents: false,

      search: '',
      hint: '',
      readOnly: false
    }
  },
  // apollo: {
  //   fields: {
  //     query: GET_FIELDS,
  //     variables () {
  //       return {
  //         name: this.input
  //       }
  //     },
  //     update: data => data.__type.inputFields,
  //     fetchPolicy: 'no-cache'
  //   }
  // },
  methods: {
    getShownFields (search) {
      const matches = []
      for (const item of this.allFields) {
        if (item.name.toLowerCase().indexOf(search) > -1 && this.selected.indexOf(item) === -1) {
          matches.push(item)
        }
      }
      this.shownFields = matches
    },

    getShownRecents () {
      const recents = []
      for (const field of this.allFields) {
        if (recents.length > 4) {
          break
        }
        if (field.used > this.average && this.selected.indexOf(field) === -1) {
          recents.push(field)
        }
      }
      this.shownRecents = recents
    },

    selectMatchedField (item, index) {
      /* this.selected.push(item)
      this.shownFields.splice(index, 1) */
      this.getShownFields(item.name)
      this.search = item.name + ':'
      this.staged = item
      this.staging = true
    },

    selectRecentField (item, index) {
      this.shownRecents.splice(index, 1)
      this.staged = item
      this.staging = true
      this.readOnly = true
    },

    remove (index) {
      this.selected.splice(index, 1)
    },

    stageIt (stage = this.shownFields[0]) {
      if (stage.type === 'Boolean') {
        stage.value = true
        this.staged = stage
        this.submitStaged()
      } else {
        this.staging = true
        this.staged = stage
        this.search = stage.name + ':'
      }
    },

    editSelected (item, index) {
      if (!this.staging) {
        this.selected.splice(index, 1)
        this.staged = item
        this.staging = true
        this.readOnly = true
      }
    },

    submitStaged () {
      if (this.staged.type === 'Boolean' || (this.staging && this.$refs.stage.validate())) {
        this.selected.push(this.staged)
        this.datePickerDate = ''
        this.search = ''
        this.staging = false
        this.readOnly = false
        this.getShownFields('')
        document.getElementById('searcher').focus()
      }
    },

    clearStaged () {
      if (this.staging) {
        this.staging = false
        this.search = ''
        this.readOnly = false
        this.staged = {}
        this.datePickerDate = ''
        this.search = ''
        this.getShownFields('')
      }
    },

    updateDate (obj) {
      const isDateTime = this.staged.type === 'DateTime'
      this.staged.value = isDateTime ? obj.dateTime : obj.value
      this.staged.text = obj.value
      this.staged.hint = obj.hint
      this.search = this.search.split(':')[0] + ':' + this.staged.value
      this.submitStaged()
    },

    editDate (item, index, obj) {
      const isDateTime = item.type === 'DateTime'
      item.value = isDateTime ? obj.dateTime : obj.value
      item.text = item.value
      item.hint = obj.hint
      this.selected.splice(index, 1, item)
    },

    editPrice (item, index, event) {
      item.value = event ? GetDjangoMoneyCompatibleInput(this.$ci.parse(`${event}`)) : ''
      item.text = event
      index !== -1 && this.selected.splice(index, 1, item)
    },

    updateStaged (text) {
      this.staged.value = text
      this.staged.text = text
    },

    // updateItemBoolean (item, event) {
    //   item.value = event
    // },

    // type setup
    processType (type) {
      if (type === 'Date') {
        this.prepDatePicker()
      }
    },

    prepDatePicker () {
      this.datePickerConfig.label = this.staged.name
      this.hint = 'Click below to enter a date!'
    }

  },
  mounted () {
  }
}
</script>

<style scoped>
  .field-container {
    overflow-y: auto;
    min-width: 250px;
    max-height: 230px;
    min-height: 250px;
  }

  .clickable {
    cursor: pointer;
  }
</style>
