<template>
  <v-text-field
    v-currency
    v-bind="$props"
    :value="value"
    :label="label"
    @input="$emit('input', getBackendCompatibleCurrency($event))"
    @keyup.enter="$emit('enter')"
  />
</template>

<script>
import { GetDjangoMoneyCompatibleInput } from '@/lib/moneyHelpers'
export default {
  name: 'Currency',
  props: ['label', 'value', 'error-messages', 'box', 'solo', 'outline', 'autofocus'],
  data () {
    return {
      // backup currency thingy formatter thingy
      // add this as an attribute
      // :rules="[rules.required, rules.money]"
      // rules: {
      //   required: value => !!value || 0,
      //   money: value => {
      //     const pattern = /^([0-9]*)?([.,][0-9]{0,2})?$/
      //     return pattern.test(value) || 'Invalid Format (123.45)'
      //   }
      // }

      currencyOptions: {
        currency: this.$store.state.profile.currency || 'USD',
        locale: this.getLanguage(),
        precision: 2,
        allowNegative: false,
        'distraction-free': false
      }
    }
  },
  methods: {
    getLanguage () {
      if (navigator.languages !== undefined) {
        return navigator.languages[0]
      } else return navigator.language
    },

    getBackendCompatibleCurrency (event) {
      if (event) {
        const money = this.$ci.parse(event)
        return money
        // console.log(money, event, GetDjangoMoneyCompatibleInput(money))
        // return GetDjangoMoneyCompatibleInput(money)
      } return ''
    }
  }
}
</script>

<style scoped>

</style>
