<template>
   <master-details-row
     :params="params"
   />
</template>

<script>
import Vue from 'vue'
import masterDetailsRow from '@/components/renderers/masterDetailsRow'
export default Vue.extend({
  name: 'masterDetailsRenderer',
  components: {
    'master-details-row': masterDetailsRow
  }
})
</script>

<style scoped>

</style>
