import gql from 'graphql-tag'

/* FRAGMENTS */

export const FRAGMENT__ADDRESS__LIST = gql`
    fragment AddressList on Address_Address {
      building_name
      client { id name short_name }
      company { name }
      city
      easypost_id
      id
      state
      street_1
    }
`

export const FRAGMENT__ADDRESS__GRID = gql`
    fragment AddressGrid on Address_Address {
      ...AddressList
      country {
        name
        code
      }
      default_contact {
        id
        full_name
      }
      street_2
      zip_code
    }
    ${FRAGMENT__ADDRESS__LIST}
`

export const FRAGMENT__ADDRESS__FULL = gql`
    fragment AddressFull on Address_Address {
      ...AddressGrid
      carrier_facility
      creation_date
      creator { id initials }
      default_billing_for_client {
        id
      }
      default_billing_for_company {
        id
      }
      default_remit_for_client {
        id
      }
      default_remit_for_company {
        id
      }
      default_return_to_for_client {
        id
      }
      default_remit_for_company {
        id
      }
      default_ship_from_for_client {
        id
      }
      default_ship_from_for_company {
        id
      }
      default_ship_to_for_client {
        id
      }
      default_ship_to_for_company {
        id
      }
      residential
      updated_date
    }
    ${FRAGMENT__ADDRESS__GRID}
`

/* FRAGMENT QUERIES */

export const GET_ADDRESSES__LIST = gql`
  query GetAddress_List ($input: ManyQueryInput!) {
    address_addresses (input: $input) {
        ...AddressList
    }
  }
  ${FRAGMENT__ADDRESS__LIST}
`

export const GET_ADDRESSES__GRID = gql`
  query GetAddress_Grid ($input: ManyQueryInput!) {
    address_addresses (input: $input) {
      ...AddressGrid
    }
  }
  ${FRAGMENT__ADDRESS__GRID}
`

export const GET_ADDRESSES__FULL = gql`
  query GetAddress_Full ($input: ManyQueryInput!) {
    address_addresses (input: $input) {
      ...AddressFull
    }
  }
  ${FRAGMENT__ADDRESS__FULL}
`

/* COMPLEX QUERIES */ // The goal here is to try to limit these as much as possible
export const GET_STANDARD_ADDRESSES = gql`
    query StandardAddresses ($client: Any!) {
      clientAddresses: address_addresses (input: { filters: [ { key: "client__id", value: $client }]}) {
        ...AddressList
      }
      companyAddresses: address_addresses (input: { filters: [{ key: "company__isnull", value: false }]}) {
        ...AddressList
      }
      company_company {
        default: default_ship_from_address {
          ...AddressList
        }
      }
    }
    ${FRAGMENT__ADDRESS__LIST}
`

/* MUTATIONS */

export const VERIFY_ADDRESS = gql`
  mutation VerifyAddress ($input: EasyPost_MakeAddressInput!) {
    verified: EasyPost_MakeAddress (input: $input) {
      id
      residential
      phone
      name
      company
      street1
      city
      state
      country
      verifications {
        delivery {
          errors {
            field
            message
          }
          success
        }
        zip4 {
          errors {
            field
            message
          }
          success
        }
      }
      street2
      zip
    }
  }
`

export const CREATE_ADDRESS = gql`
  mutation CreateAddress ($input: Create__Address_Address__Input!) {
    Create__Address_Address (input: $input) {
      ...AddressFull
    }
  }
  ${FRAGMENT__ADDRESS__FULL}
`

export const DELETE_ADDRESS = gql`
    mutation DeleteAddress ($id: ID!) {
      Delete__Address_Address (id: $id) { id }
    }
`

export const UPDATE_ADDRESS = gql`
  mutation UpdateAddress ($input: Update__Address_Address__Input!) {
    address: Update__Address_Address (input: $input) {
      ...AddressFull
    }
  }
  ${FRAGMENT__ADDRESS__FULL}
`
