<template>
  <v-container pa-0 ma-0>
    <v-autocomplete
      data-cy="google-places"
      ref="autocomplete"
      :search-input.sync="search"
      autofocus
      attach
      :items="predictions"
      :success="success"
      :label="config.label"
      :loading="loadingPredictions"
      :hint="error ? error : `Press<span class='emphasis'>enter</span> to search`"
      clearable
      hide-no-data
      item-text="description"
      item-value="placeId"
      no-filter
      :error-messages="error"
      :menu-props="{ 'offset-overflow': true, top: true }"
      @keyup.enter="getPredictions"
      @click:clear="clear"
      @input="getPlaceById"
    >
      <template #message="{ key, message }">
        <span :key="key" v-html="message"></span>
      </template>
      <template #prepend>
        <slot name="prepend">
          <v-btn small icon @click="$emit('toggleShow')">
            <v-icon color="primary">fal fa-keyboard</v-icon>
          </v-btn>
        </slot>
      </template>
      <template #item="{ item }">
        <v-icon class="mr-2">far fa-map-marker-alt</v-icon>
        <v-list-tile-content>
          <v-list-tile-title style="font-size: 14px;">{{ item.description }}</v-list-tile-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
  </v-container>
</template>

<script>
export default {
  name: 'places',
  props: {
    config: {
      type: Object,
      required: true
    },
    addressConfig: {
      type: Object,
      required: true
    },
    success: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    allFilled () {
      // for (const key in this.place) {
      //   if (this.place[key] === '' && key !== 'subPremise') return false
      // }
      return true
    }
  },
  watch: {
    search: function (n, o) {
      if (n !== o && this.success) {
        this.$emit('update-success', { value: false })
      }
    }
  },
  data () {
    return {
      error: '',
      search: '',
      place: {
        streetNumber: '',
        subPremise: '',
        route: '',
        locality: '',
        adminLevel1: '',
        country: '',
        postal: ''
      },

      autocompleteService: {},
      placesService: {},
      loadingPredictions: false,
      predictions: []
    }
  },
  methods: {
    clear () {
      this.$emit('clear')
      this.predictions.splice(0, this.predictions.length)
      this.place = {
        street1: '',
        street2: '',
        city: '',
        state: '',
        country: '',
        zip: ''
      }
    },

    getPlacesData (list, type, name) {
      return list.find(e => e.types.includes(type))?.[name] || 'Could not find data'
    },

    receivedPredictions (response, status) {
      this.loadingPredictions = false
      if (status === 'OK') {
        if (response.length > 0) {
          response.forEach(r => this.predictions.push({ description: r.description, placeId: r.place_id }))
        }
      } else if (status === 'ZERO_RESULTS') {
        this.$emit('no-results')
      }
    },

    receivedPlace (response, status) {
      if (status === 'OK') {
        const ac = response.address_components
        const streetLine1Components = []
        const streetLine2Components = []
        const cityComponents = []
        const stateComponents = []
        const politicalComponents = []
        const zipComponents = []
        for (const c of ac) {
          if (c.types[0] === 'subpremise') {
            streetLine2Components.push(`${c.long_name}`)
          } else if (c.types[0] === 'street_number' || c.types[0] === 'route') {
            streetLine1Components.push(`${c.long_name}`)
          } else if (c.types.indexOf('sublocality') > -1) {
            streetLine2Components.push(`${c.long_name}`)
          } else if (c.types[0] === 'postal_town' || c.types[0] === 'locality') {
            cityComponents.push(`${c.long_name}`)
          } else if (c.types[0] === 'administrative_area_level_1' || c.types[0] === 'political') {
            stateComponents.push(`${c.long_name}`)
          } else if (c.types.indexOf('country') > -1) {
            politicalComponents.push(`${c.short_name}`)
          } else if (c.types.indexOf('postal_code') > -1) {
            zipComponents.push(`${c.long_name}`)
          }
        }

        if (politicalComponents.indexOf('United Kingdom') > -1) {
          this.place.street1 = [...streetLine2Components, ...streetLine1Components].join(' ')
        } else {
          this.place.street1 = streetLine1Components.join(' ')
          this.place.street2 = streetLine2Components.join(' ')
        }
        this.place.city = cityComponents.join(' ')
        this.place.state = stateComponents.join(' ')
        this.place.country = politicalComponents.join(' ')
        this.place.zip = zipComponents.join(' ')

        this.$emit('updated', this.place)
      }
    },

    getPlaceById (placeId) {
      if (placeId) {
        this.placesService.getDetails({
          placeId: placeId,
          fields: ['address_components']
        }, this.receivedPlace)
      }
    },

    getPredictions () {
      this.error = ''
      this.clear()
      this.predictions.splice(0, this.predictions.length)
      this.loadingPredictions = true
      this.autocompleteService.getQueryPredictions({
        input: this.search
      }, this.receivedPredictions)
    }

  },
  mounted () {
    // eslint-disable-next-line no-undef
    this.autocompleteService = new google.maps.places.AutocompleteService()
    // eslint-disable-next-line no-undef
    this.placesService = new google.maps.places.PlacesService(document.createElement('div'))
  }
}
</script>

<style scoped>

</style>
