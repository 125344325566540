import gql from 'graphql-tag'

export const FRAGMENT__COUNTRY = gql`
  fragment Country on CountryObject {
    name
    code
    flag: unicode_flag
    numeric
  }
`

/* QUERIES */

export const GET_COUNTRIES = gql`
  query GetCountries {
    countries {
      ...Country
    }
  }
  ${FRAGMENT__COUNTRY}
`

/* MUTATIONS */
