<template>
  <v-card>
    <v-card-title class="pb-0">
      <v-toolbar tabs color="white" data-cy="reporting-toolbar" id="reporting-toolbar">
        <v-toolbar-title class="">Report Management</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="error lighten-2" @click="close">Close</v-btn>
        <template #extension>
          <v-tabs v-model="tabs" centered slider-color="purple">
            <v-tab ripple>Run</v-tab>
            <v-tab ripple>Build</v-tab>
            <v-tab ripple>View</v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
    </v-card-title>
    <v-card-text class="pt-0">
      <v-container grid-list-xl fluid class="pa-0">
          <v-tabs-items v-model="tabs">
            <!-- List -->
            <v-tab-item>
              <reports-list @run="showReports"/>
            </v-tab-item>
            <!-- Builder -->
            <v-tab-item>
              <report-builder></report-builder>
            </v-tab-item>
            <!-- Viewer -->
            <v-tab-item>
              <!-- Can use slots here: c-actions -->
              <report-viewer :report-ids="reports" />
            </v-tab-item>
          </v-tabs-items>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import Builder from '@/views/reporting/Builder'
import Viewer from '@/views/reporting/Viewer'
import Reports from '@/views/reporting/Reports'

export default {
  name: 'ReportContainer',
  components: {
    'report-builder': Builder,
    'report-viewer': Viewer,
    'reports-list': Reports
  },
  watch: {
    '$route': function (value) {
      if (value.query?.reports) {
        this.showReports({ reports: value.query.reports })
      }
    }
  },
  data () {
    return {
      tabs: 0,
      data: {},
      options: {},
      reports: []
    }
  },
  methods: {
    close () {
      this.$emit('close')
      if (this.$route.query?.dialog) {
        this.$router.replace({ name: this.$route.name, query: undefined })
      }
    },

    showReports (event) {
      this.reports = event.reports
      this.tabs = 2
    }
  },
  beforeMount () {
    if (this.$route.query?.dialog && this.$route.query?.reports) {
      this.showReports({ reports: this.$route.query.reports })
    }
  }
}
</script>
