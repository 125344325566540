import { render, staticRenderFns } from "./BetaProgress.vue?vue&type=template&id=f7afa944&scoped=true&"
import script from "./BetaProgress.vue?vue&type=script&lang=ts&"
export * from "./BetaProgress.vue?vue&type=script&lang=ts&"
import style0 from "./BetaProgress.vue?vue&type=style&index=0&id=f7afa944&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7afa944",
  null
  
)

export default component.exports