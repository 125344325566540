// Some comments
import gql from 'graphql-tag'
import { FRAGMENT__ADDRESS__FULL } from '@/api/graphql/Constants/Addresses'
import { FRAGMENT__MONEY } from '@/api/graphql/Constants/TERMS'
import {
  CarriersQuery,
  Create__Shipping_Carrier__Input,
  Create__Shipping_ServiceLevel__Input,
  Create__Shipping_ShipmentOrder__Input,
  CreateShipmentOrderMutation,
  Mutation,
  MutationCreate__Shipping_CarrierArgs,
  MutationCreate__Shipping_ServiceLevelArgs,
  ShipmentOrderDetailsFragment,
  Shipping_Carrier,
  Update__Shipping_ShipmentOrder__Input,
  UpdateShipmentOrderMutation,
  UpdateShipmentOrderMutationVariables
} from '@/models/generated/graphql/ErpBackend'
import { MakeServiceNamePretty } from '@/lib/helpers'
import { apolloClient as apollo } from '@/api/graphql/apollo'
import { MutationOptions } from 'apollo-client'
import { Sentry } from '@/lib/Sentry'
import { RefreshEvents } from '@/store/refreshEvents'
import { FRAGMENT__USERS_FULL } from '@/api/graphql/Constants/Users'
import { FRAGMENT__CONTACT__FULL } from '@/api/graphql/Constants/Contacts'

/* FRAGMENTS */

export const FRAGMENT__SHIPPING_SHIPMENT__FULL = gql`
  fragment Shipping_Shippment_Full on Shipping_Shipment {
    id
    box_number
    easypost_id
    index: box_number
    predefined: predefined_parcel
    length: parcel_length_in
    width: parcel_width_in
    height: parcel_height_in
    weight: parcel_weight_oz

  }
`

export const FRAGMENT__SHIPMENT_ORDER_DETAILS_FULL = gql`
 fragment ShipmentOrderDetails_Full on Shipping_ShipmentOrder {
   id
   account {
     id
     name
     number
     shipper {
       id
       name
     }
     easypost_id
   }
   assignee: assigned_to {
     id
     full_name
     initials
   }
   sale: attached_sale {
     id
     comments: internal_comment
   }
   blind
   deliverBy: deliver_by_date
   easypost_id
   isInternational: is_international
   billTo: purchaser_address {
     ...AddressFull
   }
   billToContact: purchaser_contact {
     id
     full_name
     email
     phone
   }
   pickup_type
   release: release_number
   returnTo: return_address {
     ...AddressFull
   }
   service {
     id
     easypost_name
     service
     shipper {
       id
       name
     }
   }
   shipFrom: ship_from_address {
     ...AddressFull
   }
   shipFromContact: ship_from_contact {
     id
     full_name
     email
     phone
   }
   shipTo: ship_to_address {
     ...AddressFull
   }
   shipToContact: ship_to_contact {
     id
     full_name
     email
     phone
   }
   shipments {
     ...Shipping_Shippment_Full
   }
   comments: shipping_comments
   status {
     id
     status
   }
   logs: system_logs {
     id
     action {
       id
       action
       description
     }
   }
   tracking: tracking_number
   wePay: we_pay
 }
 ${FRAGMENT__ADDRESS__FULL}
 ${FRAGMENT__SHIPPING_SHIPMENT__FULL}
`

export const FRAGMENT__SHIPMENT_ORDER_DETAILS = gql`
  fragment ShipmentOrderDetails on Shipping_ShipmentOrder {
    id
    purchase: attached_purchase {
      id
      client {
        id
      }
    }
    sale: attached_sale {
      id
      client {
        id
      }
    }
    account {
      id
      name
      number
    }
    blind
    deliver_by_date
    service {
      id
      shipper {
        id
        name
      }
      service
    }
    shipFrom: ship_from_address {
      ...AddressFull
    }
    shipFromRep: ship_from_rep { id full_name email }
    shipFromContact: ship_from_contact {
      id
      phone
      email
      full_name
    }
    shipToRep: ship_to_rep { id full_name email }
    shipTo: ship_to_address {
      ...AddressFull
    }
    shipToContact: ship_to_contact {
      id
      phone
      email
      full_name
    }
    billToRep: purchaser_rep { id full_name email }
    billTo: purchaser_address {
      ...AddressFull
    }
    billToContact: purchaser_contact {
      id
      phone
      email
      full_name
    }
    cost: total_billed_cost { ...Money }
    status {
      id
      status
    }
    tracking_number
    pickupType: pickup_type
    we_pay
  }
  ${FRAGMENT__ADDRESS__FULL}
  ${FRAGMENT__MONEY}
`

export const FRAGMENT__SHIPMENT_ORDER__LIST = gql`
    fragment ShipmentOrderForList on Shipping_ShipmentOrder {
      id
      status { id status order }
      sale: attached_sale {
        id
        rep { id initials }
      }
      prma: attached_purchase_rma {
        id
        order: pt {
          id
          rep { id initials }
        }
      }
      srma: attached_rma {
        id
        order: st {
          id
          rep { id initials }
        }
      }
      assignee: assigned_to {
        id
        initials
        profile { id color }
      }
      isInternational: is_international
      service {
        id
        color
        service
        carrier: shipper { id name }
      }
      pickup_type
    }
`

export const FRAGMENT__CARRIER_ACCOUNT__FULL = gql`
    fragment CarrierAccountFull on Shipping_Account {
      id
      description
      easypost_id
      default_shipping_account_for_client {
        id
      }
      client {
        id
        name
      }
      name
      number
      shipper {
        id
        name
      }
    }
`

export const FRAGMENT__SHIPMENT_ORDER_TO_EDIT = gql`
  fragment ShipmentOrderToEdit on Shipping_ShipmentOrder {
    id
    service { id service }
    account { id }
    ship_to_address { ...AddressFull }
    ship_to_contact { ...Contact_Full }
    ship_to_rep { ...FullUser }
    ship_from_address { ...AddressFull }
    ship_from_contact { ...Contact_Full }
    ship_from_rep { ...FullUser }
    purchaser_address { ...AddressFull }
    purchaser_contact { ...Contact_Full }
    purchaser_rep { ...FullUser }
    blind
    tracking_email_recipients
    pickup_type
    we_pay
  }
  ${FRAGMENT__USERS_FULL}
  ${FRAGMENT__ADDRESS__FULL}
  ${FRAGMENT__CONTACT__FULL}
`

/* QUERIES */

export const GET_SHIPPING_SERVICE_LEVELS = gql`
  query GetShippingServiceLevels {
    shipping_service_levels (input: { limit: 100000 }) {
      id
      color
      service
      easypost_name
      order_arrive_by
      order_ready_by
      shipper {
        id
        name
        easypost_name
      }
    }
  }
`

export const GET_SHIPMENT_ORDER_DETAILS__FULL = gql`
  query ShipmentOrderDetails_Full ($id: ID!) {
    order: shipping_shipment_order (input: { id: $id }) {
      ...ShipmentOrderDetails_Full
      }
    }
  ${FRAGMENT__SHIPMENT_ORDER_DETAILS_FULL}
`

export const GET_SHIPMENTS_ON_SHIPPING_ORDER = gql`
  query GetShipmentsOnShippingOrder ($id: Any!) {
    shipping_shipments (input: { filters: [{ key: "order__id", value: $id }]}) {
      ...Shipping_Shippment_Full
    }
  }
  ${FRAGMENT__SHIPPING_SHIPMENT__FULL}
`

export const GET_SHIPMENT_ORDER_DETAILS = gql`
  query ShipmentOrder ($id: ID!) {
    order: shipping_shipment_order (input: { id: $id }) {
      ...ShipmentOrderDetails
    }
  }
  ${FRAGMENT__SHIPMENT_ORDER_DETAILS}
`

export const GET_SHIPMENT_ORDERS = gql`
    query ShipmentOrdersList ($input: ManyQueryInput!) {
      shipments: shipping_shipment_orders (input: $input) {
        ...ShipmentOrderForList
        annotations { name value }
      }
    }
  ${FRAGMENT__SHIPMENT_ORDER__LIST}
`

export const GET_CARRIER_ACCOUNTS = gql`
  query GetCarrierAccounts ($input: ManyQueryInput!) {
    carrier_accounts: shipping_accounts (input: $input) {
      id
      description
      easypost_id
      default_shipping_account_for_client {
        id
      }
      client {
        id
        name
      }
      name
      number
      shipper {
        id
        name
      }
    }
  }
`
export const GET_CARRIER_ACCOUNTS__AUTOFILL = gql`
    query GetCarrierAccountsForAutofill ($filters: [QueryFilter!]) {
      shipping_accounts (input: { filters: $filters }) {
        id
        easypost_id
        title: name
        number
        shipper {
          id
          name
        }
      }
    }
`

export const GET_COMPANY_CARRIER_ACCOUNTS_WITH_NUMBER__AUTOFILL = gql`
    query GetCompanyCarrierAccountsWithNumberForAutofill {
      accounts: shipping_accounts (input: { filters: [{ key: "client__isnull", value: true }]}) {
        id
        title: name
        subtitle: number
        shipper {
          id
          name
        }
        easypost_id
      }
    }
`

export const CLIENT_AND_COMPANY_CARRIER_ACCOUNTS = gql`
  query ClientAndCompanyCarrierAccounts ($id: Any!) {
    accounts: shipping_accounts (input: { filters: [
      {
        key: "client__id",
        value: $id,
        or: {
          key: "client__isnull",
          value: true
        }
      }
    ]}) {
      id
      easypost_id
      client {
        id
        name
      }
      name
      number
      shipper {
        id
        name
      }
    }
  }
`

export const GET_CLIENT_CARRIER_ACCOUNTS_WITH_NUMBER__AUTOFILL = gql`
  query GetClientCarrierAccountsWithNumberForAutofill ($id: Any!) {
    accounts: shipping_accounts (input: { filters: [{ key: "client__id", value: $id }]}) {
      id
      title: name
      subtitle: number
      shipper {
        id
        name
      }
      easypost_id
    }
  }
`

export const GET_ALL_CARRIERS__ALIASED = gql`
  query CarriersAliases {
    carriers: shipping_carriers (input: { limit: 1000 }) {
      id
      title: name
    }
  }
`

export const GET_ALL_CARRIERS = gql`
    query Carriers {
      shipping_carriers (input: { limit: 10000 }) {
        id
        name
        is_courier
      }
    }
`

/* MUTATIONS */

export const CREATE_SHIPMENT_ORDER = gql`
  mutation CreateShipmentOrder ($input: Create__Shipping_ShipmentOrder__Input!) {
    order: Create__Shipping_ShipmentOrder (input: $input) {
      ...ShipmentOrderDetails
    }
  }
  ${FRAGMENT__SHIPMENT_ORDER_DETAILS}
`

export const DELETE_SHIPMENT_ORDER = gql`
  mutation DeleteShipmentOrder ($id: ID!) {
    Delete__Shipping_ShipmentOrder (id: $id) { id }
  }
`

export const DELETE_SHIPMENT = gql`
  mutation DeleteShipment ($id: ID!) {
    Delete__Shipping_Shipment (id: $id) { id }
  }
`

export const EDIT_SERVICE_COLOR = gql`
  mutation EditServiceColor ($id: ID!, $color: String) {
    service: Update__Shipping_ServiceLevel (input: { id: $id, color: $color }) {
      id
      easypost_name
      color
      order_arrive_by
      order_ready_by
      service
      shipper {
        id
        name
        easypost_name
      }
    }
  }
`

export const UPDATE_SHIPMENT_ORDER = gql`
  mutation UpdateShipmentOrder ($input: Update__Shipping_ShipmentOrder__Input!) {
    order: Update__Shipping_ShipmentOrder (input: $input) {
      ...ShipmentOrderDetails
    }
  }
  ${FRAGMENT__SHIPMENT_ORDER_DETAILS}
`

export const CREATE_CARRIER_ACCOUNT = gql`
  mutation CreateCarrierAccount ($input: Create__Shipping_Account__Input!) {
    Create__Shipping_Account (input: $input) {
        ...CarrierAccountFull
    }
  }
  ${FRAGMENT__CARRIER_ACCOUNT__FULL}
`

export const DELETE_CARRIER_ACCOUNT = gql`
    mutation DeleteCarrierAccount ($id: ID!) {
      Delete__Shipping_Account (id: $id) { id }
    }
`

/* HELPERS */
export async function GetAllSavedCarrierNameAndIds (): Promise<{ name: string, id: string }[]> {
  try {
    const response = await apollo.query<CarriersQuery>({ query: GET_ALL_CARRIERS })
    return response.data.shipping_carriers?.map(c => ({ name: c.name, id: c.id })) ?? []
  } catch (e) {
    throw new Error(e.message)
  }
}

export async function AddCarrierServicesFromCarrier (carrier: Shipping_Carrier): Promise<void> {
  const json = require('@/components/settings/service_levels.json')
  const services = json[carrier.name] ?? []
  const promises = []
  if (services.length === 0) return // no services to add
  for (const service of services) {
    const input: Create__Shipping_ServiceLevel__Input = {
      service: MakeServiceNamePretty(service, carrier.name),
      shipper_id: carrier.id,
      color: '#000',
      easypost_name: service
    }
    const promise = apollo.mutate<Mutation, MutationCreate__Shipping_ServiceLevelArgs>({
      mutation: gql`
          mutation CreateCarrierService ($input: Create__Shipping_ServiceLevel__Input!) {
            Create__Shipping_ServiceLevel (input: $input) {
              id
              service
              shipper { id name }
              color
            }
          }
        `,
      variables: { input: input }
    }).catch(() => { throw new Error('Could not add service from carrier. Contact support to fix.') })
    promises.push(promise)
  }
  await Promise.all(promises).then(() => {
    RefreshEvents.$emit('refresh-carrier-services')
  })
}

export async function CreateCarrier (input: Create__Shipping_Carrier__Input) {
  const response = await apollo.mutate<Mutation, MutationCreate__Shipping_CarrierArgs>({
    mutation: gql`
        mutation CreateCarrier ($input: Create__Shipping_Carrier__Input!) {
          Create__Shipping_Carrier (input: $input) {
            id
            name
          }
        }
      `,
    variables: {
      input: input
    }
  })
  const carrier = response.data?.Create__Shipping_Carrier
  if (carrier && !input.is_courier) {
    AddCarrierServicesFromCarrier(carrier).catch(e => Sentry.captureEvent(e)) // do it in the background without making them wait
  }
  return carrier
}

export async function UpdateShipmentOrder (input: Update__Shipping_ShipmentOrder__Input): Promise<ShipmentOrderDetailsFragment | undefined> {
  try {
    const mutation = {
      mutation: UPDATE_SHIPMENT_ORDER,
      variables: { input: input }
    }
    const response = await apollo.mutate<UpdateShipmentOrderMutation, UpdateShipmentOrderMutationVariables>(mutation)
    if (response.data?.order) return response.data.order
  } catch (error) {
    throw new Error(error.message)
  }
}

export async function CreateShipmentOrder (input: Create__Shipping_ShipmentOrder__Input): Promise<ShipmentOrderDetailsFragment | undefined> {
  try {
    const mutation: MutationOptions = {
      mutation: CREATE_SHIPMENT_ORDER,
      variables: { input: input }
    }
    const response = await apollo.mutate<CreateShipmentOrderMutation, Create__Shipping_ShipmentOrder__Input>(mutation)
    if (response.data?.order) return response.data.order
  } catch (error) {
    throw new Error(error.message)
  }
}
