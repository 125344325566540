<template>
  <v-autocomplete
    v-model="selected"
    attach
    :items="items"
    ref="autocomplete"
    :loading="loading"
    :id="config.name"
    :filter="filter"
    :label="config.label || label"
    :placeholder="config.placeholder"
    :disabled="disabled"
    :hint="config.hint"
    :error-messages="error"
    :persistent-hint="showHint"
    item-text="title"
    item-value="id"
    :autofocus="config.focus"
    :clearable="config.clearable"
    autocomplete="chrome-off"
  >
    <template v-slot:message="{ message, key }">
      <span :key="key" v-html="message"/>
    </template>
    <template v-slot:item="{ item }">
      <slot :data-cy="item.title" name="avatar" v-bind:item="item"/>
      <v-list-tile-content>
        <v-list-tile-title>{{ item.title }}</v-list-tile-title>
        <v-list-tile-sub-title class="text--primary" v-if="item.primary"> {{ item.primary }}</v-list-tile-sub-title>
        <v-list-tile-sub-title>{{ item.subtitle }} {{ item.sub2title ? '    |    ' + item.sub2title : '' }}
          {{ item.sub3title ? '     |     ' + item.sub3title : '' }}
        </v-list-tile-sub-title>
      </v-list-tile-content>
    </template>
    <template v-slot:prepend-item>
      <slot name="creator" v-bind:autocompleteRef="autocompleteRef"/>
    </template>
    <template v-slot:no-data>
      <slot name="no-data">
        <v-list-tile>
          <v-list-tile-title>No data available</v-list-tile-title>
        </v-list-tile>
      </slot>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'standard',
  props: {
    config: {
      type: Object,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    id: {
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    load: {
      type: Boolean,
      required: false
    },
    error: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    autoUpdateOnSingle: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    selected: {
      get () {
        return this.id
      },
      set (value) {
        if (value !== 0) this.$emit('updated', { key: this.config.name, value: value || 0 })
      }
    },

    loading () {
      return (this.load ? this.load : this.$store.state.data.loading[this.config.name]) && this.items?.length === 0
    },

    showHint () {
      return this.config.hint && !this.error
    }
  },
  watch: {
    items: function (value) {
      this.itemsWatchHandler(value)
    }
  },
  data () {
    return {
      autocompleteRef: null
    }
  },
  methods: {
    filter (item, queryText) {
      const title = item.title.toLowerCase()
      const subtitle = item.subtitle ? item.subtitle.toLowerCase() : ''
      const search = queryText.toLowerCase()

      return title.indexOf(search) > -1 || subtitle.indexOf(search) > -1
    },

    itemsWatchHandler (value) {
      if (value) {
        if (value?.length === 1) {
          if (this.autoUpdateOnSingle) this.selected = value[0].id
        } else {
          const index = value.findIndex(i => {
            return i.id === this.selected
          })
          if (index === -1) {
            this.selected = 0
          }
        }
      }
    }
  },
  mounted () {
    this.autocompleteRef = this.$refs.autocomplete
    this.itemsWatchHandler(this.items)
  }
}
</script>
