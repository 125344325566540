// vuex store for data related to purchases

const state = {
  account: null,
  billTo: 0,
  blindClient: {},
  blindContact: {},
  client: {},
  condition: 0,
  contact: {},
  contractComment: '',
  continueId: 0,
  deliverBy: '',
  dropShip: 'NOT_BLIND',
  internalComment: '',
  parts: [],
  priority: '',
  quote: 0,
  releaseNumber: '',
  rep: 0,
  shipFrom: 0,
  shipTo: 0,
  shippingService: null,
  localPickup: 'NOT_PICKUP',
  terms: 0,
  wePay: true
}

const mutations = {
  /**
   * Updating any part of a purchase
   * @param state
   * @param payload
   */
  update (state, payload) {
    state[payload.key] = payload.value
  },

  /**
   * Used to align cached purchase data with vuex sale object
   * @param state
   * @param {Object} payload
   */
  load (state, payload) {
    for (const [key, value] of Object.entries(payload)) {
      if (value && value.id) {
        state[key] = value.id
      } else if (key !== 'id') {
        state[key] = value
      }
    }
  },

  /**
   * Mutation to reset the purchase object
   * @param state
   */
  reset (state) {
    state.account = null
    state.billTo = 0
    state.blindClient = {}
    state.blindContact = {}
    state.client = {}
    state.contact = {}
    state.contractComment = ''
    state.continueId = {}
    state.deliverBy = ''
    state.dropShip = 'NOT_BLIND'
    state.internalComment = ''
    state.loadedFromCache = false
    state.localPickup = 'NOT_PICKUP'
    state.parts = []
    state.priority = ''
    state.quote = 0
    state.rep = 0
    state.shipFrom = 0
    state.shipTo = 0
    state.shippingService = null
    state.terms = 0
    state.wePay = true
  }
}

const actions = {
  /**
   * Action to update any part of a purchase
   * @param commit
   * @param payload
   * @param state
   */
  update ({ commit, state }, payload) {
    commit('update', payload)
  },

  /**
   * Action to load a cached purchase into processing
   * @param commit
   * @param dispatch
   * @param {Object} payload
   * @param {Object} payload.order
   */
  load ({ commit, dispatch }, payload) {
    commit('load', payload)
    dispatch('start')
  },

  /**
   * Action to reset the purchase object
   * @param commit
   */
  reset ({ commit }) {
    commit('reset')
  },

  /**
   * Bring up the purchase order form
   * @param commit
   * @param payload
   */
  start ({ dispatch }, payload) {
    dispatch('grid/changeSheet', { app: 'topbar', component: 'newPurchase' }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
