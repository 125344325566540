<template>
  <v-autocomplete
    v-model="localType"
    label="Part Classification"
    item-text="name"
    item-value="name"
    data-cy="part-type"
    auto-select-first
    :items="types"
    :error-messages="errors"
  >
    <template
      slot="item"
      slot-scope="data"
    >
      <v-list-tile-content>
        <v-list-tile-title>{{ data.item.name }}</v-list-tile-title>
      </v-list-tile-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'partCategories',
  props: {
    type: {
      required: false,
      type: String,
      default: undefined
    },
    errors: {
      required: false,
      type: String,
      default: ''
    },
    types: {
      type: Array,
      required: false,
      default: () => {
        return [
          { name: 'Access Point' },
          { name: 'Battery' },
          { name: 'Bezel' },
          { name: 'Bracket' },
          { name: 'Cable' },
          { name: 'Cache' },
          { name: 'CPU' },
          { name: 'Disk Drive' },
          { name: 'Expansion Card' },
          { name: 'Fan' },
          { name: 'GPU' },
          { name: 'Heat Sink' },
          { name: 'IO Module' },
          { name: 'Memory' },
          { name: 'Miscellaneous' },
          { name: 'Motherboard' },
          { name: 'Network Card' },
          { name: 'IP Phone' },
          { name: 'Power Supply' },
          { name: 'Rails' },
          { name: 'Router' },
          { name: 'Server' },
          { name: 'Storage' },
          { name: 'Storage Enclosure' },
          { name: 'Supplies' },
          { name: 'Switch' }
        ]
      }
    }
  },
  computed: {
    localType: {
      get () {
        return this.type === undefined ? '' : this.type
      },
      set (value) {
        this.$emit('updated', { value: value })
      }
    }
  }
}
</script>
