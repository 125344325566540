import gql from 'graphql-tag'

/* FRAGMENTS */

export const FRAGMENT__ROLE = gql`
  fragment Role on CustomPermissions_Role {
    id
    name
    description
  }
`

export const FRAGMENT__DEPARTMENT = gql`
  fragment Department on Department_Department {
    id
    name
    description
  }
`

export const FRAGMENT__GQL_PERMISSION = gql`
  fragment GraphQlPermissions on CustomPermissions_GraphQLPermissions {
    id
    name
    name_type
    roles {
      id
      role {
        ...Role
      }
    }
  }
  ${FRAGMENT__ROLE}
`

export const FRAGMENT__ROLE_PERMISSION = gql`
  fragment RolePermission on CustomPermissions_RolePermissions {
    id
    permission { ...GraphQlPermissions }
    role { ...Role }
  }
  ${FRAGMENT__GQL_PERMISSION}
  ${FRAGMENT__ROLE}
`

export const FRAGMENT__USER__GRID = gql`
  fragment User_Grid on Users_User {
    id
    full_name
    first_name
    last_name
    last_login
    email
    is_active
    profile {
      id
      commission_percentage
    }
  }
`

/* HELPERS */

/* QUERIES */
export const GET_GQL_PERMISSIONS = gql`
  query GetGqlPermissions ($input: ManyQueryInput!) {
    custom_permissions_graphql_permissions (input: $input) {
      ...GraphQlPermissions
    }
  }
  ${FRAGMENT__GQL_PERMISSION}
`

export const GET_ROLES_FOR_PERMISSIONS = gql`
  query GetRolesForPermissions ($input: ManyQueryInput!) {
    custom_permissions_roles (input: $input) {
      ...Role
    }
  }
  ${FRAGMENT__ROLE}
`

export const GET_USERS_FOR_PERMISSIONS = gql`
  query GetUsersForPermissions ($input: ManyQueryInput!) {
    users_users (input: $input) {
      id
      full_name
      first_name
      last_name
      last_login
      email
      profile {
        id
        commission_percentage
      }
      departments_m2m {
        id
        department {
          id
          description
          name
        }
      }
    }
  }
`

export const GET_USERS__GRID = gql`
  query GetUsers_Grid ($input: ManyQueryInput!) {
    users_users (input: $input) {
      ...User_Grid
    }
  }
  ${FRAGMENT__USER__GRID}
`

export const GET_DEPARTMENTS_FOR_PERMISSIONS = gql`
  query GetDepartmentsForPermissions ($input: ManyQueryInput!) {
    department_departments (input: $input) {
      ...Department
    }
  }
  ${FRAGMENT__DEPARTMENT}
`

/* MUTATIONS */
