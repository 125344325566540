<template>
  <v-card style="height: 100%;">
    <v-card-title class="light-blue darken-4">
      <h3 class="white--text">{{ params.data.name.toUpperCase() }}</h3>
    </v-card-title>
    <v-card-text class="pt-0">
      <v-container fluid pt-2 pb-2>
        <v-layout row nowrap justify-start align-center>
          <v-flex d-flex v-for="grid in subGrids" :key="grid.name"
                  style="text-overflow: ellipsis; overflow-x: hidden; white-space: nowrap;"
          >
            <v-btn
              small
              @click="setActive(grid)"
              color="light-blue darken-4"
              class="white--text"
              :flat="!isActive(grid.tab)"
            >{{ grid.name }}
            </v-btn>
          </v-flex>
          <!-- Refetch Button -->
          <v-flex d-flex shrink>
            <v-btn
              small
              :loading="$apollo.queries.clientDetails.loading"
              @click="refetch()"
            >
              <v-icon small color="light-blue darken-4">fal fa-sync</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
      <v-container grid-list-xl fluid pa-0 pt-2 style="height: 100%;">
        <v-layout row wrap justify-space-between fill-height>
          <!-- Financial Info / Shipping Info / Sub Part Info -->
          <v-flex pt-2 v-if="component && !isLoading">
            <component :is="component" :client-information="clientDetails"></component>
          </v-flex>
<!--          <v-flex grow v-else-if="isLoading">-->
<!--            <v-progress-circular indeterminate color="primary"></v-progress-circular>-->
<!--          </v-flex>-->
          <!-- Grid -->
          <v-flex v-else-if="!component" pt-2>
            <v-fade-transition>
              <grid-wrapper
                v-if="showGrid"
                style="width: 100%;"
                :class="{ 'pulled-up': alertMessage, 'pulled-down': !alertMessage }"
                :columns="activeGrid.columns"
                :component-name="`client-row-${activeGrid.tab}`"
                :context-menu-items="contextMenuItems"
                :query="activeGrid.query"
                :filter="activeGrid.filters"
                :nested-grid="false"
                @ready="gridReady"
              />
            </v-fade-transition>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
    <v-container pt-0 v-if="alertMessage">
      <t-alert :message="alertMessage" :type="alertType"/>
    </v-container>
    <v-dialog
      v-model="dialog"
    >
      <component :is="dialogComponent" :id="dialogId" @close="dialogComponent = ''"></component>
    </v-dialog>
  </v-card>
</template>

<script>
import Vue from 'vue'
import columns from '../mixins/columns'
import mutateAddresses from '../../api/graphql/mutationsJS/mutateAddresses'
import { GET_ADDRESSES__FULL } from '@/api/graphql/Constants/Addresses'
import { GET_CLIENT_PURCHASES, GET_CLIENT_SALES } from '@/api/graphql/Constants/Orders'
import { GET_CLIENT__FULL, UPDATE_CLIENT } from '@/api/graphql/Constants/Clients'
import tAlert from '@/components/notifications/tAlert'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import { ViewPurchaseTransaction, ViewSaleTransaction } from '@/lib/routerHelper'
import {
  COLUMNS__CLIENT_NESTED_ADDRESSES,
  COLUMNS__CLIENT_NESTED_CONTACTS,
  COLUMNS__CLIENT_NESTED_PURCHASES,
  COLUMNS__CLIENT_NESTED_SALES
} from '@/lib/agGridColumnConfiguration'
import { CONTEXT_MENU__CLIENTS_NESTED } from '@/lib/agGridContextMenuConfigurations'
import ClientShippingSettings from '@/components/cards/ClientShippingSettings'
import { GET_CONTACTS__GRID } from '@/api/graphql/Constants/Contacts'
import ClientFinancialInformation from '@/components/cards/ClientFinancialInformation'
import ClientSubPartInfo from '@/components/cards/ClientSubPartInfo'
import { GridEvents } from '@/store/gridEvents'
import EditContact from '@/components/dialogs/EditContact.vue'
export default Vue.extend({
  name: 'clientDetailsRow',
  mixins: [columns, mutateAddresses],
  components: {
    'client-financial-info': ClientFinancialInformation,
    'grid-wrapper': () => import('@/components/wrappers/gridWrapper'),
    't-alert': tAlert,
    'client-shipping-settings': ClientShippingSettings,
    'client-sub-info': ClientSubPartInfo,
    'edit-contact': EditContact
  },
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  computed: {
    activeGrid () {
      return this.subGrids.find(s => s.tab === this.active)
    },

    dialog () {
      return this.dialogComponent !== ''
    },

    roleCanViewTax () {
      return true
    },

    detailsAreDifferent () {
      for (const key in this.clientDetails) {
        if (this.clientDetails[key] !== this.oldClientDetails[key]) {
          return true
        }
      }
      return false
    },

    clientExemptDetailsPass () {
      return (this.clientDetails.is_tax_exempt && this.clientDetails.tax_exempt_number) || !this.clientDetails.is_tax_exempt
    },

    refresh () {
      return this.$store.state.data.refresh
    },

    isDev () {
      return this.$store.getters['profile/isDev']
    },

    rowData () {
      return this[`${this.active}RowData`]
    },

    colDefs () {
      return this.columnize(this[`${this.active}ColDefs`])
    },

    showAlert () {
      return this.alertMessage !== ''
    },

    columnConfigName () {
      return `Clients-${this.active}TableConfig`
    },

    columnConfig () {
      return this.$store.getters['profile/tableConfig'](this.columnConfigName)
    }
  },
  watch: {
    refresh: function (value) {
      value ? this.gridOptions.api.showLoadingOverlay() : this.gridOptions.api.hideOverlay()
    },

    active: function (value) {
      if (this.$route.fullPath.indexOf(value) === -1) {
        this.$router.push({ name: this.$route.name, params: { id: this.params.data.id, nested: value } })
      }
    }
  },
  data () {
    return {
      subGrids: [
        {
          name: 'Addresses',
          tab: 'addresses',
          query: GET_ADDRESSES__FULL,
          filters: [
            { key: 'client__id', value: this.params.data.id }
          ],
          columns: COLUMNS__CLIENT_NESTED_ADDRESSES,
          shortcuts: []
        },
        {
          name: 'Alternate Parts',
          tab: 'alternates',
          component: 'client-sub-info'
        },
        {
          name: 'Contacts',
          tab: 'contacts',
          query: GET_CONTACTS__GRID,
          filters: [
            { key: 'owner__id', value: this.params.data.id }
          ],
          columns: COLUMNS__CLIENT_NESTED_CONTACTS,
          shortcuts: []
        },
        {
          name: 'Financial',
          tab: 'financial',
          component: 'client-financial-info'
        },
        {
          name: 'Purchases',
          tab: 'purchases',
          query: GET_CLIENT_PURCHASES,
          filters: [
            { key: 'client__id', value: this.params.data.id }
          ],
          columns: COLUMNS__CLIENT_NESTED_PURCHASES,
          shortcuts: [
            { key: 'v', handler: params => ViewPurchaseTransaction({ id: params.node.data.id }) }
          ]
        },
        {
          name: 'Sales',
          tab: 'sales',
          query: GET_CLIENT_SALES,
          filters: [
            { key: 'client__id', value: this.params.data.id }
          ],
          columns: COLUMNS__CLIENT_NESTED_SALES,
          shortcuts: [
            { key: 'v', handler: params => ViewSaleTransaction({ id: params.node.data.id }) }
          ]
        },
        {
          name: 'Shipping',
          tab: 'shipping',
          component: 'client-shipping-settings'
        }
      ],
      alertMessage: '',
      alertType: 'info',
      lock: true,
      isLoading: false,

      gridApi: null,
      columnApi: null,

      contextMenuItems: params => CONTEXT_MENU__CLIENTS_NESTED(params, this.active, this.contextMenuCallback),

      active: 'addresses',
      savingClientDetails: false,
      clientDetails: {
        id: 0,
        federal_tax_id: '',
        state_tax_id: '',
        tax_exempt_number: '',
        is_tax_exempt: false,
        is_1099: false
      },
      oldClientDetails: {
        id: 0,
        federal_tax_id: '',
        state_tax_id: '',
        tax_exempt_number: '',
        is_tax_exempt: false,
        is_1099: false
      },
      showGrid: true,

      component: '',
      dialogComponent: '',
      dialogId: ''
    }
  },
  apollo: {
    clientDetails: {
      query: GET_CLIENT__FULL,
      variables () {
        return {
          input: {
            id: this.params.data.id
          }
        }
      },
      update (data) {
        Object.assign(this.oldClientDetails, data.client)
        return data.clients_client
      },
      watchLoading (isLoading) {
        this.isLoading = isLoading
      }
    }
  },
  methods: {
    /* DIALOG STUFF */
    prepEditContact (id) {
      this.dialogId = id + ''
      this.dialogComponent = 'edit-contact'
    },

    contextMenuCallback (params) {
      if (params.data && params.dataKey) this[params.dataKey] = params.data
      if (params.dialogToOpen) this.localDialog = params.dialogToOpen
      if (params.functionToRun) this[params.functionToRun](params.params)
    },

    gridKbEventSender (params) {
      GridKbEventHandler(params, [], [])
    },

    isActive (component) {
      return component === this.active
    },

    refetch () {
      try {
        this.gridApi?.purgeServerSideCache()
      } catch {
      }
    },

    gridReady (params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi
    },

    resetClientDetails () {
      for (const key in this.oldClientDetails) {
        this.clientDetails[key] = this.oldClientDetails[key]
      }
      this.lock = true
    },

    saveClientDetails () {
      if (this.clientExemptDetailsPass) {
        this.savingClientDetails = true
        this.$apollo.mutate({
          mutation: UPDATE_CLIENT,
          variables: { input: this.clientDetails }
        }).then(() => {
          this.alertHandler('Updated client details successfully!', 'success', 4000)
        }).catch(() => {
          this.alertHandler('Could not update client data.', 'error', 4000)
        }).finally(() => {
          this.savingClientDetails = false
        })
      } else {
        this.alertHandler('Uncheck Tax Exemption or enter a Tax Exemption Number', 'warning', 4000)
      }
    },

    setActive (subGridConfig) {
      this.showGrid = false
      this.active = subGridConfig.tab
      this.component = subGridConfig.component ?? ''
      setTimeout(() => {
        this.showGrid = true
      }, 200)
    },

    alertTimeout (timeout) {
      if (timeout < 10) timeout = timeout * 1000
      setTimeout(() => {
        this.alertMessage = ''
        this.alertType = 'info'
      }, timeout)
    },

    alertHandler (message, type, timeout) {
      this.alertMessage = message
      this.alertType = type
      if (timeout) {
        this.alertTimeout(timeout)
      }
    },

    onGridReady (params) {
      const detailGridId = 'detail_' + this.rowIndex

      const gridInfo = {
        id: detailGridId,
        api: params.api,
        columnApi: params.columnApi
      }

      this.masterGridApi.addDetailGridInfo(detailGridId, gridInfo)
    }
  },
  mounted () {
    this.rowIndex = this.params.rowIndex
    this.masterGridApi = this.params.api

    const nested = this.$route.params.nested
    if (nested && this.$route.params.id === this.params.data.id) {
      const tab = this.subGrids.find(s => s.tab === nested)
      if (tab) {
        this.setActive(tab.tab)
      } else {
        this.$router.push({ name: this.$route.name, params: { id: this.params.data.id, nested: 'contacts' } })
      }
    } else {
      this.$router.push({ name: this.$route.name, params: { id: this.params.data.id, nested: this.active } })
    }

    GridEvents.$on('refetch-nested-client', () => this.refetch())
  },
  beforeDestroy () {
    const detailGridId = 'detail_' + this.rowIndex

    // ag-Grid is automatically destroyed

    this.masterGridApi.removeDetailGridInfo(detailGridId)
    if (this.$route.params.id || this.$route.params.nested) this.$router.push({ name: this.$route.name })
    GridEvents.$off('refetch-nested-client', () => this.refetch())
  }
})
</script>

<style scoped>

  .pulled-up {
    height: 465px;
  }

  .pulled-down {
    height: 520px;
  }

</style>
