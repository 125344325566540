import { reactive, watch } from '@vue/composition-api'

type alertType = 'success' | 'error' | 'warning' | 'primary' | 'info'

export interface UseTAlertReturn {
  alert: TAlertObject
}

interface TAlertSetterInput {
  message: string,
  type: alertType,
  timeout?: number
}

export interface TAlertObject {
  message: string,
  type: alertType
  timeout: number,
  setter: (event: TAlertSetterInput) => void
}

export function useTAlert (initialMessage?: string): UseTAlertReturn {
  const alert: TAlertObject = reactive({
    message: initialMessage || '',
    type: 'info',
    timeout: 0,
    setter: ({ message, type, timeout = 0 }) => {
      alert.message = message
      alert.type = type
      alert.timeout = timeout
    }
  })

  watch(() => alert.timeout, (v) => {
    if (v) setTimeout(() => alert.message === '', v)
  })

  return { alert }
}
