


































import { defineComponent, provide, computed, ComputedRef, watch } from '@vue/composition-api'
import { ApolloClients, useQuery, useResult } from '@vue/apollo-composable'
import { GET_TERMS__LIST } from '@/api/graphql/Constants/TERMS'
import { apolloClient } from '@/api/graphql/apollo'
import { groupLikeItemsWithGivenKeys } from '@/lib/arrayHelpers'
import { Clients_Terms } from '@/models/generated/graphql/ErpBackend.ts'

export default defineComponent({
  name: 'TermsAutofill',
  props: {
    termId: {
      type: [String, Number],
      required: true
    },
    error: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    attach: {
      type: Boolean,
      required: false,
      default: false
    },
    exclude: {
      type: Array as () => Clients_Terms[],
      required: false,
      default: () => []
    }
  },
  setup (props, { emit }) {
    provide(ApolloClients, {
      default: apolloClient
    })
    const localTermId: ComputedRef<number> = computed({
      get (): number {
        return Number(props.termId)
      },
      set (value): void {
        emit('updated', { value: `${value}` })
      }
    })

    const { result, loading } = useQuery(GET_TERMS__LIST)
    const terms = useResult(result, [], data => {
      return data.clients_terms.filter((c: Clients_Terms) => props.exclude.map(t => `${t.id}`).indexOf(`${c.id}`) === -1)
    })
    watch(terms, value => {
      if (value.length === 1) {
        emit('updated', { value: `${value[0].id}` })
      }
    })

    return {
      localTermId,
      terms,
      loading
    }
  }
})
