import { encode } from 'jwt-simple'

interface UserForFreshDesk {
  firstName: string,
  lastName: string,
  email: string
}
interface FreshDeskPayload {
  name: string,
  email: string,
  exp: number
}

class FreshDeskWidget {
  readonly widget: any
  private isAuthed: boolean
  private isHidden: boolean
  private user: UserForFreshDesk
  private token: string
  private expireTime: number
  readonly key: string = '8a2194102b22946d6f084c8fa643a231'

  constructor (user: UserForFreshDesk, isHidden: boolean = false) {
    // @ts-ignore
    this.widget = window.FreshworksWidget
    this.isHidden = isHidden
    this.user = user
    this.expireTime = this.getAnExpireTime()
    this.token = this.tokenize()
    this.isAuthed = !!this.token
    this.authenticate()
  }

  /* Auth related */

  getAnExpireTime (): number { // in seconds
    return Math.floor(Date.now() / 1000) + 7000 // max time for FreshDesk widget is 2hours
  }

  tokenize (): string {
    const payload: FreshDeskPayload = {
      name: `${this.user.firstName} ${this.user.lastName}`,
      email: this.user.email,
      exp: this.expireTime
    }

    return encode(payload, this.key, 'HS256')
  }

  authenticate () {
    this.widget('authenticate', {
      token: this.token
    })
  }

  reAuthenticate () {
    this.widget('destroy')
    this.expireTime = this.getAnExpireTime()
    this.token = this.tokenize()
    this.widget('boot')
    this.widget('authenticate', {
      token: this.token
    })
  }

  isPassedExpireTime () {
    return this.expireTime < (Date.now() / 1000) + 900 // "subtract" 15 minutes from time remaining to eagerly refresh
  }

  /* Visual */
  open () {
    // eslint-disable-next-line no-undef
    // @ts-ignore
    this.widget('open')
  }

  bump (amount: string = '65px') {
    const w: HTMLElement | null = document.querySelector('#launcher-frame')
    if (w?.style) {
      w.style.marginBottom = amount
    } else {
      setTimeout(() => {
        if (w?.style) w.style.marginBottom = amount
      }, 1000)
    }
  }

  unBump () {
    const w: HTMLElement | null = document.querySelector('#launcher-frame')
    if (w?.style) {
      w.style.marginBottom = '0'
    } else {
      setTimeout(() => {
        if (w?.style) w.style.marginBottom = '0'
      }, 1000)
    }
  }
}

export let freshDeskWidget: null | FreshDeskWidget = null

export function initializeFreshDesk (user: UserForFreshDesk) {
  if (freshDeskWidget === null || user.firstName === undefined) {
    freshDeskWidget = new FreshDeskWidget(user, false)
  } else if (freshDeskWidget.isPassedExpireTime()) {
    freshDeskWidget.reAuthenticate()
  }
}
