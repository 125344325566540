<template>
  <v-card flat hover ripple @click="copy">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-container pa-1 grid-list-md>
          <v-layout column justify-start align-start nowrap v-on="on">
            <v-flex v-if="address.company">
              <span class="orderText bold">{{ address.company }}</span>
            </v-flex>
            <v-flex v-if="address.name">
              <span class="orderText" :class="{ 'bold' : !address.company }">{{ address.name }}</span>
            </v-flex>
            <v-flex>
              <span class="orderText">{{ address.line1 }}</span>
            </v-flex>
            <v-flex>
              <span class="orderText">{{ address.line2 }}</span>
            </v-flex>
            <v-flex>
              <span class="orderText">{{ address.line3 }}</span>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
      <span>Click to copy!</span>
    </v-tooltip>
  </v-card>
</template>

<script>
export default {
  name: 'addresses',
  props: {
    address: {
      type: Object,
      required: true
    }
  },
  methods: {
    copy () {
      const text = `${this.address.line1} \n ${this.address.line2}` +
        `\n ${this.address.line3}`
      const input = document.createElement('input')
      input.setAttribute('value', text)
      document.body.appendChild(input)
      input.select()
      const result = document.execCommand('copy')
      document.body.removeChild(input)
      return result
    }
  }
}
</script>

<style scoped>
  .orderText {
    font-size: 14px;
  }
</style>
