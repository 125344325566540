<template>
  <v-autocomplete
    v-model="localCountry"
    no-filter
    label="Country"
    item-text="name"
    item-value="code"
    auto-select-first
    return-object
    :items="filteredCountries"
    :loading="loading"
    :disabled="disabled"
    :search-input.sync="search"
    browser-autocomplete="chrome-off"
  >
    <template
      v-slot:item="{ item }"
    >
      <v-list-tile-avatar>
        {{ item.flag }}
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title>{{ item.name }}</v-list-tile-title>
        <v-list-tile-sub-title>{{ item.code }}</v-list-tile-sub-title>
      </v-list-tile-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { GET_COUNTRIES } from '@/api/graphql/Constants/Countries'
export default {
  name: 'Countries',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    country: {
      type: String,
      required: true
    }
  },
  computed: {
    localCountry: {
      get () {
        return this.country
      },
      set (value) {
        this.update(value.code)
      }
    },

    loading () {
      return this.countries.length === 0
    },

    filteredCountries () {
      const search = this.search?.toLowerCase() ?? ''
      const found = []

      /* CODE MATCH */
      const matchesCodePerfectly = (code) => {
        if (code === search) {
          return 10
        }
        return 0
      }

      /* LOGIC */
      for (const country of this.countries) {
        let matchValue = 0
        const name = country.name.toLowerCase()
        const code = country.code.toLowerCase()

        if (name.indexOf(search) > -1) matchValue += 1
        matchValue += matchesCodePerfectly(code)

        country.matchValue = matchValue
        matchValue && found.push(country)
      }
      return found.sort((a, b) => b.matchValue - a.matchValue)
    }
  },
  watch: {
    localCountry: function (value) {
      if (this.filteredCountries.length === 1) {
        const country = this.filteredCountries[0]
        const code = country.code
        if (value !== code && value === country.name) {
          this.update(code)
        }
      }
    },
    filteredCountries: function (value) {
      if (value.length === 1) {
        const country = value[0]
        const code = country.code
        if (this.localCountry !== code && this.localCountry === country.name) {
          this.update(code)
        }
      }
    }
  },
  data () {
    return {
      countries: [],
      search: ''
    }
  },
  apollo: {
    countries: {
      query: GET_COUNTRIES
    }
  },
  methods: {
    update (value) {
      this.$emit('updated', { value: value })
    },

    filter (item, query) {
      const search = query.toLowerCase()
      const searchOne = item.code.toLowerCase()
      const searchTwo = item.name.toLowerCase()

      return searchOne.indexOf(search || '') > -1 || searchTwo.indexOf(search || '') > -1
    }
  }
}
</script>

<style scoped>

</style>
