<template>
   <order-details-row :params="params"></order-details-row>
</template>

<script>
import Vue from 'vue'
import orderDetailsRow from '@/components/renderers/orderDetailsRow'
export default Vue.extend({
  name: 'orderDetailRenderer',
  components: {
    'order-details-row': orderDetailsRow
  }
})
</script>
