<template>
  <div>
    <v-card v-if="showReports" flat color="transparent">
      <v-card-title class="py-0">
        <v-toolbar tabs flat color="transparent">
          <v-toolbar-title>
            View Reports
          </v-toolbar-title>
          <v-btn
            flat
            color="error"
            @click="clearReports"
          >CLEAR REPORTS</v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card class="pt-0">
        <v-tabs>
          <v-tab
            v-for="report in reports"
            :key="report.id"
          >
            <span v-if="report.name">
              {{ report.name }}
              <v-btn icon flat @click.stop="removeReport(report.id)"><v-icon color="error">fal fa-times</v-icon></v-btn>
            </span>
            <v-progress-circular v-else indeterminate color="purple"/>
          </v-tab>
          <v-tab-item
            v-for="report in reports"
            :key="report.id"
          >
            <v-scale-transition>
              <chart v-if="report.name" :data="report.data" :type="report.chart_type"/>
              <v-progress-linear v-else indeterminate color="purple"/>
            </v-scale-transition>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-card>
    <v-progress-linear v-else-if="isLoading" class="mt-0" indeterminate color="purple"/>
    <v-container v-else>
      <v-layout wrap justify-space-around align-center>
        <v-flex expand>
          <v-text-field
            v-model="reportUrl"
            label="Report URL/ID"
            hint="Entire URL or Report IDs, Comma Separated"
            solo
            @input="parseText"
            @keyup.enter="validUrl && viewReport()"
          />
        </v-flex>
        <v-fade-transition>
          <v-flex xs12 v-if="errorMessage">
            <b class="error--text">{{ errorMessage }}</b>
          </v-flex>
        </v-fade-transition>
        <v-flex xs12>
          <v-btn
            block
            color="success"
            :disabled="!validUrl"
            @click="viewReport"
          >View </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import Chart from '@/components/Chart'
export default {
  name: 'Viewer',
  components: {
    'chart': Chart
  },
  props: {
    reportIds: {
      type: Array,
      required: true
    }
  },
  watch: {
    reportIds: function (value) {
      this.grabReportData(value)
    }
  },
  data () {
    return {
      reportUrl: '',
      validUrl: false,
      showReports: false,
      errorMessage: '',

      isLoading: false,
      reports: []
      // buttons: [
      //   { name: '+5', icon: '', handler: () => this.showFive(true) },
      //   { name: '-5', icon: '', handler: () => this.showFive(false) },
      //   { name: 'Average', icon: 'fal fa-tachometer-alt-average', iconColor: 'primary', handler: () => this.showAverage() },
      //   { name: 'All', icon: '', handler: () => this.showAll() }
      // ],
    }
  },
  methods: {
    async grabReportData (ids) {
      // try {
      //   this.isLoading = true
      //   // grab the query for the report here
      //   const response = await this.$apollo.query({
      //     query: 'GRAB THE REPORT',
      //     variables: { id: id }
      //   })
      //   if (response.data.Reporting_Report) {
      //     const report = response.data.Reporting_Report
      //     await this.setChart({ data: report.data, type: report.chart_type })
      //   }
      // } catch (e) {
      //   this.errorMessage = e.message
      // } finally {
      //   this.isLoading = false
      // }
      if (this.reportIds.length !== 0) {
        for (const report of this.reportIds) {
          this.reports.push({ id: report, name: '', data: 'isTesting', chart_type: 'line' })
        }
      } else {
        for (let i = 0; i < ids.length; i++) {
          this.reports.push({ id: ids[i], name: '', data: 'isTesting', chart_type: 'line' })
        }
      }
      let i = 0
      const names = [
        'Most Number of Sales',
        'Highest Margin per Sale',
        'Highest Cost per Sale',
        'Highest Cost Total'
      ]
      const interval = setInterval(() => {
        const report = this.reports[i]
        report.name = names[i]
        report.chart_type = 'line'
        i++
        if (i >= this.reports.length - 1) {
          setTimeout(() => clearInterval(interval), 2001)
        }
        if (i > names.length - 1) {
          i = 0
        }
      }, 2000)
      this.showReports = true
    },

    removeReport (id) {
      const index = this.reports.find(r => r.id === id)
      this.reports.splice(index, 1)
      if (this.reports.length === 0) {
        this.clearReports()
      }
    },

    clearReports () {
      this.reports.splice(0, this.reports.length)
      this.showReports = false
      this.validUrl = false
      this.reportUrl = ''
      this.$router.replace({ name: this.$route.name })
    },

    parseText (u) {
      // check to make sure url is legit
      const reportRegex = /(&report=\d+\b)/
      const reportIdRegex = /\b[1-9]+\b/
      // helpers
      function isOrHasValidIds (string) {
        if (string.indexOf(',') === -1) {
          return reportIdRegex.test(u)
        }
        const splitskies = string.split(',').map(s => s.trim())
        if (splitskies.length === 0) return false
        for (const split of splitskies) {
          if (!reportIdRegex.test(split)) return false
        }
        return true
      }
      const isValidUrl = (url) => {
        return url.indexOf('.traqsys.com/') > -1 && url.indexOf('?dialog=reports') > -1 && reportRegex.test(url)
      }

      if (isValidUrl(u) || isOrHasValidIds(u)) {
        this.validUrl = true
        this.errorMessage = ''
      } else {
        if (u) {
          this.errorMessage = 'You did not paste in a valid url or report ID.'
        } else {
          this.errorMessage = ''
        }
        this.validUrl = false
      }
    },

    viewReport () {
      if (this.reportUrl.indexOf('?dialog') > -1) {
        const query = this.reportUrl.substring(this.reportUrl.indexOf('?dialog'))
        this.$router.replace(this.$route.fullPath + query)
        if (this.$route.query?.report) {
          this.grabReportData(this.$route.query.report)
        } else {
          this.errorMessage = 'Make sure to include the "reports=N", where N is a number in the URL'
        }
      } else {
        this.$router.replace({ name: this.$route.name, query: { dialog: 'reports', reports: this.reportUrl.split(',') } })
      }
    }
  },
  mounted () {
    if (this.reportIds.length > 0) {
      this.grabReportData(this.reportIds)
    }
  }
}
</script>
