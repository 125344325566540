<template>
  <v-autocomplete
    v-model="id"
    auto-select-first
    :items="productCompanies"
    data-cy="product-company"
    :loading="loading"
    :label="label"
    :error-messages="error"
    item-text="short_name"
    item-value="id"
    @change="emit"
  >
    <template
      slot="item"
      slot-scope="data"
    >
      <v-list-tile-content>
        <v-list-tile-title>{{ data.item.short_name }}</v-list-tile-title>
        <v-list-tile-sub-title>{{ data.item.name }}</v-list-tile-sub-title>
      </v-list-tile-content>
    </template>
  </v-autocomplete>
</template>

<script>
import gql from 'graphql-tag'
import { GET_PRODUCT_COMPANIES } from '@/api/graphql/Constants/ProductCompanies'
export default {
  name: 'productCompany',
  props: {
    label: {
      type: String,
      required: true,
      default: 'Product Company'
    },
    error: {
      type: String,
      required: true,
      default: ''
    }
  },
  data () {
    return {
      productCompanies: [],
      id: null,
      loading: false
    }
  },
  apollo: {
    productCompanies: {
      query: GET_PRODUCT_COMPANIES,
      update: data => data.parts_product_companys,
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
  },
  methods: {
    emit () {
      this.$emit('updated', this.id)
    }
  }
}
</script>

<style scoped>

</style>
