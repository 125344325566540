<template>
  <v-layout row nowrap justify-end fill-height>
    <v-flex xs11>
      <v-layout column nowrap align-end>
<!--        <v-flex xs11 mb-2 mr-2 style="direction: rtl;">-->
<!--          <span> {{ contact.name }}</span>-->
<!--        </v-flex>-->
        <v-flex xs11 mb-2 mr-2 style="direction: rtl;" v-if="contact.email">
          <span>{{ contact.email }}</span>
        </v-flex>
        <v-flex v-if="contact.phone" xs11 mb-2 mr-2 style="direction: rtl;">
          <span>{{ contact.phone }}</span>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs1>
      <v-layout column nowrap align-center>
<!--        <v-flex xs1 mb-2 mr-2>-->
<!--          <v-icon small>far fa-user</v-icon>-->
<!--        </v-flex>-->
        <v-flex xs1 mb-2 mr-2 v-if="contact.email">
          <v-icon small>far fa-envelope</v-icon>
        </v-flex>
        <v-flex v-if="contact.phone" xs1 mr-2>
          <v-icon small>far fa-phone</v-icon>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'contact',
  props: {
    contact: {
      // valid if object, not if false
      required: true
    }
  }
}
</script>
