








































































import { computed, ComputedRef, defineComponent, provide, reactive, Ref, ref } from '@vue/composition-api'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import { ApolloClients, useMutation, useQuery } from '@vue/apollo-composable'
import { apolloClient } from '@/api/graphql/apollo'
import { UseEditContact } from '@/composition/UseEditContact'
import { GET_CONTACT_TO_EDIT, UPDATE_CONTACT } from '@/api/graphql/Constants/Contacts'
import {
  Contact_GridFragment,
  GetContactToEditQuery, GetContactToEditQueryVariables,
  Mutation,
  MutationUpdate__Clients_ContactArgs,
  Update__Clients_Contact__Input
} from '@/models/generated/graphql/ErpBackend'
import tAlert from '@/components/notifications/tAlert.vue'
export default defineComponent({
  name: 'EditContact',
  components: {
    'cancel-button': CancelButton,
    'submit-button': SubmitButton,
    't-alert': tAlert
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    provide(ApolloClients, {
      default: apolloClient
    })
    const validate: Ref<string> = ref('')
    const alert = reactive({
      message: '',
      type: 'info'
    })
    const success = ref(false)
    // @ts-ignore
    const contact: Contact_GridFragment = reactive({
      first_name: '',
      last_name: '',
      phone: '',
      email: ''
    })

    const {
      loading,
      onResult
    } = useQuery<GetContactToEditQuery, GetContactToEditQueryVariables>(GET_CONTACT_TO_EDIT,
      () => ({ id: props.id }))
    const {
      form,
      hasErrors
      // @ts-ignore
    } = UseEditContact(validate)

    onResult((response) => {
      if (response?.data?.clients_contact) {
        const c = response.data.clients_contact
        for (const key of Object.keys(form)) {
          // @ts-ignore
          form[key].value = c[key]
          // @ts-ignore
          contact[key] = c[key]
        }
      }
    })

    const cancel = () => {
      emit('close')
    }

    const input: ComputedRef<Update__Clients_Contact__Input> = computed(() => ({
      first_name: form.first_name.edited(contact.first_name) ? form.first_name.value : undefined,
      last_name: form.last_name.edited(contact.last_name) ? form.last_name.value : undefined,
      phone: form.phone.edited(contact.phone) ? form.phone.value : undefined,
      email: form.email.edited(contact.email) ? form.email.value : undefined,
      id: props.id
    }))

    const {
      mutate,
      loading: mutating,
      onError,
      onDone
    } = useMutation<Mutation, MutationUpdate__Clients_ContactArgs>(UPDATE_CONTACT)

    const submit = () => {
      validate.value = 'yes'
      if (!hasErrors.value) {
        mutate({ input: input.value })
      }
    }
    onError((e) => {
      alert.message = e.message
      alert.type = 'error'
    })
    onDone(() => {
      alert.message = 'Edited successfully'
      alert.type = 'success'
      success.value = true
    })

    const submitIsDisabled = computed(() => {
      for (const key of Object.keys(input.value)) {
        // @ts-ignore
        if (input.value[key] !== undefined && key !== 'id') {
          return false
        }
      }
      return true
    })

    return {
      form,
      alert,
      contact,
      hasErrors,
      loading,
      mutating,
      input,
      cancel,
      submit,
      submitIsDisabled,
      success
    }
  }
})
