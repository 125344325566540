import { computed, ComputedRef, reactive, Ref, ref, watch } from '@vue/composition-api'
import { FILTER_OPTIONS, IGridToGqlFilterStorageObject } from '@/lib/queryParser'
import { NaturalLanguageDate, naturalLanguageDatesForReports } from '@/lib/Dates'
import { apolloClient as apollo } from '@/api/graphql/apollo'
import { GET_TYPE__FIELDS, GET_TYPES } from '@/api/graphql/Constants/Company'
import { splitByCapInsertSpace } from '@/lib/helpers'
import store from '@/store/store'
import { Aggr_Type, AnnotationObjectInput, ManyQueryInput, QueryFilter } from '@/models/generated/graphql/ErpBackend'
import { QueryBuilder } from '@/lib/QueryBuilder'
import { LOCAL_STORAGE_KEYS, locStore } from '@/lib/LocalStorageWrapper'

/*
  Need to have an end result that can create this:

  query Report ($input: InputType) {
    ${queryName} ${topLevelInput -> (input: $input)} {
      ${fields}
      ${fieldWithQuery -> attached_sale (input: $input) { ${fields} }
    }
  }

  sort by something in an annotation?

  required pieces are:
  query name

*/

interface UIReportInput {
  name: string,
  queryName: string,
  annotation: AnnotationObjectInput,
  fields: field[],
  filters: QueryFilter[],
  isNestedInput: boolean
}

export interface field {
  name: string,
  text: string,
  type?: field,
  ofType?: field,
  kind?: string,
  children?: field[]
}

interface model {
  children: field[],
  description?: string,
  filters: field[],
  loading: boolean,
  name: string,
  text: string,
  color?: string
}

interface domain {
  text: string,
  matcher: (params: model) => boolean,
  children: model[],
  childEditor?: (params: string) => string,
  additives: model[],
  excluder: (params: field) => void,
  loading: boolean
}

interface StatisticalMethod {
  text: string,
  name: Aggr_Type,
  icon: string
}

type SelectedReportData = {
  [key: string]: any
  domain: domain
  model: model
  field: field
  nld: NaturalLanguageDate
  method: StatisticalMethod
  filterField: field
  dates: string[]
}

interface UpdateSelectedDataInput {
  [key: string]: any,
  domain?: domain,
  model?: model
  field?: field
  filterField?: field
  method?: StatisticalMethod
  filter?: IGridToGqlFilterStorageObject
  dates?: string[]
}

export enum SELECTED_DATA_KEYS {
  domain = 'domain',
  model = 'model',
  field = 'field',
  method = 'method',
  filterField = 'filterField',
  dates = 'dates'
}

function buildQuery (report: UIReportInput) {
  const query = new QueryBuilder(report.queryName)
  query.setFields(['id', { annotations: ['name', 'value'] }])
  const input: ManyQueryInput = {
    limit: 100000,
    annotations: [report.annotation],
    filters: report.filters
  }
  query.setInput(input)
}

function makeTextPretty (s: string): string {
  return s.replace(/_/g, ' ').replace(/\b\w/g, r => r.toUpperCase())
}

function noInputsOrInterfaces (t: model) {
  return !t.name.includes('__Input') && !t.name.includes('__Interface')
}

function excludeGeneric (f: field) {
  const name = f.name.toLowerCase()
  return ['id', 'annotations'].indexOf(name) === -1
}

function excludeClientModels (m: model): boolean {
  return ['Clients_Subs', 'Clients_Terms', 'Clients_ShippingLabel'].indexOf(m.name) === -1
}

function getTheRealType (item: field): string {
  if (item.type?.name) {
    return item.type.name
  }
  if (item.type?.ofType?.name) {
    return item.type?.ofType.name
  }
  return item?.type?.ofType?.ofType?.name ?? ''
}

export async function fetchFurtherChildren (field: field): Promise<field[] | undefined> {
  const type = getTheRealType(field)
  if (type && type !== 'Money') {
    try {
      const response = await apollo.query({
        query: GET_TYPE__FIELDS,
        variables: { name: type }
      })
      const fields = response.data.__type.fields?.filter((f: field) => {
        if (field.type?.name === 'Money') return true
        return f.type?.name === 'Money'
      })
        .map((f: field) => {
          if (['LIST', 'OBJECT'].indexOf(f.type?.kind ?? '') > -1 && getTheRealType(f) !== 'Money') {
            f.children = []
          }
          f.text = f.name.replace(/_/g, ' ').replace(/\b\w/g, f => f.toUpperCase())
          return f
        })
      if (fields?.length > 0) {
        return fields
      } else {
        return undefined
      }
    } catch (e) {
      throw new Error('A network error has occurred.')
    }
  } else {
    return undefined
  }
}

function prepareFields (fields: field[], model: model, domain: domain): Promise<field[]> {
  return new Promise((resolve, reject) => {
    const children: field[] = fields.filter(f => (f.type?.kind === 'LIST' && getTheRealType(f) !== model.name) || getTheRealType(f) === 'Money')
    const moneyOnes: field[] = fields.filter(f => getTheRealType(f) === 'Money').map(f => {
      if (!f.text) {
        f.text = f.name.replace(/_/g, ' ').replace(/\b\w/g, f => f.toUpperCase())
        f.name = `${model.name}__${f.name}`
        f.text = f.text.replace(f.name, '').trim()
        if (domain.childEditor) {
          f.text = domain.childEditor(f.text)
        }
      }
      return f
    })
    const promises: Promise<any>[] = []
    for (const f of children) {
      if (!f.text) {
        f.text = f.name.replace(/_/g, ' ').replace(/\b\w/g, f => f.toUpperCase())
        f.name = `${model.name}__${f.name}`
        promises.push(fetchFurtherChildren(f).then(r => { f.children = r }).catch(reject))
        if (domain.childEditor) {
          f.text = domain.childEditor(f.text)
        }
      }
    }
    Promise.all(promises).then(() => {
      resolve([...children?.filter(f => f.children), ...moneyOnes])
    })
  })
}

function prepareFilters (fields: field[], domain: domain): field[] {
  const dateTypes = ['DateTime', 'Date']
  const isDate = (f: field) => {
    return dateTypes.indexOf(f.type?.ofType?.name ?? '') > -1 || dateTypes.indexOf(f.type?.name ?? '') > -1
  }
  return fields
    .filter(f => isDate(f) && f.name !== 'updated_date')
    .map(f => {
      if (!f.text) f.text = makeTextPretty(f.name)

      if (domain.childEditor) {
        f.text = domain.childEditor(f.text)
      }

      return f
    })
}

// function buildReportQuery (report: UIReportInput): string {
// }

export function UseReportBuilder () {
  const reportToBuild: UIReportInput = reactive({
    name: '',
    queryName: '',
    annotation: { aggr_field: '', aggr_filters: [], name: 'ReportValue', aggr_type: Aggr_Type.Count },
    fields: [],
    filters: [],
    isNestedInput: false
  })
  const domains: domain[] = [
    {
      text: 'Inventory',
      matcher: (t) => t.name.includes('Parts_') && !t.name.includes('Parts_Part') && noInputsOrInterfaces(t),
      children: [],
      additives: [
        { name: 'Inventory_Item', text: 'ALL', color: 'purple lighten-5', children: [], loading: false, filters: [] }
      ],
      excluder: (f) => excludeGeneric(f),
      loading: false
    },
    {
      text: 'Purchases',
      matcher: (t) => t.name.includes('Purchases_') && noInputsOrInterfaces(t),
      children: [],
      additives: [],
      excluder: (f) => excludeGeneric(f),
      loading: false
    },
    {
      text: 'Sales',
      matcher: t => t.name.includes('Sales_') && ['Sales_Fees', 'Sales_SoldServices', 'Sales_SoldSoftware'].indexOf(t.name) === -1 && noInputsOrInterfaces(t),
      children: [],
      additives: [],
      childEditor: (s) => s.replace('St ', ''),
      excluder: field => excludeGeneric(field) && !field.name.includes('St '),
      loading: false
    },
    {
      text: 'Quotes',
      matcher: t => t.name.includes('Quotes_') && noInputsOrInterfaces(t),
      children: [],
      additives: [],
      // childEditor: (s) => s.replace('St ', ''),
      excluder: field => excludeGeneric(field),
      loading: false
    },
    {
      text: 'Clients',
      matcher: t => t.name.includes('Clients_') && noInputsOrInterfaces(t) && excludeClientModels(t),
      children: [],
      additives: [],
      excluder: field => excludeGeneric(field),
      loading: false
    },
    {
      text: 'Accounting',
      matcher: t => (t.name.includes('Accounting') || t.name.includes('Invoice')) && noInputsOrInterfaces(t),
      children: [],
      additives: [],
      excluder: field => excludeGeneric(field),
      loading: false
    },
    {
      text: 'Shipping',
      matcher: t => t.name.includes('Shipping_') && noInputsOrInterfaces(t),
      children: [],
      additives: [],
      excluder: field => excludeGeneric(field),
      loading: false
    },
    // {
    //   name: 'Processing',
    //   matcher: t => t.name.includes(''),
    //   children: [],
    //   additives: [],
    //   excluder: field => excludeGeneric(field),
    //   loading: false
    // },
    {
      text: 'Users',
      matcher: t => t.name.includes('Users_') && noInputsOrInterfaces(t),
      children: [],
      additives: [
        { name: 'Profile_ProfileMetrics', text: 'Profile', color: 'purple lighten-5', children: [], filters: [], loading: false }
      ],
      excluder: field => excludeGeneric(field),
      loading: false
    }
  ]
  const models: Ref<model[]> = ref([])
  const shownModelFields = computed(() => {
    return selected.model.children.filter((c: field) => selected.domain.excluder(c))
  })
  const currentModel: Ref<string> = ref('')
  const allModels: Ref<model[]> = ref([])
  if (locStore.get(LOCAL_STORAGE_KEYS.types) !== undefined) {
    allModels.value = locStore.get(LOCAL_STORAGE_KEYS.types)
  }
  const isLoadingModel: Ref<boolean> = ref(false)
  const fields: Ref<field[]> = ref([])
  const activeFields: Ref<field[]> = ref([])

  const methods: StatisticalMethod[] = [
    { text: 'Count', name: Aggr_Type.Count, icon: 'fal fa-tally' },
    { text: 'Average', name: Aggr_Type.Avg, icon: 'fal fa-tachometer-alt-average' },
    { text: 'Sum', name: Aggr_Type.Sum, icon: 'fal fa-sigma' },
    { text: 'Max Value', name: Aggr_Type.Max, icon: 'fal fa-arrow-to-top' },
    { text: 'Minimum Value', name: Aggr_Type.Min, icon: 'fal fa-arrow-from-top' }
  ]
  const filters = FILTER_OPTIONS
  const computedFilters = computed(() => {
    return filters
      .filter(f => f.types.indexOf('date') > -1)
      .map(f => {
        f.grid = f.grid.replace('Greater Than', 'Before').replace('Less Than', 'After')
        return f
      })
  })
  // function filterIsInUse (filter: IGridToGqlFilterStorageObject) {
  //   return filters.find(f => filter.grid === f.grid) !== undefined
  // }

  const naturalLanguageDates = naturalLanguageDatesForReports
  const useNaturalDates = ref(false)
  watch(useNaturalDates, (value) => {
    selected.nld = { text: '', startValue: () => '', endValue: () => '' }
    selected.dates = []
    reportToBuild.filters = []
  })

  let selected: SelectedReportData = reactive<SelectedReportData>({
    domain: { text: '', additives: [], excluder: () => null, matcher: () => false, children: [], loading: false },
    model: { name: '', text: '', children: [], filters: [], loading: false },
    field: { name: '', text: '' },
    nld: { text: '', startValue: () => '', endValue: () => '' },
    method: { text: '', icon: '', name: Aggr_Type.Min },
    filterField: { name: '', text: '' },
    dates: []
  })

  const noFilterConfigured: ComputedRef<boolean> = computed(() =>
    selected.filterField.text === '' && reportToBuild.filters.length === 0)
  const filterHalfConfigured: ComputedRef<boolean> = computed(() =>
    (selected.filterField.text === '' && reportToBuild.filters.length !== 0) ||
    (selected.filterField.text !== '' && reportToBuild.filters.length === 0))

  function setSelectedData (input: UpdateSelectedDataInput): void {
    if (input.dates) {
      selected.dates = input.dates
    } else {
      const childKey: string = Object.keys(input)[0]
      if (childKey) {
        for (const key in input[childKey]) {
          selected[childKey][key] = input[childKey][key]
        }
      } else {
        throw new Error('You supplied an empty object.')
      }
    }
  }
  function setFilterField (field: field | undefined, parent: string): void {
    if (field === undefined) {
      resetSubSelectedData(SELECTED_DATA_KEYS.filterField)
    } else {
      setSelectedData({ filterField: { text: field.text, name: parent + '__' + field.name } })
    }
  }

  function resetSelectedData (): void {
    selected = reactive<SelectedReportData>({
      domain: { text: '', additives: [], excluder: () => null, matcher: () => false, children: [], loading: false },
      model: { name: '', text: '', children: [], filters: [], loading: false },
      field: { name: '', text: '' },
      nld: { text: '', startValue: () => '', endValue: () => '' },
      method: { text: '', icon: '', name: Aggr_Type.Min },
      filterField: { name: '', text: '' },
      dates: []
    })
  }
  function resetSubSelectedData (input: SELECTED_DATA_KEYS): void {
    if (input === SELECTED_DATA_KEYS.domain) {
      selected.domain = { text: '', additives: [], excluder: () => null, matcher: () => false, children: [], loading: false }
    } else if (input === SELECTED_DATA_KEYS.model) {
      selected.model = { name: '', text: '', children: [], filters: [], loading: false }
    } else if (input === SELECTED_DATA_KEYS.field) {
      selected.field = { name: '', text: '' }
    } else if (input === SELECTED_DATA_KEYS.method) {
      selected.method = { text: '', icon: '', name: Aggr_Type.Min }
    } else if (input === SELECTED_DATA_KEYS.filterField) {
      selected.filterField = { name: '', text: '' }
    }
  }

  const message: Ref<string> = ref('')
  const type: Ref<string> = ref('')

  const canGenerateReport = computed(() => {
    return type.value !== '' // when they get to this point there are sub level checks prior to this already
  })
  const canSaveReport = computed(() => {
    return canGenerateReport.value && reportToBuild.name !== ''
  })

  async function selectDomain (domain: domain, callback: Function): Promise<void> {
    try {
      if (domain.children.length === 0) {
        domain.loading = true
        if (allModels.value.length === 0) {
          const response = await apollo.query({
            query: GET_TYPES
          })
          allModels.value = response.data.__schema.types
          locStore.set(LOCAL_STORAGE_KEYS.types, allModels.value)
        }
        const children: model[] = allModels.value.filter(m => domain.matcher(m))
          .map(m => {
            m.text = splitByCapInsertSpace(m.name.substring(m.name.indexOf('_') + 1))
            m.children = []
            m.filters = []
            m.loading = false
            if (['Purchase', 'Sale'].indexOf(m.text) > -1) {
              m.text = 'Orders'
            }
            return m
          })
          .sort((a, b) => a.text > b.text ? 1 : a.text < b.text ? -1 : 0)
        domain.children.push(...domain.additives, ...children)
      }
      setSelectedData({ domain: domain })
    } catch (error) {
      store.dispatch('notifications/createSnackbar', {
        message: 'A network error has occurred. Please refresh to try again.',
        color: 'error'
      })
    } finally {
      domain.loading = false
      callback()
    }
  }

  async function selectModel (model: model, callback: Function): Promise<void> {
    currentModel.value = model.name
    if (model.children.length === 0) {
      try {
        isLoadingModel.value = true
        const response = await apollo.query({
          query: GET_TYPE__FIELDS,
          variables: { name: model.name }
        })
        if (response.data.__type.fields) {
          const children = response.data.__type.fields
          const fields: field[] = await prepareFields(children, model, selected.domain)
          const filters: field[] = prepareFilters(children, selected.domain)
          model.children.push(...fields)
          model.filters.push(...filters)

          isLoadingModel.value = false
        }
        setSelectedData({ model: model })
        reportToBuild.queryName = (model.name + 's').toLowerCase()
        callback()
      } catch (e) {
        store.dispatch('notifications/createSnackbar', {
          message: 'A network error has occurred. Please refresh to try again.',
          color: 'error'
        })
      } finally {
        isLoadingModel.value = false
      }
    } else {
      setSelectedData({ model: model })
      reportToBuild.queryName = (model.name + 's').toLowerCase()
      callback()
    }
  }

  function selectMetric (field: string, method: Aggr_Type): void {
    reportToBuild.annotation.aggr_type = method
    reportToBuild.annotation.aggr_field = field
  }

  function setDatesFromPicker (date: string, filter: IGridToGqlFilterStorageObject, callback: Function) {
    let f: QueryFilter
    let hint: string
    const fieldName = selected.filterField.name.replace(`${selected.model.name}__`, '')
    if (!date.includes(' - ')) {
      f = {
        key: `${fieldName}__${filter.gql}`,
        value: date
      }
      hint = selected.filterField.text + ' ' + computedFilters.value.find(c => c.gql === filter.gql)!.grid
    } else { // is sent in format dd/mm/yyyy - dd/mm/yyyy
      const start = date.split(' - ')[0]
      const end = date.split(' - ')[1]
      f = {
        key: `${fieldName}__gte`,
        value: start,
        and: {
          key: `${fieldName}__lte`,
          value: end
        }
      }
      hint = 'In Range'
    }
    reportToBuild.filters.push(f)
    callback(hint, date)
  }

  function setDatesFromNld (nld: NaturalLanguageDate, callback: Function) {
    let filter: QueryFilter
    const fieldName = selected.filterField.name.replace(`${selected.model.name}__`, '')
    if (nld.endValue() !== '') {
      filter = {
        key: `${fieldName}__gte`,
        value: nld.startValue(),
        and: { key: `${fieldName}`, value: nld.endValue() }
      }
    } else {
      filter = {
        key: `${fieldName}__gte`,
        value: nld.startValue()
      }
    }
    reportToBuild.filters.push(filter)
    callback(nld.text, selected.filterField.text)
  }

  function isLoadingThisModel (model: model): boolean {
    return model.name === currentModel.value && isLoadingModel.value
  }

  return {
    domains,
    models,
    currentModel,
    shownModelFields,
    isLoadingModel,
    fields,
    activeFields,
    methods,
    filters,
    computedFilters,
    naturalLanguageDates,
    useNaturalDates,
    selected,
    noFilterConfigured,
    filterHalfConfigured,
    setSelectedData,
    setFilterField,
    resetSelectedData,
    resetSubSelectedData,
    type,
    message,
    allModels,
    canGenerateReport,
    canSaveReport,
    selectDomain,
    selectModel,
    selectMetric,
    setDatesFromPicker,
    setDatesFromNld,
    isLoadingThisModel,
    reportToBuild
  }
}
