import {
  GET_DEPARTMENTS_FOR_PERMISSIONS,
  GET_ROLES_FOR_PERMISSIONS,
  GET_USERS_FOR_PERMISSIONS
} from '@/api/graphql/Constants/Permissions'

export const PERMISSION_TABS = [
  { name: 'User Management', to: { name: 'user-management' } },
  { name: 'Departments', to: { name: 'departments' } },
  { name: 'Roles', to: { name: 'roles' } },
  { name: 'Permissions', to: { name: 'permissions' } }
]

export const USERS_DEPARTMENTS_PROPS = {
  query: GET_USERS_FOR_PERMISSIONS,
  gridName: 'users-departments',
  contextMenuItems: params => {
    const cm = []

    return cm
  },
  filter: []
}

export const DEPARTMENTS_ROLES_PROPS = {
  query: GET_DEPARTMENTS_FOR_PERMISSIONS,
  columns: [
    { headerName: 'Name', field: 'name', colId: 'name' },
    { headerName: 'Description', field: 'description', colId: 'description' }
  ],
  gridName: 'departments-roles',
  contextMenuItems: params => {
    return []
  },
  filter: []
}

export const ROLES_PERMISSIONS_PROPS = {
  query: GET_ROLES_FOR_PERMISSIONS,
  columns: [
    { headerName: 'Name', field: 'name', colId: 'name' },
    { headerName: 'Description', field: 'description', colId: 'description' }
  ],
  gridName: 'roles-permissions',
  contextMenuItems: params => {
    return []
  },
  filter: []
}
