<template>
  <new-data-form
    :errors="{
      panel0: rmaErrors,
      panel1: shipmentErrors,
      panel2: false
    }"
    :succeeded="succeeded"
    :failed="failed"
    :footer-message="footerMessage"
  >
    <template #header0> RMA INFO </template>
    <template #panel0>
      <v-container pb-0 px-5 grid-list-xl>
        <v-layout row wrap justify-start align-center>
          <v-flex xs4 md2>
            <v-text-field
              v-model="pt"
              label="PT"
              box
              mask="#############"
              data-cy="rma-id"
              :readonly="startedWithPt"
            />
          </v-flex>
          <v-flex xs4>
            <v-checkbox
              v-model="returnRequested"
              label="Return Parts"
              color="brown"
              data-cy="return-parts-checkbox"
            />
          </v-flex>
          <v-flex xs5 sm4 md3>
            <date-picker
              :date="date"
              :config="dateConfig"
              :errors="errors.rma.date.value"
              @updated="update({ key: 'date', value: $event.value })"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </template>

    <template #header1> SHIPMENT INFO </template>
    <template #panel1>
      <v-container grid-list-xl>
        <v-layout row wrap justify-start align-center px-5 pb-0>
          <!-- Ship From  -->
          <v-flex xs12 sm6 lg4 pa-3>
            <standard-autofill
              :id="shipFrom"
              :items="shipFroms"
              :load="loadingShipFroms"
              :config="shipFromConfig"
              :error="errors.shipping.shipFrom.value"
              :disabled="!returnRequested"
              data-cy="ship-from"
              @updated="update({ key: 'shipFrom', value: $event.value })"
            />
          </v-flex>
          <!-- Ship To -->
          <v-flex xs12 sm6 lg4 pa-3>
            <standard-autofill
              :id="shipTo"
              :items="shipTos"
              :load="loadingShipTos"
              :config="shipToConfig"
              :error="errors.shipping.shipTo.value"
              :disabled="!returnRequested"
              data-cy="ship-to"
              @updated="update({ key: 'shipTo', value: $event.value })"
            />
          </v-flex>
          <!-- Courier -->
          <v-flex xs4>
            <v-checkbox
              v-model="isLocalPickup"
              label="Courier/Customer Pickup"
              color="red"
              data-cy="courier-checkbox"
              :disabled="!returnRequested"
            />
          </v-flex>
          <!-- Courier Selection -->
          <v-flex xs4 v-if="isLocalPickup">
            <courier-selection
              data-cy="courier-select"
              :pickup-type="localPickup"
              :errors="errors.shipping.localPickup.value"
              :disabled="!returnRequested"
              @update="update({ key: 'localPickup', value: $event.value })"
            />
          </v-flex>
          <!-- We Pay -->
          <v-flex xs4 lg2 ml-4 v-else>
            <v-checkbox
              v-model="wePay"
              label="We Pay"
              color="info"
              data-cy="wepay-checkbox"
              :disabled="!returnRequested"
            />
          </v-flex>
          <!-- Shipping Account -->
          <v-flex xs6 md4 v-if="!isLocalPickup">
            <standard-autofill
              data-cy="carrier-account"
              :id="account"
              :items="shownAccounts"
              :config="accountConfig"
              :error="errors.shipping.account.value"
              :disabled="!returnRequested"
              @updated="update({ key: 'account', value: $event.value })"
            />
          </v-flex>
          <!-- Carrier Service -->
          <v-flex xs6 md5 v-if="!isLocalPickup">
            <carrier-services
              :service-id="service"
              :ship-from="shipFromEpId"
              :ship-to="shipToEpId"
              :bill-to="shipToEpId"
              :account="accountEpId"
              :carrier="carrierName"
              :error="errors.shipping.service.value"
              :disabled="!returnRequested"
              @update="update({ key: 'service', value: $event.value })"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </template>

    <template #header2> PRODUCT INFO </template>
    <template #panel2>
      <v-container pb-0 px-5>
        <v-data-table
          :items="items"
          :headers="headers"
        >
          <template #items="{ item }">
            <td data-cy="rma-pn">{{ item.pn }}</td>
            <!-- <td>{{ item.sn }}</td> -->
            <td>{{ item.orderLineId }}</td>
            <td data-cy="rma-value">{{ item.value.pretty }}</td>
            <td data-cy="rma-credit">
              <v-checkbox
                v-model="item.credit"
                hide-details
                color="info"
              />
            </td>
            <td class="justify-center layout px-0">
              <v-icon data-cy="rma-part-remove" small color="error" @click="removePart(item)">fal fa-trash-alt</v-icon>
            </td>
          </template>
        </v-data-table>
      </v-container>
    </template>

    <template #dataActions>
      <!-- Cancel -->
      <cancel-button data-cy="close" :success="succeeded" @click="cancel"/>
      <!-- Submit -->
      <submit-button data-cy="submit" :loading="saving" :disabled="succeeded" @click="submit"/>
    </template>
  </new-data-form>
</template>

<script>
import datePicker from '@/components/autocompletes/datePicker'
import { CREATE_PURCHASE_RMA, CREATE_PURCHASE_RMA_ITEM } from '@/api/graphql/Constants/RMAs'
import formTemplate from '@/components/forms/formTemplate'
import standard2 from '@/components/autocompletes/standard2'
import { GET_ADDRESSES__LIST } from '@/api/graphql/Constants/Addresses'
import { PURCHASE_DETAILS_FOR_RMA } from '@/api/graphql/Constants/Orders'
import courierSelection from '@/components/autocompletes/courierSelection'
import carrierServices from '@/components/autocompletes/carrierServices'
import { CLIENT_AND_COMPANY_CARRIER_ACCOUNTS } from '@/api/graphql/Constants/Shipments'
import mutateShipments from '@/api/graphql/mutationsJS/mutateShipments'
import { GetAddressDetailsForAutofill } from '@/lib/gqlDataGetters'
import SubmitButton from '@/components/buttons/SubmitButton'
import CancelButton from '@/components/buttons/CancelButton'

export default {
  name: 'newPurchaseRma',
  mixins: [mutateShipments],
  components: {
    'date-picker': datePicker,
    'new-data-form': formTemplate,
    'standard-autofill': standard2,
    'courier-selection': courierSelection,
    'carrier-services': carrierServices,
    'submit-button': SubmitButton,
    'cancel-button': CancelButton
  },
  computed: {
    footerMessage () {
      if (this.message) {
        return this.message
      }
      if (this.hasErrors) {
        return 'Fix errors to continue'
      }
      if (this.succeeded) {
        return 'Created Purchase RMA Successfully'
      }
      return 'New Purchase RMA'
    },

    rmaErrors () {
      return this.errors.rma.date.value
    },

    shipmentErrors () {
      for (const key in this.errors.shipping) {
        if (this.errors.shipping[key].value) return true
      }
      return false
    },

    hasErrors () {
      return this.rmaErrors || this.shipmentErrors
    },

    pt: {
      get () {
        return this.$store.state.rma.transactionId
      },
      set (value) {
        this.$store.dispatch('rma/update', { key: 'transactionId', value: value })
      }
    },

    items () {
      return this.$store.state.rma.items
    },

    shipToObject () {
      return this.shipTos.filter(a => {
        return a.id === this.shipTo
      })[0] ?? null
    },

    shipToEpId () {
      return this.shipToObject?.easypost_id ?? ''
    },

    shipFromObject () {
      return this.shipFroms.filter(a => {
        return a.id === this.shipFrom
      })[0] ?? null
    },

    shipFromEpId () {
      return this.shipFromObject?.easypost_id ?? ''
    },

    accountObject () {
      return this.accounts.filter(a => {
        return a.id === this.account
      })[0] ?? null
    },

    accountEpId () {
      return this.accountObject?.easypost_id ?? ''
    },

    shownAccounts () {
      return this.accounts.filter(a => {
        return this.wePay ? !a.client : a.client?.id ?? false
      })
    },

    carrierName () {
      return this.accountObject?.shipper.name ?? ''
    }

  },
  watch: {
    isLocalPickup: function () {
      this.localPickup = 'NOT_PICKUP'
      this.resetShippingErrors()
    },

    returnRequested: function () {
      this.resetShippingErrors()
    }
  },
  data () {
    return {
      succeeded: false,
      failed: false,
      saving: false,
      message: '',
      order: {},

      startedWithPt: false,
      returnRequested: false,

      date: '',
      dateConfig: {
        label: 'Claim Date',
        maxDate: 'today'
      },

      // shipping info
      shipFrom: 0,
      shipFroms: [],
      shipFromConfig: {
        name: 'shipFrom',
        label: 'Ship From Address'
      },
      loadingShipFroms: false,

      shipTo: 0,
      shipTos: [],
      shipToConfig: {
        name: 'shipTo',
        label: 'Ship To Address'
      },
      loadingShipTos: false,

      isLocalPickup: false,
      localPickup: 'NOT_PICKUP',
      wePay: false,

      account: 0,
      accounts: [],
      accountConfig: {
        label: 'Carrier Account',
        name: 'account',
        clearable: true
      },
      loadingAccounts: false,

      service: 0,

      // product info data
      headers: [
        { text: 'Part Number', value: 'pn' },
        { text: 'PT-ID', value: 'pt' },
        { text: 'Cost', value: 'value' },
        { text: 'Return For Credit', value: 'credit' },
        { text: 'Actions', value: 'actions' }
      ],

      // errors
      errors: {
        rma: {
          date: { value: '', validator: e => e ? '' : 'This is required' }
        },
        shipping: {
          shipFrom: { value: '', validator: e => e ? '' : 'This is required' },
          shipTo: { value: '', validator: e => e ? '' : 'This is required' },
          localPickup: {
            value: '',
            validator: e => {
              if (this.isLocalPickup) return e ? '' : 'This is required'
              else return ''
            }
          },
          account: {
            value: '',
            validator: e => (!this.returnRequested || this.isLocalPickup || !!e) ? '' : 'This is required when using a service'
          },
          service: {
            value: '',
            validator: e => (!this.returnRequested || this.isLocalPickup || !!e) ? '' : 'This is required when using a service'
          }
        }
      }
    }
  },
  apollo: {
    shipTos: {
      query: GET_ADDRESSES__LIST,
      variables () {
        return {
          input: {
            filters: [{ key: 'client__id', value: this.order.client.id }]
          }
        }
      },
      skip () {
        return !this.order?.client?.id ?? true
      },
      update: data => {
        return data.address_addresses.map(a => ({
          ...a,
          title: GetAddressDetailsForAutofill(a),
          subtitle: a.client.name
        }))
      },
      watchLoading (isLoading) {
        this.loadingShipFroms = isLoading
      }
    },
    shipFroms: {
      query: GET_ADDRESSES__LIST,
      variables: { input: { filters: [{ key: 'company__isnull', value: false }] } },
      update: data => {
        return data.address_addresses.map(a => ({
          ...a,
          title: GetAddressDetailsForAutofill(a)
        }))
      },
      watchLoading (isLoading) {
        this.loadingShipTos = isLoading
      }
    },
    order: {
      query: PURCHASE_DETAILS_FOR_RMA,
      variables () {
        return {
          id: this.pt
        }
      },
      skip () {
        return !this.pt
      }
    },
    accounts: {
      query: CLIENT_AND_COMPANY_CARRIER_ACCOUNTS,
      variables () {
        return {
          id: this.order.client.id
        }
      },
      skip () {
        return !this.order?.client?.id ?? true
      },
      update (data) {
        return data.accounts.map(a => ({
          ...a,
          title: a.name,
          subtitle: a.number
        }))
      }
    }
  },
  methods: {
    cancel () {
      this.$store.dispatch('grid/resetSheet')
      this.$store.dispatch('rma/reset')
    },

    update (obj) {
      for (const step in this.errors) {
        for (const key in this.errors[step]) {
          if (key === obj.key) {
            this.errors[step][key].value = ''
          }
        }
      }
      this[obj.key] = obj.value
    },

    resultHandler ({ message, type }) {
      if (type === 'success') {
        this.succeeded = true
      } else if (type === 'error') this.failed = true
      this.message = message
      this.saving = false
    },

    async submit () {
      // status: 48
      this.validateInfo()
      if (!this.hasErrors) {
        this.saving = true
        let newShipmentId = ''
        if (this.returnRequested) {
          newShipmentId = await this.createShipmentOrder({
            account_id: this.account,
            blind: 'NOT_BLIND',
            pickup_type: this.pickupType,
            ship_from_address_id: this.shipFrom,
            ship_to_address_id: this.shipTo,
            we_pay: this.wePay
          }).id
        }
        this.$apollo.mutate({
          mutation: CREATE_PURCHASE_RMA,
          variables: {
            date: this.date,
            pt: this.pt,
            ...(newShipmentId && { shipment: newShipmentId })
          }
        })
          .then(({ data: { rma } }) => {
            const rmaItemsToCreate = []
            for (const item of this.items) {
              const rmaItemToCreate = {
                credit: item.credit,
                returned_part_id: item.itemId,
                rma_id: rma.id,
                ...(newShipmentId && { shipment_id: newShipmentId })
              }
              rmaItemsToCreate.push(rmaItemToCreate)
            }
            this.$apollo.mutate({
              mutation: CREATE_PURCHASE_RMA_ITEM,
              variables: { items: rmaItemsToCreate }
            })
              .then(() => {
                this.resultHandler({ type: 'success' })
              })
              .catch(() => {
                this.resultHandler({
                  message: 'Created RMA but not the items. Try again or contact support.',
                  type: 'error'
                })
              })
          })
          .catch(() => {
            this.resultHandler({
              message: 'Could not create RMA',
              type: 'error'
            })
          })
      }
    },

    validateInfo () {
      for (const key in this.errors) {
        for (const field in this.errors[key]) {
          this.errors[key][field].value = this.errors[key][field].validator(this[field])
        }
      }
    },

    resetShippingErrors () {
      for (const key in this.errors.shipping) {
        this.errors.shipping[key].value = ''
      }
    },

    removePart (item) {
      const index = this.items.filter(p => {
        return p.orderLineId === item.orderLineId
      })
      this.items.splice(index, 1)
    }
  },
  mounted () {
    this.startedWithPt = this.pt !== 0
  }
}
</script>
