import Keycloak from 'keycloak-js'
import store from '@/store/store.js'
import router from '@/router/router'
import STORE from '@/store/store'
import { LOCAL_STORAGE_KEYS, locStore } from '@/lib/LocalStorageWrapper'
import { initializeFreshDesk } from '@/lib/freshDesk.ts'

const urlKeycloakAuth = 'https://auth.traqsys.com/auth'
const realm = 'TRAQSYS'
const client = 'traqsys-web'

function getRedirect (route) {
  const host = window.location.host
  const mode = process.env.NODE_ENV
  const isProd = mode !== 'development' && mode !== 'test'
  const secure = host.includes('localhost') ? '' : 's'
  const isEl = navigator.userAgent.includes('Electron') ? '#' : ''
  const prodAndElRoute = `app://./index.html/#/${route}`
  const other = `http${secure}://${host}/${isEl}/${route}`
  const returnElectronProductionRoute = navigator.userAgent.includes('Electron') && isProd
  return returnElectronProductionRoute ? prodAndElRoute : other
}

// export function decodeToken (token) {
//   if (token) {
//     const accessBase64Url = token.split('.')[1]
//     const accessBase64 = accessBase64Url.replace(/-/g, '+').replace(/_/g, '/')
//     const accessJsonPayload = decodeURIComponent(atob(accessBase64).split('').map(function (c) {
//       return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
//     }).join(''))
//
//     return JSON.parse(accessJsonPayload)
//   } else {
//     return undefined
//   }
// }

export function processToken (keycloak) {
  const accessDecoded = keycloak.tokenParsed
  if (accessDecoded === undefined) {
    store.dispatch('auth/logout')
    return
  }
  if (accessDecoded.exp) {
    const expireTime = accessDecoded.exp * 1000
    locStore.set(LOCAL_STORAGE_KEYS.expire, expireTime) // store time in seconds
  }
  if (accessDecoded.groups) {
    const tenants = accessDecoded.groups.filter(g => {
      return g.includes('/Tenants/')
    })
    store.dispatch('auth/updateState', { key: 'tenants', value: tenants })
    locStore.set(LOCAL_STORAGE_KEYS.tenants, tenants)
  }
  if (accessDecoded.sub) {
    store.dispatch('profile/update', { key: 'keycloakId', value: accessDecoded.sub })
  }
  if (window.location.origin.includes('localhost')) {
    const initials = accessDecoded.initials
    locStore.set(LOCAL_STORAGE_KEYS.localInitials, initials)
  }
  if (accessDecoded.email) {
    const user = { firstName: accessDecoded.given_name, lastName: accessDecoded.family_name, email: accessDecoded.email }
    initializeFreshDesk(user)
  }
  if (!store.state.profile.company.name && locStore.get(LOCAL_STORAGE_KEYS.tenant)) {
    store.dispatch('profile/getLoginData')
  }
  store.dispatch('auth/setTokens', { refresh: keycloak.refreshToken, access: keycloak.token })
  if (router.currentRoute.name !== 'tenant' && router.currentRoute.fullPath.includes('tenant')) {
    router.push({ name: 'tenant' })
  }
}

export const URL_KEYCLOAK_REDIRECT_LOGOUT = getRedirect('login')
export const URL_KEYCLOAK_REDIRECT_TENANT = getRedirect('tenant')
export const URL_KEYCLOAK_REDIRECT_SSO = getRedirect('sso')

export const keycloak = new Keycloak({
  url: urlKeycloakAuth,
  realm: realm,
  clientId: client,
  enableLogging: true
})

keycloak.onAuthSuccess = function () {
  processToken(keycloak)
}

keycloak.onAuthRefreshError = function () {
  STORE.dispatch('auth/logout', { expire: true })
}

keycloak.onAuthRefreshSuccess = function () {
  processToken(keycloak)
}

keycloak.onTokenExpired = function () {
  STORE.dispatch('auth/renewAccessToken')
}

keycloak.onReady = function (authed) {
  if (authed) {
    store.dispatch('profile/update', { key: 'keycloakId', value: keycloak.subject })
  }
}
