// vuex store for data related to setting up sales and purchase transactions

const state = {
  orderType: null,

  salesOrders: [],
  shipments: [],
  selectedShipment: null,
  listSortConfig: {
    sortBy: {
      name: 'ID',
      field: 'id'
    },
    descending: false
  },

  shipperList: [],

  partsList: []
}

const getters = {
  cardData: state => id => {
    return state.cardData[id]
  },

  salesOrder: state => id => {
    return state.salesOrders.filter(item => item.id === id)[0]
  },

  listSortConfig: state => {
    return state.listSortConfig
  }
}

const mutations = {
  /**
   * Used for setting the sort config for the task views
   * @param state
   * @param payload
   * @param payload.key
   * @param payload.value
   */
  setListSortConfig (state, payload) {
    state.listSortConfig[payload.key] = payload.value
  },

  /**
   * Method to update the selected sales order for processing
   * @param state
   * @param payload
   */

  setSelectedShipment (state, payload) {
    state.selectedShipment = payload.order
  },

  setAutofillList (state, payload) {
    // payload: { listName, list }
    state.autofillLists[payload.listName] = payload.list
  },

  setLoadingStatus (state, payload) {
    state.loading[payload.type] = !state.loading[payload.type]
  },

  setOrderType (state, payload) {
    state.orderType = payload.type
  }
}

const actions = {
  /**
   * Action to change sort config in task views
   * @param commit
   * @param payload
   * @param payload.key
   * @param payload.value
   */
  changeListSortConfig ({ commit }, payload) {
    commit('setListSortConfig', payload)
  },
  /**
   * action to change selected sales order for processing/shipping
   * @param commit
   * @param payload
   */
  changeSelectedShipment ({ commit }, payload) {
    commit('setSelectedShipment', payload)
  },

  changeOrderType ({ commit }, payload) {
    commit('setOrderType', payload)
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
