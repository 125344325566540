<template>
  <div>
    <!-- Table -->
    <v-container fluid style="overflow-y: auto; max-height: 460px;">
      <v-card v-if="parts.length > 0">
        <v-data-table
          :headers="headers"
          :items="groupedParts"
          :rows-per-page-items="[5, 10, 15]"
          item-key="rowId"
          :custom-sort="customSort"
          data-cy="parts-template-table"
        >
          <template v-slot:items="{ item }">
            <!-- Part Number -->
              <td>
                <v-layout column>
                  <sales-alt-part-number
                    v-if="isSale"
                    :key="item.pn"
                    :part="item"
                    data-cy="alt-part-number"
                    @updated="updateAltPart(item, $event)"
                    @cacheAltParts="cacheAltParts(item, $event)"
                  />
                  <b v-else data-cy="item-pn">{{ item.pn }}</b>
                  <span v-if="!isLgAndUp">{{ item.description }}</span>
                </v-layout>
            </td>
            <!-- Description -->
            <td v-if="$vuetify.breakpoint.lgAndUp" data-cy="item-desc">{{ item.description }}</td>
            <!-- Quantity -->
            <td v-if="!isSale || !item.pt">
              <v-edit-dialog
                :return-value.sync="item.quantity"
                lazy
                large
                transition="scale-transition"
                data-cy="edit-quantity-dialog"
                @save="editQuantity(item)"
              > {{ item.quantity }}
                <v-icon small class="pl-2" data-cy="edit-quantity-icon">fal fa-pencil</v-icon>
                <template v-slot:input>
                  <v-text-field
                    v-model.number="item.quantity"
                    :rules="[e => e > 0 || 'Must be > 0']"
                    mask="NNNNNN"
                    label="Edit"
                    single-line
                    autofocus
                    data-cy="quantity-text-field"
                  />
                </template>
              </v-edit-dialog>
            </td>
            <td v-else data-cy="item-qty">{{ item.quantity }}</td>
            <!-- Sale/Quote Value (Editable) -->
            <td v-if="isSale || isQuote">
              <v-edit-dialog
                :return-value.sync="item['soldFor']"
                lazy
                attach
                large
                transition="scale-transition"
                data-cy="item-soldFor-edit-dialog"
                @save="editValue(item)"
              > {{ format(item['soldFor']) }}
                <v-icon small class="pl-2" data-cy="item-soldFor-edit-icon">fal fa-pencil</v-icon>
                <template v-slot:input>
                  <currency
                    :value="item['soldFor']"
                    :rules="[e => e > 0 || 'Must be > 0']"
                    label="Edit"
                    single-line
                    autofocus
                    data-cy="item-soldFor-text-field"
                    @input="item['soldFor'] = $event"
                  />
                </template>
              </v-edit-dialog>
            </td>
            <!-- Purchase/Wo Value (Editable) -->
            <td v-else>
              <v-edit-dialog
                :return-value.sync="item['cost']"
                lazy
                large
                transition="scale-transition"
                data-qy="item-cost-edit-dialog"
                @save="editValue(item)"
              > {{ format(item['cost']) }}
                <v-icon small class="pl-2" data-cy="item-cost-edit-icon">fal fa-pencil</v-icon>
                <template v-slot:input>
                  <v-text-field
                    v-model.number="item['cost']"
                    :rules="[e => e > 0 || 'Must be > 0']"
                    label="Edit"
                    single-line
                    autofocus
                    data-cy="item-cost-edit-text-field"
                  />
                </template>
              </v-edit-dialog>
            </td>
            <!-- PT-ID (for allocation) -->
            <td v-if="isSale" style="display: flex; justify-content: center; align-items: center;" @mouseenter="setHover(item, true)" @mouseleave="setHover(item, false)">
              <div style="display: flex; align-items: center;">
                <span data-cy="item-pt">{{ item.pt || '--' }}</span>
              </div>
            </td>
            <!-- Actions -->
            <td>
              <v-layout justify-end>
                <v-menu offset-y transition="slide-y-transition">
                  <template #activator="{ on: actions }">
                    <v-btn  flat color="primary" v-on="actions">Actions</v-btn>
                  </template>
                  <v-list>
                    <v-list-tile v-if="!item.pt && isSale">
                      <v-list-tile-content>
                        <!-- Allocation -->
                        <v-btn
                          small
                          id="allocate-button"
                          color="info"
                          flat
                          block
                          data-cy="item-allocate-button"
                          @click="allocate(item)"
                        >Allocate</v-btn>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile
                      v-if="isSale && item.pt !== null"
                    >
                      <v-list-tile-content>
                        <v-btn block small color="primary" flat @click="allocate(item)">
                          <!--                      <v-icon data-cy="item-reAllocate-button">fal fa-pencil</v-icon>-->
                          Re-Allocate
                        </v-btn>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile
                      v-if="isSale && item.pt !== null"
                    >
                      <v-list-tile-content>
                        <v-btn block small color="warning" flat @click="unAllocateItem(item)">
                          <!--                      <v-icon data-cy="item-unAllocate-button">fal fa-trash</v-icon>-->
                          Un-Allocate
                        </v-btn>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile>
                      <v-list-tile-content>
                        <v-btn block color="error" small flat data-cy="item-delete-button" @click="removePart(item)" >
                          <!--                      <v-icon small>fal fa-times</v-icon>-->
                          Remove
                        </v-btn>
                      </v-list-tile-content>
                    </v-list-tile>
                  </v-list>
                </v-menu>
              </v-layout>
            </td>
          </template>
          <template v-slot:footer>
            <tr class="light-blue lighten-5" data-cy="item-totals-footer">
              <td :colspan="1">TOTALS</td>
              <td v-if="isLgAndUp" :colspan="1"></td>
              <td v-if="isWo" :colspan="1"></td>
              <td>{{ parts.length }}</td>
              <td v-if="isSale" :colspan="1"></td>
              <td :colspan="3"> $ {{ format(totalMoney()) }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
      <v-subheader v-else-if="!error">
        <slot name="no-items">Add parts to your order.</slot>
      </v-subheader>
      <v-subheader v-if="error" class="error--text" v-html="error"/>
    </v-container>
    <!-- Form -->
    <v-layout row justify-center align-start>
      <v-flex xs12>
        <v-form ref="part">
          <v-container grid-list-xl pa-0>
            <v-layout row wrap align-center justify-end>
              <!-- Part Number -->
              <v-flex xs12 sm6 md4>
                <parts-autofill
                  v-if="!isWo"
                  :selected="selectedPart"
                  :attach="attach"
                  :error="partError"
                  data-cy="part-selection"
                  @updated="updateSelectedPart"
                />
                <work-order-supplies
                  v-else
                  :product="selectedPart"
                  data-cy="work-order-parts-selection"
                  @updated="updateSelectedPart"
                />
              </v-flex>
              <!-- Quantity -->
              <v-flex xs6 sm2>
                <v-text-field
                  v-model.number="quantity"
                  label="Quantity"
                  id="quantity-field"
                  reverse
                  box
                  :rules="[ e=> e > 0 || 'Must be number > 0',
                  e => e % 1 === 0 || 'Enter a whole number']"
                  data-cy="quantity-field"
                  @click="highlightAll('quantity-field')"
                />
              </v-flex>
              <!-- Value -->
              <v-flex xs6 sm3 md2>
                <currency
                  label="Value"
                  id="value-field"
                  box
                  :value="value"
                  data-cy="value-field"
                  @enter="addPart"
                  @input="value = $event"
                  @click="highlightAll('value-field')"
                />
              </v-flex>
              <!-- Add Button -->
              <v-flex shrink>
                <v-btn small @click="addPart" data-cy="add-parts-button">Add</v-btn>
              </v-flex>
              <!-- Remove All Button -->
              <v-flex shrink>
                <v-slide-x-reverse-transition>
                  <v-btn
                    v-if="groupedParts.length > 1"
                    small
                    color="error"
                    data-cy="remove-all-parts-button"
                    @click="confirmRemoval ? removeAll() : confirmRemoval = true"
                  >{{ !confirmRemoval ? 'Remove All' : 'One more time!'}}</v-btn>
                </v-slide-x-reverse-transition>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="showAllocate"
      lazy
      scrollable
      width="90%"
      persistent
      data-cy="item-allocate-dialog"
    >
      <template #activator>
      </template>
      <allocation
        v-if="showAllocate"
        :part="partToAllocate"
        :allocated-parts="allocatedParts"
        :rep-id="repId"
        data-cy="item-allocation-template"
        @close="showAllocate = false"
        @save="saveAllocations"
      />
    </v-dialog>
  </div>
</template>

<script>
import moneyFormatter from '../mixins/moneyFormatter'
import allocation from '../dialogs/allocation'
import { groupLikeItemsWithGivenKeys } from '@/lib/arrayHelpers'
import WorkOrderSupplies from '@/components/autocompletes/WorkOrderSupplies'
import PartsAutofill from '@/components/autocompletes/PartsAutofill'
import salesPartNumber from '@/components/autocompletes/salesPartNumber'
import Currency from '../fields/Currency.vue'
import { c } from '@/lib/Currency'
export default {
  name: 'AddPartsSet',
  mixins: [moneyFormatter],
  components: {
    'parts-autofill': PartsAutofill,
    'allocation': allocation,
    'work-order-supplies': WorkOrderSupplies,
    'sales-alt-part-number': salesPartNumber,
    'currency': Currency
  },
  props: {
    parts: {
      type: Array,
      required: true
    },
    repId: {
      type: String,
      required: false,
      default: ''
    },
    error: {
      type: String,
      required: false,
      default: ''
    },
    order: {
      required: true,
      validator: prop => ['sale', 'purchase', 'wo', 'quote'].indexOf(prop) !== -1
    },
    attach: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    groupedParts () {
      if (this.isSale) return groupLikeItemsWithGivenKeys(this.parts, ['id', 'item', 'soldFor'])
      if (this.isPurchase) return groupLikeItemsWithGivenKeys(this.parts, ['id', 'cost'])
      if (this.isQuote) return groupLikeItemsWithGivenKeys(this.parts, ['id', 'soldFor'])
      if (this.isWo) return groupLikeItemsWithGivenKeys(this.parts, ['id', 'cost'])
      else return []
    },

    isLgAndUp () {
      return this.$vuetify.breakpoint.lgAndUp
    },

    allocatedParts () {
      return this.parts.filter(p => {
        return p.item !== null
      })
    },

    isSale () {
      return this.order === 'sale'
    },

    isPurchase () {
      return this.order === 'purchase'
    },

    isQuote () {
      return this.order === 'quote'
    },

    isWo () {
      return this.order === 'wo'
    },

    totalRevenue () {
      let total = 0
      for (const part of this.parts) {
        if (part.soldFor) {
          total += parseFloat(part.soldFor)
        }
      }
      return total
    },

    totalCost () {
      let cost = 0
      let itemsCost = 0
      for (const item of this.groupedParts) {
        const amount = item.cost || item.cost === 0 ? item.cost : item.soldFor
        const money = parseFloat(amount)
        itemsCost = (parseInt(item.quantity) * money)
        cost = cost + itemsCost
      }
      return cost
    }
  },
  watch: {
    confirmRemoval (value) {
      if (value) {
        const self = this
        setTimeout(function () {
          self.confirmRemoval = false
        }, 3000)
      }
    },

    isLgAndUp: function (value) {
      if (value) {
        this.headers.splice(1, 0, { text: 'Description', value: 'description' })
      } else {
        const index = this.headers.findIndex(h => h.text === 'Description')
        if (index > -1) {
          this.headers.splice(index, 1)
        }
      }
    },

    selectedPart: {
      deep: true,
      handler: function (n) {
        if (n.id) this.partError = ''
      }
    }
  },
  data () {
    return {
      headers: [
        { text: 'Part Number', value: 'pn' },
        { text: 'Quantity', value: 'quantity' }
      ],

      hovered: [],

      value: 0,
      quantity: 1,

      // remove all button
      confirmRemoval: false,

      partToAllocate: {},
      showAllocate: false,
      selectedPart: { pn: '', id: '' },
      partError: ''
    }
  },
  methods: {
    highlightAll (id) {
      const el = document.getElementById(id)
      el.select()
    },

    updateAltPart (item, event) {
      const index = this.parts.findIndex(p => p.id === item.id)
      const part = this.parts[index]
      part.altPart = event.value
      this.parts.splice(index, 1, part)
    },

    cacheAltParts (item, event) {
      const index = this.parts.findIndex(p => p.id === item.id)
      const part = this.parts[index]
      part.altParts = event.value
      this.parts.splice(index, 1, part)
    },

    editQuantity (item) {
      const newQuantity = item.quantity
      const soldFor = c(item?.soldFor ?? 0).intValue // uses currency because this is currency or annoying,
      // which ever one you want to call it
      const cost = c(item?.cost ?? 0).intValue // uses currency because of same reason above
      const sameParts = this.parts.filter(p => {
        if (this.isSale) {
          return p.pn === item.pn && !p.item && c(p.soldFor).intValue === soldFor
        } else if (this.isPurchase) {
          return p.id === item.id && c(p.cost).intValue === cost
        } else if (this.isQuote) {
          return p.pn === item.pn && c(p.soldFor).intValue === soldFor
        }
      })
      const oldQuantity = sameParts.length
      const candidatePart = sameParts[0] // get part to add if needed
      if (newQuantity > oldQuantity) { // if adding parts
        for (let i = 0; i < (newQuantity - oldQuantity); i++) {
          this.parts.push(candidatePart)
        }
      } else if (newQuantity < oldQuantity) {
        let count = oldQuantity - newQuantity
        for (let i = 0; i < this.parts.length && count > 0; i++) { // this is here because Array.filter() returns a
          // BRAND NEW ARRAY and not a reference to the old one
          const part = this.parts[i]
          if (part.id === item.id) {
            if (this.isSale && !part.item && part.soldFor === item.soldFor) {
              this.parts.splice(i, 1)
              i--
              count--
            } else if (this.isPurchase && part.cost === item.cost) {
              this.parts.splice(i, 1)
              i--
              count--
            } else if (this.isQuote && part.soldFor === item.soldFor) {
              this.parts.splice(i, 1)
              i--
              count--
            }
          }
        }
      }
    },

    editValue (item) {
      if (this.isSale) {
        this.editSaleValue(item)
      } else if (this.isPurchase || this.isWo) {
        this.editPurchaseValue(item)
      } else if (this.isQuote) {
        this.editQuoteValue(item)
      }
    },

    editSaleValue (item) {
      const newSoldFor = item.soldFor
      if (!item.item) {
        const sameParts = this.parts.filter(p => {
          return p.pn === item.pn && !p.item
        })
        for (const part of sameParts) {
          part.soldFor = newSoldFor
        }
      }
    },

    editPurchaseValue (item) {
      const newCost = item.cost
      const sameParts = this.parts.filter(part => part.pn === item.pn)
      for (const part of sameParts) {
        part.cost = newCost
      }
    },

    editQuoteValue (item) {
      const newSoldFor = item.soldFor
      const sameParts = this.parts.filter(p => {
        return p.pn === item.pn && !p.item && p.soldFor === item.soldFor
      })
      for (const part of sameParts) {
        part.soldFor = newSoldFor
      }
    },
    // Part Mutation Methods

    /**
     * @temporary
     */
    editSerialNumber (item) {
      // item will have a pt other editing won't be enabled
      const part = this.parts.find(p => item.pt === p.pt)
      part.sn = item.sn
    },

    /**
     * Setup the allocation process
     * @param {Object} item the item to allocate
     */
    allocate (item) {
      this.partToAllocate = item
      this.showAllocate = true
    },

    /**
     * Used to unAllocate a single specific item
     * @param {Object} item
     */
    unAllocateItem (item) {
      const part = this.parts.find(p => p.pt === item.pt)
      const index = this.parts.find(p => p.pt === item.pt)
      part.pt = null
      part.item = null
      part.sn = ''
      this.hovered.splice(0, this.hovered.length)
      this.parts.splice(index, 1, part)
    },

    /**
     * Method to save allocations to items
     * @param {Array<Object>} unAllocated
     */
    saveAllocations (unAllocated) {
      // steps
      // if an item is allocated already remove those details
      if (this.partToAllocate.item) {
        const allocatedPart = this.parts.find(p => this.partToAllocate.item === p.item)
        allocatedPart.item = ''
        allocatedPart.pt = ''
        allocatedPart.cost = ''
      }
      // allocate
      const parts = this.parts.filter(p => { // filter parts
        const idMatch = p.id === unAllocated[0].part
        const notAllocated = !p.item
        return idMatch && notAllocated
      }).reverse()
      parts.forEach((p) => { // allocate
        if (unAllocated.length > 0) {
          const u = unAllocated.pop()
          p.item = u.item
          p.cost = u.cost
          p.split = u.split
          p.pt = u.pt
        }
      })
      this.showAllocate = false
      this.partToAllocate = {}
    },

    /**
     * Method to remove item from parts
     * @param item
     */
    removePart (item) {
      if (this.isSale) {
        this.removeSalePart(item)
      } else if (this.isQuote) {
        this.removeQuotePart(item)
      } else if (this.isPurchase || this.isWo) {
        this.removePurchasePart(item)
      }
    },

    removeSalePart (item) {
      for (let i = 0; i < this.parts.length; i++) {
        const part = this.parts[i]
        if (item.item === part.item && Number(item.soldFor) === Number(part.soldFor) && item.id === part.id) {
          this.parts.splice(i, 1)
          i--
        }
      }
    },

    removePurchasePart (item) {
      for (let i = 0; i < this.parts.length; i++) {
        const part = this.parts[i]
        if (Number(item.cost) === Number(part.cost) && item.id === part.id) {
          this.parts.splice(i, 1)
          i--
        }
      }
    },

    removeQuotePart (item) {
      for (let i = 0; i < this.parts.length; i++) {
        const part = this.parts[i]
        if (Number(item.soldFor) === Number(part.soldFor) && item.id === part.id) {
          this.parts.splice(i, 1)
          i--
        }
      }
    },

    setHover (item, bool) {
      bool && this.hovered.push(item)
      !bool && this.hovered.splice(0, this.hovered.length)
    },

    /**
     * Method to add a part to the parts prop
     */
    addPart () {
      this.partError = ''
      if (this.$refs.part.validate() && this.selectedPart.id) {
        for (let i = 0; i < this.quantity; i++) {
          this.parts.unshift({
            id: this.selectedPart.id, // translated to part_id
            pn: this.selectedPart.pn, // string pn
            quantity: 1,
            description: this.selectedPart.description,
            ...((this.isSale || this.isQuote) && { soldFor: this.value }),
            ...(this.isSale && { cost: '', split: '', sn: '' }),
            ...((this.isPurchase || this.isWo) && { cost: this.value }),
            ...(this.isSale && { pt: null }),
            ...(this.isSale && { item: null }),
            hover: false
          })
        }
        document.getElementById('quantity-field').blur()
        document.getElementById('part-field').focus()
        this.reset()
      } else if (!this.selectedPart.id) {
        this.partError = 'Select a part to add'
      }
    },

    /**
     * Method to remove all parts
     */
    removeAll () {
      this.parts.splice(0, this.parts.length)
    },

    // End of Part Mutation Methods

    /**
     * Reset helper
     */
    reset () {
      this.selectedPart = { pn: '', id: '' }
      this.quantity = 1
      this.value = 0
    },

    /**
     * Helper method to return the right kind of money object
     * @returns {string||Number}
     */
    totalMoney () {
      if (this.isSale || this.isQuote) {
        return this.totalRevenue
      } else if (this.isPurchase || this.isWo) {
        return this.totalCost
      } else {
        return 'Wrong Component Checking'
      }
    },

    /**
     * Method to make the part in the items component the item in the parent (this component)
     * @param event
     */
    updateSelectedPart (event) {
      const part = event.value
      if (part === undefined) {
        this.selectedPart = { pn: '', id: '' }
      } else {
        this.selectedPart = part
      }
    },

    // table sorting
    /**
     * A custom sorting method for PTs because they need it
     * @param {Array<Object>} items the array of items coming in
     * @param {String} column the column that is sorting
     * @param {Boolean} isDesc if we are sorting in a descending manner
     * @return {Array<Object>} sorted array of items
     */
    customSort (items, column, isDesc) {
      if (column === 'pt') {
        return this.sortByPurchaseLineItem(items, isDesc)
      } else {
        if (isDesc) {
          return items.sort((a, b) => {
            return a[column] > b[column] ? -1 : 1
          })
        } else {
          return items.sort((a, b) => {
            return a[column] < b[column] ? -1 : 1
          })
        }
      }
    },

    /**
     * Helper method for sorting by PTs
     * @param {Array<String>} a split string array of first pt (ex: 12-2 --> ['12', '2'])
     * @param {Array<String>} b split string array of second pt
     * @return {Number} > 0 if a is larger or < 0 is b is larger
     */
    sortTwoPtIds (a, b) {
      if (a === null) return 1
      if (b === null) return 1
      if (parseInt(a[0]) > parseInt(b[0])) return 1
      if (parseInt(a[0]) < parseInt(b[0])) return -1
      if (parseInt(a[1]) > parseInt(b[1])) return 1
      if (parseInt(a[1]) < parseInt(b[1])) return -1
      return 0
    },

    /**
     * Helper function for customSort
     * @param items
     * @param desc
     * return {Array} items
     */
    sortByPurchaseLineItem (items, desc) {
      if (!desc) {
        return items.sort((a, b) => {
          const aSplit = a.pt?.split('-') ?? null
          const bSplit = b.pt?.split('-') ?? null
          return this.sortTwoPtIds(aSplit, bSplit)
        })
      } else {
        return items.sort((a, b) => {
          const aSplit = a.pt?.split('-') ?? null
          const bSplit = b.pt?.split('-') ?? null
          return this.sortTwoPtIds(bSplit, aSplit)
        })
      }
    }
  },
  beforeMount () {
    this.$vuetify.breakpoint.lgAndUp && this.headers.splice(1, 0, { text: 'Description', value: 'description' })
    if (this.isSale) {
      // this.headers.push({ text: 'Serial Number', value: 'sn' })
      this.headers.push({ text: 'Sale Price (ea)', value: 'soldFor' })
      this.headers.push({ text: 'PT', value: 'pt', align: 'center', sortable: true })
    } else if (this.isPurchase) {
      this.headers.push({ text: 'Cost (ea)', value: 'cost' })
    } else if (this.isQuote) {
      this.headers.push({ text: 'Sale Price (ea)', value: 'soldFor' })
    } else if (this.isWo) {
      this.headers.push({ text: 'Cost (ea)', value: 'cost' })
    }
    this.headers.push({ text: 'Actions', value: 'actions', align: 'center' })
  }
}
</script>
