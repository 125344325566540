import { RowNode } from 'ag-grid-community'
import * as _ from 'lodash'
import { IContextMenuLockObject } from '@/models/GuiPartsModels'
import { IPassAndReasonObject } from '@/models/errorAndValidationModels'
import {
  AnnotationObject,
  Inventory_Item,
  Rma_PurchaseRma,
  Rma_Rma,
  Sales_Sale,
  Shipping_ShipmentOrder
} from '@/models/generated/graphql/ErpBackend.ts'
import { ProcessingCardData } from '@/models/ExtraBackendModels'

export function CheckIfUserCanLockOrUnlock (nodes: RowNode[], initials: string) {
  return CheckRowsForIdenticalValuesInColumn(nodes, 'locked')
}

export function HtmlGuidGenerator (): string {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return ('a' + S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4())
}

export function GetContextMenuLockButtonDetails (nodes: RowNode[]): IContextMenuLockObject {
  const s = nodes.length > 1 ? 's' : ''
  const data: Inventory_Item = nodes[0].data
  const lock = data.locked
  const name = `${lock ? 'Unlock' : 'Lock'} Part${s}`
  return {
    name: name,
    lock: !lock
  }
}

export function CheckRowsForCheckInOrOut (nodes: RowNode[]): boolean {
  return CheckRowsForIdenticalValuesInColumn(nodes, 'checked_out_by.id')
}

export function CheckRowsForIdenticalValuesInColumn (nodes: RowNode[], column: string): boolean {
  if (nodes.length === 1) return true
  for (let i = 0; i < nodes.length - 1; i++) {
    const node = nodes[i].data
    const leftNode = nodes[i + 1].data
    const value = _.get(node, column)
    const leftValue = _.get(leftNode, column)
    if (value !== leftValue) {
      return false
    }
  }
  return true
}

export function CheckRowsForValueInColumn (nodes: RowNode[], column: string, test: (params: any) => boolean): boolean {
  if (nodes.length === 1) return test(_.get(nodes[0].data, column))

  for (const node of nodes) {
    if (!test(_.get(node.data, column))) return false
  }
  return true
}

export function CheckRowsForSingleUniqueValueAmongNulls (nodes: RowNode[], column: string, keyToCompare: string): IPassAndReasonObject {
  let returnable: IPassAndReasonObject = { pass: false, reason: 'No valid values found.', data: null }
  for (const node of nodes) {
    const value: any | null = _.get(node.data, column)
    if (value !== null) {
      // temporary shit for weird country of origin library
      if (keyToCompare === 'code') {
        if (value.code !== null) {
          if (returnable.data === null) returnable = { pass: true, reason: '', data: value }
          else if (returnable.data.code !== value.code) return { pass: false, reason: 'More than one unique value found.', data: null }
        }
        // end temporary
      } else {
        if (returnable.data === null) returnable = { pass: true, reason: '', data: value }
        else if (returnable.data[keyToCompare] !== value[keyToCompare]) return { pass: false, reason: 'More than one unique value found.', data: null }
      }
    }
  }

  return returnable
}

export function GetTypeNameFromTqsModelEnum (modelEnum: string): string {
  const stringer: string = `${modelEnum}`
  if (stringer.charAt(0) === '_') return stringer.substring(1, modelEnum.length)
  else return modelEnum
}

export function MatchesKeyboardEvent (event: KeyboardEvent, key: string, altKey: boolean | undefined): boolean {
  return (event.key === key || event.code === `Key${key.toUpperCase()}`) && (altKey ? event.altKey : true)
}

export function ReturnPossibleMoneyValueFormatted (money: string): string {
  return Number.isSafeInteger(money) ? parseFloat(money).toFixed(2) : money
}

export function RowMasterFunctionForItemCountAnnotation (annotations: AnnotationObject[]): boolean {
  return Number(annotations.find((a: AnnotationObject) => a.name === 'itemCount')?.value) > 0
}

export function ScrollHorizontalWithWheel (e: WheelEvent): void {
  const delta = e.deltaY
  const el = document.getElementById('list-group-container')
  if (el) {
    el.scrollLeft -= (delta * 5)
  }
  e.preventDefault()
}

export function MakeServiceNamePretty (name: string, carrier: string): string {
  let returnable: string = ''
  if (carrier === 'FedEx') {
    returnable = CapIt(name.toLowerCase().replace(/_/g, ' ').replace(carrier.toLowerCase(), '').trim())
  } else returnable = name.replace(carrier, '').replace(/([A-Z])/g, ' $1').replace(/( (A|P) M )/, '$1$2').trim()
  returnable = returnable.replace(' A M', ' AM').replace(' P M', ' PM')
  return returnable
}

export function CapIt (name: string) {
  if (name) {
    if (name.split(' ').length > 1) {
      return name.toLowerCase().split(' ').map(s => s[0].toUpperCase() + s.substr(1)).join(' ')
    } else {
      return name[0].toUpperCase() + name.substr(1)
    }
  }
  return ''
}

export function NormalizeEasypostName (name: string, carrier: string) {
  return name.toLowerCase()
    .replace(/_/g, ' ')
    .replace(carrier.toLowerCase(), '')
    .trim()
}

function GetShipmentService (s: Shipping_ShipmentOrder): string {
  if (s.pickup_type === 'NOT_PICKUP') {
    return s.service?.service ?? 'NO SERVICE SELECTED'
  } else return s.pickup_type?.replace('_', ' ') ?? ''
}

export function MapShipmentOrderFieldsForProcessingCards (orders: Shipping_ShipmentOrder[]): ProcessingCardData[] {
  return orders?.map((o) => {
    // @ts-ignore
    const n: ProcessingCardData = {
      itemCount: -1,
      carrier: o.service?.shipper.name ?? '',
      service: GetShipmentService(o),
      shipmentId: o.id,
      color: o.service?.color ?? '#B9F6CA'
    }
    if (o.attached_sale?.id) {
      const sale: Sales_Sale = o.attached_sale
      n.id = sale.id
      n.status = sale.status.id
      n.rep = sale.rep?.initials ?? ''
      n.assignee = sale.assigned_to?.initials ?? 'Claim'
      n.assigneeColor = sale.assigned_to?.profile?.color ?? ''
      n.label = `ST: ${sale.id}`
    } if (o.attached_rma?.id) {
      const rma: Rma_Rma = o.attached_rma
      n.id = rma.id
      n.status = rma.status.id
      n.rep = rma.st.rep?.initials ?? ''
      // @ts-ignore
      n.assignee = rma.assigned_to?.initials ?? 'Claim'
      // @ts-ignore
      n.assigneeColor = rma.assigned_to?.profile?.color ?? ''
      n.itemCount = -1
      n.carrier = o.service?.shipper.name ?? ''
      n.service = GetShipmentService(o)
      n.shipmentId = o.id
      n.color = o.service?.color ?? '#B9F6CA'
      n.label = `RMA-S: ${rma.id}`
    } if (o.attached_purchase_rma?.id) {
      const rma: Rma_PurchaseRma = o.attached_purchase_rma
      n.id = rma.id
      n.status = rma.status.id
      n.rep = rma.pt.rep?.initials ?? ''
      // @ts-ignore
      n.assignee = rma.assigned_to?.initials ?? 'Claim'
      // @ts-ignore
      n.assigneeColor = rma.assigned_to?.profile?.color ?? ''
      n.itemCount = -1
      n.carrier = o.service?.shipper.name ?? ''
      n.service = GetShipmentService(o)
      n.shipmentId = o.id
      n.color = o.service?.color ?? '#B9F6CA'
      n.label = `RMA-P: ${rma.id}`
    }
    return n
  }) ?? []
}

export function splitByCapInsertSpace (word: string): string {
  const isCap = (c: string) => {
    return /[A-Z]/.test(c)
  }
  let result = word[0]
  for (let i = 1; i < word.length; i++) {
    if ((isCap(word[i]) && !isCap(word[i - 1])) || (isCap(word[i]) && !isCap(word[i + 1]) && i !== word.length - 1)) {
      result += ` ${word[i]}`
    } else {
      result += word[i]
    }
  }
  return result
}

export function GetNumberValue (s: string): number {
  const n = Number(s)
  if (Number.isFinite(n)) {
    return n
  } else {
    return 0
  }
}

/**
 * Used to get a pretty field name from the backend field.key
 * @param name
 * @returns {string} pretty
 */
export function GetPrettyFieldName (name: string): string {
  const spaced = name.replace(/_/g, ' ')
  return CapIt(spaced)
}

export interface xOrReturn {
  boolean: boolean,
  a: boolean,
  b: boolean
}
export function xOR (a: string | undefined | number, b: string | undefined | number): xOrReturn {
  return { boolean: (!a && !!b) || (!!a && !b), a: !a, b: !b }
}
