<template>
  <v-menu
    v-model="showDatePicker"
    :close-on-content-click="false"
    transition="scale-transition"
    origin="top center"
    offset-y
  >
    <template #activator="{ on }">
      <slot name="activator" v-bind:on="on" v-bind:value="dateValue">
        <div class="ag-floating-filter-input the-date" role="presentation">
          <!--AG-INPUT-TEXT-FIELD-->
          <div role="presentation" ref="eFloatingFilterInput" class="ag-labeled ag-label-align-left ag-text-field ag-input-field">
            <label ref="eLabel" class="ag-input-field-label ag-label ag-hidden ag-text-field-label"/>
            <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper" role="presentation">
              <input v-model="dateValue" v-on="on" readonly class="ag-input-field-input ag-text-field-input" type="text">
            </div>
          </div>
        </div>
      </slot>
    </template>
    <v-date-picker
      :value="pickedDates"
      multiple
      no-title
      @input="handleDateChanges"
    >
      <v-layout column justify-start align-center>
        <v-flex shrink>
          <v-select
            v-model="filter"
            :items="filters"
            label="Data is..."
            item-text="dateText"
            return-object
          ></v-select>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex>
          <v-btn flat color="warning" @click="clear">Clear Filter</v-btn>
          <v-btn flat color="info" @click="cancel">Cancel</v-btn>
          <v-btn flat color="info" :disabled="disabled" @click="save">OK</v-btn>
        </v-flex>
      </v-layout>
    </v-date-picker>
  </v-menu>
</template>

<script>
import {
  GetDatesBetweenTwoDates, GetEarliestDateFromList, GetLatestDateFromList, GetTimeZonedDateTime, GetUtcDateTime
} from '@/lib/Dates'
import { FILTER_OPTIONS } from '@/lib/queryParser'
export default {
  name: 'DateRangePicker',
  props: {
    dates: {
      required: true,
      type: Array
    },
    label: {
      required: true,
      type: String
    },
    hint: {
      type: String,
      required: false,
      default: 'YYYY-MM-DD'
    },
    errors: {
      required: true,
      type: String
    }
  },
  computed: {
    dateValue () {
      if (this.pickedDates.length >= 2) {
        return `${GetEarliestDateFromList(this.pickedDates)} - ${GetLatestDateFromList(this.pickedDates)}`
      } else if (this.pickedDates.length === 1) {
        return this.pickedDates[0]
      }
      return ''
    },

    pickedDates: {
      get () {
        return this.dates
      },
      set (value) {
        if (value !== undefined) {
          this.$emit('updated', { value: value.filter(v => v !== undefined), filter: this.filter })
        }
      }
    },

    canSelectRange () {
      return this.filter?.gql === 'in-range'
    },

    disabled () {
      return this.pickedDates.length === 0 || this.filter.grid === '' || (this.filter.gql === 'in-range' && this.pickedDates.length < 2)
    }
  },
  watch: {
    filter: function (value, old) {
      if (old.gql === 'in-range' && this.pickedDates.length > 1) {
        this.pickedDates.splice(0, this.pickedDates.length)
      }
    }
  },
  data () {
    return {
      showDatePicker: false,
      filter: { grid: '', gql: '', dateText: '', types: [] },
      filters: FILTER_OPTIONS.filter(f => f.dateText)
    }
  },
  methods: {
    handleDateChanges (event) {
      if (this.canSelectRange) {
        if (event.length < 2) {
          this.pickedDates.push(...event)
        } else if (event.length === 2) {
          this.pickedDates.splice(0, 2)
          this.pickedDates.push(event[0], ...GetDatesBetweenTwoDates(event[0], event[1]), event[1])
        } else { // if a range is selected already and another date is picked
          // if the date is outside the range
          const newStartDate = event.filter(d => this.pickedDates.indexOf(d) === -1)?.[0]
          if (newStartDate) {
            this.replaceAllWithOne(newStartDate)
          } else { // if the date is inside the range
            const newDate = this.pickedDates.filter(d => event.indexOf(d) === -1)?.[0]
            if (newDate) {
              this.replaceAllWithOne(newDate)
            } else {
              this.pickedDates.splice(0, this.pickedDates.length)
            }
          }
        }
      } else {
        this.pickedDates.splice(0, this.pickedDates.length)
        event.length > 0 && this.pickedDates.push(event[event.length - 1])
      }
    },

    save () {
      this.$emit('save', { value: this.filter, text: this.dateValue })
      this.showDatePicker = false
    },

    clear () {
      this.pickedDates.splice(0, this.pickedDates.length)
      this.$emit('save', '')
    },

    cancel () {
      this.pickedDates.splice(0, this.pickedDates.length)
      this.showDatePicker = false
    },

    replaceAllWithOne (date) {
      this.pickedDates.splice(0, this.pickedDates.length, date)
    }
  },
  mounted () {
    this.filter = this.filters.find(f => f.dateText === 'Exactly')
  }
}
</script>
