import helpers, { matchKeyUpTo } from '@/api/helpers'
import {
  UPDATE_INVENTORY_COMMENT__COMMENT_ALIAS
} from '@/api/graphql/Constants/Inventory'
import { UPDATE_PURCHASE_ORDER__COMMENT_ALIAS, UPDATE_SALES_ORDER__COMMENT_ALIAS } from '@/api/graphql/Constants/Orders'
import { UPDATE_QUOTE__COMMENT_ALIAS } from '@/api/graphql/Constants/Quotes'

const grid = {
  computed: {
    dialog () {
      return this.$store.state.grid.dialog
    },

    user () {
      return this.$store.state.profile.user
    },

    isDev () {
      return this.$store.state.profile.isDev
    },

    model () {
      return this.$route.fullPath.includes('purchase') ? 'purchase_rma' : 'rma'
    },

    currentOrderRep () {
      return this.currentOrderObject?.rep?.id ?? '' + ''
    },

    saving: {
      get () {
        return this.$store.state.data.saving
      },
      set (newValue) {
        this.$store.dispatch('data/changeSaving', { bool: newValue })
      }
    }
  },
  data () {
    return {
      apolloLoading: 0,
      editableNodes: [],
      gridMode: 'read',
      gapi: null,
      capi: null,
      gridOptions: null,
      nestedGapi: null,
      nestedCapi: null,
      originalComments: [],
      selectedNode: null,
      currentOrderObject: null,
      skip: false
    }
  },
  methods: {
    setCurrentOrderObject (obj) {
      this.currentOrderObject = obj
    },

    getRmaStatuses (id) {
      const currentStatus = this.$store.getters['data/status'](id)
      if (currentStatus.type !== 'FINAL') {
        return this.$store.getters['data/nextStatus']('rma', this.model, 'status', currentStatus.order)
      } else {
        return { id: id }
      }
    },

    ready (params) {
      this.gapi = params.api
      this.capi = params.columnApi
      this.gridOptions = params
    },

    nestedReady (params) {
      this.nestedGapi = params.api
      this.nestedCapi = params.columnApi
      this.nestedGridOptions = params
    },

    getNextRMAStatus (data) {
      const nextStatus = this.getRmaStatuses(data.status.id)
      if (nextStatus.id !== data.status.id) {
        const name = `Mark: ${nextStatus.status}`
        return {
          name: name,
          action: () => {
            this.placeInNextStatus(data.id, nextStatus.id)
          },
          cssClasses: ['bold']
        }
      } else {
        return false
      }
    },

    setComments (data) {
      for (const comment in this.commentList) {
        if (comment !== 'id') {
          this.commentList[comment].text = data[comment]
        }
      }
      this.commentList.id = data.id
      if (this.commentList.lock_reason) {
        if (data.locked) {
          this.commentList.lock_reason.show = true
        } else {
          this.commentList.lock_reason.show = false
        }
      }
    },

    async saveComments (comment) {
      const nodes = this.gapi.getSelectedNodes()
      const data = helpers.getOneNodeData(this.gapi)
      if (data) {
        this.saving = true
        const id = data.id
        const commentToSave = {}
        commentToSave[comment.name] = comment.text
        commentToSave.id = id
        try {
          await this.$apollo.mutate({
            mutation: this.getSaveCommentMutation(),
            variables: { input: commentToSave }
          })
          this.saving = false
          // nodes[0].setDataValue(comment.name, comment.text)
          nodes[0].data[comment.name] = comment.text
          this.$store.dispatch('notifications/createSnackbar', {
            message: 'Saved comment successfully',
            color: 'success',
            timeout: 2000
          })
        } catch (error) {
          this.saving = false
          this.$store.dispatch('notifications/createSnackbar', {
            message: error.message,
            color: 'error',
            timeout: 3000
          })
        }
      } else {
        this.$store.dispatch('notifications/createSnackbar', {
          message: 'Please select only one row to save comments.',
          color: 'warning',
          timeout: 4000
        })
      }
    },

    getSaveCommentMutation (input) {
      const path = this.$route
      let mutation = ''
      if (path.name === 'item' || path.name === 'system') {
        mutation = UPDATE_INVENTORY_COMMENT__COMMENT_ALIAS
      } else if (path.name === 'purchaseOrders') {
        mutation = UPDATE_PURCHASE_ORDER__COMMENT_ALIAS
      } else if (path.name === 'saleOrders') {
        mutation = UPDATE_SALES_ORDER__COMMENT_ALIAS
      } else if (path.name === 'quotes') {
        mutation = UPDATE_QUOTE__COMMENT_ALIAS
      }
      return mutation
    }
  }
}

export default grid
