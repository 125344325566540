// file for print node helpers as well as queries and mutations
import gql from 'graphql-tag'
import { apolloClient as apollo } from '@/api/graphql/apollo'
import store from '@/store/store'
import { GetPickListPdfData, GetRmaPackSlipPdfData, GetSalesPackSlipPdfData } from '@/lib/PdfPrep'
import { genPDF } from '@/components/mixins/pdfmaker'
import { Printnode_Content_Type } from '@/models/generated/graphql/ErpBackend'

/* HELPERS */

export async function PrintPickListForSt (order, printer) {
  const data = await GetPickListPdfData(order.transactionId)
  const content = GetBase64StringFromGeneratedPdf(genPDF(data.pdf).output('datauri'))
  await CreatePrintJob({
    content: content,
    type: Printnode_Content_Type.PdfBase64,
    printer: printer,
    title: `Pick List for ${order.label}`
  })
}

export async function PrintPackList (order, printer) {
  const label = order.label
  if (label.includes('ST')) {
    const data = await GetSalesPackSlipPdfData(order.transactionId)
    const content = GetBase64StringFromGeneratedPdf(genPDF(data.pdf).output('datauri'))
    await CreatePrintJob({
      content: content,
      type: Printnode_Content_Type.PdfBase64,
      printer: printer,
      title: `Pick List for ${order.label}`
    })
  } else if (label.includes('RMA')) {
    const data = await GetRmaPackSlipPdfData(order.transactionId)
    const content = GetBase64StringFromGeneratedPdf(genPDF(data.pdf).output('datauri'))
    await CreatePrintJob({
      content: content,
      type: Printnode_Content_Type.PdfBase64,
      printer: printer,
      title: `Pick List for ${order.label}`
    })
  }
}

export function GetBase64StringFromGeneratedPdf (base64PdfDataUriString) {
  const base64String = 'base64,'
  const length = base64String.length
  const index = base64PdfDataUriString.indexOf(base64String)
  return base64PdfDataUriString.substr(index + length)
}

/**
 * Sends a print job to print node
 * @param {String} content
 * @param {String} type
 * @param {Number | undefined} expire
 * @param {Number} printer
 * @param {Number | undefined} quantity
 * @param {String} title
 * @param {Object} options
 * @return {Promise<Object>}
 */
export async function CreatePrintJob ({
  content,
  type,
  expire = 0,
  printer,
  quantity = 1,
  title,
  options = false
}) {
  const response = await apollo.mutate({
    mutation: CREATE_PRINT_JOB,
    variables: {
      content: content,
      contentType: type,
      ...(expire && { expireAt: expire }),
      printerId: printer,
      qty: quantity,
      source: store.getters['profile/fullName'],
      title: title,
      ...(options && { options: options })
    }
  })
  return response.data.job
}

/**
 * Takes an object of item details and returns a single label for that item
 * @param pn
 * @param sn
 * @param location
 * @param id
 * @param pt
 * @param ptID
 * @returns {string} label
 */
export function getInventoryLabel ({ pn, sn, location, id, pt, ptID }) {
  return `
CT~~CD,~CC^~CT~
^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI0^XZ
^XA
^MMT
^PW780
^LL0406
^LS0
^FO10,60^ARN,40,35^FDPart Number:^FS
^FO10,120^A0N,30,30^FD${pn}^FS
^FO10,270^ARN,40,35^FDSerial Number:^FS
^FO10,320^A0N,30,30^FD${sn}^FS
^FO300,200^ARN,40,35^FDPT:^FS
^FO300,250^A0N,30,30^FD${pt}^FS
^FO300,60^ARN,40,35^FDLocation:^FS
^FO300,120^A0N,30,30^FD${location}^FS
^FT520,295^BQN,2,6
^FH^FDLA,[{ "id": "${id}", "pn": "${pn}", "sn": "${sn}", "loc": "${location}", "pt": "${pt}" }]^FS
^PQ1,0,1,Y^XZl`
}

/**
 * Returns an individual packing label for individually packed boxes
 * @param pn
 * @param sn
 * @returns {string} label
 */
export function getIndividualItemPackingLabel ({ pn, sn }) {
  return `
 CT~~CD,~CC^~CT~
 ^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI0^XZ
 ^XA
 ^MMT
 ^PW812
 ^LL0406
 ^LS0
 ^FO20,30^ARN,40,35^FD${pn}^FS
 ^FO20,240^ARN,40,35^FD${sn}^FS
 ^BY2,3,81^FT16,150^BCN,,Y,N
 ^FD>:${pn}^FS
 ^BY2,3,81^FT16,360^BCN,,Y,N
 ^FD>:${sn}^FS
 ^PQ1,0,1,Y^XZ`
}

/**
 * Takes a list of items to print and gets their respective labels
 * @param {Array<Object>} items
 */
export function prepInventoryLabelsForPrintJob (items) {
  let zpl = ''
  for (const item of items) {
    zpl += getInventoryLabel(item)
  }
  return zpl
}

/**
 * Prints labels for shipments
 * @param printerId
 * @param {Array<{ id: number, uri: string }>} shipments
 * @return {Promise<void>}
 */
export async function PrintLabelsFromUri (printerId, shipments) {
  try {
    for (const shipment of shipments) {
      await apollo.mutate({
        mutation: CREATE_PRINT_JOB,
        variables: {
          content: shipment.uri,
          contentType: 'raw_uri',
          printerId: printerId,
          qty: 1,
          source: `${store?.state?.profile?.user?.firstName ?? ''} ${store?.state?.profile?.user?.lastName ?? ''}`,
          title: `Shipment Label for ShipmentId: ${shipment.id}`
        }
      })
    }
  } catch (error) {
    if (error.networkError) throw new Error(error.networkError)
    else throw new Error('Could not print labels from printer, it may be disconnected.')
  }
}

/* QUERIES */

export const GET_PRINT_NODE_PRINTERS = gql`
  query GetPrintNodePrinters {
    printers: PrintNode_GetPrinters {
      id
      name
      description
      state
      computer {
        id
        name
        hostname
        state
      }
    }
  }
`

/* MUTATIONS */

export const CREATE_PRINT_JOB = gql`
   mutation CreatePrintJob (
     $content: String!,
     $contentType: PRINTNODE_CONTENT_TYPE!,
     $expireAfter: Int,
     $printerId: ID!,
     $qty: Int,
     $source: String!,
     $title: String!,
     $options: PrintNodeOptionsInput
   ) {
     job: PrintNode_CreatePrintJob (input: {
       content: $content,
       contentType: $contentType,
       expireAfter: $expireAfter,
       printerId: $printerId,
       qty: $qty,
       source: $source,
       title: $title
     }, options: $options)
   }
`
