<template>
  <div>
    <canvas :id="chartId"/>
    <slot name="c-actions" v-bind:chart="chart" v-bind:data="chartData"/>
  </div>
</template>

<script>
import { HtmlGuidGenerator } from '@/lib/helpers'
import Chart from 'chart.js'
import colors from 'vuetify/es5/util/colors'

export default {
  name: 'Chart',
  props: {
    data: {
      type: () => String || Object,
      required: true
    },
    options: {
      type: Object,
      required: false,
      default: () => {}
    },
    type: {
      type: String,
      required: true,
      default: 'line'
    }
  },
  data () {
    return {
      chartId: '',
      chart: null,
      colors: [
        colors.red,
        colors.orange,
        colors.yellow,
        colors.lime,
        colors.green,
        colors.teal,
        colors.blue,
        colors.lightBlue,
        colors.cyan,
        colors.purple,
        colors.pink,
        colors.brown,
        colors.blueGrey,
        colors.grey
      ],

      chartData: null
    }
  },
  methods: {
    async setChart ({ data, type = this.type }) {
      if (this.chart !== null) {
        this.chart.destroy()
      }
      this.chartData = {}
      const stringy = await JSON.stringify(data)
      this.chartData = JSON.parse(stringy)
      if (this.data === 'isTesting') {
        this.chartData = { labels: null, datasets: null }
        this.chartData.labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        this.chartData.datasets = [
          { label: 'A' },
          { label: 'B' },
          { label: 'C' },
          { label: 'D' },
          { label: 'E' }
        ]
        this.chartData.datasets.forEach(d => {
          d.data = this.generateNumbers(this.chartData.labels.length)
        })
      }
      this.chartData.datasets.sort((a, b) => {
        const avgA = a.data.reduce((o, n) => o + n, 0) / a.data.length
        const avgB = b.data.reduce((o, n) => o + n, 0) / b.data.length
        return avgB - avgA
      })
      this.prepColors(this.chartData)
      const average = {
        type: 'line',
        label: 'Average',
        data: this.getAverage(this.chartData.datasets),
        backgroundColor: 'black',
        borderColor: 'black',
        pointBackGroundColor: 'black',
        fill: false
      }
      this.chartData.datasets.unshift(average)
      const el = document.getElementById(this.chartId)
      this.chart = new Chart(el, {
        type: type,
        data: this.chartData,
        options: this.options
      })
      this.isLoading = false
    },

    prepColors (data) {
      let i = 0
      let hue = 'base'
      for (const set of data.datasets) {
        if (this.type === 'line') {
          this.setLineDataSet(set, i, hue)
        } else if (['bar', 'pie'].indexOf(this.type) > -1) {
          this.setNotLineDataSet(set, i, hue)
        }
        i++
        if (i === this.colors.length) {
          hue = 'darken-3'
          i = 0
        } if (i > data.datasets.length / 2 && data.datasets.length > 6) {
          set.hidden = true
        }
      }
    },

    setLineDataSet (set, i, hue) {
      set.borderColor = this.colors[i][hue]
      set.pointBackGroundColor = 'white'
      set.backgroundColor = ''
      set.fill = false
    },

    setNotLineDataSet (set, i, hue) {
      set.borderColor = ''
      set.pointBackGroundColor = ''
      set.backgroundColor = this.colors[i][hue]
    },

    generateNumbers (length) {
      const array = []
      for (let i = 0; i < length; i++) {
        array.push(Math.floor(Math.random() * 99))
      }
      return array
    },

    getAverage (dataset) {
      const averages = []
      let avg = 0
      let j = 0
      let i = 0
      while (j < this.chartData.labels.length) {
        const a = dataset[i]?.data?.[j] ?? 0
        avg += a
        i++
        if (i === dataset.length - 1) {
          averages.push(avg / dataset.length)
          i = 0
          j++
          avg = 0
        }
      }
      return averages
    }
  },
  beforeMount () {
    this.chartId = HtmlGuidGenerator()
  },
  mounted () {
    this.$nextTick(() => {
      this.setChart({ data: this.data })
    })
  }
}
</script>

<style scoped>

</style>
