
/**
 * Transposes a two dimensional array.
 * Rows become columns and columns become rows.
 * @param {any[][]} arr - a two dimensional array of anything
 * @returns {any[][]} - The transposed two dimensional array
 *
 * @example
 * transpose([
 *   ['a1', 'a2', 'a3, 'a4'],
 *   ['b1', 'b2', 'b3, 'b4'],
 *   ['c1', 'c2', 'c3, 'c4'],
 *   ['d1', 'd2', 'd3, 'd4'],
 * ])
 *
 * //=> [
 *   ['a1', 'b1', 'c1', 'd1'],
 *   ['a2', 'b2', 'c2', 'd2'],
 *   ['a3', 'b3', 'c3', 'd3'],
 *   ['a4', 'b4', 'c4', 'd4'],
 * ]
 *
 */
export function transpose (arr: any[][]): any[][] {
  return arr[0].map((_, i) => arr.map(r => r[i]))
}
