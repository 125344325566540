<template>
  <div>
    <v-container>
      <v-layout row wrap justify-start>
        <!-- DIMM Type -->
        <v-flex xs3>
          <choice-list :config="dimmConfig"></choice-list>
        </v-flex>
        <!-- Capacity Amount-->
        <v-flex xs3 offset-xs1>
          <v-text-field
            v-model="capacityAmount"
            label="Capacity Amount"
            :rules="[ e => e > 0 && !isNaN(e) || 'Must be a number > 0' ]"
          ></v-text-field>
        </v-flex>
        <!-- Capacity Unit -->
        <v-flex xs3 offset-xs1>
          <choice-list :config="capacityUnitConfig"></choice-list>
        </v-flex>
        <!-- Speed -->
        <v-flex xs2>
          <choice-list :config="speedConfig"></choice-list>
        </v-flex>
        <!-- CAS -->
        <v-flex xs2 offset-xs1>
          <v-text-field
            v-model="cas"
            label="CAS Latency"
            placeholder="Optional"
            :rules="[ e => ((e !== '' && !isNaN(e)) || (e === '')) || 'Must be a number' ]"
          ></v-text-field>
        </v-flex>
        <!-- CAS Confirm-->
        <v-flex xs2 offset-xs1>
          <v-text-field
            v-model="casConfirm"
            label="CAS Latency Confirm"
            placeholder="Optional"
            :disabled="!cas"
          ></v-text-field>
        </v-flex>
        <!-- PINS -->
        <v-flex xs2 offset-xs1>
          <choice-list :config="pinsConfig"></choice-list>
        </v-flex>
      </v-layout>
    </v-container>
    <standard-actions
      :save-button="{ name: 'Submit', disabled: !canProceed }"
      :cancel-button="{ name: 'Back' }"
      @save="save"
      @cancel="$emit('cancel')"
      @close="$emit('close')"
    ></standard-actions>
  </div>
</template>

<script>
import standardActions from '../standardActions'
import choices from '../../autocompletes/choices'

export default {
  name: 'memory',
  components: {
    'standard-actions': standardActions,
    'choice-list': choices
  },
  computed: {
    canProceed () {
      return this.dimmConfig.id && this.speedConfig.id && this.pinsConfig.id &&
        this.capacity !== '' && ((this.cas === this.casConfirm) || !this.cas)
    }
  },
  data () {
    return {
      dimmConfig: {
        id: 0,
        label: 'DIMM Type',
        appName: 'parts',
        modelName: 'memory',
        fieldName: 'dimm_type'
      },

      capacityAmount: '',
      capacityUnitConfig: {
        id: 0,
        label: 'Capacity Units',
        appName: 'parts',
        modelName: 'storage',
        fieldName: 'capacity_unit'
      },

      speedConfig: {
        id: 0,
        label: 'Speed',
        appName: 'parts',
        modelName: 'memory',
        fieldName: 'speed'
      },

      cas: '',
      casConfirm: '',
      pinsConfig: {
        id: 0,
        label: 'PIN Count',
        appName: 'parts',
        modelName: 'memory',
        fieldName: 'pins'
      }
    }
  },
  methods: {
    save () {
      const details = {
        dimm_type_id: this.dimmConfig.id,
        capacity_amount: this.capacityAmount,
        capacity_unit_id: this.capacityUnitConfig.id,
        speed_id: this.speedConfig.id,
        pins_id: this.pinsConfig.id
      }
      if (this.cas) {
        details.cas_latency = this.cas
      }
      this.$emit('save', details)
    }
  }
}
</script>

<style scoped>

</style>
