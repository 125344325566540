import gql from 'graphql-tag'
// import { GridEvents } from '../../../store/gridEvents'
import errorHandling from '../errorHandling'
import { FRAGMENT__SALE as SALES, GET_SALES } from '@/api/graphql/Constants/Orders'
import { GET_PURCHASES as PURCHASES } from '@/api/graphql/Constants/Orders'

const salesInput = {
  excludes: [],
  filters: [],
  limit: 51,
  offset: 0,
  order_by: ['-id'],
  annotations: [
    {
      name: 'unAllocated',
      aggr_type: 'COUNT',
      aggr_field: 'st_items',
      aggr_filters: [{ key: 'st_items__item__isnull', value: true }]
    },
    {
      name: 'itemCount',
      aggr_type: 'SUM',
      aggr_field: 'st_items',
      aggr_filters: [{ key: 'st_items__id__gt', value: 0 }]
    }
  ]
}

export const SALES_INPUT_FOR_CACHE = salesInput

const purchaseInput = {
  annotations: [{ name: 'unReceivedCount', aggr_type: 'COUNT', aggr_field: 'pt_items', aggr_filters: [{ key: 'pt_items__item__receive_status', value: false }] }],
  excludes: [],
  filters: [],
  limit: 51,
  offset: 0,
  order_by: ['-id']
}

export default {
  mixins: [errorHandling],
  methods: {
    /**
     * Update a sale object
     * @param {Object} saleFields
     * @return {Promise<void>}
     */
    async updateSale (saleFields) {
      try {
        await this.$apollo.mutate({
          mutation: gql`mutation UpdateSalejs ($input: Update__Sales_Sale__Input!) {
            sale: Update__Sales_Sale (input: $input) {
              ...Sale
            }
          }
          ${SALES}
          `,
          variables: { input: saleFields }
        })
        this.getSaleAnnotation(saleFields.id)
      } catch (error) {
        throw new Error(error)
      }
    },

    async getSaleAnnotation (id) {
      try {
        await this.$apollo.query({
          query: gql`query GetAnnotationForSale ($id: ID!) {
            sale: sales_sale (input: {id: $id, annotations: [
              {
                name: "unAllocated",
                aggr_type: COUNT,
                aggr_field: "st_items",
                aggr_filters: [{ key: "st_items__item__isnull", value: true }]
              },
              {
                name: "itemCount",
                aggr_type: SUM,
                aggr_field: "st_items",
                aggr_filters: [{ key: "st_items__id__gt", value: 0 }]
              }
            ]}) {
              ...Sale
            }
          }
          ${SALES}
          `,
          variables: { id: id }
        })
        // GridEvents.$emit('updateNodeData', { id: id, data: response.data.sale })
      } catch (error) {
        throw new Error('Could not retrieve updated sale info, perform manual refresh.')
      }
    }
  }
}
