<template>
  <v-snackbar
    v-model="showSnackbar"
    :color="snackbar.color"
    :top="showTop"
    :bottom="!snackbar.top"
  >
    {{ message }}
    <v-btn
      v-if="timeout === 0"
      flat
      data-cy="hide-snackbar"
      :class="{ 'black--text': snackbar.color === 'success' }"
      @click="$store.dispatch('notifications/hideSnackbar')"
    >
      <v-icon small>fal fa-times</v-icon>
    </v-btn>
    <v-progress-circular
      v-if="timeout > 0"
      :value="value"
      rotate="-90"
      :color="progressColor"
      size="30"
      class="ml-4"
    >
      <v-icon
        v-if="snackbar.canClose"
        small
        data-cy="hide-snackbar"
        :color="progressColor"
        @click="$store.dispatch('notifications/hideSnackbar')"
      >
        fal fa-times
      </v-icon>
    </v-progress-circular>
  </v-snackbar>
</template>

<script>
export default {
  name: 'snackbar',
  computed: {
    snackbar () {
      return this.$store.state.notifications.snackbar
    },

    message () {
      return this.snackbar?.message ?? ''
    },

    showTop () {
      return this.message.toLowerCase().includes('login') ?? false
    },

    timeout () {
      return this.snackbar.timeout
    },

    progressColor () {
      return this.snackbar.color === 'success' ? 'grey darken-2' : 'white'
    },

    fraction () {
      return 100 / (this.timeout / 1000)
    },

    showSnackbar: {
      get () {
        return this.$store.getters['notifications/showSnackbar']
      },
      set (value) {
        !value && this.$store.dispatch('notifications/hideSnackbar')
      }
    }
  },
  data () {
    return {
      interval: {},
      value: 100,
      showClose: false
    }
  },
  mounted () {
    if (this.timeout) {
      this.interval = setInterval(() => {
        if (this.value <= 0) {
          setTimeout(() => {
            this.showSnackbar = false
          }, 1000)
          return (this.value = 0)
        }
        this.value -= this.fraction
      }, 1000)
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>
