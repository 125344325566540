import { computed, ComputedRef, reactive, watch } from '@vue/composition-api'
import { EmittedValue, FormAction, OrderFormObject, RequiredValidationRunner } from '@/composition/UseOrderInformation'
import {
  Address_Address, AddressFullFragment,
  Clients_Contact, QueryFilter, ShipmentOrderToEditFragment,
  Shipping_Account,
  Users_User
} from '@/models/generated/graphql/ErpBackend'

export type ShipFormKey = 'shipTo' | 'shipFrom' | 'billTo' | 'isLocalPickup' | 'blind' | 'wePay' | 'carrierAccount' | 'carrierService' | 'trackingEmails'

interface EasyPostShipOrderFormObject<TValue, TObject> extends OrderFormObject<EmittedValue<TValue>, TObject, string> {
  easypost_id: string
}

type Address = Address_Address | AddressFullFragment
interface UIShipmentCarrierAccount extends EasyPostShipOrderFormObject<Shipping_Account, Shipping_Account> {
  carrierName: string // the name of the carrier (not easypost id or anything)
}

export interface AddressFiltersObject {
  t: ComputedRef<QueryFilter | null> // to
  f: ComputedRef<QueryFilter | null> // from
  b: ComputedRef<QueryFilter | null> // bill
}

export interface ShipOrderForm {
  [key: string]: any
  shipTo: EasyPostShipOrderFormObject<Address, Address>
  contactTo: OrderFormObject<EmittedValue<Clients_Contact>, Clients_Contact, string>
  repTo: OrderFormObject<EmittedValue<Users_User>, Users_User, string>
  shipFrom: EasyPostShipOrderFormObject<Address, Address>
  contactFrom: OrderFormObject<EmittedValue<Clients_Contact>, Clients_Contact, string>
  repFrom: OrderFormObject<EmittedValue<Users_User>, Users_User, string>
  billTo: EasyPostShipOrderFormObject<Address, Address>
  contactBill: OrderFormObject<EmittedValue<Clients_Contact>, Clients_Contact, string>
  repBill: OrderFormObject<EmittedValue<Users_User>, Users_User, string>
  isLocalPickup: { value: boolean }
  blind: { value: string },
  wePay: { value: boolean }
  carrierAccount: UIShipmentCarrierAccount,
  carrierService: OrderFormObject<EmittedValue<string>, undefined, string>
  trackingEmails: { value: string, validator: () => void, message: string },
  shipmentId: string, // the new shipment id being made
  companyReturnToAddressId: string
}

export interface UseShippingReturn {
  form: ShipOrderForm
  ValidateShipping: (action: FormAction) => void
  getShipFromContact: (contactId: string) => undefined | string
  hasErrors: ComputedRef<boolean>
  apply: (order: ShipmentOrderToEditFragment) => void
}

/**
 * @return {UseShippingReturn}
 */
export function useShipping (): UseShippingReturn {
  const form: ShipOrderForm = reactive({
    shipTo: {
      value: '',
      easypost_id: '',
      // @ts-ignore
      object: {},
      validator: (action: FormAction) => {
        let message = ''
        if (action === 'SAVE' || form.blind.value) {
        } else {
          message = 'This is required to continue.'
        }
        form.shipTo.message = message
      },
      message: '',
      setter: event => {
        const address = event.value
        form.shipTo.value = address?.id ?? ''
        form.shipTo.easypost_id = address?.easypost_id ?? ''
        if (address) form.shipTo.object = address
        form.shipTo.message = ''
      }
    },
    contactTo: {
      value: '',
      // @ts-ignore
      object: {},
      validator: (action: FormAction) => null,
      message: '',
      setter: event => {
        const contact = event.value
        form.contactTo.value = contact?.id ?? ''
        form.contactTo.object = contact
        form.contactTo.message = ''
        form.repTo.value = ''
      }
    },
    repTo: {
      value: '',
      // @ts-ignore
      object: {},
      validator: (action: FormAction) => null,
      message: '',
      setter: (event) => {
        const rep = event.value
        form.repTo.value = rep?.id ?? ''
        form.repTo.object = rep
        form.repTo.message = ''
        form.contactTo.value = ''
      }
    },
    shipFrom: {
      value: '',
      easypost_id: '',
      // @ts-ignore
      object: {},
      validator: (action: FormAction) => RequiredValidationRunner(action, form.shipFrom),
      message: '',
      setter: event => {
        const address = event.value
        form.shipFrom.value = address?.id ?? ''
        form.shipFrom.easypost_id = address?.easypost_id ?? ''
        if (address) form.shipFrom.object = address
        form.shipFrom.message = ''
      }
    },
    contactFrom: {
      value: '',
      // @ts-ignore
      object: {},
      validator: (action: FormAction) => null,
      message: '',
      setter: event => {
        const contact = event.value
        form.contactFrom.value = contact?.id ?? ''
        form.contactFrom.object = contact
        form.contactFrom.message = ''
        form.repFrom.value = ''
      }
    },
    repFrom: {
      value: '',
      // @ts-ignore
      object: {},
      validator: (action: FormAction) => null,
      message: '',
      setter: event => {
        const rep = event.value
        form.repFrom.value = rep?.id ?? ''
        form.repFrom.object = rep
        form.repFrom.message = ''
        form.contactFrom.value = ''
      }
    },
    billTo: {
      value: '',
      easypost_id: '',
      // @ts-ignore
      object: {},
      validator: (action: FormAction) => RequiredValidationRunner(action, form.billTo),
      message: '',
      setter: event => {
        const address = event.value
        form.billTo.value = address?.id ?? ''
        form.billTo.easypost_id = address?.easypost_id ?? ''
        form.billTo.message = ''
        if (address) form.billTo.object = address
      }
    },
    contactBill: {
      value: '',
      // @ts-ignore
      object: {},
      validator: (action: FormAction) => null,
      message: '',
      setter: event => {
        const contact = event.value
        form.contactBill.value = contact?.id ?? ''
        form.contactBill.object = contact
        form.contactBill.message = ''
        form.repBill.value = ''
      }
    },
    repBill: {
      value: '',
      // @ts-ignore
      object: {},
      validator: (action: FormAction) => null,
      message: '',
      setter: event => {
        const rep = event.value
        form.repBill.value = rep?.id ?? ''
        form.repBill.object = rep
        form.repBill.message = ''
        form.contactBill.value = ''
      }
    },
    isLocalPickup: {
      value: false
    },
    blind: {
      value: 'NOT_BLIND'
    },
    wePay: {
      value: false
    },
    carrierAccount: {
      value: '',
      easypost_id: '',
      carrierName: '',
      // @ts-ignore
      object: {},
      validator: (action: FormAction) => {
        let message = ''
        if (action === 'SAVE' || form.isLocalPickup.value || form.carrierAccount.value) {} else {
          message = 'This is required to continue'
        }
        form.carrierAccount.message = message
      },
      message: '',
      setter: event => {
        const account = event.value
        form.carrierAccount.value = account?.id ?? ''
        form.carrierAccount.easypost_id = account?.easypost_id ?? ''
        form.carrierAccount.carrierName = account?.shipper?.name ?? ''
        form.carrierAccount.message = ''
        form.carrierAccount.object = account
      }
    },
    carrierService: {
      value: '',
      validator: (action: FormAction) => {
        let message = ''
        if (action === 'SAVE' || form.isLocalPickup.value || form.carrierService.value) {
        } else {
          message = 'This is required to continue'
        }
        form.carrierService.message = message
      },
      message: '',
      setter: event => {
        form.carrierService.value = event?.value ?? ''
        form.carrierService.message = ''
      }
    },
    trackingEmails: {
      value: '',
      message: '',
      validator: () => {
        const emails = form.trackingEmails.value.split(' ').filter(f => f.length > 0)
        let message = ''
        let invalid = ''
        for (const e of emails) {
          if (!(/[A-Z0-9._%+-]+@[A-Z0-9.-]+[.]+.[a-z]{1,4}/igm).test(e)) invalid += `${e} `
        }
        if (invalid) message = invalid + 'is/are not valid email(s)'
        form.trackingEmails.message = message
      }
    },
    shipmentId: '',
    companyReturnToAddressId: ''
  })

  watch(() => form.blind.value, () => {
    form.isLocalPickup.value = false
    // @ts-ignore
    form.shipFrom.setter({ value: {} })
    // @ts-ignore
    form.shipTo.setter({ value: {} })
  })
  watch(() => form.isLocalPickup.value, () => {
    form.blind.value = 'NOT_BLIND'
  })

  function ValidateShipping (action: FormAction) {
    for (const key in form) {
      if (form[key].validator) {
        form[key].validator(action)
      }
    }
  }

  const getShipFromContact = (contactId: string): undefined | string => {
    if (form.blind.value === 'BLIND') {
      return contactId
    } else return undefined
  }

  const hasErrors = computed(() => {
    for (const key in form) {
      // @ts-ignore
      const obj = form[key]
      if (obj.message) return true
    }
    return false
  })

  const apply = (order: ShipmentOrderToEditFragment) => {
    form.shipTo.setter({ value: order.ship_to_address })
    // @ts-ignore
    form.contactTo.setter({ value: order.ship_to_contact })
    // @ts-ignore
    form.repTo.setter({ value: order.ship_to_rep })
    form.shipFrom.setter({ value: order.ship_from_address })
    // @ts-ignore
    form.contactFrom.setter({ value: order.ship_from_contact })
    // @ts-ignore
    form.repFrom.setter({ value: order.ship_from_rep })
    form.billTo.setter({ value: order.purchaser_address })
    // @ts-ignore
    form.contactBill.setter({ value: order.purchaser_contact })
    // @ts-ignore
    form.repBill.setter({ value: order.purchaser_rep })

    form.isLocalPickup.value = order.pickup_type !== 'NOT_PICKUP'
    form.blind.value = order.blind
    // @ts-ignore
    form.carrierAccount.setter({ value: order.account })
    form.carrierService.setter({ value: order.service?.id ?? '' })
    form.trackingEmails.value = order.tracking_email_recipients?.join(' ') ?? ''
    form.shipmentId = order.id
  }

  return {
    form,
    ValidateShipping,
    getShipFromContact,
    hasErrors,
    apply
  }
}
