<template>
  <v-card :height="cardHeight" v-resize="setHeight">
    <v-card-title class="successAlert" id="bsCardTitle">
      <h3 class="white--text">Buy Sell History: {{ currentPart.pn }}</h3>
    </v-card-title>
    <v-card-text :style="{ 'height': textHeight+'px' }" id="bsCardText">
      <v-container grid-list-xl pa-0 fluid fill-height>
        <v-layout column justify-start align-space-around fill-height>
          <v-flex xs1 py-0>
            <v-layout row wrap justify-space-between align-center>
              <!-- Part Number -->
              <v-flex xs5>
                <parts-autofill
                  :selected-part="part"
                  @updated="newSearch"
                />
              </v-flex>
              <!-- Description -->
              <v-flex xs5>
                <v-text-field
                  readonly
                  box
                  v-model="currentPart.description"
                  label="Description"
                />
              </v-flex>
              <!-- Manufacturer
              <v-flex xs2>
                <v-text-field
                  readonly
                  box
                  v-model="currentPart.mfgr.name"
                  label="Mfgr"
                />
              </v-flex>
              -->
            </v-layout>
          </v-flex>
          <!-- Grid -->
          <v-flex xs11>
            <grid-wrapper
              :contextMenuItems="contextMenuItems"
              :query="bsItems"
              :filter="filter"
              :componentName="$options.name"
              :columns="columns"
              :nested-grid="false"
              @ready="ready"
              @cell-key-down="gridKbEventSender"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
    <v-card-actions class="align-end" id="bsCardAction">
      <v-container fluid py-0>
        <v-layout row nowrap justify-space-between align-center>
          <v-flex xs9>
            <v-layout row justify-space-between>
              <span class="descriptor level0-days-away">Less than 30 Days</span>
              <span class="descriptor level1-days-away">Less than 60 Days</span>
              <span class="descriptor level2-days-away">Less than 90 Day</span>
              <span class="descriptor level3-days-away">More than 90 Days</span>
            </v-layout>
          </v-flex>
          <v-spacer/>
          <v-flex>
            <v-layout row justify-end>
              <v-btn
                shrink
                color="info"
                @click="$store.dispatch('grid/resetDialog')"
              >Close</v-btn>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from 'vue'
import GridWrapper from '@/components/wrappers/gridWrapper.vue'
import grid from '../mixins/grid'
import columns from '../mixins/columns'
import { COLUMNS__BUY_SELL_HISTORY } from '@/lib/agGridColumnConfiguration'
import { CONTEXT_MENU__BUY_SELL_HISTORY } from '@/lib/agGridContextMenuConfigurations'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import { KB_SHORTCUT__MASTER_BUY_SELL_HISTORY } from '@/lib/agGridKbShortCuts'
import PartsAutofill from '@/components/autocompletes/PartsAutofill'
import { GET_BUY_SELL_HISTORY } from '@/api/graphql/Constants/Inventory'
export default {
  name: 'buySellHistory',
  mixins: [grid, columns],
  components: {
    'grid-wrapper': GridWrapper,
    'parts-autofill': PartsAutofill
  },
  computed: {
    part () {
      return this.$store.state.grid.buySellHistoryPart
    }
  },
  data () {
    return {
      bsItems: GET_BUY_SELL_HISTORY,
      filter: [],

      contextMenuItems: (params) => CONTEXT_MENU__BUY_SELL_HISTORY(params),

      columns: [],
      currentPart: {},

      cardHeight: 0,
      textHeight: 0
    }
  },
  methods: {
    gridKbEventSender (params) {
      GridKbEventHandler(params, KB_SHORTCUT__MASTER_BUY_SELL_HISTORY, false)
    },

    ready (params) {
      this.gapi = params.api
      this.capi = params.capi
      this.gridOptions = params

      const sort = [{ colId: 'sDate', sort: 'asc' }]
      this.gapi.setSortModel(sort)
    },

    newSearch (part) {
      if (part) {
        part.mfgr = this.$_.get(part, 'mfgr.name', '')
        Vue.set(this, 'currentPart', part)
        this.filter[0].value = part.id
        this.gapi.onFilterChanged()
      }
    },

    setHeight () {
      this.cardHeight = window.innerHeight * 0.90
      this.getTextHeight()
    },

    getTextHeight () {
      const titleElementHeight = document.getElementById('bsCardTitle')?.offsetHeight ?? 0
      const actionElementHeight = document.getElementById('bsCardAction')?.offsetHeight ?? 0

      this.textHeight = this.cardHeight - (titleElementHeight + actionElementHeight)
    }
  },
  beforeMount () {
    this.currentPart = this.part
    this.filter = [{ key: 'part__id', value: this.currentPart.id }]
    this.columns = COLUMNS__BUY_SELL_HISTORY
  },
  mounted () {
    this.getTextHeight()
  }
}
</script>
