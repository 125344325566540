import gql from 'graphql-tag'
import { FRAGMENT__ADDRESS__LIST } from '@/api/graphql/Constants/Addresses'

/* FRAGMENTS */

export const FRAGMENT__CONTACT__LIST = gql`
  fragment Contact_List on Clients_Contact {
    id
    full_name
    email
    phone
    owner { id }
  }
`

export const FRAGMENT__CONTACT__GRID = gql`
  fragment Contact_Grid on Clients_Contact {
    ...Contact_List
    fax
    first_name
    last_name
    default_billing_for_contact { id }
    default_remit_for_contact { id }
    default_ship_to_for_contact { id }
  }
  ${FRAGMENT__CONTACT__LIST}
`

export const FRAGMENT__CONTACT__FULL = gql`
  fragment Contact_Full on Clients_Contact {
    ...Contact_Grid
    attached_address { ...AddressList }
  }
  ${FRAGMENT__ADDRESS__LIST}
  ${FRAGMENT__CONTACT__GRID}
`

export const FRAGMENT__LIST_CONTACT = gql`
  fragment ListContact on Clients_Contact {
    id
    title: full_name
    subtitle: email
    phone
  }
`

export const FRAGMENT__LIST_USER = gql`
  fragment ListUser on Users_User {
    id
    title: full_name
    subtitle: initials
    email
  }
`

/* QUERIES */

export const GET_CONTACTS__LIST = gql`
  query GetContacts_List ($input: ManyQueryInput!) {
    clients_contacts (input: $input) {
      ...Contact_List
    }
  }
  ${FRAGMENT__CONTACT__LIST}
`

export const GET_CONTACTS__GRID = gql`
  query GetContacts_Grid ($input: ManyQueryInput!) {
    clients_contacts (input: $input) {
      ...Contact_Grid
    }
  }
  ${FRAGMENT__CONTACT__GRID}
`

export const GET_CONTACTS__FULL = gql`
  query GetContacts_Full ($input: ManyQueryInput!) {
    clients_contacts (input: $input) {
      ...Contact_Full
    }
  }
  ${FRAGMENT__CONTACT__FULL}
`

export const GET_CONTACT__FULL = gql`
  query GetContact_Full ($input: SingleQueryInput!) {
    clients_contact (input: $input) {
      ...Contact_Full
    }
  }
  ${FRAGMENT__CONTACT__FULL}
`

export const GET_CONTACT_TO_EDIT = gql`
  query GetContactToEdit ($id: Any!) {
    clients_contact (input: { filters: [{ key: "owner_id", value: $id }]}) {
      ...Contact_Grid
    }
  }
  ${FRAGMENT__CONTACT__GRID}
`

/* MUTATIONS */

export const CREATE_CONTACT = gql`
  mutation CreateContact ($input: Create__Clients_Contact__Input!) {
    Create__Clients_Contact (input: $input) {
      ...Contact_Full
    }
  }
  ${FRAGMENT__CONTACT__FULL}
`

export const UPDATE_CONTACT = gql`
  mutation UpdateContact ($input: Update__Clients_Contact__Input!) {
    Update__Clients_Contact (input: $input) {
      ...Contact_Full
    }
  }
  ${FRAGMENT__CONTACT__FULL}
`

export const DELETE_CONTACT = gql`
    mutation DeleteContact ($id: ID!) {
      Delete__Clients_Contact (id: $id) { id }
    }
`
