import gql from 'graphql-tag'
import { apolloClient as apollo } from '@/api/graphql/apollo'
import { FRAGMENT__ADDRESS__LIST } from '@/api/graphql/Constants/Addresses'

export function GetCompanyAndClientAddressesForList ({ client, noCompany }) {
  return new Promise((resolve, reject) => {
    apollo.query({
      query: gql`
        query CompanyAndClientAddresses ($client: Any!, $noCompany: Any!) {
          client: address_addresses (input: { filters: [{ key: "client__id", value: $client }] }) {
            ...AddressList
          }
          other: address_addresses (input: { filters: [{ key: "company__isnull", value: $noCompany }]}) {
            ...AddressList
          }
        }
        ${FRAGMENT__ADDRESS__LIST}
      `,
      variables: { client: client, noCompany: noCompany }
    }).then(({ data: { client, other } }) => {
      client.forEach(a => {
        a.title = `${a.street_1} ${a.city}, ${a.state}`
        a.subtitle = a.client.name
        return a
      })
      other.forEach(a => {
        a.title = `${a.street_1} ${a.city}, ${a.state}`
        a.subtitle = a.client?.name ?? a.company?.name ?? ''
        return a
      })
      const obj = {
        client: client,
        other: other
      }
      resolve(obj)
    }).catch(error => {
      reject(error)
    })
  })
}
