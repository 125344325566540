import { ApolloLink, fromPromise, NextLink, Operation } from 'apollo-link'
import store from '@/store/store'
import { LOCAL_STORAGE_KEYS, locStore } from '@/lib/LocalStorageWrapper'

class WaitLink extends ApolloLink {
  private waiting: boolean = false
  private pendingOps: Array<() => void> = []

  request (operation: Operation, forward: NextLink) {
    const context = operation.getContext()
    if (context.wait) {
      if (!this.waiting) {
        this.setWaiting(true)
        return fromPromise(
          store.dispatch('auth/renewAccessToken').catch(() => {
            this.forwardPendingOps()
            store.dispatch('auth/logout', { expire: true })
            locStore.clear()
            return forward(operation)
          })
        ).flatMap(() => {
          this.forwardPendingOps()
          this.setWaiting(false)
          this.updateAuth(operation)
          return forward(operation)
        })
      } else {
        return fromPromise(
          new Promise(resolve => {
            this.addToPendingOps(() => {
              this.updateAuth(operation)
              resolve()
            })
          })
        ).flatMap(() => forward(operation))
      }
    } else {
      return forward(operation)
    }
  }

  private forwardPendingOps () {
    this.pendingOps.map(callback => callback())
    this.pendingOps = []
  }

  private updateAuth (operation: Operation) {
    operation.setContext(({ headers }: { headers: Headers }) => ({
      headers: {
        ...headers,
        'Authorization': `Bearer ${locStore.get(LOCAL_STORAGE_KEYS.accessToken)}`
      }
    }))
  }

  private setWaiting (value: boolean) {
    this.waiting = value
  }

  private addToPendingOps (op: () => void) {
    this.pendingOps.push(op)
  }
}

export default WaitLink
