<template>
  <div>
    <v-btn
      v-if="$vuetify.breakpoint.mdAndUp"
      data-cy="submit"
      color="primary"
      :loading="loading"
      :disabled="disabled || loading"
      @click="$emit('click')"
    ><slot name="label">Submit</slot>
    </v-btn>
    <v-btn
      v-else
      icon
      dark
      data-cy="submit"
      :loading="loading"
      :disabled="disabled || loading"
      @click="$emit('click')"
    >
      <v-icon
        color="primary"
      >far fa-save</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'SubmitButton',
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
