<template>
  <v-textarea
    v-model.trim="localComment"
    :readonly="readonly"
    no-resize
    height="160px"
    box
    :hint="hint"
    persistent-hint
    :id="id"
    :color="darkMode ? 'white' : 'blue'"
    :background-color="bgColor"
    @keydown.tab.exact.prevent="jump"
  >
    <template v-slot:append>
      <slot name="append"/>
    </template>
    <template #message="{ key, message }">
      <span :key="key" v-html="message"/>
    </template>
    <template v-slot:label>
      <slot name="label"/>
    </template>
  </v-textarea>
</template>

<script>
import AutoSuggest from './liveCommentUtils/AutoSuggest'
import { GET_LIVE_COMMENTS } from '@/api/graphql/Constants/Profile'
export default {
  name: 'liveCommentArea',
  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    comment: {
      type: String,
      required: true
    },
    bgColor: {
      type: String,
      required: false,
      default: 'white'
    },
    saveButtonId: { // the id of the button to focus when hitting tab
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    darkMode () {
      return this.$store.state.profile.theme === 'dark'
    },

    user () {
      return this.$store.getters['profile/user']
    },

    localComment: {
      get () {
        return this.comment
      },
      set (value) {
        this.$emit('updateComment', value)
      }
    }
  },
  data () {
    return {
      commented: false,
      searching: false,
      firstJumpCharacter: '[',
      secondJumpCharacter: ']',

      templates: [],
      matches: [],

      hint: 'Type <span class="emphasis px-2">/</span> to use templates'
    }
  },
  methods: {
    jump () {
      const textArea = document.getElementById(this.id)
      const index = textArea.value.search(/\[.*?\]/)
      const text = textArea.value
      if (index > -1) {
        const lastChar = text.indexOf(']')
        textArea.selectionStart = index
        textArea.selectionEnd = lastChar + 1
        textArea.value = text.substring(0, lastChar + 1)
        textArea.scrollTop = textArea.scrollHeight
        textArea.value = text
        textArea.setSelectionRange(index, lastChar + 1)
      } else if (!this.localComment.startsWith('/')) {
        // textArea.selectionStart = textArea.value.length
        document.getElementById(this.saveButtonId).focus()
      }
    }

    /* highlight () {
      let area = document.getElementById('live-comment-area')
      area.value = area.value.replace(/\[.*?]/g, function (s) {
        return `<span class="text--hint">${s}</span>`
      })
    } */ // can only be done with contenteditable div not v-textarea
  },
  mounted () {
    const el = document.getElementById(this.id)
    const autoSuggest = new AutoSuggest({
      caseSensitive: false,
      onChange: (template) => {
        this.localComment = template.comment
        this.jump()
      },
      suggestions: [
        {
          trigger: '/',
          values: async ($0, callback) => {
            const observer = await this.$apollo.watchQuery({
              query: GET_LIVE_COMMENTS,
              variables: { id: this.user.id }
            })
            observer.subscribe(response => {
              if (response.data) {
                callback(response.data.templates.filter(t => {
                  return t.name.toLowerCase().indexOf($0.toLowerCase()) > -1
                }))
              }
            })
            // }
          }
          // values: self.templates
        }
      ]
    }, el)
    autoSuggest.addInputs(el)
  }
}
</script>

<style>
  .autosuggest-loader {
    color: #2196F3;
    font-size: 6px;
    margin: 24px auto;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }

  @-webkit-keyframes load4 {
    0%, 100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }

  @keyframes load4 {
    0%, 100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
</style>
