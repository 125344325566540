const commonAutofillFilters = {
  methods: {
    nameFilter (item, queryText) {
      if (item.short_name) {
        const name = item.name.toLowerCase()
        const short = item.short_name.toLowerCase()
        const text = queryText.toLowerCase()
        return name.indexOf(text) > -1 || short.indexOf(text) > -1
      } else if (item.street_1) {
        const street1 = item.street_1.toLowerCase()
        const city = item.city.toLowerCase()
        const name = item.client?.name ?? item.company?.name ?? ''
        const text = queryText.toLowerCase()
        return name.indexOf(text) > -1 || street1.indexOf(text) > -1 || city.indexOf(text) > -1
      } else {
        const name = item.name.toLowerCase()
        const text = queryText.toLowerCase()
        return name.indexOf(text) > -1
      }
    },

    idFilter (item, queryText) {
      return item.id.indexOf(queryText) > -1
    },

    repFilter (item, queryText) {
      const name = item.full_name.toLowerCase()
      const initials = item.initials.toLowerCase()
      const text = queryText.toLowerCase()
      return name.indexOf(text) > -1 || initials.indexOf(text) > -1
    },

    contactFilter (item, queryText) {
      let name = item.first_name + ' ' + item.last_name
      const email = item.email.toLowerCase()
      name = name.toLowerCase()
      const text = queryText.toLowerCase()
      return name.indexOf(text) > -1 || email.indexOf(text) > -1
    },

    partsFilter (item, queryText) {
      const partNumber = item.pn.toLowerCase()
      return partNumber.indexOf(queryText.toLowerCase()) > -1
    },

    shipAccountFilter (item, queryText) {
      const desc = item.description.toLowerCase()
      const number = item.number
      const company = item.name
      const text = queryText.toLowerCase()
      return desc.indexOf(text) > -1 || number.indexOf(text) > -1 || company.indexOf(text) > -1
    }
  }
}

export default commonAutofillFilters
