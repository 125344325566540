import gql from 'graphql-tag'
import { FRAGMENT__USER_MINIMAL } from '@/api/graphql/Constants/Users'
import { FRAGMENT__MONEY } from '@/api/graphql/Constants/TERMS'

/* FRAGMENTS */

export const FRAGMENT__BASIC_PART = gql`
  fragment BasicPart on Parts_Part {
    id
    brand {
      id
      name
      short_name
    }
    description
    has_battery
    mfgr {
      id
      name
      short_name
    }
    pn
    type
    sku
  }
`

export const FRAGMENT__BASIC_SYSTEM = gql`
  fragment BasicSystem on Inventory_System {
    allocated_date
    alternate_serial_number
    associated_pn {
      associated_pn
    }
    audit_date
    break_down_orders {
      id
      status {
        id
      }
    }
    checked_out_by {
      ...UserMinimal
    }
    condition {
      id
      choice
      sub_choice
    }
    condition_comment
    country_of_origin {
      name
      code
      unicode_flag
    }
    creation_date
    creator {
      id
      initials
      full_name
    }
    fw
    grade {
      id
      choice
      sub_choice
    }
    hot
    id
    internal
    inventory_comment
    is_lost
    last_write_down_date
    location {
      id
      name
      site {
        id
        name
        short_name
      }
    }
    lock_reason
    locked
    next_write_down_date
    part {
      ...BasicPart
    }
    purchase_rma_item_details {
      id
      rma {
        id
      }
    }
    purchases_items_details {
      id
      line_number
      original_cost { ...Money }
      current_cost { ...Money }
      transaction {
        id
        rep {
          id
          initials
        }
      }
    }
    quantity
    receive_status
    received_by {
      ...UserMinimal
    }
    received_date
    rma_item_details {
      id
      rma {
        id
      }
    }
    sales_items_details {
      id
      line_number
      sold_for { ...Money }
      transaction {
        id
        rep {
          id
          initials
        }
      }
    }
    serial_number
    shipment {
      id
    }
    system_logs { id action { id action } }
    test_status {
      id
      tag
      description
      color
    }
    unbooked_date
    updated_date
  }
  ${FRAGMENT__USER_MINIMAL}
  ${FRAGMENT__BASIC_PART}
  ${FRAGMENT__MONEY}
`

export const FRAGMENT__BASIC_ITEM = gql`
  fragment InventoryItem on Inventory_Item {
    allocated_date
    alternate_serial_number
    associated_pn {
      associated_pn
    }
    audit_date
    checked_out_by {
      ...UserMinimal
    }
    condition {
      id
      choice
      sub_choice
    }
    condition_comment
    country_of_origin {
      name
      code
      unicode_flag
    }
    creation_date
    creator {
      id
      initials
      full_name
    }
    fw
    grade {
      id
      choice
      sub_choice
    }
    hot
    id
    internal
    inventory_comment
    is_lost
    last_write_down_date
    location {
      id
      name
      site {
        id
        name
        short_name
      }
    }
    lock_reason
    locked
    next_write_down_date
    part {
      ...BasicPart
    }
    purchase_rma_item_details {
      id
      rma {
        id
      }
    }
    purchases_items_details {
      id
      line_number
      current_cost { ...Money }
      original_cost { ...Money }
      transaction {
        id
        rep {
          id
          initials
        }
      }
    }
    quantity
    receive_status
    received_by {
      ...UserMinimal
    }
    received_date
    rma_item_details {
      id
      rma {
        id
      }
    }
    sales_items_details {
      id
      line_number
      sold_for { ...Money }
      transaction {
        id
        rep {
          id
          initials
        }
      }
    }
    serial_number
    shipment {
      id
    }
    system {
      id
    }
    system_logs { id action { id action } }
    test_status {
      id
      tag
      description
      color
    }
    unbooked_date
    updated_date
  }
  ${FRAGMENT__USER_MINIMAL}
  ${FRAGMENT__BASIC_PART}
  ${FRAGMENT__MONEY}
`

export const FRAGMENT__FULL_PART = gql`
  fragment FullPart on Parts_Part__Interface {
    alt_part_for_client {
      id
    }
    alt_part_for_part {
      id
      pn
    }
    brand {
      id
      name
      short_name
    }
    description
    ean
    has_battery
    hs_code
    htsus_code
    id
    mfgr {
      id
      name
      short_name
    }
    pn
    schedule_b_code
    secondary_pn
    sku
    type
    upc
  }
`

/* QUERIES */

export const GET_UNALLOCATED_ITEMS = gql`
  query UnAllocated ($filters: [QueryFilter!], $order: [String!]) {
    unAllocatedParts: inventory_items (input: { filters: $filters, order_by: $order, limit: 200 }) {
      id
      purchases_items_details {
        id
        current_cost { ...Money }
        line_number
        original_cost { ...Money }
        transaction {
          id
          rep {
            id
            initials
          }
        }
      }
      part {
        id
        pn
      }
      sales_items_details {
        id
      }
      received_date
      serial_number
    }
  }
  ${FRAGMENT__MONEY}
`

export const ITEMS = gql`
  query Items ($input: ManyQueryInput!) {
    items: inventory_items (input: $input) {
      id
      serial_number
      receive_status
      location {
        id
        name
        site {
          id
          name
        }
      }
      purchaseItem: purchases_items_details {
        id
        line_number
        original_cost { ...Money }
      }
      saleItem: sales_items_details {
        id
        transaction {
          id
        }
      }
      locked
      hot
      is_lost
      country_of_origin {
        code
        name
        flag: unicode_flag
      }
      test_status {
        id
        tag
        color
      }
      part {
        id
        pn
        description
      }
    }
  }
`

/*
export const GRID__GET_ITEMS = columns => {
  return gql`
    query GRID__GetItems ($input: ManyQueryInput!) {
      items: inventory_items (input: $input) {
        ${columns}
        condition_comment
        inventory_comment
        lock_reason
      }
    }
  `
}
*/

export const GET_ITEMS = gql`
  query GetItems ($input: ManyQueryInput!) {
    items: inventory_items (input: $input) {
      ...InventoryItem
    }
  }
  ${FRAGMENT__BASIC_ITEM}
`

/* export const GRID__GET_SYSTEMS = columns => {
  return gql`
    query GRID__GetSystem ($input: ManyQueryInput!) {
      systems: inventory_systems (input: $input) {
        ${columns}
        add_on_items {
          id
        }
        condition_comment
        inventory_comment
        lock_reason
      }
    }
  `
} */

export const GET_SYSTEMS = gql`
  query GetSystems ($input: ManyQueryInput!) {
    systems: inventory_systems (input: $input) {
      ...BasicSystem
      oobmgmt_serial
      annotations { name value }
    }
  }
  ${FRAGMENT__BASIC_SYSTEM}
`

export const ITEMS_FOR_BUILDUP = gql`
  query GetItemsForBuildUp ($pn: Any!) {
    items: inventory_items (input: { filters: [{ key: "part__pn", value: $pn }, { key: "sales_items_details__isnull", value: true },
      { key: "build_up_items__isnull", value: true }, { key: "system__build_up_items__isnull", value: true }] }) {
      id
      build_up_items {
        id
      }
      serial_number
      part {
        id
        pn
        description
      }
      pt: purchases_items_details {
        id
        line_number
        transaction {
          id
        }
      }
    }
  }
`

export const ITEMS_FOR_BREAKDOWN = gql`
  query GetItemsForBreakdown ($pn: Any!) {
    items: inventory_items (input: { filters: [{ key: "part__pn", value: $pn }, { key: "sales_items_details__isnull", value: true }] }) {
      id
      serial_number
      part {
        id
        pn
        description
      }
      pt: purchases_items_details {
        id
        line_number
        transaction {
          id
        }
      }
    }
  }
`

export const ITEM_FROM_PT_ID = gql`
  query ItemFromPtID ($pt: Any!, $line: Any!) {
    item: inventory_item (input: { filters: [
      {
        key: "purchases_items_details__transaction__id",
        value: $pt
      },
      {
        key: "purchases_items_details__line_number",
        value: $line
      },
      {
        key: "build_up_items__isnull",
        value: true
      }
    ]}) {
      id
      part {
        id
        pn
      }
    }
  }
`

export const MASTER_PART_SALES_ITEMS = gql`
  query MasterPartSalesItems ($input: ManyQueryInput!) {
    sold_items: sales_sold_items (input: $input) {
      id
      creation_date
      sold_for { ...Money }
      transaction {
        id
        creation_date
        rep {
          id
          initials
        }
        client {
          id
          name
        }
      }
    }
  }
  ${FRAGMENT__MONEY}
`

export const MASTER_PART_PURCHASES_ITEMS = gql`
  query MasterPartPurchasesItems ($input: ManyQueryInput!) {
    purchased_items: purchases_purchased_items (input: $input) {
      id
      creation_date
      transaction {
        id
        creation_date
        rep {
          id
          initials
        }
        client {
          id
          name
        }
      }
      original_cost { ...Money }
      current_cost { ...Money }
    }
  }
  ${FRAGMENT__MONEY}
`

export const MASTER_PART_QUOTES_ITEMS = gql`
  query MasterPartQuotesItems ($input: ManyQueryInput!) {
    quote_parts: quotes_quote_parts (input: $input) {
      id
      creation_date
      annotations {
        name
        value
      }
      quote {
        id
        attached_sales {
          id
        }
        client {
          id
          name
        }
        contact {
          id
          full_name
        }
      }
      price_per_part { ...Money }
      qty
    }
  }
  ${FRAGMENT__MONEY}
`

export const MASTER_PART_SUB_PARTS = gql`
  query MasterPartSubParts ($input: ManyQueryInput!) {
    sub_parts: parts_parts (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const GET_SYSTEM = gql`
  query GetSystem ($id: ID!) {
    inventory_system (input: { id: $id }) {
      id
      serial_number
      part {
        id
        pn
        description
      }
      purchases_items_details {
        id
        line_number
        current_cost { ...Money }
        original_cost { ...Money }
        transaction {
          id
        }
      }
    }
  }
  ${FRAGMENT__MONEY}
`

export const GRID_GET_ADD_ON_ITEMS_FOR_SYSTEM = gql`
  query GetAddOnItemsForSystem ($input: ManyQueryInput!) {
    items: inventory_items (input: $input) {
      id
      broke_down_items {
        id
        status {
          id
          status
        }
      }
      part {
        id
        pn
        description
      }
      serial_number
      system {
        id
      }
      purchases_items_details {
        id
        line_number
        transaction {
          id
        }
        original_cost { ...Money }
        current_cost { ...Money }
      }
    }
  }
    ${FRAGMENT__MONEY}
`

export const GET_PARTS_FOR_AUTOFILL = gql`
  query BasicPartInfo ($filters: [QueryFilter!], $excludes: [QueryFilter!]) {
    parts: parts_parts (input: { filters: $filters, excludes: $excludes }) {
      id
      title: pn
      subtitle: description
      secondary_pn
    }
  }
`

export const MASTER_PARTS = gql`
  query MasterParts ($input: ManyQueryInput!) {
    parts: parts_parts (input: $input) {
      ...BasicPart
      ean
      hs_code
      htsus_code
      schedule_b_code
      secondary_pn
      upc
      market {
        id
        choice
      }
    }
  }
  ${FRAGMENT__BASIC_PART}
`

export const GET_BUY_SELL_HISTORY = gql`
  query BuySellHistory ($input: ManyQueryInput!) {
    items: inventory_items (input: $input) {
      id
      condition {
        id
        choice
      }
      part {
        ...BasicPart
      }
      purchases_items_details {
        id
        creation_date
        current_cost { ...Money }
        line_number
        original_cost { ...Money }
        transaction {
          id
          client {
            id
            name
          }
          purchase_date
          rep {
            id
            initials
          }
        }
      }
      receive_status
      sales_items_details {
        id
        creation_date
        line_number
        sold_for { ...Money }
        transaction {
          id
          client {
            id
            name
          }
          sale_date
          rep {
            id
            initials
          }
        }
      }
    }
  }
  ${FRAGMENT__BASIC_PART}
  ${FRAGMENT__MONEY}
`

export const PARTS__FILTER = gql`
  query Parts ($filters: [QueryFilter]) {
    parts: parts_parts (input: { filters: $filters }) {
      pn
      id
      description
      type
      mfgr {
        id
        name
      }
    }
  }
`

/* MUTATIONS */

export const LINK_SUB_PART_ONE_WAY = gql`
  mutation LinkSubPartOneWay ($part: ID!, $sub: ID!) {
    sub: Update__Parts_Part (input: { id: $sub, alt_part_for_part_id: $part }) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const LINK_SUB_PART_TWO_PAY = gql`
    mutation LinkSubPartTwoWay ($part: ID!, $sub: ID!) {
      sub: Update__Parts_Part (input: { id: $part, alt_part_for_part_id: $sub }) {
        ...FullPart
      }
      part: Update__Parts_Part (input: { id: $sub, alt_part_for_part_id: $part }) {
        ...FullPart
      }
    }
  ${FRAGMENT__FULL_PART}
`

export const UNLINK_SUB_PART_ONE_WAY = gql`
    mutation UnlinkSubPartOneWay ($part: ID!) {
      part: Update__Parts_Part (input: { id: $part, alt_part_for_part_id: null }) {
        id
      }
    }
`

export const UPDATE_INVENTORY_ITEM__COMMENT_ALIAS = gql`
  mutation UpdateInventoryItemForComment ($input: [Update__Inventory_Item__Input!]!) {
    comment: Update__Inventory_Item (input: $input) {
      id
      ...InventoryItem
    }
  }
  ${FRAGMENT__BASIC_ITEM}
`

export const UPDATE_INVENTORY_COMMENT__COMMENT_ALIAS = gql`
    mutation UpdateInventoryComment ($input: [Update__Inventory_Item__Input!]!) {
      comment: Update__Inventory_Item (input: $input) {
        id
        condition_comment
        inventory_comment
        lock_reason
      }
    }
`

export const UPDATE_INVENTORY_SYSTEM__COMMENT_ALIAS = gql`
  mutation UpdateInventorySystemForComment ($input: Update__Inventory_System__Input!) {
    comment: Update__Inventory_System (input: $input) {
      ...BasicSystem
    }
  }
  ${FRAGMENT__BASIC_SYSTEM}
`

export const UPDATE_INVENTORY_ITEM = gql`
  mutation UpdateInventoryItem ($input: [Update__Inventory_Item__Input!]!) {
    Update__Inventory_Item (input: $input) {
      id
      ...InventoryItem
    }
  }
  ${FRAGMENT__BASIC_ITEM}
`

export const SET_ITEM_TEST_STATUS = gql`
  mutation SetItemTestStatus ($id: ID!, $statuses: [ID!]) {
    item: UpdateItemTestStatus (input: { id: $id, ids_to_alter: $statuses, type: SET }) {
      id
      test_status {
        id
        tag
        description
        color
      }
    }
  }
`

export const UPDATE_INVENTORY_SYSTEM = gql`
  mutation UpdateInventorySystem ($input: Update__Inventory_System__Input!) {
    Update__Inventory_System (input: $input) {
      ...BasicSystem
    }
  }
  ${FRAGMENT__BASIC_SYSTEM}
`

// TEMPORARY SERIAL NUMBER UPDATES
export const ADD_SERIAL_NUMBER = gql`
  mutation AddSerialNumber ($id: ID!, $serial: String) {
    UpdateItem: Update__Inventory_Item (input: { id: $id, serial_number: $serial }) {
      id
    }
  }
`

export const MARK_ITEM_AS_LOST = gql`
  mutation MarkItemAsLost ($id: ID!, $lost: Boolean) {
    item: Update__Inventory_Item (input: { id: $id, is_lost: $lost }) {
      id
    }
  }
`

export const RECEIVE_ITEM = gql`
  mutation ReceiveItem ($id: ID!, $country: COUNTRIES, $serial: String, $loc: ID, $user: ID, $date: DateTime, $status: Boolean) {
    UpdateItem: Update__Inventory_Item (input:
    {
      id: $id,
      country_of_origin: $country,
      serial_number: $serial,
      location_id: $loc,
      received_by_id: $user,
      received_date: $date,
      receive_status: $status
    })
    {
      id
      serial_number
      location {
        id
        name
      }
      receive_status
    }
  }`

export const SET_SYSTEM_ADD_ON_ITEMS = gql`
  mutation UpdateSystemAddOnItems ($system: ID!, $items: [ID!]) {
    system: UpdateSystemAddOnItems(input: { id: $system, ids_to_alter: $items, type: SET }) {
      id
    }
  }
`

export const REMOVE_SYSTEM_ADD_ON_ITEMS = gql`
    mutation RemoveSystemAddOnItems ($system: ID!, $items: [ID!]) {
      system: UpdateSystemAddOnItems (input: { id: $system, ids_to_alter: $items, type: REMOVE }) {
        id
      }
    }
`

export const UPDATE_PART_HAS_BATTERY = gql`
  mutation UpdatePartHasBattery ($id: ID!, $has_battery: Boolean) {
    part: Update__Parts_Part (input: { id: $id, has_battery: $has_battery }) {
      id
    }
  }
`

/* PART CREATION MUTATIONS */

export const CREATE_ACCESS_POINT = gql`
  mutation CreateAccessPoint ($input: Create__Parts_AccessPoint__Input!) {
    part: Create__Parts_AccessPoint (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_BATTERY = gql`
  mutation CreateBattery ($input: Create__Parts_Battery__Input!) {
    part: Create__Parts_Battery (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_BEZEL = gql`
  mutation CreateBezel ($input: Create__Parts_Bezel__Input!) {
    part: Create__Parts_Bezel (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_BRACKET = gql`
  mutation CreateBracket ($input: Create__Parts_Bracket__Input!) {
    part: Create__Parts_Bracket (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_CPU = gql`
  mutation CreateCPU ($input: Create__Parts_CPU__Input!) {
    part: Create__Parts_CPU (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_CABLE = gql`
  mutation CreateCable ($input: Create__Parts_Cable__Input!) {
    part: Create__Parts_Cable (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_CACHE = gql`
  mutation CreateCache ($input: Create__Parts_Cache__Input!) {
    part: Create__Parts_Cache (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_DISK_DRIVE = gql`
  mutation CreateDiskDrive ($input: Create__Parts_DiskDrive__Input!) {
    part: Create__Parts_DiskDrive (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_EXPANSION_CARD = gql`
  mutation CreateExpansionCard ($input: Create__Parts_ExpansionCard__Input!) {
    part: Create__Parts_ExpansionCard (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_FAN = gql`
  mutation CreateFan ($input: Create__Parts_Fan__Input!) {
    part: Create__Parts_Fan (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_GPU = gql`
  mutation CreateGPU ($input: Create__Parts_GPU__Input!) {
    part: Create__Parts_GPU (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_HEAT_SINK = gql`
  mutation CreateHeatSink ($input: Create__Parts_Heatsink__Input!) {
    part: Create__Parts_Heatsink (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_IO_MODULE = gql`
  mutation IOModule ($input: Create__Parts_IOModule__Input!) {
    part: Create__Parts_IOModule (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_MEMORY = gql`
  mutation CreateMemory ($input: Create__Parts_Memory__Input!) {
    part: Create__Parts_Memory (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_MOTHERBOARD = gql`
  mutation CreateMotherboard ($input: Create__Parts_Motherboard__Input!) {
    part: Create__Parts_Motherboard (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_NETWORK_CARD = gql`
  mutation CreateNetworkCard ($input: Create__Parts_NetworkCard__Input!) {
    part: Create__Parts_NetworkCard (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_PHONE = gql`
  mutation CreatePhone ($input: Create__Parts_Phone__Input!) {
    part: Create__Parts_Phone (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_POWER_SUPPLY = gql`
  mutation CreatePowerSupply ($input: Create__Parts_PowerSupply__Input!) {
    part: Create__Parts_PowerSupply (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_RAILS = gql`
  mutation CreateRails ($input: Create__Parts_Rails__Input!) {
    part: Create__Parts_Rails (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_ROUTER = gql`
  mutation CreateRouter ($input: Create__Parts_Router__Input!) {
    part: Create__Parts_Router (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_SERVER = gql`
  mutation CreateServer ($input: Create__Parts_Server__Input!) {
    part: Create__Parts_Server (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_STORAGE = gql`
  mutation CreateStorage ($input: Create__Parts_Storage__Input!) {
    part: Create__Parts_Storage (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_STORAGE_ENCLOSURE = gql`
  mutation CreateStorageEnclosure ($input: Create__Parts_StorageEnclosure__Input!) {
    part: Create__Parts_StorageEnclosure (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_SUPPLIES = gql`
  mutation CreateSupplies ($input: Create__Parts_Supplies__Input!) {
    part: Create__Parts_Supplies (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const CREATE_SWITCH = gql`
  mutation CreateSwitch ($input: Create__Parts_Switch__Input!) {
    part: Create__Parts_Switch (input: $input) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`
