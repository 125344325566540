<template>
  <div>
    <v-container>
      <v-layout row wrap justify-space-around>
        <v-flex xs2>
          <v-text-field
            v-model="ports"
            label="No. of Ports"
            :rules="[ e => e > 0 || 'Must be > 0' ]"
          ></v-text-field>
        </v-flex>
        <v-flex xs2>
          <v-text-field
            v-model="speed"
            label="Speed"
            suffix="Gbps"
            :rules="[ e => e > 0 || 'Must be > 0' ]"
          ></v-text-field>
        </v-flex>
      </v-layout>
    </v-container>
    <standard-actions
      @save="save"
      @cancel="cancel"
      @close="$emit('close')"
      :save-button="{ name: 'Submit', disabled: !canProceed }"
      :cancel-button="{ name: 'Back' }"
    ></standard-actions>
  </div>
</template>

<script>
import standardActions from '../standardActions'

export default {
  name: 'storage',
  components: {
    'standard-actions': standardActions
  },
  computed: {
    canProceed () {
      return this.ports && this.speed
    }
  },
  data () {
    return {
      ports: 0,
      speed: ''
    }
  },
  methods: {
    save () {
      const details = {
        ports: this.ports,
        speed: this.speed
      }
      this.$emit('save', details)
    },

    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
