<template>
  <v-card flat color="transparent">
    <v-card-title class="py-0">
      <v-toolbar color="transparent" flat>
        <v-toolbar-title>Select Reports to Run</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon :disabled="selectedReports.length !== 1" @click="deleteReport">
          <v-icon color="error">fas fa-trash</v-icon>
        </v-btn>
        <v-divider vertical inset></v-divider>
        <v-btn icon :disabled="selectedReports.length === 0" @click="run">
          <v-icon color="primary">fas fa-running</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card-text id="report-text" class="pa-0">
      <v-container grid-list-xl fluid pa-0>
        <v-layout wrap justify-space-around align-space-around>
          <v-flex xs12>
            <v-card class="elevation-1">
              <v-card-title class="py-2">
                <h4 class="primary--text">Selected Reports</h4>
                <v-btn
                  flat
                  color="error"
                  :disabled="selectedReports.length === 0"
                  @click="clearReports"
                >Clear</v-btn>
                <v-spacer></v-spacer>
                <span :class="{ 'primary--text': selectedReports.length === 5 }">{{ selectedReports.length }} / 5</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="px-0">
                <v-layout row justify-start align-center wrap>
                  <v-flex xs12>
                    <v-scale-transition
                      group leave-absolute mode="out-in"
                      tag="v-layout" class="row wrap justify-start align-center px-3"
                      style="min-height: 70px;"
                    >
                      <v-flex shrink v-for="report in selectedReports" :key="report.id" pr-0>
                        <v-tooltip top>
                          <template #activator="{ on }">
                            <v-chip  v-on="on" :color="report.model.toLowerCase()" close @input="removeReport(report.id)">
                              <v-avatar class="pl-1">
                                <v-icon small color="white">
                                  {{ getReportTypeIcon(report.chart_type) }}
                                </v-icon>
                              </v-avatar>
                              <span class="white--text bold">
                            {{ report.name }}
                          </span>
                            </v-chip>
                          </template>
                          <span>{{ report.model }}</span>
                        </v-tooltip>
                      </v-flex>
                    </v-scale-transition>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 expand id="report-grid-container">
            <ag-grid-vue
              style="height: 100%; width: 100%; min-width: 600px; min-height: 400px;"
              class="ag-theme-material elevation-2"
              :gridOptions="options"
              :columnDefs="columns"
              :rowData="reports"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import { COLUMN_TYPES, COLUMNS__REPORTS } from '@/lib/agGridColumnConfiguration'
import { DeleteReport } from '@/api/graphql/Constants/Reporting'
export default {
  name: 'Reports',
  components: {
    'ag-grid-vue': AgGridVue
  },
  computed: {
    oneReportIsSelected () {
      return this.selectedReports.length === 1
    }
  },
  data () {
    return {
      style: {
        height: '',
        width: ''
      },

      api: null,
      columnApi: null,

      reports: [
        { name: 'Lost', description: 'Lorem Ipsum oops doo', chart_type: 'line', model: 'Inventory', id: 1 },
        { name: 'Highest Margin Orders', description: 'Lorem Ipsum oops doo', chart_type: 'bar', model: 'Sales', id: 2 },
        { name: 'Cancelled Orders', description: 'Lorem Ipsum oops doo', chart_type: 'scatter', model: 'Purchases', id: 3 },
        { name: 'Converted Quotes', description: 'Lorem Ipsum oops doo', chart_type: 'network', model: 'Quotes', id: 4 },
        { name: 'Receive Count', description: 'Lorem Ipsum oops doo', chart_type: 'pie', model: 'Users', id: 5 },
        { name: 'Lost', description: 'Lorem Ipsum oops doo', chart_type: 'line', model: 'Inventory', id: 6 },
        { name: 'Highest Margin Orders', description: 'Lorem Ipsum oops doo', chart_type: 'bar', model: 'Sales', id: 7 },
        { name: 'Cancelled Orders', description: 'Lorem Ipsum oops doo', chart_type: 'scatter', model: 'Purchases', id: 8 },
        { name: 'Converted Quotes', description: 'Lorem Ipsum oops doo', chart_type: 'network', model: 'Quotes', id: 9 },
        { name: 'Receive Count', description: 'Lorem Ipsum oops doo', chart_type: 'pie', model: 'Users', id: 10 }
      ],
      selectedReports: [],

      columns: COLUMNS__REPORTS,

      // grid options
      options: {
        allowDragFromColumnsToolPanel: true,
        animateRows: true,
        cacheBlockSize: 35,
        maxConcurrentDatasourceRequests: 2,
        columnTypes: COLUMN_TYPES,
        defaultColDef: {
          enableValue: false,
          enableRowGroup: false,
          enablePivot: false,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset', 'apply'],
            filterOptions: [
              'Starts With',
              'Ends With',
              'Contains',
              'Is'
            ]
          },
          floatingFilter: true,
          floatingFiltersHeight: 40,
          menuTabs: ['generalMenuTab'],
          sortable: true,
          resizable: true
        },
        detailCellRendererParams: null,
        getRowNodeId: (data) => {
          return data.id
        },
        onRowSelected: (event) => {
          this.updateSelectedReports(event)
        },
        onGridReady: (params) => {
          this.api = params.api
          this.columnApi = params.columnApi
        },
        onFirstDataRendered: params => {
        },
        rowData: null,
        rowModelType: 'clientSide',
        rowSelection: 'multiple',
        sideBar: {
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressRowGroups: true
              }
            }
          ]
        },
        sortingOrder: ['asc', 'desc'],
        suppressContextMenu: true,
        suppressMultiRangeSelection: true,
        suppressRowClickSelection: true,
        suppressPropertyNamesCheck: true,
        tooltipShowDelay: 0
      }
    }
  },
  methods: {
    run () {
      this.$router.replace({ name: this.$route.name, query: { dialog: 'reports', reports: this.selectedReports.map(r => r.id) } })
      this.api.forEachNode(n => n.setSelected(false))
    },

    getReportTypeIcon (type) {
      return `fal fa-chart-${type.toLowerCase()}`
    },

    updateSelectedReports (event) {
      const n = event.node
      const count = event.api.getSelectedNodes().length
      if (n.isSelected()) {
        if (count < 6) {
          this.selectedReports.push(n.data)
        } else {
          n.setSelected(false)
        }
      } else {
        const index = this.selectedReports.findIndex(s => s.id === n.id)
        if (index > -1) {
          this.selectedReports.splice(index, 1)
        }
      }
    },

    removeReport (id) {
      this.api.forEachNode(n => {
        if (n.id === id) n.setSelected(false)
      })
    },

    clearReports () {
      this.api.forEachNode(n => n.setSelected(false))
    },

    editReport () {
      // console.log(this.selectedReports[0].id)
    },

    deleteReport () {
      const report = this.api.getSelectedNodes()[0].data
      const del = window.confirm(`Are you sure you wish to delete this report? \n\n ${report.name}`)
      if (del) {
        try {
          DeleteReport(report.id)
        } catch (e) {
          this.$store.dispatch('notifications/createSnackbar', {
            message: e.message,
            color: 'error'
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-theme-material.css";

#report-grid-container {
  height: calc(100vh - 240px - 199px); // 100vh - top - bottom
}
</style>
