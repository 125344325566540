<template>
    <v-card>
      <v-card-title class="purple">
        <h3 class="white--text">
          Select a Printer to Print Tags
        </h3>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-layout justify-center align-center>
            <v-flex grow>
              <printers
                :printer="printer"
                @updated="printer = $event.value"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <t-alert :message="message" :type="type"/>
        <v-spacer></v-spacer>
        <cancel-button :success="success" @click="close"/>
        <submit-button :disabled="!printer || success" :loading="printing" @click="printTags"/>
      </v-card-actions>
    </v-card>
</template>

<script>
import printers from '@/components/autocompletes/printers'
import { CREATE_PRINT_JOB, prepInventoryLabelsForPrintJob } from '@/api/graphql/Constants/printNode'
import tAlert from '@/components/notifications/tAlert'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default {
  name: 'printTags',
  components: {
    'printers': printers,
    't-alert': tAlert,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  computed: {
    user () {
      return this.$store.state.profile.user
    },
    nodes () {
      return this.$store.state.grid.dataForPrinting
    }
  },
  data () {
    return {
      printer: 0,
      printing: false,
      success: false,
      message: '',
      type: 'info'
    }
  },
  methods: {
    close () {
      this.$store.dispatch('grid/resetDialog')
    },

    printTags () {
      this.printing = true
      const printables = []
      for (const node of this.nodes) {
        const item = node.data
        printables.push({
          pn: item.part.pn,
          sn: item.serial_number,
          location: item.location?.name ?? '',
          id: item.id,
          pt: `${item.purchases_items_details.transaction.id}-${item.purchases_items_details.line_number}`
        })
      }
      const zpl = prepInventoryLabelsForPrintJob(printables)
      this.$apollo.mutate({
        mutation: CREATE_PRINT_JOB,
        variables: {
          content: zpl,
          contentType: 'raw_base64',
          printerId: this.printer,
          qty: 1,
          source: `${this.user.firstName} ${this.user.lastName}`,
          title: `Inventory Labels for ${printables.length} parts.`
        }
      })
        .then(() => {
          this.printing = false
          this.success = true
          this.message = 'Sent print request to printer'
          this.type = 'success'
        })
        .catch(() => {
          this.printing = false
          this.success = true
          this.message = 'Could not communicate with the printer.'
          this.type = 'error'
        })
    }
  }
}
</script>

<style scoped>

</style>
