import { FormAction, OrderForm, UseOrderInformation } from '@/composition/UseOrderInformation'
import { AddressFiltersObject, ShipOrderForm, useShipping } from '@/composition/UseShipping'
import { PartForm, TranslateQuotePartsToNewSaleParts, usePartsForOrder } from '@/composition/UsePartsForOrder'
import {
  Address_Address,
  Create__Sales_Sale__Input, Create__Sales_SoldItems__Input, Create__Shipping_ShipmentOrder__Input, GetCompanyInfoQuery,
  GetQuoteForSaleImportQuery, GetSaleForEditQuery, GetSaleForEditQueryVariables,
  Query, SaleForEditFragment,
  SalesDetailsForCopyQuery, Swap_Type, Update__Sales_Sale__Input, Update__Shipping_ShipmentOrder__Input
} from '@/models/generated/graphql/ErpBackend'
import { computed, ComputedRef, onBeforeMount, reactive, Ref, ref, watch } from '@vue/composition-api'
import { INewSalePart } from '@/models/GuiPartsModels'
import { useEventHook } from '@/composition/UseEventHook'
import {
  CheckClientPO,
  CreateSaleOrder,
  CreateSaleOrderItems, GET_SALE_FOR_EDIT,
  SALES_DETAILS_FOR_COPY, UpdateSaleOrder
} from '@/api/graphql/Constants/Orders'
import { QueryOptions } from 'apollo-client'
import { GET_CLIENT__FULL } from '@/api/graphql/Constants/Clients'
import { apolloClient as apollo } from '@/api/graphql/apollo'
import { BlindForm, saveBlindStuff, useBlindShipping } from '@/composition/UseBlindShipping'
import store from '@/store/store'
import { GET_QUOTE_FOR_SALE_IMPORT } from '@/api/graphql/Constants/Quotes'
import { GetAllocatedObjectWithIoSplit, GetDjangoMoneyCompatibleInput } from '@/lib/moneyHelpers'
import { Sentry } from '@/lib/Sentry'
import { Severity } from '@sentry/types'
import { CreateShipmentOrder, UpdateShipmentOrder } from '@/api/graphql/Constants/Shipments'
import { OrderEvents } from '@/store/orderEvents'
import { COMPANY_INFO } from '@/api/graphql/Constants/Company'
import { useQuery } from '@vue/apollo-composable'
import { ExtraFieldsObject, useDynamicPrimitiveFieldEntry } from '@/composition/UseDynamicPrimitiveFieldEntry'
import { finished } from 'stream'

export interface UseSaleReturn {
  order: OrderForm
  hasOrderErrors: ComputedRef<boolean>
  shipment: ShipOrderForm
  hasShippingErrors: ComputedRef<boolean>
  filters: AddressFiltersObject
  isBlind: ComputedRef<boolean>
  blind: BlindForm
  hasBlindErrors: ComputedRef<boolean>
  checkBlind: () => void
  parts: PartForm<INewSalePart>
  hasPartErrors: ComputedRef<boolean>
  hasErrors: ComputedRef<boolean>
  extra: ExtraFieldsObject
  loadingExistingOrder: Ref<boolean>
  saving: Ref<boolean>
  processing: Ref<boolean>
  submit: (action: FormAction) => void
  onCreated: (fn: () => void) => { off: () => void }
  onFailure: (fn: (params: Error) => void) => { off: () => void }
  onValidationError: (fn: (params: string) => void) => { off: () => void }
  input: SaleInput
}

export interface SaleInput {
  sale: ComputedRef<Create__Sales_Sale__Input>
  shipment: ComputedRef<Create__Shipping_ShipmentOrder__Input>
  parts: ComputedRef<Create__Sales_SoldItems__Input>
}

export const PREP_SALE_FOR_EDIT = ({ id, statusId }: { id: string, statusId: string }) => {
  store.dispatch('data/setEditOrder', { id, statusId })
  store.dispatch('sale/start')
}

export function useSale (orderId: ComputedRef<string>): UseSaleReturn {
  /* Basics */
  const companyReturnToId = ref('')
  const saving = ref(false)
  const processing = ref(false)
  const inUse = [
    'deliver_by_date',
    'we_pay',
    'sale_date',
    'estimated_cost',
    'estimated_margin',
    'book_date',
    'client_order_number',
    'internal_comment',
    'contract_comment'
  ]

  /* Event Hooks */
  const createdResult = useEventHook()
  const failedResult = useEventHook()
  const validationErrorResult = useEventHook()

  /* Composition Function Returns */
  // order
  const { form: order, hasErrors: hasOrderErrors, ValidateOrder, apply: applyOrder } = UseOrderInformation()
  // shipping
  const { form: shipForm, hasErrors: hasShippingErrors, ValidateShipping, apply: applyShipmentOrder } = useShipping()

  // blind shipping
  const { form: blind, hasErrors: hasBlindErrors, Validate: ValidateBlind, CheckClientContactValue, apply: applyBlind } =
    useBlindShipping()

  // parts
  const partsValidator = (saleParts: INewSalePart[], action: FormAction) => {
    const setMessage = (message: string) => {
      parts.parts.message = message
    }
    const isAllocated = (p: INewSalePart) => p.item !== null
    if (action === 'SAVE') setMessage('')
    else {
      const isProcessable = saleParts.every(isAllocated)
      if (isProcessable) {
        setMessage('')
      } else {
        setMessage('You must allocate all parts before processing.')
      }
    }
  }
  const { form: parts, hasErrors: hasPartErrors } = usePartsForOrder<INewSalePart>(partsValidator)

  // extra info
  const { extra, loading: loadingExtra, apply: applyExtra } = useDynamicPrimitiveFieldEntry({ type: 'Create__Sales_Sale__Input', inUse: inUse })

  /* Computed using data returned from Comp Functions */
  const isBlind = computed(() => shipForm.blind.value !== 'NOT_BLIND')
  // @ts-ignore
  const filters: AddressFiltersObject = reactive({
    f: computed(() => {
      if (isBlind.value) { // if blind
        if (order.client.value) { // if a client is set
          return { key: 'client__id', value: order.client.value }
        } else return null
      } else { // if not blind
        return { key: 'company__isnull', value: false }
      }
    }),
    t: computed(() => {
      if (isBlind.value) { // if blind
        return null // doesn't matter the component that uses this filter is not visible
      } else { // if not blind
        if (order.client.value) { // if client is set
          return { key: 'client__id', value: order.client.value }
        } else return null // if not
      }
    }),
    b: computed(() => {
      if (order.client.value) { // if a client is set
        return { key: 'client__id', value: order.client.value }
      } else return null // if not
    })
  })

  /* Watchers for data returned from Comp Functions */
  watch(() => order.client.value, async (value) => {
    // check client po to see if it matches another order
    if (!orderId.value) {
      order.clientOrderNumber.message = await CheckClientPO(order.clientOrderNumber.value, value)
    }

    // grab client to figure out defaults
    const query: QueryOptions = {
      query: GET_CLIENT__FULL,
      variables: { input: { id: value } }
    }
    const response = await apollo.query<Query>(query)

    const client = response.data.clients_client
    // if client retrieved successfully, do defaults
    if (client) {
      const service = client.default_shipping_service
      const terms = client.default_st_terms
      const shipTo = client.default_ship_to_address
      const shipFrom = client.default_ship_from_address
      const billTo = client.default_billing_address
      const trackPeeps = client.default_tracking_email_recipients as string[]
      const contactBill = client.default_billing_contact
      const rep = client.internal_rep

      if (rep && !isBlind.value) shipForm.repFrom.setter({ value: rep })
      if (contactBill) shipForm.contactBill.setter({ value: contactBill })
      terms && order.terms.setter({ value: terms.id })
      service && shipForm.carrierService.setter({ value: service.id });
      (shipTo && !isBlind.value) && shipForm.shipTo.setter({ value: shipTo });
      (shipFrom && isBlind.value) && shipForm.shipFrom.setter({ value: shipFrom })
      billTo && shipForm.billTo.setter({ value: billTo })
      shipForm.trackingEmails.value = trackPeeps.join(' ')
    }
  })

  /* Inputs */

  // order
  const extraFields = computed(() => {
    const x = {}
    for (const field of extra.selected) {
      // @ts-ignore
      if (field.value) {
        // @ts-ignore
        x[field.field] = field.value
        // @ts-ignore
      } else if (field.value === '') x[field.field] = null
    }
    return x
  })
  const saleInput: ComputedRef<Create__Sales_Sale__Input> = computed(() => ({
    client_id: order.client.value || undefined,
    client_order_number: order.clientOrderNumber.value || undefined,
    contact_id: order.contact.value || undefined,
    contract_comment: order.contractComment || undefined,
    internal_comment: order.internalComment || undefined,
    // freight: order.freight || undefined,
    quote_id: order.quote.value || undefined,
    rep_id: order.rep.value || undefined,
    shipment_order_id: orderId.value ? shipForm.shipmentId || undefined : undefined,
    status_id: orderId.value ? undefined : order.statusId,
    terms_id: order.terms.value || undefined,
    ...extraFields.value
  }))

  // shipment
  const accountInput = computed(() => shipForm.isLocalPickup.value ? undefined : (shipForm.carrierAccount.value || undefined))
  const serviceInput = computed(() => shipForm.isLocalPickup.value ? undefined : (shipForm.carrierService.value || undefined))
  // @ts-ignore because it doesn't like undefined
  const shipmentInput: ComputedRef<Create__Shipping_ShipmentOrder__Input> = computed(() => ({
    account_id: accountInput.value,
    blind: shipForm.blind.value,
    pickup_type: shipForm.isLocalPickup.value ? 'CUSTOMER' : 'NOT_PICKUP',
    purchaser_address_id: shipForm.billTo.value || undefined,
    purchaser_contact_id: shipForm.contactBill.value || undefined,
    purchaser_rep_id: shipForm.repBill.value || undefined,
    return_address_id: isBlind ? blind.returnToCompany
      ? shipForm.shipFrom.value : companyReturnToId.value
      : companyReturnToId.value,
    service_id: serviceInput.value,
    ship_from_address_id: shipForm.shipFrom.value || undefined,
    ship_from_contact_id: shipForm.contactFrom.value || undefined,
    ship_from_rep_id: shipForm.repFrom.value || undefined,
    ship_to_address_id: shipForm.shipTo.value || undefined,
    ship_to_contact_id: shipForm.contactTo.value || undefined,
    ship_to_rep_id: shipForm.repTo.value || undefined,
    status_id: orderId.value ? 56 : undefined,
    tracking_email_recipients: shipForm.trackingEmails.value.split(' ').filter(f => f.length > 0),
    we_pay: shipForm.wePay.value
  }))

  // parts
  const partsInput: ComputedRef<Create__Sales_SoldItems__Input[]> = computed(() => {
    const partsInput: Create__Sales_SoldItems__Input[] = []
    for (const part of parts.parts.value) {
      partsInput.push({
        line_number: partsInput.length + 1,
        sold_for: GetDjangoMoneyCompatibleInput(part.soldFor),
        part_id: (part.altPart ? part.altPart.id : `${part.id}`),
        swap_type: (part.altPart ? Swap_Type.Alt : Swap_Type.None),
        status_id: part.item ? '85' : '18',
        transaction_id: order.orderId,
        ...(part.item && GetAllocatedObjectWithIoSplit({ soldItem: part }))
      })
    }
    return partsInput
  })

  // @ts-ignore idk why it does not like this
  const input: SaleInput = reactive({
    sale: saleInput,
    shipment: shipmentInput,
    parts: partsInput
  })

  /* Other helpers */

  /**
   * @name importQuote
   * @description Use this function get details from the quote and import it into this sale.
   * @param {string} id the id of the quote
   * @return {Promise<void>}
   */
  const importQuote = async (id: string): Promise<void> => {
    if (id) {
      await store.dispatch('notifications/createSnackbar', {
        message: 'Grabbing quote details...',
        color: 'info'
      })
      const query: QueryOptions<{ id: string}> = {
        query: GET_QUOTE_FOR_SALE_IMPORT,
        variables: { id: id }
      }
      const response = await apollo.query<GetQuoteForSaleImportQuery, { id: string }>(query)
      const quote = response.data?.quote
      if (quote) {
        if (quote.contact?.id) order.contact.value = quote.contact.id
        if (quote.client?.id) order.client.value = quote.client.id
        order.rep.value = quote.creator.id
        if (quote.internal_comment) order.internalComment = quote.internal_comment
        if (quote.contract_comment) order.contractComment = quote.contract_comment
        parts.parts.value = TranslateQuotePartsToNewSaleParts(quote.parts ?? [])
        await store.dispatch('notifications/hideSnackbar')
      }
    } else {
      throw new Error('A quote id could not be fetched. This a bad-code error.')
    }
  }

  const importDuplicate = async (id: string): Promise<void> => {
    if (id) {
      await store.dispatch('notifications/createSnackbar', {
        message: 'Grabbing sale details...',
        color: 'info'
      })
      const query: QueryOptions<{ id: string}> = {
        query: SALES_DETAILS_FOR_COPY,
        variables: { id: id }
      }
      const response = await apollo.query<SalesDetailsForCopyQuery, { id: string }>(query)
      const sale = response.data?.sale
      if (sale) {
        // @ts-ignore
        if (sale.contact?.id) order.contact.setter({ value: sale.contact })
        order.internalComment = sale.internal_comment ?? ''
        order.contractComment = sale.contract_comment ?? ''
        // if (sale.client?.id) order.client.value = sale.client.id
        // order.rep.value = sale.rep?.id ?? ''
        parts.parts.value = sale.st_items?.map(i => ({
          id: i.part.id,
          pn: i.part.pn,
          description: i?.part?.description ?? '',
          soldFor: Number(i.sold_for?.amount ?? 0),
          item: null,
          pt: null,
          quantity: 1,
          hover: false
        })) ?? []
        await store.dispatch('notifications/hideSnackbar')
      }
    } else {
      throw new Error('A quote id could not be fetched. This a bad-code error.')
    }
  }

  /* Submit Stuff */
  const Validate = (action: FormAction) => {
    ValidateOrder(action)
    ValidateShipping(action)
    isBlind.value && ValidateBlind()
    parts.parts.validator(action)
  }

  const hasErrors = computed(() => {
    return hasOrderErrors.value || hasShippingErrors.value || hasPartErrors.value ||
      (isBlind.value && hasBlindErrors.value)
  })
  const create = async (action: FormAction): Promise<void> => {
    Sentry.addBreadcrumb({ category: 'sale', message: `User attempted to ${action} the sale`, level: Severity.Info })
    const isSaving = action === 'SAVE'
    // store status id for later
    order.statusId = isSaving ? '7' : '8'
    // check for valid data
    Validate(action)
    // if data is valid
    if (!hasErrors.value) {
      try {
        Sentry.addBreadcrumb({ category: 'sale', message: 'The data was valid for this session', level: Severity.Info })
        // set loading state
        if (isSaving) saving.value = true
        else processing.value = true
        // prep for blind stuff
        let blindStuff: any = { contact: order.contact.value, address: shipForm.shipTo.value }
        if (isBlind && blind.address.validated && ((blind.client.value && blind.client.shortName) || blind.client.id)) {
          Sentry.addBreadcrumb({ category: 'sale', message: 'Blind is being used', level: Severity.Info })
          blindStuff = await saveBlindStuff(blind)
          Sentry.setContext('blind_input', blind)
          Sentry.setContext('blind_payload', blindStuff)
        }
        // create the shipment input object
        const shipment: Create__Shipping_ShipmentOrder__Input = {
          ...shipmentInput.value,
          ship_to_contact_id: blindStuff.contact || order.contact.value || undefined,
          ship_to_address_id: blindStuff.address
        }
        shipment.ship_to_address_id = blindStuff.address
        // ready to actually create the data
        Sentry.setContext('sale_input', saleInput.value)
        Sentry.setContext('shipment_input', shipmentInput.value)
        // create the shipment
        const newShipment = await CreateShipmentOrder(shipment)
        Sentry.addBreadcrumb({ category: 'sale', message: 'Created a shipment OK' })
        // assign the id to the sale to be created
        shipForm.shipmentId = newShipment!.id
        // create the sale
        const newSale = await CreateSaleOrder(saleInput.value)
        Sentry.addBreadcrumb({ category: 'sale', message: 'Created a sale OK' })
        // update with the new sale id so computed <partsInput> computed variable updates
        order.orderId = newSale!.id
        // create the sold items (and update sale money)
        Sentry.setContext('soldItems_input', { parts: JSON.stringify(partsInput.value) })
        await CreateSaleOrderItems(newSale!.id, partsInput.value)
        createdResult.trigger(null)
        if (window.location.href.includes('sales/orders')) {
          store.dispatch('data/changeRefresh', { bool: true })
        }
        Sentry.captureMessage('Created a sale test')
      } catch (error) {
        Sentry.captureEvent(error)
        failedResult.trigger(error)
        await store.dispatch('notifications/createSnackbar', {
          message: error.message,
          color: 'error'
        })
      } finally {
        saving.value = false
        processing.value = false
      }
    }
  }

  const edit = async (): Promise<void> => {
    Sentry.addBreadcrumb({ category: 'sale', message: 'User attempted to edit the sale', level: Severity.Info })
    // store status id for later
    // check for valid data
    Validate('SAVE')
    // if data is valid
    if (!hasErrors.value) {
      try {
        Sentry.addBreadcrumb({ category: 'sale', message: 'The data was valid for this session', level: Severity.Info })
        // set loading state
        saving.value = true
        // prep for blind stuff
        let blindStuff: any = { contact: order.contact.value, address: shipForm.shipTo.value }
        if (isBlind && blind.address.validated && ((blind.client.value && blind.client.shortName) || blind.client.id)) {
          Sentry.addBreadcrumb({ category: 'sale', message: 'Blind is being used', level: Severity.Info })
          blindStuff = await saveBlindStuff(blind)
          Sentry.setContext('blind_input', blind)
          Sentry.setContext('blind_payload', blindStuff)
        }
        // create the shipment input object
        const shipment: Create__Shipping_ShipmentOrder__Input = {
          ...shipmentInput.value,
          ship_to_contact_id: blindStuff.contact || order.contact.value || undefined,
          ship_to_address_id: blindStuff.address
        }
        shipment.ship_to_address_id = blindStuff.address
        // ready to actually edit the data
        Sentry.setContext('shipment_input', shipmentInput.value)
        // update the shipment
        const newShipment = shipForm.shipmentId === '' ? await CreateShipmentOrder(shipment) : await UpdateShipmentOrder({ ...shipment, id: shipForm.shipmentId })
        Sentry.addBreadcrumb({ category: 'sale', message: 'Edited a shipment OK' })
        // create the sale
        const updateSaleInput: Update__Sales_Sale__Input = {
          ...saleInput.value,
          id: orderId.value,
          shipment_order_id: shipForm.shipmentId === '' ? newShipment!.id : undefined
        }
        Sentry.setContext('sale_input', updateSaleInput)
        await UpdateSaleOrder(updateSaleInput)
        Sentry.addBreadcrumb({ category: 'sale', message: 'Edited a sale OK' })
        createdResult.trigger('pizza')
        if (window.location.href.includes('sales/orders')) {
          store.dispatch('data/changeRefresh', { bool: true })
        }
        Sentry.captureMessage('Edited a sale test')
      } catch (error) {
        Sentry.captureEvent(error)
        failedResult.trigger(error)
        await store.dispatch('notifications/createSnackbar', {
          message: error.message,
          color: 'error'
        })
      } finally {
        saving.value = false
        processing.value = false
      }
    }
  }

  const submit = async (action: FormAction) => {
    enabled.value = false
    if (orderId.value) {
      await edit()
    } else {
      await create(action)
    }
  }

  const enabled = ref(true)

  /* Get Order For Editing */
  const {
    onResult,
    onError,
    loading: loadingExistingOrder
  } = useQuery<GetSaleForEditQuery, GetSaleForEditQueryVariables>(GET_SALE_FOR_EDIT,
    () => ({
      id: orderId.value
    }),
    () => ({
      enabled: orderId.value !== '' && enabled.value
    })
  )
  onResult(result => {
    const sale: SaleForEditFragment | undefined = result.data?.sales_sale ?? undefined
    if (sale) {
      // set up order information
      applyOrder(sale)
      // set up shipping information
      if (sale.shipment_order?.id) applyShipmentOrder(sale.shipment_order)
      // set up extra fields information
      const primitives: { field: string, value: boolean | string }[] = []
      for (const key in sale) {
        // @ts-ignore
        if (typeof sale[key] === typeof 'string' || typeof sale[key] === typeof true) {
          // @ts-ignore
          primitives.push({ field: key, value: sale[key] })
        }
      }
      applyExtra(primitives)

      if (sale.shipment_order?.blind !== 'NOT_BLIND') {
        !!sale.shipment_order && applyBlind(sale.shipment_order)
      }
    }
  })
  onError(error => {
    Sentry.captureEvent(error)
    failedResult.trigger(new Error('Could not get order to edit. Try again or contact support.'))
  })

  onBeforeMount(async () => {
    Sentry.resetScope()
    // import quote listener
    OrderEvents.$once('send-quote-id', (event: { value: string }) => {
      importQuote(event.value)
    })
    // duplicate st listener
    OrderEvents.$once('duplicate-sale', (event: { value: string }) => {
      importDuplicate(event.value)
    })
    // get shipping defaults
    const query: QueryOptions = {
      query: COMPANY_INFO
    }
    const response = await apollo.query<GetCompanyInfoQuery>(query)
    const company = response.data.company
    if (company) {
      companyReturnToId.value = company.default_return_to_address?.id ?? ''
      if (company?.default_ship_from_address?.id) {
        shipForm.shipFrom.setter({ value: company.default_ship_from_address as Address_Address })
      }
    }
  })

  return {
    order,
    hasOrderErrors,
    shipment: shipForm,
    hasShippingErrors,
    filters,
    isBlind,
    blind,
    hasBlindErrors,
    checkBlind: CheckClientContactValue,
    parts,
    hasPartErrors,
    hasErrors,
    extra,
    loadingExistingOrder,
    saving,
    processing,
    submit,
    onCreated: createdResult.on,
    onFailure: failedResult.on,
    onValidationError: validationErrorResult.on,
    input
  }
}
