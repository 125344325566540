<template>
  <div>
    <div v-if="isEmpty" class="base warning-bar">Needs Items</div>
    <div v-else-if="isOpen" class="base info-bar">Open</div>
    <div v-else class="base">Allocated</div>
  </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
  name: 'openAllocated',
  computed: {
    unAllocatedCount () {
      const value = this.params.data?.annotations?.filter(a => {
        return a.name === 'unAllocated'
      })[0]?.value ?? 0
      if (Number.isFinite(Number(value))) return value
      return 0
    },

    itemCount () {
      const value = this.params?.data?.annotations?.filter(a => {
        return a.name === 'itemCount'
      })[0]?.value ?? 0
      if (Number.isFinite(Number(value))) return value
      return 0
    },

    isOpen () {
      return !this.isEmpty && this.unAllocatedCount > 0
    },

    isEmpty () {
      return this.itemCount < 1
    }
  },
  methods: {
    refresh (params) {
      this.params = params
      return true
    }
  }
})
</script>
