<template>
  <div>
    <v-container>
      <v-layout row wrap justify-space-between>
        <v-flex xs2>
          <choice-list :config="interfaceConfig"></choice-list>
        </v-flex>
        <v-flex xs2>
          <choice-list :config="speedConfig"></choice-list>
        </v-flex>
        <v-flex xs2>
          <v-text-field
            v-model="bays25"
            label="2.5in bays"
            hint="Quantity"
          ></v-text-field>
        </v-flex>
        <v-flex xs2>
          <v-text-field
            v-model="bays35"
            label="3.5in bays"
            hint="Quantity"
          ></v-text-field>
        </v-flex>
      </v-layout>
    </v-container>
    <standard-actions
      @save="save"
      @cancel="cancel"
      @close="$emit('close')"
      :save-button="{ name: 'Submit', disabled: !canProceed }"
      :cancel-button="{ name: 'Back' }"
    ></standard-actions>
  </div>
</template>

<script>
import standardActions from '../standardActions'
import choices from '../../autocompletes/choices'

export default {
  name: 'storage',
  components: {
    'standard-actions': standardActions,
    'choice-list': choices
  },
  computed: {
    canProceed () {
      return this.interfaceConfig.id && this.speedConfig.id && (this.bays25 || this.bays35)
    }
  },
  data () {
    return {
      interfaceConfig: {
        id: 0,
        label: 'Interface Type',
        appName: 'parts',
        modelName: 'storage',
        fieldName: 'interface'
      },

      speedConfig: {
        id: 0,
        label: 'Speed',
        appName: 'parts',
        modelName: 'storage',
        fieldName: 'speed'
      },
      bays25: 0,
      bays35: 0
    }
  },
  methods: {
    save () {
      const details = {
        interface_id: this.interfaceConfig.id,
        speed_id: this.speedConfig.id,
        storage_bays_25: this.bays25,
        storage_bays_35: this.bays35
      }
      this.$emit('save', details)
    },

    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
