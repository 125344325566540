
export interface Address {
  title?: 'Ship From' | 'Ship To' | 'Bill To' | 'Vendor' | 'Buyer' | 'Seller' | string
  name?: string
  street_1?: string
  street_2?: string
  city?: string
  state?: string
  zip_code?: string
  country?: string
  extra?: string[]
}

/**
 * Convert an Address object into a multi line string object.
 * Useful for PDF addresses.
 * @param {Address?} address - An address object various fields like street and zip code
 * @returns {string} - A multi line address string
 *
 * @example
 * parseAddress({
 *      title: 'Ship From',
 *      name: 'The White House',
 *      street_1: '1600 Pennsylvania Ave',
 *      city: 'Washington D.C.',
 *      zip_code: '12345',
 *      country: 'United States of America',
 * })
 *
 * //=> `Ship From
 * The White House
 * 1600 Pennsylvania Ave
 * Washington D.C. 12345
 * United States of America`
 *
 */
export function parseAddress (address?: Address): string {
  if (!address) {
    return ''
  }
  return [
    address?.name ?? '',
    address?.street_1 ?? '',
    address?.street_2 ?? '',
    [
      address?.city ?? '',
      address?.state ?? '',
      address?.zip_code ?? ''
    ]
      .join(' ')
      .replace(/\s+/g, ' ')
      .trim(),
    address?.country
  ]
    .filter(i => !!i)
    .join('\n')
    .trim()
}
