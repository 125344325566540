<template>
  <div>
    <v-container>
      <v-layout row wrap justify-start>
        <!-- Sub Type -->
        <v-flex xs3>
          <choice-list :config="subTypeConfig"></choice-list>
        </v-flex>
        <!-- Interface -->
        <v-flex xs3 offset-xs1>
          <choice-list :config="interfaceConfig"></choice-list>
        </v-flex>
        <!-- Physical Size -->
        <v-flex xs3 offset-xs1>
          <choice-list :config="physicalSizeConfig"></choice-list>
        </v-flex>
        <!-- Capacity Amount -->
        <v-flex xs3>
          <v-text-field
            v-model="capacityAmount"
            label="Capacity Amount"
          ></v-text-field>
        </v-flex>
        <!-- Capacity Unit -->
        <v-flex xs3 offset-xs1>
          <choice-list :config="capacityUnitConfig"></choice-list>
        </v-flex>
        <!-- Capacity -->
        <v-flex xs3 offset-xs1>
          <v-text-field
            v-model="capacity"
            label="Capacity"
            readonly
          ></v-text-field>
        </v-flex>
        <!-- Speed -->
        <v-flex xs3>
          <choice-list :config="speedConfig"></choice-list>
        </v-flex>
        <!-- Spindle Speed -->
        <v-flex xs3 offset-xs1>
          <choice-list :config="spindleSpeedConfig"></choice-list>
        </v-flex>
      </v-layout>
    </v-container>
    <standard-actions
      @save="save"
      @cancel="cancel"
      @close="$emit('close')"
      :save-button="{ name: 'Submit', disabled: !canProceed }"
      :cancel-button="{ name: 'Back' }"
    ></standard-actions>
  </div>
</template>

<script>
import standardActions from '../standardActions'
import choices from '../../autocompletes/choices'

export default {
  name: 'storage',
  components: {
    'standard-actions': standardActions,
    'choice-list': choices
  },
  computed: {
    capacity () {
      return this.capacityAmount + ' ' + this.capacityUnits
    },

    canProceed () {
      return this.subTypeConfig.id && this.interfaceConfig.id && this.physicalSizeConfig.id &&
        this.speedConfig.id && this.capacityUnitConfig.id && this.spindleSpeedConfig.id &&
        this.capacityAmount !== ''
    },

    capacityAmountInt () {
      return parseInt(this.capacityAmount)
    }
  },
  watch: {
    capacityUnitConfig: {
      handler: function (newValue) {
        if (newValue.id > -1) {
          this.capacityUnits = this.$store.getters['data/choices']('parts', 'storage', 'capacity_unit').filter(
            item => item.id === newValue.id
          )[0].choice
        }
      },
      deep: true
    }
  },
  data () {
    return {
      subTypeConfig: {
        id: 0,
        label: 'Drive Type',
        appName: 'parts',
        modelName: 'storage',
        fieldName: 'sub_type'
      },

      interfaceConfig: {
        id: 0,
        label: 'Drive Interface',
        appName: 'parts',
        modelName: 'storage',
        fieldName: 'interface'
      },

      physicalSizeConfig: {
        id: 0,
        label: 'Form Factor',
        appName: 'parts',
        modelName: 'storage',
        fieldName: 'physical_size'
      },

      speedConfig: {
        id: 0,
        label: 'Throughput',
        appName: 'parts',
        modelName: 'storage',
        fieldName: 'speed'
      },

      capacityUnitConfig: {
        id: 0,
        label: 'Storage Units',
        appName: 'parts',
        modelName: 'storage',
        fieldName: 'capacity_unit'
      },

      spindleSpeedConfig: {
        id: 0,
        label: 'Spindle Speed',
        appName: 'parts',
        modelName: 'storage',
        fieldName: 'spindle_speed'
      },

      capacityAmount: '',
      capacityUnits: ''
    }
  },
  methods: {
    save () {
      const details = {
        capacity_amount: this.capacityAmountInt,
        capacity_unit_id: this.capacityUnitConfig.id,
        interface_id: this.interfaceConfig.id,
        physical_size_id: this.physicalSizeConfig.id,
        speed_id: this.speedConfig.id,
        spindle_speed_id: this.spindleSpeedConfig.id,
        sub_type_id: this.subTypeConfig.id
      }
      this.$emit('save', details)
    },

    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
