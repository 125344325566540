<template>
  <div>
    <v-container>
      <v-layout row wrap justify-space-between>
        <v-flex xs2>
          <v-text-field
            v-model="length"
            label="Length"
            suffix="in"
          ></v-text-field>
        </v-flex>
        <v-flex xs2>
          <v-text-field
            v-model="port1"
            label="End One"
            placeholder="Ex: DVI-D - Male"
          ></v-text-field>
        </v-flex>
        <v-flex xs2>
          <v-text-field
            v-model="port2"
            label="End Two"
            placeholder="Ex: DVI-D - Male"
          ></v-text-field>
        </v-flex>
        <v-flex xs2>
          <choice-list :config="subTypeConfig"></choice-list>
        </v-flex>
      </v-layout>
    </v-container>
    <standard-actions
      @save="save"
      @cancel="cancel"
      @close="$emit('close')"
      :save-button="{ name: 'Submit', disabled: !canProceed }"
      :cancel-button="{ name: 'Back' }"
    ></standard-actions>
  </div>
</template>

<script>
import standardActions from '../standardActions'
import choices from '../../autocompletes/choices'

export default {
  name: 'storage',
  components: {
    'standard-actions': standardActions,
    'choice-list': choices
  },
  computed: {
    canProceed () {
      return this.length && this.port1 && this.port2 && this.subTypeConfig.id > -1
    }
  },
  data () {
    return {
      length: null,
      port1: null,
      port2: null,
      subTypeConfig: {
        id: 0,
        label: 'Sub Type',
        appName: 'parts',
        modelName: 'cable',
        fieldName: 'sub_type'
      }
    }
  },
  methods: {
    save () {
      const details = {
        length: this.length,
        port: this.port1,
        port_2: this.port2,
        sub_type: this.subTypeConfig.id
      }
      this.$emit('save', details)
    },

    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
