






















































import { defineComponent, provide, Ref, ref } from '@vue/composition-api'
import { TEST_STATUS_TAGS } from '@/api/graphql/Constants/Choices'
import mutateOrderItems from '../../api/graphql/mutationsJS/mutateOrderItems'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import { ApolloClients, useQuery } from '@vue/apollo-composable'
import { apolloClient } from '@/api/graphql/apollo'
import { useMarkAsTestedForm } from '@/composition/UseMarkAsTestedForm'
import {
  Choices_Tag,
  TestStatusTagsForListQuery
} from '@/models/generated/graphql/ErpBackend'
import { SET_ITEM_TEST_STATUS } from '@/api/graphql/Constants/Inventory'
import { RowNode } from 'ag-grid-community'
import tAlert from '@/components/notifications/tAlert.vue'
import { useTAlert } from '@/composition/UseTAlert'
export default defineComponent({
  name: 'markTested',
  mixins: [mutateOrderItems],
  components: {
    't-alert': tAlert,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  props: {
    nodes: {
      type: Array as () => RowNode[],
      required: true
    }
  },
  setup (props, { emit }) {
    provide(ApolloClients, {
      default: apolloClient
    })

    const { alert } = useTAlert('This will replace currently selected statuses on the item(s).')

    const confirmed = ref(false) // used for confirming to remove all statuses
    const tags: Ref<Array<Choices_Tag | Pick<Choices_Tag, 'id' | 'tag' | 'description' | 'color'>>> = ref([])
    const succeeded = ref(false)
    const saving = ref(false)

    const { form, validate, hasErrors } = useMarkAsTestedForm(props.nodes.map(n => n.data.id), false, () => { alert.message = '' })

    const {
      loading,
      onResult
    } = useQuery<TestStatusTagsForListQuery>(TEST_STATUS_TAGS)
    onResult((result) => {
      tags.value = result.data?.tags ?? []
    })

    const doTheMutation = () => {
      return new Promise((resolve, reject) => {
        const promises = []
        for (const i of form.input) {
          const mutation = {
            mutation: SET_ITEM_TEST_STATUS,
            variables: i
          }
          promises.push(apolloClient.mutate(mutation))
        }
        Promise.all(promises).then(() => resolve()).catch(e => reject(e))
      })
    }

    const submit = async () => {
      if (!confirmed.value && form.ids.value.length === 0) {
        alert.setter({
          message: 'This will remove all statuses from selected parts. Click submit again to confirm.',
          type: 'warning',
          timeout: 0
        })
        confirmed.value = true
      } else {
        validate()
        if (!hasErrors.value) {
          try {
            saving.value = true
            await doTheMutation()
            alert.setter({ message: 'Marked statuses successfully', type: 'success', timeout: 0 })
            succeeded.value = true
          } catch (e) {
            console.log(e)
            alert.setter({ message: e.message, type: 'error', timeout: 0 })
          } finally {
            saving.value = false
          }
        }
      }
    }

    const removeStatus = (id: number) => {
      const index = form.ids.value.findIndex(item => item === id)
      form.ids.setter({ value: form.ids.value.splice(index, 1) })
    }

    const cancel = () => {
      emit('close')
    }

    return {
      succeeded,
      alert,
      form,
      hasErrors,
      loading,
      tags,
      saving,
      submit,
      cancel,
      removeStatus
    }
  }
})
