<template>
  <nav class="t-topbar">
    <v-layout row justify-space-between align-center>
      <!--------- Left side stuff ---------->
      <div v-if="isElectron" style="width: 70px;"></div>
      <v-layout row nowrap justify-start align-center>
        <!-- App Titles  -->
        <v-progress-circular color="white" v-if="noCompany" indeterminate/>
        <v-menu
          v-else
          bottom
          transition="slide-y-transition"
        >
          <template #activator="{ on: menu }">
            <v-btn
              v-if="tenants.length > 0"
              flat
              color="white"
              v-on="menu"
              style="font-weight: bold;"
            >{{ company }}</v-btn>
            <span v-else class="t-bar-item bold" id="app-title">{{ company }}</span>
          </template>
          <v-list>
            <v-list-tile
              v-for="tenant in tenants"
              :key="tenant"
              @click="switchTenant(tenant)"
            >
              <v-list-tile-title>{{ tenant }}</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
        <span v-if="isDev" class="bold white--text">{{ breakpoint }}</span>
        <v-btn v-if="isDev" flat @click="showReports">
          <v-icon color="white" class="t-bar-item">
            fal fa-analytics
          </v-icon>
        </v-btn>
      </v-layout>
      <!--------- Right side stuff ---------->
      <v-layout row reverse nowrap justify-start align-center>
          <!-- Settings -->
          <v-btn
            class="mx-0"
            flat
            to="/settings/account-management"
            active-class="i-active"
            data-cy="settings-topbar-button"
          >
            <v-icon color="white">fal fa-cog</v-icon>
          </v-btn>
          <!-- User Settings -->
          <v-menu
            offset-y
            left
            transition="slide-y-transition"
            :dark="theme === 'dark'"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-0"
                flat
                v-on="on"
              >
                <v-icon color="white">fal fa-user</v-icon>
              </v-btn>
            </template>
            <v-list
            >
              <v-list-tile
              >
                <v-list-tile-title
                  class="bold"
                >
                  {{ email }}
                </v-list-tile-title>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile class="purple--text" @click="showBetaProgress">
                <v-list-tile-content>
                  <v-list-tile-title>Product Testing Dashboard</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile @click="goToHelpDesk()">
                <v-list-tile-title
                >
                  TRAQSYS Support Desk
                </v-list-tile-title>
                <v-list-tile-avatar>
                  <v-icon small color="primary">fas fa-external-link</v-icon>
                </v-list-tile-avatar>
              </v-list-tile>
              <v-list-tile @click="showQuickSupport()">
                <v-list-tile-title>
                  TRAQSYS Quick Support
                </v-list-tile-title>
                <v-list-tile-avatar>
                  <v-icon small color="black"> fal fa-question-circle </v-icon>
                </v-list-tile-avatar>
              </v-list-tile>
              <v-list-tile
                @click="logout"
              >
                <v-list-tile-title>
                  Log Out
                </v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
          <!-- Notifications -->
          <notifications v-if="showNotifications"/>
          <!-- More Button -->
          <div class="t-dropdown-hover t-right" v-if="isDev">
            <v-menu
              offset-y
              left
              transition="slide-y-transition"
              :dark="theme === 'dark'"
            >
              <button
                slot="activator"
                class="t-button"
              >
                <i
                  class="fal fa-ellipsis-h-alt t-icon-font"
                >
                </i>
              </button>
              <v-list>
                <v-list-tile
                  v-for="button in moreButtonTiles"
                  :key="button.name"
                  @click="button.action"
                >
                  <v-list-tile-title>{{ button.name }}</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </div>
      </v-layout>
    </v-layout>
    <!-------- Bottom Sheet Stuff --------->
    <v-bottom-sheet
      v-model="showSheet"
      :inset="true"
      persistent
      hide-overlay
      max-width="90%"
    >
      <component :is="sheet.component"/>
    </v-bottom-sheet>
    <!------------- Dialogs --------------->
    <v-dialog
      v-model="showDialog"
      lazy
      persistent
      v-bind="dialogProps"
      :scrollable="dialogComponent === 'beta-progress'"
    >
      <v-card dark v-if="dialogComponent === 'switching-tenants'">
        <v-card-title>Switching Tenants...</v-card-title>
        <v-card-text>
          <v-progress-linear
            indeterminate
            :color="tenantColor"
          />
        </v-card-text>
      </v-card>
      <component v-else :is="dialogComponent" @close="$store.dispatch('grid/resetDialog')"></component>
    </v-dialog>
  </nav>
</template>

<script>
import newQuote from '../forms/newQuote'
import newClient from '../forms/newClient'
import newWorkOrder from '../forms/newWorkOrder'
import newSale from '../forms/newSale'
import newPurchase from '@/components/forms/newPurchase'
import newPurchaseRma from '@/components/forms/newPurchaseRma'
import newSaleRma from '@/components/forms/newSaleRma'
import newPart from '@/components/forms/newPart'
import NotificationContainer from '@/components/notifications/NotificationContainer'
import buysellhistory from '@/components/modals/buysellhistory'
import { freshDeskWidget } from '@/lib/freshDesk.ts'
import printTags from '@/components/dialogs/printTags'
import ReportContainer from '@/views/reporting/ReportContainer'
import { LOCAL_STORAGE_KEYS, locStore } from '@/lib/LocalStorageWrapper'
import BetaProgress from '@/components/dialogs/BetaProgress.vue'

export default {
  name: 'TopBar',
  components: {
    'beta-progress': BetaProgress,
    'newQuote': newQuote,
    'newClient': newClient,
    'newWorkOrder': newWorkOrder,
    'newSale': newSale,
    'newPurchase': newPurchase,
    'prma': newPurchaseRma,
    'srma': newSaleRma,
    'newPart': newPart,
    'notifications': NotificationContainer,
    'buysellhistory': buysellhistory, // used in parts.vue
    'print-tags': printTags,
    'report-container': ReportContainer
  },
  computed: {
    showNotifications () {
      return this.$store.getters['profile/isDev']
    },

    dialogProps () {
      return this.dialog.props ?? null
    },

    isElectron () {
      return navigator.userAgent.includes('Electron')
    },

    user () {
      return this.$store.state.profile.user
    },

    userIsSet () {
      return this.user.firstName
    },

    email () {
      return this.$store.state.profile.user.email
    },

    breakpoint () {
      return this.$vuetify?.breakpoint?.name ?? ''
    },

    isDev () {
      return this.user.isSuperuser
    },

    tenants () {
      const tenants = this.$store.getters['auth/tenantsForSwitcher']
      const addLocal = window.location.origin.includes('localhost') && tenants.findIndex(t => t === 'LOCAL') === -1
      addLocal && tenants.push('LOCAL')
      return tenants
    },

    switchingTenants: {
      get () {
        return this.$store.state.data.switchingTenants
      },
      set (value) {
        this.$store.commit('data/setSwitchingTenants', { bool: value })
      }
    },

    company () {
      return this.$store.state.profile.company.name
    },

    noCompany () {
      return this.company === ''
    },

    theme: {
      get: function () {
        return this.$store.state.profile.theme
      },
      set: function (newValue) {
        this.$store.dispatch('profile/changeTheme', { theme: newValue })
      }
    },

    showSheet () {
      return this.sheet.app === 'topbar'
    },

    orderType () {
      return this.$store.state.orders.orderType
    },

    sheet () {
      return this.$store.state.grid.sheet
    },

    showDialog: {
      get () {
        return this.dialog?.app === 'topbar' && this.dialogComponent !== ''
      },
      set (value) {
        return value
      }
    },

    dialog: {
      get () {
        const dialog = this.$store.state.grid.dialog
        if (dialog.app === 'topbar') {
          return dialog
        } else return { dialog: '', component: '' }
      },
      set (value) {
        this.$store.dispatch('grid/changeDialog', value)
      }
    },

    dialogComponent () {
      return this.dialog.component
    }

  },
  watch: {
    switchingTenants: function (value) {
      if (value) {
        this.colorInterval = setInterval(() => {
          this.changeColor()
        }, 1000)
      } else {
        this.dialog = { app: '', component: '' }
        this.colorInterval = {}
        this.tenantColor = 'red'
      }
    },
    showDialog: function (val) {
      !val && this.$store.dispatch('grid/resetDialog')
    }
  },
  data () {
    return {
      tenantColor: 'red',
      tenantColors: [
        'red',
        'orange',
        'yellow',
        'green',
        'blue',
        'purple',
        'black',
        'white'
      ],
      colorInterval: {},

      moreButtonTiles: [
        { name: 'Test Button', action: () => this.test() },
        { name: 'Toggle Dark Mode', action: () => this.toggleDarkMode() },
        { name: 'Test Notify', action: () => { this.testNotify() } },
        {
          name: 'Test Route thing',
          action: () => {
            this.$router.replace({ name: this.$route.name, query: { dialog: 'reports', reports: [1, 2, 3] } })
          }
        }
      ]
    }
  },
  methods: {
    showQuickSupport () {
      freshDeskWidget?.open()
    },

    showBetaProgress () {
      this.dialog = { app: 'topbar', component: 'beta-progress', props: {} }
    },

    showReports () {
      this.dialog = { app: 'topbar', component: 'report-container', props: { fullscreen: true } }
    },

    goToHelpDesk () {
      window.open('https://help.traqsys.com/support/home', '_blank')
    },

    switchTenant (tenant) {
      locStore.set(LOCAL_STORAGE_KEYS.tenant, tenant)
      this.$apollo.getClient().stop()
      this.$apollo.getClient().resetStore()
        .then(() => {
          this.switchingTenants = true
          this.dialog = { app: 'topbar', component: 'switching-tenants' }
          this.$store.dispatch('auth/attemptAutoLogin')
            .then(() => {
              setTimeout(() => {
                this.$store.dispatch('data/changeRefresh', { bool: true })
              }, 1000)
            })
        })
    },

    changeColor () {
      const index = this.tenantColors.findIndex(color => color === this.tenantColor)
      if (index === this.tenantColors.length - 1) this.tenantColor = this.tenantColors[0]
      else this.tenantColor = this.tenantColors[index + 1]
    },

    toggleDarkMode () {
      const mode = (this.theme === 'light')

      if (mode) {
        this.theme = 'dark'
        document.body.classList = 'dark'
      } else {
        this.theme = 'light'
        document.body.classList = 'light'
      }
    },

    logout () {
      this.$store.dispatch('auth/logout', { expire: false })
    },

    testNotify () {
      fetch('http://10.5.1.9:8001/reset')
    },

    async test () {
    }
  }
}
</script>
