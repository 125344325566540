import { apolloClient } from '@/api/graphql/apollo'
import router from '../../router/router'
import gql from 'graphql-tag'
import Vue from 'vue'
import { DELETE_TABLE_CONFIG, GET_LOGIN_DATA, GET_OTHER_LOGIN_DATA } from '@/api/graphql/Constants/Profile'
import { InitUserflow } from '@/api/userflow'
import { LogIt } from '@/lib/logger'
// module for storing state related to user accounts/profiles and logging in

const state = {
  // user data
  user: {
    role: {},
    username: '', // THIS IS THE KEYCLOAK ID OF THE USER
    email: '',
    initials: '',
    firstName: '',
    lastName: '',
    dateJoined: '',
    profile: {
      color: '',
      jobTitle: '',
      manager: '',
      department: ''
    },
    isSuperuser: false,
    id: 0
  },
  keycloakId: '',
  tableConfigs: [],

  // company data
  company: {
    name: '',
    returnTo: ''
  },
  logo: '',
  currency: '',

  theme: 'light',

  devs: ['AGL', 'JTL', 'WDH'],

  roles: [
    {
      name: 'accountant',
      canViewTech: false,
      canViewAcct: true,
      canViewIO: false,
      canViewSettings: false,
      canViewReports: true,
      canViewBD: false,
      canViewProc: false,
      canViewLogs: true
    },
    {
      name: 'tech',
      canViewTech: true,
      canViewAcct: false,
      canViewIO: false,
      canViewSettings: false,
      canViewReports: false,
      canViewBD: true,
      canViewProc: true,
      canViewLogs: false
    },
    {
      name: 'admin',
      canViewTech: true,
      canViewAcct: true,
      canViewIO: true,
      canViewSettings: true,
      canViewReports: true,
      canViewBD: true,
      canViewProc: true,
      canViewLogs: true
    },
    {
      name: 'sales',
      canViewTech: true,
      canViewAcct: false,
      canViewIO: true,
      canViewSettings: false,
      canViewReports: true,
      canViewProc: true,
      canViewBD: true,
      canViewLogs: false
    }
  ]
}

const getters = {
  tableConfig: state => (table) => {
    const found = state.tableConfigs.filter(config => {
      return config.table === table
    })
    if (found.length > 0) {
      let config = found[0].config
      while (typeof config === typeof 'string') {
        config = JSON.parse(config)
      }
      return config
    } else {
      return []
    }
  },

  user: state => {
    return state.user
  },

  fullName: state => {
    const first = state.user.firstName
    const last = state.user.lastName
    return `${first} ${last}`
  },

  tableConfigDoesNotExist: state => table => {
    const found = state.tableConfigs.find(c => c.table === table)
    return !found
  },

  tableConfigId: state => table => {
    return state.tableConfigs.find(c => c.table === table)?.id ?? 0
  },

  isDev: state => {
    return state.user.isSuperuser
  },

  isTest: state => {
    return (state.user.initials === 'DEV')
  }
}

const mutations = {
  setTheme (state, payload) {
    state.theme = payload.theme
  },

  update (state, payload) {
    state[payload.key] = payload.value
  },

  setUserRole (state, role) {
    // TODO: calculate role shit based off of what is received from db
  },

  updateProfile (state, { key, value }) {
    Vue.set(state.user.profile, key, value)
  },

  setCompanyInfo (state, payload) {
    state.company.name = payload.name
    state.company.returnTo = payload.default_return_to_address?.id ?? ''
  },

  /**
   * Sets the initial value for tableConfigs
   * @param state
   * @param {Array<Object>} payload
   */
  setTableConfigs (state, payload) {
    state.tableConfigs = payload.configs
  },

  /**
   * Update config for a table
   * @param state
   * @param payload
   * @param payload.index
   * @param payload.config
   * @param payload.table
   */
  updateTableConfig (state, payload) {
    state.tableConfigs[payload.index].config = payload.config
  },

  addNewTableConfig (state, payload) {
    state.tableConfigs.push(payload.config)
  },

  deleteTableConfig (state, payload) {
    state.tableConfigs.splice(payload.index, 1)
  },

  /**
   * Goes through and sets all user keys from snake_case to camelCase
   * @param state
   * @param payload
   */
  setUserData (state, payload) {
    for (const key of Object.keys(payload)) {
      if (key !== 'profile') {
        if (key !== '__typename') {
          const toCamel = (key) => {
            return key.replace(/([-_][a-z])/ig, ($1) => {
              return $1.toUpperCase()
                .replace('-', '')
                .replace('_', '')
            })
          }
          state.user[toCamel(key)] = payload[key]
        }
      } else {
        for (const pKey of Object.keys(payload[key])) {
          if (pKey !== '__typename') {
            const toCamel = (key) => {
              return key.replace(/([-_][a-z])/ig, ($1) => {
                return $1.toUpperCase()
                  .replace('-', '')
                  .replace('_', '')
              })
            }
            state.user.profile[toCamel(pKey)] = payload[key][pKey]
          }
        }
      }
    }
    const role = state.roles.filter(role => {
      return role.name === 'admin'
    })[0]
    Vue.set(state.user, 'role', role)
    state.loggingOut = false

    // route push
    state.authenticated = true
    if (state.user.email !== 'dev.last@traqsys.com') { InitUserflow() }

    if (state.lastActiveUrlName) {
      router.push({ name: state.lastActiveUrlName })
        .catch(() => {
          router.push({ name: 'item' })
            .catch(() => {
            })
        })
    } else if (router.currentRoute.name === 'login') {
      router.push({ name: 'item' })
        .catch(() => {
        })
    }
  },

  updateCompanyLogo (state, payload) {
    state.logo = payload.logo
  },

  setUserID (state, payload) {
    state.user.id = payload.id
  }
}

const actions = {
  update ({ commit }, payload) {
    commit('update', payload)
  },
  /**
   * Updates the table config
   * @param state
   * @param commit
   * @param {Object} payload
   * @param {Object} payload.config
   * @param {String} payload.table
   * @return {Promise<void>}
   */
  updateTableConfig ({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      const newPayload = payload
      newPayload.index = state.tableConfigs.findIndex(config => {
        return config.table === payload.table
      })
      if (newPayload.index > -1) {
        const id = state.tableConfigs[newPayload.index].id
        commit('updateTableConfig', newPayload)
        apolloClient.mutate({
          mutation: gql`mutation UpdateTableConfig ($config: JSON!, $id: ID!) {
            config: Update__Profile_TableConfig (input: { id: $id, config: $config }) {
              id
            }
          }`,
          variables: { id: id, config: JSON.stringify(payload.config) }
        })
      } else {
        apolloClient.mutate({
          mutation: gql`mutation CreateTableConfig ($config: JSON!, $table: String!) {
            config: Create__Profile_TableConfig (input: { table_name: $table, config: $config }) {
              id
              table: table_name
              config
            }
          }`,
          variables: { table: payload.table, config: JSON.stringify(payload.config) }
        })
          .then(response => {
            commit('addNewTableConfig', { config: response.data.config })
            resolve()
          })
      }
    })
  },

  /**
   *
   * @param commit
   * @param {number} index
   */
  async deleteTableConfig ({ commit }, { index }) {
    try {
      await apolloClient.mutate({
        mutation: DELETE_TABLE_CONFIG,
        variables: { id: index }
      })
      commit('deleteTableConfig', { index })
    } catch (error) {
      return 'Could not delete table config'
    }
  },

  /**
   * Grabs the tableConfig from the database
   * @param commit
   * @param rootState
   * @returns {Promise<void>}
   */
  getTableConfigs ({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: gql`query GetUserTableConfigs {
          configs: profile_table_configs (input: { filters: [{ key: "creator__id", value: ${state.user.id} }]}) {
            id
            table: table_name
            config
          }
        }`
      }).then(response => {
        commit('setTableConfigs', { configs: response.data.configs })
        resolve()
      })
    })
  },

  updateProfile ({ commit }, payload) {
    commit('updateProfile', payload)
  },

  /**
   * Apollo query to get all relevant user and company data on login
   * @param commit
   * @param dispatch
   * @param state
   * @return {Promise<void>}
   */
  async getLoginData ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: GET_LOGIN_DATA
      })
        .then(({ data }) => {
          dispatch('getOtherLoginData')
          const user = data.me
          const company = data.company_company
          const settingsKickOffObject = {
            settings: user.profile.settings,
            id: user.id
          }
          commit('setUserData', user)
          commit('setCompanyInfo', company)
          dispatch('getOtherLoginData')
          dispatch('getTableConfigs')
            .then(() => {
              dispatch('data/settingsKickOff', settingsKickOffObject, { root: true })
              commit('auth/updateState', { key: 'authenticated', value: true }, { root: true })
              resolve()
            })
        })
        .catch((error) => {
          commit('setCompanyInfo', { name: 'TRAQSYS ERP' })
          reject(new Error(error.message))
        })
    })
  },

  /**
   * Retrieves other data that isn't necessary for login
   * @param commit
   * @returns {Promise<void>}
   */
  async getOtherLoginData ({ commit }) {
    try {
      const response = await apolloClient.query({
        query: GET_OTHER_LOGIN_DATA
      })
      if (response.data) {
        const statuses = response.data.statuses
        // const schema = response.data.__schema
        commit('updateCompanyLogo', { logo: response.data.company_company.logo })
        commit('data/setStatuses', { statuses: statuses }, { root: true })
        commit('data/setTags', { tags: response.data.tags }, { root: true })
        commit('data/setSchema', null, { root: true })
      }
    } catch (e) {
      LogIt.Warn(e)
    }
  },

  changeTheme ({ commit }, payload) {
    commit('setTheme', payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
