<template>
  <div :class="{ lost: data.is_lost }">
    <div v-if="data">
      <i v-if="data.locked" class="fal fa-lock g-icon mr-2"></i>
      <v-tooltip
        top
        lazy
        v-if="hasOriginalPart"
      >
        <template #activator="{ on }">
          <v-icon v-on="on" small color="primary">fal fa-info-circle</v-icon>
        </template>
        <span>Original part to allocate is: <b>{{ originalPart.pn }}</b></span>
      </v-tooltip>
      <i v-if="data.hot" class="far fa-fire g-icon orange--text text--darken-3"></i>
      <span style="margin-left: 5px;">{{ data.part.pn }}</span>
      <v-tooltip v-if="data.test_status && data.test_status.length === 0" top lazy>
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            size="12"
            color="#9295A0"
            class="part-tag"
            style="margin-bottom: 2px;"
          >fas fa-circle
          </v-icon>
        </template>
        <span>Not Tested</span>
      </v-tooltip>
      <template v-for="tag in data.test_status">
        <v-tooltip :key="tag.id" top>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              size="12"
              :color="tag.color"
              class="part-tag"
              style="margin-bottom: 2px;"
            >fas fa-circle
            </v-icon>
          </template>
          <span>{{ tag.tag }}</span>
        </v-tooltip>
      </template>
    </div>
    <span v-else>{{ params.value }}</span>
  </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
  name: 'partNumberCell',
  data () {
    return {
      data: null,
      hasOriginalPart: false,
      originalPart: { id: 0, pn: '' }
    }
  },
  created () {
    this.data = this.params.data?.item ?? this.params?.data ?? false
    if (this.data.__typename === 'Sales_SoldItems') {
      const originalPart = this.data.original_part
      if (originalPart) {
        this.hasOriginalPart = true
        this.originalPart = this.data.original_part
      }
    }
  }
})
</script>
