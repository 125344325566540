

















import { computed, defineComponent, provide } from '@vue/composition-api'
import { apolloClient } from '@/api/graphql/apollo'
import { ApolloClients, useQuery, UseQueryReturn, useResult, UseResultReturn } from '@vue/apollo-composable'
import { GET_TYPE__FIELDS } from '@/api/graphql/Constants/Company'
import { Chart_Type } from '@/models/generated/graphql/ErpBackend'

export default defineComponent({
  name: 'ReportTypeSelect',
  props: {
    type: {
      type: String as () => Chart_Type,
      default: '',
      required: true
    },
    enumName: {
      type: String,
      required: true
    },
    hint: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup (props, { emit }) {
    provide(ApolloClients, {
      default: apolloClient
    })
    const localType = computed({
      get: () => props.type,
      set: value => emit('updated', { value: value })
    })

    interface ChartType {
      name: string
    }

    interface SchemaType {
      __type: {
        enumValues: ChartType[]
      }
    }

    const { result, loading }: UseQueryReturn<SchemaType, { name: string }> = useQuery(GET_TYPE__FIELDS, {
      name: props.enumName.toUpperCase()
    })
    const types: UseResultReturn<string[]> = useResult(result, [], data => data.__type?.enumValues.map(v => v.name))

    return {
      types,
      localType,
      loading
    }
  }
})
