import userflow from 'userflow.js'
import store from '@/store/store'
const version = require('../../package.json').version

export function InitUserflow () {
  userflow.init('ct_vrb2zfbnj5fl7ogxcdj6frcfe4')
  userflow.identify(store.state.profile.user.username, {
    name: store.state.profile.user.fullName,
    first_name: store.state.profile.user.firstName,
    last_name: store.state.profile.user.lastName,
    email: store.state.profile.user.email,
    signed_up: { set_once: store.state.profile.user.dateJoined },
    last_version: { set: version, data_type: 'string' }
  })
}
