import moment from 'moment'
const columns = {
  computed: {
    isElectron () {
      return (window.process && window.process.type === 'renderer')
    }
  },
  data () {
    return {
      fields: {
      }
    }
  },
  methods: {
    checkBoolRenderer (params) {
      return params.value
        ? '<i class="fas fa-check-circle grid-icon-font grid-icon-green"></i>'
        : '<i class="fas fa-times-circle grid-icon-font grid-icon-red"></i>'
    },

    columnize (columns) {
      if (columns) {
        for (const column of columns) {
          const name = column.field.toLowerCase()
          // if is a data object
          if (name.includes('date') || name === 'eta' || name.includes('last_login')) {
            column.cellRenderer = this.dateCellRenderer
            column.comparator = this.dateComparator
            column.filter = false
            // if is a user object
          } else if (name.includes('_by') && !name.includes('.')) {
            const field = name + '_user'
            column.field = field
            column.cellRenderer = function (params) {
              return params.value ? params.value.initials : ''
            }
            // if money object
          } else if ((column.headername === 'pt') || (column.headername === 'st')) {
            // if it isn't an id of a field
            if (!column.field.includes('id')) {
              // column.valuegetter = this.ordervaluegetter
              // column.comparator = this.ordercomparator
              // column.filterparams = {
              //   buttons: ['reset', 'apply'],
              //   newrowsaction: 'keep'
              // }
              // column.filterparams.filteroptions = ['equals']
              //
              //
              // if it is
            } else {
              column.filterparams = {
                filteroptions: ['is'],
                buttons: ['reset', 'apply']
              }
            }
            column.floatingfiltercomponentparams = { suppressfilterbutton: true }
          }
        }
        return columns
      }
      return []
    },

    dateCellRenderer (params) {
      return params.value ? moment(params.value).format('L') : ''
    },

    dateTimeCellRenderer (params) {
      return params.value ? moment(params.value).format('DD/MM/YYYY hh:mm:ss a') : ''
    },

    dateComparator (date1, date2) {
      if (date1 === null && date2 !== null) {
        return -1
      } else if (date2 === null && date1 !== null) {
        return 1
      } else if (date1 === null & date2 === null) {
        return 0
      }
      if (moment(date1).isBefore(date2)) {
        return -1
      } else if (moment(date2).isBefore(date1)) {
        return 1
      }
      return 0
    },

    orderValueGetter (params) {
      const order = this.$_.get(params, `data[${params.column.colId}]`, false)
      const orderItem = this.$_.get(params, `data.item[${params.column.colId}]`, false)
      if (order) { // most order numbers
        return order.transaction.id + '-' + order.line_number
      } else if (orderItem) { // rma items
        return `${orderItem.transaction.id}-` +
          `${orderItem.line_number}`
      } else {
        return ''
      }
    },

    moneyComparator (a, b) {
      const af = a ? parseFloat(a) : 0.00
      const bf = b ? parseFloat(b) : 0.00
      return parseFloat(af) - parseFloat(bf)
    },

    orderComparator (a, b) {
      if (a && b) {
        const aPT = a.split('-')[0]
        const aLineNumber = a.split('-')[1]
        const bPT = b.split('-')[0]
        const bLineNumber = b.split('-')[1]

        if (aPT !== bPT) {
          return aPT - bPT
        } else {
          return aLineNumber - bLineNumber
        }
      }
    }
  }
}

export default columns
