class Logger {
  Log (message: string) {
    console.log(message)
  }

  Warn (message: string) {
    console.warn(message)
  }

  Error (message: string) {
    console.error(message)
  }

  Log_Info (message: string) {
    console.log(`%c${message}`, 'color: hsl(207,90%,54%); font-weight: bold;')
  }
}

export const LogIt = new Logger()
