<template>
    <client-details-row :params="params"/>
</template>

<script>
import Vue from 'vue'
import clientDetailsRow from '@/components/renderers/clientDetailsRow'
export default Vue.extend({
  name: 'clientDetailsRenderer',
  components: {
    'client-details-row': clientDetailsRow
  }
})
</script>

<style scoped>

</style>
