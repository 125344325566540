// vuex store for managing editing data and prepping it for the backend
import { LOCAL_STORAGE_KEYS, locStore } from '@/lib/LocalStorageWrapper'
import { UpdateProfileSettings } from '@/api/graphql/Constants/Profile.ts'
import { Sentry } from '@/lib/Sentry.ts'

const state = {
  editedData: {},
  editOrder: { id: '', statusId: '' },
  dataToDelete: [],
  logo: '',
  saving: false,
  refresh: false,
  switchingTenants: false,
  locations: { names: [], objects: [] },
  choices: [],
  statuses: [],
  tags: [],
  inputObjects: [],
  users: [],
  schema: {},
  dateFields: [], // this stores/caches all of the fields in the schema that are of type "DateTime" or "Date"

  userSettings: {
    showColumnBorders: false,
    defaultPrinterId: 0
  },

  autofillLists: {
    clients: [],
    contacts: [],
    quotes: [],
    billTos: [],
    terms: [],
    reps: [],
    shipFroms: [],
    shipTos: [],
    parts: [],
    servers: [],
    accounts: [],
    shippers: [],
    shippingServices: [],
    sites: [],
    unAllocatedParts: [],
    saleRMAs: [],
    purchaseRMAs: []
  },

  loading: {
    clients: false,
    contacts: false,
    quotes: false,
    billTos: false,
    terms: false,
    reps: false,
    shipFroms: false,
    shipTos: false,
    parts: false,
    servers: false,
    accounts: false,
    shippers: false,
    shippingServices: false,
    sites: false,
    unAllocatedParts: false,
    saleRMAs: false,
    purchaseRMAs: false
  }
}

const getters = {
  isElectron: () => {
    return navigator.userAgent.includes('Electron')
  },

  settings: state => {
    return state.userSettings
  },

  version: (state, getters) => {
    return getters.isElectron ? '' : require('@/../package.json').version
  },

  inputObjectFields: state => name => {
    return state.inputObjects.filter(object => {
      return object.name === name
    })[0].inputFields
  },

  inputObjects: state => {
    return state.inputObjects
  },

  editedData: state => {
    return state.editedData
  },

  dateSchemaField: state => name => {
    return state.dateFields.find(f => {
      return f.name.toLowerCase() === name.toLowerCase()
    }) ?? null
  },

  saving: state => {
    return state.saving
  },

  refresh: state => {
    return state.refresh
  },

  locations: state => {
    return state.locations
  },

  /**
   * @deprecated
   * @param state
   * @return {function(*, *, *): *[]}
   */
  choices: state => (app, model, field) => {
    return state.choices.filter(item => {
      return item.app_name === app &&
        item.model_name === model &&
        item.field_name === field
    })
  },

  statuses: state => (app, model, field) => {
    return state.statuses.filter(item => {
      return item.app === app &&
        item.model === model &&
        item.field === field
    })
  },

  nextStatus: state => (app, model, field, order) => {
    return state.statuses.find(status => {
      return status.app === app && status.model === model &&
        status.field.toLowerCase() === field.toLowerCase() && status.order === order + 1
    })
  },

  status: state => id => {
    return state.statuses.filter(status => {
      return status.id === id
    })[0]
  },

  userFromId: state => id => {
    return state.users.filter(u => u.id === id)[0]
  },

  vendors: state => {
    return state.vendors
  },

  partTypes: state => {
    return state.partTypes
  }
}

const mutations = {

  /**
   * Set the current user settings in vuex
   * @param state
   * @param {Object} payload
   * @param {{ defaultPrinterId: number, showColumnBorders: boolean }} payload.settings the users settings
   */
  setUserSettings (state, payload) {
    state.userSettings = payload.settings
  },

  updateUserSettingsKey (state, { key, value }) {
    state.userSettings[key] = value
  },

  setSchema (state) {
    const schema = require('../../../graphql.schema.json').__schema
    state.schema = schema

    const fields = []
    for (const type of schema.types) {
      if (type.fields) {
        fields.push(...type.fields.filter(f => {
          return f.type?.name?.toLowerCase().includes('date') && ['creation_date', 'updated_date'].indexOf(f.name) === -1
        }))
      }
    }

    state.dateFields = fields
    locStore.set(LOCAL_STORAGE_KEYS.dateFields, fields)
  },

  setDateFields (state, payload) {
    state.dateFields = payload.fields
  },

  setUsers (state, { users }) {
    state.users = users
  },

  setSwitchingTenants (state, { bool }) {
    state.switchingTenants = bool
  },

  /**
   * Set input objects for dynamic field entry
   * @param state
   * @param payload
   */
  setInputObjects (state, payload) {
    state.inputObjects = payload.objects
  },

  setLocations (state, payload) {
    // payload: { key, list }
    state.locations.names = payload.names
    state.locations.objects = payload.objects
  },

  commitEditions (state, payload) {
    // payload: { id, column, value, purchaseItemID, moneyItemID }
    const id = payload.id
    const nestedID = payload.purchaseItemID ? payload.purchaseItemID : ''
    let column = payload.column
    let value = payload.value
    const deleteIndex = state.dataToDelete.findIndex(item => {
      return item.id === id
    })

    // if column has location, need to translate to primary key for patch
    if (column.indexOf('location') > -1) {
      column = 'location'
      value = state.locations.objects.filter(item => {
        return item.name === value
      })[0].id
    }

    // if column is nested, grab the leaf key
    if (column.indexOf('.') > -1) {
      column = column.split('.').pop()
    }

    // if this is the first time this id is being edited, create the obj
    if (!state.editedData[id] && column !== 'delete' && deleteIndex === -1) {
      state.editedData[id] = {}
    }

    // if they are deleting this ID handle accordingly, otherwise make the edit
    if (column === 'delete') {
      if (value) {
        delete state.editedData[id]
        state.dataToDelete.push({ id: id, purchaseItemID: nestedID, moneyID: payload.moneyItemID })
      } else if (!value && deleteIndex > -1) {
        state.dataToDelete.splice(1, deleteIndex)
      }
    } else if (column !== 'delete' && deleteIndex === -1) {
      state.editedData[id][column] = value
    }
  },

  clearEditions (state) {
    state.editedData = {}
  },

  clearDataToDelete (state) {
    state.dataToDelete = []
  },

  resetAutofillLists (state) {
    for (const list in state.autofillLists) {
      state.autofillLists[list] = []
    }
  },

  SET_EDIT_ORDER (state, { id, status }) {
    state.editOrder.id = id
    state.editOrder.status = status
  },

  resetPartsList (state) {
    state.autofillLists.parts = []
  },

  addToRowsData (state, payload) {
    const newData = payload.data
    const oldData = state.rowData[state.currentApp.app][state.currentApp.subApp].data.results
    state.rowData[state.currentApp.app][state.currentApp.subApp].data.results = oldData.concat(newData)
  },

  setCurrentApp (state, payload) {
    // payload: { app, subApp, endpoint }
    state.currentApp = { app: payload.app, subApp: payload.subApp, endpoint: payload.endpoint }
  },

  setSaving (state, payload) {
    // payload: { bool }
    state.saving = payload.bool
  },

  setRefresh (state, payload) {
    state.refresh = payload.bool
  },

  setChoices (state, payload) {
    state.choices = payload.data
  },

  setStatuses (state, payload) {
    state.statuses = payload.statuses
  },

  setTags (state, payload) {
    state.tags = payload.tags
  },

  /**
   * Sets the autofill list for a sale
   * @param state
   * @param payload
   * @param {String} payload.list
   * @param {Array<Object>} payload.data
   */
  setAutofillData (state, payload) {
    state.autofillLists[payload.list] = payload.data
  },

  setLoading (state, payload) {
    state.loading[payload.name] = payload.bool
  }
}

const actions = {
  /**
   * Initialize settings to whatever they are in the database
   * @param commit
   * @param state
   * @param rootState
   * @param {Object} payload
   * @param {string} payload.settings
   * @param {number | number} payload.id
   */
  settingsKickOff ({ commit, state, rootState }, payload) {
    /**
     * Used to keep track of whether or not we need to update server side settings
     * @type {boolean}
     */
    let updateServerSideSettings = false
    /**
     * The parsed version of the user's settings from the server. May need to be parsed twice
     * @type {Object}
     */
    let settings = JSON.parse(payload.settings)
    if (typeof settings === typeof 'string') settings = JSON.parse(settings)
    for (const key in state.userSettings) {
      // if the key that is in the vuex state is undefined on the server side, we need to update the server side
      if (settings[key] === undefined) {
        updateServerSideSettings = true
      } else {
        // otherwise mark it in the vuex state
        state.userSettings[key] = settings[key]
      }
    }
    // if the settings on the server side don't have any keys in them (brand new user) we need to fix that
    if (Object.keys(settings).length === 0) updateServerSideSettings = true
    // fix it
    if (updateServerSideSettings) {
      UpdateProfileSettings(payload.id, state.userSettings).catch(e => Sentry.captureEvent(e))
    }
  },

  updateUserSettingsKey ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      commit('updateUserSettingsKey', payload)
      resolve(state.userSettings)
    })
  },

  clearEditions ({ commit }) {
    commit('clearEditions')
  },

  changeRefresh ({ commit }, payload) {
    commit('setRefresh', payload)
  },

  changeSaving ({ commit }, payload) {
    commit('setSaving', payload)
  },

  resetAutofillLists ({ commit }) {
    commit('resetAutofillLists')
  },

  setEditOrder ({ commit }, payload) {
    commit('SET_EDIT_ORDER', payload)
  },

  /**
   * Used with apollo to set autofill data already received in components
   * @param commit commit(setAutofillData)
   * @param {Object} payload
   * @param {String} payload.list the name of the list to update
   * @param {Array<Object>} payload.data the data to update the list with
   */
  setAutofillData ({ commit }, payload) {
    commit('setAutofillData', payload)
  },

  /**
   * Changes loading state of an autofillList
   * @param commit
   * @param {Object} payload
   * @param {String} payload.name
   * @param {Boolean} payload.bool
   */
  setAutofillLoading ({ commit }, payload) {
    commit('setLoading', payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
