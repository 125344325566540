import gql from 'graphql-tag'
import errorHandling from '../errorHandling'

export default {
  mixins: [errorHandling],
  data () {
    return {
      importedMutateAddresses: true
    }
  },
  methods: {
    /**
     * Creates an address
     * @param {Object} address
     * @return {Object} address
     */
    async createAddress (address) {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`mutation CreateAddressjs ($input: Create__Address_Address__Input!) {
            address: Create__Address_Address (input: $input) {
              id
              building_name
              street_1
              client {
                id
                name
              }
              company {
                name
              }
              easypost_id
            }
          }`,
          variables: { input: address }
        })
        return response.data.address
      } catch (error) {
        throw new Error(this.errorHandler(error))
      }
    },

    /**
     * Verifies an address via easypost
     * @param address
     * @return {Promise<boolean>}
     */
    async verifyAddress (address) {
      const response = await this.$apollo.mutate({
        mutation: gql`mutation VerifyAddressjs ($input: EasyPost_MakeAddressInput!) {
            verified: EasyPost_MakeAddress (input: $input) {
              id
              residential
              street1
              street2
              state
              city
              country
              zip
              verifications {
                delivery {
                  errors {
                    code
                    field
                    message
                  }
                  success
                }
                zip4 {
                  errors {
                    field
                    message
                  }
                  success
                }
              }
            }
          }`,
        variables: { input: address }
      })
      return response.data.verified
    },

    async DELETE_ADDRESS (id) {
      try {
        await this.$apollo.mutate({
          mutation: gql`mutation DeleteAddress {
            Delete__Address_Address (id: ${id}) {
              id
            }
          }`
        })
      } catch (error) {
        throw new Error(this.errorHandler(error))
      }
    }
  }
}
