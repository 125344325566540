/**
 * Generates a short name hash for the input company name.
 * Used for Client short_names on Blind and Double Blind orders.
 * @param {string} name - The company name
 * @param {number} seed - The number to change the hash output.
 * @returns {string} - The hashed short name limited to 10 characters.
 *
 * @example
 * shortNameGenerator('mosbius designs')
 * //=> 'OKELKMJDIP'
 *
 * @example
 * shortNameGenerator('Traqsys', 1)
 * //=> 'NEOHDJCJDC'
 */
export function shortNameHash (name: string, seed: number = 0): string {
  return hashCode(name, seed)
    .replace(/[0]/g, 'G')
    .replace(/[1]/g, 'H')
    .replace(/[2]/g, 'I')
    .replace(/[3]/g, 'J')
    .replace(/[4]/g, 'K')
    .replace(/[5]/g, 'L')
    .replace(/[6]/g, 'M')
    .replace(/[7]/g, 'N')
    .replace(/[8]/g, 'O')
    .replace(/[9]/g, 'P')
    .toUpperCase()
    .slice(0, 10)
}

/**
 * generates a 53-bit hash string
 * https://stackoverflow.com/a/52171480
 * @param {string} str  - The string to hash
 * @param {number} seed - The seed to change hash output
 * @returns {string} - The hashed string
 *
 */
export function hashCode (str: string, seed: number = 0): string {
  let h1 = 0xdeadbeef ^ seed
  let h2 = 0x41c6ce57 ^ seed
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i)
    h1 = Math.imul(h1 ^ ch, 2654435761)
    h2 = Math.imul(h2 ^ ch, 1597334677)
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909)
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909)
  return (h2 >>> 0).toString(16).padStart(8, '0') + (h1 >>> 0).toString(16).padStart(8, '0')
};

/**
 * Generates a short name based on the provided company name.
 * @param {string} name - The company name
 * @returns {string} - The new short name limited to 10 characters
 *
 * @example
 * // Get the short name
 * const result = shortNameGenerator('mosbius designs')
 * //=> MD
 *
 * @example
 * // Dashes work too
 * const result = shortNameGenerator('dashed-name')
 * //=> DN
 *
 * @example
 * // Handles most weird characters
 * const result = shortNameGenerator('Mosby & Stinson, LLC.')
 * //=> MSL
 *
 * @example
 * // Longer values cut off at 10 characters
 * const result = shortNameGenerator('superdupercompany')
 * //=> SUPERDUPER
 *
 * @example
 * // non unicode values get removed
 * const result = shortNameGenerator('überkooltimez')
 * //=> BERKOOLTIM
 *
 */
export function shortNameBasic (name: string): string {
  const title = titleCase(name)
  const match: RegExpMatchArray = title.match(/[A-Z]/g) ?? []
  let result = match.join('')
  if (result.length < 2) {
    result = name.toUpperCase()
  }
  return result.replace(/[^A-Z]/g, '').substring(0, 10)
}

/**
 * Capitalizes the first character in every word
 * @param {string} str - any string value
 * @returns {string} - The string turned into a title
 *
 * @example
 * // Turn into a title
 * const result = shortNameGenerator('mosbius designs')
 * //=> Mosbius Designs
 *
 */
export function titleCase (str: string): string {
  return str.toLowerCase().split(/[- ]/).map(s => {
    return s.charAt(0).toUpperCase() + s.substring(1).toLowerCase()
  }).join(' ')
}
