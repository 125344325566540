





















































































































































































import { ComputedRef, defineComponent, ref, Ref, computed, reactive, watch, provide, unref } from '@vue/composition-api'
import { ApolloClients, useMutation, useQuery, useResult } from '@vue/apollo-composable'
import { apolloClient } from '@/api/graphql/apollo'
import { IVuetifyDatatableHeader } from '@/models/VuetifyModels'
import { DELETE_CARRIER_ACCOUNT, GET_SHIPPING_SERVICE_LEVELS } from '@/api/graphql/Constants/Shipments'
import { UPDATE_CLIENT } from '@/api/graphql/Constants/Clients'
import addShippingAccount from '@/components/dialogs/addShippingAccount.vue'
import store from '@/store/store'
import EmailMultiSelect from '@/components/autocompletes/EmailMultiSelect.vue'
import {
  Address_Address,
  Clients_Client,
  Maybe,
  Shipping_Account,
  Shipping_ServiceLevel
} from '@/models/generated/graphql/ErpBackend.ts'
export default defineComponent({
  name: 'ClientShippingSettings',
  components: {
    'add-carrier-account': addShippingAccount,
    'email-multi-select': EmailMultiSelect
  },
  props: {
    clientInformation: {
      type: Object as () => Clients_Client,
      required: true
    }
  },
  setup (props) {
    const client: ComputedRef<Clients_Client> = computed(() => props.clientInformation)

    provide(ApolloClients, {
      default: apolloClient
    })

    const {
      mutate: UpdateClientDetails,
      onError: onUpdateClientError,
      onDone: onUpdateClientSuccess
    } = useMutation(UPDATE_CLIENT)
    onUpdateClientError(() => {
      store.dispatch('notifications/createSnackbar', {
        message: 'Could not update default, you most likely do not have proper permissions',
        color: 'error'
      })
    })
    onUpdateClientSuccess(() => {
      store.dispatch('notifications/createSnackbar', {
        message: 'Updated default successfully.',
        color: 'success'
      })
    })

    const dialog: boolean = false

    const carrierHeaders: IVuetifyDatatableHeader[] = [
      { text: 'Account Name', value: 'name' },
      { text: 'Account Number', value: 'number' },
      { text: 'Carrier Name', value: 'shipper.name' },
      { text: 'Actions', value: 'actions' }
    ]
    const carrierAccounts: ComputedRef<Shipping_Account[]> = computed(() => client.value.shipping_accounts ?? [])
    const addCarrierAccount = (account: Shipping_Account) => {
      carrierAccounts.value.push(account)
    }
    const {
      mutate: deleteCarrierAccount, loading: isDeletingCarrierAccount, onError: onErrorDeletion,
      onDone: onSuccessDeletion
    } = useMutation(
      DELETE_CARRIER_ACCOUNT
    )
    onErrorDeletion(() => {
      store.dispatch('notifications/createSnackbar', {
        message: 'Could not delete account, you most likely do not have permissions',
        color: 'error'
      })
    })
    onSuccessDeletion((result) => {
      const index: number = carrierAccounts.value.findIndex((a: Shipping_Account) => a.id === result?.data.id)
      index > -1 && carrierAccounts.value.splice(1, index)
      store.dispatch('notifications/createSnackbar', {
        message: 'Removed successfully.',
        color: 'success'
      })
    })

    function PrepCarrierAccountForDeletion (account: Shipping_Account) {
      if (defaultCarrierAccount.value === account.id) {
        store.dispatch('notifications/createSnackbar', {
          message: 'This account is currently selected as the default shipping account. Change the default account to remove this one.',
          color: 'warning'
        })
      } else {
        deleteCarrierAccount({ id: account.id })
      }
    }

    const addresses: ComputedRef<Address_Address[]> = computed(() => {
      return client.value.attached_address?.map((a: Address_Address) => ({
        title: `${a.street_1} ${a.city}, ${a.state}`,
        ...a
      })) ?? []
    })

    const defaultShipFrom: Ref<string> = ref(client.value.default_ship_from_address?.id ?? '')

    const defaultShipTo: Ref<string> = ref(client.value.default_ship_to_address?.id ?? '')

    const defaultBillTo: Ref<string> = ref(client.value.default_billing_address?.id ?? '')

    const defaultReturnTo: Ref<string> = ref(client.value.default_return_to_address?.id ?? '')

    const defaultCarrierAccount: Ref<string> = ref(client.value.default_shipping_account?.id ?? '')

    const defaultShipService: Ref<string> = ref(client.value.default_shipping_service?.id ?? '')

    const trackingEmails: Ref<Maybe<string>[]> = ref(client.value.default_tracking_email_recipients ?? [])

    // Shipping Services Query
    const { result: shippingServicesResult, loading: isLoadingShippingServices } = useQuery(
      GET_SHIPPING_SERVICE_LEVELS
    )
    // Shipping Services Data
    const serviceLevels = useResult(shippingServicesResult, [])

    const getServiceText = (item: Shipping_ServiceLevel) => {
      return `${item.shipper?.name ?? ''} ${item.service}`
    }

    return {
      getServiceText,
      dialog,
      UpdateClientDetails,
      carrierHeaders,
      carrierAccounts,
      addCarrierAccount,
      PrepCarrierAccountForDeletion,
      isDeletingCarrierAccount,
      addresses,
      serviceLevels,
      isLoadingShippingServices,
      defaultReturnTo,
      defaultBillTo,
      defaultShipFrom,
      defaultShipTo,
      defaultShipService,
      defaultCarrierAccount,
      trackingEmails
    }
  }
})
