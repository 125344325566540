import gql from 'graphql-tag'
import { DocumentNode } from 'apollo-link'

/* FRAGMENTS */

/* HELPERS */

/* QUERIES */

export const GET_PRODUCT_COMPANIES: DocumentNode = gql`
    query GetProductCompanies {
      parts_product_companys (input: { limit: 1000 }) {
        id
        name
        short_name
      }
    }
`

/* MUTATIONS */
