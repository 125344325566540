import Vue from 'vue' // vue (duh)
import CompositionApi from '@vue/composition-api'
import '@/scss/main.scss'
import router from './router/router.js' // router
import Home from './views/Home.vue' // entry vue component
import store from './store/store.js' // vuex
import 'ag-grid-enterprise' // enterprise version of vendor grid
import { LicenseManager } from 'ag-grid-enterprise' // vendor grid
import 'vuetify/dist/vuetify.min.css'
import Vuetify from 'vuetify'
import colors from 'vuetify/es5/util/colors'
import VueApollo from 'vue-apollo'
import { apolloProvider } from '@/api/graphql/apollo'
import _ from 'lodash'
import VueCurrencyInput from 'vue-currency-input'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import '@/styles/colors.css'

const pluginOptions = {
  /* see config reference */
  globalOptions: {
    currency: 'USD',
    locale: 'en',
    precision: 2,
    allowNegative: false,
    // autoDecimalMode: true,
    distractionFree: false
  }
}

/* Plugins */
Vue.use(VueCurrencyInput, pluginOptions)
Vue.use(VueApollo)
Vue.use(CompositionApi)
Vue.use(Vuetify, {
  theme: {
    success: '#51EC9C',
    primary: '#3891F0',
    inventory: '#3891F0',
    accounting: colors.green.base,
    clients: colors.lightBlue.darken4,
    processing: colors.deepPurple.base,
    purchases: colors.teal.base,
    quotes: colors.cyan.darken2,
    sales: colors.indigo.base,
    shipping: colors.brown.base,
    users: '#557D8B',
    successAlert: colors.green.base,
    secondary: '#557D8B'
  }
})

/* Lodash Use */
Object.defineProperty(Vue.prototype, '$_', { value: _ })

const envIdentifier = () => {
  const host = window.location.hostname
  switch (host) {
    case 'app.traqsys.com':
      return 'production'
    case 'beta.traqsys.com':
      return 'staging'
    case 'dev.traqsys.com':
      return 'development'
    default:
      return 'local'
  }
}
/* Sentry.io */
Sentry.init({
  Vue,
  dsn: 'https://e4c5a372a09e4cc1bce26e58961a49d6@o523083.ingest.sentry.io/5634970',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampler (): number | boolean {
    if (envIdentifier() === 'local') return 0.0
    else return 1.0
  },
  release: `erp-frontend@${require('../package.json').version}`,
  environment: envIdentifier()
})

// set license for ag-grid
LicenseManager.setLicenseKey('CompanyName=Traqsys,LicensedApplication=TRAQSYS ERP,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-007744,ExpiryDate=15_April_2021_[v2]_MTYxODQ0MTIwMDAwMA==ae59e36b8a2ebcac7aa4fb587f645cd6')

new Vue({
  el: '#app',
  router,
  store,
  apolloProvider,
  render: h => h(Home)
}).$mount('#app')
