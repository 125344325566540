import gql from 'graphql-tag'
import { FRAGMENT__ADDRESS__GRID } from '@/api/graphql/Constants/Addresses'
import { FRAGMENT__CARRIER_ACCOUNT__FULL } from '@/api/graphql/Constants/Shipments'
import { FRAGMENT__MONEY, FRAGMENT__TERMS__LIST } from '@/api/graphql/Constants/TERMS'

/* FRAGMENTS */

export const FRAGMENT__CLIENT__LIST = gql`
  fragment Client_List on Clients_Client {
    id
    allow_sales
    ecommerce_store
    internal_rep { id initials }
    name
    outstanding_invoice_limit { ...Money }
    rating
    short_name
  }
  ${FRAGMENT__MONEY}
`

export const FRAGMENT__CLIENT__GRID = gql`
  fragment Client_Grid on Clients_Client {
    ...Client_List
    credit { ...Money }
    credit_limit { ...Money }
    notes
    shipping_instructions
    type { id choice sub_choice }
    website
  }
  ${FRAGMENT__CLIENT__LIST}
  ${FRAGMENT__MONEY}
`

export const FRAGMENT__CLIENT__FULL = gql`
  fragment Client_Full on Clients_Client {
    ...Client_Grid
    attached_address { ...AddressGrid }
    creation_date
    creator { id initials }
    default_billing_address { ...AddressGrid }
    default_remit_address { ...AddressGrid }
    default_return_to_address { ...AddressGrid }
    default_ship_from_address { ...AddressGrid }
    default_ship_to_address { ...AddressGrid }
    default_shipping_account { ...CarrierAccountFull }
    default_shipping_service { id service shipper { id name }}
    default_tracking_email_recipients
    default_pt_terms { ...Terms_List }
    default_st_terms { ...Terms_List }
    federal_tax_id
    is_1099
    is_our_client
    is_tax_exempt
    origin
    payment
    shipping_accounts { ...CarrierAccountFull }
    state_tax_id
    tax_exempt_number
    terms { ...Terms_List }
    updated_date
  }
  ${FRAGMENT__CARRIER_ACCOUNT__FULL}
  ${FRAGMENT__ADDRESS__GRID}
  ${FRAGMENT__CLIENT__GRID}
  ${FRAGMENT__CARRIER_ACCOUNT__FULL}
  ${FRAGMENT__TERMS__LIST}
`

/* QUERIES */

export const GET_CLIENTS__LIST = gql`
  query GetClients_List ($input: ManyQueryInput!) {
    clients_clients (input: $input) {
      ...Client_List
    }
  }
  ${FRAGMENT__CLIENT__LIST}
`

export const GET_CLIENTS__GRID = gql`
  query GetClients_Grid ($input: ManyQueryInput!) {
    clients_clients (input: $input) {
      annotations { name value }
      ...Client_Grid
    }
  }
  ${FRAGMENT__CLIENT__GRID}
`

export const GET_CLIENTS__FULL = gql`
  query GetClientsFull ($input: ManyQueryInput!) {
    clients_clients (input: $input) {
      ...Client_Full
    }
  }
  ${FRAGMENT__CLIENT__FULL}
`

export const GET_CLIENT__FULL = gql`
  query GetClientFull ($input: SingleQueryInput!) {
    clients_client (input: $input) {
      ...Client_Full
    }
  }
  ${FRAGMENT__CLIENT__FULL}
`

export const GET_CLIENT_ALT_PARTS = gql`
    query GetClientAltParts ($clientId: ID!) {
      clients_client (input: { id: $clientId }) {
        id
        alt_parts {
          id
          pn
          alt_part_for_part {
            id
            pn
          }
        }
      }
    }
`

export const GET_CLIENT_ALT_PARTS_FOR_PART = gql`
  query GetClientAltPartsForPart ($clientId: ID!, $partId: Any!) {
    clients_client (input: { id: $clientId }) {
      id
      alt_parts (filters: [{ key: "alt_part_for_part__id", value: $partId }]) {
        id
        pn
        description
      }
    }
  }
`

/* MUTATIONS */

export const DELETE_CLIENT = gql`
  mutation DeleteClient ($id: ID!) {
    Delete__Clients_Client (id: $id) {id}
  }
`

export const UPDATE_CLIENT = gql`
  mutation UpdateClientDetails ($input: Update__Clients_Client__Input!) {
    Update__Clients_Client (input: $input) {
      ...Client_Full
    }
  }
  ${FRAGMENT__CLIENT__FULL}
`

export const CREATE_CLIENT = gql`
  mutation CreateClient ($input: Create__Clients_Client__Input!) {
    Create__Clients_Client (input: $input) {
      ...Client_Full
    }
  }
  ${FRAGMENT__CLIENT__FULL}
`
