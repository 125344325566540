import gql from 'graphql-tag'
import errorHandling from '../errorHandling'

export default {
  mixins: [errorHandling],
  methods: {
    /**
     * Used purely for updating the status of a purchase RMA
      * @param id
     * @param status
     * @return {Promise<Object>} rma
     */
    async updatePurchaseRmaStatus (id, status) {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`mutation UpdatePurchaseRmaStatus {
            rma: Update__RMA_PurchaseRMA(input: {id: ${id}, status_id: ${status}}) {
              id
              status {
                id
                status
              }
            }
          }`
        })
        return response.data.rma
      } catch (error) {
        throw new Error(this.errorHandler(error))
      }
    },

    /**
     * Update the status of a sales related rma
      * @param id
     * @param status
     * @return {Promise<Object>} rma
     */
    async updateSalesRmaStatus (id, status) {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`mutation UpdateSalesRmaStatus {
            rma: Update__RMA_RMA(input: {id: ${id}, status_id: ${status}}) {
              id
              status {
                id
                status
              }
            }
          }`
        })
        return response.data.rma
      } catch (error) {
        throw new Error(this.errorHandler(error))
      }
    }
  }
}
