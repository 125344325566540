import gql from 'graphql-tag'
import errorHandling from '../errorHandling'

export default {
  mixins: [errorHandling],
  methods: {
    /**
     * Create a carrier account for the company, do not include client_id
     * @param {String} description
     * @param {String} name
     * @param {String} number
     * @param {Number} carrier
     * @return {object} account
     */
    async createCompanyCarrierAccount (name, description, number, carrier) {
      try {
        const response = this.$apollo.mutate({
          mutation: gql`mutation CreateCompanyCarrierAccount ($input: Create__Shipping_Account__Input!) {
            account: Create__Shipping_Account (input: $input) {
              id
              description
              name
              number
              shipper {
                id
                name
              }
            }
          }`,
          variables: {
            input:
              {
                name: name,
                description: description,
                number: number,
                shipper_id: carrier
              }
          }
        })
        return response.data.account
      } catch (error) {
        throw new Error(this.errorHandler(error))
      }
    },

    /**
     * Creates a carrier account for a client
     * @param {String || Number} clientId
     * @param {String} name
     * @param {String} number
     * @param {String || Number} shipper
     * @returns {Error || Object} account the created account
     */
    async createClientCarrierAccount ({ clientId = 0, name, number, shipper }) {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`mutation CreateClientCarrierAccount ($input: Create__Shipping_Account__Input!) {
            account: Create__Shipping_Account (input: $input) {
              id
              description
              name
              number
              shipper {
                id
                name
              }
            }
          }`,
          variables: {
            input: {
              ...(clientId && { client_id: clientId }),
              description: '',
              name: name,
              number: number,
              shipper_id: shipper
            }
          }
        })
        return response.data.account
      } catch (error) {
        let message = this.errorHandler(error)
        if (message.includes('duplicate key')) {
          message = 'An account with these numbers already exists'
        }
        throw new Error(message)
      }
    }
  }
}
