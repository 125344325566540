<template>
  <v-card-actions>
    <v-layout row nowrap justify-end align-center>
      <v-flex xs7 v-if="alert.message">
        <v-alert
          :value="true"
          :color="alert.color"
          class="py-2"
          outline
          :dismissible="alert.color !== 'success'"
          @input="$store.dispatch('notifications/resetAlert')"
        >{{ alert.message }}
        </v-alert>
      </v-flex>
      <v-flex xs2/>
      <!-- Cancel Button-->
      <div style="margin-right: 8px;">
        <slot name="cancel-button">
          <v-btn
            v-if="!finished"
            @click="cancel"
            color="error"
          >
            {{ cancelButton.name }}
          </v-btn>
        </slot>
      </div>
      <!-- Save Button -->
      <div>
        <slot name="save-button">
          <v-btn
            @click="save"
            color="#51EC9C"
            :disabled="saveButton.disabled && !finished"
            :loading="saving"
          >
            {{ finished ? 'Close' : saveButton.name }}
          </v-btn>
        </slot>
      </div>
    </v-layout>
  </v-card-actions>
</template>

<script>
export default {
  name: 'standardActions',
  props: {
    saveButton: {
      type: Object,
      required: true
    },
    cancelButton: {
      type: Object,
      required: false,
      default: function () {
        return {
          name: 'Cancel'
        }
      }
    }
  },
  computed: {
    saving: function () {
      return this.saveButton.saving ?? this.$store.state.data.saving
    },

    finished: function () {
      return this.alert.color.includes('success')
    },

    alert: function () {
      return this.$store.state.notifications.alert
    }
  },
  methods: {
    save () {
      this.finished ? this.$emit('close') : this.$emit('save')
    },

    cancel () {
      this.$store.dispatch('notifications/resetAlert')
      this.$emit('cancel')
    }
  }
}
</script>
