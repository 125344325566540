<template>
  <div>
    <v-tooltip right v-if="hasInternalComment">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" class="grid-icon-font" stlye="margin-left: auto; margin-right: auto; display: block;" color="light-blue accent-4">fas fa-comment</v-icon>
      </template>
      <div>
        <label style="display: block; padding-bottom: 5px;" for="comment">Internal Comment</label>
        <textarea id="comment" style="padding: 10px; border: 1px solid white; border-radius: 2px;" :rows="rowCount" :cols="cols" v-model="params.data['internal_comment']" disabled/>
      </div>
    </v-tooltip>
  </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
  name: 'commentRenderer',
  computed: {
    rowCount () {
      if (this.hasInternalComment) {
        const rows = this.params.data.internal_comment?.split(/\r\n|\r|\n/)
        let finalCount = rows.length
        for (const row of rows) {
          if (row.length > 30) {
            finalCount = finalCount + Math.ceil(row.length / 30)
          }
        }
        return finalCount < 50 ? finalCount + 1 : 50
      } else {
        return 0
      }
    },
    cols () {
      if (this.hasInternalComment) {
        const rows = this.params?.data?.internal_comment?.split(/\r\n|\r|\n/)
        let colCount = 0
        const max = 30
        for (const row of rows) {
          if (row.length > colCount) {
            colCount = row.length > max ? max : row.length + 5
          }
        }
        return colCount
      } else return 0
    },
    hasInternalComment () {
      return this.params?.data?.internal_comment ?? false
    }
  },
  methods: {
    refresh (params) {
      this.params = params
      return true
    }
  }
})
</script>
