import {
  CellKeyDownEvent,
  GetContextMenuItems,
  GetContextMenuItemsParams,
  MenuItemDef,
  RowNode
} from 'ag-grid-community'
import store from '@/store/store'
import {
  ContextMenuCallback,
  IContextMenuLockObject,
  INewPurchasePart,
  INewQuotePart,
  INewSalePart
} from '@/models/GuiPartsModels'
import helpers from '@/api/helpers'
import { fields } from '@/lib/agGridColumnConfiguration'
import { IRmaItem, IWorkOrderItem } from '@/models/vuexStateModels'
import { apolloClient as apollo } from '@/api/graphql/apollo'
import {
  APPROVE_PURCHASE,
  DELETE_PURCHASED_ITEM,
  DELETE_SOLD_ITEM, getUpdateSaleMoneyData,
  UNAPPROVE_PURCHASE,
  UPDATE_PURCHASE_ORDER_STATUS, UPDATE_SALE_MONEY,
  UPDATE_SOLD_ITEMS
} from '@/api/graphql/Constants/Orders'
import {
  GRID_GET_ADD_ON_ITEMS_FOR_SYSTEM,
  MARK_ITEM_AS_LOST,
  UPDATE_INVENTORY_ITEM,
  UPDATE_PART_HAS_BATTERY
} from '@/api/graphql/Constants/Inventory'
import {
  CheckIfUserCanLockOrUnlock,
  CheckRowsForCheckInOrOut, CheckRowsForSingleUniqueValueAmongNulls,
  GetContextMenuLockButtonDetails,
  GetTypeNameFromTqsModelEnum
} from '@/lib/helpers'
import { ViewPurchaseTransaction, ViewSaleTransaction, ViewSystem } from '@/lib/routerHelper'
import { DocumentNode, FetchResult } from 'apollo-link'
import {
  BREAKDOWN_STATUS,
  PURCHASE_ORDER_STATUS,
  RMA_TYPE, RouterQueryParamsForBackend,
  SALES_ORDER_STATUS
} from '@/models/ExtraBackendModels'
import router from '@/router/router'
import { GET_BOMS_FOR_PARTS } from '@/api/graphql/Constants/Assembly'
import { IGeneratedContextMenuObject } from '@/models/ExtraAgGridRelatedModels'
import { UPDATE_PURCHASE_RMA_STATUS, UPDATE_SALE_RMA_STATUS } from '@/api/graphql/Constants/RMAs'
import { DELETE_CONTACT } from '@/api/graphql/Constants/Contacts'
import { DELETE_ADDRESS, UPDATE_ADDRESS } from '@/api/graphql/Constants/Addresses'
import { getPtId } from '@/lib/fieldDataGetters'
import { IPassAndReasonObject } from '@/models/errorAndValidationModels'
import { DELETE_WORK_ORDER_ITEM } from '@/api/graphql/Constants/WorkOrders'
import {
  Address_Address,
  AnnotationObject,
  Breakdown_BreakDown,
  BuildUp_BuildUp,
  Choices_Status,
  Clients_Client,
  Clients_Contact,
  CountryObject,
  Inventory_Item,
  Inventory_System, Invoices_ApPayment, Invoices_ArInvoice, Invoices_ArPayment,
  Location_Location,
  Model_Enum,
  Parts_BomParts,
  Parts_Part,
  Purchases_Purchase,
  Purchases_PurchasedItems,
  Query,
  Quotes_Quote,
  Rma_PurchaseRma,
  Rma_Rma,
  Sales_Sale,
  Sales_SoldItems,
  Update__Address_Address__Input,
  Update__Inventory_Item__Input, Users_User,
  WorkOrder_WorkOrder
} from '@/models/generated/graphql/ErpBackend.ts'
import { ApolloQueryResult } from 'apollo-client'
import { CREATE_BREAK_DOWN, CREATE_BREAK_DOWN_ITEMS } from '@/api/graphql/Constants/Disassembly'
import { ViewBreakdownTask } from '@/lib/routeHelp'
import { MarkPaymentAsPosted } from '@/api/graphql/Constants/Accounting'
import { GridEvents } from '@/store/gridEvents'
import { PREP_SALE_FOR_EDIT } from '@/composition/UseSale'
import { PREP_PURCHASE_FOR_EDIT } from '@/composition/UsePurchase'

/* Constants used here */

const noPermissionsMessageInContextMenuToolTip: string = 'You do not have sufficient permissions to perform this action'

const routeIcon: string = '<i class="far fa-road"/>'

/* Helpers */

function UseSubMenus () {
  const createMenu: MenuItemDef = {
    name: 'Create',
    subMenu: [],
    icon: '<i class="fas fa-plus-circle primary--text" style="font-size: 14px;"></i>'
  }
  const editMenu: MenuItemDef = {
    name: 'Edit',
    subMenu: [],
    icon: '<i class="fas fa-pencil orange--text"></i>'
  }
  const viewMenu: MenuItemDef = {
    name: 'View',
    subMenu: [],
    icon: '<i class="fas fa-glasses"></i>'
  }
  const deleteMenu: MenuItemDef = {
    name: 'DELETE',
    subMenu: [],
    icon: '<i class="fas fa-trash error--text"></i>'
  }
  return {
    createMenu,
    editMenu,
    viewMenu,
    deleteMenu
  }
}

function GenerateContextMenuItems (params: GetContextMenuItemsParams, callback: ContextMenuCallback, data: any): IGeneratedContextMenuObject {
  const contextMenuObject: IGeneratedContextMenuObject = { view: [], edit: [], aux: [] }
  const object: Query = params.node.data
  const type: 'Query' = object.__typename!

  if (type === GetTypeNameFromTqsModelEnum(Model_Enum.InventoryItem) || type === GetTypeNameFromTqsModelEnum(Model_Enum.InventorySystem)) { // is inventory item
    const inventoryContextMenuObject = GenerateCurrentInventoryContextMenuItems(params, callback, data)
    contextMenuObject.view.push(...inventoryContextMenuObject.view ?? [])
    contextMenuObject.edit.push(...inventoryContextMenuObject.edit ?? [])
    contextMenuObject.aux.push(...inventoryContextMenuObject.aux ?? [])
  }

  return contextMenuObject
}

function GenerateCurrentInventoryContextMenuItems (params: GetContextMenuItemsParams, callback: ContextMenuCallback, data: any): IGeneratedContextMenuObject {
  const contextMenuObject: IGeneratedContextMenuObject = { view: [], edit: [], aux: [] }
  const selectedNodes: RowNode[] = params.api!.getSelectedNodes()
  const item: Inventory_Item | Inventory_System = params.node.data
  let isSystem: boolean = false
  if (item.__typename === GetTypeNameFromTqsModelEnum(Model_Enum.InventorySystem)) {
    isSystem = true
  }

  // buysellhistory button
  const buySellHistoryButton: MenuItemDef = {
    name: 'Buy/Sell History',
    shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">h</span>',
    action: () => {
      const item: Inventory_Item = params.node.data
      callback({ functionToRun: 'showBSHistory', params: item })
    },
    cssClasses: ['bold']
  }
  contextMenuObject.view.push(buySellHistoryButton)

  // view pt button
  const viewPTButton = {
    name: 'Purchase Transaction',
    shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">p</span>',
    action: () => {
      ViewPurchaseTransaction({ id: item.purchases_items_details!.transaction.id })
    },
    icon: routeIcon,
    cssClasses: ['bold']
  }
  contextMenuObject.view.push(viewPTButton)

  // view sale button
  const hasSale = item.sales_items_details !== null
  const viewSTButton = {
    name: 'Sales Transaction',
    shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">s</span>',
    action: () => {
      ViewSaleTransaction({ id: item.sales_items_details!.transaction.id })
    },
    disabled: !hasSale,
    tooltip: hasSale ? '' : 'This system has not been sold',
    icon: routeIcon,
    cssClasses: ['bold']
  }
  contextMenuObject.view.push(viewSTButton)

  // lock button
  const userInitials: string = data.userInitials
  const canLock: boolean = CheckIfUserCanLockOrUnlock(selectedNodes, userInitials)

  let lockObject: IContextMenuLockObject = { name: 'Lock/UnLock', lock: false }

  if (canLock) lockObject = GetContextMenuLockButtonDetails(selectedNodes)

  const lockButton: MenuItemDef = {
    name: lockObject.name,
    shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">l</span>',
    action: () => {
      LockOrUnlock(selectedNodes, lockObject.lock, callback)
    },
    disabled: !canLock,
    tooltip: canLock ? '' : 'You can only edit the lock status of your own parts or parts that have identical lock status.',
    cssClasses: ['bold']
  }

  contextMenuObject.edit.push(lockButton)

  // check out button
  const canCheckInOrOut = CheckRowsForCheckInOrOut(selectedNodes)
  const isCheckingIn: boolean = params.node.data.checked_out_by !== null

  const checkoutButton: MenuItemDef = {
    name: isCheckingIn ? 'Check In' : 'Check Out',
    shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">c</span>',
    action: () => {
      CheckInOrOutItems(params, isCheckingIn)
    },
    disabled: !canCheckInOrOut,
    tooltip: canCheckInOrOut ? '' : 'Make sure selected items hold the same "check out" value.',
    cssClasses: ['bold']
  }

  contextMenuObject.edit.push(checkoutButton)

  let canViewBreakdownTask: boolean = false
  let order: Breakdown_BreakDown | undefined
  if (isSystem) {
    const system: Inventory_System = (item as Inventory_System)
    if (system.break_down_orders) {
      order = system.break_down_orders.find(b => b.status.id !== BREAKDOWN_STATUS.COMPLETED)
      if (order) {
        canViewBreakdownTask = true
      }
    }
  }
  const viewBreakdown = {
    name: 'Breakdown Task',
    shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">b</span>',
    action: () => {
      ViewBreakdownTask(order!.id)
    },
    disabled: !canViewBreakdownTask,
    tooltip: canViewBreakdownTask ? '' : 'This system is not currently on an active breakdown task',
    icon: routeIcon,
    cssClasses: ['bold']
  }
  contextMenuObject.view.push(viewBreakdown)

  // print tags
  const printTags: MenuItemDef = {
    name: 'Print Tags',
    shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">t</span>',
    action: () => {
      store.dispatch('grid/prepareToPrintTags', {
        app: 'topbar',
        component: 'print-tags',
        data: params.api?.getSelectedNodes() ?? [],
        props: {
          'max-width': 400
        }
      })
    },
    cssClasses: ['auxiliaryAction']
  }
  contextMenuObject.aux.push(printTags)

  return contextMenuObject
}

function CorrectSelectedNodes (params: GetContextMenuItemsParams) {
  if (params.node) {
    const rightClickedNodeIsInSelectedNodes = params.node.isSelected()
    if (!rightClickedNodeIsInSelectedNodes && params.api!.getSelectedNodes().length > 0) {
      params.api?.forEachNode(n => {
        n.setSelected(n.data.id === params.node.data.id)
      })
    }
  } else {
  }
}

export function CreatePurchaseOrderFromItem (item: Inventory_Item): void {
  const part: INewPurchasePart = {
    id: item.part!.id,
    pn: item.part!.pn,
    hover: false,
    quantity: 1,
    cost: 0
  }
  store.dispatch('purchase/load', { parts: [part] })
}

export function CreateSalesOrderFromItem (item: Inventory_Item): void {
  const part: INewSalePart = {
    id: item.part!.id,
    pn: item.part!.pn,
    hover: false,
    quantity: 1,
    soldFor: 0,
    pt: null,
    item: null
  }
  store.dispatch('sale/load', { parts: [part] })
}

export function CreateQuoteFromItem (item: Inventory_Item): void {
  const part: INewQuotePart = {
    id: item.part!.id,
    pn: item.part!.pn,
    hover: false,
    quantity: 1,
    soldFor: 0
  }
  store.dispatch('quotes/load', { parts: [part] })
}

export function ItemIsReceived (item: Inventory_Item): boolean {
  return item.receive_status === true
}

export function ItemsAreReceived (nodes: RowNode[]): boolean {
  // if (nodes[0].data.__typename === 'Purchases_PurchasedItems') {
  if (nodes[0].data.__typename === GetTypeNameFromTqsModelEnum(Model_Enum.PurchasesPurchasedItems)) {
    for (const node of nodes) {
      const purchasedItem: Purchases_PurchasedItems = node.data
      if (purchasedItem.item?.receive_status !== true) return false
    }
    return true
  } else if (nodes[0].data.__typename === GetTypeNameFromTqsModelEnum(Model_Enum.InventoryItem)) { // 'Inventory_Item'
    for (const node of nodes) {
      const item: Inventory_Item = node.data
      if (item.receive_status !== true) return false
    }
    return true
  }
  return false
}

export function CheckInOrOutItems (params: GetContextMenuItemsParams | CellKeyDownEvent, isCheckingIn: boolean) {
  const itemsToUpdate: Update__Inventory_Item__Input[] = []
  if (isCheckingIn) {
    for (const node of params.api?.getSelectedNodes() ?? []) {
      const item: Inventory_Item = params.node.data
      // @ts-ignore
      itemsToUpdate.push({ id: item.id, checked_out_by_id: null })
    }
  } else {
    for (const node of params.api?.getSelectedNodes() ?? []) {
      const item: Inventory_Item = params.node.data
      itemsToUpdate.push({ id: item.id, checked_out_by_id: store.state.profile.user.id })
    }
  }
  apollo.mutate({
    mutation: UPDATE_INVENTORY_ITEM,
    variables: { input: itemsToUpdate }
  }).then(() => {
    store.dispatch('notifications/createSnackbar', {
      message: `Items have been checked ${isCheckingIn ? 'in' : 'out'}!`,
      color: 'success'
    })
    params.api?.redrawRows({ rowNodes: params.api.getSelectedNodes() })
  }).catch(() => {
    store.dispatch('notifications/createSnackbar', {
      message: `Was not able to check ${isCheckingIn ? 'in' : 'out'} items, try again or contact support.`,
      color: 'error'
    })
  })
}

/* END HELPERS */

/* BEGIN CONTEXT MENU ITEMS */

export const CONTEXT_MENU__BUY_SELL_HISTORY: GetContextMenuItems = (params: GetContextMenuItemsParams): MenuItemDef[] => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef> = []

  const data: Inventory_Item = params.node.data

  const createPurchaseOrder: MenuItemDef = {
    name: 'Create PT for Part',
    shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">p</span>',
    action: () => {
      CreatePurchaseOrderFromItem(data)
    },
    cssClasses: ['bold']
  }

  const createSalesOrder: MenuItemDef = {
    name: 'Create ST for Part',
    shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">s</span>',
    action: () => {
      CreateSalesOrderFromItem(data)
    },
    cssClasses: ['positiveAction']
  }

  const createQuote: MenuItemDef = {
    name: 'Create Quote for Part',
    shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">q</span>',
    action: () => {
      CreateQuoteFromItem(data)
    },
    cssClasses: ['bold']
  }

  contextMenu.push(createPurchaseOrder, createSalesOrder, createQuote)

  return contextMenu
}

/* Purchase Details Helpers and Context Menu */

/* Purchase Views Context Menus and HELPERS */

// HELPERS

export function CreatePurchaseRMA (nodes: RowNode[], orderId: string): void {
  const items: IRmaItem[] = []
  for (const node of nodes) {
    const item = node.data
    items.push({
      rmaId: '0',
      itemId: item.item!.id,
      pn: item.part!.pn,
      sn: item.item!.serial_number ?? '',
      credit: false,
      value: item.original_cost,
      orderLineId: `${orderId}-${item.line_number}`
    })
  }
  store.dispatch('rma/start', {
    transactionId: orderId,
    items: items,
    type: 'purchase'
  })
}

export function AddToPurchaseRMA (nodes: RowNode[], clientId: string): void {
  const items: IRmaItem[] = []

  for (const node of nodes) {
    const item: Purchases_PurchasedItems = node.data
    items.push({ rmaId: '', itemId: item.item!.id })
  }

  store.dispatch('rma/start', {
    type: 'purchase',
    clientId: clientId,
    items: items
  })
}

export function AddCountryOfOrigin (nodes: RowNode[], callback: ContextMenuCallback): void {
  const nodesToAddCoOTo: RowNode[] = []
  for (const node of nodes) {
    const purchasedItem: Purchases_PurchasedItems = node.data
    if (purchasedItem.item?.receive_status !== null) {
      nodesToAddCoOTo.push(node)
    }
  }
  callback({ data: nodesToAddCoOTo, dataKey: 'nodesToAddCoOTo', dialogToOpen: 'addCoO' })
}

export function MarkAsTested (nodes: RowNode[], callback: ContextMenuCallback): void {
  const nodesToTest: RowNode[] = []
  for (const node of nodes) {
    nodesToTest.push(node)
  }
  callback({ data: nodesToTest, dataKey: 'nodesToTest', dialogToOpen: 'markTested' })
}

export function UpdatePurchaseOrderApproval (params: GetContextMenuItemsParams, id: string, mutation: DocumentNode, action: string): void {
  apollo.mutate({
    mutation: mutation,
    variables: { id: id }
  }).catch(() => {
    store.dispatch('notifications/createSnackbar', {
      message: `Unable to ${action} purchase`,
      color: 'error',
      timeout: 5000
    })
  })
}

export function UpdatePurchaseOrderStatus (params: GetContextMenuItemsParams, id: string, status: number): void {
  apollo.mutate({
    mutation: UPDATE_PURCHASE_ORDER_STATUS,
    variables: { id: id, status: status }
  }).catch(() => {
    store.dispatch('notifications/createSnackbar', {
      message: 'Unable to update purchase status',
      color: 'error',
      timeout: 5000
    })
  })
}

export function GetNextRMAStatusName (order: number, model: RMA_TYPE): Choices_Status {
  return store.getters['data/nextStatus']('rma', model, 'status', order)
}

export function UpdateRmaStatus (params: GetContextMenuItemsParams | CellKeyDownEvent, mutation: DocumentNode, id: string, status: number): void {
  apollo.mutate({
    mutation: mutation,
    variables: { id: id, status: status }
  }).then(response => {
    const rma: Rma_PurchaseRma | Rma_Rma = response.data.rma
    params.node.data.status = rma.status
    params.api?.redrawRows({ rowNodes: [params.node] })
  })
}

// CONTEXT MENUS

export const CONTEXT_MENU__PURCHASE_ORDERS = (params: GetContextMenuItemsParams, callback: ContextMenuCallback): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []
  const { viewMenu, editMenu, deleteMenu } = UseSubMenus()

  if (params.api && params.node) {
    const id: string = params.node.data.id
    const orderStatus: string = params.node.data.status.id

    const toArrive: boolean = Number(orderStatus) === PURCHASE_ORDER_STATUS.CREATED
    const toApprove: boolean = Number(orderStatus) === 2

    /* Permissions */
    const hasPermissionsToApprove: boolean = true
    const hasPermissionsToUnApprove: boolean = true
    const hasPermissionsToMarkAsArrived: boolean = true
    const hasPermissionToMarkAsReceived: boolean = true
    const hasPermissionToReceive: boolean = true
    const hasPermissionToAddItemsToOrder: boolean = true
    const hasPermissionToViewContract: boolean = true
    const hasPermissionToEdit: boolean = true

    /* Buttons */
    const approveButton = {
      name: 'Approve',
      action: async () => {
        UpdatePurchaseOrderApproval(params, id, APPROVE_PURCHASE, 'Approve')
      },
      disabled: !hasPermissionsToApprove,
      tooltip: hasPermissionsToApprove ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }
    const unApproveButton = {
      name: 'UnApprove',
      action: async () => {
        UpdatePurchaseOrderApproval(params, id, UNAPPROVE_PURCHASE, 'Un-Approve')
      },
      disabled: !hasPermissionsToUnApprove,
      tooltip: hasPermissionsToUnApprove ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }
    const markAsArrived = {
      name: 'Mark as Arrived',
      action: async () => {
        UpdatePurchaseOrderStatus(params, id, PURCHASE_ORDER_STATUS.TO_APPROVE)
      },
      disabled: !hasPermissionsToMarkAsArrived,
      tooltip: hasPermissionsToMarkAsArrived ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }
    // const markAsReceived = {
    //   name: 'Mark as Received',
    //   action: async () => {
    //     UpdatePurchaseOrderStatus(params, id, PURCHASE_ORDER_STATUS.RECEIVED)
    //   },
    //   disabled: !hasPermissionToMarkAsReceived || isReceived,
    //   tooltip: hasPermissionToMarkAsReceived ? '' : noPermissionsMessageInContextMenuToolTip,
    //   cssClasses: ['bold']
    // }

    const receive = {
      name: 'Receive',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">r</span>',
      action: () => {
        const routeQuery: RouterQueryParamsForBackend = { id: id }
        router.push({ name: 'receive', params: routeQuery })
      },
      disabled: !hasPermissionToReceive,
      tooltip: hasPermissionToReceive ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['positiveAction']
    }

    const editPurchase: MenuItemDef = {
      name: 'Edit Purchase',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">e</span>',
      action: () => {
        PREP_PURCHASE_FOR_EDIT({ id: id, statusId: orderStatus })
      },
      disabled: !hasPermissionToEdit,
      tooltip: hasPermissionToEdit ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }

    if (id) {
      if (toArrive) {
        editMenu.subMenu!.push(markAsArrived)
      } else if (toApprove) {
        editMenu.subMenu!.push(approveButton)
      } else {
        Number(orderStatus) < PURCHASE_ORDER_STATUS.RECEIVED && contextMenu.push(receive)
        editMenu.subMenu!.push(unApproveButton)
        // editMenu.subMenu!.push(markAsReceived)
      }
    }

    // check to see if tracking number exists or if order is at shipping already
    // if able to ship add shipping button

    const addParts = {
      name: 'Add Parts to PT',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">a</span>',
      action: () => {
        callback({ functionToRun: 'addItems', params: id })
      },
      disabled: !hasPermissionToAddItemsToOrder,
      tooltip: hasPermissionToAddItemsToOrder ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['auxiliaryAction']
    }
    editMenu.subMenu!.unshift(addParts)

    // View contract button
    const viewContractButton = {
      name: 'Contract',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">v</span>',
      action: () => {
        callback({ functionToRun: 'viewContract', params: id })
      },
      disabled: !hasPermissionToViewContract,
      tooltip: hasPermissionToViewContract ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }
    viewMenu.subMenu!.push(viewContractButton)

    const viewReleaseForm = {
      name: 'Release Form',
      action: () => {
        callback({ functionToRun: 'viewReleaseForm', params: id })
      },
      cssClasses: ['bold']
    }
    viewMenu.subMenu!.push(viewReleaseForm)

    // View Work Order
    // const hasWorkOrder = params.node.data.work_order !== null
    // const viewWorkOrderButton = {
    //   name: 'View Work Order',
    //   action: () => {
    //     this.width = '800'
    //     this.viewWO(params)
    //   },
    //   cssClasses: ['bold']
    // }
    // if (hasWorkOrder) {
    //   viewMenu.subMenu.push(viewWorkOrderButton)
    // }

    editMenu.subMenu!.unshift(editPurchase)
    if (editMenu.subMenu!.length > 0) {
      contextMenu.push(editMenu)
    }
    if (viewMenu.subMenu!.length > 0) {
      contextMenu.push(viewMenu)
    }
    contextMenu.push('copy', 'export')
  }

  return contextMenu
}

export const CONTEXT_MENU__PURCHASE_DETAILS = (params: GetContextMenuItemsParams, orderId: string, clientId: string, callback: ContextMenuCallback): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  const editMenu: MenuItemDef = {
    name: 'Edit',
    subMenu: []
  }

  const viewMenu: MenuItemDef = {
    name: 'View',
    subMenu: []
  }

  const canDeletePurchaseItem: boolean = true

  const nodes = params.api?.getSelectedNodes() ?? []
  const selectedNodes: RowNode[] = nodes.length > 0 ? nodes : [params.node]
  const data: Purchases_PurchasedItems = params.node.data

  const isReceived: boolean = data.item !== null && ItemIsReceived(data.item!)

  const itemIsNotOnRMA: boolean = data.item?.purchase_rma_item_details === null
  // @ts-ignore
  const itemsAreNotOnRMA: boolean = helpers.checkAllSame({ column: fields.transactionItems.prmaID, nodes: selectedNodes })
  const canRMA: boolean = itemIsNotOnRMA && itemsAreNotOnRMA

  const createRMA: MenuItemDef = {
    name: 'Create RMA',
    shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">r</span>',
    action: () => {
      CreatePurchaseRMA(selectedNodes, orderId)
    },
    cssClasses: ['bold']
  }

  const addToRMa: MenuItemDef = {
    name: 'Add To an RMA',
    shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">t</span>',
    action: () => {
      AddToPurchaseRMA(selectedNodes, clientId)
    },
    cssClasses: ['bold']
  }

  if (!isReceived) {
    createRMA.disabled = true
    addToRMa.disabled = true
    const message: string = `Cannot RMA ${selectedNodes.length === 1 ? 'this item' : 'these items'} until <b>received!</b>!`
    createRMA.tooltip = message
    addToRMa.tooltip = message
  } else if (!canRMA) {
    createRMA.disabled = true
    addToRMa.disabled = true
    const message: string = `${selectedNodes.length === 1 ? 'This item is' : 'These items are'} already on an RMA!`
    createRMA.tooltip = message
    addToRMa.tooltip = message
  }

  const addCountryOfOrigin: MenuItemDef = {
    name: 'Add Country of Origin',
    shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">c</span>',
    action: () => {
      AddCountryOfOrigin(selectedNodes, callback)
    },
    cssClasses: ['bold']
  }

  // @ts-ignore
  if (!(isReceived && helpers.checkAllSame({ columns: fields.transactionItems.received, nodes: selectedNodes }))) {
    addCountryOfOrigin.disabled = true
    addCountryOfOrigin.tooltip = 'Cannot add country of origin to items that are not <b>received!</b>!'
  }

  const markTested: MenuItemDef = {
    name: 'Mark as Tested',
    shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">m</span>',
    action: () => {
      MarkAsTested(selectedNodes, callback)
    },
    cssClasses: ['bold']
  }

  if (!isReceived) {
    markTested.disabled = true
    markTested.tooltip = 'Cannot mark as tested until <b>received!</b>'
  }

  const deletePart: MenuItemDef = {
    name: `Delete Item${selectedNodes.length === 1 ? '' : 's'}`,
    action: async () => {
      const promises: Promise<null>[] = []
      for (const node of selectedNodes) {
        const purchasedItem: Purchases_Purchase = node.data
        const promise: Promise<any> = apollo.mutate({
          mutation: DELETE_PURCHASED_ITEM,
          variables: { id: purchasedItem.id }
        })
        promises.push(promise)
      }

      Promise.all(promises).then(() => {
        selectedNodes.forEach(({ data }) => {
          callback({ functionToRun: 'removeIdOfRowInRowData', params: { id: data.id } })
        })
      })
    },
    disabled: !canDeletePurchaseItem,
    tooltip: canDeletePurchaseItem ? '' : noPermissionsMessageInContextMenuToolTip,
    cssClasses: ['bold']
  }

  editMenu.subMenu!.push(createRMA, addToRMa, addCountryOfOrigin, markTested, deletePart)

  if (editMenu.subMenu!.length > 0) {
    contextMenu.push(editMenu)
  }
  if (viewMenu.subMenu!.length > 0) {
    contextMenu.push(viewMenu)
  }

  contextMenu.push('copy', 'export')

  return contextMenu
}

export const CONTEXT_MENU__PURCHASES_RMAS = (params: GetContextMenuItemsParams): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  if (params.api) {
    const rma: Rma_PurchaseRma = params.node.data
    const nodes: RowNode[] = params.api.getSelectedNodes()
    const hasNextStatus: boolean = rma.status.status_type !== 'FINAL'
    const nextStatusName: string = GetNextRMAStatusName(rma.status.order, RMA_TYPE.PURCHASE)?.status ?? ''

    const markAsNextStatus: MenuItemDef = {
      name: 'Mark as ' + nextStatusName,
      // shortcut: `<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">m</span>`,
      action: () => {
        hasNextStatus && UpdateRmaStatus(params, UPDATE_PURCHASE_RMA_STATUS, rma.id, Number(rma.status.id) + 1)
      },
      cssClasses: ['bold']
    }

    const viewPurchase: MenuItemDef = {
      name: 'View Purchase Transaction',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">p</span>',
      action: () => {
        ViewPurchaseTransaction({ id: rma.pt.id })
      },
      icon: routeIcon,
      cssClasses: ['bold']
    }

    hasNextStatus && contextMenu.push(markAsNextStatus)
    contextMenu.push(viewPurchase, 'copy', 'export')
  }

  return contextMenu
}

export const CONTEXT_MENU__PURCHASES_RMAS_NESTED = (params: GetContextMenuItemsParams): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  if (params.api) {
    contextMenu.push('copy', 'export')
  }

  return contextMenu
}

export const CONTEXT_MENU__WORK_ORDERS = (params: GetContextMenuItemsParams, callback: ContextMenuCallback): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  if (params.api) {
    const workOrder: WorkOrder_WorkOrder = params.node.data

    const hasPermissionsToAddProductToWo: boolean = true

    const addItems: MenuItemDef = {
      name: 'Add Products',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">a</span>',
      action: () => {
        callback({ functionToRun: 'prepAddProductDialog', params: params.node.data })
      },
      disabled: !hasPermissionsToAddProductToWo,
      tooltip: hasPermissionsToAddProductToWo ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }

    const viewContract = {
      name: 'View Contract',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">v</span>',
      action: () => {
        callback({ functionToRun: 'viewContract', params: workOrder.id })
      },
      disabled: !workOrder.shipment_order,
      tooltip: workOrder.shipment_order ? '' : 'An error has occurred',
      cssClasses: ['bold']
    }

    contextMenu.push(addItems, viewContract)

    contextMenu.push('copy', 'export')
  }

  return contextMenu
}

export const CONTEXT_MENU__WORK_ORDERS_NESTED = (params: GetContextMenuItemsParams): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  if (params.api) {
    const workOrderItem: IWorkOrderItem = params.node.data

    const hasPermissionsToDeleteWorkOrderItem: boolean = true

    const deleteItem: MenuItemDef = {
      name: 'Delete Product',
      action: () => {
        apollo.mutate({
          mutation: DELETE_WORK_ORDER_ITEM,
          variables: { id: workOrderItem.id }
        })
      },
      disabled: !hasPermissionsToDeleteWorkOrderItem,
      tooltip: hasPermissionsToDeleteWorkOrderItem ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['negativeAction']
    }

    contextMenu.push(deleteItem, 'copy', 'export')
  }

  return contextMenu
}

/* End Purchase Views */
/* Sales Details Helpers and Context Menus */

export function AllocateParts (data: Sales_SoldItems, nodes: RowNode[], callback: ContextMenuCallback): void {
  const partToAllocate = {
    pn: data.part.pn,
    quantity: nodes.length,
    id: data.part.id
  }
  // @ts-ignore
  if (data.sub_type !== 'NONE' && data.original_part) {
    partToAllocate.id = data.original_part.id
    partToAllocate.pn = data.original_part.pn
  }

  const partsToAllocate = []
  for (const node of nodes) {
    const soldItem: Sales_SoldItems = node.data
    partsToAllocate.push({
      id: soldItem.id,
      soldFor: soldItem.sold_for
    })
  }
  callback({ functionToRun: 'prepareAllocation', params: { partToAllocate: partToAllocate, partsToAllocate: partsToAllocate } })
}

export function UnAllocateParts (nodes: RowNode[], orderId: string, callback: ContextMenuCallback): void {
  const itemsToUnAllocate = []
  for (const node of nodes) {
    const data: Sales_SoldItems = node.data
    itemsToUnAllocate.push({
      id: data.id,
      item_id: null,
      status_id: 18
    })
  }
  apollo.mutate({
    mutation: UPDATE_SOLD_ITEMS,
    variables: { input: itemsToUnAllocate }
  }).then(async ({ data: { items } }) => {
    try {
      callback({ functionToRun: 'updateRowData', params: items })
      const saleUpdateObject = await getUpdateSaleMoneyData(orderId)
      if (saleUpdateObject?.id) {
        const mutation = {
          mutation: UPDATE_SALE_MONEY,
          variables: { input: saleUpdateObject }
        }
        apollo.mutate(mutation)
          .then(() => {
            store.dispatch('notifications/createSnackbar', {
              message: 'Unallocated successfully.',
              color: 'success'
            })
          })
      }
    } catch {
    }
  }).catch(error => {
    store.dispatch('notifications/createSnackbar', {
      message: error,
      color: 'error',
      timeout: 4000
    })
  })
}

export function MarkAsLost (params: GetContextMenuItemsParams | CellKeyDownEvent): void {
  const data: Sales_SoldItems = params.node.data
  apollo.mutate({
    mutation: MARK_ITEM_AS_LOST,
    variables: { id: data.item!.id, lost: !data.item!.is_lost }
  }).then(() => {
    data.item!.is_lost = !data.item?.is_lost
    params.api?.redrawRows({ rowNodes: [params.node] })
  })
}

export function CreateSaleRMA (nodes: RowNode[], orderId: string): void {
  const items: IRmaItem[] = []

  for (const node of nodes) {
    const soldItem = node.data
    items.push({
      rmaId: '',
      itemId: soldItem.item?.id ?? '',
      pn: soldItem.part!.pn ?? '',
      sn: soldItem.item?.serial_number ?? '',
      credit: false,
      value: soldItem.sold_for,
      orderLineId: `${orderId}-${soldItem.line_number}`
    })
  }
  store.dispatch('rma/start', {
    transactionId: orderId,
    items: items,
    type: 'sale'
  })
}

export async function AddToSaleRMA (nodes: RowNode[], clientId: string, callback: ContextMenuCallback) {
  // ADD TO RMA LOGIC
  const items: IRmaItem[] = []
  for (const node of nodes) {
    const soldItem: Sales_SoldItems = node.data
    items.push({ rmaId: '', itemId: soldItem.item!.id })
  }
  const payload = {
    type: 'sale',
    clientId: clientId,
    items: items
  }
  await store.dispatch('rma/update', payload)
  callback({ dialogToOpen: 'addToRMA' })
}

export function RemoveItemsFromSale (nodes: RowNode[], orderId: string, callback: ContextMenuCallback): void {
  const promises: Promise<any>[] = []
  for (const node of nodes) {
    const soldItem: Sales_SoldItems = node.data
    const promise = apollo.mutate({
      mutation: DELETE_SOLD_ITEM,
      variables: { id: soldItem.id }
    })
    promises.push(promise)
  }
  Promise.all(promises).then(async () => {
    store.dispatch('notifications/createSnackbar', {
      message: 'Items removed from ST successfully.',
      color: 'success',
      top: true,
      canClose: true,
      timeout: 4000
    })
    callback({ functionToRun: 'refresh' })
  }).catch(error => {
    store.dispatch('notifications/createSnackbar', {
      message: error.message,
      color: 'error',
      top: true,
      canClose: true,
      timeout: 0
    })
  })
}

export const CONTEXT_MENU__SALES_ORDERS = (params: GetContextMenuItemsParams, callback: ContextMenuCallback): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu = []
  const { editMenu, viewMenu, deleteMenu } = UseSubMenus()

  const hasPermissionsToBookSt: boolean = true
  const hasPermissionsToDeleteSt: boolean = true

  if (params.api) {
    const sale: Sales_Sale = params.node.data
    const isInShipping: boolean = sale.status.id === SALES_ORDER_STATUS.BROUGHT_TO_SHIPPING
    const isBooked: boolean = sale.status.id === SALES_ORDER_STATUS.BOOKED

    const addPartsToST: MenuItemDef = {
      name: 'Add Parts to ST',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">a</span>',
      action: () => {
        callback({ functionToRun: 'addParts', params: sale })
      },
      disabled: isBooked,
      cssClasses: ['auxiliaryAction']
    }

    if (sale.status.id === SALES_ORDER_STATUS.BOOKED) {
      addPartsToST.disabled = true
      addPartsToST.tooltip = 'Cannot add parts to a sale that has been booked!'
    }

    const viewContract: MenuItemDef = {
      name: 'Contract',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">v</span>',
      action: () => {
        callback({ functionToRun: 'viewContract', params: sale.id })
      },
      cssClasses: ['bold']
    }

    const viewPickList: MenuItemDef = {
      name: 'PickList',
      action: () => {
        callback({ functionToRun: 'viewPickList', params: sale.id })
      },
      cssClasses: ['bold']
    }

    const viewPackSlip: MenuItemDef = {
      name: 'Pack Slip',
      action: () => {
        callback({ functionToRun: 'viewPackSlip', params: sale.id })
      },
      disabled: !sale.shipment_order,
      tooltip: sale.shipment_order ? '' : 'Shipment needs to be created first!',
      cssClasses: ['bold']
    }

    const copyTracking: MenuItemDef = {
      name: 'Copy Tracking',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">t</span>',
      action: () => {
        const trackingNumber = sale.shipment_order!.tracking_number
        callback({ functionToRun: 'copyTracking', params: trackingNumber })
      },
      cssClasses: ['bold']
    }

    if (!sale.shipment_order || !sale.shipment_order.tracking_number) {
      copyTracking.disabled = true
      copyTracking.tooltip = 'There is not a tracking number to copy yet.'
    }
    const deleteTooltip = (): string => {
      if (!hasPermissionsToDeleteSt) return noPermissionsMessageInContextMenuToolTip
      if (params.api?.getSelectedNodes().length !== 1) return 'Select only one row to delete.'
      return ''
    }

    const deleteSalesOrder: MenuItemDef = {
      name: 'Delete',
      action: () => {
        callback({ functionToRun: 'setCurrentOrderObject', params: sale })
        store.dispatch('grid/changeDialog', { app: 'sts', component: 'delete' })
      },
      disabled: !hasPermissionsToDeleteSt || params.api.getSelectedNodes().length !== 1,
      icon: '<i class="fas fa-trash error--text"></i>',
      tooltip: deleteTooltip(),
      cssClasses: ['negativeAction']
    }

    const editSale: MenuItemDef = {
      name: 'Edit Sale',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">e</span>',
      action: () => {
        PREP_SALE_FOR_EDIT({ id: sale.id, statusId: sale.status.id })
      },
      cssClasses: ['bold']
    }

    const copyST = {
      name: 'Duplicate ST', // changed from "copy" bc copy could mean copy/paste
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">c</span>',
      action: () => {
        callback({ functionToRun: 'copyST', params: sale.id })
      },
      cssClasses: ['bold']
    }

    let bookedTooltip: string = ''
    if (!isInShipping) bookedTooltip = 'This order is not in shipping yet'
    if (sale.status.status_type === 'FINAL') bookedTooltip = 'This order is already booked!'
    else if (!hasPermissionsToBookSt) bookedTooltip = noPermissionsMessageInContextMenuToolTip
    const markAsBooked: MenuItemDef = {
      name: 'Mark as Booked',
      action: () => {
        callback({ functionToRun: 'book', params: { id: sale.id } })
      },
      disabled: !isInShipping,
      tooltip: bookedTooltip,
      cssClasses: ['positiveAction']
    }

    editMenu.subMenu!.push(editSale, addPartsToST, markAsBooked)
    viewMenu.subMenu!.push(viewContract, viewPickList, viewPackSlip)
    if (editMenu.subMenu!.length > 0) {
      contextMenu.push(editMenu)
    }
    if (viewMenu.subMenu!.length > 0) {
      contextMenu.push(viewMenu)
    }
    contextMenu.push(deleteSalesOrder)
    // if (deleteMenu.subMenu!.length > 0) {
    //   contextMenu.push(deleteMenu)
    // }
    contextMenu.push(copyST, copyTracking, 'copy', 'export')
    return contextMenu
  } else return []
}

export const CONTEXT_MENU__SALES_DETAILS = (params: GetContextMenuItemsParams, orderId: string, clientId: string, callback: ContextMenuCallback): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []
  const nodes = params.api?.getSelectedNodes() ?? []
  const selectedNodes: RowNode[] = nodes.length > 0 ? nodes : [params.node]

  const viewMenu: MenuItemDef = {
    name: 'View',
    subMenu: [],
    cssClasses: ['bold']
  }

  const editMenu: MenuItemDef = {
    name: 'Edit',
    subMenu: [],
    cssClasses: ['bold']
  }

  const data: Sales_SoldItems = params.node.data
  // @ts-ignore
  const allSamePartNumber: boolean = helpers.checkAllSame({ column: 'part.id', nodes: selectedNodes })
  // @ts-ignore
  const allSameAllocation: boolean = helpers.checkAllSame({ column: 'item', nodes: selectedNodes })
  // @ts-ignore
  const canRMA: boolean = data.item?.rma_item_details === null && helpers.checkAllSame({ column: 'item.rma_item_details', nodes: selectedNodes })
  const rmaMessage: string = 'Item(s) are already on an RMA'

  const unAllocateMessage: string = 'Unable to un-allocate items until they are allocated!'

  const markAsLostMessage: string = 'Cannot mark as lost until allocated.'

  const createRMA: MenuItemDef = {
    name: 'Create RMA',
    shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">r</span>',
    action: () => {
      CreateSaleRMA(selectedNodes, orderId)
    },
    disabled: !canRMA,
    cssClasses: ['bold']
  }

  const addToRMA: MenuItemDef = {
    name: 'Add to an RMA',
    shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">t</span>',
    action: () => {
      AddToSaleRMA(selectedNodes, clientId, callback)
    },
    disabled: !canRMA,
    cssClasses: ['bold']
  }

  let allAllocated = true
  let allAbleToBeDeleted = true
  let lost = false
  for (const node of selectedNodes) {
    const data: Sales_SoldItems = node.data
    if (data.item === null) allAllocated = false
    else allAbleToBeDeleted = false
    if (data.item?.is_lost ?? false) lost = true
  }

  const removeItems: MenuItemDef = {
    name: 'Delete Items',
    action: () => {
      RemoveItemsFromSale(selectedNodes, orderId, callback)
    },
    disabled: !allAbleToBeDeleted,
    cssClasses: ['negativeAction']
  }

  const allocateParts: MenuItemDef = {
    name: (allAllocated || lost) ? 'Re-Allocate' : 'Allocate',
    shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">a</span>',
    action: () => {
      AllocateParts(data, selectedNodes, callback)
    },
    cssClasses: ['positiveAction']
  }

  const unAllocate: MenuItemDef = {
    name: 'UnAllocate Items',
    shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">u</span>',
    action: () => {
      UnAllocateParts(selectedNodes, orderId, callback)
    },
    cssClasses: ['negativeAction']
  }

  const markAsLost: MenuItemDef = {
    name: `Mark as ${data.item?.is_lost ? 'Found' : 'Lost'}`,
    shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">l</span>',
    action: () => {
      MarkAsLost(params)
    },
    cssClasses: ['warningAction']
  }

  /* Determine what menu items are disabled and have tooltips */

  if (allSamePartNumber && allSameAllocation && data.item === null) {
    unAllocate.disabled = true
    unAllocate.tooltip = unAllocateMessage
    markAsLost.disabled = true
    markAsLost.tooltip = markAsLostMessage
  } else {
    if (!allSamePartNumber) {
      allocateParts.disabled = true
      allocateParts.tooltip = 'Can only group allocate parts that are the same!'
    } else if (!allSameAllocation) {
      allocateParts.disabled = true
      allocateParts.tooltip = 'You have selected some parts that are already allocated!'
    }
  }

  if (!allAllocated) {
    markAsLost.disabled = true
    markAsLost.tooltip = markAsLostMessage
  }

  if (allSameAllocation && data.item === null) {
    createRMA.tooltip = `${selectedNodes.length === 1 ? 'This item' : 'These items'} cannot be added to an RMA until allocated and sold.`
    addToRMA.tooltip = `${selectedNodes.length === 1 ? 'This item' : 'These items'} cannot be added to an RMA until allocated and sold.`
  } else if (!canRMA) {
    createRMA.tooltip = rmaMessage
    addToRMA.tooltip = rmaMessage
  }

  editMenu.subMenu!.push(createRMA, addToRMA, allocateParts, removeItems, unAllocate, markAsLost)

  if (editMenu.subMenu!.length > 0) {
    contextMenu.push(editMenu)
  }
  if (viewMenu.subMenu!.length > 0) {
    contextMenu.push(viewMenu)
  }

  contextMenu.push('copy', 'export')

  return contextMenu
}

export const CONTEXT_MENU__SALES_RMA = (params: GetContextMenuItemsParams, component: any): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  if (params.api) {
    const rma: Rma_Rma = params.node.data
    const nodes: RowNode[] = params.api.getSelectedNodes()

    const hasNextStatus: boolean = rma.status.status_type !== 'FINAL'
    const nextStatusName: string = GetNextRMAStatusName(rma.status.order, RMA_TYPE.SALE)?.status ?? ''

    const markNextStatus: MenuItemDef = {
      name: 'Mark as ' + nextStatusName,
      // shortcut: `<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">m</span>`,
      action: () => {
        hasNextStatus && UpdateRmaStatus(params, UPDATE_SALE_RMA_STATUS, rma.id, Number(rma.status.id) + 1)
      },
      cssClasses: ['bold']
    }

    const viewSale: MenuItemDef = {
      name: 'View Sale Transaction',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">s</span>',
      action: () => {
        ViewSaleTransaction({ id: rma.st.id })
      },
      icon: routeIcon,
      cssClasses: ['bold']
    }
    const viewContract: MenuItemDef = {
      name: 'View Contract',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">v</span>',
      action: () => {
        component.viewContract(rma.id)
      },
      cssClasses: ['bold']
    }

    hasNextStatus && contextMenu.push(markNextStatus)
    contextMenu.push(viewSale, viewContract, 'copy', 'export')
  }

  return contextMenu
}

export const CONTEXT_MENU__SALES_RMA_NESTED = (params: GetContextMenuItemsParams): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  if (params.api) {
    contextMenu.push('copy', 'export')
  }

  return contextMenu
}

export const CONTEXT_MENU__QUOTES = (params: GetContextMenuItemsParams, callback: ContextMenuCallback): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  if (params.api) {
    const quote: Quotes_Quote = params.node.data

    const hasPermissionsToAddParts: boolean = true
    const hasPermissionsToCreateSale: boolean = true

    const addParts: MenuItemDef = {
      name: 'Add Parts',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">a</span>',
      action: () => {
        callback({ functionToRun: 'addParts', params: quote })
      },
      disabled: !hasPermissionsToAddParts,
      tooltip: hasPermissionsToAddParts ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['auxiliaryAction']
    }

    const convertToSale: MenuItemDef = {
      name: 'Convert To Sale',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">s</span>',
      action: () => {
        callback({ functionToRun: 'convertToSale', params: { params: params } })
      },
      disabled: !hasPermissionsToCreateSale,
      tooltip: hasPermissionsToCreateSale ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['positiveAction']
    }

    const viewPdf: MenuItemDef = {
      name: 'View Quote PDF',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">v</span>',
      action: () => {
        callback({ functionToRun: 'viewPdf', params: { id: quote.id } })
      },
      cssClasses: ['bold']
    }

    contextMenu.push(addParts, convertToSale, viewPdf, 'copy', 'export')
  }

  return contextMenu
}

export const CONTEXT_MENU__QUOTES_NESTED = (params: GetContextMenuItemsParams): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  if (params.api) {
    contextMenu.push('copy', 'export')
  }

  return contextMenu
}

/* End Sales Views */
/* Inventory Items */

export function LockOrUnlock (nodes: RowNode[], lock: boolean, callback: ContextMenuCallback): void {
  lock ? callback({ functionToRun: 'lock', params: nodes }) : callback({ functionToRun: 'unlock', params: nodes })
}

export function CreateBuildUpTask (system: Inventory_System): void {
  const partIdForNewBuildUp = `${system.part!.id}`
  const itemID = `${system.id}`
  const itemPtID = `${system.purchases_items_details!.transaction.id}-${system.purchases_items_details!.line_number}`
  apollo.query({
    query: GET_BOMS_FOR_PARTS,
    variables: { input: { filters: [{ key: 'bom_for__id', value: partIdForNewBuildUp }] } }
  }).then(({ data: { parts_boms } }) => {
    if (parts_boms.length > 0) {
      router.push({ name: 'new-build-up', params: { id: partIdForNewBuildUp, itemID: itemID, itemPtID: itemPtID } })
    } else {
      store.dispatch('notifications/createSnackbar', {
        message: 'A Bill of Materials does not exist for this part. Go to master parts to create one.',
        color: 'warning',
        timeout: 5000,
        canClose: false,
        top: false
      })
    }
  })
}

export function CreateBreakdownTask (system: Inventory_System): void {
  store.dispatch('notifications/createSnackbar', {
    message: 'Creating break down task...',
    color: 'info',
    timeout: 0
  })
  const id = system.id
  apollo.query({
    query: GRID_GET_ADD_ON_ITEMS_FOR_SYSTEM,
    variables: {
      input: {
        filters: [{ key: 'add_on_items__system__id', value: id }]
      }
    }
  }).then((response: ApolloQueryResult<{ items: Inventory_Item[] }>) => {
    const items = response.data.items
    apollo.mutate({
      mutation: CREATE_BREAK_DOWN,
      variables: { id: id }
    }).then((response: FetchResult<{ breakDown: Breakdown_BreakDown }>) => {
      const breakdown = response.data!.breakDown
      const itemsInput = []
      for (const item of items) {
        itemsInput.push({
          break_down_id: breakdown.id,
          item_id: item.id,
          status_id: 69
        })
      }
      apollo.mutate({
        mutation: CREATE_BREAK_DOWN_ITEMS,
        variables: {
          input: itemsInput
        }
      }).then(() => {
        store.dispatch('notifications/createSnackbar', {
          message: 'Success. This task can now be assigned.',
          color: 'success'
        })
      }).catch((error) => {
        store.dispatch('notifications/createSnackbar', {
          message: error.message,
          color: 'error'
        })
      })
    }).catch((error) => {
      store.dispatch('notifications/createSnackbar', {
        message: error.message,
        color: 'error'
      })
    })
  })
}

export const CONTEXT_MENU__INVENTORY_ITEMS = (params: GetContextMenuItemsParams, userInitials: string, callback: ContextMenuCallback): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  const { editMenu, viewMenu } = UseSubMenus()

  if (params.api) {
    const generatedCMIs: IGeneratedContextMenuObject = GenerateContextMenuItems(params, callback, { userInitials })

    editMenu.subMenu!.push(...generatedCMIs.edit)
    viewMenu.subMenu!.push(...generatedCMIs.view)

    if (editMenu.subMenu!.length > 0) {
      contextMenu.push(editMenu)
    }
    if (viewMenu.subMenu!.length > 0) {
      contextMenu.push(viewMenu)
    }
    contextMenu.push(...generatedCMIs.aux)
    contextMenu.push('copy', 'export')
  }

  return contextMenu
}

export const CONTEXT_MENU__INVENTORY_SYSTEMS = (params: GetContextMenuItemsParams, userInitials: string, callback: ContextMenuCallback): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []
  const { editMenu, createMenu, viewMenu } = UseSubMenus()

  if (params.api) {
    const selectedNodes = params.api.getSelectedNodes()
    const hasOneSelected = selectedNodes.length === 1
    const system: Inventory_System = params.node.data

    const itemCountForSystem: number = Number(system.annotations!.find(a => a!.name === 'itemCount')!.value)
    const hasAddOnItems: boolean = itemCountForSystem > 0

    const hasPermissionsForBuildUp: boolean = true
    const hasPermissionsForBreakdown: boolean = true
    const hasPermissionsForOobMgmtSerial: boolean = true
    const hasPermissionsToAuditSystem: boolean = true

    const canBuildUp = hasOneSelected && hasPermissionsForBuildUp
    const canBreakDown = hasOneSelected && hasPermissionsForBreakdown && hasAddOnItems

    const oobMgmt: MenuItemDef = {
      name: 'OOB Mgmt Serial',
      action: () => {
        callback({ functionToRun: 'addOobMgmtSerial', params: system.oobmgmt_serial })
      },
      disabled: !hasPermissionsForOobMgmtSerial,
      tooltip: hasPermissionsForOobMgmtSerial ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }
    // editMenu.subMenu!.push(oobMgmt)

    const buildupButton: MenuItemDef = {
      name: 'Build Up Task for: ' + system.part?.pn ?? 'System',
      action: () => {
        CreateBuildUpTask(system)
      },
      disabled: !canBuildUp,
      tooltip: canBuildUp ? '' : 'You may not have permissions for buildup or have selected more than one row.',
      cssClasses: ['bold']
    }

    createMenu.subMenu!.push(buildupButton)

    let breakDownToolTip: string = ''
    if (!hasPermissionsForBreakdown) breakDownToolTip = 'You do not have permissions to create a breakdown'
    else if (!hasOneSelected) breakDownToolTip = 'You need to select only one system to create a breakdown'
    else if (!hasAddOnItems) breakDownToolTip = 'This system does not have any noted add on items. Please audit this system first.'

    const breakdownButton: MenuItemDef = {
      name: `Breakdown Task for ${system.part?.pn ?? 'System'}`,
      action: () => {
        CreateBreakdownTask(system)
      },
      disabled: !canBreakDown,
      tooltip: breakDownToolTip,
      cssClasses: ['bold']
    }
    createMenu.subMenu!.push(breakdownButton)

    const auditSystem: MenuItemDef = {
      name: 'Audit System',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">a</span>',
      action: () => {
        router.push({ name: 'pre-breakdown-audit', params: { id: `${system.id}` } })
      },
      disabled: !hasPermissionsToAuditSystem,
      tooltip: hasPermissionsToAuditSystem ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }

    const cmObject: IGeneratedContextMenuObject = GenerateContextMenuItems(params, callback, { userInitials })

    editMenu.subMenu!.push(...cmObject.edit)
    viewMenu.subMenu!.push(...cmObject.view)

    if (createMenu.subMenu!.length > 0) {
      contextMenu.push(createMenu)
    }
    if (editMenu.subMenu!.length > 0) {
      contextMenu.push(editMenu)
    }
    if (viewMenu.subMenu!.length > 0) {
      contextMenu.push(viewMenu)
    }
    contextMenu.push(...cmObject.aux, auditSystem)
    contextMenu.push('copy', 'export')
  }

  return contextMenu
}

export const CONTEXT_MENU__INVENTORY_HISTORY = (params: GetContextMenuItemsParams, userInitials: string): Array<MenuItemDef | string> => {
  const callback: ContextMenuCallback = () => null
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  const generatedContextMenuObject: IGeneratedContextMenuObject = GenerateContextMenuItems(params, callback, { userInitials })

  contextMenu.push(...generatedContextMenuObject.view.filter(e => e.name.includes('Transaction')))
  contextMenu.push('copy', 'export')

  return contextMenu
}

export const CONTEXT_MENU__INVENTORY_MASTER = (params: GetContextMenuItemsParams, callback: Function): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []
  const part: Parts_Part = params.node.data
  const hasPermissionsToEditPart: boolean = true

  if (params.api) {
    const nodes = params.api.getSelectedNodes()

    const edit: MenuItemDef = {
      name: `Edit ${part.pn}`,
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">e</span>',
      action: () => {
        callback().editPart(part)
      },
      disabled: !hasPermissionsToEditPart,
      tooltip: hasPermissionsToEditPart ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }

    contextMenu.push(edit, 'copy', 'export')
  }

  return contextMenu
}

/* End Inventory */

/* CLIENTS Helpers and CMI */

export function AddContactToClient (callback: ContextMenuCallback) {
  callback({ data: '80%', dataKey: 'localDialog' })
  store.dispatch('grid/changeDialog', { app: 'clients', component: 'newContact' })
}

export function AddAddressToClient (callback: ContextMenuCallback) {
  callback({ data: '80%', dataKey: 'localDialog' })
  store.dispatch('grid/changeDialog', { app: 'clients', component: 'newAddress' })
}

export function AddCarrierToClient (callback: ContextMenuCallback) {
  callback({ data: '80%', dataKey: 'localDialog' })
  store.dispatch('grid/changeDialog', { app: 'clients', component: 'carrierAccount' })
}

export function FindOtherDefaultInColumn (params: GetContextMenuItemsParams, column: string): string {
  let result = ''
  params.api!.forEachNode(n => {
    if (n.data[column]) result = n.data.id
  })
  return result
}

export const CONTEXT_MENU__CLIENTS = (params: GetContextMenuItemsParams, callback: ContextMenuCallback): Array<MenuItemDef | string> => {
  if (params.value === null) return []
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  if (params.api && params?.node?.data) {
    const client: Clients_Client = params.node.data
    const nodes: RowNode[] = params.api.getSelectedNodes()

    const editWidth = (width: string) => callback({ dataKey: 'dialogWidth', data: width })

    callback({ data: client, dataKey: 'selectedClient' })

    const hasPermissionsToAddContact: boolean = true
    const hasPermissionsToAddAddress: boolean = true
    const hasPermissionsToAddCarrier: boolean = true
    const hasPermissionsToChangeClientType: boolean = true

    const addContact: MenuItemDef = {
      name: 'Add Contact',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">c</span>',
      action: () => {
        AddContactToClient(callback)
      },
      disabled: !hasPermissionsToAddContact,
      tooltip: hasPermissionsToAddContact ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }

    const addAddress: MenuItemDef = {
      name: 'Add Address',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">a</span>',
      action: () => {
        AddAddressToClient(callback)
      },
      disabled: !hasPermissionsToAddAddress,
      tooltip: hasPermissionsToAddAddress ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }

    const addCarrierAccount: MenuItemDef = {
      name: 'Add Carrier Account',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">r</span>',
      action: () => {
        AddCarrierToClient(callback)
      },
      disabled: !hasPermissionsToAddCarrier,
      tooltip: hasPermissionsToAddCarrier ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }

    const changeClientType: MenuItemDef = {
      name: 'Change Client Type',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">e</span>',
      action: () => {
        store.dispatch('grid/changeDialog', { app: 'clients', component: 'changeClientType' })
      },
      disabled: !hasPermissionsToChangeClientType,
      tooltip: hasPermissionsToChangeClientType ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }

    contextMenu.push(addContact, addAddress, addCarrierAccount, changeClientType, 'copy', 'export')
  }

  return contextMenu
}

export const CONTEXT_MENU__CLIENTS_NESTED = (params: GetContextMenuItemsParams, active: string, callback: ContextMenuCallback): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []
  const successMessage = (type: string): string => {
    return `Successfully changed default ${type}.`
  }

  const performAlertHandler = (message: string, type: string, timeout: number) => {
    callback({ functionToRun: 'alertHandler', params: { message: message, type: type, timeout: timeout } })
  }

  if (params.api) {
    const contextMenu = []

    const contactsIsActive: boolean = active === 'contacts'
    const addressesIsActive: boolean = active === 'addresses'
    const carrierAccountsIsActive: boolean = active === 'accounts'
    const purchasesIsActive: boolean = active === 'purchases'
    const salesIsActive: boolean = active === 'sales'

    // contacts context menu items
    if (contactsIsActive) {
      const contact: Clients_Contact = params.node?.data

      const hasPermissionsToDeleteContact: boolean = true
      const hasPermissionsToEditContact: boolean = true

      const editContact: MenuItemDef = {
        name: 'Edit',
        action: () => {
          callback({ functionToRun: 'prepEditContact', params: contact.owner.id })
        },
        icon: '<i class="fal fa-pencil"></i>',
        disabled: !hasPermissionsToEditContact,
        tooltip: hasPermissionsToEditContact ? '' : noPermissionsMessageInContextMenuToolTip,
        cssClasses: ['bold']
      }
      const deleteContact: MenuItemDef = {
        name: 'DELETE',
        action: () => {
          apollo.mutate({
            mutation: DELETE_CONTACT,
            variables: { id: contact.id }
          }).then(() => {
            GridEvents.$emit('refetch-nested-client')
          }).catch((e) => {
            store.dispatch('notifications/createSnackbar', {
              message: e.message,
              color: 'error'
            })
          })
        },
        disabled: !hasPermissionsToDeleteContact,
        tooltip: hasPermissionsToDeleteContact ? '' : noPermissionsMessageInContextMenuToolTip,
        cssClasses: ['negativeAction']
      }

      !!params.node && contextMenu.push(editContact, deleteContact)
    }
    // addresses context menu items
    if (addressesIsActive && params?.node?.data) {
      const address: Address_Address = params.node.data

      const hasPermissionsToChangeAddressDefaults: boolean = true

      const deleteAddress: MenuItemDef = {
        name: 'Delete Address',
        action: () => {
          apollo.mutate({
            mutation: DELETE_ADDRESS,
            variables: { id: address.id }
          }).then(() => {
            callback({ functionToRun: 'refetch' })
          }).catch(async error => {
            await store.dispatch('notifications/createSnackbar', {
              message: error.message,
              color: 'error'
            })
          })
        },
        disabled: !hasPermissionsToChangeAddressDefaults,
        tooltip: hasPermissionsToChangeAddressDefaults ? '' : noPermissionsMessageInContextMenuToolTip,
        cssClasses: ['negativeAction']
      }

      !!params.node && contextMenu.push(deleteAddress)
    }

    // accounts context menu items
    if (carrierAccountsIsActive) {
      const permissionToEditClientCarrierAccount: boolean = true

      const addAccount: MenuItemDef = {
        name: 'Add Carrier Account',
        action: () => {
          callback({ functionToRun: 'prepAddCarrierAccount', params: {} })
        },
        disabled: !permissionToEditClientCarrierAccount,
        tooltip: permissionToEditClientCarrierAccount ? '' : noPermissionsMessageInContextMenuToolTip,
        cssClasses: ['positiveAction']
      }

      contextMenu.push(addAccount)
    }
    // purchases context menu items
    if (purchasesIsActive) {
      const purchase: Purchases_Purchase = params.node?.data
      const viewPt: MenuItemDef = {
        name: 'View Purchase Transaction',
        action: () => {
          ViewPurchaseTransaction({ id: purchase.id })
        },
        cssClasses: ['bold']
      }

      purchase.id && contextMenu.push(viewPt)
    }

    // sales context menu items
    if (salesIsActive) {
      const sale: Sales_Sale = params.node?.data
      const viewSt: MenuItemDef = {
        name: 'View ST',
        action: () => {
          ViewSaleTransaction({ id: sale.id })
        },
        cssClasses: ['bold']
      }

      sale.id && contextMenu.push(viewSt)
    }

    contextMenu.push('copy', 'export')
    return contextMenu
  }

  return contextMenu
}

/* BUILD_UP */

export const CONTEXT_MENU__BUILD_UP = (params: GetContextMenuItemsParams, callback: ContextMenuCallback): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  if (params.api) {
    const buildUp: BuildUp_BuildUp = params.node.data

    const selfId: number = store.state.profile.user.id

    const hasPermissionsToAssignBuildUp: boolean = true
    const hasPermissionsToChangeBuildUpStatus: boolean = true

    const push = (item: MenuItemDef) => {
      contextMenu.push(item)
    }

    const goToSystem = {
      name: `Go To System ${getPtId({ purchaseDetails: buildUp.system.purchases_items_details })}`,
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">s</span>',
      action: () => {
        ViewSystem({ id: buildUp.system.id })
      },
      icon: routeIcon,
      cssClasses: ['bold']
    }
    push(goToSystem)

    const isAssigned = params.node.data.assigned_to?.id ?? false
    const notAssignedToSelf = (params.node.data.assigned_to?.id ?? 0) !== selfId
    const assignTo: MenuItemDef = {
      name: `${isAssigned ? 'Rea' : 'A'}ssign Task`,
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">a</span>',
      action: () => {
        callback({ functionToRun: 'prepareBuildUpAssignment', params: { params: params } })
      },
      cssClasses: ['bold']
    }

    const assignToSelf: MenuItemDef = {
      name: 'Assign to ME',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">m</span>',
      action: () => {
        callback({ functionToRun: 'assignBuildUp', params: { user: selfId, buildUpId: buildUp.id } })
      },
      disabled: !hasPermissionsToAssignBuildUp,
      tooltip: hasPermissionsToAssignBuildUp ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }
    push(assignTo)
    notAssignedToSelf && push(assignToSelf)

    const notReady = params.node.data.status.id === 74
    const isReady: MenuItemDef = {
      name: 'Build Up Task is Ready',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">r</span>',
      action: () => {
        callback({ functionToRun: 'buildUpIsReady', params: { params: params } })
      },
      disabled: !hasPermissionsToChangeBuildUpStatus,
      tooltip: hasPermissionsToChangeBuildUpStatus ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['positiveAction']
    }
    notReady && push(isReady)

    const hasNonCompleteItems = params.node.data.annotations.find((a: AnnotationObject) => a.name === 'nonCompleteItems').value !== null
    const hasItems = params.node.data.annotations.find((a: AnnotationObject) => a.name === 'itemCount').value > 0
    const isOwner = params.node.data.creator.id === selfId
    const isNotComplete = params.node.data.status.id !== 78 && hasItems
    const showComplete = !hasNonCompleteItems && isOwner && isNotComplete
    const complete: MenuItemDef = {
      name: 'Complete Build Up',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">c</span>',
      action: () => {
        callback({ functionToRun: 'completeBuildUp', params: { params: params } })
      },
      disabled: !hasPermissionsToChangeBuildUpStatus,
      tooltip: hasPermissionsToChangeBuildUpStatus ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['positiveAction']
    }
    showComplete && push(complete)
  }

  return contextMenu
}

export const CONTEXT_MENU__BUILD_UP_NESTED = (params: GetContextMenuItemsParams, callback: ContextMenuCallback): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  const hasPermissionsToChangeBuildUpItemStatus: boolean = true

  if (params.api) {
    const next: MenuItemDef = {
      name: 'Next Status',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">n</span>',
      action: () => {
        callback({ functionToRun: 'markBuildUpItem', params: { params: params } })
      },
      disabled: !hasPermissionsToChangeBuildUpItemStatus,
      tooltip: hasPermissionsToChangeBuildUpItemStatus ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['positiveAction']
    }

    const back: MenuItemDef = {
      name: 'Go Back a Status',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">b</span>',
      action: () => {
        callback({ functionToRun: 'markBuildUpItem', params: { params: params, back: true } })
      },
      disabled: !hasPermissionsToChangeBuildUpItemStatus,
      tooltip: hasPermissionsToChangeBuildUpItemStatus ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['negativeAction']
    }

    contextMenu.push(next, back)
  }

  return contextMenu
}

/* BreakDown */

export const CONTEXT_MENU__BREAK_DOWN = (params: GetContextMenuItemsParams, callback: ContextMenuCallback): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  if (params.api) {
    const breakdown: Breakdown_BreakDown = params.node.data
    const nodes: RowNode[] = params.api.getSelectedNodes()
    const userId: number = store.state.profile.user.id

    const hasPermissionsToDoBreakdown: boolean = true
    const canBeMarkedAsCompleted: boolean = breakdown.status.status_type === 'DONE'

    const doBreakdown: MenuItemDef = {
      name: 'Process Breakdown',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">b</span>',
      action: () => {
        // need to use a filter from the context of PURCHASED ITEMS because we are going to the receiving endpoint
        const routeQuery: RouterQueryParamsForBackend = {
          filters: [{ key: 'item__broke_down_items__break_down__id', value: breakdown.id }]
        }
        router.push({ name: 'receive', query: routeQuery, params: { id: breakdown.system.purchases_items_details?.transaction.id ?? '' } })
      },
      disabled: !hasPermissionsToDoBreakdown || canBeMarkedAsCompleted,
      icon: routeIcon,
      tooltip: hasPermissionsToDoBreakdown ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['positiveAction']
    }

    const assignBreakdown: MenuItemDef = {
      name: 'Assign To a User',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">a</span>',
      action: () => {
        callback({ functionToRun: 'prepareBreakDownAssignment', params: { params: params } })
      },
      disabled: !hasPermissionsToDoBreakdown,
      tooltip: hasPermissionsToDoBreakdown ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }

    const assignToSelf: MenuItemDef = {
      name: 'Assign to Me',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">m</span>',
      action: () => {
        callback({ functionToRun: 'assignBreakDown', params: { user: userId, id: breakdown.id } })
      },
      disabled: !hasPermissionsToDoBreakdown,
      tooltip: hasPermissionsToDoBreakdown ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }

    const breakDownIsReady: MenuItemDef = {
      name: 'Breakdown Is Ready',
      action: () => {
        callback({ functionToRun: 'breakDownIsReady', params: breakdown.id })
      },
      disabled: (!hasPermissionsToDoBreakdown || breakdown.status.id !== BREAKDOWN_STATUS.CREATED),
      tooltip: hasPermissionsToDoBreakdown ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }

    const completeBreakdown = {
      name: 'Complete Breakdown',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">c</span>',
      action: () => {
        callback({ functionToRun: 'completeBreakdown', params: { id: breakdown.id } })
      },
      disabled: !hasPermissionsToDoBreakdown || !canBeMarkedAsCompleted,
      tooltip: hasPermissionsToDoBreakdown ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }

    contextMenu.push(doBreakdown, assignBreakdown, assignToSelf, breakDownIsReady, completeBreakdown)
  }

  return contextMenu
}

export const CONTEXT_MENU__BREAK_DOWN_NESTED = (params: GetContextMenuItemsParams, callback: ContextMenuCallback): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  if (params.api) {
    const hasPermissionsToChangeBreakdownItemStatus: boolean = true
    /*

    const next: MenuItemDef = {
      name: 'Next Status',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">n</span>',
      action: () => {
        callback({ functionToRun: 'markBreakDownItem', params: { params: params } })
      },
      disabled: !hasPermissionsToChangeBreakdownItemStatus,
      tooltip: hasPermissionsToChangeBreakdownItemStatus ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['positiveAction']
    }

    const back: MenuItemDef = {
      name: 'Go Back a Status',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">b</span>',
      action: () => {
        callback({ functionToRun: 'markBreakDownItem', params: { params: params, back: true } })
      },
      disabled: !hasPermissionsToChangeBreakdownItemStatus,
      tooltip: hasPermissionsToChangeBreakdownItemStatus ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['negativeAction']
    }

*/
    // contextMenu.push(next, back)
    contextMenu.push('copy', 'export')
  }

  return contextMenu
}

/* RECEIVING */

export function MultiEditLocation (params: GetContextMenuItemsParams | CellKeyDownEvent, nodes: RowNode[], location: Location_Location): void {
  for (const node of nodes) {
    const purchaseItem: Purchases_PurchasedItems = node.data
    purchaseItem.item!.location = location
  }
  params.api?.redrawRows({ rowNodes: nodes })
}

export function MultiEditCountry (params: GetContextMenuItemsParams | CellKeyDownEvent, nodes: RowNode[], country: CountryObject): void {
  for (const node of nodes) {
    const purchaseItem: Purchases_PurchasedItems = node.data
    // @ts-ignore
    purchaseItem.item!.country_of_origin = country
  }
  params.api?.redrawRows({ rowNodes: nodes })
}

export function MultiReceivePurchasedItems (nodes: RowNode[], value: boolean): void {
  for (const node of nodes) {
    const currentStatus = node.data.item.receive_status
    if (!currentStatus && !node.data.delete) {
      node.setDataValue('receive', value)
    } else if (currentStatus) {
      node.setDataValue('receive', value)
    }
  }
}

export function MultiDeletePurchasedItems (nodes: RowNode[], value: boolean): void {
  for (const node of nodes) {
    const currentStatus = node.data.delete
    if (!currentStatus && !node.data.item.receive_status && !node.data.item.saleItem) {
      node.setDataValue('delete', value)
    } else if (currentStatus) {
      node.setDataValue('delete', value)
    }
  }
}

export const CONTEXT_MENU__RECEIVING = (params: GetContextMenuItemsParams): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  if (params.api) {
    const nodes = params.api.getSelectedNodes()

    const locationCheck: IPassAndReasonObject = CheckRowsForSingleUniqueValueAmongNulls(nodes, 'item.location', 'name')
    const countryCheck: IPassAndReasonObject = CheckRowsForSingleUniqueValueAmongNulls(nodes, 'item.country_of_origin', 'code')
    const location: Location_Location = locationCheck.data
    const country: CountryObject = countryCheck.data
    const locationName: string = `Multi-Row Location Set ${location ? ':' + location.name : ''}`
    const countryName: string = `Multi-Row Country Set ${country ? ':' + country.name : ''}`

    const moreThanOneSelected = nodes.length > 1

    const locationDisabled = !moreThanOneSelected || !locationCheck.pass
    const locationTooltip = !moreThanOneSelected ? 'Select more than one' : locationCheck.reason
    const multiLocationEdit = {
      name: locationName,
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">l</span>',
      action: () => {
        MultiEditLocation(params, nodes, location)
      },
      disabled: locationDisabled,
      tooltip: locationTooltip
    }

    const countryDisabled = !moreThanOneSelected || !countryCheck.pass || !country.name
    const countryTooltip = !moreThanOneSelected ? 'Select more than one' : countryCheck.reason
    const multiCountryEdit = {
      name: countryName,
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">c</span>',
      action: () => {
        MultiEditCountry(params, nodes, country)
      },
      disabled: countryDisabled,
      tooltip: countryTooltip
    }

    const receive: MenuItemDef = {
      name: 'Receive',
      cssClasses: ['positiveAction'],
      subMenu: []
    }

    const d: MenuItemDef = {
      name: 'Delete',
      cssClasses: ['negativeAction'],
      subMenu: []
    }

    const multiReceive = {
      name: 'Check Receive for Selected',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">r</span>',
      action: () => {
        MultiReceivePurchasedItems(nodes, true)
      },
      cssClasses: ['bold']
    }

    const multiUnCheckReceive = {
      name: 'UnCheck Receive for Selected',
      action: () => {
        MultiReceivePurchasedItems(nodes, false)
      },
      cssClasses: ['bold']
    }
    receive.subMenu!.push(multiReceive, multiUnCheckReceive)

    let cannotDelete = false
    for (const node of nodes) {
      if (node.data.item.saleItem) {
        cannotDelete = true
      }
    }
    const multiDelete = {
      name: 'Check DELETE for Selected',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">d</span>',
      action: () => {
        MultiDeletePurchasedItems(nodes, true)
      },
      disabled: cannotDelete,
      cssClasses: ['bold']
    }
    const unCheckDelete = {
      name: 'Un DELETE for Selected',
      action: () => {
        MultiDeletePurchasedItems(nodes, false)
      },
      disabled: cannotDelete,
      cssClasses: ['bold']
    }
    d.subMenu!.push(multiDelete, unCheckDelete)

    contextMenu.push(multiLocationEdit, multiCountryEdit, receive, d, 'copy', 'export')
  }

  return contextMenu
}

export const CONTEXT_MENU__MASTER_NESTED = (params: GetContextMenuItemsParams, active: string, self: any): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  const hasPermissionsToEditBoms: boolean = true

  if (params.api) {
    if (active === 'bom') {
      const bomPart: Parts_BomParts = params.node?.data ?? {}

      const addBomPart: MenuItemDef = {
        name: 'Add',
        // shortcut: params.node ? `<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">a</span>` : '',
        action: () => {
          self.addBomPart()
          // callback({ functionToRun: 'addBomPart', params: {} })
        },
        disabled: !hasPermissionsToEditBoms,
        tooltip: hasPermissionsToEditBoms ? '' : noPermissionsMessageInContextMenuToolTip,
        icon: '<i class="fal fa-plus"></i>',
        cssClasses: ['positiveAction']
      }
      contextMenu.push(addBomPart)

      const deleteBomPart: MenuItemDef = {
        name: 'Remove',
        // shortcut: `<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">d</span>`,
        action: async () => {
          self.deleteBomPart({ id: bomPart.id })
        },
        disabled: !hasPermissionsToEditBoms,
        tooltip: hasPermissionsToEditBoms ? '' : noPermissionsMessageInContextMenuToolTip,
        icon: '<i class="fal fa-times-circle"></i>',
        cssClasses: ['negativeAction']
      }

      const editBomPart: MenuItemDef = {
        name: 'Edit',
        // shortcut: `<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">e</span>`,
        action: () => {
          self.editBomPart({ data: bomPart })
        },
        disabled: !hasPermissionsToEditBoms,
        tooltip: hasPermissionsToEditBoms ? '' : noPermissionsMessageInContextMenuToolTip,
        icon: '<i class="fal fa-pencil"></i>',
        cssClasses: ['auxiliaryAction']
      }
      !!params.node && contextMenu.push(deleteBomPart, editBomPart) // checks for node because there can be none
    } else if (active === 'subs') {
      const sub: Parts_Part = params.node?.data ?? {}

      const hasPermissionsToEditSubs: boolean = true

      const addSubPart: MenuItemDef = {
        name: 'Link a Sub',
        shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">l</span>',
        action: () => {
          self.prepLinkPart()
        },
        disabled: !hasPermissionsToEditSubs,
        tooltip: hasPermissionsToEditSubs ? '' : noPermissionsMessageInContextMenuToolTip,
        cssClasses: ['positiveAction']
      }
      contextMenu.push(addSubPart)

      const unlink: MenuItemDef = {
        name: 'Unlink Sub',
        shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut"></span>',
        action: () => {
          self.unlinkPart({ id: sub.id })
        },
        disabled: !hasPermissionsToEditSubs,
        tooltip: hasPermissionsToEditSubs ? '' : noPermissionsMessageInContextMenuToolTip,
        cssClasses: ['negativeAction']
      }

      !!params.node && contextMenu.push(unlink)
    }
    contextMenu.push('copy', 'export')
  }

  return contextMenu
}

/* ACCOUNTING */

export const CONTEXT_MENU__ACCOUNTING_GL = (params: GetContextMenuItemsParams, callback: ContextMenuCallback): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []
  const canCopyExport: boolean = true
  const canReviewEntry: boolean = true
  const canAddEntry: boolean = true

  if (params.api) {
    if (params.node.data.__typename.includes('Transaction')) {
      const markAsReviewed = {
        name: 'Mark Reviewed',
        action: () => {
          callback({ functionToRun: 'markAsReviewed', params: params.node.data.id })
        },
        disabled: !canReviewEntry,
        tooltip: canReviewEntry ? '' : noPermissionsMessageInContextMenuToolTip,
        cssClasses: ['bold']
      }

      const addEntryToTransaction = {
        name: 'Add Entry To Transaction',
        action: () => {
          callback({ functionToRun: 'showAddGlTransactionSheet', params: params.node.data.id })
        },
        disabled: !canAddEntry,
        icon: '<i class="fal fa-plus-circle primary--text"></i>',
        tooltip: canAddEntry ? '' : noPermissionsMessageInContextMenuToolTip,
        cssClasses: ['bold']
      }

      contextMenu.push(addEntryToTransaction, markAsReviewed)
    }

    canCopyExport && contextMenu.push('copy', 'export')
  }

  return contextMenu
}

export const CONTEXT_MENU__ACCOUNTING_AR = (params: GetContextMenuItemsParams, callback: ContextMenuCallback): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  const hasPermissionsToEditArInvoices: boolean = true

  if (params.api) {
    const invoice: Invoices_ArInvoice = params.node.data

    const addNewCredit: MenuItemDef = {
      name: 'Add Credit',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">c</span>',
      action: () => {
        callback({ functionToRun: 'addCreditToArInvoice', params: { params: params } })
      },
      disabled: !hasPermissionsToEditArInvoices,
      tooltip: hasPermissionsToEditArInvoices ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }

    const addNewPayment: MenuItemDef = {
      name: 'Record Payment',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">r</span>',
      action: () => {
        callback({ functionToRun: 'showAddPaymentToInvoiceDialog', params: { params: params } })
      },
      disabled: !hasPermissionsToEditArInvoices,
      tooltip: hasPermissionsToEditArInvoices ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }

    const voidInvoice: MenuItemDef = {
      name: 'VOID',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">v</span>',
      action: () => {
        callback({ functionToRun: 'prepareToVoidArInvoices', params: { params: params } })
      },
      disabled: !hasPermissionsToEditArInvoices,
      tooltip: hasPermissionsToEditArInvoices ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['negativeAction']
    }

    const reissueInvoice: MenuItemDef = {
      name: 'Reissue',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">i</span>',
      action: () => {
        callback({ functionToRun: 'reissueInvoice', params: { params: params } })
      },
      cssClasses: ['bold']
    }

    const viewSt: MenuItemDef = {
      name: 'View Related ST',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">s</span>',
      action: () => {
        ViewSaleTransaction({ id: params.node.data.order.id })
      },
      icon: routeIcon,
      cssClasses: ['bold']
    }

    contextMenu.push(addNewPayment, voidInvoice, viewSt, 'copy', 'export')
  }

  return contextMenu
}

export const CONTEXT_MENU__ACCOUNTING_AR_NESTED = (params: GetContextMenuItemsParams, callback: ContextMenuCallback): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []
  const canMarkAsPosted: boolean = true

  if (params.api) {
    const payment: Invoices_ArPayment = params.node.data

    const markAsPosted: MenuItemDef = {
      name: `Mark as ${payment.is_posted ? 'Not' : ''} Posted`,
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">m</span>',
      action: async () => {
        await MarkPaymentAsPosted(payment.id, payment.is_posted, 'AR')
      },
      disabled: !canMarkAsPosted,
      tooltip: canMarkAsPosted ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }
    contextMenu.push(markAsPosted, 'copy', 'export')
  }

  return contextMenu
}

export const CONTEXT_MENU__ACCOUNTING_AP = (params: GetContextMenuItemsParams, callback: ContextMenuCallback): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  if (params.api) {
    const hasPermissionsToEditApInvoices: boolean = true

    const recordPayment: MenuItemDef = {
      name: 'Record Payment',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">r</span>',
      action: () => {
        callback({ functionToRun: 'showAddPaymentToInvoiceDialog', params: { params: params } })
      },
      disabled: !hasPermissionsToEditApInvoices,
      tooltip: hasPermissionsToEditApInvoices ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }

    const reissueInvoice: MenuItemDef = {
      name: 'Reissue Invoice',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">r</span>',
      action: () => {
        callback({ functionToRun: 'reissueInvoice', params: { params: params } })
      },
      disabled: !hasPermissionsToEditApInvoices,
      tooltip: hasPermissionsToEditApInvoices ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }

    const voidInvoice: MenuItemDef = {
      name: 'VOID',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">v</span>',
      action: () => {
        callback({ functionToRun: 'prepareToVoidApInvoices', params: { params: params } })
      },
      disabled: !hasPermissionsToEditApInvoices,
      tooltip: hasPermissionsToEditApInvoices ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['negativeAction']
    }

    const viewPT: MenuItemDef = {
      name: 'View Related PT',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">p</span>',
      action: () => {
        ViewPurchaseTransaction({ id: params.node.data.order.id })
      },
      icon: routeIcon,
      disabled: !hasPermissionsToEditApInvoices,
      tooltip: hasPermissionsToEditApInvoices ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }

    // const printInvoice: MenuItemDef = {
    //   name: 'Print Invoice',
    //   shortcut: `<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">p</span>`,
    //   action: () => {
    //     this.printInvoice({ params: params })
    //   },
    //   disabled: !hasRowSelection,
    //   cssClasses: ['bold']
    // }

    contextMenu.push(recordPayment, voidInvoice, viewPT, 'copy', 'export')
  }

  return contextMenu
}

export const CONTEXT_MENU__ACCOUNTING_AP_NESTED = (params: GetContextMenuItemsParams, callback: ContextMenuCallback): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []
  const canMarkAsPosted: boolean = true

  if (params.api) {
    const payment: Invoices_ApPayment = params.node.data

    const markAsPosted: MenuItemDef = {
      name: `Mark as ${payment.is_posted ? 'Not' : ''} Posted`,
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">m</span>',
      action: async () => {
        await MarkPaymentAsPosted(payment.id, payment.is_posted, 'AP')
      },
      disabled: !canMarkAsPosted,
      tooltip: canMarkAsPosted ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }
    contextMenu.push(markAsPosted, 'copy', 'export')
  }

  return contextMenu
}

export const CONTEXT_MENU__ACCOUNTING_COA = (params: GetContextMenuItemsParams, callback: ContextMenuCallback): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  if (params.api) {
    const nodes = params.api.getSelectedNodes()

    const hasPermissionsToEditAccounts: boolean = true

    const addNewSubAccount: MenuItemDef = {
      name: 'Add Sub Account',
      icon: '<i class="fal fa-plus-circle primary--text"></i>',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">a</span>',
      action: () => {
        callback({ functionToRun: 'prepareToAddSubAccountNumber', params: { params: params } })
      },
      disabled: !hasPermissionsToEditAccounts,
      tooltip: hasPermissionsToEditAccounts ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }

    const editAccount = {
      name: 'Edit',
      icon: '<i class="fal fa-pencil warning--text"></i>',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">e</span>',
      action: () => {
        callback({ functionToRun: 'prepareToEdit', params: { params: params } })
      },
      disabled: !hasPermissionsToEditAccounts,
      tooltip: hasPermissionsToEditAccounts ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }

    contextMenu.push(addNewSubAccount, editAccount, 'copy', 'export')
  }

  return contextMenu
}

export const CONTEXT_MENU__ACCOUNTING_COA_NESTED = (params: GetContextMenuItemsParams, callback: ContextMenuCallback): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  if (params.api) {
    const nodes = params.api.getSelectedNodes()

    const hasPermissionsToEditAccounts: boolean = true

    const editAccount = {
      name: 'Edit',
      icon: '<i class="fal fa-pencil warning--text"></i>',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">e</span>',
      action: () => {
        callback({ functionToRun: 'prepareToEdit', params: { params: params } })
      },
      disabled: !hasPermissionsToEditAccounts,
      tooltip: hasPermissionsToEditAccounts ? '' : noPermissionsMessageInContextMenuToolTip,
      cssClasses: ['bold']
    }

    contextMenu.push(editAccount, 'copy', 'export')
  }

  return contextMenu
}

/* PERMISSIONS */

export const CONTEXT_MENU__USER_MANAGEMENT = (params: GetContextMenuItemsParams, vm: any): Array<MenuItemDef | string> => {
  CorrectSelectedNodes(params)
  const contextMenu: Array<MenuItemDef | string> = []

  if (params.api) {
    const user: Users_User = params.node.data

    const hasPermissionToEditCommissions = true
    const editCommissions = {
      name: 'Edit Commission',
      action: () => {
        vm.editUserCommissions(user)
      },
      disabled: !hasPermissionToEditCommissions,
      tooltip: hasPermissionToEditCommissions ? '' : 'You do not have permissions to do this action',
      cssClasses: ['bold']
    }
    contextMenu.push(editCommissions)
  }

  return contextMenu
}
