import { FRAGMENT__BASIC_ITEM as BasicItem, FRAGMENT__BASIC_PART as BasicPart } from '@/api/graphql/Constants/Inventory'
import gql from 'graphql-tag'
import { FRAGMENT__MONEY } from '@/api/graphql/Constants/TERMS'
import { FRAGMENT__CONTACT__FULL, FRAGMENT__CONTACT__LIST, FRAGMENT__LIST_USER } from '@/api/graphql/Constants/Contacts'
import {
  Create__Purchases_Purchase__Input,
  Create__Purchases_PurchasedItems__Input,
  Create__Sales_Sale__Input,
  Create__Sales_SoldItems__Input,
  CreatePurchasedItemMutation,
  CreatePurchasedItemMutationVariables,
  CreatePurchaseMutation,
  CreatePurchaseMutationVariables,
  CreateSoldItemsMutation,
  CreateSoldItemsMutationVariables,
  Mutation,
  MutationCreate__Sales_SaleArgs,
  PurchaseFragment,
  SaleFragment,
  Sales_Sale,
  SalesDetailsForMoneyUpdateQuery,
  Update__Sales_Sale__Input,
  UpdatePurchaseMutation,
  UpdatePurchaseMutationVariables,
  UpdateSaleMoneyMutation,
  UpdateSaleMoneyMutationVariables,
  UpdateSaleMutation,
  UpdateSaleMutationVariables
} from '@/models/generated/graphql/ErpBackend'
import { apolloClient as apollo } from '@/api/graphql/apollo'
import { CalculateMoneyPostSaleUpdate } from '@/lib/moneyHelpers'
import { MutationOptions } from 'apollo-client'
import { FRAGMENT__CLIENT__LIST } from '@/api/graphql/Constants/Clients'
import { FRAGMENT__USERS_FULL } from '@/api/graphql/Constants/Users'
import { FRAGMENT__ADDRESS__FULL } from '@/api/graphql/Constants/Addresses'
import { FRAGMENT__SHIPMENT_ORDER_TO_EDIT } from '@/api/graphql/Constants/Shipments'

/* INPUTS */

export const GQL_INPUT__PURCHASE_ORDERS = {
  annotations: [{ name: 'unReceivedCount', aggr_type: 'COUNT', aggr_field: 'pt_items', aggr_filters: [{ key: 'pt_items__item__receive_status', value: false }] }],
  excludes: [],
  filters: [],
  limit: 51,
  offset: 0,
  order_by: ['-id']
}

export const GQL_INPUT__SALE_ORDERS = {
  annotations: [
    {
      name: 'unAllocated',
      aggr_type: 'COUNT',
      aggr_field: 'st_items',
      aggr_filters: [{ key: 'st_items__item__isnull', value: true }]
    },
    {
      name: 'itemCount',
      aggr_type: 'SUM',
      aggr_field: 'st_items',
      aggr_filters: [{ key: 'st_items__id__gt', value: 0 }]
    }
  ],
  excludes: [],
  filters: [],
  limit: 51,
  offset: 0,
  order_by: ['-id']
}

/* FRAGMENTS */

export const FRAGMENT__PURCHASE = gql`
  fragment Purchase on Purchases_Purchase {
    id
    acceptance_date
    approval_date
    approved_by {
      id
      full_name
      initials
    }
    client {
      id
      name
    }
    client_order_number
    condition {
      id
      choice
    }
    contact {
      id
      email
      full_name
    }
    contract_comment
    contract_sent_by {
      id
      full_name
      initials
    }
    creation_date
    creator {
      id
      initials
      full_name
    }
    eta
    hot
    internal_comment
    freight { ...Money }
    estimated_part_total { ...Money }
    purchase_date
    quote {
      id
    }
    rep {
      id
      initials
      full_name
      email
    }
    requested_date
    shipment_order {
      id
      blind
      deliver_by_date
      tracking_number
      status {
        id
        status
      }
    }
    status {
      id
      status
      status_type
    }
    tax { ...Money }
    terms {
      id
      name
    }
    estimated_total { ...Money }
    vendor_reference
  }
  ${FRAGMENT__MONEY}
`

export const FRAGMENT__PURCHASE_FOR_PDF = gql`
  fragment PurchaseForPdf on Purchases_Purchase {
    client {
      id
      name
      short_name
    }
    contact {
      id
      email
      phone
      full_name
    }
    id
    contract_comment
    pt_items {
      id
      line_number
      part {
        id
        pn
        description
        brand {
          id
          name
        }
        mfgr {
          id
          name
        }
      }
      original_cost { ...Money }
    }
    rep {
      id
      initials
      full_name
      email
    }
    shipment_order {
      id
      blind
      purchaser_address {
        id
        street_1
        street_2
        city
        state
        country {
          code
          name
        }
        zip_code
        company {
          name
        }
        client {
          id
          name
        }
        default_contact {
          id
          full_name
        }
      }
      ship_from_address {
        id
        street_1
        street_2
        city
        state
        country {
          code
          name
        }
        zip_code
        company {
          name
        }
        client {
          id
          name
        }
        default_contact {
          id
          full_name
        }
      }
      ship_to_address {
        id
        street_1
        street_2
        city
        state
        country {
          code
          name
        }
        zip_code
        client {
          id
          name
        }
        company {
          name
        }
        default_contact {
          id
          full_name
        }
      }
    }
    status {
      id
      status
    }
    total { ...Money }
    purchase_date
    terms {
      id
      name
    }
  }
  ${FRAGMENT__MONEY}
`

export const FRAGMENT__PURCHASED_ITEM = gql`
  fragment PurchasedItem on Purchases_PurchasedItems {
    current_cost { ...Money }
    edit_lock
    id
    item {
      ...InventoryItem
      sale: sales_items_details {
        id
      }
      purchase_rma_item_details {
        id
        rma {
          id
        }
      }
    }
    line_number
    original_cost { ...Money }
    part {
      ...BasicPart
    }
    shipment {
      id
    }
    swap_type
    updated_date
  }
  ${BasicPart}
  ${BasicItem}
  ${FRAGMENT__MONEY}
`

export const FRAGMENT__SALE_FOR_GRID = gql`
  fragment SaleForGrid on Sales_Sale {
    book_date
    client {
      id
      name
      short_name
    }
    client_order_number
    condition {
      id
      choice
      sub_choice
    }
    contact {
      id
      full_name
      email
      phone
    }
    contract_comment
    contract_send_by_date
    creation_date
    creator {
      id
      initials
    }
    estimated_cost { ...Money }
    estimated_margin { ...Money }
    id
    internal_comment
    freight { ...Money }
    promise_date
    quote {
      id
    }
    rep {
      id
      initials
      full_name
      email
    }
    sale_date
    ship_by_date
    shipment_order {
      id
      blind
      tracking_number
      pickup_type
      status {
        id
        status
      }
    }
    status {
      id
      status
      status_type
    }
    tax { ...Money }
    terms {
      id
      name
      notes
    }
    estimated_total { ...Money }
  }
  ${FRAGMENT__MONEY}
`

export const FRAGMENT__SALE = gql`
  fragment Sale on Sales_Sale {
    book_date
    client {
      id
      name
      short_name
    }
    client_order_number
    condition {
      id
      choice
      sub_choice
    }
    contact {
      id
      full_name
      first_name
      last_name
      email
      phone
    }
    contract_comment
    contract_send_by_date
    creation_date
    creator {
      id
      initials
    }
    estimated_cost { ...Money }
    estimated_margin { ...Money }
    id
    internal_comment
    freight { ...Money }
    promise_date
    quote {
      id
    }
    rep {
      id
      initials
      full_name
      email
    }
    sale_date
    ship_by_date
    shipment_order {
      id
      blind
      tracking_number
      pickup_type
      status {
        id
        status
      }
    }
    st_items {
      id
      item {
        id
      }
    }
    status {
      id
      status
      status_type
    }
    tax { ...Money }
    terms {
      id
      name
      notes
    }
    estimated_total { ...Money }
  }
  ${FRAGMENT__MONEY}
`

export const FRAGMENT_SALE_FOR_EDIT = gql`
  fragment SaleForEdit on Sales_Sale {
    book_date
    client { id name }
    client_order_number
    contact { id first_name last_name full_name email owner { id } }
    contract_comment
    contract_send_by_date
    creation_date
    creator { id initials full_name }
    declared_value { ...Money }
    deposit { ...Money }
    down_payment { ...Money }
    freight { ...Money }
    installation_cost { ...Money }
    id
    internal_comment
    misc { ...Money }
    promise_date
    quote { id }
    rep { id full_name initials }
    ship_by_date
    shipment_order {
      ...ShipmentOrderToEdit
    }
    tax { ...Money }
    terms { id name }
    we_install
    we_transport
  }
  ${FRAGMENT__SHIPMENT_ORDER_TO_EDIT}
  ${FRAGMENT__MONEY}
`

export const FRAGMENT__PURCHASE_FOR_EDIT = gql`
  fragment PurchaseForEdit on Purchases_Purchase {
    acceptance_date
    approval_date
    client { id name }
    client_order_number
    closing_date
    condition { id choice }
    contact { id first_name last_name full_name email owner { id } }
    contract_comment
    creation_date
    creator { id initials full_name }
    down_payment { ...Money }
    eta
    freight { ...Money }
    id
    internal_comment
    misc { ...Money }
    purchase_date
    quote { id }
    rep { id full_name initials }
    requested_date
    shipment_order { ...ShipmentOrderToEdit }
    tax { ...Money }
    terms { id name }
    vendor_reference
    warranty_end_date
    we_transport
  }
  ${FRAGMENT__MONEY}
  ${FRAGMENT__SHIPMENT_ORDER_TO_EDIT}
`

export const FRAGMENT__SOLD_ITEM = gql`
  fragment SoldItem on Sales_SoldItems {
    edit_lock
    id
    item {
      ...InventoryItem
      rma_item_details {
        id
        rma {
          id
        }
      }
      purchases_items_details {
        id
        current_cost { ...Money }
        line_number
        original_cost { ...Money }
        transaction {
          id
        }
      }
    }
    line_number
    original_part {
      ...BasicPart
    }
    part {
      ...BasicPart
    }
    shipment {
      id
    }
    status {
      id
      status
    }
    sold_for { ...Money }
    swap_type
    updated_date
  }
  ${BasicPart}
  ${BasicItem}
  ${FRAGMENT__MONEY}
`

export const FRAGMENT__SALE_FOR_PDF = gql`
  fragment SaleForPDF on Sales_Sale {
    client {
      id
      name
      short_name
    }
    client_order_number
    contact {
      id
      email
      phone
      full_name
    }
    id
    contract_comment
    st_items {
      id
      line_number
      part {
        id
        pn
        description
        brand {
          id
          name
        }
        mfgr {
          id
          name
        }
        type
      }
      sold_for { ...Money }
    }
    rep {
      id
      initials
      full_name
      email
    }
    shipment_order {
      id
      blind
      purchaser_contact { ...Contact_List }
      purchaser_address {
        id
        street_1
        street_2
        city
        state
        country {
          code
          name
        }
        zip_code
        company {
          name
        }
        client {
          id
          name
        }
        default_contact {
          id
          full_name
        }
      }
      ship_from_contact { ...Contact_List }
      ship_from_address {
        id
        street_1
        street_2
        city
        state
        country {
          code
          name
        }
        zip_code
        company {
          name
        }
        client {
          id
          name
        }
        default_contact {
          id
          full_name
        }
      }
      ship_to_contact { ...Contact_List }
      ship_to_address {
        id
        street_1
        street_2
        city
        state
        country {
          code
          name
        }
        zip_code
        client {
          id
          name
        }
        company {
          name
        }
        default_contact {
          id
          full_name
        }
      }
    }
    status {
      id
      status
    }
    estimated_total { ...Money }
    sale_date
    terms {
      id
      name
    }
  }
  ${FRAGMENT__CONTACT__LIST}
  ${FRAGMENT__MONEY}
`

export const FRAGMENT__SALE_FOR_DELETE = gql`
  fragment SaleForDelete on Sales_Sale {
    id
    st_items {
      id
      line_number
      item { id }
    }
    shipment_order {
      id
      shipments {
        id
      }
    }
  }
`

/* HELPERS */

export const SALE_ANNOTATIONS = [
  {
    name: 'unAllocated',
    aggr_type: 'COUNT',
    aggr_field: 'st_items',
    aggr_filters: [{ key: 'st_items__item__isnull', value: true }]
  },
  {
    name: 'itemCount',
    aggr_type: 'SUM',
    aggr_field: 'st_items',
    aggr_filters: [{ key: 'st_items__id__gt', value: 0 }]
  }
]

/* QUERIES */

export const GET_ORDER_PREP_INFO = gql`
  query OrderInfo {
    services: shipping_service_levels (input: { limit: 10000 }) {
      id
      easypost_name
      sub2title: order_arrive_by
      order_ready_by
      title: service
      shipper {
        id
        name
        easypost_name
      }
    }
    terms: clients_terms (input: { limit: 10000, order_by: "name" }) {
      id: id
      subtitle: description
      title: name
    }
    users: users_users (input: { limit: 10000, order_by: "first_name", excludes: [{ key: "first_name", value: "" }] }) {
      id: id
      subtitle: full_name
      title: initials
    }
  }
`

// sales

export const GET_SALE_FOR_EDIT = gql`
  query GetSaleForEdit ($id: ID!) {
    sales_sale(input: { id: $id }) {
      ...SaleForEdit
    }
  }
  ${FRAGMENT_SALE_FOR_EDIT}
`

export const GET_SALES_ORDER_FOR_DELETION = gql`
  query GetSalesForDelete ($id: ID!) {
    sales_sale (input: { id: $id }) {
      ...SaleForDelete
    }
  }
  ${FRAGMENT__SALE_FOR_DELETE}
`

export const GET_SOLD_ITEMS_FOR_CUSTOMS = gql`
  query CustomsItemsQuery ($id: Any!) {
    items: sales_sold_items (input: {filters: [{ key: "transaction__id", value: $id }]}) {
      id
      part {
        id
        pn
        description
        htsus_code
        hs_code
        type
      }
      sold_for { ...Money }
    }
  }
  ${FRAGMENT__MONEY}
`

export const GET_ORDERS_TO_PROCESS = gql`
  query SalesToProcess ($input: ManyQueryInput!) {
    orders: shipping_shipment_orders (input: $input) {
      annotations { name value }
      id
      attached_sale {
        id
        assigned_to {
          id
          initials
          profile { id color }
        }
        rep { id initials full_name email }
        status { id status }
      }
      attached_purchase_rma {
        id
        status { id status }
        pt {
          id
          rep { id initials }
        }
      }
      attached_rma {
        id
        status { id status }
        st {
          id
          rep { id initials }
        }
      }
      service {
        id
        color
        service
        shipper { id name }
      }
    }
  }
`

export const GRID__GET_SALES = (columns: string[]) => {
  return gql`
    query GRID__GetSales ($input: ManyQueryInput!) {
      sales: sales_sales (input: $input) {
        ${columns}
        contract_comment
        internal_comment
      }
    }
  `
}

export const GET_SALES = gql`
  query GetSales ($input: ManyQueryInput!) {
    sales: sales_sales (input: $input) {
      ...Sale
      annotations {
        name
        value
      }
    }
  }
  ${FRAGMENT__SALE}
`

export const GET_SOLD_ITEMS = gql`
  query SoldItems ($id: Any!) {
    items: sales_sold_items (input: { limit: 10000, filters: [{ key: "transaction__id", value: $id }] }) {
      ...SoldItem
    }
  }
  ${FRAGMENT__SOLD_ITEM}
`

export const SALES_DETAILS_FOR_RMA = gql`
  query SalesDetailsForRMA ($id: ID) {
    order: sales_sale (input: { id: $id }) {
      id
      client {
        id
        name
      }
    }
  }
`

export const SALES_DETAILS_FOR_COPY = gql`
  query SalesDetailsForCopy ($id: ID) {
    sale: sales_sale (input: { id: $id }) {
      id
      client {
        ...Client_List
      }
      contact {
        ...Contact_List
      }
      internal_comment
      contract_comment
      st_items {
        part {
          id
          description
          pn
        }
        sold_for { ...Money }
      }
      rep {
        ...ListUser
      }
      terms {
        id
      }
    }
  }
  ${FRAGMENT__CONTACT__LIST}
  ${FRAGMENT__CLIENT__LIST}
  ${FRAGMENT__LIST_USER}
  ${FRAGMENT__MONEY}
`

export const GET_SALE_FOR_PDF = gql`
  query GetSaleForPDF ($id: ID!) {
    sale: sales_sale (input: { id: $id }) {
      ...SaleForPDF
    }
  }
  ${FRAGMENT__SALE_FOR_PDF}
`

export const GET_PURCHASE_FOR_PDF = gql`
  query GetPurchaseForPdf ($id: ID!) {
    purchases_purchase (input: {id: $id}) {
      ...PurchaseForPdf
    }
  }
  ${FRAGMENT__PURCHASE_FOR_PDF}
`

export const FRAGMENT__SALE_FOR_MONEY = gql`
  fragment SaleForMoneyUpdate on Sales_Sale {
    id
    estimated_cost { ...Money }
    estimated_margin { ...Money }
    st_items {
      id
      sold_for { ...Money }
      item {
        id
        purchases_items_details {
          id
          original_cost { ...Money }
        }
      }
    }
  }
  ${FRAGMENT__MONEY}
`
export const SALES_DETAILS_FOR_MONEY_UPDATE = gql`
  query SalesDetailsForMoneyUpdate ($id: ID!) {
    sales_sale (input: { id: $id }) {
      ...SaleForMoneyUpdate
    }
  }
  ${FRAGMENT__SALE_FOR_MONEY}
`

export const GET_SALES_NEXT_LINE_NUMBER = gql`
  query GetSaleNextLineNumber ($id: ID!) {
    sale: sales_sale (input: { id: $id, annotations: [{
      name: "lineNumber",
      aggr_type: MAX,
      aggr_field: "st_items__line_number",
    }] }) {
      id
      annotations {
        name
        value
      }
    }
  }
`

export const GET_SALE = gql`
  query GetSale ($id: ID!, $annotations: [AnnotationObjectInput]) {
    sale: sales_sale (input: { id: $id, annotations: $annotations }) {
      ...Sale
      annotations {
        name
        value
      }
    }
  }
  ${FRAGMENT__SALE}
`

export const GET_SALE_DETAILS_TO_MAKE_SHIPMENT_ORDER = gql`
  query GetSaleDetailsToMakeShipmentOrder ($id: ID!) {
    order: sales_sale (input: { id: $id }) {
      id
      client {
        id
      }
    }
  }
`

export const GET_CLIENT_SALES = gql`
  query GetClientSALES ($input: ManyQueryInput!) {
    sales: sales_sales (input: $input) {
      id
      estimated_cost { ...Money }
      estimated_margin { ...Money }
      sale_date
      rep {
        id
        initials
      }
      estimated_total { ...Money }
    }
  }
  ${FRAGMENT__MONEY}
`

export const GET_ITEM_COUNT_FOR_SALE = gql`
  query GetItemCountForSale ($id: ID!) {
    count: sales_sale (input: { id: $id, annotations: [{ aggr_type: COUNT, aggr_field: "st_items", name: "itemCount" }]}) {
      id
      annotations { name value }
    }
  }
`

// purchases

export const GET_PURCHASE_FOR_EDIT = gql`
  query GetPurchaseForEdit ($id: ID!) {
    purchases_purchase(input: { id: $id }) {
      ...PurchaseForEdit
    }
  }
  ${FRAGMENT__PURCHASE_FOR_EDIT}
`

export const GRID__GET_PURCHASES = (columns: string[]) => {
  return gql`
    query GRID__GetPurchases ($input: ManyQueryInput!) {
      purchases: purchases_purchases (input: $input) {
        ${columns}
        internal_comment
        contract_comment
      }
    }
  `
}

export const GET_PURCHASES = gql`
  query GetPurchases ($input: ManyQueryInput!) {
    purchases: purchases_purchases (input: $input) {
      ...Purchase
      annotations { name value }
    }
  }
  ${FRAGMENT__PURCHASE}
`

export const GET_PURCHASE = gql`
  query GetPurchase ($id: ID!) {
    purchases_purchase (input: { id: $id }) {
      ...Purchase
      pt_items {
        id
        line_number
      }
    }
  }
  ${FRAGMENT__PURCHASE}
`

export const GET_PURCHASE_DETAILS_TO_MAKE_SHIPMENT_ORDER = gql`
  query GetPurchseDetailsToMakeShipmentOrder ($id: ID!) {
    order: purchases_purchase (input: { id: $id }) {
      id
      client {
        id
      }
    }
  }
`

export const GET_PURCHASED_ITEMS = gql`
  query PurchaseItems ($id: Any!) {
    items: purchases_purchased_items (input: { limit: 10000, filters: [{ key: "transaction__id", value: $id }]}) {
      ...PurchasedItem
    }
  }
  ${FRAGMENT__PURCHASED_ITEM}
`

export const GET_PURCHASE_ITEMS_RECEIVE_STATUS = gql`
  query GetPurchaseItemsStatus ($id: Any!) {
    items: inventory_items (input: { limit: 100000, filters: [{ key: "purchases_items_details__transaction__id", value: $id }] })
    {
        id
        receive_status
    }
  }
`

export const PURCHASED_ITEMS_TO_RECEIVE = gql`
  query PurchaseItemsToReceive ($input: ManyQueryInput!) {
    items: purchases_purchased_items (input: $input) {
      id
      line_number
      transaction {
        id
      }
      original_cost { ...Money }
      item {
        id
        broke_down_items { id break_down { id } }
        serial_number
        receive_status
        location {
          id
          name
          site {
            id
            name
          }
        }
        saleItem: sales_items_details {
          id
          transaction {
            id
          }
        }
        locked
        hot
        is_lost
        country_of_origin {
          code
          name
          flag: unicode_flag
        }
        test_status {
          id
          tag
          color
        }
        part {
          id
          pn
          description
        }
      }
    }
  }
  ${FRAGMENT__MONEY}
`

export const PURCHASE_DETAILS_FOR_RMA = gql`
  query PurchaseDetailsForRMA ($id: ID) {
    order: purchases_purchase (input: { id: $id }) {
      id
      client {
        id
        name
      }
    }
  }
`

export const GET_CLIENT_PURCHASES = gql`
  query GetClientPurchases ($input: ManyQueryInput!) {
    purchases: purchases_purchases (input: $input) {
      id
      purchase_date
      rep {
        id
        initials
      }
      estimated_total { ...Money }
    }
  }
  ${FRAGMENT__MONEY}
`

export const VALIDATE_PT = gql`
  query ValidatePt ($id: ID!) {
    purchase: purchases_purchase (input: { id: $id }) {
      id
    }
  }
`

/* MUTATIONS */

export const CREATE_PURCHASE = gql`
  mutation CreatePurchase ($input: Create__Purchases_Purchase__Input!) {
    order: Create__Purchases_Purchase (input: $input) {
      id
    }
  }
`

export const UPDATE_PURCHASE = gql`
  mutation UpdatePurchase ($input: Update__Purchases_Purchase__Input!) {
    Update__Purchases_Purchase(input: $input) {
      ...Purchase
    }
  }
  ${FRAGMENT__PURCHASE}
`

export const APPROVE_PURCHASE = gql`
  mutation ApprovePurchase ($id: ID!) {
    purchase: ApprovePurchase(id: $id) {
      id
      status {
        id
        status
      }
      approval_date
      approved_by {
        id
        initials
      }
    }
  }
`

export const UNAPPROVE_PURCHASE = gql`
  mutation UnApprovePurchase ($id: ID!) {
    purchase: UnapprovePurchase(id: $id) {
      id
      status {
        id
        status
      }
      approval_date
      approved_by {
        id
        initials
      }
    }
  }
`

export const UPDATE_PURCHASE_ORDER__COMMENT_ALIAS = gql`
  mutation UpdatePurchaseOrderForComment ($input: Update__Purchases_Purchase__Input!) {
    comment: Update__Purchases_Purchase (input: $input) {
      ...Purchase
    }
  }
  ${FRAGMENT__PURCHASE}
`

export const UPDATE_SALES_ORDER__COMMENT_ALIAS = gql`
  mutation UpdateSalesOrder ($input: Update__Sales_Sale__Input!) {
    comment: Update__Sales_Sale (input: $input) {
      ...Sale
    }
  }
  ${FRAGMENT__SALE}
`

export const UPDATE_PURCHASE_ORDER_STATUS = gql`
  mutation UpdatePurchaseOrderStatus ($id: ID!, $status: ID!) {
    purchase: Update__Purchases_Purchase (input: {id: $id, status_id: $status }) {
      id
      status {
        id
        status
        status_type
      }
    }
  }
`

export const DELETE_PURCHASED_ITEM = gql`
  mutation DeletePurchasedItem ($id: ID!) {
    Delete__Purchases_PurchasedItems (id: $id) {
      id
    }
  }
`

export const DELETE_SOLD_ITEM = gql`
  mutation DeleteSoldItem ($id: ID!) {
    Delete__Sales_SoldItems (id: $id) {
      id
    }
  }
`

export const CREATE_SOLD_ITEMS = gql`
  mutation CreateSoldItems ($items: [Create__Sales_SoldItems__Input!]!) {
    items: Create__Sales_SoldItems (input: $items) {
      ...SoldItem
    }
  }
  ${FRAGMENT__SOLD_ITEM}
`

export const CREATE_SALE = gql`
  mutation CreateSale ($input: Create__Sales_Sale__Input!) {
    Create__Sales_Sale (input: $input) {
      ...Sale
    }
  }
  ${FRAGMENT__SALE}
`

// will make fragment for this #later
export const UPDATE_SALE = gql`
  mutation UpdateSale ($input: Update__Sales_Sale__Input!) {
    order: Update__Sales_Sale (input: $input) {
      ...Sale
    }
  }
  ${FRAGMENT__SALE}
`

export const UPDATE_SALE_MONEY = gql`
  mutation UpdateSaleMoney ($input: Update__Sales_Sale__Input!) {
    order: Update__Sales_Sale (input: $input) {
      id
      estimated_margin { ...Money }
      estimated_cost { ...Money }
    }
  }
  ${FRAGMENT__MONEY}
`

export const DELETE_SALE = gql`
  mutation DeleteSale ($id: ID!) {
    Delete__Sales_Sale (id: $id) { id }
  }
`

export const UPDATE_SOLD_ITEMS = gql`
  mutation AllocateSoldItems ($input: [Update__Sales_SoldItems__Input!]!) {
    items: Update__Sales_SoldItems (input: $input) {
      ...SoldItem
    }
  }
  ${FRAGMENT__SOLD_ITEM}
`

export const CREATE_PURCHASED_ITEMS = gql`
  mutation CreatePurchasedItem ($items: [Create__Purchases_PurchasedItems__Input!]!) {
    items: Create__Purchases_PurchasedItems (input: $items) {
      ...PurchasedItem
    }
  }
  ${FRAGMENT__PURCHASED_ITEM}
`

export const UPDATE_PURCHASED_ITEMS = gql`
  mutation UpdatePurchasedItems ($input: [Update__Purchases_PurchasedItems__Input!]!) {
    Update__Purchases_PurchasedItems (input: $input) {
      ...PurchasedItem
    }
  }
  ${FRAGMENT__PURCHASED_ITEM}
`

interface IdInput {
  id: string | number
}

export function getUpdateSaleMoneyData (id: string | number): Promise<Update__Sales_Sale__Input | undefined> {
  return new Promise((resolve, reject) => {
    apollo.query<SalesDetailsForMoneyUpdateQuery, IdInput>({
      query: SALES_DETAILS_FOR_MONEY_UPDATE,
      variables: { id: id }
    })
      .then((response) => {
        if (response.data.sales_sale) {
          resolve(CalculateMoneyPostSaleUpdate(response.data.sales_sale))
        } else {
          reject(new Error('Sale was not returned'))
        }
      })
  })
}

export async function CheckClientPO (orderNumber: string, clientId: string): Promise<string> {
  if (orderNumber && clientId) {
    const response = await apollo.query({
      query: GET_SALES,
      variables: { input: { filters: [{ key: 'client_order_number', value: orderNumber.toUpperCase() }, { key: 'client__id', value: clientId }] } }
    })
    const sales = response.data?.sales ?? []
    if (sales.length > 0) {
      const sale = sales[0]
      return `Order: ${sale.id} already has Client PO: ${orderNumber}`
    } else {
      return ''
    }
  } else if (orderNumber === '') {
    return ''
  }
  return ''
}

export async function CreateSaleOrder (input: Create__Sales_Sale__Input): Promise<Sales_Sale | undefined> {
  try {
    const mutation: MutationOptions<Mutation, MutationCreate__Sales_SaleArgs> = {
      mutation: CREATE_SALE,
      variables: { input: input }
    }
    const response = await apollo.mutate<Mutation, MutationCreate__Sales_SaleArgs>(mutation)
    if (response.data?.Create__Sales_Sale) return response.data.Create__Sales_Sale
  } catch (error) {
    throw new Error(error.message)
  }
}

export async function UpdateSaleOrder (input: Update__Sales_Sale__Input): Promise<SaleFragment | undefined> {
  try {
    const mutation: MutationOptions<UpdateSaleMutation, UpdateSaleMutationVariables> = {
      mutation: UPDATE_SALE,
      variables: { input: input }
    }
    const response = await apollo.mutate<UpdateSaleMutation, UpdateSaleMutationVariables>(mutation)
    if (response.data?.order) return response.data.order
  } catch (error) {
    throw new Error(error.message)
  }
}

export async function CreatePurchaseOrder (input: Create__Purchases_Purchase__Input): Promise<string | undefined> {
  try {
    const response = await apollo.mutate<CreatePurchaseMutation, CreatePurchaseMutationVariables>({
      mutation: CREATE_PURCHASE,
      variables: {
        input: input
      }
    })
    return response.data?.order?.id ?? undefined
  } catch (e) {
    throw new Error(e.message)
  }
}

export async function UpdatePurchaseOrder (input: Update__Sales_Sale__Input): Promise<PurchaseFragment | undefined> {
  try {
    const mutation: MutationOptions<UpdatePurchaseMutation, UpdatePurchaseMutationVariables> = {
      mutation: UPDATE_PURCHASE,
      variables: { input: input }
    }
    const response = await apollo.mutate<UpdatePurchaseMutation, UpdatePurchaseMutationVariables>(mutation)
    if (response.data?.Update__Purchases_Purchase) return response.data.Update__Purchases_Purchase
  } catch (error) {
    throw new Error(error.message)
  }
}

/**
 * Creates sale order items and updates sale money info.
 * @param saleId the sale of the id, required for updating sale money
 * @param input the items to create
 */
export async function CreateSaleOrderItems (saleId: string, input: Create__Sales_SoldItems__Input[]): Promise<void> {
  try {
    const mutation: MutationOptions<CreateSoldItemsMutation, CreateSoldItemsMutationVariables> = {
      mutation: CREATE_SOLD_ITEMS,
      variables: { items: input }
    }
    if (input.length > 0) await apollo.mutate<CreateSoldItemsMutation, CreateSoldItemsMutationVariables>(mutation)
    if (saleId) {
      const updater = await getUpdateSaleMoneyData(saleId)
      if (updater?.id) {
        const mutation: MutationOptions<UpdateSaleMoneyMutation, UpdateSaleMoneyMutationVariables> = {
          mutation: UPDATE_SALE_MONEY,
          variables: { input: updater }
        }
        await apollo.mutate(mutation)
      }
    }
  } catch (e) {
    throw new Error(e.message)
  }
}

export async function CreatePurchaseOrderItems (
  purchaseId: string,
  input: Create__Purchases_PurchasedItems__Input[]): Promise<void> {
  try {
    await apollo.mutate<CreatePurchasedItemMutation, CreatePurchasedItemMutationVariables>({
      mutation: CREATE_PURCHASED_ITEMS,
      variables: {
        items: input
      }
    })
  } catch (e) {
    throw new Error(e.message)
  }
}
