import router from '@/router/router'

export function PushWithDynamicQuery ({ name, query }) {
  if (router.currentRoute.name !== name || query.value !== router?.currentRoute?.query?.value) {
    router.push({ name: name, query: query })
  }
}

export function PushWithSingleFieldQuery ({ name, field, value, type }) {
  if (router.currentRoute.name !== name || value !== router?.currentRoute?.query?.value) {
    router.push({ name: name, query: { field: field, type: type, value: `${value}` } })
  }
}

export function ViewPurchaseTransaction ({ id }) {
  PushWithSingleFieldQuery({ name: 'purchaseOrders', field: 'pt', type: 'equals', value: id })
}

export function ViewSaleTransaction ({ id }) {
  PushWithSingleFieldQuery({ name: 'saleOrders', field: 'st', type: 'equals', value: id })
}

export function ViewSaleRma ({ id }) {
  PushWithSingleFieldQuery({ name: 'srma', field: 'rma', type: 'equals', value: id })
}

export function ViewPurchaseRma ({ id }) {
  PushWithSingleFieldQuery({ name: 'prma', field: 'rma', type: 'equals', value: id })
}

export function ViewSystem ({ id }) {
  PushWithSingleFieldQuery({ name: 'system', field: 'id', type: 'equals', value: id })
}
