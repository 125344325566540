<template>
    <v-autocomplete
      v-model="config.id"
      :items="items"
      :loading="loading"
      :filter="filter"
      :label="config.label"
      :rules="config.rules"
      :placeholder="config.placeholder"
      :disabled="config.disabled ? config.disabled : false"
      item-text="title"
      item-value="id"
    >
      <template
        slot="item"
        slot-scope="data"
      >
        <v-list-tile-content>
          <v-list-tile-title>{{ data.item.title }}</v-list-tile-title>
          <v-list-tile-sub-title>{{ data.item.subtitle }} {{ data.item.sub2title ? '    |    ' + data.item.sub2title : '' }}
            {{ data.item.sub3title ? '     |     ' + data.item.sub3title : '' }}
          </v-list-tile-sub-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
</template>

<script>
export default {
  name: 'standard',
  props: {
    config: {
      type: Object,
      required: true,
      validator: function (props) {
        let valid = true
        if (!props.label) {
          valid = false
        } else if (props.id === undefined) {
          valid = false
        } else if (props.filter) {
        } else if (!props.rules) {
          valid = false
        }
        return valid
      }
    }
  },
  computed: {
    items: function () {
      if (typeof this.config.list === typeof []) {
        return this.config.list
      } else {
        return this.$store.state.data.autofillLists[this.config.list]
      }
    },

    loading: function () {
      if (this.config.loading) {
        return this.config.loading
      } else {
        return this.$store.state.data.loading[this.config.list]
      }
    }
  },
  watch: {
    items: function (value) {
      if (value) {
        if (value.length === 1) {
          this.config.id = value[0].id
        }
      }
    }
  },
  methods: {
    filter (item, queryText) {
      const title = item.title.toLowerCase()
      const subtitle = item.subtitle.toLowerCase()
      const search = queryText.toLowerCase()

      return title.indexOf(search) > -1 || subtitle.indexOf(search) > -1
    }
  }
}
</script>
