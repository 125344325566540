<template>
    <v-card>
      <v-card-title
        class="headline"
        primary-title
      >
        <v-layout justify-end align-center fill-height>
          <v-layout column>
            <v-flex>
              Allocate {{ part.pn }}
            </v-flex>
          </v-layout>
          <v-spacer/>
          <v-layout column>
            <v-flex>
              <v-text-field
                v-model="allocationSearch"
                label="Search local results"
                persistent-hint
                :hint="searchHint"
                single-line
                :disabled="submitted"
                @keyup.enter="serverSearch"
              >
                <template v-slot:message="{ message, key }">
                  <span :key="key" v-html="message"/>
                </template>
                <template
                  v-slot:append
                >
                  <v-tooltip
                    bottom
                  >
                    <template
                      v-slot:activator="{ on }"
                    >
                      <v-icon
                        v-on="on"
                        @click="serverSearch"
                      >search
                      </v-icon>
                    </template>
                    Click here to search the server!
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-flex>
            <v-flex class="text-xs-right" >
              <v-switch
                v-model="searchWithRep"
                color="info"
                :label="`Search only ST REP's parts`"
                hide-details
              >
                Any REP
              </v-switch>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-data-table
          v-model="selected"
          ref="allocationTable"
          :headers="headers"
          :items="unAllocatedParts"
          :select-all="quantityEqualsCount"
          :pagination.sync="pagination"
          item-key="ptId"
          :rows-per-page-items="[5,10,15]"
          :loading="loading"
          :search="allocationSearch"
          :custom-sort="customSort"
        >
          <template v-slot:no-results>
            <v-alert color="orange darken-3" :value="true" icon="search" @click="serverSearch" class="no-results-alert">
              <strong>Not finding what you are looking for? Click here to search on the server!</strong>
            </v-alert>
          </template>
          <template v-slot:no-data>
            <v-alert :value="true" color="info" icon="sync" v-if="loading">
              Loading your data, sit tight!
            </v-alert>
            <v-alert color="error" :value="true" icon="error" v-else-if="!unSearched" @click="searchWithRep ? searchWithRep = false : ''">
              <span v-if="!searchWithRep">You need to order more {{ part.pn }}'s!</span>
              <span v-else>Couldn't find any parts under this rep. Click here to search without a rep.</span>
            </v-alert>
            <!-- <v-alert color="error" :value="true" icon="error" v-else>
              Oops! It appears an error has occurred. Please close out and try again.
            </v-alert> -->
          </template>
          <template v-slot:items="props">
            <tr
              :active="props.selected"
              :key="props.item.ptId"
              @click="maybeSelect(props)"
              @click.shift="shiftSelect(props)"
              :style="userSelectStyle"
            >
              <td v-if="!quantityEqualsCount">
                <v-layout row nowrap align-center
                >
                  <v-checkbox
                    v-model="props.selected"
                    hide-details
                    color="info"
                    :disabled="disabledCheckbox(props.item)"
                    @click.stop="props.selected = !props.selected"
                  >
                  </v-checkbox>
                  <span>{{ props.item.part.pn }}</span>
                </v-layout>
              </td>
              <td v-else>
                <v-checkbox
                  v-model="props.selected"
                  hide-details
                  color="info"
                  :disabled="disabledCheckbox(props.item)"
                  @click.stop="props.selected = !props.selected"
                >
                </v-checkbox>
              </td>
              <td v-if="quantityEqualsCount">{{ props.item.part.pn }}</td>
              <td class="scaley-font"> {{ props.item.serial_number }} </td>
              <td class="scaley-font"> {{ props.item.purchases_items_details.transaction.id + '-' + props.item.purchases_items_details.line_number }} </td>
              <td> {{ props.item.purchases_items_details.transaction.rep.initials }} </td>
              <td> {{ formatDate(props.item.received_date) }} </td>
              <td class="text-xs-right" @click.stop>
                <v-edit-dialog
                  :return-value.sync="props.item.split"
                  lazy
                  transition="scale-transition"
                  large
                  persistent
                >
                  <v-layout row justify-start>
                    <v-flex>
                      {{ props.item.split }}
                    </v-flex>
                    <v-flex ml-2>
                      %
                    </v-flex>
                  </v-layout>
                  <v-icon small class="ml-4">fal fa-pencil</v-icon>
                  <template v-slot:input>
                    <v-text-field
                      v-model.number="props.item.split"
                      :rules="splitMarginRules"
                      label="Enter margin %"
                      mask="NNN"
                      suffix="%"
                      single-line
                      autofocus
                      lazy-validation
                    />
                  </template>
                </v-edit-dialog>
              </td>
              <td> {{ props.item.purchases_items_details.current_cost.pretty ? props.item.purchases_items_details.current_cost.pretty : '0.00' }} </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-container grid-list-xl fluid py-0>
          <v-layout align-center justify-end>
            <v-flex xs2>
              <v-text-field
                v-model="marginToApply"
                label="Margin"
                mask="NNN"
                box
                suffix="%"
                hide-details
              />
            </v-flex>
            <v-flex>
              <v-btn
                :disabled="selected.length === 0"
                @click="applyMargin"
              >Apply TO Selected</v-btn>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex shrink pr-4>
              <v-layout row justify-start>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-if="selected.length === 0"
                      v-on="on"
                      color="info"
                      :disabled="allocationCountMet || submitted || $apollo.loading"
                      data-cy="auto-allocate-button"
                      @click="autoAllocate"
                    >
                      Auto Allocate
                    </v-btn>
                    <v-btn
                      v-else
                      v-on="on"
                      color="warning"
                      @click="selected = []"
                    >Reset Allocations</v-btn>
                  </template>
                  <span v-if="selected.length === 0">Allocate <strong>selling rep's</strong> parts based on FIFO, if available.</span>
                  <span v-else>Click here to reset your allocations!</span>
                </v-tooltip>
              </v-layout>
            </v-flex>
            <slot name="actions" v-bind:save="save" v-bind:parts="selected">
              <v-btn
                color="error"
                @click="cancel"
              >Cancel</v-btn>
              <v-btn
                color="success"
                :disabled="selected.length === 0 || submitted"
                data-cy="allocate-submit-button"
                @click="save"
              >Submit</v-btn>
            </slot>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>
</template>

<script>
import dateFormatter from '../mixins/dateFormatter'
import moment from 'moment'
import { GET_UNALLOCATED_ITEMS } from '@/api/graphql/Constants/Inventory'

export default {
  name: 'allocation',
  mixins: [dateFormatter],
  props: {
    part: {
      required: true,
      type: Object,
      validate: function (prop) {
        return prop.quantity && prop.pn
      }
    },
    repId: {
      type: String,
      required: true
    },
    allocatedParts: {
      required: true,
      type: Array
    }
  },
  computed: {
    user () {
      return this.$store.state.profile.user
    },

    userSelectStyle () {
      return { 'user-select': (this.shiftIsPressed ? 'none' : 'text') }
    },

    searchedByRepIndex () {
      return this.originalFilters.findIndex(f => {
        return f.key.includes('rep__id')
      })
    },

    searchedByRep () {
      return this.searchedByRepIndex > -1
    },

    allocationCountMet () {
      return this.selected.length === Number(this.part.quantity)
    },

    loading () {
      return this.$apollo.loading
    },

    quantityEqualsCount () {
      try {
        return (this.unAllocatedParts.length || this.paginateChildRows.rowsPerPage) === Number(this.part.quantity)
      } catch {
        return false
      }
    },

    originalFilters () {
      return [
        {
          key: 'part__id',
          value: this.part.id ?? ''
        },
        {
          key: 'sales_items_details__isnull',
          value: true
        }
      ]
    }
  },
  watch: {
    unAllocatedParts: function (value) {
      if (value !== undefined) this.selectAllocated()
    }
  },
  data () {
    return {
      shiftIsPressed: false, // used for handling shift key presses
      allocationSearch: '',
      closing: false,
      searchWithRep: true,
      marginToApply: '',
      searchHint: 'Press <span class="emphasis">Enter</span> to search database',
      unAllocatedParts: [],
      bankedParts: [],
      submitted: false,
      unSearched: true,
      pagination: {},
      headers: [
        { text: 'Part Number', value: 'part.pn', sortable: false },
        { text: 'Serial Number', value: 'serial_number', sortable: false },
        { text: 'PT', value: 'ptId' },
        { text: 'REP', value: 'purchase.transaction.rep.initials' },
        { text: 'Receive Date', value: 'received_date' },
        { text: 'Seller Margin %', value: 'split' },
        { text: 'Cost', value: 'purchase.current_cost.amount' }
      ],
      show: true,
      selected: [],
      filters: [],

      splitMarginRules: [
        e => e <= 100 || 'Must be less than 101',
        e => e >= 0 || 'Must be greater than -1'
      ]
    }
  },
  apollo: {
    unAllocatedParts: {
      query: GET_UNALLOCATED_ITEMS,
      variables () {
        let filters = [];
        (this.searchWithRep && this.repId) && filters.push(
          {
            key: 'purchases_items_details__transaction__rep__id',
            value: this.repId
          }
        )
        filters = filters.concat(this.originalFilters.concat(this.filters))
        return {
          filters: filters,
          order: ['purchases_items_details__transaction']
        }
      },
      skip () {
        return this.part.id === undefined
      },
      update: function (data) {
        if (data.unAllocatedParts) {
          this.unSearched = false
          for (const part of this.bankedParts) {
            data.unAllocatedParts.push(part)
          }
          return data.unAllocatedParts.filter(item => {
            for (const part of this.allocatedParts) {
              if (part.item === item.id) {
                return false
              }
            }
            return item.purchases_items_details
          })
            .map(item => {
              item.ptId = `${item.purchases_items_details?.transaction?.id}-${item.purchases_items_details?.line_number}`
              item.split = 50
              return item
            })
        }
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  },
  methods: {
    cancel () {
      this.$emit('close')
      this.$store.dispatch('notifications/resetAlert')
      this.closing = true
      this.unAllocatedParts = []
      this.selected = []
    },

    save () {
      const allocatedParts = []

      for (const item of this.selected) {
        const pt = item.purchases_items_details.transaction.id + '-' + item.purchases_items_details.line_number
        const itemToAdd = {
          pt: pt,
          item: item.id,
          part: item.part.id,
          cost: item.purchases_items_details.current_cost.amount || 0,
          split: item.split
        }
        allocatedParts.push(itemToAdd)
      }
      this.$emit('save', allocatedParts)
      this.submitted = true
    },

    shiftSelect (props) {
      if (this.selected.length >= 2) {
        const items = this.$refs.allocationTable.filteredItems
        let index = items.findIndex(i => i.id === this.selected[this.selected.length - 2].id)
        // if shift selecting down the table
        if (index > props.index && !this.allocationCountMet) {
          while (index !== props.index) {
            const newIndex = this.selected.findIndex(s => s.id === items[index].id)
            const alreadyExists = newIndex > -1
            !alreadyExists && this.selected.push(items[index])
            index--
          }
        } else if (index < props.index && !this.allocationCountMet) {
          // if shift selecting up the table
          while (index !== props.index) {
            const newIndex = this.selected.findIndex(s => s.id === items[index].id)
            const alreadyExists = newIndex > -1
            !alreadyExists && this.selected.push(items[index])
            index++
          }
        }
      }
    },

    removeRepId () {
      this.originalFilters.splice(this.searchedByRepIndex, 1)
      this.bankedParts = this.selected
      this.$apollo.queries.unAllocatedParts.refetch()
    },

    goToPurchases () {
      this.cancel()
      this.$router.push({ name: 'purchaseOrders' })
    },

    disabledCheckbox (item) {
      return (this.allocationCountMet && !this.selected.includes(item)) || this.submitted
    },

    autoAllocate () {
      const list = this.sortByPurchaseLineItem(this.unAllocatedParts, false)
      let count = this.selected.length
      for (const i of list) {
        if (this.selected.map(s => s.ptId).indexOf(i.ptId) === -1) {
          this.selected.push(i)
          count++
        }
        if (`${count}` === `${this.part.quantity}`) {
          break
        }
      }
    },

    applyMargin () {
      for (const item of this.selected) {
        item.split = this.marginToApply
      }
    },

    /**
     * A custom sorting method for the data table
     * @param {Array} items the items to be sorted
     * @param {String} column the column to sort with
     * @param {Boolean} desc whether or not it is descending
     * @return {Array} items
     */
    customSort (items, column, desc) {
      if (column === 'ptId') {
        return this.sortByPurchaseLineItem(items, desc)
      } else if (column === 'purchase.transaction.rep.initials') {
        return this.sortByRep(items, desc)
      } else if (column === 'purchase.current_cost.amount') {
        return this.sortByCost(items, desc)
      } else if (column === 'received_date') {
        return this.sortByDate(items, desc)
      } else {
        return items
      }
    },

    sortByDate (items, desc) {
      if (!desc) {
        return items.sort((a, b) => {
          const dateA = a.received_date
          const dateB = a.received_date
          if (moment(dateA).isAfter(dateB)) {
            return 1
          } else if (moment(dateB).isAfter(dateA)) {
            return -1
          } else {
            return 0
          }
        })
      } else {
        return items.sort((a, b) => {
          const dateA = a.received_date
          const dateB = a.received_date
          if (moment(dateA).isAfter(dateB)) {
            return -1
          } else if (moment(dateB).isAfter(dateA)) {
            return 1
          } else {
            return 0
          }
        })
      }
    },

    /**
     * Helper method for sortBy
     * @param {Array} items
     * @param {Boolean} desc
     * @return {Array} items sorted
     */
    sortByCost (items, desc) {
      if (!desc) {
        return items.sort((a, b) => {
          const costA = parseInt(a.purchase.current_cost.amount)
          const costB = parseInt(b.purchase.current_cost.amount)
          return costA - costB
        })
      } else {
        return items.sort((a, b) => {
          const costA = parseInt(a.purchase.current_cost.amount)
          const costB = parseInt(b.purchase.current_cost.amount)
          return costB - costA
        })
      }
    },

    /**
     * Helper method for customSort
     * @param {Array} items
     * @param {Boolean} desc
     */
    sortByRep (items, desc) {
      if (!desc) {
        return items.sort((a, b) => {
          const repA = a.purchase.transaction.rep.initials
          const repB = b.purchase.transaction.rep.initials
          if (repA > repB) return 1
          if (repA < repB) return -1
          return 0
        })
      } else {
        return items.sort((a, b) => {
          const repA = a.purchase.transaction.rep.initials
          const repB = b.purchase.transaction.rep.initials
          if (repA > repB) return -1
          if (repA < repB) return 1
          return 0
        })
      }
    },

    /**
     * Helper function for customSort
     * @param {Array<Object>} items
     * @param {Boolean} desc
     * return {Array} items
     */
    sortByPurchaseLineItem (items, desc) {
      if (!desc) {
        return items.sort((a, b) => {
          const aSplit = a.ptId.split('-')
          const bSplit = b.ptId.split('-')
          return this.sortTwoPtIds(aSplit, bSplit)
        })
      } else {
        return items.sort((a, b) => {
          const aSplit = a.ptId.split('-')
          const bSplit = b.ptId.split('-')
          return this.sortTwoPtIds(bSplit, aSplit)
        })
      }
    },

    sortTwoPtIds (a, b) {
      const aTransaction = parseInt(a[0])
      const aLine = parseInt(a[1])
      const bTransaction = parseInt(b[0])
      const bLine = parseInt(b[1])
      if (aTransaction === bTransaction) {
        if (aLine > bLine) return 1
        return -1
      } else {
        if (aTransaction > bTransaction) return 1
        return -1
      }
    },

    maybeSelect (props) {
      if (props.selected && !this.submitted) {
        props.selected = false
      } else {
        if (!this.allocationCountMet && !this.submitted) {
          props.selected = true
        }
      }
    },

    selectAllocated () {
      for (const allocated of this.allocatedParts) {
        for (const part of this.unAllocatedParts) {
          if (allocated.item === part.id) {
            this.selected.push(part)
          }
        }
      }
    },

    serverSearch () {
      // three things to search on: rep, serial number, and pt
      this.unAllocatedParts = []
      this.filters.length = 0
      let key = ''
      let value = ''
      let filterPrep = []
      const ptRegex = /\b(\d*[^a-z])\b/

      // check if search term is initials
      if (ptRegex.test(this.allocationSearch)) {
        // check if pt number
        if (this.allocationSearch.indexOf('-') > -1) {
          filterPrep = this.allocationSearch.split('-')
          key = 'purchases_items_details__transaction__id'
          value = filterPrep[0]
          this.filters.push({ key: key, value: value })
          key = 'purchases_items_details__line_number'
          value = filterPrep[1]
          this.filters.push({ key: key, value: value })
        } else {
          key = 'purchases_items_details__transaction__id'
          value = this.allocationSearch
          this.filters.push({ key: key, value: value })
        }
      } else if (this.allocationSearch !== '') {
        // is most likely serial
        key = 'serial_number__icontains'
        const key2 = 'purchases_items_details__transaction__rep__initials'
        value = this.allocationSearch.toLocaleUpperCase()
        this.filters.push({ key: key, value: value, or: { key: key2, value: value } })
        this.filters.push({ key: key, value: value.toLocaleUpperCase() })
      } else if (this.allocationSearch === '') {
        this.$apollo.queries.unAllocatedParts.refresh()
      }
    },

    keyupHandler (e) {
      if (e.key === 'Shift') {
        this.shiftIsPressed = false
      }
    },

    keydownHandler (e) {
      if (e.key === 'Shift') {
        this.shiftIsPressed = true
      }
    }
  },
  beforeMount () {
    this.selected = []
  },
  mounted () {
    window.addEventListener('keyup', this.keyupHandler)
    window.addEventListener('keydown', this.keydownHandler)
  },
  beforeDestroy () {
    window.removeEventListener('keyup', this.keyupHandler)
    window.removeEventListener('keydown', this.keydownHandler)
  }
}
</script>

<style>
  .no-results-alert {
    cursor: pointer;
  }

  .v-alert.error {
    cursor: pointer;
  }

  .scaley-font {
    font-size: clamp(9px, 1vw, 13px) !important;
  }
</style>
