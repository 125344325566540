import { QueryFilter } from '@/models/generated/graphql/ErpBackend'
import { Dictionary } from 'vue-router/types/router'

export interface RouterQueryParamsForBackend extends Dictionary<any> {
  filters?: QueryFilter[],
  id?: string
}

export enum PURCHASE_ORDER_STATUS {
  'CREATED' = 1,
  'TO_APPROVE' = 2,
  'APPROVED' = 3,
  'TO_RECEIVE' = 4,
  'PARTIALLY_RECEIVED' = 5,
  'RECEIVED' = 6
}

export enum SALES_ORDER_STATUS {
  'CREATED' = '7',
  'TO_PICK' = '8',
  'PICKING' = '9',
  'WAITING_FOR_TESTING' = '10',
  'BROUGHT_TO_SHIPPING' = '11',
  'BOOKED' = '86'
}

export enum SALES_ITEM_STATUS {
  'TO_ALLOCATE' = '18',
  'ALLOCATED' = '85',
  'TO_PICK' = '19',
  'PICKING' = '20',
  'WAITING_FOR_TESTING' = '21',
  'TESTING' = '22',
  'TESTED' = '23',
  'PICKED' = '24',
  'BROUGHT_TO_SHIPPING' = '25',
  'TO_PACK' = '26',
  'PACKING' = '27',
  'AWAITING_CARRIER' = '29',
  'PICKED_UP_BY_CARRIER' = '30'
}

export enum PURCHASE_RMA_STATUS {
  CREATED = 48,
  TO_CLAIM,
  CLAIMED_TO_VENDOR,
  WAITING_FOR_VENDOR_RESPONSE,
  VENDOR_RESPONDED,
  TO_SHIP,
  SHIPPED,
  CLOSED
}

export enum SALES_RMA_STATUS {
  CREATED = 37,
  TO_RECEIVE,
  RECEIVED,
  TO_VERIFY,
  VERIFIED,
  TO_TEST,
  TESTING,
  TESTED,
  TO_SHIP,
  SHIPPED,
  CLOSED
}

export enum SHIPMENT_ORDER_STATUS {
  CREATED = '56',
  TO_PACK = '57',
  PACKING = '58',
  TO_PROCESS = '59',
  PROCESSING = '60',
  PROCESSED = '61',
  AWAITING_CARRIER = '62',
  PICKED_UP_BY_CARRIER = '63'
}

export enum BREAKDOWN_STATUS {
  CREATED = '64',
  TO_BREAK_DOWN = '65',
  BREAKING_DOWN = '66',
  BROKEN_DOWN = '67',
  COMPLETED = '68'
}

export enum BREAKDOWN_ITEM_STATUS {
  CREATED = '69',
  TO_BREAK_OUT = '70',
  BREAKING_OUT = '71',
  BROKEN_OUT = '72',
  COMPLETED = '73'
}

export enum RMA_TYPE {
  SALE= 'rma',
  PURCHASE = 'purchase_rma'
}

export interface ProcessingCardData {
  id: string, // the id of the shipment order
  status: string, // the status of the actual order being shipped
  rep: string, // the rep who owns the order
  assignee: string, // the assignee who is handling the order
  assigneeColor: string, // color of assignee
  itemCount: number, // number of items on the order
  carrier: string, // the carrier that is shipping the order
  service: string, // the service
  shipmentId: string, // the id of the shipment order
  color: string, // the color of the service
  label: string // the label used on the cards
}
