<template>
  <div>
    <v-container>
      <v-layout row wrap justify-space-between>
        <v-flex xs2>
          <v-text-field
            v-model="bays25"
            label="2.5in bays"
            hint="Quantity"
            :rules="[ e => e > 0 || 'Must be > 0' ]"
          ></v-text-field>
        </v-flex>
        <v-flex xs2>
          <v-text-field
            v-model="bays35"
            label="3.5in bays"
            hint="Quantity"
            :rules="[ e => e > 0 || 'Must be > 0' ]"
          ></v-text-field>
        </v-flex>
        <v-flex xs2>
          <choice-list :config="ffConfig"></choice-list>
        </v-flex>
        <v-flex xs2>
          <choice-list :config="rhConfig" :disabled="ffConfig.id === 220"></choice-list>
        </v-flex>
      </v-layout>
    </v-container>
    <standard-actions
      @save="save"
      @cancel="cancel"
      @close="$emit('close')"
      :save-button="{ name: 'Submit', disabled: !canProceed }"
      :cancel-button="{ name: 'Back' }"
    ></standard-actions>
  </div>
</template>

<script>
import standardActions from '../standardActions'
import choices from '../../autocompletes/choices'

export default {
  name: 'storage',
  components: {
    'standard-actions': standardActions,
    'choice-list': choices
  },
  computed: {
    canProceed () {
      return this.bays25 && this.bays35 && ((this.rhConfig.id && this.ffConfig.id === 219) ||
        this.ffConfig.id === 220) && this.ffConfig.id > -1
    }
  },
  data () {
    return {
      bays25: 0,
      bays35: 0,
      ffConfig: {
        id: 0,
        label: 'Form Factor',
        appName: 'parts',
        modelName: 'server',
        fieldName: 'form_factor'
      },

      rhConfig: {
        id: 0,
        label: 'Rack Height',
        appName: 'parts',
        modelName: 'server',
        fieldName: 'rack_height'
      }
    }
  },
  methods: {
    save () {
      const details = {
        storage_bays_25: this.bays25,
        storage_bays_35: this.bays35,
        form_factor_id: this.ffConfig.id,
        rack_height_id: this.rhConfig.id
      }
      this.$emit('save', details)
    },

    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
