<template>
  <v-app id="app" data-app>
    <v-content id="home">
      <!-- Snackbar -->
      <snackbar v-if="showSnackbar"/>
      <router-view name="topBar"/>
      <router-view name="content" :id="notLogInOrTenant ? 'router-view' : ''"/>
      <router-view name="sideBar"/>
      <v-dialog
        v-model="dialog"
        v-if="dialog"
        width="50%"
      >
        <v-card>
            <v-autocomplete
              :items="filteredRoutes"
              item-text="text"
              return-object
              :search-input.sync="routeSearch"
              no-filter
              id="route-jumper"
              hide-details
              box
              auto-select-first
              autofocus
              @change="executeRouteJumper"
              @keyup.esc="resetDialog"
            />
        </v-card>
      </v-dialog>
    </v-content>
  </v-app>
</template>

<script>
import snackbar from '@/components/notifications/snackbar'
import { keycloak, processToken } from '@/api/keycloak'
import { LogIt } from '@/lib/logger'
import { LOCAL_STORAGE_KEYS, locStore } from '@/lib/LocalStorageWrapper'
import { Sentry } from '@/lib/Sentry.ts'
import { initializeFreshDesk } from '@/lib/freshDesk.ts'

export default {
  name: 'Home',
  components: {
    'snackbar': snackbar
  },
  computed: {
    isElectron () {
      return navigator.userAgent.includes('Electron')
    },

    email () {
      return this.user.email
    },

    user () {
      return this.$store.state.profile.user
    },

    username () {
      return `${this.user.firstName} ${this.user.lastName}`
    },

    authenticated () {
      return this.$store.state.auth.authenticated
    },

    tenants () {
      return this.$store.getters['auth/tenantsForSwitcher']
    },

    filteredRoutes () {
      /* PREP */
      const search = this.routeSearch?.toLowerCase() ?? ''
      const found = []
      const pushToFound = (route, value) => {
        const index = found.findIndex(f => f.text === route.text)
        if (index > -1) {
          const r = found[index]
          r.matchValue += value
          found.splice(index, 1, r)
        } else {
          route.matchValue = value || 1
          found.push(route)
        }
      }

      /* FIRST LETTERS SEARCH */
      const matchFirstLettersOfRoutesWithLettersOfSearch = text => {
        if (text.includes(' ')) {
          let index = 0
          for (const word of text.split(' ')) {
            if (word.charAt(0).toLowerCase() === search.charAt(index)) {
              index++
            } else {
              return 0
            }
          }
          return 10 * index
        } else {
          return 0
        }
      }

      const matchesSplitSearch = text => {
        let value = 0
        for (let i = 0; i < search.length; i++) {
          value += matchFirstLettersOfRoutesWithLettersOfSearch(text)
          if (text.indexOf(search.charAt(i)) === -1) {
            return 0
          }
          value += search.length - i
        }
        return value
      }
      /* END PREP */

      /* LOGIC */
      for (const route of this.routes) {
        const text = route.text.toLowerCase()
        if (text.indexOf(search) > -1) pushToFound(route, 1)
        const value = matchesSplitSearch(text)
        if (value) pushToFound(route, value)
      }
      return found.sort((a, b) => b.matchValue - a.matchValue)
    },

    notLogInOrTenant () {
      return !this.$route.path.includes('login') && !this.$route.path.includes('tenant')
    },

    showSnackbar: {
      get () {
        return this.$store.getters['notifications/showSnackbar']
      },
      set (value) {
        if (value === false) {
          this.$store.dispatch('notifications/hideSnackbar')
        }
      }
    },

    theme () {
      return this.$store.state.profile.theme
    }
  },
  watch: {
    '$route': {
      handler: function (value) {
        if (value.query?.dialog) {
          const dialog = value.query.dialog
          if (dialog === 'reports') {
            this.$store.dispatch('grid/changeDialogToReports')
          }
        }
      },
      deep: true
    },

    email: function (v) {
      if (v) {
        if (!this.setUser) {
          Sentry.setUser(v, this.username)
          this.setUser = true
        }
        if (!this.setFreshDesk) {
          initializeFreshDesk({ email: this.email, firstName: this.user.firstName, lastName: this.user.lastName })
          this.setFreshDesk = true
        }
      }
    }
  },
  data () {
    return {
      dialog: false,
      route: '',
      routes: [],
      routeSearch: '',
      setUser: false,
      setFreshDesk: false,
      attempted: false
    }
  },
  methods: {
    executeRouteJumper (route) {
      route.jump()
    },

    showRouteJumper () {
      this.dialog = true
      setTimeout(function () {
        const el = document.getElementById('route-jumper')
        el.focus()
      }, 200)
    },

    resetDialog () {
      this.dialog = false
      this.route = ''
    },

    keyupHandler (event) {
      if (['INPUT', 'TEXTAREA'].indexOf(document.activeElement.nodeName) === -1 && this.authenticated) {
        if (event.key === '/') this.showRouteJumper()
      }
    },

    initializeKeycloak () {
      const expireTime = Number(locStore.get(LOCAL_STORAGE_KEYS.expire))
      const notExpired = expireTime > Date.now()
      const isTesting = !!locStore.get(LOCAL_STORAGE_KEYS.isTesting) || locStore.get(LOCAL_STORAGE_KEYS.tenant) === 'local'
      try {
        !isTesting && keycloak.init({
          flow: 'standard',
          pkceMethod: 'S256',
          responseMode: this.isElectron ? 'query' : 'fragment',
          ...(notExpired && { token: locStore.get(LOCAL_STORAGE_KEYS.accessToken) }),
          refreshToken: locStore.get(LOCAL_STORAGE_KEYS.refreshToken)
        }).then(response => {
          if (response) {
            processToken(keycloak)
          } else {
            const renewToken = !isTesting && this.$route.name !== 'login'
            if (renewToken) {
              this.$store.dispatch('auth/renewAccessToken')
                .catch(() => this.$store.dispatch('auth/logout', { expire: false }))
            }
          }
        }).catch((error) => {
          console.log(error, 'le error')
          this.$store.dispatch('auth/renewAccessToken')
        })
      } catch (e) {
        console.error(e.message)
      }
    },

    setStuff () {
      Sentry.setUser(this.email, this.username)
      initializeFreshDesk({ email: this.email, firstName: this.user.firstName, lastName: this.user.lastName })
      this.setUser = true
      this.setFreshDesk = true
    }
  },
  created () {
    this.$router.options.routes.forEach(r => {
      r.jumpName && this.routes.push({
        text: r.jumpName,
        name: r.name,
        path: r.path,
        jump: () => {
          this.route = ''
          this.dialog = false
          this.$router.push({ name: r.name })
        }
      })
      if (r.children) {
        r.children.forEach(c => {
          c.jumpName && this.routes.push({
            text: c.jumpName,
            name: c.name,
            path: c.path,
            jump: () => {
              this.route = ''
              this.dialog = false
              this.$router.push({ name: c.name })
            }
          })
        })
      }
    })
    this.routes.push(
      {
        text: 'New Sale',
        jump: () => {
          this.route = ''
          this.dialog = false
          this.$store.dispatch('sale/start')
        }
      },
      {
        text: 'New Purchase',
        jump: () => {
          this.route = ''
          this.dialog = false
          this.$store.dispatch('purchase/start')
        }
      },
      {
        text: 'New Quote',
        jump: () => {
          this.route = ''
          this.dialog = false
          this.$store.dispatch('grid/changeSheet', { app: 'topbar', component: 'newQuote' })
        }
      }
    )
  },
  beforeMount () {
    this.initializeKeycloak()
    // this is a wrapper for the localStorage and its method to run every time the user visits or refreshes the app
    locStore.onMount()
  },
  mounted () {
    document.body.classList = this.theme

    // if current route doesn't include tenant or login
    if (!document.getElementById('favi')) {
      const link = document.createElement('link')
      link.id = 'favi'
      link.rel = 'icon'
      link.type = 'image/png'
      link.href = require('@/assets/favicon.png')
      document.head.appendChild(link)
    }

    if (this.isElectron) {
      const ipcRenderer = window.ipcRenderer
      ipcRenderer.on('notify', (event, message) => {
        this.$store.dispatch('notifications/createSnackbar', {
          message: message,
          color: 'info'
        })
      })
      ipcRenderer.on('logger', (event, message) => {
        LogIt.Error(message)
      })
      ipcRenderer.on('open-route', (event, url) => {
        LogIt.Log_Info(`opening this guy: ${url}`)
        if (!this.$route.fullPath.includes('tenant') && url.includes('tenant')) {
          this.$router.replace(url)
        } else {
          this.$router.push(url)
        }
      })
    }

    document.addEventListener('keyup', this.keyupHandler)

    if (!this.setUser && this.email && this.username) {
      this.setStuff()
    }
  },
  beforeDestroy () {
    document.removeEventListener('keyup', this.keyupHandler)
  }
}
</script>
