<template>
  <div>
    <v-container>
      <v-layout row wrap justify-space-between>
        <v-flex xs3>
          <v-text-field
            v-model="wattage"
            label="Wattage"
            :rules="[ e => e > 0 || 'Must be > 0' ]"
          ></v-text-field>
        </v-flex>
        <v-flex xs3>
          <choice-list :config="efficiencyConfig"></choice-list>
        </v-flex>
        <v-flex xs3>
          <v-text-field
            v-model="current"
            label="Amperage"
            :rules="[ e => e > 0 || 'Must be > 0' ]"
          ></v-text-field>
        </v-flex>
      </v-layout>
    </v-container>
    <standard-actions
      @save="save"
      @cancel="cancel"
      @close="$emit('close')"
      :save-button="{ name: 'Submit', disabled: !canProceed }"
      :cancel-button="{ name: 'Back' }"
    ></standard-actions>
  </div>
</template>

<script>
import standardActions from '../standardActions'
import choices from '../../autocompletes/choices'

export default {
  name: 'storage',
  components: {
    'standard-actions': standardActions,
    'choice-list': choices
  },
  computed: {
    canProceed () {
      return this.wattage > 0 && this.efficiencyConfig.id && this.current > 0
    }
  },
  data () {
    return {
      wattage: null,
      efficiencyConfig: {
        id: 0,
        label: 'Efficiency',
        appName: 'parts',
        modelName: 'power_supply',
        fieldName: 'efficiency'
      },

      current: null
    }
  },
  methods: {
    save () {
      const details = {
        wattage: this.wattage,
        efficiency_id: this.efficiencyConfig.id,
        current: this.current
      }
      this.$emit('save', details)
    },

    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
