import { c } from '@/lib/Currency.ts'

export function getPtId ({ purchaseDetails }) {
  return `${purchaseDetails.transaction.id}-${purchaseDetails.line_number}`
}

export function GetShippingDetails ({ shipOrder, rep }) {
  const shipTo = shipOrder.shipTo ?? false
  const shipFrom = shipOrder.shipFrom ?? false
  const billTo = shipOrder.billTo ?? false
  const isBlind = shipOrder.blind !== 'NOT_BLIND'
  const elRep = isBlind ? null : rep
  const cFreight = c(shipOrder?.freight?.amount)
  const cCost = c(shipOrder?.cost?.amount)
  let cost
  if (cFreight.intValue !== 0) {
    cost = cFreight.value
  } else if (cCost.intValue !== 0) {
    cost = cCost.value
  } else {
    cost = 0
  }

  const toAddress = PrettifyAddress({ addressDetails: shipTo, contact: shipOrder.shipToContact ?? shipOrder.shipToRep })
  if (isBlind && !shipTo.client) {
    toAddress.company = ''
  }

  try {
    return {
      id: shipOrder.id,
      toAddress: toAddress,
      toContact: PrettifyContact({ contact: shipOrder.shipToContact, rep: elRep }),
      fromAddress: PrettifyAddress({ addressDetails: shipFrom, contact: shipOrder.shipFromContact ?? shipOrder.shipFromRep }),
      fromContact: PrettifyContact({ contact: shipOrder.shipFromContact, rep: elRep }),
      billToAddress: PrettifyAddress({ addressDetails: billTo, contact: shipOrder.billToContact ?? shipOrder.billToRep }),
      billToContact: PrettifyContact({ contact: shipOrder.billToContact, rep: elRep }),
      method: GetShippingMethod({ shipOrder: shipOrder }),
      blind: shipOrder.blind ?? false,
      cost: cost,
      pickupType: shipOrder.pickupType ?? false,
      status: shipOrder.status ?? false
    }
  } catch (error) {
    console.log(error)
  }
}

export function PrettifyAddress ({ addressDetails, contact }) {
  if (addressDetails) {
    const company = addressDetails?.company?.name ?? addressDetails?.client?.name ?? ''
    const name = contact?.full_name ?? ''
    const line1 = addressDetails?.street_1 ?? ''
    const line2 = addressDetails?.street_2 ?? ''
    const line3 = `${addressDetails.city}, ${addressDetails.state ?? ''} ${addressDetails.country.code} ${addressDetails.zip_code}`

    return {
      id: addressDetails.id,
      company: company,
      name: name,
      line1: line1,
      line2: line2,
      line3: line3
    }
  } else {
    return false
  }
}

export function PrettifyContact ({ contact, rep }) {
  if (contact) {
    return {
      name: contact.full_name,
      email: contact.email,
      phone: contact.phone
    }
  } else if (rep) {
    return {
      name: rep.full_name,
      email: rep.email
    }
  }
  return null
}

export function GetShippingMethod ({ shipOrder }) {
  const data = shipOrder
  const service = data.service ?? false
  const date = data.deliver_by_date ?? false
  const pickupType = data.pickupType
  const notPickup = data.pickupType === 'NOT_PICKUP'
  if (notPickup) {
    if (service) {
      return {
        method: `${service.shipper.name} ` +
          `${service.service}`,
        cost: data?.cost?.pretty ?? 'Pending',
        tracking: data.tracking_number
          ? data.tracking_number : 'Pending'
      }
      // or by delivery date
    } else if (date) {
      return {
        method: `<strong>${date}</strong>`,
        cost: data.cost.pretty ? data.cost.pretty : 'Pending',
        tracking: data.tracking_number
          ? data.tracking_number : 'Pending'
      }
      // or by cheapest
    } else {
      return {
        method: 'NOT SET',
        cost: data.cost ? data.cost.pretty : 'Pending',
        tracking: data.tracking_number ? data.tracking_number : 'Pending'
      }
    }
  } else {
    return {
      method: `${pickupType} PICKUP`,
      cost: 'Pending',
      tracking: false
    }
  }
}
