<template>
  <div class="pr-2">
    <v-btn
      v-if="$vuetify.breakpoint.mdAndUp"
      data-cy="close"
      outline
      :disabled="disabled"
      @click="$emit('click')"
    >
      {{ success ? 'Close' : 'Cancel' }}
    </v-btn>
    <v-btn
      v-else
      data-cy="close"
      icon
      :disabled="disabled"
      dark
      @click="$emit('click')"
    >
      <v-icon
        :color="success ? 'primary' : ''"
      >fal fa-times</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'CancelButton',
  props: {
    success: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
