import { ApolloLink, NextLink, Operation } from 'apollo-link'
import { LOCAL_STORAGE_KEYS, locStore } from '@/lib/LocalStorageWrapper'
import store from '@/store/store'
import { Sentry } from '@/lib/Sentry'
import { Severity } from '@sentry/types'
import { keycloak } from '@/api/keycloak'

type Headers = {
  authorization?: string
}

class AuthorizationLink extends ApolloLink {
  private access: string = ''
  private refresh: string = ''
  private tenant: string = ''
  private uri: string = ''
  private authHeaderValue: string = ''
  private expireTime: number = 0
  private needToWait: boolean = false

  setup (operation: Operation) {
    try {
      this.access = locStore.get(LOCAL_STORAGE_KEYS.accessToken) as string
      this.refresh = locStore.get(LOCAL_STORAGE_KEYS.refreshToken) as string
      this.tenant = locStore.get(LOCAL_STORAGE_KEYS.tenant) as string
      this.expireTime = locStore.get(LOCAL_STORAGE_KEYS.expire)
      this.checkForValidity()
      this.uri = this.computeUri()
      this.authHeaderValue = this.setAuthHeaderValue()
    } catch (error) {
      Sentry.addBreadcrumb({ category: 'pre-auth', message: 'Failed during pre-auth setup', level: Severity.Error })
      Sentry.setContext('operation', { name: operation.operationName })
      Sentry.captureEvent(error)
    }
  }

  request (operation: Operation, forward: NextLink) {
    try {
      this.setup(operation)
      operation.setContext(({ headers }: { headers: Headers }) => ({
        uri: this.uri,
        wait: this.needToWait,
        headers: {
          ...headers,
          'Authorization': this.authHeaderValue
        }
      }))
    } catch (error) {
      Sentry.addBreadcrumb({ category: 'pre-auth', message: 'Failed during pre-auth requested', level: Severity.Error })
      Sentry.setContext('operation', { name: operation.operationName })
      Sentry.captureEvent(error)
    }
    return forward(operation)
  }

  private accessTokenIsValid () {
    const accessTokenExists = this.access?.length > 1
    const notExpired = this.expireTime > Date.now()
    return accessTokenExists && notExpired
  }

  private refreshTokenIsValid () {
    return this.refresh !== ''
  }

  private computeUri (): string {
    if (this.tenantIsLocal()) {
      return process.env.VUE_APP_BACKEND_URL ?? 'http://localhost:8000/graphql'
    } else {
      return `https://${this.tenant}.backend.traqsys.com/graphql`
    }
  }

  private setAuthHeaderValue (): string {
    return this.tenantIsLocal() ? 'DEV' : `Bearer ${this.access}`
  }

  private tenantIsLocal (): boolean {
    return locStore.get(LOCAL_STORAGE_KEYS.tenant) === 'local'
  }

  private checkForValidity (): void {
    let wait = false
    const noTenant = !this.tenant
    if (this.tenantIsLocal()) return
    if (!this.refreshTokenIsValid()) {
      store.dispatch('auth/logout', { expire: false })
      this.needToWait = true
      return
    } else if (noTenant) {
      const tenants = locStore.get(LOCAL_STORAGE_KEYS.tenants)
      if (tenants.length === 1) {
        this.tenant = tenants[0].toLowerCase().replace('/tenants/', '')
        locStore.set(LOCAL_STORAGE_KEYS.tenant, this.tenant)
      } else if (locStore.get(LOCAL_STORAGE_KEYS.defaultTenant)) {
        this.tenant = locStore.get(LOCAL_STORAGE_KEYS.defaultTenant)
        locStore.set(LOCAL_STORAGE_KEYS.tenant, this.tenant)
      } else {
        wait = true
      }
    }
    const accessTokenIsNotValid = !this.accessTokenIsValid()
    this.needToWait = accessTokenIsNotValid || wait
  }
}

export default AuthorizationLink
