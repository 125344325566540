import { computed, reactive, Ref, watch } from '@vue/composition-api'
import { OrderFormObject } from '@/composition/UseOrderInformation'
import {
  Address_Address,
  Create__Address_Address__Input,
  SaleForEditFragment, ShipmentOrderToEditFragment
} from '@/models/generated/graphql/ErpBackend'
import store from '@/store/store'
import { CREATE_CLIENT } from '@/api/graphql/Constants/Clients'
import { CREATE_CONTACT } from '@/api/graphql/Constants/Contacts'
import { CREATE_ADDRESS } from '@/api/graphql/Constants/Addresses'
import { apolloClient as apollo } from '@/api/graphql/apollo'
import { MutationOptions } from 'apollo-client'
import { TranslateGqlAddressToString } from '@/lib/Addresses'

interface BlindClient extends OrderFormObject<{ key: 'value' | 'shortName' | 'id', value: string }, undefined, string> {
  shortName: string
  id: string
}

interface BlindContact extends OrderFormObject<{ key: 'value' | 'id' | 'first' | 'last', value: string }, undefined, string> {
  id: string,
  first: string,
  last: string
}

interface BlindAddress extends OrderFormObject<{ key: 'value' | 'id' | 'parsed' | 'input', value: any }, undefined, string> {
  id: string
  parsed: Address_Address,
  input: Create__Address_Address__Input
  validated: boolean,
  invalid: boolean,
  hasChanged: boolean
}

export interface BlindForm {
  client: BlindClient
  contact: BlindContact
  address: BlindAddress
  returnToCompany: boolean
}

export function useBlindShipping () {
  const form: BlindForm = reactive({
    client: {
      value: '',
      shortName: '',
      id: '',
      message: '',
      setter: (event) => {
        form.client[event.key] = event.value
        form.client.message = ''
      },
      validator: () => {
        form.client.message = form.client.value ? '' : 'This is required when shipping blind'
      }
    },
    contact: {
      value: '',
      id: '',
      message: '',
      first: '',
      last: '',
      validator: () => {
        const contactIsEmpty = form.contact.value === ''
        const clientIsEmpty = form.client.value === ''
        if (clientIsEmpty && contactIsEmpty) {
          form.contact.message = 'Enter a client name or a contact name here'
        } else form.contact.message = ''
      },
      setter: (event) => {
        form.contact[event.key] = event.value
        form.contact.message = ''
      }
    },
    address: {
      value: '',
      id: '',
      // @ts-ignore
      parsed: {},
      input: { street_1: '', zip_code: '' },
      message: '',
      validator: () => {
        if (form.address.value && !form.address.validated) {
          form.address.message = 'The address needs to be validated to continue.'
        } else if (form.address.invalid) {
          form.address.message = 'This address is not valid'
        }
      },
      setter: event => {
        form.address[event.key] = event.value
      },
      validated: false,
      invalid: false,
      hasChanged: false
    },
    returnToCompany: false
  })

  const hasErrors = computed(() => {
    return !!form.client.message || !!form.contact.message || form.address.invalid
  })

  function CheckClientContactValue () { // move this into BlindDetails.vue
    if (form.client.value || form.contact.value) {
      form.client.message = ''
      form.contact.message = ''
    } else {
      if (!form.client.value) form.client.message = 'Enter a client value here'
      if (!form.contact.value) form.contact.message = 'OR a contact value here'
    }
  }

  function Validate () {
    form.client.validator()
    form.contact.validator()
    form.address.validator()
  }

  const apply = (order: ShipmentOrderToEditFragment): void => {
    form.client.setter({ key: 'value', value: order.ship_to_address?.client?.name ?? '' })
    form.client.setter({ key: 'id', value: order.ship_to_address?.client?.id ?? '' })
    form.client.setter({ key: 'shortName', value: order.ship_to_address?.client?.short_name ?? '' })
    form.contact.setter({ key: 'id', value: order.ship_to_contact?.id ?? '' })
    form.contact.setter({ key: 'value', value: order.ship_to_contact?.full_name ?? '' })
    form.contact.setter({ key: 'first', value: order.ship_to_contact?.first_name ?? '' })
    form.contact.setter({ key: 'last', value: order.ship_to_contact?.last_name ?? '' })
    form.address.setter({ key: 'id', value: order.ship_to_address?.id ?? '' })
    const shipTo = order.ship_to_address
    if (shipTo) {
      const address = TranslateGqlAddressToString(shipTo as Address_Address)
      form.address.setter({ key: 'value', value: address })
      form.address.validated = true
    }
  }

  return { form, hasErrors, Validate, CheckClientContactValue, apply }
}

export async function saveBlindStuff (form: BlindForm): Promise<{ address: string, contact: string } | undefined> {
  let client = ''
  let contact = ''
  const address = form.address.input

  const onlyClientIsFilled = form.client.value && !form.contact.first
  const onlyContactIsFilled = !form.client.value && form.contact.first
  const neitherAreFilled = !form.client.value && !form.contact.first

  if (onlyContactIsFilled) { // if only a contact is written down
    client = `${form.contact.first} ${form.contact.last}`
  } else if (onlyClientIsFilled) {
    client = form.client.value
  } else if (neitherAreFilled) {
    form.client.message = 'Input a client or contact name'
  } else {
    contact = `${form.contact.first} ${form.contact.last}`
    client = form.client.value
  }

  try {
    const id = form.client.id || await createBlindClient(client, form.client.shortName)
    let contactId = ''
    if (form.contact.first) {
      contactId = form.contact.id || await createBlindContact(contact, id)
    }
    address.client_id = id
    const addressId = form.address.id || await createBlindAddress(address)
    if (addressId) {
      return { address: addressId, contact: contactId }
    }
  } catch (error) {
    store.dispatch('notifications/createSnackbar', {
      message: error.message,
      color: 'error'
    })
  }
}

/**
 * Creates a blind client to attach blind details to
 * @param name
 * @param shortName
 * @return {Promise<string>} the id of the new client
 */
export async function createBlindClient (name: string, shortName: string): Promise<string> {
  // check if shortName is an id of a found client
  if (Number.isFinite(Number(shortName))) return shortName
  // if not
  const mutation = {
    mutation: CREATE_CLIENT,
    variables: {
      input: {
        name: name,
        short_name: shortName,
        is_our_client: false
      }
    }
  }
  const response = await apollo.mutate(mutation)
  return response.data?.Create__Clients_Client?.id ?? ''
}

export async function createBlindContact (name: string, id: string): Promise<string> {
  const mutation = {
    mutation: CREATE_CONTACT,
    variables: {
      input: {
        owner_id: id,
        first_name: name.split(' ')[0],
        last_name: name.split(' ')?.[1] ?? 'N/A'
      }
    }
  }
  const response = await apollo.mutate(mutation)
  return response.data?.Create__Clients_Contact?.id ?? ''
}

export async function createBlindAddress (address: Create__Address_Address__Input): Promise<string | undefined> {
  try {
    const mutation: MutationOptions = {
      mutation: CREATE_ADDRESS,
      variables: { input: address }
    }
    const response = await apollo.mutate(mutation)
    return response.data?.Create__Address_Address.id ?? ''
  } catch (error) {
    store.dispatch('notifications/createSnackbar', {
      message: error.message,
      color: 'error'
    })
  }
}
