import { apolloClient as apollo } from '@/api/graphql/apollo'
import helpers from '@/api/helpers'
import { TEST_STATUS_TAGS } from '@/api/graphql/Constants/Choices'
import {
  ColDef,
  ICellRendererParams,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams
} from 'ag-grid-community'
import moment from 'moment'
import { FILTER_OPTIONS } from '@/lib/queryParser'
import * as _ from 'lodash'
import {
  AnnotationObject,
  Breakdown_BreakDown,
  Choices_Tag,
  Clients_Client,
  Inventory_Item,
  Parts_Part,
  Purchases_PurchasedItems,
  Quotes_Quote,
  Quotes_QuoteParts, SaleFragment,
  Sales_Sale,
  Sales_SoldItems
} from '@/models/generated/graphql/ErpBackend.ts'
import { PURCHASE_ORDER_STATUS } from '@/models/ExtraBackendModels'

enum orderType {
  'pt' = 'PT',
  'st' = 'ST',
  'wo' = 'WO'
}

export const fields: any = {
  generic: {
    id: 'id',
    client: 'client.name',
    clientCode: 'client.short_name',
    contact: 'contact.full_name',
    creator: 'creator.initials',
    creationDate: 'creation_date',
    quote: 'quote.id',
    rep: 'rep.initials',
    shipFrom: 'shipment_order.ship_from_address',
    shipTo: 'shipment_order.ship_to_address',
    shipment: 'shipment_order',
    tax: 'tax',
    terms: 'terms.name',
    transactionStatus: 'status.status',
    total: 'estimated_total',
    warrantyEnd: 'warranty_end_date'
  },
  rmas: {
    claimDate: 'claim_date',
    closedBy: 'closed_by.initials',
    closedDate: 'closed_date',
    openedBy: 'creator.initials',
    openedDate: 'creation_date',
    pt: 'pt.id',
    receiver: 'received_by.initials',
    receivedDate: 'received_date',
    returnCode: 'return_code.choice',
    returnReason: 'return_reason',
    rma: 'id',
    st: 'st.id',
    tester: 'tested_by.initials',
    testedDate: 'tested_date',
    verifier: 'verified_by.initials',
    verifiedDate: 'verified_date',
    vendor: 'pt.client.name',
    voider: 'voided_by.initials',
    voidedDate: 'voided_date',
    warranty: 'warranty_honored_after_cut_off'
  },
  rmaItems: {
    description: 'item.part.description',
    partNumber: 'item.part.pn',
    pt: 'purchase',
    st: 'sale',
    serial: 'item.serial_number',
    tested: 'rmaTested.choice'
  },
  transactions: {
    st: {
      bookDate: 'book_date',
      clientPO: 'client_order_number',
      contractSendBy: 'contract_send_by_date',
      estimatedCost: 'estimated_cost',
      estimatedMargin: 'estimated_margin',
      promiseDate: 'promise_date',
      salesDate: 'sale_date',
      shipBy: 'ship_by_date',
      soldAs: 'sold_as_condition',
      tracking: 'shipment_order.tracking_number'
    },
    pt: {
      acceptanceDate: 'acceptance_date',
      approvalDate: 'approval_date',
      approvedBy: 'approved_by.initials',
      clientST: 'client_order_number',
      closingDate: 'closing_date',
      condition: 'condition.choice',
      contractSender: 'contract_sent_by.full_name',
      eta: 'eta',
      quote: 'quote',
      partTotal: 'part_total',
      purchaseDate: 'creation_date',
      subTotal: 'sub_total',
      requestedByDate: 'requested_dated',
      vendorRef: 'vendor_reference'
    },
    wo: {
      type: 'type.choice',
      vendor: 'vendor.name',
      needBy: 'need_by',
      status: 'status.status',
      terms: 'terms.name',
      warrantyEndDate: 'warranty_end_date'
    }
  },
  transactionItems: {
    cost: 'original_cost',
    stCost: 'item.purchases_items_details.current_cost',
    countryOfOrigin: 'item.country_of_origin.name',
    sub: 'sub',
    line: 'line_number',
    location: 'item.location.name',
    received: 'item.receive_status',
    serial: 'item.serial_number',
    shipmentID: 'shipment',
    prmaID: 'item.purchase_rma_item_details.rma.id',
    rmaID: 'item.rma_item_details.rma.id',
    soldFor: 'sold_for',
    item: 'item',
    status: 'status.status'
  },
  inventory: {
    items: {
      allocatedDate: 'allocated_date',
      alternateSerial: 'alternate_serial_number',
      associatedPN: 'associated_pn.associated_pn',
      auditDate: 'audit_date',
      lastAuditor: 'audited_by.initials',
      brand: 'part.brand.name',
      breakdownID: 'broke_down_items.break_down.id',
      buildupID: 'build_up_items.build_up.id',
      checkedOutBy: 'checked_out_by.initials',
      client: 'sales_items_details.transaction.client.name',
      condition: 'condition.choice',
      countryOfOrigin: 'country_of_origin.name',
      currentCost: 'purchases_items_details.current_cost',
      originalCost: 'purchases_items_details.original_cost',
      description: 'part.description',
      firmware: 'fw',
      grade: 'grade.choice',
      lastWriteDownDate: 'last_write_down_date',
      location: 'location.name',
      mfgr: 'part.mfgr.name',
      nextWriteDown: 'next_write_down_date',
      oobMgtSerial: 'oobmgmt_serial',
      partNumber: 'part.pn',
      pt: 'purchases_items_details', // via method valueGetter,
      ptRep: 'purchases_items_details.transaction.rep.initials',
      purchaseDate: 'purchases_items_details.transaction.purchase_date',
      qty: 'quantity',
      receivedDate: 'received_date',
      receiver: 'received_by.initials',
      received: 'receive_status',
      salesDate: 'sales_items_details.transaction.sale_date',
      serial: 'serial_number',
      site: 'location.site.short_name',
      soldFor: 'sales_items_details.sold_for',
      st: 'sales_items_details', // via method valueGetter
      stRep: 'sales_items_details.transaction.rep.initials',
      status: 'status.status',
      testStatus: 'test_status',
      unbookedDate: 'unbooked_date',
      vendor: 'purchases_items_details.transaction.client.name'
    }
  },
  parts: {
    battery: 'has_battery',
    brand: 'brand.name',
    desc: 'description',
    ean: 'ean',
    market: 'market.choice',
    mfgr: 'mfgr.name',
    pn: 'pn',
    secondaryPN: 'secondary_pn',
    type: 'type',
    upc: 'upc'
  },
  quotes: {
    totalPrice: 'total_price',
    pricePerPart: 'price_per_part',
    quote: 'quote.id',
    qty: 'qty',
    totalPartPrice: 'total'
  },
  clients: {
    allowSales: 'allow_sales',
    cl: 'credit_limit',
    credit: 'credit',
    name: 'name',
    notes: 'notes',
    ourClient: 'is_our_client',
    outStandInvoiceLimit: 'outstanding_invoice_limit',
    payment: 'payment',
    rating: 'rating',
    rep: 'internal_rep.initials',
    sName: 'short_name',
    shipNotes: 'shipping_instructions',
    type: 'type.choice',
    website: 'website'
  }
}

export function GetFieldFromColumnId (id: string, columns: Array<ColDef>): string {
  const column = columns.find(c => c.colId === id)
  if (column?.field) return column.field
  return ''
}

/* Renderers and Value Getters/Formatters */

export const BooleanCellRenderer = (params: ICellRendererParams, color: string = 'black'): string => {
  return params.value ? `<i class="fas fa-check-circle grid-icon-font grid-icon ${color}--text"></i>` : '--'
}

export function DateCellRenderer (params: ICellRendererParams): string {
  return params.value ? moment(params.value).format('DD/MM/YYYY') : ''
}

export function DateTimeCellRenderer (params: ICellRendererParams): string {
  return params.value ? moment(params.value).format('DD/MM/YYYY hh:mm:ss a') : ''
}

export function FormatMoney (money: string): string {
  return (Number(money)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export function MoneyRenderer (params: ValueFormatterParams): string {
  const money = params.value ?? 0
  if (money !== null) return FormatMoney(money)
  return '0.00'
}

export function CompareMoney (a: string, b: string): number {
  return (parseFloat(a) || 0.00) - (parseFloat(b) || 0.00)
}

let valueGetters: { [index: string]: any } = {}
valueGetters = {
  ptOrder: function ({ data }: { data: Inventory_Item }): string {
    const order: Purchases_PurchasedItems | null = data?.purchases_items_details!
    if (order) return `${order.transaction.id}-${order.line_number}`
    return ''
  },
  stOrder: function ({ data }: { data: Inventory_Item }): string {
    const order: Sales_SoldItems | null | undefined = data?.sales_items_details
    if (order) return `${order.transaction.id}-${order.line_number}`
    return ''
  }
}

function OrderComparator (valueA: string, valueB: string, nodeA: RowNode, nodeB: RowNode, isInverted: boolean): number {
  if (valueA && valueB) {
    const splitA = valueA.split('-')
    const splitB = valueB.split('-')

    if (splitA[0] === splitB[0]) {
      return Number(splitA[1]) - Number(splitB[1])
    }
    return Number(splitA[0]) - Number(splitB[0])
  }
  return 0
}

/* Column Type Definitions */

const moneyFilterOptions = FILTER_OPTIONS.filter(f => f.types.includes('money')).map(f => f.grid)
const dateFilterOptions = FILTER_OPTIONS.filter(f => f.types.includes('date')).map(f => f.grid)

enum ENUM__COLUMN_TYPE {
  'date' = 'date',
  'money' = 'money',
  'dateTime' = 'dateTime',
  'group' = 'group',
  'location' = 'location',
  'noFloatingFilter' = 'no-floating-filter',
  'noFilter' = 'no-filter'
}

export const COLUMN_TYPES: { [index: string] : ColDef } = {
  'date': {
    cellRenderer: DateCellRenderer,
    filterParams: {
      filterOptions: dateFilterOptions
    },
    floatingFilterComponent: 'DateFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true
    }
  },
  'dateTime': {
    suppressMenu: true,
    cellRenderer: DateTimeCellRenderer,
    filter: false
  },
  'money': {
    comparator: CompareMoney,
    valueFormatter: params => {
      return params.value?.pretty ?? params.value ?? '--'
    },
    filterParams: {
      buttons: ['reset', 'apply'],
      newRowsAction: 'keep',
      filterOptions: moneyFilterOptions
    }
  },
  'location': {
    // use custom filter for this guy! like the status one
    field: 'test'
  },
  'group': {
    cellRenderer: 'agGroupCellRenderer',
    pinned: 'left',
    cellRendererParams: {
      suppressDoubleClickExpand: true
    }
  },
  'no-floating-filter': {
    filterParams: {
      buttons: ['reset', 'apply'],
      newRowsAction: 'keep',
      filterOptions: ['Equals']
    },
    floatingFilterComponentParams: {
      suppressFilterButton: true
    }
  },
  'no-filter': {
    filter: false
  }
}

/* End Column Type Definitions */

/* COLUMNS THAT ARE USED ACROSS MULTIPLE DIFFERENT VIEWS */

export function DynamicPtOrStColumn (column: string, orderType: orderType, headerName: string): ColDef {
  const lower: string = orderType.toLowerCase()

  return {
    headerName: headerName,
    field: column,
    colId: lower,
    valueGetter: params => {
      const orderItem: Purchases_PurchasedItems | Sales_SoldItems = _.get(params.node.data, column, null)
      return `${orderItem?.transaction?.id}-${orderItem?.line_number}`
    },
    comparator: OrderComparator,
    type: ENUM__COLUMN_TYPE.noFloatingFilter
  }
}

function SearchObjectForKeyAndRetrieveValue (object: any, key: string): any {
  let result = null
  for (const k in object) {
    if (k === key) {
      result = object[key]
    }
    if (object[k]?.id) {
      result = SearchObjectForKeyAndRetrieveValue(object[k], key)
    }

    if (result !== null) return result
  }
  return null
}

const getPurchaseOrSaleColumn = (orderType: string): ColDef => {
  const upper: string = orderType.toUpperCase()
  const lower: string = orderType.toLowerCase()

  return {
    headerName: upper,
    field: fields.inventory.items[lower],
    colId: lower,
    valueGetter: valueGetters[`${lower}Order`],
    comparator: OrderComparator,
    type: ENUM__COLUMN_TYPE.noFloatingFilter
  }
}

const testStatusesColumn: ColDef = {
  headerName: 'Test Statuses',
  field: fields.inventory.items.testStatus,
  colId: 'testStatus',
  valueGetter: ({ data }: { data: Inventory_Item}): Array<string> | string => {
    const display = []
    if (data && data?.test_status) {
      for (const tag of data.test_status) {
        display.push((tag as Choices_Tag).tag)
      }
      if (display.length === 0) {
        return 'Untested'
      }
      return display
    }
    return ''
  },
  filter: 'agSetColumnFilter',
  sortable: false,
  filterParams: {
    buttons: ['apply'],
    keyCreator: (params: any): string => {
      return params.value.tag
    },
    suppressMiniFilter: true,
    values: async (params: any): Promise<void> => {
      const response = await apollo.query({
        query: TEST_STATUS_TAGS
      })
      const tags = []
      for (const tag of response.data.tags) {
        tags.push(tag.tag)
      }
      params.success(tags)
    }
  }
}

const partNumberColumn: ColDef = {
  headerName: 'Part Number',
  field: fields.inventory.items.partNumber,
  colId: 'partNumber',
  cellRenderer: 'partNumberCell',
  pinned: 'left',
  cellClass: (params) => {
    if (params.node.data) {
      const data: any = params.node.data
      if (data.is_lost === undefined) {
        const find = SearchObjectForKeyAndRetrieveValue(data, 'is_lost')
        return find ? 'lost' : ''
      } else {
        return data.is_lost ? 'lost' : ''
      }
    } else return ''
  },
  tooltipValueGetter: params => {
    const find = SearchObjectForKeyAndRetrieveValue(params.node.data, 'is_lost')
    return find ? 'This item is lost!' : ''
  }
}

const masterPartNumberColumn: ColDef = {
  headerName: partNumberColumn.headerName,
  colId: partNumberColumn.colId,
  field: fields.parts.pn,
  type: 'group',
  cellRendererParams: {
    innerRenderer: (params: ICellRendererParams): string => {
      const data: Parts_Part = params.data
      if (data.has_battery) return params.value + '<i class="far fa-car-battery part-tag" style="color: #2E7D32"></i>'
      return params.value
    }
  }
}

const systemPartNumberColumn: ColDef = {
  headerName: 'Part Number',
  field: fields.inventory.items.partNumber,
  colId: 'partNumber',
  pinned: 'left',
  cellRenderer: 'agGroupCellRenderer',
  cellRendererParams: {
    innerRenderer: 'partNumberCell',
    suppressDoubleClickExpand: true
  },
  tooltipValueGetter: params => {
    const find = SearchObjectForKeyAndRetrieveValue(params.node.data, 'is_lost')
    return find ? 'This item is lost!' : ''
  }
}

/* END COLUMNS THAT ARE USED MORE THAN ONCE */

/* Actual Column Configs or ColDefs as AG-Grid likes to call them */

export const COLUMNS__INVENTORY_ITEMS: Array<ColDef> = [
  { headerName: 'Allocated Date', type: 'date', field: fields.inventory.items.allocatedDate, colId: 'allocatedDate' },
  { headerName: 'Alternate Serials', field: fields.inventory.items.alternateSerial, colId: 'alternateSerials' },
  { headerName: 'Associated PN', field: fields.inventory.items.associatedPN, colId: 'associatedPn' },
  { headerName: 'Audit Date', type: 'date', field: fields.inventory.items.auditDate, colId: 'auditDate' },
  { headerName: 'Brand', field: fields.inventory.items.brand, colId: 'brand' },
  { headerName: 'Buildup Number', field: fields.inventory.items.buildupID, colId: 'buildupNumber' },
  { headerName: 'Checked Out By', field: fields.inventory.items.checkedOutBy, colId: 'checkedOutBy' },
  { headerName: 'Condition', field: fields.inventory.items.condition, colId: 'condition' },
  { headerName: 'Country of Origin', field: fields.inventory.items.countryOfOrigin, colId: 'countryOfOrigin' },
  { headerName: 'Current Cost', type: 'money', field: fields.inventory.items.currentCost, colId: 'currentCost' },
  { headerName: 'Original Cost', type: 'money', field: fields.inventory.items.originalCost, colId: 'originalCost' },
  { headerName: 'Description', field: fields.inventory.items.description, colId: 'description' },
  { headerName: 'Firmware Version', field: fields.inventory.items.firmware, colId: 'firmwareVersion' },
  { headerName: 'Grade', field: fields.inventory.items.grade, colId: 'grade' },
  { headerName: 'Last Write Down', type: 'date', field: fields.inventory.items.lastWriteDownDate, colId: 'lastWriteDownDate' },
  {
    headerName: 'Location',
    field: fields.inventory.items.location,
    colId: 'location',
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: ['test']
    }
  },
  { headerName: 'Manufacturer', field: fields.inventory.items.mfgr, colId: 'mfgr' },
  { headerName: 'Next Write Down', type: 'date', field: fields.inventory.items.nextWriteDown, colId: 'nextWriteDownDate' },
  partNumberColumn,
  // { headerName: 'Processor', field: 'processed_by' },
  // { headerName: 'PT', field: fields.inventory.items.pt, colId: 'pt' },
  getPurchaseOrSaleColumn(orderType.pt),
  { headerName: 'PT Rep', field: fields.inventory.items.ptRep, colId: 'ptRep' },
  { headerName: 'Quantity', field: fields.inventory.items.qty, colId: 'quantity' },
  { headerName: 'Received Date', type: 'date', field: fields.inventory.items.receivedDate, colId: 'receivedDate' },
  { headerName: 'Receiver', field: fields.inventory.items.receiver, colId: 'receiver' },
  { headerName: 'Received', field: fields.inventory.items.received, colId: 'received', cellRenderer: BooleanCellRenderer },
  { headerName: 'Serial Number', field: fields.inventory.items.serial, colId: 'serialNumber' },
  { headerName: 'Site', field: fields.inventory.items.site, colId: 'site' },
  { headerName: 'Sold For', type: 'money', field: fields.inventory.items.soldFor, colId: 'soldFor' },
  getPurchaseOrSaleColumn(orderType.st),
  { headerName: 'ST Rep', field: fields.inventory.items.stRep, colId: 'stRep' },
  {
    headerName: 'Status',
    field: fields.inventory.items.status,
    colId: 'status',
    valueFormatter: ({ data }: { data: Inventory_Item }): string => {
      if (data) {
        if (data.sales_items_details) {
          return 'Allocated'
        } else {
          return 'Open'
        }
      }
      return ''
    }
  },
  testStatusesColumn,
  { headerName: 'Unbooked Date', type: 'date', field: fields.inventory.items.unbookedDate, colId: 'unbookedDate' }
]

export const COLUMNS__INVENTORY_SYSTEMS: Array<ColDef> = [
  ...COLUMNS__INVENTORY_ITEMS.filter((c: ColDef) => c.colId !== 'partNumber'),
  systemPartNumberColumn,
  {
    headerName: 'Breakdown Tasks',
    field: 'break_down_orders',
    colId: 'breakdownNumber',
    valueGetter: params => {
      return params.data.break_down_orders?.map((b: Breakdown_BreakDown) => b.id).toString() ?? ''
    }
  },
  { headerName: 'OOB Serial', field: fields.inventory.items.oobMgtSerial, colId: 'oobSerial' }
]

export const COLUMNS__INVENTORY_SYSTEMS_NESTED: Array<ColDef> = [
  { headerName: 'Part Number', field: 'part.pn', colId: 'pn', pinned: 'left' },
  { headerName: 'Description', field: 'part.description', colId: 'description' },
  { headerName: 'Serial Number', field: 'serial_number', colId: 'serialNumber' },
  getPurchaseOrSaleColumn(orderType.pt),
  { headerName: 'Current Cost', type: 'money', field: fields.inventory.items.currentCost, colId: 'currentCost' },
  { headerName: 'Original Cost', type: 'money', field: fields.inventory.items.originalCost, colId: 'originalCost' },
  { headerName: 'Broken Out Status', field: 'broke_down_items.status.status', colId: 'brokenOutStatus' }
]

export const COLUMNS__INVENTORY_HISTORY: Array<ColDef> = [
  ...COLUMNS__INVENTORY_ITEMS.filter((c: ColDef) => {
    return !c.colId?.includes('checked') ||
      !c.colId?.includes('site') ||
      !c.colId?.includes('location')
  })
]

export const COLUMNS__INVENTORY_MASTER: Array<ColDef> = [
  { headerName: 'Brand', field: fields.parts.brand, colId: 'brand' },
  { headerName: 'Description', field: fields.parts.desc, colId: 'description' },
  { headerName: 'EAN', field: 'ean', colId: 'ean' },
  { headerName: 'HS Code', field: 'hs_code', colId: 'hsCode' },
  { headerName: 'HTSUS Code', field: 'htsus_code', colId: 'htsus' },
  { headerName: 'Market', field: fields.parts.market, colId: 'market' },
  masterPartNumberColumn,
  { headerName: 'Schedule B Code', field: 'schedule_b_code', colId: 'scheduleB' },
  { headerName: 'Secondary Part Number', field: fields.parts.secondaryPN, colId: 'secondaryPartNumber' },
  { headerName: 'SKU', field: 'sku', colId: 'sku' },
  { headerName: 'Type', field: fields.parts.type, colId: 'type' }
]

export const COLUMNS__MASTER_NESTED_BOMS: Array<ColDef> = [
  { headerName: 'Part', field: 'part.pn', colId: 'partNumber', pinned: 'left' },
  { headerName: 'Description', field: 'part.description', colId: 'description' },
  { headerName: 'Type', field: 'part_type', colId: 'type' },
  { headerName: 'Min Required', field: 'minimum_count', colId: 'min' },
  { headerName: 'Max Allowed', field: 'maximum_count', colId: 'max' }
]

export const COLUMNS__MASTER_NESTED_PURCHASES: Array<ColDef> = [
  { headerName: 'PT', field: 'transaction.id', colId: 'id', pinned: 'left' },
  { headerName: 'Client', field: 'transaction.client.name', colId: 'client' },
  { headerName: 'Purchase Cost', field: 'original_cost', colId: 'cost', type: 'money' },
  { headerName: 'Purchase Date', field: 'creation_date', colId: 'date', type: 'date' },
  { headerName: 'Current Cost', field: 'current_cost', colId: 'currentCost', type: 'money' },
  { headerName: 'Rep', field: 'transaction.rep.initials', colId: 'rep' }
]

export const COLUMNS__MASTER_NESTED_SALES: Array<ColDef> = [
  { headerName: 'ST', field: 'transaction.id', colId: 'id', pinned: 'left' },
  { headerName: 'Client', field: 'transaction.client.name', colId: 'client' },
  { headerName: 'Sold For', field: 'sold_for', colId: 'soldFor', type: 'money' },
  { headerName: 'Sales Date', field: 'creation_date', colId: 'salesDate', type: 'date' },
  { headerName: 'Rep', field: 'transaction.rep.initials', colId: 'rep' }
]

export const COLUMNS__MASTER_NESTED_QUOTES: Array<ColDef> = [
  {
    headerName: 'Quote',
    field: 'quote.id',
    colId: 'id',
    pinned: 'left',
    cellRenderer: (params: ICellRendererParams) => {
      const data: Quotes_QuoteParts = params.data
      const quote: Quotes_Quote = data.quote
      let iconClass = 'fas fa-check-circle transparent--text pr-2'
      const hasST = (quote?.attached_sales ?? []).length > 0
      if (hasST) iconClass = iconClass.replace('transparent', 'green')
      return `<i class="${iconClass}"></i>${params.value}`
    }
  },
  { headerName: 'Quoted Date', field: 'creation_date', colId: 'date', type: 'date' },
  { headerName: 'Price', field: 'price_per_part', colId: 'pricePerPart', type: 'money' },
  { headerName: 'Quantity Quoted', field: 'qty', colId: 'qty' },
  { headerName: 'Client', field: 'quote.client.name', colId: 'client' },
  { headerName: 'Contact', field: 'quote.contact.full_name', colId: 'contact' }
]

/* PURCHASES */
export const COLUMNS__PURCHASES: Array<ColDef> = [
  { headerName: 'Acceptance Date', type: 'date', field: fields.transactions.pt.acceptanceDate, colId: 'acceptanceDate' },
  { headerName: 'Approval Date', type: 'date', field: fields.transactions.pt.approvalDate, colId: 'approvalDate' },
  { headerName: 'Approved By', field: fields.transactions.pt.approvedBy, colId: 'approvedBy' },
  { headerName: 'Client', field: fields.generic.client, colId: 'client' },
  { headerName: 'Client Code', field: fields.generic.clientCode, colId: 'clientCode' },
  { headerName: 'Client ST', field: fields.transactions.pt.clientST, colId: 'clientSt' },
  { headerName: 'Closing Date', type: 'date', field: fields.transactions.pt.closingDate, colId: 'closingDate' },
  { headerName: 'Condition', field: fields.transactions.pt.condition, colId: 'condition' },
  { headerName: 'Contact', field: fields.generic.contact, colId: 'contact' },
  { headerName: 'Contract Sender', field: fields.transactions.pt.contractSender, colId: 'contractSender' },
  { headerName: 'Creator', field: fields.generic.creator, colId: 'creator' },
  { headerName: 'ETA', type: 'date', field: fields.transactions.pt.eta, colId: 'eta' },
  { headerName: 'Quote Number', field: fields.generic.quote },
  { headerName: 'Estimated Part Cost', field: 'estimated_part_total', colId: 'partTotal', type: 'money' },
  { headerName: 'Purchase Date', type: 'date', field: fields.transactions.pt.purchaseDate, colId: 'purchaseDate' },
  {
    headerName: 'PT',
    field: fields.generic.id,
    colId: 'pt',
    type: 'group',
    pinned: 'left',
    cellRendererParams: {
      innerRenderer: (params: ICellRendererParams) => {
        // let hotClass = 'space pr-2'
        let hotClass = 'fas fa-fire pr-2 transparent--text'
        if (params.data?.hot) {
          // renderee = renderee + `<i class="far fa-fire g-icon fire"></i>`
          hotClass = hotClass.replace('transparent', 'red')
        }
        return `<i class="${hotClass}"></i>${params.value}`
      }
    }
  },
  { headerName: 'REP', field: fields.generic.rep, colId: 'ptRep' },
  {
    headerName: 'Status',
    field: fields.generic.transactionStatus,
    colId: 'status',
    valueGetter: (params) => {
      const currentStatusName = params.data?.status?.status ?? ''
      const currentStatusId = params.data?.status?.id ?? 0
      // const receivedStatusId = PURCHASE_ORDER_STATUS.RECEIVED
      if (currentStatusId < 3) return currentStatusName
      else {
        const count = params.data?.annotations?.filter((a: AnnotationObject) => {
          return a.name === 'unReceivedCount'
        })?.[0]?.value ?? 'Error, refresh.'
        const unReceivedCount = parseInt(count)
        if (isNaN(count)) return { name: 'Error, refresh', value: '' }
        // if (unReceivedCount === 0) {
        //   if (currentStatusId !== 6) {
        //     return { name: 'All Parts Received', value: unReceivedCount }
        //   }
        //   return { name: 'Received', value: unReceivedCount }
        // } else {
        //   return { name: 'Unreceived', value: unReceivedCount }
        // }
        return { name: currentStatusName, value: unReceivedCount }
      }
    },
    cellRenderer: (params) => {
      if (params.value?.value === 0) {
        return params.value?.name ?? params.value
      } else if (params.value?.value > 0) {
        return `<div class="base warning-bar">${params.value.name}: <b>${params.value.value}</b></div>`
      } else {
        if (params.value === 'Approved') {
          return `<div class="base info-bar">${params.value}</div>`
        } else {
          return params.value
        }
      }
    }
  },
  { headerName: 'Terms', field: fields.generic.terms, colId: 'terms' },
  {
    headerName: 'Estimated Total Cost',
    field: fields.generic.total,
    type: 'money',
    colId: 'totalCost',
    valueGetter: params => params.data.estimated_total ?? 'Pending order completion...'
  },
  { headerName: 'Vendor Ref', field: fields.transactions.pt.vendorRef, colId: 'vendorRef' },
  { headerName: 'Warranty Expires', type: 'date', field: fields.generic.warrantyEnd, colId: 'warrantyExpires' }
]

export const COLUMNS__PURCHASES_NESTED: Array<ColDef> = [
  {
    headerName: 'Allocated',
    field: 'item.sale',
    colId: 'allocated',
    cellRenderer: BooleanCellRenderer,
    valueGetter: (params: ValueGetterParams) => {
      const data: Purchases_PurchasedItems = params.data
      return data.item?.sales_items_details?.id ?? false
    }
  },
  { headerName: 'Brand', field: fields.inventory.items.brand, colId: 'brand' },
  { headerName: 'Cost', field: fields.transactionItems.cost, colId: 'cost', type: 'money' },
  { headerName: 'Country of Origin', field: fields.transactionItems.countryOfOrigin, colId: 'countryOfOrigin' },
  { headerName: 'Is Sub', field: fields.transactionItems.sub, colId: 'isSub', cellRenderer: BooleanCellRenderer },
  { headerName: 'LN', field: fields.transactionItems.line, colId: 'lineNumber', pinned: 'left' },
  { headerName: 'Location', field: fields.transactionItems.location, colId: 'location' },
  { headerName: 'Mfgr', field: fields.inventory.items.mfgr, colId: 'mfgr' },
  partNumberColumn,
  { headerName: 'Received', field: fields.transactionItems.received, colId: 'received', cellRenderer: BooleanCellRenderer },
  { headerName: 'RMA', field: fields.transactionItems.prmaID, colId: 'rma' },
  { headerName: 'Serial Number', field: fields.transactionItems.serial, colId: 'serialNumber' },
  { headerName: 'Shipment Number', field: fields.transactionItems.shipmentID, colId: 'shipmentNumber' }
]

interface WatchedPurchaseItem extends Purchases_PurchasedItems {
  watch: {
    [key: string]: { value: any, isEdited: boolean }
  },
  delete: boolean
}

export const COLUMNS__PURCHASES_RECEIVING: Array<ColDef> = [
  { headerName: 'Line', field: 'line_number', width: 100, colId: 'lineNumber', pinned: 'left' },
  { headerName: 'Part Number', field: 'item.part.pn', colId: 'partNumber', pinned: 'left' },
  { headerName: 'Description', field: 'item.part.description', colId: 'description' },
  { headerName: 'ST #', field: 'item.saleItem.transaction.id', colId: 'st' },
  { // use the value setter to make updates how I want so that I can capture data the way I want
    headerName: 'Receive',
    field: 'item.receive_status',
    cellRenderer: 'checkbox',
    colId: 'receive',
    valueSetter: (params) => {
      const ptItem: WatchedPurchaseItem = params.data
      ptItem.item!.receive_status = params.newValue
      ptItem.watch.receive_status.isEdited = params.newValue !== ptItem.watch.receive_status.value
      return true
    }
  },
  {
    headerName: 'Delete',
    field: 'delete',
    cellRenderer: 'checkbox',
    colId: 'delete',
    valueSetter: params => {
      const ptItem: WatchedPurchaseItem = params.data
      ptItem.delete = params.newValue
      ptItem.watch.delete.isEdited = params.newValue !== ptItem.watch.delete.value
      return true
    }
  },
  {
    headerName: 'Serial Number',
    field: 'item.serial_number',
    colId: 'serialNumber',
    cellClass: 'editable',
    editable: true,
    valueSetter: params => {
      const ptItem: WatchedPurchaseItem = params.data
      if (params.newValue !== undefined) {
        ptItem.item!.serial_number = params?.newValue ?? ptItem.watch.serial_number.value
        ptItem.watch.serial_number.isEdited = params.newValue !== ptItem.watch.serial_number.value
        return true
      } return true
    },
    cellRenderer: (params) => {
      return `<div class="mt-2 bold">${params?.value ?? ''}</div>`
    }
  },
  {
    headerName: 'Location',
    field: 'item.location',
    colId: 'location',
    cellClass: 'editable',
    editable: true,
    cellEditor: 'locationCellEditor',
    cellRenderer: (params) => {
      const value = params?.value?.name ?? ''
      return `<div class="mt-2 bold">${value}</div>`
    },
    valueSetter: (params) => {
      const ptItem: WatchedPurchaseItem = params.data
      params.data.item.location = params.newValue
      ptItem.watch.location.isEdited = (params.newValue?.id ?? 0) !== ptItem.watch.location.value
      return true
    }
  },
  {
    headerName: 'Site',
    field: 'item.location.site.name',
    colId: 'site'
  },
  {
    headerName: 'Country of Origin',
    field: 'item.country_of_origin',
    colId: 'countryOfOrigin',
    editable: true,
    cellClass: 'editable',
    cellEditor: 'countryCellEditor',
    cellRenderer: (params) => {
      const name = params?.value?.name ?? ''
      const flag = params?.value?.flag ?? ''
      return `<span class="bold pr-2">${name}</span><span style="font-size: 30px">${flag}</spans>`
    },
    valueSetter: (params) => {
      const ptItem: WatchedPurchaseItem = params.data
      ptItem.item!.country_of_origin = params.newValue
      ptItem.watch.country_of_origin.isEdited = (params.newValue?.code ?? '') !== ptItem.watch.country_of_origin.value
      return true
    }
  },
  {
    headerName: 'Original Cost',
    field: 'original_cost.amount',
    colId: 'originalCost',
    type: 'money',
    cellClass: 'editable',
    editable: true,
    valueSetter: (params) => {
      const ptItem: WatchedPurchaseItem = params.data
      if (Number(params.newValue) >= 0) {
        ptItem.original_cost!.amount = params.newValue
        ptItem.watch.original_cost.isEdited = params.newValue !== ptItem.watch.original_cost.value
        return true
      }
      return false
    },
    cellRenderer: (params) => {
      return params.value || 0.00
    }
  }
]

/* PURCHASE RMAs */

export const COLUMNS__PURCHASE_RMAS: Array<ColDef> = [
  { headerName: 'Claim Date', field: fields.rmas.claimDate, colId: 'claimDate', type: 'date' },
  { headerName: 'Closed By', field: fields.rmas.closedBy, colId: 'closedBy' },
  { headerName: 'Closed Date', field: fields.rmas.closedDate, colId: 'closedDate', type: 'date' },
  { headerName: 'From PT', field: 'pt.id', colId: 'pt' },
  { headerName: 'Received By', field: fields.rmas.receiver, colId: 'receivedBy' },
  { headerName: 'RMA No.', field: fields.rmas.rma, type: 'group', colId: 'rma', pinned: 'left' },
  { headerName: 'Status', field: fields.generic.transactionStatus, colId: 'status' },
  { headerName: 'Vendor', field: fields.rmas.vendor, colId: 'vendor' }
]

export const COLUMNS__PURCHASE_RMAS_NESTED: Array<ColDef> = [
  { headerName: 'Description', field: fields.rmaItems.description, colId: 'description' },
  { headerName: 'Part Number', field: fields.rmaItems.partNumber, colId: 'partNumber', pinned: 'left' },
  { headerName: 'PT', field: fields.rmaItems.pt, colId: 'pt' },
  { headerName: 'Serial Number', field: fields.rmaItems.serial, colId: 'serialNumber' },
  { headerName: 'Tested Status', field: fields.rmaItems.tested, colId: 'testedStatus' }
]

/* WORK ORDERS */

export const COLUMNS__WORK_ORDERS: Array<ColDef> = [
  { headerName: 'WO', field: fields.generic.id, colId: 'wo', type: 'group' },
  { headerName: 'Date', field: fields.generic.creationDate, colId: 'date', type: 'date' },
  { headerName: 'Type', field: fields.transactions.wo.type, colId: 'type' },
  { headerName: 'Vendor', field: fields.transactions.wo.vendor, colId: 'vendor' },
  { headerName: 'Cost', field: 'cost', colId: 'cost', type: 'money' },
  { headerName: 'Requested Date', field: fields.transactions.wo.needBy, colId: 'requestedDate', type: 'date' },
  { headerName: 'Status', field: fields.transactions.wo.status, colId: 'status' },
  { headerName: 'Terms', field: fields.transactions.wo.terms, colId: 'terms' },
  { headerName: 'Created By', field: fields.generic.creator, colId: 'createdBy' },
  { headerName: 'Warranty End Date', field: fields.transactions.wo.warrantyEndDate, colId: 'warrantyEndDate', type: 'date' }
]

export const COLUMNS__WORK_ORDERS__NESTED: Array<ColDef> = [
  { headerName: 'Product', field: 'item.part.pn', colId: 'product', pinned: 'left' },
  { headerName: 'Description', field: 'item.part.description', colId: 'description' },
  { headerName: 'Cost', field: 'cost', colId: 'cost', type: 'money' }
]

/* SALES */

export const COLUMNS__SALES: Array<ColDef> = [
  { headerName: 'Booking Date', field: fields.transactions.st.bookDate, colId: 'bookingDate', type: 'date' },
  {
    headerName: 'ST',
    field: fields.generic.id,
    colId: 'st',
    pinned: 'left',
    type: 'group',
    cellRendererParams: {
      innerRenderer: (params: ICellRendererParams) => {
        const data: Sales_Sale = params.data
        let blindClass = 'fas fa-eye-slash pr-2 transparent--text'
        const blind = data?.shipment_order?.blind ?? false
        if (blind && blind !== 'NOT_BLIND') {
          blindClass = blindClass.replace('transparent', 'black')
        }
        return `<i class="${blindClass}"></i>${params.value}`
      }
    }
  },
  { headerName: 'Client', field: fields.generic.client, colId: 'client' },
  { headerName: 'Client Code', field: fields.generic.clientCode, colId: 'clientCode' },
  {
    headerName: 'Client PO',
    field: fields.transactions.st.clientPO,
    colId: 'clientPO',
    cellClass: (params) => {
      if (params?.value?.includes('PO NEEDED')) return 'error--text bold'
      else return ''
    }
  },
  { headerName: 'Comment', field: 'internal_comment', colId: 'internalComment', cellRenderer: 'commentRenderer', filter: false },
  {
    headerName: 'Contact',
    field: 'contact.first_name',
    colId: 'contact',
    valueGetter: params => {
      const order: SaleFragment = params.data
      return order?.contact?.full_name ?? ''
    }
  },
  { headerName: 'Contract Send By Date', field: fields.transactions.st.contractSendBy, colId: 'contractSendByDate', type: 'date' },
  { headerName: 'Estimated Cost', field: fields.transactions.st.estimatedCost, colId: 'estimatedCost', type: 'money' },
  {
    headerName: 'Estimated Margin',
    field: fields.transactions.st.estimatedMargin,
    colId: 'estimatedMargin',
    type: 'money'
  },
  { headerName: 'Open / Allocated', field: 'annotations', colId: 'openAllocated', cellRenderer: 'openAllocated', sortable: false, filter: false },
  { headerName: 'Promised By Date', field: fields.transactions.st.promiseDate, colId: 'promisedByDate', type: 'date', cellRenderer: DateCellRenderer },
  {
    headerName: 'Pickup Type',
    field: 'shipment_order.pickup_type',
    colId: 'pickupType',
    valueGetter: (params) => {
      const data: Sales_Sale = params.data
      const value = data.shipment_order?.pickup_type ?? false
      if (value) {
        if (value === 'NOT_PICKUP') {
          return '---'
        }
        return value
      } else {
        return ''
      }
    }
  },
  { headerName: 'Quote', field: fields.generic.quote, colId: 'quote' },
  { headerName: 'Sales Date', field: fields.transactions.st.salesDate, colId: 'salesDate', type: 'date' },
  { headerName: 'Sales Rep', field: fields.generic.rep, colId: 'stRep' },
  { headerName: 'Ship By Date', field: fields.transactions.st.shipBy, colId: 'shipByDate', type: 'date' },
  { headerName: 'Status', field: fields.generic.transactionStatus, colId: 'status' },
  { headerName: 'Tracking Number', field: fields.transactions.st.tracking, colId: 'trackingNumber' },
  { headerName: 'Terms', field: fields.generic.terms, colId: 'terms' },
  { headerName: 'Total', field: fields.generic.total, colId: 'total', type: 'money' },
  { headerName: 'Warranty End Date', field: fields.generic.warrantyEnd, colId: 'warrantyEndDate', type: 'date' }
]

export const COLUMNS__SALES_NESTED: Array<ColDef> = [
  { headerName: 'Brand', field: fields.inventory.items.brand, colId: 'brand' },
  { headerName: 'Description', field: fields.inventory.items.description, colId: 'description' },
  { headerName: 'Current Cost', field: fields.transactionItems.stCost, colId: 'currentCost', type: 'money' },
  { headerName: 'LN', field: fields.transactionItems.line, colId: 'lineNumber', pinned: 'left' },
  { headerName: 'Mfgr', field: fields.inventory.items.mfgr, colId: 'mfgr' },
  partNumberColumn,
  {
    headerName: 'PT',
    field: 'item',
    colId: 'pt',
    valueGetter: (params: ValueGetterParams): string => {
      const data: Sales_SoldItems = params.data
      if (data) {
        const purchase: Purchases_PurchasedItems | undefined = data?.item?.purchases_items_details ?? undefined
        const hasItem = data.item
        const pt = `${purchase?.transaction.id ?? ''}-${purchase?.line_number ?? ''}`
        return hasItem ? pt : ''
      } return ''
    }
  },
  { headerName: 'Received', field: fields.transactionItems.received, colId: 'received', cellRenderer: BooleanCellRenderer },
  { headerName: 'RMA No.', field: fields.transactionItems.rmaID, colId: 'rma' },
  { headerName: 'Serial Number', field: fields.transactionItems.serial, colId: 'serialNumber' },
  { headerName: 'Sold Price', field: fields.transactionItems.soldFor, colId: 'soldPrice', type: ENUM__COLUMN_TYPE.money },
  { headerName: 'Status', field: fields.transactionItems.status, colId: 'status' }
]

/* SALES RMA */

export const COLUMNS__SALES_RMA: Array<ColDef> = [
  { headerName: 'Contact', field: fields.generic.contact, colId: 'contact' },
  { headerName: 'Claim Date', field: fields.rmas.claimDate, colId: 'claimDate', type: 'date' },
  { headerName: 'For ST', field: fields.rmas.st, colId: 'forSt' },
  { headerName: 'Exp. Warranty Honored', field: fields.rmas.warranty, colId: 'expiredWarrantyHonored', cellRenderer: BooleanCellRenderer },
  { headerName: 'Return Code', field: fields.rmas.returnCode, colId: 'returnCode' },
  { headerName: 'Return Details', field: fields.rmas.returnReason, colId: 'returnDetails' },
  { headerName: 'RMA No.', field: fields.rmas.rma, colId: 'rma', type: 'group', pinned: 'left' },
  { headerName: 'Opened By', field: fields.rmas.openedBy, colId: 'openedBy' },
  { headerName: 'Opened Date', field: fields.rmas.openedDate, colId: 'openedDate', type: 'date' },
  { headerName: 'Receiver', field: fields.rmas.receiver, colId: 'receiver' },
  { headerName: 'Received Date', field: fields.rmas.receivedDate, colId: 'receivedDate', type: 'date' },
  { headerName: 'Verifier', field: fields.rmas.verifier, colId: 'verifier' },
  { headerName: 'Verified Date', field: fields.rmas.verifiedDate, colId: 'verifiedDate', type: 'date' },
  { headerName: 'Tester', field: fields.rmas.tester, colId: 'tester' },
  { headerName: 'Tested Date', field: fields.rmas.testedDate, colId: 'testedDate', type: 'date' },
  { headerName: 'Voider', field: fields.rmas.voider, colId: 'voider' },
  { headerName: 'Voided Date', field: fields.rmas.voidedDate, colId: 'voidedDate', type: 'date' },
  { headerName: 'Closed By', field: fields.rmas.closedBy, colId: 'closedBy' },
  { headerName: 'Closed Date', field: fields.rmas.closedDate, colId: 'closedByDate', type: 'date' },
  { headerName: 'Status', field: fields.generic.transactionStatus, colId: 'state' }
]

export const COLUMNS__SALES_RMA_NESTED: Array<ColDef> = [
  { headerName: 'Part Number', field: fields.rmaItems.partNumber, colId: 'partNumber', pinned: 'left' },
  { headerName: 'Serial Number', field: fields.rmaItems.serial, colId: 'serialNumber' },
  { headerName: 'Description', field: fields.rmaItems.description, colId: 'description' },
  { headerName: 'Tested', field: fields.rmaItems.tested, colId: 'tested' },
  { headerName: 'ST', field: fields.rmaItems.st, colId: 'st' }
]

/* QUOTES */

export const COLUMNS__QUOTES: Array<ColDef> = [
  {
    headerName: 'Quote',
    field: 'id',
    colId: 'id',
    pinned: 'left',
    type: 'group',
    cellRendererParams: {
      innerRenderer: (params: ICellRendererParams) => {
        const data: Quotes_Quote = params.data
        let iconClass = 'fas fa-check-circle transparent--text pr-2'
        const sales = data.attached_sales ?? []
        if (sales.length > 0) {
          iconClass = iconClass.replace('transparent', 'green')
        }
        return `<i class="${iconClass}"></i>${params.value}`
      },
      suppressDoubleClickExpand: true
    }
  },
  { headerName: 'Client', field: fields.generic.client, colId: 'client' },
  { headerName: 'Contact', field: fields.generic.contact, colId: 'contact' },
  { headerName: 'Rep', field: fields.generic.creator, colId: 'rep' },
  { headerName: 'Quoted Date', field: fields.generic.creationDate, colId: 'quotedDate', type: 'date' },
  { headerName: 'Quoted Price', field: fields.quotes.totalPrice, colId: 'totalPrice', type: 'money' }
]

export const COLUMNS__QUOTES_NESTED: Array<ColDef> = [
  { headerName: 'Description', field: fields.inventory.items.description, colId: 'description' },
  partNumberColumn,
  { headerName: 'Price/Part', field: fields.quotes.pricePerPart, colId: 'pricePerPart', type: 'money' },
  { headerName: 'Quantity', field: fields.quotes.qty, colId: 'quantity' },
  { headerName: 'Quoting Rep', field: fields.generic.creator, colId: 'rep' },
  { headerName: 'Total for Parts', field: fields.quotes.totalPartPrice, colId: 'totalPartsMoney', type: 'money' }
]

/* CLIENTS */

export const COLUMNS__CLIENTS: Array<ColDef> = [
  {
    headerName: 'Client Name',
    field: fields.clients.name,
    colId: 'client',
    pinned: 'left',
    type: 'group',
    cellRendererParams: {
      innerRenderer: (params: ICellRendererParams) => {
        const data: Clients_Client = params.data
        if (data) {
          if (!data.allow_sales) {
            return `${data.name} <i class="fas fa-times" style="color: #FC3200"></i>`
          } else {
            return `${data.name}`
          }
        }
      }
    }
  },
  { headerName: 'Client Code', field: fields.clients.sName, colId: 'clientCode' },
  {
    headerName: 'Client Rating',
    field: fields.clients.rating,
    colId: 'clientRating',
    cellRenderer: (params: ICellRendererParams) => {
      const count = params.value > -1 ? params.value : 0
      const color = (c: number) => {
        switch (c) {
          case 0:
            return 'error--text'
          case 1:
            return 'error--text'
          case 2:
            return 'orange--text'
          case 3:
            return 'yellow--text text--darken-2'
          case 4:
            return 'primary--text'
          case 5:
            return 'success--text text--darken-2'
        }
      }
      const c = color(count)
      const filled: string = `<i class="fas fa-star ${c}"></i>`
      const unfilled: string = '<i class="fal fa-star error--text"></i>'
      return filled.repeat(count) + unfilled.repeat(5 - count)
    }
  },
  { headerName: 'Client Type', field: fields.clients.type, colId: 'clientTYpe' },
  { headerName: 'Credit Limit', field: fields.clients.cl, colId: 'creditLimit', type: 'money' },
  { headerName: 'e-Store', field: 'ecommerce_store', colId: 'ecommerceStore' },
  { headerName: 'Current Credit', field: fields.clients.credit, colId: 'currentCredit', type: 'money' },
  { headerName: 'Managing Rep', field: fields.clients.rep, colId: 'rep' },
  { headerName: 'Outstanding Invoice Limit', field: fields.clients.outStandInvoiceLimit, colId: 'outstandingLimit', type: 'money' },
  { headerName: 'Preferred Payment Method', field: fields.clients.payment, colId: 'preferredPaymentMethod' },
  { headerName: 'Website', field: fields.clients.website, colId: 'website' }
]

export const COLUMNS__CLIENT_NESTED_CONTACTS: Array<ColDef> = [
  { headerName: 'First Name', field: 'first_name', colId: 'first', pinned: 'left' },
  { headerName: 'Last Name', field: 'last_name', colId: 'last', pinned: 'left' },
  { headerName: 'Email', field: 'email', colId: 'email' },
  { headerName: 'Phone Number', field: 'phone', colId: 'phone' },
  { headerName: 'Fax', field: 'fax', colId: 'fax' },
  { headerName: 'Default Billing', field: 'default_billing_for_client', colId: 'billing', cellRenderer: BooleanCellRenderer, type: 'no-filter' },
  { headerName: 'Default Remit', field: 'default_remit_for_client', colId: 'remit', cellRenderer: BooleanCellRenderer, type: 'no-filter' },
  { headerName: 'Default Ship To', field: 'default_ship_to_client', colId: 'shipTo', cellRenderer: BooleanCellRenderer, type: 'no-filter' }
]

export const COLUMNS__CLIENT_NESTED_ADDRESSES: Array<ColDef> = [
  { headerName: 'Nick Name', field: 'building_name', colId: 'buildingName' },
  { headerName: 'Street Line 1', field: 'street_1', colId: 'street1', pinned: 'left' },
  { headerName: 'Street Line 2', field: 'street_2', colId: 'street2' },
  { headerName: 'City', field: 'city', colId: 'city', pinned: 'left' },
  { headerName: 'State', field: 'state', colId: 'state' },
  { headerName: 'Country', field: 'country.name', colId: 'countryName' },
  { headerName: 'Zip Code', field: 'zip_code', colId: 'zipCode' },
  { headerName: 'Residential', field: 'residential', colId: 'residential', cellRenderer: BooleanCellRenderer },
  { headerName: 'Default Contact', field: 'default_contact.full_name', colId: 'contact' },
  { headerName: 'Verified', field: 'easypost_id', colId: 'verified', cellRenderer: BooleanCellRenderer, type: 'no-filter' }
]

export const COLUMNS__CLIENT_NESTED_ACCOUNTS: Array<ColDef> = [
  { headerName: 'Name', field: 'name', colId: 'name', pinned: 'left' },
  { headerName: 'Number', field: 'number', colId: 'number' },
  { headerName: 'Shipper', field: 'shipper.name', colId: 'shipper' }
]

export const COLUMNS__CLIENT_NESTED_TERMS: Array<ColDef> = [
  { headerName: 'Term Name', field: 'terms.name', colId: 'termName', pinned: 'left' },
  { headerName: 'PT Default', field: 'pt_default', cellRenderer: BooleanCellRenderer, colId: 'ptDefault' },
  { headerName: 'ST Default', field: 'st_default', cellRenderer: BooleanCellRenderer, colId: 'stDefault' }
]

export const COLUMNS__CLIENT_NESTED_PURCHASES: Array<ColDef> = [
  { headerName: 'PT', field: 'id', colId: 'pt', pinned: 'left' },
  { headerName: 'Date', field: 'purchase_date', colId: 'purchaseDate', type: 'date' },
  { headerName: 'REP', field: 'rep.initials', colId: 'rep' },
  { headerName: 'Total', field: 'total', colId: 'total', type: 'money' }
]

export const COLUMNS__CLIENT_NESTED_SALES: Array<ColDef> = [
  { headerName: 'ST', field: 'id', colId: 'st', pinned: 'left' },
  { headerName: 'Date', field: 'sale_date', colId: 'date', type: 'date' },
  { headerName: 'REP', field: 'rep.initials', colId: 'rep' },
  { headerName: 'Cost', field: 'estimated_cost', colId: 'cost', type: 'money' },
  { headerName: 'Margin', field: 'estimated_margin', colId: 'margin', type: 'money' },
  { headerName: 'Total', field: 'total', colId: 'total', type: 'money' }
]

/* BUY SELL HISTORY */

export const COLUMNS__BUY_SELL_HISTORY: Array<ColDef> = [
  { headerName: 'Client', field: fields.inventory.items.client, colId: 'client' },
  { headerName: 'Current Cost', field: fields.inventory.items.currentCost, colId: 'currentCost', type: 'money' },
  { headerName: 'Original Cost', field: fields.inventory.items.originalCost, colId: 'originalCost', type: 'money' },
  getPurchaseOrSaleColumn('pt'),
  { headerName: 'Condition', field: fields.inventory.items.condition, colId: 'condition' },
  { headerName: 'PT Rep', field: fields.inventory.items.ptRep, colId: 'ptRep' },
  { headerName: 'Purchase Date', field: fields.inventory.items.purchaseDate, colId: 'pDate', type: 'date' },
  { headerName: 'Received Status', field: 'receive_status', colId: 'receiveStatus', cellRenderer: BooleanCellRenderer },
  { headerName: 'Sale Date', field: fields.inventory.items.salesDate, colId: 'sDate', type: 'date' },
  {
    headerName: 'Sold Price',
    field: fields.inventory.items.soldFor,
    colId: 'soldPrice',
    type: 'money',
    cellClass: function (params) {
      return params.data && params.data.sales_items_details ? helpers.countDaysFrom(params.data.sales_items_details.transaction.sale_date) : params.value
    }
  },
  getPurchaseOrSaleColumn('st'),
  { headerName: 'ST Rep', field: fields.inventory.items.stRep, colId: 'stRep' },
  { headerName: 'Vendor', field: fields.inventory.items.vendor, colId: 'vendor' }
]

/* ACCOUNTING */

export const COLUMNS__ACCOUNTING_GL__BULK: Array<ColDef> = [
  { headerName: 'Transaction ID', field: 'transaction.id', colId: 'transactionId', pinned: 'left', sort: 'desc' },
  { headerName: 'Entry', field: 'id', colId: 'entryId' },
  { headerName: 'Source', field: 'source.name', colId: 'sourceName' },
  { headerName: 'Date', field: 'created_date', colId: 'date', type: 'date' },
  { headerName: 'Reference', field: 'transaction.reference', colId: 'reference' },
  { headerName: 'Order Number', field: 'order', colId: 'orderNumber' },
  { headerName: 'Invoice ID', field: 'invoice', colId: 'invoice' },
  { headerName: 'Account', field: 'account.number', colId: 'accountNumber' },
  { headerName: 'Account Name', field: 'account.name', colId: 'accountName' },
  { headerName: 'Amount', field: 'amount', colId: 'amount', type: 'money' },
  { headerName: 'Linked Transaction', field: 'internal_reference', colId: 'linkedTransaction' },
  // { headerName: 'Posted', field: 'posted', colId: 'posted', cellRenderer: BooleanCellRenderer },
  { headerName: 'Reviewed', field: 'was_reviewed', colId: 'reviewed', cellRenderer: BooleanCellRenderer }
]

export const COLUMNS__ACCOUNTING_TRANSACTIONS: Array<ColDef> = [
  { headerName: 'Transaction ID', field: 'id', colId: 'id', sort: 'desc', pinned: 'left' },
  { headerName: 'Date', field: 'creation_date', colId: 'date', type: 'date' },
  { headerName: 'Linked Transaction', field: 'internal_reference', colId: 'linkedTransaction' },
  { headerName: 'Reference', field: 'reference', colId: 'reference' },
  { headerName: 'Is Balanced', field: 'is_balanced', colId: 'isBalanced', cellRenderer: BooleanCellRenderer }
]

export const COLUMNS__ACCOUNTING_ENTRIES: Array<ColDef> = [
  { headerName: 'Entry Id', field: 'id', colId: 'entryId', pinned: 'left' },
  { headerName: 'Type', field: 'entry_type', colId: 'entryType' },
  { headerName: 'Account Name', field: 'account.name', colId: 'accountName' },
  { headerName: 'Account', field: 'account.number', colId: 'accountNumber' },
  { headerName: 'Amount', field: 'amount', colId: 'amount', type: 'money' }
]

export const COLUMNS__ACCOUNTING_AR: Array<ColDef> = [
  { headerName: 'Client Name', field: 'order.client.name', colId: 'clientName' },
  { headerName: 'Client Code', field: 'order.client.short_name', colId: 'clientShortName' },
  { headerName: 'Invoice No.', field: 'id', colId: 'id', type: 'group', pinned: 'left' },
  { headerName: 'ST', field: 'order.id', colId: 'st' },
  { headerName: 'Date', field: 'date_sent', colId: 'date', type: 'date' },
  { headerName: 'PT', field: 'order.id', colId: 'st' },
  {
    headerName: 'Due Date',
    field: 'date_due',
    colId: 'dueDate',
    type: ENUM__COLUMN_TYPE.date,
    cellClass: function (params) {
      return params.data && params.data.date_due ? helpers.countDaysFrom(params.data.date_due) : params.value
    }
  },
  // { headerName: 'Pain in Full Date', field: 'paid_in_full_date', colId: 'paidInFullDate', type: 'date' },
  // { headerName: 'Voided Date', field: 'voided_date', colId: 'voidedDate', type: 'date' },
  { headerName: 'Status', field: 'status.status', colId: 'posted' },
  { headerName: 'Voided', field: 'is_voided', colId: 'voided', cellRenderer: params => BooleanCellRenderer(params, 'error') },
  { headerName: 'REP', field: 'order.rep.initials', colId: 'repInitials' },
  { headerName: 'Invoice Total', field: 'amount', colId: 'invoiceTotal', type: 'money' },
  { headerName: 'Amount Paid', field: 'paid_posted', colId: 'amountPaid', type: 'money' },
  {
    headerName: 'Amount Due',
    field: 'amount_due',
    colId: 'amountDue',
    type: 'money'
  }
  // { headerName: 'Reviewed', field: 'reviewed', colId: 'reviewed', cellRenderer: BooleanCellRenderer }
]

export const COLUMNS__ACCOUNTING_AR_NESTED: Array<ColDef> = [
  { headerName: 'Amount', field: 'amount', colId: 'amount', type: 'money' },
  { headerName: 'Recorded Date', field: 'creation_date', colId: 'creationDate', type: 'date' },
  { headerName: 'Recorder', field: 'creator.initials', colId: 'creator' },
  { headerName: 'Payment ID', field: 'id', colId: 'id' },
  { headerName: 'Posted', field: 'is_posted', colId: 'isPosted', cellRenderer: params => BooleanCellRenderer(params, 'successAlert') },
  { headerName: 'Reference', field: 'reference', colId: 'reference' }
]

export const COLUMNS__ACCOUNTING_AP: Array<ColDef> = [
  { headerName: 'Amount', field: 'amount', colId: 'amount', type: ENUM__COLUMN_TYPE.money },
  { headerName: 'Client Name', field: 'order.client.name', colId: 'clientName' },
  { headerName: 'Client Code', field: 'order.client.short_name', colId: 'clientShortName' },
  { headerName: 'Recorded Date', field: 'creation_date', colId: 'creationDate', type: ENUM__COLUMN_TYPE.date },
  {
    headerName: 'Due Date',
    field: 'date_due',
    colId: 'dueDate',
    type: ENUM__COLUMN_TYPE.date,
    cellClass: function (params) {
      return params.data && params.data.date_due ? helpers.countDaysFrom(params.data.date_due) : params.value
    }
  },
  { headerName: 'Date Received', field: 'date_sent', colId: 'dateSent', type: ENUM__COLUMN_TYPE.date },
  { headerName: 'Invoice No.', field: 'id', colId: 'id', type: ENUM__COLUMN_TYPE.group },
  { headerName: 'Paid', field: 'is_paid', colId: 'isPaid', cellRenderer: BooleanCellRenderer },
  { headerName: 'Voided', field: 'is_voided', colId: 'isVoided', cellRenderer: BooleanCellRenderer },
  { headerName: 'PT', field: 'order.id', colId: 'orderId' },
  { headerName: 'unPosted Paid', field: 'paid_not_posted', colId: 'paidNotPosted', type: ENUM__COLUMN_TYPE.money },
  { headerName: 'Posted Paid', field: 'paid_posted', colId: 'paidPosted', type: ENUM__COLUMN_TYPE.money },
  { headerName: 'Reference', field: 'reference', colId: 'reference' },
  { headerName: 'Status', field: 'status.choice', colId: 'statusChoice' },
  { headerName: 'Total Paid', field: 'total_paid', colId: 'totalPaid', type: ENUM__COLUMN_TYPE.money }
]

export const COLUMNS__ACCOUNTING_AP_NESTED: Array<ColDef> = COLUMNS__ACCOUNTING_AR_NESTED

export const COLUMNS__ACCOUNTING_COA: Array<ColDef> = [
  { headerName: 'Name', field: 'name', colId: 'accountName', pinned: 'left', type: 'group' },
  { headerName: 'Number', field: 'account_number', colId: 'accountNumber', pinned: 'left' },
  { headerName: 'Description', field: 'description', colId: 'accountDescription' },
  { headerName: 'Type', field: 'account_type', colId: 'type' },
  { headerName: 'Debits', field: 'total_debit', colId: 'totalDebit', type: 'money' },
  { headerName: 'Credits', field: 'total_credit', colId: 'totalCredit', type: 'money' },
  { headerName: 'Running Total', field: 'account_total', colId: 'total', type: 'money' }
]

/* BREAK DOWN */

export const COLUMNS__BREAK_DOWN: ColDef[] = [
  { headerName: 'ID', field: 'id', colId: 'id', type: [ENUM__COLUMN_TYPE.group, ENUM__COLUMN_TYPE.noFloatingFilter] },
  { headerName: 'Assignee', field: 'assigned_to.initials', colId: 'assignedToInitials' },
  {
    headerName: 'Item Count',
    field: 'annotations',
    colId: 'itemCount',
    valueGetter: params => {
      const value = params.data?.annotations?.find((a: AnnotationObject) => a.name === 'itemCount')?.value ?? 0
      const string = `Item${value === 1 ? '' : 's'}`
      return `${value} ${string}`
    }
  },
  { headerName: 'Created', field: 'creation_date', colId: 'creationDate', type: 'date' },
  { headerName: 'Creator', field: 'creator.initials', colId: 'creatorInitials' },
  { headerName: 'Status', field: 'status.status', colId: 'status' },
  { headerName: 'System PN', field: 'system.part.pn', colId: 'systemPartNumber' },
  DynamicPtOrStColumn('system.purchases_items_details', orderType.pt, 'System PT #')
]

export const COLUMNS__BREAK_DOWN_NESTED: ColDef[] = [
  { headerName: 'Part Number', field: 'item.part.pn', colId: 'partNumber' },
  { headerName: 'Serial Number', field: 'item.serial_number', colId: 'serialNumber' },
  DynamicPtOrStColumn('item.purchases_items_details', orderType.pt, 'PT'),
  { headerName: 'Status', field: 'status.status', colId: 'status' },
  { headerName: 'Current Cost', field: 'item.purchases_items_details.current_cost', colId: 'currentCost', type: 'money' },
  { headerName: 'Original Cost', field: 'item.purchases_items_details.original_cost', colId: 'originalCost', type: 'money' }
]

/* Build Up */

export const COLUMNS__BUILD_UP: ColDef[] = [
  { headerName: 'ID', field: 'id', colId: 'id', type: 'group' },
  { headerName: 'Assignee', field: 'assigned_to.initials', colId: 'assignedToInitials' },
  {
    headerName: 'Item Count',
    field: 'annotations',
    colId: 'itemCount',
    valueGetter: params => {
      const value = params.data?.annotations.find((a: AnnotationObject) => a.name === 'itemCount')?.value ?? 0
      const string = `Item${value === 1 ? '' : 's'}`
      return `${value} ${string}`
    }
  },
  { headerName: 'Created', field: 'creation_date', colId: 'createdDate', type: 'date' },
  { headerName: 'Creator', field: 'creator.initials', colId: 'creatorInitials' },
  { headerName: 'Status', field: 'status.status', colId: 'status' },
  { headerName: 'System PN', field: 'system.part.pn', colId: 'systemPartNumber' },
  DynamicPtOrStColumn('system.purchases_items_details', orderType.pt, 'System PT #'),
  { headerName: 'System Serial #', field: 'system.serial_number', colId: 'serialNumber' }
]

export const COLUMNS__BUILD_UP_NESTED: ColDef[] = [
  { headerName: 'Part Number', field: 'item.part.pn', colId: 'partNumber' },
  { headerName: 'Serial Number', field: 'item.serial_number', colId: 'serialNumber' },
  DynamicPtOrStColumn('item.purchases_items_details', orderType.pt, 'PT'),
  { headerName: 'Status', field: 'status.status', colId: 'status' }
]

/* PERMISSIONS */

export const COLUMNS__USER_MANAGEMENT: ColDef[] = [
  { headerName: 'First Name', field: 'first_name', colId: 'firstName' },
  { headerName: 'Last Name', field: 'last_name', colId: 'lastName' },
  { headerName: 'Last Login', field: 'last_login', colId: 'lastLogin', type: 'date' },
  { headerName: 'Email', field: 'email', colId: 'email' },
  { headerName: 'Commission %', field: 'profile.commission_percentage', colId: 'commission_percentage' }
]

/* Reports */

export const COLUMNS__REPORTS: ColDef[] = [
  {
    headerName: 'Report Name',
    field: 'name',
    colId: 'name',
    checkboxSelection: true
  },
  { headerName: 'Description', field: 'description', colId: 'description' },
  { headerName: 'Chart Type', field: 'chart_type', colId: 'chartType' },
  { headerName: 'Chart Category', field: 'model', colId: 'model' },
  { headerName: 'Report Recipients', field: 'recipients', colId: 'recipients' }
]
