<template>
  <div>
    <v-container>
      <v-layout row wrap justify-start>
        <!-- Built in Wifi -->
        <v-flex xs3>
          <v-checkbox
            v-model="hasWifi"
            color="info"
            label="Includes WiFi?"
          ></v-checkbox>
        </v-flex>
        <!-- Wifi Standards -->
        <v-flex xs3 offset-xs1>
          <choice-list :config="wifiStandardsConfig" :disabled="!hasWifi"></choice-list>
        </v-flex>
        <!-- Personal Wifi Security -->
        <v-flex xs4 offset-xs1>
          <choice-list :config="personalWifiSecurityConfig" :disabled="!hasWifi"></choice-list>
        </v-flex>
        <!-- Enterprise Wifi Security -->
        <v-flex xs4>
          <choice-list :config="enterpriseWifiSecurityConfig" :disabled="!hasWifi"></choice-list>
        </v-flex>
        <!-- Has Web Management -->
        <v-flex xs3 offset-xs1>
          <v-checkbox
            v-model="hasWebManagement"
            color="info"
            label="Has web management?"
          ></v-checkbox>
        </v-flex>
        <!-- Wan Ports -->
        <v-flex xs3 offset-xs1>
          <v-text-field
            label="How many WAN Ports?"
            v-model="wanPorts"
          ></v-text-field>
        </v-flex>
        <!-- LAN Ports -->
        <v-flex xs3>
          <v-text-field
            v-model="lanPorts"
            label="LAN Ports?"
          ></v-text-field>
        </v-flex>
        <!-- Has VPN -->
        <v-flex xs3 offset-xs1>
          <v-checkbox
            v-model="hasVPN"
            label="Built in VPN?"
            color="info"
          ></v-checkbox>
        </v-flex>
        <v-flex xs3 offset-xs1>
          <v-text-field
            v-model="throughput"
            label="Throughput"
            suffix="Mbps"
          ></v-text-field>
        </v-flex>
      </v-layout>
    </v-container>
    <standard-actions
      @save="save"
      @cancel="cancel"
      @close="$emit('close')"
      :save-button="{ name: 'Submit', disabled: !canProceed }"
      :cancel-button="{ name: 'Back' }"
    ></standard-actions>
  </div>
</template>

<script>
import standardActions from '../standardActions'
import choices from '../../autocompletes/choices'

export default {
  name: 'networkRouter',
  components: {
    'standard-actions': standardActions,
    'choice-list': choices
  },
  computed: {
    canProceed () {
      return ((this.hasWifi && this.wifiStandardsConfig.id && this.personalWifiSecurityConfig.id &&
        this.enterpriseWifiSecurityConfig.id > -1) || !this.hasWifi) && this.wanPorts > 0 && this.throughput > 0
    }
  },
  data () {
    return {
      hasWifi: false,
      wifiStandardsConfig: {
        id: 0,
        label: 'Max Wifi Standard',
        appName: 'parts',
        modelName: 'access_point',
        fieldName: 'wifi_standards'
      },

      hasWebManagement: false,

      personalWifiSecurityConfig: {
        id: 0,
        label: 'Max consumer Wifi Security',
        appName: 'parts',
        modelName: 'access_point',
        fieldName: 'security'
      },

      enterpriseWifiSecurityConfig: {
        id: 0,
        label: 'Max enterprise Wifi Security',
        appName: 'parts',
        modelName: 'access_point',
        fieldName: 'security'
      },
      wanPorts: '',
      lanPorts: '',
      hasVPN: false,
      throughput: ''
    }
  },
  methods: {
    save () {
      const details = {
        is_web_management: this.hasWebManagement,
        has_wifi: this.hasWifi,
        wan_ports: this.wanPorts,
        lan_porst: this.lanPorts,
        built_in_vpn: this.hasVPN,
        wifi_standards_id: this.wifiStandardsConfig.id,
        personal_wireless_security_id: this.personalWifiSecurityConfig.id,
        enterprise_wireless_security_id: this.enterpriseWifiSecurityConfig.id,
        throughput: this.throughput
      }
      this.$emit('save', details)
    },

    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
