// module for storing state related to using the grid and its data

const state = {
  activeTab: '',
  rowToExpand: 0,
  buySellHistoryPart: {
    pn: '',
    id: 0
  },

  dialog: { app: '', component: '' },
  sheet: { app: '', component: '' },
  extended: false,
  hasFilter: false,
  dataForPrinting: []
}

const mutations = {
  setHasFilter (state, payload) {
    // set the hasFilter variable to the given boolean
    state.hasFilter = payload.bool
  },

  /**
   * Used to notify the gridwrapper which row to expand when creating new data for summary views
   * @param state
   * @param {Object} payload
   * @param {String || Number} payload.id
   */
  setRowToExpand (state, payload) {
    state.rowToExpand = payload.id
  },

  setDatForPrinting (state, payload) {
    state.dataForPrinting = payload.data
  },

  /**
   * Resets this state
   * @param state
   */
  resetRowToExpand (state) {
    state.rowToExpand = 0
  },

  setSaving (state, payload) {
    // set the saving boolean to the given value
    state.saving = payload.bool
  },

  setActiveTab (state, payload) {
    // set the active Tab to the given value
    state.activeTab = payload.activeTab
  },

  /**
   * Used for activating a dialog
   * @param state
   * @param payload
   * @param payload.app the name of the view hosting the dialog
   * @param payload.component the name of the child component in the dialog
   */
  setDialog (state, payload) {
    state.dialog = payload
  },

  /**
   * USed for activating a bottom sheet
   * @param state
   * @param payload
   */
  setSheet (state, payload) {
    state.sheet = payload
  },

  /**
   * Used to reset the active dialog
   * @param state
   */
  resetDialog (state) {
    state.dialog = { app: '', component: '' }
  },

  /**
   * Used to reset the active bottom sheet
   * @param state
   */
  resetSheet (state) {
    state.sheet = { app: '', component: '' }
  },

  switchExtended (state) {
    // flip the boolean
    state.extended = !state.extended
  },

  setBuySellPart (state, payload) {
    state.buySellHistoryPart = payload
  },

  resetBuySellPart (state) {
    for (const key of state.buySellHistoryPart) {
      state.buySellHistoryPart[key] = null
    }
  },

  setExtended (state, payload) {
    // change the extended state to the proper value
    state.extended = payload.extended
  }
}

const actions = {
  setBuySellPart ({ commit }, payload) {
    commit('setBuySellPart', payload)
  },

  resetBuySellPart ({ commit }) {
    commit('resetBuySellPart')
  },

  /**
   * Used to change the id of the row node to expand after creating new data
   * @param commit
   * @param {Object} payload
   * @param {Number} payload.id
   */
  changeRowToExpand ({ commit }, payload) {
    commit('setRowToExpand', payload)
  },

  prepareToPrintTags ({ commit }, payload) {
    commit('setDatForPrinting', payload)
    commit('setDialog', payload)
  },

  resetRowToExpand ({ commit }) {
    commit('resetRowToExpand')
  },

  changeHasFilter ({ commit }, payload) {
    commit('setHasFilter', payload)
  },

  changeActiveTab ({ commit }, payload) {
    commit('setActiveTab', payload)
  },

  /**
   * Used for committing changes to dialog
   * @param commit
   * @param payload
   * @param {string} payload.app
   * @param {string} payload.component
   */
  changeDialog ({ commit }, payload) {
    commit('notifications/resetAlert', null, { root: true })
    commit('setDialog', payload)
  },

  changeDialogToReports ({ dispatch }) {
    dispatch('changeDialog', { app: 'topbar', component: 'report-container', props: { fullscreen: true } })
  },

  /**
   * Used to reset the active dialog
   * @param commit
   */
  resetDialog ({ commit }) {
    commit('resetDialog')
    commit('notifications/resetAlert', null, { root: true })
    commit('data/setSaving', { bool: false }, { root: true })
  },

  /**
   * Action to setup a sheet
   * @param commit
   * @param payload
   */
  changeSheet ({ commit }, payload) {
    commit('notifications/resetAlert', null, { root: true })
    commit('setSheet', payload)
  },

  /**
   * Action to reset the sheet and alerts
   * @param commit
   */
  resetSheet ({ commit }) {
    commit('resetSheet')
    commit('notifications/resetAlert', null, { root: true })
    commit('data/setSaving', { bool: false }, { root: true })
    commit('data/SET_EDIT_ORDER', { id: '' }, { root: true })
  },

  switchExtended ({ commit }) {
    commit('switchExtended')
  },

  setExtended ({ commit }, payload) {
    commit('setExtended', payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
