import gql from 'graphql-tag'
import errorHandling from '../errorHandling'

export default {
  mixins: [errorHandling],
  methods: {
    /**
     * Apollo helper method to create a quote
     * @param quote
     * @param quote.client_id
     * @param quote.contact_id
     * @param quote.total_price
     * @param quote.type (PT/ST/WO)
     * @return {Promise<*>}
     */
    async createQuote (quote) {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`mutation createQuote ($input: Create__Quotes_Quote__Input!) {
            quote: Create__Quotes_Quote (input: $input) {
              id
            }
          }`,
          variables: { input: quote }
        })
        this.$store.dispatch('data/changeRefresh', { bool: true })
        return response.data.quote.id
      } catch (error) {
        this.saving = false
        throw new Error(this.errorHandler(error))
      }
    },

    /**
     * Updates information on a quote
     * @param quote
     * @return {Promise<Object>} quote
     */
    async updateQuote (quote) {
      try {
        await this.$apollo.mutate({
          mutation: gql`mutation UpdateQuote ($input: Update__Quotes_Quote__Input!) {
            quote: Update__Quotes_Quote(input: $input) {
              id
            }
          }`,
          variables: { input: quote }
        })
      } catch (error) {
        throw new Error(this.errorHandler(error))
      }
    },

    /**
     * Apollo Helper to create quote parts on a quote
     * BATCH: TRUE
     * @param {Object} part
     * @param {Number} part.part_id
     * @param {Number} part.price_per_part
     * @param {Number} part.qty
     * @param {Number} part.quote_id
     * @return {Promise<*>}
     */
    async createQuotePart (part) {
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`mutation CreateQuoteParts ($input: Create__Quotes_QuoteParts__Input!) {
            part: Create__Quotes_QuoteParts (input: $input) {
              id
              qty
              partPrice: price_per_part
              part {
                id
                pn
                description
                brand {
                  id
                  name
                }
                mfgr {
                  id
                  name
                }
              }
              total
            }
          }`,
          variables: { input: part }
        })
        return response.data.part
      } catch (error) {
        this.saving = false
        throw new Error(this.errorHandler(error))
      }
    }
  }
}
