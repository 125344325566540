<template>
  <div>
    <v-container>
      <v-layout row wrap justify-space-between>
        <!-- Has Wifi -->
        <v-flex xs4>
          <v-checkbox
            v-model="hasWifi"
            label="Wifi Capable?"
            color="info"
          ></v-checkbox>
        </v-flex>
        <!-- Wifi Standard -->
        <v-flex xs3>
          <choice-list :config="wifiStandardConfig"></choice-list>
        </v-flex>
        <!-- LAN Ports -->
        <v-flex xs3>
          <v-text-field
            v-model="lanPorts"
            label="No. of LAN Ports"
          ></v-text-field>
        </v-flex>
        <!-- LAN Port speed -->
        <v-flex xs3>
          <v-text-field
            v-model="lanPortSpeed"
            label="LAN Port(s) Speed"
            suffix="Mbps"
          ></v-text-field>
        </v-flex>
        <!-- POE Capable -->
        <v-flex xs3>
          <v-checkbox
            v-model="hasPOE"
            label="POE Capable?"
          ></v-checkbox>
        </v-flex>
      </v-layout>
    </v-container>
    <standard-actions
      @save="save"
      @cancel="cancel"
      @close="$emit('close')"
      :save-button="{ name: 'Submit', disabled: !canProceed }"
      :cancel-button="{ name: 'Back' }"
    ></standard-actions>
  </div>
</template>

<script>
import standardActions from '../standardActions'
import choices from '../../autocompletes/choices'

export default {
  name: 'ipPhone',
  components: {
    'standard-actions': standardActions,
    'choice-list': choices
  },
  computed: {
    canProceed () {
      return this.wifiStandardConfig.id && this.lanPorts > 0 && this.lanPortSpeed > 0
    }
  },
  data () {
    return {
      hasWifi: false,
      wifiStandardConfig: {
        id: 0,
        label: 'Wifi Standard',
        appName: 'parts',
        modelName: 'access_point',
        fieldName: 'wifi_standards'
      },

      lanPorts: null,
      lanPortSpeed: null,
      hasPOE: false
    }
  },
  methods: {
    save () {
      const details = {
        wifi_capable: this.hasWifi,
        wifi_standards_id: this.wifiStandardConfig.id,
        lan_ports: this.lanPorts,
        lan_port_speed_mbps: this.lanPortSpeed,
        peo_capable: this.hasPOE
      }
      this.$emit('save', details)
    },

    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
