<template>
    <v-alert
      data-cy="alert"
      class="t-alert"
      transition="scale-transition"
      :type="type"
      :value="message"
      :dismissible="dismissible"
      :class="[{ 'black--text': type === 'success' }]"
    >
      <slot name="message">
        {{ message }}
      </slot>
    </v-alert>
</template>

<script>
export default {
  name: 'tAlert',
  props: {
    message: {
      type: String,
      required: false,
      default: 'Set the message prop or use the slot "message"'
    },
    type: {
      type: String,
      required: true
    },
    dismissible: {
      type: Boolean,
      required: false,
      default: false
    },
    timeout: {
      type: Number,
      required: false,
      default: 5000
    }
  }
}
</script>
