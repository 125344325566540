<template>
  <v-autocomplete
    v-model="localId"
    item-text="choice"
    item-value="id"
    data-cy="choice-list"
    :attach="attach"
    :items="choices"
    :loading="loading"
    :filter="filter"
    :label="config.label"
    :rules="rules"
    :disabled="disabled"
    :error-messages="error"
  >
    <template
      slot="item"
      slot-scope="data"
    >
      <v-list-tile-content :data-cy="data.item.choice">
        <v-list-tile-title>{{ data.item.choice }}</v-list-tile-title>
        <v-list-tile-sub-title v-if="data.item.sub_choice">{{ data.item.sub_choice }}</v-list-tile-sub-title>
      </v-list-tile-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { GET_CHOICES_CHOICES } from '@/api/graphql/Constants/Choices'
export default {
  name: 'choices',
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    attach: {
      type: Boolean,
      required: false,
      default: false
    },
    config: {
      type: Object,
      required: true,
      validator: function (props) {
        let valid = true
        if (!props.label) {
          valid = false
        } else if (!props.appName) {
          valid = false
        } else if (!props.modelName) {
          valid = false
        } else if (!props.fieldName) {
          valid = false
        } else if (!props.name) {
          valid = false
        }
        return valid
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    error: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    localId: {
      get () {
        return this.id
      },
      set (value) {
        this.$emit('updated', { key: this.config.name, value: value })
      }
    }
  },
  data () {
    return {
      rules: [
        e => !!e || 'Select a choice'
      ],
      choices: [],
      loading: false
    }
  },
  apollo: {
    choices: {
      query: GET_CHOICES_CHOICES,
      variables () {
        return {
          input: {
            filters: [
              {
                key: 'app_name',
                value: this.config.appName
              },
              {
                key: 'model_name',
                value: this.config.modelName
              },
              {
                key: 'field_name',
                value: this.config.fieldName
              }
            ]
          }
        }
      },
      update: data => data.choices_choices,
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
  },
  methods: {
    filter (item, queryText) {
      const text = item.choice.toLowerCase()
      const search = queryText.toLowerCase()

      return text.indexOf(search) > -1
    }
  }
}
</script>
