<template>
  <v-card>
    <v-card-title class="brown">
      <h3 class="white--text">Shipping details for order: {{ order }}</h3>
    </v-card-title>
    <v-fade-transition>
      <v-card-text style="max-height: 800px; overflow-y: auto" v-if="!loading">
        <v-form ref="shipping">
          <v-container grid-list-xl>
            <h3>Addresses and Contacts</h3>
            <v-layout row wrap justify-start align-center>
              <!-- Ship From -->
              <v-flex xs4>
                <v-layout column justify-start align-space-around fill-height>
                  <v-flex xs12>
                    <standard-autofill
                      v-if="!isBlind"
                      :id="edited.shipFrom"
                      :items="shipFroms"
                      :config="shipFromConfig"
                      :load="loadingAddresses"
                      :error="errors.shipFrom.value"
                      @updated="update({ key: 'shipFrom', value: $event.value })"
                    />
                    <!--                    <addresses-->
                    <!--                      v-else-->
                    <!--                      :selected-address="edited.shipFrom"-->
                    <!--                      @update="update({ key: 'shipFrom', value: $event.value })"-->
                    <!--                    >-->
                    <!--                      <template #label>-->
                    <!--                        Ship From Address-->
                    <!--                      </template>-->
                    <!--                    </addresses>-->
                  </v-flex>
                  <v-flex xs12>
                    <standard-autofill
                      :id="edited.shipFromContact"
                      :items="shipFromContacts"
                      :load="loadingContacts"
                      :config="shipFromContactConfig"
                      @updated="update({ key: 'shipFromContact', value: $event.value })"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
              <!-- Ship To -->
              <v-flex xs4>
                <v-layout column justify-start align-space-around fill-height>
                  <v-flex xs12>
                    <standard-autofill
                      v-if="!isBlind"
                      :id="edited.shipTo"
                      :items="shipTos"
                      :config="shipToConfig"
                      :load="loadingAddresses"
                      :error="errors.shipTo.value"
                      @updated="update({ key: 'shipTo', value: $event.value })"
                    />
                    <!--                    <addresses-->
                    <!--                      v-else-->
                    <!--                      :selected-address="edited.shipTo"-->
                    <!--                      @update="update({ key: 'shipTo', value: $event.value })"-->
                    <!--                    >-->
                    <!--                      <template #label>-->
                    <!--                        Ship To Address-->
                    <!--                      </template>-->
                    <!--                    </addresses>-->
                  </v-flex>
                  <v-flex xs12>
                    <standard-autofill
                      :id="edited.shipToContact"
                      :items="shipToContacts"
                      :disabled="edited.blind !== 'NOT_BLIND'"
                      :load="loadingContacts"
                      :config="shipToContactConfig"
                      @updated="update({ key: 'shipToContact', value: $event.value })"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
              <!-- Bill To -->
              <v-flex xs4>
                <v-layout column justify-start align-space-around fill-height>
                  <v-flex xs12>
                    <standard-autofill
                      :id="edited.billTo"
                      :items="billTos"
                      :config="billToConfig"
                      :load="loadingAddresses"
                      :error="errors.billTo.value"
                      @updated="update({ key: 'billTo', value: $event.value })"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <standard-autofill
                      :id="edited.billToContact"
                      :items="billToContacts"
                      :load="loadingContacts"
                      :config="billToContactConfig"
                      @updated="update({ key: 'billToContact', value: $event.value })"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
          <v-container grid-list-xl>
            <h3 :class="{ 'black--text': edited.blind !== 'NOT_BLIND', 'grey--text': edited.blind === 'NOT_BLIND' }"
            >Blind Details</h3>
            <v-slide-y-transition>
              <keep-alive>
                <blind-details
                  v-if="edited.blind !== 'NOT_BLIND'"
                  :blind-form="form"
                  :has-errors="hasBlindErrors"
                  :shipment-order-id="shipmentId"
                />
              </keep-alive>
            </v-slide-y-transition>
          </v-container>
          <v-container grid-list-xl>
            <h3>Methods</h3>
            <v-layout row align-center justify-space-around fill-height wrap v-if="!loading">
              <!-- Is Local Pickup Checkbox -->
              <v-flex shrink>
                <v-checkbox
                  v-model="isPickup"
                  label="Courier/Customer Pickup"
                  color="red"
                />
              </v-flex>
              <!-- We Pay -->
              <v-flex shrink>
                <v-checkbox
                  v-model="edited.pay"
                  label="We Pay"
                  color="info"
                  :disabled="isPickup"
                />
              </v-flex>
              <!-- Customer Visibility -->
              <v-flex xs4 md3 lg2>
                <v-select
                  v-model="edited.blind"
                  :disabled="isPickup"
                  :items="[
                  { name: 'Standard', value: 'NOT_BLIND' },
                  { name: 'Blind', value: 'BLIND'},
                  { name: 'Double Blind', value: 'DOUBLE_BLIND'}
                ]"
                  label="Customer Visibility"
                  item-text="name"
                  item-value="value"
                  @change="blindChangeHandler"
                />
              </v-flex>
              <!-- Switch Selection: Priority/Service -->
              <v-flex xs6 md4 lg2>
                <v-select
                  v-model="selectService"
                  label="Ship Via"
                  :disabled="isPickup"
                  :items="[
                  { name: 'Service', value: true },
                  { name: 'Priority', value: false }
                ]"
                  item-text="name"
                  item-value="value"
                  :error-messages="selectServiceError"
                />
              </v-flex>
              <!-- Shipping Account -->
              <v-flex xs12 md4 lg3>
                <standard-autofill
                  :id="edited.account"
                  :disabled="!selectService || isPickup"
                  :items="accounts"
                  :load="loadingAccounts"
                  :config="shipAccountConfig"
                  :error="errors['account'].value"
                  @updated="update({ key: 'account', value: $event.value })"
                />
              </v-flex>
              <!-- Shipping Service -->
              <v-flex xs12 md4>
                <carrier-services
                  :disabled="!selectService || isPickup"
                  :service-id="edited.service"
                  :ship-to="shipToEpId"
                  :ship-from="shipFromEpId"
                  :bill-to="billToEpId"
                  :account="carrierAccountEpId"
                  :carrier="carrierName"
                  :error="errors['service'].value"
                  @update="update({ key: 'service', value: $event.value || 0 })"
                />
              </v-flex>
              <!-- Priority -->
              <v-flex shrink>
                <v-select
                  v-model="edited.priority"
                  :disabled="selectService || isPickup"
                  label="Priority Selection"
                  :items="[
                  { name: 'Cheapest', value: 'cheap' },
                  { name: 'By Date', value: 'date' }
                ]"
                  item-value="value"
                  item-text="name"
                  :error-messages="errors.priority.value"
                  @change="update({ key: 'priority', value: $event })"
                />
              </v-flex>
              <!-- Date Picker -->
              <v-flex xs4 lg3>
                <date-picker
                  :date="date"
                  :disabled="edited.priority !== 'date' || selectService || isPickup"
                  :config="dateConfig"
                  :errors="errors['date'].value"
                  @updated="update({ key: 'date', value: $event.value })"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-progress-linear v-else class="pt-0 mt-0" indeterminate color="purple"/>
    </v-fade-transition>
    <v-card-actions>
      <v-layout row justify-end align-center>
        <v-btn
          @click="cancel"
          :color="succeeded ? 'primary' : 'error'"
        >{{ succeeded ? 'Close' : 'Cancel' }}</v-btn>
        <v-btn
          v-if="!succeeded"
          @click="submit"
          color="success"
          :loading="saving"
        >Submit</v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import commonAutofillFilters from '../mixins/commonAutofillFilters'
import standard2 from '../autocompletes/standard2'
import datePicker from '../autocompletes/datePicker'
import carrierServices from '@/components/autocompletes/carrierServices'
import {
  CREATE_SHIPMENT_ORDER, GET_CARRIER_ACCOUNTS__AUTOFILL,
  GET_SHIPMENT_ORDER_DETAILS,
  UPDATE_SHIPMENT_ORDER
} from '@/api/graphql/Constants/Shipments'
import { GET_ADDRESSES__LIST } from '@/api/graphql/Constants/Addresses'
import { GetCompanyAndClientAddressesForList } from '@/api/graphql/Constants/CompanyAndClientAddress'
import {
  FRAGMENT__LIST_CONTACT,
  FRAGMENT__LIST_USER
} from '@/api/graphql/Constants/Contacts'
import {
  GET_PURCHASE_DETAILS_TO_MAKE_SHIPMENT_ORDER,
  GET_SALE_DETAILS_TO_MAKE_SHIPMENT_ORDER, UPDATE_SALE
} from '@/api/graphql/Constants/Orders'
import { GetAddressDetailsForAutofill } from '@/lib/gqlDataGetters'
import { apolloClient as apollo } from '@/api/graphql/apollo'
import gql from 'graphql-tag'
import BlindDetails from '@/components/forms/BlindDetails'
import { saveBlindStuff, useBlindShipping } from '@/composition/UseBlindShipping'
export default {
  name: 'changeShipping',
  mixins: [commonAutofillFilters],
  components: {
    'standard-autofill': standard2,
    'date-picker': datePicker,
    'carrier-services': carrierServices,
    'blind-details': BlindDetails
  },
  props: {
    shipmentId: {
      type: String,
      required: true
    },
    order: {
      type: String,
      required: true
    },
    orderType: {
      type: String, // sale or purchase
      required: true
    }
  },
  setup () {
    const action = ref('')
    const {
      form,
      hasErrors: hasBlindErrors
    } = useBlindShipping(action)

    return {
      form,
      hasBlindErrors
    }
  },
  computed: {
    shipToObject () {
      const id = this.edited.shipTo
      return this.shipTos.find(a => a.id === id)
    },

    shipFromObject () {
      const id = this.edited.shipFrom
      return this.shipFroms.find(a => a.id === id)
    },

    billToObject () {
      const id = this.edited.billTo
      return this.billTos.find(a => a.id === id)
    },

    shipToEpId () {
      if (!this.isPurchase && !this.isBlind) {
        return this.blindForm.address?.parsed?.easypost_id ?? ''
      } else {
        return this.shipToObject?.easypost_id ?? ''
      }
    },

    shipFromEpId () {
      return this.shipFromObject?.easypost_id ?? ''
    },

    billToEpId () {
      return this.billToObject?.easypost_id ?? ''
    },

    isPurchase () {
      return this.orderType === 'purchase'
    },

    blindContactOwner () {
      return this.edited?.blindContact?.owner?.id ?? 0
    },

    serviceHint () {
      return this.carrierServiceObject?.shipper?.name ?? ''
    },

    carrierServiceObject () {
      return this.shippingServices?.filter(s => {
        return s.id === this.edited.service
      })?.[0] ?? null
    },

    carrierAccountObject () {
      return this.accounts?.filter(a => {
        return a.id === this.edited.account
      })?.[0]
    },

    carrierAccountEpId () {
      return this.carrierAccountObject?.easypost_id ?? ''
    },

    carrierName () {
      return this.carrierAccountObject?.shipper?.name ?? ''
    },

    isDifferent () {
      for (const key in this.edited) {
        if (this.edited[key] !== this.original[key]) {
          return true
        }
      }
      return false
    },

    hasErrors () {
      for (const key in this.errors) {
        const value = this.errors[key]
        if (value?.value) {
          return true
        }
        if (value && typeof value === typeof 'value') {
          return true
        }
      }
      return !this.hasBlindErrors
    },

    priorityDateBinding () {
      const binding = {}
      binding.column = this.$vuetify.breakpoint.lgAndUp
      binding.row = !binding.column
      return binding
    },

    isBlind () {
      return this.edited.blind !== 'NOT_BLIND'
    }
  },
  watch: {
    selectService: function (value) {
      if (value) {
        this.date = ''
        this.priority = ''
      } else {
        this.edited.account = 0
        this.edited.service = 0
      }
      this.selectServiceError = ''
    },

    isPickup: function (value) {
      this.edited.account = 0
      this.edited.service = 0
      this.priority = ''
      this.date = ''
      this.edited.blind = 'NOT_BLIND'
      this.edited.priority = ''
      this.selectService = null
      this.getAddresses()
      this.getContacts()
    },

    priority: function (value) {
      if (value === 'cheap') {
        this.date = ''
      }
    },

    serviceHint: function (value) {
      if (value) this.shipServiceConfig.hint = `<span style="color: #FF9800; font-weight: bold;">${value}</span>`
      else this.shipServiceConfig.hint = ''
    }
  },
  data () {
    return {
      selectService: null,
      isPickup: false,

      saving: false,

      date: '',
      dateConfig: {
        label: 'Delivery Date',
        maxDate: ''
      },

      accounts: [],
      loadingAccounts: false,
      shipAccountConfig: {
        name: 'account',
        label: 'Shipping Account',
        clearable: true
      },

      original: {
        service: 0,
        account: 0,
        pay: false,
        pickupType: '',
        priority: '',
        date: '',
        blind: '',
        shipTo: 0,
        shipToContact: 0,
        shipFrom: 0,
        shipFromContact: 0,
        billTo: 0,
        billToContact: 0,
        blindClient: { id: 0 },
        blindContact: { id: 0, owner: { id: 0 } },
        returnAddress: 0
      },

      edited: {
        service: 0,
        account: 0,
        pay: false,
        pickupType: '',
        priority: '',
        date: '',
        blind: '',
        shipTo: 0,
        shipToContact: 0,
        shipFrom: 0,
        shipFromContact: 0,
        billTo: 0,
        billToContact: 0,
        blindClient: { id: 0 },
        blindContact: { id: 0, owner: { id: 0 } },
        returnAddress: 0
      },

      // services config
      shipTo: '',
      shipTos: [],
      shipToContacts: [],
      shipFrom: '',
      shipFroms: [],
      shipFromContacts: [],
      billTo: '',
      billTos: [],
      billToContacts: [],
      returnAddresses: [],
      loadingReturnAddresses: false,
      returnConfig: {
        name: 'returnAddress',
        label: 'Return Address',
        clearable: true
      },

      loadingContacts: false,
      loadingAddresses: false,

      shipFromConfig: {
        name: 'shipFrom',
        label: 'Ship From Address',
        clearable: true,
        hint: '<span class="emphasis">Required</span>'
      },
      shipFromContactConfig: {
        name: 'shipFromContact',
        label: 'Ship From Contact',
        clearable: true
      },

      shipToConfig: {
        name: 'shipTo',
        label: 'Ship To Address',
        clearable: true,
        hint: '<span class="emphasis">Required</span>'
      },
      shipToContactConfig: {
        name: 'shipToContact',
        label: 'Ship To Contact',
        clearable: true
      },

      billToConfig: {
        name: 'billTo',
        label: 'Billing Address',
        clearable: true,
        hint: '<span class="emphasis">Required</span>'
      },
      billToContactConfig: {
        name: 'billToContact',
        label: 'Billing Contact',
        clearable: true
      },

      shippingServices: [],
      loadingShippingServices: false,
      shipServiceConfig: {
        name: 'shippingService',
        label: 'Shipping Service',
        hint: ''
      },

      errors: {
        date: { value: '', validator: (e) => { return (this.edited.priority === 'date' && !e) ? 'Date is required' : '' } },
        shipFrom: { value: '', validator: (e) => { return e ? '' : 'This is required' } },
        shipFromContact: '', // not required
        shipTo: { value: '', validator: (e) => { return e ? '' : 'This is required' } },
        shipToContact: '', // not required
        billTo: { value: '', validator: (e) => { return e ? '' : 'This is required' } },
        billToContact: '', // not required
        account: { value: '', validator: (e) => { return (this.selectService && !e) ? 'When \'Ship Via Service" is selected, an account is required.' : '' } },
        service: { value: '', validator: (e) => { return (this.selectService && !e) ? 'When \'Ship Via Service" is selected, a service is required.' : '' } },
        pickupType: { value: '', validator: (e) => { return this.isPickup && e === 'NOT_PICKUP' ? 'Required when pickup is selected' : '' } },
        priority: { value: '', validator: (e) => { return (!this.isPickup && !this.selectService && e === '') ? 'Priority is required' : '' } }, // uses in-template rules
        blindClient: { value: '', validator: (e) => { return (this.edited.blind !== 'NOT_BLIND' && !e) ? 'Required when blind to select ship To' : '' } },
        returnAddress: ''
      },
      selectServiceError: '',
      skip: true,
      client: 0,
      loading: false,
      succeeded: false
    }
  },
  apollo: {
    accounts: {
      query: GET_CARRIER_ACCOUNTS__AUTOFILL,
      variables () {
        return {
          filters: [{
            key: !this.edited.pay ? 'client__id' : 'client__isnull',
            value: !this.edited.pay ? this.client : true
          }]
        }
      },
      update: (data) => {
        const accounts = data.shipping_accounts
        if (accounts.length > 0) {
          return accounts.map(a => ({
            ...a,
            subtitle: a.shipper.name
          }))
        }
      },
      watchLoading (isLoading) {
        this.loadingAccounts = isLoading
      }
    }
  },
  methods: {
    resultHandler ({ message, type }) {
      this.$store.dispatch('notifications/createSnackbar', {
        message: message,
        color: type,
        timeout: 4000
      })
      if (type === 'success') {
        this.succeeded = true
      }
    },

    cancel () {
      this.$emit('cancel')
    },

    submit () {
      this.dataValidator()
      if (!this.hasErrors && this.isDifferent) {
        this.saving = true
        if (this.shipmentId) this.save()
        else this.create()
      } if (!this.isDifferent) {
        this.resultHandler({ message: 'You have not made any changes.', type: 'warning' })
      }
    },

    createShipmentInputObject (id = 0, blindContactId = null, shipTo = this.edited.shipTo) {
      return {
        ...(id && { id: id }),
        ...(this.edited.shipTo && { ship_to_address_id: shipTo }),
        ship_to_contact_id: this.getShipToContact(blindContactId),
        ...(this.edited.shipFrom && { ship_from_address_id: this.edited.shipFrom }),
        ship_from_contact_id: this.getShipFromContact(),
        ...(this.edited.billTo && { purchaser_address_id: this.edited.billTo }),
        purchaser_contact_id: this.getBillToContact(),
        ...(this.edited.account && { account_id: this.edited.account }),
        ...(this.edited.service && { service_id: this.edited.service }),
        we_pay: this.edited.pay,
        ...(this.date && { deliver_by_date: this.date }),
        pickup_type: this.isPickup ? 'CUSTOMER' : 'NOT_PICKUP',
        blind: this.edited.blind
      }
    },

    async create () {
      let blind = { addressId: this.edited.shipTo, contactId: null }
      if (this.edited.blind) {
        blind = await saveBlindStuff(this.blindForm)
      }
      const newShipment = this.createShipmentInputObject(0, blind.contactId, blind.addressId)

      this.$apollo.mutate({
        mutation: CREATE_SHIPMENT_ORDER,
        variables: { input: newShipment }
      }).then(({ data: { order } }) => {
        this.$apollo.mutate({
          mutation: UPDATE_SALE,
          variables: { input: { id: this.order, shipment_order_id: order.id } }
        }).then(() => {
          this.$emit('save', order)
          this.resultHandler({ message: 'Created shipment order successfully', type: 'success' })
        })
      }).catch(() => {
        this.resultHandler({ message: 'Could not create shipment order, check info and try again', type: 'error' })
      }).finally(() => {
        this.saving = false
      })
    },

    async save () {
      let blind = { addressId: this.edited.shipTo, contactId: null }
      if (this.edited.blind) {
        blind = await saveBlindStuff(this.blindForm)
      }
      const editions = this.createShipmentInputObject(this.shipmentId, blind.contactId, blind.addressId)

      this.$apollo.mutate({
        mutation: UPDATE_SHIPMENT_ORDER,
        variables: { input: editions }
      }).then(({ data: { order } }) => {
        this.$emit('save', order)
        this.resultHandler({ message: 'Edited shipping details successfully!', type: 'success' })
      }).catch(() => {
        this.resultHandler({ message: 'Could not edit details, check info and try again', type: 'success' })
      }).finally(() => {
        this.saving = false
      })
    },

    getShipFromContact () {
      const isPt = this.orderType === 'purchase'
      const isSt = this.orderType === 'sale'
      if (isPt) { // if editing a shipment order attached to a purchase
        if (this.isBlind) {
          return null
        } else {
          return this.edited.shipFromContact
        }
      } else if (isSt) { // if editing a shipment order attached to a sale
        if (this.isBlind) {
          return this.edited.shipFromContact
        } else {
          return null
        }
      }
    },

    getShipToContact (blindContactId = null) {
      const isPt = this.orderType === 'purchase'
      const isSt = this.orderType === 'sale'
      if (isPt) { // if editing a shipment order attached to a purchase
        if (this.isBlind) {
          return this.edited.blindContact.id
        } else {
          return null
        }
      } else if (isSt) { // if editing a shipment order attached to a sale
        return blindContactId || this.edited.shipToContact
      }
    },

    getBillToContact () {
      const isPt = this.orderType === 'purchase'
      const isSt = this.orderType === 'sale'
      if (isPt) {
        return null
      } else if (isSt) {
        return this.edited.billToContact
      }
    },

    blindChangeHandler () {
      this.getAddresses()
      this.getContacts()
      if (this.edited.blind === 'NOT_BLIND') {
        if (this.edited.blindContact?.id) {
          this.edited.shipToContact = this.edited.blindContact.id
        }
        this.edited.blindContact = 0
        this.edited.blindClient = 0
        this.edited.returnAddress = 0
      } else {
        if (this.edited.shipToContact) {
          this.edited.blindContact = this.shipToContacts.find(c => c.id === this.edited.shipToContact)
          this.edited.shipToContact = 0
        }
      }
    },

    update ({ key, value }) {
      if (key === 'shipToContact' && this.edited.blind !== 'NOT_BLIND') {

      } else {
        if (key) {
          this.edited[key] = value
          if (this.errors[key]?.value !== undefined) this.errors[key].value = ''
          else this.errors[key] = ''
        }
        if (['shipTo', 'shipFrom', 'billTo'].indexOf(key) > -1) {
          if (value) {
            this[key] = this[`${key}s`].find(e => e.id === value).easypost_id
          } else this[key] = ''
        }
      }
    },

    // updateBlindClient (obj) {
    //   this.errors.blindClient.value = ''
    //   this.blindClient = obj
    // },

    setUpEditions () {
      for (const key in this.original) {
        this.edited[key] = this.original[key]
      }
    },

    dataValidator () {
      for (const key in this.errors) {
        // if it doesn't have an object under the key it isn't required and does not need to be processed
        if (this.errors[key].value !== undefined) {
          this.errors[key].value = this.errors[key].validator(this.edited[key])
        }
      }
      this.selectServiceError = (this.isPickup || this.selectService !== null) ? '' : 'This option is required'
    },

    GetCompanyAndClientUsersForList ({ client }) {
      return new Promise((resolve, reject) => {
        apollo.query({
          query: gql`
        query GetCompanyAndClientUsersForList ($client: Any!) {
          contacts: clients_contacts (input: { filters: [{ key: "owner__id", value: $client }] }) {
            ...ListContact
          }
          users: users_users (input: { limit: 1000 }) {
            ...ListUser
          }
        }
        ${FRAGMENT__LIST_CONTACT}
        ${FRAGMENT__LIST_USER}
      `,
          variables: { client: client }
        }).then(({ data: { contacts, users } }) => {
          resolve({ contacts, users })
        }).catch(() => { reject(new Error('le error')) })
      })
    },

    /* Contacts Stuff */
    getContacts () {
      this.loadingContacts = true
      this.GetCompanyAndClientUsersForList({ client: this.client })
        .then(({ contacts, users }) => {
          if ((this.isPurchase && this.isBlind) || (!this.isPurchase && !this.isBlind)) {
            this.shipToContacts = contacts
            this.shipFromContacts = users
          } else {
            this.shipToContacts = users
            this.shipFromContacts = contacts
          }
          this.billToContacts = this.isPurchase ? users : contacts
          this.loadingContacts = false
        }).catch(() => {
          this.loadingContacts = false
        })
    },

    /* Address Stuff */
    getAddresses () {
      const isNotBlind = this.edited.blind === 'NOT_BLIND'
      this.loadingAddresses = true
      GetCompanyAndClientAddressesForList({ client: this.client, noCompany: this.isBlind })
        .then(({ client, other }) => {
          if ((this.isPurchase && this.isBlind) || (!this.isPurchase && isNotBlind)) {
            this.shipTos = client
            this.shipFroms = other
          } else {
            this.shipTos = other
            this.shipFroms = client
          }
          this.billTos = this.isPurchase ? other : client
          this.loadingAddresses = false
        }).catch(() => {
          this.loadingAddresses = false
        })
    },

    async getReturnAddresses () {
      this.loadingReturnAddresses = true
      try {
        const input = () => {
          if (this.isPurchase) return { input: { filters: [{ key: 'client__id', value: this.client }] } }
          else return { input: { filters: [{ key: 'company__isnull', value: false }] } }
        }
        const response = await this.$apollo.query({
          query: GET_ADDRESSES__LIST,
          variables: input()
        })
        this.returnAddresses = response?.data?.address_addresses.map(a => {
          a.title = GetAddressDetailsForAutofill(a)
          return a
        }) ?? []
      } catch {
      } finally {
        this.loadingReturnAddresses = false
      }
    },

    getShipmentData () {
      this.$apollo.query({
        query: GET_SHIPMENT_ORDER_DETAILS,
        variables: { id: this.shipmentId }
      }).then(({ data: { order } }) => {
        this.original.account = order.account?.id ?? 0
        this.original.service = order.service?.id ?? 0
        this.client = order.sale?.client?.id ?? order.purchase?.client?.id ?? 0
        this.original.pickupType = order.pickupType
        this.skip = false
        this.original.pay = order.we_pay
        this.original.blind = order.blind
        this.original.shipTo = order.shipTo?.id ?? 0
        this.original.shipFromContact = order.shipFromContact?.id ?? 0
        this.original.shipFrom = order.shipFrom?.id ?? 0
        this.original.shipToContact = order.shipToContact?.id ?? 0
        this.original.billTo = order.billTo?.id ?? 0
        this.original.billToContact = order.billToContact?.id ?? 0
        this.isPickup = this.original.pickupType !== 'NOT_PICKUP'
        this.setUpEditions()

        this.getReturnAddresses()
        this.getAddresses()
        this.getContacts()

        this.shipTo = order.shipTo.easypost_id
        this.shipFrom = order.shipFrom.easypost_id
        this.billTo = order.billTo.easypost_id
        if (!this.isPickup) {
          if (!this.original.service) {
            this.selectService = false
            if (order.deliver_by_date) {
              this.date = order.deliver_by_date
              this.priority = 'date'
              this.original.priority = this.priority
            } else {
              this.priority = 'cheap'
              this.original.priority = this.priority
            }
          } else {
            this.selectService = true
            this.original.priority = ''
          }
        }
        this.loading = false
      })
    },

    getOrderData () {
      this.$apollo.query({
        query: this.isPurchase ? GET_PURCHASE_DETAILS_TO_MAKE_SHIPMENT_ORDER : GET_SALE_DETAILS_TO_MAKE_SHIPMENT_ORDER,
        variables: { id: this.order }
      }).then(({ data: { order } }) => {
        this.client = order.client.id
        this.original.blind = 'NOT_BLIND'

        this.setUpEditions()
        this.getReturnAddresses()
        this.getAddresses()
        this.getContacts()
        this.loading = false
      })
    }
  },
  created () {
    this.loading = true
    if (this.shipmentId) {
      this.getShipmentData()
    } else {
      this.getOrderData()
    }
  }
}
</script>

<style scoped>

</style>
