import gql from 'graphql-tag'
import { FRAGMENT__FULL_PART } from '@/api/graphql/Constants/Inventory'

export const CREATE_ALTERNATE_PART_LINK = gql`
  mutation CreateAltPartLink ($linker: ID!, $linkee: ID!, $clientId: ID!) {
    Update__Parts_Part (input: { id: $linker, alt_part_for_part_id: $linkee, alt_part_for_client_id: $clientId }) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`

export const UPDATE_PART = gql`
    mutation UpdatePart ($input: Update__Parts_Part__Input!) {
      Update__Parts_Part (input: $input) {
        ...FullPart
      }
    }
    ${FRAGMENT__FULL_PART}
`

export const GET_MASTER_PART_SUMMARY = gql`
  query MasterPartSummary ($id: ID!) {
    parts_part (input: { id: $id }) {
      ...FullPart
    }
  }
  ${FRAGMENT__FULL_PART}
`
