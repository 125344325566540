<template>
  <v-autocomplete
    v-model="selected"
    attach
    :items="shownServices"
    :loading="loading"
    :filter="filter"
    label="Carrier Services"
    :disabled="localDisabled || disabled"
    :hint="localDisabled ? hint : ''"
    :persistent-hint="localDisabled"
    :error-messages="errorMessage"
    data-cy="carrier-service"
    item-value="id"
    clearable
  >
    <template #selection="{ item }">
      <v-icon class="pr-2" small :color="item.color">fas fa-circle</v-icon>
      <span data-cy="service-selection">{{ item.text || item.service }}</span>
    </template>
    <template #item="{ item }">
        <v-list-tile-avatar>
          <v-icon v-if="item.color" small :color="item.color">fas fa-circle</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title>{{ item.text }}</v-list-tile-title>
          <v-list-tile-sub-title><b class="pr-2">{{ item.carrier }}</b>{{ item.date ? `| ${item.date}` : '' }}</v-list-tile-sub-title>
        </v-list-tile-content>
    </template>
    <template #prepend>
      <v-btn
        class="mt-0"
        icon
        data-cy="refresh-services"
        :disabled="localDisabled || loading || disabled"
        :loading="loading"
        @click="getCarrierServices()"
      >
        <v-icon
          size="22px"
          color="blue"
        >fal fa-sync</v-icon>
      </v-btn>
    </template>
  </v-autocomplete>
</template>

<script>
import mutateShipments from '@/api/graphql/mutationsJS/mutateShipments'
import { GET_SHIPPING_SERVICE_LEVELS } from '@/api/graphql/Constants/Shipments'
import { MakeServiceNamePretty } from '@/lib/helpers'
import { GetPrettyDate } from '@/lib/gqlDataGetters'
export default {
  name: 'carrierServices',
  props: {
    serviceId: {
      required: true
    },
    // easypost ship to
    shipTo: {
      type: String,
      required: true
    },
    // easypost ship from
    shipFrom: {
      type: String,
      required: true
    },
    // easypost bill to
    billTo: {
      type: String,
      required: true
    },
    // easypost carrier account
    account: {
      type: String,
      required: true
    },
    // carrier name
    carrier: {
      type: String,
      required: true
    },
    error: {
      type: String,
      required: true
    },
    hint: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mixins: [mutateShipments],
  computed: {
    errorMessage () {
      return this.error || this.localErrorMessage
    },

    shownServices () {
      return this.services.filter(s => s.carrier === this.carrier || (!this.carrier && this.approvedCarriers.indexOf(s.carrier) > -1))
    },

    approvedCarriers () {
      return [...new Set(this.approvedServices.map(s => s.shipper.name))] ?? []
    },

    selected: {
      get () {
        return this.serviceId
      },
      set (value) {
        this.$emit('update', { key: 'shippingService', value: value })
      }
    },

    localDisabled () {
      return !this.shipTo || !this.shipFrom || !this.billTo
    }
  },
  watch: {
    shipTo: async function (value, oldValue) {
      if (value !== oldValue) this.infoCount++
      else if (!value) this.infoCount--
    },

    shipFrom: async function (value, oldValue) {
      if (value !== oldValue) this.infoCount++
      else if (!value) this.infoCount--
    },

    billTo: async function (value, oldValue) {
      if (value !== oldValue) this.infoCount++
      else if (!value) this.infoCount--
    },

    account: async function (value, oldValue) {
      if (value !== oldValue) this.infoCount++
      else if (!value) this.infoCount--
    },
    infoCount: async function (value) {
      if (value >= 3 && !this.loading && !this.localDisabled) {
        await this.getCarrierServices()
      }
    },

    carrier: async function (value) {
      if (this.infoCount >= 3 && !this.loading && !this.localDisabled) {
        this.services.length === 0 && await this.getCarrierServices()
      }
    }
  },
  data () {
    return {
      approvedServices: [],
      services: [],
      loading: false,
      infoCount: 0,
      localErrorMessage: ''
    }
  },
  apollo: {
    approvedServices: {
      query: GET_SHIPPING_SERVICE_LEVELS,
      update (data) {
        return data.shipping_service_levels.map(s => ({
          ...s,
          subtitle: s.shipper.name,
          color: s.color,
          title: s.service
        }))
      }
    }
  },
  methods: {
    filter (item, queryText) {
      const title = item.service.toLowerCase()
      const subtitle = item?.carrier ? item.carrier.toLowerCase() : ''
      const search = queryText.toLowerCase()

      return title.indexOf(search) > -1 || subtitle.indexOf(search) > -1
    },

    async getCarrierServices () {
      this.loading = true
      const easyPostToAddress = this.shipTo
      const easyPostFromAddress = this.shipFrom
      // const easyPostPurchaseAddress = this.billTo
      const carrierAccounts = []
      if (this.account) {
        carrierAccounts.push(this.account)
      }
      try {
        const parcel = await this.createEasyPostParcel({ length: 10.00, width: 10.00, height: 10.00, weight: 100.00 })
        const shipment = await this.createEasyPostShipment({
          fromAddressId: easyPostFromAddress,
          toAddressId: easyPostToAddress,
          isReturnShipment: false,
          parcelId: parcel.id,
          reference: 'dummy-shipment-for-rates',
          carrierAccounts: carrierAccounts
        })
        if (shipment.rates) {
          const ratesToMatch = shipment.rates.map(rate => ({
            id: rate.id,
            service: rate.service,
            text: MakeServiceNamePretty(rate.service, rate.carrier),
            carrier: rate.carrier,
            date: GetPrettyDate(rate.deliveryDate),
            color: ''
          }))
          // match up the received services with the services from the database
          for (const service of this.approvedServices) {
            const index = ratesToMatch.findIndex(r => r.service === service.easypost_name)
            if (index > -1) {
              ratesToMatch[index].color = service.color
              ratesToMatch[index].id = service.id
            }
          }
          this.services = ratesToMatch
        }
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    }
  },
  beforeMount () {
    if (this.serviceId && this.approvedServices.length > 0) {
      this.getCarrierServices()
    }
  }
}
</script>
