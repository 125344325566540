import {
  Clients_Client,
  Clients_Contact,
  GetSaleForEditQuery,
  GetSaleForEditQueryVariables,
  PurchaseForEditFragment,
  Quotes_Quote,
  SaleForEditFragment
} from '@/models/generated/graphql/ErpBackend'
import { computed, reactive, Ref, ref } from '@vue/composition-api'
import { xOR } from '@/lib/helpers'
import { GET_PURCHASE, GET_SALE_FOR_EDIT } from '@/api/graphql/Constants/Orders'
import { apolloClient as apollo } from '@/api/graphql/apollo'

export type FormAction = 'SAVE' | 'PROCESS' | undefined

export interface OrderFormObject<TSetterEvent, TObject, TValue> {
  value: TValue,
  message: string,
  validator: (action?: FormAction) => void,
  setter: (object: TSetterEvent) => void
  object?: TObject
}

export interface EmittedValue<T> {
  value: T | undefined
}

interface ContactOrderFormObject extends OrderFormObject<EmittedValue<Clients_Contact>, Clients_Contact, string> {
  ownerId: string
}

export interface OrderForm {
  [key: string]: any,
  client: OrderFormObject<EmittedValue<Clients_Client>, Clients_Client, string>
  clientOrderNumber: OrderFormObject<string, undefined, string>
  condition: OrderFormObject<EmittedValue<string>, undefined, string>
  contact: ContactOrderFormObject
  contractComment: string
  internalComment: string
  freight: string | number
  orderId: string // id returned from creation
  quote: OrderFormObject<EmittedValue<Quotes_Quote>, Quotes_Quote, string>
  rep: OrderFormObject<EmittedValue<string>, undefined, string>
  statusId: string // status for order
  terms: OrderFormObject<EmittedValue<string>, undefined, string>
}

/**
 * If objects value is set or the action is save, clear the message, otherwise set it.
 * @param action
 * @param obj
 * @return void
 */
export function RequiredValidationRunner (action: FormAction, obj: OrderFormObject<any, any, any>): void {
  obj.message = (obj.value !== '' || action === 'SAVE') ? '' : 'This is required to continue.'
}

export function UseOrderInformation () {
  const form: OrderForm = reactive({
    client: {
      value: '',
      validator: (action: FormAction) => RequiredValidationRunner('PROCESS', form.client),
      message: '',
      // @ts-ignore
      object: { id: '', name: '' },
      setter: ({ value: client }) => {
        form.client.value = client?.id ?? ''
        form.client.object = client
        form.contact.ownerId = client?.id ?? ''
        if (client?.internal_rep?.id) {
          form.rep.value = client.internal_rep.id
          form.rep.message = ''
        }
        if (client?.default_st_terms?.id) {
          form.terms.value = client.default_st_terms?.id ?? ''
          form.client.message = ''
        }
      }
    },
    contact: {
      value: '',
      ownerId: '',
      message: '',
      // @ts-ignore
      object: { id: '', full_name: '' },
      validator: () => '',
      setter: ({ value: contact }) => {
        form.contact.value = contact?.id ?? ''
        form.contact.object = contact
        form.contact.ownerId = contact?.owner?.id ?? ''
        form.contact.message = ''
      }
    },
    rep: {
      value: '',
      message: '',
      validator: action => RequiredValidationRunner(action, form.rep),
      setter: ({ value }) => {
        form.rep.value = value ?? ''
        form.rep.message = ''
      }
    },
    terms: {
      value: '',
      message: '',
      validator: action => RequiredValidationRunner(action, form.rep),
      setter: ({ value }) => {
        form.terms.value = value ?? ''
        form.terms.message = ''
      }
    },
    quote: {
      value: '',
      message: '',
      // @ts-ignore
      object: {},
      validator: action => '',
      setter: (event: EmittedValue<Quotes_Quote>) => {
        form.quote.value = event.value?.id ?? ''
        form.quote.object = event.value
        form.quote.message = ''
      }
    },
    clientOrderNumber: {
      value: 'PO NEEDED',
      message: '',
      validator: action => RequiredValidationRunner(action, form.rep),
      setter: (event: string) => {
        form.clientOrderNumber.value = event
        form.clientOrderNumber.message = ''
      }
    },
    condition: {
      value: '',
      message: '',
      validator: action => '',
      setter: ({ value }) => {
        form.condition.value = value ?? ''
        form.condition.message = ''
      }
    },
    internalComment: '',
    contractComment: '',
    freight: '',
    orderId: '',
    statusId: ''
  })

  const hasErrors = computed(() => {
    for (const key in form) {
      if (form[key].message) return true
    }
    return false
  })

  const validateOrder = (action: FormAction): void => {
    for (const key in form) {
      if (form[key].validator) form[key].validator(action)
    }
  }

  const apply = (order: SaleForEditFragment | PurchaseForEditFragment) => {
    // @ts-ignore
    form.contact.setter({ value: order.contact })
    // @ts-ignore
    if (!order.contact) form.client.setter({ value: order.client })
    form.rep.setter({ value: order.rep?.id ?? '' })
    form.terms.setter({ value: order.terms?.id ?? '' })
    form.clientOrderNumber.setter(order.client_order_number)
    form.internalComment = order.internal_comment
    form.contractComment = order.contract_comment
    if (order.__typename === 'Purchases_Purchase') {
      form.condition.setter({ value: order.condition?.id ?? '' })
    }
  }

  return {
    form,
    hasErrors,
    ValidateOrder: validateOrder,
    apply
  }
}
