import gql from 'graphql-tag'

// There are four types/models used in this file
// Parts_BOMParts --> the part configuration that is listed on a BOM that describes how the part can be configured for a build up
// Parts_BOM --> the actual bill of materials (BOM) for a part to build up
// BuildUp_BuildUpItems --> the actual items (concrete) that are on a build up task
// BuildUp_BuildUp --> The build up task the contains all the above information

/* FRAGMENTS */

// Parts_BOMParts
export const FRAGMENT__BOM_PART__FULL = gql`
    fragment BomPartFull on Parts_BOMParts {
      id
      bom { id }
      creation_date
      creator { id initials }
      maximum_count
      minimum_count
      part { id pn description type }
      part_type
      updated_date
    }
`

// Parts_BOM
export const FRAGMENT__BOM_FOR_PART__GRID = gql`
    fragment BomForPartGrid on Parts_BOM {
      id
      bom_for {
        id
        brand { id name }
        pn
        type
      }
      creation_date
      creator { id initials }
      description
      updated_date
    }
`

export const FRAGMENT__BOM_FOR_PART__FULL = gql`
    fragment BomForPartFull on Parts_BOM {
      id
      bom_for {
        id
        brand { id name }
        pn
        type
      }
      creation_date
      creator { id initials }
      description
      parts {
        ...BomPartFull
      }
      updated_date
    }
    ${FRAGMENT__BOM_PART__FULL}
`

// BuildUp_BuildUp
export const FRAGMENT__BUILD_UPS__GRID = gql`
  fragment BuildUpsGrid on BuildUp_BuildUp {
    id
    annotations { name value }
    assigned_to { id full_name initials email }
    creation_date
    creator { id initials email }
    status { id status }
    system {
      id
      part { id pn description }
      purchases_items_details {
        id
        line_number
        transaction { id }
      }
      serial_number
    }
    updated_date
  }
`

export const FRAGMENT__BUILD_UPS_ITEM__FULL = gql`
    fragment BuildUpItemFull on BuildUp_BuildUpItems {
      annotations { name value }
      build_up { id }
      creation_date
      creator { id initials }
      id
      item {
        id
        part {
          id
          pn
          type
        }
        purchases_items_details {
          id
          line_number
          transaction {
            id
          }
        }
        serial_number
      }
      status { id status status_type }
      updated_date
    }
`

/* QUERIES */

export const GET_BOMS_FOR_PARTS = gql`
  query GetBomsForParts ($input: ManyQueryInput!) {
    parts_boms (input: $input) {
      ...BomForPartGrid
      annotations { name value }
    }
  }
  ${FRAGMENT__BOM_FOR_PART__GRID}
`

export const GET_PARTS_BOMS__FULL = gql`
  query GetPartsBoms_Full ($input: ManyQueryInput!) {
    parts_boms (input: $input) {
      ...BomForPartFull
      annotations { name value }
    }
  }
  ${FRAGMENT__BOM_FOR_PART__FULL}
`

export const GET_BUILD_UPS__GRID = gql`
  query GetBuildUps ($input: ManyQueryInput!) {
    build_up_build_ups (input: $input) {
        ...BuildUpsGrid
      annotations { name value }
    }
  }
  ${FRAGMENT__BUILD_UPS__GRID}
`

export const GET_BOM_PARTS__FULL = gql`
  query GetBompartsFull ($input: ManyQueryInput!) {
    parts_bom_parts (input: $input) {
      ...BomPartFull
      annotations { name value }
    }
  }
  ${FRAGMENT__BOM_PART__FULL}
`

export const GET_BUILD_UP_ITEMS__GRID = gql`
    query GetBuildUpItemsGrid ($input: ManyQueryInput!) {
      build_up_build_up_items (input: $input) {
        ...BuildUpItemFull
        annotations { name value }
      }
    }
    ${FRAGMENT__BUILD_UPS_ITEM__FULL}
`

/* MUTATIONS */

export const CREATE_BOM = gql`
  mutation CreateBomForPart ($id: ID!, $description: String!) {
    Create__Parts_BOM (input: {
      bom_for_id: $id,
      description: $description
    }) {
      ...BomForPartFull
    }
  }
  ${FRAGMENT__BOM_FOR_PART__FULL}
`

export const CREATE_BOM_PARTS = gql`
  mutation CreateBomParts ($id: ID!, $part_id: ID!, $max: PositiveInteger, $min: PositiveInteger, $type: String!) {
    Create__Parts_BOMParts (input: {
      bom_id: $id,
      part_id: $part_id,
      part_type: $type,
      maximum_count: $max,
      minimum_count: $min
    }) {
      ...BomPartFull
    }
  }
  ${FRAGMENT__BOM_PART__FULL}
`

export const UPDATE_BOM_PART = gql`
  mutation UpdateBomPart ($id: ID!, $max: PositiveInteger, $min: PositiveInteger, $type: String!) {
    Update__Parts_BOMParts (input: {
      id: $id,
      maximum_count: $max,
      minimum_count: $min,
      part_type: $type
    }) {
      ...BomPartFull
    }
  }
  ${FRAGMENT__BOM_PART__FULL}
`

export const DELETE_BOM_PART = gql`
  mutation DeleteBomPart ($id: ID!) {
    Delete__Parts_BOMParts (id: $id) { id }
  }
`

export const CREATE_BUILDUP = gql`
  mutation CreateBuildUp($id: ID!) {
    Create__BuildUp_BuildUp (input: {
      system_id: $id
    }) {
      ...BuildUpsGrid
    }
  }
  ${FRAGMENT__BUILD_UPS__GRID}
`

export const UPDATE_BUILDUP = gql`
    mutation UpdateBuildUp ($input: Update__BuildUp_BuildUp__Input!) {
      Update__BuildUp_BuildUp (input: $input) {
        ...BuildUpsGrid
      }
    }
    ${FRAGMENT__BUILD_UPS__GRID}
`

export const CREATE_BUILDUP_ITEMS = gql`
  mutation CreateBuildUpItems($input: [Create__BuildUp_BuildUpItems__Input!]!) {
    buildUpItems: Create__BuildUp_BuildUpItems (input: $input) {
      ...BuildUpItemFull
    }
  }
  ${FRAGMENT__BUILD_UPS_ITEM__FULL}
`

export const UPDATE_BUILDUP_ITEMS = gql`
  mutation UpdateBuildUpItems ($input: [Update__BuildUp_BuildUpItems__Input!]!) {
    Update__BuildUp_BuildUpItems (input: $input) {
      ...BuildUpItemFull
    }
  }
  ${FRAGMENT__BUILD_UPS_ITEM__FULL}
`
