import gql from 'graphql-tag'

/* FRAGMENTS */

export const FRAGMENT__CHOICES_STATUS = gql`
    fragment ChoicesStatus on Choices_Status {
      id
      app_name
      field_name
      model_name
      status
      status_type
    }
`

export const FRAGMENT__CHOICES_TAGS = gql`
    fragment ChoicesTags on Choices_Tag {
      id
      tag
      description
      color
      app_name
      model_name
      field_name
    }
`

export const FRAGMENT__CHOICES_CHOICES = gql`
    fragment ChoicesChoice on Choices_Choice {
      id
      choice
      sub_choice
      app_name
      model_name
      field_name
    }
`

/* HELPERS */

/* QUERIES */

export const TEST_STATUS_TAGS = gql`
  query TestStatusTagsForList {
    tags: choices_tags (input: { limit: 10000 }) {
      id
      tag
      description
      color
    }
  }
`

export const GET_CHOICES_CHOICES = gql`
    query GetChoicesChoices ($input: ManyQueryInput!) {
      choices_choices (input: $input) {
        app_name
        model_name
        field_name
        id
        choice
        sub_choice
      }
    }
`

/* MUTATIONS */
