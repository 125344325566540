import {
  Blind,
  Clients_Contact,
  Model_Enum,
  Purchases_Purchase,
  Purchases_PurchasedItems, Query, QueryRma_RmaArgs,
  Rma_Rma,
  Rma_RmaItems,
  Sales_Sale,
  Sales_SoldItems,
  Shipping_ShipmentOrder,
  Users_User,
  Quotes_Quote,
  WorkOrder_WorkOrder
} from '@/models/generated/graphql/ErpBackend'
import { Address } from '@/lib/parseAddress'
import { Info, PDFData } from '@/components/mixins/pdfmaker'
import { GetPrettyDate } from '@/lib/gqlDataGetters'
import { c } from '@/lib/Currency'
// @ts-ignore
import { CustomOption } from '@/components/dialogs/ViewPDF.vue'
import store from '@/store/store'
import { getPtId } from '@/lib/fieldDataGetters'
import { CapIt } from '@/lib/helpers'
import { apolloClient as apollo } from '@/api/graphql/apollo'
import { GET_SALE_FOR_PDF } from '@/api/graphql/Constants/Orders'
import { GET_RMA_FOR_PDF } from '@/api/graphql/Constants/RMAs'

interface UsablePdfObject {
  pdf: PDFData,
  rules: CustomOption[]
}

const footerMessage = 'Generated by TRAQSYS'

export const prepPdfData = {
  Sale (order: Sales_Sale): UsablePdfObject {
    const pdf: PDFData = {}
    const rules: CustomOption[] = [
      {
        display: 'Show Company Logo',
        model: true,
        onChange (data: any, value: boolean) { ToggleCompanyLogo(data, value) }
      },
      {
        display: 'Show Sales Rep',
        model: true,
        onChange (data: PDFData, value: boolean) {
          if (value) {
            data.info!.Rep = order.rep?.initials ?? ''
          } else {
            data.info!.Rep = ''
          }
        }
      },
      {
        display: 'Hide internal parts',
        model: false,
        onChange (data: PDFData, model: boolean) {
          let partInfo
          if (model) {
            partInfo = PrepareOrderParts(order.st_items?.filter((p: Sales_SoldItems) => p.part.type === 'Server') ?? [], 'sold_for')
          } else {
            partInfo = PrepareOrderParts(order.st_items ?? [], 'sold_for')
          }
          data.parts = partInfo.parts ?? []
        }
      },
      {
        display: 'Show Sold To Information',
        model: true,
        onChange (data: PDFData, value: boolean) {
          if (!value && data.addresses) {
            const index = data.addresses.findIndex(a => a.title === 'Sold To')
            if (index > -1) {
              data.addresses.splice(index, 1)
            }
          } else if (data.addresses && order.shipment_order) {
            data.addresses.push(PrepareAddressesForPdf(order.shipment_order, order.rep ?? null, Model_Enum.SalesSale)[2])
          }
        }
      }
      // { name: 'showBuiltinParts', display: 'Builtin Parts', model: false }
    ]

    pdf.title = `Sale Order # ${order.id}`
    if (!order.shipment_order) throw new Error('Shipment Order has not yet been created')
    pdf.addresses = PrepareAddressesForPdf(order.shipment_order, order.rep ?? null, Model_Enum.SalesSale)
    pdf.info = {
      Rep: order.rep?.initials ?? '',
      Terms: order.terms?.name ?? '',
      'Order Date': GetPrettyDate(order.sale_date),
      'PO #': order.client_order_number
    }
    const partInfo = PrepareOrderParts(order.st_items ?? [], 'sold_for')
    pdf.parts = partInfo.parts
    pdf.partsTotal = partInfo.partsTotal
    pdf.comment = order.contract_comment
    pdf.footer = footerMessage
    return { pdf: pdf, rules: rules }
  },

  Quote (order: Quotes_Quote): UsablePdfObject {
    const pdf: PDFData = {}
    const rules: CustomOption[] = []

    pdf.title = `Quote # ${order.id}`
    pdf.addresses = [
      {
        title: 'Client',
        name: order.ship_to_address?.client?.name,
        street_1: order.ship_to_address?.street_1 ?? '',
        street_2: order.ship_to_address?.street_2 ?? '',
        city: order.ship_to_address?.city,
        state: order.ship_to_address?.state,
        zip_code: order.ship_to_address?.zip_code,
        country: order.ship_to_address?.country.name,
        extra: [
          order.contact?.full_name ?? ''
        ]
      },
      {
        title: 'Vendor',
        name: order.ship_from_address?.company?.name ?? '',
        street_1: order.ship_from_address?.street_1 ?? '',
        street_2: order.ship_from_address?.street_2 ?? '',
        city: order.ship_from_address?.city,
        state: order.ship_from_address?.state,
        zip_code: order.ship_from_address?.zip_code,
        country: order.ship_from_address?.country.name,
        extra: [
          order.creator?.full_name ?? ''
        ]
      }
    ]
    pdf.info = {
      Rep: order.creator?.initials ?? '',
      'Quote Date': GetPrettyDate(order.creation_date)
    }
    const quoteParts = []
    let runningTotal: number = 0
    for (const part of order?.parts ?? []) {
      const extended = c(part.price_per_part?.amount ?? '0').multiply(part.qty)
      quoteParts.push({
        'Part Number': part?.part?.pn ?? '',
        'Description': part.part?.description ?? '',
        'Qty': `${part.qty}`,
        'Mfgr': part.part?.mfgr?.name ?? '',
        'Unit Price': c(part.price_per_part?.amount ?? '0').format(),
        'Extended': extended.format()
      })
      runningTotal += extended.value
    }
    pdf.parts = quoteParts
    pdf.partsTotal = c(runningTotal).format()
    pdf.comment = order.contract_comment
    pdf.footer = footerMessage
    return { pdf: pdf, rules: rules }
  },

  PickList (order: Sales_Sale): UsablePdfObject {
    const pdf: PDFData = {}
    const rules: CustomOption[] = []
    pdf.title = `Pick List for ST ${order.id}`
    pdf.info = {
      Rep: order.rep?.initials ?? '',
      Terms: order.terms?.name ?? '',
      'Order Date': GetPrettyDate(order.sale_date)
    }
    pdf.comment = order.contract_comment
    if (!order.shipment_order) throw new Error('Shipment has not been created.')
    pdf.addresses = PrepareAddressesForPdf(order.shipment_order, order.rep ?? null, Model_Enum.SalesSale)
    if (order?.st_items?.length === 0) throw new Error('There are no parts yet on this order.')
    pdf.parts = PreparePartsForPickList(order.st_items ?? []).parts
    return { pdf, rules }
  },

  PackSlipForSale (order: Sales_Sale): UsablePdfObject {
    const pdf: PDFData = {}
    const rules: CustomOption[] = []
    if (!order.shipment_order) throw new Error('Shipment has not been created.')
    pdf.title = `Pack Slip ST-${order.id}`
    pdf.info = {
      Date: GetPrettyDate(order.sale_date),
      Rep: order.rep?.initials ?? '',
      'Our Reference': order.id
    }
    try {
      pdf.addresses = PrepareAddressesForPdf(order.shipment_order, order.rep ?? null, Model_Enum.SalesSale)
      pdf.addresses.splice(2, 1)
    } catch (e) {
      console.log(e.message)
    }

    if (order?.st_items?.length === 0) throw new Error('There are no parts yet on this order.')
    pdf.parts = PreparePartsForPickList(order.st_items ?? []).parts
    pdf.parts = pdf.parts.map((p) => ({
      Line: p.Line,
      Qty: p.Qty,
      'Part Number': p['Part Number'],
      Description: p.Description,
      Mfgr: p.Mfgr,
      Serial: p.Serial
    }))

    pdf.footer = footerMessage

    return { pdf, rules }
  },

  Purchase (order: Purchases_Purchase): UsablePdfObject {
    const pdf: PDFData = {}
    const rules: CustomOption[] = []
    pdf.title = `Purchase Order # ${order.id}`
    if (!order.shipment_order) throw new Error('Shipment order has not yet been created')
    pdf.addresses = PrepareAddressesForPdf(order.shipment_order, order.rep ?? null, Model_Enum.PurchasesPurchase)
    pdf.info = {
      Rep: order.rep?.initials ?? '',
      Terms: order.terms?.name ?? '',
      'Order Date': GetPrettyDate(order.purchase_date)
    }
    const partInfo = PrepareOrderParts(order.pt_items ?? [], 'original_cost')
    pdf.parts = partInfo.parts
    pdf.partsTotal = partInfo.partsTotal
    pdf.comment = order.contract_comment
    pdf.footer = footerMessage

    return { pdf: pdf, rules: rules }
  },

  PurchaseRelease (order: Purchases_Purchase): UsablePdfObject {
    const pdf: PDFData = {}
    const rules: CustomOption[] = []
    pdf.title = 'Purchase Release Form'
    pdf.info = {
      Reference: `PT - ${order.id}`,
      Rep: order.rep?.initials ?? ''
    }
    if (!order.shipment_order) throw new Error('Shipment has not been created.')
    pdf.addresses = PrepareAddressesForPdf(order.shipment_order, order.rep ?? null, Model_Enum.PurchasesPurchase)
    pdf.addresses.splice(2, 1)

    pdf.parts = PreparePartsForReleaseDoc(order.pt_items ?? []).parts
    pdf.footer = footerMessage

    return { pdf, rules }
  },

  SalesRelease (order: Sales_Sale): UsablePdfObject {
    const pdf: PDFData = {}
    const rules: CustomOption[] = []
    pdf.title = 'Sold Equipment Release Form'
    pdf.info = {
      Reference: `ST - ${order.id}`,
      Rep: order.rep?.initials ?? ''
    }
    if (!order.shipment_order) throw new Error('Shipment has not been created.')
    pdf.addresses = PrepareAddressesForPdf(order.shipment_order, order.rep ?? null, Model_Enum.SalesSale)
    pdf.addresses.splice(2, 1)

    pdf.parts = PreparePartsForReleaseDoc(order.st_items ?? []).parts
    pdf.footer = footerMessage

    return { pdf, rules }
  },

  Rma (order: Rma_Rma): UsablePdfObject {
    const pdf: PDFData = {}
    const rules: CustomOption[] = []
    pdf.title = `RMA # ${order.id}`
    pdf.info = {
      Rep: order.st.rep?.initials ?? '',
      Currency: order.rma_items?.[0].returned_part.sales_items_details?.sold_for?.currency.code ?? '',
      ST: order.st.id,
      'Return Reason': order.return_reason
    }
    if (!order.shipment_order) throw new Error('Shipment has not been created.')
    pdf.addresses = PrepareAddressesForPdf(order.shipment_order, order.st.rep ?? null, Model_Enum.RmaRma)
    pdf.addresses.splice(2, 1)

    pdf.parts = PreparePartsForRma(order, order.rma_items ?? []).parts
    pdf.comment = order.contract_comment

    pdf.footer = footerMessage

    return { pdf, rules }
  },

  WorkOrder (order: WorkOrder_WorkOrder): UsablePdfObject {
    const pdf: PDFData = {}
    const rules: CustomOption[] = []
    pdf.title = `WorkOrder ${order.id}`
    pdf.info = {
      Date: GetPrettyDate(order.creation_date),
      Rep: order.purchase_transaction?.rep?.initials ?? order.creator.initials
    }
    if (!order.shipment_order) throw new Error('Shipment not created.')
    pdf.addresses = PrepareAddressesForPdf(
      order.shipment_order,
      (order.purchase_transaction?.rep ?? order.creator),
      Model_Enum.WorkOrderWorkOrder)

    pdf.parts = [
      {
        'Product': 'Equipment Pack',
        'Description': 'A package of equipment',
        'PT': '12-2',
        Insurance: '30.00',
        Weight: '100 lbs',
        Dims: '20 x 24 x 20 in',
        Qty: '1',
        'Unit Cost': '2,000',
        Extended: '2,000'
      }
    ]
    pdf.partsTotal = 'Total: 2000'
    return { pdf: pdf, rules: rules }
  }
}

export function PrepareAddressesForPdf (order: Shipping_ShipmentOrder, rep: Users_User | null, type: Model_Enum): Address[] {
  const isBlind = order.blind === Blind.Blind
  const isDoubleBlind = order.blind === Blind.DoubleBlind
  const isNotBlind = order.blind === Blind.NotBlind

  const typeNameIs = (model: Model_Enum) => {
    return type === model
  }

  const getTheExtra = (address: string): string[] => {
    // @ts-ignore
    const contact: Clients_Contact = order[`${address}_contact`] ?? null
    if (contact) {
      return [
        CapIt(contact.full_name),
        contact.phone ?? '',
        contact.email ?? ''
      ]
    } else return []
  }

  const getRepDetails = () => {
    if (rep) {
      return [CapIt(rep.full_name), rep.email]
    } else return []
  }

  const addresses: Address[] = []

  if (typeNameIs(Model_Enum.SalesSale)) { // if order is a sale order
    addresses.push({
      title: 'Ship To',
      name: order.ship_to_address?.client?.name ?? order.ship_to_address?.company?.name ?? '',
      street_1: order.ship_to_address?.street_1 ?? '',
      street_2: order.ship_to_address?.street_2 ?? '',
      city: order.ship_to_address?.city,
      state: order.ship_to_address?.state,
      zip_code: order.ship_to_address?.zip_code,
      country: order.ship_to_address?.country.name,
      extra: getTheExtra('ship_to')
    },
    {
      title: 'Ship From',
      name: isDoubleBlind ? 'Warehouse' : order.ship_from_address?.client?.name ?? order.ship_to_address?.company?.name ?? '',
      street_1: order.ship_from_address?.street_1 ?? '',
      street_2: order.ship_from_address?.street_2 ?? '',
      city: order.ship_from_address?.city,
      state: order.ship_from_address?.state,
      zip_code: order.ship_from_address?.zip_code,
      country: order.ship_from_address?.country.name,
      extra: isNotBlind ? getRepDetails() : getTheExtra('ship_from')
    },
    {
      title: 'Sold To',
      name: order.purchaser_address?.client?.name ?? order.ship_to_address?.company?.name ?? '',
      street_1: order.purchaser_address?.street_1 ?? '',
      street_2: order.purchaser_address?.street_2 ?? '',
      city: order.purchaser_address?.city,
      state: order.purchaser_address?.state,
      zip_code: order.purchaser_address?.zip_code,
      country: order.purchaser_address?.country.name,
      extra: getTheExtra('purchaser')
    })
  } else if (typeNameIs(Model_Enum.PurchasesPurchase)) {
    addresses.push({
      title: 'Ship To',
      name: order.ship_to_address?.client?.name ?? order.ship_to_address?.company?.name ?? '',
      street_1: order.ship_to_address?.street_1 ?? '',
      street_2: order.ship_to_address?.street_2 ?? '',
      city: order.ship_to_address?.city,
      state: order.ship_to_address?.state,
      zip_code: order.ship_to_address?.zip_code,
      country: order.ship_to_address?.country.name,
      extra: isNotBlind ? getRepDetails() : getTheExtra('ship_to')
    },
    {
      title: 'Ship From',
      name: isDoubleBlind ? 'Warehouse' : order.ship_from_address?.client?.name ?? order.ship_to_address?.company?.name ?? '',
      street_1: order.ship_from_address?.street_1 ?? '',
      street_2: order.ship_from_address?.street_2 ?? '',
      city: order.ship_from_address?.city,
      state: order.ship_from_address?.state,
      zip_code: order.ship_from_address?.zip_code,
      country: order.ship_from_address?.country.name,
      extra: getTheExtra('ship_from')
    },
    {
      title: 'Buyer',
      name: order.purchaser_address?.client?.name ?? order.ship_to_address?.company?.name ?? '',
      street_1: order.purchaser_address?.street_1 ?? '',
      street_2: order.purchaser_address?.street_2 ?? '',
      city: order.purchaser_address?.city,
      state: order.purchaser_address?.state,
      zip_code: order.purchaser_address?.zip_code,
      country: order.purchaser_address?.country.name,
      extra: getTheExtra('purchaser')
    })
  } else if (typeNameIs(Model_Enum.RmaRma)) {
    addresses.push({
      title: 'Ship To',
      name: order.ship_to_address?.company?.name ?? '',
      street_1: order.ship_to_address?.street_1 ?? '',
      street_2: order.ship_to_address?.street_2 ?? '',
      city: order.ship_to_address?.city,
      state: order.ship_to_address?.state,
      zip_code: order.ship_to_address?.zip_code,
      country: order.ship_to_address?.country.name,
      extra: getTheExtra('ship_to')
    },
    {
      title: 'Ship From',
      name: order.ship_from_address?.client?.name ?? '',
      street_1: order.ship_from_address?.street_1 ?? '',
      street_2: order.ship_from_address?.street_2 ?? '',
      city: order.ship_from_address?.city,
      state: order.ship_from_address?.state,
      zip_code: order.ship_from_address?.zip_code,
      country: order.ship_from_address?.country.name,
      extra: getTheExtra('ship_from')
    })
  } else if (typeNameIs(Model_Enum.WorkOrderWorkOrder)) {
    addresses.push({
      title: 'Deliver To',
      name: order.ship_to_address?.client?.name,
      street_1: order.ship_to_address?.street_1 ?? '',
      street_2: order.ship_to_address?.street_2 ?? '',
      city: order.ship_to_address?.city,
      state: order.ship_to_address?.state,
      zip_code: order.ship_to_address?.zip_code,
      country: order.ship_to_address?.country.name,
      extra: getTheExtra('purchaser') // this might need to change
    },
    {
      title: 'Vendor',
      name: order.ship_from_address?.company?.name ?? '',
      street_1: order.ship_from_address?.street_1 ?? '',
      street_2: order.ship_from_address?.street_2 ?? '',
      city: order.ship_from_address?.city,
      state: order.ship_from_address?.state,
      zip_code: order.ship_from_address?.zip_code,
      country: order.ship_from_address?.country.name
    },
    {
      title: 'Pick Up From',
      name: order.purchaser_address?.client?.name ?? order.ship_to_address?.company?.name ?? '',
      street_1: order.purchaser_address?.street_1 ?? '',
      street_2: order.purchaser_address?.street_2 ?? '',
      city: order.purchaser_address?.city,
      state: order.purchaser_address?.state,
      zip_code: order.purchaser_address?.zip_code,
      country: order.purchaser_address?.country.name,
      extra: getRepDetails()
    })
  }

  return addresses
}

interface PrepPartsReturn {
  parts: Info[],
  partsTotal?: string
}

interface PdfPart {
  pn: string,
  description: string
}

interface PartForOrder extends PdfPart{
  quantity: number,
  mfgr: string,
  'unit price': currency,
  extended: currency
}

interface PartForRma extends PdfPart {
  serial: string,
  type: string,
  status: string,
  value: number,
  credit: number
}

interface PartForPickList extends PdfPart {
  Line: string,
  Quantity: string,
  Mfgr: string,
  'PT-ID': string,
  'Site-Loc': string,
  Serial: string
}

export function PrepareOrderParts (parts: Array<Sales_SoldItems | Purchases_PurchasedItems>, valueKey: 'sold_for' | 'original_cost'): PrepPartsReturn {
  const partPrep: PartForOrder[] = []
  const returnable: PrepPartsReturn = { parts: [], partsTotal: '' }

  if (parts.length > 0) {
    // @ts-ignore
    const currency = parts[0][valueKey]?.currency.code ?? 'USD'
    for (const orderPart of parts) {
      const index = partPrep.findIndex((p) => p.pn === orderPart.part.pn)
      if (index > -1) {
        partPrep[index].quantity++
      } else {
        partPrep.push({
          pn: orderPart.part.pn,
          description: orderPart.part.description,
          quantity: 1,
          mfgr: orderPart.part.mfgr?.name ?? '',
          // @ts-ignore
          'unit price': c(orderPart[valueKey]?.amount ?? '0'),
          // @ts-ignore
          extended: c(orderPart[valueKey].amount ?? '0')
        })
      }
    }
    let total: number = 0
    for (const part of partPrep) {
      returnable.parts.push({
        'Part Number': part.pn,
        'Description': part.description,
        'Qty': `${part.quantity}`,
        'Mfgr': part.mfgr,
        'Unit Price': part['unit price'].format(),
        'Extended': part.extended.multiply(part.quantity).format() // FormatMoney(part.extended.multiply(part.quantity).value, currency)
      })
      total += part.extended.multiply(part.quantity).value
    }
    returnable.partsTotal = c(total).format()
    return returnable
  } return { parts: [], partsTotal: '' }
}

export function PreparePartsForPickList (parts: Sales_SoldItems[]): PrepPartsReturn {
  const prepParts: Info[] = []
  for (const part of parts) {
    prepParts.push({
      Line: `${part.line_number}`,
      'Part Number': part.part.pn,
      'Description': part.part.description,
      Qty: `${part.item?.quantity ?? '1'}`,
      Mfgr: part.part.mfgr?.name ?? '',
      'PT-ID': part.item ? getPtId({ purchaseDetails: part.item?.purchases_items_details }) : '-',
      'Site-Loc': `${part.item?.location?.site?.name ?? '1'}-${part.item?.location?.name ?? 'NA'}`,
      Serial: part.item?.serial_number ?? ''
    })
  }
  return { parts: prepParts }
}

export function PreparePartsForReleaseDoc (orderParts: Array<Sales_SoldItems | Purchases_PurchasedItems>) {
  const parts: Info[] = []
  for (const part of orderParts) {
    const index = parts.findIndex((p) => p['Part Number'] === part.part.pn)
    if (index > -1) {
      parts[index].Qty = `${Number(parts[index].Qty) + 1}`
    } else {
      parts.push({
        'Part Number': part.part.pn,
        'Description': part.part.description,
        Mfgr: part.part.mfgr?.name ?? '-',
        Serial: part.item?.serial_number ?? '-',
        Qty: '1'
      })
    }
  }
  return { parts }
}

export function PreparePartsForRma (rma: Rma_Rma, rmaParts: Rma_RmaItems[]): PrepPartsReturn {
  const parts: Info[] = []
  for (const part of rmaParts) {
    const p = part.returned_part.part
    const value = part.returned_part.sales_items_details?.sold_for?.pretty ?? ''
    parts.push({
      'Part Number': p?.pn ?? '',
      Mfgr: p?.mfgr?.name ?? '',
      Description: p?.description ?? '',
      Serial: part.returned_part.serial_number ?? '',
      'Return Type': rma.return_code?.choice ?? '',
      // 'Repair Status': ,
      'Sold For': value,
      Credit: part.credit ? value : '-'
    })
  }
  return { parts }
}

interface ObjectConvertee {
  [index: string]: any
}

export function StringifyValues (obj: ObjectConvertee) {
  const returnable: Info = {}
  for (const key in obj) {
    returnable[key] = `${obj[key]}`
  }
  return returnable
}

export function ToggleCompanyLogo (data: PDFData, value: boolean): void {
  if (value && data.logo) {
    delete data.logo
  } else {
    data.logo = store.state.profile.logo
  }
}

export function FormatMoney (money: number, c: string = store.state.profile.currency) {
  return Intl.NumberFormat('en', {
    style: 'currency',
    currency: c
  }).format(money)
}

/* Helper Methods */

export async function GetSalesPackSlipPdfData (id: string | number): Promise<UsablePdfObject> {
  const response = await apollo.query({
    query: GET_SALE_FOR_PDF,
    variables: { id: id }
  })
  return prepPdfData.PackSlipForSale(response.data.sale)
}

export async function GetRmaPackSlipPdfData (id: string | number): Promise<UsablePdfObject> {
  const response = await apollo.query<Query, { id: string | number }>({
    query: GET_RMA_FOR_PDF,
    variables: { id: id }
  })
  if (response.data.rma_rma) {
    return prepPdfData.Rma(response.data.rma_rma)
  } else throw new Error('Could not retrieve RMA details')
}

export async function GetPickListPdfData (id: string | number): Promise<UsablePdfObject> {
  const response = await apollo.query({
    query: GET_SALE_FOR_PDF,
    variables: { id: id }
  })
  return prepPdfData.PickList(response.data.sale)
}
