<template>
  <nav class="t-bar-block t-sidebar">
    <div class="side-bar-button-wrapper">
      <!-- Main Buttons -->
      <div id="main-button-container"> <!-- Used for Userflow -->
        <div v-for="button in sideBarButtonList" class="t-dropdown-hover not-top-icons" :key="button.view">
          <router-link :to="button.route" class="t-button t-bar-item"
                       active-class="i-active" @click.native="setActiveView(button)"
                       tag="a"
          >
            <i :class="classType + button.iconClass" class="t-icon-font t-display-middle"/>
          </router-link>
          <!-- Popout Content for Main Buttons -->
          <div class="t-dropdown-content t-card-drop t-sidemenu" v-if="activeView !== button.view">
            <div class="t-bar-item dropdown-title">{{ button.view }}</div>
          </div>
        </div>
      </div>
      <hr style="width: 75%; margin-top: 10px; margin-bottom: 10px;">
      <!-- Role Buttons -->
      <div id="role-button-container"> <!-- Used for Userflow -->
        <div v-for="button in enabledRoleButtons" class="t-dropdown-hover not-top-icons" :key="button.view">
          <router-link :to="button.route" class="t-button t-bar-item"
                       active-class="i-active"
                       @click.native="setActiveView(button)"
                       tag="a">
            <i class="t-icon-font t-display-middle" :class="classType + button.iconClass"/>
          </router-link>
          <!-- Popout Content for Role Buttons -->
          <div class="t-dropdown-content t-card-drop t-sidemenu" v-if="activeView !== button.view">
            <div class="t-bar-item dropdown-title">{{ button.view }}</div>
          </div>
        </div>
      </div>
      <!-- Switch It Button -->
      <v-slide-x-transition>
      <div class="not-top-icons" id="action-bar-button" v-if="showActionBarButton">
        <button data-cy="switch-it-button" class="t-button t-bar-item" @click="switchIt">
          <v-icon color="white" medium>fas {{ extended ? 'fa-angle-left' : 'fa-angle-right' }}</v-icon>
        </button>
      </div>
      </v-slide-x-transition>
    </div>
    <span class="white--text" style="position: absolute; bottom: 0; left: 3px; font-size: .8em;">{{ version }}</span>
  </nav>
</template>

<script>
export default {
  name: 'Sidebar',
  computed: {
    path () {
      // used for the arrow to show or not
      return this.$route.fullPath
    },

    version () {
      return this.$store.getters['data/version']
    },

    extended () {
      return this.$store.state.grid.extended
    },

    activeTab: {
      get () {
        return this.$store.state.grid.activeTab
      },
      set (newValue) {
        this.$store.dispatch('grid/changeActiveTab', { activeTab: newValue })
      }
    },

    enabledRoleButtons: function () {
      return this.roleButtonList.filter((button) => {
        return button.role
      })
    },

    user: function () {
      return this.$store.state.profile.user
    },

    role: function () {
      return this.user.role
    },

    isDev: function () {
      return this.$store.getters['profile/isDev']
    }
  },
  watch: {
    role: {
      deep: true,
      handler: function (value) {
        if (value.name) {
          this.setRoleButtons()
        }
      }
    },
    '$route': {
      handler: function () {
        setTimeout(() => {
          const el = document.getElementById('action-bar')
          this.showActionBarButton = !!el
          if (!this.showActionBarButton && this.extended) {
            this.switchIt()
          }
        }, 200)
      },
      immediate: true
    }
  },
  data () {
    return {
      showActionBarButton: null,
      classType: 'fal ',

      // main buttons for the sidebar
      sideBarButtonList: [
        {
          route: '/inventory/',
          view: 'Inventory',
          iconClass: 'fa-inventory',
          firstHit: 'items'
        },
        {
          route: '/purchases/',
          view: 'Purchases',
          iconClass: 'fa-download',
          firstHit: 'pts'
        },
        {
          route: '/sales/',
          view: 'Sales',
          iconClass: 'fa-upload',
          firstHit: 'sts'
        },
        {
          route: '/clients/',
          view: 'Clients',
          iconClass: 'fa-users',
          firstHit: 'clients'
        }
      ],

      roleButtonList: [],

      // buttons that are available depending on user's role

      // activeModule for the buttons
      activeView: 'none'
    }
  },
  methods: {
    switchIt () {
      // animates changing of the action bar and grid height
      this.$store.dispatch('grid/switchExtended')
    },

    setActiveView (button) {
      // sets the active view for side bar icons
      this.activeView = button.view
      this.activeTab = button.firstHit
    },

    setRoleButtons () {
      this.roleButtonList.splice(0, this.roleButtonList.length)
      this.roleButtonList.push(
        {
          route: '/shipping/',
          view: 'Shipping',
          iconClass: 'fa-truck',
          role: this.role.canViewIO
        },
        {
          route: '/playground/',
          view: 'Playground',
          iconClass: 'fa-code',
          role: window.location.host.includes('localhost')
        },
        /* {
          route: '/tech/',
          view: 'Tech Central',
          iconClass: 'fa-wrench',
          role: this.user.role.canViewTech
        }, */
        {
          route: '/processing/',
          view: 'Processing',
          iconClass: 'fa-shopping-cart',
          role: this.role.canViewProc
        },
        {
          route: '/assembly/',
          view: 'Breakdown/Buildup',
          iconClass: 'fa-shapes',
          role: this.role.canViewBD
        },
        /* {
          route: '/assembly/breakdown',
          view: 'Breakdown & Buildup',
          iconClass: 'fa-screwdriver',
          role: this.user.role.canViewBD
        } */
        {
          route: '/accounting/',
          view: 'Accounting',
          iconClass: 'fa-dollar-sign',
          role: this.user.role.canViewAcct
        }
        /* {
          route: '/logs/',
          view: 'Logs',
          iconClass: 'fa-book',
          role: this.user.role.canViewLogs
        } */
      )
    }
  },
  beforeMount () {
    this.setRoleButtons()
  }
}
</script>
