import gql from 'graphql-tag'
import { FRAGMENT__ADDRESS__FULL } from '@/api/graphql/Constants/Addresses'
import { FRAGMENT__CARRIER_ACCOUNT__FULL } from '@/api/graphql/Constants/Shipments'

/* HELPERS */

/* QUERIES */

export const COMPANY_INFO = gql`
  query GetCompanyInfo {
    company: company_company {
      name
      phone
      short_name
      default_new_client_terms { id }
      default_return_to_address { ...AddressFull }
      default_ship_from_address { ...AddressFull }
      default_ship_to_address { ...AddressFull }
      default_billing_address { ...AddressFull }
      default_remit_address { ...AddressFull }
      default_shipping_account { ...CarrierAccountFull }
      default_shipping_service { id }
    }
  }
  ${FRAGMENT__ADDRESS__FULL}
  ${FRAGMENT__CARRIER_ACCOUNT__FULL}
`

export const GET_FIELDS = gql`
    query GetFields ($name: String!) {
      __type (name: $name) {
        name
        description
        inputFields {
          name
          description
          type {
            name
          }
        }
      }
    }
`

export const GET_TYPE__FIELDS = gql`
  query GetTypeFields ($name: String!) {
    __type (name: $name) {
      name
      description
      interfaces {
        name
      }
      inputFields {
        name
      }
      fields {
        name
        description
        type {
          name
          kind
          ofType {
            name
            ofType {
              name
            }
          }
        }
      }
      enumValues { name }
    }
  }
`

export const GET_TYPES = gql`
  query GetTypes {
    __schema {
      types {
        name
        description
        fields {
          type { name }
          name
        }
      }
    }
  }
`

/* MUTATIONS */

export const UPDATE_COMPANY = gql`
  mutation UpdateCompanyInfo ($input: Update__Company_Company__Input!) {
    Update__Company_Company (input: $input) {
      id
    }
  }
`
