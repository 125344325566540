














































































































































































































































































import { apolloClient as apollo } from '@/api/graphql/apollo'
import store from '@/store/store'
import standard2 from '@/components/autocompletes/standard2.vue'
import { TERMS_FOR_AUTOCOMPLETE } from '@/api/graphql/Constants/TERMS'
import choices2 from '@/components/autocompletes/choices2.vue'
import datePicker from '@/components/autocompletes/datePicker.vue'
import formTemplate from '@/components/forms/formTemplate.vue'
import companies from '@/components/autocompletes/companies.vue'
import { ValidateVue3ErrorObject } from '@/lib/validation'
import addPartsTemplate from '@/components/templates/addPartsTemplate.vue'
import WorkOrderSupplies from '@/components/autocompletes/WorkOrderSupplies.vue'
import liveCommentArea from '@/components/templates/liveCommentArea.vue'
import { computed, ComputedRef, defineComponent, onMounted, reactive, Ref, ref, watch } from '@vue/composition-api'
import courierSelection from '@/components/autocompletes/courierSelection.vue'
import { UseShipping } from '@/lib/UseShippingLogic'
import carrierServices from '@/components/autocompletes/carrierServices.vue'
import { CREATE_SHIPMENT_ORDER } from '@/api/graphql/Constants/Shipments'
import { CREATE_WORK_ORDER, CREATE_WORK_ORDER_ITEM } from '@/api/graphql/Constants/WorkOrders'
import { VALIDATE_PT } from '@/api/graphql/Constants/Orders'
import { IWorkOrderItem } from '@/models/vuexStateModels'
import { IFetchResultObject, IValidationObjectSingleLevel } from '@/models/errorAndValidationModels'
import {
  Blind,
  Clients_Terms,
  Create__Shipping_ShipmentOrder__Input,
  Create__WorkOrder_WorkOrder__Input, Create__WorkOrder_WorkOrderItems__Input, Shipping_ShipmentOrder
} from '@/models/generated/graphql/ErpBackend.ts'
import { GetDjangoMoneyCompatibleInput } from '@/lib/moneyHelpers'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default defineComponent({
  name: 'newWorkOrder',
  components: {
    'carrier-services': carrierServices,
    'companies': companies,
    'live-comments-area': liveCommentArea,
    'standard-autofill': standard2,
    'choices-autofill': choices2,
    'date-picker': datePicker,
    'new-data-form': formTemplate,
    'add-parts-template': addPartsTemplate,
    'work-order-supplies': WorkOrderSupplies,
    'courier-selection': courierSelection,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  setup () {
    // user
    const user = computed(() => store.state.profile.user)

    // structural data
    const footerMessage: Ref<string> = ref<string>('New Work Order')
    const footerColor: Ref<string> = ref('brown darken-4')
    const succeeded: Ref<boolean> = ref(false)
    const failed: Ref<boolean> = ref(false)
    const isSavingWorkOrder: Ref<boolean> = ref(false)

    // comment data
    const tabs: Ref<string> = ref('')
    const internalComment: Ref<string> = ref('')
    const contractComment: Ref<string> = ref('')

    // work order variables
    const termsId: Ref<number> = ref(0)
    const terms: Ref<Array<Clients_Terms>> = ref([])
    const isLoadingTerms: Ref<boolean> = ref(false)
    const termsLabel: string = 'Work Order Terms'

    const date: Ref<string> = ref('')
    const warrantyConfig = {
      label: 'Warranty Expiration',
      optional: true
    }

    const deliverByDate: Ref<string> = ref('')
    const deliverByDateConfig = {
      label: 'Deliver By',
      optional: true
    }

    const typeId: Ref<number> = ref(0)
    const typeConfig = {
      label: 'Type of WO',
      appName: 'work_order',
      modelName: 'work_order',
      fieldName: 'type',
      name: 'typeId'
    }

    const attachedPt: Ref<string> = ref('')
    const isValidatingPt: Ref<boolean> = ref(false)
    const ptErrorMessage: Ref<string> = ref('')
    const ptSuccessMessage: Ref<string> = ref('')

    async function ValidatePt () {
      isValidatingPt.value = true
      if (attachedPt.value) {
        const response = await apollo.query({
          query: VALIDATE_PT,
          variables: { id: attachedPt.value }
        })
        if (response.data.purchase) {
          ptSuccessMessage.value = 'Valid PT!'
        } else {
          ptErrorMessage.value = 'This PT does not exist'
        }
        isValidatingPt.value = false
      }
    }

    function UpdatePtValidationMessages () {
      ptSuccessMessage.value = ''
      ptErrorMessage.value = ''
    }

    const validPt: ComputedRef<boolean> = computed(() => ptSuccessMessage.value !== '')
    const invalidPt: ComputedRef<boolean> = computed(() => ptErrorMessage.value !== '')

    // product data
    const supplies: Ref<Array<IWorkOrderItem>> = ref([])
    const totalAmount: ComputedRef<number> = computed<number>(() => {
      let total = 0
      for (const product of supplies.value) {
        total = total + product.cost
      }
      return total
    })

    const vendor = reactive({ id: 0 })
    watch(vendor, (value) => {
      if (value !== undefined) {
        value.id && GetShipFroms([{ key: 'client__id', value: value.id }])
        value.id && validateVendor()
      }
    })
    const vendorMessage = ref('')
    const validateVendor = () => {
      vendorMessage.value = vendor.id ? '' : 'A vendor is required'
    }

    // shipping
    const {
      /* SHIP TO */ shipToId, shipToConfig, shipTos, shipToMessage, shipToEpId, GetShipTos,
      /* SHIP FROM */ shipFromId, shipFromConfig, shipFroms, shipFromMessage, shipFromEpId, GetShipFroms,
      /* BILL TO */ billToId, billToConfig, billTos, billToMessage, billToEpId, GetBillTos,
      /* CARRIER ACCOUNT */ carrierAccountId, carrierAccountConfig, carrierAccounts, carrierAccountMessage, carrierAccountCarrierName, carrierAccountEpId,
      /* SHIPPING SERVICE */ shipServiceId, shipServiceConfig, shippingServices, serviceMessage,
      /* EXTRA BITS */ wePay,
      /* LOCAL PICK UP */ isLocalPickup, localPickupType, pickupErrorMessage,
      CallValidators
    } = UseShipping(vendor)

    const shippingCost: Ref<number> = ref(0)
    const cost = ref(null)

    const errors: Ref<IValidationObjectSingleLevel> = ref({
      vendor: { value: '', validator: () => vendor.id ? '' : 'A vendor is required' },
      typeId: { value: '', validator: () => typeId.value ? '' : 'A type is required' },
      termsId: { value: '', validator: () => termsId.value ? '' : 'Terms are required' },
      supplies: { value: '', validator: () => supplies.value.length > 0 ? '' : 'Supplies are required' }
    })

    const hasWorkOrderErrors: ComputedRef<boolean> = computed(() => {
      for (const key of ['vendor', 'typeId', 'termsId']) {
        if (errors.value[key].value) {
          return true
        }
      }
      return false
    })

    const hasShipmentErrors: ComputedRef<boolean> = computed(() => {
      return shipToMessage.value !== '' || shipFromMessage.value !== '' || billToMessage.value !== '' ||
          carrierAccountMessage.value !== '' || serviceMessage.value !== '' || pickupErrorMessage.value !== ''
    })

    const hasSuppliesError: ComputedRef<boolean> = computed(() => {
      return errors.value.supplies.value !== ''
    })

    const hasErrors: ComputedRef<boolean> = computed(() => {
      return hasWorkOrderErrors.value || hasShipmentErrors.value || hasSuppliesError.value
    })

    const defineCostManually = ref(false)
    const manualCostRules = [
      (e: string) => !defineCostManually || Number(e) >= 0 || 'Must enter in a valid shipping cost'
    ]

    function PrepareWorkOrderObjects () {
      ValidateVue3ErrorObject(errors.value)
      CallValidators(true, true, true, false, !defineCostManually, !defineCostManually, true)
      validateVendor()
      // figure something out for the cost ref form here TODO:
      const shipment: Create__Shipping_ShipmentOrder__Input = {
        ...(carrierAccountId.value && { account_id: carrierAccountId.value }),
        blind: Blind.NotBlind,
        ...(deliverByDate.value && { deliver_by_date: deliverByDate.value }),
        pickup_type: localPickupType.value,
        purchaser_address_id: billToId.value,
        purchaser_contact_id: user.value.id,
        // release_number: '',
        ...(shipServiceId.value && { service_id: shipServiceId.value }),
        ship_from_address_id: shipFromId.value,
        ship_to_address_id: shipToId.value,
        we_pay: wePay.value
      }

      const workOrder: Create__WorkOrder_WorkOrder__Input = {
        amount: GetDjangoMoneyCompatibleInput(shippingCost.value),
        contract_comment: contractComment.value,
        internal_comment: internalComment.value,
        ...(deliverByDate.value && { need_by: deliverByDate.value }),
        ...(attachedPt.value && { purchase_transaction_id: attachedPt.value }),
        shipment_order_id: '',
        terms_id: `${termsId.value}`,
        type_id: `${typeId.value}`,
        vendor_id: `${vendor.id}`,
        ...(date.value && { warranty_end_date: date.value })
      }

      return { shipment, workOrder }
    }

    function CreateWorkOrder () {
      const { shipment, workOrder } = PrepareWorkOrderObjects()
      let resultObj: IFetchResultObject = { message: '', color: '' }
      if (!hasErrors.value) {
        isSavingWorkOrder.value = true
        apollo.mutate({
          mutation: CREATE_SHIPMENT_ORDER,
          variables: { input: shipment }
        }).then(async ({ data }) => {
          const shipmentOrder: Shipping_ShipmentOrder = data.order
          workOrder.shipment_order_id = shipmentOrder.id
          try {
            const woResponse = await apollo.mutate({
              mutation: CREATE_WORK_ORDER,
              variables: { input: workOrder }
            })
            const workOrderId = woResponse.data.order.id
            resultObj = await CreateWorkOrderItems(workOrderId)
          } catch {
            resultObj = { message: 'Could not create work order', color: 'error' }
          }
        }).catch(() => {
          resultObj.message = 'Could not create shipment order. Double check info and try again.'
          resultObj.color = 'error'
        }).finally(() => {
          isSavingWorkOrder.value = false
          footerColor.value = resultObj.color
          footerMessage.value = resultObj.message
        })
      }
    }

    async function CreateWorkOrderItems (id: string): Promise<IFetchResultObject> {
      const workOrderItems: Array<Create__WorkOrder_WorkOrderItems__Input> = []
      for (const product of supplies.value) {
        workOrderItems.push({ item_id: product.id, work_order_id: id })
      }
      try {
        await apollo.mutate({
          mutation: CREATE_WORK_ORDER_ITEM,
          variables: { input: workOrderItems }
        })
        return { message: 'Created work order successfully', color: 'success' }
      } catch {
        return { message: 'Could not create items on work order. Add the items again in the list view.', color: 'error' }
      }
    }

    function Close () {
      store.dispatch('grid/resetSheet')
    }

    onMounted(() => {
      GetBillTos([{ key: 'company__isnull', value: false }])
      GetShipTos([{ key: 'company__isnull', value: false }])
      isLoadingTerms.value = true
      apollo.query({
        query: TERMS_FOR_AUTOCOMPLETE
      }).then(response => {
        terms.value = response.data.terms
        isLoadingTerms.value = false
      })
      wePay.value = true
    })

    return {
      footerMessage,
      footerColor,
      succeeded,
      failed,
      isSavingWorkOrder,
      tabs,
      internalComment,
      contractComment,
      termsId,
      terms,
      isLoadingTerms,
      termsLabel,
      date,
      warrantyConfig,
      deliverByDate,
      deliverByDateConfig,
      typeId,
      typeConfig,
      attachedPt,
      isValidatingPt,
      ValidatePt,
      UpdatePtValidationMessages,
      validPt,
      invalidPt,
      PrepareWorkOrderObjects,
      CreateWorkOrder,
      CreateWorkOrderItems,
      Close,
      ptErrorMessage,
      ptSuccessMessage,
      supplies,
      errors,
      defineCostManually,
      manualCostRules,
      shipToId,
      shipToConfig,
      shipTos,
      shipToMessage,
      shipToEpId,
      shipFromId,
      shipFromConfig,
      shipFroms,
      shipFromMessage,
      shipFromEpId,
      billToId,
      billTos,
      billToConfig,
      billToMessage,
      billToEpId,
      isLocalPickup,
      localPickupType,
      pickupErrorMessage,
      carrierAccountId,
      carrierAccounts,
      carrierAccountConfig,
      carrierAccountMessage,
      carrierAccountEpId,
      carrierAccountCarrierName,
      shipServiceId,
      shipServiceConfig,
      shippingServices,
      shippingCost,
      serviceMessage,
      wePay,
      vendor,
      vendorMessage,
      validateVendor,
      CallValidators,
      hasWorkOrderErrors,
      hasShipmentErrors,
      hasSuppliesError
    }
  }
  // apollo: {
  //   terms: {
  //     query: TERMS_FOR_AUTOCOMPLETE,
  //     watchLoading (isLoading: boolean) {
  //       this.isLoadingTerms = isLoading
  //     }
  //   }
  // }
})
