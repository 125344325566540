<template>
  <div>
    <v-container>
      <v-layout row wrap justify-space-between>
        <v-flex xs2>
          <v-text-field
            v-model="speed"
            label="CPU Speed"
            :rules="[ e => e > 0 || 'Must be > 0' ]"
          ></v-text-field>
        </v-flex>
        <v-flex xs2>
          <choice-list :config="hzConfig"></choice-list>
        </v-flex>
        <v-flex xs2>
          <v-text-field
            v-model="cores"
            label="Cores"
          ></v-text-field>
        </v-flex>
        <v-flex xs2>
          <v-text-field
            v-model="threads"
            label="Threads"
          ></v-text-field>
        </v-flex>
      </v-layout>
    </v-container>
    <standard-actions
      @save="save"
      @cancel="cancel"
      @close="$emit('close')"
      :save-button="{ name: 'Submit', disabled: !canProceed }"
      :cancel-button="{ name: 'Back' }"
    ></standard-actions>
  </div>
</template>

<script>
import standardActions from '../standardActions'
import choices from '../../autocompletes/choices'

export default {
  name: 'storage',
  components: {
    'standard-actions': standardActions,
    'choice-list': choices
  },
  computed: {
    canProceed () {
      return this.speed && this.hzConfig.id && this.cores && this.threads
    }
  },
  data () {
    return {
      speed: null,
      hzConfig: {
        id: 0,
        label: 'Speed Units',
        appName: 'parts',
        modelName: 'cpu',
        fieldName: 'hz'
      },

      cores: null,
      threads: null
    }
  },
  methods: {
    save () {
      const details = {
        speed: this.speed,
        hz: this.hzConfig.id,
        cores: this.cores,
        threads: this.threads
      }
      this.$emit('save', details)
    },

    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
