


























import { defineComponent, computed, ComputedRef, provide, watch } from '@vue/composition-api'
import { apolloClient } from '@/api/graphql/apollo'
import { ApolloClients, useQuery, UseQueryReturn, useResult } from '@vue/apollo-composable'
import {
  QueryFilter,
  Shipping_Account
} from '@/models/generated/graphql/ErpBackend'
import { GET_CARRIER_ACCOUNTS } from '@/api/graphql/Constants/Shipments'

export default defineComponent({
  name: 'CarrierAccountsAutocomplete',
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    clientId: {
      type: [String, Number],
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    searchCompany: {
      type: Boolean,
      required: false,
      default: false
    },
    error: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    provide(ApolloClients, {
      default: apolloClient
    })

    const localId = computed({
      get () {
        return props.id
      },
      set (value) {
        emit('updated', { value: value })
      }
    })
    const searchByClient: ComputedRef<boolean> = computed(() => !!props.clientId && !props.searchCompany)

    const filter = (): QueryFilter[] => {
      if (props.searchCompany) {
        return [{
          key: 'client__isnull',
          value: true
        }]
      } else {
        return [{
          key: 'client__id',
          value: props.clientId
        }]
      }
    }
    // @ts-ignore
    const { result, loading, refetch }: UseQueryReturn<GetCarrierAccountsQuery, QueryShipping_AccountsArgs> = useQuery(
      GET_CARRIER_ACCOUNTS,
      () => ({
        input: {
          filters: filter()
        }
      }),
      () => ({
        enabled: searchByClient.value || props.searchCompany
      })
    )
    watch(() => props.searchCompany, () => {
      refetch()
    })
    // onResult(() => {
    //   if (accounts.value.length === 1) {
    //     emit('updated', { value: accounts.value[0].id, easypost_id: accounts.value[0].easypost_id ?? '' })
    //   }
    // })

    const accounts = useResult(result, [], (data) => {
      if (data.carrier_accounts) {
        const accounts: Shipping_Account[] = data.carrier_accounts
        let account
        if (accounts?.length === 1) {
          account = accounts[0]
        } else {
          const a = accounts.find(a => !!a.default_shipping_account_for_client?.id)
          if (a) {
            account = a
          }
        }
        !props.disabled && emit('updated', { value: account })
        if (!props.searchCompany) {
          return (data.carrier_accounts as Shipping_Account[]).filter(a => a.client)
        } else return data.carrier_accounts
      }
    })

    function getTitle (account: Shipping_Account): string {
      return account?.number ?? ''
    }

    function getSubtitle (account: Shipping_Account): string {
      return account.shipper?.name ?? ''
    }

    const getText = (item: Shipping_Account): string => {
      return `${getSubtitle(item)}: ${getTitle(item)}`
    }

    return {
      localId,
      loading,
      getTitle,
      getSubtitle,
      getText,
      accounts
    }
  }
})
