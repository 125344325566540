import {
  Create__Purchases_Purchase__Input,
  Create__Sales_Sale__Input,
  GetFieldsQuery, GetFieldsQueryVariables
} from '@/models/generated/graphql/ErpBackend'
import { reactive, ref, Ref } from '@vue/composition-api'
import { apolloClient as apollo } from '@/api/graphql/apollo'
import { GET_FIELDS } from '@/api/graphql/Constants/Company'

type SupportedFieldEntryTypeStrings = 'Create__Sales_Sale__Input' | 'Create__Purchases_Purchase__Input'
type SupportedFieldEntryTypes = Create__Sales_Sale__Input | Create__Purchases_Purchase__Input

interface FieldObject {
  field: string
  value: string | boolean
  name: string
  type: string
  hint: string
  text: string
}

interface Field {
  field: string,
  value: boolean | string
}

export interface ExtraFieldsObject {
  inUse: string[]
  selected: FieldObject[]
  all: FieldObject[]
}

interface UseDynamicPrimitiveFieldEntryParams {
  type: SupportedFieldEntryTypeStrings,
  inUse: string[]
}

interface UseDynamicPrimitiveFieldEntryReturn {
  extra: ExtraFieldsObject
  error: Ref<string>
  loading: Ref<boolean>
  apply: (fields: Field[]) => void
}

interface InputField {
  name: string,
  description: string,
  type: {
    name: string
  }
}

export const getFields = async (string: SupportedFieldEntryTypeStrings) => {
  try {
    const response = await apollo.query<GetFieldsQuery, GetFieldsQueryVariables>({
      query: GET_FIELDS,
      variables: {
        name: string
      }
    })
    return response.data.__type?.inputFields
  } catch (e) {
    throw new Error(e.message)
  }
}

export const makeFields = (inUse: string[], fields: InputField[]): FieldObject[] => {
  const filtered: InputField[] = fields.filter(f => {
    const notInUse = inUse.indexOf(f.name) === -1
    const doesNotIncludeId = !f.name.includes('id')
    const notACurrencyDeclaration = !f.name.includes('urrency')
    const notEditLock = f.name !== 'edit_lock'
    const notTotal = !f.name.includes('total')
    return notInUse && doesNotIncludeId && notACurrencyDeclaration && notEditLock && notTotal
  })

  return filtered.map(f => {
    const newF: FieldObject = { field: '', name: '', value: '', type: '', hint: '', text: '' }
    newF.field = f.name
    newF.name = f.name.replace(/_/g, ' ').replace(/\b\w/g, f => f.toUpperCase())
    newF.value = ''
    newF.type = f.type.name || 'String'
    return newF
  })
}

export function useDynamicPrimitiveFieldEntry<T extends SupportedFieldEntryTypes>
(params: UseDynamicPrimitiveFieldEntryParams): UseDynamicPrimitiveFieldEntryReturn {
  const loading = ref(false)
  const extra: ExtraFieldsObject = reactive({
    inUse: [],
    selected: [],
    all: []
  })
  const error = ref<string>('')

  loading.value = true
  getFields(params.type).then(result => {
    if (result) {
      extra.all.push(...makeFields(params.inUse, result as InputField[]))
      loading.value = false
    } else {
      error.value = 'No result'
    }
  })

  const apply = (fields: Field[]) => {
    for (const field of fields) {
      const f = extra.all.find(f => f.field === field.field)
      if (f && (f.type !== 'Boolean' || field.value === true)) { // we either want booleans that are true or just not booleans
        f.value = field.value
        if (f.type !== 'Boolean') f.text = f.value as string
        field.value && extra.selected.push(f)
      }
    }
  }

  return {
    extra,
    error,
    loading,
    apply
  }
}
