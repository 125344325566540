// module for storing state related to notifications

const state = {
  notifications: [],
  alert: {
    color: '',
    message: ''
  },
  notificationPeeked: false,
  hasUnreadNotifications: false,
  snackbar: null
}

const getters = {
  hasUnreadNotifications: state => {
    return state.notifications.length > 0 && !state.notificationPeeked
  },

  notifications: state => {
    return state.notifications
  },

  alert: state => {
    return state.alert
  },

  snackbar: state => {
    return state.snackbar
  },

  showSnackbar: state => {
    return state.snackbar !== null
  },

  notificationPeeked: state => {
    return state.notificationPeeked
  }
}

const mutations = {
  /**
   * Sets the snackbar to an object to be displayed
   * @param state
   * @param payload
   * @param payload.message {string}
   * @param payload.color {string}
   * @param payload.timeout {number}
   * @param payload.canClose {boolean}
   * @param payload.top {boolean}
   */
  setSnackbar (state, payload) {
    state.snackbar = payload
  },

  hideSnackbar (state) {
    state.snackbar = null
  },

  addNotification (state, payload) {
    const notification = payload.notification
    let duplicateNotification = false
    for (let i = 0; i < state.notifications.length; i++) {
      if (state.notifications[i].title === notification.title) {
        duplicateNotification = true
      }
    }
    if (!duplicateNotification) {
      state.hasUnreadNotifications = true
      state.notificationPeeked = false
      state.notifications.push(notification)
    }
  },

  removeNotification (state, payload) {
    state.notifications.splice(payload.index, 1)
  },

  /**
   * used for setting an alert
   * @param state
   * @param payload
   * @param payload.message
   * @param payload.color
   */
  setAlert (state, payload) {
    state.alert = payload
  },

  resetAlert (state) {
    state.alert = { color: '', message: '' }
  },

  setNotificationPeeked (state, payload) {
    state.notificationPeeked = payload.bool
  },

  setUnreadNotifications (state, payload) {
    state.hasUnreadNotifications = payload.bool
  },

  removeNotificationsOfType (state, payload) {
    for (let i = 0; i < state.notifications.length; i++) {
      const notification = state.notifications[i]
      if (notification.type === payload.type) {
        state.notifications.splice(i, 1)
        i--
      }
    }
  },

  removeNotificationMatch (state, payload) {
    for (let i = 0; i < state.notifications.length; i++) {
      const notification = state.notifications[i]
      if (notification.text === payload.text) {
        state.notifications.splice(i, 1)
        i--
      }
    }
  }
}

const actions = {
  /**
   * Creates a snackbar about logging back in
   * @param commit
   * @param message {string}
   * @param color {string}
   * @param timeout {number}
   * @param canClose {boolean}
   * @param top {boolean}
   */
  createSnackbar ({ commit }, { message, color, timeout = 0, canClose = true, top = false }) {
    commit('setSnackbar', { message, color, timeout, canClose, top })
  },

  /**
   * Hides the snackbar
   * @param commit
   */
  hideSnackbar ({ commit }) {
    commit('hideSnackbar')
  },

  addNotification ({ commit }, payload) {
    commit('addNotification', payload)
  },

  removeNotification ({ commit }, payload) {
    commit('removeNotification', payload)
  },

  changeNotificationPeeked ({ commit }, payload) {
    commit('setNotificationPeeked', payload)
  },

  changeUnreadNotifications ({ commit }, payload) {
    commit('setUnreadNotifications', payload)
  },

  removeNotificationsOfType ({ commit }, payload) {
    commit('removeNotificationsOfType', payload)
  },

  removeNotificationMatch ({ commit }, payload) {
    commit('removeNotificationMatch', payload)
  },

  /**
   * Add a standard alert message to notifications
   * @param commit
   * @param payload
   * @params payload.message What you want the alert to say
   */
  addTextNotification ({ commit }, payload) {
    const notification = {
      text: payload.message,
      route: '',
      data: '',
      isDismissable: true,
      dismissOnClick: true,
      function: function () {},
      hasNestedButton: false,
      tabName: '',
      useStandardRouting: false,
      type: 'alert',
      color: payload.color ? payload.color : null
    }
    commit('addNotification', { notification })
  },

  /**
   * Used for adding a custom alert message
   * @param commit
   * @param dispatch
   * @param payload
   */
  addAlert ({ commit, dispatch }, payload) {
    commit('setAlert', payload)
    dispatch('data/changeSaving', { bool: false }, { root: true })
  },

  /**
   * Used for adding a success alert message
   * @param commit
   * @param dispatch
   * @param payload
   * @param payload.message
   */

  addSuccessAlert ({ commit, dispatch }, payload) {
    payload.color = 'successAlert'
    dispatch('data/changeSaving', { bool: false }, { root: true })
    commit('setAlert', payload)
  },

  /**
   * Used for adding an error alert message
   * @param commit
   * @param dispatch
   * @param payload
   */
  addErrorAlert ({ commit, dispatch }, payload) {
    payload.color = 'error'
    payload.message = payload.message.message
    dispatch('data/changeSaving', { bool: false }, { root: true })
    commit('setAlert', payload)
  },

  /**
   * Used for resetting the alert
   * @param commit
   * @param payload
   */
  resetAlert ({ commit }, payload) {
    commit('resetAlert')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
