











































































































































































































































































































import { computed, ComputedRef, defineComponent, onMounted, reactive, Ref, ref } from '@vue/composition-api'
import * as fs from 'fs'

export default defineComponent({
  name: 'BetaProgress',
  setup () {
    interface ticket {
      tags?: string[]
      id: number | null
      subject: string
      status: number
      statusColor: string
      statusText: string
    }

    interface person {
      name: string,
      signOff: boolean
    }

    interface Step {
      subject: string,
      note?: string,
      tSign: boolean,
      iSign: boolean
      ticket: {
        jira: string,
        link?: number | null
      }
    }

    interface IDepartment {
      iSign: boolean
      name: string
      search: string,
      bugs: ticket[]
      persons: person[]
      steps: Step[]
      stepSearch: string,
      progress: number
      statusMessage: string
      tSign: boolean
      tab: number | null
      tag: string
    }

    // const accountingSteps: Step[] = [
    //   {
    //     subject: 'Posting AR and AP when we choose not live',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Have access to set up GL codes and determine where they it the Income Statement and/or Balance sheet',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'System determined Income statements',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'System determined Balance sheet',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'System determined Trial Balance',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Ability to enter GL codes that cannot be changed',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Report on all checks cut',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'report on all void checks',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Prepay for inventory and once received inventory is received automatically and costs are moved from inventory prepayment account',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Prepaid Sales once product ships system moves funds from prepaid equipment to AR invoice payment',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Shipping costs can be added and linked to STs or PTs.',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Enter additional charges against specific PTs or STs.',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Can enter misc items against a specific sale  rep',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Can change inventory costs but manually adjust PTs Invoices to match new cost',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Print the report for all shipped product that has not been paid so we can note for month end expected commissions to be paid.',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Print a commission report for items that have been paid for that we will be using to figure out the commission we will be paying out',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Enter AP and AR invoices that will be applied to either vendor purchases or items that can be directly connected to an ST or PT',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Be able to keep track of employee compensation',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   }
    // ]
    // const csvSteps: Step[] = [
    //   {
    //     subject: 'Enter customer credit limit that will prevent additional orders being entered if they are over their credit limit',
    //     tSign: true,
    //     iSign: false,
    //     ticket: 'TQS-1361'
    //   },
    //   {
    //     subject: 'Enter customer terms that will prevent additional orders being entered if they are past due on the terms they are granted',
    //     tSign: false,
    //     iSign: false,
    //     ticket: 'TQS-1363'
    //   },
    //   {
    //     subject: 'Enter if the customer/vendor is taxable or tax exempt',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Enter the Exempt number if provided',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Select if we want to suspend an account',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   // {
    //   //   subject: 'Select if the account is secure and who has access to view the account (moved to accounting)',
    //   //   note: 'Moved to accounting, this is for tracking employee compensation',
    //   //   tSign: false,
    //   //   iSign: false,
    //   //   ticket: ''
    //   // },
    //   {
    //     subject: 'Can note if the customer is a 1099 customer',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Can designate the type of client/vendor they are',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'There is an area for our account number with the vendor or our customer account assigned by them',
    //     tSign: false,
    //     iSign: false,
    //     ticket: 'TQS-1370'
    //   },
    //   {
    //     subject: 'Multiple delivery addresses that we can assign for each client',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'ST Terms/PT Terms',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Federal ID Field',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'is a taxable Customer',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Rep associated with c/v',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Notes/Comment field',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Select whether or not Freight/Install/misc are included in sales/use tax (radio buttons)',
    //     tSign: false,
    //     iSign: false,
    //     ticket: 'TQS-1366'
    //   },
    //   {
    //     subject: 'Can enter sales tax and up to 4 special sales tax locations',
    //     note: 'Should be provided by quickbooks',
    //     tSign: false,
    //     iSign: false,
    //     ticket: 'TQS-1369'
    //   },
    //   {
    //     subject: 'Can view all STs sold to customer with equipment',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'can view all PTs purchased with detail',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Can view all invoices to and from c/v',
    //     note: 'Quickbooks should handle this',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Provide a financial snapshot or Current AR/AP',
    //     note: 'Should be provided by quickbooks',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Provide a financial snapshot of last 30 days, 6 months and 1 year of purchase/sales totals for customer',
    //     note: 'Should be provided by quickbooks',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Can set default shipping for customer with number noted',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   }
    // ]
    // const inventorySteps: Step[] = [
    //   {
    //     subject: 'Can filter by Date',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Can filter by Rep',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Can filter by Age',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Can filter by Master Item List Includes',
    //     note: 'We are not sure what this means exactly.',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Can filter by Item number',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Can filter by mfg',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Can filter by description',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Can filter by machine type',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Can filter by category',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Can filter by Whether it is a sub',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Can filter by Warehouse location',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Item activity with condition, number intransit, on hand, available, allocated, assigned and average cost',
    //     tSign: false,
    //     iSign: false,
    //     ticket: 'TQS-1626'
    //   },
    //   {
    //     subject: 'Comments section',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Can sort items by warehouse',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Enter HC code when adding part into inventory',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   }
    // ]
    // const stSteps: Step[] = [
    //   // {
    //   //   subject: 'Enter WO directly from ST',
    //   //   tSign: false,
    //   //   iSign: false,
    //   //   ticket: ''
    //   // },
    //   {
    //     subject: 'View job history with all items associated with a sale noted for each',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Select and view comment templates',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Allocate multiples of the same part number in one ST',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'search STs by typing in the ST/Client ID/PO field of the ST list',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Search previous STs by entering, ST#, serial, or PT# in the inventory history list',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Make blind pack list by unchecking "show rep and company logo" and sold to field',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Uncheck ST lines when printing pick/pack for partial ships or dummy packs',
    //     tSign: false,
    //     iSign: false,
    //     ticket: 'TQS-1373'
    //   },
    //   {
    //     subject: 'Timestamp when an ST is printed to TC',
    //     note: 'This is 99% finished, just needs to be hooked up',
    //     tSign: false,
    //     iSign: false,
    //     ticket: 'TQS-24'
    //   },
    //   {
    //     subject: 'IO is automatically 50/50, we are able to split it differently when allocating if needed.',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Add freight charge to ST',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Search by tracking # to find ST',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   }
    // ]
    // const ptSteps: Step[] = [
    //   {
    //     subject: 'Enter WO directly from PT',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'View job history with all items associated with a sale noted for each',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Add PT',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Lookup PT by #/Client/amount',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Sorting PT by Client',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'lookup vendor/enter vendor',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Enter Terms',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Enter condition',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Add Line',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Delete line',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Edit line',
    //     note: 'Is this add/remove parts?',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Duplicate line',
    //     note: 'Not duplicate exactly but it amounts to the same',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Add line comments',
    //     note: 'What are these?',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'if needed Add Part- CSR',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'if needed Add MFGR-CSR',
    //     note: 'We auto-add 99.9% of all mfgrs',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'if needed Add Client- CSR',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Add comments',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Send Contract email',
    //     note: 'In progress',
    //     tSign: false,
    //     iSign: false,
    //     ticket: 'TQS-'
    //   },
    //   {
    //     subject: 'Contract Conditions',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Contract terms',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Select credit card',
    //     note: 'Quickbooks should handle this',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Ship Via',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'taxable',
    //     note: 'What does this mean specifically?',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'freight costs',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Status',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   // {
    //   //   subject: 'Job history',
    //   //   tSign: false,
    //   //   iSign: false,
    //   //   ticket: ''
    //   // },
    //   {
    //     subject: 'Sort PT by status to in transit/open/received',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Tracking/shipping info',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'lookup invoice',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Searching Inventory',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Inventory History',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Buy Sell History',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Inventory Reports Buy/Sell, SN List, Master Summary',
    //     note: 'Buy/Sell is available, need specifics on the others.',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Commission reports/Margin Reports/Unpaid Commission',
    //     note: 'Quickbooks will handle this I believe, if not we will',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Open to buy report',
    //     note: 'What are these?',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Ship From /Ship To',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Print Inventory Labels',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Item Summary',
    //     note: 'What needs to be in this?',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Dashboard reporting',
    //     note: 'What needs to be in this?',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Look up RMAs',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   }
    // ]
    // const csrSteps: Step[] = [
    //   {
    //     subject: 'Enter HC code when adding part into inventory',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Allocate parts using FIFO if rep does not have one in stock - we are able to sort parts by date and rep',
    //     tSign: true,
    //     iSign: true,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'View both PT and ST lists at the same time',
    //     tSign: true,
    //     iSign: true,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Allocate multiples of the same part number in one ST by selecting "mark"',
    //     tSign: true,
    //     iSign: true,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'search STs by typing in the ST/Client ID/PO field of the ST list',
    //     tSign: true,
    //     iSign: true,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Search previous STs by entering, ST#, serial, or PT# in the inventory history list',
    //     tSign: true,
    //     iSign: true,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Make blind pack list by unchecking "show rep and company logo" and sold to field',
    //     tSign: true,
    //     iSign: true,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Uncheck ST lines when printing pick/pack for partial ships or dummy packs',
    //     tSign: false,
    //     iSign: false,
    //     ticket: 'TQS-1373'
    //   },
    //   {
    //     subject: 'Add freight charge to ST',
    //     tSign: true,
    //     iSign: true,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Search by tracking # to find ST',
    //     tSign: true,
    //     iSign: true,
    //     ticket: ''
    //   }
    // ]
    // const shipSteps: Step[] = [
    //   {
    //     subject: 'Need steps from IGS Beta Member',
    //     ticket: '',
    //     tSign: false,
    //     iSign: false
    //   }
    // ]
    // const receiveSteps: Step[] = [
    //   {
    //     subject: 'Be able to open up receiving window',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'PT needs to be approved before receiving',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Search by PT number in purchases',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Be able to enter a serial number',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Be able to enter a location',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Be able to enter a COO (country of origin)',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Show if a pt item is allocated',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Be able to print tags',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   }
    // ]
    // const reportSteps: Step[] = [
    //   {
    //     subject: 'Ecomm report',
    //     tSign: false,
    //     iSign: false,
    //     ticket: 'TQS-838'
    //   },
    //   {
    //     subject: 'Open RMA report',
    //     note: 'Can filter the RMAs table for this info',
    //     tSign: true,
    //     iSign: false,
    //     ticket: 'TQS-839'
    //   },
    //   {
    //     subject: 'To be tested report',
    //     note: 'Can filter by test status',
    //     tSign: true,
    //     iSign: false,
    //     ticket: 'TQS-840'
    //   },
    //   {
    //     subject: 'to be received report',
    //     note: 'Can filter by receive status',
    //     tSign: true,
    //     iSign: false,
    //     ticket: 'TQS-841'
    //   },
    //   {
    //     subject: 'additions to the master list report',
    //     note: 'Can filter master list by creation date',
    //     tSign: true,
    //     iSign: false,
    //     ticket: 'TQS-842'
    //   },
    //   {
    //     subject: 'BrokerBin Report',
    //     tSign: false,
    //     iSign: false,
    //     ticket: 'TQS-843'
    //   },
    //   {
    //     subject: 'Daily receiving report by employee',
    //     tSign: false,
    //     iSign: false,
    //     ticket: 'TQS-844'
    //   },
    //   {
    //     subject: 'Daily shipping report total $',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Tax Report for items sold to each state',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Open AP Invoices with aging',
    //     note: 'Quickbooks should handle this',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Open AP Invoices with aging with select dates',
    //     note: 'Quickbooks should handle this',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Void Checks',
    //     note: 'Quickbooks should handle this',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Open AR invoices with aging',
    //     note: 'Quickbooks should handle this',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Open AR Invoices with aging with select dates',
    //     note: 'Quickbooks should handle this',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Income Statement',
    //     note: 'Quickbooks should handle this',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Balance Sheet',
    //     note: 'Quickbooks should handle this',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Trial Balance',
    //     note: 'Quickbooks should handle this',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'AR receipts and credits register',
    //     note: 'Quickbooks should handle this',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Customer Statement',
    //     note: 'Quickbooks should handle this',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Invoice Register',
    //     note: 'Quickbooks should handle this',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Projected AP invoices',
    //     note: 'Quickbooks should handle this',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Rep Margin Report',
    //     note: 'Quickbooks should handle this',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'GL Detail per GL account',
    //     note: 'Quickbooks should handle this',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Master Inventory List',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Work Order',
    //     note: 'Not sure what the requirements for this one are',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Bank Rec Report Select multiple accounts',
    //     note: 'Quickbooks',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Master Inventory Summary',
    //     note: 'Not sure what the requirements are for this',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Buy/Sell History',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Unpaid Commission reports',
    //     note: 'Quickbooks',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   }
    // ]
    // const breakdownSteps: Step[] = [
    //   {
    //     subject: 'Open up a breakdown view',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'enter a list of parts from a pt item to break down',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'spread cost of original on new items',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'essentially do what is done for receiving but for breakdown items',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   }
    // ]
    // const processSteps: Step[] = [
    //   {
    //     subject: 'Look up items (st and pt)',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Pull items from warehouse',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Recieve stock items in pt',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Allocate to st',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Relocate items to warehouse',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'make sure eveything is pulled correctly for st',
    //     note: 'We have a mobile app that will be used after everyone is on board that shoud make this 100x easier',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Bring complete ST to shipping',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   }
    // ]
    // const configSteps: Step[] = [
    //   {
    //     subject: 'Permission System',
    //     note: 'This is roughly 80% complete',
    //     tSign: false,
    //     iSign: false,
    //     ticket: 'TQS-19'
    //   },
    //   {
    //     subject: 'Set defaults for all systems',
    //     note: 'This will need to be much more specific before we can confidently say we have this.',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'PT Digits',
    //     note: 'If I understand this correctly, this is not needed because IQ is weird?',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Number of digits',
    //     note: 'Number of digits for what?',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Default terms',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Default Credit limits',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Set Breakdown default name',
    //     note: 'What is this exactly? In our platform breakdowns act similar to orders in that there is a unique id for them',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Set Report options',
    //     note: 'We are going to need what options specifically',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Whether to Auto receive or not',
    //     note: 'We do not currently support auto-receiving and have been told by a few IGS members that it is not needed',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Prompt for Auto receive or not',
    //     note: 'See "Whether to Auto receive or not"',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Add user defined fields without having to contact TS',
    //     note: 'This is not something we are going to support. BUT adding a field in our system takes maybe 2-3 lines of code total ' +
    //     'and can be done in minutes.',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Select codes for all automatic accounts',
    //     note: 'I am not sure what this is, maybe will be handled by Quickbooks?',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Cost of sales',
    //     note: 'Quickbooks should cover this',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Inventory for resale',
    //     note: 'I am not sure what this means',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Inventory Adjustment',
    //     note: 'Quickbooks should cover this',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'RMA unbook/rebook',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Intercompany sales',
    //     note: 'What does this process look like exactly?',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Inventory Write Down',
    //     tSign: false,
    //     iSign: false,
    //     ticket: 'TQS-162'
    //   },
    //   {
    //     subject: 'Month End Processing',
    //     note: 'I think Quickbooks should handle this?',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Credit card info that will show up on PT order for payment with contract',
    //     note: 'Quickbooks?',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Set up tax Codes',
    //     note: 'I think Quickbooks should handle this?',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Enter user info',
    //     note: 'What kind of user info?',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Abilty to set up system shipping defaults',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Set up User comments list and list where they can be entered.',
    //     tSign: true,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Create GL numbers - names',
    //     note: 'Quickbooks',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Assign GL structure and categories',
    //     note: 'Quickbooks',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   },
    //   {
    //     subject: 'Set up state tax GL numbers',
    //     note: 'Quickbooks',
    //     tSign: false,
    //     iSign: false,
    //     ticket: ''
    //   }
    // ]

    const statuses: {[key: number]: { color: string, text: string }} = {
      2: { text: 'Awaiting Assignment', color: 'gray' },
      3: { text: 'Awaiting Customer Reply', color: 'warning' },
      6: { text: 'Awaiting Customer Reply', color: 'warning' },
      8: { text: 'Change is Queued for Release', color: 'primary' },
      9: { text: 'In Progress', color: 'light-blue' },
      10: { text: 'Change Accepted and Awaiting Assignment', color: 'grey' },
      11: { text: 'Deferred to Later Update -- Backlog', color: 'black' }
    }

    const alphaInProgressMessage = 'Alpha actively in-progress'
    const printMessageStatus = 'Ready for Traqsys sign-off after printing feature is complete'
    const departments: Ref<IDepartment[]> = ref([
      {
        name: 'Accounting',
        search: '',
        stepSearch: '',
        steps: [],
        color: 'accounting',
        progress: 0,
        persons: [
          {
            name: 'Teresa Kintop',
            signOff: false
          }
        ],
        statusMessage: 'QuickBooks integration in-progress',
        tab: null,
        tSign: false,
        iSign: false,
        tag: 'acct',
        bugs: []
      },
      {
        name: 'Breakdown / Buildup',
        search: '',
        stepSearch: '',
        steps: [],
        color: 'primary',
        statusMessage: 'Ready for Testing',
        progress: 100,
        persons: [
          {
            name: 'Bob Privett',
            signOff: false
          }
        ],
        tSign: true,
        iSign: false,
        tag: 'bdbu',
        tab: null,
        bugs: []
      },
      {
        name: 'Customer Service',
        search: '',
        stepSearch: '',
        steps: [],
        statusMessage: alphaInProgressMessage,
        color: 'clients',
        progress: 67,
        persons: [
          {
            name: 'Chelsey Perrault',
            signOff: false
          },
          {
            name: 'Ta Vang',
            signOff: false
          }
        ],
        tSign: false,
        iSign: false,
        tab: null,
        tag: 'csrv',
        bugs: []
      },
      {
        name: 'Inventory',
        search: '',
        stepSearch: '',
        steps: [],
        statusMessage: alphaInProgressMessage,
        color: 'black',
        progress: 86,
        persons: [
          {
            name: 'Teresa Kintop',
            signOff: false
          }
        ],
        tSign: false,
        iSign: false,
        tab: null,
        tag: 'inventory',
        bugs: []
      },
      {
        name: 'Processing',
        search: '',
        stepSearch: '',
        steps: [],
        statusMessage: 'Ready for testing',
        color: 'processing',
        progress: 100,
        persons: [
          {
            name: 'Shirly Lo',
            signOff: false
          }
        ],
        tSign: true,
        iSign: false,
        tag: 'proc',
        tab: null,
        bugs: []
      },
      {
        name: 'Purchasing',
        search: '',
        stepSearch: '',
        steps: [],
        statusMessage: alphaInProgressMessage,
        color: 'purchases',
        progress: 70,
        persons: [
          {
            name: 'Patti McCoven',
            signOff: false
          },
          {
            name: 'Joe Battaglia',
            signOff: false
          },
          {
            name: 'Dan Battaglia',
            signOff: false
          }
        ],
        tab: null,
        tSign: false,
        iSign: false,
        tag: 'prch',
        bugs: []
      },
      {
        name: 'Receiving',
        search: '',
        stepSearch: '',
        steps: [],
        statusMessage: 'Ready for testing',
        color: 'primary',
        tab: null,
        progress: 100,
        persons: [
          {
            name: 'Bob Privett',
            signOff: false
          }
        ],
        tSign: true,
        iSign: false,
        tag: 'recv',
        bugs: []
      },
      {
        name: 'Reports',
        search: '',
        stepSearch: '',
        steps: [],
        statusMessage: '',
        color: 'blue lighten-3',
        tab: null,
        progress: 20,
        persons: [
          {
            name: 'Greg Vyskocil',
            signOff: false
          }
        ],
        tSign: false,
        iSign: false,
        tag: 'report',
        bugs: []
      },
      {
        name: 'Sales',
        search: '',
        stepSearch: '',
        steps: [],
        statusMessage: alphaInProgressMessage,
        color: 'sales',
        progress: 66,
        tab: null,
        persons: [
          {
            name: 'Patti McCoven',
            signOff: false
          },
          {
            name: 'Joe Battaglia',
            signOff: false
          },
          {
            name: 'Dan Battaglia',
            signOff: false
          }
        ],
        tSign: false,
        iSign: false,
        tag: 'sale',
        bugs: []
      },
      {
        name: 'Shipping',
        search: '',
        stepSearch: '',
        steps: [],
        statusMessage: 'Ready for testing',
        color: 'shipping',
        progress: 95,
        tab: null,
        persons: [
          {
            name: 'Ed Olson',
            signOff: false
          }
        ],
        tSign: false,
        iSign: false,
        tag: 'ship',
        bugs: []
      },
      {
        name: 'System Config/Setup',
        search: '',
        stepSearch: '',
        steps: [],
        statusMessage: '',
        color: 'secondary darken-3',
        progress: 22,
        tab: null,
        persons: [
          { name: 'Teresa Kintop', signOff: false }
        ],
        tSign: false,
        iSign: false,
        tag: 'config',
        bugs: []
      },
      {
        name: 'Tech',
        search: '',
        stepSearch: '',
        steps: [],
        statusMessage: 'Ready for testing',
        color: 'secondary',
        progress: 100,
        tab: null,
        persons: [
          {
            name: 'Jean Baha',
            signOff: false
          }
        ],
        tSign: true,
        iSign: false,
        tag: 'tech',
        bugs: []
      }
    ])
    const style = reactive({
      'overflow-x': 'auto',
      'max-width': computed(() => {
        const el = document.getElementsByClassName('a-scroller')
        return el?.item(0)?.getBoundingClientRect().width ?? '100%'
      })
    })
    const departmentSearch: Ref<string> = ref('')
    const filteredDepartments: ComputedRef<IDepartment[]> = computed(() => {
      return departments.value.filter(d => d.name.toLowerCase().indexOf(departmentSearch.value || '') > -1)
    })

    const tabs = ref(null)

    const goToHelpDesk = (ticket: number) => {
      if (ticket) window.open(`https://help.traqsys.com/support/tickets/${ticket}`)
    }

    const currentPhase = ref('Alpha')
    const nonActivePhase = ref('Beta')
    const swap = () => {
      const hold = currentPhase.value
      currentPhase.value = nonActivePhase.value
      nonActivePhase.value = hold
    }

    const organizeTickets = (tickets: ticket[]): void => {
      for (const d of departments.value) {
        const ticketsToAdd = tickets.filter(t => (t.tags ?? []).indexOf(`beta-${d.tag}`) > -1).map(t => {
          const status = getTicketStatus(t.status)
          return {
            ...t,
            statusText: status.text,
            statusColor: status.color
          }
        })
        for (const t of ticketsToAdd) {
          const jiraTag = t.tags?.find(t => t.includes('TQS'))
          if (jiraTag) {
            const step = d.steps.find(s => s.ticket.jira === jiraTag)
            if (step) {
              step.ticket.link = t.id
            }
          }
        }
        d.bugs.push(...ticketsToAdd)
      }
    }

    const tagQuery = computed(() => {
      let returnable = ''
      const tags = departments.value.map(d => `tag:'beta-${d.tag}'`)
      tags.forEach(t => {
        returnable += `${t} OR `
      })
      returnable = returnable.substr(0, returnable.length - 4)
      return returnable
    })
    const statusQuery = '(status:2 OR status:3 OR status:6 OR status:8 OR status:9 OR status:10 OR status:11)'
    const query = encodeURI(`(${tagQuery.value}) AND ${statusQuery}`)

    const getTickets = () => {
      fetch(`https://traqsys.freshdesk.com/api/v2/search/tickets?query="${query}"`, {
        // fetch('https://traqsys.freshdesk.com/api/v2/tickets', {
        headers: {
          Authorization: 'Basic VENwUjcxYWprV2d0M1cyM3hCMVc6WA==',
          'Content-Type': 'application/json'
        }
      }).then(r => r.json()).then(r => organizeTickets(r.results))
    }

    const filter = (search: string, tickets: Array<ticket | Step>): Array<ticket | Step> => {
      return tickets.filter(t => t.subject.toLowerCase().indexOf(search.toLowerCase()))
    }

    const getPercentage = (department: IDepartment, key: 'tSign' | 'iSign'): string => {
      const completed = department.steps.filter(s => s[key]).length
      if (department.steps.length === 0) return '--'
      return Math.floor((completed / department.steps.length) * 100) + '%'
    }

    const getTicketedPercentage = (department: IDepartment): string => {
      const ticketed = department.steps.filter(s => s.ticket.jira !== '').length
      if (department.steps.length === 0) return '--'
      return Math.floor((ticketed / department.steps.length) * 100) + '%'
    }

    const getTicketStatus = (status: number): { text: string, color: string } => {
      return statuses[status]
    }

    // const parseTheJson = () => {
    //   const jsonIssues: Step[] = require('./issues.json')
    //   const testissues: Step[] = require('./testissues.json')
    //   const newIssues = []
    //   let count = 0
    //   const issuesNotMatchingJsonButWithoutTicket = (steps: Step[]) => {
    //     // @ts-ignore
    //     return steps.filter(f => !f.tSign && f.ticket === '' && f.department !== 'accounting' && !f.note?.toLowerCase().includes('quickbooks'))
    //   }
    //   // go through each coded issue and see if it matches the json issue
    //   // if it does add the department and ticket
    //   for (const d of departments.value) {
    //     for (const step of d.steps) {
    //       count++
    //       if (!step.tSign) { // if uncompleted (because we only added tickets to ones that are not finished)
    //         const index = jsonIssues.findIndex(i => i.subject.toLowerCase() === step.subject.toLowerCase())
    //         if (index === -1) {
    //           newIssues.push({
    //             ...step,
    //             // @ts-ignore
    //             department: d.name.toLowerCase()
    //           })
    //         } else {
    //           const thing = jsonIssues[index]
    //           // if it found one
    //           newIssues.push({
    //             ...thing,
    //             // @ts-ignore
    //             tSign: thing.tSign === 'TRUE',
    //             // @ts-ignore
    //             iSign: thing.iSign === 'TRUE'
    //           })
    //         }
    //       } else {
    //         newIssues.push({
    //           ...step,
    //           department: d.name.toLowerCase()
    //         })
    //       }
    //     }
    //   }
    //   console.log(JSON.stringify(newIssues))
    // }
    //

    // const fixTheStuff = () => {
    //   const issues = require('./testissues.json')
    //   for (const i of issues) {
    //     console.log(i.ticket)
    //     const newTicket = {
    //       jira: i.ticket,
    //       link: null
    //     }
    //     i.ticket = newTicket
    //   }
    //   console.log(JSON.stringify(issues))
    // }

    const doTheDo = () => {
      const issues = require('./testissues.json')
      const getIssues = (name: string) => {
        return issues.filter((i: any) => i.department === name.toLowerCase())
      }

      for (const d of departments.value) {
        d.steps = getIssues(d.name)
      }
    }

    onMounted(() => {
      getTickets()
      // parseTheJson()
      // fixTheStuff()
      doTheDo()
    })

    return {
      filteredDepartments,
      departmentSearch,
      getTicketStatus,
      getPercentage,
      getTicketedPercentage,
      filter,
      tabs,
      style,
      departments,
      goToHelpDesk,
      currentPhase,
      nonActivePhase,
      swap
    }
  }
})
