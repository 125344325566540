import jsPDF from 'jspdf'
import * as AT from 'jspdf-autotable'
import JsBarcode from 'jsbarcode'
import autoTable, { applyPlugin } from 'jspdf-autotable'
import { parseAddress, Address } from '@/lib/parseAddress'
import { transpose } from '@/lib/transpose'

applyPlugin(jsPDF)

export enum Color {
  Black = '#000000',
  White = '#ffffff',
  Grey = '#888888',
  Red = '#ff0000'
}
export enum FontSize {
  Normal = 10,
  Large = 18
}
export enum Margin {
  None = 0,
  Normal = 10,
}
export const ATDefaults: AT.UserOptions = {
  theme: 'plain',
  margin: Margin.Normal,
  styles: {
    lineWidth: 0.001
  }
}
export const Settings = {
  startTables: 35,
  image: {
    width: 50,
    height: 30
  },
  margin: {
    top: 5,
    left: 10,
    bottom: 5,
    right: 10
  },
  font: {
    name: 'helvetica',
    size: FontSize.Normal,
    style: 'normal'
  }
}

export interface Info {
  [key: string]: string
}
export interface ImageDimensions {
  width: number
  height: number
}
export interface Image {
  data: HTMLImageElement | string
  width: number
  height: number
}
export interface PDFData {
  title?: string
  logo?: Image
  barcode?: string
  addresses?: Address[]
  info?: Info
  parts?: Info[]
  partsTotal?: string
  comment?: string
  contractSections?: Info[]
  footer?: string
}

export function blankSpace (doc: any) {
  // blankTable(doc, { body: [['']], startY: doc.lastAutoTable.finalY }, extra)
  blankTable(doc, { body: [['']], startY: doc.lastAutoTable.finalY })
}
export function borderedTable (doc: any, options: AT.UserOptions, extra: AT.UserOptions = {}) {
  autoTable(doc, Object.assign({}, ATDefaults, extra, options))
}
export function blankTable (doc: any, options: AT.UserOptions, extra: AT.UserOptions = {}) {
  autoTable(doc, Object.assign({}, { theme: 'plain' }, extra, options))
}

export function parseInfo (info: Info | Info[]): AT.UserOptions {
  const infoarray = Array.isArray(info) ? info : [info]
  const head: string[] = Object.keys(infoarray[0])
  const body: string[][] = []
  for (const i of infoarray) {
    const tmp: string[] = []
    for (const key of head) {
      tmp.push(i?.[key])
    }
    body.push(tmp)
  }
  return {
    head: [head],
    body: body
  }
}
export function createAddressTable (doc: any, addressTable?: Address[]) {
  if (!addressTable || addressTable.length === 0) {
    return
  }
  const head: string[] = []
  const body: string[][] = []
  addressTable?.forEach((address) => {
    const tmp = []
    head.push(address?.title ?? '')
    tmp.push(parseAddress(address))
    tmp.push((address.extra ?? []).filter(i => !!i).join('\n').trim())
    body.push(tmp)
  })
  borderedTable(doc, {
    head: [head],
    startY: Settings.startTables,
    headStyles: {
      fontSize: FontSize.Normal
    },
    body: transpose(body)
  })
}
export function createInfoTable (doc: any, info?: Info) {
  if (!info) {
    return
  }
  borderedTable(doc, parseInfo(info))
}
export function createPartsTable (doc: any, parts?: Info[], total: string = '') {
  if ((parts ?? []).length > 0) {
    const table = parseInfo(parts!)
    const foot: AT.CellInput[] = new Array(table?.head?.[0].length ?? 0).fill('')
    if (foot.length > 0 && total !== '') {
      foot[foot.length - 1] = total
    }
    borderedTable(doc, {
      ...table,
      foot: [foot]
    })
  }
}
export function createContractTables (doc: any, contractSections?: Info[]) {
  blankSpace(doc)
  for (const section of contractSections ?? []) {
    blankTable(doc, parseInfo(section!), { startY: doc.lastAutoTable.finalY + 2, styles: { cellPadding: { top: 0.1, left: 1 } } })
  }
}
export function footer (doc: any, text: string = '') {
  const date = new Date().toLocaleString()
  // doc.setFont(SETTINGS.font.name, 'italic')
  // doc.setFontSize(FontSize.Normal)
  // doc.text(text, Margin.Normal, doc.internal.pageSize.getHeight() - Margin.Normal, { align: 'left', baseline: 'hanging' })
  const margin = Margin.Normal
  const height = doc.internal.pageSize.getHeight() - margin
  const width = doc.internal.pageSize.getWidth() - margin
  // doc.text(text, margin, height, { align: 'left', baseline: 'hanging' })
  doc.text(text, margin, height)
  // doc.setFont(SETTINGS.font.name, SETTINGS.font.style)
  doc.text(`${date}`, width, height, { align: 'right' })
  // doc.setFont(SETTINGS.font.name, SETTINGS.font.style)
  // doc.setFontSize(SETTINGS.font.size)
}
export function writeOnEveryPage (doc: any, footerText: string = ''): number {
  const total: number = doc.internal.getNumberOfPages()
  for (let i = 1; i < total + 1; i++) {
    doc.setPage(i)
    footer(doc, footerText)
  }
  return total
}
export function createComment (doc: any, comment?: string) {
  if (!comment) {
    return
  }
  blankTable(doc, {
    styles: {
      fontSize: FontSize.Normal
    },
    head: [['Comment']],
    body: [[comment]],
    bodyStyles: {
      cellPadding: {
        top: 1,
        left: Margin.Normal
      }
    }
  })
}
export function createTitle (doc: AT.jsPDFDocument | jsPDF, title: string = '') {
  const pdfWidth = doc.internal.pageSize.getWidth()

  doc.setFont(Settings.font.name, 'bold')
  doc.setFontSize(FontSize.Large)

  doc.text(title, pdfWidth - Margin.Normal, Margin.Normal, { align: 'right' })

  doc.setFont(Settings.font.name, 'normal')
  doc.setFontSize(FontSize.Normal)
}
export function createLogo (doc: any, img?: Image) {
  if (!img) {
    return
  }
  const maxWidth = Settings.image.width
  const maxHeight = Settings.image.height
  const ratio = Math.min(maxWidth / img?.width!, maxHeight / img?.height!)
  const newWidth = img.width * ratio
  const newHeight = img.height * ratio
  const startX = Settings.margin.left
  const startY = Settings.margin.top
  const centeredY = startY + (maxHeight / 2) - (newHeight / 2)
  doc.addImage(img?.data, startX, centeredY, newWidth, newHeight)
}

export function px2mm (px: number) {
  const ratio = window.outerWidth / window.outerHeight
  return Math.floor(px * 0.264583)
}

export function createBarcode (doc: any, barcode?: string) {
  if (!barcode) {
    return barcode
  }
  const pdfWidth = doc.internal.pageSize.getWidth()

  const canvas = document.createElement('canvas')

  JsBarcode(canvas, barcode)

  const bw = px2mm(canvas.width)
  const bh = px2mm(canvas.height)

  doc.addImage(canvas, 'JPEG', (pdfWidth / 2) - (bw / 2), 0, bw, bh)
}

export function setupDocument (): any {
  /* eslint-disable-next-line new-cap */
  return new jsPDF('p', 'mm', 'letter')
}
export function genPDF (data: PDFData): AT.jsPDFDocument {
  const doc = setupDocument()
  try {
    doc.setFontSize(FontSize.Normal)
    createBarcode(doc, data?.barcode)
    createLogo(doc, data?.logo)
    createTitle(doc, data?.title)
    createAddressTable(doc, data?.addresses)
    createInfoTable(doc, data?.info)
    createPartsTable(doc, data?.parts, data?.partsTotal)
    createComment(doc, data?.comment)
    createContractTables(doc, data?.contractSections)
    writeOnEveryPage(doc, data?.footer)
    return doc
  } catch (e) {
    console.log(e.message)
  }
}
