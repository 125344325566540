<template>
  <div class="pa-0 ma-0">
    <v-card height="660">
      <v-card-title :class="{ 'teal': !isST, 'indigo': isST }">
        <h3 class="white--text">{{ isST ? 'Sale ' : 'Purchase '}}Order {{ params.data.id }}</h3>
      </v-card-title>
      <v-card-text style="overflow-y: auto; height: calc(100% - 50px);" class="pb-0">
        <v-container pa-0 grid-list-xl fluid>
          <v-layout row nowrap justify-start align-center>
            <v-flex xs8>
              <v-layout row nowrap justify-start align-center>
                <!-- Items Button -->
                <v-flex shrink>
                  <v-btn
                    small
                    @click="showItems"
                    :color="isST ? 'indigo' : 'teal'"
                    class="white--text"
                    :flat="summaryActive"
                    :class="{ shimmer: needsToAllocate && !itemsActive }"
                  >Product Details</v-btn>
                </v-flex>
                <!-- Summary Button -->
                <v-flex shrink>
                  <v-btn
                    small
                    @click="showSummary"
                    :color="isST ? 'indigo' : 'teal'"
                    class="white--text"
                    :flat="itemsActive"
                  >Summary</v-btn>
                </v-flex>
                <!-- Order Status Component -->
                <v-flex align-self-center xs8 lg6 offset-xs2 offset-lg2 class="text-xs-center" px-0 v-if="summaryActive && false">
                  <order-status/>
                </v-flex>
                <!-- Processing Notification -->
                <v-flex
                  v-if="processMessage && summaryActive"
                  offset-lg1
                  grow
                >
                  <v-alert
                    outline
                    class="my-0"
                    :value="true"
                    :type="processed ? 'success' : 'info'"
                    :color="processed ? 'successAlert' : 'info'"
                    style="text-overflow: ellipsis; overflow-x: hidden; white-space: nowrap; max-height: 38px;"
                  >
                    <h4>{{ processMessage }}</h4></v-alert>
                </v-flex>
                <!-- Allocation Notification -->
                <v-flex
                  v-else-if="needsToAllocate && itemsActive"
                  offset-lg1
                  grow
                >
                  <v-alert
                    class="my-0"
                    :value="true"
                    type="info"
                    color="info"
                    style="text-overflow: ellipsis; overflow-x: hidden; white-space: nowrap; max-height: 38px;"
                  >
                    <span>Right click on items to allocate!</span></v-alert>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs4>
              <v-layout row nowrap justify-end align-center>
                <!-- Edit Button -->
<!--                <v-btn-->
<!--                  small-->
<!--                  flat-->
<!--                  color="warning"-->
<!--                  @click="editOrder()"-->
<!--                >-->
<!--                  <v-icon class="mr-2" left small>fal fa-pencil</v-icon>-->
<!--                  Edit-->
<!--                </v-btn>-->
                <!-- Process order Button -->
                <v-slide-y-reverse-transition>
                  <v-flex shrink v-if="summaryActive && params.data.status.status === 'Created' && isST">
                    <v-btn
                      small
                      flat
                      color="info"
                      :loading="processingOrder"
                      @click="processOrder"
                    >
                      {{ processable ? 'Process Order' : 'Get Next Steps' }}
                    </v-btn>
                  </v-flex>
                </v-slide-y-reverse-transition>
                <!-- Refresh -->
                <v-flex shrink>
                  <v-btn
                    small
                    flat
                    :color="isST ? 'indigo' : 'teal'"
                    @click="refresh()"
                    :loading="$apollo.queries.rowData.loading"
                  >
                    Refresh
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
        <v-container pt-2 pb-0 fluid style="height: calc(100% - 60px);">
          <v-layout row nowrap justify-center align-space-around fill-height>
            <v-flex xs12 pt-2 >
              <v-slide-x-transition mode="out-in">
                <keep-alive>
                  <ag-grid-vue
                    v-if="itemsActive"
                    style="width: 100%; height: 100%;"
                    data-cy="order-details-grid"
                    class="ag-grid-traqsys"
                    :gridOptions="gridOptions"
                    :columnDefs="columnDefs"
                    :getContextMenuItems="contextMenuItems"
                    :rowData="rowData"
                    :frameworkComponents="frameworkComponents"
                    @cell-key-down="kbEventHandler($event, masterKbShortCuts, [])"
                    @grid-ready="onGridReady"
                  />
                  <order-summary
                    :key="theKey"
                    v-if="summaryActive"
                    :data="summaryData"
                    :items="items"
                    :loading="loadingItems"
                    @refresh-shipping="getData"
                    @edit-order="editOrder"
                  >
                  </order-summary>
                </keep-alive>
              </v-slide-x-transition>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="showDialog"
      lazy
      persistent
      width="90%"
    >
      <add-to-rma
        v-if="localDialog === 'addToRMA'"
        @cancel="localDialog = ''"
      />
      <add-country-of-origin
        v-if="localDialog === 'addCoO'"
        @save="saveCoO"
        @cancel="localDialog = ''"
      />
      <mark-tested
        v-if="localDialog === 'markTested'"
        :nodes="nodesToTest"
        @close="localDialog = ''"
      />
      <allocation
        v-if="localDialog === 'allocation'"
        :part="partToAllocate"
        :allocated-parts="[]"
        :repId="`${params.data.rep.id}`"
        @save="saveAllocations"
      >
        <template #actions="{ save: doTheAllocation, parts }">
          <cancel-button class="pr-3" :success="successfullyAllocated" @click="cancelAllocations"/>
          <submit-button :disabled="parts.length === 0 || successfullyAllocated" :loading="saving" @click="doTheAllocation" />
        </template>
      </allocation>
    </v-dialog>
  </div>
</template>

<script>
import grid from '../mixins/grid'
import { AgGridVue } from 'ag-grid-vue'
import orderSummary from '../templates/orderSummary'
import columns from '@/components/mixins/columns'
import addToRMA from '../dialogs/addToRMA'
import addCoO from '../dialogs/addCoO'
import markTested from '../dialogs/markTested'
import moneyFormatter from '../mixins/moneyFormatter'
import allocation from '../dialogs/allocation'
import partNumberCell from './partNumberCell'
import mutateOrders from '@/api/graphql/mutationsJS/mutateOrders'
import mutateItems from '@/api/graphql/mutationsJS/mutateItems'
import mutateOrderItems from '@/api/graphql/mutationsJS/mutateOrderItems'
import orderStatus from '../templates/orderStatus'
import { GridEvents } from '@/store/gridEvents'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import { GetAllocatedObjectWithIoSplit } from '@/lib/moneyHelpers'
import {
  UPDATE_SOLD_ITEMS,
  GET_PURCHASED_ITEMS,
  GET_SOLD_ITEMS,
  UPDATE_SALE_MONEY, getUpdateSaleMoneyData
} from '@/api/graphql/Constants/Orders'
import { GetShippingDetails } from '@/lib/fieldDataGetters'
import { COLUMN_TYPES, COLUMNS__PURCHASES_NESTED, COLUMNS__SALES_NESTED } from '@/lib/agGridColumnConfiguration'
import { CONTEXT_MENU__PURCHASE_DETAILS, CONTEXT_MENU__SALES_DETAILS } from '@/lib/agGridContextMenuConfigurations'
import { KB_SHORTCUT__PURCHASE_DETAILS, KB_SHORTCUT__SALE_DETAILS } from '@/lib/agGridKbShortCuts'
import { GET_SHIPMENT_ORDER_DETAILS } from '@/api/graphql/Constants/Shipments'
import { apolloClient as apollo } from '@/api/graphql/apollo'
import CancelButton from '@/components/buttons/CancelButton'
import SubmitButton from '@/components/buttons/SubmitButton'
import { PREP_SALE_FOR_EDIT } from '@/composition/UseSale.ts'
import { PREP_PURCHASE_FOR_EDIT } from '@/composition/UsePurchase.ts'
export default {
  name: 'orderDetailsRow',
  mixins: [grid, columns, moneyFormatter, mutateOrders, mutateOrderItems, mutateItems],
  components: {
    'ag-grid-vue': AgGridVue,
    'order-summary': orderSummary,
    'add-to-rma': addToRMA,
    'add-country-of-origin': addCoO,
    'mark-tested': markTested,
    'allocation': allocation,
    'orderStatus': orderStatus,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  computed: {
    masterKbShortCuts () {
      return this.isST ? KB_SHORTCUT__SALE_DETAILS(this.params.data.id, this.params.data.client.id, this.contextMenuCallback) : KB_SHORTCUT__PURCHASE_DETAILS(this.params.data.id, this.params.data.client.id, this.contextMenuCallback)
    },

    processable () {
      return this.checkSalesOrderForProcessing() && this.checkShipmentOrderForProcessing() && this.checkSaleItems()
    },

    orderType () {
      return this.$route.fullPath.includes('purchases') ? 'pts' : 'sts'
    },

    isST () {
      return this.orderType === 'sts'
    },

    showDialog: {
      get () {
        return this.localDialog
      },
      set (value) {
        return value
      }
    },

    columnConfigName () {
      return this.isST ? 'stItems' : 'ptItems'
    },

    columnConfig () {
      return this.$store.getters['profile/tableConfig'](this.columnConfigName)
    },

    rowToExpand: {
      get () {
        return this.$store.state.grid.rowToExpand
      },
      set (value) {
        if (!value) {
          this.$store.dispatch('grid/resetRowToExpand')
        } else {
          this.$store.dispatch('grid/changeRowToExpand', { id: value })
        }
      }
    },

    allAllocated () {
      for (const row of this.rowData) {
        if (!row.item) {
          return false
        }
      }
      return true
    },

    items () {
      return this.prepItems(this.rowData)
    }
  },
  watch: {
    summaryActive: function (value) {
      if (value) {
        this.setWindowWidth()
      }
    },

    columnConfig: function (value) {
      // this.columnApi.setColumnState(JSON.parse(value))
    }
  },
  data () {
    return {
      theKey: 1,
      localDialog: '',
      successfullyAllocated: false,
      unAllocatedCount: 0,
      processMessage: '',
      needsToAllocate: false,
      needsShipment: false,
      itemsActive: true,
      summaryActive: false,
      summaryData: {
        isSt: null,
        id: null,
        client: null,
        rep: null,
        shipping: null
      },
      width: '',
      cardHeight: '670',
      clientId: 0,
      shipOrder: {},
      showContact: false,
      showShipping: false,
      nodesToAddCoOTo: [],
      nodesToTest: [],
      selectedNodes: null,
      partToAllocate: { quantity: 0, pn: '' },
      allocatedParts: [],
      windowWidth: window.innerWidth,
      styleObject: {
        'overflow-y': 'hidden'
      },
      processingOrder: false,
      processed: false,

      frameworkComponents: {},
      // grid data
      gridOptions: {
        allowDragFromColumnsToolPanel: true,
        tooltipShowDelay: 0,
        animateRows: true,
        columnTypes: COLUMN_TYPES,
        defaultColDef: {
          enableValue: false,
          enableRowGroup: true,
          enablePivot: false,
          sortable: true,
          resizable: true
        },
        editType: 'singleCell',
        enableRangeSelection: false,
        getRowNodeId: (data) => {
          return data.id
        },
        headerHeight: 35,
        masterDetail: false,
        onFirstDataRendered: params => {
          // params.api.sizeColumnsToFit()
        },
        onDragStopped: (params) => {
          const columnState = params.columnApi.getColumnState()
          this.$store.dispatch('profile/updateTableConfig', { table: this.columnConfigName, config: columnState })
        },
        onColumnResized: (params) => {
          if (params.finished) {
            const columnState = params.columnApi.getColumnState()
            this.$store.dispatch('profile/updateTableConfig', { table: this.columnConfigName, config: columnState })
          }
        },
        onColumnPinned: (params) => {
          const columnState = params.columnApi.getColumnState()
          this.$store.dispatch('profile/updateTableConfig', { table: this.columnConfigName, config: columnState })
        },
        onColumnRowGroupChanged (params) {
          const columnState = params.columnApi.getColumnState()
          this.$store.dispatch('profile/updateTableConfig', { table: this.columnConfigName, config: columnState })
        },
        onSelectionChanged: (params) => {
          this.selectedNodes = params.api.getSelectedRows()
        },
        rowHeight: 30,
        rowSelection: 'multiple',
        sideBar: {
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressValues: true
              }
            }
          ]
        },
        sortingOrder: ['asc', 'desc'],
        statusBar: {
          statusPanels: [
            { statusPanel: 'agSelectedRowCountComponent', align: 'left' }
          ]
        }
      },
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      editableNodes: [],
      rowData: [],
      contextMenuItems: null,
      gridMode: 'read',

      loadingItems: false
    }
  },
  apollo: {
    rowData: {
      query () {
        return this.$route.fullPath.includes('purchase') ? GET_PURCHASED_ITEMS : GET_SOLD_ITEMS
      },
      variables () {
        return {
          id: this.params.data.id
        }
      },
      update (data) {
        if (data.items) {
          return data.items
        } else {
          return []
        }
      },
      watchLoading (isLoading) {
        this.loadingItems = isLoading
      }
    }
  },
  methods: {
    contextMenuCallback (params) {
      if (params.data && params.dataKey) this[params.dataKey] = params.data
      if (params.dialogToOpen) this.localDialog = params.dialogToOpen
      if (params.functionToRun) this[params.functionToRun](params.params)
    },

    kbEventHandler (params) {
      GridKbEventHandler(params, [], this.masterKbShortCuts)
    },

    prepareAllocation ({ partToAllocate, partsToAllocate }) {
      this.partToAllocate = partToAllocate
      this.$store.dispatch('sale/update', { key: 'rep', value: this.params.data.rep.id })
      for (const part of partsToAllocate) {
        this.allocatedParts.push(part)
      }
      this.localDialog = 'allocation'
    },

    editOrder () {
      if (this.isST) {
        PREP_SALE_FOR_EDIT({ id: this.params.data.id, statusId: this.params.data.status.id })
      } else {
        PREP_PURCHASE_FOR_EDIT({ id: this.params.data.id, statusId: this.params.data.status.id })
      }
    },

    /**
     * Used to update data without manually doing a refresh
     * @params data {array}
     */
    updateRowData (data) {
      if (!data[0]) {
        data = [data]
      }
      this.gridOptions.api.applyTransaction({ update: data })
      this.gridOptions.api.redrawRows()
    },

    setWindowWidth () {
      this.windowWidth = window.innerWidth
      // if (this.windowWidth < 1264 && this.summaryActive) {
      //   this.styleObject['overflow-y'] = 'auto'
      // } else {
      //   this.styleObject['overflow-y'] = 'hidden'
      // }
    },

    showItems () {
      if (this.summaryActive) {
        this.itemsActive = true
        this.summaryActive = false
        this.$router.push({ name: this.$route.name, params: { id: this.params.data.id, nested: 'product' } })
      }
    },

    showSummary () {
      if (this.itemsActive) {
        this.itemsActive = false
        this.summaryActive = true
        this.$router.push({ name: this.$route.name, params: { id: this.params.data.id, nested: 'summary' } })
      }
    },

    async saveCoO (country) {
      this.saving = true
      try {
        for (const node of this.nodesToAddCoOTo) {
          const updatedItem = await this.updateItem({ id: node.data.item.id, country_of_origin: country })
          node.data.item.country_of_origin.name = updatedItem.coo.name
        }
        this.saving = false
        this.updateRowData(this.selectedNodes)
        this.nodesToAddCoOTo = []
        await this.$store.dispatch('notifications/addSuccessAlert', { message: 'Changed successfully.' })
      } catch (error) {
        this.saving = false
      }
    },

    onGridReady (params) {
      const detailGridId = 'detail_' + this.rowIndex

      const gridInfo = {
        id: detailGridId,
        api: params.api,
        columnApi: params.columnApi
      }
      this.gridApi = params.api
      this.columnApi = params.columnApi
      this.params.api.addDetailGridInfo(detailGridId, gridInfo)
      if (this.columnConfig && this.columnConfig.length > 0) {
        params.columnApi.setColumnState(this.columnConfig)
      }
      const sort = [{ colId: 'lineNumber', sort: 'asc' }]
      this.gridApi.setSortModel(sort)
    },

    async saveAllocations (allocatedParts) {
      this.saving = true
      const partsToAllocate = []
      for (const part of allocatedParts) {
        const preparedPart = this.allocatedParts.pop()
        part.soldFor = preparedPart.soldFor
        const allocatedObject = GetAllocatedObjectWithIoSplit({ soldItem: part })
        const soldPart = {
          ...allocatedObject,
          status_id: 85,
          id: preparedPart.id
        }
        partsToAllocate.push(soldPart)
      }
      try {
        this.$apollo.mutate({
          mutation: UPDATE_SOLD_ITEMS,
          variables: { input: partsToAllocate }
        }).then(async ({ data: { items } }) => {
          this.unAllocatedCount -= items.length
          this.updateRowData(items)
          const saleId = this.params.data.id
          const saleUpdateObject = await getUpdateSaleMoneyData(saleId)
          if (saleUpdateObject?.id) {
            const mutation = {
              mutation: UPDATE_SALE_MONEY,
              variables: { input: saleUpdateObject }
            }
            await apollo.mutate(mutation)
          }
          await this.$store.dispatch('notifications/createSnackbar', {
            message: 'Allocated successfully.',
            color: 'success',
            timeout: 4000,
            canClose: true,
            top: false
          })
          this.processMessage = ''
          this.successfullyAllocated = true
          this.needsToAllocate = false
          // this.getData().then(() => {})
        }).catch(() => {
          this.$store.dispatch('notifications/createSnackbar', {
            message: 'Could not allocated items. Try again or contact support',
            color: 'error',
            timeout: 5000
          })
        }).finally(() => {
          this.saving = false
        })
      } catch (error) {
        await this.$store.dispatch('notifications/createSnackbar', {
          message: 'Could not allocate items.',
          color: 'error',
          timeout: 4000,
          canClose: true,
          top: false
        })
        this.saving = false
      }
    },

    cancelAllocations () {
      this.localDialog = ''
      this.allocatedParts = []
      this.successfullyAllocated = false
    },

    async processOrder () {
      if (this.canProcessOrder()) {
        try {
          this.processingOrder = true
          await this.updateSale({ id: this.params.data.id, status_id: 8 })
          this.params.data.status.id = 8
          this.processingOrder = false
          this.processed = true
          this.processMessage = 'Order is being processed'
        } catch (error) {
          this.processingOrder = false
        }
      }
    },

    /**
     * Check to see if an order can be processed
     * Required items
     * Sales Order:
     ** rep id
     ** terms id
     ** contact id -- not required but should check and confirm with user
     * Shipment Order:
     ** ship to id
     ** ship from id
     ** billTo id
     ** SWITCH CASES:
     ***  delivery_date OR
     ***  carrier account id AND service id
     ***  none of that to mean cheapest service
     * Items
     ** all items allocated
     */
    canProcessOrder () {
      // check sales items for requirements
      const saleCheck = this.checkSalesOrderForProcessing()

      // check shipment order for requirements
      const shipmentOrderCheck = this.checkShipmentOrderForProcessing()

      // check items for requirements
      const itemsCheck = this.checkSaleItems()

      // go through logic
      if (saleCheck && shipmentOrderCheck) {
        if (itemsCheck) {
          return true
        } else {
          if (this.rowData.length === 0) {
            this.processMessage = 'Right click me to add/allocate parts'
          } else {
            this.processMessage = 'Allocate items.'
            this.needsToAllocate = true
          }
          return false
        }
      } else {
        if (!shipmentOrderCheck) this.needsShipment = true
        return false
      }
    },

    /**
     * Check the requires for a sale
     * @return {Boolean}
     */
    checkSalesOrderForProcessing () {
      const order = this.params.data
      const hasRep = order.rep !== null
      const hasTerms = order.terms !== null

      return hasRep && hasTerms
    },

    /**
     * Check the requirements for the shipmentOrder
     * @return {Boolean}
     */
    checkShipmentOrderForProcessing () {
      if (this.shipOrder) {
        const hasShipTo = this.shipOrder.shipTo?.id ?? false
        const hasShipFrom = this.shipOrder.shipFrom?.id ?? false
        const hasBillTo = this.shipOrder.billTo?.id ?? false
        const hasDeliveryDate = this.shipOrder.deliver_by_date ?? false
        const hasCarrierInfo = this.shipOrder.account?.id ?? false
        const hasShipService = this.shipOrder.service?.id ?? false
        const hasServiceSelected = hasDeliveryDate || (hasCarrierInfo && hasShipService) ||
          (!hasDeliveryDate && !hasCarrierInfo && !hasShipService)

        if (!hasServiceSelected) this.processMessage = 'You need to set a carrier service.'
        return hasShipTo && hasShipFrom && hasBillTo && hasServiceSelected
      } else {
        return false
      }
    },

    /**
     * Check items on an order for requirements
     * @return {Boolean}
     */
    checkSaleItems () {
      return this.allAllocated && (this.rowData?.length > 0 ?? false)
    },

    setSummaryData (response) {
      // NEED SHIPMENT COST AND TAXES TODO:
      try {
        const data = this.params.data
        const shipmentOrder = { ...response, freight: this.params?.data?.freight ?? 0 }
        this.summaryData = {
          money: {
            terms: data.terms?.name ?? '',
            total: this.params.data?.total?.amount ?? 0.00,
            tax: this.params.data?.tax?.amount ?? 0.00
          }
        }
        this.summaryData.isST = this.isST
        this.summaryData.id = data.id
        this.summaryData.client = data.client
        this.summaryData.rep = data.rep
        if (shipmentOrder) {
          this.summaryData.shipping = GetShippingDetails({ shipOrder: shipmentOrder, rep: this.params.data.rep })
        }
      } catch {
      }
    },

    prepItems (items) {
      const summaryItems = []
      if (items.length > 0) {
        let count = 0
        for (const item of items) {
          const index = summaryItems.findIndex(part => {
            return part.partID === item.part.id
          })
          if (index > -1) {
            summaryItems[index].quantity++
          } else {
            summaryItems.push({
              partID: item.part.id,
              desc: item.part.description,
              pn: item.part.pn,
              price: this.isST ? item.sold_for
                : item.original_cost,
              quantity: 1
            })
          }
          count++
        }
        this.summaryData.count = count
      } else {
        this.summaryData.count = 'No'
      }
      this.summaryData.allAllocated = this.allAllocated
      return summaryItems
    },

    /*
    async checkItems (items) {
      let itemsToUpdate = []
      for (const item of items) {
        if (item.item && item.status.id === 18) {
          const updated = await this.updateSoldItemStatus({ id: item.id, status: 19 })
          itemsToUpdate.push(updated)
        } else if (!item.item && item.status.id === 19) {
          const updated = await this.updateSoldItemStatus({ id: item.id, status: 18 })
          itemsToUpdate.push(updated)
        }
      }
      if (itemsToUpdate.length > 0) {
        this.updateRowData(itemsToUpdate)
      }
    },
*/

    refresh () {
      this.$apollo.queries.rowData.refresh()
      this.getData()
    },

    addToRowData ({ row }) {
      this.$apolloData.data.rowData.push(row)
    },

    removeIdOfRowInRowData ({ id }) {
      const index = this.$apolloData.data.rowData.findIndex(r => r.id === id)
      if (index > -1) {
        this.$apolloData.data.rowData.splice(index, 1)
      }
    },

    async getData () {
      try {
        const response = await this.$apollo.query({
          query: GET_SHIPMENT_ORDER_DETAILS,
          variables: { id: this.params.data.shipment_order.id },
          fetchPolicy: 'no-cache'
        })
        this.clientId = this.params.data.client.id
        this.shipOrder = response.data.order ?? false
        this.setSummaryData(this.shipOrder)
      } catch (error) {
      }
    },

    getMoreItems ({ value: id }) {
      if (self.params.data.id === id) {
        this.refresh()
        this.processMessage = ''
        this.needsToAllocate = false
      }
    }
  },
  async beforeMount () {
    this.frameworkComponents = {
      partNumberCell: partNumberCell
    }
    this.setWindowWidth()
    this.$nextTick(function () {
      window.addEventListener('resize', this.setWindowWidth)
    })

    if (this.$route.fullPath.includes('purchase')) {
      /* PURCHASES */
      this.contextMenuItems = params => CONTEXT_MENU__PURCHASE_DETAILS(params, this.params.data.id, this.params.data.client.id, this.contextMenuCallback)
      this.columnDefs = COLUMNS__PURCHASES_NESTED
    } else {
      /* SALES */
      this.contextMenuItems = params => CONTEXT_MENU__SALES_DETAILS(params, this.params.data.id, this.params.data.client.id, this.contextMenuCallback)
      this.columnDefs = COLUMNS__SALES_NESTED
    }
    await this.getData()

    if (this.rowToExpand) {
      this.showSummary()
      this.rowToExpand = 0
    }
    const nested = this.$route.params.nested
    if (nested) {
      if (['summary', 'product'].indexOf(nested) > -1) {
        this.summaryActive = nested === 'summary'
        this.itemsActive = nested === 'product'
      } else {
        this.$router.push({ name: this.$route.name, params: { id: this.params.data.id, nested: 'product' } })
      }
    } else {
      this.$router.push({ name: this.$route.name, params: { id: this.params.data.id, nested: 'product' } })
    }
  },
  mounted () {
    GridEvents.$on('refresh-order-details-items', this.getMoreItems)
    GridEvents.$on('addTransactionItemsRow', this.addToRowData)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.setWindowWidth)
    GridEvents.$off('refreshData')
    GridEvents.$off('addTransactionItemsRow')
    if (this.$route.params.id || this.$route.params.nested) this.$router.push({ name: this.$route.name })
  }
}
</script>

<style>
  .shimmer {
    box-shadow: 0 3px 1px -2px var(--light-blue),
      0 2px 2px 0 var(--light-blue),
      0 1px 5px 0 var(--light-blue) !important;

    -webkit-box-shadow: 0 3px 1px -2px var(--light-blue),
      0 2px 2px 0 var(--light-blue),
      0 1px 5px 0 var(--light-blue) !important;

    animation-name: shimmery;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    -webkit-animation-name: shimmery;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
  }

  @keyframes shimmery {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .v-alert {
    border-radius: 5px !important;
  }
</style>
