<template>
  <v-card :flat="eventOnly" data-cy="add-address-card">
    <v-card-title class="brown" v-if="!eventOnly">
      <h3 class="white--text">New Address Form</h3>
      <v-spacer></v-spacer>
      <span class="white--text">{{ ownerName }}</span>
    </v-card-title>
    <v-card-text class="pb-0">
      <v-container grid-list-xl py-0 id="address-input-container">
        <v-layout row wrap justify-start align-center>
          <!-- Personal Name -->
          <v-flex shrink>
            <v-text-field
              data-cy="person"
              v-model="person"
              label="Default Recipient Name"
              hint="Defaults to Company Name if not Included"
              persistent-hint
              color="info"
            >
              <template #prepend>
                <v-icon>fal fa-user</v-icon>
              </template>
            </v-text-field>
          </v-flex>
          <!-- Nickname -->
          <v-flex shrink>
            <v-text-field
              data-cy="nickname"
              v-model="nickName"
              box
              label="Name for Address"
              placeholder="Optional"
              browser-autocomplete="chrome-off"
            />
          </v-flex>
          <!-- Residential Checkbox -->
          <v-flex shrink>
            <v-checkbox
              data-cy="residential"
              v-model="residential"
              label="Residential Address?"
              color="info"
              hint="Optional for US Addresses"
              persistent-hint
            />
          </v-flex>
          <!-- Phone Number -->
          <v-flex shrink>
            <v-text-field
              data-cy="phone"
              v-model="phone"
              label="Location phone number"
              hint="Required to Ship To, can be your own if N/A"
              persistent-hint
            >
              <template #prepend>
                <v-icon>fal fa-phone</v-icon>
              </template>
            </v-text-field>
          </v-flex>
          <v-scale-transition group leave-absolute mode="out-in" tag="v-flex" class="xs12">
            <!-- Google Places Search-->
            <v-flex key="google" v-if="showGoogle">
              <google-places
                :config="{ label: 'Search for an address or company'}"
                :addressConfig="address"
                :success="validated"
                @toggleShow="toggleGoogle"
                @no-results="toggleGoogle"
                @updated="updateAddress"
                @update-success="validated = $event.value"
                @clear="street2 = ''"
              >
                <template #prepend>
                  <v-btn id="toggle-google" small color="deep-purple lighten-3" data-cy="toggle-google" @click="toggleGoogle()" :loading="validating">
                    <!--                  <v-icon color="white">fas fa-keyboard</v-icon>-->
                    Manual
                  </v-btn>
                </template>
              </google-places>
            </v-flex>
            <!-- Manual Address -->
            <v-flex key="manual" v-if="!showGoogle">
              <v-layout row wrap justify-start>
                <!-- Street 1 -->
                <v-flex xs6 lg3 pl-2>
                  <v-text-field
                    data-cy="street1"
                    v-model="street1"
                    box
                    counter="100"
                    label="Street 1"
                    :error-messages="errors.street1"
                    :disabled="validated"
                    browser-autocomplete="chrome-off"
                  />
                </v-flex>
                <!-- Street 2 -->
                <v-flex xs6 lg3 pl-2>
                  <v-text-field
                    data-cy="street2"
                    v-model="street2"
                    box
                    counter="100"
                    label="Street 2"
                    :error-messages="errors.street2"
                    :disabled="validated"
                    browser-autocomplete="chrome-off"
                  />
                </v-flex>
                <!-- City -->
                <v-flex xs6 lg3 pl-2>
                  <v-text-field
                    data-cy="city"
                    v-model="city"
                    box
                    label="City"
                    :error-messages="errors.city"
                    :disabled="validated"
                    browser-autocomplete="chrome-off"
                  />
                </v-flex>
                <!-- State -->
                <v-flex xs6 lg3 pl-2>
                  <v-text-field
                    data-cy="state"
                    v-model="state"
                    box
                    label="State"
                    :error-messages="errors.state"
                    :disabled="validated"
                    browser-autocomplete="chrome-off"
                  />
                </v-flex>
                <!-- Country -->
                <v-flex xs6 lg3 pl-2>
                  <countries
                    data-cy="country"
                    :country="country"
                    :disabled="validated"
                    @updated="country = $event.value"/>
                </v-flex>
                <!-- ZipCode -->
                <v-flex xs6 lg3 pl-2>
                  <v-text-field
                    data-cy="zip"
                    v-model="zip"
                    box
                    label="ZipCode"
                    :disabled="validated"
                    :error-messages="errors.zip"
                    browser-autocomplete="chrome-off"
                  />
                </v-flex>
                <!-- Switch Button -->
                <v-flex xs3>
                  <v-btn
                    color="deep-purple lighten-3"
                    data-cy="toggle-google"
                    id="manual-button"
                    @click="toggleGoogle"
                  >
                    Use Autocomplete
                  </v-btn>
                </v-flex>
                <v-spacer></v-spacer>
                <!-- Validate Button -->
                <v-flex xs8>
                  <v-btn
                    data-cy="easy-post-validate"
                    @click="easyPostValidate"
                    color="primary"
                    outline
                    :loading="validating"
                    :disabled="validating || validated"
                  >
                    <v-icon
                      v-if="validated"
                      color="success"
                    >fal fa-check</v-icon>
                    <span v-else>Validate</span>
                  </v-btn>
                  <v-fade-transition>
                    <v-btn data-cy="easy-post-validate-pencil" @click="validated = false" icon v-if="validated">
                      <v-icon color="info">fal fa-pencil</v-icon>
                    </v-btn>
                  </v-fade-transition>
                </v-flex>
                <v-flex xs12>
                  <span v-if="validated && !strict" data-cy="validate-success" class="primary--text bold">Manually validated.</span>
                  <span v-else-if="validated" data-cy="validate-success" id="validate-text" class="primary--text bold">Validated! You can still click the pencil to edit and validate again.</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-scale-transition>
          <!-- Line 2 -->
          <v-flex xs3 v-if="showGoogle">
            <v-text-field
              data-cy="street2"
              v-model="street2"
              counter="100"
              box
              label="Suite Number / Street 2"
              placeholder="Optional"
              browser-autocomplete="chrome-off"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
    <v-card-actions v-if="!eventOnly">
      <v-layout justify-start align-center>
        <t-alert :message="message" :type="messageType"/>
        <v-spacer></v-spacer>
        <cancel-button class="pr-3" :success="succeeded" @click="$emit('close')"/>
        <submit-button class="pr-1" :disabled="!canSave || succeeded" :loading="saving" @click="save"/>
      </v-layout>
    </v-card-actions>
    <v-dialog
        v-model="dialog"
        lazy
        persistent>
        <v-card>
            <v-card-title class="error lighten-5"><h3>Address Validation</h3></v-card-title>
            <v-card-text>
              <v-layout justify-space-around align-center>
                <v-flex>
                  <span>Even though we cannot validate this address, if you know it is correct you can
                  still create the address and ship to it. We will be notified that an address was created without
                  validation so that we can improve our database</span>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-layout row justify-start align-center>
                <v-spacer></v-spacer>
                <v-btn
                  data-cy="ok"
                  color="primary"
                  @click="setForceCreate"
                >ok</v-btn>
              </v-layout>
            </v-card-actions>
        </v-card>
     </v-dialog>
  </v-card>
</template>

<script>
import places2 from '../autocompletes/places2'
import countries from '../autocompletes/countries'
import mutateAddresses from '@/api/graphql/mutationsJS/mutateAddresses'
import { GET_ADDRESSES__FULL } from '@/api/graphql/Constants/Addresses'
import tAlert from '@/components/notifications/tAlert'
import { GridEvents } from '@/store/gridEvents'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default {
  name: 'newAddress',
  mixins: [mutateAddresses],
  components: {
    'google-places': places2,
    'countries': countries,
    't-alert': tAlert,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  props: {
    owner: {
      type: [Number, String],
      required: true
    },
    ownerName: {
      type: String,
      required: false
    },
    eventOnly: {
      // used when creating a new client or blind stuff
      type: Boolean,
      required: true
    }
  },
  computed: {
    canSave () {
      return this.address.street1.length < 101 && this.address.street2.length < 101
    },

    address () {
      return {
        client: null,
        company: this.ownerName,
        name: this.person || this.ownerName,
        residential: this.residential,
        nickName: this.nickName,
        street1: this.street1,
        street2: this.street2,
        zip: this.zip,
        city: this.city,
        state: this.state,
        country: this.country,
        strict: this.strict
      }
    }
  },
  watch: {
    invalidCount: function (v) {
      if (v === 2) {
        this.dialog = true
      }
    },
    street1: function (val) {
      if (val && !this.showGoogle) {
        const street = val.toLowerCase()
        if ((street.replace(/\.*/g, '').replace(/\s*/g, '').indexOf('pobox') > -1) && val.substring(0, 6) !== 'PO Box') {
          const firstNumber = street.search(/\d/)
          if (firstNumber > -1) {
            this.street1 = 'PO Box' + street.substring(firstNumber, street.length)
          } else {
            this.street1 = 'PO Box'
          }
        } else {
          this.street1 = val
        }
      }
      if (val.endsWith(',')) {
        this.street1 = `${val.slice(0, -1)}`
      }
    },
    street2: function (val) {
      if (val.street2?.endsWith(',')) {
        this.address.street2 = val.street2.slice(0, -1)
      }
    }
  },
  data () {
    return {
      succeeded: false,
      message: '',
      messageType: 'info',
      saving: false,
      invalidCount: 0,
      dialog: false,
      easypostId: '',

      phone: '',
      residential: false,
      business: '',
      person: '',
      nickName: '',
      street1: '',
      street2: '',
      zip: '',
      city: '',
      state: '',
      country: '',
      strict: true,

      errors: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: ''
      },

      showGoogle: true,

      validated: false,
      validating: false,
      error: false
    }
  },
  apollo: {
    clientAddress: {
      query: GET_ADDRESSES__FULL,
      variables () {
        return {
          input: {
            filters: [{ key: 'client__id', value: this.owner }]
          }
        }
      },
      skip () {
        return !this.owner
      },
      update: data => data.address_addresses
    }
  },
  methods: {
    async updateAddress (address) {
      for (const key in address) {
        this[key] = address[key]
      }
      for (const key in this.errors) {
        this.errors[key] = ''
      }
      await this.easyPostValidate()
      this.$emit('updated', address)
    },

    async easyPostValidate () {
      if (this.invalidCount >= 2) this.invalidCount = 0
      this.resetErrors()
      this.validating = true
      const addressObject = this.makeAddressObjects()
      const easyPostVerify = addressObject.addressToVerify

      const verifyName = this.strict ? 'verify_strict' : 'verify'
      easyPostVerify[verifyName] = ['delivery']
      if (this.address.country === 'US') {
        easyPostVerify[verifyName].push('zip4')
      }
      easyPostVerify.residential = this.address.residential

      try {
        const verified = await this.verifyAddress(easyPostVerify)
        const ok = verified?.verifications?.delivery?.success
        if (ok) {
          // this is really only used when being used by newClient so we'll send up the whole object
          this.$emit('validated', { easypost_id: verified.id, ...this.address })
          this.validated = true
          this.easypostId = verified.id
          this.showGoogle && this.toggleGoogle()
        } else {
          this.parseErrors(verified?.verifications?.delivery?.errors)
          this.$emit('no-validate')
        }
        this.validating = false
      } catch (error) {
        this.validating = false
        if (error?.networkError?.result?.errors) {
          this.parseErrors(error?.networkError?.result?.errors)
        } else {
          this.invalidCount++
        }
        this.$emit('no-validate')
      }
    },

    setForceCreate () {
      this.strict = false
      this.validated = true
      this.dialog = false
    },

    resetErrors () {
      for (const key in this.errors) {
        this.errors[key] = ''
      }
    },

    resultHandler ({ message, type }) {
      this.message = message
      this.messageType = type
      this.saving = false
    },

    parseErrors (errors) {
      // reset the errors
      for (const error in this.errors) {
        this.errors[error] = ''
      }
      // hold a value that a key was matched
      let matchedKey = false
      let unFoundKey = false
      // search through the error fields to find a key that matches the errors
      // object
      for (const error of errors) {
        if (['street1', 'street2', 'city', 'state',
          'zip', 'company', 'name'].indexOf(error.field) > -1) {
          this.errors[error.field] = error.message
          matchedKey = true
        } else {
          unFoundKey = true
        }
      }
      // show a generic error if now key is found
      if (unFoundKey) {
        this.invalidCount++
      }
      this.showGoogle = false
      this.invalidCount++
    },

    async save () {
      try {
        this.saving = true
        this.resetErrors()
        const { addressToMake, addressToVerify } = this.makeAddressObjects()
        if (this.easypostId) {
          addressToMake.easypost_id = this.easypostId
        } else {
          const easypostAddress = await this.verifyAddress(addressToVerify)
          addressToMake.easypost_id = easypostAddress.id
        }
        const newAddress = await this.createAddress(addressToMake)
        this.resultHandler({ message: 'Successfully added address.', type: 'success' })
        this.invalidCount = 0
        this.succeeded = true
        this.$emit('success', newAddress)
        if (this.$route.fullPath.includes('address')) {
          GridEvents.$emit('refetch-nested-client')
        }
      } catch (error) {
        if (error.message?.includes('verify') && !this.showGoogle) {
          await this.save()
        } else {
          const message = error.message ? error.message : error
          this.resultHandler({ message: message, type: 'error' })
        }
      }
    },

    makeAddressObjects () {
      const addressToVerify = {
        company: this.ownerName,
        name: this.person,
        city: this.address.city,
        country: this.address.country,
        state: this.address.state,
        street1: this.address.street1,
        street2: this.address.street2,
        zip: this.address.zip,
        phone: this.phone
      }

      const addressToMake = {
        ...(this.address.nickName && { building_name: this.address.nickName }),
        city: this.address.city,
        ...(this.owner > 0 && { client_id: this.owner }), // don't submit client_id if it is for a drop ship address
        ...(this.owner <= 0 && { company_id: 1 }),
        country: this.address.country,
        state: this.address.state,
        street_1: this.address.street1,
        ...(this.address.street2 && { street_2: this.address.street2 }),
        zip_code: this.address.zip
      }

      if (this.address.strict) {
        addressToVerify.verify_strict = ['delivery']
        if (this.address.country.toLowerCase() === 'us') {
          addressToVerify.verify_strict.push('zip4')
          addressToMake.residential = false
        } else {
          addressToMake.residential = this.address.residential
        }
      } else {
        addressToVerify.verify = ['delivery']
        if (this.address.country.toLowerCase() === 'us') {
          addressToVerify.verify.push('zip4')
          addressToMake.residential = false
        } else {
          addressToMake.residential = this.address.residential
          addressToVerify.residential = this.address.residential
        }
      }

      return { addressToMake, addressToVerify }
    },

    toggleGoogle (reset = false) {
      if (reset) {
        for (const key in this.address) {
          if (key !== 'nickName' && key !== 'residential') {
            this[key] = ''
          }
        }
        this.validated = false
      }
      this.showGoogle = !this.showGoogle
      if (!this.showGoogle && this.eventOnly) {
        const text = this.validated ? '#validate-text' : '#manual-button'
        setTimeout(() => {
          const el = document.querySelector(text)
          const scroller = document.querySelector('#form-template')
          const realPosition = el.getBoundingClientRect().top + 100
          scroller.scrollTo({
            top: realPosition
          })
        }, 500)
      }
    }
  }
}
</script>
