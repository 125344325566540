<template>
  <v-container style="background-color: #4d4d4d" fluid fill-height pa-0>
    <div class="login-box centered">
      <v-container>
        <v-layout column justify-space-around align-center fill-height>
          <v-flex xs8>
            <h4 class="white--text">Redirecting you to login...</h4>
          </v-flex>
          <v-flex xs6 class="white--text">
            Not redirecting?
            <v-btn
              @click="redirect"
              color="info"
              flat
            >
              GO
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'Login',
  computed: {
    authenticated () {
      return this.$store.state.auth.authenticated
    }
  },
  data () {
    return {
      image: '',
      logo: null,
      screenHeight: window.innerHeight,
      screenWidth: window.innerWidth
    }
  },
  methods: {
    getBackgroundImage () {
      this.image = require('@/assets/login-photos/background-tech.jpg')
    },
    redirect () {
      setTimeout(() => {
        this.$store.dispatch('auth/keycloakLogin')
      }, 500)
    }
  },
  mounted () {
    this.redirect()
  }
}
</script>
