import gql from 'graphql-tag'
import { DocumentNode } from 'apollo-link'
import { FRAGMENT__MONEY, FRAGMENT__TERMS__LIST } from '@/api/graphql/Constants/TERMS'
import { FRAGMENT__CONTACT__LIST } from '@/api/graphql/Constants/Contacts'

/* FRAGMENTS */

export const FRAGMENT__WORK_ORDER_LIST = gql`
  fragment WorkOrderList on WorkOrder_WorkOrder {
    amount { ...Money }
    contract_comment
    creation_date
    creator {
      id
      initials
      full_name
    }
    id
    internal_comment
    need_by
    purchase_transaction {
      id
      client {
        id
        name
        short_name
      }
    }
    shipment_order { id }
    status {
      id
      status
    }
    terms {
      id
      days
      description
    }
    type {
      id
      choice
      sub_choice
    }
    updated_date
    vendor {
      id
      name
      short_name
    }
    warranty_end_date
  }
  ${FRAGMENT__MONEY}
`

export const FRAGMENT__WORK_ORDER_ITEM = gql`
    fragment WorkOrderItem on WorkOrder_WorkOrderItems {
      id
      item {
        id
        part {
          id
          pn
          description
        }
      }
    }
`

export const FRAGMENT__WORK_ORDER_FOR_PDF: DocumentNode = gql`
  fragment WorkOrderForPdf on WorkOrder_WorkOrder {
    id
    amount { ...Money }
    contract_comment
    creation_date
    creator { id initials }
    need_by
    purchase_transaction { id rep { id initials full_name email } }
    shipment_order {
      id
      blind
      purchaser_contact { ...Contact_List }
      purchaser_address {
        id
        street_1
        street_2
        city
        state
        country {
          code
          name
        }
        zip_code
        company {
          name
        }
        client {
          id
          name
        }
        default_contact {
          id
          full_name
        }
      }
      ship_from_contact { ...Contact_List }
      ship_from_address {
        id
        street_1
        street_2
        city
        state
        country {
          code
          name
        }
        zip_code
        company {
          name
        }
        client {
          id
          name
        }
        default_contact {
          id
          full_name
        }
      }
      ship_to_contact { ...Contact_List }
      ship_to_address {
        id
        street_1
        street_2
        city
        state
        country {
          code
          name
        }
        zip_code
        client {
          id
          name
        }
        company {
          name
        }
        default_contact {
          id
          full_name
        }
      }
    }
    terms { ...Terms_List }
    vendor { id name }
    wo_items {
      ...WorkOrderItem
    }
  }
  ${FRAGMENT__MONEY}
  ${FRAGMENT__CONTACT__LIST}
  ${FRAGMENT__TERMS__LIST}
  ${FRAGMENT__WORK_ORDER_ITEM}
`

/* HELPERS */

/* QUERIES */

export const GRID_GET_WORK_ORDERS: DocumentNode = gql`
    query GridGetWorkOrders ($input: ManyQueryInput!) {
      work_orders: work_order_work_orders (input: $input) {
        ...WorkOrderList
        annotations {
          name
          value
        }
      }
    }
  ${FRAGMENT__WORK_ORDER_LIST}
`

export const GRID_GET_WORK_ORDER_ITEMS: DocumentNode = gql`
    query GridGetWorkOrderItems ($input: ManyQueryInput!) {
      items: work_order_work_order_items (input: $input) {
        ...WorkOrderItem
        annotations {
          name
          value
        }
      }
    }
  ${FRAGMENT__WORK_ORDER_ITEM}
`

export const GRID_GET_WORK_ORDER_FOR_PDF: DocumentNode = gql`
  query GetWorkOrderForPDF ($id: ID!) {
    work_order_work_order(input: {id: $id}) {
      ...WorkOrderForPdf
    }
  }
    ${FRAGMENT__WORK_ORDER_FOR_PDF}
`

/* MUTATIONS */

export const CREATE_WORK_ORDER: DocumentNode = gql`
    mutation CreateWorkOrder ($input: Create__WorkOrder_WorkOrder__Input!) {
      order: Create__WorkOrder_WorkOrder (input: $input) {
        ...WorkOrderList
      }
    }
  ${FRAGMENT__WORK_ORDER_LIST}
`

export const CREATE_WORK_ORDER_ITEM: DocumentNode = gql`
    mutation CreateWorkOrderItem ($input: [Create__WorkOrder_WorkOrderItems__Input!]!) {
      Create__WorkOrder_WorkOrderItems (input: $input) {
        ...WorkOrderItem
      }
    }
  ${FRAGMENT__WORK_ORDER_ITEM}
`

export const DELETE_WORK_ORDER_ITEM: DocumentNode = gql`
  mutation DeleteWorkOrderItem ($id: ID!) {
    Delete__WorkOrder_WorkOrderItems (id: $id) { id }
  }
`
