/**
 * Groups like items in an array by the given keys and adds to quantity
 * @param {Array<Object>} items
 * @param {Array<String>} keys
 */
export const groupLikeItemsWithGivenKeys = (items, keys) => {
  const grouped = []

  // helper function
  const existsInGrouped = item => {
    return grouped.findIndex(g => {
      let isSame = true
      for (const key of keys) {
        isSame = isSame && g[key] === item[key]
      }
      return isSame
    })
  }

  for (const item of items) {
    if (item !== undefined) {
      const index = existsInGrouped(item)
      if (index > -1) {
        grouped[index].quantity++
      } else {
        const newItem = Object.assign({}, item)
        grouped.push({ ...newItem, rowId: grouped.length + 1 })
      }
    }
  }
  return grouped
}
