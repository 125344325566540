import gql from 'graphql-tag'
import { apolloClient as apollo } from '@/api/graphql/apollo'
import {
  Create__Reporting_Report__Input,
  Mutation,
  MutationCreate__Reporting_ReportArgs,
  MutationDelete__Reporting_ReportArgs,
  MutationUpdate__Reporting_ReportArgs,
  Reporting_Report,
  Update__Reporting_Report__Input
} from '@/models/generated/graphql/ErpBackend'

/* HELPERS */

export async function CreateReport (input: Create__Reporting_Report__Input): Promise<Reporting_Report> {
  try {
    const response = await apollo.mutate<Mutation, MutationCreate__Reporting_ReportArgs>({
      mutation: CREATE_REPORT,
      variables: {
        input: input
      }
    })
    return response.data!.Create__Reporting_Report!
  } catch (e) {
    throw new Error(e)
  }
}

export async function UpdateReport (input: Update__Reporting_Report__Input): Promise<Reporting_Report> {
  try {
    const response = await apollo.mutate<Mutation, MutationUpdate__Reporting_ReportArgs>({
      mutation: UPDATE_REPORT,
      variables: {
        input: input
      }
    })
    return response.data!.Update__Reporting_Report!
  } catch (e) {
    throw new Error(e)
  }
}

export async function DeleteReport (id: string): Promise<void> {
  try {
    await apollo.mutate<Mutation, MutationDelete__Reporting_ReportArgs>({
      mutation: DELETE_REPORT,
      variables: {
        id: id
      }
    })
  } catch (e) {
    throw new Error(e)
  }
}

/* FRAGMENTS */

export const FRAGMENT__REPORT__FULL = gql`
  fragment Reporting_Report__Full on Reporting_Report {
    id
    name
    description
    query
    chart_type
    data
    recipients { id email full_name initials }
  }
`

/* QUERIES */

export const GET_REPORTS__FULL = gql`
  query GetReports_Full ($input: ManyQueryInput!) {
    reporting_reports (input: $input) {
      ...Reporting_Report__Full
    }
  }
  ${FRAGMENT__REPORT__FULL}
`

/* MUTATIONS */

export const CREATE_REPORT = gql`
  mutation CreateReport ($input: Create__Reporting_Report__Input!) {
    Create__Reporting_Report (input: $input) {
      ...Reporting_Report__Full
    }
  }
  ${FRAGMENT__REPORT__FULL}
`

export const UPDATE_REPORT = gql`
  mutation UpdateReport ($input: Update__Reporting_Report__Input!) {
    Update__Reporting_Report (input: $input) {
      ...Reporting_Report__Full
    }
  }
  ${FRAGMENT__REPORT__FULL}
`

export const DELETE_REPORT = gql`
  mutation DeleteReport ($id: ID!) {
    Delete__Reporting_Report (id: $id) {
      id
    }
  }
`
