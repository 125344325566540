<template>
    <v-card>
      <v-card-title>
        Select an RMA to add these parts to.
      </v-card-title>
      <v-card-text>
        <v-container grid-list-xl>
          <v-layout row justify-center align-center>
            <v-flex xs4>
              <standard-auto-fill
                :id="id"
                :items="rmas"
                :config="rmaListConfig"
                @updated="id = $event.value"
              ></standard-auto-fill>
            </v-flex>
            <v-flex xs4>
              <v-checkbox
                v-model="credit"
                label="Return for credit?"
              ></v-checkbox>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-layout justify-start align-center nowrap row>
          <t-alert :message="message" :type="messageType"/>
          <v-spacer/>
          <cancel-button :success="succeeded" @click="cancel"/>
          <submit-button :loading="saving" @click="save"/>
        </v-layout>
      </v-card-actions>
    </v-card>
</template>

<script>
import standard2 from '../autocompletes/standard2'
import { GRID_GET_SALE_RMAS as SRMA, GRID_GET_PURCHASE_RMAS as PRMA } from '@/api/graphql/Constants/RMAs'
import mutateRMAs from '@/api/graphql/mutationsJS/mutateRMAs'
import { CREATE_PURCHASE_RMA_ITEM, CREATE_SALE_RMA_ITEM } from '@/api/graphql/Constants/RMAs'
import tAlert from '@/components/notifications/tAlert'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default {
  name: 'addToRMA',
  mixins: [mutateRMAs],
  components: {
    'standard-auto-fill': standard2,
    't-alert': tAlert,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  computed: {
    type () {
      return this.$store.state.rma.type
    },

    clientId () {
      return this.$store.state.rma.clientId
    },

    parts () {
      return this.$store.state.rma.items
    },

    succeeded () {
      return this.messageType === 'success'
    }
  },
  data () {
    return {
      id: 0,
      saving: false,
      messageType: 'info',
      message: '',
      rmas: [],
      credit: false,
      rmaListConfig: {
        label: 'Select an RMA',
        name: 'RMA Selection'
      }
    }
  },
  methods: {
    /**
     * Saves the parts to the RMA
     */
    async save () {
      if (this.id) {
        const newRmaItems = []
        for (const part of this.parts) {
          newRmaItems.push({
            rma_id: this.id,
            credit: this.credit,
            returned_part_id: part.itemId
          })
        }
        this.saving = true
        this.$apollo.mutate({
          mutation: this.type === 'sale' ? CREATE_SALE_RMA_ITEM : CREATE_PURCHASE_RMA_ITEM,
          variables: { items: newRmaItems }
        }).then(() => {
          this.message = 'Added items to the RMA'
          this.messageType = 'success'
        }).catch(() => {
          this.message = 'Could not add items to RMA'
          this.messageType = 'error'
        }).finally(() => {
          this.saving = false
        })
      } else {
        this.message = 'Select an RMA to continue'
        this.messageType = 'info'
      }
    },

    /**
     * Cancels the action
     */
    cancel () {
      this.$emit('cancel')
      this.$store.dispatch('grid/resetDialog')
      this.$store.commit('rma/resetDetails')
    },

    filter (item, queryText) {
      const toSearchFor = item.title
      const toSearchWith = queryText.toLowerCase()

      return toSearchFor.indexOf(toSearchWith) > -1
    }
  },
  async beforeMount () {
    const order = this.type === 'sale' ? 'st' : 'pt'
    const response = await this.$apollo.query({
      query: this.type === 'purchase' ? PRMA : SRMA,
      variables: { input: { limit: 100 } }
    })
    this.rmas = response.data[(this.type === 'purchase' ? 'purchase_rmas' : 'rmas')].filter(rma => {
      return rma[order].client.id === this.clientId
    }).map(rma => ({
      title: `${rma.id}: ${rma[order].client.name}`,
      id: rma.id
    }))
  }
}
</script>

<style scoped>

</style>
