const moneyFormatter = {
  methods: {
    format (money) {
      return (Number(money)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    },

    renderMoney (params) {
      if (params.value) {
        return this.format(params.value)
      }
    }
  }
}

export default moneyFormatter
