import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Price: any;
  Any: any;
  EasyPostID: any;
  Percent: any;
  Rating: any;
  Email: any;
  PositiveInteger: any;
  CustomTime: any;
  EmailAddress: any;
  DateTime: any;
  NaiveDateTime: any;
  Duration: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  PositiveFloat: any;
  PositiveInt: any;
  Long: any;
  BigInt: any;
  UnsignedInt: any;
  PhoneNumber: any;
  PostalCode: any;
  URL: any;
  GUID: any;
  HexColorCode: any;
  HSL: any;
  HSLA: any;
  RGB: any;
  RGBA: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  MAC: any;
  Port: any;
  USCurrency: any;
  JSON: any;
  JSONObject: any;
  GeoJSON: any;
  /** The `Date` scalar type represents a date object */
  Date: any;
  /** The `Time` scalar type represents a time object */
  Time: any;
};

/** This is an interface for Inventory_Item. */
export type Inventory_Item__Interface = {
  /** The sub-Items for this Item. (e.g., the parts of a system chassis.) */
  add_on_items?: Maybe<Array<Inventory_Item>>;
  /** The date the Item was allocated. */
  allocated_date?: Maybe<Scalars['DateTime']>;
  /** An alternate serial number for the Item. */
  alternate_serial_number?: Maybe<Scalars['String']>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** The associated part number of the Item. */
  associated_pn?: Maybe<Parts_AssociatedPn>;
  /** The last date the Item was audited. */
  audit_date?: Maybe<Scalars['DateTime']>;
  broke_down_items?: Maybe<Breakdown_BreakDownItems>;
  build_up_items?: Maybe<BuildUp_BuildUpItems>;
  /** Who currently has the Item. */
  checked_out_by?: Maybe<Users_User>;
  /** The sale condition of the Item. New, refurb, etc.. */
  condition?: Maybe<Choices_Choice>;
  /** More information relating to the Item's condition. */
  condition_comment: Scalars['String'];
  /** The origin country of this Item. */
  country_of_origin?: Maybe<CountryObject>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  /** The firmware version currently applied. */
  fw: Scalars['String'];
  /** What physical condition the Item is in. */
  grade?: Maybe<Choices_Choice>;
  hot: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Whether this item is for internal use or not. */
  internal: Scalars['Boolean'];
  /** General inventory comments. */
  inventory_comment: Scalars['String'];
  /** Whether the Item is lost or not. */
  is_lost: Scalars['Boolean'];
  /** The date of the last write down for this Item. */
  last_write_down_date?: Maybe<Scalars['DateTime']>;
  /** The location where the part is. */
  location?: Maybe<Location_Location>;
  /** The reason for locking the Item. */
  lock_reason: Scalars['String'];
  /** If locked, the Item can't be sold. */
  locked: Scalars['Boolean'];
  /** The date of the next write down for this Item. */
  next_write_down_date?: Maybe<Scalars['DateTime']>;
  /** What kind of part the Item is. */
  part?: Maybe<Parts_Part>;
  purchase_rma_item_details?: Maybe<Rma_PurchaseRmaItems>;
  purchases_items_details?: Maybe<Purchases_PurchasedItems>;
  /** How many of these parts are in the Item. */
  quantity: Scalars['PositiveInteger'];
  /** If the Item is Received(True), Booked(Null), or Unreceived(False). */
  receive_status?: Maybe<Scalars['Boolean']>;
  /** Who received the Item. */
  received_by?: Maybe<Users_User>;
  /** The date the Item was received. */
  received_date?: Maybe<Scalars['DateTime']>;
  rma_item_details?: Maybe<Rma_RmaItems>;
  sales_items_details?: Maybe<Sales_SoldItems>;
  /** The serial number of the Item. */
  serial_number?: Maybe<Scalars['String']>;
  shipment?: Maybe<Shipping_ShipmentSupplies>;
  /** The current status of the Item within a process. */
  status?: Maybe<Choices_Choice>;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** What wipes/tests were run or if it is failed. */
  test_status?: Maybe<Array<Choices_Tag>>;
  /** The date the Item was unbooked. */
  unbooked_date?: Maybe<Scalars['DateTime']>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
  workorder_items_details?: Maybe<WorkOrder_WorkOrderItems>;
};

/** This is an interface for Parts_Part. */
export type Parts_Part__Interface = {
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  description: Scalars['String'];
  ean: Scalars['String'];
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This is an interface for Parts_Part. */
export type Parts_Part__InterfaceAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is an interface for Parts_Part. */
export type Parts_Part__InterfaceAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is an interface for Parts_Part. */
export type Parts_Part__InterfaceAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is an interface for Parts_Part. */
export type Parts_Part__InterfaceAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is an interface for Parts_Part. */
export type Parts_Part__InterfaceBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is an interface for Parts_Part. */
export type Parts_Part__InterfaceBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is an interface for Parts_Part. */
export type Parts_Part__InterfaceInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is an interface for Parts_Part. */
export type Parts_Part__InterfaceInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is an interface for Parts_Part. */
export type Parts_Part__InterfacePart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is an interface for Parts_Part. */
export type Parts_Part__InterfacePart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is an interface for Parts_Part. */
export type Parts_Part__InterfacePurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is an interface for Parts_Part. */
export type Parts_Part__InterfaceQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is an interface for Parts_Part. */
export type Parts_Part__InterfaceSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is an interface for Parts_Part. */
export type Parts_Part__InterfaceSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

export type PrintNodeObject = {
  req_limit?: Maybe<Scalars['Int']>;
  req_offset?: Maybe<Scalars['Int']>;
  req_returned?: Maybe<Scalars['Int']>;
  req_total?: Maybe<Scalars['Int']>;
};

/** Represents a ledger account. */
export type Accounting_Account = {
  __typename?: 'Accounting_Account';
  account_number: Scalars['String'];
  account_total?: Maybe<Money>;
  account_type: Scalars['String'];
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  description: Scalars['String'];
  entries?: Maybe<Array<Accounting_Entry>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  parent_account?: Maybe<Accounting_Account>;
  sub_accounts?: Maybe<Array<Accounting_Account>>;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  total_credit?: Maybe<Money>;
  total_debit?: Maybe<Money>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};


/** Represents a ledger account. */
export type Accounting_AccountEntriesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** Represents a ledger account. */
export type Accounting_AccountSub_AccountsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This represents an Entry such as one you would enter into a journal. */
export type Accounting_Entry = {
  __typename?: 'Accounting_Entry';
  account: Accounting_Account;
  amount?: Maybe<Money>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  entry_type: Scalars['String'];
  id: Scalars['ID'];
  note: Scalars['String'];
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  transaction: Accounting_Transaction;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

/** This is a discount table for Services. */
export type Accounting_ServiceDiscount = {
  __typename?: 'Accounting_ServiceDiscount';
  active_maximum?: Maybe<Money>;
  active_minimum?: Maybe<Money>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  discount_amount?: Maybe<Money>;
  discount_percent?: Maybe<Scalars['Percent']>;
  edit_lock: Scalars['Boolean'];
  id: Scalars['ID'];
  period: Scalars['Duration'];
  service: Inventory_Service;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

/** This is a discount table for Software. */
export type Accounting_SoftwareDiscount = {
  __typename?: 'Accounting_SoftwareDiscount';
  active_maximum?: Maybe<Money>;
  active_minimum?: Maybe<Money>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  discount_amount?: Maybe<Money>;
  discount_percent?: Maybe<Scalars['Percent']>;
  edit_lock: Scalars['Boolean'];
  id: Scalars['ID'];
  period: Scalars['Duration'];
  software: Inventory_Software;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

/**
 * Represents a transaction.
 * This is more to conveniently allow compound entries.
 * Ex: Lunch with X, Purchased 500 hard drives...
 */
export type Accounting_Transaction = {
  __typename?: 'Accounting_Transaction';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  entries?: Maybe<Array<Accounting_Entry>>;
  id: Scalars['ID'];
  internal_reference: Scalars['String'];
  is_balanced?: Maybe<Scalars['Boolean']>;
  is_compound: Scalars['Boolean'];
  reference: Scalars['String'];
  /** The source of the Transaction. */
  source: Scalars['String'];
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
  /** If the Transaction has been reviewed or not. */
  was_reviewed: Scalars['Boolean'];
};


/**
 * Represents a transaction.
 * This is more to conveniently allow compound entries.
 * Ex: Lunch with X, Purchased 500 hard drives...
 */
export type Accounting_TransactionEntriesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model stores a complete address. */
export type Address_Address = {
  __typename?: 'Address_Address';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  attached_site?: Maybe<Location_Site>;
  building_name: Scalars['String'];
  carrier_facility: Scalars['String'];
  city: Scalars['String'];
  client?: Maybe<Clients_Client>;
  company?: Maybe<Company_Company>;
  country: CountryObject;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  default_billing_for_client?: Maybe<Clients_Client>;
  default_billing_for_company?: Maybe<Company_Company>;
  default_contact?: Maybe<Clients_Contact>;
  default_remit_for_client?: Maybe<Clients_Client>;
  default_remit_for_company?: Maybe<Company_Company>;
  default_return_to_for_client?: Maybe<Clients_Client>;
  default_return_to_for_company?: Maybe<Company_Company>;
  default_ship_from_for_client?: Maybe<Clients_Client>;
  default_ship_from_for_company?: Maybe<Company_Company>;
  default_ship_to_for_client?: Maybe<Clients_Client>;
  default_ship_to_for_company?: Maybe<Company_Company>;
  easypost_id: Scalars['String'];
  id: Scalars['ID'];
  quote_from_address?: Maybe<Array<Quotes_Quote>>;
  quote_purchaser_address?: Maybe<Array<Quotes_Quote>>;
  quote_return_address?: Maybe<Array<Quotes_Quote>>;
  quote_to_address?: Maybe<Array<Quotes_Quote>>;
  residential: Scalars['Boolean'];
  shipmentorder_from_address?: Maybe<Array<Shipping_ShipmentOrder>>;
  shipmentorder_purchaser_address?: Maybe<Array<Shipping_ShipmentOrder>>;
  shipmentorder_return_address?: Maybe<Array<Shipping_ShipmentOrder>>;
  shipmentorder_to_address?: Maybe<Array<Shipping_ShipmentOrder>>;
  state: Scalars['String'];
  street_1: Scalars['String'];
  street_2: Scalars['String'];
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
  zip_code: Scalars['String'];
};


/** This model stores a complete address. */
export type Address_AddressQuote_From_AddressArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores a complete address. */
export type Address_AddressQuote_Purchaser_AddressArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores a complete address. */
export type Address_AddressQuote_Return_AddressArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores a complete address. */
export type Address_AddressQuote_To_AddressArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores a complete address. */
export type Address_AddressShipmentorder_From_AddressArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores a complete address. */
export type Address_AddressShipmentorder_Purchaser_AddressArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores a complete address. */
export type Address_AddressShipmentorder_Return_AddressArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores a complete address. */
export type Address_AddressShipmentorder_To_AddressArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

export type AnnotationObject = {
  __typename?: 'AnnotationObject';
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

/** This is to keep track of systems to break down. */
export type Breakdown_BreakDown = {
  __typename?: 'Breakdown_BreakDown';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  assigned_to?: Maybe<Users_User>;
  bd_items?: Maybe<Array<Breakdown_BreakDownItems>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  id: Scalars['ID'];
  status: Choices_Status;
  system: Inventory_System;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};


/** This is to keep track of systems to break down. */
export type Breakdown_BreakDownBd_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This links Inventory.Items to Breakdowns. */
export type Breakdown_BreakDownItems = {
  __typename?: 'Breakdown_BreakDownItems';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  break_down: Breakdown_BreakDown;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  id: Scalars['ID'];
  item: Inventory_Item;
  status: Choices_Status;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

/** This keeps track of systems to build up. */
export type BuildUp_BuildUp = {
  __typename?: 'BuildUp_BuildUp';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  assigned_to?: Maybe<Users_User>;
  bu_items?: Maybe<Array<BuildUp_BuildUpItems>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  id: Scalars['ID'];
  status: Choices_Status;
  system: Inventory_System;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};


/** This keeps track of systems to build up. */
export type BuildUp_BuildUpBu_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This links items to systems for build ups. */
export type BuildUp_BuildUpItems = {
  __typename?: 'BuildUp_BuildUpItems';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  build_up: BuildUp_BuildUp;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  id: Scalars['ID'];
  item: Inventory_Item;
  status: Choices_Status;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

/** This model stores choices for any fields that have multiple string options. */
export type Choices_Choice = {
  __typename?: 'Choices_Choice';
  HZ?: Maybe<Array<Parts_Cpu>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  app_name: Scalars['String'];
  attached_clients?: Maybe<Array<Clients_Client>>;
  attached_items_condition?: Maybe<Array<Inventory_Item>>;
  attached_items_grade?: Maybe<Array<Inventory_Item>>;
  attached_items_status?: Maybe<Array<Inventory_Item>>;
  attached_locations_type?: Maybe<Array<Location_Location>>;
  attached_purchases_condition?: Maybe<Array<Purchases_Purchase>>;
  attached_sales_condition?: Maybe<Array<Sales_Sale>>;
  attached_work_order_type?: Maybe<Array<WorkOrder_WorkOrder>>;
  cable_subtype?: Maybe<Array<Parts_Cable>>;
  capacity_unit?: Maybe<Array<Parts_Memory>>;
  capacityunit?: Maybe<Array<Parts_Storage>>;
  card_subtype?: Maybe<Array<Parts_ExpansionCard>>;
  choice: Scalars['String'];
  dimm_type?: Maybe<Array<Parts_Memory>>;
  disk_subtype?: Maybe<Array<Parts_DiskDrive>>;
  efficiency?: Maybe<Array<Parts_PowerSupply>>;
  enterprise_wifi_security?: Maybe<Array<Parts_Router>>;
  field_name: Scalars['String'];
  form_factor?: Maybe<Array<Parts_Server>>;
  id: Scalars['ID'];
  interface?: Maybe<Array<Parts_Storage>>;
  interface_gpu?: Maybe<Array<Parts_Gpu>>;
  /** If this is a user made Choice object. Defaults to False. */
  is_external: Scalars['Boolean'];
  market?: Maybe<Array<Parts_Part>>;
  memory_speed?: Maybe<Array<Parts_Memory>>;
  memory_type?: Maybe<Array<Parts_Motherboard>>;
  model_name: Scalars['String'];
  personal_wifi_security?: Maybe<Array<Parts_Router>>;
  physicalsize?: Maybe<Array<Parts_Storage>>;
  pins?: Maybe<Array<Parts_Memory>>;
  poe_type_ap?: Maybe<Array<Parts_AccessPoint>>;
  purchase_rma_items_tested_condition?: Maybe<Array<Rma_PurchaseRmaItems>>;
  rack_height?: Maybe<Array<Parts_Server>>;
  rma_items_tested_condition?: Maybe<Array<Rma_RmaItems>>;
  rma_return_code?: Maybe<Array<Rma_Rma>>;
  security?: Maybe<Array<Parts_AccessPoint>>;
  speed?: Maybe<Array<Parts_StorageEnclosure>>;
  spindlespeed?: Maybe<Array<Parts_Storage>>;
  storage_interface?: Maybe<Array<Parts_StorageEnclosure>>;
  storage_speed?: Maybe<Array<Parts_Storage>>;
  sub_choice: Scalars['String'];
  subtype?: Maybe<Array<Parts_Storage>>;
  supplies?: Maybe<Array<Parts_Supplies>>;
  switchtype?: Maybe<Array<Parts_Switch>>;
  throughput_rt?: Maybe<Array<Parts_Router>>;
  wifi_standards_ap?: Maybe<Array<Parts_AccessPoint>>;
  wifi_standards_ph?: Maybe<Array<Parts_Phone>>;
  wifi_standards_rt?: Maybe<Array<Parts_Router>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceHzArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceAttached_ClientsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceAttached_Items_ConditionArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceAttached_Items_GradeArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceAttached_Items_StatusArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceAttached_Locations_TypeArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceAttached_Purchases_ConditionArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceAttached_Sales_ConditionArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceAttached_Work_Order_TypeArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceCable_SubtypeArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceCapacity_UnitArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceCapacityunitArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceCard_SubtypeArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceDimm_TypeArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceDisk_SubtypeArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceEfficiencyArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceEnterprise_Wifi_SecurityArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceForm_FactorArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceInterfaceArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceInterface_GpuArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceMarketArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceMemory_SpeedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceMemory_TypeArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoicePersonal_Wifi_SecurityArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoicePhysicalsizeArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoicePinsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoicePoe_Type_ApArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoicePurchase_Rma_Items_Tested_ConditionArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceRack_HeightArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceRma_Items_Tested_ConditionArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceRma_Return_CodeArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceSecurityArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceSpeedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceSpindlespeedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceStorage_InterfaceArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceStorage_SpeedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceSubtypeArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceSuppliesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceSwitchtypeArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceThroughput_RtArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceWifi_Standards_ApArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceWifi_Standards_PhArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores choices for any fields that have multiple string options. */
export type Choices_ChoiceWifi_Standards_RtArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/**
 * This model represents statuses in the program.
 * Allowing records to have statuses and have linear progressions for statuses.
 */
export type Choices_Status = {
  __typename?: 'Choices_Status';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  app_name: Scalars['String'];
  attached_invoices_apinvoice?: Maybe<Array<Invoices_ApInvoice>>;
  attached_invoices_arinvoice?: Maybe<Array<Invoices_ArInvoice>>;
  attached_purchase_rma_status?: Maybe<Array<Rma_PurchaseRma>>;
  attached_purchase_status?: Maybe<Array<Purchases_Purchase>>;
  attached_rma_status?: Maybe<Array<Rma_Rma>>;
  attached_sale_status?: Maybe<Array<Sales_Sale>>;
  attached_work_order_status?: Maybe<Array<WorkOrder_WorkOrder>>;
  break_down_items_status?: Maybe<Array<Breakdown_BreakDownItems>>;
  break_down_status?: Maybe<Array<Breakdown_BreakDown>>;
  build_up_items_status?: Maybe<Array<BuildUp_BuildUpItems>>;
  build_up_status?: Maybe<Array<BuildUp_BuildUp>>;
  field_name: Scalars['String'];
  id: Scalars['ID'];
  /** If this is a user made Choice object. Defaults to False. */
  is_external: Scalars['Boolean'];
  model_name: Scalars['String'];
  order: Scalars['PositiveInteger'];
  sales_items_status?: Maybe<Array<Sales_SoldItems>>;
  shipment_order?: Maybe<Array<Shipping_ShipmentOrder>>;
  status: Scalars['String'];
  status_type: Scalars['String'];
};


/**
 * This model represents statuses in the program.
 * Allowing records to have statuses and have linear progressions for statuses.
 */
export type Choices_StatusAttached_Invoices_ApinvoiceArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model represents statuses in the program.
 * Allowing records to have statuses and have linear progressions for statuses.
 */
export type Choices_StatusAttached_Invoices_ArinvoiceArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model represents statuses in the program.
 * Allowing records to have statuses and have linear progressions for statuses.
 */
export type Choices_StatusAttached_Purchase_Rma_StatusArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model represents statuses in the program.
 * Allowing records to have statuses and have linear progressions for statuses.
 */
export type Choices_StatusAttached_Purchase_StatusArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model represents statuses in the program.
 * Allowing records to have statuses and have linear progressions for statuses.
 */
export type Choices_StatusAttached_Rma_StatusArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model represents statuses in the program.
 * Allowing records to have statuses and have linear progressions for statuses.
 */
export type Choices_StatusAttached_Sale_StatusArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model represents statuses in the program.
 * Allowing records to have statuses and have linear progressions for statuses.
 */
export type Choices_StatusAttached_Work_Order_StatusArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model represents statuses in the program.
 * Allowing records to have statuses and have linear progressions for statuses.
 */
export type Choices_StatusBreak_Down_Items_StatusArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model represents statuses in the program.
 * Allowing records to have statuses and have linear progressions for statuses.
 */
export type Choices_StatusBreak_Down_StatusArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model represents statuses in the program.
 * Allowing records to have statuses and have linear progressions for statuses.
 */
export type Choices_StatusBuild_Up_Items_StatusArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model represents statuses in the program.
 * Allowing records to have statuses and have linear progressions for statuses.
 */
export type Choices_StatusBuild_Up_StatusArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model represents statuses in the program.
 * Allowing records to have statuses and have linear progressions for statuses.
 */
export type Choices_StatusSales_Items_StatusArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model represents statuses in the program.
 * Allowing records to have statuses and have linear progressions for statuses.
 */
export type Choices_StatusShipment_OrderArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model stores choices for any fields that have multiple string options. */
export type Choices_Tag = {
  __typename?: 'Choices_Tag';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  app_name: Scalars['String'];
  attached_items_test_status?: Maybe<Array<Inventory_Item>>;
  color: Scalars['String'];
  description: Scalars['String'];
  field_name: Scalars['String'];
  id: Scalars['ID'];
  /** If this is a user made Choice object. Defaults to False. */
  is_external: Scalars['Boolean'];
  model_name: Scalars['String'];
  tag: Scalars['String'];
};

/**
 * This model stores any specific Client information.
 * A Client is someone with whom you conduct business.
 * EX: You sell to/buy from a Client, not a Contact.
 */
export type Clients_Client = {
  __typename?: 'Clients_Client';
  /** Whether the client can accept gifts or not. */
  accepts_gifts: Scalars['Boolean'];
  /** If the client can be sold to. */
  allow_sales: Scalars['Boolean'];
  /** A manual override for allowing sales to a company. */
  allow_sales_override?: Maybe<Scalars['Boolean']>;
  alt_parts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  attached_address?: Maybe<Array<Address_Address>>;
  attached_contacts?: Maybe<Array<Clients_Contact>>;
  attached_purchase?: Maybe<Array<Purchases_Purchase>>;
  attached_sale?: Maybe<Array<Sales_Sale>>;
  attached_subs?: Maybe<Array<Clients_Subs>>;
  client?: Maybe<Array<Clients_Client>>;
  client_of?: Maybe<Clients_Client>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  credit?: Maybe<Money>;
  credit_limit?: Maybe<Money>;
  default_billing_address?: Maybe<Address_Address>;
  default_billing_contact?: Maybe<Clients_Contact>;
  /** The default terms when purchasing from this client. */
  default_pt_terms?: Maybe<Clients_Terms>;
  default_remit_address?: Maybe<Address_Address>;
  default_remit_contact?: Maybe<Clients_Contact>;
  default_return_to_address?: Maybe<Address_Address>;
  default_ship_from_address?: Maybe<Address_Address>;
  default_ship_to_address?: Maybe<Address_Address>;
  default_ship_to_contact?: Maybe<Clients_Contact>;
  default_shipping_account?: Maybe<Shipping_Account>;
  default_shipping_service?: Maybe<Shipping_ServiceLevel>;
  /** The default terms when selling to this client. */
  default_st_terms?: Maybe<Clients_Terms>;
  default_tracking_email_recipients?: Maybe<Array<Scalars['Email']>>;
  /** What eCommerce store the Client is from. */
  ecommerce_store: Scalars['String'];
  federal_tax_id: Scalars['String'];
  /** If the client has past due invoices. */
  has_past_due_invoices: Scalars['Boolean'];
  id: Scalars['ID'];
  internal_rep?: Maybe<Users_User>;
  is_1099: Scalars['Boolean'];
  is_our_client: Scalars['Boolean'];
  is_tax_exempt: Scalars['Boolean'];
  name: Scalars['String'];
  notes: Scalars['String'];
  origin: Scalars['String'];
  outstanding_invoice_limit?: Maybe<Money>;
  owned_terms?: Maybe<Array<Clients_Terms>>;
  payment: Scalars['String'];
  quotes?: Maybe<Array<Quotes_Quote>>;
  rating: Scalars['Rating'];
  shipping_accounts?: Maybe<Array<Shipping_Account>>;
  shipping_instructions: Scalars['String'];
  shipping_labels?: Maybe<Array<Clients_ShippingLabel>>;
  short_name: Scalars['String'];
  state_tax_id: Scalars['String'];
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  tax_exempt_number: Scalars['String'];
  /** What terms this client uses. */
  terms?: Maybe<Array<Clients_Terms>>;
  type?: Maybe<Choices_Choice>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
  website: Scalars['String'];
  work_orders?: Maybe<Array<WorkOrder_WorkOrder>>;
};


/**
 * This model stores any specific Client information.
 * A Client is someone with whom you conduct business.
 * EX: You sell to/buy from a Client, not a Contact.
 */
export type Clients_ClientAlt_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model stores any specific Client information.
 * A Client is someone with whom you conduct business.
 * EX: You sell to/buy from a Client, not a Contact.
 */
export type Clients_ClientAttached_AddressArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model stores any specific Client information.
 * A Client is someone with whom you conduct business.
 * EX: You sell to/buy from a Client, not a Contact.
 */
export type Clients_ClientAttached_ContactsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model stores any specific Client information.
 * A Client is someone with whom you conduct business.
 * EX: You sell to/buy from a Client, not a Contact.
 */
export type Clients_ClientAttached_PurchaseArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model stores any specific Client information.
 * A Client is someone with whom you conduct business.
 * EX: You sell to/buy from a Client, not a Contact.
 */
export type Clients_ClientAttached_SaleArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model stores any specific Client information.
 * A Client is someone with whom you conduct business.
 * EX: You sell to/buy from a Client, not a Contact.
 */
export type Clients_ClientAttached_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model stores any specific Client information.
 * A Client is someone with whom you conduct business.
 * EX: You sell to/buy from a Client, not a Contact.
 */
export type Clients_ClientClientArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model stores any specific Client information.
 * A Client is someone with whom you conduct business.
 * EX: You sell to/buy from a Client, not a Contact.
 */
export type Clients_ClientOwned_TermsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model stores any specific Client information.
 * A Client is someone with whom you conduct business.
 * EX: You sell to/buy from a Client, not a Contact.
 */
export type Clients_ClientQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model stores any specific Client information.
 * A Client is someone with whom you conduct business.
 * EX: You sell to/buy from a Client, not a Contact.
 */
export type Clients_ClientShipping_AccountsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model stores any specific Client information.
 * A Client is someone with whom you conduct business.
 * EX: You sell to/buy from a Client, not a Contact.
 */
export type Clients_ClientShipping_LabelsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model stores any specific Client information.
 * A Client is someone with whom you conduct business.
 * EX: You sell to/buy from a Client, not a Contact.
 */
export type Clients_ClientWork_OrdersArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/**
 * This model stores Contacts for a Client.
 * A Contact is a way to store an individual(Person) with any important information.
 * Mostly just holds ways to get a hold of them.
 */
export type Clients_Contact = {
  __typename?: 'Clients_Contact';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  attached_address?: Maybe<Array<Address_Address>>;
  attached_purchases?: Maybe<Array<Purchases_Purchase>>;
  attached_sales?: Maybe<Array<Sales_Sale>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  default_billing_for_contact?: Maybe<Clients_Client>;
  default_remit_for_contact?: Maybe<Clients_Client>;
  default_ship_to_for_contact?: Maybe<Clients_Client>;
  email: Scalars['Email'];
  fax: Scalars['String'];
  first_name: Scalars['String'];
  full_name: Scalars['String'];
  id: Scalars['ID'];
  is_our_client: Scalars['Boolean'];
  last_name: Scalars['String'];
  owner: Clients_Client;
  phone: Scalars['String'];
  purchase_shipments?: Maybe<Array<Shipping_ShipmentOrder>>;
  quotes?: Maybe<Array<Quotes_Quote>>;
  return_shipments?: Maybe<Array<Shipping_ShipmentOrder>>;
  rmas?: Maybe<Array<Rma_Rma>>;
  ship_from_shipments?: Maybe<Array<Shipping_ShipmentOrder>>;
  ship_to_shipments?: Maybe<Array<Shipping_ShipmentOrder>>;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};


/**
 * This model stores Contacts for a Client.
 * A Contact is a way to store an individual(Person) with any important information.
 * Mostly just holds ways to get a hold of them.
 */
export type Clients_ContactAttached_AddressArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model stores Contacts for a Client.
 * A Contact is a way to store an individual(Person) with any important information.
 * Mostly just holds ways to get a hold of them.
 */
export type Clients_ContactAttached_PurchasesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model stores Contacts for a Client.
 * A Contact is a way to store an individual(Person) with any important information.
 * Mostly just holds ways to get a hold of them.
 */
export type Clients_ContactAttached_SalesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model stores Contacts for a Client.
 * A Contact is a way to store an individual(Person) with any important information.
 * Mostly just holds ways to get a hold of them.
 */
export type Clients_ContactPurchase_ShipmentsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model stores Contacts for a Client.
 * A Contact is a way to store an individual(Person) with any important information.
 * Mostly just holds ways to get a hold of them.
 */
export type Clients_ContactQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model stores Contacts for a Client.
 * A Contact is a way to store an individual(Person) with any important information.
 * Mostly just holds ways to get a hold of them.
 */
export type Clients_ContactReturn_ShipmentsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model stores Contacts for a Client.
 * A Contact is a way to store an individual(Person) with any important information.
 * Mostly just holds ways to get a hold of them.
 */
export type Clients_ContactRmasArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model stores Contacts for a Client.
 * A Contact is a way to store an individual(Person) with any important information.
 * Mostly just holds ways to get a hold of them.
 */
export type Clients_ContactShip_From_ShipmentsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model stores Contacts for a Client.
 * A Contact is a way to store an individual(Person) with any important information.
 * Mostly just holds ways to get a hold of them.
 */
export type Clients_ContactShip_To_ShipmentsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** Holds shipping labels for clients. */
export type Clients_ShippingLabel = {
  __typename?: 'Clients_ShippingLabel';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  client: Clients_Client;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  id: Scalars['ID'];
  label: Scalars['String'];
  name: Scalars['String'];
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

/** This holds acceptable sub part numbers for a Client. */
export type Clients_Subs = {
  __typename?: 'Clients_Subs';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  client: Clients_Client;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  id: Scalars['ID'];
  /** The part to be subbed. */
  pn: Parts_Part;
  /** The sub for the part. */
  sub: Parts_Part;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

/** This model stores Terms information. */
export type Clients_Terms = {
  __typename?: 'Clients_Terms';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  attached_purchase?: Maybe<Array<Purchases_Purchase>>;
  attached_sale?: Maybe<Array<Sales_Sale>>;
  /** Who owns these terms. */
  client?: Maybe<Clients_Client>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  /** How many days they have. */
  days: Scalars['PositiveInteger'];
  default_pt_terms_for_client?: Maybe<Array<Clients_Client>>;
  default_st_terms_for_client?: Maybe<Array<Clients_Client>>;
  default_terms_for_company?: Maybe<Company_Company>;
  default_terms_for_new_client?: Maybe<Company_Company>;
  /** Any content for the terms. */
  description: Scalars['String'];
  id: Scalars['ID'];
  /** The name of the terms. */
  name: Scalars['String'];
  /** Any internal notes related to these terms. */
  notes: Scalars['String'];
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
  used_by_clients?: Maybe<Array<Clients_Client>>;
  work_orders?: Maybe<Array<WorkOrder_WorkOrder>>;
};


/** This model stores Terms information. */
export type Clients_TermsAttached_PurchaseArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores Terms information. */
export type Clients_TermsAttached_SaleArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores Terms information. */
export type Clients_TermsDefault_Pt_Terms_For_ClientArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores Terms information. */
export type Clients_TermsDefault_St_Terms_For_ClientArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores Terms information. */
export type Clients_TermsWork_OrdersArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/**
 * This model stores any Company information related to the company that owns the
 * instance.
 */
export type Company_Company = {
  __typename?: 'Company_Company';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  attached_address?: Maybe<Array<Address_Address>>;
  attached_credit_cards?: Maybe<Array<Company_CreditCard>>;
  /** The default address for billing. */
  default_billing_address?: Maybe<Address_Address>;
  /** The default terms to use for new clients. */
  default_new_client_terms?: Maybe<Clients_Terms>;
  /** The default address for remits. */
  default_remit_address?: Maybe<Address_Address>;
  /** The default address for return shipments. */
  default_return_to_address?: Maybe<Address_Address>;
  /** The default address to ship from. */
  default_ship_from_address?: Maybe<Address_Address>;
  /** The default address to receive shipments at. */
  default_ship_to_address?: Maybe<Address_Address>;
  /** The default shipping account for the company. */
  default_shipping_account?: Maybe<Shipping_Account>;
  /** The default shipping service to use. */
  default_shipping_service?: Maybe<Shipping_ServiceLevel>;
  /** The default terms to use when creating new orders. */
  default_terms?: Maybe<Clients_Terms>;
  id: Scalars['ID'];
  /** The company's logo image. */
  logo: Scalars['String'];
  /** The full name of the company. */
  name: Scalars['String'];
  /** The default phone number for the company. */
  phone: Scalars['String'];
  /** A short 10 letter code to reference the company by. */
  short_name: Scalars['String'];
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
};


/**
 * This model stores any Company information related to the company that owns the
 * instance.
 */
export type Company_CompanyAttached_AddressArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model stores any Company information related to the company that owns the
 * instance.
 */
export type Company_CompanyAttached_Credit_CardsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model stores credit card information. */
export type Company_CreditCard = {
  __typename?: 'Company_CreditCard';
  active: Scalars['Boolean'];
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  attached_purchase?: Maybe<Array<Purchases_Purchase>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  credit_left?: Maybe<Money>;
  id: Scalars['ID'];
  limit?: Maybe<Money>;
  name: Scalars['String'];
  owner: Company_Company;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};


/** This model stores credit card information. */
export type Company_CreditCardAttached_PurchaseArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model keeps track of acceptable subs for the owning company. */
export type Company_Subs = {
  __typename?: 'Company_Subs';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  id: Scalars['ID'];
  pn: Parts_Part;
  sub: Parts_Part;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

export type CountryObject = {
  __typename?: 'CountryObject';
  alpha3: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  numeric: Scalars['Int'];
  unicode_flag: Scalars['String'];
};

/** Represents the currency of a country. */
export type Currency = {
  __typename?: 'Currency';
  /** The ISO 3-letter code for the currency. */
  code: Scalars['String'];
  /** A list of country names that use this currency. */
  countries?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The canonical name of the currency. */
  name: Scalars['String'];
  /** The ISO 3-number code for the currency. */
  numeric: Scalars['String'];
  /** The sign for the currency. */
  sign?: Maybe<CurrencySign>;
};

/** Shows the currency sign and it's position. */
export type CurrencySign = {
  __typename?: 'CurrencySign';
  /** The currency prefix. */
  prefix: Scalars['String'];
  /** The currency suffix. */
  suffix: Scalars['String'];
};

/**
 * Represents per field permissions.
 * You locate it by specifying name_type and the name should be App.Model or
 * App.Model.field or MutationName
 */
export type CustomPermissions_GraphQlPermissions = {
  __typename?: 'CustomPermissions_GraphQLPermissions';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  name_type: Scalars['String'];
  roles?: Maybe<Array<CustomPermissions_RolePermissions>>;
};


/**
 * Represents per field permissions.
 * You locate it by specifying name_type and the name should be App.Model or
 * App.Model.field or MutationName
 */
export type CustomPermissions_GraphQlPermissionsRolesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/**
 * This model is for permission roles.
 * You attach permissions to a Role which you then attach to a Department.
 */
export type CustomPermissions_Role = {
  __typename?: 'CustomPermissions_Role';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  departments?: Maybe<Array<Department_DepartmentRoles>>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions?: Maybe<Array<CustomPermissions_RolePermissions>>;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};


/**
 * This model is for permission roles.
 * You attach permissions to a Role which you then attach to a Department.
 */
export type CustomPermissions_RoleDepartmentsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model is for permission roles.
 * You attach permissions to a Role which you then attach to a Department.
 */
export type CustomPermissions_RolePermissionsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This connects permissions to roles. */
export type CustomPermissions_RolePermissions = {
  __typename?: 'CustomPermissions_RolePermissions';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  id: Scalars['ID'];
  permission: CustomPermissions_GraphQlPermissions;
  role: CustomPermissions_Role;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

export type DbActionStatus = {
  __typename?: 'DBActionStatus';
  id: Scalars['ID'];
  message: Scalars['String'];
};

/** This holds Department information. (Techs, Breakdown, etc) */
export type Department_Department = {
  __typename?: 'Department_Department';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  description: Scalars['String'];
  employees?: Maybe<Array<Department_DepartmentEmployees>>;
  id: Scalars['ID'];
  livetemplates?: Maybe<Array<Profile_LiveTemplate>>;
  name: Scalars['String'];
  roles?: Maybe<Array<Department_DepartmentRoles>>;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};


/** This holds Department information. (Techs, Breakdown, etc) */
export type Department_DepartmentEmployeesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This holds Department information. (Techs, Breakdown, etc) */
export type Department_DepartmentLivetemplatesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This holds Department information. (Techs, Breakdown, etc) */
export type Department_DepartmentRolesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This connects Employees(Users) to Departments. */
export type Department_DepartmentEmployees = {
  __typename?: 'Department_DepartmentEmployees';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  department: Department_Department;
  id: Scalars['ID'];
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
  user: Users_User;
};

/** This connects Departments to Roles. */
export type Department_DepartmentRoles = {
  __typename?: 'Department_DepartmentRoles';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  department: Department_Department;
  id: Scalars['ID'];
  role: CustomPermissions_Role;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

export type EasyPostAddress = {
  __typename?: 'EasyPostAddress';
  carrier_facility?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  federal_tax_id?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mode: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  object: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  residential?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_tax_id?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  verifications?: Maybe<EasyPostVerifications>;
  zip?: Maybe<Scalars['String']>;
};

export type EasyPostCarrierAccount = {
  __typename?: 'EasyPostCarrierAccount';
  clone?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['String']>;
  credentials?: Maybe<EasyPostCredentialField>;
  description?: Maybe<Scalars['String']>;
  fields?: Maybe<EasyPostCarrierAccountFields>;
  id: Scalars['ID'];
  object?: Maybe<Scalars['String']>;
  readable?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  test_credentials?: Maybe<EasyPostCarrierAccountField>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type EasyPostCarrierAccountField = {
  __typename?: 'EasyPostCarrierAccountField';
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  visibility?: Maybe<Scalars['String']>;
};

export type EasyPostCarrierAccountFields = {
  __typename?: 'EasyPostCarrierAccountFields';
  auto_link?: Maybe<Scalars['Boolean']>;
  credentials?: Maybe<EasyPostCarrierAccountField>;
  custom_workflow?: Maybe<Scalars['Boolean']>;
  test_credentials?: Maybe<EasyPostCarrierAccountField>;
};

export type EasyPostCarrierDetail = {
  __typename?: 'EasyPostCarrierDetail';
  alternate_identifier?: Maybe<Scalars['String']>;
  container_type?: Maybe<Scalars['String']>;
  destination_location?: Maybe<Scalars['String']>;
  destination_tracking_location?: Maybe<EasyPostTrackingLocation>;
  est_delivery_date?: Maybe<Scalars['Date']>;
  est_delivery_time_local?: Maybe<Scalars['Time']>;
  guaranteed_delivery_date?: Maybe<Scalars['String']>;
  initial_delivery_attempt?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  origin_locaiton?: Maybe<Scalars['String']>;
  origin_tracking_location?: Maybe<EasyPostTrackingLocation>;
  service?: Maybe<Scalars['String']>;
};

export type EasyPostCredentialField = {
  __typename?: 'EasyPostCredentialField';
  account_number?: Maybe<Scalars['String']>;
};

export type EasyPostCustomsInfo = {
  __typename?: 'EasyPostCustomsInfo';
  contents_explanation?: Maybe<Scalars['String']>;
  contents_type?: Maybe<Easypost_Contents_Type>;
  created_at?: Maybe<Scalars['String']>;
  customs_certify?: Maybe<Scalars['Boolean']>;
  customs_items?: Maybe<Array<Maybe<EasyPostCustomsItem>>>;
  customs_signer?: Maybe<Scalars['String']>;
  eel_pfc: Scalars['String'];
  id: Scalars['ID'];
  non_delivery_option?: Maybe<Easypost_Non_Delivery_Option>;
  object?: Maybe<Scalars['String']>;
  restriction_comments?: Maybe<Scalars['String']>;
  restriction_type?: Maybe<Easypost_Restriction_Type>;
  updated_at?: Maybe<Scalars['String']>;
};

export type EasyPostCustomsItem = {
  __typename?: 'EasyPostCustomsItem';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  hs_tariff_number?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  object?: Maybe<Scalars['String']>;
  origin_country?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  updated_at?: Maybe<Scalars['String']>;
  /** Total value[USD](unit value * quantity */
  value: Scalars['Float'];
  /** Weight in ounces */
  weight: Scalars['Float'];
};

export type EasyPostError = {
  __typename?: 'EasyPostError';
  code?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<EasyPostFieldError>>>;
  message?: Maybe<Scalars['String']>;
};

export type EasyPostFee = {
  __typename?: 'EasyPostFee';
  amount?: Maybe<Scalars['String']>;
  charged?: Maybe<Scalars['Boolean']>;
  object?: Maybe<Scalars['String']>;
  refunded?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Easypost_Fee_Type>;
};

export type EasyPostFieldError = {
  __typename?: 'EasyPostFieldError';
  code?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type EasyPostForm = {
  __typename?: 'EasyPostForm';
  created_at?: Maybe<Scalars['String']>;
  form_type?: Maybe<Easypost_Form_Type>;
  form_url?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mode?: Maybe<Easypost_Mode>;
  object?: Maybe<Scalars['String']>;
  submitted_electronically?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type EasyPostInsurance = {
  __typename?: 'EasyPostInsurance';
  amount?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  fee?: Maybe<EasyPostFee>;
  from_address?: Maybe<EasyPostAddress>;
  id: Scalars['ID'];
  messages?: Maybe<Array<Maybe<Scalars['String']>>>;
  mode?: Maybe<Easypost_Mode>;
  object?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['ID']>;
  reference?: Maybe<Scalars['String']>;
  shipment_id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  to_address?: Maybe<EasyPostAddress>;
  tracker?: Maybe<EasyPostTracker>;
  tracking_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type EasyPostMessage = {
  __typename?: 'EasyPostMessage';
  carrier?: Maybe<Scalars['String']>;
  carrier_account_id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type EasyPostOrder = {
  __typename?: 'EasyPostOrder';
  buyer_address?: Maybe<EasyPostAddress>;
  created_at?: Maybe<Scalars['String']>;
  from_address?: Maybe<EasyPostAddress>;
  id: Scalars['ID'];
  is_return?: Maybe<Scalars['Boolean']>;
  messages?: Maybe<Array<Maybe<EasyPostMessage>>>;
  mode?: Maybe<Easypost_Mode>;
  object: Scalars['String'];
  rates?: Maybe<Array<Maybe<EasyPostRate>>>;
  reference?: Maybe<Scalars['String']>;
  return_address?: Maybe<EasyPostAddress>;
  shipments?: Maybe<Array<Maybe<EasyPostShipment>>>;
  to_address?: Maybe<EasyPostAddress>;
  updated_at?: Maybe<Scalars['String']>;
};

export type EasyPostParcel = {
  __typename?: 'EasyPostParcel';
  created_at?: Maybe<Scalars['String']>;
  /** Height in inches */
  height?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** Length in inches */
  length?: Maybe<Scalars['Float']>;
  mode?: Maybe<Easypost_Mode>;
  object?: Maybe<Scalars['String']>;
  predefined_package?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  /** Weight in ounces */
  weight?: Maybe<Scalars['Float']>;
  /** Width in inches */
  width?: Maybe<Scalars['Float']>;
};

export type EasyPostPayment = {
  __typename?: 'EasyPostPayment';
  account?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  type?: Maybe<Easypost_Payment_Type>;
};

export type EasyPostPickup = {
  __typename?: 'EasyPostPickup';
  address?: Maybe<EasyPostAddress>;
  carrier_accounts?: Maybe<Array<Maybe<EasyPostCarrierAccount>>>;
  confirmation?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  is_account_address?: Maybe<Scalars['Boolean']>;
  max_String?: Maybe<Scalars['String']>;
  messages?: Maybe<Array<Maybe<EasyPostMessage>>>;
  min_String?: Maybe<Scalars['String']>;
  mode?: Maybe<Easypost_Mode>;
  object?: Maybe<Scalars['String']>;
  pickup_rates?: Maybe<Array<Maybe<EasyPostPickupRate>>>;
  reference?: Maybe<Scalars['String']>;
  shipment?: Maybe<EasyPostShipment>;
  status?: Maybe<Easypost_Pickup_Status>;
  updated_at?: Maybe<Scalars['String']>;
};

export type EasyPostPickupRate = {
  __typename?: 'EasyPostPickupRate';
  carrier?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mode?: Maybe<Easypost_Mode>;
  object?: Maybe<Scalars['String']>;
  pickup_id?: Maybe<Scalars['ID']>;
  rate?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type EasyPostPostageLabel = {
  __typename?: 'EasyPostPostageLabel';
  created_at?: Maybe<Scalars['String']>;
  date_advance?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  integrated_form?: Maybe<Scalars['String']>;
  label_date?: Maybe<Scalars['String']>;
  label_epl2_url?: Maybe<Scalars['String']>;
  label_file_type?: Maybe<Scalars['String']>;
  label_pdf_url?: Maybe<Scalars['String']>;
  label_resolution?: Maybe<Scalars['Int']>;
  label_size?: Maybe<Scalars['String']>;
  label_type?: Maybe<Scalars['String']>;
  label_url?: Maybe<Scalars['String']>;
  label_zpl_url?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type EasyPostRate = {
  __typename?: 'EasyPostRate';
  carrier?: Maybe<Scalars['String']>;
  carrier_account_id?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  delivery_date?: Maybe<Scalars['String']>;
  delivery_date_guaranteed?: Maybe<Scalars['Boolean']>;
  delivery_days?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  list_currency?: Maybe<Scalars['String']>;
  list_rate?: Maybe<Scalars['String']>;
  mode?: Maybe<Easypost_Mode>;
  object?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['String']>;
  retail_currency?: Maybe<Scalars['String']>;
  retail_rate?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  shipment_id?: Maybe<Scalars['ID']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type EasyPostReport = {
  __typename?: 'EasyPostReport';
  created_at?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  include_children?: Maybe<Scalars['Boolean']>;
  mode?: Maybe<Easypost_Mode>;
  object?: Maybe<Easypost_Report_Type>;
  send_email?: Maybe<Scalars['Boolean']>;
  start_date?: Maybe<Scalars['Date']>;
  status?: Maybe<Easypost_Report_Status>;
  updated_at?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  url_expires_at?: Maybe<Scalars['String']>;
};

export type EasyPostScanForm = {
  __typename?: 'EasyPostScanForm';
  address?: Maybe<EasyPostAddress>;
  batch_id?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['String']>;
  form_file_type?: Maybe<Scalars['String']>;
  form_url?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  status?: Maybe<Easypost_Status_Types>;
  tracking_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
  updated_at?: Maybe<Scalars['String']>;
};

export type EasyPostShipment = {
  __typename?: 'EasyPostShipment';
  batch_id?: Maybe<Scalars['ID']>;
  batch_message?: Maybe<Scalars['String']>;
  batch_status?: Maybe<Scalars['String']>;
  buyer_address?: Maybe<EasyPostAddress>;
  created_at?: Maybe<Scalars['String']>;
  customs_info?: Maybe<EasyPostCustomsInfo>;
  fees?: Maybe<Array<Maybe<EasyPostFee>>>;
  forms?: Maybe<Array<Maybe<EasyPostForm>>>;
  from_address?: Maybe<EasyPostAddress>;
  id: Scalars['ID'];
  insurance?: Maybe<EasyPostInsurance>;
  is_return?: Maybe<Scalars['Boolean']>;
  messages?: Maybe<Array<Maybe<EasyPostMessage>>>;
  mode?: Maybe<Easypost_Mode>;
  object: Scalars['String'];
  options?: Maybe<EasyPostShipmentOptions>;
  parcel?: Maybe<EasyPostParcel>;
  postage_label?: Maybe<EasyPostPostageLabel>;
  rates?: Maybe<Array<Maybe<EasyPostRate>>>;
  reference?: Maybe<Scalars['String']>;
  refund_status?: Maybe<Scalars['String']>;
  return_address?: Maybe<EasyPostAddress>;
  scan_form?: Maybe<EasyPostScanForm>;
  selected_rate?: Maybe<EasyPostRate>;
  to_address?: Maybe<EasyPostAddress>;
  tracker?: Maybe<EasyPostTracker>;
  tracking_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  usps_zone?: Maybe<Scalars['String']>;
};

export type EasyPostShipmentOptions = {
  __typename?: 'EasyPostShipmentOptions';
  additional_handling?: Maybe<Scalars['Boolean']>;
  /** This is only for USPS */
  address_validation_level?: Maybe<Scalars['String']>;
  bill_receiver_account?: Maybe<Scalars['String']>;
  bill_receiver_postal_code?: Maybe<Scalars['String']>;
  bill_third_party_account?: Maybe<Scalars['String']>;
  bill_third_party_country?: Maybe<Scalars['String']>;
  bill_third_party_postal_code?: Maybe<Scalars['String']>;
  billing_ref?: Maybe<Scalars['String']>;
  by_drone?: Maybe<Scalars['Boolean']>;
  carbon_neutral?: Maybe<Scalars['Boolean']>;
  certified_mail?: Maybe<Easypost_Certified_Mail>;
  cod_address_id?: Maybe<EasyPostAddress>;
  cod_amount?: Maybe<Scalars['String']>;
  cod_method?: Maybe<Easypost_Cod_Method>;
  currency?: Maybe<Scalars['String']>;
  delivery_confirmation?: Maybe<Easypost_Signature_Type>;
  dropoff_type?: Maybe<Easypost_Dropoff_Type>;
  dry_ice?: Maybe<Scalars['Boolean']>;
  dry_ice_medical?: Maybe<Scalars['String']>;
  dry_ice_weight?: Maybe<Scalars['String']>;
  endorsement?: Maybe<Easypost_Endorsement>;
  freight_charge?: Maybe<Scalars['Float']>;
  handling_instructions?: Maybe<Scalars['String']>;
  hazmat?: Maybe<Easypost_Hazmat>;
  hold_for_pickup?: Maybe<Scalars['Boolean']>;
  incoterm?: Maybe<Easypost_Incoterm>;
  invoice_number?: Maybe<Scalars['String']>;
  label_date?: Maybe<Scalars['String']>;
  label_format: Easypost_Label_Format;
  machinable?: Maybe<Scalars['Boolean']>;
  payment?: Maybe<EasyPostPayment>;
  print_custom_1?: Maybe<Scalars['String']>;
  print_custom_1_barcode?: Maybe<Scalars['Boolean']>;
  print_custom_1_code?: Maybe<Scalars['String']>;
  print_custom_2?: Maybe<Scalars['String']>;
  print_custom_2_barcode?: Maybe<Scalars['Boolean']>;
  print_custom_2_code?: Maybe<Scalars['String']>;
  print_custom_3?: Maybe<Scalars['String']>;
  print_custom_3_barcode?: Maybe<Scalars['Boolean']>;
  print_custom_3_code?: Maybe<Scalars['String']>;
  registered_mail?: Maybe<Scalars['Boolean']>;
  registered_mail_amount?: Maybe<Scalars['Float']>;
  return_receipt?: Maybe<Scalars['Boolean']>;
  saturday_delivery?: Maybe<Scalars['Boolean']>;
  smartpost_hub?: Maybe<Scalars['String']>;
  smartpost_manifest?: Maybe<Scalars['String']>;
  special_rates_eligibility?: Maybe<Scalars['String']>;
};

export type EasyPostTracker = {
  __typename?: 'EasyPostTracker';
  carrier?: Maybe<Scalars['String']>;
  carrier_detail?: Maybe<EasyPostCarrierDetail>;
  created_at?: Maybe<Scalars['String']>;
  est_delivery_date?: Maybe<Scalars['String']>;
  fees?: Maybe<Array<Maybe<EasyPostFee>>>;
  id: Scalars['ID'];
  mode?: Maybe<Easypost_Mode>;
  object?: Maybe<Scalars['String']>;
  public_url?: Maybe<Scalars['String']>;
  shipment_id?: Maybe<Scalars['ID']>;
  signed_by?: Maybe<Scalars['String']>;
  status?: Maybe<Easypost_Tracker_Status>;
  tracking_code?: Maybe<Scalars['String']>;
  tracking_details?: Maybe<Array<Maybe<EasyPostTrackingDetail>>>;
  updated_at?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
};

export type EasyPostTrackingDetail = {
  __typename?: 'EasyPostTrackingDetail';
  String?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  status?: Maybe<Easypost_Tracker_Status>;
  tracking_location?: Maybe<EasyPostTrackingLocation>;
};

export type EasyPostTrackingLocation = {
  __typename?: 'EasyPostTrackingLocation';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type EasyPostVerification = {
  __typename?: 'EasyPostVerification';
  details?: Maybe<EasyPostVerificationDetails>;
  errors?: Maybe<Array<Maybe<EasyPostFieldError>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type EasyPostVerificationDetails = {
  __typename?: 'EasyPostVerificationDetails';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  time_zone?: Maybe<Scalars['String']>;
};

export type EasyPostVerifications = {
  __typename?: 'EasyPostVerifications';
  delivery?: Maybe<EasyPostVerification>;
  zip4?: Maybe<EasyPostVerification>;
};

/** This model serves as the base Inventory Item. */
export type Inventory_Item = Inventory_Item__Interface & {
  __typename?: 'Inventory_Item';
  /** The sub-Items for this Item. (e.g., the parts of a system chassis.) */
  add_on_items?: Maybe<Array<Inventory_Item>>;
  /** The date the Item was allocated. */
  allocated_date?: Maybe<Scalars['DateTime']>;
  /** An alternate serial number for the Item. */
  alternate_serial_number?: Maybe<Scalars['String']>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** The associated part number of the Item. */
  associated_pn?: Maybe<Parts_AssociatedPn>;
  /** The last date the Item was audited. */
  audit_date?: Maybe<Scalars['DateTime']>;
  broke_down_items?: Maybe<Breakdown_BreakDownItems>;
  build_up_items?: Maybe<BuildUp_BuildUpItems>;
  /** Who currently has the Item. */
  checked_out_by?: Maybe<Users_User>;
  /** The sale condition of the Item. New, refurb, etc.. */
  condition?: Maybe<Choices_Choice>;
  /** More information relating to the Item's condition. */
  condition_comment: Scalars['String'];
  /** The origin country of this Item. */
  country_of_origin?: Maybe<CountryObject>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  /** The firmware version currently applied. */
  fw: Scalars['String'];
  /** What physical condition the Item is in. */
  grade?: Maybe<Choices_Choice>;
  hot: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Whether this item is for internal use or not. */
  internal: Scalars['Boolean'];
  /** General inventory comments. */
  inventory_comment: Scalars['String'];
  /** Whether the Item is lost or not. */
  is_lost: Scalars['Boolean'];
  /** The date of the last write down for this Item. */
  last_write_down_date?: Maybe<Scalars['DateTime']>;
  /** The location where the part is. */
  location?: Maybe<Location_Location>;
  /** The reason for locking the Item. */
  lock_reason: Scalars['String'];
  /** If locked, the Item can't be sold. */
  locked: Scalars['Boolean'];
  /** The date of the next write down for this Item. */
  next_write_down_date?: Maybe<Scalars['DateTime']>;
  /** What kind of part the Item is. */
  part?: Maybe<Parts_Part>;
  purchase_rma_item_details?: Maybe<Rma_PurchaseRmaItems>;
  purchases_items_details?: Maybe<Purchases_PurchasedItems>;
  /** How many of these parts are in the Item. */
  quantity: Scalars['PositiveInteger'];
  /** If the Item is Received(True), Booked(Null), or Unreceived(False). */
  receive_status?: Maybe<Scalars['Boolean']>;
  /** Who received the Item. */
  received_by?: Maybe<Users_User>;
  /** The date the Item was received. */
  received_date?: Maybe<Scalars['DateTime']>;
  rma_item_details?: Maybe<Rma_RmaItems>;
  sales_items_details?: Maybe<Sales_SoldItems>;
  /** The serial number of the Item. */
  serial_number?: Maybe<Scalars['String']>;
  shipment?: Maybe<Shipping_ShipmentSupplies>;
  /** The current status of the Item within a process. */
  status?: Maybe<Choices_Choice>;
  system?: Maybe<Inventory_System>;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** What wipes/tests were run or if it is failed. */
  test_status?: Maybe<Array<Choices_Tag>>;
  /** The date the Item was unbooked. */
  unbooked_date?: Maybe<Scalars['DateTime']>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
  workorder_items_details?: Maybe<WorkOrder_WorkOrderItems>;
};

/** This model is to keep track of sellable Services. (X years of support) */
export type Inventory_Service = {
  __typename?: 'Inventory_Service';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  comments: Scalars['String'];
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  period: Scalars['Duration'];
  price?: Maybe<Money>;
  service_discounts?: Maybe<Array<Accounting_ServiceDiscount>>;
  services_allocated?: Maybe<Array<Sales_SoldServices>>;
  sku: Scalars['String'];
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};


/** This model is to keep track of sellable Services. (X years of support) */
export type Inventory_ServiceService_DiscountsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model is to keep track of sellable Services. (X years of support) */
export type Inventory_ServiceServices_AllocatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model is to keep track of sellable Software. (X years of Traqsys) */
export type Inventory_Software = {
  __typename?: 'Inventory_Software';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  comments: Scalars['String'];
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  description: Scalars['String'];
  id: Scalars['ID'];
  manufacturer: Scalars['String'];
  name: Scalars['String'];
  period: Scalars['Duration'];
  price?: Maybe<Money>;
  product_tier: Scalars['String'];
  product_version: Scalars['String'];
  sku: Scalars['String'];
  software_allocated?: Maybe<Array<Sales_SoldSoftware>>;
  software_discounts?: Maybe<Array<Accounting_SoftwareDiscount>>;
  support_period?: Maybe<Scalars['Duration']>;
  support_price?: Maybe<Money>;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
  vendor: Scalars['String'];
};


/** This model is to keep track of sellable Software. (X years of Traqsys) */
export type Inventory_SoftwareSoftware_AllocatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model is to keep track of sellable Software. (X years of Traqsys) */
export type Inventory_SoftwareSoftware_DiscountsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/**
 * Inherits Item and has oobmgmt data.
 * This model is for items that are Servers.
 * Other items that can be constructed of multiple items can be "Systems"
 */
export type Inventory_System = Inventory_Item__Interface & {
  __typename?: 'Inventory_System';
  /** The sub-Items for this Item. (e.g., the parts of a system chassis.) */
  add_on_items?: Maybe<Array<Inventory_Item>>;
  /** The date the Item was allocated. */
  allocated_date?: Maybe<Scalars['DateTime']>;
  /** An alternate serial number for the Item. */
  alternate_serial_number?: Maybe<Scalars['String']>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** The associated part number of the Item. */
  associated_pn?: Maybe<Parts_AssociatedPn>;
  /** The last date the Item was audited. */
  audit_date?: Maybe<Scalars['DateTime']>;
  break_down_orders?: Maybe<Array<Breakdown_BreakDown>>;
  broke_down_items?: Maybe<Breakdown_BreakDownItems>;
  build_up_items?: Maybe<BuildUp_BuildUpItems>;
  /** Who currently has the Item. */
  checked_out_by?: Maybe<Users_User>;
  /** The sale condition of the Item. New, refurb, etc.. */
  condition?: Maybe<Choices_Choice>;
  /** More information relating to the Item's condition. */
  condition_comment: Scalars['String'];
  /** The origin country of this Item. */
  country_of_origin?: Maybe<CountryObject>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  /** The firmware version currently applied. */
  fw: Scalars['String'];
  /** What physical condition the Item is in. */
  grade?: Maybe<Choices_Choice>;
  hot: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Whether this item is for internal use or not. */
  internal: Scalars['Boolean'];
  /** General inventory comments. */
  inventory_comment: Scalars['String'];
  /** Whether the Item is lost or not. */
  is_lost: Scalars['Boolean'];
  item_ptr: Inventory_Item;
  /** The date of the last write down for this Item. */
  last_write_down_date?: Maybe<Scalars['DateTime']>;
  /** The location where the part is. */
  location?: Maybe<Location_Location>;
  /** The reason for locking the Item. */
  lock_reason: Scalars['String'];
  /** If locked, the Item can't be sold. */
  locked: Scalars['Boolean'];
  /** The date of the next write down for this Item. */
  next_write_down_date?: Maybe<Scalars['DateTime']>;
  /** The serial for the Out of Bound Management System. */
  oobmgmt_serial: Scalars['String'];
  /** What kind of part the Item is. */
  part?: Maybe<Parts_Part>;
  purchase_rma_item_details?: Maybe<Rma_PurchaseRmaItems>;
  purchases_items_details?: Maybe<Purchases_PurchasedItems>;
  /** How many of these parts are in the Item. */
  quantity: Scalars['PositiveInteger'];
  /** If the Item is Received(True), Booked(Null), or Unreceived(False). */
  receive_status?: Maybe<Scalars['Boolean']>;
  /** Who received the Item. */
  received_by?: Maybe<Users_User>;
  /** The date the Item was received. */
  received_date?: Maybe<Scalars['DateTime']>;
  rma_item_details?: Maybe<Rma_RmaItems>;
  sales_items_details?: Maybe<Sales_SoldItems>;
  /** The serial number of the Item. */
  serial_number?: Maybe<Scalars['String']>;
  shipment?: Maybe<Shipping_ShipmentSupplies>;
  /** The current status of the Item within a process. */
  status?: Maybe<Choices_Choice>;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** What wipes/tests were run or if it is failed. */
  test_status?: Maybe<Array<Choices_Tag>>;
  to_build_up?: Maybe<Array<BuildUp_BuildUp>>;
  /** The date the Item was unbooked. */
  unbooked_date?: Maybe<Scalars['DateTime']>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
  workorder_items_details?: Maybe<WorkOrder_WorkOrderItems>;
};


/**
 * Inherits Item and has oobmgmt data.
 * This model is for items that are Servers.
 * Other items that can be constructed of multiple items can be "Systems"
 */
export type Inventory_SystemBreak_Down_OrdersArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * Inherits Item and has oobmgmt data.
 * This model is for items that are Servers.
 * Other items that can be constructed of multiple items can be "Systems"
 */
export type Inventory_SystemTo_Build_UpArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** Accounts Payable Invoices. */
export type Invoices_ApInvoice = {
  __typename?: 'Invoices_APInvoice';
  /** The amount to pay. */
  amount?: Maybe<Money>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  /** The date the invoice is due by. */
  date_due?: Maybe<Scalars['Date']>;
  /** The date the invoice was sent. */
  date_sent?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  /** If the invoice has been voided. */
  is_voided: Scalars['Boolean'];
  /** The purchase order this invoice is for. */
  order: Purchases_Purchase;
  payments?: Maybe<Array<Invoices_ApPayment>>;
  /** A reference number for the invoice. */
  reference: Scalars['String'];
  /** The current status of the invoice. */
  status?: Maybe<Choices_Status>;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};


/** Accounts Payable Invoices. */
export type Invoices_ApInvoicePaymentsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

export type Invoices_ApPayment = {
  __typename?: 'Invoices_APPayment';
  /** The amount paid. */
  amount?: Maybe<Money>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  id: Scalars['ID'];
  /** The invoice the payment was made on. */
  invoice: Invoices_ApInvoice;
  /** If the payment has posted. */
  is_posted: Scalars['Boolean'];
  /** A reference number for the payment. */
  reference: Scalars['String'];
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

/** Accounts Receivable Invoices. */
export type Invoices_ArInvoice = {
  __typename?: 'Invoices_ARInvoice';
  /** The amount to pay. */
  amount?: Maybe<Money>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  /** The date the invoice is due by. */
  date_due?: Maybe<Scalars['Date']>;
  /** The date the invoice was sent. */
  date_sent?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  /** If the invoice has been voided. */
  is_voided: Scalars['Boolean'];
  /** The sales order this invoice is for. */
  order: Sales_Sale;
  payments?: Maybe<Array<Invoices_ArPayment>>;
  /** A reference number for the invoice. */
  reference: Scalars['String'];
  /** The current status of the invoice. */
  status?: Maybe<Choices_Status>;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};


/** Accounts Receivable Invoices. */
export type Invoices_ArInvoicePaymentsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

export type Invoices_ArPayment = {
  __typename?: 'Invoices_ARPayment';
  /** The amount paid. */
  amount?: Maybe<Money>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  id: Scalars['ID'];
  /** The invoice the payment was made on. */
  invoice: Invoices_ArInvoice;
  /** If the payment has posted. */
  is_posted: Scalars['Boolean'];
  /** A reference number for the payment. */
  reference: Scalars['String'];
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

/**
 * This model stores Locations.
 * Main parts are a name and site its at.
 */
export type Location_Location = {
  __typename?: 'Location_Location';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  id: Scalars['ID'];
  name: Scalars['String'];
  part_type: Scalars['String'];
  site: Location_Site;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  type?: Maybe<Choices_Choice>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};


/**
 * This model stores Locations.
 * Main parts are a name and site its at.
 */
export type Location_LocationAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model stores Site data. */
export type Location_Site = {
  __typename?: 'Location_Site';
  address?: Maybe<Address_Address>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  attached_locations?: Maybe<Array<Location_Location>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  delivery_notes: Scalars['String'];
  id: Scalars['ID'];
  main_site?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  short_name: Scalars['String'];
  site_users?: Maybe<Array<Profile_Profile>>;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};


/** This model stores Site data. */
export type Location_SiteAttached_LocationsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model stores Site data. */
export type Location_SiteSite_UsersArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/**
 * This is a logging model for any action that is taken.
 * Proper creation should be to make an ActionLog, make ActionLogObjects to link it,
 * then update after the process is done with the was successful
 * ActionLog.objects.filter(pk=id).update(was_successful=True)
 */
export type Logging_ActionLog = {
  __typename?: 'Logging_ActionLog';
  action: Scalars['String'];
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  attached_objects?: Maybe<Array<Logging_ActionLogObject>>;
  description: Scalars['String'];
  id: Scalars['ID'];
  new_data: Scalars['JSON'];
  timestamp: Scalars['DateTime'];
  user: Users_User;
  was_successful: Scalars['Boolean'];
};


/**
 * This is a logging model for any action that is taken.
 * Proper creation should be to make an ActionLog, make ActionLogObjects to link it,
 * then update after the process is done with the was successful
 * ActionLog.objects.filter(pk=id).update(was_successful=True)
 */
export type Logging_ActionLogAttached_ObjectsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** Allows linking multiple objects to an ActionLog. */
export type Logging_ActionLogObject = {
  __typename?: 'Logging_ActionLogObject';
  accounting_account?: Maybe<Array<Accounting_Account>>;
  accounting_entry?: Maybe<Array<Accounting_Entry>>;
  accounting_servicediscount?: Maybe<Array<Accounting_ServiceDiscount>>;
  accounting_softwarediscount?: Maybe<Array<Accounting_SoftwareDiscount>>;
  accounting_transaction?: Maybe<Array<Accounting_Transaction>>;
  action: Logging_ActionLog;
  address_address?: Maybe<Array<Address_Address>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  breakdown_breakdown?: Maybe<Array<Breakdown_BreakDown>>;
  breakdown_breakdownitems?: Maybe<Array<Breakdown_BreakDownItems>>;
  buildup_buildup?: Maybe<Array<BuildUp_BuildUp>>;
  buildup_buildupitems?: Maybe<Array<BuildUp_BuildUpItems>>;
  clients_client?: Maybe<Array<Clients_Client>>;
  clients_contact?: Maybe<Array<Clients_Contact>>;
  clients_shippinglabel?: Maybe<Array<Clients_ShippingLabel>>;
  clients_subs?: Maybe<Array<Clients_Subs>>;
  clients_terms?: Maybe<Array<Clients_Terms>>;
  company_company?: Maybe<Array<Company_Company>>;
  company_creditcard?: Maybe<Array<Company_CreditCard>>;
  company_subs?: Maybe<Array<Company_Subs>>;
  custompermissions_role?: Maybe<Array<CustomPermissions_Role>>;
  custompermissions_rolepermissions?: Maybe<Array<CustomPermissions_RolePermissions>>;
  department_department?: Maybe<Array<Department_Department>>;
  department_departmentemployees?: Maybe<Array<Department_DepartmentEmployees>>;
  department_departmentroles?: Maybe<Array<Department_DepartmentRoles>>;
  id: Scalars['ID'];
  inventory_item?: Maybe<Array<Inventory_Item>>;
  inventory_service?: Maybe<Array<Inventory_Service>>;
  inventory_software?: Maybe<Array<Inventory_Software>>;
  invoices_apinvoice?: Maybe<Array<Invoices_ApInvoice>>;
  invoices_appayment?: Maybe<Array<Invoices_ApPayment>>;
  invoices_arinvoice?: Maybe<Array<Invoices_ArInvoice>>;
  invoices_arpayment?: Maybe<Array<Invoices_ArPayment>>;
  location_location?: Maybe<Array<Location_Location>>;
  location_site?: Maybe<Array<Location_Site>>;
  object_type?: Maybe<Scalars['String']>;
  old_data: Scalars['JSON'];
  parts_bom?: Maybe<Array<Parts_Bom>>;
  parts_bomparts?: Maybe<Array<Parts_BomParts>>;
  profile_livetemplate?: Maybe<Array<Profile_LiveTemplate>>;
  profile_tableconfig?: Maybe<Array<Profile_TableConfig>>;
  purchases_purchase?: Maybe<Array<Purchases_Purchase>>;
  purchases_purchaseditems?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes_quote?: Maybe<Array<Quotes_Quote>>;
  quotes_quoteparts?: Maybe<Array<Quotes_QuoteParts>>;
  reporting_report?: Maybe<Array<Reporting_Report>>;
  rma_purchaserma?: Maybe<Array<Rma_PurchaseRma>>;
  rma_purchasermaitems?: Maybe<Array<Rma_PurchaseRmaItems>>;
  rma_rma?: Maybe<Array<Rma_Rma>>;
  rma_rmaitems?: Maybe<Array<Rma_RmaItems>>;
  sales_fees?: Maybe<Array<Sales_Fees>>;
  sales_sale?: Maybe<Array<Sales_Sale>>;
  sales_solditems?: Maybe<Array<Sales_SoldItems>>;
  sales_soldservices?: Maybe<Array<Sales_SoldServices>>;
  sales_soldsoftware?: Maybe<Array<Sales_SoldSoftware>>;
  shipping_account?: Maybe<Array<Shipping_Account>>;
  shipping_carrier?: Maybe<Array<Shipping_Carrier>>;
  shipping_predefinedparcel?: Maybe<Array<Shipping_PredefinedParcel>>;
  shipping_servicelevel?: Maybe<Array<Shipping_ServiceLevel>>;
  shipping_shipment?: Maybe<Array<Shipping_Shipment>>;
  shipping_shipmentorder?: Maybe<Array<Shipping_ShipmentOrder>>;
  shipping_shipmentsupplies?: Maybe<Array<Shipping_ShipmentSupplies>>;
  team_team?: Maybe<Array<Team_Team>>;
  team_teammembers?: Maybe<Array<Team_TeamMembers>>;
  workorder_workorder?: Maybe<Array<WorkOrder_WorkOrder>>;
  workorder_workorderitems?: Maybe<Array<WorkOrder_WorkOrderItems>>;
};

/** Type that represents Money. */
export type Money = {
  __typename?: 'Money';
  /** The numeric value as a string. */
  amount: Scalars['Price'];
  /** The currency type of the money. */
  currency: Currency;
  /** A pretty formatted version of the amount. For example, $3.50 */
  pretty: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Approve a Purchase */
  ApprovePurchase?: Maybe<Purchases_Purchase>;
  /** Create an Accounting_Account */
  Create__Accounting_Account?: Maybe<Accounting_Account>;
  /** Create an Accounting_Entry */
  Create__Accounting_Entry?: Maybe<Accounting_Entry>;
  /** Create an Accounting_ServiceDiscount */
  Create__Accounting_ServiceDiscount?: Maybe<Accounting_ServiceDiscount>;
  /** Create an Accounting_SoftwareDiscount */
  Create__Accounting_SoftwareDiscount?: Maybe<Accounting_SoftwareDiscount>;
  /** Create an Accounting_Transaction */
  Create__Accounting_Transaction?: Maybe<Accounting_Transaction>;
  /** Create an Address_Address */
  Create__Address_Address?: Maybe<Address_Address>;
  /** Create an Breakdown_BreakDown */
  Create__Breakdown_BreakDown?: Maybe<Breakdown_BreakDown>;
  /** Create an Breakdown_BreakDownItems */
  Create__Breakdown_BreakDownItems?: Maybe<Array<Maybe<Breakdown_BreakDownItems>>>;
  /** Create an BuildUp_BuildUp */
  Create__BuildUp_BuildUp?: Maybe<BuildUp_BuildUp>;
  /** Create an BuildUp_BuildUpItems */
  Create__BuildUp_BuildUpItems?: Maybe<Array<Maybe<BuildUp_BuildUpItems>>>;
  /** Create an Clients_Client */
  Create__Clients_Client?: Maybe<Clients_Client>;
  /** Create an Clients_Contact */
  Create__Clients_Contact?: Maybe<Clients_Contact>;
  /** Create an Clients_ShippingLabel */
  Create__Clients_ShippingLabel?: Maybe<Clients_ShippingLabel>;
  /** Create an Clients_Subs */
  Create__Clients_Subs?: Maybe<Clients_Subs>;
  /** Create an Clients_Terms */
  Create__Clients_Terms?: Maybe<Clients_Terms>;
  /** Create an Company_CreditCard */
  Create__Company_CreditCard?: Maybe<Company_CreditCard>;
  /** Create an Company_Subs */
  Create__Company_Subs?: Maybe<Company_Subs>;
  /** Create an CustomPermissions_Role */
  Create__CustomPermissions_Role?: Maybe<CustomPermissions_Role>;
  /** Create an CustomPermissions_RolePermissions */
  Create__CustomPermissions_RolePermissions?: Maybe<CustomPermissions_RolePermissions>;
  /** Create an Department_Department */
  Create__Department_Department?: Maybe<Department_Department>;
  /** Create an Department_DepartmentEmployees */
  Create__Department_DepartmentEmployees?: Maybe<Department_DepartmentEmployees>;
  /** Create an Department_DepartmentRoles */
  Create__Department_DepartmentRoles?: Maybe<Department_DepartmentRoles>;
  /** Create an Inventory_Item */
  Create__Inventory_Item?: Maybe<Array<Maybe<Inventory_Item>>>;
  /** Create an Inventory_Service */
  Create__Inventory_Service?: Maybe<Inventory_Service>;
  /** Create an Inventory_Software */
  Create__Inventory_Software?: Maybe<Inventory_Software>;
  /** Create an Inventory_System */
  Create__Inventory_System?: Maybe<Inventory_System>;
  /** Create an Invoices_APInvoice */
  Create__Invoices_APInvoice?: Maybe<Invoices_ApInvoice>;
  /** Create an Invoices_APPayment */
  Create__Invoices_APPayment?: Maybe<Invoices_ApPayment>;
  /** Create an Invoices_ARInvoice */
  Create__Invoices_ARInvoice?: Maybe<Invoices_ArInvoice>;
  /** Create an Invoices_ARPayment */
  Create__Invoices_ARPayment?: Maybe<Invoices_ArPayment>;
  /** Create an Location_Location */
  Create__Location_Location?: Maybe<Location_Location>;
  /** Create an Location_Site */
  Create__Location_Site?: Maybe<Location_Site>;
  /** Create an Logging_ActionLog */
  Create__Logging_ActionLog?: Maybe<Logging_ActionLog>;
  /** Create an Notifications_Notification */
  Create__Notifications_Notification?: Maybe<Notifications_Notification>;
  /** Create an Parts_AccessPoint */
  Create__Parts_AccessPoint?: Maybe<Parts_AccessPoint>;
  /** Create an Parts_AssociatedPN */
  Create__Parts_AssociatedPN?: Maybe<Parts_AssociatedPn>;
  /** Create an Parts_BOM */
  Create__Parts_BOM?: Maybe<Parts_Bom>;
  /** Create an Parts_BOMParts */
  Create__Parts_BOMParts?: Maybe<Parts_BomParts>;
  /** Create an Parts_Battery */
  Create__Parts_Battery?: Maybe<Parts_Battery>;
  /** Create an Parts_Bezel */
  Create__Parts_Bezel?: Maybe<Parts_Bezel>;
  /** Create an Parts_Bracket */
  Create__Parts_Bracket?: Maybe<Parts_Bracket>;
  /** Create an Parts_CPU */
  Create__Parts_CPU?: Maybe<Parts_Cpu>;
  /** Create an Parts_Cable */
  Create__Parts_Cable?: Maybe<Parts_Cable>;
  /** Create an Parts_Cache */
  Create__Parts_Cache?: Maybe<Parts_Cache>;
  /** Create an Parts_DiskDrive */
  Create__Parts_DiskDrive?: Maybe<Parts_DiskDrive>;
  /** Create an Parts_ExpansionCard */
  Create__Parts_ExpansionCard?: Maybe<Parts_ExpansionCard>;
  /** Create an Parts_Fan */
  Create__Parts_Fan?: Maybe<Parts_Fan>;
  /** Create an Parts_GPU */
  Create__Parts_GPU?: Maybe<Parts_Gpu>;
  /** Create an Parts_Heatsink */
  Create__Parts_Heatsink?: Maybe<Parts_Heatsink>;
  /** Create an Parts_IOModule */
  Create__Parts_IOModule?: Maybe<Parts_IoModule>;
  /** Create an Parts_Memory */
  Create__Parts_Memory?: Maybe<Parts_Memory>;
  /** Create an Parts_Motherboard */
  Create__Parts_Motherboard?: Maybe<Parts_Motherboard>;
  /** Create an Parts_NetworkCard */
  Create__Parts_NetworkCard?: Maybe<Parts_NetworkCard>;
  /** Create an Parts_Part */
  Create__Parts_Part?: Maybe<Parts_Part>;
  /** Create an Parts_Phone */
  Create__Parts_Phone?: Maybe<Parts_Phone>;
  /** Create an Parts_PowerSupply */
  Create__Parts_PowerSupply?: Maybe<Parts_PowerSupply>;
  /** Create an Parts_ProductCompany */
  Create__Parts_ProductCompany?: Maybe<Parts_ProductCompany>;
  /** Create an Parts_Rails */
  Create__Parts_Rails?: Maybe<Parts_Rails>;
  /** Create an Parts_Router */
  Create__Parts_Router?: Maybe<Parts_Router>;
  /** Create an Parts_Server */
  Create__Parts_Server?: Maybe<Parts_Server>;
  /** Create an Parts_Storage */
  Create__Parts_Storage?: Maybe<Parts_Storage>;
  /** Create an Parts_StorageEnclosure */
  Create__Parts_StorageEnclosure?: Maybe<Parts_StorageEnclosure>;
  /** Create an Parts_Supplies */
  Create__Parts_Supplies?: Maybe<Parts_Supplies>;
  /** Create an Parts_Switch */
  Create__Parts_Switch?: Maybe<Parts_Switch>;
  /** Create an Profile_LiveTemplate */
  Create__Profile_LiveTemplate?: Maybe<Profile_LiveTemplate>;
  /** Create an Profile_Profile */
  Create__Profile_Profile?: Maybe<Profile_Profile>;
  /** Create an Profile_ProfileMetrics */
  Create__Profile_ProfileMetrics?: Maybe<Profile_ProfileMetrics>;
  /** Create an Profile_TableConfig */
  Create__Profile_TableConfig?: Maybe<Profile_TableConfig>;
  /** Create an Purchases_Purchase */
  Create__Purchases_Purchase?: Maybe<Purchases_Purchase>;
  /** Create an Purchases_PurchasedItems */
  Create__Purchases_PurchasedItems?: Maybe<Array<Maybe<Purchases_PurchasedItems>>>;
  /** Create an Quotes_Quote */
  Create__Quotes_Quote?: Maybe<Quotes_Quote>;
  /** Create an Quotes_QuoteParts */
  Create__Quotes_QuoteParts?: Maybe<Array<Maybe<Quotes_QuoteParts>>>;
  /** Create an RMA_PurchaseRMA */
  Create__RMA_PurchaseRMA?: Maybe<Rma_PurchaseRma>;
  /** Create an RMA_PurchaseRMAItems */
  Create__RMA_PurchaseRMAItems?: Maybe<Array<Maybe<Rma_PurchaseRmaItems>>>;
  /** Create an RMA_RMA */
  Create__RMA_RMA?: Maybe<Rma_Rma>;
  /** Create an RMA_RMAItems */
  Create__RMA_RMAItems?: Maybe<Array<Maybe<Rma_RmaItems>>>;
  /** Create an Reporting_Report */
  Create__Reporting_Report?: Maybe<Reporting_Report>;
  /** Create an Sales_Fees */
  Create__Sales_Fees?: Maybe<Sales_Fees>;
  /** Create an Sales_Sale */
  Create__Sales_Sale?: Maybe<Sales_Sale>;
  /** Create an Sales_SoldItems */
  Create__Sales_SoldItems?: Maybe<Array<Maybe<Sales_SoldItems>>>;
  /** Create an Sales_SoldServices */
  Create__Sales_SoldServices?: Maybe<Sales_SoldServices>;
  /** Create an Sales_SoldSoftware */
  Create__Sales_SoldSoftware?: Maybe<Sales_SoldSoftware>;
  /** Create an Shipping_Account */
  Create__Shipping_Account?: Maybe<Shipping_Account>;
  /** Create an Shipping_Carrier */
  Create__Shipping_Carrier?: Maybe<Shipping_Carrier>;
  /** Create an Shipping_PredefinedParcel */
  Create__Shipping_PredefinedParcel?: Maybe<Shipping_PredefinedParcel>;
  /** Create an Shipping_ServiceLevel */
  Create__Shipping_ServiceLevel?: Maybe<Shipping_ServiceLevel>;
  /** Create an Shipping_Shipment */
  Create__Shipping_Shipment?: Maybe<Shipping_Shipment>;
  /** Create an Shipping_ShipmentOrder */
  Create__Shipping_ShipmentOrder?: Maybe<Shipping_ShipmentOrder>;
  /** Create an Shipping_ShipmentSupplies */
  Create__Shipping_ShipmentSupplies?: Maybe<Shipping_ShipmentSupplies>;
  /** Create an Team_Team */
  Create__Team_Team?: Maybe<Team_Team>;
  /** Create an Team_TeamMembers */
  Create__Team_TeamMembers?: Maybe<Team_TeamMembers>;
  /** Create an Timer_BaseTimer */
  Create__Timer_BaseTimer?: Maybe<Timer_BaseTimer>;
  /** Create an WorkOrder_WorkOrder */
  Create__WorkOrder_WorkOrder?: Maybe<WorkOrder_WorkOrder>;
  /** Create an WorkOrder_WorkOrderItems */
  Create__WorkOrder_WorkOrderItems?: Maybe<Array<Maybe<WorkOrder_WorkOrderItems>>>;
  /** Delete an Accounting_Account */
  Delete__Accounting_Account?: Maybe<DbActionStatus>;
  /** Delete an Accounting_Entry */
  Delete__Accounting_Entry?: Maybe<DbActionStatus>;
  /** Delete an Accounting_ServiceDiscount */
  Delete__Accounting_ServiceDiscount?: Maybe<DbActionStatus>;
  /** Delete an Accounting_SoftwareDiscount */
  Delete__Accounting_SoftwareDiscount?: Maybe<DbActionStatus>;
  /** Delete an Accounting_Transaction */
  Delete__Accounting_Transaction?: Maybe<DbActionStatus>;
  /** Delete an Address_Address */
  Delete__Address_Address?: Maybe<DbActionStatus>;
  /** Delete an Breakdown_BreakDown */
  Delete__Breakdown_BreakDown?: Maybe<DbActionStatus>;
  /** Delete an Breakdown_BreakDownItems */
  Delete__Breakdown_BreakDownItems?: Maybe<DbActionStatus>;
  /** Delete an BuildUp_BuildUp */
  Delete__BuildUp_BuildUp?: Maybe<DbActionStatus>;
  /** Delete an BuildUp_BuildUpItems */
  Delete__BuildUp_BuildUpItems?: Maybe<DbActionStatus>;
  /** Delete an Clients_Client */
  Delete__Clients_Client?: Maybe<DbActionStatus>;
  /** Delete an Clients_Contact */
  Delete__Clients_Contact?: Maybe<DbActionStatus>;
  /** Delete an Clients_ShippingLabel */
  Delete__Clients_ShippingLabel?: Maybe<DbActionStatus>;
  /** Delete an Clients_Subs */
  Delete__Clients_Subs?: Maybe<DbActionStatus>;
  /** Delete an Clients_Terms */
  Delete__Clients_Terms?: Maybe<DbActionStatus>;
  /** Delete an Company_CreditCard */
  Delete__Company_CreditCard?: Maybe<DbActionStatus>;
  /** Delete an Company_Subs */
  Delete__Company_Subs?: Maybe<DbActionStatus>;
  /** Delete an CustomPermissions_Role */
  Delete__CustomPermissions_Role?: Maybe<DbActionStatus>;
  /** Delete an CustomPermissions_RolePermissions */
  Delete__CustomPermissions_RolePermissions?: Maybe<DbActionStatus>;
  /** Delete an Department_Department */
  Delete__Department_Department?: Maybe<DbActionStatus>;
  /** Delete an Department_DepartmentEmployees */
  Delete__Department_DepartmentEmployees?: Maybe<DbActionStatus>;
  /** Delete an Department_DepartmentRoles */
  Delete__Department_DepartmentRoles?: Maybe<DbActionStatus>;
  /** Delete an Inventory_Item */
  Delete__Inventory_Item?: Maybe<DbActionStatus>;
  /** Delete an Inventory_Service */
  Delete__Inventory_Service?: Maybe<DbActionStatus>;
  /** Delete an Inventory_Software */
  Delete__Inventory_Software?: Maybe<DbActionStatus>;
  /** Delete an Inventory_System */
  Delete__Inventory_System?: Maybe<DbActionStatus>;
  /** Delete an Invoices_APInvoice */
  Delete__Invoices_APInvoice?: Maybe<DbActionStatus>;
  /** Delete an Invoices_APPayment */
  Delete__Invoices_APPayment?: Maybe<DbActionStatus>;
  /** Delete an Invoices_ARInvoice */
  Delete__Invoices_ARInvoice?: Maybe<DbActionStatus>;
  /** Delete an Invoices_ARPayment */
  Delete__Invoices_ARPayment?: Maybe<DbActionStatus>;
  /** Delete an Location_Location */
  Delete__Location_Location?: Maybe<DbActionStatus>;
  /** Delete an Location_Site */
  Delete__Location_Site?: Maybe<DbActionStatus>;
  /** Delete an Logging_ActionLog */
  Delete__Logging_ActionLog?: Maybe<DbActionStatus>;
  /** Delete an Notifications_Notification */
  Delete__Notifications_Notification?: Maybe<DbActionStatus>;
  /** Delete an Parts_AccessPoint */
  Delete__Parts_AccessPoint?: Maybe<DbActionStatus>;
  /** Delete an Parts_AssociatedPN */
  Delete__Parts_AssociatedPN?: Maybe<DbActionStatus>;
  /** Delete an Parts_BOM */
  Delete__Parts_BOM?: Maybe<DbActionStatus>;
  /** Delete an Parts_BOMParts */
  Delete__Parts_BOMParts?: Maybe<DbActionStatus>;
  /** Delete an Parts_Battery */
  Delete__Parts_Battery?: Maybe<DbActionStatus>;
  /** Delete an Parts_Bezel */
  Delete__Parts_Bezel?: Maybe<DbActionStatus>;
  /** Delete an Parts_Bracket */
  Delete__Parts_Bracket?: Maybe<DbActionStatus>;
  /** Delete an Parts_CPU */
  Delete__Parts_CPU?: Maybe<DbActionStatus>;
  /** Delete an Parts_Cable */
  Delete__Parts_Cable?: Maybe<DbActionStatus>;
  /** Delete an Parts_Cache */
  Delete__Parts_Cache?: Maybe<DbActionStatus>;
  /** Delete an Parts_DiskDrive */
  Delete__Parts_DiskDrive?: Maybe<DbActionStatus>;
  /** Delete an Parts_ExpansionCard */
  Delete__Parts_ExpansionCard?: Maybe<DbActionStatus>;
  /** Delete an Parts_Fan */
  Delete__Parts_Fan?: Maybe<DbActionStatus>;
  /** Delete an Parts_GPU */
  Delete__Parts_GPU?: Maybe<DbActionStatus>;
  /** Delete an Parts_Heatsink */
  Delete__Parts_Heatsink?: Maybe<DbActionStatus>;
  /** Delete an Parts_IOModule */
  Delete__Parts_IOModule?: Maybe<DbActionStatus>;
  /** Delete an Parts_Memory */
  Delete__Parts_Memory?: Maybe<DbActionStatus>;
  /** Delete an Parts_Motherboard */
  Delete__Parts_Motherboard?: Maybe<DbActionStatus>;
  /** Delete an Parts_NetworkCard */
  Delete__Parts_NetworkCard?: Maybe<DbActionStatus>;
  /** Delete an Parts_Part */
  Delete__Parts_Part?: Maybe<DbActionStatus>;
  /** Delete an Parts_Phone */
  Delete__Parts_Phone?: Maybe<DbActionStatus>;
  /** Delete an Parts_PowerSupply */
  Delete__Parts_PowerSupply?: Maybe<DbActionStatus>;
  /** Delete an Parts_ProductCompany */
  Delete__Parts_ProductCompany?: Maybe<DbActionStatus>;
  /** Delete an Parts_Rails */
  Delete__Parts_Rails?: Maybe<DbActionStatus>;
  /** Delete an Parts_Router */
  Delete__Parts_Router?: Maybe<DbActionStatus>;
  /** Delete an Parts_Server */
  Delete__Parts_Server?: Maybe<DbActionStatus>;
  /** Delete an Parts_Storage */
  Delete__Parts_Storage?: Maybe<DbActionStatus>;
  /** Delete an Parts_StorageEnclosure */
  Delete__Parts_StorageEnclosure?: Maybe<DbActionStatus>;
  /** Delete an Parts_Supplies */
  Delete__Parts_Supplies?: Maybe<DbActionStatus>;
  /** Delete an Parts_Switch */
  Delete__Parts_Switch?: Maybe<DbActionStatus>;
  /** Delete an Profile_LiveTemplate */
  Delete__Profile_LiveTemplate?: Maybe<DbActionStatus>;
  /** Delete an Profile_Profile */
  Delete__Profile_Profile?: Maybe<DbActionStatus>;
  /** Delete an Profile_ProfileMetrics */
  Delete__Profile_ProfileMetrics?: Maybe<DbActionStatus>;
  /** Delete an Profile_TableConfig */
  Delete__Profile_TableConfig?: Maybe<DbActionStatus>;
  /** Delete an Purchases_Purchase */
  Delete__Purchases_Purchase?: Maybe<DbActionStatus>;
  /** Delete an Purchases_PurchasedItems */
  Delete__Purchases_PurchasedItems?: Maybe<DbActionStatus>;
  /** Delete an Quotes_Quote */
  Delete__Quotes_Quote?: Maybe<DbActionStatus>;
  /** Delete an Quotes_QuoteParts */
  Delete__Quotes_QuoteParts?: Maybe<DbActionStatus>;
  /** Delete an RMA_PurchaseRMA */
  Delete__RMA_PurchaseRMA?: Maybe<DbActionStatus>;
  /** Delete an RMA_PurchaseRMAItems */
  Delete__RMA_PurchaseRMAItems?: Maybe<DbActionStatus>;
  /** Delete an RMA_RMA */
  Delete__RMA_RMA?: Maybe<DbActionStatus>;
  /** Delete an RMA_RMAItems */
  Delete__RMA_RMAItems?: Maybe<DbActionStatus>;
  /** Delete an Reporting_Report */
  Delete__Reporting_Report?: Maybe<DbActionStatus>;
  /** Delete an Sales_Fees */
  Delete__Sales_Fees?: Maybe<DbActionStatus>;
  /** Delete an Sales_Sale */
  Delete__Sales_Sale?: Maybe<DbActionStatus>;
  /** Delete an Sales_SoldItems */
  Delete__Sales_SoldItems?: Maybe<DbActionStatus>;
  /** Delete an Sales_SoldServices */
  Delete__Sales_SoldServices?: Maybe<DbActionStatus>;
  /** Delete an Sales_SoldSoftware */
  Delete__Sales_SoldSoftware?: Maybe<DbActionStatus>;
  /** Delete an Shipping_Account */
  Delete__Shipping_Account?: Maybe<DbActionStatus>;
  /** Delete an Shipping_Carrier */
  Delete__Shipping_Carrier?: Maybe<DbActionStatus>;
  /** Delete an Shipping_PredefinedParcel */
  Delete__Shipping_PredefinedParcel?: Maybe<DbActionStatus>;
  /** Delete an Shipping_ServiceLevel */
  Delete__Shipping_ServiceLevel?: Maybe<DbActionStatus>;
  /** Delete an Shipping_Shipment */
  Delete__Shipping_Shipment?: Maybe<DbActionStatus>;
  /** Delete an Shipping_ShipmentOrder */
  Delete__Shipping_ShipmentOrder?: Maybe<DbActionStatus>;
  /** Delete an Shipping_ShipmentSupplies */
  Delete__Shipping_ShipmentSupplies?: Maybe<DbActionStatus>;
  /** Delete an Team_Team */
  Delete__Team_Team?: Maybe<DbActionStatus>;
  /** Delete an Team_TeamMembers */
  Delete__Team_TeamMembers?: Maybe<DbActionStatus>;
  /** Delete an Timer_BaseTimer */
  Delete__Timer_BaseTimer?: Maybe<DbActionStatus>;
  /** Delete an WorkOrder_WorkOrder */
  Delete__WorkOrder_WorkOrder?: Maybe<DbActionStatus>;
  /** Delete an WorkOrder_WorkOrderItems */
  Delete__WorkOrder_WorkOrderItems?: Maybe<DbActionStatus>;
  /** Buys an EasyPost Order */
  EasyPost_BuyOrder?: Maybe<EasyPostOrder>;
  /** Purchases a Shipment with EasyPost */
  EasyPost_BuyShipment?: Maybe<EasyPostShipment>;
  /** Insures an EasyPost Shipment. 1% of the value with a minimum of $1. */
  EasyPost_InsureShipment?: Maybe<EasyPostShipment>;
  /** Make an Address with easypost (This is how you verify an address) */
  EasyPost_MakeAddress?: Maybe<EasyPostAddress>;
  /** Makes an EasyPost CarrierAccount */
  EasyPost_MakeCarrierAccount?: Maybe<EasyPostCarrierAccount>;
  /** Makes a CustomsInfo object */
  EasyPost_MakeCustomsInfo?: Maybe<EasyPostCustomsInfo>;
  /** Makes an EasyPost Order */
  EasyPost_MakeOrder?: Maybe<EasyPostOrder>;
  /** Makes a Parcel with EasyPost */
  EasyPost_MakeParcel?: Maybe<EasyPostParcel>;
  /** Make a Shipment with EasyPost */
  EasyPost_MakeShipment?: Maybe<EasyPostShipment>;
  /** Refunds an EasyPost Shipment */
  EasyPost_RefundShipment?: Maybe<EasyPostShipment>;
  /** Registers a FedEx account with EasyPost */
  EasyPost_RegisterFedExAccount?: Maybe<Scalars['Boolean']>;
  /** Registers a UPS account with EasyPost */
  EasyPost_RegisterUPSAccount?: Maybe<Scalars['Boolean']>;
  /** Creates a PrintNode PrintJob */
  PrintNode_CreatePrintJob?: Maybe<Scalars['ID']>;
  /** Removes a PrintNode Computer */
  PrintNode_RemoveComputers?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Removes a PrintNode PrintJob */
  PrintNode_RemovePrintJob?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Removes a PrintNode Printer */
  PrintNode_RemovePrinters?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Sets is_owner on User */
  SetOwner?: Maybe<Users_User>;
  /** Unapprove a Purchase */
  UnapprovePurchase?: Maybe<Purchases_Purchase>;
  /** Update an Items add_on_items */
  UpdateItemAddOnItems?: Maybe<Inventory_Item>;
  /** Update an Items test_status */
  UpdateItemTestStatus?: Maybe<Inventory_Item>;
  /** Update a Systems add_on_items */
  UpdateSystemAddOnItems?: Maybe<Inventory_System>;
  /** Update a Systems test_status */
  UpdateSystemTestStatus?: Maybe<Inventory_System>;
  /** Update any User */
  UpdateUserAny?: Maybe<Users_User>;
  /** Update own User */
  UpdateUserSelf?: Maybe<Users_User>;
  /** Update a Accounting_Account */
  Update__Accounting_Account?: Maybe<Accounting_Account>;
  /** Update a Accounting_Entry */
  Update__Accounting_Entry?: Maybe<Accounting_Entry>;
  /** Update a Accounting_ServiceDiscount */
  Update__Accounting_ServiceDiscount?: Maybe<Accounting_ServiceDiscount>;
  /** Update a Accounting_SoftwareDiscount */
  Update__Accounting_SoftwareDiscount?: Maybe<Accounting_SoftwareDiscount>;
  /** Update a Accounting_Transaction */
  Update__Accounting_Transaction?: Maybe<Accounting_Transaction>;
  /** Update a Address_Address */
  Update__Address_Address?: Maybe<Address_Address>;
  /** Update a Breakdown_BreakDown */
  Update__Breakdown_BreakDown?: Maybe<Breakdown_BreakDown>;
  /** Update a Breakdown_BreakDownItems */
  Update__Breakdown_BreakDownItems?: Maybe<Array<Maybe<Breakdown_BreakDownItems>>>;
  /** Update a BuildUp_BuildUp */
  Update__BuildUp_BuildUp?: Maybe<BuildUp_BuildUp>;
  /** Update a BuildUp_BuildUpItems */
  Update__BuildUp_BuildUpItems?: Maybe<Array<Maybe<BuildUp_BuildUpItems>>>;
  /** Update a Clients_Client */
  Update__Clients_Client?: Maybe<Clients_Client>;
  /** Update a Clients_Contact */
  Update__Clients_Contact?: Maybe<Clients_Contact>;
  /** Update a Clients_ShippingLabel */
  Update__Clients_ShippingLabel?: Maybe<Clients_ShippingLabel>;
  /** Update a Clients_Subs */
  Update__Clients_Subs?: Maybe<Clients_Subs>;
  /** Update a Clients_Terms */
  Update__Clients_Terms?: Maybe<Clients_Terms>;
  /** Update a Company_Company */
  Update__Company_Company?: Maybe<Company_Company>;
  /** Update a Company_CreditCard */
  Update__Company_CreditCard?: Maybe<Company_CreditCard>;
  /** Update a Company_Subs */
  Update__Company_Subs?: Maybe<Company_Subs>;
  /** Update a CustomPermissions_Role */
  Update__CustomPermissions_Role?: Maybe<CustomPermissions_Role>;
  /** Update a CustomPermissions_RolePermissions */
  Update__CustomPermissions_RolePermissions?: Maybe<CustomPermissions_RolePermissions>;
  /** Update a Department_Department */
  Update__Department_Department?: Maybe<Department_Department>;
  /** Update a Department_DepartmentEmployees */
  Update__Department_DepartmentEmployees?: Maybe<Department_DepartmentEmployees>;
  /** Update a Department_DepartmentRoles */
  Update__Department_DepartmentRoles?: Maybe<Department_DepartmentRoles>;
  /** Update a Inventory_Item */
  Update__Inventory_Item?: Maybe<Array<Maybe<Inventory_Item>>>;
  /** Update a Inventory_Service */
  Update__Inventory_Service?: Maybe<Inventory_Service>;
  /** Update a Inventory_Software */
  Update__Inventory_Software?: Maybe<Inventory_Software>;
  /** Update a Inventory_System */
  Update__Inventory_System?: Maybe<Inventory_System>;
  /** Update a Invoices_APInvoice */
  Update__Invoices_APInvoice?: Maybe<Invoices_ApInvoice>;
  /** Update a Invoices_APPayment */
  Update__Invoices_APPayment?: Maybe<Invoices_ApPayment>;
  /** Update a Invoices_ARInvoice */
  Update__Invoices_ARInvoice?: Maybe<Invoices_ArInvoice>;
  /** Update a Invoices_ARPayment */
  Update__Invoices_ARPayment?: Maybe<Invoices_ArPayment>;
  /** Update a Location_Location */
  Update__Location_Location?: Maybe<Location_Location>;
  /** Update a Location_Site */
  Update__Location_Site?: Maybe<Location_Site>;
  /** Update a Logging_ActionLog */
  Update__Logging_ActionLog?: Maybe<Logging_ActionLog>;
  /** Update a Notifications_Notification */
  Update__Notifications_Notification?: Maybe<Notifications_Notification>;
  /** Update a Parts_AccessPoint */
  Update__Parts_AccessPoint?: Maybe<Parts_AccessPoint>;
  /** Update a Parts_AssociatedPN */
  Update__Parts_AssociatedPN?: Maybe<Parts_AssociatedPn>;
  /** Update a Parts_BOM */
  Update__Parts_BOM?: Maybe<Parts_Bom>;
  /** Update a Parts_BOMParts */
  Update__Parts_BOMParts?: Maybe<Parts_BomParts>;
  /** Update a Parts_Battery */
  Update__Parts_Battery?: Maybe<Parts_Battery>;
  /** Update a Parts_Bezel */
  Update__Parts_Bezel?: Maybe<Parts_Bezel>;
  /** Update a Parts_Bracket */
  Update__Parts_Bracket?: Maybe<Parts_Bracket>;
  /** Update a Parts_CPU */
  Update__Parts_CPU?: Maybe<Parts_Cpu>;
  /** Update a Parts_Cable */
  Update__Parts_Cable?: Maybe<Parts_Cable>;
  /** Update a Parts_Cache */
  Update__Parts_Cache?: Maybe<Parts_Cache>;
  /** Update a Parts_DiskDrive */
  Update__Parts_DiskDrive?: Maybe<Parts_DiskDrive>;
  /** Update a Parts_ExpansionCard */
  Update__Parts_ExpansionCard?: Maybe<Parts_ExpansionCard>;
  /** Update a Parts_Fan */
  Update__Parts_Fan?: Maybe<Parts_Fan>;
  /** Update a Parts_GPU */
  Update__Parts_GPU?: Maybe<Parts_Gpu>;
  /** Update a Parts_Heatsink */
  Update__Parts_Heatsink?: Maybe<Parts_Heatsink>;
  /** Update a Parts_IOModule */
  Update__Parts_IOModule?: Maybe<Parts_IoModule>;
  /** Update a Parts_Memory */
  Update__Parts_Memory?: Maybe<Parts_Memory>;
  /** Update a Parts_Motherboard */
  Update__Parts_Motherboard?: Maybe<Parts_Motherboard>;
  /** Update a Parts_NetworkCard */
  Update__Parts_NetworkCard?: Maybe<Parts_NetworkCard>;
  /** Update a Parts_Part */
  Update__Parts_Part?: Maybe<Parts_Part>;
  /** Update a Parts_Phone */
  Update__Parts_Phone?: Maybe<Parts_Phone>;
  /** Update a Parts_PowerSupply */
  Update__Parts_PowerSupply?: Maybe<Parts_PowerSupply>;
  /** Update a Parts_ProductCompany */
  Update__Parts_ProductCompany?: Maybe<Parts_ProductCompany>;
  /** Update a Parts_Rails */
  Update__Parts_Rails?: Maybe<Parts_Rails>;
  /** Update a Parts_Router */
  Update__Parts_Router?: Maybe<Parts_Router>;
  /** Update a Parts_Server */
  Update__Parts_Server?: Maybe<Parts_Server>;
  /** Update a Parts_Storage */
  Update__Parts_Storage?: Maybe<Parts_Storage>;
  /** Update a Parts_StorageEnclosure */
  Update__Parts_StorageEnclosure?: Maybe<Parts_StorageEnclosure>;
  /** Update a Parts_Supplies */
  Update__Parts_Supplies?: Maybe<Parts_Supplies>;
  /** Update a Parts_Switch */
  Update__Parts_Switch?: Maybe<Parts_Switch>;
  /** Update a Profile_LiveTemplate */
  Update__Profile_LiveTemplate?: Maybe<Profile_LiveTemplate>;
  /** Update a Profile_Profile */
  Update__Profile_Profile?: Maybe<Profile_Profile>;
  /** Update a Profile_ProfileMetrics */
  Update__Profile_ProfileMetrics?: Maybe<Profile_ProfileMetrics>;
  /** Update a Profile_Profile */
  Update__Profile_Profile_Self?: Maybe<Profile_Profile>;
  /** Update a Profile_TableConfig */
  Update__Profile_TableConfig?: Maybe<Profile_TableConfig>;
  /** Update a Purchases_Purchase */
  Update__Purchases_Purchase?: Maybe<Purchases_Purchase>;
  /** Update a Purchases_PurchasedItems */
  Update__Purchases_PurchasedItems?: Maybe<Array<Maybe<Purchases_PurchasedItems>>>;
  /** Update a Quotes_Quote */
  Update__Quotes_Quote?: Maybe<Quotes_Quote>;
  /** Update a Quotes_QuoteParts */
  Update__Quotes_QuoteParts?: Maybe<Array<Maybe<Quotes_QuoteParts>>>;
  /** Update a RMA_PurchaseRMA */
  Update__RMA_PurchaseRMA?: Maybe<Rma_PurchaseRma>;
  /** Update a RMA_PurchaseRMAItems */
  Update__RMA_PurchaseRMAItems?: Maybe<Array<Maybe<Rma_PurchaseRmaItems>>>;
  /** Update a RMA_RMA */
  Update__RMA_RMA?: Maybe<Rma_Rma>;
  /** Update a RMA_RMAItems */
  Update__RMA_RMAItems?: Maybe<Array<Maybe<Rma_RmaItems>>>;
  /** Update a Reporting_Report */
  Update__Reporting_Report?: Maybe<Reporting_Report>;
  /** Update a Sales_Fees */
  Update__Sales_Fees?: Maybe<Sales_Fees>;
  /** Update a Sales_Sale */
  Update__Sales_Sale?: Maybe<Sales_Sale>;
  /** Update a Sales_SoldItems */
  Update__Sales_SoldItems?: Maybe<Array<Maybe<Sales_SoldItems>>>;
  /** Update a Sales_SoldServices */
  Update__Sales_SoldServices?: Maybe<Sales_SoldServices>;
  /** Update a Sales_SoldSoftware */
  Update__Sales_SoldSoftware?: Maybe<Sales_SoldSoftware>;
  /** Update a Shipping_Account */
  Update__Shipping_Account?: Maybe<Shipping_Account>;
  /** Update a Shipping_Carrier */
  Update__Shipping_Carrier?: Maybe<Shipping_Carrier>;
  /** Update a Shipping_PredefinedParcel */
  Update__Shipping_PredefinedParcel?: Maybe<Shipping_PredefinedParcel>;
  /** Update a Shipping_ServiceLevel */
  Update__Shipping_ServiceLevel?: Maybe<Shipping_ServiceLevel>;
  /** Update a Shipping_Shipment */
  Update__Shipping_Shipment?: Maybe<Shipping_Shipment>;
  /** Update a Shipping_ShipmentOrder */
  Update__Shipping_ShipmentOrder?: Maybe<Shipping_ShipmentOrder>;
  /** Update a Shipping_ShipmentSupplies */
  Update__Shipping_ShipmentSupplies?: Maybe<Shipping_ShipmentSupplies>;
  /** Update a Team_Team */
  Update__Team_Team?: Maybe<Team_Team>;
  /** Update a Team_TeamMembers */
  Update__Team_TeamMembers?: Maybe<Team_TeamMembers>;
  /** Update a Timer_BaseTimer */
  Update__Timer_BaseTimer?: Maybe<Timer_BaseTimer>;
  /** Update a WorkOrder_WorkOrder */
  Update__WorkOrder_WorkOrder?: Maybe<WorkOrder_WorkOrder>;
  /** Update a WorkOrder_WorkOrderItems */
  Update__WorkOrder_WorkOrderItems?: Maybe<Array<Maybe<WorkOrder_WorkOrderItems>>>;
  placeholder?: Maybe<Scalars['String']>;
};


export type MutationApprovePurchaseArgs = {
  id: Scalars['ID'];
};


export type MutationCreate__Accounting_AccountArgs = {
  input: Create__Accounting_Account__Input;
};


export type MutationCreate__Accounting_EntryArgs = {
  input: Create__Accounting_Entry__Input;
};


export type MutationCreate__Accounting_ServiceDiscountArgs = {
  input: Create__Accounting_ServiceDiscount__Input;
};


export type MutationCreate__Accounting_SoftwareDiscountArgs = {
  input: Create__Accounting_SoftwareDiscount__Input;
};


export type MutationCreate__Accounting_TransactionArgs = {
  input: Create__Accounting_Transaction__Input;
};


export type MutationCreate__Address_AddressArgs = {
  input: Create__Address_Address__Input;
};


export type MutationCreate__Breakdown_BreakDownArgs = {
  input: Create__Breakdown_BreakDown__Input;
};


export type MutationCreate__Breakdown_BreakDownItemsArgs = {
  input: Array<Create__Breakdown_BreakDownItems__Input>;
};


export type MutationCreate__BuildUp_BuildUpArgs = {
  input: Create__BuildUp_BuildUp__Input;
};


export type MutationCreate__BuildUp_BuildUpItemsArgs = {
  input: Array<Create__BuildUp_BuildUpItems__Input>;
};


export type MutationCreate__Clients_ClientArgs = {
  input: Create__Clients_Client__Input;
};


export type MutationCreate__Clients_ContactArgs = {
  input: Create__Clients_Contact__Input;
};


export type MutationCreate__Clients_ShippingLabelArgs = {
  input: Create__Clients_ShippingLabel__Input;
};


export type MutationCreate__Clients_SubsArgs = {
  input: Create__Clients_Subs__Input;
};


export type MutationCreate__Clients_TermsArgs = {
  input: Create__Clients_Terms__Input;
};


export type MutationCreate__Company_CreditCardArgs = {
  input: Create__Company_CreditCard__Input;
};


export type MutationCreate__Company_SubsArgs = {
  input: Create__Company_Subs__Input;
};


export type MutationCreate__CustomPermissions_RoleArgs = {
  input: Create__CustomPermissions_Role__Input;
};


export type MutationCreate__CustomPermissions_RolePermissionsArgs = {
  input: Create__CustomPermissions_RolePermissions__Input;
};


export type MutationCreate__Department_DepartmentArgs = {
  input: Create__Department_Department__Input;
};


export type MutationCreate__Department_DepartmentEmployeesArgs = {
  input: Create__Department_DepartmentEmployees__Input;
};


export type MutationCreate__Department_DepartmentRolesArgs = {
  input: Create__Department_DepartmentRoles__Input;
};


export type MutationCreate__Inventory_ItemArgs = {
  input: Array<Create__Inventory_Item__Input>;
};


export type MutationCreate__Inventory_ServiceArgs = {
  input: Create__Inventory_Service__Input;
};


export type MutationCreate__Inventory_SoftwareArgs = {
  input: Create__Inventory_Software__Input;
};


export type MutationCreate__Inventory_SystemArgs = {
  input: Create__Inventory_System__Input;
};


export type MutationCreate__Invoices_ApInvoiceArgs = {
  input: Create__Invoices_ApInvoice__Input;
};


export type MutationCreate__Invoices_ApPaymentArgs = {
  input: Create__Invoices_ApPayment__Input;
};


export type MutationCreate__Invoices_ArInvoiceArgs = {
  input: Create__Invoices_ArInvoice__Input;
};


export type MutationCreate__Invoices_ArPaymentArgs = {
  input: Create__Invoices_ArPayment__Input;
};


export type MutationCreate__Location_LocationArgs = {
  input: Create__Location_Location__Input;
};


export type MutationCreate__Location_SiteArgs = {
  input: Create__Location_Site__Input;
};


export type MutationCreate__Logging_ActionLogArgs = {
  input: Create__Logging_ActionLog__Input;
};


export type MutationCreate__Notifications_NotificationArgs = {
  input: Create__Notifications_Notification__Input;
};


export type MutationCreate__Parts_AccessPointArgs = {
  input: Create__Parts_AccessPoint__Input;
};


export type MutationCreate__Parts_AssociatedPnArgs = {
  input: Create__Parts_AssociatedPn__Input;
};


export type MutationCreate__Parts_BomArgs = {
  input: Create__Parts_Bom__Input;
};


export type MutationCreate__Parts_BomPartsArgs = {
  input: Create__Parts_BomParts__Input;
};


export type MutationCreate__Parts_BatteryArgs = {
  input: Create__Parts_Battery__Input;
};


export type MutationCreate__Parts_BezelArgs = {
  input: Create__Parts_Bezel__Input;
};


export type MutationCreate__Parts_BracketArgs = {
  input: Create__Parts_Bracket__Input;
};


export type MutationCreate__Parts_CpuArgs = {
  input: Create__Parts_Cpu__Input;
};


export type MutationCreate__Parts_CableArgs = {
  input: Create__Parts_Cable__Input;
};


export type MutationCreate__Parts_CacheArgs = {
  input: Create__Parts_Cache__Input;
};


export type MutationCreate__Parts_DiskDriveArgs = {
  input: Create__Parts_DiskDrive__Input;
};


export type MutationCreate__Parts_ExpansionCardArgs = {
  input: Create__Parts_ExpansionCard__Input;
};


export type MutationCreate__Parts_FanArgs = {
  input: Create__Parts_Fan__Input;
};


export type MutationCreate__Parts_GpuArgs = {
  input: Create__Parts_Gpu__Input;
};


export type MutationCreate__Parts_HeatsinkArgs = {
  input: Create__Parts_Heatsink__Input;
};


export type MutationCreate__Parts_IoModuleArgs = {
  input: Create__Parts_IoModule__Input;
};


export type MutationCreate__Parts_MemoryArgs = {
  input: Create__Parts_Memory__Input;
};


export type MutationCreate__Parts_MotherboardArgs = {
  input: Create__Parts_Motherboard__Input;
};


export type MutationCreate__Parts_NetworkCardArgs = {
  input: Create__Parts_NetworkCard__Input;
};


export type MutationCreate__Parts_PartArgs = {
  input: Create__Parts_Part__Input;
};


export type MutationCreate__Parts_PhoneArgs = {
  input: Create__Parts_Phone__Input;
};


export type MutationCreate__Parts_PowerSupplyArgs = {
  input: Create__Parts_PowerSupply__Input;
};


export type MutationCreate__Parts_ProductCompanyArgs = {
  input: Create__Parts_ProductCompany__Input;
};


export type MutationCreate__Parts_RailsArgs = {
  input: Create__Parts_Rails__Input;
};


export type MutationCreate__Parts_RouterArgs = {
  input: Create__Parts_Router__Input;
};


export type MutationCreate__Parts_ServerArgs = {
  input: Create__Parts_Server__Input;
};


export type MutationCreate__Parts_StorageArgs = {
  input: Create__Parts_Storage__Input;
};


export type MutationCreate__Parts_StorageEnclosureArgs = {
  input: Create__Parts_StorageEnclosure__Input;
};


export type MutationCreate__Parts_SuppliesArgs = {
  input: Create__Parts_Supplies__Input;
};


export type MutationCreate__Parts_SwitchArgs = {
  input: Create__Parts_Switch__Input;
};


export type MutationCreate__Profile_LiveTemplateArgs = {
  input: Create__Profile_LiveTemplate__Input;
};


export type MutationCreate__Profile_ProfileArgs = {
  input: Create__Profile_Profile__Input;
};


export type MutationCreate__Profile_ProfileMetricsArgs = {
  input: Create__Profile_ProfileMetrics__Input;
};


export type MutationCreate__Profile_TableConfigArgs = {
  input: Create__Profile_TableConfig__Input;
};


export type MutationCreate__Purchases_PurchaseArgs = {
  input: Create__Purchases_Purchase__Input;
};


export type MutationCreate__Purchases_PurchasedItemsArgs = {
  input: Array<Create__Purchases_PurchasedItems__Input>;
};


export type MutationCreate__Quotes_QuoteArgs = {
  input: Create__Quotes_Quote__Input;
};


export type MutationCreate__Quotes_QuotePartsArgs = {
  input: Array<Create__Quotes_QuoteParts__Input>;
};


export type MutationCreate__Rma_PurchaseRmaArgs = {
  input: Create__Rma_PurchaseRma__Input;
};


export type MutationCreate__Rma_PurchaseRmaItemsArgs = {
  input: Array<Create__Rma_PurchaseRmaItems__Input>;
};


export type MutationCreate__Rma_RmaArgs = {
  input: Create__Rma_Rma__Input;
};


export type MutationCreate__Rma_RmaItemsArgs = {
  input: Array<Create__Rma_RmaItems__Input>;
};


export type MutationCreate__Reporting_ReportArgs = {
  input: Create__Reporting_Report__Input;
};


export type MutationCreate__Sales_FeesArgs = {
  input: Create__Sales_Fees__Input;
};


export type MutationCreate__Sales_SaleArgs = {
  input: Create__Sales_Sale__Input;
};


export type MutationCreate__Sales_SoldItemsArgs = {
  input: Array<Create__Sales_SoldItems__Input>;
};


export type MutationCreate__Sales_SoldServicesArgs = {
  input: Create__Sales_SoldServices__Input;
};


export type MutationCreate__Sales_SoldSoftwareArgs = {
  input: Create__Sales_SoldSoftware__Input;
};


export type MutationCreate__Shipping_AccountArgs = {
  input: Create__Shipping_Account__Input;
};


export type MutationCreate__Shipping_CarrierArgs = {
  input: Create__Shipping_Carrier__Input;
};


export type MutationCreate__Shipping_PredefinedParcelArgs = {
  input: Create__Shipping_PredefinedParcel__Input;
};


export type MutationCreate__Shipping_ServiceLevelArgs = {
  input: Create__Shipping_ServiceLevel__Input;
};


export type MutationCreate__Shipping_ShipmentArgs = {
  input: Create__Shipping_Shipment__Input;
};


export type MutationCreate__Shipping_ShipmentOrderArgs = {
  input: Create__Shipping_ShipmentOrder__Input;
};


export type MutationCreate__Shipping_ShipmentSuppliesArgs = {
  input: Create__Shipping_ShipmentSupplies__Input;
};


export type MutationCreate__Team_TeamArgs = {
  input: Create__Team_Team__Input;
};


export type MutationCreate__Team_TeamMembersArgs = {
  input: Create__Team_TeamMembers__Input;
};


export type MutationCreate__Timer_BaseTimerArgs = {
  input: Create__Timer_BaseTimer__Input;
};


export type MutationCreate__WorkOrder_WorkOrderArgs = {
  input: Create__WorkOrder_WorkOrder__Input;
};


export type MutationCreate__WorkOrder_WorkOrderItemsArgs = {
  input: Array<Create__WorkOrder_WorkOrderItems__Input>;
};


export type MutationDelete__Accounting_AccountArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Accounting_EntryArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Accounting_ServiceDiscountArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Accounting_SoftwareDiscountArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Accounting_TransactionArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Address_AddressArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Breakdown_BreakDownArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Breakdown_BreakDownItemsArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__BuildUp_BuildUpArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__BuildUp_BuildUpItemsArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Clients_ClientArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Clients_ContactArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Clients_ShippingLabelArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Clients_SubsArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Clients_TermsArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Company_CreditCardArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Company_SubsArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__CustomPermissions_RoleArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__CustomPermissions_RolePermissionsArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Department_DepartmentArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Department_DepartmentEmployeesArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Department_DepartmentRolesArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Inventory_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Inventory_ServiceArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Inventory_SoftwareArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Inventory_SystemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Invoices_ApInvoiceArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Invoices_ApPaymentArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Invoices_ArInvoiceArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Invoices_ArPaymentArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Location_LocationArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Location_SiteArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Logging_ActionLogArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Notifications_NotificationArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_AccessPointArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_AssociatedPnArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_BomArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_BomPartsArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_BatteryArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_BezelArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_BracketArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_CpuArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_CableArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_CacheArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_DiskDriveArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_ExpansionCardArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_FanArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_GpuArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_HeatsinkArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_IoModuleArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_MemoryArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_MotherboardArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_NetworkCardArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_PartArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_PhoneArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_PowerSupplyArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_ProductCompanyArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_RailsArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_RouterArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_ServerArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_StorageArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_StorageEnclosureArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_SuppliesArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Parts_SwitchArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Profile_LiveTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Profile_ProfileArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Profile_ProfileMetricsArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Profile_TableConfigArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Purchases_PurchaseArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Purchases_PurchasedItemsArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Quotes_QuoteArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Quotes_QuotePartsArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Rma_PurchaseRmaArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Rma_PurchaseRmaItemsArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Rma_RmaArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Rma_RmaItemsArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Reporting_ReportArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Sales_FeesArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Sales_SaleArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Sales_SoldItemsArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Sales_SoldServicesArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Sales_SoldSoftwareArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Shipping_AccountArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Shipping_CarrierArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Shipping_PredefinedParcelArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Shipping_ServiceLevelArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Shipping_ShipmentArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Shipping_ShipmentOrderArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Shipping_ShipmentSuppliesArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Team_TeamArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Team_TeamMembersArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__Timer_BaseTimerArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__WorkOrder_WorkOrderArgs = {
  id: Scalars['ID'];
};


export type MutationDelete__WorkOrder_WorkOrderItemsArgs = {
  id: Scalars['ID'];
};


export type MutationEasyPost_BuyOrderArgs = {
  carrier: Scalars['String'];
  id: Scalars['EasyPostID'];
  service: Scalars['String'];
};


export type MutationEasyPost_BuyShipmentArgs = {
  id: Scalars['EasyPostID'];
  insurance?: Maybe<Scalars['Price']>;
  rate?: Maybe<Scalars['EasyPostID']>;
};


export type MutationEasyPost_InsureShipmentArgs = {
  amount: Scalars['Float'];
  id: Scalars['EasyPostID'];
};


export type MutationEasyPost_MakeAddressArgs = {
  input: EasyPost_MakeAddressInput;
};


export type MutationEasyPost_MakeCarrierAccountArgs = {
  input: EasyPost_MakeCarrierAccountInput;
};


export type MutationEasyPost_MakeCustomsInfoArgs = {
  input: EasyPost_CustomsInfoInput;
};


export type MutationEasyPost_MakeOrderArgs = {
  input: EasyPost_OrderInput;
};


export type MutationEasyPost_MakeParcelArgs = {
  input: EasyPost_MakeParcelInput;
};


export type MutationEasyPost_MakeShipmentArgs = {
  input: EasyPost_MakeShipmentInput;
};


export type MutationEasyPost_RefundShipmentArgs = {
  id: Scalars['EasyPostID'];
};


export type MutationEasyPost_RegisterFedExAccountArgs = {
  input: EasyPost_RegisterFedexAccountInput;
};


export type MutationEasyPost_RegisterUpsAccountArgs = {
  input: EasyPost_RegisterUpsAccountInput;
};


export type MutationPrintNode_CreatePrintJobArgs = {
  input: CreatePrintJobInput;
  options?: Maybe<PrintNodeOptionsInput>;
};


export type MutationPrintNode_RemoveComputersArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationPrintNode_RemovePrintJobArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationPrintNode_RemovePrintersArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationSetOwnerArgs = {
  id: Scalars['ID'];
  is_owner: Scalars['Boolean'];
};


export type MutationUnapprovePurchaseArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateItemAddOnItemsArgs = {
  input: M2MChangeInput;
};


export type MutationUpdateItemTestStatusArgs = {
  input: M2MChangeInput;
};


export type MutationUpdateSystemAddOnItemsArgs = {
  input: M2MChangeInput;
};


export type MutationUpdateSystemTestStatusArgs = {
  input: M2MChangeInput;
};


export type MutationUpdateUserAnyArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserSelfArgs = {
  input: UpdateUserSelfInput;
};


export type MutationUpdate__Accounting_AccountArgs = {
  input: Update__Accounting_Account__Input;
};


export type MutationUpdate__Accounting_EntryArgs = {
  input: Update__Accounting_Entry__Input;
};


export type MutationUpdate__Accounting_ServiceDiscountArgs = {
  input: Update__Accounting_ServiceDiscount__Input;
};


export type MutationUpdate__Accounting_SoftwareDiscountArgs = {
  input: Update__Accounting_SoftwareDiscount__Input;
};


export type MutationUpdate__Accounting_TransactionArgs = {
  input: Update__Accounting_Transaction__Input;
};


export type MutationUpdate__Address_AddressArgs = {
  input: Update__Address_Address__Input;
};


export type MutationUpdate__Breakdown_BreakDownArgs = {
  input: Update__Breakdown_BreakDown__Input;
};


export type MutationUpdate__Breakdown_BreakDownItemsArgs = {
  input: Array<Update__Breakdown_BreakDownItems__Input>;
};


export type MutationUpdate__BuildUp_BuildUpArgs = {
  input: Update__BuildUp_BuildUp__Input;
};


export type MutationUpdate__BuildUp_BuildUpItemsArgs = {
  input: Array<Update__BuildUp_BuildUpItems__Input>;
};


export type MutationUpdate__Clients_ClientArgs = {
  input: Update__Clients_Client__Input;
};


export type MutationUpdate__Clients_ContactArgs = {
  input: Update__Clients_Contact__Input;
};


export type MutationUpdate__Clients_ShippingLabelArgs = {
  input: Update__Clients_ShippingLabel__Input;
};


export type MutationUpdate__Clients_SubsArgs = {
  input: Update__Clients_Subs__Input;
};


export type MutationUpdate__Clients_TermsArgs = {
  input: Update__Clients_Terms__Input;
};


export type MutationUpdate__Company_CompanyArgs = {
  input: Update__Company_Company__Input;
};


export type MutationUpdate__Company_CreditCardArgs = {
  input: Update__Company_CreditCard__Input;
};


export type MutationUpdate__Company_SubsArgs = {
  input: Update__Company_Subs__Input;
};


export type MutationUpdate__CustomPermissions_RoleArgs = {
  input: Update__CustomPermissions_Role__Input;
};


export type MutationUpdate__CustomPermissions_RolePermissionsArgs = {
  input: Update__CustomPermissions_RolePermissions__Input;
};


export type MutationUpdate__Department_DepartmentArgs = {
  input: Update__Department_Department__Input;
};


export type MutationUpdate__Department_DepartmentEmployeesArgs = {
  input: Update__Department_DepartmentEmployees__Input;
};


export type MutationUpdate__Department_DepartmentRolesArgs = {
  input: Update__Department_DepartmentRoles__Input;
};


export type MutationUpdate__Inventory_ItemArgs = {
  input: Array<Update__Inventory_Item__Input>;
};


export type MutationUpdate__Inventory_ServiceArgs = {
  input: Update__Inventory_Service__Input;
};


export type MutationUpdate__Inventory_SoftwareArgs = {
  input: Update__Inventory_Software__Input;
};


export type MutationUpdate__Inventory_SystemArgs = {
  input: Update__Inventory_System__Input;
};


export type MutationUpdate__Invoices_ApInvoiceArgs = {
  input: Update__Invoices_ApInvoice__Input;
};


export type MutationUpdate__Invoices_ApPaymentArgs = {
  input: Update__Invoices_ApPayment__Input;
};


export type MutationUpdate__Invoices_ArInvoiceArgs = {
  input: Update__Invoices_ArInvoice__Input;
};


export type MutationUpdate__Invoices_ArPaymentArgs = {
  input: Update__Invoices_ArPayment__Input;
};


export type MutationUpdate__Location_LocationArgs = {
  input: Update__Location_Location__Input;
};


export type MutationUpdate__Location_SiteArgs = {
  input: Update__Location_Site__Input;
};


export type MutationUpdate__Logging_ActionLogArgs = {
  input: Update__Logging_ActionLog__Input;
};


export type MutationUpdate__Notifications_NotificationArgs = {
  input: Update__Notifications_Notification__Input;
};


export type MutationUpdate__Parts_AccessPointArgs = {
  input: Update__Parts_AccessPoint__Input;
};


export type MutationUpdate__Parts_AssociatedPnArgs = {
  input: Update__Parts_AssociatedPn__Input;
};


export type MutationUpdate__Parts_BomArgs = {
  input: Update__Parts_Bom__Input;
};


export type MutationUpdate__Parts_BomPartsArgs = {
  input: Update__Parts_BomParts__Input;
};


export type MutationUpdate__Parts_BatteryArgs = {
  input: Update__Parts_Battery__Input;
};


export type MutationUpdate__Parts_BezelArgs = {
  input: Update__Parts_Bezel__Input;
};


export type MutationUpdate__Parts_BracketArgs = {
  input: Update__Parts_Bracket__Input;
};


export type MutationUpdate__Parts_CpuArgs = {
  input: Update__Parts_Cpu__Input;
};


export type MutationUpdate__Parts_CableArgs = {
  input: Update__Parts_Cable__Input;
};


export type MutationUpdate__Parts_CacheArgs = {
  input: Update__Parts_Cache__Input;
};


export type MutationUpdate__Parts_DiskDriveArgs = {
  input: Update__Parts_DiskDrive__Input;
};


export type MutationUpdate__Parts_ExpansionCardArgs = {
  input: Update__Parts_ExpansionCard__Input;
};


export type MutationUpdate__Parts_FanArgs = {
  input: Update__Parts_Fan__Input;
};


export type MutationUpdate__Parts_GpuArgs = {
  input: Update__Parts_Gpu__Input;
};


export type MutationUpdate__Parts_HeatsinkArgs = {
  input: Update__Parts_Heatsink__Input;
};


export type MutationUpdate__Parts_IoModuleArgs = {
  input: Update__Parts_IoModule__Input;
};


export type MutationUpdate__Parts_MemoryArgs = {
  input: Update__Parts_Memory__Input;
};


export type MutationUpdate__Parts_MotherboardArgs = {
  input: Update__Parts_Motherboard__Input;
};


export type MutationUpdate__Parts_NetworkCardArgs = {
  input: Update__Parts_NetworkCard__Input;
};


export type MutationUpdate__Parts_PartArgs = {
  input: Update__Parts_Part__Input;
};


export type MutationUpdate__Parts_PhoneArgs = {
  input: Update__Parts_Phone__Input;
};


export type MutationUpdate__Parts_PowerSupplyArgs = {
  input: Update__Parts_PowerSupply__Input;
};


export type MutationUpdate__Parts_ProductCompanyArgs = {
  input: Update__Parts_ProductCompany__Input;
};


export type MutationUpdate__Parts_RailsArgs = {
  input: Update__Parts_Rails__Input;
};


export type MutationUpdate__Parts_RouterArgs = {
  input: Update__Parts_Router__Input;
};


export type MutationUpdate__Parts_ServerArgs = {
  input: Update__Parts_Server__Input;
};


export type MutationUpdate__Parts_StorageArgs = {
  input: Update__Parts_Storage__Input;
};


export type MutationUpdate__Parts_StorageEnclosureArgs = {
  input: Update__Parts_StorageEnclosure__Input;
};


export type MutationUpdate__Parts_SuppliesArgs = {
  input: Update__Parts_Supplies__Input;
};


export type MutationUpdate__Parts_SwitchArgs = {
  input: Update__Parts_Switch__Input;
};


export type MutationUpdate__Profile_LiveTemplateArgs = {
  input: Update__Profile_LiveTemplate__Input;
};


export type MutationUpdate__Profile_ProfileArgs = {
  input: Update__Profile_Profile__Input;
};


export type MutationUpdate__Profile_ProfileMetricsArgs = {
  input: Update__Profile_ProfileMetrics__Input;
};


export type MutationUpdate__Profile_Profile_SelfArgs = {
  input: Update__Profile_Profile__Input;
};


export type MutationUpdate__Profile_TableConfigArgs = {
  input: Update__Profile_TableConfig__Input;
};


export type MutationUpdate__Purchases_PurchaseArgs = {
  input: Update__Purchases_Purchase__Input;
};


export type MutationUpdate__Purchases_PurchasedItemsArgs = {
  input: Array<Update__Purchases_PurchasedItems__Input>;
};


export type MutationUpdate__Quotes_QuoteArgs = {
  input: Update__Quotes_Quote__Input;
};


export type MutationUpdate__Quotes_QuotePartsArgs = {
  input: Array<Update__Quotes_QuoteParts__Input>;
};


export type MutationUpdate__Rma_PurchaseRmaArgs = {
  input: Update__Rma_PurchaseRma__Input;
};


export type MutationUpdate__Rma_PurchaseRmaItemsArgs = {
  input: Array<Update__Rma_PurchaseRmaItems__Input>;
};


export type MutationUpdate__Rma_RmaArgs = {
  input: Update__Rma_Rma__Input;
};


export type MutationUpdate__Rma_RmaItemsArgs = {
  input: Array<Update__Rma_RmaItems__Input>;
};


export type MutationUpdate__Reporting_ReportArgs = {
  input: Update__Reporting_Report__Input;
};


export type MutationUpdate__Sales_FeesArgs = {
  input: Update__Sales_Fees__Input;
};


export type MutationUpdate__Sales_SaleArgs = {
  input: Update__Sales_Sale__Input;
};


export type MutationUpdate__Sales_SoldItemsArgs = {
  input: Array<Update__Sales_SoldItems__Input>;
};


export type MutationUpdate__Sales_SoldServicesArgs = {
  input: Update__Sales_SoldServices__Input;
};


export type MutationUpdate__Sales_SoldSoftwareArgs = {
  input: Update__Sales_SoldSoftware__Input;
};


export type MutationUpdate__Shipping_AccountArgs = {
  input: Update__Shipping_Account__Input;
};


export type MutationUpdate__Shipping_CarrierArgs = {
  input: Update__Shipping_Carrier__Input;
};


export type MutationUpdate__Shipping_PredefinedParcelArgs = {
  input: Update__Shipping_PredefinedParcel__Input;
};


export type MutationUpdate__Shipping_ServiceLevelArgs = {
  input: Update__Shipping_ServiceLevel__Input;
};


export type MutationUpdate__Shipping_ShipmentArgs = {
  input: Update__Shipping_Shipment__Input;
};


export type MutationUpdate__Shipping_ShipmentOrderArgs = {
  input: Update__Shipping_ShipmentOrder__Input;
};


export type MutationUpdate__Shipping_ShipmentSuppliesArgs = {
  input: Update__Shipping_ShipmentSupplies__Input;
};


export type MutationUpdate__Team_TeamArgs = {
  input: Update__Team_Team__Input;
};


export type MutationUpdate__Team_TeamMembersArgs = {
  input: Update__Team_TeamMembers__Input;
};


export type MutationUpdate__Timer_BaseTimerArgs = {
  input: Update__Timer_BaseTimer__Input;
};


export type MutationUpdate__WorkOrder_WorkOrderArgs = {
  input: Update__WorkOrder_WorkOrder__Input;
};


export type MutationUpdate__WorkOrder_WorkOrderItemsArgs = {
  input: Array<Update__WorkOrder_WorkOrderItems__Input>;
};

/** This model is for Notifications. */
export type Notifications_Notification = {
  __typename?: 'Notifications_Notification';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  id: Scalars['ID'];
  message: Scalars['String'];
  time_stamp: Scalars['DateTime'];
  urgent: Scalars['Boolean'];
  user: Users_User;
};

/** This model inherits Parts.Part and holds AccessPoint data. */
export type Parts_AccessPoint = Parts_Part__Interface & {
  __typename?: 'Parts_AccessPoint';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  antennas: Scalars['PositiveInteger'];
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  description: Scalars['String'];
  ean: Scalars['String'];
  external_antennas: Scalars['Boolean'];
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  lan_ports: Scalars['PositiveInteger'];
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  max_throughput_mbps: Scalars['PositiveInteger'];
  mfgr?: Maybe<Parts_ProductCompany>;
  mounting_gear_included: Scalars['Boolean'];
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  poe_type?: Maybe<Choices_Choice>;
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  security?: Maybe<Choices_Choice>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
  wifi_standards?: Maybe<Choices_Choice>;
};


/** This model inherits Parts.Part and holds AccessPoint data. */
export type Parts_AccessPointAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds AccessPoint data. */
export type Parts_AccessPointAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds AccessPoint data. */
export type Parts_AccessPointAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds AccessPoint data. */
export type Parts_AccessPointAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds AccessPoint data. */
export type Parts_AccessPointBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds AccessPoint data. */
export type Parts_AccessPointBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds AccessPoint data. */
export type Parts_AccessPointInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds AccessPoint data. */
export type Parts_AccessPointInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds AccessPoint data. */
export type Parts_AccessPointPart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds AccessPoint data. */
export type Parts_AccessPointPart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds AccessPoint data. */
export type Parts_AccessPointPurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds AccessPoint data. */
export type Parts_AccessPointQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds AccessPoint data. */
export type Parts_AccessPointSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds AccessPoint data. */
export type Parts_AccessPointSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/**
 * Links base part numbers to associated part numbers for validating associated part
 * numbers.
 */
export type Parts_AssociatedPn = {
  __typename?: 'Parts_AssociatedPN';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associated_pn: Scalars['String'];
  attached_items?: Maybe<Array<Inventory_Item>>;
  base_pn: Parts_Part;
  id: Scalars['ID'];
};


/**
 * Links base part numbers to associated part numbers for validating associated part
 * numbers.
 */
export type Parts_AssociatedPnAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/**
 * The root for a BOM guide.
 * BOM is to make guides for building systems.
 */
export type Parts_Bom = {
  __typename?: 'Parts_BOM';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  bom_for: Parts_Part;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  description: Scalars['String'];
  id: Scalars['ID'];
  parts?: Maybe<Array<Parts_BomParts>>;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};


/**
 * The root for a BOM guide.
 * BOM is to make guides for building systems.
 */
export type Parts_BomPartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** The items table for part configs for BOMs */
export type Parts_BomParts = {
  __typename?: 'Parts_BOMParts';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  bom: Parts_Bom;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  id: Scalars['ID'];
  maximum_count: Scalars['PositiveInteger'];
  minimum_count: Scalars['PositiveInteger'];
  part: Parts_Part;
  part_type: Scalars['String'];
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

/** This model inherits Parts.Part and holds Battery data. */
export type Parts_Battery = Parts_Part__Interface & {
  __typename?: 'Parts_Battery';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  description: Scalars['String'];
  ean: Scalars['String'];
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds Battery data. */
export type Parts_BatteryAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Battery data. */
export type Parts_BatteryAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Battery data. */
export type Parts_BatteryAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Battery data. */
export type Parts_BatteryAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Battery data. */
export type Parts_BatteryBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Battery data. */
export type Parts_BatteryBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Battery data. */
export type Parts_BatteryInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Battery data. */
export type Parts_BatteryInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Battery data. */
export type Parts_BatteryPart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Battery data. */
export type Parts_BatteryPart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Battery data. */
export type Parts_BatteryPurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Battery data. */
export type Parts_BatteryQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Battery data. */
export type Parts_BatterySales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Battery data. */
export type Parts_BatterySubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds Bezel data. */
export type Parts_Bezel = Parts_Part__Interface & {
  __typename?: 'Parts_Bezel';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  description: Scalars['String'];
  ean: Scalars['String'];
  for_server?: Maybe<Parts_Server>;
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds Bezel data. */
export type Parts_BezelAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bezel data. */
export type Parts_BezelAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bezel data. */
export type Parts_BezelAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bezel data. */
export type Parts_BezelAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bezel data. */
export type Parts_BezelBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bezel data. */
export type Parts_BezelBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bezel data. */
export type Parts_BezelInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bezel data. */
export type Parts_BezelInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bezel data. */
export type Parts_BezelPart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bezel data. */
export type Parts_BezelPart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bezel data. */
export type Parts_BezelPurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bezel data. */
export type Parts_BezelQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bezel data. */
export type Parts_BezelSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bezel data. */
export type Parts_BezelSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds Bracket data. */
export type Parts_Bracket = Parts_Part__Interface & {
  __typename?: 'Parts_Bracket';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  description: Scalars['String'];
  ean: Scalars['String'];
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds Bracket data. */
export type Parts_BracketAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bracket data. */
export type Parts_BracketAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bracket data. */
export type Parts_BracketAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bracket data. */
export type Parts_BracketAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bracket data. */
export type Parts_BracketBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bracket data. */
export type Parts_BracketBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bracket data. */
export type Parts_BracketInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bracket data. */
export type Parts_BracketInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bracket data. */
export type Parts_BracketPart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bracket data. */
export type Parts_BracketPart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bracket data. */
export type Parts_BracketPurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bracket data. */
export type Parts_BracketQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bracket data. */
export type Parts_BracketSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Bracket data. */
export type Parts_BracketSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds CPU data. */
export type Parts_Cpu = Parts_Part__Interface & {
  __typename?: 'Parts_CPU';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  cores: Scalars['PositiveInteger'];
  description: Scalars['String'];
  ean: Scalars['String'];
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  hz?: Maybe<Choices_Choice>;
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  speed: Scalars['Float'];
  subbed_by?: Maybe<Array<Clients_Subs>>;
  threads: Scalars['PositiveInteger'];
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds CPU data. */
export type Parts_CpuAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds CPU data. */
export type Parts_CpuAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds CPU data. */
export type Parts_CpuAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds CPU data. */
export type Parts_CpuAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds CPU data. */
export type Parts_CpuBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds CPU data. */
export type Parts_CpuBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds CPU data. */
export type Parts_CpuInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds CPU data. */
export type Parts_CpuInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds CPU data. */
export type Parts_CpuPart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds CPU data. */
export type Parts_CpuPart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds CPU data. */
export type Parts_CpuPurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds CPU data. */
export type Parts_CpuQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds CPU data. */
export type Parts_CpuSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds CPU data. */
export type Parts_CpuSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds Cable data. */
export type Parts_Cable = Parts_Part__Interface & {
  __typename?: 'Parts_Cable';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  description: Scalars['String'];
  ean: Scalars['String'];
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length: Scalars['String'];
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  port: Scalars['String'];
  port_2: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  sub_type?: Maybe<Choices_Choice>;
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds Cable data. */
export type Parts_CableAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cable data. */
export type Parts_CableAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cable data. */
export type Parts_CableAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cable data. */
export type Parts_CableAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cable data. */
export type Parts_CableBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cable data. */
export type Parts_CableBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cable data. */
export type Parts_CableInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cable data. */
export type Parts_CableInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cable data. */
export type Parts_CablePart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cable data. */
export type Parts_CablePart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cable data. */
export type Parts_CablePurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cable data. */
export type Parts_CableQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cable data. */
export type Parts_CableSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cable data. */
export type Parts_CableSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds Cache data. */
export type Parts_Cache = Parts_Part__Interface & {
  __typename?: 'Parts_Cache';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  description: Scalars['String'];
  ean: Scalars['String'];
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds Cache data. */
export type Parts_CacheAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cache data. */
export type Parts_CacheAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cache data. */
export type Parts_CacheAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cache data. */
export type Parts_CacheAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cache data. */
export type Parts_CacheBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cache data. */
export type Parts_CacheBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cache data. */
export type Parts_CacheInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cache data. */
export type Parts_CacheInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cache data. */
export type Parts_CachePart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cache data. */
export type Parts_CachePart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cache data. */
export type Parts_CachePurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cache data. */
export type Parts_CacheQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cache data. */
export type Parts_CacheSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Cache data. */
export type Parts_CacheSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds DiskDrive data. */
export type Parts_DiskDrive = Parts_Part__Interface & {
  __typename?: 'Parts_DiskDrive';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  description: Scalars['String'];
  ean: Scalars['String'];
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  sub_type?: Maybe<Choices_Choice>;
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds DiskDrive data. */
export type Parts_DiskDriveAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds DiskDrive data. */
export type Parts_DiskDriveAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds DiskDrive data. */
export type Parts_DiskDriveAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds DiskDrive data. */
export type Parts_DiskDriveAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds DiskDrive data. */
export type Parts_DiskDriveBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds DiskDrive data. */
export type Parts_DiskDriveBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds DiskDrive data. */
export type Parts_DiskDriveInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds DiskDrive data. */
export type Parts_DiskDriveInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds DiskDrive data. */
export type Parts_DiskDrivePart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds DiskDrive data. */
export type Parts_DiskDrivePart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds DiskDrive data. */
export type Parts_DiskDrivePurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds DiskDrive data. */
export type Parts_DiskDriveQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds DiskDrive data. */
export type Parts_DiskDriveSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds DiskDrive data. */
export type Parts_DiskDriveSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds ExpansionCard data. */
export type Parts_ExpansionCard = Parts_Part__Interface & {
  __typename?: 'Parts_ExpansionCard';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  description: Scalars['String'];
  ean: Scalars['String'];
  gen: Scalars['String'];
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  interface: Scalars['String'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  lanes: Scalars['PositiveInteger'];
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  sub_type?: Maybe<Choices_Choice>;
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds ExpansionCard data. */
export type Parts_ExpansionCardAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds ExpansionCard data. */
export type Parts_ExpansionCardAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds ExpansionCard data. */
export type Parts_ExpansionCardAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds ExpansionCard data. */
export type Parts_ExpansionCardAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds ExpansionCard data. */
export type Parts_ExpansionCardBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds ExpansionCard data. */
export type Parts_ExpansionCardBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds ExpansionCard data. */
export type Parts_ExpansionCardInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds ExpansionCard data. */
export type Parts_ExpansionCardInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds ExpansionCard data. */
export type Parts_ExpansionCardPart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds ExpansionCard data. */
export type Parts_ExpansionCardPart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds ExpansionCard data. */
export type Parts_ExpansionCardPurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds ExpansionCard data. */
export type Parts_ExpansionCardQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds ExpansionCard data. */
export type Parts_ExpansionCardSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds ExpansionCard data. */
export type Parts_ExpansionCardSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds Fan data. */
export type Parts_Fan = Parts_Part__Interface & {
  __typename?: 'Parts_Fan';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  description: Scalars['String'];
  ean: Scalars['String'];
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds Fan data. */
export type Parts_FanAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Fan data. */
export type Parts_FanAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Fan data. */
export type Parts_FanAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Fan data. */
export type Parts_FanAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Fan data. */
export type Parts_FanBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Fan data. */
export type Parts_FanBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Fan data. */
export type Parts_FanInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Fan data. */
export type Parts_FanInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Fan data. */
export type Parts_FanPart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Fan data. */
export type Parts_FanPart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Fan data. */
export type Parts_FanPurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Fan data. */
export type Parts_FanQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Fan data. */
export type Parts_FanSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Fan data. */
export type Parts_FanSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds GPU data. */
export type Parts_Gpu = Parts_Part__Interface & {
  __typename?: 'Parts_GPU';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  core_clock_mhz: Scalars['PositiveInteger'];
  description: Scalars['String'];
  ean: Scalars['String'];
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  interface?: Maybe<Choices_Choice>;
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  memory_bandwidth_mbps: Scalars['PositiveInteger'];
  memory_mb: Scalars['PositiveInteger'];
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds GPU data. */
export type Parts_GpuAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds GPU data. */
export type Parts_GpuAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds GPU data. */
export type Parts_GpuAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds GPU data. */
export type Parts_GpuAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds GPU data. */
export type Parts_GpuBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds GPU data. */
export type Parts_GpuBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds GPU data. */
export type Parts_GpuInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds GPU data. */
export type Parts_GpuInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds GPU data. */
export type Parts_GpuPart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds GPU data. */
export type Parts_GpuPart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds GPU data. */
export type Parts_GpuPurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds GPU data. */
export type Parts_GpuQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds GPU data. */
export type Parts_GpuSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds GPU data. */
export type Parts_GpuSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds Heatsink data. */
export type Parts_Heatsink = Parts_Part__Interface & {
  __typename?: 'Parts_Heatsink';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  description: Scalars['String'];
  ean: Scalars['String'];
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds Heatsink data. */
export type Parts_HeatsinkAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Heatsink data. */
export type Parts_HeatsinkAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Heatsink data. */
export type Parts_HeatsinkAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Heatsink data. */
export type Parts_HeatsinkAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Heatsink data. */
export type Parts_HeatsinkBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Heatsink data. */
export type Parts_HeatsinkBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Heatsink data. */
export type Parts_HeatsinkInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Heatsink data. */
export type Parts_HeatsinkInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Heatsink data. */
export type Parts_HeatsinkPart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Heatsink data. */
export type Parts_HeatsinkPart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Heatsink data. */
export type Parts_HeatsinkPurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Heatsink data. */
export type Parts_HeatsinkQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Heatsink data. */
export type Parts_HeatsinkSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Heatsink data. */
export type Parts_HeatsinkSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds IOModule data. */
export type Parts_IoModule = Parts_Part__Interface & {
  __typename?: 'Parts_IOModule';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  description: Scalars['String'];
  ean: Scalars['String'];
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds IOModule data. */
export type Parts_IoModuleAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds IOModule data. */
export type Parts_IoModuleAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds IOModule data. */
export type Parts_IoModuleAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds IOModule data. */
export type Parts_IoModuleAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds IOModule data. */
export type Parts_IoModuleBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds IOModule data. */
export type Parts_IoModuleBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds IOModule data. */
export type Parts_IoModuleInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds IOModule data. */
export type Parts_IoModuleInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds IOModule data. */
export type Parts_IoModulePart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds IOModule data. */
export type Parts_IoModulePart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds IOModule data. */
export type Parts_IoModulePurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds IOModule data. */
export type Parts_IoModuleQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds IOModule data. */
export type Parts_IoModuleSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds IOModule data. */
export type Parts_IoModuleSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds Memory data. */
export type Parts_Memory = Parts_Part__Interface & {
  __typename?: 'Parts_Memory';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  capacity?: Maybe<Scalars['String']>;
  capacity_amount: Scalars['PositiveInteger'];
  capacity_unit?: Maybe<Choices_Choice>;
  cas_latency: Scalars['String'];
  description: Scalars['String'];
  dimm_type?: Maybe<Choices_Choice>;
  ean: Scalars['String'];
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pins?: Maybe<Choices_Choice>;
  pn: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  speed?: Maybe<Choices_Choice>;
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds Memory data. */
export type Parts_MemoryAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Memory data. */
export type Parts_MemoryAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Memory data. */
export type Parts_MemoryAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Memory data. */
export type Parts_MemoryAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Memory data. */
export type Parts_MemoryBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Memory data. */
export type Parts_MemoryBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Memory data. */
export type Parts_MemoryInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Memory data. */
export type Parts_MemoryInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Memory data. */
export type Parts_MemoryPart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Memory data. */
export type Parts_MemoryPart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Memory data. */
export type Parts_MemoryPurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Memory data. */
export type Parts_MemoryQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Memory data. */
export type Parts_MemorySales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Memory data. */
export type Parts_MemorySubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds Motherboard data. */
export type Parts_Motherboard = Parts_Part__Interface & {
  __typename?: 'Parts_Motherboard';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  cpu_sockets: Scalars['PositiveInteger'];
  description: Scalars['String'];
  ean: Scalars['String'];
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  max_memory_gb: Scalars['PositiveInteger'];
  memory_type?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  ram_sockets: Scalars['PositiveInteger'];
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds Motherboard data. */
export type Parts_MotherboardAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Motherboard data. */
export type Parts_MotherboardAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Motherboard data. */
export type Parts_MotherboardAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Motherboard data. */
export type Parts_MotherboardAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Motherboard data. */
export type Parts_MotherboardBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Motherboard data. */
export type Parts_MotherboardBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Motherboard data. */
export type Parts_MotherboardInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Motherboard data. */
export type Parts_MotherboardInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Motherboard data. */
export type Parts_MotherboardPart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Motherboard data. */
export type Parts_MotherboardPart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Motherboard data. */
export type Parts_MotherboardPurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Motherboard data. */
export type Parts_MotherboardQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Motherboard data. */
export type Parts_MotherboardSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Motherboard data. */
export type Parts_MotherboardSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds NetworkCard data. */
export type Parts_NetworkCard = Parts_Part__Interface & {
  __typename?: 'Parts_NetworkCard';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  description: Scalars['String'];
  ean: Scalars['String'];
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  ports: Scalars['PositiveInteger'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  speed: Scalars['String'];
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds NetworkCard data. */
export type Parts_NetworkCardAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds NetworkCard data. */
export type Parts_NetworkCardAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds NetworkCard data. */
export type Parts_NetworkCardAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds NetworkCard data. */
export type Parts_NetworkCardAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds NetworkCard data. */
export type Parts_NetworkCardBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds NetworkCard data. */
export type Parts_NetworkCardBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds NetworkCard data. */
export type Parts_NetworkCardInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds NetworkCard data. */
export type Parts_NetworkCardInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds NetworkCard data. */
export type Parts_NetworkCardPart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds NetworkCard data. */
export type Parts_NetworkCardPart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds NetworkCard data. */
export type Parts_NetworkCardPurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds NetworkCard data. */
export type Parts_NetworkCardQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds NetworkCard data. */
export type Parts_NetworkCardSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds NetworkCard data. */
export type Parts_NetworkCardSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/**
 * This is the base model for a Part object. Supposed to store part number information,
 * description, type, mfgr, brand, market, and, mfgrd_country.
 */
export type Parts_Part = Parts_Part__Interface & {
  __typename?: 'Parts_Part';
  accesspoint?: Maybe<Parts_AccessPoint>;
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  battery?: Maybe<Parts_Battery>;
  bezel?: Maybe<Parts_Bezel>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  bracket?: Maybe<Parts_Bracket>;
  brand?: Maybe<Parts_ProductCompany>;
  cable?: Maybe<Parts_Cable>;
  cache?: Maybe<Parts_Cache>;
  cpu?: Maybe<Parts_Cpu>;
  description: Scalars['String'];
  diskdrive?: Maybe<Parts_DiskDrive>;
  ean: Scalars['String'];
  expansioncard?: Maybe<Parts_ExpansionCard>;
  fan?: Maybe<Parts_Fan>;
  gpu?: Maybe<Parts_Gpu>;
  has_battery: Scalars['Boolean'];
  heatsink?: Maybe<Parts_Heatsink>;
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  iomodule?: Maybe<Parts_IoModule>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  memory?: Maybe<Parts_Memory>;
  mfgr?: Maybe<Parts_ProductCompany>;
  motherboard?: Maybe<Parts_Motherboard>;
  networkcard?: Maybe<Parts_NetworkCard>;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  phone?: Maybe<Parts_Phone>;
  pn: Scalars['String'];
  powersupply?: Maybe<Parts_PowerSupply>;
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  rails?: Maybe<Parts_Rails>;
  router?: Maybe<Parts_Router>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  server?: Maybe<Parts_Server>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  storage?: Maybe<Parts_Storage>;
  storageenclosure?: Maybe<Parts_StorageEnclosure>;
  subbed_by?: Maybe<Array<Clients_Subs>>;
  supplies?: Maybe<Parts_Supplies>;
  switch?: Maybe<Parts_Switch>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/**
 * This is the base model for a Part object. Supposed to store part number information,
 * description, type, mfgr, brand, market, and, mfgrd_country.
 */
export type Parts_PartAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This is the base model for a Part object. Supposed to store part number information,
 * description, type, mfgr, brand, market, and, mfgrd_country.
 */
export type Parts_PartAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This is the base model for a Part object. Supposed to store part number information,
 * description, type, mfgr, brand, market, and, mfgrd_country.
 */
export type Parts_PartAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This is the base model for a Part object. Supposed to store part number information,
 * description, type, mfgr, brand, market, and, mfgrd_country.
 */
export type Parts_PartAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This is the base model for a Part object. Supposed to store part number information,
 * description, type, mfgr, brand, market, and, mfgrd_country.
 */
export type Parts_PartBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This is the base model for a Part object. Supposed to store part number information,
 * description, type, mfgr, brand, market, and, mfgrd_country.
 */
export type Parts_PartBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This is the base model for a Part object. Supposed to store part number information,
 * description, type, mfgr, brand, market, and, mfgrd_country.
 */
export type Parts_PartInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This is the base model for a Part object. Supposed to store part number information,
 * description, type, mfgr, brand, market, and, mfgrd_country.
 */
export type Parts_PartInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This is the base model for a Part object. Supposed to store part number information,
 * description, type, mfgr, brand, market, and, mfgrd_country.
 */
export type Parts_PartPart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This is the base model for a Part object. Supposed to store part number information,
 * description, type, mfgr, brand, market, and, mfgrd_country.
 */
export type Parts_PartPart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This is the base model for a Part object. Supposed to store part number information,
 * description, type, mfgr, brand, market, and, mfgrd_country.
 */
export type Parts_PartPurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This is the base model for a Part object. Supposed to store part number information,
 * description, type, mfgr, brand, market, and, mfgrd_country.
 */
export type Parts_PartQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This is the base model for a Part object. Supposed to store part number information,
 * description, type, mfgr, brand, market, and, mfgrd_country.
 */
export type Parts_PartSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This is the base model for a Part object. Supposed to store part number information,
 * description, type, mfgr, brand, market, and, mfgrd_country.
 */
export type Parts_PartSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds Phone data. */
export type Parts_Phone = Parts_Part__Interface & {
  __typename?: 'Parts_Phone';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  description: Scalars['String'];
  ean: Scalars['String'];
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  lan_port_speed_mbps: Scalars['PositiveInteger'];
  lan_ports: Scalars['PositiveInteger'];
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  poe_capable: Scalars['Boolean'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
  wifi_capable: Scalars['Boolean'];
  wifi_standards?: Maybe<Choices_Choice>;
};


/** This model inherits Parts.Part and holds Phone data. */
export type Parts_PhoneAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Phone data. */
export type Parts_PhoneAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Phone data. */
export type Parts_PhoneAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Phone data. */
export type Parts_PhoneAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Phone data. */
export type Parts_PhoneBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Phone data. */
export type Parts_PhoneBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Phone data. */
export type Parts_PhoneInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Phone data. */
export type Parts_PhoneInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Phone data. */
export type Parts_PhonePart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Phone data. */
export type Parts_PhonePart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Phone data. */
export type Parts_PhonePurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Phone data. */
export type Parts_PhoneQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Phone data. */
export type Parts_PhoneSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Phone data. */
export type Parts_PhoneSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds PowerSupply data. */
export type Parts_PowerSupply = Parts_Part__Interface & {
  __typename?: 'Parts_PowerSupply';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  current: Scalars['String'];
  description: Scalars['String'];
  ean: Scalars['String'];
  efficiency?: Maybe<Choices_Choice>;
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  wattage: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds PowerSupply data. */
export type Parts_PowerSupplyAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds PowerSupply data. */
export type Parts_PowerSupplyAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds PowerSupply data. */
export type Parts_PowerSupplyAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds PowerSupply data. */
export type Parts_PowerSupplyAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds PowerSupply data. */
export type Parts_PowerSupplyBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds PowerSupply data. */
export type Parts_PowerSupplyBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds PowerSupply data. */
export type Parts_PowerSupplyInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds PowerSupply data. */
export type Parts_PowerSupplyInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds PowerSupply data. */
export type Parts_PowerSupplyPart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds PowerSupply data. */
export type Parts_PowerSupplyPart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds PowerSupply data. */
export type Parts_PowerSupplyPurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds PowerSupply data. */
export type Parts_PowerSupplyQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds PowerSupply data. */
export type Parts_PowerSupplySales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds PowerSupply data. */
export type Parts_PowerSupplySubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** A Product Company is someone who manufactures or brands the parts. */
export type Parts_ProductCompany = {
  __typename?: 'Parts_ProductCompany';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  brand?: Maybe<Array<Parts_Part>>;
  id: Scalars['ID'];
  mfgr?: Maybe<Array<Parts_Part>>;
  name: Scalars['String'];
  short_name: Scalars['String'];
};


/** A Product Company is someone who manufactures or brands the parts. */
export type Parts_ProductCompanyBrandArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** A Product Company is someone who manufactures or brands the parts. */
export type Parts_ProductCompanyMfgrArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds Rails data. */
export type Parts_Rails = Parts_Part__Interface & {
  __typename?: 'Parts_Rails';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  description: Scalars['String'];
  ean: Scalars['String'];
  for_server?: Maybe<Parts_Server>;
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds Rails data. */
export type Parts_RailsAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Rails data. */
export type Parts_RailsAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Rails data. */
export type Parts_RailsAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Rails data. */
export type Parts_RailsAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Rails data. */
export type Parts_RailsBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Rails data. */
export type Parts_RailsBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Rails data. */
export type Parts_RailsInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Rails data. */
export type Parts_RailsInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Rails data. */
export type Parts_RailsPart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Rails data. */
export type Parts_RailsPart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Rails data. */
export type Parts_RailsPurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Rails data. */
export type Parts_RailsQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Rails data. */
export type Parts_RailsSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Rails data. */
export type Parts_RailsSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds Router data. */
export type Parts_Router = Parts_Part__Interface & {
  __typename?: 'Parts_Router';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  built_in_vpn: Scalars['Boolean'];
  description: Scalars['String'];
  ean: Scalars['String'];
  enterprise_wifi_security?: Maybe<Choices_Choice>;
  has_battery: Scalars['Boolean'];
  has_wifi: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  is_web_management: Scalars['Boolean'];
  lan_ports: Scalars['PositiveInteger'];
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  personal_wifi_security?: Maybe<Choices_Choice>;
  pn: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  subbed_by?: Maybe<Array<Clients_Subs>>;
  throughput?: Maybe<Choices_Choice>;
  type: Scalars['String'];
  upc: Scalars['String'];
  wan_ports: Scalars['PositiveInteger'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
  wifi_standards?: Maybe<Choices_Choice>;
};


/** This model inherits Parts.Part and holds Router data. */
export type Parts_RouterAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Router data. */
export type Parts_RouterAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Router data. */
export type Parts_RouterAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Router data. */
export type Parts_RouterAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Router data. */
export type Parts_RouterBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Router data. */
export type Parts_RouterBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Router data. */
export type Parts_RouterInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Router data. */
export type Parts_RouterInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Router data. */
export type Parts_RouterPart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Router data. */
export type Parts_RouterPart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Router data. */
export type Parts_RouterPurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Router data. */
export type Parts_RouterQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Router data. */
export type Parts_RouterSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Router data. */
export type Parts_RouterSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds Server data. */
export type Parts_Server = Parts_Part__Interface & {
  __typename?: 'Parts_Server';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bezels?: Maybe<Array<Parts_Bezel>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  description: Scalars['String'];
  ean: Scalars['String'];
  form_factor?: Maybe<Choices_Choice>;
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  rack_height?: Maybe<Choices_Choice>;
  rails?: Maybe<Array<Parts_Rails>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  storage_bays_25: Scalars['PositiveInteger'];
  storage_bays_35: Scalars['PositiveInteger'];
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds Server data. */
export type Parts_ServerAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Server data. */
export type Parts_ServerAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Server data. */
export type Parts_ServerAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Server data. */
export type Parts_ServerAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Server data. */
export type Parts_ServerBezelsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Server data. */
export type Parts_ServerBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Server data. */
export type Parts_ServerBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Server data. */
export type Parts_ServerInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Server data. */
export type Parts_ServerInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Server data. */
export type Parts_ServerPart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Server data. */
export type Parts_ServerPart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Server data. */
export type Parts_ServerPurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Server data. */
export type Parts_ServerQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Server data. */
export type Parts_ServerRailsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Server data. */
export type Parts_ServerSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Server data. */
export type Parts_ServerSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds Storage data. */
export type Parts_Storage = Parts_Part__Interface & {
  __typename?: 'Parts_Storage';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  capacity?: Maybe<Scalars['String']>;
  capacity_amount: Scalars['PositiveInteger'];
  capacity_unit?: Maybe<Choices_Choice>;
  description: Scalars['String'];
  ean: Scalars['String'];
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  interface?: Maybe<Choices_Choice>;
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  physical_size?: Maybe<Choices_Choice>;
  pn: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  speed?: Maybe<Choices_Choice>;
  spindle_speed?: Maybe<Choices_Choice>;
  sub_type?: Maybe<Choices_Choice>;
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds Storage data. */
export type Parts_StorageAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Storage data. */
export type Parts_StorageAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Storage data. */
export type Parts_StorageAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Storage data. */
export type Parts_StorageAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Storage data. */
export type Parts_StorageBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Storage data. */
export type Parts_StorageBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Storage data. */
export type Parts_StorageInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Storage data. */
export type Parts_StorageInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Storage data. */
export type Parts_StoragePart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Storage data. */
export type Parts_StoragePart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Storage data. */
export type Parts_StoragePurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Storage data. */
export type Parts_StorageQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Storage data. */
export type Parts_StorageSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Storage data. */
export type Parts_StorageSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds StorageEnclosure data. */
export type Parts_StorageEnclosure = Parts_Part__Interface & {
  __typename?: 'Parts_StorageEnclosure';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  description: Scalars['String'];
  ean: Scalars['String'];
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  interface?: Maybe<Choices_Choice>;
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  speed?: Maybe<Choices_Choice>;
  storage_bays_25: Scalars['PositiveInteger'];
  storage_bays_35: Scalars['PositiveInteger'];
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds StorageEnclosure data. */
export type Parts_StorageEnclosureAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds StorageEnclosure data. */
export type Parts_StorageEnclosureAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds StorageEnclosure data. */
export type Parts_StorageEnclosureAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds StorageEnclosure data. */
export type Parts_StorageEnclosureAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds StorageEnclosure data. */
export type Parts_StorageEnclosureBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds StorageEnclosure data. */
export type Parts_StorageEnclosureBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds StorageEnclosure data. */
export type Parts_StorageEnclosureInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds StorageEnclosure data. */
export type Parts_StorageEnclosureInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds StorageEnclosure data. */
export type Parts_StorageEnclosurePart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds StorageEnclosure data. */
export type Parts_StorageEnclosurePart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds StorageEnclosure data. */
export type Parts_StorageEnclosurePurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds StorageEnclosure data. */
export type Parts_StorageEnclosureQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds StorageEnclosure data. */
export type Parts_StorageEnclosureSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds StorageEnclosure data. */
export type Parts_StorageEnclosureSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds Supplies data. */
export type Parts_Supplies = Parts_Part__Interface & {
  __typename?: 'Parts_Supplies';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  description: Scalars['String'];
  ean: Scalars['String'];
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Money>;
  size: Scalars['String'];
  sku: Scalars['String'];
  sub_type?: Maybe<Choices_Choice>;
  subbed_by?: Maybe<Array<Clients_Subs>>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds Supplies data. */
export type Parts_SuppliesAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Supplies data. */
export type Parts_SuppliesAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Supplies data. */
export type Parts_SuppliesAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Supplies data. */
export type Parts_SuppliesAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Supplies data. */
export type Parts_SuppliesBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Supplies data. */
export type Parts_SuppliesBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Supplies data. */
export type Parts_SuppliesInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Supplies data. */
export type Parts_SuppliesInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Supplies data. */
export type Parts_SuppliesPart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Supplies data. */
export type Parts_SuppliesPart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Supplies data. */
export type Parts_SuppliesPurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Supplies data. */
export type Parts_SuppliesQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Supplies data. */
export type Parts_SuppliesSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Supplies data. */
export type Parts_SuppliesSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model inherits Parts.Part and holds Switch data. */
export type Parts_Switch = Parts_Part__Interface & {
  __typename?: 'Parts_Switch';
  /** What client its an alternate part for */
  alt_part_for_client?: Maybe<Clients_Client>;
  /** What part its an alternate for */
  alt_part_for_part?: Maybe<Parts_Part>;
  alts?: Maybe<Array<Parts_Part>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  associatedpn?: Maybe<Array<Parts_AssociatedPn>>;
  attached_items?: Maybe<Array<Inventory_Item>>;
  available_subs?: Maybe<Array<Clients_Subs>>;
  bom_parts?: Maybe<Array<Parts_BomParts>>;
  boms?: Maybe<Array<Parts_Bom>>;
  brand?: Maybe<Parts_ProductCompany>;
  description: Scalars['String'];
  ean: Scalars['String'];
  has_battery: Scalars['Boolean'];
  height_in: Scalars['Float'];
  hs_code: Scalars['String'];
  htsus_code: Scalars['String'];
  id: Scalars['ID'];
  internal_available_subs?: Maybe<Array<Company_Subs>>;
  internal_subbed_by?: Maybe<Array<Company_Subs>>;
  length_in: Scalars['Float'];
  market?: Maybe<Choices_Choice>;
  mfgr?: Maybe<Parts_ProductCompany>;
  part_ptr: Parts_Part;
  part_purchases_items?: Maybe<Array<Purchases_PurchasedItems>>;
  part_sales_items?: Maybe<Array<Sales_SoldItems>>;
  pn: Scalars['String'];
  poe_ports: Scalars['PositiveInteger'];
  ports: Scalars['PositiveInteger'];
  purchases_items_subbed_on?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes?: Maybe<Array<Quotes_QuoteParts>>;
  sales_items_subbed_on?: Maybe<Array<Sales_SoldItems>>;
  schedule_b_code: Scalars['String'];
  secondary_pn?: Maybe<Scalars['String']>;
  sfp_ports: Scalars['PositiveInteger'];
  shipment_estimate?: Maybe<Money>;
  sku: Scalars['String'];
  speed: Scalars['String'];
  subbed_by?: Maybe<Array<Clients_Subs>>;
  switch_type?: Maybe<Choices_Choice>;
  type: Scalars['String'];
  upc: Scalars['String'];
  weight_oz: Scalars['Float'];
  width_in: Scalars['Float'];
};


/** This model inherits Parts.Part and holds Switch data. */
export type Parts_SwitchAltsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Switch data. */
export type Parts_SwitchAssociatedpnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Switch data. */
export type Parts_SwitchAttached_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Switch data. */
export type Parts_SwitchAvailable_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Switch data. */
export type Parts_SwitchBom_PartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Switch data. */
export type Parts_SwitchBomsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Switch data. */
export type Parts_SwitchInternal_Available_SubsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Switch data. */
export type Parts_SwitchInternal_Subbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Switch data. */
export type Parts_SwitchPart_Purchases_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Switch data. */
export type Parts_SwitchPart_Sales_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Switch data. */
export type Parts_SwitchPurchases_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Switch data. */
export type Parts_SwitchQuotesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Switch data. */
export type Parts_SwitchSales_Items_Subbed_OnArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model inherits Parts.Part and holds Switch data. */
export type Parts_SwitchSubbed_ByArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

export type PrintNodeAccountInformation = PrintNodeObject & {
  __typename?: 'PrintNodeAccountInformation';
  Tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  canCreateSubAccounts?: Maybe<Scalars['Boolean']>;
  childAccounts?: Maybe<Array<Maybe<Scalars['String']>>>;
  connected?: Maybe<Array<Maybe<Scalars['String']>>>;
  creatorEmail?: Maybe<Scalars['String']>;
  creatorRef?: Maybe<Scalars['String']>;
  credits?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastname?: Maybe<Scalars['String']>;
  numComputers?: Maybe<Scalars['Int']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  req_limit?: Maybe<Scalars['Int']>;
  req_offset?: Maybe<Scalars['Int']>;
  req_returned?: Maybe<Scalars['Int']>;
  req_total?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  totalPrints?: Maybe<Scalars['Int']>;
  versions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PrintNodeComputer = PrintNodeObject & {
  __typename?: 'PrintNodeComputer';
  createTimestamp?: Maybe<Scalars['String']>;
  hostname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inet?: Maybe<Scalars['String']>;
  inet6?: Maybe<Scalars['String']>;
  jre?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  req_limit?: Maybe<Scalars['Int']>;
  req_offset?: Maybe<Scalars['Int']>;
  req_returned?: Maybe<Scalars['Int']>;
  req_total?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type PrintNodeMeasurement = {
  __typename?: 'PrintNodeMeasurement';
  g?: Maybe<Scalars['Int']>;
  kg?: Maybe<Scalars['Int']>;
  lb?: Maybe<Scalars['Int']>;
  oz?: Maybe<Scalars['Int']>;
};

export type PrintNodePaper = {
  __typename?: 'PrintNodePaper';
  height?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type PrintNodePrintJob = PrintNodeObject & {
  __typename?: 'PrintNodePrintJob';
  contentType?: Maybe<Printnode_Content_Type>;
  createTimestamp?: Maybe<Scalars['String']>;
  expireAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  printer?: Maybe<PrintNodePrinter>;
  req_limit?: Maybe<Scalars['Int']>;
  req_offset?: Maybe<Scalars['Int']>;
  req_returned?: Maybe<Scalars['Int']>;
  req_total?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<Printnode_Printjob_State>;
  title?: Maybe<Scalars['String']>;
};

export type PrintNodePrintJobState = PrintNodeObject & {
  __typename?: 'PrintNodePrintJobState';
  age?: Maybe<Scalars['Int']>;
  clientVersion?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  printJobId?: Maybe<Scalars['Int']>;
  req_limit?: Maybe<Scalars['Int']>;
  req_offset?: Maybe<Scalars['Int']>;
  req_returned?: Maybe<Scalars['Int']>;
  req_total?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
};

export type PrintNodePrintRate = {
  __typename?: 'PrintNodePrintRate';
  rate?: Maybe<Scalars['Float']>;
  unit?: Maybe<Printnode_Unit>;
};

export type PrintNodePrinter = PrintNodeObject & {
  __typename?: 'PrintNodePrinter';
  capabilities?: Maybe<PrintNodePrinterCapabilties>;
  computer?: Maybe<PrintNodeComputer>;
  createTimestamp?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  req_limit?: Maybe<Scalars['Int']>;
  req_offset?: Maybe<Scalars['Int']>;
  req_returned?: Maybe<Scalars['Int']>;
  req_total?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
};

export type PrintNodePrinterCapabilties = {
  __typename?: 'PrintNodePrinterCapabilties';
  bins?: Maybe<Array<Maybe<Scalars['String']>>>;
  collate?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['Boolean']>;
  copies?: Maybe<Scalars['Int']>;
  dpis?: Maybe<Array<Maybe<Scalars['String']>>>;
  extent?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']>>>>>;
  medias?: Maybe<Array<Maybe<Scalars['String']>>>;
  nup?: Maybe<Array<Maybe<Scalars['Int']>>>;
  papers?: Maybe<Array<Maybe<PrintNodePaper>>>;
  printrate?: Maybe<PrintNodePrintRate>;
  supports_custom_paper_size?: Maybe<Scalars['Boolean']>;
};

export type PrintNodeScale = PrintNodeObject & {
  __typename?: 'PrintNodeScale';
  ageOfData?: Maybe<Scalars['Int']>;
  clientReportedCreateTimestamp?: Maybe<Scalars['String']>;
  computerId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  deviceName?: Maybe<Scalars['String']>;
  deviceNum?: Maybe<Scalars['Int']>;
  mass?: Maybe<Array<Maybe<Scalars['Int']>>>;
  measurement?: Maybe<PrintNodeMeasurement>;
  ntpOffset?: Maybe<Scalars['Int']>;
  port?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['Int']>;
  req_limit?: Maybe<Scalars['Int']>;
  req_offset?: Maybe<Scalars['Int']>;
  req_returned?: Maybe<Scalars['Int']>;
  req_total?: Maybe<Scalars['Int']>;
  vendor?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['Int']>;
};

/** Functions as comment templates that they can use for repeat text data entry. */
export type Profile_LiveTemplate = {
  __typename?: 'Profile_LiveTemplate';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  department?: Maybe<Department_Department>;
  id: Scalars['ID'];
  name: Scalars['String'];
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  team?: Maybe<Team_Team>;
  template: Scalars['String'];
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

/**
 * This keeps track of any non-strictly user data.
 * Profiles have any user related data that isn't purely for identification/auth.
 */
export type Profile_Profile = {
  __typename?: 'Profile_Profile';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  color: Scalars['String'];
  column_config: Scalars['JSON'];
  commission_percentage?: Maybe<Scalars['Percent']>;
  default_site?: Maybe<Location_Site>;
  hire_date?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  job_title: Scalars['String'];
  last_review?: Maybe<Scalars['DateTime']>;
  manager?: Maybe<Users_User>;
  metrics?: Maybe<Array<Profile_ProfileMetrics>>;
  order_cache: Scalars['JSON'];
  settings: Scalars['JSON'];
  terminated: Scalars['Boolean'];
  terminated_date?: Maybe<Scalars['DateTime']>;
  upcoming_review?: Maybe<Scalars['DateTime']>;
  user: Users_User;
  wage: Scalars['Percent'];
};


/**
 * This keeps track of any non-strictly user data.
 * Profiles have any user related data that isn't purely for identification/auth.
 */
export type Profile_ProfileMetricsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/**
 * This is a daily history model that keeps of what the user did in a day.
 * Ex: ABC received X parts 1/20/2018
 */
export type Profile_ProfileMetrics = {
  __typename?: 'Profile_ProfileMetrics';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  creation_date: Scalars['Date'];
  current: Scalars['Boolean'];
  id: Scalars['ID'];
  parts_broken_down: Scalars['PositiveInteger'];
  parts_moved: Scalars['PositiveInteger'];
  parts_owned: Scalars['PositiveInteger'];
  parts_processed: Scalars['PositiveInteger'];
  parts_purchased: Scalars['PositiveInteger'];
  parts_received: Scalars['PositiveInteger'];
  parts_shipped: Scalars['PositiveInteger'];
  parts_sold: Scalars['PositiveInteger'];
  parts_tested: Scalars['PositiveInteger'];
  parts_wiped: Scalars['PositiveInteger'];
  profile: Profile_Profile;
  sales: Scalars['PositiveInteger'];
};

/** Stores column configs for all of the frontend table views. */
export type Profile_TableConfig = {
  __typename?: 'Profile_TableConfig';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  config: Scalars['JSON'];
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  display_name: Scalars['String'];
  id: Scalars['ID'];
  is_shareable: Scalars['Boolean'];
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  table_name: Scalars['String'];
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

/** This model keeps track of Purchase data. */
export type Purchases_Purchase = {
  __typename?: 'Purchases_Purchase';
  /** The date the order was accepted. */
  acceptance_date?: Maybe<Scalars['DateTime']>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** The date the order was approved. */
  approval_date?: Maybe<Scalars['DateTime']>;
  /** Who approved the order. */
  approved_by?: Maybe<Users_User>;
  /** Who was assigned to pick this order. */
  assigned_to?: Maybe<Users_User>;
  attached_invoices?: Maybe<Array<Invoices_ApInvoice>>;
  attached_work_order?: Maybe<Array<WorkOrder_WorkOrder>>;
  /** The client this order was made to/from. */
  client?: Maybe<Clients_Client>;
  /** The client's order number. */
  client_order_number: Scalars['String'];
  /** The date to close the order by. */
  closing_date?: Maybe<Scalars['Date']>;
  /** The condition of the parts on this order. */
  condition?: Maybe<Choices_Choice>;
  /** The person to contact for this order. */
  contact?: Maybe<Clients_Contact>;
  /** Comments to put on the contract for this order. */
  contract_comment: Scalars['String'];
  /** Who sent the contract. */
  contract_sent_by?: Maybe<Users_User>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  credit_card?: Maybe<Company_CreditCard>;
  /** The down payment amount for this order. */
  down_payment?: Maybe<Money>;
  /** If editing is locked for this object or not. */
  edit_lock: Scalars['Boolean'];
  /** The estimated total value of all parts on the order. */
  estimated_part_total?: Maybe<Money>;
  /** The estimated sub total (total-tax) of the order. */
  estimated_sub_total?: Maybe<Money>;
  /** The estimated total value of the order. */
  estimated_total?: Maybe<Money>;
  /** The estimated time of arrival for the order. */
  eta?: Maybe<Scalars['DateTime']>;
  /** The cost of freight for this order. */
  freight?: Maybe<Money>;
  /** Whether this order has items that are coming in and are on an ST. */
  hot: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Comments on this order for internal use. */
  internal_comment: Scalars['String'];
  /** The amount to charge for late delivery. */
  late_delivery_charge?: Maybe<Money>;
  /** Miscellaneous charges on this order. */
  misc?: Maybe<Money>;
  /** The amount to pre pay for the order. */
  pre_pay?: Maybe<Money>;
  pt_items?: Maybe<Array<Purchases_PurchasedItems>>;
  /** The date the order was made. */
  purchase_date?: Maybe<Scalars['DateTime']>;
  purchase_rma?: Maybe<Array<Rma_PurchaseRma>>;
  /** The quote used for creating this order. */
  quote?: Maybe<Quotes_Quote>;
  /** Who's order this is. */
  rep?: Maybe<Users_User>;
  /** The date the order was requested by. */
  requested_date?: Maybe<Scalars['DateTime']>;
  /** The Shipment Order this order is tied to. */
  shipment_order?: Maybe<Shipping_ShipmentOrder>;
  status: Choices_Status;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** The tax amount for this order. */
  tax?: Maybe<Money>;
  /** The terms for this order. */
  terms?: Maybe<Clients_Terms>;
  /** Who was assigned to test this order. */
  tester?: Maybe<Users_User>;
  /** The total value of the order. */
  total?: Maybe<Money>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
  /** The vendors own reference number. */
  vendor_reference: Scalars['String'];
  /** The date that the warranty expires. */
  warranty_end_date?: Maybe<Scalars['Date']>;
  /** If we arrange transportation. */
  we_transport: Scalars['Boolean'];
};


/** This model keeps track of Purchase data. */
export type Purchases_PurchaseAttached_InvoicesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model keeps track of Purchase data. */
export type Purchases_PurchaseAttached_Work_OrderArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model keeps track of Purchase data. */
export type Purchases_PurchasePt_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model keeps track of Purchase data. */
export type Purchases_PurchasePurchase_RmaArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model links Inventory.Items to Purchases and keeps track of any relevant data. */
export type Purchases_PurchasedItems = {
  __typename?: 'Purchases_PurchasedItems';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  /** The current value of this Item. */
  current_cost?: Maybe<Money>;
  /** If editing is locked for this object or not. */
  edit_lock: Scalars['Boolean'];
  id: Scalars['ID'];
  /** The Item on the order. */
  item?: Maybe<Inventory_Item>;
  /** The Item's line on the order. */
  line_number: Scalars['PositiveInteger'];
  /** The amount this Item was originally purchased for. */
  original_cost?: Maybe<Money>;
  /** What the Item was originally bought/sold as. */
  original_part?: Maybe<Parts_Part>;
  /** What the Item is being bought/sold as. */
  part: Parts_Part;
  /** What Shipment this Item is on. */
  shipment?: Maybe<Shipping_Shipment>;
  /** If the part is swapped or not, and if so what kind of swap. */
  swap_type: Scalars['String'];
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** The tax value for this Item. */
  tax?: Maybe<Money>;
  /** The order this Item is attached to. */
  transaction: Purchases_Purchase;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

export type Query = {
  __typename?: 'Query';
  /** Lists the EasyPost CarrierAccounts */
  EasyPost_ListCarrierAccounts?: Maybe<Array<Maybe<EasyPostCarrierAccount>>>;
  /** Gets the EasyPost Address */
  EasyPost_RetrieveAddress?: Maybe<EasyPostAddress>;
  /** Gets the EasyPost CarrierAccount */
  EasyPost_RetrieveCarrierAccount?: Maybe<EasyPostCarrierAccount>;
  /** Gets the EasyPost Insurance */
  EasyPost_RetrieveInsurance?: Maybe<EasyPostInsurance>;
  /** Gets the EasyPost Order */
  EasyPost_RetrieveOrder?: Maybe<EasyPostOrder>;
  /** Gets rates for an EasyPost Order */
  EasyPost_RetrieveOrderRates?: Maybe<EasyPostOrder>;
  /** Gets the EasyPost Parcel */
  EasyPost_RetrieveParcel?: Maybe<EasyPostParcel>;
  /** Gets the EasyPost Pickup */
  EasyPost_RetrievePickup?: Maybe<EasyPostPickup>;
  /** Gets the EasyPost Shipment */
  EasyPost_RetrieveShipment?: Maybe<EasyPostShipment>;
  /** Gets an EasyPost Shipment Label */
  EasyPost_RetrieveShipmentLabel?: Maybe<EasyPostShipment>;
  /** Gets the EasyPost Tracker */
  EasyPost_RetrieveTracker?: Maybe<EasyPostTracker>;
  /** Checks if credentials are valid. */
  PrintNodeNoop?: Maybe<Scalars['String']>;
  /** Tests the accessibility of the PrintNode API */
  PrintNodePing?: Maybe<Scalars['String']>;
  /** Gets PrintNode computers */
  PrintNode_GetComputers?: Maybe<Array<Maybe<PrintNodeComputer>>>;
  /** Gets PrintNode printjob states */
  PrintNode_GetPrintJobStates?: Maybe<Array<Maybe<PrintNodePrintJobState>>>;
  /** Gets PrintNode printjobs */
  PrintNode_GetPrintJobs?: Maybe<Array<Maybe<PrintNodePrintJob>>>;
  /** Gets PrintNode printers */
  PrintNode_GetPrinters?: Maybe<Array<Maybe<PrintNodePrinter>>>;
  /** Gets PrintNode scales */
  PrintNode_GetScales?: Maybe<Array<Maybe<PrintNodeScale>>>;
  /** Grabs a single Accounting_Account object */
  accounting_account?: Maybe<Accounting_Account>;
  /** Grabs multiple Accounting_Account objects */
  accounting_accounts?: Maybe<Array<Maybe<Accounting_Account>>>;
  /** Grabs a single Accounting_Entry object */
  accounting_entry?: Maybe<Accounting_Entry>;
  /** Grabs multiple Accounting_Entry objects */
  accounting_entrys?: Maybe<Array<Maybe<Accounting_Entry>>>;
  /** Grabs a single Accounting_ServiceDiscount object */
  accounting_service_discount?: Maybe<Accounting_ServiceDiscount>;
  /** Grabs multiple Accounting_ServiceDiscount objects */
  accounting_service_discounts?: Maybe<Array<Maybe<Accounting_ServiceDiscount>>>;
  /** Grabs a single Accounting_SoftwareDiscount object */
  accounting_software_discount?: Maybe<Accounting_SoftwareDiscount>;
  /** Grabs multiple Accounting_SoftwareDiscount objects */
  accounting_software_discounts?: Maybe<Array<Maybe<Accounting_SoftwareDiscount>>>;
  /** Grabs a single Accounting_Transaction object */
  accounting_transaction?: Maybe<Accounting_Transaction>;
  /** Grabs multiple Accounting_Transaction objects */
  accounting_transactions?: Maybe<Array<Maybe<Accounting_Transaction>>>;
  /** Grabs a single Address_Address object */
  address_address?: Maybe<Address_Address>;
  /** Grabs multiple Address_Address objects */
  address_addresses?: Maybe<Array<Maybe<Address_Address>>>;
  /** Grabs a single Breakdown_BreakDown object */
  breakdown_break_down?: Maybe<Breakdown_BreakDown>;
  /** Grabs a single Breakdown_BreakDownItems object */
  breakdown_break_down_item?: Maybe<Breakdown_BreakDownItems>;
  /** Grabs multiple Breakdown_BreakDownItems objects */
  breakdown_break_down_items?: Maybe<Array<Maybe<Breakdown_BreakDownItems>>>;
  /** Grabs multiple Breakdown_BreakDown objects */
  breakdown_break_downs?: Maybe<Array<Maybe<Breakdown_BreakDown>>>;
  /** Grabs a single BuildUp_BuildUp object */
  build_up_build_up?: Maybe<BuildUp_BuildUp>;
  /** Grabs a single BuildUp_BuildUpItems object */
  build_up_build_up_item?: Maybe<BuildUp_BuildUpItems>;
  /** Grabs multiple BuildUp_BuildUpItems objects */
  build_up_build_up_items?: Maybe<Array<Maybe<BuildUp_BuildUpItems>>>;
  /** Grabs multiple BuildUp_BuildUp objects */
  build_up_build_ups?: Maybe<Array<Maybe<BuildUp_BuildUp>>>;
  /** Grabs a single Choices_Choice object */
  choices_choice?: Maybe<Choices_Choice>;
  /** Grabs multiple Choices_Choice objects */
  choices_choices?: Maybe<Array<Maybe<Choices_Choice>>>;
  /** Grabs a single Choices_Status object */
  choices_status?: Maybe<Choices_Status>;
  /** Grabs multiple Choices_Status objects */
  choices_statuses?: Maybe<Array<Maybe<Choices_Status>>>;
  /** Grabs a single Choices_Tag object */
  choices_tag?: Maybe<Choices_Tag>;
  /** Grabs multiple Choices_Tag objects */
  choices_tags?: Maybe<Array<Maybe<Choices_Tag>>>;
  /** Grabs a single Clients_Client object */
  clients_client?: Maybe<Clients_Client>;
  /** Grabs multiple Clients_Client objects */
  clients_clients?: Maybe<Array<Maybe<Clients_Client>>>;
  /** Grabs a single Clients_Contact object */
  clients_contact?: Maybe<Clients_Contact>;
  /** Grabs multiple Clients_Contact objects */
  clients_contacts?: Maybe<Array<Maybe<Clients_Contact>>>;
  /** Grabs a single Clients_ShippingLabel object */
  clients_shipping_label?: Maybe<Clients_ShippingLabel>;
  /** Grabs multiple Clients_ShippingLabel objects */
  clients_shipping_labels?: Maybe<Array<Maybe<Clients_ShippingLabel>>>;
  /** Grabs a single Clients_Subs object */
  clients_sub?: Maybe<Clients_Subs>;
  /** Grabs multiple Clients_Subs objects */
  clients_subs?: Maybe<Array<Maybe<Clients_Subs>>>;
  /** Grabs a single Clients_Terms object */
  clients_term?: Maybe<Clients_Terms>;
  /** Grabs multiple Clients_Terms objects */
  clients_terms?: Maybe<Array<Maybe<Clients_Terms>>>;
  /** Grabs a single Company_Company object */
  company_company?: Maybe<Company_Company>;
  /** Grabs a single Company_CreditCard object */
  company_credit_card?: Maybe<Company_CreditCard>;
  /** Grabs multiple Company_CreditCard objects */
  company_credit_cards?: Maybe<Array<Maybe<Company_CreditCard>>>;
  /** Grabs a single Company_Subs object */
  company_sub?: Maybe<Company_Subs>;
  /** Grabs multiple Company_Subs objects */
  company_subs?: Maybe<Array<Maybe<Company_Subs>>>;
  /** Converts money from one currency to another. */
  convert_money: Money;
  /** Gets a list of all countries. */
  countries?: Maybe<Array<Maybe<CountryObject>>>;
  /** Gets a list of all currencies. */
  currencies?: Maybe<Array<Maybe<Currency>>>;
  /** Gets a currency by its ISO code. */
  currency?: Maybe<Currency>;
  /** Grabs a single CustomPermissions_GraphQLPermissions object */
  custom_permissions_graphql_permission?: Maybe<CustomPermissions_GraphQlPermissions>;
  /** Grabs multiple CustomPermissions_GraphQLPermissions objects */
  custom_permissions_graphql_permissions?: Maybe<Array<Maybe<CustomPermissions_GraphQlPermissions>>>;
  /** Grabs a single CustomPermissions_Role object */
  custom_permissions_role?: Maybe<CustomPermissions_Role>;
  /** Grabs a single CustomPermissions_RolePermissions object */
  custom_permissions_role_permission?: Maybe<CustomPermissions_RolePermissions>;
  /** Grabs multiple CustomPermissions_RolePermissions objects */
  custom_permissions_role_permissions?: Maybe<Array<Maybe<CustomPermissions_RolePermissions>>>;
  /** Grabs multiple CustomPermissions_Role objects */
  custom_permissions_roles?: Maybe<Array<Maybe<CustomPermissions_Role>>>;
  /** Gets the instances default currency. */
  default_currency?: Maybe<Currency>;
  /** Grabs a single Department_Department object */
  department_department?: Maybe<Department_Department>;
  /** Grabs a single Department_DepartmentEmployees object */
  department_department_employee?: Maybe<Department_DepartmentEmployees>;
  /** Grabs multiple Department_DepartmentEmployees objects */
  department_department_employees?: Maybe<Array<Maybe<Department_DepartmentEmployees>>>;
  /** Grabs a single Department_DepartmentRoles object */
  department_department_role?: Maybe<Department_DepartmentRoles>;
  /** Grabs multiple Department_DepartmentRoles objects */
  department_department_roles?: Maybe<Array<Maybe<Department_DepartmentRoles>>>;
  /** Grabs multiple Department_Department objects */
  department_departments?: Maybe<Array<Maybe<Department_Department>>>;
  /** Gets aggregate data for a model. */
  get_aggregate?: Maybe<Scalars['JSON']>;
  /** Get exchange rate. */
  get_exchange_rate: Scalars['Price'];
  /** Grabs a single Inventory_Item object */
  inventory_item?: Maybe<Inventory_Item>;
  /** Grabs a single Inventory_Item__Interface object */
  inventory_item__interface?: Maybe<Inventory_Item__Interface>;
  /** Grabs multiple Inventory_Item objects */
  inventory_items?: Maybe<Array<Maybe<Inventory_Item>>>;
  /** Grabs multiple Inventory_Item__Interface objects */
  inventory_items__interface?: Maybe<Array<Maybe<Inventory_Item__Interface>>>;
  /** Grabs a single Inventory_Service object */
  inventory_service?: Maybe<Inventory_Service>;
  /** Grabs multiple Inventory_Service objects */
  inventory_services?: Maybe<Array<Maybe<Inventory_Service>>>;
  /** Grabs a single Inventory_Software object */
  inventory_software?: Maybe<Inventory_Software>;
  /** Grabs multiple Inventory_Software objects */
  inventory_softwares?: Maybe<Array<Maybe<Inventory_Software>>>;
  /** Grabs a single Inventory_System object */
  inventory_system?: Maybe<Inventory_System>;
  /** Grabs multiple Inventory_System objects */
  inventory_systems?: Maybe<Array<Maybe<Inventory_System>>>;
  /** Grabs a single Invoices_APInvoice object */
  invoices_a_p_invoice?: Maybe<Invoices_ApInvoice>;
  /** Grabs multiple Invoices_APInvoice objects */
  invoices_a_p_invoices?: Maybe<Array<Maybe<Invoices_ApInvoice>>>;
  /** Grabs a single Invoices_APPayment object */
  invoices_a_p_payment?: Maybe<Invoices_ApPayment>;
  /** Grabs multiple Invoices_APPayment objects */
  invoices_a_p_payments?: Maybe<Array<Maybe<Invoices_ApPayment>>>;
  /** Grabs a single Invoices_ARInvoice object */
  invoices_a_r_invoice?: Maybe<Invoices_ArInvoice>;
  /** Grabs multiple Invoices_ARInvoice objects */
  invoices_a_r_invoices?: Maybe<Array<Maybe<Invoices_ArInvoice>>>;
  /** Grabs a single Invoices_ARPayment object */
  invoices_a_r_payment?: Maybe<Invoices_ArPayment>;
  /** Grabs multiple Invoices_ARPayment objects */
  invoices_a_r_payments?: Maybe<Array<Maybe<Invoices_ArPayment>>>;
  /** Grabs a single Location_Location object */
  location_location?: Maybe<Location_Location>;
  /** Grabs multiple Location_Location objects */
  location_locations?: Maybe<Array<Maybe<Location_Location>>>;
  /** Grabs a single Location_Site object */
  location_site?: Maybe<Location_Site>;
  /** Grabs multiple Location_Site objects */
  location_sites?: Maybe<Array<Maybe<Location_Site>>>;
  /** Grabs a single Logging_ActionLog object */
  logging_action_log?: Maybe<Logging_ActionLog>;
  /** Grabs a single Logging_ActionLogObject object */
  logging_action_log_object?: Maybe<Logging_ActionLogObject>;
  /** Grabs multiple Logging_ActionLogObject objects */
  logging_action_log_objects?: Maybe<Array<Maybe<Logging_ActionLogObject>>>;
  /** Grabs multiple Logging_ActionLog objects */
  logging_action_logs?: Maybe<Array<Maybe<Logging_ActionLog>>>;
  me?: Maybe<Users_User>;
  /** Grabs a single Notifications_Notification object */
  notifications_notification?: Maybe<Notifications_Notification>;
  /** Grabs multiple Notifications_Notification objects */
  notifications_notifications?: Maybe<Array<Maybe<Notifications_Notification>>>;
  /** Grabs a single Parts_AccessPoint object */
  parts_access_point?: Maybe<Parts_AccessPoint>;
  /** Grabs multiple Parts_AccessPoint objects */
  parts_access_points?: Maybe<Array<Maybe<Parts_AccessPoint>>>;
  /** Grabs a single Parts_AssociatedPN object */
  parts_associated_pn?: Maybe<Parts_AssociatedPn>;
  /** Grabs multiple Parts_AssociatedPN objects */
  parts_associated_pns?: Maybe<Array<Maybe<Parts_AssociatedPn>>>;
  /** Grabs a single Parts_Battery object */
  parts_battery?: Maybe<Parts_Battery>;
  /** Grabs multiple Parts_Battery objects */
  parts_batterys?: Maybe<Array<Maybe<Parts_Battery>>>;
  /** Grabs a single Parts_Bezel object */
  parts_bezel?: Maybe<Parts_Bezel>;
  /** Grabs multiple Parts_Bezel objects */
  parts_bezels?: Maybe<Array<Maybe<Parts_Bezel>>>;
  /** Grabs a single Parts_BOM object */
  parts_bom?: Maybe<Parts_Bom>;
  /** Grabs a single Parts_BOMParts object */
  parts_bom_part?: Maybe<Parts_BomParts>;
  /** Grabs multiple Parts_BOMParts objects */
  parts_bom_parts?: Maybe<Array<Maybe<Parts_BomParts>>>;
  /** Grabs multiple Parts_BOM objects */
  parts_boms?: Maybe<Array<Maybe<Parts_Bom>>>;
  /** Grabs a single Parts_Bracket object */
  parts_bracket?: Maybe<Parts_Bracket>;
  /** Grabs multiple Parts_Bracket objects */
  parts_brackets?: Maybe<Array<Maybe<Parts_Bracket>>>;
  /** Grabs a single Parts_Cable object */
  parts_cable?: Maybe<Parts_Cable>;
  /** Grabs multiple Parts_Cable objects */
  parts_cables?: Maybe<Array<Maybe<Parts_Cable>>>;
  /** Grabs a single Parts_Cache object */
  parts_cache?: Maybe<Parts_Cache>;
  /** Grabs multiple Parts_Cache objects */
  parts_caches?: Maybe<Array<Maybe<Parts_Cache>>>;
  /** Grabs a single Parts_CPU object */
  parts_cpu?: Maybe<Parts_Cpu>;
  /** Grabs multiple Parts_CPU objects */
  parts_cpus?: Maybe<Array<Maybe<Parts_Cpu>>>;
  /** Grabs a single Parts_DiskDrive object */
  parts_disk_drive?: Maybe<Parts_DiskDrive>;
  /** Grabs multiple Parts_DiskDrive objects */
  parts_disk_drives?: Maybe<Array<Maybe<Parts_DiskDrive>>>;
  /** Grabs a single Parts_ExpansionCard object */
  parts_expansion_card?: Maybe<Parts_ExpansionCard>;
  /** Grabs multiple Parts_ExpansionCard objects */
  parts_expansion_cards?: Maybe<Array<Maybe<Parts_ExpansionCard>>>;
  /** Grabs a single Parts_Fan object */
  parts_fan?: Maybe<Parts_Fan>;
  /** Grabs multiple Parts_Fan objects */
  parts_fans?: Maybe<Array<Maybe<Parts_Fan>>>;
  /** Grabs a single Parts_GPU object */
  parts_gpu?: Maybe<Parts_Gpu>;
  /** Grabs multiple Parts_GPU objects */
  parts_gpus?: Maybe<Array<Maybe<Parts_Gpu>>>;
  /** Grabs a single Parts_Heatsink object */
  parts_heatsink?: Maybe<Parts_Heatsink>;
  /** Grabs multiple Parts_Heatsink objects */
  parts_heatsinks?: Maybe<Array<Maybe<Parts_Heatsink>>>;
  /** Grabs a single Parts_IOModule object */
  parts_io_module?: Maybe<Parts_IoModule>;
  /** Grabs multiple Parts_IOModule objects */
  parts_io_modules?: Maybe<Array<Maybe<Parts_IoModule>>>;
  /** Grabs a single Parts_Memory object */
  parts_memory?: Maybe<Parts_Memory>;
  /** Grabs multiple Parts_Memory objects */
  parts_memorys?: Maybe<Array<Maybe<Parts_Memory>>>;
  /** Grabs a single Parts_Motherboard object */
  parts_motherboard?: Maybe<Parts_Motherboard>;
  /** Grabs multiple Parts_Motherboard objects */
  parts_motherboards?: Maybe<Array<Maybe<Parts_Motherboard>>>;
  /** Grabs a single Parts_NetworkCard object */
  parts_network_card?: Maybe<Parts_NetworkCard>;
  /** Grabs multiple Parts_NetworkCard objects */
  parts_network_cards?: Maybe<Array<Maybe<Parts_NetworkCard>>>;
  /** Grabs a single Parts_Part object */
  parts_part?: Maybe<Parts_Part>;
  /** Grabs a single Parts_Part__Interface object */
  parts_part__interface?: Maybe<Parts_Part__Interface>;
  /** Grabs multiple Parts_Part objects */
  parts_parts?: Maybe<Array<Maybe<Parts_Part>>>;
  /** Grabs multiple Parts_Part__Interface objects */
  parts_parts__interface?: Maybe<Array<Maybe<Parts_Part__Interface>>>;
  /** Grabs a single Parts_Phone object */
  parts_phone?: Maybe<Parts_Phone>;
  /** Grabs multiple Parts_Phone objects */
  parts_phones?: Maybe<Array<Maybe<Parts_Phone>>>;
  /** Grabs a single Parts_PowerSupply object */
  parts_power_supply?: Maybe<Parts_PowerSupply>;
  /** Grabs multiple Parts_PowerSupply objects */
  parts_power_supplys?: Maybe<Array<Maybe<Parts_PowerSupply>>>;
  /** Grabs a single Parts_ProductCompany object */
  parts_product_company?: Maybe<Parts_ProductCompany>;
  /** Grabs multiple Parts_ProductCompany objects */
  parts_product_companys?: Maybe<Array<Maybe<Parts_ProductCompany>>>;
  /** Grabs a single Parts_Rails object */
  parts_rail?: Maybe<Parts_Rails>;
  /** Grabs multiple Parts_Rails objects */
  parts_rails?: Maybe<Array<Maybe<Parts_Rails>>>;
  /** Grabs a single Parts_Router object */
  parts_router?: Maybe<Parts_Router>;
  /** Grabs multiple Parts_Router objects */
  parts_routers?: Maybe<Array<Maybe<Parts_Router>>>;
  /** Grabs a single Parts_Server object */
  parts_server?: Maybe<Parts_Server>;
  /** Grabs multiple Parts_Server objects */
  parts_servers?: Maybe<Array<Maybe<Parts_Server>>>;
  /** Grabs a single Parts_Storage object */
  parts_storage?: Maybe<Parts_Storage>;
  /** Grabs a single Parts_StorageEnclosure object */
  parts_storage_enclosure?: Maybe<Parts_StorageEnclosure>;
  /** Grabs multiple Parts_StorageEnclosure objects */
  parts_storage_enclosures?: Maybe<Array<Maybe<Parts_StorageEnclosure>>>;
  /** Grabs multiple Parts_Storage objects */
  parts_storages?: Maybe<Array<Maybe<Parts_Storage>>>;
  /** Grabs multiple Parts_Supplies objects */
  parts_supplies?: Maybe<Array<Maybe<Parts_Supplies>>>;
  /** Grabs a single Parts_Supplies object */
  parts_supply?: Maybe<Parts_Supplies>;
  /** Grabs a single Parts_Switch object */
  parts_switch?: Maybe<Parts_Switch>;
  /** Grabs multiple Parts_Switch objects */
  parts_switchs?: Maybe<Array<Maybe<Parts_Switch>>>;
  /** Grabs a single Profile_LiveTemplate object */
  profile_live_template?: Maybe<Profile_LiveTemplate>;
  /** Grabs multiple Profile_LiveTemplate objects */
  profile_live_templates?: Maybe<Array<Maybe<Profile_LiveTemplate>>>;
  /** Grabs a single Profile_Profile object */
  profile_profile?: Maybe<Profile_Profile>;
  /** Grabs a single Profile_ProfileMetrics object */
  profile_profile_metric?: Maybe<Profile_ProfileMetrics>;
  /** Grabs multiple Profile_ProfileMetrics objects */
  profile_profile_metrics?: Maybe<Array<Maybe<Profile_ProfileMetrics>>>;
  /** Grabs multiple Profile_Profile objects */
  profile_profiles?: Maybe<Array<Maybe<Profile_Profile>>>;
  /** Grabs a single Profile_TableConfig object */
  profile_table_config?: Maybe<Profile_TableConfig>;
  /** Grabs multiple Profile_TableConfig objects */
  profile_table_configs?: Maybe<Array<Maybe<Profile_TableConfig>>>;
  /** Grabs a single Purchases_Purchase object */
  purchases_purchase?: Maybe<Purchases_Purchase>;
  /** Grabs a single Purchases_PurchasedItems object */
  purchases_purchased_item?: Maybe<Purchases_PurchasedItems>;
  /** Grabs multiple Purchases_PurchasedItems objects */
  purchases_purchased_items?: Maybe<Array<Maybe<Purchases_PurchasedItems>>>;
  /** Grabs multiple Purchases_Purchase objects */
  purchases_purchases?: Maybe<Array<Maybe<Purchases_Purchase>>>;
  /** Grabs a single Quotes_Quote object */
  quotes_quote?: Maybe<Quotes_Quote>;
  /** Grabs a single Quotes_QuoteParts object */
  quotes_quote_part?: Maybe<Quotes_QuoteParts>;
  /** Grabs multiple Quotes_QuoteParts objects */
  quotes_quote_parts?: Maybe<Array<Maybe<Quotes_QuoteParts>>>;
  /** Grabs multiple Quotes_Quote objects */
  quotes_quotes?: Maybe<Array<Maybe<Quotes_Quote>>>;
  /** Grabs a single Reporting_Report object */
  reporting_report?: Maybe<Reporting_Report>;
  /** Grabs multiple Reporting_Report objects */
  reporting_reports?: Maybe<Array<Maybe<Reporting_Report>>>;
  /** Grabs a single RMA_PurchaseRMA object */
  rma_purchase_rma?: Maybe<Rma_PurchaseRma>;
  /** Grabs a single RMA_PurchaseRMAItems object */
  rma_purchase_rma_item?: Maybe<Rma_PurchaseRmaItems>;
  /** Grabs multiple RMA_PurchaseRMAItems objects */
  rma_purchase_rma_items?: Maybe<Array<Maybe<Rma_PurchaseRmaItems>>>;
  /** Grabs multiple RMA_PurchaseRMA objects */
  rma_purchase_rmas?: Maybe<Array<Maybe<Rma_PurchaseRma>>>;
  /** Grabs a single RMA_RMA object */
  rma_rma?: Maybe<Rma_Rma>;
  /** Grabs a single RMA_RMAItems object */
  rma_rma_item?: Maybe<Rma_RmaItems>;
  /** Grabs multiple RMA_RMAItems objects */
  rma_rma_items?: Maybe<Array<Maybe<Rma_RmaItems>>>;
  /** Grabs multiple RMA_RMA objects */
  rma_rmas?: Maybe<Array<Maybe<Rma_Rma>>>;
  /** Grabs a single Sales_Fees object */
  sales_fee?: Maybe<Sales_Fees>;
  /** Grabs multiple Sales_Fees objects */
  sales_fees?: Maybe<Array<Maybe<Sales_Fees>>>;
  /** Grabs a single Sales_Sale object */
  sales_sale?: Maybe<Sales_Sale>;
  /** Grabs multiple Sales_Sale objects */
  sales_sales?: Maybe<Array<Maybe<Sales_Sale>>>;
  /** Grabs a single Sales_SoldItems object */
  sales_sold_item?: Maybe<Sales_SoldItems>;
  /** Grabs multiple Sales_SoldItems objects */
  sales_sold_items?: Maybe<Array<Maybe<Sales_SoldItems>>>;
  /** Grabs a single Sales_SoldServices object */
  sales_sold_service?: Maybe<Sales_SoldServices>;
  /** Grabs multiple Sales_SoldServices objects */
  sales_sold_services?: Maybe<Array<Maybe<Sales_SoldServices>>>;
  /** Grabs a single Sales_SoldSoftware object */
  sales_sold_software?: Maybe<Sales_SoldSoftware>;
  /** Grabs multiple Sales_SoldSoftware objects */
  sales_sold_softwares?: Maybe<Array<Maybe<Sales_SoldSoftware>>>;
  /** Grabs a single Shipping_Account object */
  shipping_account?: Maybe<Shipping_Account>;
  /** Grabs multiple Shipping_Account objects */
  shipping_accounts?: Maybe<Array<Maybe<Shipping_Account>>>;
  /** Grabs a single Shipping_Carrier object */
  shipping_carrier?: Maybe<Shipping_Carrier>;
  /** Grabs multiple Shipping_Carrier objects */
  shipping_carriers?: Maybe<Array<Maybe<Shipping_Carrier>>>;
  /** Grabs a single Shipping_PredefinedParcel object */
  shipping_predefined_parcel?: Maybe<Shipping_PredefinedParcel>;
  /** Grabs multiple Shipping_PredefinedParcel objects */
  shipping_predefined_parcels?: Maybe<Array<Maybe<Shipping_PredefinedParcel>>>;
  /** Grabs a single Shipping_ServiceLevel object */
  shipping_service_level?: Maybe<Shipping_ServiceLevel>;
  /** Grabs multiple Shipping_ServiceLevel objects */
  shipping_service_levels?: Maybe<Array<Maybe<Shipping_ServiceLevel>>>;
  /** Grabs a single Shipping_Shipment object */
  shipping_shipment?: Maybe<Shipping_Shipment>;
  /** Grabs a single Shipping_ShipmentOrder object */
  shipping_shipment_order?: Maybe<Shipping_ShipmentOrder>;
  /** Grabs multiple Shipping_ShipmentOrder objects */
  shipping_shipment_orders?: Maybe<Array<Maybe<Shipping_ShipmentOrder>>>;
  /** Grabs multiple Shipping_ShipmentSupplies objects */
  shipping_shipment_supplies?: Maybe<Array<Maybe<Shipping_ShipmentSupplies>>>;
  /** Grabs a single Shipping_ShipmentSupplies object */
  shipping_shipment_supply?: Maybe<Shipping_ShipmentSupplies>;
  /** Grabs multiple Shipping_Shipment objects */
  shipping_shipments?: Maybe<Array<Maybe<Shipping_Shipment>>>;
  /** Grabs a single Team_Team object */
  team_team?: Maybe<Team_Team>;
  /** Grabs a single Team_TeamMembers object */
  team_team_member?: Maybe<Team_TeamMembers>;
  /** Grabs multiple Team_TeamMembers objects */
  team_team_members?: Maybe<Array<Maybe<Team_TeamMembers>>>;
  /** Grabs multiple Team_Team objects */
  team_teams?: Maybe<Array<Maybe<Team_Team>>>;
  /** General test query to see if engine is working. */
  test: Scalars['String'];
  /** Tests the Any scalar. */
  test_any?: Maybe<Scalars['Any']>;
  /** Test query to make sure email is working. */
  test_email: Scalars['String'];
  /** Test query to make sure error catching and error email are working. Should always fail. */
  test_error?: Maybe<Scalars['Boolean']>;
  /** Test query to make sure json works. */
  test_json: Scalars['JSON'];
  /** Tests any scalar. */
  test_scalar?: Maybe<ScalarTestResponse>;
  /** Makes sure it can get the tenant. */
  test_tenant?: Maybe<Scalars['String']>;
  /** Grabs a single Timer_BaseTimer object */
  timer_base_timer?: Maybe<Timer_BaseTimer>;
  /** Grabs multiple Timer_BaseTimer objects */
  timer_base_timers?: Maybe<Array<Maybe<Timer_BaseTimer>>>;
  /** Grabs a single Users_User object */
  users_user?: Maybe<Users_User>;
  /** Grabs multiple Users_User objects */
  users_users?: Maybe<Array<Maybe<Users_User>>>;
  /** Grabs a single WorkOrder_WorkOrder object */
  work_order_work_order?: Maybe<WorkOrder_WorkOrder>;
  /** Grabs a single WorkOrder_WorkOrderItems object */
  work_order_work_order_item?: Maybe<WorkOrder_WorkOrderItems>;
  /** Grabs multiple WorkOrder_WorkOrderItems objects */
  work_order_work_order_items?: Maybe<Array<Maybe<WorkOrder_WorkOrderItems>>>;
  /** Grabs multiple WorkOrder_WorkOrder objects */
  work_order_work_orders?: Maybe<Array<Maybe<WorkOrder_WorkOrder>>>;
};


export type QueryEasyPost_RetrieveAddressArgs = {
  id: Scalars['ID'];
};


export type QueryEasyPost_RetrieveCarrierAccountArgs = {
  id: Scalars['ID'];
};


export type QueryEasyPost_RetrieveInsuranceArgs = {
  id: Scalars['ID'];
};


export type QueryEasyPost_RetrieveOrderArgs = {
  id: Scalars['ID'];
};


export type QueryEasyPost_RetrieveOrderRatesArgs = {
  id: Scalars['ID'];
};


export type QueryEasyPost_RetrieveParcelArgs = {
  id: Scalars['ID'];
};


export type QueryEasyPost_RetrievePickupArgs = {
  id: Scalars['ID'];
};


export type QueryEasyPost_RetrieveShipmentArgs = {
  id: Scalars['ID'];
};


export type QueryEasyPost_RetrieveShipmentLabelArgs = {
  file_format: Easypost_Label_Format;
  id: Scalars['EasyPostID'];
};


export type QueryEasyPost_RetrieveTrackerArgs = {
  id: Scalars['ID'];
};


export type QueryPrintNode_GetComputersArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryPrintNode_GetPrintJobStatesArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryPrintNode_GetPrintJobsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryPrintNode_GetPrintersArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryPrintNode_GetScalesArgs = {
  computer_id: Scalars['ID'];
};


export type QueryAccounting_AccountArgs = {
  input: SingleQueryInput;
};


export type QueryAccounting_AccountsArgs = {
  input: ManyQueryInput;
};


export type QueryAccounting_EntryArgs = {
  input: SingleQueryInput;
};


export type QueryAccounting_EntrysArgs = {
  input: ManyQueryInput;
};


export type QueryAccounting_Service_DiscountArgs = {
  input: SingleQueryInput;
};


export type QueryAccounting_Service_DiscountsArgs = {
  input: ManyQueryInput;
};


export type QueryAccounting_Software_DiscountArgs = {
  input: SingleQueryInput;
};


export type QueryAccounting_Software_DiscountsArgs = {
  input: ManyQueryInput;
};


export type QueryAccounting_TransactionArgs = {
  input: SingleQueryInput;
};


export type QueryAccounting_TransactionsArgs = {
  input: ManyQueryInput;
};


export type QueryAddress_AddressArgs = {
  input: SingleQueryInput;
};


export type QueryAddress_AddressesArgs = {
  input: ManyQueryInput;
};


export type QueryBreakdown_Break_DownArgs = {
  input: SingleQueryInput;
};


export type QueryBreakdown_Break_Down_ItemArgs = {
  input: SingleQueryInput;
};


export type QueryBreakdown_Break_Down_ItemsArgs = {
  input: ManyQueryInput;
};


export type QueryBreakdown_Break_DownsArgs = {
  input: ManyQueryInput;
};


export type QueryBuild_Up_Build_UpArgs = {
  input: SingleQueryInput;
};


export type QueryBuild_Up_Build_Up_ItemArgs = {
  input: SingleQueryInput;
};


export type QueryBuild_Up_Build_Up_ItemsArgs = {
  input: ManyQueryInput;
};


export type QueryBuild_Up_Build_UpsArgs = {
  input: ManyQueryInput;
};


export type QueryChoices_ChoiceArgs = {
  input: SingleQueryInput;
};


export type QueryChoices_ChoicesArgs = {
  input: ManyQueryInput;
};


export type QueryChoices_StatusArgs = {
  input: SingleQueryInput;
};


export type QueryChoices_StatusesArgs = {
  input: ManyQueryInput;
};


export type QueryChoices_TagArgs = {
  input: SingleQueryInput;
};


export type QueryChoices_TagsArgs = {
  input: ManyQueryInput;
};


export type QueryClients_ClientArgs = {
  input: SingleQueryInput;
};


export type QueryClients_ClientsArgs = {
  input: ManyQueryInput;
};


export type QueryClients_ContactArgs = {
  input: SingleQueryInput;
};


export type QueryClients_ContactsArgs = {
  input: ManyQueryInput;
};


export type QueryClients_Shipping_LabelArgs = {
  input: SingleQueryInput;
};


export type QueryClients_Shipping_LabelsArgs = {
  input: ManyQueryInput;
};


export type QueryClients_SubArgs = {
  input: SingleQueryInput;
};


export type QueryClients_SubsArgs = {
  input: ManyQueryInput;
};


export type QueryClients_TermArgs = {
  input: SingleQueryInput;
};


export type QueryClients_TermsArgs = {
  input: ManyQueryInput;
};


export type QueryCompany_Credit_CardArgs = {
  input: SingleQueryInput;
};


export type QueryCompany_Credit_CardsArgs = {
  input: ManyQueryInput;
};


export type QueryCompany_SubArgs = {
  input: SingleQueryInput;
};


export type QueryCompany_SubsArgs = {
  input: ManyQueryInput;
};


export type QueryConvert_MoneyArgs = {
  amount: Scalars['Price'];
  currency: Currency;
  target_currency: Currency;
};


export type QueryCurrencyArgs = {
  code: Currency;
};


export type QueryCustom_Permissions_Graphql_PermissionArgs = {
  input: SingleQueryInput;
};


export type QueryCustom_Permissions_Graphql_PermissionsArgs = {
  input: ManyQueryInput;
};


export type QueryCustom_Permissions_RoleArgs = {
  input: SingleQueryInput;
};


export type QueryCustom_Permissions_Role_PermissionArgs = {
  input: SingleQueryInput;
};


export type QueryCustom_Permissions_Role_PermissionsArgs = {
  input: ManyQueryInput;
};


export type QueryCustom_Permissions_RolesArgs = {
  input: ManyQueryInput;
};


export type QueryDepartment_DepartmentArgs = {
  input: SingleQueryInput;
};


export type QueryDepartment_Department_EmployeeArgs = {
  input: SingleQueryInput;
};


export type QueryDepartment_Department_EmployeesArgs = {
  input: ManyQueryInput;
};


export type QueryDepartment_Department_RoleArgs = {
  input: SingleQueryInput;
};


export type QueryDepartment_Department_RolesArgs = {
  input: ManyQueryInput;
};


export type QueryDepartment_DepartmentsArgs = {
  input: ManyQueryInput;
};


export type QueryGet_AggregateArgs = {
  aggregates: Array<AggregateInput>;
  model_name?: Maybe<Model_Enum>;
};


export type QueryGet_Exchange_RateArgs = {
  source: Currency;
  target: Currency;
};


export type QueryInventory_ItemArgs = {
  input: SingleQueryInput;
};


export type QueryInventory_Item__InterfaceArgs = {
  input: SingleQueryInput;
};


export type QueryInventory_ItemsArgs = {
  input: ManyQueryInput;
};


export type QueryInventory_Items__InterfaceArgs = {
  input: ManyQueryInput;
};


export type QueryInventory_ServiceArgs = {
  input: SingleQueryInput;
};


export type QueryInventory_ServicesArgs = {
  input: ManyQueryInput;
};


export type QueryInventory_SoftwareArgs = {
  input: SingleQueryInput;
};


export type QueryInventory_SoftwaresArgs = {
  input: ManyQueryInput;
};


export type QueryInventory_SystemArgs = {
  input: SingleQueryInput;
};


export type QueryInventory_SystemsArgs = {
  input: ManyQueryInput;
};


export type QueryInvoices_A_P_InvoiceArgs = {
  input: SingleQueryInput;
};


export type QueryInvoices_A_P_InvoicesArgs = {
  input: ManyQueryInput;
};


export type QueryInvoices_A_P_PaymentArgs = {
  input: SingleQueryInput;
};


export type QueryInvoices_A_P_PaymentsArgs = {
  input: ManyQueryInput;
};


export type QueryInvoices_A_R_InvoiceArgs = {
  input: SingleQueryInput;
};


export type QueryInvoices_A_R_InvoicesArgs = {
  input: ManyQueryInput;
};


export type QueryInvoices_A_R_PaymentArgs = {
  input: SingleQueryInput;
};


export type QueryInvoices_A_R_PaymentsArgs = {
  input: ManyQueryInput;
};


export type QueryLocation_LocationArgs = {
  input: SingleQueryInput;
};


export type QueryLocation_LocationsArgs = {
  input: ManyQueryInput;
};


export type QueryLocation_SiteArgs = {
  input: SingleQueryInput;
};


export type QueryLocation_SitesArgs = {
  input: ManyQueryInput;
};


export type QueryLogging_Action_LogArgs = {
  input: SingleQueryInput;
};


export type QueryLogging_Action_Log_ObjectArgs = {
  input: SingleQueryInput;
};


export type QueryLogging_Action_Log_ObjectsArgs = {
  input: ManyQueryInput;
};


export type QueryLogging_Action_LogsArgs = {
  input: ManyQueryInput;
};


export type QueryNotifications_NotificationArgs = {
  input: SingleQueryInput;
};


export type QueryNotifications_NotificationsArgs = {
  input: ManyQueryInput;
};


export type QueryParts_Access_PointArgs = {
  input: SingleQueryInput;
};


export type QueryParts_Access_PointsArgs = {
  input: ManyQueryInput;
};


export type QueryParts_Associated_PnArgs = {
  input: SingleQueryInput;
};


export type QueryParts_Associated_PnsArgs = {
  input: ManyQueryInput;
};


export type QueryParts_BatteryArgs = {
  input: SingleQueryInput;
};


export type QueryParts_BatterysArgs = {
  input: ManyQueryInput;
};


export type QueryParts_BezelArgs = {
  input: SingleQueryInput;
};


export type QueryParts_BezelsArgs = {
  input: ManyQueryInput;
};


export type QueryParts_BomArgs = {
  input: SingleQueryInput;
};


export type QueryParts_Bom_PartArgs = {
  input: SingleQueryInput;
};


export type QueryParts_Bom_PartsArgs = {
  input: ManyQueryInput;
};


export type QueryParts_BomsArgs = {
  input: ManyQueryInput;
};


export type QueryParts_BracketArgs = {
  input: SingleQueryInput;
};


export type QueryParts_BracketsArgs = {
  input: ManyQueryInput;
};


export type QueryParts_CableArgs = {
  input: SingleQueryInput;
};


export type QueryParts_CablesArgs = {
  input: ManyQueryInput;
};


export type QueryParts_CacheArgs = {
  input: SingleQueryInput;
};


export type QueryParts_CachesArgs = {
  input: ManyQueryInput;
};


export type QueryParts_CpuArgs = {
  input: SingleQueryInput;
};


export type QueryParts_CpusArgs = {
  input: ManyQueryInput;
};


export type QueryParts_Disk_DriveArgs = {
  input: SingleQueryInput;
};


export type QueryParts_Disk_DrivesArgs = {
  input: ManyQueryInput;
};


export type QueryParts_Expansion_CardArgs = {
  input: SingleQueryInput;
};


export type QueryParts_Expansion_CardsArgs = {
  input: ManyQueryInput;
};


export type QueryParts_FanArgs = {
  input: SingleQueryInput;
};


export type QueryParts_FansArgs = {
  input: ManyQueryInput;
};


export type QueryParts_GpuArgs = {
  input: SingleQueryInput;
};


export type QueryParts_GpusArgs = {
  input: ManyQueryInput;
};


export type QueryParts_HeatsinkArgs = {
  input: SingleQueryInput;
};


export type QueryParts_HeatsinksArgs = {
  input: ManyQueryInput;
};


export type QueryParts_Io_ModuleArgs = {
  input: SingleQueryInput;
};


export type QueryParts_Io_ModulesArgs = {
  input: ManyQueryInput;
};


export type QueryParts_MemoryArgs = {
  input: SingleQueryInput;
};


export type QueryParts_MemorysArgs = {
  input: ManyQueryInput;
};


export type QueryParts_MotherboardArgs = {
  input: SingleQueryInput;
};


export type QueryParts_MotherboardsArgs = {
  input: ManyQueryInput;
};


export type QueryParts_Network_CardArgs = {
  input: SingleQueryInput;
};


export type QueryParts_Network_CardsArgs = {
  input: ManyQueryInput;
};


export type QueryParts_PartArgs = {
  input: SingleQueryInput;
};


export type QueryParts_Part__InterfaceArgs = {
  input: SingleQueryInput;
};


export type QueryParts_PartsArgs = {
  input: ManyQueryInput;
};


export type QueryParts_Parts__InterfaceArgs = {
  input: ManyQueryInput;
};


export type QueryParts_PhoneArgs = {
  input: SingleQueryInput;
};


export type QueryParts_PhonesArgs = {
  input: ManyQueryInput;
};


export type QueryParts_Power_SupplyArgs = {
  input: SingleQueryInput;
};


export type QueryParts_Power_SupplysArgs = {
  input: ManyQueryInput;
};


export type QueryParts_Product_CompanyArgs = {
  input: SingleQueryInput;
};


export type QueryParts_Product_CompanysArgs = {
  input: ManyQueryInput;
};


export type QueryParts_RailArgs = {
  input: SingleQueryInput;
};


export type QueryParts_RailsArgs = {
  input: ManyQueryInput;
};


export type QueryParts_RouterArgs = {
  input: SingleQueryInput;
};


export type QueryParts_RoutersArgs = {
  input: ManyQueryInput;
};


export type QueryParts_ServerArgs = {
  input: SingleQueryInput;
};


export type QueryParts_ServersArgs = {
  input: ManyQueryInput;
};


export type QueryParts_StorageArgs = {
  input: SingleQueryInput;
};


export type QueryParts_Storage_EnclosureArgs = {
  input: SingleQueryInput;
};


export type QueryParts_Storage_EnclosuresArgs = {
  input: ManyQueryInput;
};


export type QueryParts_StoragesArgs = {
  input: ManyQueryInput;
};


export type QueryParts_SuppliesArgs = {
  input: ManyQueryInput;
};


export type QueryParts_SupplyArgs = {
  input: SingleQueryInput;
};


export type QueryParts_SwitchArgs = {
  input: SingleQueryInput;
};


export type QueryParts_SwitchsArgs = {
  input: ManyQueryInput;
};


export type QueryProfile_Live_TemplateArgs = {
  input: SingleQueryInput;
};


export type QueryProfile_Live_TemplatesArgs = {
  input: ManyQueryInput;
};


export type QueryProfile_ProfileArgs = {
  input: SingleQueryInput;
};


export type QueryProfile_Profile_MetricArgs = {
  input: SingleQueryInput;
};


export type QueryProfile_Profile_MetricsArgs = {
  input: ManyQueryInput;
};


export type QueryProfile_ProfilesArgs = {
  input: ManyQueryInput;
};


export type QueryProfile_Table_ConfigArgs = {
  input: SingleQueryInput;
};


export type QueryProfile_Table_ConfigsArgs = {
  input: ManyQueryInput;
};


export type QueryPurchases_PurchaseArgs = {
  input: SingleQueryInput;
};


export type QueryPurchases_Purchased_ItemArgs = {
  input: SingleQueryInput;
};


export type QueryPurchases_Purchased_ItemsArgs = {
  input: ManyQueryInput;
};


export type QueryPurchases_PurchasesArgs = {
  input: ManyQueryInput;
};


export type QueryQuotes_QuoteArgs = {
  input: SingleQueryInput;
};


export type QueryQuotes_Quote_PartArgs = {
  input: SingleQueryInput;
};


export type QueryQuotes_Quote_PartsArgs = {
  input: ManyQueryInput;
};


export type QueryQuotes_QuotesArgs = {
  input: ManyQueryInput;
};


export type QueryReporting_ReportArgs = {
  input: SingleQueryInput;
};


export type QueryReporting_ReportsArgs = {
  input: ManyQueryInput;
};


export type QueryRma_Purchase_RmaArgs = {
  input: SingleQueryInput;
};


export type QueryRma_Purchase_Rma_ItemArgs = {
  input: SingleQueryInput;
};


export type QueryRma_Purchase_Rma_ItemsArgs = {
  input: ManyQueryInput;
};


export type QueryRma_Purchase_RmasArgs = {
  input: ManyQueryInput;
};


export type QueryRma_RmaArgs = {
  input: SingleQueryInput;
};


export type QueryRma_Rma_ItemArgs = {
  input: SingleQueryInput;
};


export type QueryRma_Rma_ItemsArgs = {
  input: ManyQueryInput;
};


export type QueryRma_RmasArgs = {
  input: ManyQueryInput;
};


export type QuerySales_FeeArgs = {
  input: SingleQueryInput;
};


export type QuerySales_FeesArgs = {
  input: ManyQueryInput;
};


export type QuerySales_SaleArgs = {
  input: SingleQueryInput;
};


export type QuerySales_SalesArgs = {
  input: ManyQueryInput;
};


export type QuerySales_Sold_ItemArgs = {
  input: SingleQueryInput;
};


export type QuerySales_Sold_ItemsArgs = {
  input: ManyQueryInput;
};


export type QuerySales_Sold_ServiceArgs = {
  input: SingleQueryInput;
};


export type QuerySales_Sold_ServicesArgs = {
  input: ManyQueryInput;
};


export type QuerySales_Sold_SoftwareArgs = {
  input: SingleQueryInput;
};


export type QuerySales_Sold_SoftwaresArgs = {
  input: ManyQueryInput;
};


export type QueryShipping_AccountArgs = {
  input: SingleQueryInput;
};


export type QueryShipping_AccountsArgs = {
  input: ManyQueryInput;
};


export type QueryShipping_CarrierArgs = {
  input: SingleQueryInput;
};


export type QueryShipping_CarriersArgs = {
  input: ManyQueryInput;
};


export type QueryShipping_Predefined_ParcelArgs = {
  input: SingleQueryInput;
};


export type QueryShipping_Predefined_ParcelsArgs = {
  input: ManyQueryInput;
};


export type QueryShipping_Service_LevelArgs = {
  input: SingleQueryInput;
};


export type QueryShipping_Service_LevelsArgs = {
  input: ManyQueryInput;
};


export type QueryShipping_ShipmentArgs = {
  input: SingleQueryInput;
};


export type QueryShipping_Shipment_OrderArgs = {
  input: SingleQueryInput;
};


export type QueryShipping_Shipment_OrdersArgs = {
  input: ManyQueryInput;
};


export type QueryShipping_Shipment_SuppliesArgs = {
  input: ManyQueryInput;
};


export type QueryShipping_Shipment_SupplyArgs = {
  input: SingleQueryInput;
};


export type QueryShipping_ShipmentsArgs = {
  input: ManyQueryInput;
};


export type QueryTeam_TeamArgs = {
  input: SingleQueryInput;
};


export type QueryTeam_Team_MemberArgs = {
  input: SingleQueryInput;
};


export type QueryTeam_Team_MembersArgs = {
  input: ManyQueryInput;
};


export type QueryTeam_TeamsArgs = {
  input: ManyQueryInput;
};


export type QueryTest_AnyArgs = {
  input: Scalars['Any'];
};


export type QueryTest_JsonArgs = {
  input: Scalars['JSON'];
};


export type QueryTest_ScalarArgs = {
  input: ScalarTestInput;
};


export type QueryTimer_Base_TimerArgs = {
  input: SingleQueryInput;
};


export type QueryTimer_Base_TimersArgs = {
  input: ManyQueryInput;
};


export type QueryUsers_UserArgs = {
  input: SingleQueryInput;
};


export type QueryUsers_UsersArgs = {
  input: ManyQueryInput;
};


export type QueryWork_Order_Work_OrderArgs = {
  input: SingleQueryInput;
};


export type QueryWork_Order_Work_Order_ItemArgs = {
  input: SingleQueryInput;
};


export type QueryWork_Order_Work_Order_ItemsArgs = {
  input: ManyQueryInput;
};


export type QueryWork_Order_Work_OrdersArgs = {
  input: ManyQueryInput;
};

/** This model keeps track of quotes and their relevant data. */
export type Quotes_Quote = {
  __typename?: 'Quotes_Quote';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  attached_purchases?: Maybe<Array<Purchases_Purchase>>;
  attached_sales?: Maybe<Array<Sales_Sale>>;
  client?: Maybe<Clients_Client>;
  contact?: Maybe<Clients_Contact>;
  contract_comment: Scalars['String'];
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  /** How much the customer is in need of the product on a scale of 0-5. */
  desire_level: Scalars['Rating'];
  id: Scalars['ID'];
  internal_comment: Scalars['String'];
  parts?: Maybe<Array<Quotes_QuoteParts>>;
  /** How good the pricing is on the quote on a scale of 0-5. */
  price_grade: Scalars['Rating'];
  purchaser_address?: Maybe<Address_Address>;
  return_address?: Maybe<Address_Address>;
  ship_from_address?: Maybe<Address_Address>;
  ship_to_address?: Maybe<Address_Address>;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  total_price?: Maybe<Money>;
  type: Scalars['String'];
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};


/** This model keeps track of quotes and their relevant data. */
export type Quotes_QuoteAttached_PurchasesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model keeps track of quotes and their relevant data. */
export type Quotes_QuoteAttached_SalesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model keeps track of quotes and their relevant data. */
export type Quotes_QuotePartsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model keeps track of parts attached to a base quote */
export type Quotes_QuoteParts = {
  __typename?: 'Quotes_QuoteParts';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  id: Scalars['ID'];
  part?: Maybe<Parts_Part>;
  price_per_part?: Maybe<Money>;
  qty: Scalars['PositiveInteger'];
  quote: Quotes_Quote;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  total?: Maybe<Money>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

/** This model keeps track of any RMAs that they(Company) have with parts they bought. */
export type Rma_PurchaseRma = {
  __typename?: 'RMA_PurchaseRMA';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  claim_date?: Maybe<Scalars['Date']>;
  closed_date?: Maybe<Scalars['Date']>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  id: Scalars['ID'];
  pt: Purchases_Purchase;
  purchase_rma_items?: Maybe<Array<Rma_PurchaseRmaItems>>;
  received_date?: Maybe<Scalars['Date']>;
  shipment_order?: Maybe<Shipping_ShipmentOrder>;
  status: Choices_Status;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
  voided_date?: Maybe<Scalars['Date']>;
};


/** This model keeps track of any RMAs that they(Company) have with parts they bought. */
export type Rma_PurchaseRmaPurchase_Rma_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model links Inventory.Items to PurchaseRMAs and keeps track of any relevant data. */
export type Rma_PurchaseRmaItems = {
  __typename?: 'RMA_PurchaseRMAItems';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  credit: Scalars['Boolean'];
  id: Scalars['ID'];
  returned_part: Inventory_Item;
  rma: Rma_PurchaseRma;
  shipment?: Maybe<Shipping_Shipment>;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  tested_condition?: Maybe<Choices_Choice>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

/** This model keeps track of RMAs and any relevant data. */
export type Rma_Rma = {
  __typename?: 'RMA_RMA';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  assigned_to?: Maybe<Users_User>;
  claim_date?: Maybe<Scalars['Date']>;
  closed_by?: Maybe<Users_User>;
  closed_date?: Maybe<Scalars['DateTime']>;
  contact?: Maybe<Clients_Contact>;
  contract_comment: Scalars['String'];
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  id: Scalars['ID'];
  internal_comment: Scalars['String'];
  received_by?: Maybe<Users_User>;
  received_date?: Maybe<Scalars['DateTime']>;
  return_code?: Maybe<Choices_Choice>;
  return_reason: Scalars['String'];
  rma_items?: Maybe<Array<Rma_RmaItems>>;
  shipment_order?: Maybe<Shipping_ShipmentOrder>;
  st: Sales_Sale;
  status: Choices_Status;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  tested_by?: Maybe<Users_User>;
  tested_date?: Maybe<Scalars['DateTime']>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
  verified_by?: Maybe<Users_User>;
  verified_date?: Maybe<Scalars['DateTime']>;
  voided_by?: Maybe<Users_User>;
  voided_date?: Maybe<Scalars['DateTime']>;
  warranty_honored_after_cut_off: Scalars['Boolean'];
};


/** This model keeps track of RMAs and any relevant data. */
export type Rma_RmaRma_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model links Inventory.Items to RMAs and keeps track of any relevant data. */
export type Rma_RmaItems = {
  __typename?: 'RMA_RMAItems';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  credit: Scalars['Boolean'];
  id: Scalars['ID'];
  returned_part: Inventory_Item;
  rma: Rma_Rma;
  shipment?: Maybe<Shipping_Shipment>;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  tested_condition?: Maybe<Choices_Choice>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

export type Reporting_Report = {
  __typename?: 'Reporting_Report';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** This stores ChartJS settings to use instead of the defaults. */
  chart_data: Scalars['JSON'];
  /** What kind of chart it is. */
  chart_type: Scalars['String'];
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  /** The data from the query. */
  data: Scalars['JSON'];
  /** A description of the report. */
  description: Scalars['String'];
  /** Who favorited this report. */
  favorites?: Maybe<Array<Users_User>>;
  id: Scalars['ID'];
  is_list: Scalars['Boolean'];
  /** What is the name of the report. */
  name: Scalars['String'];
  /** The query to use for the report. */
  query: Scalars['String'];
  /** Who this report is for. */
  recipients?: Maybe<Array<Users_User>>;
  /** What Model the report is for. */
  report_model: Scalars['String'];
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

/** This model links Fees to a Sale. */
export type Sales_Fees = {
  __typename?: 'Sales_Fees';
  /** The amount of the fee. */
  amount?: Maybe<Money>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  /** A description for the fee. */
  description: Scalars['String'];
  id: Scalars['ID'];
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** The order this fee is on. */
  transaction: Sales_Sale;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

/** This model keeps track of Sales and any relevant data. */
export type Sales_Sale = {
  __typename?: 'Sales_Sale';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** Who was assigned to pick this order. */
  assigned_to?: Maybe<Users_User>;
  attached_invoices?: Maybe<Array<Invoices_ArInvoice>>;
  /** The date the order was booked. */
  book_date?: Maybe<Scalars['DateTime']>;
  /** The client this order was made to/from. */
  client?: Maybe<Clients_Client>;
  /** The client's order number. */
  client_order_number: Scalars['String'];
  /** The condition of the parts on this order. */
  condition?: Maybe<Choices_Choice>;
  /** The person to contact for this order. */
  contact?: Maybe<Clients_Contact>;
  /** Comments to put on the contract for this order. */
  contract_comment: Scalars['String'];
  /** When the contract needs to be sent by. */
  contract_send_by_date?: Maybe<Scalars['Date']>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  /** The declared value of the order. */
  declared_value?: Maybe<Money>;
  /** The deposit amount for the order. */
  deposit?: Maybe<Money>;
  /** The down payment amount for this order. */
  down_payment?: Maybe<Money>;
  /** If editing is locked for this object or not. */
  edit_lock: Scalars['Boolean'];
  /** The estimated cost of the order. */
  estimated_cost?: Maybe<Money>;
  /** The estimated margin on the order. */
  estimated_margin?: Maybe<Money>;
  /** The estimated total part cost of this order. */
  estimated_part_total?: Maybe<Money>;
  /** The estimated total cost of this order. */
  estimated_total?: Maybe<Money>;
  /** The cost of freight for this order. */
  freight?: Maybe<Money>;
  id: Scalars['ID'];
  /** The cost of installing equipment for the customer. */
  installation_cost?: Maybe<Money>;
  /** Comments on this order for internal use. */
  internal_comment: Scalars['String'];
  /** Miscellaneous charges on this order. */
  misc?: Maybe<Money>;
  /** The date the order was promised by. */
  promise_date?: Maybe<Scalars['DateTime']>;
  /** The quote used for creating this order. */
  quote?: Maybe<Quotes_Quote>;
  /** Who's order this is. */
  rep?: Maybe<Users_User>;
  rmas?: Maybe<Array<Rma_Rma>>;
  /** The date the order was made. */
  sale_date?: Maybe<Scalars['DateTime']>;
  /** The date the order needs to ship by. */
  ship_by_date?: Maybe<Scalars['Date']>;
  /** The Shipment Order this order is tied to. */
  shipment_order?: Maybe<Shipping_ShipmentOrder>;
  st_fees?: Maybe<Array<Sales_Fees>>;
  st_items?: Maybe<Array<Sales_SoldItems>>;
  st_services?: Maybe<Array<Sales_SoldServices>>;
  st_software?: Maybe<Array<Sales_SoldSoftware>>;
  status: Choices_Status;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** The tax amount for this order. */
  tax?: Maybe<Money>;
  /** The terms for this order. */
  terms?: Maybe<Clients_Terms>;
  /** Who was assigned to test this order. */
  tester?: Maybe<Users_User>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
  /** The date that the warranty expires. */
  warranty_end_date?: Maybe<Scalars['Date']>;
  /** If we pay the installation cost. */
  we_install: Scalars['Boolean'];
  /** If we arrange transportation. */
  we_transport: Scalars['Boolean'];
};


/** This model keeps track of Sales and any relevant data. */
export type Sales_SaleAttached_InvoicesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model keeps track of Sales and any relevant data. */
export type Sales_SaleRmasArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model keeps track of Sales and any relevant data. */
export type Sales_SaleSt_FeesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model keeps track of Sales and any relevant data. */
export type Sales_SaleSt_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model keeps track of Sales and any relevant data. */
export type Sales_SaleSt_ServicesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model keeps track of Sales and any relevant data. */
export type Sales_SaleSt_SoftwareArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model links Inventory.Items to Sales and keeps track of any relevant data. */
export type Sales_SoldItems = {
  __typename?: 'Sales_SoldItems';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** The buyer's margin. */
  buyer_margin?: Maybe<Money>;
  /** The percentage of the margin that goes to the purchasing rep. */
  buyer_margin_percentage: Scalars['Percent'];
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  /** If editing is locked for this object or not. */
  edit_lock: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Returns True if the sub is valid. */
  is_sub_valid?: Maybe<Scalars['Boolean']>;
  /** The Item on the order. */
  item?: Maybe<Inventory_Item>;
  /** The Item's line on the order. */
  line_number: Scalars['PositiveInteger'];
  /** The margin on this Item. */
  margin?: Maybe<Money>;
  /** What the Item was originally bought/sold as. */
  original_part?: Maybe<Parts_Part>;
  /** What the Item is being bought/sold as. */
  part: Parts_Part;
  /** The seller's margin. */
  seller_margin?: Maybe<Money>;
  /** The percentage of the margin that goes to the selling rep. */
  seller_margin_percentage: Scalars['Percent'];
  /** What Shipment this Item is on. */
  shipment?: Maybe<Shipping_Shipment>;
  /** The amount this Item was sold for. */
  sold_for?: Maybe<Money>;
  status: Choices_Status;
  /** If the part is swapped or not, and if so what kind of swap. */
  swap_type: Scalars['String'];
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** The tax value of this Item. */
  tax?: Maybe<Money>;
  /** The order this Item was sold on. */
  transaction: Sales_Sale;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

/** This model links Inventory.Services to Sales and keeps track of any relevant data. */
export type Sales_SoldServices = {
  __typename?: 'Sales_SoldServices';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  /** If editing is locked for this object. */
  edit_lock: Scalars['Boolean'];
  id: Scalars['ID'];
  /** How many periods this product was sold for. */
  periods_sold_for: Scalars['PositiveInteger'];
  /** How many items of this product were sold. */
  quantity: Scalars['PositiveInteger'];
  /** The Service that was sold. */
  service: Inventory_Service;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** The tax value of this product. */
  tax?: Maybe<Money>;
  /** The total value of this product. */
  total?: Maybe<Money>;
  /** The order this Service was sold on. */
  transaction: Sales_Sale;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

/** This model links Inventory.Software to Sales and keeps track of any relevant data. */
export type Sales_SoldSoftware = {
  __typename?: 'Sales_SoldSoftware';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  /** If editing is locked for this object. */
  edit_lock: Scalars['Boolean'];
  id: Scalars['ID'];
  /** How many periods this product was sold for. */
  periods_sold_for: Scalars['PositiveInteger'];
  /** How many items of this product were sold. */
  quantity: Scalars['PositiveInteger'];
  /** The Software that was sold. */
  software: Inventory_Software;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** The tax value of this product. */
  tax?: Maybe<Money>;
  /** The total value of this product. */
  total?: Maybe<Money>;
  /** The order this Software was sold on. */
  transaction: Sales_Sale;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

export type ScalarTestResponse = {
  __typename?: 'ScalarTestResponse';
  any?: Maybe<Scalars['Any']>;
  id?: Maybe<Scalars['ID']>;
};

/** This model represents a carrier account. */
export type Shipping_Account = {
  __typename?: 'Shipping_Account';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  client?: Maybe<Clients_Client>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  default_shipping_account_for_client?: Maybe<Clients_Client>;
  default_shipping_account_for_company?: Maybe<Company_Company>;
  description: Scalars['String'];
  easypost_id?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  number: Scalars['String'];
  rep?: Maybe<Array<Users_User>>;
  shipmentorder?: Maybe<Array<Shipping_ShipmentOrder>>;
  shipper: Shipping_Carrier;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};


/** This model represents a carrier account. */
export type Shipping_AccountShipmentorderArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model keeps track of Carriers(FedEx, UPS, USPS, etc) and any relevant data. */
export type Shipping_Carrier = {
  __typename?: 'Shipping_Carrier';
  accounts?: Maybe<Array<Shipping_Account>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  easypost_name: Scalars['String'];
  id: Scalars['ID'];
  is_courier: Scalars['Boolean'];
  is_easypost: Scalars['Boolean'];
  name: Scalars['String'];
  services?: Maybe<Array<Shipping_ServiceLevel>>;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};


/** This model keeps track of Carriers(FedEx, UPS, USPS, etc) and any relevant data. */
export type Shipping_CarrierAccountsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This model keeps track of Carriers(FedEx, UPS, USPS, etc) and any relevant data. */
export type Shipping_CarrierServicesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/**
 * This is for predefined parcel types.
 * Meaning boxes. Like '14x14x14' or '20x20x14'
 */
export type Shipping_PredefinedParcel = {
  __typename?: 'Shipping_PredefinedParcel';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  height_in: Scalars['Float'];
  id: Scalars['ID'];
  length_in: Scalars['Float'];
  name: Scalars['String'];
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
  width_in: Scalars['Float'];
};

/**
 * This represents a service level offered by a carrier.
 * Ground, 2Day, etc...
 */
export type Shipping_ServiceLevel = {
  __typename?: 'Shipping_ServiceLevel';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  color: Scalars['String'];
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  default_shipping_service_for_client?: Maybe<Clients_Client>;
  default_shipping_service_for_company?: Maybe<Company_Company>;
  easypost_name: Scalars['String'];
  id: Scalars['ID'];
  order_arrive_by?: Maybe<Scalars['CustomTime']>;
  order_ready_by?: Maybe<Scalars['CustomTime']>;
  service: Scalars['String'];
  shipmentorder?: Maybe<Array<Shipping_ShipmentOrder>>;
  shipper: Shipping_Carrier;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};


/**
 * This represents a service level offered by a carrier.
 * Ground, 2Day, etc...
 */
export type Shipping_ServiceLevelShipmentorderArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/**
 * This links Purchased/Sold Items to Accounts and Services and keeps track of any
 * relevant data.
 * A Shipment should be thought of like each box.
 */
export type Shipping_Shipment = {
  __typename?: 'Shipping_Shipment';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  billed_cost?: Maybe<Money>;
  box_number?: Maybe<Scalars['PositiveInteger']>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  easypost_id?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insurance_cost?: Maybe<Money>;
  insurance_status: Scalars['String'];
  order: Shipping_ShipmentOrder;
  parcel_height_in: Scalars['Float'];
  parcel_length_in: Scalars['Float'];
  parcel_weight_oz: Scalars['Float'];
  parcel_width_in: Scalars['Float'];
  payment_status: Scalars['String'];
  predefined_parcel: Scalars['String'];
  purchase_rmas?: Maybe<Array<Rma_PurchaseRmaItems>>;
  purchases_items_shipment?: Maybe<Purchases_PurchasedItems>;
  refund_status: Scalars['String'];
  release_number: Scalars['String'];
  rmas?: Maybe<Array<Rma_RmaItems>>;
  sales_items_shipment?: Maybe<Sales_SoldItems>;
  shipment_cost?: Maybe<Money>;
  shipment_supplies?: Maybe<Array<Shipping_ShipmentSupplies>>;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  tracking_number: Scalars['String'];
  tracking_status: Scalars['String'];
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};


/**
 * This links Purchased/Sold Items to Accounts and Services and keeps track of any
 * relevant data.
 * A Shipment should be thought of like each box.
 */
export type Shipping_ShipmentPurchase_RmasArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This links Purchased/Sold Items to Accounts and Services and keeps track of any
 * relevant data.
 * A Shipment should be thought of like each box.
 */
export type Shipping_ShipmentRmasArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This links Purchased/Sold Items to Accounts and Services and keeps track of any
 * relevant data.
 * A Shipment should be thought of like each box.
 */
export type Shipping_ShipmentShipment_SuppliesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** A ShipmentOrder is a way to collect shipments together that belong on one order */
export type Shipping_ShipmentOrder = {
  __typename?: 'Shipping_ShipmentOrder';
  account?: Maybe<Shipping_Account>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  assigned_to?: Maybe<Users_User>;
  attached_purchase?: Maybe<Purchases_Purchase>;
  attached_purchase_rma?: Maybe<Rma_PurchaseRma>;
  attached_rma?: Maybe<Rma_Rma>;
  attached_sale?: Maybe<Sales_Sale>;
  attached_work_order?: Maybe<WorkOrder_WorkOrder>;
  blind: Scalars['String'];
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  deliver_by_date?: Maybe<Scalars['Date']>;
  easypost_id?: Maybe<Scalars['String']>;
  has_enough_shipments: Scalars['Boolean'];
  id: Scalars['ID'];
  is_international?: Maybe<Scalars['Boolean']>;
  pickup_type: Scalars['String'];
  purchaser_address?: Maybe<Address_Address>;
  purchaser_contact?: Maybe<Clients_Contact>;
  purchaser_rep?: Maybe<Users_User>;
  release_number: Scalars['String'];
  return_address?: Maybe<Address_Address>;
  return_contact?: Maybe<Clients_Contact>;
  return_rep?: Maybe<Users_User>;
  service?: Maybe<Shipping_ServiceLevel>;
  ship_from_address?: Maybe<Address_Address>;
  ship_from_contact?: Maybe<Clients_Contact>;
  ship_from_rep?: Maybe<Users_User>;
  ship_to_address?: Maybe<Address_Address>;
  ship_to_contact?: Maybe<Clients_Contact>;
  ship_to_rep?: Maybe<Users_User>;
  /** The type of shipment order. */
  shipment_order_type?: Maybe<Scalars['String']>;
  shipments?: Maybe<Array<Shipping_Shipment>>;
  shipping_comments: Scalars['String'];
  status: Choices_Status;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  total_billed_cost: Money;
  total_shipment_cost: Money;
  tracking_email_recipients?: Maybe<Array<Scalars['Email']>>;
  tracking_number: Scalars['String'];
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
  we_pay: Scalars['Boolean'];
};


/** A ShipmentOrder is a way to collect shipments together that belong on one order */
export type Shipping_ShipmentOrderShipmentsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model links supplies(Inventory.Items) to shipments. (Boxes, bubbles, etc) */
export type Shipping_ShipmentSupplies = {
  __typename?: 'Shipping_ShipmentSupplies';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  id: Scalars['ID'];
  shipment: Shipping_Shipment;
  supplies: Inventory_Item;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

/**
 * This model keeps track of Teams and any relevant data.
 * A team is a group of people (Sales rep, CSR, Tech)
 */
export type Team_Team = {
  __typename?: 'Team_Team';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  id: Scalars['ID'];
  livetemplates?: Maybe<Array<Profile_LiveTemplate>>;
  members?: Maybe<Array<Team_TeamMembers>>;
  name: Scalars['String'];
  notes: Scalars['String'];
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};


/**
 * This model keeps track of Teams and any relevant data.
 * A team is a group of people (Sales rep, CSR, Tech)
 */
export type Team_TeamLivetemplatesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/**
 * This model keeps track of Teams and any relevant data.
 * A team is a group of people (Sales rep, CSR, Tech)
 */
export type Team_TeamMembersArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model links Users to Teams and keeps track of any relevant data. */
export type Team_TeamMembers = {
  __typename?: 'Team_TeamMembers';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  id: Scalars['ID'];
  member: Users_User;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  team: Team_Team;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
};

/** Abstract model for Timer models to inherit from. */
export type Timer_BaseTimer = {
  __typename?: 'Timer_BaseTimer';
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** A short description of the timer. */
  description: Scalars['String'];
  /** If the timer is enabled. */
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  /** When the timer was last ran. */
  last_ran?: Maybe<Scalars['DateTime']>;
  /** A short and unique name for the timer. */
  name: Scalars['String'];
  /** The ID/PK of the object for the timer. */
  object_pk: Scalars['PositiveInteger'];
  /** How often the timer is supposed to be run. Minimum unit is an hour. */
  period: Scalars['Duration'];
  /** What sort of timer it is. */
  timer_type: Scalars['String'];
  /** How many times the timer has been run. */
  times_run: Scalars['PositiveInteger'];
  /** How many times to run it. 0 means forever. */
  times_to_run: Scalars['PositiveInteger'];
};

/** This is the base User model. */
export type Users_User = {
  __typename?: 'Users_User';
  accounting_account_created?: Maybe<Array<Accounting_Account>>;
  accounting_entry_created?: Maybe<Array<Accounting_Entry>>;
  accounting_servicediscount_created?: Maybe<Array<Accounting_ServiceDiscount>>;
  accounting_softwarediscount_created?: Maybe<Array<Accounting_SoftwareDiscount>>;
  accounting_transaction_created?: Maybe<Array<Accounting_Transaction>>;
  address_address_created?: Maybe<Array<Address_Address>>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  approved_pts?: Maybe<Array<Purchases_Purchase>>;
  attached_actions?: Maybe<Array<Logging_ActionLog>>;
  attached_clients?: Maybe<Array<Clients_Client>>;
  attached_notifications?: Maybe<Array<Notifications_Notification>>;
  breakdown_breakdown_created?: Maybe<Array<Breakdown_BreakDown>>;
  breakdown_breakdownitems_created?: Maybe<Array<Breakdown_BreakDownItems>>;
  buildup_buildup_created?: Maybe<Array<BuildUp_BuildUp>>;
  buildup_buildupitems_created?: Maybe<Array<BuildUp_BuildUpItems>>;
  checked_out_items?: Maybe<Array<Inventory_Item>>;
  claimed_breakdown?: Maybe<Array<Breakdown_BreakDown>>;
  claimed_buildup?: Maybe<Array<BuildUp_BuildUp>>;
  claimed_purchase?: Maybe<Array<Purchases_Purchase>>;
  claimed_sale?: Maybe<Array<Sales_Sale>>;
  claimed_shipmentorder?: Maybe<Array<Shipping_ShipmentOrder>>;
  clients_client_created?: Maybe<Array<Clients_Client>>;
  clients_contact_created?: Maybe<Array<Clients_Contact>>;
  clients_shippinglabel_created?: Maybe<Array<Clients_ShippingLabel>>;
  clients_subs_created?: Maybe<Array<Clients_Subs>>;
  clients_terms_created?: Maybe<Array<Clients_Terms>>;
  company_creditcard_created?: Maybe<Array<Company_CreditCard>>;
  company_subs_created?: Maybe<Array<Company_Subs>>;
  custompermissions_role_created?: Maybe<Array<CustomPermissions_Role>>;
  custompermissions_rolepermissions_created?: Maybe<Array<CustomPermissions_RolePermissions>>;
  date_joined: Scalars['DateTime'];
  department_department_created?: Maybe<Array<Department_Department>>;
  department_departmentemployees_created?: Maybe<Array<Department_DepartmentEmployees>>;
  department_departmentroles_created?: Maybe<Array<Department_DepartmentRoles>>;
  departments_m2m?: Maybe<Array<Department_DepartmentEmployees>>;
  email: Scalars['Email'];
  favorite_reports?: Maybe<Array<Reporting_Report>>;
  first_name: Scalars['String'];
  full_name: Scalars['String'];
  id: Scalars['ID'];
  initials: Scalars['String'];
  inventory_item_created?: Maybe<Array<Inventory_Item>>;
  inventory_service_created?: Maybe<Array<Inventory_Service>>;
  inventory_software_created?: Maybe<Array<Inventory_Software>>;
  invoices_apinvoice_created?: Maybe<Array<Invoices_ApInvoice>>;
  invoices_appayment_created?: Maybe<Array<Invoices_ApPayment>>;
  invoices_arinvoice_created?: Maybe<Array<Invoices_ArInvoice>>;
  invoices_arpayment_created?: Maybe<Array<Invoices_ArPayment>>;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active: Scalars['Boolean'];
  /** If the User is a developer. */
  is_dev: Scalars['Boolean'];
  /** If the User is for an Integration. */
  is_integration: Scalars['Boolean'];
  /** If the user is the owner of the instance. */
  is_owner: Scalars['Boolean'];
  /** Designates whether the user can log into this admin site. */
  is_staff: Scalars['Boolean'];
  /** Designates that this user has all permissions without explicitly assigning them. */
  is_superuser: Scalars['Boolean'];
  last_login?: Maybe<Scalars['DateTime']>;
  last_name: Scalars['String'];
  location_location_created?: Maybe<Array<Location_Location>>;
  location_site_created?: Maybe<Array<Location_Site>>;
  parts_bom_created?: Maybe<Array<Parts_Bom>>;
  parts_bomparts_created?: Maybe<Array<Parts_BomParts>>;
  parts_received?: Maybe<Array<Inventory_Item>>;
  permissions?: Maybe<Array<CustomPermissions_RolePermissions>>;
  profile?: Maybe<Profile_Profile>;
  profile_livetemplate_created?: Maybe<Array<Profile_LiveTemplate>>;
  profile_tableconfig_created?: Maybe<Array<Profile_TableConfig>>;
  purchase_contracts_sent?: Maybe<Array<Purchases_Purchase>>;
  purchaser_shipments?: Maybe<Array<Shipping_ShipmentOrder>>;
  purchases?: Maybe<Array<Purchases_Purchase>>;
  purchases_purchase_created?: Maybe<Array<Purchases_Purchase>>;
  purchases_purchaseditems_created?: Maybe<Array<Purchases_PurchasedItems>>;
  quotes_quote_created?: Maybe<Array<Quotes_Quote>>;
  quotes_quoteparts_created?: Maybe<Array<Quotes_QuoteParts>>;
  reporting_report_created?: Maybe<Array<Reporting_Report>>;
  reports_to_receive?: Maybe<Array<Reporting_Report>>;
  return_shipments?: Maybe<Array<Shipping_ShipmentOrder>>;
  rma_purchaserma_created?: Maybe<Array<Rma_PurchaseRma>>;
  rma_purchasermaitems_created?: Maybe<Array<Rma_PurchaseRmaItems>>;
  rma_rma_created?: Maybe<Array<Rma_Rma>>;
  rma_rmaitems_created?: Maybe<Array<Rma_RmaItems>>;
  rmas_assigned?: Maybe<Array<Rma_Rma>>;
  rmas_closed?: Maybe<Array<Rma_Rma>>;
  rmas_received?: Maybe<Array<Rma_Rma>>;
  rmas_tested?: Maybe<Array<Rma_Rma>>;
  rmas_verified?: Maybe<Array<Rma_Rma>>;
  rmas_voided?: Maybe<Array<Rma_Rma>>;
  roles?: Maybe<Array<CustomPermissions_Role>>;
  sales?: Maybe<Array<Sales_Sale>>;
  sales_fees_created?: Maybe<Array<Sales_Fees>>;
  sales_sale_created?: Maybe<Array<Sales_Sale>>;
  sales_solditems_created?: Maybe<Array<Sales_SoldItems>>;
  sales_soldservices_created?: Maybe<Array<Sales_SoldServices>>;
  sales_soldsoftware_created?: Maybe<Array<Sales_SoldSoftware>>;
  ship_from_shipments?: Maybe<Array<Shipping_ShipmentOrder>>;
  ship_to_shipments?: Maybe<Array<Shipping_ShipmentOrder>>;
  shipping_account_created?: Maybe<Array<Shipping_Account>>;
  shipping_accounts?: Maybe<Array<Shipping_Account>>;
  shipping_carrier_created?: Maybe<Array<Shipping_Carrier>>;
  shipping_predefinedparcel_created?: Maybe<Array<Shipping_PredefinedParcel>>;
  shipping_servicelevel_created?: Maybe<Array<Shipping_ServiceLevel>>;
  shipping_shipment_created?: Maybe<Array<Shipping_Shipment>>;
  shipping_shipmentorder_created?: Maybe<Array<Shipping_ShipmentOrder>>;
  shipping_shipmentsupplies_created?: Maybe<Array<Shipping_ShipmentSupplies>>;
  subordinates?: Maybe<Array<Profile_Profile>>;
  team_team_created?: Maybe<Array<Team_Team>>;
  team_teammembers_created?: Maybe<Array<Team_TeamMembers>>;
  teams_m2m?: Maybe<Array<Team_TeamMembers>>;
  tester_for_purchase?: Maybe<Array<Purchases_Purchase>>;
  tester_for_sale?: Maybe<Array<Sales_Sale>>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String'];
  workorder_workorder_created?: Maybe<Array<WorkOrder_WorkOrder>>;
  workorder_workorderitems_created?: Maybe<Array<WorkOrder_WorkOrderItems>>;
};


/** This is the base User model. */
export type Users_UserAccounting_Account_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserAccounting_Entry_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserAccounting_Servicediscount_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserAccounting_Softwarediscount_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserAccounting_Transaction_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserAddress_Address_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserApproved_PtsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserAttached_ActionsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserAttached_ClientsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserAttached_NotificationsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserBreakdown_Breakdown_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserBreakdown_Breakdownitems_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserBuildup_Buildup_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserBuildup_Buildupitems_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserChecked_Out_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserClaimed_BreakdownArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserClaimed_BuildupArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserClaimed_PurchaseArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserClaimed_SaleArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserClaimed_ShipmentorderArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserClients_Client_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserClients_Contact_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserClients_Shippinglabel_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserClients_Subs_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserClients_Terms_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserCompany_Creditcard_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserCompany_Subs_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserCustompermissions_Role_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserCustompermissions_Rolepermissions_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserDepartment_Department_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserDepartment_Departmentemployees_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserDepartment_Departmentroles_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserDepartments_M2mArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserInventory_Item_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserInventory_Service_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserInventory_Software_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserInvoices_Apinvoice_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserInvoices_Appayment_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserInvoices_Arinvoice_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserInvoices_Arpayment_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserLocation_Location_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserLocation_Site_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserParts_Bom_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserParts_Bomparts_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserParts_ReceivedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserProfile_Livetemplate_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserProfile_Tableconfig_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserPurchase_Contracts_SentArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserPurchaser_ShipmentsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserPurchasesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserPurchases_Purchase_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserPurchases_Purchaseditems_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserQuotes_Quote_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserQuotes_Quoteparts_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserReporting_Report_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserReturn_ShipmentsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserRma_Purchaserma_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserRma_Purchasermaitems_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserRma_Rma_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserRma_Rmaitems_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserRmas_AssignedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserRmas_ClosedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserRmas_ReceivedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserRmas_TestedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserRmas_VerifiedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserRmas_VoidedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserSalesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserSales_Fees_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserSales_Sale_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserSales_Solditems_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserSales_Soldservices_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserSales_Soldsoftware_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserShip_From_ShipmentsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserShip_To_ShipmentsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserShipping_Account_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserShipping_Carrier_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserShipping_Predefinedparcel_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserShipping_Servicelevel_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserShipping_Shipment_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserShipping_Shipmentorder_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserShipping_Shipmentsupplies_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserSubordinatesArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserTeam_Team_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserTeam_Teammembers_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserTeams_M2mArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserTester_For_PurchaseArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserTester_For_SaleArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserWorkorder_Workorder_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};


/** This is the base User model. */
export type Users_UserWorkorder_Workorderitems_CreatedArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

export type ValidationResponse = {
  __typename?: 'ValidationResponse';
  errors?: Maybe<Array<Scalars['String']>>;
  state: Scalars['String'];
};

/**
 * This model keeps track of WorkOrders and any relevant data.
 * Mostly used for recording freight costs
 * if any services are used this is also used.
 */
export type WorkOrder_WorkOrder = {
  __typename?: 'WorkOrder_WorkOrder';
  amount?: Maybe<Money>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  contract_comment: Scalars['String'];
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  id: Scalars['ID'];
  internal_comment: Scalars['String'];
  need_by?: Maybe<Scalars['Date']>;
  purchase_transaction?: Maybe<Purchases_Purchase>;
  shipment_order?: Maybe<Shipping_ShipmentOrder>;
  status: Choices_Status;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  terms?: Maybe<Clients_Terms>;
  type?: Maybe<Choices_Choice>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
  vendor?: Maybe<Clients_Client>;
  warranty_end_date?: Maybe<Scalars['Date']>;
  wo_items?: Maybe<Array<WorkOrder_WorkOrderItems>>;
};


/**
 * This model keeps track of WorkOrders and any relevant data.
 * Mostly used for recording freight costs
 * if any services are used this is also used.
 */
export type WorkOrder_WorkOrderWo_ItemsArgs = {
  filters?: Maybe<Array<QueryFilter>>;
};

/** This model links WorkOrders to Inventory.Items and keeps track of any relevant data. */
export type WorkOrder_WorkOrderItems = {
  __typename?: 'WorkOrder_WorkOrderItems';
  amount?: Maybe<Money>;
  annotations?: Maybe<Array<Maybe<AnnotationObject>>>;
  /** When this object was created. */
  creation_date: Scalars['DateTime'];
  /** Who created this object. */
  creator: Users_User;
  id: Scalars['ID'];
  item: Inventory_Item;
  /** The events for this object. */
  system_logs?: Maybe<Array<Logging_ActionLogObject>>;
  /** When this object was last updated. */
  updated_date: Scalars['DateTime'];
  work_order: WorkOrder_WorkOrder;
};

export enum Account_Type {
  /** Assets */
  Assets = 'ASSETS',
  /** Equity */
  Equity = 'EQUITY',
  /** Liabilities */
  Liabilities = 'LIABILITIES'
}

export enum Action {
  /** M2M Add */
  Added = 'ADDED',
  /** Approved */
  Approved = 'APPROVED',
  /** Checked Out */
  CheckedOut = 'CHECKED_OUT',
  /** M2M Clear */
  Cleared = 'CLEARED',
  /** Created */
  Created = 'CREATED',
  /** Deleted */
  Deleted = 'DELETED',
  /** Edited */
  Edited = 'EDITED',
  /** Moved */
  Moved = 'MOVED',
  /** M2M Remove */
  Removed = 'REMOVED',
  /** Checked In */
  Returned = 'RETURNED',
  /** M2M Set */
  Set = 'SET',
  /** Unapproved */
  Unapproved = 'UNAPPROVED'
}

export enum Aggr_Type {
  Avg = 'AVG',
  Count = 'COUNT',
  Max = 'MAX',
  Min = 'MIN',
  Sum = 'SUM'
}

export enum Blind {
  /** Blind */
  Blind = 'BLIND',
  /** Double Blind */
  DoubleBlind = 'DOUBLE_BLIND',
  /** Not Blind */
  NotBlind = 'NOT_BLIND'
}

export enum Chart_Type {
  /** Bar */
  Bar = 'bar',
  /** Bubble */
  Bubble = 'bubble',
  /** Doughnut */
  Doughnut = 'doughnut',
  /** Horizontal Bar */
  HorizontalBar = 'horizontalBar',
  /** Line */
  Line = 'line',
  /** Pie */
  Pie = 'pie',
  /** Polar Area */
  PolarArea = 'polarArea',
  /** Radar */
  Radar = 'radar',
  /** Scatter */
  Scatter = 'scatter'
}

export enum Countries {
  /** Andorra:🇦🇩 */
  Ad = 'AD',
  /** United Arab Emirates:🇦🇪 */
  Ae = 'AE',
  /** Afghanistan:🇦🇫 */
  Af = 'AF',
  /** Antigua and Barbuda:🇦🇬 */
  Ag = 'AG',
  /** Anguilla:🇦🇮 */
  Ai = 'AI',
  /** Albania:🇦🇱 */
  Al = 'AL',
  /** Armenia:🇦🇲 */
  Am = 'AM',
  /** Angola:🇦🇴 */
  Ao = 'AO',
  /** Antarctica:🇦🇶 */
  Aq = 'AQ',
  /** Argentina:🇦🇷 */
  Ar = 'AR',
  /** American Samoa:🇦🇸 */
  As = 'AS',
  /** Austria:🇦🇹 */
  At = 'AT',
  /** Australia:🇦🇺 */
  Au = 'AU',
  /** Aruba:🇦🇼 */
  Aw = 'AW',
  /** Åland Islands:🇦🇽 */
  Ax = 'AX',
  /** Azerbaijan:🇦🇿 */
  Az = 'AZ',
  /** Bosnia and Herzegovina:🇧🇦 */
  Ba = 'BA',
  /** Barbados:🇧🇧 */
  Bb = 'BB',
  /** Bangladesh:🇧🇩 */
  Bd = 'BD',
  /** Belgium:🇧🇪 */
  Be = 'BE',
  /** Burkina Faso:🇧🇫 */
  Bf = 'BF',
  /** Bulgaria:🇧🇬 */
  Bg = 'BG',
  /** Bahrain:🇧🇭 */
  Bh = 'BH',
  /** Burundi:🇧🇮 */
  Bi = 'BI',
  /** Benin:🇧🇯 */
  Bj = 'BJ',
  /** Saint Barthélemy:🇧🇱 */
  Bl = 'BL',
  /** Bermuda:🇧🇲 */
  Bm = 'BM',
  /** Brunei:🇧🇳 */
  Bn = 'BN',
  /** Bolivia:🇧🇴 */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba:🇧🇶 */
  Bq = 'BQ',
  /** Brazil:🇧🇷 */
  Br = 'BR',
  /** Bahamas:🇧🇸 */
  Bs = 'BS',
  /** Bhutan:🇧🇹 */
  Bt = 'BT',
  /** Bouvet Island:🇧🇻 */
  Bv = 'BV',
  /** Botswana:🇧🇼 */
  Bw = 'BW',
  /** Belarus:🇧🇾 */
  By = 'BY',
  /** Belize:🇧🇿 */
  Bz = 'BZ',
  /** Canada:🇨🇦 */
  Ca = 'CA',
  /** Cocos (Keeling) Islands:🇨🇨 */
  Cc = 'CC',
  /** Congo (the Democratic Republic of the):🇨🇩 */
  Cd = 'CD',
  /** Central African Republic:🇨🇫 */
  Cf = 'CF',
  /** Congo:🇨🇬 */
  Cg = 'CG',
  /** Switzerland:🇨🇭 */
  Ch = 'CH',
  /** Côte d'Ivoire:🇨🇮 */
  Ci = 'CI',
  /** Cook Islands:🇨🇰 */
  Ck = 'CK',
  /** Chile:🇨🇱 */
  Cl = 'CL',
  /** Cameroon:🇨🇲 */
  Cm = 'CM',
  /** China:🇨🇳 */
  Cn = 'CN',
  /** Colombia:🇨🇴 */
  Co = 'CO',
  /** Costa Rica:🇨🇷 */
  Cr = 'CR',
  /** Cuba:🇨🇺 */
  Cu = 'CU',
  /** Cabo Verde:🇨🇻 */
  Cv = 'CV',
  /** Curaçao:🇨🇼 */
  Cw = 'CW',
  /** Christmas Island:🇨🇽 */
  Cx = 'CX',
  /** Cyprus:🇨🇾 */
  Cy = 'CY',
  /** Czechia:🇨🇿 */
  Cz = 'CZ',
  /** Germany:🇩🇪 */
  De = 'DE',
  /** Djibouti:🇩🇯 */
  Dj = 'DJ',
  /** Denmark:🇩🇰 */
  Dk = 'DK',
  /** Dominica:🇩🇲 */
  Dm = 'DM',
  /** Dominican Republic:🇩🇴 */
  Do = 'DO',
  /** Algeria:🇩🇿 */
  Dz = 'DZ',
  /** Ecuador:🇪🇨 */
  Ec = 'EC',
  /** Estonia:🇪🇪 */
  Ee = 'EE',
  /** Egypt:🇪🇬 */
  Eg = 'EG',
  /** Western Sahara:🇪🇭 */
  Eh = 'EH',
  /** Eritrea:🇪🇷 */
  Er = 'ER',
  /** Spain:🇪🇸 */
  Es = 'ES',
  /** Ethiopia:🇪🇹 */
  Et = 'ET',
  /** Finland:🇫🇮 */
  Fi = 'FI',
  /** Fiji:🇫🇯 */
  Fj = 'FJ',
  /** Falkland Islands (Malvinas):🇫🇰 */
  Fk = 'FK',
  /** Micronesia (Federated States of):🇫🇲 */
  Fm = 'FM',
  /** Faroe Islands:🇫🇴 */
  Fo = 'FO',
  /** France:🇫🇷 */
  Fr = 'FR',
  /** Gabon:🇬🇦 */
  Ga = 'GA',
  /** United Kingdom:🇬🇧 */
  Gb = 'GB',
  /** Grenada:🇬🇩 */
  Gd = 'GD',
  /** Georgia:🇬🇪 */
  Ge = 'GE',
  /** French Guiana:🇬🇫 */
  Gf = 'GF',
  /** Guernsey:🇬🇬 */
  Gg = 'GG',
  /** Ghana:🇬🇭 */
  Gh = 'GH',
  /** Gibraltar:🇬🇮 */
  Gi = 'GI',
  /** Greenland:🇬🇱 */
  Gl = 'GL',
  /** Gambia:🇬🇲 */
  Gm = 'GM',
  /** Guinea:🇬🇳 */
  Gn = 'GN',
  /** Guadeloupe:🇬🇵 */
  Gp = 'GP',
  /** Equatorial Guinea:🇬🇶 */
  Gq = 'GQ',
  /** Greece:🇬🇷 */
  Gr = 'GR',
  /** South Georgia and the South Sandwich Islands:🇬🇸 */
  Gs = 'GS',
  /** Guatemala:🇬🇹 */
  Gt = 'GT',
  /** Guam:🇬🇺 */
  Gu = 'GU',
  /** Guinea-Bissau:🇬🇼 */
  Gw = 'GW',
  /** Guyana:🇬🇾 */
  Gy = 'GY',
  /** Hong Kong:🇭🇰 */
  Hk = 'HK',
  /** Heard Island and McDonald Islands:🇭🇲 */
  Hm = 'HM',
  /** Honduras:🇭🇳 */
  Hn = 'HN',
  /** Croatia:🇭🇷 */
  Hr = 'HR',
  /** Haiti:🇭🇹 */
  Ht = 'HT',
  /** Hungary:🇭🇺 */
  Hu = 'HU',
  /** Indonesia:🇮🇩 */
  Id = 'ID',
  /** Ireland:🇮🇪 */
  Ie = 'IE',
  /** Israel:🇮🇱 */
  Il = 'IL',
  /** Isle of Man:🇮🇲 */
  Im = 'IM',
  /** India:🇮🇳 */
  In = 'IN',
  /** British Indian Ocean Territory:🇮🇴 */
  Io = 'IO',
  /** Iraq:🇮🇶 */
  Iq = 'IQ',
  /** Iran:🇮🇷 */
  Ir = 'IR',
  /** Iceland:🇮🇸 */
  Is = 'IS',
  /** Italy:🇮🇹 */
  It = 'IT',
  /** Jersey:🇯🇪 */
  Je = 'JE',
  /** Jamaica:🇯🇲 */
  Jm = 'JM',
  /** Jordan:🇯🇴 */
  Jo = 'JO',
  /** Japan:🇯🇵 */
  Jp = 'JP',
  /** Kenya:🇰🇪 */
  Ke = 'KE',
  /** Kyrgyzstan:🇰🇬 */
  Kg = 'KG',
  /** Cambodia:🇰🇭 */
  Kh = 'KH',
  /** Kiribati:🇰🇮 */
  Ki = 'KI',
  /** Comoros:🇰🇲 */
  Km = 'KM',
  /** Saint Kitts and Nevis:🇰🇳 */
  Kn = 'KN',
  /** North Korea:🇰🇵 */
  Kp = 'KP',
  /** South Korea:🇰🇷 */
  Kr = 'KR',
  /** Kuwait:🇰🇼 */
  Kw = 'KW',
  /** Cayman Islands:🇰🇾 */
  Ky = 'KY',
  /** Kazakhstan:🇰🇿 */
  Kz = 'KZ',
  /** Laos:🇱🇦 */
  La = 'LA',
  /** Lebanon:🇱🇧 */
  Lb = 'LB',
  /** Saint Lucia:🇱🇨 */
  Lc = 'LC',
  /** Liechtenstein:🇱🇮 */
  Li = 'LI',
  /** Sri Lanka:🇱🇰 */
  Lk = 'LK',
  /** Liberia:🇱🇷 */
  Lr = 'LR',
  /** Lesotho:🇱🇸 */
  Ls = 'LS',
  /** Lithuania:🇱🇹 */
  Lt = 'LT',
  /** Luxembourg:🇱🇺 */
  Lu = 'LU',
  /** Latvia:🇱🇻 */
  Lv = 'LV',
  /** Libya:🇱🇾 */
  Ly = 'LY',
  /** Morocco:🇲🇦 */
  Ma = 'MA',
  /** Monaco:🇲🇨 */
  Mc = 'MC',
  /** Moldova:🇲🇩 */
  Md = 'MD',
  /** Montenegro:🇲🇪 */
  Me = 'ME',
  /** Saint Martin (French part):🇲🇫 */
  Mf = 'MF',
  /** Madagascar:🇲🇬 */
  Mg = 'MG',
  /** Marshall Islands:🇲🇭 */
  Mh = 'MH',
  /** North Macedonia:🇲🇰 */
  Mk = 'MK',
  /** Mali:🇲🇱 */
  Ml = 'ML',
  /** Myanmar:🇲🇲 */
  Mm = 'MM',
  /** Mongolia:🇲🇳 */
  Mn = 'MN',
  /** Macao:🇲🇴 */
  Mo = 'MO',
  /** Northern Mariana Islands:🇲🇵 */
  Mp = 'MP',
  /** Martinique:🇲🇶 */
  Mq = 'MQ',
  /** Mauritania:🇲🇷 */
  Mr = 'MR',
  /** Montserrat:🇲🇸 */
  Ms = 'MS',
  /** Malta:🇲🇹 */
  Mt = 'MT',
  /** Mauritius:🇲🇺 */
  Mu = 'MU',
  /** Maldives:🇲🇻 */
  Mv = 'MV',
  /** Malawi:🇲🇼 */
  Mw = 'MW',
  /** Mexico:🇲🇽 */
  Mx = 'MX',
  /** Malaysia:🇲🇾 */
  My = 'MY',
  /** Mozambique:🇲🇿 */
  Mz = 'MZ',
  /** Namibia:🇳🇦 */
  Na = 'NA',
  /** New Caledonia:🇳🇨 */
  Nc = 'NC',
  /** Niger:🇳🇪 */
  Ne = 'NE',
  /** Norfolk Island:🇳🇫 */
  Nf = 'NF',
  /** Nigeria:🇳🇬 */
  Ng = 'NG',
  /** Nicaragua:🇳🇮 */
  Ni = 'NI',
  /** Netherlands:🇳🇱 */
  Nl = 'NL',
  /** Norway:🇳🇴 */
  No = 'NO',
  /** Nepal:🇳🇵 */
  Np = 'NP',
  /** Nauru:🇳🇷 */
  Nr = 'NR',
  /** Niue:🇳🇺 */
  Nu = 'NU',
  /** New Zealand:🇳🇿 */
  Nz = 'NZ',
  /** Oman:🇴🇲 */
  Om = 'OM',
  /** Panama:🇵🇦 */
  Pa = 'PA',
  /** Peru:🇵🇪 */
  Pe = 'PE',
  /** French Polynesia:🇵🇫 */
  Pf = 'PF',
  /** Papua New Guinea:🇵🇬 */
  Pg = 'PG',
  /** Philippines:🇵🇭 */
  Ph = 'PH',
  /** Pakistan:🇵🇰 */
  Pk = 'PK',
  /** Poland:🇵🇱 */
  Pl = 'PL',
  /** Saint Pierre and Miquelon:🇵🇲 */
  Pm = 'PM',
  /** Pitcairn:🇵🇳 */
  Pn = 'PN',
  /** Puerto Rico:🇵🇷 */
  Pr = 'PR',
  /** Palestine, State of:🇵🇸 */
  Ps = 'PS',
  /** Portugal:🇵🇹 */
  Pt = 'PT',
  /** Palau:🇵🇼 */
  Pw = 'PW',
  /** Paraguay:🇵🇾 */
  Py = 'PY',
  /** Qatar:🇶🇦 */
  Qa = 'QA',
  /** Réunion:🇷🇪 */
  Re = 'RE',
  /** Romania:🇷🇴 */
  Ro = 'RO',
  /** Serbia:🇷🇸 */
  Rs = 'RS',
  /** Russia:🇷🇺 */
  Ru = 'RU',
  /** Rwanda:🇷🇼 */
  Rw = 'RW',
  /** Saudi Arabia:🇸🇦 */
  Sa = 'SA',
  /** Solomon Islands:🇸🇧 */
  Sb = 'SB',
  /** Seychelles:🇸🇨 */
  Sc = 'SC',
  /** Sudan:🇸🇩 */
  Sd = 'SD',
  /** Sweden:🇸🇪 */
  Se = 'SE',
  /** Singapore:🇸🇬 */
  Sg = 'SG',
  /** Saint Helena, Ascension and Tristan da Cunha:🇸🇭 */
  Sh = 'SH',
  /** Slovenia:🇸🇮 */
  Si = 'SI',
  /** Svalbard and Jan Mayen:🇸🇯 */
  Sj = 'SJ',
  /** Slovakia:🇸🇰 */
  Sk = 'SK',
  /** Sierra Leone:🇸🇱 */
  Sl = 'SL',
  /** San Marino:🇸🇲 */
  Sm = 'SM',
  /** Senegal:🇸🇳 */
  Sn = 'SN',
  /** Somalia:🇸🇴 */
  So = 'SO',
  /** Suriname:🇸🇷 */
  Sr = 'SR',
  /** South Sudan:🇸🇸 */
  Ss = 'SS',
  /** Sao Tome and Principe:🇸🇹 */
  St = 'ST',
  /** El Salvador:🇸🇻 */
  Sv = 'SV',
  /** Sint Maarten (Dutch part):🇸🇽 */
  Sx = 'SX',
  /** Syria:🇸🇾 */
  Sy = 'SY',
  /** Eswatini:🇸🇿 */
  Sz = 'SZ',
  /** Turks and Caicos Islands:🇹🇨 */
  Tc = 'TC',
  /** Chad:🇹🇩 */
  Td = 'TD',
  /** French Southern Territories:🇹🇫 */
  Tf = 'TF',
  /** Togo:🇹🇬 */
  Tg = 'TG',
  /** Thailand:🇹🇭 */
  Th = 'TH',
  /** Tajikistan:🇹🇯 */
  Tj = 'TJ',
  /** Tokelau:🇹🇰 */
  Tk = 'TK',
  /** Timor-Leste:🇹🇱 */
  Tl = 'TL',
  /** Turkmenistan:🇹🇲 */
  Tm = 'TM',
  /** Tunisia:🇹🇳 */
  Tn = 'TN',
  /** Tonga:🇹🇴 */
  To = 'TO',
  /** Turkey:🇹🇷 */
  Tr = 'TR',
  /** Trinidad and Tobago:🇹🇹 */
  Tt = 'TT',
  /** Tuvalu:🇹🇻 */
  Tv = 'TV',
  /** Taiwan:🇹🇼 */
  Tw = 'TW',
  /** Tanzania:🇹🇿 */
  Tz = 'TZ',
  /** Ukraine:🇺🇦 */
  Ua = 'UA',
  /** Uganda:🇺🇬 */
  Ug = 'UG',
  /** United States Minor Outlying Islands:🇺🇲 */
  Um = 'UM',
  /** United States of America:🇺🇸 */
  Us = 'US',
  /** Uruguay:🇺🇾 */
  Uy = 'UY',
  /** Uzbekistan:🇺🇿 */
  Uz = 'UZ',
  /** Holy See:🇻🇦 */
  Va = 'VA',
  /** Saint Vincent and the Grenadines:🇻🇨 */
  Vc = 'VC',
  /** Venezuela:🇻🇪 */
  Ve = 'VE',
  /** Virgin Islands (British):🇻🇬 */
  Vg = 'VG',
  /** Virgin Islands (U.S.):🇻🇮 */
  Vi = 'VI',
  /** Vietnam:🇻🇳 */
  Vn = 'VN',
  /** Vanuatu:🇻🇺 */
  Vu = 'VU',
  /** Wallis and Futuna:🇼🇫 */
  Wf = 'WF',
  /** Samoa:🇼🇸 */
  Ws = 'WS',
  /** Yemen:🇾🇪 */
  Ye = 'YE',
  /** Mayotte:🇾🇹 */
  Yt = 'YT',
  /** South Africa:🇿🇦 */
  Za = 'ZA',
  /** Zambia:🇿🇲 */
  Zm = 'ZM',
  /** Zimbabwe:🇿🇼 */
  Zw = 'ZW'
}

export enum CURRENCY {
  /** UAE Dirham */
  Aed = 'AED',
  /** Afghani */
  Afn = 'AFN',
  /** Lek */
  All = 'ALL',
  /** Armenian Dram */
  Amd = 'AMD',
  /** Netherlands Antillian Guilder */
  Ang = 'ANG',
  /** Kwanza */
  Aoa = 'AOA',
  /** Argentine Peso */
  Ars = 'ARS',
  /** Australian Dollar */
  Aud = 'AUD',
  /** Aruban Guilder */
  Awg = 'AWG',
  /** Azerbaijanian Manat */
  Azn = 'AZN',
  /** Convertible Marks */
  Bam = 'BAM',
  /** Barbados Dollar */
  Bbd = 'BBD',
  /** Taka */
  Bdt = 'BDT',
  /** Bulgarian Lev */
  Bgn = 'BGN',
  /** Bahraini Dinar */
  Bhd = 'BHD',
  /** Burundi Franc */
  Bif = 'BIF',
  /** Bermudian Dollar (customarily known as Bermuda Dollar) */
  Bmd = 'BMD',
  /** Brunei Dollar */
  Bnd = 'BND',
  /** Boliviano */
  Bob = 'BOB',
  /** Mvdol */
  Bov = 'BOV',
  /** Brazilian Real */
  Brl = 'BRL',
  /** Bahamian Dollar */
  Bsd = 'BSD',
  /** Bhutanese ngultrum */
  Btn = 'BTN',
  /** Pula */
  Bwp = 'BWP',
  /** Belarussian Ruble */
  Byn = 'BYN',
  /** Belarussian Ruble */
  Byr = 'BYR',
  /** Belize Dollar */
  Bzd = 'BZD',
  /** Canadian Dollar */
  Cad = 'CAD',
  /** Congolese franc */
  Cdf = 'CDF',
  /** WIR Euro */
  Che = 'CHE',
  /** Swiss Franc */
  Chf = 'CHF',
  /** WIR Franc */
  Chw = 'CHW',
  /** Unidad de Fomento */
  Clf = 'CLF',
  /** Chilean peso */
  Clp = 'CLP',
  /** Yuan Renminbi */
  Cny = 'CNY',
  /** Colombian peso */
  Cop = 'COP',
  /** Unidad de Valor Real */
  Cou = 'COU',
  /** Costa Rican Colon */
  Crc = 'CRC',
  /** Cuban convertible peso */
  Cuc = 'CUC',
  /** Cuban Peso */
  Cup = 'CUP',
  /** Cape Verde Escudo */
  Cve = 'CVE',
  /** Czech Koruna */
  Czk = 'CZK',
  /** Djibouti Franc */
  Djf = 'DJF',
  /** Danish Krone */
  Dkk = 'DKK',
  /** Dominican Peso */
  Dop = 'DOP',
  /** Algerian Dinar */
  Dzd = 'DZD',
  /** Egyptian Pound */
  Egp = 'EGP',
  /** Nakfa */
  Ern = 'ERN',
  /** Ethiopian Birr */
  Etb = 'ETB',
  /** Euro */
  Eur = 'EUR',
  /** Fiji Dollar */
  Fjd = 'FJD',
  /** Falkland Islands Pound */
  Fkp = 'FKP',
  /** Pound Sterling */
  Gbp = 'GBP',
  /** Lari */
  Gel = 'GEL',
  /** Ghana Cedi */
  Ghs = 'GHS',
  /** Gibraltar Pound */
  Gip = 'GIP',
  /** Dalasi */
  Gmd = 'GMD',
  /** Guinea Franc */
  Gnf = 'GNF',
  /** Quetzal */
  Gtq = 'GTQ',
  /** Guyana Dollar */
  Gyd = 'GYD',
  /** Hong Kong Dollar */
  Hkd = 'HKD',
  /** Lempira */
  Hnl = 'HNL',
  /** Croatian Kuna */
  Hrk = 'HRK',
  /** Haitian gourde */
  Htg = 'HTG',
  /** Forint */
  Huf = 'HUF',
  /** Rupiah */
  Idr = 'IDR',
  /** New Israeli Sheqel */
  Ils = 'ILS',
  /** Isle of Man Pound */
  Imp = 'IMP',
  /** Indian Rupee */
  Inr = 'INR',
  /** Iraqi Dinar */
  Iqd = 'IQD',
  /** Iranian Rial */
  Irr = 'IRR',
  /** Iceland Krona */
  Isk = 'ISK',
  /** Jamaican Dollar */
  Jmd = 'JMD',
  /** Jordanian Dinar */
  Jod = 'JOD',
  /** Yen */
  Jpy = 'JPY',
  /** Kenyan Shilling */
  Kes = 'KES',
  /** Som */
  Kgs = 'KGS',
  /** Riel */
  Khr = 'KHR',
  /** Comoro Franc */
  Kmf = 'KMF',
  /** North Korean Won */
  Kpw = 'KPW',
  /** Won */
  Krw = 'KRW',
  /** Kuwaiti Dinar */
  Kwd = 'KWD',
  /** Cayman Islands Dollar */
  Kyd = 'KYD',
  /** Tenge */
  Kzt = 'KZT',
  /** Kip */
  Lak = 'LAK',
  /** Lebanese Pound */
  Lbp = 'LBP',
  /** Sri Lanka Rupee */
  Lkr = 'LKR',
  /** Liberian Dollar */
  Lrd = 'LRD',
  /** Lesotho loti */
  Lsl = 'LSL',
  /** Lithuanian Litas */
  Ltl = 'LTL',
  /** Latvian Lats */
  Lvl = 'LVL',
  /** Libyan Dinar */
  Lyd = 'LYD',
  /** Moroccan Dirham */
  Mad = 'MAD',
  /** Moldovan Leu */
  Mdl = 'MDL',
  /** Malagasy Ariary */
  Mga = 'MGA',
  /** Denar */
  Mkd = 'MKD',
  /** Kyat */
  Mmk = 'MMK',
  /** Tugrik */
  Mnt = 'MNT',
  /** Pataca */
  Mop = 'MOP',
  /** Ouguiya */
  Mro = 'MRO',
  /** Mauritius Rupee */
  Mur = 'MUR',
  /** Rufiyaa */
  Mvr = 'MVR',
  /** Malawian Kwacha */
  Mwk = 'MWK',
  /** Mexican peso */
  Mxn = 'MXN',
  /** Mexican Unidad de Inversion (UDI) */
  Mxv = 'MXV',
  /** Malaysian Ringgit */
  Myr = 'MYR',
  /** Metical */
  Mzn = 'MZN',
  /** Namibian Dollar */
  Nad = 'NAD',
  /** Naira */
  Ngn = 'NGN',
  /** Cordoba Oro */
  Nio = 'NIO',
  /** Norwegian Krone */
  Nok = 'NOK',
  /** Nepalese Rupee */
  Npr = 'NPR',
  /** New Zealand Dollar */
  Nzd = 'NZD',
  /** Rial Omani */
  Omr = 'OMR',
  /** Balboa */
  Pab = 'PAB',
  /** Nuevo Sol */
  Pen = 'PEN',
  /** Kina */
  Pgk = 'PGK',
  /** Philippine Peso */
  Php = 'PHP',
  /** Pakistan Rupee */
  Pkr = 'PKR',
  /** Zloty */
  Pln = 'PLN',
  /** Guarani */
  Pyg = 'PYG',
  /** Qatari Rial */
  Qar = 'QAR',
  /** New Leu */
  Ron = 'RON',
  /** Serbian Dinar */
  Rsd = 'RSD',
  /** Russian Ruble */
  Rub = 'RUB',
  /** Rwanda Franc */
  Rwf = 'RWF',
  /** Saudi Riyal */
  Sar = 'SAR',
  /** Solomon Islands Dollar */
  Sbd = 'SBD',
  /** Seychelles Rupee */
  Scr = 'SCR',
  /** Sudanese Pound */
  Sdg = 'SDG',
  /** Swedish Krona */
  Sek = 'SEK',
  /** Singapore Dollar */
  Sgd = 'SGD',
  /** Saint Helena Pound */
  Shp = 'SHP',
  /** Leone */
  Sll = 'SLL',
  /** Somali Shilling */
  Sos = 'SOS',
  /** Surinam Dollar */
  Srd = 'SRD',
  /** South Sudanese Pound */
  Ssp = 'SSP',
  /** Dobra */
  Std = 'STD',
  /** El Salvador Colon */
  Svc = 'SVC',
  /** Syrian Pound */
  Syp = 'SYP',
  /** Lilangeni */
  Szl = 'SZL',
  /** Baht */
  Thb = 'THB',
  /** Somoni */
  Tjs = 'TJS',
  /** Manat */
  Tmm = 'TMM',
  /** Turkmenistan New Manat */
  Tmt = 'TMT',
  /** Tunisian Dinar */
  Tnd = 'TND',
  /** Paanga */
  Top = 'TOP',
  /** Turkish Lira */
  Try = 'TRY',
  /** Trinidad and Tobago Dollar */
  Ttd = 'TTD',
  /** Tuvalu dollar */
  Tvd = 'TVD',
  /** New Taiwan Dollar */
  Twd = 'TWD',
  /** Tanzanian Shilling */
  Tzs = 'TZS',
  /** Hryvnia */
  Uah = 'UAH',
  /** Uganda Shilling */
  Ugx = 'UGX',
  /** US Dollar */
  Usd = 'USD',
  /** US Dollar (Next day) */
  Usn = 'USN',
  /** Uruguay Peso en Unidades Indexadas (URUIURUI) */
  Uyi = 'UYI',
  /** Uruguayan peso */
  Uyu = 'UYU',
  /** Uzbekistan Sum */
  Uzs = 'UZS',
  /** Bolivar Fuerte */
  Vef = 'VEF',
  /** Dong */
  Vnd = 'VND',
  /** Vatu */
  Vuv = 'VUV',
  /** Tala */
  Wst = 'WST',
  /** CFA franc BEAC */
  Xaf = 'XAF',
  /** Silver */
  Xag = 'XAG',
  /** Gold */
  Xau = 'XAU',
  /** Bond Markets Units European Composite Unit (EURCO) */
  Xba = 'XBA',
  /** European Monetary Unit (E.M.U.-6) */
  Xbb = 'XBB',
  /** European Unit of Account 9(E.U.A.-9) */
  Xbc = 'XBC',
  /** European Unit of Account 17(E.U.A.-17) */
  Xbd = 'XBD',
  /** East Caribbean Dollar */
  Xcd = 'XCD',
  /** SDR */
  Xdr = 'XDR',
  /** Gold-Franc */
  Xfo = 'XFO',
  /** UIC-Franc */
  Xfu = 'XFU',
  /** CFA Franc BCEAO */
  Xof = 'XOF',
  /** Palladium */
  Xpd = 'XPD',
  /** CFP Franc */
  Xpf = 'XPF',
  /** Platinum */
  Xpt = 'XPT',
  /** Sucre */
  Xsu = 'XSU',
  /** Codes specifically reserved for testing purposes */
  Xts = 'XTS',
  /** ADB Unit of Account */
  Xua = 'XUA',
  /** The codes assigned for transactions where no currency is involved */
  Xxx = 'XXX',
  /** Default currency. */
  Xyz = 'XYZ',
  /** Yemeni Rial */
  Yer = 'YER',
  /** Rand */
  Zar = 'ZAR',
  /** Zambian Kwacha */
  Zmk = 'ZMK',
  /** Zambian Kwacha */
  Zmw = 'ZMW',
  /** Zimbabwe Dollar A/06 */
  Zwd = 'ZWD',
  /** Zimbabwe dollar A/09 */
  Zwl = 'ZWL',
  /** Zimbabwe dollar A/08 */
  Zwn = 'ZWN'
}

export enum Easypost_Certified_Mail {
  CertifiedMail = 'CERTIFIED_MAIL',
  CertifiedMailAdultSignatureRequired = 'CERTIFIED_MAIL_ADULT_SIGNATURE_REQUIRED',
  CertifiedMailAdultSignatureRestrictedDelivery = 'CERTIFIED_MAIL_ADULT_SIGNATURE_RESTRICTED_DELIVERY',
  CertifiedMailRestrictedDelivery = 'CERTIFIED_MAIL_RESTRICTED_DELIVERY'
}

export enum Easypost_Cod_Method {
  Cash = 'CASH',
  Check = 'CHECK',
  MoneyOrder = 'MONEY_ORDER'
}

export enum Easypost_Contents_Type {
  Documents = 'documents',
  Gifts = 'gifts',
  Merchandise = 'merchandise',
  Other = 'other',
  ReturnedGoods = 'returned_goods',
  Sample = 'sample'
}

export enum Easypost_Dropoff_Type {
  DropBox = 'DROP_BOX',
  RegularPickup = 'REGULAR_PICKUP',
  RetailLocation = 'RETAIL_LOCATION',
  ScheduledPickup = 'SCHEDULED_PICKUP',
  Station = 'STATION'
}

export enum Easypost_Endorsement {
  AddressServiceRequested = 'ADDRESS_SERVICE_REQUESTED',
  ChangeServiceRequested = 'CHANGE_SERVICE_REQUESTED',
  ForwardingServiceRequested = 'FORWARDING_SERVICE_REQUESTED',
  LeaveIfNoResponse = 'LEAVE_IF_NO_RESPONSE',
  ReturnServiceRequested = 'RETURN_SERVICE_REQUESTED'
}

export enum Easypost_Fee_Type {
  InsuranceFee = 'InsuranceFee',
  LabelFee = 'LabelFee',
  PostageFee = 'PostageFee',
  TrackerFee = 'TrackerFee'
}

export enum Easypost_Form_Type {
  Cn_22 = 'cn_22',
  CodReturnLabel = 'cod_return_label',
  CommercialInvoice = 'commercial_invoice',
  HighValueReport = 'high_value_report',
  OrderSummary = 'order_summary'
}

export enum Easypost_Hazmat {
  LimitedQuantity = 'LIMITED_QUANTITY',
  Lithium = 'LITHIUM',
  Ormd = 'ORMD',
  Primary = 'PRIMARY',
  PrimaryContained = 'PRIMARY_CONTAINED',
  PrimaryPacked = 'PRIMARY_PACKED',
  Secondary = 'SECONDARY',
  SecondaryContained = 'SECONDARY_CONTAINED',
  SecondaryPacked = 'SECONDARY_PACKED'
}

export enum Easypost_Incoterm {
  Cfr = 'CFR',
  Cif = 'CIF',
  Cip = 'CIP',
  Cpt = 'CPT',
  Dap = 'DAP',
  Dat = 'DAT',
  Ddp = 'DDP',
  Exw = 'EXW',
  Fas = 'FAS',
  Fca = 'FCA',
  Fob = 'FOB'
}

export enum Easypost_Label_Format {
  Epl2 = 'EPL2',
  Pdf = 'PDF',
  Png = 'PNG',
  Zpl = 'ZPL'
}

export enum Easypost_Mode {
  Production = 'production',
  Test = 'test'
}

export enum Easypost_Non_Delivery_Option {
  Abandon = 'abandon',
  Return = 'return'
}

export enum Easypost_Payment_Type {
  Collect = 'COLLECT',
  Receiver = 'RECEIVER',
  Sender = 'SENDER',
  ThirdParty = 'THIRD_PARTY'
}

export enum Easypost_Pickup_Status {
  Canceled = 'canceled',
  Scheduled = 'scheduled',
  Unknown = 'unknown'
}

export enum Easypost_Report_Status {
  Available = 'available',
  Failed = 'failed',
  New = 'new'
}

export enum Easypost_Report_Type {
  PaymentLogReport = 'PaymentLogReport',
  RefundReport = 'RefundReport',
  ShipmentReport = 'ShipmentReport',
  TrackerReport = 'TrackerReport'
}

export enum Easypost_Restriction_Type {
  None = 'none',
  Other = 'other',
  Quarantine = 'quarantine',
  SanitaryPhytosanitaryInspection = 'sanitary_phytosanitary_inspection'
}

export enum Easypost_Signature_Type {
  AdultSignature = 'ADULT_SIGNATURE',
  IndirectSignature = 'INDIRECT_SIGNATURE',
  NoSignature = 'NO_SIGNATURE',
  Signature = 'SIGNATURE'
}

export enum Easypost_Status_Types {
  Created = 'created',
  Creating = 'creating',
  Failed = 'failed'
}

export enum Easypost_Tracker_Status {
  AvailableForPickup = 'available_for_pickup',
  Cancelled = 'cancelled',
  Delivered = 'delivered',
  Error = 'error',
  Failure = 'failure',
  InTransit = 'in_transit',
  OutForDelivery = 'out_for_delivery',
  PreTransit = 'pre_transit',
  ReturnToSender = 'return_to_sender',
  Unknown = 'unknown'
}

export enum Entry_Type {
  /** Credit */
  Credit = 'CREDIT',
  /** Debit */
  Debit = 'DEBIT'
}

export enum M2MActionType {
  Add = 'ADD',
  Clear = 'CLEAR',
  Remove = 'REMOVE',
  Set = 'SET'
}

export enum Model_Enum {
  AccountingServiceDiscount = '_Accounting_ServiceDiscount',
  AccountingSoftwareDiscount = '_Accounting_SoftwareDiscount',
  AddressAddress = '_Address_Address',
  BreakdownBreakDown = '_Breakdown_BreakDown',
  BreakdownBreakDownItems = '_Breakdown_BreakDownItems',
  BuildUpBuildUp = '_BuildUp_BuildUp',
  BuildUpBuildUpItems = '_BuildUp_BuildUpItems',
  ChoicesChoice = '_Choices_Choice',
  ChoicesStatus = '_Choices_Status',
  ChoicesTag = '_Choices_Tag',
  ClientsClient = '_Clients_Client',
  ClientsClientTerms = '_Clients_ClientTerms',
  ClientsContact = '_Clients_Contact',
  ClientsSubs = '_Clients_Subs',
  ClientsTerms = '_Clients_Terms',
  CompanyCompany = '_Company_Company',
  CompanyCreditCard = '_Company_CreditCard',
  CompanySubs = '_Company_Subs',
  CustomPermissionsRole = '_CustomPermissions_Role',
  CustomPermissionsRolePermissions = '_CustomPermissions_RolePermissions',
  DepartmentDepartment = '_Department_Department',
  DepartmentDepartmentEmployees = '_Department_DepartmentEmployees',
  DepartmentDepartmentRoles = '_Department_DepartmentRoles',
  ImporterStaging = '_Importer_Staging',
  InventoryItem = '_Inventory_Item',
  InventoryService = '_Inventory_Service',
  InventorySoftware = '_Inventory_Software',
  InventorySystem = '_Inventory_System',
  InvoicesApInvoice = '_Invoices_APInvoice',
  InvoicesArInvoice = '_Invoices_ARInvoice',
  LocationLocation = '_Location_Location',
  LocationSite = '_Location_Site',
  LoggingActionLog = '_Logging_ActionLog',
  LoggingActionLogObject = '_Logging_ActionLogObject',
  NotificationsNotification = '_Notifications_Notification',
  PartsAccessPoint = '_Parts_AccessPoint',
  PartsAssociatedPn = '_Parts_AssociatedPN',
  PartsBattery = '_Parts_Battery',
  PartsBezel = '_Parts_Bezel',
  PartsBracket = '_Parts_Bracket',
  PartsCpu = '_Parts_CPU',
  PartsCable = '_Parts_Cable',
  PartsCache = '_Parts_Cache',
  PartsDiskDrive = '_Parts_DiskDrive',
  PartsExpansionCard = '_Parts_ExpansionCard',
  PartsFan = '_Parts_Fan',
  PartsGpu = '_Parts_GPU',
  PartsHeatsink = '_Parts_Heatsink',
  PartsIoModule = '_Parts_IOModule',
  PartsMemory = '_Parts_Memory',
  PartsMotherboard = '_Parts_Motherboard',
  PartsNetworkCard = '_Parts_NetworkCard',
  PartsPart = '_Parts_Part',
  PartsPhone = '_Parts_Phone',
  PartsPowerSupply = '_Parts_PowerSupply',
  PartsProductCompany = '_Parts_ProductCompany',
  PartsRails = '_Parts_Rails',
  PartsRouter = '_Parts_Router',
  PartsServer = '_Parts_Server',
  PartsStorage = '_Parts_Storage',
  PartsStorageEnclosure = '_Parts_StorageEnclosure',
  PartsSupplies = '_Parts_Supplies',
  PartsSwitch = '_Parts_Switch',
  ProfileLiveTemplate = '_Profile_LiveTemplate',
  ProfileProfile = '_Profile_Profile',
  ProfileProfileMetrics = '_Profile_ProfileMetrics',
  PurchasesPurchase = '_Purchases_Purchase',
  PurchasesPurchasedItems = '_Purchases_PurchasedItems',
  QuotesQuote = '_Quotes_Quote',
  QuotesQuoteParts = '_Quotes_QuoteParts',
  RmaPurchaseRma = '_RMA_PurchaseRMA',
  RmaPurchaseRmaItems = '_RMA_PurchaseRMAItems',
  RmaRma = '_RMA_RMA',
  RmaRmaItems = '_RMA_RMAItems',
  SalesSale = '_Sales_Sale',
  SalesSoldItems = '_Sales_SoldItems',
  SalesSoldServices = '_Sales_SoldServices',
  SalesSoldSoftware = '_Sales_SoldSoftware',
  ShippingAccount = '_Shipping_Account',
  ShippingCarrier = '_Shipping_Carrier',
  ShippingPredefinedParcel = '_Shipping_PredefinedParcel',
  ShippingService = '_Shipping_Service',
  ShippingShipment = '_Shipping_Shipment',
  ShippingShipmentOrder = '_Shipping_ShipmentOrder',
  ShippingShipmentSupplies = '_Shipping_ShipmentSupplies',
  TeamTeam = '_Team_Team',
  TeamTeamMembers = '_Team_TeamMembers',
  TimerInternalTimer = '_Timer_InternalTimer',
  TimerUserTimer = '_Timer_UserTimer',
  UsersUser = '_Users_User',
  WorkOrderWorkOrder = '_WorkOrder_WorkOrder',
  WorkOrderWorkOrderItems = '_WorkOrder_WorkOrderItems'
}

export enum Name_Type {
  /** Field */
  Field = 'FIELD',
  /** Model */
  Model = 'MODEL',
  /** Mutation */
  Mutation = 'MUTATION'
}

export enum Part_Type {
  AccessPoint = 'AccessPoint',
  Battery = 'Battery',
  Bezel = 'Bezel',
  Bracket = 'Bracket',
  Cpu = 'CPU',
  Cable = 'Cable',
  Cache = 'Cache',
  DiskDrive = 'DiskDrive',
  ExpansionCard = 'ExpansionCard',
  Fan = 'Fan',
  Gpu = 'GPU',
  Heatsink = 'Heatsink',
  IoModule = 'IOModule',
  Memory = 'Memory',
  Motherboard = 'Motherboard',
  NetworkCard = 'NetworkCard',
  Phone = 'Phone',
  PowerSupply = 'PowerSupply',
  Rails = 'Rails',
  Router = 'Router',
  Server = 'Server',
  Storage = 'Storage',
  StorageEnclosure = 'StorageEnclosure',
  Supplies = 'Supplies',
  Switch = 'Switch'
}

export enum Pickup_Type {
  /** Courier Pickup */
  Courier = 'COURIER',
  /** Customer Pickup */
  Customer = 'CUSTOMER',
  /** Not Pickup */
  NotPickup = 'NOT_PICKUP'
}

export enum Printnode_Content_Type {
  PdfBase64 = 'pdf_base64',
  PdfUri = 'pdf_uri',
  RawBase64 = 'raw_base64',
  RawUri = 'raw_uri'
}

export enum Printnode_Printjob_State {
  Deleted = 'deleted',
  Disappeared = 'disappeared',
  Done = 'done',
  Downloaded = 'downloaded',
  Downloading = 'downloading',
  Error = 'error',
  Expired = 'expired',
  InProgress = 'in_progress',
  New = 'new',
  PendingConfirmation = 'pending_confirmation',
  PreparingToPrint = 'preparing_to_print',
  Queued = 'queued',
  QueuedToPrint = 'queued_to_print',
  Received = 'received',
  SentToClient = 'sent_to_client'
}

export enum Printnode_Unit {
  Cpm = 'cpm',
  Ipm = 'ipm',
  Lmp = 'lmp',
  Ppm = 'ppm'
}

export enum Shipment_Order_Type {
  /** PURCHASE */
  Purchase = 'PURCHASE',
  /** PURCHASE_RMA */
  PurchaseRma = 'PURCHASE_RMA',
  /** RMA */
  Rma = 'RMA',
  /** SALE */
  Sale = 'SALE',
  /** WORK_ORDER */
  WorkOrder = 'WORK_ORDER'
}

export enum Source {
  /** Accounts Payable */
  Ap = 'AP',
  /** Accounts Receivable */
  Ar = 'AR',
  /** Unbook */
  Unbook = 'UNBOOK'
}

export enum Status_Type {
  /** Created */
  Created = 'CREATED',
  /** Doing */
  Doing = 'DOING',
  /** Done */
  Done = 'DONE',
  /** Final */
  Final = 'FINAL',
  /** Todo */
  Todo = 'TODO',
  /** Waiting */
  Waiting = 'WAITING'
}

export enum Swap_Type {
  /** Alt */
  Alt = 'ALT',
  /** None */
  None = 'NONE',
  /** Sub */
  Sub = 'SUB'
}

export enum Timer_Type {
  /** Alert */
  Alert = 'ALERT',
  /** Report */
  Report = 'REPORT'
}

export enum Type {
  /** Pt */
  Pt = 'PT',
  /** St */
  St = 'ST',
  /** Wo */
  Wo = 'WO'
}

/** The types of verification that can be done. */
export enum Verify_Type {
  /** Verifies that the address is deliverable. */
  Delivery = 'delivery',
  /** This is only for US. Gets back the zip4 for the zip. */
  Zip4 = 'zip4'
}

export type AggregateInput = {
  aggr_type: Aggr_Type;
  name: Scalars['String'];
};

/** Formats to the arguments for a Django Annotation query */
export type AnnotationObjectInput = {
  /** The field to aggregate. */
  aggr_field: Scalars['String'];
  /** The filters to apply to the annotation query. EXCLUDES will be ignored. */
  aggr_filters?: Maybe<Array<Maybe<QueryFilter>>>;
  /** The type of aggregation to perform in the annotation. */
  aggr_type: Aggr_Type;
  /** The name for the attribute to be stored on the object. */
  name: Scalars['String'];
};

export type CreatePrintJobInput = {
  /** Should be the base64 encoding of the document to print. */
  content: Scalars['String'];
  /** Only raw_base64 and pdf_base64 are supported currently. */
  contentType: Printnode_Content_Type;
  expireAfter?: Maybe<Scalars['Int']>;
  printerId: Scalars['ID'];
  qty?: Maybe<Scalars['Int']>;
  source: Scalars['String'];
  title: Scalars['String'];
};

export type Create__Accounting_Account__Input = {
  account_number: Scalars['String'];
  account_type: Account_Type;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parent_account_id?: Maybe<Scalars['ID']>;
};

export type Create__Accounting_Entry__Input = {
  account_id: Scalars['ID'];
  amount?: Maybe<Scalars['Price']>;
  amount_currency?: Maybe<Currency>;
  entry_type: Entry_Type;
  note?: Maybe<Scalars['String']>;
  transaction_id: Scalars['ID'];
};

export type Create__Accounting_ServiceDiscount__Input = {
  active_maximum?: Maybe<Scalars['Price']>;
  active_maximum_currency?: Maybe<Currency>;
  active_minimum?: Maybe<Scalars['Price']>;
  active_minimum_currency?: Maybe<Currency>;
  discount_amount?: Maybe<Scalars['Price']>;
  discount_amount_currency?: Maybe<Currency>;
  discount_percent?: Maybe<Scalars['Percent']>;
  edit_lock?: Maybe<Scalars['Boolean']>;
  period: Scalars['Duration'];
  service_id: Scalars['ID'];
};

export type Create__Accounting_SoftwareDiscount__Input = {
  active_maximum?: Maybe<Scalars['Price']>;
  active_maximum_currency?: Maybe<Currency>;
  active_minimum?: Maybe<Scalars['Price']>;
  active_minimum_currency?: Maybe<Currency>;
  discount_amount?: Maybe<Scalars['Price']>;
  discount_amount_currency?: Maybe<Currency>;
  discount_percent?: Maybe<Scalars['Percent']>;
  edit_lock?: Maybe<Scalars['Boolean']>;
  period: Scalars['Duration'];
  software_id: Scalars['ID'];
};

export type Create__Accounting_Transaction__Input = {
  internal_reference?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  /** The source of the Transaction. */
  source?: Maybe<Source>;
  /** If the Transaction has been reviewed or not. */
  was_reviewed?: Maybe<Scalars['Boolean']>;
};

export type Create__Address_Address__Input = {
  building_name?: Maybe<Scalars['String']>;
  carrier_facility?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['ID']>;
  company_id?: Maybe<Scalars['ID']>;
  country?: Maybe<Countries>;
  default_contact_id?: Maybe<Scalars['ID']>;
  easypost_id?: Maybe<Scalars['String']>;
  residential?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  street_1: Scalars['String'];
  street_2?: Maybe<Scalars['String']>;
  zip_code: Scalars['String'];
};

export type Create__Breakdown_BreakDownItems__Input = {
  break_down_id: Scalars['ID'];
  item_id: Scalars['ID'];
  status_id?: Maybe<Scalars['ID']>;
};

export type Create__Breakdown_BreakDown__Input = {
  assigned_to_id?: Maybe<Scalars['ID']>;
  status_id?: Maybe<Scalars['ID']>;
  system_id: Scalars['ID'];
};

export type Create__BuildUp_BuildUpItems__Input = {
  build_up_id: Scalars['ID'];
  item_id: Scalars['ID'];
  status_id?: Maybe<Scalars['ID']>;
};

export type Create__BuildUp_BuildUp__Input = {
  assigned_to_id?: Maybe<Scalars['ID']>;
  status_id?: Maybe<Scalars['ID']>;
  system_id: Scalars['ID'];
};

export type Create__Choices_Tag__Input = {
  app_name: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  field_name: Scalars['String'];
  model_name: Scalars['String'];
  tag: Scalars['String'];
};

export type Create__Clients_Client__Input = {
  /** Whether the client can accept gifts or not. */
  accepts_gifts?: Maybe<Scalars['Boolean']>;
  /** A manual override for allowing sales to a company. */
  allow_sales_override?: Maybe<Scalars['Boolean']>;
  client_of_id?: Maybe<Scalars['ID']>;
  credit?: Maybe<Scalars['Price']>;
  credit_currency?: Maybe<Currency>;
  credit_limit?: Maybe<Scalars['Price']>;
  credit_limit_currency?: Maybe<Currency>;
  default_billing_address_id?: Maybe<Scalars['ID']>;
  default_billing_contact_id?: Maybe<Scalars['ID']>;
  /** The default terms when purchasing from this client. */
  default_pt_terms_id?: Maybe<Scalars['ID']>;
  default_remit_address_id?: Maybe<Scalars['ID']>;
  default_remit_contact_id?: Maybe<Scalars['ID']>;
  default_return_to_address_id?: Maybe<Scalars['ID']>;
  default_ship_from_address_id?: Maybe<Scalars['ID']>;
  default_ship_to_address_id?: Maybe<Scalars['ID']>;
  default_ship_to_contact_id?: Maybe<Scalars['ID']>;
  default_shipping_account_id?: Maybe<Scalars['ID']>;
  default_shipping_service_id?: Maybe<Scalars['ID']>;
  /** The default terms when selling to this client. */
  default_st_terms_id?: Maybe<Scalars['ID']>;
  default_tracking_email_recipients?: Maybe<Array<Maybe<Scalars['Email']>>>;
  /** What eCommerce store the Client is from. */
  ecommerce_store?: Maybe<Scalars['String']>;
  federal_tax_id?: Maybe<Scalars['String']>;
  internal_rep_id?: Maybe<Scalars['ID']>;
  is_1099?: Maybe<Scalars['Boolean']>;
  is_our_client?: Maybe<Scalars['Boolean']>;
  is_tax_exempt?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  outstanding_invoice_limit?: Maybe<Scalars['Price']>;
  outstanding_invoice_limit_currency?: Maybe<Currency>;
  payment?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Rating']>;
  shipping_instructions?: Maybe<Scalars['String']>;
  short_name: Scalars['String'];
  state_tax_id?: Maybe<Scalars['String']>;
  tax_exempt_number?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['ID']>;
  website?: Maybe<Scalars['String']>;
};

export type Create__Clients_Contact__Input = {
  email?: Maybe<Scalars['Email']>;
  fax?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  is_our_client?: Maybe<Scalars['Boolean']>;
  last_name: Scalars['String'];
  owner_id: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
};

export type Create__Clients_ShippingLabel__Input = {
  client_id: Scalars['ID'];
  label: Scalars['String'];
  name: Scalars['String'];
};

export type Create__Clients_Subs__Input = {
  client_id: Scalars['ID'];
  /** The part to be subbed. */
  pn_id: Scalars['ID'];
  /** The sub for the part. */
  sub_id: Scalars['ID'];
};

export type Create__Clients_Terms__Input = {
  /** Who owns these terms. */
  client_id?: Maybe<Scalars['ID']>;
  /** How many days they have. */
  days?: Maybe<Scalars['PositiveInteger']>;
  /** Any content for the terms. */
  description?: Maybe<Scalars['String']>;
  /** The name of the terms. */
  name: Scalars['String'];
  /** Any internal notes related to these terms. */
  notes?: Maybe<Scalars['String']>;
};

export type Create__Company_CreditCard__Input = {
  active?: Maybe<Scalars['Boolean']>;
  credit_left?: Maybe<Scalars['Price']>;
  credit_left_currency?: Maybe<Currency>;
  limit?: Maybe<Scalars['Price']>;
  limit_currency?: Maybe<Currency>;
  name: Scalars['String'];
  owner_id: Scalars['ID'];
};

export type Create__Company_Subs__Input = {
  pn_id: Scalars['ID'];
  sub_id: Scalars['ID'];
};

export type Create__CustomPermissions_RolePermissions__Input = {
  permission_id: Scalars['ID'];
  role_id: Scalars['ID'];
};

export type Create__CustomPermissions_Role__Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Create__Department_DepartmentEmployees__Input = {
  department_id: Scalars['ID'];
  user_id: Scalars['ID'];
};

export type Create__Department_DepartmentRoles__Input = {
  department_id: Scalars['ID'];
  role_id: Scalars['ID'];
};

export type Create__Department_Department__Input = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Create__Inventory_Item__Input = {
  /** The date the Item was allocated. */
  allocated_date?: Maybe<Scalars['DateTime']>;
  /** An alternate serial number for the Item. */
  alternate_serial_number?: Maybe<Scalars['String']>;
  /** The associated part number of the Item. */
  associated_pn_id: Scalars['ID'];
  /** The last date the Item was audited. */
  audit_date?: Maybe<Scalars['DateTime']>;
  /** Who currently has the Item. */
  checked_out_by_id?: Maybe<Scalars['ID']>;
  /** More information relating to the Item's condition. */
  condition_comment?: Maybe<Scalars['String']>;
  /** The sale condition of the Item. New, refurb, etc.. */
  condition_id: Scalars['ID'];
  /** The origin country of this Item. */
  country_of_origin: Countries;
  /** The firmware version currently applied. */
  fw?: Maybe<Scalars['String']>;
  /** What physical condition the Item is in. */
  grade_id: Scalars['ID'];
  /** Whether this item is for internal use or not. */
  internal?: Maybe<Scalars['Boolean']>;
  /** General inventory comments. */
  inventory_comment?: Maybe<Scalars['String']>;
  /** Whether the Item is lost or not. */
  is_lost?: Maybe<Scalars['Boolean']>;
  /** The date of the last write down for this Item. */
  last_write_down_date?: Maybe<Scalars['DateTime']>;
  /** The location where the part is. */
  location_id?: Maybe<Scalars['ID']>;
  /** The reason for locking the Item. */
  lock_reason?: Maybe<Scalars['String']>;
  /** If locked, the Item can't be sold. */
  locked?: Maybe<Scalars['Boolean']>;
  /** The date of the next write down for this Item. */
  next_write_down_date?: Maybe<Scalars['DateTime']>;
  /** What kind of part the Item is. */
  part_id: Scalars['ID'];
  /** How many of these parts are in the Item. */
  quantity?: Maybe<Scalars['PositiveInteger']>;
  /** If the Item is Received(True), Booked(Null), or Unreceived(False). */
  receive_status?: Maybe<Scalars['Boolean']>;
  /** Who received the Item. */
  received_by_id?: Maybe<Scalars['ID']>;
  /** The date the Item was received. */
  received_date?: Maybe<Scalars['DateTime']>;
  /** The serial number of the Item. */
  serial_number?: Maybe<Scalars['String']>;
  /** The current status of the Item within a process. */
  status_id?: Maybe<Scalars['ID']>;
  /** The date the Item was unbooked. */
  unbooked_date?: Maybe<Scalars['DateTime']>;
};

export type Create__Inventory_Service__Input = {
  comments?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  name: Scalars['String'];
  period: Scalars['Duration'];
  price?: Maybe<Scalars['Price']>;
  price_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
};

export type Create__Inventory_Software__Input = {
  comments?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  manufacturer?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  period: Scalars['Duration'];
  price?: Maybe<Scalars['Price']>;
  price_currency?: Maybe<Currency>;
  product_tier?: Maybe<Scalars['String']>;
  product_version?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  support_period?: Maybe<Scalars['Duration']>;
  support_price?: Maybe<Scalars['Price']>;
  support_price_currency?: Maybe<Currency>;
  vendor?: Maybe<Scalars['String']>;
};

export type Create__Inventory_System__Input = {
  /** The date the Item was allocated. */
  allocated_date?: Maybe<Scalars['DateTime']>;
  /** An alternate serial number for the Item. */
  alternate_serial_number?: Maybe<Scalars['String']>;
  /** The associated part number of the Item. */
  associated_pn_id: Scalars['ID'];
  /** The last date the Item was audited. */
  audit_date?: Maybe<Scalars['DateTime']>;
  /** Who currently has the Item. */
  checked_out_by_id?: Maybe<Scalars['ID']>;
  /** More information relating to the Item's condition. */
  condition_comment?: Maybe<Scalars['String']>;
  /** The sale condition of the Item. New, refurb, etc.. */
  condition_id: Scalars['ID'];
  /** The origin country of this Item. */
  country_of_origin: Countries;
  /** The firmware version currently applied. */
  fw?: Maybe<Scalars['String']>;
  /** What physical condition the Item is in. */
  grade_id: Scalars['ID'];
  /** Whether this item is for internal use or not. */
  internal?: Maybe<Scalars['Boolean']>;
  /** General inventory comments. */
  inventory_comment?: Maybe<Scalars['String']>;
  /** Whether the Item is lost or not. */
  is_lost?: Maybe<Scalars['Boolean']>;
  /** The date of the last write down for this Item. */
  last_write_down_date?: Maybe<Scalars['DateTime']>;
  /** The location where the part is. */
  location_id?: Maybe<Scalars['ID']>;
  /** The reason for locking the Item. */
  lock_reason?: Maybe<Scalars['String']>;
  /** If locked, the Item can't be sold. */
  locked?: Maybe<Scalars['Boolean']>;
  /** The date of the next write down for this Item. */
  next_write_down_date?: Maybe<Scalars['DateTime']>;
  /** The serial for the Out of Bound Management System. */
  oobmgmt_serial?: Maybe<Scalars['String']>;
  /** What kind of part the Item is. */
  part_id: Scalars['ID'];
  /** How many of these parts are in the Item. */
  quantity?: Maybe<Scalars['PositiveInteger']>;
  /** If the Item is Received(True), Booked(Null), or Unreceived(False). */
  receive_status?: Maybe<Scalars['Boolean']>;
  /** Who received the Item. */
  received_by_id?: Maybe<Scalars['ID']>;
  /** The date the Item was received. */
  received_date?: Maybe<Scalars['DateTime']>;
  /** The serial number of the Item. */
  serial_number?: Maybe<Scalars['String']>;
  /** The current status of the Item within a process. */
  status_id?: Maybe<Scalars['ID']>;
  /** The date the Item was unbooked. */
  unbooked_date?: Maybe<Scalars['DateTime']>;
};

export type Create__Invoices_ApInvoice__Input = {
  /** The amount to pay. */
  amount?: Maybe<Scalars['Price']>;
  amount_currency?: Maybe<Currency>;
  /** The date the invoice is due by. */
  date_due?: Maybe<Scalars['Date']>;
  /** The date the invoice was sent. */
  date_sent?: Maybe<Scalars['Date']>;
  /** If the invoice has been voided. */
  is_voided?: Maybe<Scalars['Boolean']>;
  /** The purchase order this invoice is for. */
  order_id: Scalars['ID'];
  /** A reference number for the invoice. */
  reference?: Maybe<Scalars['String']>;
  /** The current status of the invoice. */
  status_id?: Maybe<Scalars['ID']>;
};

export type Create__Invoices_ApPayment__Input = {
  /** The amount paid. */
  amount?: Maybe<Scalars['Price']>;
  amount_currency?: Maybe<Currency>;
  /** The invoice the payment was made on. */
  invoice_id: Scalars['ID'];
  /** If the payment has posted. */
  is_posted?: Maybe<Scalars['Boolean']>;
  /** A reference number for the payment. */
  reference?: Maybe<Scalars['String']>;
};

export type Create__Invoices_ArInvoice__Input = {
  /** The amount to pay. */
  amount?: Maybe<Scalars['Price']>;
  amount_currency?: Maybe<Currency>;
  /** The date the invoice is due by. */
  date_due?: Maybe<Scalars['Date']>;
  /** The date the invoice was sent. */
  date_sent?: Maybe<Scalars['Date']>;
  /** If the invoice has been voided. */
  is_voided?: Maybe<Scalars['Boolean']>;
  /** The sales order this invoice is for. */
  order_id: Scalars['ID'];
  /** A reference number for the invoice. */
  reference?: Maybe<Scalars['String']>;
  /** The current status of the invoice. */
  status_id?: Maybe<Scalars['ID']>;
};

export type Create__Invoices_ArPayment__Input = {
  /** The amount paid. */
  amount?: Maybe<Scalars['Price']>;
  amount_currency?: Maybe<Currency>;
  /** The invoice the payment was made on. */
  invoice_id: Scalars['ID'];
  /** If the payment has posted. */
  is_posted?: Maybe<Scalars['Boolean']>;
  /** A reference number for the payment. */
  reference?: Maybe<Scalars['String']>;
};

export type Create__Location_Location__Input = {
  name: Scalars['String'];
  part_type?: Maybe<Scalars['String']>;
  site_id: Scalars['ID'];
  type_id?: Maybe<Scalars['ID']>;
};

export type Create__Location_Site__Input = {
  address_id?: Maybe<Scalars['ID']>;
  delivery_notes?: Maybe<Scalars['String']>;
  main_site?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  short_name: Scalars['String'];
};

export type Create__Logging_ActionLog__Input = {
  action: Action;
  description?: Maybe<Scalars['String']>;
  new_data?: Maybe<Scalars['JSON']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  user_id: Scalars['ID'];
  was_successful?: Maybe<Scalars['Boolean']>;
};

export type Create__Notifications_Notification__Input = {
  message: Scalars['String'];
  time_stamp?: Maybe<Scalars['DateTime']>;
  urgent?: Maybe<Scalars['Boolean']>;
  user_id: Scalars['ID'];
};

export type Create__Parts_AccessPoint__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  antennas?: Maybe<Scalars['PositiveInteger']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  external_antennas?: Maybe<Scalars['Boolean']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  lan_ports?: Maybe<Scalars['PositiveInteger']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  max_throughput_mbps?: Maybe<Scalars['PositiveInteger']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  mounting_gear_included?: Maybe<Scalars['Boolean']>;
  pn: Scalars['String'];
  poe_type_id?: Maybe<Scalars['ID']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  security_id?: Maybe<Scalars['ID']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
  wifi_standards_id?: Maybe<Scalars['ID']>;
};

export type Create__Parts_AssociatedPn__Input = {
  associated_pn: Scalars['String'];
  base_pn_id: Scalars['ID'];
};

export type Create__Parts_BomParts__Input = {
  bom_id: Scalars['ID'];
  maximum_count?: Maybe<Scalars['PositiveInteger']>;
  minimum_count?: Maybe<Scalars['PositiveInteger']>;
  part_id: Scalars['ID'];
  part_type?: Maybe<Scalars['String']>;
};

export type Create__Parts_Bom__Input = {
  bom_for_id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
};

export type Create__Parts_Battery__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_Bezel__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  for_server_id?: Maybe<Scalars['ID']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_Bracket__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_Cpu__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  cores?: Maybe<Scalars['PositiveInteger']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  hz_id?: Maybe<Scalars['ID']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  speed?: Maybe<Scalars['Float']>;
  threads?: Maybe<Scalars['PositiveInteger']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_Cable__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['String']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  port?: Maybe<Scalars['String']>;
  port_2?: Maybe<Scalars['String']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  sub_type_id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_Cache__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_DiskDrive__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  sub_type_id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_ExpansionCard__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  gen?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  interface?: Maybe<Scalars['String']>;
  lanes?: Maybe<Scalars['PositiveInteger']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  sub_type_id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_Fan__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_Gpu__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  core_clock_mhz?: Maybe<Scalars['PositiveInteger']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  interface_id?: Maybe<Scalars['ID']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  memory_bandwidth_mbps?: Maybe<Scalars['PositiveInteger']>;
  memory_mb?: Maybe<Scalars['PositiveInteger']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_Heatsink__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_IoModule__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_Memory__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  capacity_amount?: Maybe<Scalars['PositiveInteger']>;
  capacity_unit_id?: Maybe<Scalars['ID']>;
  cas_latency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dimm_type_id?: Maybe<Scalars['ID']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pins_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  speed_id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_Motherboard__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  cpu_sockets?: Maybe<Scalars['PositiveInteger']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  max_memory_gb?: Maybe<Scalars['PositiveInteger']>;
  memory_type_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  ram_sockets?: Maybe<Scalars['PositiveInteger']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_NetworkCard__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  ports?: Maybe<Scalars['PositiveInteger']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  speed?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_Part__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_Phone__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  lan_port_speed_mbps?: Maybe<Scalars['PositiveInteger']>;
  lan_ports?: Maybe<Scalars['PositiveInteger']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  poe_capable?: Maybe<Scalars['Boolean']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
  wifi_capable?: Maybe<Scalars['Boolean']>;
  wifi_standards_id?: Maybe<Scalars['ID']>;
};

export type Create__Parts_PowerSupply__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  current?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  efficiency_id?: Maybe<Scalars['ID']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  wattage?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_ProductCompany__Input = {
  name: Scalars['String'];
  short_name: Scalars['String'];
};

export type Create__Parts_Rails__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  for_server_id?: Maybe<Scalars['ID']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_Router__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  built_in_vpn?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  enterprise_wifi_security_id?: Maybe<Scalars['ID']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  has_wifi?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  is_web_management?: Maybe<Scalars['Boolean']>;
  lan_ports?: Maybe<Scalars['PositiveInteger']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  personal_wifi_security_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  throughput_id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  wan_ports?: Maybe<Scalars['PositiveInteger']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
  wifi_standards_id?: Maybe<Scalars['ID']>;
};

export type Create__Parts_Server__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  form_factor_id?: Maybe<Scalars['ID']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  rack_height_id?: Maybe<Scalars['ID']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  storage_bays_25?: Maybe<Scalars['PositiveInteger']>;
  storage_bays_35?: Maybe<Scalars['PositiveInteger']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_StorageEnclosure__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  interface_id?: Maybe<Scalars['ID']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  speed_id?: Maybe<Scalars['ID']>;
  storage_bays_25?: Maybe<Scalars['PositiveInteger']>;
  storage_bays_35?: Maybe<Scalars['PositiveInteger']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_Storage__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  capacity_amount?: Maybe<Scalars['PositiveInteger']>;
  capacity_unit_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  interface_id?: Maybe<Scalars['ID']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  physical_size_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  speed_id?: Maybe<Scalars['ID']>;
  spindle_speed_id?: Maybe<Scalars['ID']>;
  sub_type_id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_Supplies__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  size?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  sub_type_id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Parts_Switch__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn: Scalars['String'];
  poe_ports?: Maybe<Scalars['PositiveInteger']>;
  ports?: Maybe<Scalars['PositiveInteger']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  sfp_ports?: Maybe<Scalars['PositiveInteger']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  speed?: Maybe<Scalars['String']>;
  switch_type_id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Create__Profile_LiveTemplate__Input = {
  department_id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  team_id?: Maybe<Scalars['ID']>;
  template: Scalars['String'];
};

export type Create__Profile_ProfileMetrics__Input = {
  current?: Maybe<Scalars['Boolean']>;
  parts_broken_down?: Maybe<Scalars['PositiveInteger']>;
  parts_moved?: Maybe<Scalars['PositiveInteger']>;
  parts_owned?: Maybe<Scalars['PositiveInteger']>;
  parts_processed?: Maybe<Scalars['PositiveInteger']>;
  parts_purchased?: Maybe<Scalars['PositiveInteger']>;
  parts_received?: Maybe<Scalars['PositiveInteger']>;
  parts_shipped?: Maybe<Scalars['PositiveInteger']>;
  parts_sold?: Maybe<Scalars['PositiveInteger']>;
  parts_tested?: Maybe<Scalars['PositiveInteger']>;
  parts_wiped?: Maybe<Scalars['PositiveInteger']>;
  profile_id: Scalars['ID'];
  sales?: Maybe<Scalars['PositiveInteger']>;
};

export type Create__Profile_Profile__Input = {
  color?: Maybe<Scalars['String']>;
  column_config?: Maybe<Scalars['JSON']>;
  commission_percentage?: Maybe<Scalars['Percent']>;
  default_site_id?: Maybe<Scalars['ID']>;
  hire_date?: Maybe<Scalars['DateTime']>;
  job_title?: Maybe<Scalars['String']>;
  last_review?: Maybe<Scalars['DateTime']>;
  manager_id?: Maybe<Scalars['ID']>;
  order_cache?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  terminated?: Maybe<Scalars['Boolean']>;
  terminated_date?: Maybe<Scalars['DateTime']>;
  upcoming_review?: Maybe<Scalars['DateTime']>;
  user_id: Scalars['ID'];
  wage?: Maybe<Scalars['Percent']>;
};

export type Create__Profile_TableConfig__Input = {
  config?: Maybe<Scalars['JSON']>;
  display_name?: Maybe<Scalars['String']>;
  is_shareable?: Maybe<Scalars['Boolean']>;
  table_name: Scalars['String'];
};

export type Create__Purchases_Purchase__Input = {
  /** The date the order was accepted. */
  acceptance_date?: Maybe<Scalars['DateTime']>;
  /** The date the order was approved. */
  approval_date?: Maybe<Scalars['DateTime']>;
  /** Who approved the order. */
  approved_by_id?: Maybe<Scalars['ID']>;
  /** Who was assigned to pick this order. */
  assigned_to_id?: Maybe<Scalars['ID']>;
  /** The client this order was made to/from. */
  client_id?: Maybe<Scalars['ID']>;
  /** The client's order number. */
  client_order_number?: Maybe<Scalars['String']>;
  /** The date to close the order by. */
  closing_date?: Maybe<Scalars['Date']>;
  /** The condition of the parts on this order. */
  condition_id?: Maybe<Scalars['ID']>;
  /** The person to contact for this order. */
  contact_id?: Maybe<Scalars['ID']>;
  /** Comments to put on the contract for this order. */
  contract_comment?: Maybe<Scalars['String']>;
  /** Who sent the contract. */
  contract_sent_by_id?: Maybe<Scalars['ID']>;
  credit_card_id?: Maybe<Scalars['ID']>;
  /** The down payment amount for this order. */
  down_payment?: Maybe<Scalars['Price']>;
  down_payment_currency?: Maybe<Currency>;
  /** If editing is locked for this object or not. */
  edit_lock?: Maybe<Scalars['Boolean']>;
  /** The estimated time of arrival for the order. */
  eta?: Maybe<Scalars['DateTime']>;
  /** The cost of freight for this order. */
  freight?: Maybe<Scalars['Price']>;
  freight_currency?: Maybe<Currency>;
  /** Comments on this order for internal use. */
  internal_comment?: Maybe<Scalars['String']>;
  /** The amount to charge for late delivery. */
  late_delivery_charge?: Maybe<Scalars['Price']>;
  late_delivery_charge_currency?: Maybe<Currency>;
  /** Miscellaneous charges on this order. */
  misc?: Maybe<Scalars['Price']>;
  misc_currency?: Maybe<Currency>;
  /** The amount to pre pay for the order. */
  pre_pay?: Maybe<Scalars['Price']>;
  pre_pay_currency?: Maybe<Currency>;
  /** The date the order was made. */
  purchase_date?: Maybe<Scalars['DateTime']>;
  /** The quote used for creating this order. */
  quote_id?: Maybe<Scalars['ID']>;
  /** Who's order this is. */
  rep_id?: Maybe<Scalars['ID']>;
  /** The date the order was requested by. */
  requested_date?: Maybe<Scalars['DateTime']>;
  /** The Shipment Order this order is tied to. */
  shipment_order_id?: Maybe<Scalars['ID']>;
  status_id?: Maybe<Scalars['ID']>;
  /** The tax amount for this order. */
  tax?: Maybe<Scalars['Price']>;
  tax_currency?: Maybe<Currency>;
  /** The terms for this order. */
  terms_id?: Maybe<Scalars['ID']>;
  /** Who was assigned to test this order. */
  tester_id?: Maybe<Scalars['ID']>;
  /** The total value of the order. */
  total?: Maybe<Scalars['Price']>;
  total_currency?: Maybe<Currency>;
  /** The vendors own reference number. */
  vendor_reference?: Maybe<Scalars['String']>;
  /** The date that the warranty expires. */
  warranty_end_date?: Maybe<Scalars['Date']>;
  /** If we arrange transportation. */
  we_transport?: Maybe<Scalars['Boolean']>;
};

export type Create__Purchases_PurchasedItems__Input = {
  /** The current value of this Item. */
  current_cost?: Maybe<Scalars['Price']>;
  current_cost_currency?: Maybe<Currency>;
  /** If editing is locked for this object or not. */
  edit_lock?: Maybe<Scalars['Boolean']>;
  /** The Item on the order. */
  item_id?: Maybe<Scalars['ID']>;
  /** The Item's line on the order. */
  line_number?: Maybe<Scalars['PositiveInteger']>;
  /** The amount this Item was originally purchased for. */
  original_cost?: Maybe<Scalars['Price']>;
  original_cost_currency?: Maybe<Currency>;
  /** What the Item was originally bought/sold as. */
  original_part_id?: Maybe<Scalars['ID']>;
  /** What the Item is being bought/sold as. */
  part_id: Scalars['ID'];
  /** What Shipment this Item is on. */
  shipment_id?: Maybe<Scalars['ID']>;
  /** If the part is swapped or not, and if so what kind of swap. */
  swap_type?: Maybe<Swap_Type>;
  /** The tax value for this Item. */
  tax?: Maybe<Scalars['Price']>;
  tax_currency?: Maybe<Currency>;
  /** The order this Item is attached to. */
  transaction_id: Scalars['ID'];
};

export type Create__Quotes_QuoteParts__Input = {
  part_id?: Maybe<Scalars['ID']>;
  price_per_part?: Maybe<Scalars['Price']>;
  price_per_part_currency?: Maybe<Currency>;
  qty: Scalars['PositiveInteger'];
  quote_id: Scalars['ID'];
};

export type Create__Quotes_Quote__Input = {
  client_id: Scalars['ID'];
  contact_id?: Maybe<Scalars['ID']>;
  contract_comment?: Maybe<Scalars['String']>;
  /** How much the customer is in need of the product on a scale of 0-5. */
  desire_level?: Maybe<Scalars['Rating']>;
  internal_comment?: Maybe<Scalars['String']>;
  /** How good the pricing is on the quote on a scale of 0-5. */
  price_grade?: Maybe<Scalars['Rating']>;
  purchaser_address_id?: Maybe<Scalars['ID']>;
  return_address_id?: Maybe<Scalars['ID']>;
  ship_from_address_id?: Maybe<Scalars['ID']>;
  ship_to_address_id?: Maybe<Scalars['ID']>;
  type: Type;
};

export type Create__Rma_PurchaseRmaItems__Input = {
  credit?: Maybe<Scalars['Boolean']>;
  returned_part_id: Scalars['ID'];
  rma_id: Scalars['ID'];
  shipment_id?: Maybe<Scalars['ID']>;
  tested_condition_id?: Maybe<Scalars['ID']>;
};

export type Create__Rma_PurchaseRma__Input = {
  claim_date?: Maybe<Scalars['Date']>;
  closed_date?: Maybe<Scalars['Date']>;
  pt_id: Scalars['ID'];
  received_date?: Maybe<Scalars['Date']>;
  shipment_order_id?: Maybe<Scalars['ID']>;
  status_id?: Maybe<Scalars['ID']>;
  voided_date?: Maybe<Scalars['Date']>;
};

export type Create__Rma_RmaItems__Input = {
  credit?: Maybe<Scalars['Boolean']>;
  returned_part_id: Scalars['ID'];
  rma_id: Scalars['ID'];
  shipment_id?: Maybe<Scalars['ID']>;
  tested_condition_id?: Maybe<Scalars['ID']>;
};

export type Create__Rma_Rma__Input = {
  assigned_to_id?: Maybe<Scalars['ID']>;
  claim_date?: Maybe<Scalars['Date']>;
  closed_by_id?: Maybe<Scalars['ID']>;
  closed_date?: Maybe<Scalars['DateTime']>;
  contact_id?: Maybe<Scalars['ID']>;
  contract_comment?: Maybe<Scalars['String']>;
  internal_comment?: Maybe<Scalars['String']>;
  received_by_id?: Maybe<Scalars['ID']>;
  received_date?: Maybe<Scalars['DateTime']>;
  return_code_id?: Maybe<Scalars['ID']>;
  return_reason?: Maybe<Scalars['String']>;
  shipment_order_id?: Maybe<Scalars['ID']>;
  st_id: Scalars['ID'];
  status_id?: Maybe<Scalars['ID']>;
  tested_by_id?: Maybe<Scalars['ID']>;
  tested_date?: Maybe<Scalars['DateTime']>;
  verified_by_id?: Maybe<Scalars['ID']>;
  verified_date?: Maybe<Scalars['DateTime']>;
  voided_by_id?: Maybe<Scalars['ID']>;
  voided_date?: Maybe<Scalars['DateTime']>;
  warranty_honored_after_cut_off?: Maybe<Scalars['Boolean']>;
};

export type Create__Reporting_Report__Input = {
  /** This stores ChartJS settings to use instead of the defaults. */
  chart_data?: Maybe<Scalars['JSON']>;
  /** What kind of chart it is. */
  chart_type?: Maybe<Chart_Type>;
  /** The data from the query. */
  data?: Maybe<Scalars['JSON']>;
  /** A description of the report. */
  description: Scalars['String'];
  is_list?: Maybe<Scalars['Boolean']>;
  /** What is the name of the report. */
  name: Scalars['String'];
  /** The query to use for the report. */
  query: Scalars['String'];
  /** What Model the report is for. */
  report_model: Scalars['String'];
};

export type Create__Sales_Fees__Input = {
  /** The amount of the fee. */
  amount?: Maybe<Scalars['Price']>;
  amount_currency?: Maybe<Currency>;
  /** A description for the fee. */
  description: Scalars['String'];
  /** The order this fee is on. */
  transaction_id: Scalars['ID'];
};

export type Create__Sales_Sale__Input = {
  /** Who was assigned to pick this order. */
  assigned_to_id?: Maybe<Scalars['ID']>;
  /** The date the order was booked. */
  book_date?: Maybe<Scalars['DateTime']>;
  /** The client this order was made to/from. */
  client_id?: Maybe<Scalars['ID']>;
  /** The client's order number. */
  client_order_number?: Maybe<Scalars['String']>;
  /** The condition of the parts on this order. */
  condition_id?: Maybe<Scalars['ID']>;
  /** The person to contact for this order. */
  contact_id?: Maybe<Scalars['ID']>;
  /** Comments to put on the contract for this order. */
  contract_comment?: Maybe<Scalars['String']>;
  /** When the contract needs to be sent by. */
  contract_send_by_date?: Maybe<Scalars['Date']>;
  /** The declared value of the order. */
  declared_value?: Maybe<Scalars['Price']>;
  declared_value_currency?: Maybe<Currency>;
  /** The deposit amount for the order. */
  deposit?: Maybe<Scalars['Price']>;
  deposit_currency?: Maybe<Currency>;
  /** The down payment amount for this order. */
  down_payment?: Maybe<Scalars['Price']>;
  down_payment_currency?: Maybe<Currency>;
  /** If editing is locked for this object or not. */
  edit_lock?: Maybe<Scalars['Boolean']>;
  /** The estimated cost of the order. */
  estimated_cost?: Maybe<Scalars['Price']>;
  estimated_cost_currency?: Maybe<Currency>;
  /** The estimated margin on the order. */
  estimated_margin?: Maybe<Scalars['Price']>;
  estimated_margin_currency?: Maybe<Currency>;
  /** The cost of freight for this order. */
  freight?: Maybe<Scalars['Price']>;
  freight_currency?: Maybe<Currency>;
  /** The cost of installing equipment for the customer. */
  installation_cost?: Maybe<Scalars['Price']>;
  installation_cost_currency?: Maybe<Currency>;
  /** Comments on this order for internal use. */
  internal_comment?: Maybe<Scalars['String']>;
  /** Miscellaneous charges on this order. */
  misc?: Maybe<Scalars['Price']>;
  misc_currency?: Maybe<Currency>;
  /** The date the order was promised by. */
  promise_date?: Maybe<Scalars['DateTime']>;
  /** The quote used for creating this order. */
  quote_id?: Maybe<Scalars['ID']>;
  /** Who's order this is. */
  rep_id?: Maybe<Scalars['ID']>;
  /** The date the order was made. */
  sale_date?: Maybe<Scalars['DateTime']>;
  /** The date the order needs to ship by. */
  ship_by_date?: Maybe<Scalars['Date']>;
  /** The Shipment Order this order is tied to. */
  shipment_order_id?: Maybe<Scalars['ID']>;
  status_id?: Maybe<Scalars['ID']>;
  /** The tax amount for this order. */
  tax?: Maybe<Scalars['Price']>;
  tax_currency?: Maybe<Currency>;
  /** The terms for this order. */
  terms_id?: Maybe<Scalars['ID']>;
  /** Who was assigned to test this order. */
  tester_id?: Maybe<Scalars['ID']>;
  /** The date that the warranty expires. */
  warranty_end_date?: Maybe<Scalars['Date']>;
  /** If we pay the installation cost. */
  we_install?: Maybe<Scalars['Boolean']>;
  /** If we arrange transportation. */
  we_transport?: Maybe<Scalars['Boolean']>;
};

export type Create__Sales_SoldItems__Input = {
  /** The percentage of the margin that goes to the purchasing rep. */
  buyer_margin_percentage?: Maybe<Scalars['Percent']>;
  /** If editing is locked for this object or not. */
  edit_lock?: Maybe<Scalars['Boolean']>;
  /** The Item on the order. */
  item_id?: Maybe<Scalars['ID']>;
  /** The Item's line on the order. */
  line_number?: Maybe<Scalars['PositiveInteger']>;
  /** What the Item was originally bought/sold as. */
  original_part_id?: Maybe<Scalars['ID']>;
  /** What the Item is being bought/sold as. */
  part_id: Scalars['ID'];
  /** The percentage of the margin that goes to the selling rep. */
  seller_margin_percentage?: Maybe<Scalars['Percent']>;
  /** What Shipment this Item is on. */
  shipment_id?: Maybe<Scalars['ID']>;
  /** The amount this Item was sold for. */
  sold_for?: Maybe<Scalars['Price']>;
  sold_for_currency?: Maybe<Currency>;
  status_id?: Maybe<Scalars['ID']>;
  /** If the part is swapped or not, and if so what kind of swap. */
  swap_type?: Maybe<Swap_Type>;
  /** The tax value of this Item. */
  tax?: Maybe<Scalars['Price']>;
  tax_currency?: Maybe<Currency>;
  /** The order this Item was sold on. */
  transaction_id: Scalars['ID'];
};

export type Create__Sales_SoldServices__Input = {
  /** If editing is locked for this object. */
  edit_lock?: Maybe<Scalars['Boolean']>;
  /** How many periods this product was sold for. */
  periods_sold_for?: Maybe<Scalars['PositiveInteger']>;
  /** How many items of this product were sold. */
  quantity?: Maybe<Scalars['PositiveInteger']>;
  /** The Service that was sold. */
  service_id: Scalars['ID'];
  /** The tax value of this product. */
  tax?: Maybe<Scalars['Price']>;
  tax_currency?: Maybe<Currency>;
  /** The total value of this product. */
  total?: Maybe<Scalars['Price']>;
  total_currency?: Maybe<Currency>;
  /** The order this Service was sold on. */
  transaction_id: Scalars['ID'];
};

export type Create__Sales_SoldSoftware__Input = {
  /** If editing is locked for this object. */
  edit_lock?: Maybe<Scalars['Boolean']>;
  /** How many periods this product was sold for. */
  periods_sold_for?: Maybe<Scalars['PositiveInteger']>;
  /** How many items of this product were sold. */
  quantity?: Maybe<Scalars['PositiveInteger']>;
  /** The Software that was sold. */
  software_id: Scalars['ID'];
  /** The tax value of this product. */
  tax?: Maybe<Scalars['Price']>;
  tax_currency?: Maybe<Currency>;
  /** The total value of this product. */
  total?: Maybe<Scalars['Price']>;
  total_currency?: Maybe<Currency>;
  /** The order this Software was sold on. */
  transaction_id: Scalars['ID'];
};

export type Create__Shipping_Account__Input = {
  client_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  easypost_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  shipper_id: Scalars['ID'];
};

export type Create__Shipping_Carrier__Input = {
  easypost_name?: Maybe<Scalars['String']>;
  is_courier?: Maybe<Scalars['Boolean']>;
  is_easypost?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type Create__Shipping_PredefinedParcel__Input = {
  height_in: Scalars['Float'];
  length_in: Scalars['Float'];
  name: Scalars['String'];
  width_in: Scalars['Float'];
};

export type Create__Shipping_ServiceLevel__Input = {
  color?: Maybe<Scalars['String']>;
  easypost_name?: Maybe<Scalars['String']>;
  order_arrive_by?: Maybe<Scalars['CustomTime']>;
  order_ready_by?: Maybe<Scalars['CustomTime']>;
  service?: Maybe<Scalars['String']>;
  shipper_id: Scalars['ID'];
};

export type Create__Shipping_ShipmentOrder__Input = {
  account_id?: Maybe<Scalars['ID']>;
  assigned_to_id?: Maybe<Scalars['ID']>;
  blind?: Maybe<Blind>;
  deliver_by_date?: Maybe<Scalars['Date']>;
  easypost_id?: Maybe<Scalars['String']>;
  pickup_type?: Maybe<Pickup_Type>;
  purchaser_address_id?: Maybe<Scalars['ID']>;
  purchaser_contact_id?: Maybe<Scalars['ID']>;
  purchaser_rep_id?: Maybe<Scalars['ID']>;
  release_number?: Maybe<Scalars['String']>;
  return_address_id?: Maybe<Scalars['ID']>;
  return_contact_id?: Maybe<Scalars['ID']>;
  return_rep_id?: Maybe<Scalars['ID']>;
  service_id?: Maybe<Scalars['ID']>;
  ship_from_address_id?: Maybe<Scalars['ID']>;
  ship_from_contact_id?: Maybe<Scalars['ID']>;
  ship_from_rep_id?: Maybe<Scalars['ID']>;
  ship_to_address_id?: Maybe<Scalars['ID']>;
  ship_to_contact_id?: Maybe<Scalars['ID']>;
  ship_to_rep_id?: Maybe<Scalars['ID']>;
  shipping_comments?: Maybe<Scalars['String']>;
  status_id?: Maybe<Scalars['ID']>;
  tracking_email_recipients?: Maybe<Array<Maybe<Scalars['Email']>>>;
  tracking_number?: Maybe<Scalars['String']>;
  we_pay?: Maybe<Scalars['Boolean']>;
};

export type Create__Shipping_ShipmentSupplies__Input = {
  shipment_id: Scalars['ID'];
  supplies_id: Scalars['ID'];
};

export type Create__Shipping_Shipment__Input = {
  billed_cost?: Maybe<Scalars['Price']>;
  billed_cost_currency?: Maybe<Currency>;
  box_number?: Maybe<Scalars['PositiveInteger']>;
  easypost_id?: Maybe<Scalars['String']>;
  insurance_cost?: Maybe<Scalars['Price']>;
  insurance_cost_currency?: Maybe<Currency>;
  insurance_status?: Maybe<Scalars['String']>;
  order_id: Scalars['ID'];
  parcel_height_in?: Maybe<Scalars['Float']>;
  parcel_length_in?: Maybe<Scalars['Float']>;
  parcel_weight_oz?: Maybe<Scalars['Float']>;
  parcel_width_in?: Maybe<Scalars['Float']>;
  payment_status?: Maybe<Scalars['String']>;
  predefined_parcel?: Maybe<Scalars['String']>;
  refund_status?: Maybe<Scalars['String']>;
  release_number?: Maybe<Scalars['String']>;
  shipment_cost?: Maybe<Scalars['Price']>;
  shipment_cost_currency?: Maybe<Currency>;
  tracking_number?: Maybe<Scalars['String']>;
  tracking_status?: Maybe<Scalars['String']>;
};

export type Create__Team_TeamMembers__Input = {
  member_id: Scalars['ID'];
  team_id: Scalars['ID'];
};

export type Create__Team_Team__Input = {
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
};

export type Create__Timer_BaseTimer__Input = {
  /** A short description of the timer. */
  description?: Maybe<Scalars['String']>;
  /** If the timer is enabled. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** When the timer was last ran. */
  last_ran?: Maybe<Scalars['DateTime']>;
  /** A short and unique name for the timer. */
  name: Scalars['String'];
  /** The ID/PK of the object for the timer. */
  object_pk: Scalars['PositiveInteger'];
  /** How often the timer is supposed to be run. Minimum unit is an hour. */
  period?: Maybe<Scalars['Duration']>;
  /** What sort of timer it is. */
  timer_type: Timer_Type;
  /** How many times the timer has been run. */
  times_run?: Maybe<Scalars['PositiveInteger']>;
  /** How many times to run it. 0 means forever. */
  times_to_run?: Maybe<Scalars['PositiveInteger']>;
};

export type Create__WorkOrder_WorkOrderItems__Input = {
  amount?: Maybe<Scalars['Price']>;
  amount_currency?: Maybe<Currency>;
  item_id: Scalars['ID'];
  work_order_id: Scalars['ID'];
};

export type Create__WorkOrder_WorkOrder__Input = {
  amount?: Maybe<Scalars['Price']>;
  amount_currency?: Maybe<Currency>;
  contract_comment?: Maybe<Scalars['String']>;
  internal_comment?: Maybe<Scalars['String']>;
  need_by?: Maybe<Scalars['Date']>;
  purchase_transaction_id?: Maybe<Scalars['ID']>;
  shipment_order_id?: Maybe<Scalars['ID']>;
  status_id?: Maybe<Scalars['ID']>;
  terms_id?: Maybe<Scalars['ID']>;
  type_id?: Maybe<Scalars['ID']>;
  vendor_id?: Maybe<Scalars['ID']>;
  warranty_end_date?: Maybe<Scalars['Date']>;
};

export type EasyPost_CarrierAccountFieldsInput = {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type EasyPost_CustomsInfoInput = {
  contents_explanation?: Maybe<Scalars['String']>;
  contents_type?: Maybe<Easypost_Contents_Type>;
  customs_certify?: Maybe<Scalars['Boolean']>;
  customs_items?: Maybe<Array<Maybe<EasyPost_CustomsItemInput>>>;
  customs_signer?: Maybe<Scalars['String']>;
  eel_pfc: Scalars['String'];
  non_delivery_option?: Maybe<Easypost_Non_Delivery_Option>;
  restriction_comments?: Maybe<Scalars['String']>;
  restriction_type?: Maybe<Easypost_Restriction_Type>;
};

export type EasyPost_CustomsItemInput = {
  /** SKUUPC or other product identifier. */
  code?: Maybe<Scalars['String']>;
  /** 3 character currency code. */
  currency?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  /** Tariff code */
  hs_tariff_number?: Maybe<Scalars['String']>;
  /** 2 character country code. */
  origin_country: Scalars['String'];
  quantity: Scalars['Float'];
  /** Total value[USD](unit value * quantity */
  value: Scalars['Float'];
  /** Weight in ounces */
  weight: Scalars['Float'];
};

export type EasyPost_MakeAddressInput = {
  carrier_facility?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  federal_tax_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  residential?: Maybe<Scalars['Boolean']>;
  state: Scalars['String'];
  state_tax_id?: Maybe<Scalars['String']>;
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  /** What kind of verification to do. */
  verify?: Maybe<Array<Maybe<Verify_Type>>>;
  /** Same as verify, except if it fails verification no address is made. */
  verify_strict?: Maybe<Array<Maybe<Verify_Type>>>;
  zip: Scalars['String'];
};

export type EasyPost_MakeCarrierAccountInput = {
  clone?: Maybe<Scalars['Boolean']>;
  credentials?: Maybe<Array<EasyPost_CarrierAccountFieldsInput>>;
  description?: Maybe<Scalars['String']>;
  readable?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  test_credentials?: Maybe<Array<EasyPost_CarrierAccountFieldsInput>>;
  type?: Maybe<Scalars['String']>;
};

export type EasyPost_MakeParcelInput = {
  /** Height in inches */
  height?: Maybe<Scalars['Float']>;
  /** Length in inches */
  length?: Maybe<Scalars['Float']>;
  /** The predefined_package in easypost for that carrier. */
  predefined_package?: Maybe<Scalars['String']>;
  /** Weight in ounces */
  weight: Scalars['Float'];
  /** Width in inches */
  width?: Maybe<Scalars['Float']>;
};

export type EasyPost_MakeShipmentInput = {
  /** Carrier Account IDs */
  carrier_accounts?: Maybe<Array<Maybe<Scalars['EasyPostID']>>>;
  /** Customs Info ID. Required for international shipments. */
  customs_info?: Maybe<Scalars['EasyPostID']>;
  /** Address ID */
  from_address?: Maybe<Scalars['EasyPostID']>;
  /** Whether or not to generate a return label for the shipment. */
  is_return?: Maybe<Scalars['Boolean']>;
  /** Shipment options */
  options?: Maybe<EasyPost_ShipmentOptionsInput>;
  /** Parcel ID */
  parcel: Scalars['EasyPostID'];
  /** A reference name string */
  reference?: Maybe<Scalars['String']>;
  /** Address ID */
  to_address?: Maybe<Scalars['EasyPostID']>;
};

export type EasyPost_OrderInput = {
  /** The buyer's address, defaults to to_address */
  buyer_address?: Maybe<Scalars['EasyPostID']>;
  /** A list of carrier account ids. */
  carrier_accounts?: Maybe<Array<Maybe<Scalars['EasyPostID']>>>;
  /** Destination address */
  from_address: Scalars['EasyPostID'];
  /** Set true to create a return. */
  is_return?: Maybe<Scalars['Boolean']>;
  /** The shipper's address, defaults to from_address */
  return_address?: Maybe<Scalars['EasyPostID']>;
  /** A list of easypost IDs. */
  shipments?: Maybe<Array<Maybe<Scalars['EasyPostID']>>>;
  /** Origin address */
  to_address: Scalars['EasyPostID'];
};

export type EasyPost_RegisterFedexAccountInput = {
  /** { "label": "Account Number", "parent": "credential", "child": "account" }  */
  account_number: Scalars['String'];
  /** { "label": "City", "placeholder": "Minneapolis", "parent": "company", "child": "city" } */
  corporate_city: Scalars['String'];
  /** { "label": "Name", "placeholder": "Your company name", "parent": "company", "child": "company-name" } */
  corporate_company_name: Scalars['String'];
  /** { "label": "Country Code", "placeholder": "US", "parent": "company", "child": "country" } */
  corporate_country_code: Scalars['String'];
  /** { "label": "Email", "placeholder": "first.last@company.com", "parent": "account", "child": "email" } */
  corporate_email_address: Scalars['String'];
  /** { "label": "First Name", "placeholder": "John", "parent": "account", "child": "first-name" } */
  corporate_first_name: Scalars['String'];
  /** { "label": "Job Title", "placeholder": "Manager", "parent": "account", "child": "title" } */
  corporate_job_title: Scalars['String'];
  /** { "label": "Last Name", "placeholder": "Smith", "parent": "account", "child": "last-name" } */
  corporate_last_name: Scalars['String'];
  /** { "label": "Phone", "placeholder": "123-123-1234", "parent": "account", "child": "phone" } */
  corporate_phone_number: Scalars['String'];
  /** { "label": "Postal Code", "placeholder": "55112", "parent": "company", "child": "postal" } */
  corporate_postal_code: Scalars['String'];
  /** { "label": "State", "placeholder": "Minnesota", "parent": "company", "child": "state" } */
  corporate_state: Scalars['String'];
  /** { "label": "Street Address", "placeholder": "1234 Example St. Suite 100", "parent": "company", "child": "street" } */
  corporate_streets: Scalars['String'];
  /** { "label": "City", "placeholder": "Minneapolis", "parent": "shipping", "child": "city", "link": "company" } */
  shipping_city: Scalars['String'];
  /** { "label": "Country Code", "placeholder": "US", "parent": "shipping", "child": "country", "link": "company" } */
  shipping_country_code: Scalars['String'];
  /** { "label": "Postal Code", "placeholder": "55112", "parent": "shipping", "child": "postal", "link": "company" } */
  shipping_postal_code: Scalars['String'];
  /** { "label": "State", "placeholder": "Minnesota", "parent": "shipping", "child": "state", "link": "company" } */
  shipping_state: Scalars['String'];
  /** { "label": "Street Address", "placeholder": "1234 Example St. Suite 100", "parent": "shipping", "child": "street", "link": "company" } */
  shipping_streets: Scalars['String'];
};

export type EasyPost_RegisterUpsAccountInput = {
  /** { "label": "UPS Account Number", "parent": "credential", "child": "account" }  */
  account_number: Scalars['String'];
  /** { "label": "City", "placeholder": "Minneapolis", "parent": "company", "child": "city" } */
  city: Scalars['String'];
  /** { "label": "Name", "placeholder": "Your company name", "parent": "company", "child": "company-name" } */
  company: Scalars['String'];
  /** { "label": "Country", "placeholder": "United States of America", "parent": "company", "child": "country" } */
  country: Scalars['String'];
  /** { "label": "Email", "placeholder": "first.last@company.com", "parent": "account", "child": "email" } */
  email: Scalars['String'];
  /** { "label": "Amount", "placeholder": "xxx.xx", "hint": "Optional if account doesn't have an invoice yet", "parent": "invoice", "child": "invoice_amount" } */
  invoice_amount?: Maybe<Scalars['String']>;
  /** { "label": "Control ID", "placeholder": "", "hint": "Optional if account doesn't have an invoice yet", "parent": "invoice", "child": "invoice_control_id" } */
  invoice_control_id?: Maybe<Scalars['String']>;
  /** { "label": "Currency", "placeholder": "USD", "hint": "NOT OPTIONAL", "parent": "invoice", "child": "invoice_currency" } */
  invoice_currency: Scalars['String'];
  /** { "label": "Date", "placeholder": "mm/dd/yyyy", "hint": "Optional if account doesn't have an invoice yet", "parent": "invoice", "child": "invoice_date" } */
  invoice_date?: Maybe<Scalars['String']>;
  /**
   * { "label": "Number", "placeholder": "Valid Recent Invoice", "hint":
   * "Optional if account doesn't have an invoice yet", "parent": "invoice", "child": "invoice_number" }
   */
  invoice_number?: Maybe<Scalars['String']>;
  /** { "label": "Name", "placeholder": "John Smith", "parent": "account", "child": "person-name" } */
  name: Scalars['String'];
  /** { "label": "Phone", "placeholder": "123-123-1234", "parent": "account", "child": "email" } */
  phone: Scalars['String'];
  /** { "label": "Postal Code", "placeholder": "55112", "parent": "company", "child": "postal" } */
  postal_code: Scalars['String'];
  /** { "label": "State", "placeholder": "Minnesota", "parent": "company", "child": "state" } */
  state: Scalars['String'];
  /** { "label": "Street Address", "placeholder": "1234 Example St. Suite 100", "parent": "company", "child": "street" } */
  street1: Scalars['String'];
  /** { "label": "Job Title", "placeholder": "Manager", "parent": "account", "child": "title" } */
  title: Scalars['String'];
  /** { "label": "Website", "placeholder": "www.example.com", "parent": "company", "child": "company-website" } */
  website: Scalars['String'];
};

export type EasyPost_ShipmentOptionsInput = {
  additional_handling?: Maybe<Scalars['Boolean']>;
  /** This is only for USPS */
  address_validation_level?: Maybe<Scalars['String']>;
  bill_receiver_account?: Maybe<Scalars['String']>;
  bill_receiver_postal_code?: Maybe<Scalars['String']>;
  bill_third_party_account?: Maybe<Scalars['String']>;
  bill_third_party_country?: Maybe<Scalars['String']>;
  bill_third_party_postal_code?: Maybe<Scalars['String']>;
  billing_ref?: Maybe<Scalars['String']>;
  by_drone?: Maybe<Scalars['Boolean']>;
  carbon_neutral?: Maybe<Scalars['Boolean']>;
  certified_mail?: Maybe<Easypost_Certified_Mail>;
  /** Address ID */
  cod_address_id?: Maybe<Scalars['EasyPostID']>;
  cod_amount?: Maybe<Scalars['String']>;
  cod_method?: Maybe<Easypost_Cod_Method>;
  currency?: Maybe<Scalars['String']>;
  delivery_confirmation?: Maybe<Easypost_Signature_Type>;
  dropoff_type?: Maybe<Easypost_Dropoff_Type>;
  dry_ice?: Maybe<Scalars['Boolean']>;
  dry_ice_medical?: Maybe<Scalars['String']>;
  dry_ice_weight?: Maybe<Scalars['String']>;
  endorsement?: Maybe<Easypost_Endorsement>;
  freight_charge?: Maybe<Scalars['Float']>;
  handling_instructions?: Maybe<Scalars['String']>;
  hazmat?: Maybe<Easypost_Hazmat>;
  hold_for_pickup?: Maybe<Scalars['Boolean']>;
  incoterm?: Maybe<Easypost_Incoterm>;
  invoice_number?: Maybe<Scalars['String']>;
  label_date?: Maybe<Scalars['String']>;
  label_format: Easypost_Label_Format;
  machinable?: Maybe<Scalars['Boolean']>;
  payment?: Maybe<EasyPost_ShipmentOptionsPaymentInput>;
  print_custom_1?: Maybe<Scalars['String']>;
  print_custom_1_barcode?: Maybe<Scalars['Boolean']>;
  print_custom_1_code?: Maybe<Scalars['String']>;
  print_custom_2?: Maybe<Scalars['String']>;
  print_custom_2_barcode?: Maybe<Scalars['Boolean']>;
  print_custom_2_code?: Maybe<Scalars['String']>;
  print_custom_3?: Maybe<Scalars['String']>;
  print_custom_3_barcode?: Maybe<Scalars['Boolean']>;
  print_custom_3_code?: Maybe<Scalars['String']>;
  registered_mail?: Maybe<Scalars['Boolean']>;
  registered_mail_amount?: Maybe<Scalars['Float']>;
  return_receipt?: Maybe<Scalars['Boolean']>;
  saturday_delivery?: Maybe<Scalars['Boolean']>;
  smartpost_hub?: Maybe<Scalars['String']>;
  smartpost_manifest?: Maybe<Scalars['String']>;
  special_rates_eligibility?: Maybe<Scalars['String']>;
};

export type EasyPost_ShipmentOptionsPaymentInput = {
  account?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  type?: Maybe<Easypost_Payment_Type>;
};

export type M2MChangeInput = {
  id: Scalars['ID'];
  ids_to_alter?: Maybe<Array<Scalars['ID']>>;
  type: M2MActionType;
};

export type ManyQueryInput = {
  /** Annotations to apply to the query. */
  annotations?: Maybe<Array<Maybe<AnnotationObjectInput>>>;
  /** Filters to exclude with. */
  excludes?: Maybe<Array<Maybe<QueryFilter>>>;
  /** Filters to filter with. */
  filters?: Maybe<Array<Maybe<QueryFilter>>>;
  /** How many objects to limit the query by. */
  limit?: Maybe<Scalars['Int']>;
  /** How many objects to offset the query by. */
  offset?: Maybe<Scalars['Int']>;
  /** Field name to order by. ASCENDING: 'field', DESCENDING: '-field' */
  order_by?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PrintNodeOptionsInput = {
  bin?: Maybe<Scalars['String']>;
  collate?: Maybe<Scalars['Boolean']>;
  copies?: Maybe<Scalars['Int']>;
  dpi?: Maybe<Scalars['String']>;
  duplex?: Maybe<Scalars['String']>;
  fit_to_page?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Scalars['String']>;
  nup?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['String']>;
  paper?: Maybe<Scalars['String']>;
  rotate?: Maybe<Scalars['Int']>;
};

/** A way to set key,value pairs of different types to make filters. */
export type QueryFilter = {
  /** A filter to AND against. */
  and?: Maybe<QueryFilter>;
  /** The filter key. Usually field_name__iexact etc.. */
  key: Scalars['String'];
  /** A filter to OR against. */
  or?: Maybe<QueryFilter>;
  /** A value to filter with. */
  value: Scalars['Any'];
};

export type ScalarTestInput = {
  any?: Maybe<Scalars['Any']>;
  id?: Maybe<Scalars['ID']>;
};

export type SingleQueryInput = {
  /** Annotations to apply to the query. */
  annotations?: Maybe<Array<Maybe<AnnotationObjectInput>>>;
  /** Filters that should match a single object. */
  filters?: Maybe<Array<Maybe<QueryFilter>>>;
  /** ID of the object to get, 0 gets the first object and -1 gets the last. */
  id?: Maybe<Scalars['ID']>;
};

export type UpdateUserInput = {
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  initials?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_staff?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type UpdateUserSelfInput = {
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  is_staff?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type Update__Accounting_Account__Input = {
  account_number?: Maybe<Scalars['String']>;
  account_type?: Maybe<Account_Type>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  parent_account_id?: Maybe<Scalars['ID']>;
};

export type Update__Accounting_Entry__Input = {
  account_id?: Maybe<Scalars['ID']>;
  amount?: Maybe<Scalars['Price']>;
  amount_currency?: Maybe<Currency>;
  entry_type?: Maybe<Entry_Type>;
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['ID']>;
};

export type Update__Accounting_ServiceDiscount__Input = {
  active_maximum?: Maybe<Scalars['Price']>;
  active_maximum_currency?: Maybe<Currency>;
  active_minimum?: Maybe<Scalars['Price']>;
  active_minimum_currency?: Maybe<Currency>;
  discount_amount?: Maybe<Scalars['Price']>;
  discount_amount_currency?: Maybe<Currency>;
  discount_percent?: Maybe<Scalars['Percent']>;
  edit_lock?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  period?: Maybe<Scalars['Duration']>;
  service_id?: Maybe<Scalars['ID']>;
};

export type Update__Accounting_SoftwareDiscount__Input = {
  active_maximum?: Maybe<Scalars['Price']>;
  active_maximum_currency?: Maybe<Currency>;
  active_minimum?: Maybe<Scalars['Price']>;
  active_minimum_currency?: Maybe<Currency>;
  discount_amount?: Maybe<Scalars['Price']>;
  discount_amount_currency?: Maybe<Currency>;
  discount_percent?: Maybe<Scalars['Percent']>;
  edit_lock?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  period?: Maybe<Scalars['Duration']>;
  software_id?: Maybe<Scalars['ID']>;
};

export type Update__Accounting_Transaction__Input = {
  id: Scalars['ID'];
  internal_reference?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  /** The source of the Transaction. */
  source?: Maybe<Source>;
  /** If the Transaction has been reviewed or not. */
  was_reviewed?: Maybe<Scalars['Boolean']>;
};

export type Update__Address_Address__Input = {
  building_name?: Maybe<Scalars['String']>;
  carrier_facility?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['ID']>;
  company_id?: Maybe<Scalars['ID']>;
  country?: Maybe<Countries>;
  default_contact_id?: Maybe<Scalars['ID']>;
  easypost_id?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  residential?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  street_1?: Maybe<Scalars['String']>;
  street_2?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

export type Update__Breakdown_BreakDownItems__Input = {
  break_down_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  item_id?: Maybe<Scalars['ID']>;
  status_id?: Maybe<Scalars['ID']>;
};

export type Update__Breakdown_BreakDown__Input = {
  assigned_to_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  status_id?: Maybe<Scalars['ID']>;
  system_id?: Maybe<Scalars['ID']>;
};

export type Update__BuildUp_BuildUpItems__Input = {
  build_up_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  item_id?: Maybe<Scalars['ID']>;
  status_id?: Maybe<Scalars['ID']>;
};

export type Update__BuildUp_BuildUp__Input = {
  assigned_to_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  status_id?: Maybe<Scalars['ID']>;
  system_id?: Maybe<Scalars['ID']>;
};

export type Update__Choices_Tag__Input = {
  app_name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  field_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  model_name?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
};

export type Update__Clients_Client__Input = {
  /** Whether the client can accept gifts or not. */
  accepts_gifts?: Maybe<Scalars['Boolean']>;
  /** A manual override for allowing sales to a company. */
  allow_sales_override?: Maybe<Scalars['Boolean']>;
  client_of_id?: Maybe<Scalars['ID']>;
  credit?: Maybe<Scalars['Price']>;
  credit_currency?: Maybe<Currency>;
  credit_limit?: Maybe<Scalars['Price']>;
  credit_limit_currency?: Maybe<Currency>;
  default_billing_address_id?: Maybe<Scalars['ID']>;
  default_billing_contact_id?: Maybe<Scalars['ID']>;
  /** The default terms when purchasing from this client. */
  default_pt_terms_id?: Maybe<Scalars['ID']>;
  default_remit_address_id?: Maybe<Scalars['ID']>;
  default_remit_contact_id?: Maybe<Scalars['ID']>;
  default_return_to_address_id?: Maybe<Scalars['ID']>;
  default_ship_from_address_id?: Maybe<Scalars['ID']>;
  default_ship_to_address_id?: Maybe<Scalars['ID']>;
  default_ship_to_contact_id?: Maybe<Scalars['ID']>;
  default_shipping_account_id?: Maybe<Scalars['ID']>;
  default_shipping_service_id?: Maybe<Scalars['ID']>;
  /** The default terms when selling to this client. */
  default_st_terms_id?: Maybe<Scalars['ID']>;
  default_tracking_email_recipients?: Maybe<Array<Maybe<Scalars['Email']>>>;
  /** What eCommerce store the Client is from. */
  ecommerce_store?: Maybe<Scalars['String']>;
  federal_tax_id?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internal_rep_id?: Maybe<Scalars['ID']>;
  is_1099?: Maybe<Scalars['Boolean']>;
  is_our_client?: Maybe<Scalars['Boolean']>;
  is_tax_exempt?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  outstanding_invoice_limit?: Maybe<Scalars['Price']>;
  outstanding_invoice_limit_currency?: Maybe<Currency>;
  payment?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Rating']>;
  shipping_instructions?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  state_tax_id?: Maybe<Scalars['String']>;
  tax_exempt_number?: Maybe<Scalars['String']>;
  /** What terms this client uses. */
  terms?: Maybe<Array<Maybe<Scalars['ID']>>>;
  type_id?: Maybe<Scalars['ID']>;
  website?: Maybe<Scalars['String']>;
};

export type Update__Clients_Contact__Input = {
  email?: Maybe<Scalars['Email']>;
  fax?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_our_client?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
};

export type Update__Clients_ShippingLabel__Input = {
  client_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Update__Clients_Subs__Input = {
  client_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** The part to be subbed. */
  pn_id?: Maybe<Scalars['ID']>;
  /** The sub for the part. */
  sub_id?: Maybe<Scalars['ID']>;
};

export type Update__Clients_Terms__Input = {
  /** Who owns these terms. */
  client_id?: Maybe<Scalars['ID']>;
  /** How many days they have. */
  days?: Maybe<Scalars['PositiveInteger']>;
  /** Any content for the terms. */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The name of the terms. */
  name?: Maybe<Scalars['String']>;
  /** Any internal notes related to these terms. */
  notes?: Maybe<Scalars['String']>;
};

export type Update__Company_Company__Input = {
  /** The default address for billing. */
  default_billing_address_id?: Maybe<Scalars['ID']>;
  /** The default terms to use for new clients. */
  default_new_client_terms_id?: Maybe<Scalars['ID']>;
  /** The default address for remits. */
  default_remit_address_id?: Maybe<Scalars['ID']>;
  /** The default address for return shipments. */
  default_return_to_address_id?: Maybe<Scalars['ID']>;
  /** The default address to ship from. */
  default_ship_from_address_id?: Maybe<Scalars['ID']>;
  /** The default address to receive shipments at. */
  default_ship_to_address_id?: Maybe<Scalars['ID']>;
  /** The default shipping account for the company. */
  default_shipping_account_id?: Maybe<Scalars['ID']>;
  /** The default shipping service to use. */
  default_shipping_service_id?: Maybe<Scalars['ID']>;
  /** The default terms to use when creating new orders. */
  default_terms_id?: Maybe<Scalars['ID']>;
  /** The company's logo image. */
  logo?: Maybe<Scalars['String']>;
  /** The full name of the company. */
  name?: Maybe<Scalars['String']>;
  /** The default phone number for the company. */
  phone?: Maybe<Scalars['String']>;
  /** A short 10 letter code to reference the company by. */
  short_name?: Maybe<Scalars['String']>;
};

export type Update__Company_CreditCard__Input = {
  active?: Maybe<Scalars['Boolean']>;
  credit_left?: Maybe<Scalars['Price']>;
  credit_left_currency?: Maybe<Currency>;
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Price']>;
  limit_currency?: Maybe<Currency>;
  name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['ID']>;
};

export type Update__Company_Subs__Input = {
  id: Scalars['ID'];
  pn_id?: Maybe<Scalars['ID']>;
  sub_id?: Maybe<Scalars['ID']>;
};

export type Update__CustomPermissions_RolePermissions__Input = {
  id: Scalars['ID'];
  permission_id?: Maybe<Scalars['ID']>;
  role_id?: Maybe<Scalars['ID']>;
};

export type Update__CustomPermissions_Role__Input = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type Update__Department_DepartmentEmployees__Input = {
  department_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  user_id?: Maybe<Scalars['ID']>;
};

export type Update__Department_DepartmentRoles__Input = {
  department_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  role_id?: Maybe<Scalars['ID']>;
};

export type Update__Department_Department__Input = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type Update__Inventory_Item__Input = {
  /** The sub-Items for this Item. (e.g., the parts of a system chassis.) */
  add_on_items?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The date the Item was allocated. */
  allocated_date?: Maybe<Scalars['DateTime']>;
  /** An alternate serial number for the Item. */
  alternate_serial_number?: Maybe<Scalars['String']>;
  /** The associated part number of the Item. */
  associated_pn_id?: Maybe<Scalars['ID']>;
  /** The last date the Item was audited. */
  audit_date?: Maybe<Scalars['DateTime']>;
  /** Who currently has the Item. */
  checked_out_by_id?: Maybe<Scalars['ID']>;
  /** More information relating to the Item's condition. */
  condition_comment?: Maybe<Scalars['String']>;
  /** The sale condition of the Item. New, refurb, etc.. */
  condition_id?: Maybe<Scalars['ID']>;
  /** The origin country of this Item. */
  country_of_origin?: Maybe<Countries>;
  /** The firmware version currently applied. */
  fw?: Maybe<Scalars['String']>;
  /** What physical condition the Item is in. */
  grade_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** Whether this item is for internal use or not. */
  internal?: Maybe<Scalars['Boolean']>;
  /** General inventory comments. */
  inventory_comment?: Maybe<Scalars['String']>;
  /** Whether the Item is lost or not. */
  is_lost?: Maybe<Scalars['Boolean']>;
  /** The date of the last write down for this Item. */
  last_write_down_date?: Maybe<Scalars['DateTime']>;
  /** The location where the part is. */
  location_id?: Maybe<Scalars['ID']>;
  /** The reason for locking the Item. */
  lock_reason?: Maybe<Scalars['String']>;
  /** If locked, the Item can't be sold. */
  locked?: Maybe<Scalars['Boolean']>;
  /** The date of the next write down for this Item. */
  next_write_down_date?: Maybe<Scalars['DateTime']>;
  /** What kind of part the Item is. */
  part_id?: Maybe<Scalars['ID']>;
  /** How many of these parts are in the Item. */
  quantity?: Maybe<Scalars['PositiveInteger']>;
  /** If the Item is Received(True), Booked(Null), or Unreceived(False). */
  receive_status?: Maybe<Scalars['Boolean']>;
  /** Who received the Item. */
  received_by_id?: Maybe<Scalars['ID']>;
  /** The date the Item was received. */
  received_date?: Maybe<Scalars['DateTime']>;
  /** The serial number of the Item. */
  serial_number?: Maybe<Scalars['String']>;
  /** The current status of the Item within a process. */
  status_id?: Maybe<Scalars['ID']>;
  /** What wipes/tests were run or if it is failed. */
  test_status?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The date the Item was unbooked. */
  unbooked_date?: Maybe<Scalars['DateTime']>;
};

export type Update__Inventory_Service__Input = {
  comments?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['Duration']>;
  price?: Maybe<Scalars['Price']>;
  price_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
};

export type Update__Inventory_Software__Input = {
  comments?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  manufacturer?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['Duration']>;
  price?: Maybe<Scalars['Price']>;
  price_currency?: Maybe<Currency>;
  product_tier?: Maybe<Scalars['String']>;
  product_version?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  support_period?: Maybe<Scalars['Duration']>;
  support_price?: Maybe<Scalars['Price']>;
  support_price_currency?: Maybe<Currency>;
  vendor?: Maybe<Scalars['String']>;
};

export type Update__Inventory_System__Input = {
  /** The sub-Items for this Item. (e.g., the parts of a system chassis.) */
  add_on_items?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The date the Item was allocated. */
  allocated_date?: Maybe<Scalars['DateTime']>;
  /** An alternate serial number for the Item. */
  alternate_serial_number?: Maybe<Scalars['String']>;
  /** The associated part number of the Item. */
  associated_pn_id?: Maybe<Scalars['ID']>;
  /** The last date the Item was audited. */
  audit_date?: Maybe<Scalars['DateTime']>;
  /** Who currently has the Item. */
  checked_out_by_id?: Maybe<Scalars['ID']>;
  /** More information relating to the Item's condition. */
  condition_comment?: Maybe<Scalars['String']>;
  /** The sale condition of the Item. New, refurb, etc.. */
  condition_id?: Maybe<Scalars['ID']>;
  /** The origin country of this Item. */
  country_of_origin?: Maybe<Countries>;
  /** The firmware version currently applied. */
  fw?: Maybe<Scalars['String']>;
  /** What physical condition the Item is in. */
  grade_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** Whether this item is for internal use or not. */
  internal?: Maybe<Scalars['Boolean']>;
  /** General inventory comments. */
  inventory_comment?: Maybe<Scalars['String']>;
  /** Whether the Item is lost or not. */
  is_lost?: Maybe<Scalars['Boolean']>;
  /** The date of the last write down for this Item. */
  last_write_down_date?: Maybe<Scalars['DateTime']>;
  /** The location where the part is. */
  location_id?: Maybe<Scalars['ID']>;
  /** The reason for locking the Item. */
  lock_reason?: Maybe<Scalars['String']>;
  /** If locked, the Item can't be sold. */
  locked?: Maybe<Scalars['Boolean']>;
  /** The date of the next write down for this Item. */
  next_write_down_date?: Maybe<Scalars['DateTime']>;
  /** The serial for the Out of Bound Management System. */
  oobmgmt_serial?: Maybe<Scalars['String']>;
  /** What kind of part the Item is. */
  part_id?: Maybe<Scalars['ID']>;
  /** How many of these parts are in the Item. */
  quantity?: Maybe<Scalars['PositiveInteger']>;
  /** If the Item is Received(True), Booked(Null), or Unreceived(False). */
  receive_status?: Maybe<Scalars['Boolean']>;
  /** Who received the Item. */
  received_by_id?: Maybe<Scalars['ID']>;
  /** The date the Item was received. */
  received_date?: Maybe<Scalars['DateTime']>;
  /** The serial number of the Item. */
  serial_number?: Maybe<Scalars['String']>;
  /** The current status of the Item within a process. */
  status_id?: Maybe<Scalars['ID']>;
  /** What wipes/tests were run or if it is failed. */
  test_status?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The date the Item was unbooked. */
  unbooked_date?: Maybe<Scalars['DateTime']>;
};

export type Update__Invoices_ApInvoice__Input = {
  /** The amount to pay. */
  amount?: Maybe<Scalars['Price']>;
  amount_currency?: Maybe<Currency>;
  /** The date the invoice is due by. */
  date_due?: Maybe<Scalars['Date']>;
  /** The date the invoice was sent. */
  date_sent?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  /** If the invoice has been voided. */
  is_voided?: Maybe<Scalars['Boolean']>;
  /** The purchase order this invoice is for. */
  order_id?: Maybe<Scalars['ID']>;
  /** A reference number for the invoice. */
  reference?: Maybe<Scalars['String']>;
  /** The current status of the invoice. */
  status_id?: Maybe<Scalars['ID']>;
};

export type Update__Invoices_ApPayment__Input = {
  /** The amount paid. */
  amount?: Maybe<Scalars['Price']>;
  amount_currency?: Maybe<Currency>;
  id: Scalars['ID'];
  /** The invoice the payment was made on. */
  invoice_id?: Maybe<Scalars['ID']>;
  /** If the payment has posted. */
  is_posted?: Maybe<Scalars['Boolean']>;
  /** A reference number for the payment. */
  reference?: Maybe<Scalars['String']>;
};

export type Update__Invoices_ArInvoice__Input = {
  /** The amount to pay. */
  amount?: Maybe<Scalars['Price']>;
  amount_currency?: Maybe<Currency>;
  /** The date the invoice is due by. */
  date_due?: Maybe<Scalars['Date']>;
  /** The date the invoice was sent. */
  date_sent?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  /** If the invoice has been voided. */
  is_voided?: Maybe<Scalars['Boolean']>;
  /** The sales order this invoice is for. */
  order_id?: Maybe<Scalars['ID']>;
  /** A reference number for the invoice. */
  reference?: Maybe<Scalars['String']>;
  /** The current status of the invoice. */
  status_id?: Maybe<Scalars['ID']>;
};

export type Update__Invoices_ArPayment__Input = {
  /** The amount paid. */
  amount?: Maybe<Scalars['Price']>;
  amount_currency?: Maybe<Currency>;
  id: Scalars['ID'];
  /** The invoice the payment was made on. */
  invoice_id?: Maybe<Scalars['ID']>;
  /** If the payment has posted. */
  is_posted?: Maybe<Scalars['Boolean']>;
  /** A reference number for the payment. */
  reference?: Maybe<Scalars['String']>;
};

export type Update__Location_Location__Input = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  part_type?: Maybe<Scalars['String']>;
  site_id?: Maybe<Scalars['ID']>;
  type_id?: Maybe<Scalars['ID']>;
};

export type Update__Location_Site__Input = {
  address_id?: Maybe<Scalars['ID']>;
  delivery_notes?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  main_site?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
};

export type Update__Logging_ActionLog__Input = {
  action?: Maybe<Action>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  new_data?: Maybe<Scalars['JSON']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  user_id?: Maybe<Scalars['ID']>;
  was_successful?: Maybe<Scalars['Boolean']>;
};

export type Update__Notifications_Notification__Input = {
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  time_stamp?: Maybe<Scalars['DateTime']>;
  urgent?: Maybe<Scalars['Boolean']>;
  user_id?: Maybe<Scalars['ID']>;
};

export type Update__Parts_AccessPoint__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  antennas?: Maybe<Scalars['PositiveInteger']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  external_antennas?: Maybe<Scalars['Boolean']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lan_ports?: Maybe<Scalars['PositiveInteger']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  max_throughput_mbps?: Maybe<Scalars['PositiveInteger']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  mounting_gear_included?: Maybe<Scalars['Boolean']>;
  pn?: Maybe<Scalars['String']>;
  poe_type_id?: Maybe<Scalars['ID']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  security_id?: Maybe<Scalars['ID']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
  wifi_standards_id?: Maybe<Scalars['ID']>;
};

export type Update__Parts_AssociatedPn__Input = {
  associated_pn?: Maybe<Scalars['String']>;
  base_pn_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
};

export type Update__Parts_BomParts__Input = {
  bom_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  maximum_count?: Maybe<Scalars['PositiveInteger']>;
  minimum_count?: Maybe<Scalars['PositiveInteger']>;
  part_id?: Maybe<Scalars['ID']>;
  part_type?: Maybe<Scalars['String']>;
};

export type Update__Parts_Bom__Input = {
  bom_for_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type Update__Parts_Battery__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_Bezel__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  for_server_id?: Maybe<Scalars['ID']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_Bracket__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_Cpu__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  cores?: Maybe<Scalars['PositiveInteger']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  hz_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  speed?: Maybe<Scalars['Float']>;
  threads?: Maybe<Scalars['PositiveInteger']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_Cable__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  length?: Maybe<Scalars['String']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['String']>;
  port_2?: Maybe<Scalars['String']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  sub_type_id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_Cache__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_DiskDrive__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  sub_type_id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_ExpansionCard__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  gen?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  interface?: Maybe<Scalars['String']>;
  lanes?: Maybe<Scalars['PositiveInteger']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  sub_type_id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_Fan__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_Gpu__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  core_clock_mhz?: Maybe<Scalars['PositiveInteger']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  interface_id?: Maybe<Scalars['ID']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  memory_bandwidth_mbps?: Maybe<Scalars['PositiveInteger']>;
  memory_mb?: Maybe<Scalars['PositiveInteger']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_Heatsink__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_IoModule__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_Memory__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  capacity_amount?: Maybe<Scalars['PositiveInteger']>;
  capacity_unit_id?: Maybe<Scalars['ID']>;
  cas_latency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dimm_type_id?: Maybe<Scalars['ID']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pins_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  speed_id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_Motherboard__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  cpu_sockets?: Maybe<Scalars['PositiveInteger']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  max_memory_gb?: Maybe<Scalars['PositiveInteger']>;
  memory_type_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  ram_sockets?: Maybe<Scalars['PositiveInteger']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_NetworkCard__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  ports?: Maybe<Scalars['PositiveInteger']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  speed?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_Part__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_Phone__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lan_port_speed_mbps?: Maybe<Scalars['PositiveInteger']>;
  lan_ports?: Maybe<Scalars['PositiveInteger']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  poe_capable?: Maybe<Scalars['Boolean']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
  wifi_capable?: Maybe<Scalars['Boolean']>;
  wifi_standards_id?: Maybe<Scalars['ID']>;
};

export type Update__Parts_PowerSupply__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  current?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  efficiency_id?: Maybe<Scalars['ID']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  wattage?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_ProductCompany__Input = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
};

export type Update__Parts_Rails__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  for_server_id?: Maybe<Scalars['ID']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_Router__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  built_in_vpn?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  enterprise_wifi_security_id?: Maybe<Scalars['ID']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  has_wifi?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_web_management?: Maybe<Scalars['Boolean']>;
  lan_ports?: Maybe<Scalars['PositiveInteger']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  personal_wifi_security_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  throughput_id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  wan_ports?: Maybe<Scalars['PositiveInteger']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
  wifi_standards_id?: Maybe<Scalars['ID']>;
};

export type Update__Parts_Server__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  form_factor_id?: Maybe<Scalars['ID']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  rack_height_id?: Maybe<Scalars['ID']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  storage_bays_25?: Maybe<Scalars['PositiveInteger']>;
  storage_bays_35?: Maybe<Scalars['PositiveInteger']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_StorageEnclosure__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  interface_id?: Maybe<Scalars['ID']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  speed_id?: Maybe<Scalars['ID']>;
  storage_bays_25?: Maybe<Scalars['PositiveInteger']>;
  storage_bays_35?: Maybe<Scalars['PositiveInteger']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_Storage__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  capacity_amount?: Maybe<Scalars['PositiveInteger']>;
  capacity_unit_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  interface_id?: Maybe<Scalars['ID']>;
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  physical_size_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  speed_id?: Maybe<Scalars['ID']>;
  spindle_speed_id?: Maybe<Scalars['ID']>;
  sub_type_id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_Supplies__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  size?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  sub_type_id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Parts_Switch__Input = {
  /** What client its an alternate part for */
  alt_part_for_client_id?: Maybe<Scalars['ID']>;
  /** What part its an alternate for */
  alt_part_for_part_id?: Maybe<Scalars['ID']>;
  brand_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  has_battery?: Maybe<Scalars['Boolean']>;
  height_in?: Maybe<Scalars['Float']>;
  hs_code?: Maybe<Scalars['String']>;
  htsus_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  length_in?: Maybe<Scalars['Float']>;
  market_id?: Maybe<Scalars['ID']>;
  mfgr_id?: Maybe<Scalars['ID']>;
  pn?: Maybe<Scalars['String']>;
  poe_ports?: Maybe<Scalars['PositiveInteger']>;
  ports?: Maybe<Scalars['PositiveInteger']>;
  schedule_b_code?: Maybe<Scalars['String']>;
  secondary_pn?: Maybe<Scalars['String']>;
  sfp_ports?: Maybe<Scalars['PositiveInteger']>;
  shipment_estimate?: Maybe<Scalars['Price']>;
  shipment_estimate_currency?: Maybe<Currency>;
  sku?: Maybe<Scalars['String']>;
  speed?: Maybe<Scalars['String']>;
  switch_type_id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  weight_oz?: Maybe<Scalars['Float']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Profile_LiveTemplate__Input = {
  department_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['ID']>;
  template?: Maybe<Scalars['String']>;
};

export type Update__Profile_ProfileMetrics__Input = {
  current?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  parts_broken_down?: Maybe<Scalars['PositiveInteger']>;
  parts_moved?: Maybe<Scalars['PositiveInteger']>;
  parts_owned?: Maybe<Scalars['PositiveInteger']>;
  parts_processed?: Maybe<Scalars['PositiveInteger']>;
  parts_purchased?: Maybe<Scalars['PositiveInteger']>;
  parts_received?: Maybe<Scalars['PositiveInteger']>;
  parts_shipped?: Maybe<Scalars['PositiveInteger']>;
  parts_sold?: Maybe<Scalars['PositiveInteger']>;
  parts_tested?: Maybe<Scalars['PositiveInteger']>;
  parts_wiped?: Maybe<Scalars['PositiveInteger']>;
  profile_id?: Maybe<Scalars['ID']>;
  sales?: Maybe<Scalars['PositiveInteger']>;
};

export type Update__Profile_Profile__Input = {
  color?: Maybe<Scalars['String']>;
  column_config?: Maybe<Scalars['JSON']>;
  commission_percentage?: Maybe<Scalars['Percent']>;
  default_site_id?: Maybe<Scalars['ID']>;
  hire_date?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  job_title?: Maybe<Scalars['String']>;
  last_review?: Maybe<Scalars['DateTime']>;
  manager_id?: Maybe<Scalars['ID']>;
  order_cache?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  terminated?: Maybe<Scalars['Boolean']>;
  terminated_date?: Maybe<Scalars['DateTime']>;
  upcoming_review?: Maybe<Scalars['DateTime']>;
  user_id?: Maybe<Scalars['ID']>;
  wage?: Maybe<Scalars['Percent']>;
};

export type Update__Profile_TableConfig__Input = {
  config?: Maybe<Scalars['JSON']>;
  display_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_shareable?: Maybe<Scalars['Boolean']>;
  table_name?: Maybe<Scalars['String']>;
};

export type Update__Purchases_Purchase__Input = {
  /** The date the order was accepted. */
  acceptance_date?: Maybe<Scalars['DateTime']>;
  /** The date the order was approved. */
  approval_date?: Maybe<Scalars['DateTime']>;
  /** Who approved the order. */
  approved_by_id?: Maybe<Scalars['ID']>;
  /** Who was assigned to pick this order. */
  assigned_to_id?: Maybe<Scalars['ID']>;
  /** The client this order was made to/from. */
  client_id?: Maybe<Scalars['ID']>;
  /** The client's order number. */
  client_order_number?: Maybe<Scalars['String']>;
  /** The date to close the order by. */
  closing_date?: Maybe<Scalars['Date']>;
  /** The condition of the parts on this order. */
  condition_id?: Maybe<Scalars['ID']>;
  /** The person to contact for this order. */
  contact_id?: Maybe<Scalars['ID']>;
  /** Comments to put on the contract for this order. */
  contract_comment?: Maybe<Scalars['String']>;
  /** Who sent the contract. */
  contract_sent_by_id?: Maybe<Scalars['ID']>;
  credit_card_id?: Maybe<Scalars['ID']>;
  /** The down payment amount for this order. */
  down_payment?: Maybe<Scalars['Price']>;
  down_payment_currency?: Maybe<Currency>;
  /** If editing is locked for this object or not. */
  edit_lock?: Maybe<Scalars['Boolean']>;
  /** The estimated time of arrival for the order. */
  eta?: Maybe<Scalars['DateTime']>;
  /** The cost of freight for this order. */
  freight?: Maybe<Scalars['Price']>;
  freight_currency?: Maybe<Currency>;
  id: Scalars['ID'];
  /** Comments on this order for internal use. */
  internal_comment?: Maybe<Scalars['String']>;
  /** The amount to charge for late delivery. */
  late_delivery_charge?: Maybe<Scalars['Price']>;
  late_delivery_charge_currency?: Maybe<Currency>;
  /** Miscellaneous charges on this order. */
  misc?: Maybe<Scalars['Price']>;
  misc_currency?: Maybe<Currency>;
  /** The amount to pre pay for the order. */
  pre_pay?: Maybe<Scalars['Price']>;
  pre_pay_currency?: Maybe<Currency>;
  /** The date the order was made. */
  purchase_date?: Maybe<Scalars['DateTime']>;
  /** The quote used for creating this order. */
  quote_id?: Maybe<Scalars['ID']>;
  /** Who's order this is. */
  rep_id?: Maybe<Scalars['ID']>;
  /** The date the order was requested by. */
  requested_date?: Maybe<Scalars['DateTime']>;
  /** The Shipment Order this order is tied to. */
  shipment_order_id?: Maybe<Scalars['ID']>;
  status_id?: Maybe<Scalars['ID']>;
  /** The tax amount for this order. */
  tax?: Maybe<Scalars['Price']>;
  tax_currency?: Maybe<Currency>;
  /** The terms for this order. */
  terms_id?: Maybe<Scalars['ID']>;
  /** Who was assigned to test this order. */
  tester_id?: Maybe<Scalars['ID']>;
  /** The total value of the order. */
  total?: Maybe<Scalars['Price']>;
  total_currency?: Maybe<Currency>;
  /** The vendors own reference number. */
  vendor_reference?: Maybe<Scalars['String']>;
  /** The date that the warranty expires. */
  warranty_end_date?: Maybe<Scalars['Date']>;
  /** If we arrange transportation. */
  we_transport?: Maybe<Scalars['Boolean']>;
};

export type Update__Purchases_PurchasedItems__Input = {
  /** The current value of this Item. */
  current_cost?: Maybe<Scalars['Price']>;
  current_cost_currency?: Maybe<Currency>;
  /** If editing is locked for this object or not. */
  edit_lock?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** The Item on the order. */
  item_id?: Maybe<Scalars['ID']>;
  /** The Item's line on the order. */
  line_number?: Maybe<Scalars['PositiveInteger']>;
  /** The amount this Item was originally purchased for. */
  original_cost?: Maybe<Scalars['Price']>;
  original_cost_currency?: Maybe<Currency>;
  /** What the Item was originally bought/sold as. */
  original_part_id?: Maybe<Scalars['ID']>;
  /** What the Item is being bought/sold as. */
  part_id?: Maybe<Scalars['ID']>;
  /** What Shipment this Item is on. */
  shipment_id?: Maybe<Scalars['ID']>;
  /** If the part is swapped or not, and if so what kind of swap. */
  swap_type?: Maybe<Swap_Type>;
  /** The tax value for this Item. */
  tax?: Maybe<Scalars['Price']>;
  tax_currency?: Maybe<Currency>;
  /** The order this Item is attached to. */
  transaction_id?: Maybe<Scalars['ID']>;
};

export type Update__Quotes_QuoteParts__Input = {
  id: Scalars['ID'];
  part_id?: Maybe<Scalars['ID']>;
  price_per_part?: Maybe<Scalars['Price']>;
  price_per_part_currency?: Maybe<Currency>;
  qty?: Maybe<Scalars['PositiveInteger']>;
  quote_id?: Maybe<Scalars['ID']>;
};

export type Update__Quotes_Quote__Input = {
  client_id?: Maybe<Scalars['ID']>;
  contact_id?: Maybe<Scalars['ID']>;
  contract_comment?: Maybe<Scalars['String']>;
  /** How much the customer is in need of the product on a scale of 0-5. */
  desire_level?: Maybe<Scalars['Rating']>;
  id: Scalars['ID'];
  internal_comment?: Maybe<Scalars['String']>;
  /** How good the pricing is on the quote on a scale of 0-5. */
  price_grade?: Maybe<Scalars['Rating']>;
  purchaser_address_id?: Maybe<Scalars['ID']>;
  return_address_id?: Maybe<Scalars['ID']>;
  ship_from_address_id?: Maybe<Scalars['ID']>;
  ship_to_address_id?: Maybe<Scalars['ID']>;
  type?: Maybe<Type>;
};

export type Update__Rma_PurchaseRmaItems__Input = {
  credit?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  returned_part_id?: Maybe<Scalars['ID']>;
  rma_id?: Maybe<Scalars['ID']>;
  shipment_id?: Maybe<Scalars['ID']>;
  tested_condition_id?: Maybe<Scalars['ID']>;
};

export type Update__Rma_PurchaseRma__Input = {
  claim_date?: Maybe<Scalars['Date']>;
  closed_date?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  pt_id?: Maybe<Scalars['ID']>;
  received_date?: Maybe<Scalars['Date']>;
  shipment_order_id?: Maybe<Scalars['ID']>;
  status_id?: Maybe<Scalars['ID']>;
  voided_date?: Maybe<Scalars['Date']>;
};

export type Update__Rma_RmaItems__Input = {
  credit?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  returned_part_id?: Maybe<Scalars['ID']>;
  rma_id?: Maybe<Scalars['ID']>;
  shipment_id?: Maybe<Scalars['ID']>;
  tested_condition_id?: Maybe<Scalars['ID']>;
};

export type Update__Rma_Rma__Input = {
  assigned_to_id?: Maybe<Scalars['ID']>;
  claim_date?: Maybe<Scalars['Date']>;
  closed_by_id?: Maybe<Scalars['ID']>;
  closed_date?: Maybe<Scalars['DateTime']>;
  contact_id?: Maybe<Scalars['ID']>;
  contract_comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internal_comment?: Maybe<Scalars['String']>;
  received_by_id?: Maybe<Scalars['ID']>;
  received_date?: Maybe<Scalars['DateTime']>;
  return_code_id?: Maybe<Scalars['ID']>;
  return_reason?: Maybe<Scalars['String']>;
  shipment_order_id?: Maybe<Scalars['ID']>;
  st_id?: Maybe<Scalars['ID']>;
  status_id?: Maybe<Scalars['ID']>;
  tested_by_id?: Maybe<Scalars['ID']>;
  tested_date?: Maybe<Scalars['DateTime']>;
  verified_by_id?: Maybe<Scalars['ID']>;
  verified_date?: Maybe<Scalars['DateTime']>;
  voided_by_id?: Maybe<Scalars['ID']>;
  voided_date?: Maybe<Scalars['DateTime']>;
  warranty_honored_after_cut_off?: Maybe<Scalars['Boolean']>;
};

export type Update__Reporting_Report__Input = {
  /** This stores ChartJS settings to use instead of the defaults. */
  chart_data?: Maybe<Scalars['JSON']>;
  /** What kind of chart it is. */
  chart_type?: Maybe<Chart_Type>;
  /** The data from the query. */
  data?: Maybe<Scalars['JSON']>;
  /** A description of the report. */
  description?: Maybe<Scalars['String']>;
  /** Who favorited this report. */
  favorites?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id: Scalars['ID'];
  is_list?: Maybe<Scalars['Boolean']>;
  /** What is the name of the report. */
  name?: Maybe<Scalars['String']>;
  /** The query to use for the report. */
  query?: Maybe<Scalars['String']>;
  /** Who this report is for. */
  recipients?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What Model the report is for. */
  report_model?: Maybe<Scalars['String']>;
};

export type Update__Sales_Fees__Input = {
  /** The amount of the fee. */
  amount?: Maybe<Scalars['Price']>;
  amount_currency?: Maybe<Currency>;
  /** A description for the fee. */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The order this fee is on. */
  transaction_id?: Maybe<Scalars['ID']>;
};

export type Update__Sales_Sale__Input = {
  /** Who was assigned to pick this order. */
  assigned_to_id?: Maybe<Scalars['ID']>;
  /** The date the order was booked. */
  book_date?: Maybe<Scalars['DateTime']>;
  /** The client this order was made to/from. */
  client_id?: Maybe<Scalars['ID']>;
  /** The client's order number. */
  client_order_number?: Maybe<Scalars['String']>;
  /** The condition of the parts on this order. */
  condition_id?: Maybe<Scalars['ID']>;
  /** The person to contact for this order. */
  contact_id?: Maybe<Scalars['ID']>;
  /** Comments to put on the contract for this order. */
  contract_comment?: Maybe<Scalars['String']>;
  /** When the contract needs to be sent by. */
  contract_send_by_date?: Maybe<Scalars['Date']>;
  /** The declared value of the order. */
  declared_value?: Maybe<Scalars['Price']>;
  declared_value_currency?: Maybe<Currency>;
  /** The deposit amount for the order. */
  deposit?: Maybe<Scalars['Price']>;
  deposit_currency?: Maybe<Currency>;
  /** The down payment amount for this order. */
  down_payment?: Maybe<Scalars['Price']>;
  down_payment_currency?: Maybe<Currency>;
  /** If editing is locked for this object or not. */
  edit_lock?: Maybe<Scalars['Boolean']>;
  /** The estimated cost of the order. */
  estimated_cost?: Maybe<Scalars['Price']>;
  estimated_cost_currency?: Maybe<Currency>;
  /** The estimated margin on the order. */
  estimated_margin?: Maybe<Scalars['Price']>;
  estimated_margin_currency?: Maybe<Currency>;
  /** The cost of freight for this order. */
  freight?: Maybe<Scalars['Price']>;
  freight_currency?: Maybe<Currency>;
  id: Scalars['ID'];
  /** The cost of installing equipment for the customer. */
  installation_cost?: Maybe<Scalars['Price']>;
  installation_cost_currency?: Maybe<Currency>;
  /** Comments on this order for internal use. */
  internal_comment?: Maybe<Scalars['String']>;
  /** Miscellaneous charges on this order. */
  misc?: Maybe<Scalars['Price']>;
  misc_currency?: Maybe<Currency>;
  /** The date the order was promised by. */
  promise_date?: Maybe<Scalars['DateTime']>;
  /** The quote used for creating this order. */
  quote_id?: Maybe<Scalars['ID']>;
  /** Who's order this is. */
  rep_id?: Maybe<Scalars['ID']>;
  /** The date the order was made. */
  sale_date?: Maybe<Scalars['DateTime']>;
  /** The date the order needs to ship by. */
  ship_by_date?: Maybe<Scalars['Date']>;
  /** The Shipment Order this order is tied to. */
  shipment_order_id?: Maybe<Scalars['ID']>;
  status_id?: Maybe<Scalars['ID']>;
  /** The tax amount for this order. */
  tax?: Maybe<Scalars['Price']>;
  tax_currency?: Maybe<Currency>;
  /** The terms for this order. */
  terms_id?: Maybe<Scalars['ID']>;
  /** Who was assigned to test this order. */
  tester_id?: Maybe<Scalars['ID']>;
  /** The date that the warranty expires. */
  warranty_end_date?: Maybe<Scalars['Date']>;
  /** If we pay the installation cost. */
  we_install?: Maybe<Scalars['Boolean']>;
  /** If we arrange transportation. */
  we_transport?: Maybe<Scalars['Boolean']>;
};

export type Update__Sales_SoldItems__Input = {
  /** The percentage of the margin that goes to the purchasing rep. */
  buyer_margin_percentage?: Maybe<Scalars['Percent']>;
  /** If editing is locked for this object or not. */
  edit_lock?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** The Item on the order. */
  item_id?: Maybe<Scalars['ID']>;
  /** The Item's line on the order. */
  line_number?: Maybe<Scalars['PositiveInteger']>;
  /** What the Item was originally bought/sold as. */
  original_part_id?: Maybe<Scalars['ID']>;
  /** What the Item is being bought/sold as. */
  part_id?: Maybe<Scalars['ID']>;
  /** The percentage of the margin that goes to the selling rep. */
  seller_margin_percentage?: Maybe<Scalars['Percent']>;
  /** What Shipment this Item is on. */
  shipment_id?: Maybe<Scalars['ID']>;
  /** The amount this Item was sold for. */
  sold_for?: Maybe<Scalars['Price']>;
  sold_for_currency?: Maybe<Currency>;
  status_id?: Maybe<Scalars['ID']>;
  /** If the part is swapped or not, and if so what kind of swap. */
  swap_type?: Maybe<Swap_Type>;
  /** The tax value of this Item. */
  tax?: Maybe<Scalars['Price']>;
  tax_currency?: Maybe<Currency>;
  /** The order this Item was sold on. */
  transaction_id?: Maybe<Scalars['ID']>;
};

export type Update__Sales_SoldServices__Input = {
  /** If editing is locked for this object. */
  edit_lock?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** How many periods this product was sold for. */
  periods_sold_for?: Maybe<Scalars['PositiveInteger']>;
  /** How many items of this product were sold. */
  quantity?: Maybe<Scalars['PositiveInteger']>;
  /** The Service that was sold. */
  service_id?: Maybe<Scalars['ID']>;
  /** The tax value of this product. */
  tax?: Maybe<Scalars['Price']>;
  tax_currency?: Maybe<Currency>;
  /** The total value of this product. */
  total?: Maybe<Scalars['Price']>;
  total_currency?: Maybe<Currency>;
  /** The order this Service was sold on. */
  transaction_id?: Maybe<Scalars['ID']>;
};

export type Update__Sales_SoldSoftware__Input = {
  /** If editing is locked for this object. */
  edit_lock?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** How many periods this product was sold for. */
  periods_sold_for?: Maybe<Scalars['PositiveInteger']>;
  /** How many items of this product were sold. */
  quantity?: Maybe<Scalars['PositiveInteger']>;
  /** The Software that was sold. */
  software_id?: Maybe<Scalars['ID']>;
  /** The tax value of this product. */
  tax?: Maybe<Scalars['Price']>;
  tax_currency?: Maybe<Currency>;
  /** The total value of this product. */
  total?: Maybe<Scalars['Price']>;
  total_currency?: Maybe<Currency>;
  /** The order this Software was sold on. */
  transaction_id?: Maybe<Scalars['ID']>;
};

export type Update__Shipping_Account__Input = {
  client_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  easypost_id?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  rep?: Maybe<Array<Maybe<Scalars['ID']>>>;
  shipper_id?: Maybe<Scalars['ID']>;
};

export type Update__Shipping_Carrier__Input = {
  easypost_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_courier?: Maybe<Scalars['Boolean']>;
  is_easypost?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type Update__Shipping_PredefinedParcel__Input = {
  height_in?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  length_in?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  width_in?: Maybe<Scalars['Float']>;
};

export type Update__Shipping_ServiceLevel__Input = {
  color?: Maybe<Scalars['String']>;
  easypost_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  order_arrive_by?: Maybe<Scalars['CustomTime']>;
  order_ready_by?: Maybe<Scalars['CustomTime']>;
  service?: Maybe<Scalars['String']>;
  shipper_id?: Maybe<Scalars['ID']>;
};

export type Update__Shipping_ShipmentOrder__Input = {
  account_id?: Maybe<Scalars['ID']>;
  assigned_to_id?: Maybe<Scalars['ID']>;
  blind?: Maybe<Blind>;
  deliver_by_date?: Maybe<Scalars['Date']>;
  easypost_id?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  pickup_type?: Maybe<Pickup_Type>;
  purchaser_address_id?: Maybe<Scalars['ID']>;
  purchaser_contact_id?: Maybe<Scalars['ID']>;
  purchaser_rep_id?: Maybe<Scalars['ID']>;
  release_number?: Maybe<Scalars['String']>;
  return_address_id?: Maybe<Scalars['ID']>;
  return_contact_id?: Maybe<Scalars['ID']>;
  return_rep_id?: Maybe<Scalars['ID']>;
  service_id?: Maybe<Scalars['ID']>;
  ship_from_address_id?: Maybe<Scalars['ID']>;
  ship_from_contact_id?: Maybe<Scalars['ID']>;
  ship_from_rep_id?: Maybe<Scalars['ID']>;
  ship_to_address_id?: Maybe<Scalars['ID']>;
  ship_to_contact_id?: Maybe<Scalars['ID']>;
  ship_to_rep_id?: Maybe<Scalars['ID']>;
  shipping_comments?: Maybe<Scalars['String']>;
  status_id?: Maybe<Scalars['ID']>;
  tracking_email_recipients?: Maybe<Array<Maybe<Scalars['Email']>>>;
  tracking_number?: Maybe<Scalars['String']>;
  we_pay?: Maybe<Scalars['Boolean']>;
};

export type Update__Shipping_ShipmentSupplies__Input = {
  id: Scalars['ID'];
  shipment_id?: Maybe<Scalars['ID']>;
  supplies_id?: Maybe<Scalars['ID']>;
};

export type Update__Shipping_Shipment__Input = {
  billed_cost?: Maybe<Scalars['Price']>;
  billed_cost_currency?: Maybe<Currency>;
  box_number?: Maybe<Scalars['PositiveInteger']>;
  easypost_id?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insurance_cost?: Maybe<Scalars['Price']>;
  insurance_cost_currency?: Maybe<Currency>;
  insurance_status?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['ID']>;
  parcel_height_in?: Maybe<Scalars['Float']>;
  parcel_length_in?: Maybe<Scalars['Float']>;
  parcel_weight_oz?: Maybe<Scalars['Float']>;
  parcel_width_in?: Maybe<Scalars['Float']>;
  payment_status?: Maybe<Scalars['String']>;
  predefined_parcel?: Maybe<Scalars['String']>;
  refund_status?: Maybe<Scalars['String']>;
  release_number?: Maybe<Scalars['String']>;
  shipment_cost?: Maybe<Scalars['Price']>;
  shipment_cost_currency?: Maybe<Currency>;
  tracking_number?: Maybe<Scalars['String']>;
  tracking_status?: Maybe<Scalars['String']>;
};

export type Update__Team_TeamMembers__Input = {
  id: Scalars['ID'];
  member_id?: Maybe<Scalars['ID']>;
  team_id?: Maybe<Scalars['ID']>;
};

export type Update__Team_Team__Input = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type Update__Timer_BaseTimer__Input = {
  /** A short description of the timer. */
  description?: Maybe<Scalars['String']>;
  /** If the timer is enabled. */
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** When the timer was last ran. */
  last_ran?: Maybe<Scalars['DateTime']>;
  /** A short and unique name for the timer. */
  name?: Maybe<Scalars['String']>;
  /** The ID/PK of the object for the timer. */
  object_pk?: Maybe<Scalars['PositiveInteger']>;
  /** How often the timer is supposed to be run. Minimum unit is an hour. */
  period?: Maybe<Scalars['Duration']>;
  /** What sort of timer it is. */
  timer_type?: Maybe<Timer_Type>;
  /** How many times the timer has been run. */
  times_run?: Maybe<Scalars['PositiveInteger']>;
  /** How many times to run it. 0 means forever. */
  times_to_run?: Maybe<Scalars['PositiveInteger']>;
};

export type Update__WorkOrder_WorkOrderItems__Input = {
  amount?: Maybe<Scalars['Price']>;
  amount_currency?: Maybe<Currency>;
  id: Scalars['ID'];
  item_id?: Maybe<Scalars['ID']>;
  work_order_id?: Maybe<Scalars['ID']>;
};

export type Update__WorkOrder_WorkOrder__Input = {
  amount?: Maybe<Scalars['Price']>;
  amount_currency?: Maybe<Currency>;
  contract_comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internal_comment?: Maybe<Scalars['String']>;
  need_by?: Maybe<Scalars['Date']>;
  purchase_transaction_id?: Maybe<Scalars['ID']>;
  shipment_order_id?: Maybe<Scalars['ID']>;
  status_id?: Maybe<Scalars['ID']>;
  terms_id?: Maybe<Scalars['ID']>;
  type_id?: Maybe<Scalars['ID']>;
  vendor_id?: Maybe<Scalars['ID']>;
  warranty_end_date?: Maybe<Scalars['Date']>;
};












































/** A GraphQL Schema defines the capabilities of a GraphQL server. It exposes all available types and directives on the server, as well as the entry points for query, mutation, and subscription operations. */
export type __Schema = {
  __typename?: '__Schema';
  description?: Maybe<Scalars['String']>;
  /** A list of all types supported by this server. */
  types: Array<__Type>;
  /** The type that query operations will be rooted at. */
  queryType: __Type;
  /** If this server supports mutation, the type that mutation operations will be rooted at. */
  mutationType?: Maybe<__Type>;
  /** If this server support subscription, the type that subscription operations will be rooted at. */
  subscriptionType?: Maybe<__Type>;
  /** A list of all directives supported by this server. */
  directives: Array<__Directive>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __Type = {
  __typename?: '__Type';
  kind: __TypeKind;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  specifiedByUrl?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<__Field>>;
  interfaces?: Maybe<Array<__Type>>;
  possibleTypes?: Maybe<Array<__Type>>;
  enumValues?: Maybe<Array<__EnumValue>>;
  inputFields?: Maybe<Array<__InputValue>>;
  ofType?: Maybe<__Type>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeFieldsArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeEnumValuesArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeInputFieldsArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};

/** An enum describing what kind of type a given `__Type` is. */
export enum __TypeKind {
  /** Indicates this type is a scalar. */
  Scalar = 'SCALAR',
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  Object = 'OBJECT',
  /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
  Interface = 'INTERFACE',
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  Union = 'UNION',
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  Enum = 'ENUM',
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  InputObject = 'INPUT_OBJECT',
  /** Indicates this type is a list. `ofType` is a valid field. */
  List = 'LIST',
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  NonNull = 'NON_NULL'
}

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __Field = {
  __typename?: '__Field';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  args: Array<__InputValue>;
  type: __Type;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};


/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __FieldArgsArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};

/** Arguments provided to Fields or Directives and the input fields of an InputObject are represented as Input Values which describe their type and optionally a default value. */
export type __InputValue = {
  __typename?: '__InputValue';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: __Type;
  /** A GraphQL-formatted string representing the default value for this input value. */
  defaultValue?: Maybe<Scalars['String']>;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};

/** One possible value for a given Enum. Enum values are unique values, not a placeholder for a string or numeric value. However an Enum value is returned in a JSON response as a string. */
export type __EnumValue = {
  __typename?: '__EnumValue';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};

/**
 * A Directive provides a way to describe alternate runtime execution and type validation behavior in a GraphQL document.
 *
 * In some cases, you need to provide options to alter GraphQL's execution behavior in ways field arguments will not suffice, such as conditionally including or skipping a field. Directives provide this by describing additional information to the executor.
 */
export type __Directive = {
  __typename?: '__Directive';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isRepeatable: Scalars['Boolean'];
  locations: Array<__DirectiveLocation>;
  args: Array<__InputValue>;
};

/** A Directive can be adjacent to many parts of the GraphQL language, a __DirectiveLocation describes one such possible adjacencies. */
export enum __DirectiveLocation {
  /** Location adjacent to a query operation. */
  Query = 'QUERY',
  /** Location adjacent to a mutation operation. */
  Mutation = 'MUTATION',
  /** Location adjacent to a subscription operation. */
  Subscription = 'SUBSCRIPTION',
  /** Location adjacent to a field. */
  Field = 'FIELD',
  /** Location adjacent to a fragment definition. */
  FragmentDefinition = 'FRAGMENT_DEFINITION',
  /** Location adjacent to a fragment spread. */
  FragmentSpread = 'FRAGMENT_SPREAD',
  /** Location adjacent to an inline fragment. */
  InlineFragment = 'INLINE_FRAGMENT',
  /** Location adjacent to a variable definition. */
  VariableDefinition = 'VARIABLE_DEFINITION',
  /** Location adjacent to a schema definition. */
  Schema = 'SCHEMA',
  /** Location adjacent to a scalar definition. */
  Scalar = 'SCALAR',
  /** Location adjacent to an object type definition. */
  Object = 'OBJECT',
  /** Location adjacent to a field definition. */
  FieldDefinition = 'FIELD_DEFINITION',
  /** Location adjacent to an argument definition. */
  ArgumentDefinition = 'ARGUMENT_DEFINITION',
  /** Location adjacent to an interface definition. */
  Interface = 'INTERFACE',
  /** Location adjacent to a union definition. */
  Union = 'UNION',
  /** Location adjacent to an enum definition. */
  Enum = 'ENUM',
  /** Location adjacent to an enum value definition. */
  EnumValue = 'ENUM_VALUE',
  /** Location adjacent to an input object type definition. */
  InputObject = 'INPUT_OBJECT',
  /** Location adjacent to an input object field definition. */
  InputFieldDefinition = 'INPUT_FIELD_DEFINITION'
}

export type Accounting_Account__ListFragment = (
  { __typename?: 'Accounting_Account' }
  & Pick<Accounting_Account, 'id' | 'account_number' | 'description' | 'name'>
);

export type Accounting_Account__GridFragment = (
  { __typename?: 'Accounting_Account' }
  & Pick<Accounting_Account, 'account_type'>
  & { account_total?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, total_credit?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, total_debit?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )> }
  & Accounting_Account__ListFragment
);

export type Accounting_Transaction__GridFragment = (
  { __typename?: 'Accounting_Transaction' }
  & Pick<Accounting_Transaction, 'creation_date' | 'id' | 'internal_reference' | 'is_balanced' | 'is_compound' | 'reference'>
  & { creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'full_name' | 'initials'>
  ) }
);

export type Accounting_Entry__GridFragment = (
  { __typename?: 'Accounting_Entry' }
  & Pick<Accounting_Entry, 'creation_date' | 'entry_type' | 'id' | 'note'>
  & { account: (
    { __typename?: 'Accounting_Account' }
    & Pick<Accounting_Account, 'id' | 'name' | 'account_number'>
  ), amount?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'full_name' | 'initials'>
  ), transaction: (
    { __typename?: 'Accounting_Transaction' }
    & Pick<Accounting_Transaction, 'id' | 'reference'>
  ) }
);

export type Invoice_Ar__FullFragment = (
  { __typename?: 'Invoices_ARInvoice' }
  & Pick<Invoices_ArInvoice, 'creation_date' | 'date_due' | 'date_sent' | 'id' | 'is_voided' | 'updated_date'>
  & { amount?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials'>
  ), order: (
    { __typename?: 'Sales_Sale' }
    & Pick<Sales_Sale, 'id'>
    & { rep?: Maybe<(
      { __typename?: 'Users_User' }
      & Pick<Users_User, 'id' | 'initials'>
    )>, client?: Maybe<(
      { __typename?: 'Clients_Client' }
      & Pick<Clients_Client, 'id' | 'name' | 'short_name'>
    )> }
  ), status?: Maybe<(
    { __typename?: 'Choices_Status' }
    & Pick<Choices_Status, 'id' | 'status'>
  )> }
);

export type Invoice_Ap__FullFragment = (
  { __typename?: 'Invoices_APInvoice' }
  & Pick<Invoices_ApInvoice, 'creation_date' | 'date_due' | 'date_sent' | 'id' | 'is_voided' | 'updated_date'>
  & { amount?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials'>
  ), order: (
    { __typename?: 'Purchases_Purchase' }
    & Pick<Purchases_Purchase, 'id'>
    & { rep?: Maybe<(
      { __typename?: 'Users_User' }
      & Pick<Users_User, 'id' | 'initials'>
    )>, client?: Maybe<(
      { __typename?: 'Clients_Client' }
      & Pick<Clients_Client, 'id' | 'name' | 'short_name'>
    )> }
  ), status?: Maybe<(
    { __typename?: 'Choices_Status' }
    & Pick<Choices_Status, 'id' | 'status'>
  )> }
);

export type Invoice_Ar_Payment__FullFragment = (
  { __typename?: 'Invoices_ARPayment' }
  & Pick<Invoices_ArPayment, 'creation_date' | 'id' | 'is_posted' | 'reference' | 'updated_date'>
  & { amount?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials'>
  ) }
);

export type Invoice_Ap_Payment__FullFragment = (
  { __typename?: 'Invoices_APPayment' }
  & Pick<Invoices_ApPayment, 'creation_date' | 'id' | 'is_posted' | 'reference' | 'updated_date'>
  & { amount?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials'>
  ) }
);

export type GetAccountingTransactions_GridQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetAccountingTransactions_GridQuery = (
  { __typename?: 'Query' }
  & { accounting_transactions?: Maybe<Array<Maybe<(
    { __typename?: 'Accounting_Transaction' }
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & Accounting_Transaction__GridFragment
  )>>> }
);

export type GetAccountingEntries_GridQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetAccountingEntries_GridQuery = (
  { __typename?: 'Query' }
  & { accounting_entrys?: Maybe<Array<Maybe<(
    { __typename?: 'Accounting_Entry' }
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & Accounting_Entry__GridFragment
  )>>> }
);

export type Get_Accounting_Accounts__ListQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type Get_Accounting_Accounts__ListQuery = (
  { __typename?: 'Query' }
  & { accounting_accounts?: Maybe<Array<Maybe<(
    { __typename?: 'Accounting_Account' }
    & Accounting_Account__ListFragment
  )>>> }
);

export type Get_Accounting_Accounts__GridQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type Get_Accounting_Accounts__GridQuery = (
  { __typename?: 'Query' }
  & { accounting_accounts?: Maybe<Array<Maybe<(
    { __typename?: 'Accounting_Account' }
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & Accounting_Account__GridFragment
  )>>> }
);

export type Get_Accounting_Ap_Invoices__FullQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type Get_Accounting_Ap_Invoices__FullQuery = (
  { __typename?: 'Query' }
  & { invoices_a_p_invoices?: Maybe<Array<Maybe<(
    { __typename?: 'Invoices_APInvoice' }
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & Invoice_Ap__FullFragment
  )>>> }
);

export type Get_Accounting_Ar_Invoices__FullQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type Get_Accounting_Ar_Invoices__FullQuery = (
  { __typename?: 'Query' }
  & { invoices_a_r_invoices?: Maybe<Array<Maybe<(
    { __typename?: 'Invoices_ARInvoice' }
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & Invoice_Ar__FullFragment
  )>>> }
);

export type Get_Accounting_Invoice_For_StQueryVariables = Exact<{
  st: Scalars['Any'];
}>;


export type Get_Accounting_Invoice_For_StQuery = (
  { __typename?: 'Query' }
  & { invoices_a_r_invoices?: Maybe<Array<Maybe<(
    { __typename?: 'Invoices_ARInvoice' }
    & Pick<Invoices_ArInvoice, 'id'>
  )>>> }
);

export type GetInvoicePaymentsForApInvoiceQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetInvoicePaymentsForApInvoiceQuery = (
  { __typename?: 'Query' }
  & { invoices?: Maybe<Array<Maybe<(
    { __typename?: 'Invoices_APPayment' }
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & Invoice_Ap_Payment__FullFragment
  )>>> }
);

export type GetInvoicePaymentsForArInvoiceQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetInvoicePaymentsForArInvoiceQuery = (
  { __typename?: 'Query' }
  & { invoices?: Maybe<Array<Maybe<(
    { __typename?: 'Invoices_ARPayment' }
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & Invoice_Ar_Payment__FullFragment
  )>>> }
);

export type Create_Accounting_AccountMutationVariables = Exact<{
  input: Create__Accounting_Account__Input;
}>;


export type Create_Accounting_AccountMutation = (
  { __typename?: 'Mutation' }
  & { Create__Accounting_Account?: Maybe<(
    { __typename?: 'Accounting_Account' }
    & Accounting_Account__GridFragment
  )> }
);

export type Update_Accounting_AccountMutationVariables = Exact<{
  input: Update__Accounting_Account__Input;
}>;


export type Update_Accounting_AccountMutation = (
  { __typename?: 'Mutation' }
  & { Update__Accounting_Account?: Maybe<(
    { __typename?: 'Accounting_Account' }
    & Accounting_Account__GridFragment
  )> }
);

export type CreateAccountingTransactionMutationVariables = Exact<{
  input: Create__Accounting_Transaction__Input;
}>;


export type CreateAccountingTransactionMutation = (
  { __typename?: 'Mutation' }
  & { Create__Accounting_Transaction?: Maybe<(
    { __typename?: 'Accounting_Transaction' }
    & Accounting_Transaction__GridFragment
  )> }
);

export type CreateAccountingEntryMutationVariables = Exact<{
  input: Create__Accounting_Entry__Input;
}>;


export type CreateAccountingEntryMutation = (
  { __typename?: 'Mutation' }
  & { Create__Accounting_Entry?: Maybe<(
    { __typename?: 'Accounting_Entry' }
    & Accounting_Entry__GridFragment
  )> }
);

export type CreateAccountingArInvoiceMutationVariables = Exact<{
  input: Create__Invoices_ArInvoice__Input;
}>;


export type CreateAccountingArInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { Create__Invoices_ARInvoice?: Maybe<(
    { __typename?: 'Invoices_ARInvoice' }
    & Invoice_Ar__FullFragment
  )> }
);

export type UpdateAccountingArInvoiceMutationVariables = Exact<{
  input: Update__Invoices_ArInvoice__Input;
}>;


export type UpdateAccountingArInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { Update__Invoices_ARInvoice?: Maybe<(
    { __typename?: 'Invoices_ARInvoice' }
    & Invoice_Ar__FullFragment
  )> }
);

export type CreateAccountingApInvoiceMutationVariables = Exact<{
  input: Create__Invoices_ApInvoice__Input;
}>;


export type CreateAccountingApInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { Create__Invoices_APInvoice?: Maybe<(
    { __typename?: 'Invoices_APInvoice' }
    & Invoice_Ap__FullFragment
  )> }
);

export type UpdateAccountingApInvoiceMutationVariables = Exact<{
  input: Update__Invoices_ApInvoice__Input;
}>;


export type UpdateAccountingApInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { Update__Invoices_APInvoice?: Maybe<(
    { __typename?: 'Invoices_APInvoice' }
    & Invoice_Ap__FullFragment
  )> }
);

export type CreateInvoiceApPaymentMutationVariables = Exact<{
  input: Create__Invoices_ApPayment__Input;
}>;


export type CreateInvoiceApPaymentMutation = (
  { __typename?: 'Mutation' }
  & { invoice?: Maybe<(
    { __typename?: 'Invoices_APPayment' }
    & Invoice_Ap_Payment__FullFragment
  )> }
);

export type CreateInvoiceArPaymentMutationVariables = Exact<{
  input: Create__Invoices_ArPayment__Input;
}>;


export type CreateInvoiceArPaymentMutation = (
  { __typename?: 'Mutation' }
  & { invoice?: Maybe<(
    { __typename?: 'Invoices_ARPayment' }
    & Invoice_Ar_Payment__FullFragment
  )> }
);

export type UpdateInvoiceApPaymentMutationVariables = Exact<{
  input: Update__Invoices_ApPayment__Input;
}>;


export type UpdateInvoiceApPaymentMutation = (
  { __typename?: 'Mutation' }
  & { invoice?: Maybe<(
    { __typename?: 'Invoices_APPayment' }
    & Invoice_Ap_Payment__FullFragment
  )> }
);

export type UpdateInvoiceArPaymentMutationVariables = Exact<{
  input: Update__Invoices_ArPayment__Input;
}>;


export type UpdateInvoiceArPaymentMutation = (
  { __typename?: 'Mutation' }
  & { invoice?: Maybe<(
    { __typename?: 'Invoices_ARPayment' }
    & Invoice_Ar_Payment__FullFragment
  )> }
);

export type AddressListFragment = (
  { __typename?: 'Address_Address' }
  & Pick<Address_Address, 'building_name' | 'city' | 'easypost_id' | 'id' | 'state' | 'street_1'>
  & { client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id' | 'name' | 'short_name'>
  )>, company?: Maybe<(
    { __typename?: 'Company_Company' }
    & Pick<Company_Company, 'name'>
  )> }
);

export type AddressGridFragment = (
  { __typename?: 'Address_Address' }
  & Pick<Address_Address, 'street_2' | 'zip_code'>
  & { country: (
    { __typename?: 'CountryObject' }
    & Pick<CountryObject, 'name' | 'code'>
  ), default_contact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Pick<Clients_Contact, 'id' | 'full_name'>
  )> }
  & AddressListFragment
);

export type AddressFullFragment = (
  { __typename?: 'Address_Address' }
  & Pick<Address_Address, 'carrier_facility' | 'creation_date' | 'residential' | 'updated_date'>
  & { creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials'>
  ), default_billing_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, default_billing_for_company?: Maybe<(
    { __typename?: 'Company_Company' }
    & Pick<Company_Company, 'id'>
  )>, default_remit_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, default_remit_for_company?: Maybe<(
    { __typename?: 'Company_Company' }
    & Pick<Company_Company, 'id'>
  )>, default_return_to_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, default_ship_from_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, default_ship_from_for_company?: Maybe<(
    { __typename?: 'Company_Company' }
    & Pick<Company_Company, 'id'>
  )>, default_ship_to_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, default_ship_to_for_company?: Maybe<(
    { __typename?: 'Company_Company' }
    & Pick<Company_Company, 'id'>
  )> }
  & AddressGridFragment
);

export type GetAddress_ListQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetAddress_ListQuery = (
  { __typename?: 'Query' }
  & { address_addresses?: Maybe<Array<Maybe<(
    { __typename?: 'Address_Address' }
    & AddressListFragment
  )>>> }
);

export type GetAddress_GridQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetAddress_GridQuery = (
  { __typename?: 'Query' }
  & { address_addresses?: Maybe<Array<Maybe<(
    { __typename?: 'Address_Address' }
    & AddressGridFragment
  )>>> }
);

export type GetAddress_FullQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetAddress_FullQuery = (
  { __typename?: 'Query' }
  & { address_addresses?: Maybe<Array<Maybe<(
    { __typename?: 'Address_Address' }
    & AddressFullFragment
  )>>> }
);

export type StandardAddressesQueryVariables = Exact<{
  client: Scalars['Any'];
}>;


export type StandardAddressesQuery = (
  { __typename?: 'Query' }
  & { clientAddresses?: Maybe<Array<Maybe<(
    { __typename?: 'Address_Address' }
    & AddressListFragment
  )>>>, companyAddresses?: Maybe<Array<Maybe<(
    { __typename?: 'Address_Address' }
    & AddressListFragment
  )>>>, company_company?: Maybe<(
    { __typename?: 'Company_Company' }
    & { default?: Maybe<(
      { __typename?: 'Address_Address' }
      & AddressListFragment
    )> }
  )> }
);

export type VerifyAddressMutationVariables = Exact<{
  input: EasyPost_MakeAddressInput;
}>;


export type VerifyAddressMutation = (
  { __typename?: 'Mutation' }
  & { verified?: Maybe<(
    { __typename?: 'EasyPostAddress' }
    & Pick<EasyPostAddress, 'id' | 'residential' | 'phone' | 'name' | 'company' | 'street1' | 'city' | 'state' | 'country' | 'street2' | 'zip'>
    & { verifications?: Maybe<(
      { __typename?: 'EasyPostVerifications' }
      & { delivery?: Maybe<(
        { __typename?: 'EasyPostVerification' }
        & Pick<EasyPostVerification, 'success'>
        & { errors?: Maybe<Array<Maybe<(
          { __typename?: 'EasyPostFieldError' }
          & Pick<EasyPostFieldError, 'field' | 'message'>
        )>>> }
      )>, zip4?: Maybe<(
        { __typename?: 'EasyPostVerification' }
        & Pick<EasyPostVerification, 'success'>
        & { errors?: Maybe<Array<Maybe<(
          { __typename?: 'EasyPostFieldError' }
          & Pick<EasyPostFieldError, 'field' | 'message'>
        )>>> }
      )> }
    )> }
  )> }
);

export type CreateAddressMutationVariables = Exact<{
  input: Create__Address_Address__Input;
}>;


export type CreateAddressMutation = (
  { __typename?: 'Mutation' }
  & { Create__Address_Address?: Maybe<(
    { __typename?: 'Address_Address' }
    & AddressFullFragment
  )> }
);

export type DeleteAddressMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAddressMutation = (
  { __typename?: 'Mutation' }
  & { Delete__Address_Address?: Maybe<(
    { __typename?: 'DBActionStatus' }
    & Pick<DbActionStatus, 'id'>
  )> }
);

export type UpdateAddressMutationVariables = Exact<{
  input: Update__Address_Address__Input;
}>;


export type UpdateAddressMutation = (
  { __typename?: 'Mutation' }
  & { address?: Maybe<(
    { __typename?: 'Address_Address' }
    & AddressFullFragment
  )> }
);

export type BomPartFullFragment = (
  { __typename?: 'Parts_BOMParts' }
  & Pick<Parts_BomParts, 'id' | 'creation_date' | 'maximum_count' | 'minimum_count' | 'part_type' | 'updated_date'>
  & { bom: (
    { __typename?: 'Parts_BOM' }
    & Pick<Parts_Bom, 'id'>
  ), creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials'>
  ), part: (
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn' | 'description' | 'type'>
  ) }
);

export type BomForPartGridFragment = (
  { __typename?: 'Parts_BOM' }
  & Pick<Parts_Bom, 'id' | 'creation_date' | 'description' | 'updated_date'>
  & { bom_for: (
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn' | 'type'>
    & { brand?: Maybe<(
      { __typename?: 'Parts_ProductCompany' }
      & Pick<Parts_ProductCompany, 'id' | 'name'>
    )> }
  ), creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials'>
  ) }
);

export type BomForPartFullFragment = (
  { __typename?: 'Parts_BOM' }
  & Pick<Parts_Bom, 'id' | 'creation_date' | 'description' | 'updated_date'>
  & { bom_for: (
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn' | 'type'>
    & { brand?: Maybe<(
      { __typename?: 'Parts_ProductCompany' }
      & Pick<Parts_ProductCompany, 'id' | 'name'>
    )> }
  ), creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials'>
  ), parts?: Maybe<Array<(
    { __typename?: 'Parts_BOMParts' }
    & BomPartFullFragment
  )>> }
);

export type BuildUpsGridFragment = (
  { __typename?: 'BuildUp_BuildUp' }
  & Pick<BuildUp_BuildUp, 'id' | 'creation_date' | 'updated_date'>
  & { annotations?: Maybe<Array<Maybe<(
    { __typename?: 'AnnotationObject' }
    & Pick<AnnotationObject, 'name' | 'value'>
  )>>>, assigned_to?: Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'full_name' | 'initials' | 'email'>
  )>, creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials' | 'email'>
  ), status: (
    { __typename?: 'Choices_Status' }
    & Pick<Choices_Status, 'id' | 'status'>
  ), system: (
    { __typename?: 'Inventory_System' }
    & Pick<Inventory_System, 'id' | 'serial_number'>
    & { part?: Maybe<(
      { __typename?: 'Parts_Part' }
      & Pick<Parts_Part, 'id' | 'pn' | 'description'>
    )>, purchases_items_details?: Maybe<(
      { __typename?: 'Purchases_PurchasedItems' }
      & Pick<Purchases_PurchasedItems, 'id' | 'line_number'>
      & { transaction: (
        { __typename?: 'Purchases_Purchase' }
        & Pick<Purchases_Purchase, 'id'>
      ) }
    )> }
  ) }
);

export type BuildUpItemFullFragment = (
  { __typename?: 'BuildUp_BuildUpItems' }
  & Pick<BuildUp_BuildUpItems, 'creation_date' | 'id' | 'updated_date'>
  & { annotations?: Maybe<Array<Maybe<(
    { __typename?: 'AnnotationObject' }
    & Pick<AnnotationObject, 'name' | 'value'>
  )>>>, build_up: (
    { __typename?: 'BuildUp_BuildUp' }
    & Pick<BuildUp_BuildUp, 'id'>
  ), creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials'>
  ), item: (
    { __typename?: 'Inventory_Item' }
    & Pick<Inventory_Item, 'id' | 'serial_number'>
    & { part?: Maybe<(
      { __typename?: 'Parts_Part' }
      & Pick<Parts_Part, 'id' | 'pn' | 'type'>
    )>, purchases_items_details?: Maybe<(
      { __typename?: 'Purchases_PurchasedItems' }
      & Pick<Purchases_PurchasedItems, 'id' | 'line_number'>
      & { transaction: (
        { __typename?: 'Purchases_Purchase' }
        & Pick<Purchases_Purchase, 'id'>
      ) }
    )> }
  ), status: (
    { __typename?: 'Choices_Status' }
    & Pick<Choices_Status, 'id' | 'status' | 'status_type'>
  ) }
);

export type GetBomsForPartsQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetBomsForPartsQuery = (
  { __typename?: 'Query' }
  & { parts_boms?: Maybe<Array<Maybe<(
    { __typename?: 'Parts_BOM' }
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & BomForPartGridFragment
  )>>> }
);

export type GetPartsBoms_FullQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetPartsBoms_FullQuery = (
  { __typename?: 'Query' }
  & { parts_boms?: Maybe<Array<Maybe<(
    { __typename?: 'Parts_BOM' }
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & BomForPartFullFragment
  )>>> }
);

export type GetBuildUpsQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetBuildUpsQuery = (
  { __typename?: 'Query' }
  & { build_up_build_ups?: Maybe<Array<Maybe<(
    { __typename?: 'BuildUp_BuildUp' }
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & BuildUpsGridFragment
  )>>> }
);

export type GetBompartsFullQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetBompartsFullQuery = (
  { __typename?: 'Query' }
  & { parts_bom_parts?: Maybe<Array<Maybe<(
    { __typename?: 'Parts_BOMParts' }
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & BomPartFullFragment
  )>>> }
);

export type GetBuildUpItemsGridQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetBuildUpItemsGridQuery = (
  { __typename?: 'Query' }
  & { build_up_build_up_items?: Maybe<Array<Maybe<(
    { __typename?: 'BuildUp_BuildUpItems' }
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & BuildUpItemFullFragment
  )>>> }
);

export type CreateBomForPartMutationVariables = Exact<{
  id: Scalars['ID'];
  description: Scalars['String'];
}>;


export type CreateBomForPartMutation = (
  { __typename?: 'Mutation' }
  & { Create__Parts_BOM?: Maybe<(
    { __typename?: 'Parts_BOM' }
    & BomForPartFullFragment
  )> }
);

export type CreateBomPartsMutationVariables = Exact<{
  id: Scalars['ID'];
  part_id: Scalars['ID'];
  max?: Maybe<Scalars['PositiveInteger']>;
  min?: Maybe<Scalars['PositiveInteger']>;
  type: Scalars['String'];
}>;


export type CreateBomPartsMutation = (
  { __typename?: 'Mutation' }
  & { Create__Parts_BOMParts?: Maybe<(
    { __typename?: 'Parts_BOMParts' }
    & BomPartFullFragment
  )> }
);

export type UpdateBomPartMutationVariables = Exact<{
  id: Scalars['ID'];
  max?: Maybe<Scalars['PositiveInteger']>;
  min?: Maybe<Scalars['PositiveInteger']>;
  type: Scalars['String'];
}>;


export type UpdateBomPartMutation = (
  { __typename?: 'Mutation' }
  & { Update__Parts_BOMParts?: Maybe<(
    { __typename?: 'Parts_BOMParts' }
    & BomPartFullFragment
  )> }
);

export type DeleteBomPartMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBomPartMutation = (
  { __typename?: 'Mutation' }
  & { Delete__Parts_BOMParts?: Maybe<(
    { __typename?: 'DBActionStatus' }
    & Pick<DbActionStatus, 'id'>
  )> }
);

export type CreateBuildUpMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CreateBuildUpMutation = (
  { __typename?: 'Mutation' }
  & { Create__BuildUp_BuildUp?: Maybe<(
    { __typename?: 'BuildUp_BuildUp' }
    & BuildUpsGridFragment
  )> }
);

export type UpdateBuildUpMutationVariables = Exact<{
  input: Update__BuildUp_BuildUp__Input;
}>;


export type UpdateBuildUpMutation = (
  { __typename?: 'Mutation' }
  & { Update__BuildUp_BuildUp?: Maybe<(
    { __typename?: 'BuildUp_BuildUp' }
    & BuildUpsGridFragment
  )> }
);

export type CreateBuildUpItemsMutationVariables = Exact<{
  input: Array<Create__BuildUp_BuildUpItems__Input> | Create__BuildUp_BuildUpItems__Input;
}>;


export type CreateBuildUpItemsMutation = (
  { __typename?: 'Mutation' }
  & { buildUpItems?: Maybe<Array<Maybe<(
    { __typename?: 'BuildUp_BuildUpItems' }
    & BuildUpItemFullFragment
  )>>> }
);

export type UpdateBuildUpItemsMutationVariables = Exact<{
  input: Array<Update__BuildUp_BuildUpItems__Input> | Update__BuildUp_BuildUpItems__Input;
}>;


export type UpdateBuildUpItemsMutation = (
  { __typename?: 'Mutation' }
  & { Update__BuildUp_BuildUpItems?: Maybe<Array<Maybe<(
    { __typename?: 'BuildUp_BuildUpItems' }
    & BuildUpItemFullFragment
  )>>> }
);

export type ChoicesStatusFragment = (
  { __typename?: 'Choices_Status' }
  & Pick<Choices_Status, 'id' | 'app_name' | 'field_name' | 'model_name' | 'status' | 'status_type'>
);

export type ChoicesTagsFragment = (
  { __typename?: 'Choices_Tag' }
  & Pick<Choices_Tag, 'id' | 'tag' | 'description' | 'color' | 'app_name' | 'model_name' | 'field_name'>
);

export type ChoicesChoiceFragment = (
  { __typename?: 'Choices_Choice' }
  & Pick<Choices_Choice, 'id' | 'choice' | 'sub_choice' | 'app_name' | 'model_name' | 'field_name'>
);

export type TestStatusTagsForListQueryVariables = Exact<{ [key: string]: never; }>;


export type TestStatusTagsForListQuery = (
  { __typename?: 'Query' }
  & { tags?: Maybe<Array<Maybe<(
    { __typename?: 'Choices_Tag' }
    & Pick<Choices_Tag, 'id' | 'tag' | 'description' | 'color'>
  )>>> }
);

export type GetChoicesChoicesQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetChoicesChoicesQuery = (
  { __typename?: 'Query' }
  & { choices_choices?: Maybe<Array<Maybe<(
    { __typename?: 'Choices_Choice' }
    & Pick<Choices_Choice, 'app_name' | 'model_name' | 'field_name' | 'id' | 'choice' | 'sub_choice'>
  )>>> }
);

export type Client_ListFragment = (
  { __typename?: 'Clients_Client' }
  & Pick<Clients_Client, 'id' | 'allow_sales' | 'ecommerce_store' | 'name' | 'rating' | 'short_name'>
  & { internal_rep?: Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials'>
  )>, outstanding_invoice_limit?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )> }
);

export type Client_GridFragment = (
  { __typename?: 'Clients_Client' }
  & Pick<Clients_Client, 'notes' | 'shipping_instructions' | 'website'>
  & { credit?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, credit_limit?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, type?: Maybe<(
    { __typename?: 'Choices_Choice' }
    & Pick<Choices_Choice, 'id' | 'choice' | 'sub_choice'>
  )> }
  & Client_ListFragment
);

export type Client_FullFragment = (
  { __typename?: 'Clients_Client' }
  & Pick<Clients_Client, 'creation_date' | 'default_tracking_email_recipients' | 'federal_tax_id' | 'is_1099' | 'is_our_client' | 'is_tax_exempt' | 'origin' | 'payment' | 'state_tax_id' | 'tax_exempt_number' | 'updated_date'>
  & { attached_address?: Maybe<Array<(
    { __typename?: 'Address_Address' }
    & AddressGridFragment
  )>>, creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials'>
  ), default_billing_address?: Maybe<(
    { __typename?: 'Address_Address' }
    & AddressGridFragment
  )>, default_remit_address?: Maybe<(
    { __typename?: 'Address_Address' }
    & AddressGridFragment
  )>, default_return_to_address?: Maybe<(
    { __typename?: 'Address_Address' }
    & AddressGridFragment
  )>, default_ship_from_address?: Maybe<(
    { __typename?: 'Address_Address' }
    & AddressGridFragment
  )>, default_ship_to_address?: Maybe<(
    { __typename?: 'Address_Address' }
    & AddressGridFragment
  )>, default_shipping_account?: Maybe<(
    { __typename?: 'Shipping_Account' }
    & CarrierAccountFullFragment
  )>, default_shipping_service?: Maybe<(
    { __typename?: 'Shipping_ServiceLevel' }
    & Pick<Shipping_ServiceLevel, 'id' | 'service'>
    & { shipper: (
      { __typename?: 'Shipping_Carrier' }
      & Pick<Shipping_Carrier, 'id' | 'name'>
    ) }
  )>, default_pt_terms?: Maybe<(
    { __typename?: 'Clients_Terms' }
    & Terms_ListFragment
  )>, default_st_terms?: Maybe<(
    { __typename?: 'Clients_Terms' }
    & Terms_ListFragment
  )>, shipping_accounts?: Maybe<Array<(
    { __typename?: 'Shipping_Account' }
    & CarrierAccountFullFragment
  )>>, terms?: Maybe<Array<(
    { __typename?: 'Clients_Terms' }
    & Terms_ListFragment
  )>> }
  & Client_GridFragment
);

export type GetClients_ListQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetClients_ListQuery = (
  { __typename?: 'Query' }
  & { clients_clients?: Maybe<Array<Maybe<(
    { __typename?: 'Clients_Client' }
    & Client_ListFragment
  )>>> }
);

export type GetClients_GridQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetClients_GridQuery = (
  { __typename?: 'Query' }
  & { clients_clients?: Maybe<Array<Maybe<(
    { __typename?: 'Clients_Client' }
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & Client_GridFragment
  )>>> }
);

export type GetClientsFullQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetClientsFullQuery = (
  { __typename?: 'Query' }
  & { clients_clients?: Maybe<Array<Maybe<(
    { __typename?: 'Clients_Client' }
    & Client_FullFragment
  )>>> }
);

export type GetClientFullQueryVariables = Exact<{
  input: SingleQueryInput;
}>;


export type GetClientFullQuery = (
  { __typename?: 'Query' }
  & { clients_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Client_FullFragment
  )> }
);

export type GetClientAltPartsQueryVariables = Exact<{
  clientId: Scalars['ID'];
}>;


export type GetClientAltPartsQuery = (
  { __typename?: 'Query' }
  & { clients_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
    & { alt_parts?: Maybe<Array<(
      { __typename?: 'Parts_Part' }
      & Pick<Parts_Part, 'id' | 'pn'>
      & { alt_part_for_part?: Maybe<(
        { __typename?: 'Parts_Part' }
        & Pick<Parts_Part, 'id' | 'pn'>
      )> }
    )>> }
  )> }
);

export type GetClientAltPartsForPartQueryVariables = Exact<{
  clientId: Scalars['ID'];
  partId: Scalars['Any'];
}>;


export type GetClientAltPartsForPartQuery = (
  { __typename?: 'Query' }
  & { clients_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
    & { alt_parts?: Maybe<Array<(
      { __typename?: 'Parts_Part' }
      & Pick<Parts_Part, 'id' | 'pn' | 'description'>
    )>> }
  )> }
);

export type DeleteClientMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteClientMutation = (
  { __typename?: 'Mutation' }
  & { Delete__Clients_Client?: Maybe<(
    { __typename?: 'DBActionStatus' }
    & Pick<DbActionStatus, 'id'>
  )> }
);

export type UpdateClientDetailsMutationVariables = Exact<{
  input: Update__Clients_Client__Input;
}>;


export type UpdateClientDetailsMutation = (
  { __typename?: 'Mutation' }
  & { Update__Clients_Client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Client_FullFragment
  )> }
);

export type CreateClientMutationVariables = Exact<{
  input: Create__Clients_Client__Input;
}>;


export type CreateClientMutation = (
  { __typename?: 'Mutation' }
  & { Create__Clients_Client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Client_FullFragment
  )> }
);

export type GetCompanyInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyInfoQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company_Company' }
    & Pick<Company_Company, 'name' | 'phone' | 'short_name'>
    & { default_new_client_terms?: Maybe<(
      { __typename?: 'Clients_Terms' }
      & Pick<Clients_Terms, 'id'>
    )>, default_return_to_address?: Maybe<(
      { __typename?: 'Address_Address' }
      & AddressFullFragment
    )>, default_ship_from_address?: Maybe<(
      { __typename?: 'Address_Address' }
      & AddressFullFragment
    )>, default_ship_to_address?: Maybe<(
      { __typename?: 'Address_Address' }
      & AddressFullFragment
    )>, default_billing_address?: Maybe<(
      { __typename?: 'Address_Address' }
      & AddressFullFragment
    )>, default_remit_address?: Maybe<(
      { __typename?: 'Address_Address' }
      & AddressFullFragment
    )>, default_shipping_account?: Maybe<(
      { __typename?: 'Shipping_Account' }
      & CarrierAccountFullFragment
    )>, default_shipping_service?: Maybe<(
      { __typename?: 'Shipping_ServiceLevel' }
      & Pick<Shipping_ServiceLevel, 'id'>
    )> }
  )> }
);

export type GetFieldsQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type GetFieldsQuery = (
  { __typename?: 'Query' }
  & { __type?: Maybe<(
    { __typename?: '__Type' }
    & Pick<__Type, 'name' | 'description'>
    & { inputFields?: Maybe<Array<(
      { __typename?: '__InputValue' }
      & Pick<__InputValue, 'name' | 'description'>
      & { type: (
        { __typename?: '__Type' }
        & Pick<__Type, 'name'>
      ) }
    )>> }
  )> }
);

export type GetTypeFieldsQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type GetTypeFieldsQuery = (
  { __typename?: 'Query' }
  & { __type?: Maybe<(
    { __typename?: '__Type' }
    & Pick<__Type, 'name' | 'description'>
    & { interfaces?: Maybe<Array<(
      { __typename?: '__Type' }
      & Pick<__Type, 'name'>
    )>>, inputFields?: Maybe<Array<(
      { __typename?: '__InputValue' }
      & Pick<__InputValue, 'name'>
    )>>, fields?: Maybe<Array<(
      { __typename?: '__Field' }
      & Pick<__Field, 'name' | 'description'>
      & { type: (
        { __typename?: '__Type' }
        & Pick<__Type, 'name' | 'kind'>
        & { ofType?: Maybe<(
          { __typename?: '__Type' }
          & Pick<__Type, 'name'>
          & { ofType?: Maybe<(
            { __typename?: '__Type' }
            & Pick<__Type, 'name'>
          )> }
        )> }
      ) }
    )>>, enumValues?: Maybe<Array<(
      { __typename?: '__EnumValue' }
      & Pick<__EnumValue, 'name'>
    )>> }
  )> }
);

export type GetTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTypesQuery = (
  { __typename?: 'Query' }
  & { __schema: (
    { __typename?: '__Schema' }
    & { types: Array<(
      { __typename?: '__Type' }
      & Pick<__Type, 'name' | 'description'>
      & { fields?: Maybe<Array<(
        { __typename?: '__Field' }
        & Pick<__Field, 'name'>
        & { type: (
          { __typename?: '__Type' }
          & Pick<__Type, 'name'>
        ) }
      )>> }
    )> }
  ) }
);

export type UpdateCompanyInfoMutationVariables = Exact<{
  input: Update__Company_Company__Input;
}>;


export type UpdateCompanyInfoMutation = (
  { __typename?: 'Mutation' }
  & { Update__Company_Company?: Maybe<(
    { __typename?: 'Company_Company' }
    & Pick<Company_Company, 'id'>
  )> }
);

export type CompanyAndClientAddressesQueryVariables = Exact<{
  client: Scalars['Any'];
  noCompany: Scalars['Any'];
}>;


export type CompanyAndClientAddressesQuery = (
  { __typename?: 'Query' }
  & { client?: Maybe<Array<Maybe<(
    { __typename?: 'Address_Address' }
    & AddressListFragment
  )>>>, other?: Maybe<Array<Maybe<(
    { __typename?: 'Address_Address' }
    & AddressListFragment
  )>>> }
);

export type Contact_ListFragment = (
  { __typename?: 'Clients_Contact' }
  & Pick<Clients_Contact, 'id' | 'full_name' | 'email' | 'phone'>
  & { owner: (
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  ) }
);

export type Contact_GridFragment = (
  { __typename?: 'Clients_Contact' }
  & Pick<Clients_Contact, 'fax' | 'first_name' | 'last_name'>
  & { default_billing_for_contact?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, default_remit_for_contact?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, default_ship_to_for_contact?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )> }
  & Contact_ListFragment
);

export type Contact_FullFragment = (
  { __typename?: 'Clients_Contact' }
  & { attached_address?: Maybe<Array<(
    { __typename?: 'Address_Address' }
    & AddressListFragment
  )>> }
  & Contact_GridFragment
);

export type ListContactFragment = (
  { __typename?: 'Clients_Contact' }
  & Pick<Clients_Contact, 'id' | 'phone'>
  & { title: Clients_Contact['full_name'], subtitle: Clients_Contact['email'] }
);

export type ListUserFragment = (
  { __typename?: 'Users_User' }
  & Pick<Users_User, 'id' | 'email'>
  & { title: Users_User['full_name'], subtitle: Users_User['initials'] }
);

export type GetContacts_ListQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetContacts_ListQuery = (
  { __typename?: 'Query' }
  & { clients_contacts?: Maybe<Array<Maybe<(
    { __typename?: 'Clients_Contact' }
    & Contact_ListFragment
  )>>> }
);

export type GetContacts_GridQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetContacts_GridQuery = (
  { __typename?: 'Query' }
  & { clients_contacts?: Maybe<Array<Maybe<(
    { __typename?: 'Clients_Contact' }
    & Contact_GridFragment
  )>>> }
);

export type GetContacts_FullQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetContacts_FullQuery = (
  { __typename?: 'Query' }
  & { clients_contacts?: Maybe<Array<Maybe<(
    { __typename?: 'Clients_Contact' }
    & Contact_FullFragment
  )>>> }
);

export type GetContact_FullQueryVariables = Exact<{
  input: SingleQueryInput;
}>;


export type GetContact_FullQuery = (
  { __typename?: 'Query' }
  & { clients_contact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Contact_FullFragment
  )> }
);

export type GetContactToEditQueryVariables = Exact<{
  id: Scalars['Any'];
}>;


export type GetContactToEditQuery = (
  { __typename?: 'Query' }
  & { clients_contact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Contact_GridFragment
  )> }
);

export type CreateContactMutationVariables = Exact<{
  input: Create__Clients_Contact__Input;
}>;


export type CreateContactMutation = (
  { __typename?: 'Mutation' }
  & { Create__Clients_Contact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Contact_FullFragment
  )> }
);

export type UpdateContactMutationVariables = Exact<{
  input: Update__Clients_Contact__Input;
}>;


export type UpdateContactMutation = (
  { __typename?: 'Mutation' }
  & { Update__Clients_Contact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Contact_FullFragment
  )> }
);

export type DeleteContactMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteContactMutation = (
  { __typename?: 'Mutation' }
  & { Delete__Clients_Contact?: Maybe<(
    { __typename?: 'DBActionStatus' }
    & Pick<DbActionStatus, 'id'>
  )> }
);

export type CountryFragment = (
  { __typename?: 'CountryObject' }
  & Pick<CountryObject, 'name' | 'code' | 'numeric'>
  & { flag: CountryObject['unicode_flag'] }
);

export type GetCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountriesQuery = (
  { __typename?: 'Query' }
  & { countries?: Maybe<Array<Maybe<(
    { __typename?: 'CountryObject' }
    & CountryFragment
  )>>> }
);

export type BreakdownListFragment = (
  { __typename?: 'Breakdown_BreakDown' }
  & Pick<Breakdown_BreakDown, 'id' | 'creation_date'>
  & { assigned_to?: Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'full_name' | 'initials' | 'email'>
  )>, creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials' | 'email'>
  ), status: (
    { __typename?: 'Choices_Status' }
    & Pick<Choices_Status, 'id' | 'status' | 'status_type'>
  ), system: (
    { __typename?: 'Inventory_System' }
    & Pick<Inventory_System, 'id' | 'serial_number'>
    & { part?: Maybe<(
      { __typename?: 'Parts_Part' }
      & Pick<Parts_Part, 'id' | 'pn' | 'description'>
    )>, purchases_items_details?: Maybe<(
      { __typename?: 'Purchases_PurchasedItems' }
      & Pick<Purchases_PurchasedItems, 'id' | 'line_number'>
      & { transaction: (
        { __typename?: 'Purchases_Purchase' }
        & Pick<Purchases_Purchase, 'id'>
      ) }
    )> }
  ) }
);

export type BreakdownFragment = (
  { __typename?: 'Breakdown_BreakDown' }
  & Pick<Breakdown_BreakDown, 'id' | 'creation_date'>
  & { assigned_to?: Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'full_name' | 'initials' | 'email'>
  )>, creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials' | 'email'>
  ), status: (
    { __typename?: 'Choices_Status' }
    & Pick<Choices_Status, 'id' | 'status' | 'status_type'>
  ), system: (
    { __typename?: 'Inventory_System' }
    & Pick<Inventory_System, 'id' | 'serial_number'>
    & { part?: Maybe<(
      { __typename?: 'Parts_Part' }
      & Pick<Parts_Part, 'id' | 'pn' | 'description'>
    )>, purchases_items_details?: Maybe<(
      { __typename?: 'Purchases_PurchasedItems' }
      & Pick<Purchases_PurchasedItems, 'id' | 'line_number'>
      & { current_cost?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragment
      )>, original_cost?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragment
      )>, transaction: (
        { __typename?: 'Purchases_Purchase' }
        & Pick<Purchases_Purchase, 'id'>
      ) }
    )> }
  ) }
);

export type BreakdownItemFragment = (
  { __typename?: 'Breakdown_BreakDownItems' }
  & Pick<Breakdown_BreakDownItems, 'id'>
  & { item: (
    { __typename?: 'Inventory_Item' }
    & Pick<Inventory_Item, 'id' | 'serial_number'>
    & { country_of_origin?: Maybe<(
      { __typename?: 'CountryObject' }
      & Pick<CountryObject, 'name' | 'code' | 'unicode_flag'>
    )>, location?: Maybe<(
      { __typename?: 'Location_Location' }
      & Pick<Location_Location, 'id' | 'name'>
      & { site: (
        { __typename?: 'Location_Site' }
        & Pick<Location_Site, 'id' | 'name' | 'short_name'>
      ) }
    )>, part?: Maybe<(
      { __typename?: 'Parts_Part' }
      & Pick<Parts_Part, 'id' | 'pn'>
    )>, purchases_items_details?: Maybe<(
      { __typename?: 'Purchases_PurchasedItems' }
      & Pick<Purchases_PurchasedItems, 'id' | 'line_number'>
      & { original_cost?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragment
      )>, current_cost?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragment
      )>, transaction: (
        { __typename?: 'Purchases_Purchase' }
        & Pick<Purchases_Purchase, 'id'>
      ) }
    )> }
  ), status: (
    { __typename?: 'Choices_Status' }
    & Pick<Choices_Status, 'id' | 'status'>
  ) }
);

export type GetBreakDownItemsQueryVariables = Exact<{
  id: Scalars['Any'];
}>;


export type GetBreakDownItemsQuery = (
  { __typename?: 'Query' }
  & { breakDownItems?: Maybe<Array<Maybe<(
    { __typename?: 'Breakdown_BreakDownItems' }
    & BreakdownItemFragment
  )>>> }
);

export type GridGetBreakDownItemsQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GridGetBreakDownItemsQuery = (
  { __typename?: 'Query' }
  & { breakDownItems?: Maybe<Array<Maybe<(
    { __typename?: 'Breakdown_BreakDownItems' }
    & BreakdownItemFragment
  )>>> }
);

export type GetBreakDownQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBreakDownQuery = (
  { __typename?: 'Query' }
  & { breakdown?: Maybe<(
    { __typename?: 'Breakdown_BreakDown' }
    & BreakdownFragment
  )> }
);

export type GetBreakDownsQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetBreakDownsQuery = (
  { __typename?: 'Query' }
  & { breakdowns?: Maybe<Array<Maybe<(
    { __typename?: 'Breakdown_BreakDown' }
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & BreakdownListFragment
  )>>> }
);

export type GetCountOfUnfinishedBreakDownItemsOnBreakDownQueryVariables = Exact<{
  id: Scalars['Any'];
}>;


export type GetCountOfUnfinishedBreakDownItemsOnBreakDownQuery = (
  { __typename?: 'Query' }
  & { breakdown_break_down_items?: Maybe<Array<Maybe<(
    { __typename?: 'Breakdown_BreakDownItems' }
    & Pick<Breakdown_BreakDownItems, 'id'>
  )>>> }
);

export type UpdateBreakDownStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  status: Scalars['ID'];
}>;


export type UpdateBreakDownStatusMutation = (
  { __typename?: 'Mutation' }
  & { breakDown?: Maybe<(
    { __typename?: 'Breakdown_BreakDown' }
    & Pick<Breakdown_BreakDown, 'id'>
    & { status: (
      { __typename?: 'Choices_Status' }
      & ChoicesStatusFragment
    ) }
  )> }
);

export type UpdateBreakDownItemStatusMutationVariables = Exact<{
  input: Array<Update__Breakdown_BreakDownItems__Input> | Update__Breakdown_BreakDownItems__Input;
}>;


export type UpdateBreakDownItemStatusMutation = (
  { __typename?: 'Mutation' }
  & { breakDownItem?: Maybe<Array<Maybe<(
    { __typename?: 'Breakdown_BreakDownItems' }
    & Pick<Breakdown_BreakDownItems, 'id'>
    & { status: (
      { __typename?: 'Choices_Status' }
      & ChoicesStatusFragment
    ) }
  )>>> }
);

export type CreateBreakDownMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CreateBreakDownMutation = (
  { __typename?: 'Mutation' }
  & { breakDown?: Maybe<(
    { __typename?: 'Breakdown_BreakDown' }
    & Pick<Breakdown_BreakDown, 'id'>
  )> }
);

export type CreateBreakDownItemsMutationVariables = Exact<{
  input: Array<Create__Breakdown_BreakDownItems__Input> | Create__Breakdown_BreakDownItems__Input;
}>;


export type CreateBreakDownItemsMutation = (
  { __typename?: 'Mutation' }
  & { breakDownItems?: Maybe<Array<Maybe<(
    { __typename?: 'Breakdown_BreakDownItems' }
    & Pick<Breakdown_BreakDownItems, 'id'>
  )>>> }
);

export type AssignBreakDownMutationVariables = Exact<{
  id: Scalars['ID'];
  assignee: Scalars['ID'];
}>;


export type AssignBreakDownMutation = (
  { __typename?: 'Mutation' }
  & { breakDown?: Maybe<(
    { __typename?: 'Breakdown_BreakDown' }
    & Pick<Breakdown_BreakDown, 'id'>
    & { assigned_to?: Maybe<(
      { __typename?: 'Users_User' }
      & Pick<Users_User, 'id' | 'full_name' | 'initials' | 'email'>
    )> }
  )> }
);

export type EasyPostCustomsItems_FullFragment = (
  { __typename?: 'EasyPostCustomsItem' }
  & Pick<EasyPostCustomsItem, 'code' | 'currency' | 'description' | 'hs_tariff_number' | 'id' | 'object' | 'origin_country' | 'quantity' | 'value' | 'weight'>
);

export type EasyPostCustomsInfo_FullFragment = (
  { __typename?: 'EasyPostCustomsInfo' }
  & Pick<EasyPostCustomsInfo, 'contents_explanation' | 'contents_type' | 'customs_certify' | 'customs_signer' | 'eel_pfc' | 'id' | 'object' | 'restriction_comments' | 'restriction_type'>
  & { customs_items?: Maybe<Array<Maybe<(
    { __typename?: 'EasyPostCustomsItem' }
    & EasyPostCustomsItems_FullFragment
  )>>> }
);

export type EasyPostParcel_FullFragment = (
  { __typename?: 'EasyPostParcel' }
  & Pick<EasyPostParcel, 'height' | 'id' | 'length' | 'mode' | 'predefined_package' | 'weight' | 'width'>
);

export type EasyPostShipment_FullFragment = (
  { __typename?: 'EasyPostShipment' }
  & Pick<EasyPostShipment, 'id' | 'refund_status' | 'reference'>
  & { customs_info?: Maybe<(
    { __typename?: 'EasyPostCustomsInfo' }
    & EasyPostCustomsInfo_FullFragment
  )>, forms?: Maybe<Array<Maybe<(
    { __typename?: 'EasyPostForm' }
    & Pick<EasyPostForm, 'form_url' | 'id' | 'form_type' | 'submitted_electronically'>
  )>>>, options?: Maybe<(
    { __typename?: 'EasyPostShipmentOptions' }
    & Pick<EasyPostShipmentOptions, 'additional_handling' | 'delivery_confirmation' | 'dropoff_type' | 'hold_for_pickup' | 'invoice_number' | 'hazmat' | 'label_format' | 'machinable'>
    & { payment?: Maybe<(
      { __typename?: 'EasyPostPayment' }
      & Pick<EasyPostPayment, 'type' | 'account' | 'country' | 'postal_code'>
    )> }
  )>, parcel?: Maybe<(
    { __typename?: 'EasyPostParcel' }
    & EasyPostParcel_FullFragment
  )>, postage_label?: Maybe<(
    { __typename?: 'EasyPostPostageLabel' }
    & Pick<EasyPostPostageLabel, 'id' | 'label_pdf_url' | 'label_epl2_url' | 'label_zpl_url' | 'label_url'>
  )> }
);

export type EasyPostCarrierAccount__FullFragment = (
  { __typename?: 'EasyPostCarrierAccount' }
  & Pick<EasyPostCarrierAccount, 'clone' | 'created_at' | 'description' | 'id' | 'object' | 'readable' | 'reference' | 'type' | 'updated_at'>
  & { credentials?: Maybe<(
    { __typename?: 'EasyPostCredentialField' }
    & Pick<EasyPostCredentialField, 'account_number'>
  )> }
);

export type GetEasyPostShipment_FullQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetEasyPostShipment_FullQuery = (
  { __typename?: 'Query' }
  & { EasyPost_RetrieveShipment?: Maybe<(
    { __typename?: 'EasyPostShipment' }
    & EasyPostShipment_FullFragment
  )> }
);

export type Get_EasyPost_Shipment_RatesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Get_EasyPost_Shipment_RatesQuery = (
  { __typename?: 'Query' }
  & { EasyPost_RetrieveShipment?: Maybe<(
    { __typename?: 'EasyPostShipment' }
    & Pick<EasyPostShipment, 'id'>
    & { rates?: Maybe<Array<Maybe<(
      { __typename?: 'EasyPostRate' }
      & Pick<EasyPostRate, 'id' | 'rate' | 'list_rate' | 'retail_rate' | 'service' | 'carrier'>
    )>>>, postage_label?: Maybe<(
      { __typename?: 'EasyPostPostageLabel' }
      & Pick<EasyPostPostageLabel, 'id' | 'label_pdf_url' | 'label_epl2_url' | 'label_zpl_url' | 'label_url'>
    )> }
  )> }
);

export type GetEasypostCarrierAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEasypostCarrierAccountsQuery = (
  { __typename?: 'Query' }
  & { EasyPost_ListCarrierAccounts?: Maybe<Array<Maybe<(
    { __typename?: 'EasyPostCarrierAccount' }
    & EasyPostCarrierAccount__FullFragment
  )>>> }
);

export type BuyEasyPostShipmentMutationVariables = Exact<{
  id: Scalars['EasyPostID'];
  rate?: Maybe<Scalars['EasyPostID']>;
  insurance?: Maybe<Scalars['Price']>;
}>;


export type BuyEasyPostShipmentMutation = (
  { __typename?: 'Mutation' }
  & { EasyPost_BuyShipment?: Maybe<(
    { __typename?: 'EasyPostShipment' }
    & Pick<EasyPostShipment, 'id' | 'tracking_code'>
    & { postage_label?: Maybe<(
      { __typename?: 'EasyPostPostageLabel' }
      & Pick<EasyPostPostageLabel, 'id' | 'label_pdf_url' | 'label_epl2_url' | 'label_zpl_url' | 'label_url'>
    )> }
  )> }
);

export type RefundEasyPostShipmentMutationVariables = Exact<{
  id: Scalars['EasyPostID'];
}>;


export type RefundEasyPostShipmentMutation = (
  { __typename?: 'Mutation' }
  & { EasyPost_RefundShipment?: Maybe<(
    { __typename?: 'EasyPostShipment' }
    & Pick<EasyPostShipment, 'id'>
  )> }
);

export type CreateEasypostFedExCarrierAccountMutationVariables = Exact<{
  input: EasyPost_RegisterFedexAccountInput;
}>;


export type CreateEasypostFedExCarrierAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EasyPost_RegisterFedExAccount'>
);

export type CreateEasypostUpsCarrierAccountMutationVariables = Exact<{
  input: EasyPost_RegisterUpsAccountInput;
}>;


export type CreateEasypostUpsCarrierAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EasyPost_RegisterUPSAccount'>
);

export type CreateEasypostCarrierAccountMutationVariables = Exact<{
  input: EasyPost_MakeCarrierAccountInput;
}>;


export type CreateEasypostCarrierAccountMutation = (
  { __typename?: 'Mutation' }
  & { EasyPost_MakeCarrierAccount?: Maybe<(
    { __typename?: 'EasyPostCarrierAccount' }
    & Pick<EasyPostCarrierAccount, 'id'>
  )> }
);

export type BasicPartFragment = (
  { __typename?: 'Parts_Part' }
  & Pick<Parts_Part, 'id' | 'description' | 'has_battery' | 'pn' | 'type' | 'sku'>
  & { brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

export type BasicSystemFragment = (
  { __typename?: 'Inventory_System' }
  & Pick<Inventory_System, 'allocated_date' | 'alternate_serial_number' | 'audit_date' | 'condition_comment' | 'creation_date' | 'fw' | 'hot' | 'id' | 'internal' | 'inventory_comment' | 'is_lost' | 'last_write_down_date' | 'lock_reason' | 'locked' | 'next_write_down_date' | 'quantity' | 'receive_status' | 'received_date' | 'serial_number' | 'unbooked_date' | 'updated_date'>
  & { associated_pn?: Maybe<(
    { __typename?: 'Parts_AssociatedPN' }
    & Pick<Parts_AssociatedPn, 'associated_pn'>
  )>, break_down_orders?: Maybe<Array<(
    { __typename?: 'Breakdown_BreakDown' }
    & Pick<Breakdown_BreakDown, 'id'>
    & { status: (
      { __typename?: 'Choices_Status' }
      & Pick<Choices_Status, 'id'>
    ) }
  )>>, checked_out_by?: Maybe<(
    { __typename?: 'Users_User' }
    & UserMinimalFragment
  )>, condition?: Maybe<(
    { __typename?: 'Choices_Choice' }
    & Pick<Choices_Choice, 'id' | 'choice' | 'sub_choice'>
  )>, country_of_origin?: Maybe<(
    { __typename?: 'CountryObject' }
    & Pick<CountryObject, 'name' | 'code' | 'unicode_flag'>
  )>, creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials' | 'full_name'>
  ), grade?: Maybe<(
    { __typename?: 'Choices_Choice' }
    & Pick<Choices_Choice, 'id' | 'choice' | 'sub_choice'>
  )>, location?: Maybe<(
    { __typename?: 'Location_Location' }
    & Pick<Location_Location, 'id' | 'name'>
    & { site: (
      { __typename?: 'Location_Site' }
      & Pick<Location_Site, 'id' | 'name' | 'short_name'>
    ) }
  )>, part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & BasicPartFragment
  )>, purchase_rma_item_details?: Maybe<(
    { __typename?: 'RMA_PurchaseRMAItems' }
    & Pick<Rma_PurchaseRmaItems, 'id'>
    & { rma: (
      { __typename?: 'RMA_PurchaseRMA' }
      & Pick<Rma_PurchaseRma, 'id'>
    ) }
  )>, purchases_items_details?: Maybe<(
    { __typename?: 'Purchases_PurchasedItems' }
    & Pick<Purchases_PurchasedItems, 'id' | 'line_number'>
    & { original_cost?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )>, current_cost?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )>, transaction: (
      { __typename?: 'Purchases_Purchase' }
      & Pick<Purchases_Purchase, 'id'>
      & { rep?: Maybe<(
        { __typename?: 'Users_User' }
        & Pick<Users_User, 'id' | 'initials'>
      )> }
    ) }
  )>, received_by?: Maybe<(
    { __typename?: 'Users_User' }
    & UserMinimalFragment
  )>, rma_item_details?: Maybe<(
    { __typename?: 'RMA_RMAItems' }
    & Pick<Rma_RmaItems, 'id'>
    & { rma: (
      { __typename?: 'RMA_RMA' }
      & Pick<Rma_Rma, 'id'>
    ) }
  )>, sales_items_details?: Maybe<(
    { __typename?: 'Sales_SoldItems' }
    & Pick<Sales_SoldItems, 'id' | 'line_number'>
    & { sold_for?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )>, transaction: (
      { __typename?: 'Sales_Sale' }
      & Pick<Sales_Sale, 'id'>
      & { rep?: Maybe<(
        { __typename?: 'Users_User' }
        & Pick<Users_User, 'id' | 'initials'>
      )> }
    ) }
  )>, shipment?: Maybe<(
    { __typename?: 'Shipping_ShipmentSupplies' }
    & Pick<Shipping_ShipmentSupplies, 'id'>
  )>, system_logs?: Maybe<Array<(
    { __typename?: 'Logging_ActionLogObject' }
    & Pick<Logging_ActionLogObject, 'id'>
    & { action: (
      { __typename?: 'Logging_ActionLog' }
      & Pick<Logging_ActionLog, 'id' | 'action'>
    ) }
  )>>, test_status?: Maybe<Array<(
    { __typename?: 'Choices_Tag' }
    & Pick<Choices_Tag, 'id' | 'tag' | 'description' | 'color'>
  )>> }
);

export type InventoryItemFragment = (
  { __typename?: 'Inventory_Item' }
  & Pick<Inventory_Item, 'allocated_date' | 'alternate_serial_number' | 'audit_date' | 'condition_comment' | 'creation_date' | 'fw' | 'hot' | 'id' | 'internal' | 'inventory_comment' | 'is_lost' | 'last_write_down_date' | 'lock_reason' | 'locked' | 'next_write_down_date' | 'quantity' | 'receive_status' | 'received_date' | 'serial_number' | 'unbooked_date' | 'updated_date'>
  & { associated_pn?: Maybe<(
    { __typename?: 'Parts_AssociatedPN' }
    & Pick<Parts_AssociatedPn, 'associated_pn'>
  )>, checked_out_by?: Maybe<(
    { __typename?: 'Users_User' }
    & UserMinimalFragment
  )>, condition?: Maybe<(
    { __typename?: 'Choices_Choice' }
    & Pick<Choices_Choice, 'id' | 'choice' | 'sub_choice'>
  )>, country_of_origin?: Maybe<(
    { __typename?: 'CountryObject' }
    & Pick<CountryObject, 'name' | 'code' | 'unicode_flag'>
  )>, creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials' | 'full_name'>
  ), grade?: Maybe<(
    { __typename?: 'Choices_Choice' }
    & Pick<Choices_Choice, 'id' | 'choice' | 'sub_choice'>
  )>, location?: Maybe<(
    { __typename?: 'Location_Location' }
    & Pick<Location_Location, 'id' | 'name'>
    & { site: (
      { __typename?: 'Location_Site' }
      & Pick<Location_Site, 'id' | 'name' | 'short_name'>
    ) }
  )>, part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & BasicPartFragment
  )>, purchase_rma_item_details?: Maybe<(
    { __typename?: 'RMA_PurchaseRMAItems' }
    & Pick<Rma_PurchaseRmaItems, 'id'>
    & { rma: (
      { __typename?: 'RMA_PurchaseRMA' }
      & Pick<Rma_PurchaseRma, 'id'>
    ) }
  )>, purchases_items_details?: Maybe<(
    { __typename?: 'Purchases_PurchasedItems' }
    & Pick<Purchases_PurchasedItems, 'id' | 'line_number'>
    & { current_cost?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )>, original_cost?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )>, transaction: (
      { __typename?: 'Purchases_Purchase' }
      & Pick<Purchases_Purchase, 'id'>
      & { rep?: Maybe<(
        { __typename?: 'Users_User' }
        & Pick<Users_User, 'id' | 'initials'>
      )> }
    ) }
  )>, received_by?: Maybe<(
    { __typename?: 'Users_User' }
    & UserMinimalFragment
  )>, rma_item_details?: Maybe<(
    { __typename?: 'RMA_RMAItems' }
    & Pick<Rma_RmaItems, 'id'>
    & { rma: (
      { __typename?: 'RMA_RMA' }
      & Pick<Rma_Rma, 'id'>
    ) }
  )>, sales_items_details?: Maybe<(
    { __typename?: 'Sales_SoldItems' }
    & Pick<Sales_SoldItems, 'id' | 'line_number'>
    & { sold_for?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )>, transaction: (
      { __typename?: 'Sales_Sale' }
      & Pick<Sales_Sale, 'id'>
      & { rep?: Maybe<(
        { __typename?: 'Users_User' }
        & Pick<Users_User, 'id' | 'initials'>
      )> }
    ) }
  )>, shipment?: Maybe<(
    { __typename?: 'Shipping_ShipmentSupplies' }
    & Pick<Shipping_ShipmentSupplies, 'id'>
  )>, system?: Maybe<(
    { __typename?: 'Inventory_System' }
    & Pick<Inventory_System, 'id'>
  )>, system_logs?: Maybe<Array<(
    { __typename?: 'Logging_ActionLogObject' }
    & Pick<Logging_ActionLogObject, 'id'>
    & { action: (
      { __typename?: 'Logging_ActionLog' }
      & Pick<Logging_ActionLog, 'id' | 'action'>
    ) }
  )>>, test_status?: Maybe<Array<(
    { __typename?: 'Choices_Tag' }
    & Pick<Choices_Tag, 'id' | 'tag' | 'description' | 'color'>
  )>> }
);

type FullPart_Parts_AccessPoint_Fragment = (
  { __typename?: 'Parts_AccessPoint' }
  & Pick<Parts_AccessPoint, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_Battery_Fragment = (
  { __typename?: 'Parts_Battery' }
  & Pick<Parts_Battery, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_Bezel_Fragment = (
  { __typename?: 'Parts_Bezel' }
  & Pick<Parts_Bezel, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_Bracket_Fragment = (
  { __typename?: 'Parts_Bracket' }
  & Pick<Parts_Bracket, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_Cpu_Fragment = (
  { __typename?: 'Parts_CPU' }
  & Pick<Parts_Cpu, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_Cable_Fragment = (
  { __typename?: 'Parts_Cable' }
  & Pick<Parts_Cable, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_Cache_Fragment = (
  { __typename?: 'Parts_Cache' }
  & Pick<Parts_Cache, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_DiskDrive_Fragment = (
  { __typename?: 'Parts_DiskDrive' }
  & Pick<Parts_DiskDrive, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_ExpansionCard_Fragment = (
  { __typename?: 'Parts_ExpansionCard' }
  & Pick<Parts_ExpansionCard, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_Fan_Fragment = (
  { __typename?: 'Parts_Fan' }
  & Pick<Parts_Fan, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_Gpu_Fragment = (
  { __typename?: 'Parts_GPU' }
  & Pick<Parts_Gpu, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_Heatsink_Fragment = (
  { __typename?: 'Parts_Heatsink' }
  & Pick<Parts_Heatsink, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_IoModule_Fragment = (
  { __typename?: 'Parts_IOModule' }
  & Pick<Parts_IoModule, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_Memory_Fragment = (
  { __typename?: 'Parts_Memory' }
  & Pick<Parts_Memory, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_Motherboard_Fragment = (
  { __typename?: 'Parts_Motherboard' }
  & Pick<Parts_Motherboard, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_NetworkCard_Fragment = (
  { __typename?: 'Parts_NetworkCard' }
  & Pick<Parts_NetworkCard, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_Part_Fragment = (
  { __typename?: 'Parts_Part' }
  & Pick<Parts_Part, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_Phone_Fragment = (
  { __typename?: 'Parts_Phone' }
  & Pick<Parts_Phone, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_PowerSupply_Fragment = (
  { __typename?: 'Parts_PowerSupply' }
  & Pick<Parts_PowerSupply, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_Rails_Fragment = (
  { __typename?: 'Parts_Rails' }
  & Pick<Parts_Rails, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_Router_Fragment = (
  { __typename?: 'Parts_Router' }
  & Pick<Parts_Router, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_Server_Fragment = (
  { __typename?: 'Parts_Server' }
  & Pick<Parts_Server, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_Storage_Fragment = (
  { __typename?: 'Parts_Storage' }
  & Pick<Parts_Storage, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_StorageEnclosure_Fragment = (
  { __typename?: 'Parts_StorageEnclosure' }
  & Pick<Parts_StorageEnclosure, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_Supplies_Fragment = (
  { __typename?: 'Parts_Supplies' }
  & Pick<Parts_Supplies, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

type FullPart_Parts_Switch_Fragment = (
  { __typename?: 'Parts_Switch' }
  & Pick<Parts_Switch, 'description' | 'ean' | 'has_battery' | 'hs_code' | 'htsus_code' | 'id' | 'pn' | 'schedule_b_code' | 'secondary_pn' | 'sku' | 'type' | 'upc'>
  & { alt_part_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, alt_part_for_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn'>
  )>, brand?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>, mfgr?: Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )> }
);

export type FullPartFragment = FullPart_Parts_AccessPoint_Fragment | FullPart_Parts_Battery_Fragment | FullPart_Parts_Bezel_Fragment | FullPart_Parts_Bracket_Fragment | FullPart_Parts_Cpu_Fragment | FullPart_Parts_Cable_Fragment | FullPart_Parts_Cache_Fragment | FullPart_Parts_DiskDrive_Fragment | FullPart_Parts_ExpansionCard_Fragment | FullPart_Parts_Fan_Fragment | FullPart_Parts_Gpu_Fragment | FullPart_Parts_Heatsink_Fragment | FullPart_Parts_IoModule_Fragment | FullPart_Parts_Memory_Fragment | FullPart_Parts_Motherboard_Fragment | FullPart_Parts_NetworkCard_Fragment | FullPart_Parts_Part_Fragment | FullPart_Parts_Phone_Fragment | FullPart_Parts_PowerSupply_Fragment | FullPart_Parts_Rails_Fragment | FullPart_Parts_Router_Fragment | FullPart_Parts_Server_Fragment | FullPart_Parts_Storage_Fragment | FullPart_Parts_StorageEnclosure_Fragment | FullPart_Parts_Supplies_Fragment | FullPart_Parts_Switch_Fragment;

export type UnAllocatedQueryVariables = Exact<{
  filters?: Maybe<Array<QueryFilter> | QueryFilter>;
  order?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type UnAllocatedQuery = (
  { __typename?: 'Query' }
  & { unAllocatedParts?: Maybe<Array<Maybe<(
    { __typename?: 'Inventory_Item' }
    & Pick<Inventory_Item, 'id' | 'received_date' | 'serial_number'>
    & { purchases_items_details?: Maybe<(
      { __typename?: 'Purchases_PurchasedItems' }
      & Pick<Purchases_PurchasedItems, 'id' | 'line_number'>
      & { current_cost?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragment
      )>, original_cost?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragment
      )>, transaction: (
        { __typename?: 'Purchases_Purchase' }
        & Pick<Purchases_Purchase, 'id'>
        & { rep?: Maybe<(
          { __typename?: 'Users_User' }
          & Pick<Users_User, 'id' | 'initials'>
        )> }
      ) }
    )>, part?: Maybe<(
      { __typename?: 'Parts_Part' }
      & Pick<Parts_Part, 'id' | 'pn'>
    )>, sales_items_details?: Maybe<(
      { __typename?: 'Sales_SoldItems' }
      & Pick<Sales_SoldItems, 'id'>
    )> }
  )>>> }
);

export type ItemsQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type ItemsQuery = (
  { __typename?: 'Query' }
  & { items?: Maybe<Array<Maybe<(
    { __typename?: 'Inventory_Item' }
    & Pick<Inventory_Item, 'id' | 'serial_number' | 'receive_status' | 'locked' | 'hot' | 'is_lost'>
    & { location?: Maybe<(
      { __typename?: 'Location_Location' }
      & Pick<Location_Location, 'id' | 'name'>
      & { site: (
        { __typename?: 'Location_Site' }
        & Pick<Location_Site, 'id' | 'name'>
      ) }
    )>, purchaseItem?: Maybe<(
      { __typename?: 'Purchases_PurchasedItems' }
      & Pick<Purchases_PurchasedItems, 'id' | 'line_number'>
      & { original_cost?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragment
      )> }
    )>, saleItem?: Maybe<(
      { __typename?: 'Sales_SoldItems' }
      & Pick<Sales_SoldItems, 'id'>
      & { transaction: (
        { __typename?: 'Sales_Sale' }
        & Pick<Sales_Sale, 'id'>
      ) }
    )>, country_of_origin?: Maybe<(
      { __typename?: 'CountryObject' }
      & Pick<CountryObject, 'code' | 'name'>
      & { flag: CountryObject['unicode_flag'] }
    )>, test_status?: Maybe<Array<(
      { __typename?: 'Choices_Tag' }
      & Pick<Choices_Tag, 'id' | 'tag' | 'color'>
    )>>, part?: Maybe<(
      { __typename?: 'Parts_Part' }
      & Pick<Parts_Part, 'id' | 'pn' | 'description'>
    )> }
  )>>> }
);

export type GetItemsQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetItemsQuery = (
  { __typename?: 'Query' }
  & { items?: Maybe<Array<Maybe<(
    { __typename?: 'Inventory_Item' }
    & InventoryItemFragment
  )>>> }
);

export type GetSystemsQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetSystemsQuery = (
  { __typename?: 'Query' }
  & { systems?: Maybe<Array<Maybe<(
    { __typename?: 'Inventory_System' }
    & Pick<Inventory_System, 'oobmgmt_serial'>
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & BasicSystemFragment
  )>>> }
);

export type GetItemsForBuildUpQueryVariables = Exact<{
  pn: Scalars['Any'];
}>;


export type GetItemsForBuildUpQuery = (
  { __typename?: 'Query' }
  & { items?: Maybe<Array<Maybe<(
    { __typename?: 'Inventory_Item' }
    & Pick<Inventory_Item, 'id' | 'serial_number'>
    & { build_up_items?: Maybe<(
      { __typename?: 'BuildUp_BuildUpItems' }
      & Pick<BuildUp_BuildUpItems, 'id'>
    )>, part?: Maybe<(
      { __typename?: 'Parts_Part' }
      & Pick<Parts_Part, 'id' | 'pn' | 'description'>
    )>, pt?: Maybe<(
      { __typename?: 'Purchases_PurchasedItems' }
      & Pick<Purchases_PurchasedItems, 'id' | 'line_number'>
      & { transaction: (
        { __typename?: 'Purchases_Purchase' }
        & Pick<Purchases_Purchase, 'id'>
      ) }
    )> }
  )>>> }
);

export type GetItemsForBreakdownQueryVariables = Exact<{
  pn: Scalars['Any'];
}>;


export type GetItemsForBreakdownQuery = (
  { __typename?: 'Query' }
  & { items?: Maybe<Array<Maybe<(
    { __typename?: 'Inventory_Item' }
    & Pick<Inventory_Item, 'id' | 'serial_number'>
    & { part?: Maybe<(
      { __typename?: 'Parts_Part' }
      & Pick<Parts_Part, 'id' | 'pn' | 'description'>
    )>, pt?: Maybe<(
      { __typename?: 'Purchases_PurchasedItems' }
      & Pick<Purchases_PurchasedItems, 'id' | 'line_number'>
      & { transaction: (
        { __typename?: 'Purchases_Purchase' }
        & Pick<Purchases_Purchase, 'id'>
      ) }
    )> }
  )>>> }
);

export type ItemFromPtIdQueryVariables = Exact<{
  pt: Scalars['Any'];
  line: Scalars['Any'];
}>;


export type ItemFromPtIdQuery = (
  { __typename?: 'Query' }
  & { item?: Maybe<(
    { __typename?: 'Inventory_Item' }
    & Pick<Inventory_Item, 'id'>
    & { part?: Maybe<(
      { __typename?: 'Parts_Part' }
      & Pick<Parts_Part, 'id' | 'pn'>
    )> }
  )> }
);

export type MasterPartSalesItemsQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type MasterPartSalesItemsQuery = (
  { __typename?: 'Query' }
  & { sold_items?: Maybe<Array<Maybe<(
    { __typename?: 'Sales_SoldItems' }
    & Pick<Sales_SoldItems, 'id' | 'creation_date'>
    & { sold_for?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )>, transaction: (
      { __typename?: 'Sales_Sale' }
      & Pick<Sales_Sale, 'id' | 'creation_date'>
      & { rep?: Maybe<(
        { __typename?: 'Users_User' }
        & Pick<Users_User, 'id' | 'initials'>
      )>, client?: Maybe<(
        { __typename?: 'Clients_Client' }
        & Pick<Clients_Client, 'id' | 'name'>
      )> }
    ) }
  )>>> }
);

export type MasterPartPurchasesItemsQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type MasterPartPurchasesItemsQuery = (
  { __typename?: 'Query' }
  & { purchased_items?: Maybe<Array<Maybe<(
    { __typename?: 'Purchases_PurchasedItems' }
    & Pick<Purchases_PurchasedItems, 'id' | 'creation_date'>
    & { transaction: (
      { __typename?: 'Purchases_Purchase' }
      & Pick<Purchases_Purchase, 'id' | 'creation_date'>
      & { rep?: Maybe<(
        { __typename?: 'Users_User' }
        & Pick<Users_User, 'id' | 'initials'>
      )>, client?: Maybe<(
        { __typename?: 'Clients_Client' }
        & Pick<Clients_Client, 'id' | 'name'>
      )> }
    ), original_cost?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )>, current_cost?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )> }
  )>>> }
);

export type MasterPartQuotesItemsQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type MasterPartQuotesItemsQuery = (
  { __typename?: 'Query' }
  & { quote_parts?: Maybe<Array<Maybe<(
    { __typename?: 'Quotes_QuoteParts' }
    & Pick<Quotes_QuoteParts, 'id' | 'creation_date' | 'qty'>
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>>, quote: (
      { __typename?: 'Quotes_Quote' }
      & Pick<Quotes_Quote, 'id'>
      & { attached_sales?: Maybe<Array<(
        { __typename?: 'Sales_Sale' }
        & Pick<Sales_Sale, 'id'>
      )>>, client?: Maybe<(
        { __typename?: 'Clients_Client' }
        & Pick<Clients_Client, 'id' | 'name'>
      )>, contact?: Maybe<(
        { __typename?: 'Clients_Contact' }
        & Pick<Clients_Contact, 'id' | 'full_name'>
      )> }
    ), price_per_part?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )> }
  )>>> }
);

export type MasterPartSubPartsQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type MasterPartSubPartsQuery = (
  { __typename?: 'Query' }
  & { sub_parts?: Maybe<Array<Maybe<(
    { __typename?: 'Parts_Part' }
    & FullPart_Parts_Part_Fragment
  )>>> }
);

export type GetSystemQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSystemQuery = (
  { __typename?: 'Query' }
  & { inventory_system?: Maybe<(
    { __typename?: 'Inventory_System' }
    & Pick<Inventory_System, 'id' | 'serial_number'>
    & { part?: Maybe<(
      { __typename?: 'Parts_Part' }
      & Pick<Parts_Part, 'id' | 'pn' | 'description'>
    )>, purchases_items_details?: Maybe<(
      { __typename?: 'Purchases_PurchasedItems' }
      & Pick<Purchases_PurchasedItems, 'id' | 'line_number'>
      & { current_cost?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragment
      )>, original_cost?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragment
      )>, transaction: (
        { __typename?: 'Purchases_Purchase' }
        & Pick<Purchases_Purchase, 'id'>
      ) }
    )> }
  )> }
);

export type GetAddOnItemsForSystemQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetAddOnItemsForSystemQuery = (
  { __typename?: 'Query' }
  & { items?: Maybe<Array<Maybe<(
    { __typename?: 'Inventory_Item' }
    & Pick<Inventory_Item, 'id' | 'serial_number'>
    & { broke_down_items?: Maybe<(
      { __typename?: 'Breakdown_BreakDownItems' }
      & Pick<Breakdown_BreakDownItems, 'id'>
      & { status: (
        { __typename?: 'Choices_Status' }
        & Pick<Choices_Status, 'id' | 'status'>
      ) }
    )>, part?: Maybe<(
      { __typename?: 'Parts_Part' }
      & Pick<Parts_Part, 'id' | 'pn' | 'description'>
    )>, system?: Maybe<(
      { __typename?: 'Inventory_System' }
      & Pick<Inventory_System, 'id'>
    )>, purchases_items_details?: Maybe<(
      { __typename?: 'Purchases_PurchasedItems' }
      & Pick<Purchases_PurchasedItems, 'id' | 'line_number'>
      & { transaction: (
        { __typename?: 'Purchases_Purchase' }
        & Pick<Purchases_Purchase, 'id'>
      ), original_cost?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragment
      )>, current_cost?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragment
      )> }
    )> }
  )>>> }
);

export type BasicPartInfoQueryVariables = Exact<{
  filters?: Maybe<Array<QueryFilter> | QueryFilter>;
  excludes?: Maybe<Array<QueryFilter> | QueryFilter>;
}>;


export type BasicPartInfoQuery = (
  { __typename?: 'Query' }
  & { parts?: Maybe<Array<Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'secondary_pn'>
    & { title: Parts_Part['pn'], subtitle: Parts_Part['description'] }
  )>>> }
);

export type MasterPartsQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type MasterPartsQuery = (
  { __typename?: 'Query' }
  & { parts?: Maybe<Array<Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'ean' | 'hs_code' | 'htsus_code' | 'schedule_b_code' | 'secondary_pn' | 'upc'>
    & { market?: Maybe<(
      { __typename?: 'Choices_Choice' }
      & Pick<Choices_Choice, 'id' | 'choice'>
    )> }
    & BasicPartFragment
  )>>> }
);

export type BuySellHistoryQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type BuySellHistoryQuery = (
  { __typename?: 'Query' }
  & { items?: Maybe<Array<Maybe<(
    { __typename?: 'Inventory_Item' }
    & Pick<Inventory_Item, 'id' | 'receive_status'>
    & { condition?: Maybe<(
      { __typename?: 'Choices_Choice' }
      & Pick<Choices_Choice, 'id' | 'choice'>
    )>, part?: Maybe<(
      { __typename?: 'Parts_Part' }
      & BasicPartFragment
    )>, purchases_items_details?: Maybe<(
      { __typename?: 'Purchases_PurchasedItems' }
      & Pick<Purchases_PurchasedItems, 'id' | 'creation_date' | 'line_number'>
      & { current_cost?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragment
      )>, original_cost?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragment
      )>, transaction: (
        { __typename?: 'Purchases_Purchase' }
        & Pick<Purchases_Purchase, 'id' | 'purchase_date'>
        & { client?: Maybe<(
          { __typename?: 'Clients_Client' }
          & Pick<Clients_Client, 'id' | 'name'>
        )>, rep?: Maybe<(
          { __typename?: 'Users_User' }
          & Pick<Users_User, 'id' | 'initials'>
        )> }
      ) }
    )>, sales_items_details?: Maybe<(
      { __typename?: 'Sales_SoldItems' }
      & Pick<Sales_SoldItems, 'id' | 'creation_date' | 'line_number'>
      & { sold_for?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragment
      )>, transaction: (
        { __typename?: 'Sales_Sale' }
        & Pick<Sales_Sale, 'id' | 'sale_date'>
        & { client?: Maybe<(
          { __typename?: 'Clients_Client' }
          & Pick<Clients_Client, 'id' | 'name'>
        )>, rep?: Maybe<(
          { __typename?: 'Users_User' }
          & Pick<Users_User, 'id' | 'initials'>
        )> }
      ) }
    )> }
  )>>> }
);

export type PartsQueryVariables = Exact<{
  filters?: Maybe<Array<Maybe<QueryFilter>> | Maybe<QueryFilter>>;
}>;


export type PartsQuery = (
  { __typename?: 'Query' }
  & { parts?: Maybe<Array<Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'pn' | 'id' | 'description' | 'type'>
    & { mfgr?: Maybe<(
      { __typename?: 'Parts_ProductCompany' }
      & Pick<Parts_ProductCompany, 'id' | 'name'>
    )> }
  )>>> }
);

export type LinkSubPartOneWayMutationVariables = Exact<{
  part: Scalars['ID'];
  sub: Scalars['ID'];
}>;


export type LinkSubPartOneWayMutation = (
  { __typename?: 'Mutation' }
  & { sub?: Maybe<(
    { __typename?: 'Parts_Part' }
    & FullPart_Parts_Part_Fragment
  )> }
);

export type LinkSubPartTwoWayMutationVariables = Exact<{
  part: Scalars['ID'];
  sub: Scalars['ID'];
}>;


export type LinkSubPartTwoWayMutation = (
  { __typename?: 'Mutation' }
  & { sub?: Maybe<(
    { __typename?: 'Parts_Part' }
    & FullPart_Parts_Part_Fragment
  )>, part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & FullPart_Parts_Part_Fragment
  )> }
);

export type UnlinkSubPartOneWayMutationVariables = Exact<{
  part: Scalars['ID'];
}>;


export type UnlinkSubPartOneWayMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id'>
  )> }
);

export type UpdateInventoryItemForCommentMutationVariables = Exact<{
  input: Array<Update__Inventory_Item__Input> | Update__Inventory_Item__Input;
}>;


export type UpdateInventoryItemForCommentMutation = (
  { __typename?: 'Mutation' }
  & { comment?: Maybe<Array<Maybe<(
    { __typename?: 'Inventory_Item' }
    & Pick<Inventory_Item, 'id'>
    & InventoryItemFragment
  )>>> }
);

export type UpdateInventoryCommentMutationVariables = Exact<{
  input: Array<Update__Inventory_Item__Input> | Update__Inventory_Item__Input;
}>;


export type UpdateInventoryCommentMutation = (
  { __typename?: 'Mutation' }
  & { comment?: Maybe<Array<Maybe<(
    { __typename?: 'Inventory_Item' }
    & Pick<Inventory_Item, 'id' | 'condition_comment' | 'inventory_comment' | 'lock_reason'>
  )>>> }
);

export type UpdateInventorySystemForCommentMutationVariables = Exact<{
  input: Update__Inventory_System__Input;
}>;


export type UpdateInventorySystemForCommentMutation = (
  { __typename?: 'Mutation' }
  & { comment?: Maybe<(
    { __typename?: 'Inventory_System' }
    & BasicSystemFragment
  )> }
);

export type UpdateInventoryItemMutationVariables = Exact<{
  input: Array<Update__Inventory_Item__Input> | Update__Inventory_Item__Input;
}>;


export type UpdateInventoryItemMutation = (
  { __typename?: 'Mutation' }
  & { Update__Inventory_Item?: Maybe<Array<Maybe<(
    { __typename?: 'Inventory_Item' }
    & Pick<Inventory_Item, 'id'>
    & InventoryItemFragment
  )>>> }
);

export type SetItemTestStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  statuses?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type SetItemTestStatusMutation = (
  { __typename?: 'Mutation' }
  & { item?: Maybe<(
    { __typename?: 'Inventory_Item' }
    & Pick<Inventory_Item, 'id'>
    & { test_status?: Maybe<Array<(
      { __typename?: 'Choices_Tag' }
      & Pick<Choices_Tag, 'id' | 'tag' | 'description' | 'color'>
    )>> }
  )> }
);

export type UpdateInventorySystemMutationVariables = Exact<{
  input: Update__Inventory_System__Input;
}>;


export type UpdateInventorySystemMutation = (
  { __typename?: 'Mutation' }
  & { Update__Inventory_System?: Maybe<(
    { __typename?: 'Inventory_System' }
    & BasicSystemFragment
  )> }
);

export type AddSerialNumberMutationVariables = Exact<{
  id: Scalars['ID'];
  serial?: Maybe<Scalars['String']>;
}>;


export type AddSerialNumberMutation = (
  { __typename?: 'Mutation' }
  & { UpdateItem?: Maybe<Array<Maybe<(
    { __typename?: 'Inventory_Item' }
    & Pick<Inventory_Item, 'id'>
  )>>> }
);

export type MarkItemAsLostMutationVariables = Exact<{
  id: Scalars['ID'];
  lost?: Maybe<Scalars['Boolean']>;
}>;


export type MarkItemAsLostMutation = (
  { __typename?: 'Mutation' }
  & { item?: Maybe<Array<Maybe<(
    { __typename?: 'Inventory_Item' }
    & Pick<Inventory_Item, 'id'>
  )>>> }
);

export type ReceiveItemMutationVariables = Exact<{
  id: Scalars['ID'];
  country?: Maybe<Countries>;
  serial?: Maybe<Scalars['String']>;
  loc?: Maybe<Scalars['ID']>;
  user?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['Boolean']>;
}>;


export type ReceiveItemMutation = (
  { __typename?: 'Mutation' }
  & { UpdateItem?: Maybe<Array<Maybe<(
    { __typename?: 'Inventory_Item' }
    & Pick<Inventory_Item, 'id' | 'serial_number' | 'receive_status'>
    & { location?: Maybe<(
      { __typename?: 'Location_Location' }
      & Pick<Location_Location, 'id' | 'name'>
    )> }
  )>>> }
);

export type UpdateSystemAddOnItemsMutationVariables = Exact<{
  system: Scalars['ID'];
  items?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type UpdateSystemAddOnItemsMutation = (
  { __typename?: 'Mutation' }
  & { system?: Maybe<(
    { __typename?: 'Inventory_System' }
    & Pick<Inventory_System, 'id'>
  )> }
);

export type RemoveSystemAddOnItemsMutationVariables = Exact<{
  system: Scalars['ID'];
  items?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type RemoveSystemAddOnItemsMutation = (
  { __typename?: 'Mutation' }
  & { system?: Maybe<(
    { __typename?: 'Inventory_System' }
    & Pick<Inventory_System, 'id'>
  )> }
);

export type UpdatePartHasBatteryMutationVariables = Exact<{
  id: Scalars['ID'];
  has_battery?: Maybe<Scalars['Boolean']>;
}>;


export type UpdatePartHasBatteryMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id'>
  )> }
);

export type CreateAccessPointMutationVariables = Exact<{
  input: Create__Parts_AccessPoint__Input;
}>;


export type CreateAccessPointMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_AccessPoint' }
    & FullPart_Parts_AccessPoint_Fragment
  )> }
);

export type CreateBatteryMutationVariables = Exact<{
  input: Create__Parts_Battery__Input;
}>;


export type CreateBatteryMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_Battery' }
    & FullPart_Parts_Battery_Fragment
  )> }
);

export type CreateBezelMutationVariables = Exact<{
  input: Create__Parts_Bezel__Input;
}>;


export type CreateBezelMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_Bezel' }
    & FullPart_Parts_Bezel_Fragment
  )> }
);

export type CreateBracketMutationVariables = Exact<{
  input: Create__Parts_Bracket__Input;
}>;


export type CreateBracketMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_Bracket' }
    & FullPart_Parts_Bracket_Fragment
  )> }
);

export type CreateCpuMutationVariables = Exact<{
  input: Create__Parts_Cpu__Input;
}>;


export type CreateCpuMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_CPU' }
    & FullPart_Parts_Cpu_Fragment
  )> }
);

export type CreateCableMutationVariables = Exact<{
  input: Create__Parts_Cable__Input;
}>;


export type CreateCableMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_Cable' }
    & FullPart_Parts_Cable_Fragment
  )> }
);

export type CreateCacheMutationVariables = Exact<{
  input: Create__Parts_Cache__Input;
}>;


export type CreateCacheMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_Cache' }
    & FullPart_Parts_Cache_Fragment
  )> }
);

export type CreateDiskDriveMutationVariables = Exact<{
  input: Create__Parts_DiskDrive__Input;
}>;


export type CreateDiskDriveMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_DiskDrive' }
    & FullPart_Parts_DiskDrive_Fragment
  )> }
);

export type CreateExpansionCardMutationVariables = Exact<{
  input: Create__Parts_ExpansionCard__Input;
}>;


export type CreateExpansionCardMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_ExpansionCard' }
    & FullPart_Parts_ExpansionCard_Fragment
  )> }
);

export type CreateFanMutationVariables = Exact<{
  input: Create__Parts_Fan__Input;
}>;


export type CreateFanMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_Fan' }
    & FullPart_Parts_Fan_Fragment
  )> }
);

export type CreateGpuMutationVariables = Exact<{
  input: Create__Parts_Gpu__Input;
}>;


export type CreateGpuMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_GPU' }
    & FullPart_Parts_Gpu_Fragment
  )> }
);

export type CreateHeatSinkMutationVariables = Exact<{
  input: Create__Parts_Heatsink__Input;
}>;


export type CreateHeatSinkMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_Heatsink' }
    & FullPart_Parts_Heatsink_Fragment
  )> }
);

export type IoModuleMutationVariables = Exact<{
  input: Create__Parts_IoModule__Input;
}>;


export type IoModuleMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_IOModule' }
    & FullPart_Parts_IoModule_Fragment
  )> }
);

export type CreateMemoryMutationVariables = Exact<{
  input: Create__Parts_Memory__Input;
}>;


export type CreateMemoryMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_Memory' }
    & FullPart_Parts_Memory_Fragment
  )> }
);

export type CreateMotherboardMutationVariables = Exact<{
  input: Create__Parts_Motherboard__Input;
}>;


export type CreateMotherboardMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_Motherboard' }
    & FullPart_Parts_Motherboard_Fragment
  )> }
);

export type CreateNetworkCardMutationVariables = Exact<{
  input: Create__Parts_NetworkCard__Input;
}>;


export type CreateNetworkCardMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_NetworkCard' }
    & FullPart_Parts_NetworkCard_Fragment
  )> }
);

export type CreatePhoneMutationVariables = Exact<{
  input: Create__Parts_Phone__Input;
}>;


export type CreatePhoneMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_Phone' }
    & FullPart_Parts_Phone_Fragment
  )> }
);

export type CreatePowerSupplyMutationVariables = Exact<{
  input: Create__Parts_PowerSupply__Input;
}>;


export type CreatePowerSupplyMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_PowerSupply' }
    & FullPart_Parts_PowerSupply_Fragment
  )> }
);

export type CreateRailsMutationVariables = Exact<{
  input: Create__Parts_Rails__Input;
}>;


export type CreateRailsMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_Rails' }
    & FullPart_Parts_Rails_Fragment
  )> }
);

export type CreateRouterMutationVariables = Exact<{
  input: Create__Parts_Router__Input;
}>;


export type CreateRouterMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_Router' }
    & FullPart_Parts_Router_Fragment
  )> }
);

export type CreateServerMutationVariables = Exact<{
  input: Create__Parts_Server__Input;
}>;


export type CreateServerMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_Server' }
    & FullPart_Parts_Server_Fragment
  )> }
);

export type CreateStorageMutationVariables = Exact<{
  input: Create__Parts_Storage__Input;
}>;


export type CreateStorageMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_Storage' }
    & FullPart_Parts_Storage_Fragment
  )> }
);

export type CreateStorageEnclosureMutationVariables = Exact<{
  input: Create__Parts_StorageEnclosure__Input;
}>;


export type CreateStorageEnclosureMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_StorageEnclosure' }
    & FullPart_Parts_StorageEnclosure_Fragment
  )> }
);

export type CreateSuppliesMutationVariables = Exact<{
  input: Create__Parts_Supplies__Input;
}>;


export type CreateSuppliesMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_Supplies' }
    & FullPart_Parts_Supplies_Fragment
  )> }
);

export type CreateSwitchMutationVariables = Exact<{
  input: Create__Parts_Switch__Input;
}>;


export type CreateSwitchMutation = (
  { __typename?: 'Mutation' }
  & { part?: Maybe<(
    { __typename?: 'Parts_Switch' }
    & FullPart_Parts_Switch_Fragment
  )> }
);

export type Site_ListFragment = (
  { __typename?: 'Location_Site' }
  & Pick<Location_Site, 'id' | 'name' | 'short_name' | 'delivery_notes' | 'main_site'>
);

export type Site_GridFragment = (
  { __typename?: 'Location_Site' }
  & { address?: Maybe<(
    { __typename?: 'Address_Address' }
    & AddressGridFragment
  )> }
  & Site_ListFragment
);

export type Location_ListFragment = (
  { __typename?: 'Location_Location' }
  & Pick<Location_Location, 'id' | 'name'>
  & { site: (
    { __typename?: 'Location_Site' }
    & Site_ListFragment
  ) }
);

export type Location_GridFragment = (
  { __typename?: 'Location_Location' }
  & Pick<Location_Location, 'part_type'>
  & Location_ListFragment
);

export type GetLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocationsQuery = (
  { __typename?: 'Query' }
  & { locations?: Maybe<Array<Maybe<(
    { __typename?: 'Location_Location' }
    & Location_ListFragment
  )>>> }
);

export type GetLocations_GridQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetLocations_GridQuery = (
  { __typename?: 'Query' }
  & { location_locations?: Maybe<Array<Maybe<(
    { __typename?: 'Location_Location' }
    & Location_GridFragment
  )>>> }
);

export type GetSitesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSitesQuery = (
  { __typename?: 'Query' }
  & { sites?: Maybe<Array<Maybe<(
    { __typename?: 'Location_Site' }
    & Site_ListFragment
  )>>> }
);

export type GetSites_GridQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSites_GridQuery = (
  { __typename?: 'Query' }
  & { sites?: Maybe<Array<Maybe<(
    { __typename?: 'Location_Site' }
    & Site_GridFragment
  )>>> }
);

export type CreateLocationMutationVariables = Exact<{
  input: Create__Location_Location__Input;
}>;


export type CreateLocationMutation = (
  { __typename?: 'Mutation' }
  & { Create__Location_Location?: Maybe<(
    { __typename?: 'Location_Location' }
    & Location_GridFragment
  )> }
);

export type UpdateLocationMutationVariables = Exact<{
  input: Update__Location_Location__Input;
}>;


export type UpdateLocationMutation = (
  { __typename?: 'Mutation' }
  & { Update__Location_Location?: Maybe<(
    { __typename?: 'Location_Location' }
    & Location_GridFragment
  )> }
);

export type DeleteLocationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLocationMutation = (
  { __typename?: 'Mutation' }
  & { Delete__Location_Location?: Maybe<(
    { __typename?: 'DBActionStatus' }
    & Pick<DbActionStatus, 'id'>
  )> }
);

export type CreateSiteMutationVariables = Exact<{
  input: Create__Location_Site__Input;
}>;


export type CreateSiteMutation = (
  { __typename?: 'Mutation' }
  & { Create__Location_Site?: Maybe<(
    { __typename?: 'Location_Site' }
    & Site_GridFragment
  )> }
);

export type UpdateSiteMutationVariables = Exact<{
  input: Update__Location_Site__Input;
}>;


export type UpdateSiteMutation = (
  { __typename?: 'Mutation' }
  & { Update__Location_Site?: Maybe<(
    { __typename?: 'Location_Site' }
    & Site_GridFragment
  )> }
);

export type DeleteSiteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSiteMutation = (
  { __typename?: 'Mutation' }
  & { Delete__Location_Site?: Maybe<(
    { __typename?: 'DBActionStatus' }
    & Pick<DbActionStatus, 'id'>
  )> }
);

export type PurchaseFragment = (
  { __typename?: 'Purchases_Purchase' }
  & Pick<Purchases_Purchase, 'id' | 'acceptance_date' | 'approval_date' | 'client_order_number' | 'contract_comment' | 'creation_date' | 'eta' | 'hot' | 'internal_comment' | 'purchase_date' | 'requested_date' | 'vendor_reference'>
  & { approved_by?: Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'full_name' | 'initials'>
  )>, client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id' | 'name'>
  )>, condition?: Maybe<(
    { __typename?: 'Choices_Choice' }
    & Pick<Choices_Choice, 'id' | 'choice'>
  )>, contact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Pick<Clients_Contact, 'id' | 'email' | 'full_name'>
  )>, contract_sent_by?: Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'full_name' | 'initials'>
  )>, creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials' | 'full_name'>
  ), freight?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, estimated_part_total?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, quote?: Maybe<(
    { __typename?: 'Quotes_Quote' }
    & Pick<Quotes_Quote, 'id'>
  )>, rep?: Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials' | 'full_name' | 'email'>
  )>, shipment_order?: Maybe<(
    { __typename?: 'Shipping_ShipmentOrder' }
    & Pick<Shipping_ShipmentOrder, 'id' | 'blind' | 'deliver_by_date' | 'tracking_number'>
    & { status: (
      { __typename?: 'Choices_Status' }
      & Pick<Choices_Status, 'id' | 'status'>
    ) }
  )>, status: (
    { __typename?: 'Choices_Status' }
    & Pick<Choices_Status, 'id' | 'status' | 'status_type'>
  ), tax?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, terms?: Maybe<(
    { __typename?: 'Clients_Terms' }
    & Pick<Clients_Terms, 'id' | 'name'>
  )>, estimated_total?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )> }
);

export type PurchaseForPdfFragment = (
  { __typename?: 'Purchases_Purchase' }
  & Pick<Purchases_Purchase, 'id' | 'contract_comment' | 'purchase_date'>
  & { client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id' | 'name' | 'short_name'>
  )>, contact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Pick<Clients_Contact, 'id' | 'email' | 'phone' | 'full_name'>
  )>, pt_items?: Maybe<Array<(
    { __typename?: 'Purchases_PurchasedItems' }
    & Pick<Purchases_PurchasedItems, 'id' | 'line_number'>
    & { part: (
      { __typename?: 'Parts_Part' }
      & Pick<Parts_Part, 'id' | 'pn' | 'description'>
      & { brand?: Maybe<(
        { __typename?: 'Parts_ProductCompany' }
        & Pick<Parts_ProductCompany, 'id' | 'name'>
      )>, mfgr?: Maybe<(
        { __typename?: 'Parts_ProductCompany' }
        & Pick<Parts_ProductCompany, 'id' | 'name'>
      )> }
    ), original_cost?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )> }
  )>>, rep?: Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials' | 'full_name' | 'email'>
  )>, shipment_order?: Maybe<(
    { __typename?: 'Shipping_ShipmentOrder' }
    & Pick<Shipping_ShipmentOrder, 'id' | 'blind'>
    & { purchaser_address?: Maybe<(
      { __typename?: 'Address_Address' }
      & Pick<Address_Address, 'id' | 'street_1' | 'street_2' | 'city' | 'state' | 'zip_code'>
      & { country: (
        { __typename?: 'CountryObject' }
        & Pick<CountryObject, 'code' | 'name'>
      ), company?: Maybe<(
        { __typename?: 'Company_Company' }
        & Pick<Company_Company, 'name'>
      )>, client?: Maybe<(
        { __typename?: 'Clients_Client' }
        & Pick<Clients_Client, 'id' | 'name'>
      )>, default_contact?: Maybe<(
        { __typename?: 'Clients_Contact' }
        & Pick<Clients_Contact, 'id' | 'full_name'>
      )> }
    )>, ship_from_address?: Maybe<(
      { __typename?: 'Address_Address' }
      & Pick<Address_Address, 'id' | 'street_1' | 'street_2' | 'city' | 'state' | 'zip_code'>
      & { country: (
        { __typename?: 'CountryObject' }
        & Pick<CountryObject, 'code' | 'name'>
      ), company?: Maybe<(
        { __typename?: 'Company_Company' }
        & Pick<Company_Company, 'name'>
      )>, client?: Maybe<(
        { __typename?: 'Clients_Client' }
        & Pick<Clients_Client, 'id' | 'name'>
      )>, default_contact?: Maybe<(
        { __typename?: 'Clients_Contact' }
        & Pick<Clients_Contact, 'id' | 'full_name'>
      )> }
    )>, ship_to_address?: Maybe<(
      { __typename?: 'Address_Address' }
      & Pick<Address_Address, 'id' | 'street_1' | 'street_2' | 'city' | 'state' | 'zip_code'>
      & { country: (
        { __typename?: 'CountryObject' }
        & Pick<CountryObject, 'code' | 'name'>
      ), client?: Maybe<(
        { __typename?: 'Clients_Client' }
        & Pick<Clients_Client, 'id' | 'name'>
      )>, company?: Maybe<(
        { __typename?: 'Company_Company' }
        & Pick<Company_Company, 'name'>
      )>, default_contact?: Maybe<(
        { __typename?: 'Clients_Contact' }
        & Pick<Clients_Contact, 'id' | 'full_name'>
      )> }
    )> }
  )>, status: (
    { __typename?: 'Choices_Status' }
    & Pick<Choices_Status, 'id' | 'status'>
  ), total?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, terms?: Maybe<(
    { __typename?: 'Clients_Terms' }
    & Pick<Clients_Terms, 'id' | 'name'>
  )> }
);

export type PurchasedItemFragment = (
  { __typename?: 'Purchases_PurchasedItems' }
  & Pick<Purchases_PurchasedItems, 'edit_lock' | 'id' | 'line_number' | 'swap_type' | 'updated_date'>
  & { current_cost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, item?: Maybe<(
    { __typename?: 'Inventory_Item' }
    & { sale?: Maybe<(
      { __typename?: 'Sales_SoldItems' }
      & Pick<Sales_SoldItems, 'id'>
    )>, purchase_rma_item_details?: Maybe<(
      { __typename?: 'RMA_PurchaseRMAItems' }
      & Pick<Rma_PurchaseRmaItems, 'id'>
      & { rma: (
        { __typename?: 'RMA_PurchaseRMA' }
        & Pick<Rma_PurchaseRma, 'id'>
      ) }
    )> }
    & InventoryItemFragment
  )>, original_cost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, part: (
    { __typename?: 'Parts_Part' }
    & BasicPartFragment
  ), shipment?: Maybe<(
    { __typename?: 'Shipping_Shipment' }
    & Pick<Shipping_Shipment, 'id'>
  )> }
);

export type SaleForGridFragment = (
  { __typename?: 'Sales_Sale' }
  & Pick<Sales_Sale, 'book_date' | 'client_order_number' | 'contract_comment' | 'contract_send_by_date' | 'creation_date' | 'id' | 'internal_comment' | 'promise_date' | 'sale_date' | 'ship_by_date'>
  & { client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id' | 'name' | 'short_name'>
  )>, condition?: Maybe<(
    { __typename?: 'Choices_Choice' }
    & Pick<Choices_Choice, 'id' | 'choice' | 'sub_choice'>
  )>, contact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Pick<Clients_Contact, 'id' | 'full_name' | 'email' | 'phone'>
  )>, creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials'>
  ), estimated_cost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, estimated_margin?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, freight?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, quote?: Maybe<(
    { __typename?: 'Quotes_Quote' }
    & Pick<Quotes_Quote, 'id'>
  )>, rep?: Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials' | 'full_name' | 'email'>
  )>, shipment_order?: Maybe<(
    { __typename?: 'Shipping_ShipmentOrder' }
    & Pick<Shipping_ShipmentOrder, 'id' | 'blind' | 'tracking_number' | 'pickup_type'>
    & { status: (
      { __typename?: 'Choices_Status' }
      & Pick<Choices_Status, 'id' | 'status'>
    ) }
  )>, status: (
    { __typename?: 'Choices_Status' }
    & Pick<Choices_Status, 'id' | 'status' | 'status_type'>
  ), tax?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, terms?: Maybe<(
    { __typename?: 'Clients_Terms' }
    & Pick<Clients_Terms, 'id' | 'name' | 'notes'>
  )>, estimated_total?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )> }
);

export type SaleFragment = (
  { __typename?: 'Sales_Sale' }
  & Pick<Sales_Sale, 'book_date' | 'client_order_number' | 'contract_comment' | 'contract_send_by_date' | 'creation_date' | 'id' | 'internal_comment' | 'promise_date' | 'sale_date' | 'ship_by_date'>
  & { client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id' | 'name' | 'short_name'>
  )>, condition?: Maybe<(
    { __typename?: 'Choices_Choice' }
    & Pick<Choices_Choice, 'id' | 'choice' | 'sub_choice'>
  )>, contact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Pick<Clients_Contact, 'id' | 'full_name' | 'first_name' | 'last_name' | 'email' | 'phone'>
  )>, creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials'>
  ), estimated_cost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, estimated_margin?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, freight?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, quote?: Maybe<(
    { __typename?: 'Quotes_Quote' }
    & Pick<Quotes_Quote, 'id'>
  )>, rep?: Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials' | 'full_name' | 'email'>
  )>, shipment_order?: Maybe<(
    { __typename?: 'Shipping_ShipmentOrder' }
    & Pick<Shipping_ShipmentOrder, 'id' | 'blind' | 'tracking_number' | 'pickup_type'>
    & { status: (
      { __typename?: 'Choices_Status' }
      & Pick<Choices_Status, 'id' | 'status'>
    ) }
  )>, st_items?: Maybe<Array<(
    { __typename?: 'Sales_SoldItems' }
    & Pick<Sales_SoldItems, 'id'>
    & { item?: Maybe<(
      { __typename?: 'Inventory_Item' }
      & Pick<Inventory_Item, 'id'>
    )> }
  )>>, status: (
    { __typename?: 'Choices_Status' }
    & Pick<Choices_Status, 'id' | 'status' | 'status_type'>
  ), tax?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, terms?: Maybe<(
    { __typename?: 'Clients_Terms' }
    & Pick<Clients_Terms, 'id' | 'name' | 'notes'>
  )>, estimated_total?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )> }
);

export type SaleForEditFragment = (
  { __typename?: 'Sales_Sale' }
  & Pick<Sales_Sale, 'book_date' | 'client_order_number' | 'contract_comment' | 'contract_send_by_date' | 'creation_date' | 'id' | 'internal_comment' | 'promise_date' | 'ship_by_date' | 'we_install' | 'we_transport'>
  & { client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id' | 'name'>
  )>, contact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Pick<Clients_Contact, 'id' | 'first_name' | 'last_name' | 'full_name' | 'email'>
    & { owner: (
      { __typename?: 'Clients_Client' }
      & Pick<Clients_Client, 'id'>
    ) }
  )>, creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials' | 'full_name'>
  ), declared_value?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, deposit?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, down_payment?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, freight?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, installation_cost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, misc?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, quote?: Maybe<(
    { __typename?: 'Quotes_Quote' }
    & Pick<Quotes_Quote, 'id'>
  )>, rep?: Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'full_name' | 'initials'>
  )>, shipment_order?: Maybe<(
    { __typename?: 'Shipping_ShipmentOrder' }
    & ShipmentOrderToEditFragment
  )>, tax?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, terms?: Maybe<(
    { __typename?: 'Clients_Terms' }
    & Pick<Clients_Terms, 'id' | 'name'>
  )> }
);

export type PurchaseForEditFragment = (
  { __typename?: 'Purchases_Purchase' }
  & Pick<Purchases_Purchase, 'acceptance_date' | 'approval_date' | 'client_order_number' | 'closing_date' | 'contract_comment' | 'creation_date' | 'eta' | 'id' | 'internal_comment' | 'purchase_date' | 'requested_date' | 'vendor_reference' | 'warranty_end_date' | 'we_transport'>
  & { client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id' | 'name'>
  )>, condition?: Maybe<(
    { __typename?: 'Choices_Choice' }
    & Pick<Choices_Choice, 'id' | 'choice'>
  )>, contact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Pick<Clients_Contact, 'id' | 'first_name' | 'last_name' | 'full_name' | 'email'>
    & { owner: (
      { __typename?: 'Clients_Client' }
      & Pick<Clients_Client, 'id'>
    ) }
  )>, creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials' | 'full_name'>
  ), down_payment?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, freight?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, misc?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, quote?: Maybe<(
    { __typename?: 'Quotes_Quote' }
    & Pick<Quotes_Quote, 'id'>
  )>, rep?: Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'full_name' | 'initials'>
  )>, shipment_order?: Maybe<(
    { __typename?: 'Shipping_ShipmentOrder' }
    & ShipmentOrderToEditFragment
  )>, tax?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, terms?: Maybe<(
    { __typename?: 'Clients_Terms' }
    & Pick<Clients_Terms, 'id' | 'name'>
  )> }
);

export type SoldItemFragment = (
  { __typename?: 'Sales_SoldItems' }
  & Pick<Sales_SoldItems, 'edit_lock' | 'id' | 'line_number' | 'swap_type' | 'updated_date'>
  & { item?: Maybe<(
    { __typename?: 'Inventory_Item' }
    & { rma_item_details?: Maybe<(
      { __typename?: 'RMA_RMAItems' }
      & Pick<Rma_RmaItems, 'id'>
      & { rma: (
        { __typename?: 'RMA_RMA' }
        & Pick<Rma_Rma, 'id'>
      ) }
    )>, purchases_items_details?: Maybe<(
      { __typename?: 'Purchases_PurchasedItems' }
      & Pick<Purchases_PurchasedItems, 'id' | 'line_number'>
      & { current_cost?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragment
      )>, original_cost?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragment
      )>, transaction: (
        { __typename?: 'Purchases_Purchase' }
        & Pick<Purchases_Purchase, 'id'>
      ) }
    )> }
    & InventoryItemFragment
  )>, original_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & BasicPartFragment
  )>, part: (
    { __typename?: 'Parts_Part' }
    & BasicPartFragment
  ), shipment?: Maybe<(
    { __typename?: 'Shipping_Shipment' }
    & Pick<Shipping_Shipment, 'id'>
  )>, status: (
    { __typename?: 'Choices_Status' }
    & Pick<Choices_Status, 'id' | 'status'>
  ), sold_for?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )> }
);

export type SaleForPdfFragment = (
  { __typename?: 'Sales_Sale' }
  & Pick<Sales_Sale, 'client_order_number' | 'id' | 'contract_comment' | 'sale_date'>
  & { client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id' | 'name' | 'short_name'>
  )>, contact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Pick<Clients_Contact, 'id' | 'email' | 'phone' | 'full_name'>
  )>, st_items?: Maybe<Array<(
    { __typename?: 'Sales_SoldItems' }
    & Pick<Sales_SoldItems, 'id' | 'line_number'>
    & { part: (
      { __typename?: 'Parts_Part' }
      & Pick<Parts_Part, 'id' | 'pn' | 'description' | 'type'>
      & { brand?: Maybe<(
        { __typename?: 'Parts_ProductCompany' }
        & Pick<Parts_ProductCompany, 'id' | 'name'>
      )>, mfgr?: Maybe<(
        { __typename?: 'Parts_ProductCompany' }
        & Pick<Parts_ProductCompany, 'id' | 'name'>
      )> }
    ), sold_for?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )> }
  )>>, rep?: Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials' | 'full_name' | 'email'>
  )>, shipment_order?: Maybe<(
    { __typename?: 'Shipping_ShipmentOrder' }
    & Pick<Shipping_ShipmentOrder, 'id' | 'blind'>
    & { purchaser_contact?: Maybe<(
      { __typename?: 'Clients_Contact' }
      & Contact_ListFragment
    )>, purchaser_address?: Maybe<(
      { __typename?: 'Address_Address' }
      & Pick<Address_Address, 'id' | 'street_1' | 'street_2' | 'city' | 'state' | 'zip_code'>
      & { country: (
        { __typename?: 'CountryObject' }
        & Pick<CountryObject, 'code' | 'name'>
      ), company?: Maybe<(
        { __typename?: 'Company_Company' }
        & Pick<Company_Company, 'name'>
      )>, client?: Maybe<(
        { __typename?: 'Clients_Client' }
        & Pick<Clients_Client, 'id' | 'name'>
      )>, default_contact?: Maybe<(
        { __typename?: 'Clients_Contact' }
        & Pick<Clients_Contact, 'id' | 'full_name'>
      )> }
    )>, ship_from_contact?: Maybe<(
      { __typename?: 'Clients_Contact' }
      & Contact_ListFragment
    )>, ship_from_address?: Maybe<(
      { __typename?: 'Address_Address' }
      & Pick<Address_Address, 'id' | 'street_1' | 'street_2' | 'city' | 'state' | 'zip_code'>
      & { country: (
        { __typename?: 'CountryObject' }
        & Pick<CountryObject, 'code' | 'name'>
      ), company?: Maybe<(
        { __typename?: 'Company_Company' }
        & Pick<Company_Company, 'name'>
      )>, client?: Maybe<(
        { __typename?: 'Clients_Client' }
        & Pick<Clients_Client, 'id' | 'name'>
      )>, default_contact?: Maybe<(
        { __typename?: 'Clients_Contact' }
        & Pick<Clients_Contact, 'id' | 'full_name'>
      )> }
    )>, ship_to_contact?: Maybe<(
      { __typename?: 'Clients_Contact' }
      & Contact_ListFragment
    )>, ship_to_address?: Maybe<(
      { __typename?: 'Address_Address' }
      & Pick<Address_Address, 'id' | 'street_1' | 'street_2' | 'city' | 'state' | 'zip_code'>
      & { country: (
        { __typename?: 'CountryObject' }
        & Pick<CountryObject, 'code' | 'name'>
      ), client?: Maybe<(
        { __typename?: 'Clients_Client' }
        & Pick<Clients_Client, 'id' | 'name'>
      )>, company?: Maybe<(
        { __typename?: 'Company_Company' }
        & Pick<Company_Company, 'name'>
      )>, default_contact?: Maybe<(
        { __typename?: 'Clients_Contact' }
        & Pick<Clients_Contact, 'id' | 'full_name'>
      )> }
    )> }
  )>, status: (
    { __typename?: 'Choices_Status' }
    & Pick<Choices_Status, 'id' | 'status'>
  ), estimated_total?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, terms?: Maybe<(
    { __typename?: 'Clients_Terms' }
    & Pick<Clients_Terms, 'id' | 'name'>
  )> }
);

export type SaleForDeleteFragment = (
  { __typename?: 'Sales_Sale' }
  & Pick<Sales_Sale, 'id'>
  & { st_items?: Maybe<Array<(
    { __typename?: 'Sales_SoldItems' }
    & Pick<Sales_SoldItems, 'id' | 'line_number'>
    & { item?: Maybe<(
      { __typename?: 'Inventory_Item' }
      & Pick<Inventory_Item, 'id'>
    )> }
  )>>, shipment_order?: Maybe<(
    { __typename?: 'Shipping_ShipmentOrder' }
    & Pick<Shipping_ShipmentOrder, 'id'>
    & { shipments?: Maybe<Array<(
      { __typename?: 'Shipping_Shipment' }
      & Pick<Shipping_Shipment, 'id'>
    )>> }
  )> }
);

export type OrderInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type OrderInfoQuery = (
  { __typename?: 'Query' }
  & { services?: Maybe<Array<Maybe<(
    { __typename?: 'Shipping_ServiceLevel' }
    & Pick<Shipping_ServiceLevel, 'id' | 'easypost_name' | 'order_ready_by'>
    & { sub2title: Shipping_ServiceLevel['order_arrive_by'], title: Shipping_ServiceLevel['service'] }
    & { shipper: (
      { __typename?: 'Shipping_Carrier' }
      & Pick<Shipping_Carrier, 'id' | 'name' | 'easypost_name'>
    ) }
  )>>>, terms?: Maybe<Array<Maybe<(
    { __typename?: 'Clients_Terms' }
    & { id: Clients_Terms['id'], subtitle: Clients_Terms['description'], title: Clients_Terms['name'] }
  )>>>, users?: Maybe<Array<Maybe<(
    { __typename?: 'Users_User' }
    & { id: Users_User['id'], subtitle: Users_User['full_name'], title: Users_User['initials'] }
  )>>> }
);

export type GetSaleForEditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSaleForEditQuery = (
  { __typename?: 'Query' }
  & { sales_sale?: Maybe<(
    { __typename?: 'Sales_Sale' }
    & SaleForEditFragment
  )> }
);

export type GetSalesForDeleteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSalesForDeleteQuery = (
  { __typename?: 'Query' }
  & { sales_sale?: Maybe<(
    { __typename?: 'Sales_Sale' }
    & SaleForDeleteFragment
  )> }
);

export type CustomsItemsQueryQueryVariables = Exact<{
  id: Scalars['Any'];
}>;


export type CustomsItemsQueryQuery = (
  { __typename?: 'Query' }
  & { items?: Maybe<Array<Maybe<(
    { __typename?: 'Sales_SoldItems' }
    & Pick<Sales_SoldItems, 'id'>
    & { part: (
      { __typename?: 'Parts_Part' }
      & Pick<Parts_Part, 'id' | 'pn' | 'description' | 'htsus_code' | 'hs_code' | 'type'>
    ), sold_for?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )> }
  )>>> }
);

export type SalesToProcessQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type SalesToProcessQuery = (
  { __typename?: 'Query' }
  & { orders?: Maybe<Array<Maybe<(
    { __typename?: 'Shipping_ShipmentOrder' }
    & Pick<Shipping_ShipmentOrder, 'id'>
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>>, attached_sale?: Maybe<(
      { __typename?: 'Sales_Sale' }
      & Pick<Sales_Sale, 'id'>
      & { assigned_to?: Maybe<(
        { __typename?: 'Users_User' }
        & Pick<Users_User, 'id' | 'initials'>
        & { profile?: Maybe<(
          { __typename?: 'Profile_Profile' }
          & Pick<Profile_Profile, 'id' | 'color'>
        )> }
      )>, rep?: Maybe<(
        { __typename?: 'Users_User' }
        & Pick<Users_User, 'id' | 'initials' | 'full_name' | 'email'>
      )>, status: (
        { __typename?: 'Choices_Status' }
        & Pick<Choices_Status, 'id' | 'status'>
      ) }
    )>, attached_purchase_rma?: Maybe<(
      { __typename?: 'RMA_PurchaseRMA' }
      & Pick<Rma_PurchaseRma, 'id'>
      & { status: (
        { __typename?: 'Choices_Status' }
        & Pick<Choices_Status, 'id' | 'status'>
      ), pt: (
        { __typename?: 'Purchases_Purchase' }
        & Pick<Purchases_Purchase, 'id'>
        & { rep?: Maybe<(
          { __typename?: 'Users_User' }
          & Pick<Users_User, 'id' | 'initials'>
        )> }
      ) }
    )>, attached_rma?: Maybe<(
      { __typename?: 'RMA_RMA' }
      & Pick<Rma_Rma, 'id'>
      & { status: (
        { __typename?: 'Choices_Status' }
        & Pick<Choices_Status, 'id' | 'status'>
      ), st: (
        { __typename?: 'Sales_Sale' }
        & Pick<Sales_Sale, 'id'>
        & { rep?: Maybe<(
          { __typename?: 'Users_User' }
          & Pick<Users_User, 'id' | 'initials'>
        )> }
      ) }
    )>, service?: Maybe<(
      { __typename?: 'Shipping_ServiceLevel' }
      & Pick<Shipping_ServiceLevel, 'id' | 'color' | 'service'>
      & { shipper: (
        { __typename?: 'Shipping_Carrier' }
        & Pick<Shipping_Carrier, 'id' | 'name'>
      ) }
    )> }
  )>>> }
);

export type Grid__GetSalesQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type Grid__GetSalesQuery = (
  { __typename?: 'Query' }
  & { sales?: Maybe<Array<Maybe<(
    { __typename?: 'Sales_Sale' }
    & Pick<Sales_Sale, 'contract_comment' | 'internal_comment'>
  )>>> }
);

export type GetSalesQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetSalesQuery = (
  { __typename?: 'Query' }
  & { sales?: Maybe<Array<Maybe<(
    { __typename?: 'Sales_Sale' }
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & SaleFragment
  )>>> }
);

export type SoldItemsQueryVariables = Exact<{
  id: Scalars['Any'];
}>;


export type SoldItemsQuery = (
  { __typename?: 'Query' }
  & { items?: Maybe<Array<Maybe<(
    { __typename?: 'Sales_SoldItems' }
    & SoldItemFragment
  )>>> }
);

export type SalesDetailsForRmaQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type SalesDetailsForRmaQuery = (
  { __typename?: 'Query' }
  & { order?: Maybe<(
    { __typename?: 'Sales_Sale' }
    & Pick<Sales_Sale, 'id'>
    & { client?: Maybe<(
      { __typename?: 'Clients_Client' }
      & Pick<Clients_Client, 'id' | 'name'>
    )> }
  )> }
);

export type SalesDetailsForCopyQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type SalesDetailsForCopyQuery = (
  { __typename?: 'Query' }
  & { sale?: Maybe<(
    { __typename?: 'Sales_Sale' }
    & Pick<Sales_Sale, 'id' | 'internal_comment' | 'contract_comment'>
    & { client?: Maybe<(
      { __typename?: 'Clients_Client' }
      & Client_ListFragment
    )>, contact?: Maybe<(
      { __typename?: 'Clients_Contact' }
      & Contact_ListFragment
    )>, st_items?: Maybe<Array<(
      { __typename?: 'Sales_SoldItems' }
      & { part: (
        { __typename?: 'Parts_Part' }
        & Pick<Parts_Part, 'id' | 'description' | 'pn'>
      ), sold_for?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragment
      )> }
    )>>, rep?: Maybe<(
      { __typename?: 'Users_User' }
      & ListUserFragment
    )>, terms?: Maybe<(
      { __typename?: 'Clients_Terms' }
      & Pick<Clients_Terms, 'id'>
    )> }
  )> }
);

export type GetSaleForPdfQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSaleForPdfQuery = (
  { __typename?: 'Query' }
  & { sale?: Maybe<(
    { __typename?: 'Sales_Sale' }
    & SaleForPdfFragment
  )> }
);

export type GetPurchaseForPdfQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPurchaseForPdfQuery = (
  { __typename?: 'Query' }
  & { purchases_purchase?: Maybe<(
    { __typename?: 'Purchases_Purchase' }
    & PurchaseForPdfFragment
  )> }
);

export type SaleForMoneyUpdateFragment = (
  { __typename?: 'Sales_Sale' }
  & Pick<Sales_Sale, 'id'>
  & { estimated_cost?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, estimated_margin?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, st_items?: Maybe<Array<(
    { __typename?: 'Sales_SoldItems' }
    & Pick<Sales_SoldItems, 'id'>
    & { sold_for?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )>, item?: Maybe<(
      { __typename?: 'Inventory_Item' }
      & Pick<Inventory_Item, 'id'>
      & { purchases_items_details?: Maybe<(
        { __typename?: 'Purchases_PurchasedItems' }
        & Pick<Purchases_PurchasedItems, 'id'>
        & { original_cost?: Maybe<(
          { __typename?: 'Money' }
          & MoneyFragment
        )> }
      )> }
    )> }
  )>> }
);

export type SalesDetailsForMoneyUpdateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SalesDetailsForMoneyUpdateQuery = (
  { __typename?: 'Query' }
  & { sales_sale?: Maybe<(
    { __typename?: 'Sales_Sale' }
    & SaleForMoneyUpdateFragment
  )> }
);

export type GetSaleNextLineNumberQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSaleNextLineNumberQuery = (
  { __typename?: 'Query' }
  & { sale?: Maybe<(
    { __typename?: 'Sales_Sale' }
    & Pick<Sales_Sale, 'id'>
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
  )> }
);

export type GetSaleQueryVariables = Exact<{
  id: Scalars['ID'];
  annotations?: Maybe<Array<Maybe<AnnotationObjectInput>> | Maybe<AnnotationObjectInput>>;
}>;


export type GetSaleQuery = (
  { __typename?: 'Query' }
  & { sale?: Maybe<(
    { __typename?: 'Sales_Sale' }
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & SaleFragment
  )> }
);

export type GetSaleDetailsToMakeShipmentOrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSaleDetailsToMakeShipmentOrderQuery = (
  { __typename?: 'Query' }
  & { order?: Maybe<(
    { __typename?: 'Sales_Sale' }
    & Pick<Sales_Sale, 'id'>
    & { client?: Maybe<(
      { __typename?: 'Clients_Client' }
      & Pick<Clients_Client, 'id'>
    )> }
  )> }
);

export type GetClientSalesQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetClientSalesQuery = (
  { __typename?: 'Query' }
  & { sales?: Maybe<Array<Maybe<(
    { __typename?: 'Sales_Sale' }
    & Pick<Sales_Sale, 'id' | 'sale_date'>
    & { estimated_cost?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )>, estimated_margin?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )>, rep?: Maybe<(
      { __typename?: 'Users_User' }
      & Pick<Users_User, 'id' | 'initials'>
    )>, estimated_total?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )> }
  )>>> }
);

export type GetItemCountForSaleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetItemCountForSaleQuery = (
  { __typename?: 'Query' }
  & { count?: Maybe<(
    { __typename?: 'Sales_Sale' }
    & Pick<Sales_Sale, 'id'>
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
  )> }
);

export type GetPurchaseForEditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPurchaseForEditQuery = (
  { __typename?: 'Query' }
  & { purchases_purchase?: Maybe<(
    { __typename?: 'Purchases_Purchase' }
    & PurchaseForEditFragment
  )> }
);

export type Grid__GetPurchasesQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type Grid__GetPurchasesQuery = (
  { __typename?: 'Query' }
  & { purchases?: Maybe<Array<Maybe<(
    { __typename?: 'Purchases_Purchase' }
    & Pick<Purchases_Purchase, 'internal_comment' | 'contract_comment'>
  )>>> }
);

export type GetPurchasesQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetPurchasesQuery = (
  { __typename?: 'Query' }
  & { purchases?: Maybe<Array<Maybe<(
    { __typename?: 'Purchases_Purchase' }
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & PurchaseFragment
  )>>> }
);

export type GetPurchaseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPurchaseQuery = (
  { __typename?: 'Query' }
  & { purchases_purchase?: Maybe<(
    { __typename?: 'Purchases_Purchase' }
    & { pt_items?: Maybe<Array<(
      { __typename?: 'Purchases_PurchasedItems' }
      & Pick<Purchases_PurchasedItems, 'id' | 'line_number'>
    )>> }
    & PurchaseFragment
  )> }
);

export type GetPurchseDetailsToMakeShipmentOrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPurchseDetailsToMakeShipmentOrderQuery = (
  { __typename?: 'Query' }
  & { order?: Maybe<(
    { __typename?: 'Purchases_Purchase' }
    & Pick<Purchases_Purchase, 'id'>
    & { client?: Maybe<(
      { __typename?: 'Clients_Client' }
      & Pick<Clients_Client, 'id'>
    )> }
  )> }
);

export type PurchaseItemsQueryVariables = Exact<{
  id: Scalars['Any'];
}>;


export type PurchaseItemsQuery = (
  { __typename?: 'Query' }
  & { items?: Maybe<Array<Maybe<(
    { __typename?: 'Purchases_PurchasedItems' }
    & PurchasedItemFragment
  )>>> }
);

export type GetPurchaseItemsStatusQueryVariables = Exact<{
  id: Scalars['Any'];
}>;


export type GetPurchaseItemsStatusQuery = (
  { __typename?: 'Query' }
  & { items?: Maybe<Array<Maybe<(
    { __typename?: 'Inventory_Item' }
    & Pick<Inventory_Item, 'id' | 'receive_status'>
  )>>> }
);

export type PurchaseItemsToReceiveQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type PurchaseItemsToReceiveQuery = (
  { __typename?: 'Query' }
  & { items?: Maybe<Array<Maybe<(
    { __typename?: 'Purchases_PurchasedItems' }
    & Pick<Purchases_PurchasedItems, 'id' | 'line_number'>
    & { transaction: (
      { __typename?: 'Purchases_Purchase' }
      & Pick<Purchases_Purchase, 'id'>
    ), original_cost?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )>, item?: Maybe<(
      { __typename?: 'Inventory_Item' }
      & Pick<Inventory_Item, 'id' | 'serial_number' | 'receive_status' | 'locked' | 'hot' | 'is_lost'>
      & { broke_down_items?: Maybe<(
        { __typename?: 'Breakdown_BreakDownItems' }
        & Pick<Breakdown_BreakDownItems, 'id'>
        & { break_down: (
          { __typename?: 'Breakdown_BreakDown' }
          & Pick<Breakdown_BreakDown, 'id'>
        ) }
      )>, location?: Maybe<(
        { __typename?: 'Location_Location' }
        & Pick<Location_Location, 'id' | 'name'>
        & { site: (
          { __typename?: 'Location_Site' }
          & Pick<Location_Site, 'id' | 'name'>
        ) }
      )>, saleItem?: Maybe<(
        { __typename?: 'Sales_SoldItems' }
        & Pick<Sales_SoldItems, 'id'>
        & { transaction: (
          { __typename?: 'Sales_Sale' }
          & Pick<Sales_Sale, 'id'>
        ) }
      )>, country_of_origin?: Maybe<(
        { __typename?: 'CountryObject' }
        & Pick<CountryObject, 'code' | 'name'>
        & { flag: CountryObject['unicode_flag'] }
      )>, test_status?: Maybe<Array<(
        { __typename?: 'Choices_Tag' }
        & Pick<Choices_Tag, 'id' | 'tag' | 'color'>
      )>>, part?: Maybe<(
        { __typename?: 'Parts_Part' }
        & Pick<Parts_Part, 'id' | 'pn' | 'description'>
      )> }
    )> }
  )>>> }
);

export type PurchaseDetailsForRmaQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type PurchaseDetailsForRmaQuery = (
  { __typename?: 'Query' }
  & { order?: Maybe<(
    { __typename?: 'Purchases_Purchase' }
    & Pick<Purchases_Purchase, 'id'>
    & { client?: Maybe<(
      { __typename?: 'Clients_Client' }
      & Pick<Clients_Client, 'id' | 'name'>
    )> }
  )> }
);

export type GetClientPurchasesQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetClientPurchasesQuery = (
  { __typename?: 'Query' }
  & { purchases?: Maybe<Array<Maybe<(
    { __typename?: 'Purchases_Purchase' }
    & Pick<Purchases_Purchase, 'id' | 'purchase_date'>
    & { rep?: Maybe<(
      { __typename?: 'Users_User' }
      & Pick<Users_User, 'id' | 'initials'>
    )>, estimated_total?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )> }
  )>>> }
);

export type ValidatePtQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ValidatePtQuery = (
  { __typename?: 'Query' }
  & { purchase?: Maybe<(
    { __typename?: 'Purchases_Purchase' }
    & Pick<Purchases_Purchase, 'id'>
  )> }
);

export type CreatePurchaseMutationVariables = Exact<{
  input: Create__Purchases_Purchase__Input;
}>;


export type CreatePurchaseMutation = (
  { __typename?: 'Mutation' }
  & { order?: Maybe<(
    { __typename?: 'Purchases_Purchase' }
    & Pick<Purchases_Purchase, 'id'>
  )> }
);

export type UpdatePurchaseMutationVariables = Exact<{
  input: Update__Purchases_Purchase__Input;
}>;


export type UpdatePurchaseMutation = (
  { __typename?: 'Mutation' }
  & { Update__Purchases_Purchase?: Maybe<(
    { __typename?: 'Purchases_Purchase' }
    & PurchaseFragment
  )> }
);

export type ApprovePurchaseMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ApprovePurchaseMutation = (
  { __typename?: 'Mutation' }
  & { purchase?: Maybe<(
    { __typename?: 'Purchases_Purchase' }
    & Pick<Purchases_Purchase, 'id' | 'approval_date'>
    & { status: (
      { __typename?: 'Choices_Status' }
      & Pick<Choices_Status, 'id' | 'status'>
    ), approved_by?: Maybe<(
      { __typename?: 'Users_User' }
      & Pick<Users_User, 'id' | 'initials'>
    )> }
  )> }
);

export type UnApprovePurchaseMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnApprovePurchaseMutation = (
  { __typename?: 'Mutation' }
  & { purchase?: Maybe<(
    { __typename?: 'Purchases_Purchase' }
    & Pick<Purchases_Purchase, 'id' | 'approval_date'>
    & { status: (
      { __typename?: 'Choices_Status' }
      & Pick<Choices_Status, 'id' | 'status'>
    ), approved_by?: Maybe<(
      { __typename?: 'Users_User' }
      & Pick<Users_User, 'id' | 'initials'>
    )> }
  )> }
);

export type UpdatePurchaseOrderForCommentMutationVariables = Exact<{
  input: Update__Purchases_Purchase__Input;
}>;


export type UpdatePurchaseOrderForCommentMutation = (
  { __typename?: 'Mutation' }
  & { comment?: Maybe<(
    { __typename?: 'Purchases_Purchase' }
    & PurchaseFragment
  )> }
);

export type UpdateSalesOrderMutationVariables = Exact<{
  input: Update__Sales_Sale__Input;
}>;


export type UpdateSalesOrderMutation = (
  { __typename?: 'Mutation' }
  & { comment?: Maybe<(
    { __typename?: 'Sales_Sale' }
    & SaleFragment
  )> }
);

export type UpdatePurchaseOrderStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  status: Scalars['ID'];
}>;


export type UpdatePurchaseOrderStatusMutation = (
  { __typename?: 'Mutation' }
  & { purchase?: Maybe<(
    { __typename?: 'Purchases_Purchase' }
    & Pick<Purchases_Purchase, 'id'>
    & { status: (
      { __typename?: 'Choices_Status' }
      & Pick<Choices_Status, 'id' | 'status' | 'status_type'>
    ) }
  )> }
);

export type DeletePurchasedItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePurchasedItemMutation = (
  { __typename?: 'Mutation' }
  & { Delete__Purchases_PurchasedItems?: Maybe<(
    { __typename?: 'DBActionStatus' }
    & Pick<DbActionStatus, 'id'>
  )> }
);

export type DeleteSoldItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSoldItemMutation = (
  { __typename?: 'Mutation' }
  & { Delete__Sales_SoldItems?: Maybe<(
    { __typename?: 'DBActionStatus' }
    & Pick<DbActionStatus, 'id'>
  )> }
);

export type CreateSoldItemsMutationVariables = Exact<{
  items: Array<Create__Sales_SoldItems__Input> | Create__Sales_SoldItems__Input;
}>;


export type CreateSoldItemsMutation = (
  { __typename?: 'Mutation' }
  & { items?: Maybe<Array<Maybe<(
    { __typename?: 'Sales_SoldItems' }
    & SoldItemFragment
  )>>> }
);

export type CreateSaleMutationVariables = Exact<{
  input: Create__Sales_Sale__Input;
}>;


export type CreateSaleMutation = (
  { __typename?: 'Mutation' }
  & { Create__Sales_Sale?: Maybe<(
    { __typename?: 'Sales_Sale' }
    & SaleFragment
  )> }
);

export type UpdateSaleMutationVariables = Exact<{
  input: Update__Sales_Sale__Input;
}>;


export type UpdateSaleMutation = (
  { __typename?: 'Mutation' }
  & { order?: Maybe<(
    { __typename?: 'Sales_Sale' }
    & SaleFragment
  )> }
);

export type UpdateSaleMoneyMutationVariables = Exact<{
  input: Update__Sales_Sale__Input;
}>;


export type UpdateSaleMoneyMutation = (
  { __typename?: 'Mutation' }
  & { order?: Maybe<(
    { __typename?: 'Sales_Sale' }
    & Pick<Sales_Sale, 'id'>
    & { estimated_margin?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )>, estimated_cost?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )> }
  )> }
);

export type DeleteSaleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSaleMutation = (
  { __typename?: 'Mutation' }
  & { Delete__Sales_Sale?: Maybe<(
    { __typename?: 'DBActionStatus' }
    & Pick<DbActionStatus, 'id'>
  )> }
);

export type AllocateSoldItemsMutationVariables = Exact<{
  input: Array<Update__Sales_SoldItems__Input> | Update__Sales_SoldItems__Input;
}>;


export type AllocateSoldItemsMutation = (
  { __typename?: 'Mutation' }
  & { items?: Maybe<Array<Maybe<(
    { __typename?: 'Sales_SoldItems' }
    & SoldItemFragment
  )>>> }
);

export type CreatePurchasedItemMutationVariables = Exact<{
  items: Array<Create__Purchases_PurchasedItems__Input> | Create__Purchases_PurchasedItems__Input;
}>;


export type CreatePurchasedItemMutation = (
  { __typename?: 'Mutation' }
  & { items?: Maybe<Array<Maybe<(
    { __typename?: 'Purchases_PurchasedItems' }
    & PurchasedItemFragment
  )>>> }
);

export type UpdatePurchasedItemsMutationVariables = Exact<{
  input: Array<Update__Purchases_PurchasedItems__Input> | Update__Purchases_PurchasedItems__Input;
}>;


export type UpdatePurchasedItemsMutation = (
  { __typename?: 'Mutation' }
  & { Update__Purchases_PurchasedItems?: Maybe<Array<Maybe<(
    { __typename?: 'Purchases_PurchasedItems' }
    & PurchasedItemFragment
  )>>> }
);

export type CreateAltPartLinkMutationVariables = Exact<{
  linker: Scalars['ID'];
  linkee: Scalars['ID'];
  clientId: Scalars['ID'];
}>;


export type CreateAltPartLinkMutation = (
  { __typename?: 'Mutation' }
  & { Update__Parts_Part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & FullPart_Parts_Part_Fragment
  )> }
);

export type UpdatePartMutationVariables = Exact<{
  input: Update__Parts_Part__Input;
}>;


export type UpdatePartMutation = (
  { __typename?: 'Mutation' }
  & { Update__Parts_Part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & FullPart_Parts_Part_Fragment
  )> }
);

export type MasterPartSummaryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MasterPartSummaryQuery = (
  { __typename?: 'Query' }
  & { parts_part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & FullPart_Parts_Part_Fragment
  )> }
);

export type RoleFragment = (
  { __typename?: 'CustomPermissions_Role' }
  & Pick<CustomPermissions_Role, 'id' | 'name' | 'description'>
);

export type DepartmentFragment = (
  { __typename?: 'Department_Department' }
  & Pick<Department_Department, 'id' | 'name' | 'description'>
);

export type GraphQlPermissionsFragment = (
  { __typename?: 'CustomPermissions_GraphQLPermissions' }
  & Pick<CustomPermissions_GraphQlPermissions, 'id' | 'name' | 'name_type'>
  & { roles?: Maybe<Array<(
    { __typename?: 'CustomPermissions_RolePermissions' }
    & Pick<CustomPermissions_RolePermissions, 'id'>
    & { role: (
      { __typename?: 'CustomPermissions_Role' }
      & RoleFragment
    ) }
  )>> }
);

export type RolePermissionFragment = (
  { __typename?: 'CustomPermissions_RolePermissions' }
  & Pick<CustomPermissions_RolePermissions, 'id'>
  & { permission: (
    { __typename?: 'CustomPermissions_GraphQLPermissions' }
    & GraphQlPermissionsFragment
  ), role: (
    { __typename?: 'CustomPermissions_Role' }
    & RoleFragment
  ) }
);

export type User_GridFragment = (
  { __typename?: 'Users_User' }
  & Pick<Users_User, 'id' | 'full_name' | 'first_name' | 'last_name' | 'last_login' | 'email' | 'is_active'>
  & { profile?: Maybe<(
    { __typename?: 'Profile_Profile' }
    & Pick<Profile_Profile, 'id' | 'commission_percentage'>
  )> }
);

export type GetGqlPermissionsQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetGqlPermissionsQuery = (
  { __typename?: 'Query' }
  & { custom_permissions_graphql_permissions?: Maybe<Array<Maybe<(
    { __typename?: 'CustomPermissions_GraphQLPermissions' }
    & GraphQlPermissionsFragment
  )>>> }
);

export type GetRolesForPermissionsQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetRolesForPermissionsQuery = (
  { __typename?: 'Query' }
  & { custom_permissions_roles?: Maybe<Array<Maybe<(
    { __typename?: 'CustomPermissions_Role' }
    & RoleFragment
  )>>> }
);

export type GetUsersForPermissionsQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetUsersForPermissionsQuery = (
  { __typename?: 'Query' }
  & { users_users?: Maybe<Array<Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'full_name' | 'first_name' | 'last_name' | 'last_login' | 'email'>
    & { profile?: Maybe<(
      { __typename?: 'Profile_Profile' }
      & Pick<Profile_Profile, 'id' | 'commission_percentage'>
    )>, departments_m2m?: Maybe<Array<(
      { __typename?: 'Department_DepartmentEmployees' }
      & Pick<Department_DepartmentEmployees, 'id'>
      & { department: (
        { __typename?: 'Department_Department' }
        & Pick<Department_Department, 'id' | 'description' | 'name'>
      ) }
    )>> }
  )>>> }
);

export type GetUsers_GridQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetUsers_GridQuery = (
  { __typename?: 'Query' }
  & { users_users?: Maybe<Array<Maybe<(
    { __typename?: 'Users_User' }
    & User_GridFragment
  )>>> }
);

export type GetDepartmentsForPermissionsQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetDepartmentsForPermissionsQuery = (
  { __typename?: 'Query' }
  & { department_departments?: Maybe<Array<Maybe<(
    { __typename?: 'Department_Department' }
    & DepartmentFragment
  )>>> }
);

export type GetProductCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductCompaniesQuery = (
  { __typename?: 'Query' }
  & { parts_product_companys?: Maybe<Array<Maybe<(
    { __typename?: 'Parts_ProductCompany' }
    & Pick<Parts_ProductCompany, 'id' | 'name' | 'short_name'>
  )>>> }
);

export type Profile_Profile__FullFragment = (
  { __typename?: 'Profile_Profile' }
  & Pick<Profile_Profile, 'color' | 'column_config' | 'commission_percentage' | 'hire_date' | 'id' | 'job_title' | 'last_review' | 'order_cache' | 'settings' | 'terminated' | 'terminated_date' | 'upcoming_review' | 'wage'>
  & { default_site?: Maybe<(
    { __typename?: 'Location_Site' }
    & Pick<Location_Site, 'id' | 'name'>
  )>, manager?: Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'full_name' | 'initials'>
  )>, user: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'full_name' | 'initials'>
  ) }
);

export type GetLiveCommentsQueryVariables = Exact<{
  id: Scalars['Any'];
}>;


export type GetLiveCommentsQuery = (
  { __typename?: 'Query' }
  & { templates?: Maybe<Array<Maybe<(
    { __typename?: 'Profile_LiveTemplate' }
    & Pick<Profile_LiveTemplate, 'id' | 'name'>
    & { comment: Profile_LiveTemplate['template'] }
    & { creator: (
      { __typename?: 'Users_User' }
      & Pick<Users_User, 'id' | 'initials'>
    ) }
  )>>> }
);

export type LoginDataQueryVariables = Exact<{ [key: string]: never; }>;


export type LoginDataQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'date_joined' | 'email' | 'first_name' | 'full_name' | 'initials' | 'is_active' | 'is_staff' | 'is_superuser' | 'last_login' | 'last_name' | 'username'>
    & { profile?: Maybe<(
      { __typename?: 'Profile_Profile' }
      & Profile_Profile__FullFragment
    )> }
  )>, company_company?: Maybe<(
    { __typename?: 'Company_Company' }
    & Pick<Company_Company, 'name' | 'short_name'>
  )> }
);

export type OtherLoginDataQueryVariables = Exact<{ [key: string]: never; }>;


export type OtherLoginDataQuery = (
  { __typename?: 'Query' }
  & { company_company?: Maybe<(
    { __typename?: 'Company_Company' }
    & Pick<Company_Company, 'logo'>
    & { default_return_to_address?: Maybe<(
      { __typename?: 'Address_Address' }
      & Pick<Address_Address, 'id'>
    )> }
  )>, statuses?: Maybe<Array<Maybe<(
    { __typename?: 'Choices_Status' }
    & Pick<Choices_Status, 'id' | 'order' | 'status' | 'status_type'>
    & { app: Choices_Status['app_name'], field: Choices_Status['field_name'], model: Choices_Status['model_name'] }
  )>>>, tags?: Maybe<Array<Maybe<(
    { __typename?: 'Choices_Tag' }
    & Pick<Choices_Tag, 'id' | 'tag' | 'description' | 'color'>
  )>>> }
);

export type ChangeProfileColorMutationVariables = Exact<{
  color?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
}>;


export type ChangeProfileColorMutation = (
  { __typename?: 'Mutation' }
  & { profile?: Maybe<(
    { __typename?: 'Profile_Profile' }
    & Pick<Profile_Profile, 'id' | 'color'>
  )> }
);

export type DeleteTableConfigMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTableConfigMutation = (
  { __typename?: 'Mutation' }
  & { Delete__Profile_TableConfig?: Maybe<(
    { __typename?: 'DBActionStatus' }
    & Pick<DbActionStatus, 'id'>
  )> }
);

export type UpdateProfileSettingsMutationVariables = Exact<{
  input: Update__Profile_Profile__Input;
}>;


export type UpdateProfileSettingsMutation = (
  { __typename?: 'Mutation' }
  & { profile?: Maybe<(
    { __typename?: 'Profile_Profile' }
    & Profile_Profile__FullFragment
  )> }
);

export type QuotePartFragment = (
  { __typename?: 'Quotes_QuoteParts' }
  & Pick<Quotes_QuoteParts, 'id' | 'qty'>
  & { creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials'>
  ), part?: Maybe<(
    { __typename?: 'Parts_Part' }
    & Pick<Parts_Part, 'id' | 'pn' | 'description'>
  )>, price_per_part?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, total?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, quote: (
    { __typename?: 'Quotes_Quote' }
    & Pick<Quotes_Quote, 'id'>
    & { creator: (
      { __typename?: 'Users_User' }
      & Pick<Users_User, 'id' | 'initials'>
    ) }
  ) }
);

export type QuoteForSaleImportFragment = (
  { __typename?: 'Quotes_Quote' }
  & Pick<Quotes_Quote, 'id' | 'contract_comment' | 'internal_comment'>
  & { creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials' | 'full_name'>
  ), client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id' | 'name' | 'short_name'>
    & { credit_limit?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )>, internal_rep?: Maybe<(
      { __typename?: 'Users_User' }
      & Pick<Users_User, 'id' | 'initials'>
    )> }
  )>, contact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Pick<Clients_Contact, 'id' | 'full_name' | 'email'>
    & { owner: (
      { __typename?: 'Clients_Client' }
      & Pick<Clients_Client, 'id' | 'name'>
    ) }
  )>, parts?: Maybe<Array<(
    { __typename?: 'Quotes_QuoteParts' }
    & QuotePartFragment
  )>> }
);

export type QuoteForPdfFragment = (
  { __typename?: 'Quotes_Quote' }
  & Pick<Quotes_Quote, 'creation_date'>
  & { ship_to_address?: Maybe<(
    { __typename?: 'Address_Address' }
    & AddressGridFragment
  )>, ship_from_address?: Maybe<(
    { __typename?: 'Address_Address' }
    & AddressGridFragment
  )> }
  & QuoteForSaleImportFragment
);

export type QuoteFragment = (
  { __typename?: 'Quotes_Quote' }
  & Pick<Quotes_Quote, 'id' | 'internal_comment' | 'contract_comment' | 'creation_date'>
  & { attached_sales?: Maybe<Array<(
    { __typename?: 'Sales_Sale' }
    & Pick<Sales_Sale, 'id'>
    & { estimated_total?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFragment
    )>, rep?: Maybe<(
      { __typename?: 'Users_User' }
      & Pick<Users_User, 'id' | 'initials'>
    )> }
  )>>, client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id' | 'name'>
  )>, contact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Pick<Clients_Contact, 'id' | 'full_name' | 'email'>
  )>, creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials'>
  ), total_price?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )> }
);

export type GetQuotesQueryVariables = Exact<{
  id: Scalars['Any'];
}>;


export type GetQuotesQuery = (
  { __typename?: 'Query' }
  & { quotes?: Maybe<Array<Maybe<(
    { __typename?: 'Quotes_Quote' }
    & Pick<Quotes_Quote, 'id'>
    & { contact?: Maybe<(
      { __typename?: 'Clients_Contact' }
      & Pick<Clients_Contact, 'id' | 'full_name'>
    )> }
  )>>> }
);

export type QuotePartsForQuoteQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type QuotePartsForQuoteQuery = (
  { __typename?: 'Query' }
  & { quote_parts?: Maybe<Array<Maybe<(
    { __typename?: 'Quotes_QuoteParts' }
    & QuotePartFragment
  )>>> }
);

export type GetQuoteForSaleImportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetQuoteForSaleImportQuery = (
  { __typename?: 'Query' }
  & { quote?: Maybe<(
    { __typename?: 'Quotes_Quote' }
    & QuoteForSaleImportFragment
  )> }
);

export type QuotesQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type QuotesQuery = (
  { __typename?: 'Query' }
  & { quotes?: Maybe<Array<Maybe<(
    { __typename?: 'Quotes_Quote' }
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & QuoteFragment
  )>>> }
);

export type QuoteForPdfQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type QuoteForPdfQuery = (
  { __typename?: 'Query' }
  & { quotes_quote?: Maybe<(
    { __typename?: 'Quotes_Quote' }
    & QuoteForPdfFragment
  )> }
);

export type CreateQuotePartMutationVariables = Exact<{
  items: Array<Create__Quotes_QuoteParts__Input> | Create__Quotes_QuoteParts__Input;
}>;


export type CreateQuotePartMutation = (
  { __typename?: 'Mutation' }
  & { parts?: Maybe<Array<Maybe<(
    { __typename?: 'Quotes_QuoteParts' }
    & QuotePartFragment
  )>>> }
);

export type UpdateQuoteForCommentMutationVariables = Exact<{
  input: Update__Quotes_Quote__Input;
}>;


export type UpdateQuoteForCommentMutation = (
  { __typename?: 'Mutation' }
  & { comment?: Maybe<(
    { __typename?: 'Quotes_Quote' }
    & Pick<Quotes_Quote, 'id'>
  )> }
);

export type PurchaseRmaItemFragment = (
  { __typename?: 'RMA_PurchaseRMAItems' }
  & Pick<Rma_PurchaseRmaItems, 'id' | 'credit'>
  & { item: (
    { __typename?: 'Inventory_Item' }
    & { purchase?: Maybe<(
      { __typename?: 'Purchases_PurchasedItems' }
      & Pick<Purchases_PurchasedItems, 'id' | 'line_number'>
      & { transaction: (
        { __typename?: 'Purchases_Purchase' }
        & Pick<Purchases_Purchase, 'id'>
      ) }
    )>, part?: Maybe<(
      { __typename?: 'Parts_Part' }
      & Pick<Parts_Part, 'id' | 'pn' | 'description'>
    )> }
    & InventoryItemFragment
  ), rmaTested?: Maybe<(
    { __typename?: 'Choices_Choice' }
    & Pick<Choices_Choice, 'id' | 'choice'>
  )> }
);

export type SaleRmaItemFragment = (
  { __typename?: 'RMA_RMAItems' }
  & Pick<Rma_RmaItems, 'id' | 'creation_date' | 'updated_date'>
  & { tested_condition?: Maybe<(
    { __typename?: 'Choices_Choice' }
    & Pick<Choices_Choice, 'id' | 'choice' | 'sub_choice'>
  )>, item: (
    { __typename?: 'Inventory_Item' }
    & { sale?: Maybe<(
      { __typename?: 'Sales_SoldItems' }
      & Pick<Sales_SoldItems, 'id' | 'line_number'>
      & { transaction: (
        { __typename?: 'Sales_Sale' }
        & Pick<Sales_Sale, 'id'>
      ) }
    )>, part?: Maybe<(
      { __typename?: 'Parts_Part' }
      & Pick<Parts_Part, 'id' | 'pn' | 'description'>
    )> }
    & InventoryItemFragment
  ) }
);

export type GetRmaForPdfQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetRmaForPdfQuery = (
  { __typename?: 'Query' }
  & { rma_rma?: Maybe<(
    { __typename?: 'RMA_RMA' }
    & Pick<Rma_Rma, 'id' | 'return_reason'>
    & { shipment_order?: Maybe<(
      { __typename?: 'Shipping_ShipmentOrder' }
      & Pick<Shipping_ShipmentOrder, 'id' | 'blind'>
      & { purchaser_contact?: Maybe<(
        { __typename?: 'Clients_Contact' }
        & Contact_ListFragment
      )>, purchaser_address?: Maybe<(
        { __typename?: 'Address_Address' }
        & Pick<Address_Address, 'id' | 'street_1' | 'street_2' | 'city' | 'state' | 'zip_code'>
        & { country: (
          { __typename?: 'CountryObject' }
          & Pick<CountryObject, 'code' | 'name'>
        ), company?: Maybe<(
          { __typename?: 'Company_Company' }
          & Pick<Company_Company, 'name'>
        )>, client?: Maybe<(
          { __typename?: 'Clients_Client' }
          & Pick<Clients_Client, 'id' | 'name'>
        )>, default_contact?: Maybe<(
          { __typename?: 'Clients_Contact' }
          & Pick<Clients_Contact, 'id' | 'full_name'>
        )> }
      )>, ship_from_contact?: Maybe<(
        { __typename?: 'Clients_Contact' }
        & Contact_ListFragment
      )>, ship_from_address?: Maybe<(
        { __typename?: 'Address_Address' }
        & Pick<Address_Address, 'id' | 'street_1' | 'street_2' | 'city' | 'state' | 'zip_code'>
        & { country: (
          { __typename?: 'CountryObject' }
          & Pick<CountryObject, 'code' | 'name'>
        ), company?: Maybe<(
          { __typename?: 'Company_Company' }
          & Pick<Company_Company, 'name'>
        )>, client?: Maybe<(
          { __typename?: 'Clients_Client' }
          & Pick<Clients_Client, 'id' | 'name'>
        )>, default_contact?: Maybe<(
          { __typename?: 'Clients_Contact' }
          & Pick<Clients_Contact, 'id' | 'full_name'>
        )> }
      )>, ship_to_contact?: Maybe<(
        { __typename?: 'Clients_Contact' }
        & Contact_ListFragment
      )>, ship_to_address?: Maybe<(
        { __typename?: 'Address_Address' }
        & Pick<Address_Address, 'id' | 'street_1' | 'street_2' | 'city' | 'state' | 'zip_code'>
        & { country: (
          { __typename?: 'CountryObject' }
          & Pick<CountryObject, 'code' | 'name'>
        ), client?: Maybe<(
          { __typename?: 'Clients_Client' }
          & Pick<Clients_Client, 'id' | 'name'>
        )>, company?: Maybe<(
          { __typename?: 'Company_Company' }
          & Pick<Company_Company, 'name'>
        )>, default_contact?: Maybe<(
          { __typename?: 'Clients_Contact' }
          & Pick<Clients_Contact, 'id' | 'full_name'>
        )> }
      )> }
    )>, st: (
      { __typename?: 'Sales_Sale' }
      & Pick<Sales_Sale, 'id'>
      & { rep?: Maybe<(
        { __typename?: 'Users_User' }
        & Pick<Users_User, 'id' | 'full_name' | 'initials'>
      )> }
    ), rma_items?: Maybe<Array<(
      { __typename?: 'RMA_RMAItems' }
      & Pick<Rma_RmaItems, 'id' | 'credit'>
      & { returned_part: (
        { __typename?: 'Inventory_Item' }
        & Pick<Inventory_Item, 'id' | 'serial_number'>
        & { part?: Maybe<(
          { __typename?: 'Parts_Part' }
          & Pick<Parts_Part, 'id' | 'pn' | 'description'>
          & { mfgr?: Maybe<(
            { __typename?: 'Parts_ProductCompany' }
            & Pick<Parts_ProductCompany, 'id' | 'name'>
          )> }
        )>, sales_items_details?: Maybe<(
          { __typename?: 'Sales_SoldItems' }
          & Pick<Sales_SoldItems, 'id'>
          & { sold_for?: Maybe<(
            { __typename?: 'Money' }
            & MoneyFragment
          )> }
        )> }
      ) }
    )>>, return_code?: Maybe<(
      { __typename?: 'Choices_Choice' }
      & Pick<Choices_Choice, 'id' | 'choice' | 'sub_choice'>
    )> }
  )> }
);

export type GetPurchaseRmaItemsQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetPurchaseRmaItemsQuery = (
  { __typename?: 'Query' }
  & { purchase_rma_items?: Maybe<Array<Maybe<(
    { __typename?: 'RMA_PurchaseRMAItems' }
    & PurchaseRmaItemFragment
  )>>> }
);

export type GetSaleeRmaItemsQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetSaleeRmaItemsQuery = (
  { __typename?: 'Query' }
  & { rma_items?: Maybe<Array<Maybe<(
    { __typename?: 'RMA_RMAItems' }
    & SaleRmaItemFragment
  )>>> }
);

export type GridGetSalesRmaQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GridGetSalesRmaQuery = (
  { __typename?: 'Query' }
  & { rmas?: Maybe<Array<Maybe<(
    { __typename?: 'RMA_RMA' }
    & Pick<Rma_Rma, 'claim_date' | 'closed_date' | 'creation_date' | 'id' | 'received_date' | 'return_reason' | 'contract_comment' | 'internal_comment' | 'updated_date' | 'voided_date' | 'warranty_honored_after_cut_off'>
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>>, contact?: Maybe<(
      { __typename?: 'Clients_Contact' }
      & Pick<Clients_Contact, 'id' | 'full_name'>
    )>, creator: (
      { __typename?: 'Users_User' }
      & UserMinimalFragment
    ), st: (
      { __typename?: 'Sales_Sale' }
      & Pick<Sales_Sale, 'id'>
      & { client?: Maybe<(
        { __typename?: 'Clients_Client' }
        & Pick<Clients_Client, 'id' | 'name'>
      )> }
    ), return_code?: Maybe<(
      { __typename?: 'Choices_Choice' }
      & Pick<Choices_Choice, 'id' | 'choice'>
    )>, shipment_order?: Maybe<(
      { __typename?: 'Shipping_ShipmentOrder' }
      & ShipmentOrderDetailsFragment
    )>, status: (
      { __typename?: 'Choices_Status' }
      & Pick<Choices_Status, 'id' | 'status'>
    ) }
  )>>> }
);

export type GridGetPurchaseRmAsQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GridGetPurchaseRmAsQuery = (
  { __typename?: 'Query' }
  & { purchase_rmas?: Maybe<Array<Maybe<(
    { __typename?: 'RMA_PurchaseRMA' }
    & Pick<Rma_PurchaseRma, 'claim_date' | 'closed_date' | 'creation_date' | 'id' | 'received_date' | 'updated_date' | 'voided_date'>
    & { creator: (
      { __typename?: 'Users_User' }
      & UserMinimalFragment
    ), pt: (
      { __typename?: 'Purchases_Purchase' }
      & Pick<Purchases_Purchase, 'id'>
      & { client?: Maybe<(
        { __typename?: 'Clients_Client' }
        & Pick<Clients_Client, 'id' | 'name'>
      )> }
    ), shipment_order?: Maybe<(
      { __typename?: 'Shipping_ShipmentOrder' }
      & ShipmentOrderDetailsFragment
    )>, status: (
      { __typename?: 'Choices_Status' }
      & Pick<Choices_Status, 'id' | 'status' | 'status_type' | 'order'>
    ) }
  )>>> }
);

export type GetItemCountForRmaQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetItemCountForRmaQuery = (
  { __typename?: 'Query' }
  & { count?: Maybe<(
    { __typename?: 'RMA_RMA' }
    & Pick<Rma_Rma, 'id'>
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
  )> }
);

export type GetItemCountForPurchaseRmaQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetItemCountForPurchaseRmaQuery = (
  { __typename?: 'Query' }
  & { count?: Maybe<(
    { __typename?: 'RMA_PurchaseRMA' }
    & Pick<Rma_PurchaseRma, 'id'>
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
  )> }
);

export type CreatePurchaseRmaMutationVariables = Exact<{
  date?: Maybe<Scalars['Date']>;
  pt: Scalars['ID'];
  shipment?: Maybe<Scalars['ID']>;
}>;


export type CreatePurchaseRmaMutation = (
  { __typename?: 'Mutation' }
  & { rma?: Maybe<(
    { __typename?: 'RMA_PurchaseRMA' }
    & Pick<Rma_PurchaseRma, 'id'>
  )> }
);

export type CreatePurchaseRmaItemMutationVariables = Exact<{
  items: Array<Create__Rma_PurchaseRmaItems__Input> | Create__Rma_PurchaseRmaItems__Input;
}>;


export type CreatePurchaseRmaItemMutation = (
  { __typename?: 'Mutation' }
  & { items?: Maybe<Array<Maybe<(
    { __typename?: 'RMA_PurchaseRMAItems' }
    & Pick<Rma_PurchaseRmaItems, 'id'>
  )>>> }
);

export type CreateSaleRmaMutationVariables = Exact<{
  date: Scalars['Date'];
  st: Scalars['ID'];
  code: Scalars['ID'];
  reason: Scalars['String'];
  warranty: Scalars['Boolean'];
}>;


export type CreateSaleRmaMutation = (
  { __typename?: 'Mutation' }
  & { rma?: Maybe<(
    { __typename?: 'RMA_RMA' }
    & Pick<Rma_Rma, 'id'>
  )> }
);

export type CreateSaleRmaItemMutationVariables = Exact<{
  items: Array<Create__Rma_RmaItems__Input> | Create__Rma_RmaItems__Input;
}>;


export type CreateSaleRmaItemMutation = (
  { __typename?: 'Mutation' }
  & { item?: Maybe<Array<Maybe<(
    { __typename?: 'RMA_RMAItems' }
    & Pick<Rma_RmaItems, 'id'>
  )>>> }
);

export type DeleteRmaItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteRmaItemMutation = (
  { __typename?: 'Mutation' }
  & { Delete__RMA_RMAItems?: Maybe<(
    { __typename?: 'DBActionStatus' }
    & Pick<DbActionStatus, 'id'>
  )> }
);

export type UpdatePurchaseRmaStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  status: Scalars['ID'];
}>;


export type UpdatePurchaseRmaStatusMutation = (
  { __typename?: 'Mutation' }
  & { rma?: Maybe<(
    { __typename?: 'RMA_PurchaseRMA' }
    & Pick<Rma_PurchaseRma, 'id'>
    & { status: (
      { __typename?: 'Choices_Status' }
      & Pick<Choices_Status, 'id' | 'status' | 'status_type' | 'order'>
    ) }
  )> }
);

export type UpdateSaleRmaStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  status: Scalars['ID'];
}>;


export type UpdateSaleRmaStatusMutation = (
  { __typename?: 'Mutation' }
  & { rma?: Maybe<(
    { __typename?: 'RMA_RMA' }
    & Pick<Rma_Rma, 'id'>
    & { status: (
      { __typename?: 'Choices_Status' }
      & Pick<Choices_Status, 'id' | 'status' | 'status_type' | 'order'>
    ) }
  )> }
);

export type Reporting_Report__FullFragment = (
  { __typename?: 'Reporting_Report' }
  & Pick<Reporting_Report, 'id' | 'name' | 'description' | 'query' | 'chart_type' | 'data'>
  & { recipients?: Maybe<Array<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'email' | 'full_name' | 'initials'>
  )>> }
);

export type GetReports_FullQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetReports_FullQuery = (
  { __typename?: 'Query' }
  & { reporting_reports?: Maybe<Array<Maybe<(
    { __typename?: 'Reporting_Report' }
    & Reporting_Report__FullFragment
  )>>> }
);

export type CreateReportMutationVariables = Exact<{
  input: Create__Reporting_Report__Input;
}>;


export type CreateReportMutation = (
  { __typename?: 'Mutation' }
  & { Create__Reporting_Report?: Maybe<(
    { __typename?: 'Reporting_Report' }
    & Reporting_Report__FullFragment
  )> }
);

export type UpdateReportMutationVariables = Exact<{
  input: Update__Reporting_Report__Input;
}>;


export type UpdateReportMutation = (
  { __typename?: 'Mutation' }
  & { Update__Reporting_Report?: Maybe<(
    { __typename?: 'Reporting_Report' }
    & Reporting_Report__FullFragment
  )> }
);

export type DeleteReportMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteReportMutation = (
  { __typename?: 'Mutation' }
  & { Delete__Reporting_Report?: Maybe<(
    { __typename?: 'DBActionStatus' }
    & Pick<DbActionStatus, 'id'>
  )> }
);

export type IntrospectForFedexInputQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type IntrospectForFedexInputQuery = (
  { __typename?: 'Query' }
  & { __type?: Maybe<(
    { __typename?: '__Type' }
    & Pick<__Type, 'name'>
    & { inputFields?: Maybe<Array<(
      { __typename?: '__InputValue' }
      & Pick<__InputValue, 'name' | 'description'>
    )>> }
  )> }
);

export type Shipping_Shippment_FullFragment = (
  { __typename?: 'Shipping_Shipment' }
  & Pick<Shipping_Shipment, 'id' | 'box_number' | 'easypost_id'>
  & { index: Shipping_Shipment['box_number'], predefined: Shipping_Shipment['predefined_parcel'], length: Shipping_Shipment['parcel_length_in'], width: Shipping_Shipment['parcel_width_in'], height: Shipping_Shipment['parcel_height_in'], weight: Shipping_Shipment['parcel_weight_oz'] }
);

export type ShipmentOrderDetails_FullFragment = (
  { __typename?: 'Shipping_ShipmentOrder' }
  & Pick<Shipping_ShipmentOrder, 'id' | 'blind' | 'easypost_id' | 'pickup_type'>
  & { deliverBy: Shipping_ShipmentOrder['deliver_by_date'], isInternational: Shipping_ShipmentOrder['is_international'], release: Shipping_ShipmentOrder['release_number'], comments: Shipping_ShipmentOrder['shipping_comments'], tracking: Shipping_ShipmentOrder['tracking_number'], wePay: Shipping_ShipmentOrder['we_pay'] }
  & { account?: Maybe<(
    { __typename?: 'Shipping_Account' }
    & Pick<Shipping_Account, 'id' | 'name' | 'number' | 'easypost_id'>
    & { shipper: (
      { __typename?: 'Shipping_Carrier' }
      & Pick<Shipping_Carrier, 'id' | 'name'>
    ) }
  )>, assignee?: Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'full_name' | 'initials'>
  )>, sale?: Maybe<(
    { __typename?: 'Sales_Sale' }
    & Pick<Sales_Sale, 'id'>
    & { comments: Sales_Sale['internal_comment'] }
  )>, billTo?: Maybe<(
    { __typename?: 'Address_Address' }
    & AddressFullFragment
  )>, billToContact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Pick<Clients_Contact, 'id' | 'full_name' | 'email' | 'phone'>
  )>, returnTo?: Maybe<(
    { __typename?: 'Address_Address' }
    & AddressFullFragment
  )>, service?: Maybe<(
    { __typename?: 'Shipping_ServiceLevel' }
    & Pick<Shipping_ServiceLevel, 'id' | 'easypost_name' | 'service'>
    & { shipper: (
      { __typename?: 'Shipping_Carrier' }
      & Pick<Shipping_Carrier, 'id' | 'name'>
    ) }
  )>, shipFrom?: Maybe<(
    { __typename?: 'Address_Address' }
    & AddressFullFragment
  )>, shipFromContact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Pick<Clients_Contact, 'id' | 'full_name' | 'email' | 'phone'>
  )>, shipTo?: Maybe<(
    { __typename?: 'Address_Address' }
    & AddressFullFragment
  )>, shipToContact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Pick<Clients_Contact, 'id' | 'full_name' | 'email' | 'phone'>
  )>, shipments?: Maybe<Array<(
    { __typename?: 'Shipping_Shipment' }
    & Shipping_Shippment_FullFragment
  )>>, status: (
    { __typename?: 'Choices_Status' }
    & Pick<Choices_Status, 'id' | 'status'>
  ), logs?: Maybe<Array<(
    { __typename?: 'Logging_ActionLogObject' }
    & Pick<Logging_ActionLogObject, 'id'>
    & { action: (
      { __typename?: 'Logging_ActionLog' }
      & Pick<Logging_ActionLog, 'id' | 'action' | 'description'>
    ) }
  )>> }
);

export type ShipmentOrderDetailsFragment = (
  { __typename?: 'Shipping_ShipmentOrder' }
  & Pick<Shipping_ShipmentOrder, 'id' | 'blind' | 'deliver_by_date' | 'tracking_number' | 'we_pay'>
  & { pickupType: Shipping_ShipmentOrder['pickup_type'] }
  & { purchase?: Maybe<(
    { __typename?: 'Purchases_Purchase' }
    & Pick<Purchases_Purchase, 'id'>
    & { client?: Maybe<(
      { __typename?: 'Clients_Client' }
      & Pick<Clients_Client, 'id'>
    )> }
  )>, sale?: Maybe<(
    { __typename?: 'Sales_Sale' }
    & Pick<Sales_Sale, 'id'>
    & { client?: Maybe<(
      { __typename?: 'Clients_Client' }
      & Pick<Clients_Client, 'id'>
    )> }
  )>, account?: Maybe<(
    { __typename?: 'Shipping_Account' }
    & Pick<Shipping_Account, 'id' | 'name' | 'number'>
  )>, service?: Maybe<(
    { __typename?: 'Shipping_ServiceLevel' }
    & Pick<Shipping_ServiceLevel, 'id' | 'service'>
    & { shipper: (
      { __typename?: 'Shipping_Carrier' }
      & Pick<Shipping_Carrier, 'id' | 'name'>
    ) }
  )>, shipFrom?: Maybe<(
    { __typename?: 'Address_Address' }
    & AddressFullFragment
  )>, shipFromRep?: Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'full_name' | 'email'>
  )>, shipFromContact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Pick<Clients_Contact, 'id' | 'phone' | 'email' | 'full_name'>
  )>, shipToRep?: Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'full_name' | 'email'>
  )>, shipTo?: Maybe<(
    { __typename?: 'Address_Address' }
    & AddressFullFragment
  )>, shipToContact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Pick<Clients_Contact, 'id' | 'phone' | 'email' | 'full_name'>
  )>, billToRep?: Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'full_name' | 'email'>
  )>, billTo?: Maybe<(
    { __typename?: 'Address_Address' }
    & AddressFullFragment
  )>, billToContact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Pick<Clients_Contact, 'id' | 'phone' | 'email' | 'full_name'>
  )>, cost: (
    { __typename?: 'Money' }
    & MoneyFragment
  ), status: (
    { __typename?: 'Choices_Status' }
    & Pick<Choices_Status, 'id' | 'status'>
  ) }
);

export type ShipmentOrderForListFragment = (
  { __typename?: 'Shipping_ShipmentOrder' }
  & Pick<Shipping_ShipmentOrder, 'id' | 'pickup_type'>
  & { isInternational: Shipping_ShipmentOrder['is_international'] }
  & { status: (
    { __typename?: 'Choices_Status' }
    & Pick<Choices_Status, 'id' | 'status' | 'order'>
  ), sale?: Maybe<(
    { __typename?: 'Sales_Sale' }
    & Pick<Sales_Sale, 'id'>
    & { rep?: Maybe<(
      { __typename?: 'Users_User' }
      & Pick<Users_User, 'id' | 'initials'>
    )> }
  )>, prma?: Maybe<(
    { __typename?: 'RMA_PurchaseRMA' }
    & Pick<Rma_PurchaseRma, 'id'>
    & { order: (
      { __typename?: 'Purchases_Purchase' }
      & Pick<Purchases_Purchase, 'id'>
      & { rep?: Maybe<(
        { __typename?: 'Users_User' }
        & Pick<Users_User, 'id' | 'initials'>
      )> }
    ) }
  )>, srma?: Maybe<(
    { __typename?: 'RMA_RMA' }
    & Pick<Rma_Rma, 'id'>
    & { order: (
      { __typename?: 'Sales_Sale' }
      & Pick<Sales_Sale, 'id'>
      & { rep?: Maybe<(
        { __typename?: 'Users_User' }
        & Pick<Users_User, 'id' | 'initials'>
      )> }
    ) }
  )>, assignee?: Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials'>
    & { profile?: Maybe<(
      { __typename?: 'Profile_Profile' }
      & Pick<Profile_Profile, 'id' | 'color'>
    )> }
  )>, service?: Maybe<(
    { __typename?: 'Shipping_ServiceLevel' }
    & Pick<Shipping_ServiceLevel, 'id' | 'color' | 'service'>
    & { carrier: (
      { __typename?: 'Shipping_Carrier' }
      & Pick<Shipping_Carrier, 'id' | 'name'>
    ) }
  )> }
);

export type CarrierAccountFullFragment = (
  { __typename?: 'Shipping_Account' }
  & Pick<Shipping_Account, 'id' | 'description' | 'easypost_id' | 'name' | 'number'>
  & { default_shipping_account_for_client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id'>
  )>, client?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id' | 'name'>
  )>, shipper: (
    { __typename?: 'Shipping_Carrier' }
    & Pick<Shipping_Carrier, 'id' | 'name'>
  ) }
);

export type ShipmentOrderToEditFragment = (
  { __typename?: 'Shipping_ShipmentOrder' }
  & Pick<Shipping_ShipmentOrder, 'id' | 'blind' | 'tracking_email_recipients' | 'pickup_type' | 'we_pay'>
  & { service?: Maybe<(
    { __typename?: 'Shipping_ServiceLevel' }
    & Pick<Shipping_ServiceLevel, 'id' | 'service'>
  )>, account?: Maybe<(
    { __typename?: 'Shipping_Account' }
    & Pick<Shipping_Account, 'id'>
  )>, ship_to_address?: Maybe<(
    { __typename?: 'Address_Address' }
    & AddressFullFragment
  )>, ship_to_contact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Contact_FullFragment
  )>, ship_to_rep?: Maybe<(
    { __typename?: 'Users_User' }
    & FullUserFragment
  )>, ship_from_address?: Maybe<(
    { __typename?: 'Address_Address' }
    & AddressFullFragment
  )>, ship_from_contact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Contact_FullFragment
  )>, ship_from_rep?: Maybe<(
    { __typename?: 'Users_User' }
    & FullUserFragment
  )>, purchaser_address?: Maybe<(
    { __typename?: 'Address_Address' }
    & AddressFullFragment
  )>, purchaser_contact?: Maybe<(
    { __typename?: 'Clients_Contact' }
    & Contact_FullFragment
  )>, purchaser_rep?: Maybe<(
    { __typename?: 'Users_User' }
    & FullUserFragment
  )> }
);

export type GetShippingServiceLevelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetShippingServiceLevelsQuery = (
  { __typename?: 'Query' }
  & { shipping_service_levels?: Maybe<Array<Maybe<(
    { __typename?: 'Shipping_ServiceLevel' }
    & Pick<Shipping_ServiceLevel, 'id' | 'color' | 'service' | 'easypost_name' | 'order_arrive_by' | 'order_ready_by'>
    & { shipper: (
      { __typename?: 'Shipping_Carrier' }
      & Pick<Shipping_Carrier, 'id' | 'name' | 'easypost_name'>
    ) }
  )>>> }
);

export type ShipmentOrderDetails_FullQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ShipmentOrderDetails_FullQuery = (
  { __typename?: 'Query' }
  & { order?: Maybe<(
    { __typename?: 'Shipping_ShipmentOrder' }
    & ShipmentOrderDetails_FullFragment
  )> }
);

export type GetShipmentsOnShippingOrderQueryVariables = Exact<{
  id: Scalars['Any'];
}>;


export type GetShipmentsOnShippingOrderQuery = (
  { __typename?: 'Query' }
  & { shipping_shipments?: Maybe<Array<Maybe<(
    { __typename?: 'Shipping_Shipment' }
    & Shipping_Shippment_FullFragment
  )>>> }
);

export type ShipmentOrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ShipmentOrderQuery = (
  { __typename?: 'Query' }
  & { order?: Maybe<(
    { __typename?: 'Shipping_ShipmentOrder' }
    & ShipmentOrderDetailsFragment
  )> }
);

export type ShipmentOrdersListQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type ShipmentOrdersListQuery = (
  { __typename?: 'Query' }
  & { shipments?: Maybe<Array<Maybe<(
    { __typename?: 'Shipping_ShipmentOrder' }
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & ShipmentOrderForListFragment
  )>>> }
);

export type GetCarrierAccountsQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetCarrierAccountsQuery = (
  { __typename?: 'Query' }
  & { carrier_accounts?: Maybe<Array<Maybe<(
    { __typename?: 'Shipping_Account' }
    & Pick<Shipping_Account, 'id' | 'description' | 'easypost_id' | 'name' | 'number'>
    & { default_shipping_account_for_client?: Maybe<(
      { __typename?: 'Clients_Client' }
      & Pick<Clients_Client, 'id'>
    )>, client?: Maybe<(
      { __typename?: 'Clients_Client' }
      & Pick<Clients_Client, 'id' | 'name'>
    )>, shipper: (
      { __typename?: 'Shipping_Carrier' }
      & Pick<Shipping_Carrier, 'id' | 'name'>
    ) }
  )>>> }
);

export type GetCarrierAccountsForAutofillQueryVariables = Exact<{
  filters?: Maybe<Array<QueryFilter> | QueryFilter>;
}>;


export type GetCarrierAccountsForAutofillQuery = (
  { __typename?: 'Query' }
  & { shipping_accounts?: Maybe<Array<Maybe<(
    { __typename?: 'Shipping_Account' }
    & Pick<Shipping_Account, 'id' | 'easypost_id' | 'number'>
    & { title: Shipping_Account['name'] }
    & { shipper: (
      { __typename?: 'Shipping_Carrier' }
      & Pick<Shipping_Carrier, 'id' | 'name'>
    ) }
  )>>> }
);

export type GetCompanyCarrierAccountsWithNumberForAutofillQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyCarrierAccountsWithNumberForAutofillQuery = (
  { __typename?: 'Query' }
  & { accounts?: Maybe<Array<Maybe<(
    { __typename?: 'Shipping_Account' }
    & Pick<Shipping_Account, 'id' | 'easypost_id'>
    & { title: Shipping_Account['name'], subtitle: Shipping_Account['number'] }
    & { shipper: (
      { __typename?: 'Shipping_Carrier' }
      & Pick<Shipping_Carrier, 'id' | 'name'>
    ) }
  )>>> }
);

export type ClientAndCompanyCarrierAccountsQueryVariables = Exact<{
  id: Scalars['Any'];
}>;


export type ClientAndCompanyCarrierAccountsQuery = (
  { __typename?: 'Query' }
  & { accounts?: Maybe<Array<Maybe<(
    { __typename?: 'Shipping_Account' }
    & Pick<Shipping_Account, 'id' | 'easypost_id' | 'name' | 'number'>
    & { client?: Maybe<(
      { __typename?: 'Clients_Client' }
      & Pick<Clients_Client, 'id' | 'name'>
    )>, shipper: (
      { __typename?: 'Shipping_Carrier' }
      & Pick<Shipping_Carrier, 'id' | 'name'>
    ) }
  )>>> }
);

export type GetClientCarrierAccountsWithNumberForAutofillQueryVariables = Exact<{
  id: Scalars['Any'];
}>;


export type GetClientCarrierAccountsWithNumberForAutofillQuery = (
  { __typename?: 'Query' }
  & { accounts?: Maybe<Array<Maybe<(
    { __typename?: 'Shipping_Account' }
    & Pick<Shipping_Account, 'id' | 'easypost_id'>
    & { title: Shipping_Account['name'], subtitle: Shipping_Account['number'] }
    & { shipper: (
      { __typename?: 'Shipping_Carrier' }
      & Pick<Shipping_Carrier, 'id' | 'name'>
    ) }
  )>>> }
);

export type CarriersAliasesQueryVariables = Exact<{ [key: string]: never; }>;


export type CarriersAliasesQuery = (
  { __typename?: 'Query' }
  & { carriers?: Maybe<Array<Maybe<(
    { __typename?: 'Shipping_Carrier' }
    & Pick<Shipping_Carrier, 'id'>
    & { title: Shipping_Carrier['name'] }
  )>>> }
);

export type CarriersQueryVariables = Exact<{ [key: string]: never; }>;


export type CarriersQuery = (
  { __typename?: 'Query' }
  & { shipping_carriers?: Maybe<Array<Maybe<(
    { __typename?: 'Shipping_Carrier' }
    & Pick<Shipping_Carrier, 'id' | 'name' | 'is_courier'>
  )>>> }
);

export type CreateShipmentOrderMutationVariables = Exact<{
  input: Create__Shipping_ShipmentOrder__Input;
}>;


export type CreateShipmentOrderMutation = (
  { __typename?: 'Mutation' }
  & { order?: Maybe<(
    { __typename?: 'Shipping_ShipmentOrder' }
    & ShipmentOrderDetailsFragment
  )> }
);

export type DeleteShipmentOrderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteShipmentOrderMutation = (
  { __typename?: 'Mutation' }
  & { Delete__Shipping_ShipmentOrder?: Maybe<(
    { __typename?: 'DBActionStatus' }
    & Pick<DbActionStatus, 'id'>
  )> }
);

export type DeleteShipmentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteShipmentMutation = (
  { __typename?: 'Mutation' }
  & { Delete__Shipping_Shipment?: Maybe<(
    { __typename?: 'DBActionStatus' }
    & Pick<DbActionStatus, 'id'>
  )> }
);

export type EditServiceColorMutationVariables = Exact<{
  id: Scalars['ID'];
  color?: Maybe<Scalars['String']>;
}>;


export type EditServiceColorMutation = (
  { __typename?: 'Mutation' }
  & { service?: Maybe<(
    { __typename?: 'Shipping_ServiceLevel' }
    & Pick<Shipping_ServiceLevel, 'id' | 'easypost_name' | 'color' | 'order_arrive_by' | 'order_ready_by' | 'service'>
    & { shipper: (
      { __typename?: 'Shipping_Carrier' }
      & Pick<Shipping_Carrier, 'id' | 'name' | 'easypost_name'>
    ) }
  )> }
);

export type UpdateShipmentOrderMutationVariables = Exact<{
  input: Update__Shipping_ShipmentOrder__Input;
}>;


export type UpdateShipmentOrderMutation = (
  { __typename?: 'Mutation' }
  & { order?: Maybe<(
    { __typename?: 'Shipping_ShipmentOrder' }
    & ShipmentOrderDetailsFragment
  )> }
);

export type CreateCarrierAccountMutationVariables = Exact<{
  input: Create__Shipping_Account__Input;
}>;


export type CreateCarrierAccountMutation = (
  { __typename?: 'Mutation' }
  & { Create__Shipping_Account?: Maybe<(
    { __typename?: 'Shipping_Account' }
    & CarrierAccountFullFragment
  )> }
);

export type DeleteCarrierAccountMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCarrierAccountMutation = (
  { __typename?: 'Mutation' }
  & { Delete__Shipping_Account?: Maybe<(
    { __typename?: 'DBActionStatus' }
    & Pick<DbActionStatus, 'id'>
  )> }
);

export type CreateCarrierServiceMutationVariables = Exact<{
  input: Create__Shipping_ServiceLevel__Input;
}>;


export type CreateCarrierServiceMutation = (
  { __typename?: 'Mutation' }
  & { Create__Shipping_ServiceLevel?: Maybe<(
    { __typename?: 'Shipping_ServiceLevel' }
    & Pick<Shipping_ServiceLevel, 'id' | 'service' | 'color'>
    & { shipper: (
      { __typename?: 'Shipping_Carrier' }
      & Pick<Shipping_Carrier, 'id' | 'name'>
    ) }
  )> }
);

export type CreateCarrierMutationVariables = Exact<{
  input: Create__Shipping_Carrier__Input;
}>;


export type CreateCarrierMutation = (
  { __typename?: 'Mutation' }
  & { Create__Shipping_Carrier?: Maybe<(
    { __typename?: 'Shipping_Carrier' }
    & Pick<Shipping_Carrier, 'id' | 'name'>
  )> }
);

export type Terms_ListFragment = (
  { __typename?: 'Clients_Terms' }
  & Pick<Clients_Terms, 'id' | 'name' | 'description' | 'days'>
  & { default_terms_for_company?: Maybe<(
    { __typename?: 'Company_Company' }
    & Pick<Company_Company, 'name'>
  )> }
);

export type MoneyFragment = (
  { __typename?: 'Money' }
  & Pick<Money, 'pretty' | 'amount'>
  & { currency: (
    { __typename?: 'Currency' }
    & Pick<Currency, 'name' | 'numeric' | 'code'>
  ) }
);

export type Terms_ListQueryVariables = Exact<{ [key: string]: never; }>;


export type Terms_ListQuery = (
  { __typename?: 'Query' }
  & { clients_terms?: Maybe<Array<Maybe<(
    { __typename?: 'Clients_Terms' }
    & Terms_ListFragment
  )>>> }
);

export type TermsQueryVariables = Exact<{ [key: string]: never; }>;


export type TermsQuery = (
  { __typename?: 'Query' }
  & { terms?: Maybe<Array<Maybe<(
    { __typename?: 'Clients_Terms' }
    & Terms_ListFragment
  )>>> }
);

export type TermsForAutoCompleteQueryVariables = Exact<{ [key: string]: never; }>;


export type TermsForAutoCompleteQuery = (
  { __typename?: 'Query' }
  & { terms?: Maybe<Array<Maybe<(
    { __typename?: 'Clients_Terms' }
    & Pick<Clients_Terms, 'id'>
    & { title: Clients_Terms['name'], subtitle: Clients_Terms['description'] }
    & { default_terms_for_new_client?: Maybe<(
      { __typename?: 'Company_Company' }
      & Pick<Company_Company, 'id'>
    )> }
  )>>> }
);

export type CreateTermsMutationVariables = Exact<{
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  days?: Maybe<Scalars['PositiveInteger']>;
}>;


export type CreateTermsMutation = (
  { __typename?: 'Mutation' }
  & { term?: Maybe<(
    { __typename?: 'Clients_Terms' }
    & Pick<Clients_Terms, 'id' | 'days' | 'description' | 'name'>
  )> }
);

export type UpdateTermMutationVariables = Exact<{
  input: Update__Clients_Terms__Input;
}>;


export type UpdateTermMutation = (
  { __typename?: 'Mutation' }
  & { Update__Clients_Terms?: Maybe<(
    { __typename?: 'Clients_Terms' }
    & Pick<Clients_Terms, 'id'>
  )> }
);

export type DeleteTermMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTermMutation = (
  { __typename?: 'Mutation' }
  & { term?: Maybe<(
    { __typename?: 'DBActionStatus' }
    & Pick<DbActionStatus, 'id'>
  )> }
);

export type UserMinimalFragment = (
  { __typename?: 'Users_User' }
  & Pick<Users_User, 'id' | 'initials'>
);

export type FullUserFragment = (
  { __typename?: 'Users_User' }
  & Pick<Users_User, 'id' | 'email' | 'initials' | 'username'>
  & { dateJoined: Users_User['date_joined'], firstName: Users_User['first_name'], fullName: Users_User['full_name'], isActive: Users_User['is_active'], isStaff: Users_User['is_staff'], isSuperUser: Users_User['is_superuser'], lastLogin: Users_User['last_login'], lastName: Users_User['last_name'] }
  & { department?: Maybe<Array<(
    { __typename?: 'Department_DepartmentEmployees' }
    & Pick<Department_DepartmentEmployees, 'id'>
    & { department: (
      { __typename?: 'Department_Department' }
      & Pick<Department_Department, 'id' | 'name'>
      & { roles?: Maybe<Array<(
        { __typename?: 'Department_DepartmentRoles' }
        & Pick<Department_DepartmentRoles, 'id'>
        & { role: (
          { __typename?: 'CustomPermissions_Role' }
          & Pick<CustomPermissions_Role, 'id' | 'name'>
        ) }
      )>> }
    ) }
  )>>, subs?: Maybe<Array<(
    { __typename?: 'Profile_Profile' }
    & Pick<Profile_Profile, 'id'>
    & { user: (
      { __typename?: 'Users_User' }
      & Pick<Users_User, 'id' | 'initials'>
    ) }
  )>>, team?: Maybe<Array<(
    { __typename?: 'Team_TeamMembers' }
    & Pick<Team_TeamMembers, 'id'>
    & { member: (
      { __typename?: 'Users_User' }
      & Pick<Users_User, 'id' | 'initials'>
    ) }
  )>> }
);

export type GetUsersFullQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GetUsersFullQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<Maybe<(
    { __typename?: 'Users_User' }
    & FullUserFragment
  )>>> }
);

export type GetUsersForAutofillQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersForAutofillQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id'>
    & { title: Users_User['full_name'], subtitle: Users_User['initials'] }
    & { profile?: Maybe<(
      { __typename?: 'Profile_Profile' }
      & Pick<Profile_Profile, 'id' | 'color'>
    )> }
  )>>> }
);

export type UsersForAutofillQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersForAutofillQuery = (
  { __typename?: 'Query' }
  & { reps?: Maybe<Array<Maybe<(
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id'>
    & { title: Users_User['initials'], subtitle: Users_User['full_name'] }
  )>>> }
);

export type Update_User_ProfileMutationVariables = Exact<{
  input: Update__Profile_Profile__Input;
}>;


export type Update_User_ProfileMutation = (
  { __typename?: 'Mutation' }
  & { Update__Profile_Profile?: Maybe<(
    { __typename?: 'Profile_Profile' }
    & Pick<Profile_Profile, 'id' | 'commission_percentage'>
  )> }
);

export type WorkOrderListFragment = (
  { __typename?: 'WorkOrder_WorkOrder' }
  & Pick<WorkOrder_WorkOrder, 'contract_comment' | 'creation_date' | 'id' | 'internal_comment' | 'need_by' | 'updated_date' | 'warranty_end_date'>
  & { amount?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials' | 'full_name'>
  ), purchase_transaction?: Maybe<(
    { __typename?: 'Purchases_Purchase' }
    & Pick<Purchases_Purchase, 'id'>
    & { client?: Maybe<(
      { __typename?: 'Clients_Client' }
      & Pick<Clients_Client, 'id' | 'name' | 'short_name'>
    )> }
  )>, shipment_order?: Maybe<(
    { __typename?: 'Shipping_ShipmentOrder' }
    & Pick<Shipping_ShipmentOrder, 'id'>
  )>, status: (
    { __typename?: 'Choices_Status' }
    & Pick<Choices_Status, 'id' | 'status'>
  ), terms?: Maybe<(
    { __typename?: 'Clients_Terms' }
    & Pick<Clients_Terms, 'id' | 'days' | 'description'>
  )>, type?: Maybe<(
    { __typename?: 'Choices_Choice' }
    & Pick<Choices_Choice, 'id' | 'choice' | 'sub_choice'>
  )>, vendor?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id' | 'name' | 'short_name'>
  )> }
);

export type WorkOrderItemFragment = (
  { __typename?: 'WorkOrder_WorkOrderItems' }
  & Pick<WorkOrder_WorkOrderItems, 'id'>
  & { item: (
    { __typename?: 'Inventory_Item' }
    & Pick<Inventory_Item, 'id'>
    & { part?: Maybe<(
      { __typename?: 'Parts_Part' }
      & Pick<Parts_Part, 'id' | 'pn' | 'description'>
    )> }
  ) }
);

export type WorkOrderForPdfFragment = (
  { __typename?: 'WorkOrder_WorkOrder' }
  & Pick<WorkOrder_WorkOrder, 'id' | 'contract_comment' | 'creation_date' | 'need_by'>
  & { amount?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragment
  )>, creator: (
    { __typename?: 'Users_User' }
    & Pick<Users_User, 'id' | 'initials'>
  ), purchase_transaction?: Maybe<(
    { __typename?: 'Purchases_Purchase' }
    & Pick<Purchases_Purchase, 'id'>
    & { rep?: Maybe<(
      { __typename?: 'Users_User' }
      & Pick<Users_User, 'id' | 'initials' | 'full_name' | 'email'>
    )> }
  )>, shipment_order?: Maybe<(
    { __typename?: 'Shipping_ShipmentOrder' }
    & Pick<Shipping_ShipmentOrder, 'id' | 'blind'>
    & { purchaser_contact?: Maybe<(
      { __typename?: 'Clients_Contact' }
      & Contact_ListFragment
    )>, purchaser_address?: Maybe<(
      { __typename?: 'Address_Address' }
      & Pick<Address_Address, 'id' | 'street_1' | 'street_2' | 'city' | 'state' | 'zip_code'>
      & { country: (
        { __typename?: 'CountryObject' }
        & Pick<CountryObject, 'code' | 'name'>
      ), company?: Maybe<(
        { __typename?: 'Company_Company' }
        & Pick<Company_Company, 'name'>
      )>, client?: Maybe<(
        { __typename?: 'Clients_Client' }
        & Pick<Clients_Client, 'id' | 'name'>
      )>, default_contact?: Maybe<(
        { __typename?: 'Clients_Contact' }
        & Pick<Clients_Contact, 'id' | 'full_name'>
      )> }
    )>, ship_from_contact?: Maybe<(
      { __typename?: 'Clients_Contact' }
      & Contact_ListFragment
    )>, ship_from_address?: Maybe<(
      { __typename?: 'Address_Address' }
      & Pick<Address_Address, 'id' | 'street_1' | 'street_2' | 'city' | 'state' | 'zip_code'>
      & { country: (
        { __typename?: 'CountryObject' }
        & Pick<CountryObject, 'code' | 'name'>
      ), company?: Maybe<(
        { __typename?: 'Company_Company' }
        & Pick<Company_Company, 'name'>
      )>, client?: Maybe<(
        { __typename?: 'Clients_Client' }
        & Pick<Clients_Client, 'id' | 'name'>
      )>, default_contact?: Maybe<(
        { __typename?: 'Clients_Contact' }
        & Pick<Clients_Contact, 'id' | 'full_name'>
      )> }
    )>, ship_to_contact?: Maybe<(
      { __typename?: 'Clients_Contact' }
      & Contact_ListFragment
    )>, ship_to_address?: Maybe<(
      { __typename?: 'Address_Address' }
      & Pick<Address_Address, 'id' | 'street_1' | 'street_2' | 'city' | 'state' | 'zip_code'>
      & { country: (
        { __typename?: 'CountryObject' }
        & Pick<CountryObject, 'code' | 'name'>
      ), client?: Maybe<(
        { __typename?: 'Clients_Client' }
        & Pick<Clients_Client, 'id' | 'name'>
      )>, company?: Maybe<(
        { __typename?: 'Company_Company' }
        & Pick<Company_Company, 'name'>
      )>, default_contact?: Maybe<(
        { __typename?: 'Clients_Contact' }
        & Pick<Clients_Contact, 'id' | 'full_name'>
      )> }
    )> }
  )>, terms?: Maybe<(
    { __typename?: 'Clients_Terms' }
    & Terms_ListFragment
  )>, vendor?: Maybe<(
    { __typename?: 'Clients_Client' }
    & Pick<Clients_Client, 'id' | 'name'>
  )>, wo_items?: Maybe<Array<(
    { __typename?: 'WorkOrder_WorkOrderItems' }
    & WorkOrderItemFragment
  )>> }
);

export type GridGetWorkOrdersQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GridGetWorkOrdersQuery = (
  { __typename?: 'Query' }
  & { work_orders?: Maybe<Array<Maybe<(
    { __typename?: 'WorkOrder_WorkOrder' }
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & WorkOrderListFragment
  )>>> }
);

export type GridGetWorkOrderItemsQueryVariables = Exact<{
  input: ManyQueryInput;
}>;


export type GridGetWorkOrderItemsQuery = (
  { __typename?: 'Query' }
  & { items?: Maybe<Array<Maybe<(
    { __typename?: 'WorkOrder_WorkOrderItems' }
    & { annotations?: Maybe<Array<Maybe<(
      { __typename?: 'AnnotationObject' }
      & Pick<AnnotationObject, 'name' | 'value'>
    )>>> }
    & WorkOrderItemFragment
  )>>> }
);

export type GetWorkOrderForPdfQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetWorkOrderForPdfQuery = (
  { __typename?: 'Query' }
  & { work_order_work_order?: Maybe<(
    { __typename?: 'WorkOrder_WorkOrder' }
    & WorkOrderForPdfFragment
  )> }
);

export type CreateWorkOrderMutationVariables = Exact<{
  input: Create__WorkOrder_WorkOrder__Input;
}>;


export type CreateWorkOrderMutation = (
  { __typename?: 'Mutation' }
  & { order?: Maybe<(
    { __typename?: 'WorkOrder_WorkOrder' }
    & WorkOrderListFragment
  )> }
);

export type CreateWorkOrderItemMutationVariables = Exact<{
  input: Array<Create__WorkOrder_WorkOrderItems__Input> | Create__WorkOrder_WorkOrderItems__Input;
}>;


export type CreateWorkOrderItemMutation = (
  { __typename?: 'Mutation' }
  & { Create__WorkOrder_WorkOrderItems?: Maybe<Array<Maybe<(
    { __typename?: 'WorkOrder_WorkOrderItems' }
    & WorkOrderItemFragment
  )>>> }
);

export type DeleteWorkOrderItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteWorkOrderItemMutation = (
  { __typename?: 'Mutation' }
  & { Delete__WorkOrder_WorkOrderItems?: Maybe<(
    { __typename?: 'DBActionStatus' }
    & Pick<DbActionStatus, 'id'>
  )> }
);

export type GetPrintNodePrintersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPrintNodePrintersQuery = (
  { __typename?: 'Query' }
  & { printers?: Maybe<Array<Maybe<(
    { __typename?: 'PrintNodePrinter' }
    & Pick<PrintNodePrinter, 'id' | 'name' | 'description' | 'state'>
    & { computer?: Maybe<(
      { __typename?: 'PrintNodeComputer' }
      & Pick<PrintNodeComputer, 'id' | 'name' | 'hostname' | 'state'>
    )> }
  )>>> }
);

export type CreatePrintJobMutationVariables = Exact<{
  content: Scalars['String'];
  contentType: Printnode_Content_Type;
  expireAfter?: Maybe<Scalars['Int']>;
  printerId: Scalars['ID'];
  qty?: Maybe<Scalars['Int']>;
  source: Scalars['String'];
  title: Scalars['String'];
  options?: Maybe<PrintNodeOptionsInput>;
}>;


export type CreatePrintJobMutation = (
  { __typename?: 'Mutation' }
  & { job: Mutation['PrintNode_CreatePrintJob'] }
);

export const Accounting_Account__ListFragmentDoc = gql`
    fragment Accounting_Account__List on Accounting_Account {
  id
  account_number
  description
  name
}
    `;
export const MoneyFragmentDoc = gql`
    fragment Money on Money {
  pretty
  amount
  currency {
    name
    numeric
    code
  }
}
    `;
export const Accounting_Account__GridFragmentDoc = gql`
    fragment Accounting_Account__Grid on Accounting_Account {
  ...Accounting_Account__List
  account_total {
    ...Money
  }
  account_type
  total_credit {
    ...Money
  }
  total_debit {
    ...Money
  }
}
    ${Accounting_Account__ListFragmentDoc}
${MoneyFragmentDoc}`;
export const Accounting_Transaction__GridFragmentDoc = gql`
    fragment Accounting_Transaction__Grid on Accounting_Transaction {
  creation_date
  creator {
    id
    full_name
    initials
  }
  id
  internal_reference
  is_balanced
  is_compound
  reference
}
    `;
export const Accounting_Entry__GridFragmentDoc = gql`
    fragment Accounting_Entry__GRID on Accounting_Entry {
  account {
    id
    name
    account_number
  }
  amount {
    ...Money
  }
  creation_date
  creator {
    id
    full_name
    initials
  }
  entry_type
  id
  note
  transaction {
    id
    reference
  }
}
    ${MoneyFragmentDoc}`;
export const Invoice_Ar__FullFragmentDoc = gql`
    fragment Invoice_AR__FULL on Invoices_ARInvoice {
  amount {
    ...Money
  }
  creation_date
  creator {
    id
    initials
  }
  date_due
  date_sent
  id
  is_voided
  order {
    id
    rep {
      id
      initials
    }
    client {
      id
      name
      short_name
    }
  }
  status {
    id
    status
  }
  updated_date
}
    ${MoneyFragmentDoc}`;
export const Invoice_Ap__FullFragmentDoc = gql`
    fragment Invoice_AP__FULL on Invoices_APInvoice {
  amount {
    ...Money
  }
  creation_date
  creator {
    id
    initials
  }
  date_due
  date_sent
  id
  is_voided
  order {
    id
    rep {
      id
      initials
    }
    client {
      id
      name
      short_name
    }
  }
  status {
    id
    status
  }
  updated_date
}
    ${MoneyFragmentDoc}`;
export const Invoice_Ar_Payment__FullFragmentDoc = gql`
    fragment Invoice_AR_Payment__Full on Invoices_ARPayment {
  amount {
    ...Money
  }
  creation_date
  creator {
    id
    initials
  }
  id
  is_posted
  reference
  updated_date
}
    ${MoneyFragmentDoc}`;
export const Invoice_Ap_Payment__FullFragmentDoc = gql`
    fragment Invoice_AP_Payment__Full on Invoices_APPayment {
  amount {
    ...Money
  }
  creation_date
  creator {
    id
    initials
  }
  id
  is_posted
  reference
  updated_date
}
    ${MoneyFragmentDoc}`;
export const BomForPartGridFragmentDoc = gql`
    fragment BomForPartGrid on Parts_BOM {
  id
  bom_for {
    id
    brand {
      id
      name
    }
    pn
    type
  }
  creation_date
  creator {
    id
    initials
  }
  description
  updated_date
}
    `;
export const BomPartFullFragmentDoc = gql`
    fragment BomPartFull on Parts_BOMParts {
  id
  bom {
    id
  }
  creation_date
  creator {
    id
    initials
  }
  maximum_count
  minimum_count
  part {
    id
    pn
    description
    type
  }
  part_type
  updated_date
}
    `;
export const BomForPartFullFragmentDoc = gql`
    fragment BomForPartFull on Parts_BOM {
  id
  bom_for {
    id
    brand {
      id
      name
    }
    pn
    type
  }
  creation_date
  creator {
    id
    initials
  }
  description
  parts {
    ...BomPartFull
  }
  updated_date
}
    ${BomPartFullFragmentDoc}`;
export const BuildUpsGridFragmentDoc = gql`
    fragment BuildUpsGrid on BuildUp_BuildUp {
  id
  annotations {
    name
    value
  }
  assigned_to {
    id
    full_name
    initials
    email
  }
  creation_date
  creator {
    id
    initials
    email
  }
  status {
    id
    status
  }
  system {
    id
    part {
      id
      pn
      description
    }
    purchases_items_details {
      id
      line_number
      transaction {
        id
      }
    }
    serial_number
  }
  updated_date
}
    `;
export const BuildUpItemFullFragmentDoc = gql`
    fragment BuildUpItemFull on BuildUp_BuildUpItems {
  annotations {
    name
    value
  }
  build_up {
    id
  }
  creation_date
  creator {
    id
    initials
  }
  id
  item {
    id
    part {
      id
      pn
      type
    }
    purchases_items_details {
      id
      line_number
      transaction {
        id
      }
    }
    serial_number
  }
  status {
    id
    status
    status_type
  }
  updated_date
}
    `;
export const ChoicesStatusFragmentDoc = gql`
    fragment ChoicesStatus on Choices_Status {
  id
  app_name
  field_name
  model_name
  status
  status_type
}
    `;
export const ChoicesTagsFragmentDoc = gql`
    fragment ChoicesTags on Choices_Tag {
  id
  tag
  description
  color
  app_name
  model_name
  field_name
}
    `;
export const ChoicesChoiceFragmentDoc = gql`
    fragment ChoicesChoice on Choices_Choice {
  id
  choice
  sub_choice
  app_name
  model_name
  field_name
}
    `;
export const Client_ListFragmentDoc = gql`
    fragment Client_List on Clients_Client {
  id
  allow_sales
  ecommerce_store
  internal_rep {
    id
    initials
  }
  name
  outstanding_invoice_limit {
    ...Money
  }
  rating
  short_name
}
    ${MoneyFragmentDoc}`;
export const Client_GridFragmentDoc = gql`
    fragment Client_Grid on Clients_Client {
  ...Client_List
  credit {
    ...Money
  }
  credit_limit {
    ...Money
  }
  notes
  shipping_instructions
  type {
    id
    choice
    sub_choice
  }
  website
}
    ${Client_ListFragmentDoc}
${MoneyFragmentDoc}`;
export const AddressListFragmentDoc = gql`
    fragment AddressList on Address_Address {
  building_name
  client {
    id
    name
    short_name
  }
  company {
    name
  }
  city
  easypost_id
  id
  state
  street_1
}
    `;
export const AddressGridFragmentDoc = gql`
    fragment AddressGrid on Address_Address {
  ...AddressList
  country {
    name
    code
  }
  default_contact {
    id
    full_name
  }
  street_2
  zip_code
}
    ${AddressListFragmentDoc}`;
export const CarrierAccountFullFragmentDoc = gql`
    fragment CarrierAccountFull on Shipping_Account {
  id
  description
  easypost_id
  default_shipping_account_for_client {
    id
  }
  client {
    id
    name
  }
  name
  number
  shipper {
    id
    name
  }
}
    `;
export const Terms_ListFragmentDoc = gql`
    fragment Terms_List on Clients_Terms {
  id
  name
  description
  days
  default_terms_for_company {
    name
  }
}
    `;
export const Client_FullFragmentDoc = gql`
    fragment Client_Full on Clients_Client {
  ...Client_Grid
  attached_address {
    ...AddressGrid
  }
  creation_date
  creator {
    id
    initials
  }
  default_billing_address {
    ...AddressGrid
  }
  default_remit_address {
    ...AddressGrid
  }
  default_return_to_address {
    ...AddressGrid
  }
  default_ship_from_address {
    ...AddressGrid
  }
  default_ship_to_address {
    ...AddressGrid
  }
  default_shipping_account {
    ...CarrierAccountFull
  }
  default_shipping_service {
    id
    service
    shipper {
      id
      name
    }
  }
  default_tracking_email_recipients
  default_pt_terms {
    ...Terms_List
  }
  default_st_terms {
    ...Terms_List
  }
  federal_tax_id
  is_1099
  is_our_client
  is_tax_exempt
  origin
  payment
  shipping_accounts {
    ...CarrierAccountFull
  }
  state_tax_id
  tax_exempt_number
  terms {
    ...Terms_List
  }
  updated_date
}
    ${Client_GridFragmentDoc}
${AddressGridFragmentDoc}
${CarrierAccountFullFragmentDoc}
${Terms_ListFragmentDoc}`;
export const ListContactFragmentDoc = gql`
    fragment ListContact on Clients_Contact {
  id
  title: full_name
  subtitle: email
  phone
}
    `;
export const ListUserFragmentDoc = gql`
    fragment ListUser on Users_User {
  id
  title: full_name
  subtitle: initials
  email
}
    `;
export const CountryFragmentDoc = gql`
    fragment Country on CountryObject {
  name
  code
  flag: unicode_flag
  numeric
}
    `;
export const BreakdownListFragmentDoc = gql`
    fragment BreakdownList on Breakdown_BreakDown {
  id
  assigned_to {
    id
    full_name
    initials
    email
  }
  creation_date
  creator {
    id
    initials
    email
  }
  status {
    id
    status
    status_type
  }
  system {
    id
    part {
      id
      pn
      description
    }
    purchases_items_details {
      id
      line_number
      transaction {
        id
      }
    }
    serial_number
  }
}
    `;
export const BreakdownFragmentDoc = gql`
    fragment Breakdown on Breakdown_BreakDown {
  id
  assigned_to {
    id
    full_name
    initials
    email
  }
  creation_date
  creator {
    id
    initials
    email
  }
  status {
    id
    status
    status_type
  }
  system {
    id
    part {
      id
      pn
      description
    }
    purchases_items_details {
      id
      line_number
      current_cost {
        ...Money
      }
      original_cost {
        ...Money
      }
      transaction {
        id
      }
    }
    serial_number
  }
}
    ${MoneyFragmentDoc}`;
export const BreakdownItemFragmentDoc = gql`
    fragment BreakdownItem on Breakdown_BreakDownItems {
  id
  item {
    id
    country_of_origin {
      name
      code
      unicode_flag
    }
    location {
      id
      site {
        id
        name
        short_name
      }
      name
    }
    part {
      id
      pn
    }
    purchases_items_details {
      id
      line_number
      original_cost {
        ...Money
      }
      current_cost {
        ...Money
      }
      transaction {
        id
      }
    }
    serial_number
  }
  status {
    id
    status
  }
}
    ${MoneyFragmentDoc}`;
export const EasyPostCustomsItems_FullFragmentDoc = gql`
    fragment EasyPostCustomsItems_Full on EasyPostCustomsItem {
  code
  currency
  description
  hs_tariff_number
  id
  object
  origin_country
  quantity
  value
  weight
}
    `;
export const EasyPostCustomsInfo_FullFragmentDoc = gql`
    fragment EasyPostCustomsInfo_Full on EasyPostCustomsInfo {
  contents_explanation
  contents_type
  customs_certify
  customs_items {
    ...EasyPostCustomsItems_Full
  }
  customs_signer
  eel_pfc
  id
  object
  restriction_comments
  restriction_type
}
    ${EasyPostCustomsItems_FullFragmentDoc}`;
export const EasyPostParcel_FullFragmentDoc = gql`
    fragment EasyPostParcel_Full on EasyPostParcel {
  height
  id
  length
  mode
  predefined_package
  weight
  width
}
    `;
export const EasyPostShipment_FullFragmentDoc = gql`
    fragment EasyPostShipment_Full on EasyPostShipment {
  id
  customs_info {
    ...EasyPostCustomsInfo_Full
  }
  forms {
    form_url
    id
    form_type
    submitted_electronically
  }
  options {
    additional_handling
    delivery_confirmation
    dropoff_type
    hold_for_pickup
    invoice_number
    hazmat
    label_format
    machinable
    payment {
      type
      account
      country
      postal_code
    }
  }
  parcel {
    ...EasyPostParcel_Full
  }
  refund_status
  postage_label {
    id
    label_pdf_url
    label_epl2_url
    label_zpl_url
    label_url
  }
  reference
}
    ${EasyPostCustomsInfo_FullFragmentDoc}
${EasyPostParcel_FullFragmentDoc}`;
export const EasyPostCarrierAccount__FullFragmentDoc = gql`
    fragment EasyPostCarrierAccount__Full on EasyPostCarrierAccount {
  clone
  created_at
  credentials {
    account_number
  }
  description
  id
  object
  readable
  reference
  type
  updated_at
}
    `;
export const UserMinimalFragmentDoc = gql`
    fragment UserMinimal on Users_User {
  id
  initials
}
    `;
export const BasicPartFragmentDoc = gql`
    fragment BasicPart on Parts_Part {
  id
  brand {
    id
    name
    short_name
  }
  description
  has_battery
  mfgr {
    id
    name
    short_name
  }
  pn
  type
  sku
}
    `;
export const BasicSystemFragmentDoc = gql`
    fragment BasicSystem on Inventory_System {
  allocated_date
  alternate_serial_number
  associated_pn {
    associated_pn
  }
  audit_date
  break_down_orders {
    id
    status {
      id
    }
  }
  checked_out_by {
    ...UserMinimal
  }
  condition {
    id
    choice
    sub_choice
  }
  condition_comment
  country_of_origin {
    name
    code
    unicode_flag
  }
  creation_date
  creator {
    id
    initials
    full_name
  }
  fw
  grade {
    id
    choice
    sub_choice
  }
  hot
  id
  internal
  inventory_comment
  is_lost
  last_write_down_date
  location {
    id
    name
    site {
      id
      name
      short_name
    }
  }
  lock_reason
  locked
  next_write_down_date
  part {
    ...BasicPart
  }
  purchase_rma_item_details {
    id
    rma {
      id
    }
  }
  purchases_items_details {
    id
    line_number
    original_cost {
      ...Money
    }
    current_cost {
      ...Money
    }
    transaction {
      id
      rep {
        id
        initials
      }
    }
  }
  quantity
  receive_status
  received_by {
    ...UserMinimal
  }
  received_date
  rma_item_details {
    id
    rma {
      id
    }
  }
  sales_items_details {
    id
    line_number
    sold_for {
      ...Money
    }
    transaction {
      id
      rep {
        id
        initials
      }
    }
  }
  serial_number
  shipment {
    id
  }
  system_logs {
    id
    action {
      id
      action
    }
  }
  test_status {
    id
    tag
    description
    color
  }
  unbooked_date
  updated_date
}
    ${UserMinimalFragmentDoc}
${BasicPartFragmentDoc}
${MoneyFragmentDoc}`;
export const FullPartFragmentDoc = gql`
    fragment FullPart on Parts_Part__Interface {
  alt_part_for_client {
    id
  }
  alt_part_for_part {
    id
    pn
  }
  brand {
    id
    name
    short_name
  }
  description
  ean
  has_battery
  hs_code
  htsus_code
  id
  mfgr {
    id
    name
    short_name
  }
  pn
  schedule_b_code
  secondary_pn
  sku
  type
  upc
}
    `;
export const Site_ListFragmentDoc = gql`
    fragment Site_List on Location_Site {
  id
  name
  short_name
  delivery_notes
  main_site
}
    `;
export const Site_GridFragmentDoc = gql`
    fragment Site_Grid on Location_Site {
  ...Site_List
  address {
    ...AddressGrid
  }
}
    ${Site_ListFragmentDoc}
${AddressGridFragmentDoc}`;
export const Location_ListFragmentDoc = gql`
    fragment Location_List on Location_Location {
  id
  name
  site {
    ...Site_List
  }
}
    ${Site_ListFragmentDoc}`;
export const Location_GridFragmentDoc = gql`
    fragment Location_Grid on Location_Location {
  ...Location_List
  part_type
}
    ${Location_ListFragmentDoc}`;
export const PurchaseFragmentDoc = gql`
    fragment Purchase on Purchases_Purchase {
  id
  acceptance_date
  approval_date
  approved_by {
    id
    full_name
    initials
  }
  client {
    id
    name
  }
  client_order_number
  condition {
    id
    choice
  }
  contact {
    id
    email
    full_name
  }
  contract_comment
  contract_sent_by {
    id
    full_name
    initials
  }
  creation_date
  creator {
    id
    initials
    full_name
  }
  eta
  hot
  internal_comment
  freight {
    ...Money
  }
  estimated_part_total {
    ...Money
  }
  purchase_date
  quote {
    id
  }
  rep {
    id
    initials
    full_name
    email
  }
  requested_date
  shipment_order {
    id
    blind
    deliver_by_date
    tracking_number
    status {
      id
      status
    }
  }
  status {
    id
    status
    status_type
  }
  tax {
    ...Money
  }
  terms {
    id
    name
  }
  estimated_total {
    ...Money
  }
  vendor_reference
}
    ${MoneyFragmentDoc}`;
export const PurchaseForPdfFragmentDoc = gql`
    fragment PurchaseForPdf on Purchases_Purchase {
  client {
    id
    name
    short_name
  }
  contact {
    id
    email
    phone
    full_name
  }
  id
  contract_comment
  pt_items {
    id
    line_number
    part {
      id
      pn
      description
      brand {
        id
        name
      }
      mfgr {
        id
        name
      }
    }
    original_cost {
      ...Money
    }
  }
  rep {
    id
    initials
    full_name
    email
  }
  shipment_order {
    id
    blind
    purchaser_address {
      id
      street_1
      street_2
      city
      state
      country {
        code
        name
      }
      zip_code
      company {
        name
      }
      client {
        id
        name
      }
      default_contact {
        id
        full_name
      }
    }
    ship_from_address {
      id
      street_1
      street_2
      city
      state
      country {
        code
        name
      }
      zip_code
      company {
        name
      }
      client {
        id
        name
      }
      default_contact {
        id
        full_name
      }
    }
    ship_to_address {
      id
      street_1
      street_2
      city
      state
      country {
        code
        name
      }
      zip_code
      client {
        id
        name
      }
      company {
        name
      }
      default_contact {
        id
        full_name
      }
    }
  }
  status {
    id
    status
  }
  total {
    ...Money
  }
  purchase_date
  terms {
    id
    name
  }
}
    ${MoneyFragmentDoc}`;
export const InventoryItemFragmentDoc = gql`
    fragment InventoryItem on Inventory_Item {
  allocated_date
  alternate_serial_number
  associated_pn {
    associated_pn
  }
  audit_date
  checked_out_by {
    ...UserMinimal
  }
  condition {
    id
    choice
    sub_choice
  }
  condition_comment
  country_of_origin {
    name
    code
    unicode_flag
  }
  creation_date
  creator {
    id
    initials
    full_name
  }
  fw
  grade {
    id
    choice
    sub_choice
  }
  hot
  id
  internal
  inventory_comment
  is_lost
  last_write_down_date
  location {
    id
    name
    site {
      id
      name
      short_name
    }
  }
  lock_reason
  locked
  next_write_down_date
  part {
    ...BasicPart
  }
  purchase_rma_item_details {
    id
    rma {
      id
    }
  }
  purchases_items_details {
    id
    line_number
    current_cost {
      ...Money
    }
    original_cost {
      ...Money
    }
    transaction {
      id
      rep {
        id
        initials
      }
    }
  }
  quantity
  receive_status
  received_by {
    ...UserMinimal
  }
  received_date
  rma_item_details {
    id
    rma {
      id
    }
  }
  sales_items_details {
    id
    line_number
    sold_for {
      ...Money
    }
    transaction {
      id
      rep {
        id
        initials
      }
    }
  }
  serial_number
  shipment {
    id
  }
  system {
    id
  }
  system_logs {
    id
    action {
      id
      action
    }
  }
  test_status {
    id
    tag
    description
    color
  }
  unbooked_date
  updated_date
}
    ${UserMinimalFragmentDoc}
${BasicPartFragmentDoc}
${MoneyFragmentDoc}`;
export const PurchasedItemFragmentDoc = gql`
    fragment PurchasedItem on Purchases_PurchasedItems {
  current_cost {
    ...Money
  }
  edit_lock
  id
  item {
    ...InventoryItem
    sale: sales_items_details {
      id
    }
    purchase_rma_item_details {
      id
      rma {
        id
      }
    }
  }
  line_number
  original_cost {
    ...Money
  }
  part {
    ...BasicPart
  }
  shipment {
    id
  }
  swap_type
  updated_date
}
    ${MoneyFragmentDoc}
${InventoryItemFragmentDoc}
${BasicPartFragmentDoc}`;
export const SaleForGridFragmentDoc = gql`
    fragment SaleForGrid on Sales_Sale {
  book_date
  client {
    id
    name
    short_name
  }
  client_order_number
  condition {
    id
    choice
    sub_choice
  }
  contact {
    id
    full_name
    email
    phone
  }
  contract_comment
  contract_send_by_date
  creation_date
  creator {
    id
    initials
  }
  estimated_cost {
    ...Money
  }
  estimated_margin {
    ...Money
  }
  id
  internal_comment
  freight {
    ...Money
  }
  promise_date
  quote {
    id
  }
  rep {
    id
    initials
    full_name
    email
  }
  sale_date
  ship_by_date
  shipment_order {
    id
    blind
    tracking_number
    pickup_type
    status {
      id
      status
    }
  }
  status {
    id
    status
    status_type
  }
  tax {
    ...Money
  }
  terms {
    id
    name
    notes
  }
  estimated_total {
    ...Money
  }
}
    ${MoneyFragmentDoc}`;
export const SaleFragmentDoc = gql`
    fragment Sale on Sales_Sale {
  book_date
  client {
    id
    name
    short_name
  }
  client_order_number
  condition {
    id
    choice
    sub_choice
  }
  contact {
    id
    full_name
    first_name
    last_name
    email
    phone
  }
  contract_comment
  contract_send_by_date
  creation_date
  creator {
    id
    initials
  }
  estimated_cost {
    ...Money
  }
  estimated_margin {
    ...Money
  }
  id
  internal_comment
  freight {
    ...Money
  }
  promise_date
  quote {
    id
  }
  rep {
    id
    initials
    full_name
    email
  }
  sale_date
  ship_by_date
  shipment_order {
    id
    blind
    tracking_number
    pickup_type
    status {
      id
      status
    }
  }
  st_items {
    id
    item {
      id
    }
  }
  status {
    id
    status
    status_type
  }
  tax {
    ...Money
  }
  terms {
    id
    name
    notes
  }
  estimated_total {
    ...Money
  }
}
    ${MoneyFragmentDoc}`;
export const AddressFullFragmentDoc = gql`
    fragment AddressFull on Address_Address {
  ...AddressGrid
  carrier_facility
  creation_date
  creator {
    id
    initials
  }
  default_billing_for_client {
    id
  }
  default_billing_for_company {
    id
  }
  default_remit_for_client {
    id
  }
  default_remit_for_company {
    id
  }
  default_return_to_for_client {
    id
  }
  default_remit_for_company {
    id
  }
  default_ship_from_for_client {
    id
  }
  default_ship_from_for_company {
    id
  }
  default_ship_to_for_client {
    id
  }
  default_ship_to_for_company {
    id
  }
  residential
  updated_date
}
    ${AddressGridFragmentDoc}`;
export const Contact_ListFragmentDoc = gql`
    fragment Contact_List on Clients_Contact {
  id
  full_name
  email
  phone
  owner {
    id
  }
}
    `;
export const Contact_GridFragmentDoc = gql`
    fragment Contact_Grid on Clients_Contact {
  ...Contact_List
  fax
  first_name
  last_name
  default_billing_for_contact {
    id
  }
  default_remit_for_contact {
    id
  }
  default_ship_to_for_contact {
    id
  }
}
    ${Contact_ListFragmentDoc}`;
export const Contact_FullFragmentDoc = gql`
    fragment Contact_Full on Clients_Contact {
  ...Contact_Grid
  attached_address {
    ...AddressList
  }
}
    ${Contact_GridFragmentDoc}
${AddressListFragmentDoc}`;
export const FullUserFragmentDoc = gql`
    fragment FullUser on Users_User {
  id
  dateJoined: date_joined
  department: departments_m2m {
    id
    department {
      id
      name
      roles {
        id
        role {
          id
          name
        }
      }
    }
  }
  email
  firstName: first_name
  fullName: full_name
  initials
  isActive: is_active
  isStaff: is_staff
  isSuperUser: is_superuser
  lastLogin: last_login
  lastName: last_name
  subs: subordinates {
    id
    user {
      id
      initials
    }
  }
  team: teams_m2m {
    id
    member {
      id
      initials
    }
  }
  username
}
    `;
export const ShipmentOrderToEditFragmentDoc = gql`
    fragment ShipmentOrderToEdit on Shipping_ShipmentOrder {
  id
  service {
    id
    service
  }
  account {
    id
  }
  ship_to_address {
    ...AddressFull
  }
  ship_to_contact {
    ...Contact_Full
  }
  ship_to_rep {
    ...FullUser
  }
  ship_from_address {
    ...AddressFull
  }
  ship_from_contact {
    ...Contact_Full
  }
  ship_from_rep {
    ...FullUser
  }
  purchaser_address {
    ...AddressFull
  }
  purchaser_contact {
    ...Contact_Full
  }
  purchaser_rep {
    ...FullUser
  }
  blind
  tracking_email_recipients
  pickup_type
  we_pay
}
    ${AddressFullFragmentDoc}
${Contact_FullFragmentDoc}
${FullUserFragmentDoc}`;
export const SaleForEditFragmentDoc = gql`
    fragment SaleForEdit on Sales_Sale {
  book_date
  client {
    id
    name
  }
  client_order_number
  contact {
    id
    first_name
    last_name
    full_name
    email
    owner {
      id
    }
  }
  contract_comment
  contract_send_by_date
  creation_date
  creator {
    id
    initials
    full_name
  }
  declared_value {
    ...Money
  }
  deposit {
    ...Money
  }
  down_payment {
    ...Money
  }
  freight {
    ...Money
  }
  installation_cost {
    ...Money
  }
  id
  internal_comment
  misc {
    ...Money
  }
  promise_date
  quote {
    id
  }
  rep {
    id
    full_name
    initials
  }
  ship_by_date
  shipment_order {
    ...ShipmentOrderToEdit
  }
  tax {
    ...Money
  }
  terms {
    id
    name
  }
  we_install
  we_transport
}
    ${MoneyFragmentDoc}
${ShipmentOrderToEditFragmentDoc}`;
export const PurchaseForEditFragmentDoc = gql`
    fragment PurchaseForEdit on Purchases_Purchase {
  acceptance_date
  approval_date
  client {
    id
    name
  }
  client_order_number
  closing_date
  condition {
    id
    choice
  }
  contact {
    id
    first_name
    last_name
    full_name
    email
    owner {
      id
    }
  }
  contract_comment
  creation_date
  creator {
    id
    initials
    full_name
  }
  down_payment {
    ...Money
  }
  eta
  freight {
    ...Money
  }
  id
  internal_comment
  misc {
    ...Money
  }
  purchase_date
  quote {
    id
  }
  rep {
    id
    full_name
    initials
  }
  requested_date
  shipment_order {
    ...ShipmentOrderToEdit
  }
  tax {
    ...Money
  }
  terms {
    id
    name
  }
  vendor_reference
  warranty_end_date
  we_transport
}
    ${MoneyFragmentDoc}
${ShipmentOrderToEditFragmentDoc}`;
export const SoldItemFragmentDoc = gql`
    fragment SoldItem on Sales_SoldItems {
  edit_lock
  id
  item {
    ...InventoryItem
    rma_item_details {
      id
      rma {
        id
      }
    }
    purchases_items_details {
      id
      current_cost {
        ...Money
      }
      line_number
      original_cost {
        ...Money
      }
      transaction {
        id
      }
    }
  }
  line_number
  original_part {
    ...BasicPart
  }
  part {
    ...BasicPart
  }
  shipment {
    id
  }
  status {
    id
    status
  }
  sold_for {
    ...Money
  }
  swap_type
  updated_date
}
    ${InventoryItemFragmentDoc}
${MoneyFragmentDoc}
${BasicPartFragmentDoc}`;
export const SaleForPdfFragmentDoc = gql`
    fragment SaleForPDF on Sales_Sale {
  client {
    id
    name
    short_name
  }
  client_order_number
  contact {
    id
    email
    phone
    full_name
  }
  id
  contract_comment
  st_items {
    id
    line_number
    part {
      id
      pn
      description
      brand {
        id
        name
      }
      mfgr {
        id
        name
      }
      type
    }
    sold_for {
      ...Money
    }
  }
  rep {
    id
    initials
    full_name
    email
  }
  shipment_order {
    id
    blind
    purchaser_contact {
      ...Contact_List
    }
    purchaser_address {
      id
      street_1
      street_2
      city
      state
      country {
        code
        name
      }
      zip_code
      company {
        name
      }
      client {
        id
        name
      }
      default_contact {
        id
        full_name
      }
    }
    ship_from_contact {
      ...Contact_List
    }
    ship_from_address {
      id
      street_1
      street_2
      city
      state
      country {
        code
        name
      }
      zip_code
      company {
        name
      }
      client {
        id
        name
      }
      default_contact {
        id
        full_name
      }
    }
    ship_to_contact {
      ...Contact_List
    }
    ship_to_address {
      id
      street_1
      street_2
      city
      state
      country {
        code
        name
      }
      zip_code
      client {
        id
        name
      }
      company {
        name
      }
      default_contact {
        id
        full_name
      }
    }
  }
  status {
    id
    status
  }
  estimated_total {
    ...Money
  }
  sale_date
  terms {
    id
    name
  }
}
    ${MoneyFragmentDoc}
${Contact_ListFragmentDoc}`;
export const SaleForDeleteFragmentDoc = gql`
    fragment SaleForDelete on Sales_Sale {
  id
  st_items {
    id
    line_number
    item {
      id
    }
  }
  shipment_order {
    id
    shipments {
      id
    }
  }
}
    `;
export const SaleForMoneyUpdateFragmentDoc = gql`
    fragment SaleForMoneyUpdate on Sales_Sale {
  id
  estimated_cost {
    ...Money
  }
  estimated_margin {
    ...Money
  }
  st_items {
    id
    sold_for {
      ...Money
    }
    item {
      id
      purchases_items_details {
        id
        original_cost {
          ...Money
        }
      }
    }
  }
}
    ${MoneyFragmentDoc}`;
export const DepartmentFragmentDoc = gql`
    fragment Department on Department_Department {
  id
  name
  description
}
    `;
export const RoleFragmentDoc = gql`
    fragment Role on CustomPermissions_Role {
  id
  name
  description
}
    `;
export const GraphQlPermissionsFragmentDoc = gql`
    fragment GraphQlPermissions on CustomPermissions_GraphQLPermissions {
  id
  name
  name_type
  roles {
    id
    role {
      ...Role
    }
  }
}
    ${RoleFragmentDoc}`;
export const RolePermissionFragmentDoc = gql`
    fragment RolePermission on CustomPermissions_RolePermissions {
  id
  permission {
    ...GraphQlPermissions
  }
  role {
    ...Role
  }
}
    ${GraphQlPermissionsFragmentDoc}
${RoleFragmentDoc}`;
export const User_GridFragmentDoc = gql`
    fragment User_Grid on Users_User {
  id
  full_name
  first_name
  last_name
  last_login
  email
  is_active
  profile {
    id
    commission_percentage
  }
}
    `;
export const Profile_Profile__FullFragmentDoc = gql`
    fragment Profile_Profile__Full on Profile_Profile {
  color
  column_config
  commission_percentage
  default_site {
    id
    name
  }
  hire_date
  id
  job_title
  last_review
  manager {
    id
    full_name
    initials
  }
  order_cache
  settings
  terminated
  terminated_date
  upcoming_review
  user {
    id
    full_name
    initials
  }
  wage
}
    `;
export const QuotePartFragmentDoc = gql`
    fragment QuotePart on Quotes_QuoteParts {
  id
  creator {
    id
    initials
  }
  part {
    id
    pn
    description
  }
  price_per_part {
    ...Money
  }
  qty
  total {
    ...Money
  }
  quote {
    id
    creator {
      id
      initials
    }
  }
}
    ${MoneyFragmentDoc}`;
export const QuoteForSaleImportFragmentDoc = gql`
    fragment QuoteForSaleImport on Quotes_Quote {
  id
  creator {
    id
    initials
    full_name
  }
  client {
    id
    name
    short_name
    credit_limit {
      ...Money
    }
    internal_rep {
      id
      initials
    }
  }
  contact {
    id
    full_name
    email
    owner {
      id
      name
    }
  }
  contract_comment
  internal_comment
  parts {
    ...QuotePart
  }
}
    ${MoneyFragmentDoc}
${QuotePartFragmentDoc}`;
export const QuoteForPdfFragmentDoc = gql`
    fragment QuoteForPdf on Quotes_Quote {
  ...QuoteForSaleImport
  ship_to_address {
    ...AddressGrid
  }
  ship_from_address {
    ...AddressGrid
  }
  creation_date
}
    ${QuoteForSaleImportFragmentDoc}
${AddressGridFragmentDoc}`;
export const QuoteFragmentDoc = gql`
    fragment Quote on Quotes_Quote {
  id
  attached_sales {
    id
    estimated_total {
      ...Money
    }
    rep {
      id
      initials
    }
  }
  client {
    id
    name
  }
  contact {
    id
    full_name
    email
  }
  internal_comment
  contract_comment
  creation_date
  creator {
    id
    initials
  }
  total_price {
    ...Money
  }
}
    ${MoneyFragmentDoc}`;
export const PurchaseRmaItemFragmentDoc = gql`
    fragment PurchaseRmaItem on RMA_PurchaseRMAItems {
  id
  item: returned_part {
    ...InventoryItem
    purchase: purchases_items_details {
      id
      line_number
      transaction {
        id
      }
    }
    part {
      id
      pn
      description
    }
  }
  credit
  rmaTested: tested_condition {
    id
    choice
  }
}
    ${InventoryItemFragmentDoc}`;
export const SaleRmaItemFragmentDoc = gql`
    fragment SaleRmaItem on RMA_RMAItems {
  id
  tested_condition {
    id
    choice
    sub_choice
  }
  creation_date
  updated_date
  item: returned_part {
    ...InventoryItem
    sale: sales_items_details {
      id
      line_number
      transaction {
        id
      }
    }
    part {
      id
      pn
      description
    }
  }
}
    ${InventoryItemFragmentDoc}`;
export const Reporting_Report__FullFragmentDoc = gql`
    fragment Reporting_Report__Full on Reporting_Report {
  id
  name
  description
  query
  chart_type
  data
  recipients {
    id
    email
    full_name
    initials
  }
}
    `;
export const Shipping_Shippment_FullFragmentDoc = gql`
    fragment Shipping_Shippment_Full on Shipping_Shipment {
  id
  box_number
  easypost_id
  index: box_number
  predefined: predefined_parcel
  length: parcel_length_in
  width: parcel_width_in
  height: parcel_height_in
  weight: parcel_weight_oz
}
    `;
export const ShipmentOrderDetails_FullFragmentDoc = gql`
    fragment ShipmentOrderDetails_Full on Shipping_ShipmentOrder {
  id
  account {
    id
    name
    number
    shipper {
      id
      name
    }
    easypost_id
  }
  assignee: assigned_to {
    id
    full_name
    initials
  }
  sale: attached_sale {
    id
    comments: internal_comment
  }
  blind
  deliverBy: deliver_by_date
  easypost_id
  isInternational: is_international
  billTo: purchaser_address {
    ...AddressFull
  }
  billToContact: purchaser_contact {
    id
    full_name
    email
    phone
  }
  pickup_type
  release: release_number
  returnTo: return_address {
    ...AddressFull
  }
  service {
    id
    easypost_name
    service
    shipper {
      id
      name
    }
  }
  shipFrom: ship_from_address {
    ...AddressFull
  }
  shipFromContact: ship_from_contact {
    id
    full_name
    email
    phone
  }
  shipTo: ship_to_address {
    ...AddressFull
  }
  shipToContact: ship_to_contact {
    id
    full_name
    email
    phone
  }
  shipments {
    ...Shipping_Shippment_Full
  }
  comments: shipping_comments
  status {
    id
    status
  }
  logs: system_logs {
    id
    action {
      id
      action
      description
    }
  }
  tracking: tracking_number
  wePay: we_pay
}
    ${AddressFullFragmentDoc}
${Shipping_Shippment_FullFragmentDoc}`;
export const ShipmentOrderDetailsFragmentDoc = gql`
    fragment ShipmentOrderDetails on Shipping_ShipmentOrder {
  id
  purchase: attached_purchase {
    id
    client {
      id
    }
  }
  sale: attached_sale {
    id
    client {
      id
    }
  }
  account {
    id
    name
    number
  }
  blind
  deliver_by_date
  service {
    id
    shipper {
      id
      name
    }
    service
  }
  shipFrom: ship_from_address {
    ...AddressFull
  }
  shipFromRep: ship_from_rep {
    id
    full_name
    email
  }
  shipFromContact: ship_from_contact {
    id
    phone
    email
    full_name
  }
  shipToRep: ship_to_rep {
    id
    full_name
    email
  }
  shipTo: ship_to_address {
    ...AddressFull
  }
  shipToContact: ship_to_contact {
    id
    phone
    email
    full_name
  }
  billToRep: purchaser_rep {
    id
    full_name
    email
  }
  billTo: purchaser_address {
    ...AddressFull
  }
  billToContact: purchaser_contact {
    id
    phone
    email
    full_name
  }
  cost: total_billed_cost {
    ...Money
  }
  status {
    id
    status
  }
  tracking_number
  pickupType: pickup_type
  we_pay
}
    ${AddressFullFragmentDoc}
${MoneyFragmentDoc}`;
export const ShipmentOrderForListFragmentDoc = gql`
    fragment ShipmentOrderForList on Shipping_ShipmentOrder {
  id
  status {
    id
    status
    order
  }
  sale: attached_sale {
    id
    rep {
      id
      initials
    }
  }
  prma: attached_purchase_rma {
    id
    order: pt {
      id
      rep {
        id
        initials
      }
    }
  }
  srma: attached_rma {
    id
    order: st {
      id
      rep {
        id
        initials
      }
    }
  }
  assignee: assigned_to {
    id
    initials
    profile {
      id
      color
    }
  }
  isInternational: is_international
  service {
    id
    color
    service
    carrier: shipper {
      id
      name
    }
  }
  pickup_type
}
    `;
export const WorkOrderListFragmentDoc = gql`
    fragment WorkOrderList on WorkOrder_WorkOrder {
  amount {
    ...Money
  }
  contract_comment
  creation_date
  creator {
    id
    initials
    full_name
  }
  id
  internal_comment
  need_by
  purchase_transaction {
    id
    client {
      id
      name
      short_name
    }
  }
  shipment_order {
    id
  }
  status {
    id
    status
  }
  terms {
    id
    days
    description
  }
  type {
    id
    choice
    sub_choice
  }
  updated_date
  vendor {
    id
    name
    short_name
  }
  warranty_end_date
}
    ${MoneyFragmentDoc}`;
export const WorkOrderItemFragmentDoc = gql`
    fragment WorkOrderItem on WorkOrder_WorkOrderItems {
  id
  item {
    id
    part {
      id
      pn
      description
    }
  }
}
    `;
export const WorkOrderForPdfFragmentDoc = gql`
    fragment WorkOrderForPdf on WorkOrder_WorkOrder {
  id
  amount {
    ...Money
  }
  contract_comment
  creation_date
  creator {
    id
    initials
  }
  need_by
  purchase_transaction {
    id
    rep {
      id
      initials
      full_name
      email
    }
  }
  shipment_order {
    id
    blind
    purchaser_contact {
      ...Contact_List
    }
    purchaser_address {
      id
      street_1
      street_2
      city
      state
      country {
        code
        name
      }
      zip_code
      company {
        name
      }
      client {
        id
        name
      }
      default_contact {
        id
        full_name
      }
    }
    ship_from_contact {
      ...Contact_List
    }
    ship_from_address {
      id
      street_1
      street_2
      city
      state
      country {
        code
        name
      }
      zip_code
      company {
        name
      }
      client {
        id
        name
      }
      default_contact {
        id
        full_name
      }
    }
    ship_to_contact {
      ...Contact_List
    }
    ship_to_address {
      id
      street_1
      street_2
      city
      state
      country {
        code
        name
      }
      zip_code
      client {
        id
        name
      }
      company {
        name
      }
      default_contact {
        id
        full_name
      }
    }
  }
  terms {
    ...Terms_List
  }
  vendor {
    id
    name
  }
  wo_items {
    ...WorkOrderItem
  }
}
    ${MoneyFragmentDoc}
${Contact_ListFragmentDoc}
${Terms_ListFragmentDoc}
${WorkOrderItemFragmentDoc}`;
export const GetAccountingTransactions_GridDocument = gql`
    query GetAccountingTransactions_Grid($input: ManyQueryInput!) {
  accounting_transactions(input: $input) {
    annotations {
      name
      value
    }
    ...Accounting_Transaction__Grid
  }
}
    ${Accounting_Transaction__GridFragmentDoc}`;

/**
 * __useGetAccountingTransactions_GridQuery__
 *
 * To run a query within a Vue component, call `useGetAccountingTransactions_GridQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountingTransactions_GridQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAccountingTransactions_GridQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetAccountingTransactions_GridQuery(variables: GetAccountingTransactions_GridQueryVariables | VueCompositionApi.Ref<GetAccountingTransactions_GridQueryVariables> | ReactiveFunction<GetAccountingTransactions_GridQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetAccountingTransactions_GridQuery, GetAccountingTransactions_GridQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetAccountingTransactions_GridQuery, GetAccountingTransactions_GridQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetAccountingTransactions_GridQuery, GetAccountingTransactions_GridQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetAccountingTransactions_GridQuery, GetAccountingTransactions_GridQueryVariables>(GetAccountingTransactions_GridDocument, variables, options);
}
export type GetAccountingTransactions_GridQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetAccountingTransactions_GridQuery, GetAccountingTransactions_GridQueryVariables>;
export const GetAccountingEntries_GridDocument = gql`
    query GetAccountingEntries_Grid($input: ManyQueryInput!) {
  accounting_entrys(input: $input) {
    ...Accounting_Entry__GRID
    annotations {
      name
      value
    }
  }
}
    ${Accounting_Entry__GridFragmentDoc}`;

/**
 * __useGetAccountingEntries_GridQuery__
 *
 * To run a query within a Vue component, call `useGetAccountingEntries_GridQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountingEntries_GridQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAccountingEntries_GridQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetAccountingEntries_GridQuery(variables: GetAccountingEntries_GridQueryVariables | VueCompositionApi.Ref<GetAccountingEntries_GridQueryVariables> | ReactiveFunction<GetAccountingEntries_GridQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetAccountingEntries_GridQuery, GetAccountingEntries_GridQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetAccountingEntries_GridQuery, GetAccountingEntries_GridQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetAccountingEntries_GridQuery, GetAccountingEntries_GridQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetAccountingEntries_GridQuery, GetAccountingEntries_GridQueryVariables>(GetAccountingEntries_GridDocument, variables, options);
}
export type GetAccountingEntries_GridQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetAccountingEntries_GridQuery, GetAccountingEntries_GridQueryVariables>;
export const Get_Accounting_Accounts__ListDocument = gql`
    query Get_Accounting_Accounts__List($input: ManyQueryInput!) {
  accounting_accounts(input: $input) {
    ...Accounting_Account__List
  }
}
    ${Accounting_Account__ListFragmentDoc}`;

/**
 * __useGet_Accounting_Accounts__ListQuery__
 *
 * To run a query within a Vue component, call `useGet_Accounting_Accounts__ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Accounting_Accounts__ListQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGet_Accounting_Accounts__ListQuery({
 *   input: // value for 'input'
 * });
 */
export function useGet_Accounting_Accounts__ListQuery(variables: Get_Accounting_Accounts__ListQueryVariables | VueCompositionApi.Ref<Get_Accounting_Accounts__ListQueryVariables> | ReactiveFunction<Get_Accounting_Accounts__ListQueryVariables>, options: VueApolloComposable.UseQueryOptions<Get_Accounting_Accounts__ListQuery, Get_Accounting_Accounts__ListQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Get_Accounting_Accounts__ListQuery, Get_Accounting_Accounts__ListQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Get_Accounting_Accounts__ListQuery, Get_Accounting_Accounts__ListQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Get_Accounting_Accounts__ListQuery, Get_Accounting_Accounts__ListQueryVariables>(Get_Accounting_Accounts__ListDocument, variables, options);
}
export type Get_Accounting_Accounts__ListQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Get_Accounting_Accounts__ListQuery, Get_Accounting_Accounts__ListQueryVariables>;
export const Get_Accounting_Accounts__GridDocument = gql`
    query Get_Accounting_Accounts__Grid($input: ManyQueryInput!) {
  accounting_accounts(input: $input) {
    ...Accounting_Account__Grid
    annotations {
      name
      value
    }
  }
}
    ${Accounting_Account__GridFragmentDoc}`;

/**
 * __useGet_Accounting_Accounts__GridQuery__
 *
 * To run a query within a Vue component, call `useGet_Accounting_Accounts__GridQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Accounting_Accounts__GridQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGet_Accounting_Accounts__GridQuery({
 *   input: // value for 'input'
 * });
 */
export function useGet_Accounting_Accounts__GridQuery(variables: Get_Accounting_Accounts__GridQueryVariables | VueCompositionApi.Ref<Get_Accounting_Accounts__GridQueryVariables> | ReactiveFunction<Get_Accounting_Accounts__GridQueryVariables>, options: VueApolloComposable.UseQueryOptions<Get_Accounting_Accounts__GridQuery, Get_Accounting_Accounts__GridQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Get_Accounting_Accounts__GridQuery, Get_Accounting_Accounts__GridQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Get_Accounting_Accounts__GridQuery, Get_Accounting_Accounts__GridQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Get_Accounting_Accounts__GridQuery, Get_Accounting_Accounts__GridQueryVariables>(Get_Accounting_Accounts__GridDocument, variables, options);
}
export type Get_Accounting_Accounts__GridQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Get_Accounting_Accounts__GridQuery, Get_Accounting_Accounts__GridQueryVariables>;
export const Get_Accounting_Ap_Invoices__FullDocument = gql`
    query Get_Accounting_AP_Invoices__Full($input: ManyQueryInput!) {
  invoices_a_p_invoices(input: $input) {
    ...Invoice_AP__FULL
    annotations {
      name
      value
    }
  }
}
    ${Invoice_Ap__FullFragmentDoc}`;

/**
 * __useGet_Accounting_Ap_Invoices__FullQuery__
 *
 * To run a query within a Vue component, call `useGet_Accounting_Ap_Invoices__FullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Accounting_Ap_Invoices__FullQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGet_Accounting_Ap_Invoices__FullQuery({
 *   input: // value for 'input'
 * });
 */
export function useGet_Accounting_Ap_Invoices__FullQuery(variables: Get_Accounting_Ap_Invoices__FullQueryVariables | VueCompositionApi.Ref<Get_Accounting_Ap_Invoices__FullQueryVariables> | ReactiveFunction<Get_Accounting_Ap_Invoices__FullQueryVariables>, options: VueApolloComposable.UseQueryOptions<Get_Accounting_Ap_Invoices__FullQuery, Get_Accounting_Ap_Invoices__FullQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Get_Accounting_Ap_Invoices__FullQuery, Get_Accounting_Ap_Invoices__FullQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Get_Accounting_Ap_Invoices__FullQuery, Get_Accounting_Ap_Invoices__FullQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Get_Accounting_Ap_Invoices__FullQuery, Get_Accounting_Ap_Invoices__FullQueryVariables>(Get_Accounting_Ap_Invoices__FullDocument, variables, options);
}
export type Get_Accounting_Ap_Invoices__FullQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Get_Accounting_Ap_Invoices__FullQuery, Get_Accounting_Ap_Invoices__FullQueryVariables>;
export const Get_Accounting_Ar_Invoices__FullDocument = gql`
    query Get_Accounting_AR_Invoices__Full($input: ManyQueryInput!) {
  invoices_a_r_invoices(input: $input) {
    ...Invoice_AR__FULL
    annotations {
      name
      value
    }
  }
}
    ${Invoice_Ar__FullFragmentDoc}`;

/**
 * __useGet_Accounting_Ar_Invoices__FullQuery__
 *
 * To run a query within a Vue component, call `useGet_Accounting_Ar_Invoices__FullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Accounting_Ar_Invoices__FullQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGet_Accounting_Ar_Invoices__FullQuery({
 *   input: // value for 'input'
 * });
 */
export function useGet_Accounting_Ar_Invoices__FullQuery(variables: Get_Accounting_Ar_Invoices__FullQueryVariables | VueCompositionApi.Ref<Get_Accounting_Ar_Invoices__FullQueryVariables> | ReactiveFunction<Get_Accounting_Ar_Invoices__FullQueryVariables>, options: VueApolloComposable.UseQueryOptions<Get_Accounting_Ar_Invoices__FullQuery, Get_Accounting_Ar_Invoices__FullQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Get_Accounting_Ar_Invoices__FullQuery, Get_Accounting_Ar_Invoices__FullQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Get_Accounting_Ar_Invoices__FullQuery, Get_Accounting_Ar_Invoices__FullQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Get_Accounting_Ar_Invoices__FullQuery, Get_Accounting_Ar_Invoices__FullQueryVariables>(Get_Accounting_Ar_Invoices__FullDocument, variables, options);
}
export type Get_Accounting_Ar_Invoices__FullQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Get_Accounting_Ar_Invoices__FullQuery, Get_Accounting_Ar_Invoices__FullQueryVariables>;
export const Get_Accounting_Invoice_For_StDocument = gql`
    query Get_Accounting_Invoice_For_ST($st: Any!) {
  invoices_a_r_invoices(input: {filters: [{key: "order__id", value: $st}]}) {
    id
  }
}
    `;

/**
 * __useGet_Accounting_Invoice_For_StQuery__
 *
 * To run a query within a Vue component, call `useGet_Accounting_Invoice_For_StQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Accounting_Invoice_For_StQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGet_Accounting_Invoice_For_StQuery({
 *   st: // value for 'st'
 * });
 */
export function useGet_Accounting_Invoice_For_StQuery(variables: Get_Accounting_Invoice_For_StQueryVariables | VueCompositionApi.Ref<Get_Accounting_Invoice_For_StQueryVariables> | ReactiveFunction<Get_Accounting_Invoice_For_StQueryVariables>, options: VueApolloComposable.UseQueryOptions<Get_Accounting_Invoice_For_StQuery, Get_Accounting_Invoice_For_StQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Get_Accounting_Invoice_For_StQuery, Get_Accounting_Invoice_For_StQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Get_Accounting_Invoice_For_StQuery, Get_Accounting_Invoice_For_StQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Get_Accounting_Invoice_For_StQuery, Get_Accounting_Invoice_For_StQueryVariables>(Get_Accounting_Invoice_For_StDocument, variables, options);
}
export type Get_Accounting_Invoice_For_StQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Get_Accounting_Invoice_For_StQuery, Get_Accounting_Invoice_For_StQueryVariables>;
export const GetInvoicePaymentsForApInvoiceDocument = gql`
    query GetInvoicePaymentsForApInvoice($input: ManyQueryInput!) {
  invoices: invoices_a_p_payments(input: $input) {
    ...Invoice_AP_Payment__Full
    annotations {
      name
      value
    }
  }
}
    ${Invoice_Ap_Payment__FullFragmentDoc}`;

/**
 * __useGetInvoicePaymentsForApInvoiceQuery__
 *
 * To run a query within a Vue component, call `useGetInvoicePaymentsForApInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicePaymentsForApInvoiceQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetInvoicePaymentsForApInvoiceQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetInvoicePaymentsForApInvoiceQuery(variables: GetInvoicePaymentsForApInvoiceQueryVariables | VueCompositionApi.Ref<GetInvoicePaymentsForApInvoiceQueryVariables> | ReactiveFunction<GetInvoicePaymentsForApInvoiceQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetInvoicePaymentsForApInvoiceQuery, GetInvoicePaymentsForApInvoiceQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetInvoicePaymentsForApInvoiceQuery, GetInvoicePaymentsForApInvoiceQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetInvoicePaymentsForApInvoiceQuery, GetInvoicePaymentsForApInvoiceQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetInvoicePaymentsForApInvoiceQuery, GetInvoicePaymentsForApInvoiceQueryVariables>(GetInvoicePaymentsForApInvoiceDocument, variables, options);
}
export type GetInvoicePaymentsForApInvoiceQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetInvoicePaymentsForApInvoiceQuery, GetInvoicePaymentsForApInvoiceQueryVariables>;
export const GetInvoicePaymentsForArInvoiceDocument = gql`
    query GetInvoicePaymentsForArInvoice($input: ManyQueryInput!) {
  invoices: invoices_a_r_payments(input: $input) {
    ...Invoice_AR_Payment__Full
    annotations {
      name
      value
    }
  }
}
    ${Invoice_Ar_Payment__FullFragmentDoc}`;

/**
 * __useGetInvoicePaymentsForArInvoiceQuery__
 *
 * To run a query within a Vue component, call `useGetInvoicePaymentsForArInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicePaymentsForArInvoiceQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetInvoicePaymentsForArInvoiceQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetInvoicePaymentsForArInvoiceQuery(variables: GetInvoicePaymentsForArInvoiceQueryVariables | VueCompositionApi.Ref<GetInvoicePaymentsForArInvoiceQueryVariables> | ReactiveFunction<GetInvoicePaymentsForArInvoiceQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetInvoicePaymentsForArInvoiceQuery, GetInvoicePaymentsForArInvoiceQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetInvoicePaymentsForArInvoiceQuery, GetInvoicePaymentsForArInvoiceQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetInvoicePaymentsForArInvoiceQuery, GetInvoicePaymentsForArInvoiceQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetInvoicePaymentsForArInvoiceQuery, GetInvoicePaymentsForArInvoiceQueryVariables>(GetInvoicePaymentsForArInvoiceDocument, variables, options);
}
export type GetInvoicePaymentsForArInvoiceQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetInvoicePaymentsForArInvoiceQuery, GetInvoicePaymentsForArInvoiceQueryVariables>;
export const Create_Accounting_AccountDocument = gql`
    mutation Create_Accounting_Account($input: Create__Accounting_Account__Input!) {
  Create__Accounting_Account(input: $input) {
    ...Accounting_Account__Grid
  }
}
    ${Accounting_Account__GridFragmentDoc}`;

/**
 * __useCreate_Accounting_AccountMutation__
 *
 * To run a mutation, you first call `useCreate_Accounting_AccountMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreate_Accounting_AccountMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreate_Accounting_AccountMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreate_Accounting_AccountMutation(options: VueApolloComposable.UseMutationOptions<Create_Accounting_AccountMutation, Create_Accounting_AccountMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Create_Accounting_AccountMutation, Create_Accounting_AccountMutationVariables>>) {
  return VueApolloComposable.useMutation<Create_Accounting_AccountMutation, Create_Accounting_AccountMutationVariables>(Create_Accounting_AccountDocument, options);
}
export type Create_Accounting_AccountMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Create_Accounting_AccountMutation, Create_Accounting_AccountMutationVariables>;
export const Update_Accounting_AccountDocument = gql`
    mutation Update_Accounting_Account($input: Update__Accounting_Account__Input!) {
  Update__Accounting_Account(input: $input) {
    ...Accounting_Account__Grid
  }
}
    ${Accounting_Account__GridFragmentDoc}`;

/**
 * __useUpdate_Accounting_AccountMutation__
 *
 * To run a mutation, you first call `useUpdate_Accounting_AccountMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Accounting_AccountMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdate_Accounting_AccountMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_Accounting_AccountMutation(options: VueApolloComposable.UseMutationOptions<Update_Accounting_AccountMutation, Update_Accounting_AccountMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Update_Accounting_AccountMutation, Update_Accounting_AccountMutationVariables>>) {
  return VueApolloComposable.useMutation<Update_Accounting_AccountMutation, Update_Accounting_AccountMutationVariables>(Update_Accounting_AccountDocument, options);
}
export type Update_Accounting_AccountMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Update_Accounting_AccountMutation, Update_Accounting_AccountMutationVariables>;
export const CreateAccountingTransactionDocument = gql`
    mutation CreateAccountingTransaction($input: Create__Accounting_Transaction__Input!) {
  Create__Accounting_Transaction(input: $input) {
    ...Accounting_Transaction__Grid
  }
}
    ${Accounting_Transaction__GridFragmentDoc}`;

/**
 * __useCreateAccountingTransactionMutation__
 *
 * To run a mutation, you first call `useCreateAccountingTransactionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountingTransactionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateAccountingTransactionMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountingTransactionMutation(options: VueApolloComposable.UseMutationOptions<CreateAccountingTransactionMutation, CreateAccountingTransactionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateAccountingTransactionMutation, CreateAccountingTransactionMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateAccountingTransactionMutation, CreateAccountingTransactionMutationVariables>(CreateAccountingTransactionDocument, options);
}
export type CreateAccountingTransactionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateAccountingTransactionMutation, CreateAccountingTransactionMutationVariables>;
export const CreateAccountingEntryDocument = gql`
    mutation CreateAccountingEntry($input: Create__Accounting_Entry__Input!) {
  Create__Accounting_Entry(input: $input) {
    ...Accounting_Entry__GRID
  }
}
    ${Accounting_Entry__GridFragmentDoc}`;

/**
 * __useCreateAccountingEntryMutation__
 *
 * To run a mutation, you first call `useCreateAccountingEntryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountingEntryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateAccountingEntryMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountingEntryMutation(options: VueApolloComposable.UseMutationOptions<CreateAccountingEntryMutation, CreateAccountingEntryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateAccountingEntryMutation, CreateAccountingEntryMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateAccountingEntryMutation, CreateAccountingEntryMutationVariables>(CreateAccountingEntryDocument, options);
}
export type CreateAccountingEntryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateAccountingEntryMutation, CreateAccountingEntryMutationVariables>;
export const CreateAccountingArInvoiceDocument = gql`
    mutation CreateAccountingArInvoice($input: Create__Invoices_ARInvoice__Input!) {
  Create__Invoices_ARInvoice(input: $input) {
    ...Invoice_AR__FULL
  }
}
    ${Invoice_Ar__FullFragmentDoc}`;

/**
 * __useCreateAccountingArInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateAccountingArInvoiceMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountingArInvoiceMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateAccountingArInvoiceMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountingArInvoiceMutation(options: VueApolloComposable.UseMutationOptions<CreateAccountingArInvoiceMutation, CreateAccountingArInvoiceMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateAccountingArInvoiceMutation, CreateAccountingArInvoiceMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateAccountingArInvoiceMutation, CreateAccountingArInvoiceMutationVariables>(CreateAccountingArInvoiceDocument, options);
}
export type CreateAccountingArInvoiceMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateAccountingArInvoiceMutation, CreateAccountingArInvoiceMutationVariables>;
export const UpdateAccountingArInvoiceDocument = gql`
    mutation UpdateAccountingArInvoice($input: Update__Invoices_ARInvoice__Input!) {
  Update__Invoices_ARInvoice(input: $input) {
    ...Invoice_AR__FULL
  }
}
    ${Invoice_Ar__FullFragmentDoc}`;

/**
 * __useUpdateAccountingArInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateAccountingArInvoiceMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountingArInvoiceMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateAccountingArInvoiceMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountingArInvoiceMutation(options: VueApolloComposable.UseMutationOptions<UpdateAccountingArInvoiceMutation, UpdateAccountingArInvoiceMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateAccountingArInvoiceMutation, UpdateAccountingArInvoiceMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateAccountingArInvoiceMutation, UpdateAccountingArInvoiceMutationVariables>(UpdateAccountingArInvoiceDocument, options);
}
export type UpdateAccountingArInvoiceMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateAccountingArInvoiceMutation, UpdateAccountingArInvoiceMutationVariables>;
export const CreateAccountingApInvoiceDocument = gql`
    mutation CreateAccountingApInvoice($input: Create__Invoices_APInvoice__Input!) {
  Create__Invoices_APInvoice(input: $input) {
    ...Invoice_AP__FULL
  }
}
    ${Invoice_Ap__FullFragmentDoc}`;

/**
 * __useCreateAccountingApInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateAccountingApInvoiceMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountingApInvoiceMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateAccountingApInvoiceMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountingApInvoiceMutation(options: VueApolloComposable.UseMutationOptions<CreateAccountingApInvoiceMutation, CreateAccountingApInvoiceMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateAccountingApInvoiceMutation, CreateAccountingApInvoiceMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateAccountingApInvoiceMutation, CreateAccountingApInvoiceMutationVariables>(CreateAccountingApInvoiceDocument, options);
}
export type CreateAccountingApInvoiceMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateAccountingApInvoiceMutation, CreateAccountingApInvoiceMutationVariables>;
export const UpdateAccountingApInvoiceDocument = gql`
    mutation UpdateAccountingApInvoice($input: Update__Invoices_APInvoice__Input!) {
  Update__Invoices_APInvoice(input: $input) {
    ...Invoice_AP__FULL
  }
}
    ${Invoice_Ap__FullFragmentDoc}`;

/**
 * __useUpdateAccountingApInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateAccountingApInvoiceMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountingApInvoiceMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateAccountingApInvoiceMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountingApInvoiceMutation(options: VueApolloComposable.UseMutationOptions<UpdateAccountingApInvoiceMutation, UpdateAccountingApInvoiceMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateAccountingApInvoiceMutation, UpdateAccountingApInvoiceMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateAccountingApInvoiceMutation, UpdateAccountingApInvoiceMutationVariables>(UpdateAccountingApInvoiceDocument, options);
}
export type UpdateAccountingApInvoiceMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateAccountingApInvoiceMutation, UpdateAccountingApInvoiceMutationVariables>;
export const CreateInvoiceApPaymentDocument = gql`
    mutation CreateInvoiceAPPayment($input: Create__Invoices_APPayment__Input!) {
  invoice: Create__Invoices_APPayment(input: $input) {
    ...Invoice_AP_Payment__Full
  }
}
    ${Invoice_Ap_Payment__FullFragmentDoc}`;

/**
 * __useCreateInvoiceApPaymentMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceApPaymentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceApPaymentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateInvoiceApPaymentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoiceApPaymentMutation(options: VueApolloComposable.UseMutationOptions<CreateInvoiceApPaymentMutation, CreateInvoiceApPaymentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateInvoiceApPaymentMutation, CreateInvoiceApPaymentMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateInvoiceApPaymentMutation, CreateInvoiceApPaymentMutationVariables>(CreateInvoiceApPaymentDocument, options);
}
export type CreateInvoiceApPaymentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateInvoiceApPaymentMutation, CreateInvoiceApPaymentMutationVariables>;
export const CreateInvoiceArPaymentDocument = gql`
    mutation CreateInvoiceARPayment($input: Create__Invoices_ARPayment__Input!) {
  invoice: Create__Invoices_ARPayment(input: $input) {
    ...Invoice_AR_Payment__Full
  }
}
    ${Invoice_Ar_Payment__FullFragmentDoc}`;

/**
 * __useCreateInvoiceArPaymentMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceArPaymentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceArPaymentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateInvoiceArPaymentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoiceArPaymentMutation(options: VueApolloComposable.UseMutationOptions<CreateInvoiceArPaymentMutation, CreateInvoiceArPaymentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateInvoiceArPaymentMutation, CreateInvoiceArPaymentMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateInvoiceArPaymentMutation, CreateInvoiceArPaymentMutationVariables>(CreateInvoiceArPaymentDocument, options);
}
export type CreateInvoiceArPaymentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateInvoiceArPaymentMutation, CreateInvoiceArPaymentMutationVariables>;
export const UpdateInvoiceApPaymentDocument = gql`
    mutation UpdateInvoiceAPPayment($input: Update__Invoices_APPayment__Input!) {
  invoice: Update__Invoices_APPayment(input: $input) {
    ...Invoice_AP_Payment__Full
  }
}
    ${Invoice_Ap_Payment__FullFragmentDoc}`;

/**
 * __useUpdateInvoiceApPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceApPaymentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceApPaymentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateInvoiceApPaymentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceApPaymentMutation(options: VueApolloComposable.UseMutationOptions<UpdateInvoiceApPaymentMutation, UpdateInvoiceApPaymentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateInvoiceApPaymentMutation, UpdateInvoiceApPaymentMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateInvoiceApPaymentMutation, UpdateInvoiceApPaymentMutationVariables>(UpdateInvoiceApPaymentDocument, options);
}
export type UpdateInvoiceApPaymentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateInvoiceApPaymentMutation, UpdateInvoiceApPaymentMutationVariables>;
export const UpdateInvoiceArPaymentDocument = gql`
    mutation UpdateInvoiceARPayment($input: Update__Invoices_ARPayment__Input!) {
  invoice: Update__Invoices_ARPayment(input: $input) {
    ...Invoice_AR_Payment__Full
  }
}
    ${Invoice_Ar_Payment__FullFragmentDoc}`;

/**
 * __useUpdateInvoiceArPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceArPaymentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceArPaymentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateInvoiceArPaymentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceArPaymentMutation(options: VueApolloComposable.UseMutationOptions<UpdateInvoiceArPaymentMutation, UpdateInvoiceArPaymentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateInvoiceArPaymentMutation, UpdateInvoiceArPaymentMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateInvoiceArPaymentMutation, UpdateInvoiceArPaymentMutationVariables>(UpdateInvoiceArPaymentDocument, options);
}
export type UpdateInvoiceArPaymentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateInvoiceArPaymentMutation, UpdateInvoiceArPaymentMutationVariables>;
export const GetAddress_ListDocument = gql`
    query GetAddress_List($input: ManyQueryInput!) {
  address_addresses(input: $input) {
    ...AddressList
  }
}
    ${AddressListFragmentDoc}`;

/**
 * __useGetAddress_ListQuery__
 *
 * To run a query within a Vue component, call `useGetAddress_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddress_ListQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAddress_ListQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetAddress_ListQuery(variables: GetAddress_ListQueryVariables | VueCompositionApi.Ref<GetAddress_ListQueryVariables> | ReactiveFunction<GetAddress_ListQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetAddress_ListQuery, GetAddress_ListQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetAddress_ListQuery, GetAddress_ListQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetAddress_ListQuery, GetAddress_ListQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetAddress_ListQuery, GetAddress_ListQueryVariables>(GetAddress_ListDocument, variables, options);
}
export type GetAddress_ListQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetAddress_ListQuery, GetAddress_ListQueryVariables>;
export const GetAddress_GridDocument = gql`
    query GetAddress_Grid($input: ManyQueryInput!) {
  address_addresses(input: $input) {
    ...AddressGrid
  }
}
    ${AddressGridFragmentDoc}`;

/**
 * __useGetAddress_GridQuery__
 *
 * To run a query within a Vue component, call `useGetAddress_GridQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddress_GridQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAddress_GridQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetAddress_GridQuery(variables: GetAddress_GridQueryVariables | VueCompositionApi.Ref<GetAddress_GridQueryVariables> | ReactiveFunction<GetAddress_GridQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetAddress_GridQuery, GetAddress_GridQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetAddress_GridQuery, GetAddress_GridQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetAddress_GridQuery, GetAddress_GridQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetAddress_GridQuery, GetAddress_GridQueryVariables>(GetAddress_GridDocument, variables, options);
}
export type GetAddress_GridQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetAddress_GridQuery, GetAddress_GridQueryVariables>;
export const GetAddress_FullDocument = gql`
    query GetAddress_Full($input: ManyQueryInput!) {
  address_addresses(input: $input) {
    ...AddressFull
  }
}
    ${AddressFullFragmentDoc}`;

/**
 * __useGetAddress_FullQuery__
 *
 * To run a query within a Vue component, call `useGetAddress_FullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddress_FullQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAddress_FullQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetAddress_FullQuery(variables: GetAddress_FullQueryVariables | VueCompositionApi.Ref<GetAddress_FullQueryVariables> | ReactiveFunction<GetAddress_FullQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetAddress_FullQuery, GetAddress_FullQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetAddress_FullQuery, GetAddress_FullQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetAddress_FullQuery, GetAddress_FullQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetAddress_FullQuery, GetAddress_FullQueryVariables>(GetAddress_FullDocument, variables, options);
}
export type GetAddress_FullQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetAddress_FullQuery, GetAddress_FullQueryVariables>;
export const StandardAddressesDocument = gql`
    query StandardAddresses($client: Any!) {
  clientAddresses: address_addresses(
    input: {filters: [{key: "client__id", value: $client}]}
  ) {
    ...AddressList
  }
  companyAddresses: address_addresses(
    input: {filters: [{key: "company__isnull", value: false}]}
  ) {
    ...AddressList
  }
  company_company {
    default: default_ship_from_address {
      ...AddressList
    }
  }
}
    ${AddressListFragmentDoc}`;

/**
 * __useStandardAddressesQuery__
 *
 * To run a query within a Vue component, call `useStandardAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardAddressesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useStandardAddressesQuery({
 *   client: // value for 'client'
 * });
 */
export function useStandardAddressesQuery(variables: StandardAddressesQueryVariables | VueCompositionApi.Ref<StandardAddressesQueryVariables> | ReactiveFunction<StandardAddressesQueryVariables>, options: VueApolloComposable.UseQueryOptions<StandardAddressesQuery, StandardAddressesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<StandardAddressesQuery, StandardAddressesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<StandardAddressesQuery, StandardAddressesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<StandardAddressesQuery, StandardAddressesQueryVariables>(StandardAddressesDocument, variables, options);
}
export type StandardAddressesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<StandardAddressesQuery, StandardAddressesQueryVariables>;
export const VerifyAddressDocument = gql`
    mutation VerifyAddress($input: EasyPost_MakeAddressInput!) {
  verified: EasyPost_MakeAddress(input: $input) {
    id
    residential
    phone
    name
    company
    street1
    city
    state
    country
    verifications {
      delivery {
        errors {
          field
          message
        }
        success
      }
      zip4 {
        errors {
          field
          message
        }
        success
      }
    }
    street2
    zip
  }
}
    `;

/**
 * __useVerifyAddressMutation__
 *
 * To run a mutation, you first call `useVerifyAddressMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useVerifyAddressMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useVerifyAddressMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useVerifyAddressMutation(options: VueApolloComposable.UseMutationOptions<VerifyAddressMutation, VerifyAddressMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<VerifyAddressMutation, VerifyAddressMutationVariables>>) {
  return VueApolloComposable.useMutation<VerifyAddressMutation, VerifyAddressMutationVariables>(VerifyAddressDocument, options);
}
export type VerifyAddressMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<VerifyAddressMutation, VerifyAddressMutationVariables>;
export const CreateAddressDocument = gql`
    mutation CreateAddress($input: Create__Address_Address__Input!) {
  Create__Address_Address(input: $input) {
    ...AddressFull
  }
}
    ${AddressFullFragmentDoc}`;

/**
 * __useCreateAddressMutation__
 *
 * To run a mutation, you first call `useCreateAddressMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddressMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateAddressMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateAddressMutation(options: VueApolloComposable.UseMutationOptions<CreateAddressMutation, CreateAddressMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateAddressMutation, CreateAddressMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateAddressMutation, CreateAddressMutationVariables>(CreateAddressDocument, options);
}
export type CreateAddressMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateAddressMutation, CreateAddressMutationVariables>;
export const DeleteAddressDocument = gql`
    mutation DeleteAddress($id: ID!) {
  Delete__Address_Address(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteAddressMutation__
 *
 * To run a mutation, you first call `useDeleteAddressMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddressMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteAddressMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAddressMutation(options: VueApolloComposable.UseMutationOptions<DeleteAddressMutation, DeleteAddressMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteAddressMutation, DeleteAddressMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteAddressMutation, DeleteAddressMutationVariables>(DeleteAddressDocument, options);
}
export type DeleteAddressMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteAddressMutation, DeleteAddressMutationVariables>;
export const UpdateAddressDocument = gql`
    mutation UpdateAddress($input: Update__Address_Address__Input!) {
  address: Update__Address_Address(input: $input) {
    ...AddressFull
  }
}
    ${AddressFullFragmentDoc}`;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateAddressMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAddressMutation(options: VueApolloComposable.UseMutationOptions<UpdateAddressMutation, UpdateAddressMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateAddressMutation, UpdateAddressMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateAddressMutation, UpdateAddressMutationVariables>(UpdateAddressDocument, options);
}
export type UpdateAddressMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateAddressMutation, UpdateAddressMutationVariables>;
export const GetBomsForPartsDocument = gql`
    query GetBomsForParts($input: ManyQueryInput!) {
  parts_boms(input: $input) {
    ...BomForPartGrid
    annotations {
      name
      value
    }
  }
}
    ${BomForPartGridFragmentDoc}`;

/**
 * __useGetBomsForPartsQuery__
 *
 * To run a query within a Vue component, call `useGetBomsForPartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBomsForPartsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetBomsForPartsQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetBomsForPartsQuery(variables: GetBomsForPartsQueryVariables | VueCompositionApi.Ref<GetBomsForPartsQueryVariables> | ReactiveFunction<GetBomsForPartsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetBomsForPartsQuery, GetBomsForPartsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetBomsForPartsQuery, GetBomsForPartsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetBomsForPartsQuery, GetBomsForPartsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetBomsForPartsQuery, GetBomsForPartsQueryVariables>(GetBomsForPartsDocument, variables, options);
}
export type GetBomsForPartsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetBomsForPartsQuery, GetBomsForPartsQueryVariables>;
export const GetPartsBoms_FullDocument = gql`
    query GetPartsBoms_Full($input: ManyQueryInput!) {
  parts_boms(input: $input) {
    ...BomForPartFull
    annotations {
      name
      value
    }
  }
}
    ${BomForPartFullFragmentDoc}`;

/**
 * __useGetPartsBoms_FullQuery__
 *
 * To run a query within a Vue component, call `useGetPartsBoms_FullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartsBoms_FullQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetPartsBoms_FullQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetPartsBoms_FullQuery(variables: GetPartsBoms_FullQueryVariables | VueCompositionApi.Ref<GetPartsBoms_FullQueryVariables> | ReactiveFunction<GetPartsBoms_FullQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetPartsBoms_FullQuery, GetPartsBoms_FullQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetPartsBoms_FullQuery, GetPartsBoms_FullQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetPartsBoms_FullQuery, GetPartsBoms_FullQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetPartsBoms_FullQuery, GetPartsBoms_FullQueryVariables>(GetPartsBoms_FullDocument, variables, options);
}
export type GetPartsBoms_FullQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetPartsBoms_FullQuery, GetPartsBoms_FullQueryVariables>;
export const GetBuildUpsDocument = gql`
    query GetBuildUps($input: ManyQueryInput!) {
  build_up_build_ups(input: $input) {
    ...BuildUpsGrid
    annotations {
      name
      value
    }
  }
}
    ${BuildUpsGridFragmentDoc}`;

/**
 * __useGetBuildUpsQuery__
 *
 * To run a query within a Vue component, call `useGetBuildUpsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildUpsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetBuildUpsQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetBuildUpsQuery(variables: GetBuildUpsQueryVariables | VueCompositionApi.Ref<GetBuildUpsQueryVariables> | ReactiveFunction<GetBuildUpsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetBuildUpsQuery, GetBuildUpsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetBuildUpsQuery, GetBuildUpsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetBuildUpsQuery, GetBuildUpsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetBuildUpsQuery, GetBuildUpsQueryVariables>(GetBuildUpsDocument, variables, options);
}
export type GetBuildUpsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetBuildUpsQuery, GetBuildUpsQueryVariables>;
export const GetBompartsFullDocument = gql`
    query GetBompartsFull($input: ManyQueryInput!) {
  parts_bom_parts(input: $input) {
    ...BomPartFull
    annotations {
      name
      value
    }
  }
}
    ${BomPartFullFragmentDoc}`;

/**
 * __useGetBompartsFullQuery__
 *
 * To run a query within a Vue component, call `useGetBompartsFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBompartsFullQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetBompartsFullQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetBompartsFullQuery(variables: GetBompartsFullQueryVariables | VueCompositionApi.Ref<GetBompartsFullQueryVariables> | ReactiveFunction<GetBompartsFullQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetBompartsFullQuery, GetBompartsFullQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetBompartsFullQuery, GetBompartsFullQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetBompartsFullQuery, GetBompartsFullQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetBompartsFullQuery, GetBompartsFullQueryVariables>(GetBompartsFullDocument, variables, options);
}
export type GetBompartsFullQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetBompartsFullQuery, GetBompartsFullQueryVariables>;
export const GetBuildUpItemsGridDocument = gql`
    query GetBuildUpItemsGrid($input: ManyQueryInput!) {
  build_up_build_up_items(input: $input) {
    ...BuildUpItemFull
    annotations {
      name
      value
    }
  }
}
    ${BuildUpItemFullFragmentDoc}`;

/**
 * __useGetBuildUpItemsGridQuery__
 *
 * To run a query within a Vue component, call `useGetBuildUpItemsGridQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildUpItemsGridQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetBuildUpItemsGridQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetBuildUpItemsGridQuery(variables: GetBuildUpItemsGridQueryVariables | VueCompositionApi.Ref<GetBuildUpItemsGridQueryVariables> | ReactiveFunction<GetBuildUpItemsGridQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetBuildUpItemsGridQuery, GetBuildUpItemsGridQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetBuildUpItemsGridQuery, GetBuildUpItemsGridQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetBuildUpItemsGridQuery, GetBuildUpItemsGridQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetBuildUpItemsGridQuery, GetBuildUpItemsGridQueryVariables>(GetBuildUpItemsGridDocument, variables, options);
}
export type GetBuildUpItemsGridQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetBuildUpItemsGridQuery, GetBuildUpItemsGridQueryVariables>;
export const CreateBomForPartDocument = gql`
    mutation CreateBomForPart($id: ID!, $description: String!) {
  Create__Parts_BOM(input: {bom_for_id: $id, description: $description}) {
    ...BomForPartFull
  }
}
    ${BomForPartFullFragmentDoc}`;

/**
 * __useCreateBomForPartMutation__
 *
 * To run a mutation, you first call `useCreateBomForPartMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateBomForPartMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateBomForPartMutation({
 *   variables: {
 *     id: // value for 'id'
 *     description: // value for 'description'
 *   },
 * });
 */
export function useCreateBomForPartMutation(options: VueApolloComposable.UseMutationOptions<CreateBomForPartMutation, CreateBomForPartMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateBomForPartMutation, CreateBomForPartMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateBomForPartMutation, CreateBomForPartMutationVariables>(CreateBomForPartDocument, options);
}
export type CreateBomForPartMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateBomForPartMutation, CreateBomForPartMutationVariables>;
export const CreateBomPartsDocument = gql`
    mutation CreateBomParts($id: ID!, $part_id: ID!, $max: PositiveInteger, $min: PositiveInteger, $type: String!) {
  Create__Parts_BOMParts(
    input: {bom_id: $id, part_id: $part_id, part_type: $type, maximum_count: $max, minimum_count: $min}
  ) {
    ...BomPartFull
  }
}
    ${BomPartFullFragmentDoc}`;

/**
 * __useCreateBomPartsMutation__
 *
 * To run a mutation, you first call `useCreateBomPartsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateBomPartsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateBomPartsMutation({
 *   variables: {
 *     id: // value for 'id'
 *     part_id: // value for 'part_id'
 *     max: // value for 'max'
 *     min: // value for 'min'
 *     type: // value for 'type'
 *   },
 * });
 */
export function useCreateBomPartsMutation(options: VueApolloComposable.UseMutationOptions<CreateBomPartsMutation, CreateBomPartsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateBomPartsMutation, CreateBomPartsMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateBomPartsMutation, CreateBomPartsMutationVariables>(CreateBomPartsDocument, options);
}
export type CreateBomPartsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateBomPartsMutation, CreateBomPartsMutationVariables>;
export const UpdateBomPartDocument = gql`
    mutation UpdateBomPart($id: ID!, $max: PositiveInteger, $min: PositiveInteger, $type: String!) {
  Update__Parts_BOMParts(
    input: {id: $id, maximum_count: $max, minimum_count: $min, part_type: $type}
  ) {
    ...BomPartFull
  }
}
    ${BomPartFullFragmentDoc}`;

/**
 * __useUpdateBomPartMutation__
 *
 * To run a mutation, you first call `useUpdateBomPartMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBomPartMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateBomPartMutation({
 *   variables: {
 *     id: // value for 'id'
 *     max: // value for 'max'
 *     min: // value for 'min'
 *     type: // value for 'type'
 *   },
 * });
 */
export function useUpdateBomPartMutation(options: VueApolloComposable.UseMutationOptions<UpdateBomPartMutation, UpdateBomPartMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateBomPartMutation, UpdateBomPartMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateBomPartMutation, UpdateBomPartMutationVariables>(UpdateBomPartDocument, options);
}
export type UpdateBomPartMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateBomPartMutation, UpdateBomPartMutationVariables>;
export const DeleteBomPartDocument = gql`
    mutation DeleteBomPart($id: ID!) {
  Delete__Parts_BOMParts(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteBomPartMutation__
 *
 * To run a mutation, you first call `useDeleteBomPartMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBomPartMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteBomPartMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBomPartMutation(options: VueApolloComposable.UseMutationOptions<DeleteBomPartMutation, DeleteBomPartMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteBomPartMutation, DeleteBomPartMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteBomPartMutation, DeleteBomPartMutationVariables>(DeleteBomPartDocument, options);
}
export type DeleteBomPartMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteBomPartMutation, DeleteBomPartMutationVariables>;
export const CreateBuildUpDocument = gql`
    mutation CreateBuildUp($id: ID!) {
  Create__BuildUp_BuildUp(input: {system_id: $id}) {
    ...BuildUpsGrid
  }
}
    ${BuildUpsGridFragmentDoc}`;

/**
 * __useCreateBuildUpMutation__
 *
 * To run a mutation, you first call `useCreateBuildUpMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateBuildUpMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateBuildUpMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useCreateBuildUpMutation(options: VueApolloComposable.UseMutationOptions<CreateBuildUpMutation, CreateBuildUpMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateBuildUpMutation, CreateBuildUpMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateBuildUpMutation, CreateBuildUpMutationVariables>(CreateBuildUpDocument, options);
}
export type CreateBuildUpMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateBuildUpMutation, CreateBuildUpMutationVariables>;
export const UpdateBuildUpDocument = gql`
    mutation UpdateBuildUp($input: Update__BuildUp_BuildUp__Input!) {
  Update__BuildUp_BuildUp(input: $input) {
    ...BuildUpsGrid
  }
}
    ${BuildUpsGridFragmentDoc}`;

/**
 * __useUpdateBuildUpMutation__
 *
 * To run a mutation, you first call `useUpdateBuildUpMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuildUpMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateBuildUpMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBuildUpMutation(options: VueApolloComposable.UseMutationOptions<UpdateBuildUpMutation, UpdateBuildUpMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateBuildUpMutation, UpdateBuildUpMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateBuildUpMutation, UpdateBuildUpMutationVariables>(UpdateBuildUpDocument, options);
}
export type UpdateBuildUpMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateBuildUpMutation, UpdateBuildUpMutationVariables>;
export const CreateBuildUpItemsDocument = gql`
    mutation CreateBuildUpItems($input: [Create__BuildUp_BuildUpItems__Input!]!) {
  buildUpItems: Create__BuildUp_BuildUpItems(input: $input) {
    ...BuildUpItemFull
  }
}
    ${BuildUpItemFullFragmentDoc}`;

/**
 * __useCreateBuildUpItemsMutation__
 *
 * To run a mutation, you first call `useCreateBuildUpItemsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateBuildUpItemsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateBuildUpItemsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateBuildUpItemsMutation(options: VueApolloComposable.UseMutationOptions<CreateBuildUpItemsMutation, CreateBuildUpItemsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateBuildUpItemsMutation, CreateBuildUpItemsMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateBuildUpItemsMutation, CreateBuildUpItemsMutationVariables>(CreateBuildUpItemsDocument, options);
}
export type CreateBuildUpItemsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateBuildUpItemsMutation, CreateBuildUpItemsMutationVariables>;
export const UpdateBuildUpItemsDocument = gql`
    mutation UpdateBuildUpItems($input: [Update__BuildUp_BuildUpItems__Input!]!) {
  Update__BuildUp_BuildUpItems(input: $input) {
    ...BuildUpItemFull
  }
}
    ${BuildUpItemFullFragmentDoc}`;

/**
 * __useUpdateBuildUpItemsMutation__
 *
 * To run a mutation, you first call `useUpdateBuildUpItemsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuildUpItemsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateBuildUpItemsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBuildUpItemsMutation(options: VueApolloComposable.UseMutationOptions<UpdateBuildUpItemsMutation, UpdateBuildUpItemsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateBuildUpItemsMutation, UpdateBuildUpItemsMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateBuildUpItemsMutation, UpdateBuildUpItemsMutationVariables>(UpdateBuildUpItemsDocument, options);
}
export type UpdateBuildUpItemsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateBuildUpItemsMutation, UpdateBuildUpItemsMutationVariables>;
export const TestStatusTagsForListDocument = gql`
    query TestStatusTagsForList {
  tags: choices_tags(input: {limit: 10000}) {
    id
    tag
    description
    color
  }
}
    `;

/**
 * __useTestStatusTagsForListQuery__
 *
 * To run a query within a Vue component, call `useTestStatusTagsForListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestStatusTagsForListQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTestStatusTagsForListQuery();
 */
export function useTestStatusTagsForListQuery(options: VueApolloComposable.UseQueryOptions<TestStatusTagsForListQuery, TestStatusTagsForListQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TestStatusTagsForListQuery, TestStatusTagsForListQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TestStatusTagsForListQuery, TestStatusTagsForListQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TestStatusTagsForListQuery, TestStatusTagsForListQueryVariables>(TestStatusTagsForListDocument, {}, options);
}
export type TestStatusTagsForListQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TestStatusTagsForListQuery, TestStatusTagsForListQueryVariables>;
export const GetChoicesChoicesDocument = gql`
    query GetChoicesChoices($input: ManyQueryInput!) {
  choices_choices(input: $input) {
    app_name
    model_name
    field_name
    id
    choice
    sub_choice
  }
}
    `;

/**
 * __useGetChoicesChoicesQuery__
 *
 * To run a query within a Vue component, call `useGetChoicesChoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChoicesChoicesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetChoicesChoicesQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetChoicesChoicesQuery(variables: GetChoicesChoicesQueryVariables | VueCompositionApi.Ref<GetChoicesChoicesQueryVariables> | ReactiveFunction<GetChoicesChoicesQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetChoicesChoicesQuery, GetChoicesChoicesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetChoicesChoicesQuery, GetChoicesChoicesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetChoicesChoicesQuery, GetChoicesChoicesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetChoicesChoicesQuery, GetChoicesChoicesQueryVariables>(GetChoicesChoicesDocument, variables, options);
}
export type GetChoicesChoicesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetChoicesChoicesQuery, GetChoicesChoicesQueryVariables>;
export const GetClients_ListDocument = gql`
    query GetClients_List($input: ManyQueryInput!) {
  clients_clients(input: $input) {
    ...Client_List
  }
}
    ${Client_ListFragmentDoc}`;

/**
 * __useGetClients_ListQuery__
 *
 * To run a query within a Vue component, call `useGetClients_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClients_ListQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetClients_ListQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetClients_ListQuery(variables: GetClients_ListQueryVariables | VueCompositionApi.Ref<GetClients_ListQueryVariables> | ReactiveFunction<GetClients_ListQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetClients_ListQuery, GetClients_ListQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetClients_ListQuery, GetClients_ListQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetClients_ListQuery, GetClients_ListQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetClients_ListQuery, GetClients_ListQueryVariables>(GetClients_ListDocument, variables, options);
}
export type GetClients_ListQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetClients_ListQuery, GetClients_ListQueryVariables>;
export const GetClients_GridDocument = gql`
    query GetClients_Grid($input: ManyQueryInput!) {
  clients_clients(input: $input) {
    annotations {
      name
      value
    }
    ...Client_Grid
  }
}
    ${Client_GridFragmentDoc}`;

/**
 * __useGetClients_GridQuery__
 *
 * To run a query within a Vue component, call `useGetClients_GridQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClients_GridQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetClients_GridQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetClients_GridQuery(variables: GetClients_GridQueryVariables | VueCompositionApi.Ref<GetClients_GridQueryVariables> | ReactiveFunction<GetClients_GridQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetClients_GridQuery, GetClients_GridQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetClients_GridQuery, GetClients_GridQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetClients_GridQuery, GetClients_GridQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetClients_GridQuery, GetClients_GridQueryVariables>(GetClients_GridDocument, variables, options);
}
export type GetClients_GridQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetClients_GridQuery, GetClients_GridQueryVariables>;
export const GetClientsFullDocument = gql`
    query GetClientsFull($input: ManyQueryInput!) {
  clients_clients(input: $input) {
    ...Client_Full
  }
}
    ${Client_FullFragmentDoc}`;

/**
 * __useGetClientsFullQuery__
 *
 * To run a query within a Vue component, call `useGetClientsFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsFullQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetClientsFullQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetClientsFullQuery(variables: GetClientsFullQueryVariables | VueCompositionApi.Ref<GetClientsFullQueryVariables> | ReactiveFunction<GetClientsFullQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetClientsFullQuery, GetClientsFullQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetClientsFullQuery, GetClientsFullQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetClientsFullQuery, GetClientsFullQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetClientsFullQuery, GetClientsFullQueryVariables>(GetClientsFullDocument, variables, options);
}
export type GetClientsFullQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetClientsFullQuery, GetClientsFullQueryVariables>;
export const GetClientFullDocument = gql`
    query GetClientFull($input: SingleQueryInput!) {
  clients_client(input: $input) {
    ...Client_Full
  }
}
    ${Client_FullFragmentDoc}`;

/**
 * __useGetClientFullQuery__
 *
 * To run a query within a Vue component, call `useGetClientFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientFullQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetClientFullQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetClientFullQuery(variables: GetClientFullQueryVariables | VueCompositionApi.Ref<GetClientFullQueryVariables> | ReactiveFunction<GetClientFullQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetClientFullQuery, GetClientFullQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetClientFullQuery, GetClientFullQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetClientFullQuery, GetClientFullQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetClientFullQuery, GetClientFullQueryVariables>(GetClientFullDocument, variables, options);
}
export type GetClientFullQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetClientFullQuery, GetClientFullQueryVariables>;
export const GetClientAltPartsDocument = gql`
    query GetClientAltParts($clientId: ID!) {
  clients_client(input: {id: $clientId}) {
    id
    alt_parts {
      id
      pn
      alt_part_for_part {
        id
        pn
      }
    }
  }
}
    `;

/**
 * __useGetClientAltPartsQuery__
 *
 * To run a query within a Vue component, call `useGetClientAltPartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientAltPartsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetClientAltPartsQuery({
 *   clientId: // value for 'clientId'
 * });
 */
export function useGetClientAltPartsQuery(variables: GetClientAltPartsQueryVariables | VueCompositionApi.Ref<GetClientAltPartsQueryVariables> | ReactiveFunction<GetClientAltPartsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetClientAltPartsQuery, GetClientAltPartsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetClientAltPartsQuery, GetClientAltPartsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetClientAltPartsQuery, GetClientAltPartsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetClientAltPartsQuery, GetClientAltPartsQueryVariables>(GetClientAltPartsDocument, variables, options);
}
export type GetClientAltPartsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetClientAltPartsQuery, GetClientAltPartsQueryVariables>;
export const GetClientAltPartsForPartDocument = gql`
    query GetClientAltPartsForPart($clientId: ID!, $partId: Any!) {
  clients_client(input: {id: $clientId}) {
    id
    alt_parts(filters: [{key: "alt_part_for_part__id", value: $partId}]) {
      id
      pn
      description
    }
  }
}
    `;

/**
 * __useGetClientAltPartsForPartQuery__
 *
 * To run a query within a Vue component, call `useGetClientAltPartsForPartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientAltPartsForPartQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetClientAltPartsForPartQuery({
 *   clientId: // value for 'clientId'
 *   partId: // value for 'partId'
 * });
 */
export function useGetClientAltPartsForPartQuery(variables: GetClientAltPartsForPartQueryVariables | VueCompositionApi.Ref<GetClientAltPartsForPartQueryVariables> | ReactiveFunction<GetClientAltPartsForPartQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetClientAltPartsForPartQuery, GetClientAltPartsForPartQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetClientAltPartsForPartQuery, GetClientAltPartsForPartQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetClientAltPartsForPartQuery, GetClientAltPartsForPartQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetClientAltPartsForPartQuery, GetClientAltPartsForPartQueryVariables>(GetClientAltPartsForPartDocument, variables, options);
}
export type GetClientAltPartsForPartQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetClientAltPartsForPartQuery, GetClientAltPartsForPartQueryVariables>;
export const DeleteClientDocument = gql`
    mutation DeleteClient($id: ID!) {
  Delete__Clients_Client(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteClientMutation__
 *
 * To run a mutation, you first call `useDeleteClientMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteClientMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClientMutation(options: VueApolloComposable.UseMutationOptions<DeleteClientMutation, DeleteClientMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteClientMutation, DeleteClientMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteClientMutation, DeleteClientMutationVariables>(DeleteClientDocument, options);
}
export type DeleteClientMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteClientMutation, DeleteClientMutationVariables>;
export const UpdateClientDetailsDocument = gql`
    mutation UpdateClientDetails($input: Update__Clients_Client__Input!) {
  Update__Clients_Client(input: $input) {
    ...Client_Full
  }
}
    ${Client_FullFragmentDoc}`;

/**
 * __useUpdateClientDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateClientDetailsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientDetailsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateClientDetailsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientDetailsMutation(options: VueApolloComposable.UseMutationOptions<UpdateClientDetailsMutation, UpdateClientDetailsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateClientDetailsMutation, UpdateClientDetailsMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateClientDetailsMutation, UpdateClientDetailsMutationVariables>(UpdateClientDetailsDocument, options);
}
export type UpdateClientDetailsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateClientDetailsMutation, UpdateClientDetailsMutationVariables>;
export const CreateClientDocument = gql`
    mutation CreateClient($input: Create__Clients_Client__Input!) {
  Create__Clients_Client(input: $input) {
    ...Client_Full
  }
}
    ${Client_FullFragmentDoc}`;

/**
 * __useCreateClientMutation__
 *
 * To run a mutation, you first call `useCreateClientMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateClientMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientMutation(options: VueApolloComposable.UseMutationOptions<CreateClientMutation, CreateClientMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateClientMutation, CreateClientMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateClientMutation, CreateClientMutationVariables>(CreateClientDocument, options);
}
export type CreateClientMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateClientMutation, CreateClientMutationVariables>;
export const GetCompanyInfoDocument = gql`
    query GetCompanyInfo {
  company: company_company {
    name
    phone
    short_name
    default_new_client_terms {
      id
    }
    default_return_to_address {
      ...AddressFull
    }
    default_ship_from_address {
      ...AddressFull
    }
    default_ship_to_address {
      ...AddressFull
    }
    default_billing_address {
      ...AddressFull
    }
    default_remit_address {
      ...AddressFull
    }
    default_shipping_account {
      ...CarrierAccountFull
    }
    default_shipping_service {
      id
    }
  }
}
    ${AddressFullFragmentDoc}
${CarrierAccountFullFragmentDoc}`;

/**
 * __useGetCompanyInfoQuery__
 *
 * To run a query within a Vue component, call `useGetCompanyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyInfoQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCompanyInfoQuery();
 */
export function useGetCompanyInfoQuery(options: VueApolloComposable.UseQueryOptions<GetCompanyInfoQuery, GetCompanyInfoQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>(GetCompanyInfoDocument, {}, options);
}
export type GetCompanyInfoQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>;
export const GetFieldsDocument = gql`
    query GetFields($name: String!) {
  __type(name: $name) {
    name
    description
    inputFields {
      name
      description
      type {
        name
      }
    }
  }
}
    `;

/**
 * __useGetFieldsQuery__
 *
 * To run a query within a Vue component, call `useGetFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFieldsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetFieldsQuery({
 *   name: // value for 'name'
 * });
 */
export function useGetFieldsQuery(variables: GetFieldsQueryVariables | VueCompositionApi.Ref<GetFieldsQueryVariables> | ReactiveFunction<GetFieldsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetFieldsQuery, GetFieldsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetFieldsQuery, GetFieldsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetFieldsQuery, GetFieldsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetFieldsQuery, GetFieldsQueryVariables>(GetFieldsDocument, variables, options);
}
export type GetFieldsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetFieldsQuery, GetFieldsQueryVariables>;
export const GetTypeFieldsDocument = gql`
    query GetTypeFields($name: String!) {
  __type(name: $name) {
    name
    description
    interfaces {
      name
    }
    inputFields {
      name
    }
    fields {
      name
      description
      type {
        name
        kind
        ofType {
          name
          ofType {
            name
          }
        }
      }
    }
    enumValues {
      name
    }
  }
}
    `;

/**
 * __useGetTypeFieldsQuery__
 *
 * To run a query within a Vue component, call `useGetTypeFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTypeFieldsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetTypeFieldsQuery({
 *   name: // value for 'name'
 * });
 */
export function useGetTypeFieldsQuery(variables: GetTypeFieldsQueryVariables | VueCompositionApi.Ref<GetTypeFieldsQueryVariables> | ReactiveFunction<GetTypeFieldsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetTypeFieldsQuery, GetTypeFieldsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetTypeFieldsQuery, GetTypeFieldsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetTypeFieldsQuery, GetTypeFieldsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetTypeFieldsQuery, GetTypeFieldsQueryVariables>(GetTypeFieldsDocument, variables, options);
}
export type GetTypeFieldsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetTypeFieldsQuery, GetTypeFieldsQueryVariables>;
export const GetTypesDocument = gql`
    query GetTypes {
  __schema {
    types {
      name
      description
      fields {
        type {
          name
        }
        name
      }
    }
  }
}
    `;

/**
 * __useGetTypesQuery__
 *
 * To run a query within a Vue component, call `useGetTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTypesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetTypesQuery();
 */
export function useGetTypesQuery(options: VueApolloComposable.UseQueryOptions<GetTypesQuery, GetTypesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetTypesQuery, GetTypesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetTypesQuery, GetTypesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetTypesQuery, GetTypesQueryVariables>(GetTypesDocument, {}, options);
}
export type GetTypesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetTypesQuery, GetTypesQueryVariables>;
export const UpdateCompanyInfoDocument = gql`
    mutation UpdateCompanyInfo($input: Update__Company_Company__Input!) {
  Update__Company_Company(input: $input) {
    id
  }
}
    `;

/**
 * __useUpdateCompanyInfoMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyInfoMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyInfoMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateCompanyInfoMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyInfoMutation(options: VueApolloComposable.UseMutationOptions<UpdateCompanyInfoMutation, UpdateCompanyInfoMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateCompanyInfoMutation, UpdateCompanyInfoMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateCompanyInfoMutation, UpdateCompanyInfoMutationVariables>(UpdateCompanyInfoDocument, options);
}
export type UpdateCompanyInfoMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateCompanyInfoMutation, UpdateCompanyInfoMutationVariables>;
export const CompanyAndClientAddressesDocument = gql`
    query CompanyAndClientAddresses($client: Any!, $noCompany: Any!) {
  client: address_addresses(
    input: {filters: [{key: "client__id", value: $client}]}
  ) {
    ...AddressList
  }
  other: address_addresses(
    input: {filters: [{key: "company__isnull", value: $noCompany}]}
  ) {
    ...AddressList
  }
}
    ${AddressListFragmentDoc}`;

/**
 * __useCompanyAndClientAddressesQuery__
 *
 * To run a query within a Vue component, call `useCompanyAndClientAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyAndClientAddressesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCompanyAndClientAddressesQuery({
 *   client: // value for 'client'
 *   noCompany: // value for 'noCompany'
 * });
 */
export function useCompanyAndClientAddressesQuery(variables: CompanyAndClientAddressesQueryVariables | VueCompositionApi.Ref<CompanyAndClientAddressesQueryVariables> | ReactiveFunction<CompanyAndClientAddressesQueryVariables>, options: VueApolloComposable.UseQueryOptions<CompanyAndClientAddressesQuery, CompanyAndClientAddressesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CompanyAndClientAddressesQuery, CompanyAndClientAddressesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CompanyAndClientAddressesQuery, CompanyAndClientAddressesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CompanyAndClientAddressesQuery, CompanyAndClientAddressesQueryVariables>(CompanyAndClientAddressesDocument, variables, options);
}
export type CompanyAndClientAddressesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CompanyAndClientAddressesQuery, CompanyAndClientAddressesQueryVariables>;
export const GetContacts_ListDocument = gql`
    query GetContacts_List($input: ManyQueryInput!) {
  clients_contacts(input: $input) {
    ...Contact_List
  }
}
    ${Contact_ListFragmentDoc}`;

/**
 * __useGetContacts_ListQuery__
 *
 * To run a query within a Vue component, call `useGetContacts_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContacts_ListQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetContacts_ListQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetContacts_ListQuery(variables: GetContacts_ListQueryVariables | VueCompositionApi.Ref<GetContacts_ListQueryVariables> | ReactiveFunction<GetContacts_ListQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetContacts_ListQuery, GetContacts_ListQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetContacts_ListQuery, GetContacts_ListQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetContacts_ListQuery, GetContacts_ListQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetContacts_ListQuery, GetContacts_ListQueryVariables>(GetContacts_ListDocument, variables, options);
}
export type GetContacts_ListQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetContacts_ListQuery, GetContacts_ListQueryVariables>;
export const GetContacts_GridDocument = gql`
    query GetContacts_Grid($input: ManyQueryInput!) {
  clients_contacts(input: $input) {
    ...Contact_Grid
  }
}
    ${Contact_GridFragmentDoc}`;

/**
 * __useGetContacts_GridQuery__
 *
 * To run a query within a Vue component, call `useGetContacts_GridQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContacts_GridQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetContacts_GridQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetContacts_GridQuery(variables: GetContacts_GridQueryVariables | VueCompositionApi.Ref<GetContacts_GridQueryVariables> | ReactiveFunction<GetContacts_GridQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetContacts_GridQuery, GetContacts_GridQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetContacts_GridQuery, GetContacts_GridQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetContacts_GridQuery, GetContacts_GridQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetContacts_GridQuery, GetContacts_GridQueryVariables>(GetContacts_GridDocument, variables, options);
}
export type GetContacts_GridQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetContacts_GridQuery, GetContacts_GridQueryVariables>;
export const GetContacts_FullDocument = gql`
    query GetContacts_Full($input: ManyQueryInput!) {
  clients_contacts(input: $input) {
    ...Contact_Full
  }
}
    ${Contact_FullFragmentDoc}`;

/**
 * __useGetContacts_FullQuery__
 *
 * To run a query within a Vue component, call `useGetContacts_FullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContacts_FullQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetContacts_FullQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetContacts_FullQuery(variables: GetContacts_FullQueryVariables | VueCompositionApi.Ref<GetContacts_FullQueryVariables> | ReactiveFunction<GetContacts_FullQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetContacts_FullQuery, GetContacts_FullQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetContacts_FullQuery, GetContacts_FullQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetContacts_FullQuery, GetContacts_FullQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetContacts_FullQuery, GetContacts_FullQueryVariables>(GetContacts_FullDocument, variables, options);
}
export type GetContacts_FullQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetContacts_FullQuery, GetContacts_FullQueryVariables>;
export const GetContact_FullDocument = gql`
    query GetContact_Full($input: SingleQueryInput!) {
  clients_contact(input: $input) {
    ...Contact_Full
  }
}
    ${Contact_FullFragmentDoc}`;

/**
 * __useGetContact_FullQuery__
 *
 * To run a query within a Vue component, call `useGetContact_FullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContact_FullQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetContact_FullQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetContact_FullQuery(variables: GetContact_FullQueryVariables | VueCompositionApi.Ref<GetContact_FullQueryVariables> | ReactiveFunction<GetContact_FullQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetContact_FullQuery, GetContact_FullQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetContact_FullQuery, GetContact_FullQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetContact_FullQuery, GetContact_FullQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetContact_FullQuery, GetContact_FullQueryVariables>(GetContact_FullDocument, variables, options);
}
export type GetContact_FullQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetContact_FullQuery, GetContact_FullQueryVariables>;
export const GetContactToEditDocument = gql`
    query GetContactToEdit($id: Any!) {
  clients_contact(input: {filters: [{key: "owner_id", value: $id}]}) {
    ...Contact_Grid
  }
}
    ${Contact_GridFragmentDoc}`;

/**
 * __useGetContactToEditQuery__
 *
 * To run a query within a Vue component, call `useGetContactToEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactToEditQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetContactToEditQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetContactToEditQuery(variables: GetContactToEditQueryVariables | VueCompositionApi.Ref<GetContactToEditQueryVariables> | ReactiveFunction<GetContactToEditQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetContactToEditQuery, GetContactToEditQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetContactToEditQuery, GetContactToEditQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetContactToEditQuery, GetContactToEditQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetContactToEditQuery, GetContactToEditQueryVariables>(GetContactToEditDocument, variables, options);
}
export type GetContactToEditQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetContactToEditQuery, GetContactToEditQueryVariables>;
export const CreateContactDocument = gql`
    mutation CreateContact($input: Create__Clients_Contact__Input!) {
  Create__Clients_Contact(input: $input) {
    ...Contact_Full
  }
}
    ${Contact_FullFragmentDoc}`;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateContactMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactMutation(options: VueApolloComposable.UseMutationOptions<CreateContactMutation, CreateContactMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateContactMutation, CreateContactMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options);
}
export type CreateContactMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateContactMutation, CreateContactMutationVariables>;
export const UpdateContactDocument = gql`
    mutation UpdateContact($input: Update__Clients_Contact__Input!) {
  Update__Clients_Contact(input: $input) {
    ...Contact_Full
  }
}
    ${Contact_FullFragmentDoc}`;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateContactMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(options: VueApolloComposable.UseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, options);
}
export type UpdateContactMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateContactMutation, UpdateContactMutationVariables>;
export const DeleteContactDocument = gql`
    mutation DeleteContact($id: ID!) {
  Delete__Clients_Contact(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteContactMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactMutation(options: VueApolloComposable.UseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, options);
}
export type DeleteContactMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteContactMutation, DeleteContactMutationVariables>;
export const GetCountriesDocument = gql`
    query GetCountries {
  countries {
    ...Country
  }
}
    ${CountryFragmentDoc}`;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a Vue component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCountriesQuery();
 */
export function useGetCountriesQuery(options: VueApolloComposable.UseQueryOptions<GetCountriesQuery, GetCountriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetCountriesQuery, GetCountriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetCountriesQuery, GetCountriesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, {}, options);
}
export type GetCountriesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetCountriesQuery, GetCountriesQueryVariables>;
export const GetBreakDownItemsDocument = gql`
    query GetBreakDownItems($id: Any!) {
  breakDownItems: breakdown_break_down_items(
    input: {filters: [{key: "break_down__id", value: $id}]}
  ) {
    ...BreakdownItem
  }
}
    ${BreakdownItemFragmentDoc}`;

/**
 * __useGetBreakDownItemsQuery__
 *
 * To run a query within a Vue component, call `useGetBreakDownItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBreakDownItemsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetBreakDownItemsQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetBreakDownItemsQuery(variables: GetBreakDownItemsQueryVariables | VueCompositionApi.Ref<GetBreakDownItemsQueryVariables> | ReactiveFunction<GetBreakDownItemsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetBreakDownItemsQuery, GetBreakDownItemsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetBreakDownItemsQuery, GetBreakDownItemsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetBreakDownItemsQuery, GetBreakDownItemsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetBreakDownItemsQuery, GetBreakDownItemsQueryVariables>(GetBreakDownItemsDocument, variables, options);
}
export type GetBreakDownItemsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetBreakDownItemsQuery, GetBreakDownItemsQueryVariables>;
export const GridGetBreakDownItemsDocument = gql`
    query GridGetBreakDownItems($input: ManyQueryInput!) {
  breakDownItems: breakdown_break_down_items(input: $input) {
    ...BreakdownItem
  }
}
    ${BreakdownItemFragmentDoc}`;

/**
 * __useGridGetBreakDownItemsQuery__
 *
 * To run a query within a Vue component, call `useGridGetBreakDownItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGridGetBreakDownItemsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGridGetBreakDownItemsQuery({
 *   input: // value for 'input'
 * });
 */
export function useGridGetBreakDownItemsQuery(variables: GridGetBreakDownItemsQueryVariables | VueCompositionApi.Ref<GridGetBreakDownItemsQueryVariables> | ReactiveFunction<GridGetBreakDownItemsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GridGetBreakDownItemsQuery, GridGetBreakDownItemsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GridGetBreakDownItemsQuery, GridGetBreakDownItemsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GridGetBreakDownItemsQuery, GridGetBreakDownItemsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GridGetBreakDownItemsQuery, GridGetBreakDownItemsQueryVariables>(GridGetBreakDownItemsDocument, variables, options);
}
export type GridGetBreakDownItemsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GridGetBreakDownItemsQuery, GridGetBreakDownItemsQueryVariables>;
export const GetBreakDownDocument = gql`
    query GetBreakDown($id: ID!) {
  breakdown: breakdown_break_down(input: {id: $id}) {
    ...Breakdown
  }
}
    ${BreakdownFragmentDoc}`;

/**
 * __useGetBreakDownQuery__
 *
 * To run a query within a Vue component, call `useGetBreakDownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBreakDownQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetBreakDownQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetBreakDownQuery(variables: GetBreakDownQueryVariables | VueCompositionApi.Ref<GetBreakDownQueryVariables> | ReactiveFunction<GetBreakDownQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetBreakDownQuery, GetBreakDownQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetBreakDownQuery, GetBreakDownQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetBreakDownQuery, GetBreakDownQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetBreakDownQuery, GetBreakDownQueryVariables>(GetBreakDownDocument, variables, options);
}
export type GetBreakDownQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetBreakDownQuery, GetBreakDownQueryVariables>;
export const GetBreakDownsDocument = gql`
    query GetBreakDowns($input: ManyQueryInput!) {
  breakdowns: breakdown_break_downs(input: $input) {
    ...BreakdownList
    annotations {
      name
      value
    }
  }
}
    ${BreakdownListFragmentDoc}`;

/**
 * __useGetBreakDownsQuery__
 *
 * To run a query within a Vue component, call `useGetBreakDownsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBreakDownsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetBreakDownsQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetBreakDownsQuery(variables: GetBreakDownsQueryVariables | VueCompositionApi.Ref<GetBreakDownsQueryVariables> | ReactiveFunction<GetBreakDownsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetBreakDownsQuery, GetBreakDownsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetBreakDownsQuery, GetBreakDownsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetBreakDownsQuery, GetBreakDownsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetBreakDownsQuery, GetBreakDownsQueryVariables>(GetBreakDownsDocument, variables, options);
}
export type GetBreakDownsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetBreakDownsQuery, GetBreakDownsQueryVariables>;
export const GetCountOfUnfinishedBreakDownItemsOnBreakDownDocument = gql`
    query GetCountOfUnfinishedBreakDownItemsOnBreakDown($id: Any!) {
  breakdown_break_down_items(
    input: {filters: [{key: "break_down__id", value: $id}], excludes: [{key: "status_id", value: 72}]}
  ) {
    id
  }
}
    `;

/**
 * __useGetCountOfUnfinishedBreakDownItemsOnBreakDownQuery__
 *
 * To run a query within a Vue component, call `useGetCountOfUnfinishedBreakDownItemsOnBreakDownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountOfUnfinishedBreakDownItemsOnBreakDownQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCountOfUnfinishedBreakDownItemsOnBreakDownQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetCountOfUnfinishedBreakDownItemsOnBreakDownQuery(variables: GetCountOfUnfinishedBreakDownItemsOnBreakDownQueryVariables | VueCompositionApi.Ref<GetCountOfUnfinishedBreakDownItemsOnBreakDownQueryVariables> | ReactiveFunction<GetCountOfUnfinishedBreakDownItemsOnBreakDownQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetCountOfUnfinishedBreakDownItemsOnBreakDownQuery, GetCountOfUnfinishedBreakDownItemsOnBreakDownQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetCountOfUnfinishedBreakDownItemsOnBreakDownQuery, GetCountOfUnfinishedBreakDownItemsOnBreakDownQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetCountOfUnfinishedBreakDownItemsOnBreakDownQuery, GetCountOfUnfinishedBreakDownItemsOnBreakDownQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetCountOfUnfinishedBreakDownItemsOnBreakDownQuery, GetCountOfUnfinishedBreakDownItemsOnBreakDownQueryVariables>(GetCountOfUnfinishedBreakDownItemsOnBreakDownDocument, variables, options);
}
export type GetCountOfUnfinishedBreakDownItemsOnBreakDownQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetCountOfUnfinishedBreakDownItemsOnBreakDownQuery, GetCountOfUnfinishedBreakDownItemsOnBreakDownQueryVariables>;
export const UpdateBreakDownStatusDocument = gql`
    mutation UpdateBreakDownStatus($id: ID!, $status: ID!) {
  breakDown: Update__Breakdown_BreakDown(input: {id: $id, status_id: $status}) {
    id
    status {
      ...ChoicesStatus
    }
  }
}
    ${ChoicesStatusFragmentDoc}`;

/**
 * __useUpdateBreakDownStatusMutation__
 *
 * To run a mutation, you first call `useUpdateBreakDownStatusMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBreakDownStatusMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateBreakDownStatusMutation({
 *   variables: {
 *     id: // value for 'id'
 *     status: // value for 'status'
 *   },
 * });
 */
export function useUpdateBreakDownStatusMutation(options: VueApolloComposable.UseMutationOptions<UpdateBreakDownStatusMutation, UpdateBreakDownStatusMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateBreakDownStatusMutation, UpdateBreakDownStatusMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateBreakDownStatusMutation, UpdateBreakDownStatusMutationVariables>(UpdateBreakDownStatusDocument, options);
}
export type UpdateBreakDownStatusMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateBreakDownStatusMutation, UpdateBreakDownStatusMutationVariables>;
export const UpdateBreakDownItemStatusDocument = gql`
    mutation UpdateBreakDownItemStatus($input: [Update__Breakdown_BreakDownItems__Input!]!) {
  breakDownItem: Update__Breakdown_BreakDownItems(input: $input) {
    id
    status {
      ...ChoicesStatus
    }
  }
}
    ${ChoicesStatusFragmentDoc}`;

/**
 * __useUpdateBreakDownItemStatusMutation__
 *
 * To run a mutation, you first call `useUpdateBreakDownItemStatusMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBreakDownItemStatusMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateBreakDownItemStatusMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBreakDownItemStatusMutation(options: VueApolloComposable.UseMutationOptions<UpdateBreakDownItemStatusMutation, UpdateBreakDownItemStatusMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateBreakDownItemStatusMutation, UpdateBreakDownItemStatusMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateBreakDownItemStatusMutation, UpdateBreakDownItemStatusMutationVariables>(UpdateBreakDownItemStatusDocument, options);
}
export type UpdateBreakDownItemStatusMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateBreakDownItemStatusMutation, UpdateBreakDownItemStatusMutationVariables>;
export const CreateBreakDownDocument = gql`
    mutation CreateBreakDown($id: ID!) {
  breakDown: Create__Breakdown_BreakDown(input: {system_id: $id, status_id: 64}) {
    id
  }
}
    `;

/**
 * __useCreateBreakDownMutation__
 *
 * To run a mutation, you first call `useCreateBreakDownMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateBreakDownMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateBreakDownMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useCreateBreakDownMutation(options: VueApolloComposable.UseMutationOptions<CreateBreakDownMutation, CreateBreakDownMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateBreakDownMutation, CreateBreakDownMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateBreakDownMutation, CreateBreakDownMutationVariables>(CreateBreakDownDocument, options);
}
export type CreateBreakDownMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateBreakDownMutation, CreateBreakDownMutationVariables>;
export const CreateBreakDownItemsDocument = gql`
    mutation CreateBreakDownItems($input: [Create__Breakdown_BreakDownItems__Input!]!) {
  breakDownItems: Create__Breakdown_BreakDownItems(input: $input) {
    id
  }
}
    `;

/**
 * __useCreateBreakDownItemsMutation__
 *
 * To run a mutation, you first call `useCreateBreakDownItemsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateBreakDownItemsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateBreakDownItemsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateBreakDownItemsMutation(options: VueApolloComposable.UseMutationOptions<CreateBreakDownItemsMutation, CreateBreakDownItemsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateBreakDownItemsMutation, CreateBreakDownItemsMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateBreakDownItemsMutation, CreateBreakDownItemsMutationVariables>(CreateBreakDownItemsDocument, options);
}
export type CreateBreakDownItemsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateBreakDownItemsMutation, CreateBreakDownItemsMutationVariables>;
export const AssignBreakDownDocument = gql`
    mutation AssignBreakDown($id: ID!, $assignee: ID!) {
  breakDown: Update__Breakdown_BreakDown(
    input: {id: $id, assigned_to_id: $assignee}
  ) {
    id
    assigned_to {
      id
      full_name
      initials
      email
    }
  }
}
    `;

/**
 * __useAssignBreakDownMutation__
 *
 * To run a mutation, you first call `useAssignBreakDownMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAssignBreakDownMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAssignBreakDownMutation({
 *   variables: {
 *     id: // value for 'id'
 *     assignee: // value for 'assignee'
 *   },
 * });
 */
export function useAssignBreakDownMutation(options: VueApolloComposable.UseMutationOptions<AssignBreakDownMutation, AssignBreakDownMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<AssignBreakDownMutation, AssignBreakDownMutationVariables>>) {
  return VueApolloComposable.useMutation<AssignBreakDownMutation, AssignBreakDownMutationVariables>(AssignBreakDownDocument, options);
}
export type AssignBreakDownMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<AssignBreakDownMutation, AssignBreakDownMutationVariables>;
export const GetEasyPostShipment_FullDocument = gql`
    query GetEasyPostShipment_Full($id: ID!) {
  EasyPost_RetrieveShipment(id: $id) {
    ...EasyPostShipment_Full
  }
}
    ${EasyPostShipment_FullFragmentDoc}`;

/**
 * __useGetEasyPostShipment_FullQuery__
 *
 * To run a query within a Vue component, call `useGetEasyPostShipment_FullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEasyPostShipment_FullQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetEasyPostShipment_FullQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetEasyPostShipment_FullQuery(variables: GetEasyPostShipment_FullQueryVariables | VueCompositionApi.Ref<GetEasyPostShipment_FullQueryVariables> | ReactiveFunction<GetEasyPostShipment_FullQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetEasyPostShipment_FullQuery, GetEasyPostShipment_FullQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetEasyPostShipment_FullQuery, GetEasyPostShipment_FullQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetEasyPostShipment_FullQuery, GetEasyPostShipment_FullQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetEasyPostShipment_FullQuery, GetEasyPostShipment_FullQueryVariables>(GetEasyPostShipment_FullDocument, variables, options);
}
export type GetEasyPostShipment_FullQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetEasyPostShipment_FullQuery, GetEasyPostShipment_FullQueryVariables>;
export const Get_EasyPost_Shipment_RatesDocument = gql`
    query Get_EasyPost_Shipment_Rates($id: ID!) {
  EasyPost_RetrieveShipment(id: $id) {
    id
    rates {
      id
      rate
      list_rate
      retail_rate
      service
      carrier
    }
    postage_label {
      id
      label_pdf_url
      label_epl2_url
      label_zpl_url
      label_url
    }
  }
}
    `;

/**
 * __useGet_EasyPost_Shipment_RatesQuery__
 *
 * To run a query within a Vue component, call `useGet_EasyPost_Shipment_RatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_EasyPost_Shipment_RatesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGet_EasyPost_Shipment_RatesQuery({
 *   id: // value for 'id'
 * });
 */
export function useGet_EasyPost_Shipment_RatesQuery(variables: Get_EasyPost_Shipment_RatesQueryVariables | VueCompositionApi.Ref<Get_EasyPost_Shipment_RatesQueryVariables> | ReactiveFunction<Get_EasyPost_Shipment_RatesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Get_EasyPost_Shipment_RatesQuery, Get_EasyPost_Shipment_RatesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Get_EasyPost_Shipment_RatesQuery, Get_EasyPost_Shipment_RatesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Get_EasyPost_Shipment_RatesQuery, Get_EasyPost_Shipment_RatesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Get_EasyPost_Shipment_RatesQuery, Get_EasyPost_Shipment_RatesQueryVariables>(Get_EasyPost_Shipment_RatesDocument, variables, options);
}
export type Get_EasyPost_Shipment_RatesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Get_EasyPost_Shipment_RatesQuery, Get_EasyPost_Shipment_RatesQueryVariables>;
export const GetEasypostCarrierAccountsDocument = gql`
    query GetEasypostCarrierAccounts {
  EasyPost_ListCarrierAccounts {
    ...EasyPostCarrierAccount__Full
  }
}
    ${EasyPostCarrierAccount__FullFragmentDoc}`;

/**
 * __useGetEasypostCarrierAccountsQuery__
 *
 * To run a query within a Vue component, call `useGetEasypostCarrierAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEasypostCarrierAccountsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetEasypostCarrierAccountsQuery();
 */
export function useGetEasypostCarrierAccountsQuery(options: VueApolloComposable.UseQueryOptions<GetEasypostCarrierAccountsQuery, GetEasypostCarrierAccountsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetEasypostCarrierAccountsQuery, GetEasypostCarrierAccountsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetEasypostCarrierAccountsQuery, GetEasypostCarrierAccountsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetEasypostCarrierAccountsQuery, GetEasypostCarrierAccountsQueryVariables>(GetEasypostCarrierAccountsDocument, {}, options);
}
export type GetEasypostCarrierAccountsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetEasypostCarrierAccountsQuery, GetEasypostCarrierAccountsQueryVariables>;
export const BuyEasyPostShipmentDocument = gql`
    mutation BuyEasyPostShipment($id: EasyPostID!, $rate: EasyPostID, $insurance: Price) {
  EasyPost_BuyShipment(id: $id, rate: $rate, insurance: $insurance) {
    id
    postage_label {
      id
      label_pdf_url
      label_epl2_url
      label_zpl_url
      label_url
    }
    tracking_code
  }
}
    `;

/**
 * __useBuyEasyPostShipmentMutation__
 *
 * To run a mutation, you first call `useBuyEasyPostShipmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useBuyEasyPostShipmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useBuyEasyPostShipmentMutation({
 *   variables: {
 *     id: // value for 'id'
 *     rate: // value for 'rate'
 *     insurance: // value for 'insurance'
 *   },
 * });
 */
export function useBuyEasyPostShipmentMutation(options: VueApolloComposable.UseMutationOptions<BuyEasyPostShipmentMutation, BuyEasyPostShipmentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<BuyEasyPostShipmentMutation, BuyEasyPostShipmentMutationVariables>>) {
  return VueApolloComposable.useMutation<BuyEasyPostShipmentMutation, BuyEasyPostShipmentMutationVariables>(BuyEasyPostShipmentDocument, options);
}
export type BuyEasyPostShipmentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<BuyEasyPostShipmentMutation, BuyEasyPostShipmentMutationVariables>;
export const RefundEasyPostShipmentDocument = gql`
    mutation RefundEasyPostShipment($id: EasyPostID!) {
  EasyPost_RefundShipment(id: $id) {
    id
  }
}
    `;

/**
 * __useRefundEasyPostShipmentMutation__
 *
 * To run a mutation, you first call `useRefundEasyPostShipmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRefundEasyPostShipmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRefundEasyPostShipmentMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useRefundEasyPostShipmentMutation(options: VueApolloComposable.UseMutationOptions<RefundEasyPostShipmentMutation, RefundEasyPostShipmentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RefundEasyPostShipmentMutation, RefundEasyPostShipmentMutationVariables>>) {
  return VueApolloComposable.useMutation<RefundEasyPostShipmentMutation, RefundEasyPostShipmentMutationVariables>(RefundEasyPostShipmentDocument, options);
}
export type RefundEasyPostShipmentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RefundEasyPostShipmentMutation, RefundEasyPostShipmentMutationVariables>;
export const CreateEasypostFedExCarrierAccountDocument = gql`
    mutation CreateEasypostFedExCarrierAccount($input: EasyPost_RegisterFedexAccountInput!) {
  EasyPost_RegisterFedExAccount(input: $input)
}
    `;

/**
 * __useCreateEasypostFedExCarrierAccountMutation__
 *
 * To run a mutation, you first call `useCreateEasypostFedExCarrierAccountMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateEasypostFedExCarrierAccountMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateEasypostFedExCarrierAccountMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateEasypostFedExCarrierAccountMutation(options: VueApolloComposable.UseMutationOptions<CreateEasypostFedExCarrierAccountMutation, CreateEasypostFedExCarrierAccountMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateEasypostFedExCarrierAccountMutation, CreateEasypostFedExCarrierAccountMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateEasypostFedExCarrierAccountMutation, CreateEasypostFedExCarrierAccountMutationVariables>(CreateEasypostFedExCarrierAccountDocument, options);
}
export type CreateEasypostFedExCarrierAccountMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateEasypostFedExCarrierAccountMutation, CreateEasypostFedExCarrierAccountMutationVariables>;
export const CreateEasypostUpsCarrierAccountDocument = gql`
    mutation CreateEasypostUPSCarrierAccount($input: EasyPost_RegisterUPSAccountInput!) {
  EasyPost_RegisterUPSAccount(input: $input)
}
    `;

/**
 * __useCreateEasypostUpsCarrierAccountMutation__
 *
 * To run a mutation, you first call `useCreateEasypostUpsCarrierAccountMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateEasypostUpsCarrierAccountMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateEasypostUpsCarrierAccountMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateEasypostUpsCarrierAccountMutation(options: VueApolloComposable.UseMutationOptions<CreateEasypostUpsCarrierAccountMutation, CreateEasypostUpsCarrierAccountMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateEasypostUpsCarrierAccountMutation, CreateEasypostUpsCarrierAccountMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateEasypostUpsCarrierAccountMutation, CreateEasypostUpsCarrierAccountMutationVariables>(CreateEasypostUpsCarrierAccountDocument, options);
}
export type CreateEasypostUpsCarrierAccountMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateEasypostUpsCarrierAccountMutation, CreateEasypostUpsCarrierAccountMutationVariables>;
export const CreateEasypostCarrierAccountDocument = gql`
    mutation CreateEasypostCarrierAccount($input: EasyPost_MakeCarrierAccountInput!) {
  EasyPost_MakeCarrierAccount(input: $input) {
    id
  }
}
    `;

/**
 * __useCreateEasypostCarrierAccountMutation__
 *
 * To run a mutation, you first call `useCreateEasypostCarrierAccountMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateEasypostCarrierAccountMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateEasypostCarrierAccountMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateEasypostCarrierAccountMutation(options: VueApolloComposable.UseMutationOptions<CreateEasypostCarrierAccountMutation, CreateEasypostCarrierAccountMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateEasypostCarrierAccountMutation, CreateEasypostCarrierAccountMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateEasypostCarrierAccountMutation, CreateEasypostCarrierAccountMutationVariables>(CreateEasypostCarrierAccountDocument, options);
}
export type CreateEasypostCarrierAccountMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateEasypostCarrierAccountMutation, CreateEasypostCarrierAccountMutationVariables>;
export const UnAllocatedDocument = gql`
    query UnAllocated($filters: [QueryFilter!], $order: [String!]) {
  unAllocatedParts: inventory_items(
    input: {filters: $filters, order_by: $order, limit: 200}
  ) {
    id
    purchases_items_details {
      id
      current_cost {
        ...Money
      }
      line_number
      original_cost {
        ...Money
      }
      transaction {
        id
        rep {
          id
          initials
        }
      }
    }
    part {
      id
      pn
    }
    sales_items_details {
      id
    }
    received_date
    serial_number
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __useUnAllocatedQuery__
 *
 * To run a query within a Vue component, call `useUnAllocatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnAllocatedQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUnAllocatedQuery({
 *   filters: // value for 'filters'
 *   order: // value for 'order'
 * });
 */
export function useUnAllocatedQuery(variables: UnAllocatedQueryVariables | VueCompositionApi.Ref<UnAllocatedQueryVariables> | ReactiveFunction<UnAllocatedQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<UnAllocatedQuery, UnAllocatedQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UnAllocatedQuery, UnAllocatedQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UnAllocatedQuery, UnAllocatedQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<UnAllocatedQuery, UnAllocatedQueryVariables>(UnAllocatedDocument, variables, options);
}
export type UnAllocatedQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<UnAllocatedQuery, UnAllocatedQueryVariables>;
export const ItemsDocument = gql`
    query Items($input: ManyQueryInput!) {
  items: inventory_items(input: $input) {
    id
    serial_number
    receive_status
    location {
      id
      name
      site {
        id
        name
      }
    }
    purchaseItem: purchases_items_details {
      id
      line_number
      original_cost {
        ...Money
      }
    }
    saleItem: sales_items_details {
      id
      transaction {
        id
      }
    }
    locked
    hot
    is_lost
    country_of_origin {
      code
      name
      flag: unicode_flag
    }
    test_status {
      id
      tag
      color
    }
    part {
      id
      pn
      description
    }
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __useItemsQuery__
 *
 * To run a query within a Vue component, call `useItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useItemsQuery({
 *   input: // value for 'input'
 * });
 */
export function useItemsQuery(variables: ItemsQueryVariables | VueCompositionApi.Ref<ItemsQueryVariables> | ReactiveFunction<ItemsQueryVariables>, options: VueApolloComposable.UseQueryOptions<ItemsQuery, ItemsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ItemsQuery, ItemsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ItemsQuery, ItemsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ItemsQuery, ItemsQueryVariables>(ItemsDocument, variables, options);
}
export type ItemsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ItemsQuery, ItemsQueryVariables>;
export const GetItemsDocument = gql`
    query GetItems($input: ManyQueryInput!) {
  items: inventory_items(input: $input) {
    ...InventoryItem
  }
}
    ${InventoryItemFragmentDoc}`;

/**
 * __useGetItemsQuery__
 *
 * To run a query within a Vue component, call `useGetItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetItemsQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetItemsQuery(variables: GetItemsQueryVariables | VueCompositionApi.Ref<GetItemsQueryVariables> | ReactiveFunction<GetItemsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetItemsQuery, GetItemsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetItemsQuery, GetItemsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetItemsQuery, GetItemsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetItemsQuery, GetItemsQueryVariables>(GetItemsDocument, variables, options);
}
export type GetItemsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetItemsQuery, GetItemsQueryVariables>;
export const GetSystemsDocument = gql`
    query GetSystems($input: ManyQueryInput!) {
  systems: inventory_systems(input: $input) {
    ...BasicSystem
    oobmgmt_serial
    annotations {
      name
      value
    }
  }
}
    ${BasicSystemFragmentDoc}`;

/**
 * __useGetSystemsQuery__
 *
 * To run a query within a Vue component, call `useGetSystemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSystemsQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetSystemsQuery(variables: GetSystemsQueryVariables | VueCompositionApi.Ref<GetSystemsQueryVariables> | ReactiveFunction<GetSystemsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetSystemsQuery, GetSystemsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetSystemsQuery, GetSystemsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetSystemsQuery, GetSystemsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetSystemsQuery, GetSystemsQueryVariables>(GetSystemsDocument, variables, options);
}
export type GetSystemsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetSystemsQuery, GetSystemsQueryVariables>;
export const GetItemsForBuildUpDocument = gql`
    query GetItemsForBuildUp($pn: Any!) {
  items: inventory_items(
    input: {filters: [{key: "part__pn", value: $pn}, {key: "sales_items_details__isnull", value: true}, {key: "build_up_items__isnull", value: true}, {key: "system__build_up_items__isnull", value: true}]}
  ) {
    id
    build_up_items {
      id
    }
    serial_number
    part {
      id
      pn
      description
    }
    pt: purchases_items_details {
      id
      line_number
      transaction {
        id
      }
    }
  }
}
    `;

/**
 * __useGetItemsForBuildUpQuery__
 *
 * To run a query within a Vue component, call `useGetItemsForBuildUpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemsForBuildUpQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetItemsForBuildUpQuery({
 *   pn: // value for 'pn'
 * });
 */
export function useGetItemsForBuildUpQuery(variables: GetItemsForBuildUpQueryVariables | VueCompositionApi.Ref<GetItemsForBuildUpQueryVariables> | ReactiveFunction<GetItemsForBuildUpQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetItemsForBuildUpQuery, GetItemsForBuildUpQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetItemsForBuildUpQuery, GetItemsForBuildUpQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetItemsForBuildUpQuery, GetItemsForBuildUpQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetItemsForBuildUpQuery, GetItemsForBuildUpQueryVariables>(GetItemsForBuildUpDocument, variables, options);
}
export type GetItemsForBuildUpQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetItemsForBuildUpQuery, GetItemsForBuildUpQueryVariables>;
export const GetItemsForBreakdownDocument = gql`
    query GetItemsForBreakdown($pn: Any!) {
  items: inventory_items(
    input: {filters: [{key: "part__pn", value: $pn}, {key: "sales_items_details__isnull", value: true}]}
  ) {
    id
    serial_number
    part {
      id
      pn
      description
    }
    pt: purchases_items_details {
      id
      line_number
      transaction {
        id
      }
    }
  }
}
    `;

/**
 * __useGetItemsForBreakdownQuery__
 *
 * To run a query within a Vue component, call `useGetItemsForBreakdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemsForBreakdownQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetItemsForBreakdownQuery({
 *   pn: // value for 'pn'
 * });
 */
export function useGetItemsForBreakdownQuery(variables: GetItemsForBreakdownQueryVariables | VueCompositionApi.Ref<GetItemsForBreakdownQueryVariables> | ReactiveFunction<GetItemsForBreakdownQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetItemsForBreakdownQuery, GetItemsForBreakdownQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetItemsForBreakdownQuery, GetItemsForBreakdownQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetItemsForBreakdownQuery, GetItemsForBreakdownQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetItemsForBreakdownQuery, GetItemsForBreakdownQueryVariables>(GetItemsForBreakdownDocument, variables, options);
}
export type GetItemsForBreakdownQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetItemsForBreakdownQuery, GetItemsForBreakdownQueryVariables>;
export const ItemFromPtIdDocument = gql`
    query ItemFromPtID($pt: Any!, $line: Any!) {
  item: inventory_item(
    input: {filters: [{key: "purchases_items_details__transaction__id", value: $pt}, {key: "purchases_items_details__line_number", value: $line}, {key: "build_up_items__isnull", value: true}]}
  ) {
    id
    part {
      id
      pn
    }
  }
}
    `;

/**
 * __useItemFromPtIdQuery__
 *
 * To run a query within a Vue component, call `useItemFromPtIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemFromPtIdQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useItemFromPtIdQuery({
 *   pt: // value for 'pt'
 *   line: // value for 'line'
 * });
 */
export function useItemFromPtIdQuery(variables: ItemFromPtIdQueryVariables | VueCompositionApi.Ref<ItemFromPtIdQueryVariables> | ReactiveFunction<ItemFromPtIdQueryVariables>, options: VueApolloComposable.UseQueryOptions<ItemFromPtIdQuery, ItemFromPtIdQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ItemFromPtIdQuery, ItemFromPtIdQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ItemFromPtIdQuery, ItemFromPtIdQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ItemFromPtIdQuery, ItemFromPtIdQueryVariables>(ItemFromPtIdDocument, variables, options);
}
export type ItemFromPtIdQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ItemFromPtIdQuery, ItemFromPtIdQueryVariables>;
export const MasterPartSalesItemsDocument = gql`
    query MasterPartSalesItems($input: ManyQueryInput!) {
  sold_items: sales_sold_items(input: $input) {
    id
    creation_date
    sold_for {
      ...Money
    }
    transaction {
      id
      creation_date
      rep {
        id
        initials
      }
      client {
        id
        name
      }
    }
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __useMasterPartSalesItemsQuery__
 *
 * To run a query within a Vue component, call `useMasterPartSalesItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterPartSalesItemsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMasterPartSalesItemsQuery({
 *   input: // value for 'input'
 * });
 */
export function useMasterPartSalesItemsQuery(variables: MasterPartSalesItemsQueryVariables | VueCompositionApi.Ref<MasterPartSalesItemsQueryVariables> | ReactiveFunction<MasterPartSalesItemsQueryVariables>, options: VueApolloComposable.UseQueryOptions<MasterPartSalesItemsQuery, MasterPartSalesItemsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MasterPartSalesItemsQuery, MasterPartSalesItemsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MasterPartSalesItemsQuery, MasterPartSalesItemsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MasterPartSalesItemsQuery, MasterPartSalesItemsQueryVariables>(MasterPartSalesItemsDocument, variables, options);
}
export type MasterPartSalesItemsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MasterPartSalesItemsQuery, MasterPartSalesItemsQueryVariables>;
export const MasterPartPurchasesItemsDocument = gql`
    query MasterPartPurchasesItems($input: ManyQueryInput!) {
  purchased_items: purchases_purchased_items(input: $input) {
    id
    creation_date
    transaction {
      id
      creation_date
      rep {
        id
        initials
      }
      client {
        id
        name
      }
    }
    original_cost {
      ...Money
    }
    current_cost {
      ...Money
    }
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __useMasterPartPurchasesItemsQuery__
 *
 * To run a query within a Vue component, call `useMasterPartPurchasesItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterPartPurchasesItemsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMasterPartPurchasesItemsQuery({
 *   input: // value for 'input'
 * });
 */
export function useMasterPartPurchasesItemsQuery(variables: MasterPartPurchasesItemsQueryVariables | VueCompositionApi.Ref<MasterPartPurchasesItemsQueryVariables> | ReactiveFunction<MasterPartPurchasesItemsQueryVariables>, options: VueApolloComposable.UseQueryOptions<MasterPartPurchasesItemsQuery, MasterPartPurchasesItemsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MasterPartPurchasesItemsQuery, MasterPartPurchasesItemsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MasterPartPurchasesItemsQuery, MasterPartPurchasesItemsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MasterPartPurchasesItemsQuery, MasterPartPurchasesItemsQueryVariables>(MasterPartPurchasesItemsDocument, variables, options);
}
export type MasterPartPurchasesItemsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MasterPartPurchasesItemsQuery, MasterPartPurchasesItemsQueryVariables>;
export const MasterPartQuotesItemsDocument = gql`
    query MasterPartQuotesItems($input: ManyQueryInput!) {
  quote_parts: quotes_quote_parts(input: $input) {
    id
    creation_date
    annotations {
      name
      value
    }
    quote {
      id
      attached_sales {
        id
      }
      client {
        id
        name
      }
      contact {
        id
        full_name
      }
    }
    price_per_part {
      ...Money
    }
    qty
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __useMasterPartQuotesItemsQuery__
 *
 * To run a query within a Vue component, call `useMasterPartQuotesItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterPartQuotesItemsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMasterPartQuotesItemsQuery({
 *   input: // value for 'input'
 * });
 */
export function useMasterPartQuotesItemsQuery(variables: MasterPartQuotesItemsQueryVariables | VueCompositionApi.Ref<MasterPartQuotesItemsQueryVariables> | ReactiveFunction<MasterPartQuotesItemsQueryVariables>, options: VueApolloComposable.UseQueryOptions<MasterPartQuotesItemsQuery, MasterPartQuotesItemsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MasterPartQuotesItemsQuery, MasterPartQuotesItemsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MasterPartQuotesItemsQuery, MasterPartQuotesItemsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MasterPartQuotesItemsQuery, MasterPartQuotesItemsQueryVariables>(MasterPartQuotesItemsDocument, variables, options);
}
export type MasterPartQuotesItemsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MasterPartQuotesItemsQuery, MasterPartQuotesItemsQueryVariables>;
export const MasterPartSubPartsDocument = gql`
    query MasterPartSubParts($input: ManyQueryInput!) {
  sub_parts: parts_parts(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useMasterPartSubPartsQuery__
 *
 * To run a query within a Vue component, call `useMasterPartSubPartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterPartSubPartsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMasterPartSubPartsQuery({
 *   input: // value for 'input'
 * });
 */
export function useMasterPartSubPartsQuery(variables: MasterPartSubPartsQueryVariables | VueCompositionApi.Ref<MasterPartSubPartsQueryVariables> | ReactiveFunction<MasterPartSubPartsQueryVariables>, options: VueApolloComposable.UseQueryOptions<MasterPartSubPartsQuery, MasterPartSubPartsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MasterPartSubPartsQuery, MasterPartSubPartsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MasterPartSubPartsQuery, MasterPartSubPartsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MasterPartSubPartsQuery, MasterPartSubPartsQueryVariables>(MasterPartSubPartsDocument, variables, options);
}
export type MasterPartSubPartsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MasterPartSubPartsQuery, MasterPartSubPartsQueryVariables>;
export const GetSystemDocument = gql`
    query GetSystem($id: ID!) {
  inventory_system(input: {id: $id}) {
    id
    serial_number
    part {
      id
      pn
      description
    }
    purchases_items_details {
      id
      line_number
      current_cost {
        ...Money
      }
      original_cost {
        ...Money
      }
      transaction {
        id
      }
    }
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __useGetSystemQuery__
 *
 * To run a query within a Vue component, call `useGetSystemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSystemQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetSystemQuery(variables: GetSystemQueryVariables | VueCompositionApi.Ref<GetSystemQueryVariables> | ReactiveFunction<GetSystemQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetSystemQuery, GetSystemQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetSystemQuery, GetSystemQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetSystemQuery, GetSystemQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetSystemQuery, GetSystemQueryVariables>(GetSystemDocument, variables, options);
}
export type GetSystemQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetSystemQuery, GetSystemQueryVariables>;
export const GetAddOnItemsForSystemDocument = gql`
    query GetAddOnItemsForSystem($input: ManyQueryInput!) {
  items: inventory_items(input: $input) {
    id
    broke_down_items {
      id
      status {
        id
        status
      }
    }
    part {
      id
      pn
      description
    }
    serial_number
    system {
      id
    }
    purchases_items_details {
      id
      line_number
      transaction {
        id
      }
      original_cost {
        ...Money
      }
      current_cost {
        ...Money
      }
    }
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __useGetAddOnItemsForSystemQuery__
 *
 * To run a query within a Vue component, call `useGetAddOnItemsForSystemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddOnItemsForSystemQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAddOnItemsForSystemQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetAddOnItemsForSystemQuery(variables: GetAddOnItemsForSystemQueryVariables | VueCompositionApi.Ref<GetAddOnItemsForSystemQueryVariables> | ReactiveFunction<GetAddOnItemsForSystemQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetAddOnItemsForSystemQuery, GetAddOnItemsForSystemQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetAddOnItemsForSystemQuery, GetAddOnItemsForSystemQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetAddOnItemsForSystemQuery, GetAddOnItemsForSystemQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetAddOnItemsForSystemQuery, GetAddOnItemsForSystemQueryVariables>(GetAddOnItemsForSystemDocument, variables, options);
}
export type GetAddOnItemsForSystemQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetAddOnItemsForSystemQuery, GetAddOnItemsForSystemQueryVariables>;
export const BasicPartInfoDocument = gql`
    query BasicPartInfo($filters: [QueryFilter!], $excludes: [QueryFilter!]) {
  parts: parts_parts(input: {filters: $filters, excludes: $excludes}) {
    id
    title: pn
    subtitle: description
    secondary_pn
  }
}
    `;

/**
 * __useBasicPartInfoQuery__
 *
 * To run a query within a Vue component, call `useBasicPartInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasicPartInfoQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useBasicPartInfoQuery({
 *   filters: // value for 'filters'
 *   excludes: // value for 'excludes'
 * });
 */
export function useBasicPartInfoQuery(variables: BasicPartInfoQueryVariables | VueCompositionApi.Ref<BasicPartInfoQueryVariables> | ReactiveFunction<BasicPartInfoQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<BasicPartInfoQuery, BasicPartInfoQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BasicPartInfoQuery, BasicPartInfoQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<BasicPartInfoQuery, BasicPartInfoQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<BasicPartInfoQuery, BasicPartInfoQueryVariables>(BasicPartInfoDocument, variables, options);
}
export type BasicPartInfoQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<BasicPartInfoQuery, BasicPartInfoQueryVariables>;
export const MasterPartsDocument = gql`
    query MasterParts($input: ManyQueryInput!) {
  parts: parts_parts(input: $input) {
    ...BasicPart
    ean
    hs_code
    htsus_code
    schedule_b_code
    secondary_pn
    upc
    market {
      id
      choice
    }
  }
}
    ${BasicPartFragmentDoc}`;

/**
 * __useMasterPartsQuery__
 *
 * To run a query within a Vue component, call `useMasterPartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterPartsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMasterPartsQuery({
 *   input: // value for 'input'
 * });
 */
export function useMasterPartsQuery(variables: MasterPartsQueryVariables | VueCompositionApi.Ref<MasterPartsQueryVariables> | ReactiveFunction<MasterPartsQueryVariables>, options: VueApolloComposable.UseQueryOptions<MasterPartsQuery, MasterPartsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MasterPartsQuery, MasterPartsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MasterPartsQuery, MasterPartsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MasterPartsQuery, MasterPartsQueryVariables>(MasterPartsDocument, variables, options);
}
export type MasterPartsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MasterPartsQuery, MasterPartsQueryVariables>;
export const BuySellHistoryDocument = gql`
    query BuySellHistory($input: ManyQueryInput!) {
  items: inventory_items(input: $input) {
    id
    condition {
      id
      choice
    }
    part {
      ...BasicPart
    }
    purchases_items_details {
      id
      creation_date
      current_cost {
        ...Money
      }
      line_number
      original_cost {
        ...Money
      }
      transaction {
        id
        client {
          id
          name
        }
        purchase_date
        rep {
          id
          initials
        }
      }
    }
    receive_status
    sales_items_details {
      id
      creation_date
      line_number
      sold_for {
        ...Money
      }
      transaction {
        id
        client {
          id
          name
        }
        sale_date
        rep {
          id
          initials
        }
      }
    }
  }
}
    ${BasicPartFragmentDoc}
${MoneyFragmentDoc}`;

/**
 * __useBuySellHistoryQuery__
 *
 * To run a query within a Vue component, call `useBuySellHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuySellHistoryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useBuySellHistoryQuery({
 *   input: // value for 'input'
 * });
 */
export function useBuySellHistoryQuery(variables: BuySellHistoryQueryVariables | VueCompositionApi.Ref<BuySellHistoryQueryVariables> | ReactiveFunction<BuySellHistoryQueryVariables>, options: VueApolloComposable.UseQueryOptions<BuySellHistoryQuery, BuySellHistoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BuySellHistoryQuery, BuySellHistoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<BuySellHistoryQuery, BuySellHistoryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<BuySellHistoryQuery, BuySellHistoryQueryVariables>(BuySellHistoryDocument, variables, options);
}
export type BuySellHistoryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<BuySellHistoryQuery, BuySellHistoryQueryVariables>;
export const PartsDocument = gql`
    query Parts($filters: [QueryFilter]) {
  parts: parts_parts(input: {filters: $filters}) {
    pn
    id
    description
    type
    mfgr {
      id
      name
    }
  }
}
    `;

/**
 * __usePartsQuery__
 *
 * To run a query within a Vue component, call `usePartsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePartsQuery({
 *   filters: // value for 'filters'
 * });
 */
export function usePartsQuery(variables: PartsQueryVariables | VueCompositionApi.Ref<PartsQueryVariables> | ReactiveFunction<PartsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<PartsQuery, PartsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PartsQuery, PartsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PartsQuery, PartsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PartsQuery, PartsQueryVariables>(PartsDocument, variables, options);
}
export type PartsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PartsQuery, PartsQueryVariables>;
export const LinkSubPartOneWayDocument = gql`
    mutation LinkSubPartOneWay($part: ID!, $sub: ID!) {
  sub: Update__Parts_Part(input: {id: $sub, alt_part_for_part_id: $part}) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useLinkSubPartOneWayMutation__
 *
 * To run a mutation, you first call `useLinkSubPartOneWayMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useLinkSubPartOneWayMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useLinkSubPartOneWayMutation({
 *   variables: {
 *     part: // value for 'part'
 *     sub: // value for 'sub'
 *   },
 * });
 */
export function useLinkSubPartOneWayMutation(options: VueApolloComposable.UseMutationOptions<LinkSubPartOneWayMutation, LinkSubPartOneWayMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<LinkSubPartOneWayMutation, LinkSubPartOneWayMutationVariables>>) {
  return VueApolloComposable.useMutation<LinkSubPartOneWayMutation, LinkSubPartOneWayMutationVariables>(LinkSubPartOneWayDocument, options);
}
export type LinkSubPartOneWayMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<LinkSubPartOneWayMutation, LinkSubPartOneWayMutationVariables>;
export const LinkSubPartTwoWayDocument = gql`
    mutation LinkSubPartTwoWay($part: ID!, $sub: ID!) {
  sub: Update__Parts_Part(input: {id: $part, alt_part_for_part_id: $sub}) {
    ...FullPart
  }
  part: Update__Parts_Part(input: {id: $sub, alt_part_for_part_id: $part}) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useLinkSubPartTwoWayMutation__
 *
 * To run a mutation, you first call `useLinkSubPartTwoWayMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useLinkSubPartTwoWayMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useLinkSubPartTwoWayMutation({
 *   variables: {
 *     part: // value for 'part'
 *     sub: // value for 'sub'
 *   },
 * });
 */
export function useLinkSubPartTwoWayMutation(options: VueApolloComposable.UseMutationOptions<LinkSubPartTwoWayMutation, LinkSubPartTwoWayMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<LinkSubPartTwoWayMutation, LinkSubPartTwoWayMutationVariables>>) {
  return VueApolloComposable.useMutation<LinkSubPartTwoWayMutation, LinkSubPartTwoWayMutationVariables>(LinkSubPartTwoWayDocument, options);
}
export type LinkSubPartTwoWayMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<LinkSubPartTwoWayMutation, LinkSubPartTwoWayMutationVariables>;
export const UnlinkSubPartOneWayDocument = gql`
    mutation UnlinkSubPartOneWay($part: ID!) {
  part: Update__Parts_Part(input: {id: $part, alt_part_for_part_id: null}) {
    id
  }
}
    `;

/**
 * __useUnlinkSubPartOneWayMutation__
 *
 * To run a mutation, you first call `useUnlinkSubPartOneWayMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkSubPartOneWayMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUnlinkSubPartOneWayMutation({
 *   variables: {
 *     part: // value for 'part'
 *   },
 * });
 */
export function useUnlinkSubPartOneWayMutation(options: VueApolloComposable.UseMutationOptions<UnlinkSubPartOneWayMutation, UnlinkSubPartOneWayMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UnlinkSubPartOneWayMutation, UnlinkSubPartOneWayMutationVariables>>) {
  return VueApolloComposable.useMutation<UnlinkSubPartOneWayMutation, UnlinkSubPartOneWayMutationVariables>(UnlinkSubPartOneWayDocument, options);
}
export type UnlinkSubPartOneWayMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UnlinkSubPartOneWayMutation, UnlinkSubPartOneWayMutationVariables>;
export const UpdateInventoryItemForCommentDocument = gql`
    mutation UpdateInventoryItemForComment($input: [Update__Inventory_Item__Input!]!) {
  comment: Update__Inventory_Item(input: $input) {
    id
    ...InventoryItem
  }
}
    ${InventoryItemFragmentDoc}`;

/**
 * __useUpdateInventoryItemForCommentMutation__
 *
 * To run a mutation, you first call `useUpdateInventoryItemForCommentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInventoryItemForCommentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateInventoryItemForCommentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInventoryItemForCommentMutation(options: VueApolloComposable.UseMutationOptions<UpdateInventoryItemForCommentMutation, UpdateInventoryItemForCommentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateInventoryItemForCommentMutation, UpdateInventoryItemForCommentMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateInventoryItemForCommentMutation, UpdateInventoryItemForCommentMutationVariables>(UpdateInventoryItemForCommentDocument, options);
}
export type UpdateInventoryItemForCommentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateInventoryItemForCommentMutation, UpdateInventoryItemForCommentMutationVariables>;
export const UpdateInventoryCommentDocument = gql`
    mutation UpdateInventoryComment($input: [Update__Inventory_Item__Input!]!) {
  comment: Update__Inventory_Item(input: $input) {
    id
    condition_comment
    inventory_comment
    lock_reason
  }
}
    `;

/**
 * __useUpdateInventoryCommentMutation__
 *
 * To run a mutation, you first call `useUpdateInventoryCommentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInventoryCommentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateInventoryCommentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInventoryCommentMutation(options: VueApolloComposable.UseMutationOptions<UpdateInventoryCommentMutation, UpdateInventoryCommentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateInventoryCommentMutation, UpdateInventoryCommentMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateInventoryCommentMutation, UpdateInventoryCommentMutationVariables>(UpdateInventoryCommentDocument, options);
}
export type UpdateInventoryCommentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateInventoryCommentMutation, UpdateInventoryCommentMutationVariables>;
export const UpdateInventorySystemForCommentDocument = gql`
    mutation UpdateInventorySystemForComment($input: Update__Inventory_System__Input!) {
  comment: Update__Inventory_System(input: $input) {
    ...BasicSystem
  }
}
    ${BasicSystemFragmentDoc}`;

/**
 * __useUpdateInventorySystemForCommentMutation__
 *
 * To run a mutation, you first call `useUpdateInventorySystemForCommentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInventorySystemForCommentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateInventorySystemForCommentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInventorySystemForCommentMutation(options: VueApolloComposable.UseMutationOptions<UpdateInventorySystemForCommentMutation, UpdateInventorySystemForCommentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateInventorySystemForCommentMutation, UpdateInventorySystemForCommentMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateInventorySystemForCommentMutation, UpdateInventorySystemForCommentMutationVariables>(UpdateInventorySystemForCommentDocument, options);
}
export type UpdateInventorySystemForCommentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateInventorySystemForCommentMutation, UpdateInventorySystemForCommentMutationVariables>;
export const UpdateInventoryItemDocument = gql`
    mutation UpdateInventoryItem($input: [Update__Inventory_Item__Input!]!) {
  Update__Inventory_Item(input: $input) {
    id
    ...InventoryItem
  }
}
    ${InventoryItemFragmentDoc}`;

/**
 * __useUpdateInventoryItemMutation__
 *
 * To run a mutation, you first call `useUpdateInventoryItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInventoryItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateInventoryItemMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInventoryItemMutation(options: VueApolloComposable.UseMutationOptions<UpdateInventoryItemMutation, UpdateInventoryItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateInventoryItemMutation, UpdateInventoryItemMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateInventoryItemMutation, UpdateInventoryItemMutationVariables>(UpdateInventoryItemDocument, options);
}
export type UpdateInventoryItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateInventoryItemMutation, UpdateInventoryItemMutationVariables>;
export const SetItemTestStatusDocument = gql`
    mutation SetItemTestStatus($id: ID!, $statuses: [ID!]) {
  item: UpdateItemTestStatus(input: {id: $id, ids_to_alter: $statuses, type: SET}) {
    id
    test_status {
      id
      tag
      description
      color
    }
  }
}
    `;

/**
 * __useSetItemTestStatusMutation__
 *
 * To run a mutation, you first call `useSetItemTestStatusMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetItemTestStatusMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetItemTestStatusMutation({
 *   variables: {
 *     id: // value for 'id'
 *     statuses: // value for 'statuses'
 *   },
 * });
 */
export function useSetItemTestStatusMutation(options: VueApolloComposable.UseMutationOptions<SetItemTestStatusMutation, SetItemTestStatusMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SetItemTestStatusMutation, SetItemTestStatusMutationVariables>>) {
  return VueApolloComposable.useMutation<SetItemTestStatusMutation, SetItemTestStatusMutationVariables>(SetItemTestStatusDocument, options);
}
export type SetItemTestStatusMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SetItemTestStatusMutation, SetItemTestStatusMutationVariables>;
export const UpdateInventorySystemDocument = gql`
    mutation UpdateInventorySystem($input: Update__Inventory_System__Input!) {
  Update__Inventory_System(input: $input) {
    ...BasicSystem
  }
}
    ${BasicSystemFragmentDoc}`;

/**
 * __useUpdateInventorySystemMutation__
 *
 * To run a mutation, you first call `useUpdateInventorySystemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInventorySystemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateInventorySystemMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInventorySystemMutation(options: VueApolloComposable.UseMutationOptions<UpdateInventorySystemMutation, UpdateInventorySystemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateInventorySystemMutation, UpdateInventorySystemMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateInventorySystemMutation, UpdateInventorySystemMutationVariables>(UpdateInventorySystemDocument, options);
}
export type UpdateInventorySystemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateInventorySystemMutation, UpdateInventorySystemMutationVariables>;
export const AddSerialNumberDocument = gql`
    mutation AddSerialNumber($id: ID!, $serial: String) {
  UpdateItem: Update__Inventory_Item(input: {id: $id, serial_number: $serial}) {
    id
  }
}
    `;

/**
 * __useAddSerialNumberMutation__
 *
 * To run a mutation, you first call `useAddSerialNumberMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddSerialNumberMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddSerialNumberMutation({
 *   variables: {
 *     id: // value for 'id'
 *     serial: // value for 'serial'
 *   },
 * });
 */
export function useAddSerialNumberMutation(options: VueApolloComposable.UseMutationOptions<AddSerialNumberMutation, AddSerialNumberMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<AddSerialNumberMutation, AddSerialNumberMutationVariables>>) {
  return VueApolloComposable.useMutation<AddSerialNumberMutation, AddSerialNumberMutationVariables>(AddSerialNumberDocument, options);
}
export type AddSerialNumberMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<AddSerialNumberMutation, AddSerialNumberMutationVariables>;
export const MarkItemAsLostDocument = gql`
    mutation MarkItemAsLost($id: ID!, $lost: Boolean) {
  item: Update__Inventory_Item(input: {id: $id, is_lost: $lost}) {
    id
  }
}
    `;

/**
 * __useMarkItemAsLostMutation__
 *
 * To run a mutation, you first call `useMarkItemAsLostMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useMarkItemAsLostMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useMarkItemAsLostMutation({
 *   variables: {
 *     id: // value for 'id'
 *     lost: // value for 'lost'
 *   },
 * });
 */
export function useMarkItemAsLostMutation(options: VueApolloComposable.UseMutationOptions<MarkItemAsLostMutation, MarkItemAsLostMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<MarkItemAsLostMutation, MarkItemAsLostMutationVariables>>) {
  return VueApolloComposable.useMutation<MarkItemAsLostMutation, MarkItemAsLostMutationVariables>(MarkItemAsLostDocument, options);
}
export type MarkItemAsLostMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<MarkItemAsLostMutation, MarkItemAsLostMutationVariables>;
export const ReceiveItemDocument = gql`
    mutation ReceiveItem($id: ID!, $country: COUNTRIES, $serial: String, $loc: ID, $user: ID, $date: DateTime, $status: Boolean) {
  UpdateItem: Update__Inventory_Item(
    input: {id: $id, country_of_origin: $country, serial_number: $serial, location_id: $loc, received_by_id: $user, received_date: $date, receive_status: $status}
  ) {
    id
    serial_number
    location {
      id
      name
    }
    receive_status
  }
}
    `;

/**
 * __useReceiveItemMutation__
 *
 * To run a mutation, you first call `useReceiveItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useReceiveItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useReceiveItemMutation({
 *   variables: {
 *     id: // value for 'id'
 *     country: // value for 'country'
 *     serial: // value for 'serial'
 *     loc: // value for 'loc'
 *     user: // value for 'user'
 *     date: // value for 'date'
 *     status: // value for 'status'
 *   },
 * });
 */
export function useReceiveItemMutation(options: VueApolloComposable.UseMutationOptions<ReceiveItemMutation, ReceiveItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ReceiveItemMutation, ReceiveItemMutationVariables>>) {
  return VueApolloComposable.useMutation<ReceiveItemMutation, ReceiveItemMutationVariables>(ReceiveItemDocument, options);
}
export type ReceiveItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ReceiveItemMutation, ReceiveItemMutationVariables>;
export const UpdateSystemAddOnItemsDocument = gql`
    mutation UpdateSystemAddOnItems($system: ID!, $items: [ID!]) {
  system: UpdateSystemAddOnItems(
    input: {id: $system, ids_to_alter: $items, type: SET}
  ) {
    id
  }
}
    `;

/**
 * __useUpdateSystemAddOnItemsMutation__
 *
 * To run a mutation, you first call `useUpdateSystemAddOnItemsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemAddOnItemsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateSystemAddOnItemsMutation({
 *   variables: {
 *     system: // value for 'system'
 *     items: // value for 'items'
 *   },
 * });
 */
export function useUpdateSystemAddOnItemsMutation(options: VueApolloComposable.UseMutationOptions<UpdateSystemAddOnItemsMutation, UpdateSystemAddOnItemsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateSystemAddOnItemsMutation, UpdateSystemAddOnItemsMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateSystemAddOnItemsMutation, UpdateSystemAddOnItemsMutationVariables>(UpdateSystemAddOnItemsDocument, options);
}
export type UpdateSystemAddOnItemsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateSystemAddOnItemsMutation, UpdateSystemAddOnItemsMutationVariables>;
export const RemoveSystemAddOnItemsDocument = gql`
    mutation RemoveSystemAddOnItems($system: ID!, $items: [ID!]) {
  system: UpdateSystemAddOnItems(
    input: {id: $system, ids_to_alter: $items, type: REMOVE}
  ) {
    id
  }
}
    `;

/**
 * __useRemoveSystemAddOnItemsMutation__
 *
 * To run a mutation, you first call `useRemoveSystemAddOnItemsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSystemAddOnItemsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveSystemAddOnItemsMutation({
 *   variables: {
 *     system: // value for 'system'
 *     items: // value for 'items'
 *   },
 * });
 */
export function useRemoveSystemAddOnItemsMutation(options: VueApolloComposable.UseMutationOptions<RemoveSystemAddOnItemsMutation, RemoveSystemAddOnItemsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RemoveSystemAddOnItemsMutation, RemoveSystemAddOnItemsMutationVariables>>) {
  return VueApolloComposable.useMutation<RemoveSystemAddOnItemsMutation, RemoveSystemAddOnItemsMutationVariables>(RemoveSystemAddOnItemsDocument, options);
}
export type RemoveSystemAddOnItemsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RemoveSystemAddOnItemsMutation, RemoveSystemAddOnItemsMutationVariables>;
export const UpdatePartHasBatteryDocument = gql`
    mutation UpdatePartHasBattery($id: ID!, $has_battery: Boolean) {
  part: Update__Parts_Part(input: {id: $id, has_battery: $has_battery}) {
    id
  }
}
    `;

/**
 * __useUpdatePartHasBatteryMutation__
 *
 * To run a mutation, you first call `useUpdatePartHasBatteryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartHasBatteryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdatePartHasBatteryMutation({
 *   variables: {
 *     id: // value for 'id'
 *     has_battery: // value for 'has_battery'
 *   },
 * });
 */
export function useUpdatePartHasBatteryMutation(options: VueApolloComposable.UseMutationOptions<UpdatePartHasBatteryMutation, UpdatePartHasBatteryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdatePartHasBatteryMutation, UpdatePartHasBatteryMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdatePartHasBatteryMutation, UpdatePartHasBatteryMutationVariables>(UpdatePartHasBatteryDocument, options);
}
export type UpdatePartHasBatteryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdatePartHasBatteryMutation, UpdatePartHasBatteryMutationVariables>;
export const CreateAccessPointDocument = gql`
    mutation CreateAccessPoint($input: Create__Parts_AccessPoint__Input!) {
  part: Create__Parts_AccessPoint(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateAccessPointMutation__
 *
 * To run a mutation, you first call `useCreateAccessPointMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccessPointMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateAccessPointMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccessPointMutation(options: VueApolloComposable.UseMutationOptions<CreateAccessPointMutation, CreateAccessPointMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateAccessPointMutation, CreateAccessPointMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateAccessPointMutation, CreateAccessPointMutationVariables>(CreateAccessPointDocument, options);
}
export type CreateAccessPointMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateAccessPointMutation, CreateAccessPointMutationVariables>;
export const CreateBatteryDocument = gql`
    mutation CreateBattery($input: Create__Parts_Battery__Input!) {
  part: Create__Parts_Battery(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateBatteryMutation__
 *
 * To run a mutation, you first call `useCreateBatteryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateBatteryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateBatteryMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateBatteryMutation(options: VueApolloComposable.UseMutationOptions<CreateBatteryMutation, CreateBatteryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateBatteryMutation, CreateBatteryMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateBatteryMutation, CreateBatteryMutationVariables>(CreateBatteryDocument, options);
}
export type CreateBatteryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateBatteryMutation, CreateBatteryMutationVariables>;
export const CreateBezelDocument = gql`
    mutation CreateBezel($input: Create__Parts_Bezel__Input!) {
  part: Create__Parts_Bezel(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateBezelMutation__
 *
 * To run a mutation, you first call `useCreateBezelMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateBezelMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateBezelMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateBezelMutation(options: VueApolloComposable.UseMutationOptions<CreateBezelMutation, CreateBezelMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateBezelMutation, CreateBezelMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateBezelMutation, CreateBezelMutationVariables>(CreateBezelDocument, options);
}
export type CreateBezelMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateBezelMutation, CreateBezelMutationVariables>;
export const CreateBracketDocument = gql`
    mutation CreateBracket($input: Create__Parts_Bracket__Input!) {
  part: Create__Parts_Bracket(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateBracketMutation__
 *
 * To run a mutation, you first call `useCreateBracketMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateBracketMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateBracketMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateBracketMutation(options: VueApolloComposable.UseMutationOptions<CreateBracketMutation, CreateBracketMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateBracketMutation, CreateBracketMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateBracketMutation, CreateBracketMutationVariables>(CreateBracketDocument, options);
}
export type CreateBracketMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateBracketMutation, CreateBracketMutationVariables>;
export const CreateCpuDocument = gql`
    mutation CreateCPU($input: Create__Parts_CPU__Input!) {
  part: Create__Parts_CPU(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateCpuMutation__
 *
 * To run a mutation, you first call `useCreateCpuMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateCpuMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateCpuMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateCpuMutation(options: VueApolloComposable.UseMutationOptions<CreateCpuMutation, CreateCpuMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateCpuMutation, CreateCpuMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateCpuMutation, CreateCpuMutationVariables>(CreateCpuDocument, options);
}
export type CreateCpuMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateCpuMutation, CreateCpuMutationVariables>;
export const CreateCableDocument = gql`
    mutation CreateCable($input: Create__Parts_Cable__Input!) {
  part: Create__Parts_Cable(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateCableMutation__
 *
 * To run a mutation, you first call `useCreateCableMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateCableMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateCableMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateCableMutation(options: VueApolloComposable.UseMutationOptions<CreateCableMutation, CreateCableMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateCableMutation, CreateCableMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateCableMutation, CreateCableMutationVariables>(CreateCableDocument, options);
}
export type CreateCableMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateCableMutation, CreateCableMutationVariables>;
export const CreateCacheDocument = gql`
    mutation CreateCache($input: Create__Parts_Cache__Input!) {
  part: Create__Parts_Cache(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateCacheMutation__
 *
 * To run a mutation, you first call `useCreateCacheMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateCacheMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateCacheMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateCacheMutation(options: VueApolloComposable.UseMutationOptions<CreateCacheMutation, CreateCacheMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateCacheMutation, CreateCacheMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateCacheMutation, CreateCacheMutationVariables>(CreateCacheDocument, options);
}
export type CreateCacheMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateCacheMutation, CreateCacheMutationVariables>;
export const CreateDiskDriveDocument = gql`
    mutation CreateDiskDrive($input: Create__Parts_DiskDrive__Input!) {
  part: Create__Parts_DiskDrive(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateDiskDriveMutation__
 *
 * To run a mutation, you first call `useCreateDiskDriveMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateDiskDriveMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateDiskDriveMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateDiskDriveMutation(options: VueApolloComposable.UseMutationOptions<CreateDiskDriveMutation, CreateDiskDriveMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateDiskDriveMutation, CreateDiskDriveMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateDiskDriveMutation, CreateDiskDriveMutationVariables>(CreateDiskDriveDocument, options);
}
export type CreateDiskDriveMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateDiskDriveMutation, CreateDiskDriveMutationVariables>;
export const CreateExpansionCardDocument = gql`
    mutation CreateExpansionCard($input: Create__Parts_ExpansionCard__Input!) {
  part: Create__Parts_ExpansionCard(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateExpansionCardMutation__
 *
 * To run a mutation, you first call `useCreateExpansionCardMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateExpansionCardMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateExpansionCardMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateExpansionCardMutation(options: VueApolloComposable.UseMutationOptions<CreateExpansionCardMutation, CreateExpansionCardMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateExpansionCardMutation, CreateExpansionCardMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateExpansionCardMutation, CreateExpansionCardMutationVariables>(CreateExpansionCardDocument, options);
}
export type CreateExpansionCardMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateExpansionCardMutation, CreateExpansionCardMutationVariables>;
export const CreateFanDocument = gql`
    mutation CreateFan($input: Create__Parts_Fan__Input!) {
  part: Create__Parts_Fan(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateFanMutation__
 *
 * To run a mutation, you first call `useCreateFanMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateFanMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateFanMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateFanMutation(options: VueApolloComposable.UseMutationOptions<CreateFanMutation, CreateFanMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateFanMutation, CreateFanMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateFanMutation, CreateFanMutationVariables>(CreateFanDocument, options);
}
export type CreateFanMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateFanMutation, CreateFanMutationVariables>;
export const CreateGpuDocument = gql`
    mutation CreateGPU($input: Create__Parts_GPU__Input!) {
  part: Create__Parts_GPU(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateGpuMutation__
 *
 * To run a mutation, you first call `useCreateGpuMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateGpuMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateGpuMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateGpuMutation(options: VueApolloComposable.UseMutationOptions<CreateGpuMutation, CreateGpuMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateGpuMutation, CreateGpuMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateGpuMutation, CreateGpuMutationVariables>(CreateGpuDocument, options);
}
export type CreateGpuMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateGpuMutation, CreateGpuMutationVariables>;
export const CreateHeatSinkDocument = gql`
    mutation CreateHeatSink($input: Create__Parts_Heatsink__Input!) {
  part: Create__Parts_Heatsink(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateHeatSinkMutation__
 *
 * To run a mutation, you first call `useCreateHeatSinkMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateHeatSinkMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateHeatSinkMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateHeatSinkMutation(options: VueApolloComposable.UseMutationOptions<CreateHeatSinkMutation, CreateHeatSinkMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateHeatSinkMutation, CreateHeatSinkMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateHeatSinkMutation, CreateHeatSinkMutationVariables>(CreateHeatSinkDocument, options);
}
export type CreateHeatSinkMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateHeatSinkMutation, CreateHeatSinkMutationVariables>;
export const IoModuleDocument = gql`
    mutation IOModule($input: Create__Parts_IOModule__Input!) {
  part: Create__Parts_IOModule(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useIoModuleMutation__
 *
 * To run a mutation, you first call `useIoModuleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useIoModuleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useIoModuleMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useIoModuleMutation(options: VueApolloComposable.UseMutationOptions<IoModuleMutation, IoModuleMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<IoModuleMutation, IoModuleMutationVariables>>) {
  return VueApolloComposable.useMutation<IoModuleMutation, IoModuleMutationVariables>(IoModuleDocument, options);
}
export type IoModuleMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<IoModuleMutation, IoModuleMutationVariables>;
export const CreateMemoryDocument = gql`
    mutation CreateMemory($input: Create__Parts_Memory__Input!) {
  part: Create__Parts_Memory(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateMemoryMutation__
 *
 * To run a mutation, you first call `useCreateMemoryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemoryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateMemoryMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateMemoryMutation(options: VueApolloComposable.UseMutationOptions<CreateMemoryMutation, CreateMemoryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateMemoryMutation, CreateMemoryMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateMemoryMutation, CreateMemoryMutationVariables>(CreateMemoryDocument, options);
}
export type CreateMemoryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateMemoryMutation, CreateMemoryMutationVariables>;
export const CreateMotherboardDocument = gql`
    mutation CreateMotherboard($input: Create__Parts_Motherboard__Input!) {
  part: Create__Parts_Motherboard(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateMotherboardMutation__
 *
 * To run a mutation, you first call `useCreateMotherboardMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateMotherboardMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateMotherboardMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateMotherboardMutation(options: VueApolloComposable.UseMutationOptions<CreateMotherboardMutation, CreateMotherboardMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateMotherboardMutation, CreateMotherboardMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateMotherboardMutation, CreateMotherboardMutationVariables>(CreateMotherboardDocument, options);
}
export type CreateMotherboardMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateMotherboardMutation, CreateMotherboardMutationVariables>;
export const CreateNetworkCardDocument = gql`
    mutation CreateNetworkCard($input: Create__Parts_NetworkCard__Input!) {
  part: Create__Parts_NetworkCard(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateNetworkCardMutation__
 *
 * To run a mutation, you first call `useCreateNetworkCardMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateNetworkCardMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateNetworkCardMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateNetworkCardMutation(options: VueApolloComposable.UseMutationOptions<CreateNetworkCardMutation, CreateNetworkCardMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateNetworkCardMutation, CreateNetworkCardMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateNetworkCardMutation, CreateNetworkCardMutationVariables>(CreateNetworkCardDocument, options);
}
export type CreateNetworkCardMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateNetworkCardMutation, CreateNetworkCardMutationVariables>;
export const CreatePhoneDocument = gql`
    mutation CreatePhone($input: Create__Parts_Phone__Input!) {
  part: Create__Parts_Phone(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreatePhoneMutation__
 *
 * To run a mutation, you first call `useCreatePhoneMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreatePhoneMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreatePhoneMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreatePhoneMutation(options: VueApolloComposable.UseMutationOptions<CreatePhoneMutation, CreatePhoneMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreatePhoneMutation, CreatePhoneMutationVariables>>) {
  return VueApolloComposable.useMutation<CreatePhoneMutation, CreatePhoneMutationVariables>(CreatePhoneDocument, options);
}
export type CreatePhoneMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreatePhoneMutation, CreatePhoneMutationVariables>;
export const CreatePowerSupplyDocument = gql`
    mutation CreatePowerSupply($input: Create__Parts_PowerSupply__Input!) {
  part: Create__Parts_PowerSupply(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreatePowerSupplyMutation__
 *
 * To run a mutation, you first call `useCreatePowerSupplyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreatePowerSupplyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreatePowerSupplyMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreatePowerSupplyMutation(options: VueApolloComposable.UseMutationOptions<CreatePowerSupplyMutation, CreatePowerSupplyMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreatePowerSupplyMutation, CreatePowerSupplyMutationVariables>>) {
  return VueApolloComposable.useMutation<CreatePowerSupplyMutation, CreatePowerSupplyMutationVariables>(CreatePowerSupplyDocument, options);
}
export type CreatePowerSupplyMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreatePowerSupplyMutation, CreatePowerSupplyMutationVariables>;
export const CreateRailsDocument = gql`
    mutation CreateRails($input: Create__Parts_Rails__Input!) {
  part: Create__Parts_Rails(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateRailsMutation__
 *
 * To run a mutation, you first call `useCreateRailsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateRailsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateRailsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateRailsMutation(options: VueApolloComposable.UseMutationOptions<CreateRailsMutation, CreateRailsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateRailsMutation, CreateRailsMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateRailsMutation, CreateRailsMutationVariables>(CreateRailsDocument, options);
}
export type CreateRailsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateRailsMutation, CreateRailsMutationVariables>;
export const CreateRouterDocument = gql`
    mutation CreateRouter($input: Create__Parts_Router__Input!) {
  part: Create__Parts_Router(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateRouterMutation__
 *
 * To run a mutation, you first call `useCreateRouterMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateRouterMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateRouterMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateRouterMutation(options: VueApolloComposable.UseMutationOptions<CreateRouterMutation, CreateRouterMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateRouterMutation, CreateRouterMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateRouterMutation, CreateRouterMutationVariables>(CreateRouterDocument, options);
}
export type CreateRouterMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateRouterMutation, CreateRouterMutationVariables>;
export const CreateServerDocument = gql`
    mutation CreateServer($input: Create__Parts_Server__Input!) {
  part: Create__Parts_Server(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateServerMutation__
 *
 * To run a mutation, you first call `useCreateServerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateServerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateServerMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateServerMutation(options: VueApolloComposable.UseMutationOptions<CreateServerMutation, CreateServerMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateServerMutation, CreateServerMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateServerMutation, CreateServerMutationVariables>(CreateServerDocument, options);
}
export type CreateServerMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateServerMutation, CreateServerMutationVariables>;
export const CreateStorageDocument = gql`
    mutation CreateStorage($input: Create__Parts_Storage__Input!) {
  part: Create__Parts_Storage(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateStorageMutation__
 *
 * To run a mutation, you first call `useCreateStorageMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateStorageMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateStorageMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateStorageMutation(options: VueApolloComposable.UseMutationOptions<CreateStorageMutation, CreateStorageMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateStorageMutation, CreateStorageMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateStorageMutation, CreateStorageMutationVariables>(CreateStorageDocument, options);
}
export type CreateStorageMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateStorageMutation, CreateStorageMutationVariables>;
export const CreateStorageEnclosureDocument = gql`
    mutation CreateStorageEnclosure($input: Create__Parts_StorageEnclosure__Input!) {
  part: Create__Parts_StorageEnclosure(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateStorageEnclosureMutation__
 *
 * To run a mutation, you first call `useCreateStorageEnclosureMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateStorageEnclosureMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateStorageEnclosureMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateStorageEnclosureMutation(options: VueApolloComposable.UseMutationOptions<CreateStorageEnclosureMutation, CreateStorageEnclosureMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateStorageEnclosureMutation, CreateStorageEnclosureMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateStorageEnclosureMutation, CreateStorageEnclosureMutationVariables>(CreateStorageEnclosureDocument, options);
}
export type CreateStorageEnclosureMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateStorageEnclosureMutation, CreateStorageEnclosureMutationVariables>;
export const CreateSuppliesDocument = gql`
    mutation CreateSupplies($input: Create__Parts_Supplies__Input!) {
  part: Create__Parts_Supplies(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateSuppliesMutation__
 *
 * To run a mutation, you first call `useCreateSuppliesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateSuppliesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateSuppliesMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateSuppliesMutation(options: VueApolloComposable.UseMutationOptions<CreateSuppliesMutation, CreateSuppliesMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateSuppliesMutation, CreateSuppliesMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateSuppliesMutation, CreateSuppliesMutationVariables>(CreateSuppliesDocument, options);
}
export type CreateSuppliesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateSuppliesMutation, CreateSuppliesMutationVariables>;
export const CreateSwitchDocument = gql`
    mutation CreateSwitch($input: Create__Parts_Switch__Input!) {
  part: Create__Parts_Switch(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateSwitchMutation__
 *
 * To run a mutation, you first call `useCreateSwitchMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateSwitchMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateSwitchMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateSwitchMutation(options: VueApolloComposable.UseMutationOptions<CreateSwitchMutation, CreateSwitchMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateSwitchMutation, CreateSwitchMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateSwitchMutation, CreateSwitchMutationVariables>(CreateSwitchDocument, options);
}
export type CreateSwitchMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateSwitchMutation, CreateSwitchMutationVariables>;
export const GetLocationsDocument = gql`
    query GetLocations {
  locations: location_locations(input: {}) {
    ...Location_List
  }
}
    ${Location_ListFragmentDoc}`;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a Vue component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetLocationsQuery();
 */
export function useGetLocationsQuery(options: VueApolloComposable.UseQueryOptions<GetLocationsQuery, GetLocationsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetLocationsQuery, GetLocationsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetLocationsQuery, GetLocationsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, {}, options);
}
export type GetLocationsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetLocationsQuery, GetLocationsQueryVariables>;
export const GetLocations_GridDocument = gql`
    query GetLocations_Grid($input: ManyQueryInput!) {
  location_locations(input: $input) {
    ...Location_Grid
  }
}
    ${Location_GridFragmentDoc}`;

/**
 * __useGetLocations_GridQuery__
 *
 * To run a query within a Vue component, call `useGetLocations_GridQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocations_GridQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetLocations_GridQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetLocations_GridQuery(variables: GetLocations_GridQueryVariables | VueCompositionApi.Ref<GetLocations_GridQueryVariables> | ReactiveFunction<GetLocations_GridQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetLocations_GridQuery, GetLocations_GridQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetLocations_GridQuery, GetLocations_GridQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetLocations_GridQuery, GetLocations_GridQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetLocations_GridQuery, GetLocations_GridQueryVariables>(GetLocations_GridDocument, variables, options);
}
export type GetLocations_GridQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetLocations_GridQuery, GetLocations_GridQueryVariables>;
export const GetSitesDocument = gql`
    query GetSites {
  sites: location_sites(input: {limit: 1000}) {
    ...Site_List
  }
}
    ${Site_ListFragmentDoc}`;

/**
 * __useGetSitesQuery__
 *
 * To run a query within a Vue component, call `useGetSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSitesQuery();
 */
export function useGetSitesQuery(options: VueApolloComposable.UseQueryOptions<GetSitesQuery, GetSitesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetSitesQuery, GetSitesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetSitesQuery, GetSitesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetSitesQuery, GetSitesQueryVariables>(GetSitesDocument, {}, options);
}
export type GetSitesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetSitesQuery, GetSitesQueryVariables>;
export const GetSites_GridDocument = gql`
    query GetSites_Grid {
  sites: location_sites(input: {limit: 1000}) {
    ...Site_Grid
  }
}
    ${Site_GridFragmentDoc}`;

/**
 * __useGetSites_GridQuery__
 *
 * To run a query within a Vue component, call `useGetSites_GridQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSites_GridQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSites_GridQuery();
 */
export function useGetSites_GridQuery(options: VueApolloComposable.UseQueryOptions<GetSites_GridQuery, GetSites_GridQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetSites_GridQuery, GetSites_GridQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetSites_GridQuery, GetSites_GridQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetSites_GridQuery, GetSites_GridQueryVariables>(GetSites_GridDocument, {}, options);
}
export type GetSites_GridQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetSites_GridQuery, GetSites_GridQueryVariables>;
export const CreateLocationDocument = gql`
    mutation CreateLocation($input: Create__Location_Location__Input!) {
  Create__Location_Location(input: $input) {
    ...Location_Grid
  }
}
    ${Location_GridFragmentDoc}`;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateLocationMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationMutation(options: VueApolloComposable.UseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, options);
}
export type CreateLocationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateLocationMutation, CreateLocationMutationVariables>;
export const UpdateLocationDocument = gql`
    mutation UpdateLocation($input: Update__Location_Location__Input!) {
  Update__Location_Location(input: $input) {
    ...Location_Grid
  }
}
    ${Location_GridFragmentDoc}`;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateLocationMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationMutation(options: VueApolloComposable.UseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, options);
}
export type UpdateLocationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const DeleteLocationDocument = gql`
    mutation DeleteLocation($id: ID!) {
  Delete__Location_Location(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteLocationMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLocationMutation(options: VueApolloComposable.UseMutationOptions<DeleteLocationMutation, DeleteLocationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteLocationMutation, DeleteLocationMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(DeleteLocationDocument, options);
}
export type DeleteLocationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteLocationMutation, DeleteLocationMutationVariables>;
export const CreateSiteDocument = gql`
    mutation CreateSite($input: Create__Location_Site__Input!) {
  Create__Location_Site(input: $input) {
    ...Site_Grid
  }
}
    ${Site_GridFragmentDoc}`;

/**
 * __useCreateSiteMutation__
 *
 * To run a mutation, you first call `useCreateSiteMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateSiteMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteMutation(options: VueApolloComposable.UseMutationOptions<CreateSiteMutation, CreateSiteMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateSiteMutation, CreateSiteMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateSiteMutation, CreateSiteMutationVariables>(CreateSiteDocument, options);
}
export type CreateSiteMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateSiteMutation, CreateSiteMutationVariables>;
export const UpdateSiteDocument = gql`
    mutation UpdateSite($input: Update__Location_Site__Input!) {
  Update__Location_Site(input: $input) {
    ...Site_Grid
  }
}
    ${Site_GridFragmentDoc}`;

/**
 * __useUpdateSiteMutation__
 *
 * To run a mutation, you first call `useUpdateSiteMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateSiteMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSiteMutation(options: VueApolloComposable.UseMutationOptions<UpdateSiteMutation, UpdateSiteMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateSiteMutation, UpdateSiteMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateSiteMutation, UpdateSiteMutationVariables>(UpdateSiteDocument, options);
}
export type UpdateSiteMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateSiteMutation, UpdateSiteMutationVariables>;
export const DeleteSiteDocument = gql`
    mutation DeleteSite($id: ID!) {
  Delete__Location_Site(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteSiteMutation__
 *
 * To run a mutation, you first call `useDeleteSiteMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSiteMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteSiteMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSiteMutation(options: VueApolloComposable.UseMutationOptions<DeleteSiteMutation, DeleteSiteMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteSiteMutation, DeleteSiteMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteSiteMutation, DeleteSiteMutationVariables>(DeleteSiteDocument, options);
}
export type DeleteSiteMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteSiteMutation, DeleteSiteMutationVariables>;
export const OrderInfoDocument = gql`
    query OrderInfo {
  services: shipping_service_levels(input: {limit: 10000}) {
    id
    easypost_name
    sub2title: order_arrive_by
    order_ready_by
    title: service
    shipper {
      id
      name
      easypost_name
    }
  }
  terms: clients_terms(input: {limit: 10000, order_by: "name"}) {
    id: id
    subtitle: description
    title: name
  }
  users: users_users(
    input: {limit: 10000, order_by: "first_name", excludes: [{key: "first_name", value: ""}]}
  ) {
    id: id
    subtitle: full_name
    title: initials
  }
}
    `;

/**
 * __useOrderInfoQuery__
 *
 * To run a query within a Vue component, call `useOrderInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderInfoQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrderInfoQuery();
 */
export function useOrderInfoQuery(options: VueApolloComposable.UseQueryOptions<OrderInfoQuery, OrderInfoQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<OrderInfoQuery, OrderInfoQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<OrderInfoQuery, OrderInfoQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<OrderInfoQuery, OrderInfoQueryVariables>(OrderInfoDocument, {}, options);
}
export type OrderInfoQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<OrderInfoQuery, OrderInfoQueryVariables>;
export const GetSaleForEditDocument = gql`
    query GetSaleForEdit($id: ID!) {
  sales_sale(input: {id: $id}) {
    ...SaleForEdit
  }
}
    ${SaleForEditFragmentDoc}`;

/**
 * __useGetSaleForEditQuery__
 *
 * To run a query within a Vue component, call `useGetSaleForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSaleForEditQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSaleForEditQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetSaleForEditQuery(variables: GetSaleForEditQueryVariables | VueCompositionApi.Ref<GetSaleForEditQueryVariables> | ReactiveFunction<GetSaleForEditQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetSaleForEditQuery, GetSaleForEditQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetSaleForEditQuery, GetSaleForEditQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetSaleForEditQuery, GetSaleForEditQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetSaleForEditQuery, GetSaleForEditQueryVariables>(GetSaleForEditDocument, variables, options);
}
export type GetSaleForEditQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetSaleForEditQuery, GetSaleForEditQueryVariables>;
export const GetSalesForDeleteDocument = gql`
    query GetSalesForDelete($id: ID!) {
  sales_sale(input: {id: $id}) {
    ...SaleForDelete
  }
}
    ${SaleForDeleteFragmentDoc}`;

/**
 * __useGetSalesForDeleteQuery__
 *
 * To run a query within a Vue component, call `useGetSalesForDeleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesForDeleteQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSalesForDeleteQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetSalesForDeleteQuery(variables: GetSalesForDeleteQueryVariables | VueCompositionApi.Ref<GetSalesForDeleteQueryVariables> | ReactiveFunction<GetSalesForDeleteQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetSalesForDeleteQuery, GetSalesForDeleteQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetSalesForDeleteQuery, GetSalesForDeleteQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetSalesForDeleteQuery, GetSalesForDeleteQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetSalesForDeleteQuery, GetSalesForDeleteQueryVariables>(GetSalesForDeleteDocument, variables, options);
}
export type GetSalesForDeleteQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetSalesForDeleteQuery, GetSalesForDeleteQueryVariables>;
export const CustomsItemsQueryDocument = gql`
    query CustomsItemsQuery($id: Any!) {
  items: sales_sold_items(
    input: {filters: [{key: "transaction__id", value: $id}]}
  ) {
    id
    part {
      id
      pn
      description
      htsus_code
      hs_code
      type
    }
    sold_for {
      ...Money
    }
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __useCustomsItemsQueryQuery__
 *
 * To run a query within a Vue component, call `useCustomsItemsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomsItemsQueryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCustomsItemsQueryQuery({
 *   id: // value for 'id'
 * });
 */
export function useCustomsItemsQueryQuery(variables: CustomsItemsQueryQueryVariables | VueCompositionApi.Ref<CustomsItemsQueryQueryVariables> | ReactiveFunction<CustomsItemsQueryQueryVariables>, options: VueApolloComposable.UseQueryOptions<CustomsItemsQueryQuery, CustomsItemsQueryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CustomsItemsQueryQuery, CustomsItemsQueryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CustomsItemsQueryQuery, CustomsItemsQueryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CustomsItemsQueryQuery, CustomsItemsQueryQueryVariables>(CustomsItemsQueryDocument, variables, options);
}
export type CustomsItemsQueryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CustomsItemsQueryQuery, CustomsItemsQueryQueryVariables>;
export const SalesToProcessDocument = gql`
    query SalesToProcess($input: ManyQueryInput!) {
  orders: shipping_shipment_orders(input: $input) {
    annotations {
      name
      value
    }
    id
    attached_sale {
      id
      assigned_to {
        id
        initials
        profile {
          id
          color
        }
      }
      rep {
        id
        initials
        full_name
        email
      }
      status {
        id
        status
      }
    }
    attached_purchase_rma {
      id
      status {
        id
        status
      }
      pt {
        id
        rep {
          id
          initials
        }
      }
    }
    attached_rma {
      id
      status {
        id
        status
      }
      st {
        id
        rep {
          id
          initials
        }
      }
    }
    service {
      id
      color
      service
      shipper {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useSalesToProcessQuery__
 *
 * To run a query within a Vue component, call `useSalesToProcessQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesToProcessQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSalesToProcessQuery({
 *   input: // value for 'input'
 * });
 */
export function useSalesToProcessQuery(variables: SalesToProcessQueryVariables | VueCompositionApi.Ref<SalesToProcessQueryVariables> | ReactiveFunction<SalesToProcessQueryVariables>, options: VueApolloComposable.UseQueryOptions<SalesToProcessQuery, SalesToProcessQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SalesToProcessQuery, SalesToProcessQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SalesToProcessQuery, SalesToProcessQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<SalesToProcessQuery, SalesToProcessQueryVariables>(SalesToProcessDocument, variables, options);
}
export type SalesToProcessQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SalesToProcessQuery, SalesToProcessQueryVariables>;
export const Grid__GetSalesDocument = gql`
    query GRID__GetSales($input: ManyQueryInput!) {
  sales: sales_sales(input: $input) {
    contract_comment
    internal_comment
  }
}
    `;

/**
 * __useGrid__GetSalesQuery__
 *
 * To run a query within a Vue component, call `useGrid__GetSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrid__GetSalesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGrid__GetSalesQuery({
 *   input: // value for 'input'
 * });
 */
export function useGrid__GetSalesQuery(variables: Grid__GetSalesQueryVariables | VueCompositionApi.Ref<Grid__GetSalesQueryVariables> | ReactiveFunction<Grid__GetSalesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Grid__GetSalesQuery, Grid__GetSalesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Grid__GetSalesQuery, Grid__GetSalesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Grid__GetSalesQuery, Grid__GetSalesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Grid__GetSalesQuery, Grid__GetSalesQueryVariables>(Grid__GetSalesDocument, variables, options);
}
export type Grid__GetSalesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Grid__GetSalesQuery, Grid__GetSalesQueryVariables>;
export const GetSalesDocument = gql`
    query GetSales($input: ManyQueryInput!) {
  sales: sales_sales(input: $input) {
    ...Sale
    annotations {
      name
      value
    }
  }
}
    ${SaleFragmentDoc}`;

/**
 * __useGetSalesQuery__
 *
 * To run a query within a Vue component, call `useGetSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSalesQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetSalesQuery(variables: GetSalesQueryVariables | VueCompositionApi.Ref<GetSalesQueryVariables> | ReactiveFunction<GetSalesQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetSalesQuery, GetSalesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetSalesQuery, GetSalesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetSalesQuery, GetSalesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetSalesQuery, GetSalesQueryVariables>(GetSalesDocument, variables, options);
}
export type GetSalesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetSalesQuery, GetSalesQueryVariables>;
export const SoldItemsDocument = gql`
    query SoldItems($id: Any!) {
  items: sales_sold_items(
    input: {limit: 10000, filters: [{key: "transaction__id", value: $id}]}
  ) {
    ...SoldItem
  }
}
    ${SoldItemFragmentDoc}`;

/**
 * __useSoldItemsQuery__
 *
 * To run a query within a Vue component, call `useSoldItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoldItemsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSoldItemsQuery({
 *   id: // value for 'id'
 * });
 */
export function useSoldItemsQuery(variables: SoldItemsQueryVariables | VueCompositionApi.Ref<SoldItemsQueryVariables> | ReactiveFunction<SoldItemsQueryVariables>, options: VueApolloComposable.UseQueryOptions<SoldItemsQuery, SoldItemsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SoldItemsQuery, SoldItemsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SoldItemsQuery, SoldItemsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<SoldItemsQuery, SoldItemsQueryVariables>(SoldItemsDocument, variables, options);
}
export type SoldItemsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SoldItemsQuery, SoldItemsQueryVariables>;
export const SalesDetailsForRmaDocument = gql`
    query SalesDetailsForRMA($id: ID) {
  order: sales_sale(input: {id: $id}) {
    id
    client {
      id
      name
    }
  }
}
    `;

/**
 * __useSalesDetailsForRmaQuery__
 *
 * To run a query within a Vue component, call `useSalesDetailsForRmaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesDetailsForRmaQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSalesDetailsForRmaQuery({
 *   id: // value for 'id'
 * });
 */
export function useSalesDetailsForRmaQuery(variables: SalesDetailsForRmaQueryVariables | VueCompositionApi.Ref<SalesDetailsForRmaQueryVariables> | ReactiveFunction<SalesDetailsForRmaQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<SalesDetailsForRmaQuery, SalesDetailsForRmaQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SalesDetailsForRmaQuery, SalesDetailsForRmaQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SalesDetailsForRmaQuery, SalesDetailsForRmaQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<SalesDetailsForRmaQuery, SalesDetailsForRmaQueryVariables>(SalesDetailsForRmaDocument, variables, options);
}
export type SalesDetailsForRmaQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SalesDetailsForRmaQuery, SalesDetailsForRmaQueryVariables>;
export const SalesDetailsForCopyDocument = gql`
    query SalesDetailsForCopy($id: ID) {
  sale: sales_sale(input: {id: $id}) {
    id
    client {
      ...Client_List
    }
    contact {
      ...Contact_List
    }
    internal_comment
    contract_comment
    st_items {
      part {
        id
        description
        pn
      }
      sold_for {
        ...Money
      }
    }
    rep {
      ...ListUser
    }
    terms {
      id
    }
  }
}
    ${Client_ListFragmentDoc}
${Contact_ListFragmentDoc}
${MoneyFragmentDoc}
${ListUserFragmentDoc}`;

/**
 * __useSalesDetailsForCopyQuery__
 *
 * To run a query within a Vue component, call `useSalesDetailsForCopyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesDetailsForCopyQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSalesDetailsForCopyQuery({
 *   id: // value for 'id'
 * });
 */
export function useSalesDetailsForCopyQuery(variables: SalesDetailsForCopyQueryVariables | VueCompositionApi.Ref<SalesDetailsForCopyQueryVariables> | ReactiveFunction<SalesDetailsForCopyQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<SalesDetailsForCopyQuery, SalesDetailsForCopyQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SalesDetailsForCopyQuery, SalesDetailsForCopyQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SalesDetailsForCopyQuery, SalesDetailsForCopyQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<SalesDetailsForCopyQuery, SalesDetailsForCopyQueryVariables>(SalesDetailsForCopyDocument, variables, options);
}
export type SalesDetailsForCopyQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SalesDetailsForCopyQuery, SalesDetailsForCopyQueryVariables>;
export const GetSaleForPdfDocument = gql`
    query GetSaleForPDF($id: ID!) {
  sale: sales_sale(input: {id: $id}) {
    ...SaleForPDF
  }
}
    ${SaleForPdfFragmentDoc}`;

/**
 * __useGetSaleForPdfQuery__
 *
 * To run a query within a Vue component, call `useGetSaleForPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSaleForPdfQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSaleForPdfQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetSaleForPdfQuery(variables: GetSaleForPdfQueryVariables | VueCompositionApi.Ref<GetSaleForPdfQueryVariables> | ReactiveFunction<GetSaleForPdfQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetSaleForPdfQuery, GetSaleForPdfQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetSaleForPdfQuery, GetSaleForPdfQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetSaleForPdfQuery, GetSaleForPdfQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetSaleForPdfQuery, GetSaleForPdfQueryVariables>(GetSaleForPdfDocument, variables, options);
}
export type GetSaleForPdfQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetSaleForPdfQuery, GetSaleForPdfQueryVariables>;
export const GetPurchaseForPdfDocument = gql`
    query GetPurchaseForPdf($id: ID!) {
  purchases_purchase(input: {id: $id}) {
    ...PurchaseForPdf
  }
}
    ${PurchaseForPdfFragmentDoc}`;

/**
 * __useGetPurchaseForPdfQuery__
 *
 * To run a query within a Vue component, call `useGetPurchaseForPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseForPdfQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetPurchaseForPdfQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetPurchaseForPdfQuery(variables: GetPurchaseForPdfQueryVariables | VueCompositionApi.Ref<GetPurchaseForPdfQueryVariables> | ReactiveFunction<GetPurchaseForPdfQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetPurchaseForPdfQuery, GetPurchaseForPdfQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetPurchaseForPdfQuery, GetPurchaseForPdfQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetPurchaseForPdfQuery, GetPurchaseForPdfQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetPurchaseForPdfQuery, GetPurchaseForPdfQueryVariables>(GetPurchaseForPdfDocument, variables, options);
}
export type GetPurchaseForPdfQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetPurchaseForPdfQuery, GetPurchaseForPdfQueryVariables>;
export const SalesDetailsForMoneyUpdateDocument = gql`
    query SalesDetailsForMoneyUpdate($id: ID!) {
  sales_sale(input: {id: $id}) {
    ...SaleForMoneyUpdate
  }
}
    ${SaleForMoneyUpdateFragmentDoc}`;

/**
 * __useSalesDetailsForMoneyUpdateQuery__
 *
 * To run a query within a Vue component, call `useSalesDetailsForMoneyUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesDetailsForMoneyUpdateQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSalesDetailsForMoneyUpdateQuery({
 *   id: // value for 'id'
 * });
 */
export function useSalesDetailsForMoneyUpdateQuery(variables: SalesDetailsForMoneyUpdateQueryVariables | VueCompositionApi.Ref<SalesDetailsForMoneyUpdateQueryVariables> | ReactiveFunction<SalesDetailsForMoneyUpdateQueryVariables>, options: VueApolloComposable.UseQueryOptions<SalesDetailsForMoneyUpdateQuery, SalesDetailsForMoneyUpdateQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SalesDetailsForMoneyUpdateQuery, SalesDetailsForMoneyUpdateQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SalesDetailsForMoneyUpdateQuery, SalesDetailsForMoneyUpdateQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<SalesDetailsForMoneyUpdateQuery, SalesDetailsForMoneyUpdateQueryVariables>(SalesDetailsForMoneyUpdateDocument, variables, options);
}
export type SalesDetailsForMoneyUpdateQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SalesDetailsForMoneyUpdateQuery, SalesDetailsForMoneyUpdateQueryVariables>;
export const GetSaleNextLineNumberDocument = gql`
    query GetSaleNextLineNumber($id: ID!) {
  sale: sales_sale(
    input: {id: $id, annotations: [{name: "lineNumber", aggr_type: MAX, aggr_field: "st_items__line_number"}]}
  ) {
    id
    annotations {
      name
      value
    }
  }
}
    `;

/**
 * __useGetSaleNextLineNumberQuery__
 *
 * To run a query within a Vue component, call `useGetSaleNextLineNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSaleNextLineNumberQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSaleNextLineNumberQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetSaleNextLineNumberQuery(variables: GetSaleNextLineNumberQueryVariables | VueCompositionApi.Ref<GetSaleNextLineNumberQueryVariables> | ReactiveFunction<GetSaleNextLineNumberQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetSaleNextLineNumberQuery, GetSaleNextLineNumberQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetSaleNextLineNumberQuery, GetSaleNextLineNumberQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetSaleNextLineNumberQuery, GetSaleNextLineNumberQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetSaleNextLineNumberQuery, GetSaleNextLineNumberQueryVariables>(GetSaleNextLineNumberDocument, variables, options);
}
export type GetSaleNextLineNumberQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetSaleNextLineNumberQuery, GetSaleNextLineNumberQueryVariables>;
export const GetSaleDocument = gql`
    query GetSale($id: ID!, $annotations: [AnnotationObjectInput]) {
  sale: sales_sale(input: {id: $id, annotations: $annotations}) {
    ...Sale
    annotations {
      name
      value
    }
  }
}
    ${SaleFragmentDoc}`;

/**
 * __useGetSaleQuery__
 *
 * To run a query within a Vue component, call `useGetSaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSaleQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSaleQuery({
 *   id: // value for 'id'
 *   annotations: // value for 'annotations'
 * });
 */
export function useGetSaleQuery(variables: GetSaleQueryVariables | VueCompositionApi.Ref<GetSaleQueryVariables> | ReactiveFunction<GetSaleQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetSaleQuery, GetSaleQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetSaleQuery, GetSaleQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetSaleQuery, GetSaleQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetSaleQuery, GetSaleQueryVariables>(GetSaleDocument, variables, options);
}
export type GetSaleQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetSaleQuery, GetSaleQueryVariables>;
export const GetSaleDetailsToMakeShipmentOrderDocument = gql`
    query GetSaleDetailsToMakeShipmentOrder($id: ID!) {
  order: sales_sale(input: {id: $id}) {
    id
    client {
      id
    }
  }
}
    `;

/**
 * __useGetSaleDetailsToMakeShipmentOrderQuery__
 *
 * To run a query within a Vue component, call `useGetSaleDetailsToMakeShipmentOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSaleDetailsToMakeShipmentOrderQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSaleDetailsToMakeShipmentOrderQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetSaleDetailsToMakeShipmentOrderQuery(variables: GetSaleDetailsToMakeShipmentOrderQueryVariables | VueCompositionApi.Ref<GetSaleDetailsToMakeShipmentOrderQueryVariables> | ReactiveFunction<GetSaleDetailsToMakeShipmentOrderQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetSaleDetailsToMakeShipmentOrderQuery, GetSaleDetailsToMakeShipmentOrderQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetSaleDetailsToMakeShipmentOrderQuery, GetSaleDetailsToMakeShipmentOrderQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetSaleDetailsToMakeShipmentOrderQuery, GetSaleDetailsToMakeShipmentOrderQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetSaleDetailsToMakeShipmentOrderQuery, GetSaleDetailsToMakeShipmentOrderQueryVariables>(GetSaleDetailsToMakeShipmentOrderDocument, variables, options);
}
export type GetSaleDetailsToMakeShipmentOrderQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetSaleDetailsToMakeShipmentOrderQuery, GetSaleDetailsToMakeShipmentOrderQueryVariables>;
export const GetClientSalesDocument = gql`
    query GetClientSALES($input: ManyQueryInput!) {
  sales: sales_sales(input: $input) {
    id
    estimated_cost {
      ...Money
    }
    estimated_margin {
      ...Money
    }
    sale_date
    rep {
      id
      initials
    }
    estimated_total {
      ...Money
    }
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __useGetClientSalesQuery__
 *
 * To run a query within a Vue component, call `useGetClientSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientSalesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetClientSalesQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetClientSalesQuery(variables: GetClientSalesQueryVariables | VueCompositionApi.Ref<GetClientSalesQueryVariables> | ReactiveFunction<GetClientSalesQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetClientSalesQuery, GetClientSalesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetClientSalesQuery, GetClientSalesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetClientSalesQuery, GetClientSalesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetClientSalesQuery, GetClientSalesQueryVariables>(GetClientSalesDocument, variables, options);
}
export type GetClientSalesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetClientSalesQuery, GetClientSalesQueryVariables>;
export const GetItemCountForSaleDocument = gql`
    query GetItemCountForSale($id: ID!) {
  count: sales_sale(
    input: {id: $id, annotations: [{aggr_type: COUNT, aggr_field: "st_items", name: "itemCount"}]}
  ) {
    id
    annotations {
      name
      value
    }
  }
}
    `;

/**
 * __useGetItemCountForSaleQuery__
 *
 * To run a query within a Vue component, call `useGetItemCountForSaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemCountForSaleQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetItemCountForSaleQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetItemCountForSaleQuery(variables: GetItemCountForSaleQueryVariables | VueCompositionApi.Ref<GetItemCountForSaleQueryVariables> | ReactiveFunction<GetItemCountForSaleQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetItemCountForSaleQuery, GetItemCountForSaleQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetItemCountForSaleQuery, GetItemCountForSaleQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetItemCountForSaleQuery, GetItemCountForSaleQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetItemCountForSaleQuery, GetItemCountForSaleQueryVariables>(GetItemCountForSaleDocument, variables, options);
}
export type GetItemCountForSaleQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetItemCountForSaleQuery, GetItemCountForSaleQueryVariables>;
export const GetPurchaseForEditDocument = gql`
    query GetPurchaseForEdit($id: ID!) {
  purchases_purchase(input: {id: $id}) {
    ...PurchaseForEdit
  }
}
    ${PurchaseForEditFragmentDoc}`;

/**
 * __useGetPurchaseForEditQuery__
 *
 * To run a query within a Vue component, call `useGetPurchaseForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseForEditQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetPurchaseForEditQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetPurchaseForEditQuery(variables: GetPurchaseForEditQueryVariables | VueCompositionApi.Ref<GetPurchaseForEditQueryVariables> | ReactiveFunction<GetPurchaseForEditQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetPurchaseForEditQuery, GetPurchaseForEditQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetPurchaseForEditQuery, GetPurchaseForEditQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetPurchaseForEditQuery, GetPurchaseForEditQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetPurchaseForEditQuery, GetPurchaseForEditQueryVariables>(GetPurchaseForEditDocument, variables, options);
}
export type GetPurchaseForEditQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetPurchaseForEditQuery, GetPurchaseForEditQueryVariables>;
export const Grid__GetPurchasesDocument = gql`
    query GRID__GetPurchases($input: ManyQueryInput!) {
  purchases: purchases_purchases(input: $input) {
    internal_comment
    contract_comment
  }
}
    `;

/**
 * __useGrid__GetPurchasesQuery__
 *
 * To run a query within a Vue component, call `useGrid__GetPurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrid__GetPurchasesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGrid__GetPurchasesQuery({
 *   input: // value for 'input'
 * });
 */
export function useGrid__GetPurchasesQuery(variables: Grid__GetPurchasesQueryVariables | VueCompositionApi.Ref<Grid__GetPurchasesQueryVariables> | ReactiveFunction<Grid__GetPurchasesQueryVariables>, options: VueApolloComposable.UseQueryOptions<Grid__GetPurchasesQuery, Grid__GetPurchasesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Grid__GetPurchasesQuery, Grid__GetPurchasesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Grid__GetPurchasesQuery, Grid__GetPurchasesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Grid__GetPurchasesQuery, Grid__GetPurchasesQueryVariables>(Grid__GetPurchasesDocument, variables, options);
}
export type Grid__GetPurchasesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Grid__GetPurchasesQuery, Grid__GetPurchasesQueryVariables>;
export const GetPurchasesDocument = gql`
    query GetPurchases($input: ManyQueryInput!) {
  purchases: purchases_purchases(input: $input) {
    ...Purchase
    annotations {
      name
      value
    }
  }
}
    ${PurchaseFragmentDoc}`;

/**
 * __useGetPurchasesQuery__
 *
 * To run a query within a Vue component, call `useGetPurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchasesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetPurchasesQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetPurchasesQuery(variables: GetPurchasesQueryVariables | VueCompositionApi.Ref<GetPurchasesQueryVariables> | ReactiveFunction<GetPurchasesQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetPurchasesQuery, GetPurchasesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetPurchasesQuery, GetPurchasesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetPurchasesQuery, GetPurchasesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetPurchasesQuery, GetPurchasesQueryVariables>(GetPurchasesDocument, variables, options);
}
export type GetPurchasesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetPurchasesQuery, GetPurchasesQueryVariables>;
export const GetPurchaseDocument = gql`
    query GetPurchase($id: ID!) {
  purchases_purchase(input: {id: $id}) {
    ...Purchase
    pt_items {
      id
      line_number
    }
  }
}
    ${PurchaseFragmentDoc}`;

/**
 * __useGetPurchaseQuery__
 *
 * To run a query within a Vue component, call `useGetPurchaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetPurchaseQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetPurchaseQuery(variables: GetPurchaseQueryVariables | VueCompositionApi.Ref<GetPurchaseQueryVariables> | ReactiveFunction<GetPurchaseQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetPurchaseQuery, GetPurchaseQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetPurchaseQuery, GetPurchaseQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetPurchaseQuery, GetPurchaseQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetPurchaseQuery, GetPurchaseQueryVariables>(GetPurchaseDocument, variables, options);
}
export type GetPurchaseQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetPurchaseQuery, GetPurchaseQueryVariables>;
export const GetPurchseDetailsToMakeShipmentOrderDocument = gql`
    query GetPurchseDetailsToMakeShipmentOrder($id: ID!) {
  order: purchases_purchase(input: {id: $id}) {
    id
    client {
      id
    }
  }
}
    `;

/**
 * __useGetPurchseDetailsToMakeShipmentOrderQuery__
 *
 * To run a query within a Vue component, call `useGetPurchseDetailsToMakeShipmentOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchseDetailsToMakeShipmentOrderQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetPurchseDetailsToMakeShipmentOrderQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetPurchseDetailsToMakeShipmentOrderQuery(variables: GetPurchseDetailsToMakeShipmentOrderQueryVariables | VueCompositionApi.Ref<GetPurchseDetailsToMakeShipmentOrderQueryVariables> | ReactiveFunction<GetPurchseDetailsToMakeShipmentOrderQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetPurchseDetailsToMakeShipmentOrderQuery, GetPurchseDetailsToMakeShipmentOrderQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetPurchseDetailsToMakeShipmentOrderQuery, GetPurchseDetailsToMakeShipmentOrderQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetPurchseDetailsToMakeShipmentOrderQuery, GetPurchseDetailsToMakeShipmentOrderQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetPurchseDetailsToMakeShipmentOrderQuery, GetPurchseDetailsToMakeShipmentOrderQueryVariables>(GetPurchseDetailsToMakeShipmentOrderDocument, variables, options);
}
export type GetPurchseDetailsToMakeShipmentOrderQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetPurchseDetailsToMakeShipmentOrderQuery, GetPurchseDetailsToMakeShipmentOrderQueryVariables>;
export const PurchaseItemsDocument = gql`
    query PurchaseItems($id: Any!) {
  items: purchases_purchased_items(
    input: {limit: 10000, filters: [{key: "transaction__id", value: $id}]}
  ) {
    ...PurchasedItem
  }
}
    ${PurchasedItemFragmentDoc}`;

/**
 * __usePurchaseItemsQuery__
 *
 * To run a query within a Vue component, call `usePurchaseItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseItemsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePurchaseItemsQuery({
 *   id: // value for 'id'
 * });
 */
export function usePurchaseItemsQuery(variables: PurchaseItemsQueryVariables | VueCompositionApi.Ref<PurchaseItemsQueryVariables> | ReactiveFunction<PurchaseItemsQueryVariables>, options: VueApolloComposable.UseQueryOptions<PurchaseItemsQuery, PurchaseItemsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PurchaseItemsQuery, PurchaseItemsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PurchaseItemsQuery, PurchaseItemsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PurchaseItemsQuery, PurchaseItemsQueryVariables>(PurchaseItemsDocument, variables, options);
}
export type PurchaseItemsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PurchaseItemsQuery, PurchaseItemsQueryVariables>;
export const GetPurchaseItemsStatusDocument = gql`
    query GetPurchaseItemsStatus($id: Any!) {
  items: inventory_items(
    input: {limit: 100000, filters: [{key: "purchases_items_details__transaction__id", value: $id}]}
  ) {
    id
    receive_status
  }
}
    `;

/**
 * __useGetPurchaseItemsStatusQuery__
 *
 * To run a query within a Vue component, call `useGetPurchaseItemsStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseItemsStatusQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetPurchaseItemsStatusQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetPurchaseItemsStatusQuery(variables: GetPurchaseItemsStatusQueryVariables | VueCompositionApi.Ref<GetPurchaseItemsStatusQueryVariables> | ReactiveFunction<GetPurchaseItemsStatusQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetPurchaseItemsStatusQuery, GetPurchaseItemsStatusQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetPurchaseItemsStatusQuery, GetPurchaseItemsStatusQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetPurchaseItemsStatusQuery, GetPurchaseItemsStatusQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetPurchaseItemsStatusQuery, GetPurchaseItemsStatusQueryVariables>(GetPurchaseItemsStatusDocument, variables, options);
}
export type GetPurchaseItemsStatusQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetPurchaseItemsStatusQuery, GetPurchaseItemsStatusQueryVariables>;
export const PurchaseItemsToReceiveDocument = gql`
    query PurchaseItemsToReceive($input: ManyQueryInput!) {
  items: purchases_purchased_items(input: $input) {
    id
    line_number
    transaction {
      id
    }
    original_cost {
      ...Money
    }
    item {
      id
      broke_down_items {
        id
        break_down {
          id
        }
      }
      serial_number
      receive_status
      location {
        id
        name
        site {
          id
          name
        }
      }
      saleItem: sales_items_details {
        id
        transaction {
          id
        }
      }
      locked
      hot
      is_lost
      country_of_origin {
        code
        name
        flag: unicode_flag
      }
      test_status {
        id
        tag
        color
      }
      part {
        id
        pn
        description
      }
    }
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __usePurchaseItemsToReceiveQuery__
 *
 * To run a query within a Vue component, call `usePurchaseItemsToReceiveQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseItemsToReceiveQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePurchaseItemsToReceiveQuery({
 *   input: // value for 'input'
 * });
 */
export function usePurchaseItemsToReceiveQuery(variables: PurchaseItemsToReceiveQueryVariables | VueCompositionApi.Ref<PurchaseItemsToReceiveQueryVariables> | ReactiveFunction<PurchaseItemsToReceiveQueryVariables>, options: VueApolloComposable.UseQueryOptions<PurchaseItemsToReceiveQuery, PurchaseItemsToReceiveQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PurchaseItemsToReceiveQuery, PurchaseItemsToReceiveQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PurchaseItemsToReceiveQuery, PurchaseItemsToReceiveQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PurchaseItemsToReceiveQuery, PurchaseItemsToReceiveQueryVariables>(PurchaseItemsToReceiveDocument, variables, options);
}
export type PurchaseItemsToReceiveQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PurchaseItemsToReceiveQuery, PurchaseItemsToReceiveQueryVariables>;
export const PurchaseDetailsForRmaDocument = gql`
    query PurchaseDetailsForRMA($id: ID) {
  order: purchases_purchase(input: {id: $id}) {
    id
    client {
      id
      name
    }
  }
}
    `;

/**
 * __usePurchaseDetailsForRmaQuery__
 *
 * To run a query within a Vue component, call `usePurchaseDetailsForRmaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseDetailsForRmaQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePurchaseDetailsForRmaQuery({
 *   id: // value for 'id'
 * });
 */
export function usePurchaseDetailsForRmaQuery(variables: PurchaseDetailsForRmaQueryVariables | VueCompositionApi.Ref<PurchaseDetailsForRmaQueryVariables> | ReactiveFunction<PurchaseDetailsForRmaQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<PurchaseDetailsForRmaQuery, PurchaseDetailsForRmaQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PurchaseDetailsForRmaQuery, PurchaseDetailsForRmaQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PurchaseDetailsForRmaQuery, PurchaseDetailsForRmaQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PurchaseDetailsForRmaQuery, PurchaseDetailsForRmaQueryVariables>(PurchaseDetailsForRmaDocument, variables, options);
}
export type PurchaseDetailsForRmaQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PurchaseDetailsForRmaQuery, PurchaseDetailsForRmaQueryVariables>;
export const GetClientPurchasesDocument = gql`
    query GetClientPurchases($input: ManyQueryInput!) {
  purchases: purchases_purchases(input: $input) {
    id
    purchase_date
    rep {
      id
      initials
    }
    estimated_total {
      ...Money
    }
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __useGetClientPurchasesQuery__
 *
 * To run a query within a Vue component, call `useGetClientPurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientPurchasesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetClientPurchasesQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetClientPurchasesQuery(variables: GetClientPurchasesQueryVariables | VueCompositionApi.Ref<GetClientPurchasesQueryVariables> | ReactiveFunction<GetClientPurchasesQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetClientPurchasesQuery, GetClientPurchasesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetClientPurchasesQuery, GetClientPurchasesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetClientPurchasesQuery, GetClientPurchasesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetClientPurchasesQuery, GetClientPurchasesQueryVariables>(GetClientPurchasesDocument, variables, options);
}
export type GetClientPurchasesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetClientPurchasesQuery, GetClientPurchasesQueryVariables>;
export const ValidatePtDocument = gql`
    query ValidatePt($id: ID!) {
  purchase: purchases_purchase(input: {id: $id}) {
    id
  }
}
    `;

/**
 * __useValidatePtQuery__
 *
 * To run a query within a Vue component, call `useValidatePtQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatePtQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useValidatePtQuery({
 *   id: // value for 'id'
 * });
 */
export function useValidatePtQuery(variables: ValidatePtQueryVariables | VueCompositionApi.Ref<ValidatePtQueryVariables> | ReactiveFunction<ValidatePtQueryVariables>, options: VueApolloComposable.UseQueryOptions<ValidatePtQuery, ValidatePtQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ValidatePtQuery, ValidatePtQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ValidatePtQuery, ValidatePtQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ValidatePtQuery, ValidatePtQueryVariables>(ValidatePtDocument, variables, options);
}
export type ValidatePtQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ValidatePtQuery, ValidatePtQueryVariables>;
export const CreatePurchaseDocument = gql`
    mutation CreatePurchase($input: Create__Purchases_Purchase__Input!) {
  order: Create__Purchases_Purchase(input: $input) {
    id
  }
}
    `;

/**
 * __useCreatePurchaseMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreatePurchaseMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreatePurchaseMutation(options: VueApolloComposable.UseMutationOptions<CreatePurchaseMutation, CreatePurchaseMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreatePurchaseMutation, CreatePurchaseMutationVariables>>) {
  return VueApolloComposable.useMutation<CreatePurchaseMutation, CreatePurchaseMutationVariables>(CreatePurchaseDocument, options);
}
export type CreatePurchaseMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreatePurchaseMutation, CreatePurchaseMutationVariables>;
export const UpdatePurchaseDocument = gql`
    mutation UpdatePurchase($input: Update__Purchases_Purchase__Input!) {
  Update__Purchases_Purchase(input: $input) {
    ...Purchase
  }
}
    ${PurchaseFragmentDoc}`;

/**
 * __useUpdatePurchaseMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdatePurchaseMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePurchaseMutation(options: VueApolloComposable.UseMutationOptions<UpdatePurchaseMutation, UpdatePurchaseMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdatePurchaseMutation, UpdatePurchaseMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdatePurchaseMutation, UpdatePurchaseMutationVariables>(UpdatePurchaseDocument, options);
}
export type UpdatePurchaseMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdatePurchaseMutation, UpdatePurchaseMutationVariables>;
export const ApprovePurchaseDocument = gql`
    mutation ApprovePurchase($id: ID!) {
  purchase: ApprovePurchase(id: $id) {
    id
    status {
      id
      status
    }
    approval_date
    approved_by {
      id
      initials
    }
  }
}
    `;

/**
 * __useApprovePurchaseMutation__
 *
 * To run a mutation, you first call `useApprovePurchaseMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useApprovePurchaseMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useApprovePurchaseMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useApprovePurchaseMutation(options: VueApolloComposable.UseMutationOptions<ApprovePurchaseMutation, ApprovePurchaseMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ApprovePurchaseMutation, ApprovePurchaseMutationVariables>>) {
  return VueApolloComposable.useMutation<ApprovePurchaseMutation, ApprovePurchaseMutationVariables>(ApprovePurchaseDocument, options);
}
export type ApprovePurchaseMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ApprovePurchaseMutation, ApprovePurchaseMutationVariables>;
export const UnApprovePurchaseDocument = gql`
    mutation UnApprovePurchase($id: ID!) {
  purchase: UnapprovePurchase(id: $id) {
    id
    status {
      id
      status
    }
    approval_date
    approved_by {
      id
      initials
    }
  }
}
    `;

/**
 * __useUnApprovePurchaseMutation__
 *
 * To run a mutation, you first call `useUnApprovePurchaseMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUnApprovePurchaseMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUnApprovePurchaseMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useUnApprovePurchaseMutation(options: VueApolloComposable.UseMutationOptions<UnApprovePurchaseMutation, UnApprovePurchaseMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UnApprovePurchaseMutation, UnApprovePurchaseMutationVariables>>) {
  return VueApolloComposable.useMutation<UnApprovePurchaseMutation, UnApprovePurchaseMutationVariables>(UnApprovePurchaseDocument, options);
}
export type UnApprovePurchaseMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UnApprovePurchaseMutation, UnApprovePurchaseMutationVariables>;
export const UpdatePurchaseOrderForCommentDocument = gql`
    mutation UpdatePurchaseOrderForComment($input: Update__Purchases_Purchase__Input!) {
  comment: Update__Purchases_Purchase(input: $input) {
    ...Purchase
  }
}
    ${PurchaseFragmentDoc}`;

/**
 * __useUpdatePurchaseOrderForCommentMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseOrderForCommentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseOrderForCommentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdatePurchaseOrderForCommentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePurchaseOrderForCommentMutation(options: VueApolloComposable.UseMutationOptions<UpdatePurchaseOrderForCommentMutation, UpdatePurchaseOrderForCommentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdatePurchaseOrderForCommentMutation, UpdatePurchaseOrderForCommentMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdatePurchaseOrderForCommentMutation, UpdatePurchaseOrderForCommentMutationVariables>(UpdatePurchaseOrderForCommentDocument, options);
}
export type UpdatePurchaseOrderForCommentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdatePurchaseOrderForCommentMutation, UpdatePurchaseOrderForCommentMutationVariables>;
export const UpdateSalesOrderDocument = gql`
    mutation UpdateSalesOrder($input: Update__Sales_Sale__Input!) {
  comment: Update__Sales_Sale(input: $input) {
    ...Sale
  }
}
    ${SaleFragmentDoc}`;

/**
 * __useUpdateSalesOrderMutation__
 *
 * To run a mutation, you first call `useUpdateSalesOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalesOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateSalesOrderMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSalesOrderMutation(options: VueApolloComposable.UseMutationOptions<UpdateSalesOrderMutation, UpdateSalesOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateSalesOrderMutation, UpdateSalesOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateSalesOrderMutation, UpdateSalesOrderMutationVariables>(UpdateSalesOrderDocument, options);
}
export type UpdateSalesOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateSalesOrderMutation, UpdateSalesOrderMutationVariables>;
export const UpdatePurchaseOrderStatusDocument = gql`
    mutation UpdatePurchaseOrderStatus($id: ID!, $status: ID!) {
  purchase: Update__Purchases_Purchase(input: {id: $id, status_id: $status}) {
    id
    status {
      id
      status
      status_type
    }
  }
}
    `;

/**
 * __useUpdatePurchaseOrderStatusMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseOrderStatusMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseOrderStatusMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdatePurchaseOrderStatusMutation({
 *   variables: {
 *     id: // value for 'id'
 *     status: // value for 'status'
 *   },
 * });
 */
export function useUpdatePurchaseOrderStatusMutation(options: VueApolloComposable.UseMutationOptions<UpdatePurchaseOrderStatusMutation, UpdatePurchaseOrderStatusMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdatePurchaseOrderStatusMutation, UpdatePurchaseOrderStatusMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdatePurchaseOrderStatusMutation, UpdatePurchaseOrderStatusMutationVariables>(UpdatePurchaseOrderStatusDocument, options);
}
export type UpdatePurchaseOrderStatusMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdatePurchaseOrderStatusMutation, UpdatePurchaseOrderStatusMutationVariables>;
export const DeletePurchasedItemDocument = gql`
    mutation DeletePurchasedItem($id: ID!) {
  Delete__Purchases_PurchasedItems(id: $id) {
    id
  }
}
    `;

/**
 * __useDeletePurchasedItemMutation__
 *
 * To run a mutation, you first call `useDeletePurchasedItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeletePurchasedItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeletePurchasedItemMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeletePurchasedItemMutation(options: VueApolloComposable.UseMutationOptions<DeletePurchasedItemMutation, DeletePurchasedItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeletePurchasedItemMutation, DeletePurchasedItemMutationVariables>>) {
  return VueApolloComposable.useMutation<DeletePurchasedItemMutation, DeletePurchasedItemMutationVariables>(DeletePurchasedItemDocument, options);
}
export type DeletePurchasedItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeletePurchasedItemMutation, DeletePurchasedItemMutationVariables>;
export const DeleteSoldItemDocument = gql`
    mutation DeleteSoldItem($id: ID!) {
  Delete__Sales_SoldItems(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteSoldItemMutation__
 *
 * To run a mutation, you first call `useDeleteSoldItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSoldItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteSoldItemMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSoldItemMutation(options: VueApolloComposable.UseMutationOptions<DeleteSoldItemMutation, DeleteSoldItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteSoldItemMutation, DeleteSoldItemMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteSoldItemMutation, DeleteSoldItemMutationVariables>(DeleteSoldItemDocument, options);
}
export type DeleteSoldItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteSoldItemMutation, DeleteSoldItemMutationVariables>;
export const CreateSoldItemsDocument = gql`
    mutation CreateSoldItems($items: [Create__Sales_SoldItems__Input!]!) {
  items: Create__Sales_SoldItems(input: $items) {
    ...SoldItem
  }
}
    ${SoldItemFragmentDoc}`;

/**
 * __useCreateSoldItemsMutation__
 *
 * To run a mutation, you first call `useCreateSoldItemsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateSoldItemsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateSoldItemsMutation({
 *   variables: {
 *     items: // value for 'items'
 *   },
 * });
 */
export function useCreateSoldItemsMutation(options: VueApolloComposable.UseMutationOptions<CreateSoldItemsMutation, CreateSoldItemsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateSoldItemsMutation, CreateSoldItemsMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateSoldItemsMutation, CreateSoldItemsMutationVariables>(CreateSoldItemsDocument, options);
}
export type CreateSoldItemsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateSoldItemsMutation, CreateSoldItemsMutationVariables>;
export const CreateSaleDocument = gql`
    mutation CreateSale($input: Create__Sales_Sale__Input!) {
  Create__Sales_Sale(input: $input) {
    ...Sale
  }
}
    ${SaleFragmentDoc}`;

/**
 * __useCreateSaleMutation__
 *
 * To run a mutation, you first call `useCreateSaleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateSaleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateSaleMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateSaleMutation(options: VueApolloComposable.UseMutationOptions<CreateSaleMutation, CreateSaleMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateSaleMutation, CreateSaleMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateSaleMutation, CreateSaleMutationVariables>(CreateSaleDocument, options);
}
export type CreateSaleMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateSaleMutation, CreateSaleMutationVariables>;
export const UpdateSaleDocument = gql`
    mutation UpdateSale($input: Update__Sales_Sale__Input!) {
  order: Update__Sales_Sale(input: $input) {
    ...Sale
  }
}
    ${SaleFragmentDoc}`;

/**
 * __useUpdateSaleMutation__
 *
 * To run a mutation, you first call `useUpdateSaleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSaleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateSaleMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSaleMutation(options: VueApolloComposable.UseMutationOptions<UpdateSaleMutation, UpdateSaleMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateSaleMutation, UpdateSaleMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateSaleMutation, UpdateSaleMutationVariables>(UpdateSaleDocument, options);
}
export type UpdateSaleMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateSaleMutation, UpdateSaleMutationVariables>;
export const UpdateSaleMoneyDocument = gql`
    mutation UpdateSaleMoney($input: Update__Sales_Sale__Input!) {
  order: Update__Sales_Sale(input: $input) {
    id
    estimated_margin {
      ...Money
    }
    estimated_cost {
      ...Money
    }
  }
}
    ${MoneyFragmentDoc}`;

/**
 * __useUpdateSaleMoneyMutation__
 *
 * To run a mutation, you first call `useUpdateSaleMoneyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSaleMoneyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateSaleMoneyMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSaleMoneyMutation(options: VueApolloComposable.UseMutationOptions<UpdateSaleMoneyMutation, UpdateSaleMoneyMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateSaleMoneyMutation, UpdateSaleMoneyMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateSaleMoneyMutation, UpdateSaleMoneyMutationVariables>(UpdateSaleMoneyDocument, options);
}
export type UpdateSaleMoneyMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateSaleMoneyMutation, UpdateSaleMoneyMutationVariables>;
export const DeleteSaleDocument = gql`
    mutation DeleteSale($id: ID!) {
  Delete__Sales_Sale(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteSaleMutation__
 *
 * To run a mutation, you first call `useDeleteSaleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSaleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteSaleMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSaleMutation(options: VueApolloComposable.UseMutationOptions<DeleteSaleMutation, DeleteSaleMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteSaleMutation, DeleteSaleMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteSaleMutation, DeleteSaleMutationVariables>(DeleteSaleDocument, options);
}
export type DeleteSaleMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteSaleMutation, DeleteSaleMutationVariables>;
export const AllocateSoldItemsDocument = gql`
    mutation AllocateSoldItems($input: [Update__Sales_SoldItems__Input!]!) {
  items: Update__Sales_SoldItems(input: $input) {
    ...SoldItem
  }
}
    ${SoldItemFragmentDoc}`;

/**
 * __useAllocateSoldItemsMutation__
 *
 * To run a mutation, you first call `useAllocateSoldItemsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAllocateSoldItemsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAllocateSoldItemsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useAllocateSoldItemsMutation(options: VueApolloComposable.UseMutationOptions<AllocateSoldItemsMutation, AllocateSoldItemsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<AllocateSoldItemsMutation, AllocateSoldItemsMutationVariables>>) {
  return VueApolloComposable.useMutation<AllocateSoldItemsMutation, AllocateSoldItemsMutationVariables>(AllocateSoldItemsDocument, options);
}
export type AllocateSoldItemsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<AllocateSoldItemsMutation, AllocateSoldItemsMutationVariables>;
export const CreatePurchasedItemDocument = gql`
    mutation CreatePurchasedItem($items: [Create__Purchases_PurchasedItems__Input!]!) {
  items: Create__Purchases_PurchasedItems(input: $items) {
    ...PurchasedItem
  }
}
    ${PurchasedItemFragmentDoc}`;

/**
 * __useCreatePurchasedItemMutation__
 *
 * To run a mutation, you first call `useCreatePurchasedItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchasedItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreatePurchasedItemMutation({
 *   variables: {
 *     items: // value for 'items'
 *   },
 * });
 */
export function useCreatePurchasedItemMutation(options: VueApolloComposable.UseMutationOptions<CreatePurchasedItemMutation, CreatePurchasedItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreatePurchasedItemMutation, CreatePurchasedItemMutationVariables>>) {
  return VueApolloComposable.useMutation<CreatePurchasedItemMutation, CreatePurchasedItemMutationVariables>(CreatePurchasedItemDocument, options);
}
export type CreatePurchasedItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreatePurchasedItemMutation, CreatePurchasedItemMutationVariables>;
export const UpdatePurchasedItemsDocument = gql`
    mutation UpdatePurchasedItems($input: [Update__Purchases_PurchasedItems__Input!]!) {
  Update__Purchases_PurchasedItems(input: $input) {
    ...PurchasedItem
  }
}
    ${PurchasedItemFragmentDoc}`;

/**
 * __useUpdatePurchasedItemsMutation__
 *
 * To run a mutation, you first call `useUpdatePurchasedItemsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchasedItemsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdatePurchasedItemsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePurchasedItemsMutation(options: VueApolloComposable.UseMutationOptions<UpdatePurchasedItemsMutation, UpdatePurchasedItemsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdatePurchasedItemsMutation, UpdatePurchasedItemsMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdatePurchasedItemsMutation, UpdatePurchasedItemsMutationVariables>(UpdatePurchasedItemsDocument, options);
}
export type UpdatePurchasedItemsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdatePurchasedItemsMutation, UpdatePurchasedItemsMutationVariables>;
export const CreateAltPartLinkDocument = gql`
    mutation CreateAltPartLink($linker: ID!, $linkee: ID!, $clientId: ID!) {
  Update__Parts_Part(
    input: {id: $linker, alt_part_for_part_id: $linkee, alt_part_for_client_id: $clientId}
  ) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useCreateAltPartLinkMutation__
 *
 * To run a mutation, you first call `useCreateAltPartLinkMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateAltPartLinkMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateAltPartLinkMutation({
 *   variables: {
 *     linker: // value for 'linker'
 *     linkee: // value for 'linkee'
 *     clientId: // value for 'clientId'
 *   },
 * });
 */
export function useCreateAltPartLinkMutation(options: VueApolloComposable.UseMutationOptions<CreateAltPartLinkMutation, CreateAltPartLinkMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateAltPartLinkMutation, CreateAltPartLinkMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateAltPartLinkMutation, CreateAltPartLinkMutationVariables>(CreateAltPartLinkDocument, options);
}
export type CreateAltPartLinkMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateAltPartLinkMutation, CreateAltPartLinkMutationVariables>;
export const UpdatePartDocument = gql`
    mutation UpdatePart($input: Update__Parts_Part__Input!) {
  Update__Parts_Part(input: $input) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useUpdatePartMutation__
 *
 * To run a mutation, you first call `useUpdatePartMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdatePartMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePartMutation(options: VueApolloComposable.UseMutationOptions<UpdatePartMutation, UpdatePartMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdatePartMutation, UpdatePartMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdatePartMutation, UpdatePartMutationVariables>(UpdatePartDocument, options);
}
export type UpdatePartMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdatePartMutation, UpdatePartMutationVariables>;
export const MasterPartSummaryDocument = gql`
    query MasterPartSummary($id: ID!) {
  parts_part(input: {id: $id}) {
    ...FullPart
  }
}
    ${FullPartFragmentDoc}`;

/**
 * __useMasterPartSummaryQuery__
 *
 * To run a query within a Vue component, call `useMasterPartSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterPartSummaryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMasterPartSummaryQuery({
 *   id: // value for 'id'
 * });
 */
export function useMasterPartSummaryQuery(variables: MasterPartSummaryQueryVariables | VueCompositionApi.Ref<MasterPartSummaryQueryVariables> | ReactiveFunction<MasterPartSummaryQueryVariables>, options: VueApolloComposable.UseQueryOptions<MasterPartSummaryQuery, MasterPartSummaryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MasterPartSummaryQuery, MasterPartSummaryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MasterPartSummaryQuery, MasterPartSummaryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MasterPartSummaryQuery, MasterPartSummaryQueryVariables>(MasterPartSummaryDocument, variables, options);
}
export type MasterPartSummaryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MasterPartSummaryQuery, MasterPartSummaryQueryVariables>;
export const GetGqlPermissionsDocument = gql`
    query GetGqlPermissions($input: ManyQueryInput!) {
  custom_permissions_graphql_permissions(input: $input) {
    ...GraphQlPermissions
  }
}
    ${GraphQlPermissionsFragmentDoc}`;

/**
 * __useGetGqlPermissionsQuery__
 *
 * To run a query within a Vue component, call `useGetGqlPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGqlPermissionsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetGqlPermissionsQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetGqlPermissionsQuery(variables: GetGqlPermissionsQueryVariables | VueCompositionApi.Ref<GetGqlPermissionsQueryVariables> | ReactiveFunction<GetGqlPermissionsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetGqlPermissionsQuery, GetGqlPermissionsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetGqlPermissionsQuery, GetGqlPermissionsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetGqlPermissionsQuery, GetGqlPermissionsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetGqlPermissionsQuery, GetGqlPermissionsQueryVariables>(GetGqlPermissionsDocument, variables, options);
}
export type GetGqlPermissionsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetGqlPermissionsQuery, GetGqlPermissionsQueryVariables>;
export const GetRolesForPermissionsDocument = gql`
    query GetRolesForPermissions($input: ManyQueryInput!) {
  custom_permissions_roles(input: $input) {
    ...Role
  }
}
    ${RoleFragmentDoc}`;

/**
 * __useGetRolesForPermissionsQuery__
 *
 * To run a query within a Vue component, call `useGetRolesForPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesForPermissionsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetRolesForPermissionsQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetRolesForPermissionsQuery(variables: GetRolesForPermissionsQueryVariables | VueCompositionApi.Ref<GetRolesForPermissionsQueryVariables> | ReactiveFunction<GetRolesForPermissionsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetRolesForPermissionsQuery, GetRolesForPermissionsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetRolesForPermissionsQuery, GetRolesForPermissionsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetRolesForPermissionsQuery, GetRolesForPermissionsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetRolesForPermissionsQuery, GetRolesForPermissionsQueryVariables>(GetRolesForPermissionsDocument, variables, options);
}
export type GetRolesForPermissionsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetRolesForPermissionsQuery, GetRolesForPermissionsQueryVariables>;
export const GetUsersForPermissionsDocument = gql`
    query GetUsersForPermissions($input: ManyQueryInput!) {
  users_users(input: $input) {
    id
    full_name
    first_name
    last_name
    last_login
    email
    profile {
      id
      commission_percentage
    }
    departments_m2m {
      id
      department {
        id
        description
        name
      }
    }
  }
}
    `;

/**
 * __useGetUsersForPermissionsQuery__
 *
 * To run a query within a Vue component, call `useGetUsersForPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForPermissionsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetUsersForPermissionsQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetUsersForPermissionsQuery(variables: GetUsersForPermissionsQueryVariables | VueCompositionApi.Ref<GetUsersForPermissionsQueryVariables> | ReactiveFunction<GetUsersForPermissionsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetUsersForPermissionsQuery, GetUsersForPermissionsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetUsersForPermissionsQuery, GetUsersForPermissionsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetUsersForPermissionsQuery, GetUsersForPermissionsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetUsersForPermissionsQuery, GetUsersForPermissionsQueryVariables>(GetUsersForPermissionsDocument, variables, options);
}
export type GetUsersForPermissionsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetUsersForPermissionsQuery, GetUsersForPermissionsQueryVariables>;
export const GetUsers_GridDocument = gql`
    query GetUsers_Grid($input: ManyQueryInput!) {
  users_users(input: $input) {
    ...User_Grid
  }
}
    ${User_GridFragmentDoc}`;

/**
 * __useGetUsers_GridQuery__
 *
 * To run a query within a Vue component, call `useGetUsers_GridQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsers_GridQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetUsers_GridQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetUsers_GridQuery(variables: GetUsers_GridQueryVariables | VueCompositionApi.Ref<GetUsers_GridQueryVariables> | ReactiveFunction<GetUsers_GridQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetUsers_GridQuery, GetUsers_GridQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetUsers_GridQuery, GetUsers_GridQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetUsers_GridQuery, GetUsers_GridQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetUsers_GridQuery, GetUsers_GridQueryVariables>(GetUsers_GridDocument, variables, options);
}
export type GetUsers_GridQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetUsers_GridQuery, GetUsers_GridQueryVariables>;
export const GetDepartmentsForPermissionsDocument = gql`
    query GetDepartmentsForPermissions($input: ManyQueryInput!) {
  department_departments(input: $input) {
    ...Department
  }
}
    ${DepartmentFragmentDoc}`;

/**
 * __useGetDepartmentsForPermissionsQuery__
 *
 * To run a query within a Vue component, call `useGetDepartmentsForPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepartmentsForPermissionsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetDepartmentsForPermissionsQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetDepartmentsForPermissionsQuery(variables: GetDepartmentsForPermissionsQueryVariables | VueCompositionApi.Ref<GetDepartmentsForPermissionsQueryVariables> | ReactiveFunction<GetDepartmentsForPermissionsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetDepartmentsForPermissionsQuery, GetDepartmentsForPermissionsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetDepartmentsForPermissionsQuery, GetDepartmentsForPermissionsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetDepartmentsForPermissionsQuery, GetDepartmentsForPermissionsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetDepartmentsForPermissionsQuery, GetDepartmentsForPermissionsQueryVariables>(GetDepartmentsForPermissionsDocument, variables, options);
}
export type GetDepartmentsForPermissionsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetDepartmentsForPermissionsQuery, GetDepartmentsForPermissionsQueryVariables>;
export const GetProductCompaniesDocument = gql`
    query GetProductCompanies {
  parts_product_companys(input: {limit: 1000}) {
    id
    name
    short_name
  }
}
    `;

/**
 * __useGetProductCompaniesQuery__
 *
 * To run a query within a Vue component, call `useGetProductCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductCompaniesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetProductCompaniesQuery();
 */
export function useGetProductCompaniesQuery(options: VueApolloComposable.UseQueryOptions<GetProductCompaniesQuery, GetProductCompaniesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetProductCompaniesQuery, GetProductCompaniesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetProductCompaniesQuery, GetProductCompaniesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetProductCompaniesQuery, GetProductCompaniesQueryVariables>(GetProductCompaniesDocument, {}, options);
}
export type GetProductCompaniesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetProductCompaniesQuery, GetProductCompaniesQueryVariables>;
export const GetLiveCommentsDocument = gql`
    query GetLiveComments($id: Any!) {
  templates: profile_live_templates(
    input: {filters: [{key: "creator__id", value: $id}]}
  ) {
    id
    name
    comment: template
    creator {
      id
      initials
    }
  }
}
    `;

/**
 * __useGetLiveCommentsQuery__
 *
 * To run a query within a Vue component, call `useGetLiveCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveCommentsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetLiveCommentsQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetLiveCommentsQuery(variables: GetLiveCommentsQueryVariables | VueCompositionApi.Ref<GetLiveCommentsQueryVariables> | ReactiveFunction<GetLiveCommentsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetLiveCommentsQuery, GetLiveCommentsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetLiveCommentsQuery, GetLiveCommentsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetLiveCommentsQuery, GetLiveCommentsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetLiveCommentsQuery, GetLiveCommentsQueryVariables>(GetLiveCommentsDocument, variables, options);
}
export type GetLiveCommentsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetLiveCommentsQuery, GetLiveCommentsQueryVariables>;
export const LoginDataDocument = gql`
    query LoginData {
  me {
    id
    date_joined
    email
    first_name
    full_name
    initials
    is_active
    is_staff
    is_superuser
    last_login
    last_name
    profile {
      ...Profile_Profile__Full
    }
    username
  }
  company_company {
    name
    short_name
  }
}
    ${Profile_Profile__FullFragmentDoc}`;

/**
 * __useLoginDataQuery__
 *
 * To run a query within a Vue component, call `useLoginDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginDataQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useLoginDataQuery();
 */
export function useLoginDataQuery(options: VueApolloComposable.UseQueryOptions<LoginDataQuery, LoginDataQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LoginDataQuery, LoginDataQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<LoginDataQuery, LoginDataQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<LoginDataQuery, LoginDataQueryVariables>(LoginDataDocument, {}, options);
}
export type LoginDataQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<LoginDataQuery, LoginDataQueryVariables>;
export const OtherLoginDataDocument = gql`
    query otherLoginData {
  company_company {
    logo
    default_return_to_address {
      id
    }
  }
  statuses: choices_statuses(input: {limit: null}) {
    id
    app: app_name
    field: field_name
    model: model_name
    order
    status
    status_type
  }
  tags: choices_tags(input: {limit: null}) {
    id
    tag
    description
    color
  }
}
    `;

/**
 * __useOtherLoginDataQuery__
 *
 * To run a query within a Vue component, call `useOtherLoginDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useOtherLoginDataQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOtherLoginDataQuery();
 */
export function useOtherLoginDataQuery(options: VueApolloComposable.UseQueryOptions<OtherLoginDataQuery, OtherLoginDataQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<OtherLoginDataQuery, OtherLoginDataQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<OtherLoginDataQuery, OtherLoginDataQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<OtherLoginDataQuery, OtherLoginDataQueryVariables>(OtherLoginDataDocument, {}, options);
}
export type OtherLoginDataQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<OtherLoginDataQuery, OtherLoginDataQueryVariables>;
export const ChangeProfileColorDocument = gql`
    mutation ChangeProfileColor($color: String, $id: ID!) {
  profile: Update__Profile_Profile(input: {id: $id, color: $color}) {
    id
    color
  }
}
    `;

/**
 * __useChangeProfileColorMutation__
 *
 * To run a mutation, you first call `useChangeProfileColorMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useChangeProfileColorMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useChangeProfileColorMutation({
 *   variables: {
 *     color: // value for 'color'
 *     id: // value for 'id'
 *   },
 * });
 */
export function useChangeProfileColorMutation(options: VueApolloComposable.UseMutationOptions<ChangeProfileColorMutation, ChangeProfileColorMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ChangeProfileColorMutation, ChangeProfileColorMutationVariables>>) {
  return VueApolloComposable.useMutation<ChangeProfileColorMutation, ChangeProfileColorMutationVariables>(ChangeProfileColorDocument, options);
}
export type ChangeProfileColorMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ChangeProfileColorMutation, ChangeProfileColorMutationVariables>;
export const DeleteTableConfigDocument = gql`
    mutation DeleteTableConfig($id: ID!) {
  Delete__Profile_TableConfig(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteTableConfigMutation__
 *
 * To run a mutation, you first call `useDeleteTableConfigMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTableConfigMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteTableConfigMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTableConfigMutation(options: VueApolloComposable.UseMutationOptions<DeleteTableConfigMutation, DeleteTableConfigMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteTableConfigMutation, DeleteTableConfigMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteTableConfigMutation, DeleteTableConfigMutationVariables>(DeleteTableConfigDocument, options);
}
export type DeleteTableConfigMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteTableConfigMutation, DeleteTableConfigMutationVariables>;
export const UpdateProfileSettingsDocument = gql`
    mutation UpdateProfileSettings($input: Update__Profile_Profile__Input!) {
  profile: Update__Profile_Profile(input: $input) {
    ...Profile_Profile__Full
  }
}
    ${Profile_Profile__FullFragmentDoc}`;

/**
 * __useUpdateProfileSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateProfileSettingsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileSettingsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateProfileSettingsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileSettingsMutation(options: VueApolloComposable.UseMutationOptions<UpdateProfileSettingsMutation, UpdateProfileSettingsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateProfileSettingsMutation, UpdateProfileSettingsMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateProfileSettingsMutation, UpdateProfileSettingsMutationVariables>(UpdateProfileSettingsDocument, options);
}
export type UpdateProfileSettingsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateProfileSettingsMutation, UpdateProfileSettingsMutationVariables>;
export const GetQuotesDocument = gql`
    query GetQuotes($id: Any!) {
  quotes: quotes_quotes(input: {filters: [{key: "client__id", value: $id}]}) {
    id
    contact {
      id
      full_name
    }
  }
}
    `;

/**
 * __useGetQuotesQuery__
 *
 * To run a query within a Vue component, call `useGetQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuotesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetQuotesQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetQuotesQuery(variables: GetQuotesQueryVariables | VueCompositionApi.Ref<GetQuotesQueryVariables> | ReactiveFunction<GetQuotesQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetQuotesQuery, GetQuotesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetQuotesQuery, GetQuotesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetQuotesQuery, GetQuotesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetQuotesQuery, GetQuotesQueryVariables>(GetQuotesDocument, variables, options);
}
export type GetQuotesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetQuotesQuery, GetQuotesQueryVariables>;
export const QuotePartsForQuoteDocument = gql`
    query QuotePartsForQuote($input: ManyQueryInput!) {
  quote_parts: quotes_quote_parts(input: $input) {
    ...QuotePart
  }
}
    ${QuotePartFragmentDoc}`;

/**
 * __useQuotePartsForQuoteQuery__
 *
 * To run a query within a Vue component, call `useQuotePartsForQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotePartsForQuoteQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useQuotePartsForQuoteQuery({
 *   input: // value for 'input'
 * });
 */
export function useQuotePartsForQuoteQuery(variables: QuotePartsForQuoteQueryVariables | VueCompositionApi.Ref<QuotePartsForQuoteQueryVariables> | ReactiveFunction<QuotePartsForQuoteQueryVariables>, options: VueApolloComposable.UseQueryOptions<QuotePartsForQuoteQuery, QuotePartsForQuoteQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<QuotePartsForQuoteQuery, QuotePartsForQuoteQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<QuotePartsForQuoteQuery, QuotePartsForQuoteQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<QuotePartsForQuoteQuery, QuotePartsForQuoteQueryVariables>(QuotePartsForQuoteDocument, variables, options);
}
export type QuotePartsForQuoteQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<QuotePartsForQuoteQuery, QuotePartsForQuoteQueryVariables>;
export const GetQuoteForSaleImportDocument = gql`
    query GetQuoteForSaleImport($id: ID!) {
  quote: quotes_quote(input: {id: $id}) {
    ...QuoteForSaleImport
  }
}
    ${QuoteForSaleImportFragmentDoc}`;

/**
 * __useGetQuoteForSaleImportQuery__
 *
 * To run a query within a Vue component, call `useGetQuoteForSaleImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteForSaleImportQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetQuoteForSaleImportQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetQuoteForSaleImportQuery(variables: GetQuoteForSaleImportQueryVariables | VueCompositionApi.Ref<GetQuoteForSaleImportQueryVariables> | ReactiveFunction<GetQuoteForSaleImportQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetQuoteForSaleImportQuery, GetQuoteForSaleImportQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetQuoteForSaleImportQuery, GetQuoteForSaleImportQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetQuoteForSaleImportQuery, GetQuoteForSaleImportQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetQuoteForSaleImportQuery, GetQuoteForSaleImportQueryVariables>(GetQuoteForSaleImportDocument, variables, options);
}
export type GetQuoteForSaleImportQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetQuoteForSaleImportQuery, GetQuoteForSaleImportQueryVariables>;
export const QuotesDocument = gql`
    query quotes($input: ManyQueryInput!) {
  quotes: quotes_quotes(input: $input) {
    ...Quote
    annotations {
      name
      value
    }
  }
}
    ${QuoteFragmentDoc}`;

/**
 * __useQuotesQuery__
 *
 * To run a query within a Vue component, call `useQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useQuotesQuery({
 *   input: // value for 'input'
 * });
 */
export function useQuotesQuery(variables: QuotesQueryVariables | VueCompositionApi.Ref<QuotesQueryVariables> | ReactiveFunction<QuotesQueryVariables>, options: VueApolloComposable.UseQueryOptions<QuotesQuery, QuotesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<QuotesQuery, QuotesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<QuotesQuery, QuotesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<QuotesQuery, QuotesQueryVariables>(QuotesDocument, variables, options);
}
export type QuotesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<QuotesQuery, QuotesQueryVariables>;
export const QuoteForPdfDocument = gql`
    query QuoteForPdf($id: ID!) {
  quotes_quote(input: {id: $id}) {
    ...QuoteForPdf
  }
}
    ${QuoteForPdfFragmentDoc}`;

/**
 * __useQuoteForPdfQuery__
 *
 * To run a query within a Vue component, call `useQuoteForPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteForPdfQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useQuoteForPdfQuery({
 *   id: // value for 'id'
 * });
 */
export function useQuoteForPdfQuery(variables: QuoteForPdfQueryVariables | VueCompositionApi.Ref<QuoteForPdfQueryVariables> | ReactiveFunction<QuoteForPdfQueryVariables>, options: VueApolloComposable.UseQueryOptions<QuoteForPdfQuery, QuoteForPdfQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<QuoteForPdfQuery, QuoteForPdfQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<QuoteForPdfQuery, QuoteForPdfQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<QuoteForPdfQuery, QuoteForPdfQueryVariables>(QuoteForPdfDocument, variables, options);
}
export type QuoteForPdfQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<QuoteForPdfQuery, QuoteForPdfQueryVariables>;
export const CreateQuotePartDocument = gql`
    mutation CreateQuotePart($items: [Create__Quotes_QuoteParts__Input!]!) {
  parts: Create__Quotes_QuoteParts(input: $items) {
    ...QuotePart
  }
}
    ${QuotePartFragmentDoc}`;

/**
 * __useCreateQuotePartMutation__
 *
 * To run a mutation, you first call `useCreateQuotePartMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuotePartMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateQuotePartMutation({
 *   variables: {
 *     items: // value for 'items'
 *   },
 * });
 */
export function useCreateQuotePartMutation(options: VueApolloComposable.UseMutationOptions<CreateQuotePartMutation, CreateQuotePartMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateQuotePartMutation, CreateQuotePartMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateQuotePartMutation, CreateQuotePartMutationVariables>(CreateQuotePartDocument, options);
}
export type CreateQuotePartMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateQuotePartMutation, CreateQuotePartMutationVariables>;
export const UpdateQuoteForCommentDocument = gql`
    mutation UpdateQuoteForComment($input: Update__Quotes_Quote__Input!) {
  comment: Update__Quotes_Quote(input: $input) {
    id
  }
}
    `;

/**
 * __useUpdateQuoteForCommentMutation__
 *
 * To run a mutation, you first call `useUpdateQuoteForCommentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuoteForCommentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateQuoteForCommentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuoteForCommentMutation(options: VueApolloComposable.UseMutationOptions<UpdateQuoteForCommentMutation, UpdateQuoteForCommentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateQuoteForCommentMutation, UpdateQuoteForCommentMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateQuoteForCommentMutation, UpdateQuoteForCommentMutationVariables>(UpdateQuoteForCommentDocument, options);
}
export type UpdateQuoteForCommentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateQuoteForCommentMutation, UpdateQuoteForCommentMutationVariables>;
export const GetRmaForPdfDocument = gql`
    query GetRmaForPdf($id: ID!) {
  rma_rma(input: {id: $id}) {
    id
    shipment_order {
      id
      blind
      purchaser_contact {
        ...Contact_List
      }
      purchaser_address {
        id
        street_1
        street_2
        city
        state
        country {
          code
          name
        }
        zip_code
        company {
          name
        }
        client {
          id
          name
        }
        default_contact {
          id
          full_name
        }
      }
      ship_from_contact {
        ...Contact_List
      }
      ship_from_address {
        id
        street_1
        street_2
        city
        state
        country {
          code
          name
        }
        zip_code
        company {
          name
        }
        client {
          id
          name
        }
        default_contact {
          id
          full_name
        }
      }
      ship_to_contact {
        ...Contact_List
      }
      ship_to_address {
        id
        street_1
        street_2
        city
        state
        country {
          code
          name
        }
        zip_code
        client {
          id
          name
        }
        company {
          name
        }
        default_contact {
          id
          full_name
        }
      }
    }
    st {
      id
      rep {
        id
        full_name
        initials
      }
    }
    rma_items {
      id
      credit
      returned_part {
        id
        part {
          id
          pn
          description
          mfgr {
            id
            name
          }
        }
        sales_items_details {
          id
          sold_for {
            ...Money
          }
        }
        serial_number
      }
    }
    return_code {
      id
      choice
      sub_choice
    }
    return_reason
  }
}
    ${Contact_ListFragmentDoc}
${MoneyFragmentDoc}`;

/**
 * __useGetRmaForPdfQuery__
 *
 * To run a query within a Vue component, call `useGetRmaForPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRmaForPdfQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetRmaForPdfQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetRmaForPdfQuery(variables: GetRmaForPdfQueryVariables | VueCompositionApi.Ref<GetRmaForPdfQueryVariables> | ReactiveFunction<GetRmaForPdfQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetRmaForPdfQuery, GetRmaForPdfQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetRmaForPdfQuery, GetRmaForPdfQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetRmaForPdfQuery, GetRmaForPdfQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetRmaForPdfQuery, GetRmaForPdfQueryVariables>(GetRmaForPdfDocument, variables, options);
}
export type GetRmaForPdfQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetRmaForPdfQuery, GetRmaForPdfQueryVariables>;
export const GetPurchaseRmaItemsDocument = gql`
    query GetPurchaseRmaItems($input: ManyQueryInput!) {
  purchase_rma_items: rma_purchase_rma_items(input: $input) {
    ...PurchaseRmaItem
  }
}
    ${PurchaseRmaItemFragmentDoc}`;

/**
 * __useGetPurchaseRmaItemsQuery__
 *
 * To run a query within a Vue component, call `useGetPurchaseRmaItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseRmaItemsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetPurchaseRmaItemsQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetPurchaseRmaItemsQuery(variables: GetPurchaseRmaItemsQueryVariables | VueCompositionApi.Ref<GetPurchaseRmaItemsQueryVariables> | ReactiveFunction<GetPurchaseRmaItemsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetPurchaseRmaItemsQuery, GetPurchaseRmaItemsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetPurchaseRmaItemsQuery, GetPurchaseRmaItemsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetPurchaseRmaItemsQuery, GetPurchaseRmaItemsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetPurchaseRmaItemsQuery, GetPurchaseRmaItemsQueryVariables>(GetPurchaseRmaItemsDocument, variables, options);
}
export type GetPurchaseRmaItemsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetPurchaseRmaItemsQuery, GetPurchaseRmaItemsQueryVariables>;
export const GetSaleeRmaItemsDocument = gql`
    query GetSaleeRmaItems($input: ManyQueryInput!) {
  rma_items: rma_rma_items(input: $input) {
    ...SaleRmaItem
  }
}
    ${SaleRmaItemFragmentDoc}`;

/**
 * __useGetSaleeRmaItemsQuery__
 *
 * To run a query within a Vue component, call `useGetSaleeRmaItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSaleeRmaItemsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSaleeRmaItemsQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetSaleeRmaItemsQuery(variables: GetSaleeRmaItemsQueryVariables | VueCompositionApi.Ref<GetSaleeRmaItemsQueryVariables> | ReactiveFunction<GetSaleeRmaItemsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetSaleeRmaItemsQuery, GetSaleeRmaItemsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetSaleeRmaItemsQuery, GetSaleeRmaItemsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetSaleeRmaItemsQuery, GetSaleeRmaItemsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetSaleeRmaItemsQuery, GetSaleeRmaItemsQueryVariables>(GetSaleeRmaItemsDocument, variables, options);
}
export type GetSaleeRmaItemsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetSaleeRmaItemsQuery, GetSaleeRmaItemsQueryVariables>;
export const GridGetSalesRmaDocument = gql`
    query GridGetSalesRMA($input: ManyQueryInput!) {
  rmas: rma_rmas(input: $input) {
    annotations {
      name
      value
    }
    claim_date
    closed_date
    contact {
      id
      full_name
    }
    creation_date
    creator {
      ...UserMinimal
    }
    id
    st {
      id
      client {
        id
        name
      }
    }
    received_date
    return_code {
      id
      choice
    }
    return_reason
    shipment_order {
      ...ShipmentOrderDetails
    }
    status {
      id
      status
    }
    contract_comment
    internal_comment
    updated_date
    voided_date
    warranty_honored_after_cut_off
  }
}
    ${UserMinimalFragmentDoc}
${ShipmentOrderDetailsFragmentDoc}`;

/**
 * __useGridGetSalesRmaQuery__
 *
 * To run a query within a Vue component, call `useGridGetSalesRmaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGridGetSalesRmaQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGridGetSalesRmaQuery({
 *   input: // value for 'input'
 * });
 */
export function useGridGetSalesRmaQuery(variables: GridGetSalesRmaQueryVariables | VueCompositionApi.Ref<GridGetSalesRmaQueryVariables> | ReactiveFunction<GridGetSalesRmaQueryVariables>, options: VueApolloComposable.UseQueryOptions<GridGetSalesRmaQuery, GridGetSalesRmaQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GridGetSalesRmaQuery, GridGetSalesRmaQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GridGetSalesRmaQuery, GridGetSalesRmaQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GridGetSalesRmaQuery, GridGetSalesRmaQueryVariables>(GridGetSalesRmaDocument, variables, options);
}
export type GridGetSalesRmaQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GridGetSalesRmaQuery, GridGetSalesRmaQueryVariables>;
export const GridGetPurchaseRmAsDocument = gql`
    query GridGetPurchaseRMAs($input: ManyQueryInput!) {
  purchase_rmas: rma_purchase_rmas(input: $input) {
    claim_date
    closed_date
    creation_date
    creator {
      ...UserMinimal
    }
    id
    pt {
      id
      client {
        id
        name
      }
    }
    received_date
    shipment_order {
      ...ShipmentOrderDetails
    }
    status {
      id
      status
      status_type
      order
    }
    updated_date
    voided_date
  }
}
    ${UserMinimalFragmentDoc}
${ShipmentOrderDetailsFragmentDoc}`;

/**
 * __useGridGetPurchaseRmAsQuery__
 *
 * To run a query within a Vue component, call `useGridGetPurchaseRmAsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGridGetPurchaseRmAsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGridGetPurchaseRmAsQuery({
 *   input: // value for 'input'
 * });
 */
export function useGridGetPurchaseRmAsQuery(variables: GridGetPurchaseRmAsQueryVariables | VueCompositionApi.Ref<GridGetPurchaseRmAsQueryVariables> | ReactiveFunction<GridGetPurchaseRmAsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GridGetPurchaseRmAsQuery, GridGetPurchaseRmAsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GridGetPurchaseRmAsQuery, GridGetPurchaseRmAsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GridGetPurchaseRmAsQuery, GridGetPurchaseRmAsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GridGetPurchaseRmAsQuery, GridGetPurchaseRmAsQueryVariables>(GridGetPurchaseRmAsDocument, variables, options);
}
export type GridGetPurchaseRmAsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GridGetPurchaseRmAsQuery, GridGetPurchaseRmAsQueryVariables>;
export const GetItemCountForRmaDocument = gql`
    query GetItemCountForRma($id: ID!) {
  count: rma_rma(
    input: {id: $id, annotations: [{aggr_type: COUNT, aggr_field: "rma_items", name: "count"}]}
  ) {
    id
    annotations {
      name
      value
    }
  }
}
    `;

/**
 * __useGetItemCountForRmaQuery__
 *
 * To run a query within a Vue component, call `useGetItemCountForRmaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemCountForRmaQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetItemCountForRmaQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetItemCountForRmaQuery(variables: GetItemCountForRmaQueryVariables | VueCompositionApi.Ref<GetItemCountForRmaQueryVariables> | ReactiveFunction<GetItemCountForRmaQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetItemCountForRmaQuery, GetItemCountForRmaQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetItemCountForRmaQuery, GetItemCountForRmaQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetItemCountForRmaQuery, GetItemCountForRmaQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetItemCountForRmaQuery, GetItemCountForRmaQueryVariables>(GetItemCountForRmaDocument, variables, options);
}
export type GetItemCountForRmaQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetItemCountForRmaQuery, GetItemCountForRmaQueryVariables>;
export const GetItemCountForPurchaseRmaDocument = gql`
    query GetItemCountForPurchaseRma($id: ID!) {
  count: rma_purchase_rma(
    input: {id: $id, annotations: [{aggr_type: COUNT, aggr_field: "rma_items", name: "count"}]}
  ) {
    id
    annotations {
      name
      value
    }
  }
}
    `;

/**
 * __useGetItemCountForPurchaseRmaQuery__
 *
 * To run a query within a Vue component, call `useGetItemCountForPurchaseRmaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemCountForPurchaseRmaQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetItemCountForPurchaseRmaQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetItemCountForPurchaseRmaQuery(variables: GetItemCountForPurchaseRmaQueryVariables | VueCompositionApi.Ref<GetItemCountForPurchaseRmaQueryVariables> | ReactiveFunction<GetItemCountForPurchaseRmaQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetItemCountForPurchaseRmaQuery, GetItemCountForPurchaseRmaQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetItemCountForPurchaseRmaQuery, GetItemCountForPurchaseRmaQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetItemCountForPurchaseRmaQuery, GetItemCountForPurchaseRmaQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetItemCountForPurchaseRmaQuery, GetItemCountForPurchaseRmaQueryVariables>(GetItemCountForPurchaseRmaDocument, variables, options);
}
export type GetItemCountForPurchaseRmaQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetItemCountForPurchaseRmaQuery, GetItemCountForPurchaseRmaQueryVariables>;
export const CreatePurchaseRmaDocument = gql`
    mutation CreatePurchaseRMA($date: Date, $pt: ID!, $shipment: ID) {
  rma: Create__RMA_PurchaseRMA(
    input: {claim_date: $date, pt_id: $pt, status_id: 48, shipment_order_id: $shipment}
  ) {
    id
  }
}
    `;

/**
 * __useCreatePurchaseRmaMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseRmaMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseRmaMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreatePurchaseRmaMutation({
 *   variables: {
 *     date: // value for 'date'
 *     pt: // value for 'pt'
 *     shipment: // value for 'shipment'
 *   },
 * });
 */
export function useCreatePurchaseRmaMutation(options: VueApolloComposable.UseMutationOptions<CreatePurchaseRmaMutation, CreatePurchaseRmaMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreatePurchaseRmaMutation, CreatePurchaseRmaMutationVariables>>) {
  return VueApolloComposable.useMutation<CreatePurchaseRmaMutation, CreatePurchaseRmaMutationVariables>(CreatePurchaseRmaDocument, options);
}
export type CreatePurchaseRmaMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreatePurchaseRmaMutation, CreatePurchaseRmaMutationVariables>;
export const CreatePurchaseRmaItemDocument = gql`
    mutation CreatePurchaseRMAItem($items: [Create__RMA_PurchaseRMAItems__Input!]!) {
  items: Create__RMA_PurchaseRMAItems(input: $items) {
    id
  }
}
    `;

/**
 * __useCreatePurchaseRmaItemMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseRmaItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseRmaItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreatePurchaseRmaItemMutation({
 *   variables: {
 *     items: // value for 'items'
 *   },
 * });
 */
export function useCreatePurchaseRmaItemMutation(options: VueApolloComposable.UseMutationOptions<CreatePurchaseRmaItemMutation, CreatePurchaseRmaItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreatePurchaseRmaItemMutation, CreatePurchaseRmaItemMutationVariables>>) {
  return VueApolloComposable.useMutation<CreatePurchaseRmaItemMutation, CreatePurchaseRmaItemMutationVariables>(CreatePurchaseRmaItemDocument, options);
}
export type CreatePurchaseRmaItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreatePurchaseRmaItemMutation, CreatePurchaseRmaItemMutationVariables>;
export const CreateSaleRmaDocument = gql`
    mutation CreateSaleRMA($date: Date!, $st: ID!, $code: ID!, $reason: String!, $warranty: Boolean!) {
  rma: Create__RMA_RMA(
    input: {claim_date: $date, st_id: $st, status_id: 37, return_code_id: $code, return_reason: $reason, warranty_honored_after_cut_off: $warranty}
  ) {
    id
  }
}
    `;

/**
 * __useCreateSaleRmaMutation__
 *
 * To run a mutation, you first call `useCreateSaleRmaMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateSaleRmaMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateSaleRmaMutation({
 *   variables: {
 *     date: // value for 'date'
 *     st: // value for 'st'
 *     code: // value for 'code'
 *     reason: // value for 'reason'
 *     warranty: // value for 'warranty'
 *   },
 * });
 */
export function useCreateSaleRmaMutation(options: VueApolloComposable.UseMutationOptions<CreateSaleRmaMutation, CreateSaleRmaMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateSaleRmaMutation, CreateSaleRmaMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateSaleRmaMutation, CreateSaleRmaMutationVariables>(CreateSaleRmaDocument, options);
}
export type CreateSaleRmaMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateSaleRmaMutation, CreateSaleRmaMutationVariables>;
export const CreateSaleRmaItemDocument = gql`
    mutation CreateSaleRMAItem($items: [Create__RMA_RMAItems__Input!]!) {
  item: Create__RMA_RMAItems(input: $items) {
    id
  }
}
    `;

/**
 * __useCreateSaleRmaItemMutation__
 *
 * To run a mutation, you first call `useCreateSaleRmaItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateSaleRmaItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateSaleRmaItemMutation({
 *   variables: {
 *     items: // value for 'items'
 *   },
 * });
 */
export function useCreateSaleRmaItemMutation(options: VueApolloComposable.UseMutationOptions<CreateSaleRmaItemMutation, CreateSaleRmaItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateSaleRmaItemMutation, CreateSaleRmaItemMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateSaleRmaItemMutation, CreateSaleRmaItemMutationVariables>(CreateSaleRmaItemDocument, options);
}
export type CreateSaleRmaItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateSaleRmaItemMutation, CreateSaleRmaItemMutationVariables>;
export const DeleteRmaItemDocument = gql`
    mutation DeleteRmaItem($id: ID!) {
  Delete__RMA_RMAItems(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteRmaItemMutation__
 *
 * To run a mutation, you first call `useDeleteRmaItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRmaItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteRmaItemMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRmaItemMutation(options: VueApolloComposable.UseMutationOptions<DeleteRmaItemMutation, DeleteRmaItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteRmaItemMutation, DeleteRmaItemMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteRmaItemMutation, DeleteRmaItemMutationVariables>(DeleteRmaItemDocument, options);
}
export type DeleteRmaItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteRmaItemMutation, DeleteRmaItemMutationVariables>;
export const UpdatePurchaseRmaStatusDocument = gql`
    mutation UpdatePurchaseRMAStatus($id: ID!, $status: ID!) {
  rma: Update__RMA_PurchaseRMA(input: {id: $id, status_id: $status}) {
    id
    status {
      id
      status
      status_type
      order
    }
  }
}
    `;

/**
 * __useUpdatePurchaseRmaStatusMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseRmaStatusMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseRmaStatusMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdatePurchaseRmaStatusMutation({
 *   variables: {
 *     id: // value for 'id'
 *     status: // value for 'status'
 *   },
 * });
 */
export function useUpdatePurchaseRmaStatusMutation(options: VueApolloComposable.UseMutationOptions<UpdatePurchaseRmaStatusMutation, UpdatePurchaseRmaStatusMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdatePurchaseRmaStatusMutation, UpdatePurchaseRmaStatusMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdatePurchaseRmaStatusMutation, UpdatePurchaseRmaStatusMutationVariables>(UpdatePurchaseRmaStatusDocument, options);
}
export type UpdatePurchaseRmaStatusMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdatePurchaseRmaStatusMutation, UpdatePurchaseRmaStatusMutationVariables>;
export const UpdateSaleRmaStatusDocument = gql`
    mutation UpdateSaleRMAStatus($id: ID!, $status: ID!) {
  rma: Update__RMA_RMA(input: {id: $id, status_id: $status}) {
    id
    status {
      id
      status
      status_type
      order
    }
  }
}
    `;

/**
 * __useUpdateSaleRmaStatusMutation__
 *
 * To run a mutation, you first call `useUpdateSaleRmaStatusMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSaleRmaStatusMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateSaleRmaStatusMutation({
 *   variables: {
 *     id: // value for 'id'
 *     status: // value for 'status'
 *   },
 * });
 */
export function useUpdateSaleRmaStatusMutation(options: VueApolloComposable.UseMutationOptions<UpdateSaleRmaStatusMutation, UpdateSaleRmaStatusMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateSaleRmaStatusMutation, UpdateSaleRmaStatusMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateSaleRmaStatusMutation, UpdateSaleRmaStatusMutationVariables>(UpdateSaleRmaStatusDocument, options);
}
export type UpdateSaleRmaStatusMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateSaleRmaStatusMutation, UpdateSaleRmaStatusMutationVariables>;
export const GetReports_FullDocument = gql`
    query GetReports_Full($input: ManyQueryInput!) {
  reporting_reports(input: $input) {
    ...Reporting_Report__Full
  }
}
    ${Reporting_Report__FullFragmentDoc}`;

/**
 * __useGetReports_FullQuery__
 *
 * To run a query within a Vue component, call `useGetReports_FullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReports_FullQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetReports_FullQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetReports_FullQuery(variables: GetReports_FullQueryVariables | VueCompositionApi.Ref<GetReports_FullQueryVariables> | ReactiveFunction<GetReports_FullQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetReports_FullQuery, GetReports_FullQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetReports_FullQuery, GetReports_FullQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetReports_FullQuery, GetReports_FullQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetReports_FullQuery, GetReports_FullQueryVariables>(GetReports_FullDocument, variables, options);
}
export type GetReports_FullQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetReports_FullQuery, GetReports_FullQueryVariables>;
export const CreateReportDocument = gql`
    mutation CreateReport($input: Create__Reporting_Report__Input!) {
  Create__Reporting_Report(input: $input) {
    ...Reporting_Report__Full
  }
}
    ${Reporting_Report__FullFragmentDoc}`;

/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateReportMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateReportMutation(options: VueApolloComposable.UseMutationOptions<CreateReportMutation, CreateReportMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateReportMutation, CreateReportMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateReportMutation, CreateReportMutationVariables>(CreateReportDocument, options);
}
export type CreateReportMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateReportMutation, CreateReportMutationVariables>;
export const UpdateReportDocument = gql`
    mutation UpdateReport($input: Update__Reporting_Report__Input!) {
  Update__Reporting_Report(input: $input) {
    ...Reporting_Report__Full
  }
}
    ${Reporting_Report__FullFragmentDoc}`;

/**
 * __useUpdateReportMutation__
 *
 * To run a mutation, you first call `useUpdateReportMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateReportMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReportMutation(options: VueApolloComposable.UseMutationOptions<UpdateReportMutation, UpdateReportMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateReportMutation, UpdateReportMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateReportMutation, UpdateReportMutationVariables>(UpdateReportDocument, options);
}
export type UpdateReportMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateReportMutation, UpdateReportMutationVariables>;
export const DeleteReportDocument = gql`
    mutation DeleteReport($id: ID!) {
  Delete__Reporting_Report(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteReportMutation__
 *
 * To run a mutation, you first call `useDeleteReportMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteReportMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReportMutation(options: VueApolloComposable.UseMutationOptions<DeleteReportMutation, DeleteReportMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteReportMutation, DeleteReportMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteReportMutation, DeleteReportMutationVariables>(DeleteReportDocument, options);
}
export type DeleteReportMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteReportMutation, DeleteReportMutationVariables>;
export const IntrospectForFedexInputDocument = gql`
    query IntrospectForFedexInput($name: String!) {
  __type(name: $name) {
    name
    inputFields {
      name
      description
    }
  }
}
    `;

/**
 * __useIntrospectForFedexInputQuery__
 *
 * To run a query within a Vue component, call `useIntrospectForFedexInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntrospectForFedexInputQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useIntrospectForFedexInputQuery({
 *   name: // value for 'name'
 * });
 */
export function useIntrospectForFedexInputQuery(variables: IntrospectForFedexInputQueryVariables | VueCompositionApi.Ref<IntrospectForFedexInputQueryVariables> | ReactiveFunction<IntrospectForFedexInputQueryVariables>, options: VueApolloComposable.UseQueryOptions<IntrospectForFedexInputQuery, IntrospectForFedexInputQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<IntrospectForFedexInputQuery, IntrospectForFedexInputQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<IntrospectForFedexInputQuery, IntrospectForFedexInputQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<IntrospectForFedexInputQuery, IntrospectForFedexInputQueryVariables>(IntrospectForFedexInputDocument, variables, options);
}
export type IntrospectForFedexInputQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<IntrospectForFedexInputQuery, IntrospectForFedexInputQueryVariables>;
export const GetShippingServiceLevelsDocument = gql`
    query GetShippingServiceLevels {
  shipping_service_levels(input: {limit: 100000}) {
    id
    color
    service
    easypost_name
    order_arrive_by
    order_ready_by
    shipper {
      id
      name
      easypost_name
    }
  }
}
    `;

/**
 * __useGetShippingServiceLevelsQuery__
 *
 * To run a query within a Vue component, call `useGetShippingServiceLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShippingServiceLevelsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetShippingServiceLevelsQuery();
 */
export function useGetShippingServiceLevelsQuery(options: VueApolloComposable.UseQueryOptions<GetShippingServiceLevelsQuery, GetShippingServiceLevelsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetShippingServiceLevelsQuery, GetShippingServiceLevelsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetShippingServiceLevelsQuery, GetShippingServiceLevelsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetShippingServiceLevelsQuery, GetShippingServiceLevelsQueryVariables>(GetShippingServiceLevelsDocument, {}, options);
}
export type GetShippingServiceLevelsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetShippingServiceLevelsQuery, GetShippingServiceLevelsQueryVariables>;
export const ShipmentOrderDetails_FullDocument = gql`
    query ShipmentOrderDetails_Full($id: ID!) {
  order: shipping_shipment_order(input: {id: $id}) {
    ...ShipmentOrderDetails_Full
  }
}
    ${ShipmentOrderDetails_FullFragmentDoc}`;

/**
 * __useShipmentOrderDetails_FullQuery__
 *
 * To run a query within a Vue component, call `useShipmentOrderDetails_FullQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentOrderDetails_FullQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useShipmentOrderDetails_FullQuery({
 *   id: // value for 'id'
 * });
 */
export function useShipmentOrderDetails_FullQuery(variables: ShipmentOrderDetails_FullQueryVariables | VueCompositionApi.Ref<ShipmentOrderDetails_FullQueryVariables> | ReactiveFunction<ShipmentOrderDetails_FullQueryVariables>, options: VueApolloComposable.UseQueryOptions<ShipmentOrderDetails_FullQuery, ShipmentOrderDetails_FullQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ShipmentOrderDetails_FullQuery, ShipmentOrderDetails_FullQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ShipmentOrderDetails_FullQuery, ShipmentOrderDetails_FullQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ShipmentOrderDetails_FullQuery, ShipmentOrderDetails_FullQueryVariables>(ShipmentOrderDetails_FullDocument, variables, options);
}
export type ShipmentOrderDetails_FullQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ShipmentOrderDetails_FullQuery, ShipmentOrderDetails_FullQueryVariables>;
export const GetShipmentsOnShippingOrderDocument = gql`
    query GetShipmentsOnShippingOrder($id: Any!) {
  shipping_shipments(input: {filters: [{key: "order__id", value: $id}]}) {
    ...Shipping_Shippment_Full
  }
}
    ${Shipping_Shippment_FullFragmentDoc}`;

/**
 * __useGetShipmentsOnShippingOrderQuery__
 *
 * To run a query within a Vue component, call `useGetShipmentsOnShippingOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentsOnShippingOrderQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetShipmentsOnShippingOrderQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetShipmentsOnShippingOrderQuery(variables: GetShipmentsOnShippingOrderQueryVariables | VueCompositionApi.Ref<GetShipmentsOnShippingOrderQueryVariables> | ReactiveFunction<GetShipmentsOnShippingOrderQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetShipmentsOnShippingOrderQuery, GetShipmentsOnShippingOrderQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetShipmentsOnShippingOrderQuery, GetShipmentsOnShippingOrderQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetShipmentsOnShippingOrderQuery, GetShipmentsOnShippingOrderQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetShipmentsOnShippingOrderQuery, GetShipmentsOnShippingOrderQueryVariables>(GetShipmentsOnShippingOrderDocument, variables, options);
}
export type GetShipmentsOnShippingOrderQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetShipmentsOnShippingOrderQuery, GetShipmentsOnShippingOrderQueryVariables>;
export const ShipmentOrderDocument = gql`
    query ShipmentOrder($id: ID!) {
  order: shipping_shipment_order(input: {id: $id}) {
    ...ShipmentOrderDetails
  }
}
    ${ShipmentOrderDetailsFragmentDoc}`;

/**
 * __useShipmentOrderQuery__
 *
 * To run a query within a Vue component, call `useShipmentOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentOrderQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useShipmentOrderQuery({
 *   id: // value for 'id'
 * });
 */
export function useShipmentOrderQuery(variables: ShipmentOrderQueryVariables | VueCompositionApi.Ref<ShipmentOrderQueryVariables> | ReactiveFunction<ShipmentOrderQueryVariables>, options: VueApolloComposable.UseQueryOptions<ShipmentOrderQuery, ShipmentOrderQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ShipmentOrderQuery, ShipmentOrderQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ShipmentOrderQuery, ShipmentOrderQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ShipmentOrderQuery, ShipmentOrderQueryVariables>(ShipmentOrderDocument, variables, options);
}
export type ShipmentOrderQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ShipmentOrderQuery, ShipmentOrderQueryVariables>;
export const ShipmentOrdersListDocument = gql`
    query ShipmentOrdersList($input: ManyQueryInput!) {
  shipments: shipping_shipment_orders(input: $input) {
    ...ShipmentOrderForList
    annotations {
      name
      value
    }
  }
}
    ${ShipmentOrderForListFragmentDoc}`;

/**
 * __useShipmentOrdersListQuery__
 *
 * To run a query within a Vue component, call `useShipmentOrdersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentOrdersListQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useShipmentOrdersListQuery({
 *   input: // value for 'input'
 * });
 */
export function useShipmentOrdersListQuery(variables: ShipmentOrdersListQueryVariables | VueCompositionApi.Ref<ShipmentOrdersListQueryVariables> | ReactiveFunction<ShipmentOrdersListQueryVariables>, options: VueApolloComposable.UseQueryOptions<ShipmentOrdersListQuery, ShipmentOrdersListQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ShipmentOrdersListQuery, ShipmentOrdersListQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ShipmentOrdersListQuery, ShipmentOrdersListQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ShipmentOrdersListQuery, ShipmentOrdersListQueryVariables>(ShipmentOrdersListDocument, variables, options);
}
export type ShipmentOrdersListQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ShipmentOrdersListQuery, ShipmentOrdersListQueryVariables>;
export const GetCarrierAccountsDocument = gql`
    query GetCarrierAccounts($input: ManyQueryInput!) {
  carrier_accounts: shipping_accounts(input: $input) {
    id
    description
    easypost_id
    default_shipping_account_for_client {
      id
    }
    client {
      id
      name
    }
    name
    number
    shipper {
      id
      name
    }
  }
}
    `;

/**
 * __useGetCarrierAccountsQuery__
 *
 * To run a query within a Vue component, call `useGetCarrierAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarrierAccountsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCarrierAccountsQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetCarrierAccountsQuery(variables: GetCarrierAccountsQueryVariables | VueCompositionApi.Ref<GetCarrierAccountsQueryVariables> | ReactiveFunction<GetCarrierAccountsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetCarrierAccountsQuery, GetCarrierAccountsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetCarrierAccountsQuery, GetCarrierAccountsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetCarrierAccountsQuery, GetCarrierAccountsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetCarrierAccountsQuery, GetCarrierAccountsQueryVariables>(GetCarrierAccountsDocument, variables, options);
}
export type GetCarrierAccountsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetCarrierAccountsQuery, GetCarrierAccountsQueryVariables>;
export const GetCarrierAccountsForAutofillDocument = gql`
    query GetCarrierAccountsForAutofill($filters: [QueryFilter!]) {
  shipping_accounts(input: {filters: $filters}) {
    id
    easypost_id
    title: name
    number
    shipper {
      id
      name
    }
  }
}
    `;

/**
 * __useGetCarrierAccountsForAutofillQuery__
 *
 * To run a query within a Vue component, call `useGetCarrierAccountsForAutofillQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarrierAccountsForAutofillQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCarrierAccountsForAutofillQuery({
 *   filters: // value for 'filters'
 * });
 */
export function useGetCarrierAccountsForAutofillQuery(variables: GetCarrierAccountsForAutofillQueryVariables | VueCompositionApi.Ref<GetCarrierAccountsForAutofillQueryVariables> | ReactiveFunction<GetCarrierAccountsForAutofillQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<GetCarrierAccountsForAutofillQuery, GetCarrierAccountsForAutofillQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetCarrierAccountsForAutofillQuery, GetCarrierAccountsForAutofillQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetCarrierAccountsForAutofillQuery, GetCarrierAccountsForAutofillQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetCarrierAccountsForAutofillQuery, GetCarrierAccountsForAutofillQueryVariables>(GetCarrierAccountsForAutofillDocument, variables, options);
}
export type GetCarrierAccountsForAutofillQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetCarrierAccountsForAutofillQuery, GetCarrierAccountsForAutofillQueryVariables>;
export const GetCompanyCarrierAccountsWithNumberForAutofillDocument = gql`
    query GetCompanyCarrierAccountsWithNumberForAutofill {
  accounts: shipping_accounts(
    input: {filters: [{key: "client__isnull", value: true}]}
  ) {
    id
    title: name
    subtitle: number
    shipper {
      id
      name
    }
    easypost_id
  }
}
    `;

/**
 * __useGetCompanyCarrierAccountsWithNumberForAutofillQuery__
 *
 * To run a query within a Vue component, call `useGetCompanyCarrierAccountsWithNumberForAutofillQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyCarrierAccountsWithNumberForAutofillQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCompanyCarrierAccountsWithNumberForAutofillQuery();
 */
export function useGetCompanyCarrierAccountsWithNumberForAutofillQuery(options: VueApolloComposable.UseQueryOptions<GetCompanyCarrierAccountsWithNumberForAutofillQuery, GetCompanyCarrierAccountsWithNumberForAutofillQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetCompanyCarrierAccountsWithNumberForAutofillQuery, GetCompanyCarrierAccountsWithNumberForAutofillQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetCompanyCarrierAccountsWithNumberForAutofillQuery, GetCompanyCarrierAccountsWithNumberForAutofillQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetCompanyCarrierAccountsWithNumberForAutofillQuery, GetCompanyCarrierAccountsWithNumberForAutofillQueryVariables>(GetCompanyCarrierAccountsWithNumberForAutofillDocument, {}, options);
}
export type GetCompanyCarrierAccountsWithNumberForAutofillQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetCompanyCarrierAccountsWithNumberForAutofillQuery, GetCompanyCarrierAccountsWithNumberForAutofillQueryVariables>;
export const ClientAndCompanyCarrierAccountsDocument = gql`
    query ClientAndCompanyCarrierAccounts($id: Any!) {
  accounts: shipping_accounts(
    input: {filters: [{key: "client__id", value: $id, or: {key: "client__isnull", value: true}}]}
  ) {
    id
    easypost_id
    client {
      id
      name
    }
    name
    number
    shipper {
      id
      name
    }
  }
}
    `;

/**
 * __useClientAndCompanyCarrierAccountsQuery__
 *
 * To run a query within a Vue component, call `useClientAndCompanyCarrierAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientAndCompanyCarrierAccountsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useClientAndCompanyCarrierAccountsQuery({
 *   id: // value for 'id'
 * });
 */
export function useClientAndCompanyCarrierAccountsQuery(variables: ClientAndCompanyCarrierAccountsQueryVariables | VueCompositionApi.Ref<ClientAndCompanyCarrierAccountsQueryVariables> | ReactiveFunction<ClientAndCompanyCarrierAccountsQueryVariables>, options: VueApolloComposable.UseQueryOptions<ClientAndCompanyCarrierAccountsQuery, ClientAndCompanyCarrierAccountsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ClientAndCompanyCarrierAccountsQuery, ClientAndCompanyCarrierAccountsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ClientAndCompanyCarrierAccountsQuery, ClientAndCompanyCarrierAccountsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ClientAndCompanyCarrierAccountsQuery, ClientAndCompanyCarrierAccountsQueryVariables>(ClientAndCompanyCarrierAccountsDocument, variables, options);
}
export type ClientAndCompanyCarrierAccountsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ClientAndCompanyCarrierAccountsQuery, ClientAndCompanyCarrierAccountsQueryVariables>;
export const GetClientCarrierAccountsWithNumberForAutofillDocument = gql`
    query GetClientCarrierAccountsWithNumberForAutofill($id: Any!) {
  accounts: shipping_accounts(input: {filters: [{key: "client__id", value: $id}]}) {
    id
    title: name
    subtitle: number
    shipper {
      id
      name
    }
    easypost_id
  }
}
    `;

/**
 * __useGetClientCarrierAccountsWithNumberForAutofillQuery__
 *
 * To run a query within a Vue component, call `useGetClientCarrierAccountsWithNumberForAutofillQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientCarrierAccountsWithNumberForAutofillQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetClientCarrierAccountsWithNumberForAutofillQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetClientCarrierAccountsWithNumberForAutofillQuery(variables: GetClientCarrierAccountsWithNumberForAutofillQueryVariables | VueCompositionApi.Ref<GetClientCarrierAccountsWithNumberForAutofillQueryVariables> | ReactiveFunction<GetClientCarrierAccountsWithNumberForAutofillQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetClientCarrierAccountsWithNumberForAutofillQuery, GetClientCarrierAccountsWithNumberForAutofillQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetClientCarrierAccountsWithNumberForAutofillQuery, GetClientCarrierAccountsWithNumberForAutofillQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetClientCarrierAccountsWithNumberForAutofillQuery, GetClientCarrierAccountsWithNumberForAutofillQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetClientCarrierAccountsWithNumberForAutofillQuery, GetClientCarrierAccountsWithNumberForAutofillQueryVariables>(GetClientCarrierAccountsWithNumberForAutofillDocument, variables, options);
}
export type GetClientCarrierAccountsWithNumberForAutofillQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetClientCarrierAccountsWithNumberForAutofillQuery, GetClientCarrierAccountsWithNumberForAutofillQueryVariables>;
export const CarriersAliasesDocument = gql`
    query CarriersAliases {
  carriers: shipping_carriers(input: {limit: 1000}) {
    id
    title: name
  }
}
    `;

/**
 * __useCarriersAliasesQuery__
 *
 * To run a query within a Vue component, call `useCarriersAliasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarriersAliasesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCarriersAliasesQuery();
 */
export function useCarriersAliasesQuery(options: VueApolloComposable.UseQueryOptions<CarriersAliasesQuery, CarriersAliasesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CarriersAliasesQuery, CarriersAliasesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CarriersAliasesQuery, CarriersAliasesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CarriersAliasesQuery, CarriersAliasesQueryVariables>(CarriersAliasesDocument, {}, options);
}
export type CarriersAliasesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CarriersAliasesQuery, CarriersAliasesQueryVariables>;
export const CarriersDocument = gql`
    query Carriers {
  shipping_carriers(input: {limit: 10000}) {
    id
    name
    is_courier
  }
}
    `;

/**
 * __useCarriersQuery__
 *
 * To run a query within a Vue component, call `useCarriersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarriersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCarriersQuery();
 */
export function useCarriersQuery(options: VueApolloComposable.UseQueryOptions<CarriersQuery, CarriersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CarriersQuery, CarriersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CarriersQuery, CarriersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CarriersQuery, CarriersQueryVariables>(CarriersDocument, {}, options);
}
export type CarriersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CarriersQuery, CarriersQueryVariables>;
export const CreateShipmentOrderDocument = gql`
    mutation CreateShipmentOrder($input: Create__Shipping_ShipmentOrder__Input!) {
  order: Create__Shipping_ShipmentOrder(input: $input) {
    ...ShipmentOrderDetails
  }
}
    ${ShipmentOrderDetailsFragmentDoc}`;

/**
 * __useCreateShipmentOrderMutation__
 *
 * To run a mutation, you first call `useCreateShipmentOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateShipmentOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateShipmentOrderMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateShipmentOrderMutation(options: VueApolloComposable.UseMutationOptions<CreateShipmentOrderMutation, CreateShipmentOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateShipmentOrderMutation, CreateShipmentOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateShipmentOrderMutation, CreateShipmentOrderMutationVariables>(CreateShipmentOrderDocument, options);
}
export type CreateShipmentOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateShipmentOrderMutation, CreateShipmentOrderMutationVariables>;
export const DeleteShipmentOrderDocument = gql`
    mutation DeleteShipmentOrder($id: ID!) {
  Delete__Shipping_ShipmentOrder(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteShipmentOrderMutation__
 *
 * To run a mutation, you first call `useDeleteShipmentOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShipmentOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteShipmentOrderMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteShipmentOrderMutation(options: VueApolloComposable.UseMutationOptions<DeleteShipmentOrderMutation, DeleteShipmentOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteShipmentOrderMutation, DeleteShipmentOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteShipmentOrderMutation, DeleteShipmentOrderMutationVariables>(DeleteShipmentOrderDocument, options);
}
export type DeleteShipmentOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteShipmentOrderMutation, DeleteShipmentOrderMutationVariables>;
export const DeleteShipmentDocument = gql`
    mutation DeleteShipment($id: ID!) {
  Delete__Shipping_Shipment(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteShipmentMutation__
 *
 * To run a mutation, you first call `useDeleteShipmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShipmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteShipmentMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteShipmentMutation(options: VueApolloComposable.UseMutationOptions<DeleteShipmentMutation, DeleteShipmentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteShipmentMutation, DeleteShipmentMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteShipmentMutation, DeleteShipmentMutationVariables>(DeleteShipmentDocument, options);
}
export type DeleteShipmentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteShipmentMutation, DeleteShipmentMutationVariables>;
export const EditServiceColorDocument = gql`
    mutation EditServiceColor($id: ID!, $color: String) {
  service: Update__Shipping_ServiceLevel(input: {id: $id, color: $color}) {
    id
    easypost_name
    color
    order_arrive_by
    order_ready_by
    service
    shipper {
      id
      name
      easypost_name
    }
  }
}
    `;

/**
 * __useEditServiceColorMutation__
 *
 * To run a mutation, you first call `useEditServiceColorMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useEditServiceColorMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useEditServiceColorMutation({
 *   variables: {
 *     id: // value for 'id'
 *     color: // value for 'color'
 *   },
 * });
 */
export function useEditServiceColorMutation(options: VueApolloComposable.UseMutationOptions<EditServiceColorMutation, EditServiceColorMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<EditServiceColorMutation, EditServiceColorMutationVariables>>) {
  return VueApolloComposable.useMutation<EditServiceColorMutation, EditServiceColorMutationVariables>(EditServiceColorDocument, options);
}
export type EditServiceColorMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<EditServiceColorMutation, EditServiceColorMutationVariables>;
export const UpdateShipmentOrderDocument = gql`
    mutation UpdateShipmentOrder($input: Update__Shipping_ShipmentOrder__Input!) {
  order: Update__Shipping_ShipmentOrder(input: $input) {
    ...ShipmentOrderDetails
  }
}
    ${ShipmentOrderDetailsFragmentDoc}`;

/**
 * __useUpdateShipmentOrderMutation__
 *
 * To run a mutation, you first call `useUpdateShipmentOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShipmentOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateShipmentOrderMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShipmentOrderMutation(options: VueApolloComposable.UseMutationOptions<UpdateShipmentOrderMutation, UpdateShipmentOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateShipmentOrderMutation, UpdateShipmentOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateShipmentOrderMutation, UpdateShipmentOrderMutationVariables>(UpdateShipmentOrderDocument, options);
}
export type UpdateShipmentOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateShipmentOrderMutation, UpdateShipmentOrderMutationVariables>;
export const CreateCarrierAccountDocument = gql`
    mutation CreateCarrierAccount($input: Create__Shipping_Account__Input!) {
  Create__Shipping_Account(input: $input) {
    ...CarrierAccountFull
  }
}
    ${CarrierAccountFullFragmentDoc}`;

/**
 * __useCreateCarrierAccountMutation__
 *
 * To run a mutation, you first call `useCreateCarrierAccountMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateCarrierAccountMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateCarrierAccountMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateCarrierAccountMutation(options: VueApolloComposable.UseMutationOptions<CreateCarrierAccountMutation, CreateCarrierAccountMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateCarrierAccountMutation, CreateCarrierAccountMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateCarrierAccountMutation, CreateCarrierAccountMutationVariables>(CreateCarrierAccountDocument, options);
}
export type CreateCarrierAccountMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateCarrierAccountMutation, CreateCarrierAccountMutationVariables>;
export const DeleteCarrierAccountDocument = gql`
    mutation DeleteCarrierAccount($id: ID!) {
  Delete__Shipping_Account(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteCarrierAccountMutation__
 *
 * To run a mutation, you first call `useDeleteCarrierAccountMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCarrierAccountMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteCarrierAccountMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCarrierAccountMutation(options: VueApolloComposable.UseMutationOptions<DeleteCarrierAccountMutation, DeleteCarrierAccountMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteCarrierAccountMutation, DeleteCarrierAccountMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteCarrierAccountMutation, DeleteCarrierAccountMutationVariables>(DeleteCarrierAccountDocument, options);
}
export type DeleteCarrierAccountMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteCarrierAccountMutation, DeleteCarrierAccountMutationVariables>;
export const CreateCarrierServiceDocument = gql`
    mutation CreateCarrierService($input: Create__Shipping_ServiceLevel__Input!) {
  Create__Shipping_ServiceLevel(input: $input) {
    id
    service
    shipper {
      id
      name
    }
    color
  }
}
    `;

/**
 * __useCreateCarrierServiceMutation__
 *
 * To run a mutation, you first call `useCreateCarrierServiceMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateCarrierServiceMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateCarrierServiceMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateCarrierServiceMutation(options: VueApolloComposable.UseMutationOptions<CreateCarrierServiceMutation, CreateCarrierServiceMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateCarrierServiceMutation, CreateCarrierServiceMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateCarrierServiceMutation, CreateCarrierServiceMutationVariables>(CreateCarrierServiceDocument, options);
}
export type CreateCarrierServiceMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateCarrierServiceMutation, CreateCarrierServiceMutationVariables>;
export const CreateCarrierDocument = gql`
    mutation CreateCarrier($input: Create__Shipping_Carrier__Input!) {
  Create__Shipping_Carrier(input: $input) {
    id
    name
  }
}
    `;

/**
 * __useCreateCarrierMutation__
 *
 * To run a mutation, you first call `useCreateCarrierMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateCarrierMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateCarrierMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateCarrierMutation(options: VueApolloComposable.UseMutationOptions<CreateCarrierMutation, CreateCarrierMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateCarrierMutation, CreateCarrierMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateCarrierMutation, CreateCarrierMutationVariables>(CreateCarrierDocument, options);
}
export type CreateCarrierMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateCarrierMutation, CreateCarrierMutationVariables>;
export const Terms_ListDocument = gql`
    query Terms_List {
  clients_terms(input: {}) {
    ...Terms_List
  }
}
    ${Terms_ListFragmentDoc}`;

/**
 * __useTerms_ListQuery__
 *
 * To run a query within a Vue component, call `useTerms_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTerms_ListQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTerms_ListQuery();
 */
export function useTerms_ListQuery(options: VueApolloComposable.UseQueryOptions<Terms_ListQuery, Terms_ListQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<Terms_ListQuery, Terms_ListQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<Terms_ListQuery, Terms_ListQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<Terms_ListQuery, Terms_ListQueryVariables>(Terms_ListDocument, {}, options);
}
export type Terms_ListQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<Terms_ListQuery, Terms_ListQueryVariables>;
export const TermsDocument = gql`
    query Terms {
  terms: clients_terms(input: {}) {
    ...Terms_List
  }
}
    ${Terms_ListFragmentDoc}`;

/**
 * __useTermsQuery__
 *
 * To run a query within a Vue component, call `useTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTermsQuery();
 */
export function useTermsQuery(options: VueApolloComposable.UseQueryOptions<TermsQuery, TermsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TermsQuery, TermsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TermsQuery, TermsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TermsQuery, TermsQueryVariables>(TermsDocument, {}, options);
}
export type TermsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TermsQuery, TermsQueryVariables>;
export const TermsForAutoCompleteDocument = gql`
    query TermsForAutoComplete {
  terms: clients_terms(input: {limit: 1000, order_by: "name"}) {
    id
    title: name
    subtitle: description
    default_terms_for_new_client {
      id
    }
  }
}
    `;

/**
 * __useTermsForAutoCompleteQuery__
 *
 * To run a query within a Vue component, call `useTermsForAutoCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermsForAutoCompleteQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTermsForAutoCompleteQuery();
 */
export function useTermsForAutoCompleteQuery(options: VueApolloComposable.UseQueryOptions<TermsForAutoCompleteQuery, TermsForAutoCompleteQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TermsForAutoCompleteQuery, TermsForAutoCompleteQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TermsForAutoCompleteQuery, TermsForAutoCompleteQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TermsForAutoCompleteQuery, TermsForAutoCompleteQueryVariables>(TermsForAutoCompleteDocument, {}, options);
}
export type TermsForAutoCompleteQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TermsForAutoCompleteQuery, TermsForAutoCompleteQueryVariables>;
export const CreateTermsDocument = gql`
    mutation CreateTerms($name: String!, $description: String, $days: PositiveInteger) {
  term: Create__Clients_Terms(
    input: {name: $name, description: $description, days: $days}
  ) {
    id
    days
    description
    name
  }
}
    `;

/**
 * __useCreateTermsMutation__
 *
 * To run a mutation, you first call `useCreateTermsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateTermsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateTermsMutation({
 *   variables: {
 *     name: // value for 'name'
 *     description: // value for 'description'
 *     days: // value for 'days'
 *   },
 * });
 */
export function useCreateTermsMutation(options: VueApolloComposable.UseMutationOptions<CreateTermsMutation, CreateTermsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateTermsMutation, CreateTermsMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateTermsMutation, CreateTermsMutationVariables>(CreateTermsDocument, options);
}
export type CreateTermsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateTermsMutation, CreateTermsMutationVariables>;
export const UpdateTermDocument = gql`
    mutation UpdateTerm($input: Update__Clients_Terms__Input!) {
  Update__Clients_Terms(input: $input) {
    id
  }
}
    `;

/**
 * __useUpdateTermMutation__
 *
 * To run a mutation, you first call `useUpdateTermMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTermMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateTermMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTermMutation(options: VueApolloComposable.UseMutationOptions<UpdateTermMutation, UpdateTermMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateTermMutation, UpdateTermMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateTermMutation, UpdateTermMutationVariables>(UpdateTermDocument, options);
}
export type UpdateTermMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateTermMutation, UpdateTermMutationVariables>;
export const DeleteTermDocument = gql`
    mutation DeleteTerm($id: ID!) {
  term: Delete__Clients_Terms(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteTermMutation__
 *
 * To run a mutation, you first call `useDeleteTermMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTermMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteTermMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTermMutation(options: VueApolloComposable.UseMutationOptions<DeleteTermMutation, DeleteTermMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteTermMutation, DeleteTermMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteTermMutation, DeleteTermMutationVariables>(DeleteTermDocument, options);
}
export type DeleteTermMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteTermMutation, DeleteTermMutationVariables>;
export const GetUsersFullDocument = gql`
    query GetUsersFull($input: ManyQueryInput!) {
  users: users_users(input: $input) {
    ...FullUser
  }
}
    ${FullUserFragmentDoc}`;

/**
 * __useGetUsersFullQuery__
 *
 * To run a query within a Vue component, call `useGetUsersFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersFullQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetUsersFullQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetUsersFullQuery(variables: GetUsersFullQueryVariables | VueCompositionApi.Ref<GetUsersFullQueryVariables> | ReactiveFunction<GetUsersFullQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetUsersFullQuery, GetUsersFullQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetUsersFullQuery, GetUsersFullQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetUsersFullQuery, GetUsersFullQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetUsersFullQuery, GetUsersFullQueryVariables>(GetUsersFullDocument, variables, options);
}
export type GetUsersFullQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetUsersFullQuery, GetUsersFullQueryVariables>;
export const GetUsersForAutofillDocument = gql`
    query GetUsersForAutofill {
  users: users_users(input: {limit: 2000, order_by: "first_name"}) {
    id
    title: full_name
    subtitle: initials
    profile {
      id
      color
    }
  }
}
    `;

/**
 * __useGetUsersForAutofillQuery__
 *
 * To run a query within a Vue component, call `useGetUsersForAutofillQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForAutofillQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetUsersForAutofillQuery();
 */
export function useGetUsersForAutofillQuery(options: VueApolloComposable.UseQueryOptions<GetUsersForAutofillQuery, GetUsersForAutofillQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetUsersForAutofillQuery, GetUsersForAutofillQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetUsersForAutofillQuery, GetUsersForAutofillQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetUsersForAutofillQuery, GetUsersForAutofillQueryVariables>(GetUsersForAutofillDocument, {}, options);
}
export type GetUsersForAutofillQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetUsersForAutofillQuery, GetUsersForAutofillQueryVariables>;
export const UsersForAutofillDocument = gql`
    query UsersForAutofill {
  reps: users_users(input: {limit: 1000, order_by: "initials"}) {
    id
    title: initials
    subtitle: full_name
  }
}
    `;

/**
 * __useUsersForAutofillQuery__
 *
 * To run a query within a Vue component, call `useUsersForAutofillQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersForAutofillQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUsersForAutofillQuery();
 */
export function useUsersForAutofillQuery(options: VueApolloComposable.UseQueryOptions<UsersForAutofillQuery, UsersForAutofillQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UsersForAutofillQuery, UsersForAutofillQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UsersForAutofillQuery, UsersForAutofillQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<UsersForAutofillQuery, UsersForAutofillQueryVariables>(UsersForAutofillDocument, {}, options);
}
export type UsersForAutofillQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<UsersForAutofillQuery, UsersForAutofillQueryVariables>;
export const Update_User_ProfileDocument = gql`
    mutation Update_User_Profile($input: Update__Profile_Profile__Input!) {
  Update__Profile_Profile(input: $input) {
    id
    commission_percentage
  }
}
    `;

/**
 * __useUpdate_User_ProfileMutation__
 *
 * To run a mutation, you first call `useUpdate_User_ProfileMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_User_ProfileMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdate_User_ProfileMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_User_ProfileMutation(options: VueApolloComposable.UseMutationOptions<Update_User_ProfileMutation, Update_User_ProfileMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<Update_User_ProfileMutation, Update_User_ProfileMutationVariables>>) {
  return VueApolloComposable.useMutation<Update_User_ProfileMutation, Update_User_ProfileMutationVariables>(Update_User_ProfileDocument, options);
}
export type Update_User_ProfileMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<Update_User_ProfileMutation, Update_User_ProfileMutationVariables>;
export const GridGetWorkOrdersDocument = gql`
    query GridGetWorkOrders($input: ManyQueryInput!) {
  work_orders: work_order_work_orders(input: $input) {
    ...WorkOrderList
    annotations {
      name
      value
    }
  }
}
    ${WorkOrderListFragmentDoc}`;

/**
 * __useGridGetWorkOrdersQuery__
 *
 * To run a query within a Vue component, call `useGridGetWorkOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGridGetWorkOrdersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGridGetWorkOrdersQuery({
 *   input: // value for 'input'
 * });
 */
export function useGridGetWorkOrdersQuery(variables: GridGetWorkOrdersQueryVariables | VueCompositionApi.Ref<GridGetWorkOrdersQueryVariables> | ReactiveFunction<GridGetWorkOrdersQueryVariables>, options: VueApolloComposable.UseQueryOptions<GridGetWorkOrdersQuery, GridGetWorkOrdersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GridGetWorkOrdersQuery, GridGetWorkOrdersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GridGetWorkOrdersQuery, GridGetWorkOrdersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GridGetWorkOrdersQuery, GridGetWorkOrdersQueryVariables>(GridGetWorkOrdersDocument, variables, options);
}
export type GridGetWorkOrdersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GridGetWorkOrdersQuery, GridGetWorkOrdersQueryVariables>;
export const GridGetWorkOrderItemsDocument = gql`
    query GridGetWorkOrderItems($input: ManyQueryInput!) {
  items: work_order_work_order_items(input: $input) {
    ...WorkOrderItem
    annotations {
      name
      value
    }
  }
}
    ${WorkOrderItemFragmentDoc}`;

/**
 * __useGridGetWorkOrderItemsQuery__
 *
 * To run a query within a Vue component, call `useGridGetWorkOrderItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGridGetWorkOrderItemsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGridGetWorkOrderItemsQuery({
 *   input: // value for 'input'
 * });
 */
export function useGridGetWorkOrderItemsQuery(variables: GridGetWorkOrderItemsQueryVariables | VueCompositionApi.Ref<GridGetWorkOrderItemsQueryVariables> | ReactiveFunction<GridGetWorkOrderItemsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GridGetWorkOrderItemsQuery, GridGetWorkOrderItemsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GridGetWorkOrderItemsQuery, GridGetWorkOrderItemsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GridGetWorkOrderItemsQuery, GridGetWorkOrderItemsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GridGetWorkOrderItemsQuery, GridGetWorkOrderItemsQueryVariables>(GridGetWorkOrderItemsDocument, variables, options);
}
export type GridGetWorkOrderItemsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GridGetWorkOrderItemsQuery, GridGetWorkOrderItemsQueryVariables>;
export const GetWorkOrderForPdfDocument = gql`
    query GetWorkOrderForPDF($id: ID!) {
  work_order_work_order(input: {id: $id}) {
    ...WorkOrderForPdf
  }
}
    ${WorkOrderForPdfFragmentDoc}`;

/**
 * __useGetWorkOrderForPdfQuery__
 *
 * To run a query within a Vue component, call `useGetWorkOrderForPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderForPdfQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetWorkOrderForPdfQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetWorkOrderForPdfQuery(variables: GetWorkOrderForPdfQueryVariables | VueCompositionApi.Ref<GetWorkOrderForPdfQueryVariables> | ReactiveFunction<GetWorkOrderForPdfQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetWorkOrderForPdfQuery, GetWorkOrderForPdfQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetWorkOrderForPdfQuery, GetWorkOrderForPdfQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetWorkOrderForPdfQuery, GetWorkOrderForPdfQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetWorkOrderForPdfQuery, GetWorkOrderForPdfQueryVariables>(GetWorkOrderForPdfDocument, variables, options);
}
export type GetWorkOrderForPdfQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetWorkOrderForPdfQuery, GetWorkOrderForPdfQueryVariables>;
export const CreateWorkOrderDocument = gql`
    mutation CreateWorkOrder($input: Create__WorkOrder_WorkOrder__Input!) {
  order: Create__WorkOrder_WorkOrder(input: $input) {
    ...WorkOrderList
  }
}
    ${WorkOrderListFragmentDoc}`;

/**
 * __useCreateWorkOrderMutation__
 *
 * To run a mutation, you first call `useCreateWorkOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateWorkOrderMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkOrderMutation(options: VueApolloComposable.UseMutationOptions<CreateWorkOrderMutation, CreateWorkOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateWorkOrderMutation, CreateWorkOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateWorkOrderMutation, CreateWorkOrderMutationVariables>(CreateWorkOrderDocument, options);
}
export type CreateWorkOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateWorkOrderMutation, CreateWorkOrderMutationVariables>;
export const CreateWorkOrderItemDocument = gql`
    mutation CreateWorkOrderItem($input: [Create__WorkOrder_WorkOrderItems__Input!]!) {
  Create__WorkOrder_WorkOrderItems(input: $input) {
    ...WorkOrderItem
  }
}
    ${WorkOrderItemFragmentDoc}`;

/**
 * __useCreateWorkOrderItemMutation__
 *
 * To run a mutation, you first call `useCreateWorkOrderItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkOrderItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateWorkOrderItemMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkOrderItemMutation(options: VueApolloComposable.UseMutationOptions<CreateWorkOrderItemMutation, CreateWorkOrderItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateWorkOrderItemMutation, CreateWorkOrderItemMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateWorkOrderItemMutation, CreateWorkOrderItemMutationVariables>(CreateWorkOrderItemDocument, options);
}
export type CreateWorkOrderItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateWorkOrderItemMutation, CreateWorkOrderItemMutationVariables>;
export const DeleteWorkOrderItemDocument = gql`
    mutation DeleteWorkOrderItem($id: ID!) {
  Delete__WorkOrder_WorkOrderItems(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteWorkOrderItemMutation__
 *
 * To run a mutation, you first call `useDeleteWorkOrderItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkOrderItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteWorkOrderItemMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkOrderItemMutation(options: VueApolloComposable.UseMutationOptions<DeleteWorkOrderItemMutation, DeleteWorkOrderItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteWorkOrderItemMutation, DeleteWorkOrderItemMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteWorkOrderItemMutation, DeleteWorkOrderItemMutationVariables>(DeleteWorkOrderItemDocument, options);
}
export type DeleteWorkOrderItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteWorkOrderItemMutation, DeleteWorkOrderItemMutationVariables>;
export const GetPrintNodePrintersDocument = gql`
    query GetPrintNodePrinters {
  printers: PrintNode_GetPrinters {
    id
    name
    description
    state
    computer {
      id
      name
      hostname
      state
    }
  }
}
    `;

/**
 * __useGetPrintNodePrintersQuery__
 *
 * To run a query within a Vue component, call `useGetPrintNodePrintersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintNodePrintersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetPrintNodePrintersQuery();
 */
export function useGetPrintNodePrintersQuery(options: VueApolloComposable.UseQueryOptions<GetPrintNodePrintersQuery, GetPrintNodePrintersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetPrintNodePrintersQuery, GetPrintNodePrintersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetPrintNodePrintersQuery, GetPrintNodePrintersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetPrintNodePrintersQuery, GetPrintNodePrintersQueryVariables>(GetPrintNodePrintersDocument, {}, options);
}
export type GetPrintNodePrintersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetPrintNodePrintersQuery, GetPrintNodePrintersQueryVariables>;
export const CreatePrintJobDocument = gql`
    mutation CreatePrintJob($content: String!, $contentType: PRINTNODE_CONTENT_TYPE!, $expireAfter: Int, $printerId: ID!, $qty: Int, $source: String!, $title: String!, $options: PrintNodeOptionsInput) {
  job: PrintNode_CreatePrintJob(
    input: {content: $content, contentType: $contentType, expireAfter: $expireAfter, printerId: $printerId, qty: $qty, source: $source, title: $title}
    options: $options
  )
}
    `;

/**
 * __useCreatePrintJobMutation__
 *
 * To run a mutation, you first call `useCreatePrintJobMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrintJobMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreatePrintJobMutation({
 *   variables: {
 *     content: // value for 'content'
 *     contentType: // value for 'contentType'
 *     expireAfter: // value for 'expireAfter'
 *     printerId: // value for 'printerId'
 *     qty: // value for 'qty'
 *     source: // value for 'source'
 *     title: // value for 'title'
 *     options: // value for 'options'
 *   },
 * });
 */
export function useCreatePrintJobMutation(options: VueApolloComposable.UseMutationOptions<CreatePrintJobMutation, CreatePrintJobMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreatePrintJobMutation, CreatePrintJobMutationVariables>>) {
  return VueApolloComposable.useMutation<CreatePrintJobMutation, CreatePrintJobMutationVariables>(CreatePrintJobDocument, options);
}
export type CreatePrintJobMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreatePrintJobMutation, CreatePrintJobMutationVariables>;
