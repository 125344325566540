import {
  ColDef,
  DateFilterModel,
  ICombinedSimpleModel,
  IServerSideGetRowsRequest,
  ISimpleFilterModel,
  NumberFilterModel,
  TextFilterModel
} from 'ag-grid-community'
import { GetFieldFromColumnId } from '@/lib/agGridColumnConfiguration'
import { SetFilterModel } from 'ag-grid-enterprise/dist/lib/setFilter/setFilterModel'
import store from '@/store/store'
import { ReturnPossibleMoneyValueFormatted } from '@/lib/helpers'
import { Choices_Tag, QueryFilter } from '@/models/generated/graphql/ErpBackend.ts'
import { ConvertDateToDateTimeRange, GetUtcDateTime } from '@/lib/Dates'

interface IGridFilterObject {
  [key: string]: TextFilterModel |
    DateFilterModel |
    NumberFilterModel |
    SetFilterModel |
    ICombinedSimpleModel<ISimpleFilterModel>
}

interface GraphQLSpecField {
  name: string
  description?: string
  type: { name: string }
  isDeprecated: boolean
  deprecationReason?: string
}

/**
 * What this does is it tries to gather all of the filters (gridFilters coming from AG Grid) and componentFilters
 * coming from the component (dur), the component ones SHOULD in theory all be set for gql but the ag grid ones
 * need some massaging to be django compatible
 * @param gridFilters
 * @param componentFilters
 * @param columns
 * @constructor
 */

export function GetFilters (gridFilters: IGridFilterObject, componentFilters: QueryFilter[], columns: ColDef[]) {
  let filters: QueryFilter[] = []
  const prepFilters: QueryFilter[] = []
  const excludes: QueryFilter[] = []

  const testStatusLogic = (model: SetFilterModel) => {
    const testFilters = GetTestStatusFilters(model.values)
    if (testFilters.length === 1) {
      prepFilters.push(testFilters[0])
    } else if (testFilters.length > 1) {
      excludes.push(...testFilters)
    }
  }
  const orderItemLogic = (model: TextFilterModel, field: string) => {
    const term = model.filter!
    // we split the transaction up because it is either
    // '12-12' --> [12],[12]
    // or
    // '12(- or no -)' --> [12]
    const split: string[] = term.split('-')
    if (split.length > 1) {
      prepFilters.push(
        { key: `${field}__transaction__id`, value: split[0] },
        { key: `${field}__line_number`, value: split[1] }
      )
    } else {
      prepFilters.push(
        { key: `${field}__transaction__id`, value: split[0] }
      )
    }
  }
  const dateLogic = (model: TextFilterModel, field: string) => {
    /*
    this one is super "fun" because of timezones. So what we need to do with "DateTime" fields is if they only
    supply a date (not date TIME) we need to convert it to a date time that would comply with their request AND
    convert to UTC 0 because that is how we should be storing the times (luckily Postgres forces this anyways).
    so first we check to see if the field they are filtering by is a "DateTime" field and then no matter what we
    convert it to UTC. After that we need to see what type of filtering they are doing:
      - After
      - Before
      - In Range
      - Exactly
    Essentially how we would treat the bottom two is a "range" filter and here is why:
    If they want data that was created on 10/26/1991 in a -6:00 TZ from a "DateTime" field they would really only
    supply that date BUT from a code perspective what they are saying is "I want data created after
    10/26/1991 - 6:00AM but before 10/27/1991 - 6:00AM. Unless they do select a time (but we don't have that
    implemented yet). Luckily moment does the heavy lifting for us once again.
    */
    const schemaField: GraphQLSpecField | null = store.getters['data/dateSchemaField'](field)
    if (schemaField === null) throw new Error('It is taking longer to receive necessary data, wait a few seconds and try again.')
    const isDateTime: boolean = schemaField.type.name === 'DateTime'

    /* Now lets focus in on what kind of filter the user is using */
    const filter: IGridToGqlFilterStorageObject = FILTER_OPTIONS.find(f => f.grid === model.type)!
    if (filter.gql === 'in-range') {
      /*
        If the filter is one of the two options that we need to make a valid range filter, we'll first start with
        the 'in-range' one because that will be easier as it gives us the values we need off the bat in the format:
        "startDate - endDate" or "dd/dd/dddd - dd/dd/dddd"

        We start off by getting the split value, because we'll need it either way and optionally get utc time if
        needed.
      */
      const split: string[] = model.filter!.split(' - ')
      const startDate: string = isDateTime ? GetUtcDateTime(split[0]).format() : split[0]
      const endDate: string = isDateTime ? GetUtcDateTime(split[1]).format() : split[1]

      /* Now that we have the proper dates or datetimes we can use them in the our range */
      const startDateFilter: QueryFilter = { key: `${field}__gte`, value: startDate }
      const endDateFilter: QueryFilter = { key: `${field}__lte`, value: endDate }
      prepFilters.push(startDateFilter, endDateFilter)
      /* Finished */
    } else if (filter.gql === '__iexact') {
      /*
        This one is going to be tougher because we are only given one date when we essentially need two date times
        due to time zone issues. If a user wants one whole day in utcOffset = -6 that means we need all data within
        startOfDay - 6hrs AND endOfDay - 6hrs
      */
      if (isDateTime) {
        /* The logic for this is a nice one liner but to keep it all contained it has been extrapolated to the
           Date.ts module
         */
        const range: { start: string, end: string } = ConvertDateToDateTimeRange(model.filter!)
        const startQueryFilter: QueryFilter = { key: `${field}__gte`, value: range.start }
        const endQueryFilter: QueryFilter = { key: `${field}__lte`, value: range.end }
        prepFilters.push(startQueryFilter, endQueryFilter)
      } else {
        /* If not using DateTime this is much easier */
        const queryFilter: QueryFilter = { key: `${field}`, value: model.filter! }
        prepFilters.push(queryFilter)
      }
    } else {
      /* If we can just pass the gql filter with column onto the query, i.e. it is a gte or lte filter */
      const queryFilter: QueryFilter = { key: `${field}${filter.gql}`, value: model.filter! }
      prepFilters.push(queryFilter)
    }
  }

  if (Object.keys(gridFilters).length === 0 && componentFilters === null) {
    /* If there are no filters applied */
  } else if (Object.keys(gridFilters).length === 0) {
    /* if there are any component filters but not grid filters */
    return { filters: componentFilters, excludes: [] }
  } else {
    /* If there are grid filters */
    for (const key in gridFilters) { // key is the column being filtered
      const columnFieldName: string = DoTheDjango(GetFieldFromColumnId(key, columns)) // the graphql field name
      const model = gridFilters[key] // the filter model used

      /* Go through unique filter sets */
      if (key === 'testStatus') {
        testStatusLogic(model as SetFilterModel)
      } else if (columnFieldName === 'purchases_items_details' || columnFieldName === 'sales_items_details') {
        orderItemLogic(model as TextFilterModel, columnFieldName)
      } else if (columnFieldName.includes('contact__full_name')) {
        prepFilters.push(GetContactFullNameFilter(gridFilters[key] as TextFilterModel, columnFieldName))
      } else if (columnFieldName.includes('pretty')) { // filtering by money
        prepFilters.push({ key: columnFieldName.replace('pretty', 'amount'), value: (gridFilters[key] as TextFilterModel).filter })
      } else if (/(\d\d\d\d-\d\d-\d\d)|(\d\d:\d\d:\d\d)/.test((model as TextFilterModel).filter!)) {
        dateLogic(model as TextFilterModel, columnFieldName)
      } else {
        /* all other filters */
        if ((model as ICombinedSimpleModel<ISimpleFilterModel>).operator) {
          const filterModel: ICombinedSimpleModel<ISimpleFilterModel> = model as ICombinedSimpleModel<ISimpleFilterModel>
          const conditions = GetConditions(filterModel)
          prepFilters.push(GetCombinedFilter(conditions, columnFieldName, GetQueryFilterOperation(filterModel.operator)))
        } else {
          const filterModel = model as TextFilterModel
          const value: string = ReturnPossibleMoneyValueFormatted(filterModel.filter!)
          prepFilters.push(
            {
              key: GetFullKeyFilter(columnFieldName, filterModel.type),
              value: value
            })
        }
      }
      filters = prepFilters.concat(componentFilters)
    }
  }
  return { filters: filters, excludes: excludes }
}

enum QUERYFILTEROPERATION {
  'AND' = 'and',
  'OR' = 'or'
}

export function GetCombinedFilter (conditions: TextFilterModel[], column: string, operation: QUERYFILTEROPERATION): QueryFilter {
  const condition: TextFilterModel = conditions.shift()!
  const filter: QueryFilter = { key: column + TranslateFilterTypeFromAgGridType(condition.type), value: condition.filter }
  if (conditions.length === 0) {
    return filter
  } else {
    filter[operation] = GetCombinedFilter(conditions, column, operation)
    return filter
  }
}

export function GetQueryFilterOperation (operator: string): QUERYFILTEROPERATION {
  return operator === 'and' ? QUERYFILTEROPERATION.AND : QUERYFILTEROPERATION.OR
}

export function GetTestStatusFilters (statuses: string[]): QueryFilter[] {
  // make sure there are no duplicates
  const statusesSet = new Set(statuses)
  // match up with valid tags
  const matchedDbStatuses: Choices_Tag[] = store.state.data.tags.filter((t: Choices_Tag) => !statusesSet.has(t.tag))
  if (matchedDbStatuses.length > 1) {
    // going to store the valid filters in this array
    const gqlFilters: QueryFilter[] = []
    // create typed variable to loop over
    let status: Choices_Tag
    for (status of matchedDbStatuses) {
      gqlFilters.push({ key: 'test_status__tag', value: status.tag })
    }
    return gqlFilters
  } else if (matchedDbStatuses.length === 1) {
    return [{ key: 'test_status_tag', value: matchedDbStatuses[0].tag }]
  }
  return []
}

export function GetConditions (model: ICombinedSimpleModel<ISimpleFilterModel>) {
  return [model.condition1, model.condition2]
}

export function GetOrdering (request: IServerSideGetRowsRequest, columns: ColDef[]): string[] {
  const ordering = []
  if (request.sortModel.length > 0) {
    for (const model of request.sortModel) {
      const field = DoTheDjango(GetFieldFromColumnId(model.colId, columns))
      ordering.push(model.sort === 'asc' ? field : `-${field}`)
    }
  }
  return ordering
}

/**
 * This method accepts a string (an ag grid field) and returns a django ready field for filters
 * @example
 * client.name ---> client__name
 * @param s
 * @returns string
 */
export function DoTheDjango (s: string): string {
  return s.replace(/\./g, '__')
}

export interface IGridToGqlFilterStorageObject {
  grid: string,
  gql: string,
  types: string[],
  dateText?: string,
  hint?: string
}

export const FILTER_OPTIONS: IGridToGqlFilterStorageObject[] = [
  { grid: 'Greater Than or Equal To', gql: '__gte', types: ['money', 'date'], dateText: 'After' },
  { grid: 'Greater Than', gql: '__gt', types: ['money'] },
  { grid: 'Less Than or Equal To', gql: '__lte', types: ['money', 'date'], dateText: 'Before' },
  { grid: 'Less Than', gql: '__lt', types: ['money'] },
  { grid: 'Contains', gql: '__icontains', types: ['string'] },
  { grid: 'In Range', gql: 'in-range', types: ['date'], dateText: 'In Range' },
  { grid: 'Starts With', gql: '__istartswith', types: ['string'] },
  { grid: 'Ends With', gql: '__iendswith', types: ['string'] },
  { grid: 'Equals', gql: '', types: ['money'] },
  { grid: 'Is', gql: '__iexact', types: ['all', 'date'], dateText: 'Exactly' }
]

export function TranslateFilterTypeFromAgGridType (type: string): string {
  // return the gql filter that matches the grid filter
  return FILTER_OPTIONS.find(f => (f.grid.toLowerCase() === type.toLowerCase()))?.gql ?? ''
}

export function GetFullKeyFilter (field: string, type: string): string {
  return `${field}${TranslateFilterTypeFromAgGridType(type)}`
}

/**
 * Since contact full name is a computed field what needs to happen here
 * @param filterModel
 * @param columnFieldName
 * @constructor
 */
export function GetContactFullNameFilter (filterModel: TextFilterModel, columnFieldName: string): QueryFilter {
  const filter: string = filterModel.filter!
  const type: string = filterModel.type
  const firstNameField: string = columnFieldName.replace('full_name', 'first_name')
  const lastNameField: string = columnFieldName.replace('full_name', 'last_name')

  // if the filter includes a space --> they are most likely searching by full name so we need first + last
  if (filter.includes(' ')) {
    const split: string[] = filter.split(' ')
    const firstNameValue: string = split[0]
    const lastNameValue: string = split[1]
    return { key: GetFullKeyFilter(firstNameField, type), value: firstNameValue, and: { key: GetFullKeyFilter(lastNameField, type), value: lastNameValue } }
  } else {
    return { key: GetFullKeyFilter(lastNameField, type), value: filter, or: { key: GetFullKeyFilter(firstNameField, type), value: filter } }
  }
}
