<template>
  <v-autocomplete
    v-model="localContact"
    ref="autocompleteContact"
    :search-input.sync="search"
    :items="contacts"
    :disabled="disabled"
    :loading="loading"
    :filter="contactFilter"
    :id="elId"
    attach
    placeholder="Optional"
    :hint="hint"
    persistent-hint
    item-text="full_name"
    return-object
    append-icon="fal fa-times"
    @click:append="reset"
  >
    <template #prepend>
      <slot name="prepend-icon"></slot>
    </template>
    <template #label>
      <slot name="label">Contact</slot>
    </template>
    <!-- Message Slot -->
    <template v-slot:message="{ message, key }">
      <span :key="key" v-html="message"/>
    </template>
    <!-- Item Slot -->
    <template v-slot:item="{ item }">
        <v-list-tile-content>
          <v-list-tile-title>{{ item.full_name }}</v-list-tile-title>
          <v-list-tile-sub-title>{{ owner(item) }}</v-list-tile-sub-title>
        </v-list-tile-content>
    </template>
    <!-- No-Data Slot -->
    <template
      v-slot:no-data
    >
      <v-list-tile v-if="loading">
        <v-list-tile-title>
          Loading your contacts!
        </v-list-tile-title>
      </v-list-tile>
      <v-list-tile v-else-if="searchLength < 3">
        <v-list-tile-title>
          Start typing...
        </v-list-tile-title>
      </v-list-tile>
      <v-list-tile v-else>
        <v-list-tile-title>
          No contact found.
        </v-list-tile-title>
      </v-list-tile>
    </template>
    <!-- Prepend-Item slot -->
    <template v-slot:prepend-item v-if="!$apollo.loading && contacts.length === 0 && searchLength > 2">
      <slot name="prepend-item" v-bind:reference="reference"/>
    </template>
  </v-autocomplete>
</template>

<script>
import { GET_CONTACT__FULL, GET_CONTACTS__LIST } from '@/api/graphql/Constants/Contacts'
export default {
  name: 'contacts',
  props: {
    contact: {
      type: Object,
      required: true
    },
    client: {
      type: String,
      required: true
    },
    elId: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    hint: {
      required: false,
      type: String,
      default: '<span style="color: #311B92; background-color: #c0c0c0; border-radius: 2px; padding: 0px 10px 0px 10px; font-weight: bold;">Start here!</span>'
    }
  },
  computed: {
    localContact: {
      get () {
        return this.contact
      },
      set (value) {
        if (value?.id !== this.localContact?.id) {
          this.$emit('updated', { value: value })
        }
      }
    },

    loading () {
      return this.$apollo?.loading || this.$apollo?.queries?.contacts?.loading
    },

    searchLength () {
      return this.search?.length ?? 0
    },

    activatedSearch () {
      return this.search?.length > 2 ?? 0
    }
  },
  watch: {
    search: function (newValue, oldValue) {
      newValue = newValue === null ? '' : newValue
      oldValue = oldValue === null ? '' : oldValue
      if (newValue?.length < oldValue?.length && !newValue.includes(' ')) {
        this.searched = false
      }
      // this.search = newValue
      if (newValue?.length > 2 && (!this.searched || this.contacts.length === 0)) {
        this.skip = false
        this.searched = true
      }
    },
    contacts (value) {
      this.skip = true
    },
    contact: async function (value) {
      if (value.id) {
        this.skip = true
        this.searched = true
        const index = this.contacts.findIndex(c => {
          return c.id === value.id
        })
        if (index === -1) {
          this.contacts.push(value)
        } else {
          this.localContact = this.contacts[index]
        }
      }
    },
    client: async function (value) {
      if (`${value}` !== `${this.localContact?.owner?.id}`) {
        const response = await this.$apollo.query({
          query: GET_CONTACTS__LIST,
          variables: {
            input: {
              filters: [{ key: 'owner__id', value: value }]
            }
          }
        })
        this.contacts = response.data?.['clients_contacts']
        if (this.contacts?.length > 1) {
          this.localContact = {}
        } else if (this.contacts.length === 1) {
          this.localContact = this.contacts[0]
        }
      }
    }
  },
  data () {
    return {
      contacts: [],
      reference: null,
      search: '',
      searched: false,
      skip: true
    }
  },
  apollo: {
    contacts: {
      query: GET_CONTACTS__LIST,
      variables () {
        return {
          input: {
            filters: [
              {
                key: 'first_name__istartswith',
                value: this.search,
                or: {
                  key: 'last_name__istartswith',
                  value: this.search
                }
              },
              { key: 'owner__is_our_client', value: true }
            ]
          }
        }
      },
      skip () {
        return this.skip || this.search.includes(' ') || !this.search
      },
      update: data => data.clients_contacts
    }
  },
  methods: {
    contactFilter (item, query) {
      const fullName = item.full_name?.toLowerCase() ?? ''
      const search = query.toLowerCase()
      return fullName.indexOf(search) > -1
    },

    reset () {
      this.contacts = []
      this.localContact = {}
      this.search = ''
    },

    owner (contact) {
      return contact.owner?.name ?? ''
    }
  },
  async beforeMount () {
    if (this.localContact?.id && this.contacts.length === 0) {
      const response = await this.$apollo.query({
        query: GET_CONTACT__FULL,
        variables: {
          input: {
            id: this.localContact.id
          }
        }
      })
      this.contacts = [response.data?.['clients_contact']]
      if (this.contacts.length === 1) {
        this.localContact = this.contacts[0]
      }
    }
  },
  mounted () {
    if (this.elId && (!this.contact?.id ?? true)) {
      this.$nextTick(() => {
        document.getElementById(this.elId)?.focus()
      })
    }
    this.reference = this.$refs.autocompleteContact
  }
}
</script>
