<template>
  <v-btn
    style="margin-bottom: 7px;"
    small
    color="info"
    @click="saveConfig"
  >Save Config</v-btn>
</template>

<script>
import Vue from 'vue'
import { GridEvents } from '../../store/gridEvents.js'
export default Vue.extend({
  name: 'saveConfigStatusBarButton',
  data () {
    return {}
  },
  methods: {
    saveConfig () {
      GridEvents.$emit('saveColumnConfig', this.params.api)
    }
  }
})
</script>

<style scoped>

</style>
