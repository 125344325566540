import gql from 'graphql-tag'
import { FRAGMENT__BASIC_ITEM as BasicItem } from '@/api/graphql/Constants/Inventory'
import { FRAGMENT__USER_MINIMAL } from '@/api/graphql/Constants/Users'
import {
  FRAGMENT__SHIPMENT_ORDER_DETAILS
} from '@/api/graphql/Constants/Shipments'
import { FRAGMENT__MONEY } from '@/api/graphql/Constants/TERMS'
import { FRAGMENT__CONTACT__LIST } from '@/api/graphql/Constants/Contacts'

/* FRAGMENTS */

export const FRAGMENT__PURCHASE_RMA_ITEM = gql`
  fragment PurchaseRmaItem on RMA_PurchaseRMAItems {
    id
    item: returned_part {
      ...InventoryItem
      purchase: purchases_items_details {
        id
        line_number
        transaction {
          id
        }
      }
      part {
        id
        pn
        description
      }
    }
    credit
    rmaTested: tested_condition {
      id
      choice
    }
  }
  ${BasicItem}
`

export const FRAGMENT__SALE_RMA_ITEM = gql`
  fragment SaleRmaItem on RMA_RMAItems {
    id
    tested_condition {
      id
      choice
      sub_choice
    }
    creation_date
    updated_date
    item: returned_part {
      ...InventoryItem
      sale: sales_items_details {
        id
        line_number
        transaction {
          id
        }
      }
      part {
        id
        pn
        description
      }
    }
  }
  ${BasicItem}
`

// QUERIES

export const GET_RMA_FOR_PDF = gql`
  query GetRmaForPdf ($id: ID!) {
    rma_rma (input: { id: $id }) {
      id
      shipment_order {
        id
        blind
        purchaser_contact { ...Contact_List }
        purchaser_address {
          id
          street_1
          street_2
          city
          state
          country {
            code
            name
          }
          zip_code
          company {
            name
          }
          client {
            id
            name
          }
          default_contact {
            id
            full_name
          }
        }
        ship_from_contact { ...Contact_List }
        ship_from_address {
          id
          street_1
          street_2
          city
          state
          country {
            code
            name
          }
          zip_code
          company {
            name
          }
          client {
            id
            name
          }
          default_contact {
            id
            full_name
          }
        }
        ship_to_contact { ...Contact_List }
        ship_to_address {
          id
          street_1
          street_2
          city
          state
          country {
            code
            name
          }
          zip_code
          client {
            id
            name
          }
          company {
            name
          }
          default_contact {
            id
            full_name
          }
        }
      }
      st {
        id
        rep {
          id
          full_name
          initials
        }
      }
      rma_items {
        id
        credit
        returned_part {
          id
          part {
            id
            pn
            description
            mfgr {
              id
              name
            }
          }
          sales_items_details {
            id
            sold_for {
              ...Money
            }
          }
          serial_number
        }
      }
      return_code {
        id
        choice
        sub_choice
      }
      return_reason
    }
  }
  ${FRAGMENT__MONEY}
  ${FRAGMENT__CONTACT__LIST}
`

export const GRID_GET_PURCHASE_RMA_ITEMS = gql`
  query GetPurchaseRmaItems ($input: ManyQueryInput!) {
    purchase_rma_items: rma_purchase_rma_items (input: $input) {
      ...PurchaseRmaItem
    }
  }
  ${FRAGMENT__PURCHASE_RMA_ITEM}
`

export const GRID_GET_SALE_RMA_ITEMS = gql`
  query GetSaleeRmaItems ($input: ManyQueryInput!) {
    rma_items: rma_rma_items (input: $input) {
      ...SaleRmaItem
    }
  }
  ${FRAGMENT__SALE_RMA_ITEM}
`

export const GRID_GET_SALE_RMAS = gql`
    query GridGetSalesRMA ($input: ManyQueryInput!) {
      rmas: rma_rmas (input: $input) {
        annotations {
          name
          value
        }
        claim_date
        closed_date
        contact {
          id
          full_name
        }
        creation_date
        creator {
          ...UserMinimal
        }
        id
        st {
          id
          client {
            id
            name
          }
        }
        received_date
        return_code {
          id
          choice
        }
        return_reason
        shipment_order {
          ...ShipmentOrderDetails
        }
        status {
          id
          status
        }
        contract_comment
        internal_comment
        updated_date
        voided_date
        warranty_honored_after_cut_off
      }
    }
  ${FRAGMENT__USER_MINIMAL}
  ${FRAGMENT__SHIPMENT_ORDER_DETAILS}
`

export const GRID_GET_PURCHASE_RMAS = gql`
  query GridGetPurchaseRMAs ($input: ManyQueryInput!) {
    purchase_rmas: rma_purchase_rmas (input: $input) {
      claim_date
      closed_date
      creation_date
      creator {
        ...UserMinimal
      }
      id
      pt {
        id
        client {
          id
          name
        }
      }
      received_date
      shipment_order {
        ...ShipmentOrderDetails
      }
      status {
        id
        status
        status_type
        order
      }
      updated_date
      voided_date
    }
  }
  ${FRAGMENT__SHIPMENT_ORDER_DETAILS}
  ${FRAGMENT__USER_MINIMAL}
`

export const GET_ITEM_COUNT_FOR_RMA = gql`
  query GetItemCountForRma ($id: ID!) {
    count: rma_rma (input: { id: $id, annotations: [{ aggr_type: COUNT, aggr_field: "rma_items", name: "count" }]}) {
      id
      annotations { name value }
    }
  }
`

export const GET_ITEM_COUNT_FOR_PURCHASE_RMA = gql`
  query GetItemCountForPurchaseRma ($id: ID!) {
    count: rma_purchase_rma (input: { id: $id, annotations: [{ aggr_type: COUNT, aggr_field: "rma_items", name: "count" }]}) {
      id
      annotations { name value }
    }
  }
`
// MUTATIONS

export const CREATE_PURCHASE_RMA = gql`
  mutation CreatePurchaseRMA ($date: Date, $pt: ID!, $shipment: ID) {
    rma: Create__RMA_PurchaseRMA (input: {
      claim_date: $date,
      pt_id: $pt,
      status_id: 48,
      shipment_order_id: $shipment
    }) {
      id
    }
  }
`

export const CREATE_PURCHASE_RMA_ITEM = gql`
  mutation CreatePurchaseRMAItem ($items: [Create__RMA_PurchaseRMAItems__Input!]!) {
    items: Create__RMA_PurchaseRMAItems (input: $items) {
      id
    }
  }
`

export const CREATE_SALE_RMA = gql`
  mutation CreateSaleRMA ($date: Date!, $st: ID!, $code: ID!, $reason: String!, $warranty: Boolean!) {
    rma: Create__RMA_RMA (input: {
      claim_date: $date,
      st_id: $st,
      status_id: 37,
      return_code_id: $code,
      return_reason: $reason,
      warranty_honored_after_cut_off: $warranty
    }) {
      id
    }
  }
`

export const CREATE_SALE_RMA_ITEM = gql`
  mutation CreateSaleRMAItem ($items: [Create__RMA_RMAItems__Input!]!) {
    item: Create__RMA_RMAItems (input: $items) {
      id
    }
  }
`

export const DELETE_RMA_ITEM = gql`
  mutation DeleteRmaItem ($id: ID!) {
    Delete__RMA_RMAItems (id: $id) { id }
  }
`

export const UPDATE_PURCHASE_RMA_STATUS = gql`
    mutation UpdatePurchaseRMAStatus ($id: ID!, $status: ID!) {
      rma: Update__RMA_PurchaseRMA (input: { id: $id, status_id: $status }) {
        id
        status {
          id
          status
          status_type
          order
        }
      }
    }
`

export const UPDATE_SALE_RMA_STATUS = gql`
  mutation UpdateSaleRMAStatus ($id: ID!, $status: ID!) {
    rma: Update__RMA_RMA (input: { id: $id, status_id: $status }) {
      id
      status {
        id
        status
        status_type
        order
      }
    }
  }
`
