import {
  SaleForMoneyUpdateFragment,
  Sales_SoldItems,
  Scalars
} from '@/models/generated/graphql/ErpBackend'
import { c } from '@/lib/Currency'

export interface CalculatedValuesForSale {
  calculatedCost: number,
  calculatedSoldFor: number
}

export function GetDjangoMoneyCompatibleInput (money: any): string {
  // check to see if it is an actual number or money
  try {
    return c(money).format({ precision: 2, symbol: '', separator: '', errorOnInvalid: true })
  } catch {
    return '0.00'
  }
}

export function getMoniesForSoldItems (soldItems: Sales_SoldItems[]): CalculatedValuesForSale {
  let totalCost = 0.00
  let totalSoldFor = 0.00
  for (const soldItem of soldItems) {
    totalSoldFor += Number(soldItem?.sold_for?.amount ?? 0)
    totalCost += Number(soldItem?.item?.purchases_items_details?.original_cost?.amount ?? 0)
  }
  return { calculatedCost: totalCost, calculatedSoldFor: totalSoldFor }
}

export function GetAllocatedObjectWithIoSplit ({ soldItem }: { soldItem: any }) {
  const sellerSplit = soldItem.split

  return {
    item_id: soldItem.item,
    seller_margin_percentage: sellerSplit,
    buyer_margin_percentage: 100 - sellerSplit
  }
}

export interface MoneyUpdateObject {
  id: string,
  estimated_margin: Scalars['Price'],
  estimated_cost: Scalars['Price']
}
export function CalculateMoneyPostSaleUpdate (sale: SaleForMoneyUpdateFragment): MoneyUpdateObject {
  const orderCost = sale.estimated_cost?.amount ?? 0 // if estimated cost is null or just weird we should reset it to zero
  const orderMargin = sale.estimated_margin?.amount ?? 0 // same here
  // @ts-ignore
  const { calculatedCost, calculatedSoldFor } = getMoniesForSoldItems(sale?.st_items ?? []) // jest tests for this guy
  const calculatedMargin = calculatedSoldFor - calculatedCost
  const updateMonies = orderCost !== calculatedCost || orderMargin !== calculatedMargin
  if (updateMonies) {
    return {
      id: `${sale.id}`,
      estimated_margin: GetDjangoMoneyCompatibleInput(calculatedMargin),
      estimated_cost: GetDjangoMoneyCompatibleInput(calculatedCost)
    }
  } else {
    return { id: '', estimated_cost: 0, estimated_margin: 0 }
  }
}
