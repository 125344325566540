import currency from 'currency.js'
import { Money } from '@/models/generated/graphql/ErpBackend'

export const c = currency

interface UICurrencyResult {
  number: number,
  string: string
}

export function GetSumTotalOfMoneyArray (money: Money[]): UICurrencyResult {
  let total = 0
  for (const m of money) {
    const value = m?.amount ?? 0
    if (Number.isFinite(Number(value))) total = c(total).add(value).value
  }
  const t = c(total)
  return { number: t.value, string: t.format() }
}
