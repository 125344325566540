<template>
  <v-card style="height: 100%;">
    <v-card-title class="primary">
      <h3 class="white--text">{{ params.data.pn }}</h3>
    </v-card-title>
    <v-progress-linear indeterminate color="secondary" class="my-0" v-if="!dataIsSet"/>
    <v-card-text class="grey lighten-4" style="height: 100%;">
      <v-container fluid grid-list-xl pa-0>
        <!-- Navigation Buttons -->
        <v-layout row justify-start align-center>
          <v-flex
            v-for="button in buttons"
            :key="button.name"
            shrink
          >
            <v-btn
              small
              :loading="button.loading"
              :flat="!isActiveButton(button.id)"
              color="primary"
              @click="activateButton(button.id)"
            >
              {{ button.name }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
      <!-- Grid -->
      <v-scale-transition>
        <v-container fluid style="height: calc(100% - 90px);" py-1 v-if="dataIsSet">
          <v-layout column nowrap justify-space-between align-space-between fill-height>
            <v-flex xs12 pt-2>
              <v-fade-transition>
                <grid-wrapper
                  v-if="showTheGrid"
                  :columns="activeButton.columns"
                  :component-name="activeButton.gridName"
                  :context-menu-items="contextMenuItems"
                  :query="activeButton.query"
                  :annotations="activeButton.annotations"
                  :filter="activeButton.filters"
                  :nested-grid="false"
                  @ready="gridReady"
                />
                <v-container
                  v-if="showCreateBomButton"
                >
                  <v-layout row justify-center align-center>
                    <v-btn
                      color="info"
                      @click="createBOM"
                    >Create New Bill of Materials</v-btn>
                  </v-layout>
                </v-container>
              </v-fade-transition>
            </v-flex>
          </v-layout>
        </v-container>
      </v-scale-transition>

    </v-card-text>
    <v-dialog
      v-model="dialog"
      lazy
      persistent
    >
      <component :is="component" v-bind="dialogPropData" @success="handleSuccess" @cancel="resetDialog"/>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  MASTER_PART_PURCHASES_ITEMS, MASTER_PART_QUOTES_ITEMS,
  MASTER_PART_SALES_ITEMS, MASTER_PART_SUB_PARTS, UNLINK_SUB_PART_ONE_WAY
} from '@/api/graphql/Constants/Inventory'
import {
  CREATE_BOM,
  DELETE_BOM_PART,
  GET_BOM_PARTS__FULL,
  GET_BOMS_FOR_PARTS
} from '@/api/graphql/Constants/Assembly'
import bomPart from '@/components/cards/bomPart'

import {
  COLUMNS__INVENTORY_MASTER,
  COLUMNS__MASTER_NESTED_BOMS,
  COLUMNS__MASTER_NESTED_PURCHASES, COLUMNS__MASTER_NESTED_QUOTES,
  COLUMNS__MASTER_NESTED_SALES
} from '@/lib/agGridColumnConfiguration'
import { CONTEXT_MENU__MASTER_NESTED } from '@/lib/agGridContextMenuConfigurations'
import LinkSubPart from '@/components/dialogs/LinkSubPart'
import { GridEvents } from '@/store/gridEvents.js'
export default {
  name: 'MasterDetailsRow',
  components: {
    // this done because technically the parent GridWrapper component (like three levels up)
    // requires this component but this component requires the parent component
    // so we need to make this an async import
    // read more here: https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References
    'grid-wrapper': () => import('@/components/wrappers/gridWrapper'),
    'bom-part-details': bomPart,
    'link-sub-part': LinkSubPart
  },
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  computed: {
    activeButton () {
      return this.buttons.filter(b => b.isActive)[0]
    },

    dataIsSet () {
      return this.bomID !== null
    },

    showTheGrid () {
      if (this.activeButton.id === 'bom') {
        return this.bomID && this.showGrid
      }
      return this.showGrid && this.activeButton.id !== 'summary'
    },

    showCreateBomButton () {
      return this.dataIsSet && this.bomID === 0 && this.activeButton.id === 'bom'
    }
  },
  watch: {
    activeButton: function (value) {
      if (this.$route.fullPath.indexOf(value.id) === -1) {
        this.$router.push({ name: this.$route.name, params: { id: this.params.data.id, nested: value.id } })
      }
    }
  },
  data () {
    return {
      buttons: [
        // {
        //   id: 'summary',
        //   name: 'Summary',
        //   gridName: 'master-row-summary',
        //   isActive: true,
        //   loading: false,
        //   query: GET_MASTER_PART_SUMMARY,
        //   filter: [],
        //   annotations: [],
        //   columns: []
        // },
        {
          id: 'bom',
          name: 'BOM Management',
          gridName: 'master-row-bom',
          isActive: true,
          query: GET_BOM_PARTS__FULL,
          filters: [
            {
              key: 'bom__bom_for__id',
              value: this.params.data.id
            }
          ],
          annotations: [],
          columns: COLUMNS__MASTER_NESTED_BOMS
        },
        {
          id: 'purchases',
          name: 'Purchases',
          gridName: 'master-row-purchases',
          isActive: false,
          query: MASTER_PART_PURCHASES_ITEMS,
          filters: [
            {
              key: 'part__id',
              value: this.params.data.id
            }
          ],
          annotations: [],
          columns: COLUMNS__MASTER_NESTED_PURCHASES
        },
        {
          id: 'sales',
          name: 'Sales',
          gridName: 'master-row-sales',
          isActive: false,
          query: MASTER_PART_SALES_ITEMS,
          filters: [
            {
              key: 'part__id',
              value: this.params.data.id
            }
          ],
          columns: COLUMNS__MASTER_NESTED_SALES
        },
        {
          id: 'quotes',
          name: 'Quotes',
          gridName: 'master-row-quotes',
          isActive: false,
          query: MASTER_PART_QUOTES_ITEMS,
          filters: [
            {
              key: 'part__id',
              value: this.params.data.id
            }
          ],
          columns: COLUMNS__MASTER_NESTED_QUOTES
        },
        {
          id: 'subs',
          name: 'Sub Parts',
          gridName: 'master-row-subs',
          isActive: false,
          query: MASTER_PART_SUB_PARTS,
          filters: [
            {
              key: 'alt_part_for_part__id',
              value: this.params.data.id
            }
          ],
          columns: COLUMNS__INVENTORY_MASTER
        }
      ],

      contextMenuItems: params => CONTEXT_MENU__MASTER_NESTED(params, this.activeButton.id, this),

      showGrid: true,
      gridApi: null,
      columnApi: null,
      bomID: null,
      dialog: false,
      component: '',
      dialogPropData: {},
      summaryRowData: []
    }
  },
  apollo: {
    bomID: {
      query: GET_BOMS_FOR_PARTS,
      variables () {
        return {
          input: {
            filters: [{ key: 'bom_for__id', value: this.params.data.id }]
          }
        }
      },
      update ({ parts_boms }) {
        return parts_boms?.[0]?.id ?? 0
      }
    }
  },
  methods: {
    contextMenuCallback ({ functionToRun, params, data, dataKey }) {
      if (dataKey) this[dataKey] = data
      if (functionToRun) this[functionToRun](params)
    },

    handleSuccess ({ name }) {
      if (name === 'link-sub-part') {
        this.gridApi.purgeServerSideCache()
      }
    },

    gridReady (params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi
    },

    activateButton (id) {
      if (id !== this.activeButton.id) {
        this.showGrid = false
        for (const button of this.buttons) {
          button.isActive = button.id === id
        }
        const self = this
        setTimeout(function () {
          self.showGrid = true
        }, 100)
      } else {
      }
    },

    prepLinkPart () {
      this.dialogPropData = { part: { id: this.params.data.id, pn: this.params.data.pn } }
      this.component = 'link-sub-part'
      this.dialog = true
    },

    unlinkPart ({ id }) {
      this.$apollo.mutate({
        mutation: UNLINK_SUB_PART_ONE_WAY,
        variables: { part: id }
      }).then(() => {
        try {
          const cache = this.$apollo.getClient()
          const data = cache.readQuery({
            query: MASTER_PART_SUB_PARTS,
            variables: {
              input: {
                annotations: [],
                excludes: [],
                filters: [{ key: 'alt_part_for_part__id', value: this.params.data.id }],
                limit: 51,
                offset: 0,
                order_by: []
              }
            }
          })
          const index = data.sub_parts.find(d => d.id === id)
          data.sub_parts.splice(index, 1)
          cache.writeQuery({
            query: MASTER_PART_SUB_PARTS,
            variables: {
              input: {
                annotations: [],
                excludes: [],
                filters: [{ key: 'alt_part_for_part__id', value: this.params.data.id }],
                limit: 51,
                offset: 0,
                order_by: []
              }
            },
            data: data
          })
        } catch {
          this.gridApi.purgeServerSideCache()
        }
      })
    },

    isActiveButton (id) {
      return this.buttons.filter(b => b.id === id)[0].isActive
    },

    onGridReady (params) {
    },

    createBOM () {
      this.$apollo.mutate({
        mutation: CREATE_BOM,
        variables: { id: this.params.data.id, description: `Bill of Materials for ${this.params.data.pn}` }
      }).then(({ data: { Create__Parts_BOM } }) => {
        this.bomID = Create__Parts_BOM.id
      })
    },

    /* BOM CMI Methods */
    addBomPart () {
      this.dialogPropData = { data: { bomID: this.bomID, bomForID: this.params.data.id } }
      this.dialog = true
      this.component = 'bom-part-details'
    },

    editBomPart ({ data }) {
      this.dialogPropData = {
        bomID: data.bom.id,
        id: data.id,
        bomForID: this.params.data.id,
        partID: data.part.id,
        partPn: data.part.pn,
        type: data.part_type,
        maxCount: data.maximum_count,
        minCount: data.minimum_count
      }
      this.dialog = true
      this.component = 'bom-part-details'
    },

    deleteBomPart ({ id }) {
      this.$apollo.mutate({
        mutation: DELETE_BOM_PART,
        variables: { id: id }
      }).then(() => {
        try {
          const data = this.$apollo.getClient().readQuery({
            query: GET_BOM_PARTS__FULL,
            variables: {
              input: {
                annotations: [],
                excludes: [],
                filters: [
                  {
                    key: 'bom__bom_for__id',
                    value: this.params.data.id
                  }
                ],
                limit: 36,
                offset: 0,
                order_by: []
              }
            }
          })
          const index = data.parts_bom_parts.findIndex(p => p.id === id)
          data.parts_bom_parts.splice(index, 1)
          this.$apollo.getClient().writeQuery({
            query: GET_BOM_PARTS__FULL,
            variables: {
              input: {
                annotations: [],
                excludes: [],
                filters: [
                  {
                    key: 'bom__bom_for__id',
                    value: this.params.data.id
                  }
                ],
                limit: 36,
                offset: 0,
                order_by: []
              }
            },
            data: data
          })
        } catch (error) {
          console.log(error)
          this.gridApi.purgeServerSideCache()
        }
      })
    },

    resetDialog () {
      this.dialog = false
      this.component = ''
      this.dialogPropData = {}
    }
  },
  beforeMount () {
    const nested = this.$route.params?.nested ?? false
    if (nested) {
      const nestedIsValid = this.buttons.findIndex(c => c.id === nested) > -1
      if (nestedIsValid) this.activateButton(nested)
      else this.$router.push({ name: this.$route.name, params: { id: this.params.data.id, nested: 'bom' } })
    }
  },
  mounted () {
    GridEvents.$on('refetch-master-nested', () => this.gridApi.purgeServerSideCache())
  },
  beforeDestroy () {
    if (this.$route.params.id || this.$route.params.nested) this.$router.push({ name: this.$route.name })
    GridEvents.$off('refetch-master-nested', () => this.gridApi.purgeServerSideCache())
  }
}
</script>
