<template>
  <div>
    <v-container>
      <v-form
        ref="switchForm"
        v-model="canProceed"
      >
        <v-layout row nowrap justify-center>
          <!-- Ports -->
          <v-flex xs2>
            <v-text-field
              v-model="ports"
              label="Port Count"
              :rules="[ e => e > 0 || 'Must be > 0' ]"
            ></v-text-field>
          </v-flex>
          <!-- POE -->
          <v-flex xs2 offset-xs1>
            <v-checkbox
              v-model="poe"
              label="POE"
            ></v-checkbox>
          </v-flex>
          <!-- POE Ports -->
          <v-flex xs2 offset-xs1>
            <v-text-field
              v-model="poePorts"
              :disabled="!poe"
              label="POE Ports"
              :rules="[ e => e > 0 || !poe || 'Must be > 0' ]"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row nowrap justify-center>
          <!-- SFP Ports -->
          <v-flex xs2>
            <v-text-field
              v-model="sfpPorts"
              label="SFP Ports"
              placeholder="Optional"
            ></v-text-field>
          </v-flex>
          <!-- Switch Type -->
          <v-flex xs3 offset-xs1>
            <choice-list :config="switchTypeConfig"></choice-list>
          </v-flex>
          <!-- Speed -->
          <v-flex xs3 offset-xs1>
            <v-select
              v-model="maxSpeed"
              :items="speeds"
              label="Switch Speed"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>
    <standard-actions
      @save="save"
      @cancel="cancel"
      @close="$emit('close')"
      :save-button="{ name: 'Submit', disabled: !canProceed }"
      :cancel-button="{ name: 'Back' }"
    ></standard-actions>
  </div>
</template>

<script>
import standardActions from '../standardActions'
import choices from '../../autocompletes/choices'

export default {
  name: 'switch',
  components: {
    'standard-actions': standardActions,
    'choice-list': choices
  },
  data () {
    return {
      canProceed: false,
      ports: '',
      poe: false,
      poePorts: '',
      maxSpeed: '',
      sfpPorts: '',
      switchTypeConfig: {
        id: 0,
        label: 'Switch Type',
        appName: 'parts',
        modelName: 'switch',
        fieldName: 'switch_type'
      },
      speeds: [
        '10Mb',
        '100Mb',
        '1000Mb',
        '10Gb'
      ]
    }
  },
  methods: {
    save () {
      if (this.$refs.switchForm.validate()) {
        const details = {
          ports: parseInt(this.ports),
          poe_ports: this.poePorts ? this.poePorts : 0,
          speed: this.maxSpeed,
          sfp_ports: this.sfpPorts ? parseInt(this.sfpPorts) : 0,
          switch_type_id: this.switchTypeConfig.id
        }
        this.$emit('save', details)
      }
    },

    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
