import {
  Address_Address,
  EasyPostOrder,
  Purchases_PurchasedItems,
  Sales_SoldItems
} from '@/models/generated/graphql/ErpBackend.ts'
import moment from 'moment'

export function GetAddressDetailsForAutofill (address: Address_Address): string {
  return `${address.street_1} ${address.city}, ${address.state}`
}

export function GetAddressOwnerNameForAutofill (address: Address_Address, defaultName: string): string {
  if (address.client?.name) return address.client.name
  if (address.company?.name) return address.company.name
  return defaultName
}

export function GetFullAddressLine (address: Address_Address): string {
  return `${address.street_1} ${address.city}, ${address.state} ${address.country.code} ${address.zip_code}`
}

export function GetPtOrStId (details: Sales_SoldItems | Purchases_PurchasedItems): string {
  return `${details.transaction.id}-${details.line_number}`
}

export function GetZplPostageLabelsFromEasyPostShipmentOrder (order: EasyPostOrder): string[] {
  const labels: string[] = []
  if (order.shipments) {
    for (const shipment of order.shipments) {
      if (shipment && shipment?.postage_label && shipment.postage_label?.label_zpl_url) {
        labels.push(shipment.postage_label.label_zpl_url)
      }
    }
  }
  return labels
}

export function GetPrettyDate (date: string) {
  return moment(date).format('L')
}
