<template>
    <v-autocomplete
      v-model="localProduct"
      :attach="attach"
      :search-input.sync="search"
      :items="supplies"
      :loading="loading"
      :rules="rules"
      id="part-field"
      item-text="pn"
      return-object
      append-icon="fal fa-times"
      label="Products"
      @click:append="search = ''"
    >
      <template #message="{ message, key }">
        <span :key="key" v-html="message"/>
      </template>
      <template #item="{ item }">
        <v-list-tile-content>
          <v-list-tile-title>{{ item.pn }}</v-list-tile-title>
          <v-list-tile-sub-title>{{ item.description }} | {{ `Size: ${getItemSize(item)}` }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </template>
      <template #no-data>
        <v-list-tile>
          <v-list-tile-action v-if="search.length >= 3 && !$apollo.loading">
            <v-btn icon>
              <v-icon color="primary">far fa-plus</v-icon>
            </v-btn>
          </v-list-tile-action>
          <v-list-tile-content v-if="search.length < 3">
            <v-list-tile-title>
              Start entering a product name/description...
            </v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-content v-else-if="$apollo.loading">
            <v-list-tile-title>
              Searching...
            </v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-content v-else>
            <v-list-tile-title>
              Could not find an existing product.
            </v-list-tile-title>
            <v-list-tile-sub-title>Create a new one</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </template>
    </v-autocomplete>
</template>

<script>
import { PARTS__FILTER } from '@/api/graphql/Constants/Inventory'

export default {
  name: 'WorkOrderSupplies',
  props: {
    product: {
      type: Object,
      required: true
    },
    attach: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    search (newValue, oldValue) {
      newValue = newValue === null ? '' : newValue
      oldValue = oldValue === null ? '' : oldValue
      if (newValue.length < oldValue.length) {
        this.searched = false
      }
      this.search = newValue
      if (newValue.length > 2 && (!this.searched || this.supplies.length === 0)) {
        this.skip = false
        if (newValue === 'ALL') {
          this.search = ''
        }
        this.searched = true
      }
    },

    supplies () {
      this.skip = true
    }
  },
  computed: {
    localProduct: {
      get () {
        return this.product
      },
      set (value) {
        this.$emit('updated', { value: value })
      }
    }
  },
  data () {
    return {
      search: '',
      skip: true,

      supplies: [],
      loading: false,
      rules: [e => !!e || 'Select a product']
    }
  },
  apollo: {
    supplies: {
      query: PARTS__FILTER,
      variables () {
        return {
          filters: [{
            key: 'pn__icontains',
            value: this.search,
            or: {
              key: 'description__icontains',
              value: this.search
            }
          }]
        }
      },
      update: data => data.parts,
      skip () {
        return this.skip
      }
    }
  },
  methods: {
    getItemSize (item) {
      return item.size || 'Not specified'
    }
  }
}
</script>
