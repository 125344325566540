import { Module, ActionTree, MutationTree, Action } from 'vuex'
import { IKeyValuePayload, IQuoteState } from '@/models/vuexStateModels'

export const state: IQuoteState = {
  parts: [],
  client: {},
  contact: {}
}

const mutations: MutationTree<IQuoteState> = {
  LOAD (state: IQuoteState, payload: IKeyValuePayload) {
    for (const [key, value] of Object.entries(payload)) {
      if (value && value.id) {
        state[key] = value.id
      } else if (key !== 'id') {
        state[key] = value
      }
    }
  },

  RESET (state: IQuoteState) {
    state.parts = []
    state.client = {}
    state.contact = {}
  }
}

const actions: ActionTree<IQuoteState, any> = {
  load ({ commit, dispatch }, payload: IKeyValuePayload): void {
    commit('LOAD', payload)
    dispatch('start')
  },

  start ({ dispatch }) {
    dispatch('grid/changeSheet', { app: 'topbar', component: 'newQuote' }, { root: true })
  },

  reset ({ commit }) {
    commit('RESET')
  }
}

export const quotes: Module<IQuoteState, any> = {
  namespaced: true,
  state,
  mutations,
  actions
}
