
































































































import { GET_CLIENT_ALT_PARTS } from '@/api/graphql/Constants/Clients'
import { computed, ComputedRef, defineComponent, provide, reactive, Ref, ref } from '@vue/composition-api'
import { apolloClient } from '@/api/graphql/apollo'
import { ApolloClients, useMutation, useQuery, useResult } from '@vue/apollo-composable'
import store from '@/store/store'
import { IVuetifyDatatableHeader } from '@/models/VuetifyModels'
import PartsAutofill from '@/components/autocompletes/PartsAutofill.vue'
import tAlert from '@/components/notifications/tAlert.vue'
import { CREATE_ALTERNATE_PART_LINK, UPDATE_PART } from '@/api/graphql/Constants/Parts'
import { Clients_Client, Parts_Part } from '@/models/generated/graphql/ErpBackend.ts'

export default defineComponent({
  name: 'ClientSubPartInfo',
  components: {
    'part-autofill': PartsAutofill,
    't-alert': tAlert
  },
  props: {
    clientInformation: {
      type: Object as () => Clients_Client,
      required: true
    }
  },
  setup (props) {
    provide(ApolloClients, {
      default: apolloClient
    })
    const client: ComputedRef<Clients_Client> = computed(() => props.clientInformation)

    const { result: partsResult, loading: isLoadingParts, onError: onPartsError } = useQuery(
      GET_CLIENT_ALT_PARTS,
      () => ({
        clientId: client.value.id
      })
    )
    onPartsError(() => {
      store.dispatch('notifications/createSnackbar', {
        message: 'Could not access client alt parts.',
        color: 'error'
      })
    })
    const parts = useResult(partsResult, [], data => data.clients_client.alt_parts)
    const headers: IVuetifyDatatableHeader[] = [
      { text: 'Alt Part Number', value: 'pn' },
      { text: '', value: 'nullyio' },
      { text: 'Linked To', value: 'alt_part_for_part.pn' },
      { text: 'Unlink', value: 'nully' }
    ]

    // dialog info
    const dialog = ref(false)
    const alert = reactive({
      message: '',
      type: 'info'
    })

    const partLinker = reactive({
      id: '',
      pn: ''
    })
    function UpdatePartLinker (event: any) {
      partLinker.id = event.value.id
      partLinker.pn = event.value.pn
    }

    const partLinkee = reactive({
      id: '',
      pn: ''
    })
    function UpdatePartLinkee (event: any) {
      if (event.value.id === partLinker.id && partLinker.id !== '') {
        alert.message = 'You cannot link a part to itself.'
        alert.type = 'warning'
        partLinkee.id = ''
        partLinkee.pn = ''
      } else {
        partLinkee.id = event.value.id
        partLinkee.pn = event.value.pn
        alert.message = ''
        alert.type = 'info'
      }
    }

    const { mutate: LinkPartsMutation, loading: isLinkingParts, onError: onLinkFailure, onDone: onLinkSuccess } = useMutation(
      CREATE_ALTERNATE_PART_LINK,
      () => ({
        variables: {
          linker: partLinker.id,
          linkee: partLinkee.id,
          clientId: client.value.id
        },
        update: (cache, { data: { Update__Parts_Part: part } }) => {
          try {
            const data: any = cache.readQuery({
              query: GET_CLIENT_ALT_PARTS,
              variables: { clientId: client.value.id }
            })
            if (data.clients_client.alt_parts.length > 0) {
              data.clients_client.alt_parts.push(part)
              cache.writeQuery({
                query: GET_CLIENT_ALT_PARTS,
                variables: { clientId: client.value.id },
                data: data
              })
            } else {
              cache.writeQuery({
                query: GET_CLIENT_ALT_PARTS,
                variables: { clientId: client.value.id },
                data: {
                  clients_client: {
                    id: client.value.id,
                    alt_parts: [part],
                    __typename: 'Clients_Client'
                  }
                }
              })
            }
          } catch {
            parts.value.push(part)
          }
        }
      })
    )
    onLinkFailure(() => {
      alert.message = 'Could not create link, contact support.'
      alert.type = 'error'
    })
    onLinkSuccess(() => {
      alert.message = 'Created link successfully'
      alert.type = 'success'
      cancel.text = 'Close'
      cancel.color = 'primary'
      // const part = result!.data.Update__Parts_Part
      // parts.value.push(part)
    })

    const cancel = reactive({
      color: 'error',
      text: 'Cancel'
    })
    function ResetDialog () {
      cancel.color = 'error'
      cancel.text = 'Cancel'
      partLinker.id = ''
      partLinker.pn = ''
      partLinkee.id = ''
      partLinkee.pn = ''
      dialog.value = false
    }
    function LinkParts () {
      if (partLinker.id && partLinkee.id && partLinker.id !== partLinkee.id) {
        LinkPartsMutation()
      } else {
        alert.message = 'Pick two different parts to link above to submit'
        alert.type = 'warning'
      }
    }

    const { mutate: UnLinkParts, loading: isUnlinkingParts, onError: onUnlinkError, onDone: onUnlinkSuccess } = useMutation(
      UPDATE_PART,
      () => ({
        update: (cache, { data: { Update__Parts_Part: part } }) => {
          try {
            const data: any = cache.readQuery({
              query: GET_CLIENT_ALT_PARTS,
              variables: { clientId: client.value.id }
            })
            const index = data.clients_client.alt_parts.findIndex((p: Parts_Part) => p.id === part.id)
            if (index > -1) {
              data.clients_client.alt_parts.splice(index, 1)
              cache.writeQuery({
                query: GET_CLIENT_ALT_PARTS,
                variables: { clientId: client.value.id },
                data: data
              })
            }
          } catch {
          }
        }
      })
    )
    onUnlinkError(() => {
      store.dispatch('notifications/createSnackbar', {
        message: 'Could not unlink part, you may not have sufficient permissions',
        color: 'error'
      })
    })
    onUnlinkSuccess(() => {
      store.dispatch('notifications/createSnackbar', {
        message: 'Unlinked part!',
        color: 'success'
      })
    })

    const submitIsDisabled = computed(() => (alert.message !== '' || alert.type !== 'info') || cancel.color === 'primary')

    return {
      dialog,
      parts,
      isLoadingParts,
      headers,
      partLinker,
      UpdatePartLinker,
      UpdatePartLinkee,
      partLinkee,
      cancel,
      ResetDialog,
      alert,
      LinkParts,
      isLinkingParts,
      UnLinkParts,
      isUnlinkingParts,
      submitIsDisabled
    }
  }
})
