import { computed } from '@vue/composition-api'
import { SetItemTestStatusMutationVariables } from '@/models/generated/graphql/ErpBackend'

interface MarkTestedForm {
  ids: {
    value: number[],
    message: string,
    validator: () => void,
    setter: (event: { value: number[] }) => void
  },
  input: SetItemTestStatusMutationVariables[]
}

/**
 *
 * @param {number[]} ids - optional function to retrieve original values to compare against for when after they are set
 * @param {boolean} activeValidation - boolean to determine active or lazy validation
 * @param {Function} callback
 */
export function useMarkAsTestedForm (ids: number[], activeValidation: boolean, callback: Function) {
  const form: MarkTestedForm = {
    ids: {
      value: [],
      message: '',
      validator: () => {
        const e = form.ids.value
        let message: string = ''
        if ((e.indexOf(4) > -1 || form.ids.value.indexOf(9) > -1) && e.length > 1) {
          if (e.length > 1) message = 'Only either untestable or failed can be selected at once.'
        }
        form.ids.message = message
      },
      setter: (event: { value: number[] }) => {
        form.ids.value = event.value
        form.ids.message = ''
        form.input = ids.map(i => ({ id: i.toString(), statuses: event.value.map(v => `${v}`) }))
        activeValidation && form.ids.validator()
        callback()
      }
    },
    input: ids.map(i => ({ id: i.toString(), statuses: [] }))
  }

  const validate = () => {
    form.ids.validator()
  }

  const hasErrors = computed(() => form.ids.message !== '')

  return {
    form,
    validate,
    hasErrors
  }
}
