import { IStandardAutofillConfig } from '@/models/StandardAutofillModels'
import { ref, Ref, watch } from '@vue/composition-api'
import { apolloClient as apollo } from '@/api/graphql/apollo'
import { GET_ADDRESSES__FULL } from '@/api/graphql/Constants/Addresses'
import { GetAddressDetailsForAutofill } from '@/lib/gqlDataGetters'
import {
  Address_Address, Blind, Pickup_Type,
  QueryFilter,
  Shipping_Account,
  Shipping_ServiceLevel
} from '@/models/generated/graphql/ErpBackend.ts'
import {
  GET_CLIENT_CARRIER_ACCOUNTS_WITH_NUMBER__AUTOFILL,
  GET_COMPANY_CARRIER_ACCOUNTS_WITH_NUMBER__AUTOFILL
} from '@/api/graphql/Constants/Shipments'
import { QueryOptions } from 'apollo-client'

export interface UseShippingParams {
  id: number // the id of the client
}

export function UseShipping (params: UseShippingParams) {
  /* SHIP TO */
  const shipToId: Ref<string> = ref<string>('')
  watch(shipToId, (value) => {
    if (value) {
      validateShipTo()
      shipToAddressObject.value = shipTos.value?.find(a => a.id === value) ?? null
      shipToEpId.value = shipToAddressObject.value?.easypost_id ?? ''
    } else {
      shipToAddressObject.value = null
      shipToEpId.value = ''
    }
  })
  const shipToAddressObject: Ref<Address_Address | null> = ref(null)
  const shipToEpId: Ref<string> = ref<string>('')
  const shipToConfig: IStandardAutofillConfig = {
    name: 'shipTo',
    label: 'Ship To Address',
    hint: '<span class="text--hint">Required to <strong>Save</strong> any <strong>shipping</strong> details</span>',
    clearable: true
  }
  const shipTos: Ref<Address_Address[]> = ref<Address_Address[]>([])
  const shipToMessage: Ref<string> = ref<string>('')
  const validateShipTo = () => {
    shipToMessage.value = shipToId.value !== '' ? '' : 'A ship to address is required'
  }
  function GetShipTos (filter: QueryFilter[]) {
    apollo.query({
      query: GET_ADDRESSES__FULL,
      variables: {
        input: { filters: filter }
      }
    }).then(({ data: { address_addresses } }) => {
      const addresses: Address_Address[] = address_addresses
      if (addresses.length > 0) {
        shipTos.value = addresses.map((a: Address_Address) => {
          // @ts-ignore
          a.title = GetAddressDetailsForAutofill(a)
          if (a.default_ship_to_for_client || a.default_ship_to_for_company) shipToId.value = a.id
          return a
        })
      }
    })
  }

  /* SHIP FROM */
  const shipFromId: Ref<string> = ref<string>('')
  watch(shipFromId, (value) => {
    if (value) {
      validateShipFrom()
      shipFromAddressObject.value = shipFroms.value.find(a => a.id === value)!
      shipFromEpId.value = shipFromAddressObject.value.easypost_id ?? ''
    } else {
      shipFromAddressObject.value = null
      shipFromEpId.value = ''
    }
  })
  const shipFromAddressObject: Ref<Address_Address | null> = ref(null)
  const shipFromEpId: Ref<string> = ref<string>('')
  const shipFromConfig: IStandardAutofillConfig = {
    name: 'shipFrom',
    label: 'Ship From Address',
    focus: true,
    hint: '<span class="text--hint">Required to <strong>Save</strong> any <strong>shipping</strong> details</span>',
    clearable: true
  }
  const shipFroms: Ref<Address_Address[]> = ref<Address_Address[]>([])
  const shipFromMessage: Ref<string> = ref<string>('')
  const validateShipFrom = () => {
    shipFromMessage.value = shipFromId.value !== '' ? '' : 'A ship from address is required.'
  }
  function GetShipFroms (filter: QueryFilter[]) {
    apollo.query({
      query: GET_ADDRESSES__FULL,
      variables: {
        input: { filters: filter }
      }
    }).then(({ data: { address_addresses } }) => {
      const addresses: Address_Address[] = address_addresses
      if (addresses.length > 0) {
        shipFroms.value = addresses.map((a: Address_Address) => {
          // @ts-ignore
          a.title = GetAddressDetailsForAutofill(a)
          return a
        })
      }
    })
  }

  /* BILL TO */
  const billToId: Ref<string> = ref<string>('')
  watch(billToId, (value) => {
    if (value) {
      validateBillTo()
      billToAddressObject.value = billTos.value.find(a => a.id === value)!
      billToEpId.value = billToAddressObject.value.easypost_id ?? ''
    } else {
      billToAddressObject.value = null
      billToEpId.value = ''
    }
  })
  const billToAddressObject: Ref<Address_Address | null> = ref(null)
  const billToEpId: Ref<string> = ref<string>('')
  const billToConfig: IStandardAutofillConfig = {
    name: 'billTo',
    label: 'Bill To',
    clearable: true
  }
  const billTos: Ref<Address_Address[]> = ref<Address_Address[]>([])
  const billToMessage: Ref<string> = ref<string>('')
  const validateBillTo = () => {
    billToMessage.value = billToId.value !== '' ? '' : 'A bill to address is required.'
  }
  function GetBillTos (filter: QueryFilter[]) {
    apollo.query({
      query: GET_ADDRESSES__FULL,
      variables: {
        input: { filters: filter }
      }
    }).then(({ data: { address_addresses } }) => {
      const addresses: Address_Address[] = address_addresses
      if (addresses.length > 0) {
        billTos.value = addresses.map((a: Address_Address) => {
          // @ts-ignore
          a.title = GetAddressDetailsForAutofill(a)
          if (a.default_billing_for_client || a.default_billing_for_company) billToId.value = a.id
          return a
        })
      }
    })
  }

  /* RETURN TO */
  const returnToId: Ref<string> = ref<string>('')
  watch(returnToId, (value) => {
    value && validateReturnTo()
  })
  const returnToConfig: IStandardAutofillConfig = {
    name: 'returnAddress',
    label: 'Return To',
    clearable: true
  }
  const returnTos: Ref<Address_Address[]> = ref<Address_Address[]>([])
  const returnToMessage: Ref<string> = ref<string>('')
  const validateReturnTo = () => {
    returnToMessage.value = (returnToId.value !== '' || blind.value === Blind.NotBlind) ? '' : 'A return address is required'
  }

  /* CARRIER ACCOUNT */
  const carrierAccountObject: Ref<Shipping_Account | null> = ref(null)
  const carrierAccountId: Ref<string> = ref<string>('')
  watch(carrierAccountId, (value) => {
    if (value) {
      validateCarrierAccount()
      carrierAccountObject.value = carrierAccounts.value.find(a => a.id === value)!
      carrierAccountEpId.value = carrierAccountObject.value.easypost_id ?? ''
      carrierAccountCarrierName.value = carrierAccountObject.value.shipper.name
    } else {
      carrierAccountObject.value = null
      carrierAccountEpId.value = ''
      carrierAccountCarrierName.value = ''
    }
  })
  const carrierAccountEpId: Ref<string> = ref<string>('')
  const carrierAccountCarrierName: Ref<string> = ref<string>('')
  const carrierAccountConfig: IStandardAutofillConfig = {
    name: 'account',
    label: 'Carrier Account',
    clearable: true
  }
  const carrierAccounts: Ref<Shipping_Account[]> = ref<Shipping_Account[]>([])
  const carrierAccountMessage: Ref<string> = ref<string>('')
  const validateCarrierAccount = () => {
    carrierAccountMessage.value = (carrierAccountId.value !== '' && selectService) ? '' : 'A carrier account is required'
  }

  /* SHIPPING SERVICE */
  const shipServiceId: Ref<string> = ref<string>('')
  watch(shipServiceId, value => value && validateService())
  const shipServiceConfig: IStandardAutofillConfig = {
    name: 'shippingService',
    label: 'Carrier Service',
    clearable: true
  }
  const shippingServices: Ref<Shipping_ServiceLevel[]> = ref<Shipping_ServiceLevel[]>([])
  const serviceMessage: Ref<string> = ref<string>('')
  const validateService = () => {
    serviceMessage.value = (shipServiceId.value !== '' || !selectService.value) ? '' : 'A shipping service is required.'
  }

  /* EXTRA BIT */
  const selectService: Ref = ref(true)
  watch(selectService, (value) => {
    if (value) {
      priority.value = ''
      deliveryDate.value = ''
    } else {
      carrierAccountId.value = ''
      shipServiceId.value = ''
    }
  })
  const selectionsForService: Ref<{}[]> = ref<{}[]>([
    { name: 'Service', value: true },
    { name: 'Priority', value: false }
  ])

  const priority: Ref<string> = ref<string>('')
  const priorities: Ref<{}[]> = ref<{}[]>([
    { name: 'Cheapest', value: 'cheap' },
    { name: 'By Date', value: 'date' }
  ])

  const wePay: Ref<boolean> = ref<boolean>(false)
  watch(wePay, async (value) => {
    carrierAccountId.value = ''
    let options: QueryOptions
    if (value) { // if going to use company carrier accounts
      options = {
        query: GET_COMPANY_CARRIER_ACCOUNTS_WITH_NUMBER__AUTOFILL
      }
    } else { // if going to use client carrier accounts
      options = {
        query: GET_CLIENT_CARRIER_ACCOUNTS_WITH_NUMBER__AUTOFILL,
        variables: {
          id: params.id
        }
      }
    }
    const response = await apollo.query(options) // going to grab accounts
    if (response.data.accounts) {
      carrierAccounts.value = response.data.accounts
    }
  })

  /* BLIND */
  const blind: Ref<Blind> = ref<Blind>(Blind.NotBlind)
  const selectionsForBlind: Ref<{}[]> = ref<{}[]>([
    { name: 'Standard', value: Blind.NotBlind },
    { name: 'Blind', value: Blind.Blind },
    { name: 'Double Blind', value: Blind.DoubleBlind }
  ])

  /* LOCAL PICK UP TYPE */
  const isLocalPickup: Ref<boolean> = ref<boolean>(false)
  watch(isLocalPickup, (value) => {
    carrierAccountId.value = ''
    shipServiceId.value = ''
    selectService.value = false
    if (!value) localPickupType.value = Pickup_Type.NotPickup
    carrierAccountMessage.value = ''
    pickupErrorMessage.value = ''
    serviceMessage.value = ''
  })
  const localPickupType: Ref<Pickup_Type> = ref<Pickup_Type>(Pickup_Type.NotPickup)
  watch(localPickupType, value => value && validatePickupType())
  const pickupErrorMessage: Ref<string> = ref<string>('')
  const validatePickupType = () => { pickupErrorMessage.value = localPickupType.value ? '' : 'Select a local pick up type to continue' }

  /* DELIVERY DATE */

  const deliveryDate: Ref<string> = ref<string>('')
  const deliveryDateConfig = {
    label: 'Delivery Date',
    maxDate: ''
  }

  /* VALIDATOR */
  function CallValidators (to: boolean, shipFrom: boolean, bill: boolean, returnTo: boolean, account: boolean, service: boolean, pickup: boolean) {
    to && validateShipTo()
    shipFrom && validateShipFrom()
    bill && validateBillTo()
    returnTo && validateReturnTo()
    account && validateCarrierAccount()
    service && validateService()
    pickup && validatePickupType()
  }

  return {
    /* SHIP TO */
    shipToId,
    shipToConfig,
    shipTos,
    shipToMessage,
    shipToEpId,
    GetShipTos,
    /* SHIP FROM */
    shipFromId,
    shipFromConfig,
    shipFroms,
    shipFromMessage,
    shipFromEpId,
    GetShipFroms,
    /* BILL TO */
    billToId,
    billToConfig,
    billTos,
    billToMessage,
    billToEpId,
    GetBillTos,
    /* RETURN TO */
    returnToId,
    returnToConfig,
    returnTos,
    returnToMessage,
    /* CARRIER ACCOUNT */
    carrierAccountId,
    carrierAccountConfig,
    carrierAccounts,
    carrierAccountMessage,
    carrierAccountEpId,
    carrierAccountCarrierName,
    /* SHIPPING SERVICE */
    shipServiceId,
    shipServiceConfig,
    shippingServices,
    serviceMessage,
    /* EXTRA BITS */
    selectService,
    selectionsForService,
    priority,
    priorities,
    wePay,
    /* BLIND */
    blind,
    selectionsForBlind,
    /* LOCAL PICK UP */
    isLocalPickup,
    localPickupType,
    pickupErrorMessage,
    /* DELIVERY DATE */
    deliveryDate,
    deliveryDateConfig,
    CallValidators
  }
}
