<template>
  <v-select
    v-model="localType"
    attach
    label="Pickup Type"
    :loading="loading"
    :items="options"
    :error-messages="errors"
    :disabled="disabled"
  />
</template>

<script>
export default {
  name: 'courierSelection',
  props: {
    pickupType: {
      type: String,
      required: true
    },
    errors: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    localType: {
      get () {
        return this.pickupType
      },
      set (value) {
        this.$emit('update', { key: 'localPickup', value: value })
      }
    }
  },
  data () {
    return {
      options: ['CUSTOMER', 'COURIER'],
      loading: false
    }
  }
}
</script>
