export function ValidateSingleLevelObject (validators, data) {
  for (const key in validators) {
    validators[key].value = validators[key].validator(data[key])
    // eslint-disable-next-line no-prototype-builtins
    if (validators[key].hasOwnProperty('value')) {
      validators[key].value = validators[key].validator(data[key])
    } else {
    }
  }
}

export function ValidateVue3ErrorObject (validators) {
  for (const key in validators) {
    validators[key].value = validators[key].validator()
    // eslint-disable-next-line no-prototype-builtins
    // if (validators[key].hasOwnProperty('value')) {
    //   validators[key].value = validators[key].validator()
    // } else {
    // }
  }
}

export function ValidateNestedObject (validators, data) {
  for (const key in validators) {
    for (const field in validators[key]) {
      // eslint-disable-next-line no-prototype-builtins
      if (validators[key][field].hasOwnProperty('value')) {
        validators[key][field].value = validators[key][field].validator(data)
      } else {
      }
    }
  }
}
