<template>
  <div>
    <v-container>
      <v-layout row wrap justify-space-between>
        <v-flex xs2>
          <v-text-field
            v-model="cpuSocks"
            label="CPU Sockets"
          ></v-text-field>
        </v-flex>
        <v-flex xs2>
          <v-text-field
            v-model="ramSocks"
            label="Memory Sockets"
          ></v-text-field>
        </v-flex>
        <v-flex xs2>
          <v-text-field
            v-model="maxMemGB"
            label="Max Memory"
            suffix="GB"
          ></v-text-field>
        </v-flex>
        <v-flex xs2>
          <choice-list :config="memTypeConfig"></choice-list>
        </v-flex>
      </v-layout>
    </v-container>
    <standard-actions
      @save="save"
      @cancel="cancel"
      @close="$emit('close')"
      :save-button="{ name: 'Submit', disabled: !canProceed }"
      :cancel-button="{ name: 'Back' }"
    ></standard-actions>
  </div>
</template>

<script>
import standardActions from '../standardActions'
import choices from '../../autocompletes/choices'

export default {
  name: 'storage',
  components: {
    'standard-actions': standardActions,
    'choice-list': choices
  },
  computed: {
    canProceed () {
      return this.cpuSocks && this.ramSocks && this.maxMemGB && this.memTypeConfig.id > -1
    }
  },
  data () {
    return {
      cpuSocks: 0,
      ramSocks: 0,
      maxMemGB: 0,
      memTypeConfig: {
        id: 0,
        label: 'Memory Type',
        appName: 'parts',
        modelName: 'motherboard',
        fieldName: 'memory_type'
      }
    }
  },
  methods: {
    save () {
      const details = {
        cpu_sockets: this.cpuSocks,
        ram_sockets: this.ramSocks,
        max_memory_gb: this.maxMemGB,
        memory_type_id: this.memTypeConfig.id
      }
      this.$emit('save', details)
    },

    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
