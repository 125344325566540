<template>
  <new-data-form
    :errors="{
       panel0: !!errors.client.value || !!errors.contact.value,
       panel1: addressErrors,
       panel2: !!errors.parts.value
    }"
    :succeeded="succeeded"
    :failed="failed"
    :footer-message="footerMessage"
  >

    <template #header0>QUOTE INFO</template>
    <template #panel0>
      <v-container grid-list-xl>
        <v-layout row justify-space-around align-center>
          <v-flex xs5>
            <companies
              data-cy="companies"
              :client="client"
              :contact-owner="contactOwner"
              :error="errors.client.value"
              el-id="clients"
              @updated="updateClient"
            >
              <template #status="{ client }">
                <v-list-tile-avatar>
                  <v-icon
                    v-if="client.allow_sales"
                    color="success"
                  >far fa-check-circle</v-icon>
                  <v-icon
                    v-else
                    color="error"
                  >far fa-ban</v-icon>
                </v-list-tile-avatar>
              </template>
            </companies>
          </v-flex>
          <v-flex xs5>
            <contacts
              data-cy="contacts"
              :contact="contact"
              :client="`${clientID}`"
              :error="errors.contact.value"
              el-id="contacts"
              @updated="updateContact"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </template>

    <template #header1>ADDRESS INFO</template>
    <template #panel1>
      <v-container grid-list-xl>
        <v-layout justify-start align-center row wrap>
          <!-- Ship From Address -->
          <v-flex xs6>
            <address-autocomplete
              :object="shipFromAddress"
              :filter="shipFromFilter"
              :error="errors.shipFromAddress.value"
              @updated="updateAddress({ key: 'shipFrom', value: $event.value })"
            >
              <template #label>Ship From Address</template>
            </address-autocomplete>
          </v-flex>
          <!-- Ship To Address -->
          <v-flex xs6>
            <address-autocomplete
              :object="shipToAddress"
              :filter="shipToFilter"
              :error="errors.shipToAddress.value"
              @updated="updateAddress({ key: 'shipTo', value: $event.value })"
            >
              <template #label>Ship To Address</template>
            </address-autocomplete>
          </v-flex>
          <!-- Purchaser Address -->
          <v-flex xs6>
            <address-autocomplete
              :object="purchaseAddress"
              :filter="billToFilter"
              :error="errors.purchaseAddress.value"
              @updated="updateAddress({ key: 'purchase', value: $event.value })"
            >
              <template #label>Billing Address</template>
            </address-autocomplete>
          </v-flex>
        </v-layout>
      </v-container>
    </template>

    <template #header2>PRODUCT INFO</template>
    <template #panel2>
        <add-parts-set
          :parts="parts"
          :error="errors.parts.value"
          :attach="false"
          order="quote"
        />
    </template>

    <template #dataActions>
      <!-- Cancel -->
      <cancel-button :success="succeeded" @click="cancel"/>
      <!-- Submit -->
      <submit-button :disabled="succeeded" :loading="saving" @click="save"/>
    </template>
  </new-data-form>
</template>

<script>
import commonAutofillFilters from '../mixins/commonAutofillFilters'
import moneyFormatter from '../mixins/moneyFormatter'
import mutateQuotes from '@/api/graphql/mutationsJS/mutateQuotes'
import addPartsTemplate from '@/components/templates/addPartsTemplate'
import companies from '@/components/autocompletes/companies'
import contacts from '@/components/autocompletes/contacts'
import formTemplate from '@/components/forms/formTemplate'
import { ValidateSingleLevelObject } from '@/lib/validation'
import { CREATE_QUOTE_PARTS } from '@/api/graphql/Constants/Quotes'
import AddressesAutocomplete from '../autocompletes/AddressesAutocomplete.vue'
import { GetDjangoMoneyCompatibleInput } from '@/lib/moneyHelpers'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
export default {
  name: 'newQuote',
  mixins: [commonAutofillFilters, moneyFormatter, mutateQuotes],
  components: {
    'add-parts-set': addPartsTemplate,
    'companies': companies,
    'contacts': contacts,
    'new-data-form': formTemplate,
    'address-autocomplete': AddressesAutocomplete,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  computed: {
    addressErrors () {
      for (const key of ['shipTo', 'shipFrom', 'purchase']) {
        if (this.errors[`${key}Address`].value) return true
      }
      return false
    },

    footerMessage () {
      if (this.hasErrors) {
        return 'Fix errors to continue.'
      } else if (this.succeeded) {
        return 'Successfully created quote.'
      } else if (this.failed) {
        return 'Error has occurred and has been logged.'
      }
      return 'New Quote'
    },

    hasErrors () {
      return !!this.errors.client.value || !!this.errors.contact.value || !!this.errors.parts.value
    },

    clientID () {
      return this.client?.id ?? -1
    },

    contactID () {
      return this.contact?.id ?? 0
    },

    contactOwner () {
      return this.contact?.owner?.id ?? 0
    },

    parts () {
      return this.$store.state.quotes.parts
    },

    shipToFilter () {
      return {
        key: 'client__id',
        value: this.clientID
      }
    },

    shipFromFilter () {
      return {
        key: 'company__isnull',
        value: false
      }
    },

    billToFilter () {
      return this.shipToFilter
    }
  },
  watch: {
    parts: function (value) {
      if (value.length > 0) {
        this.errors.parts.value = ''
      }
    }
  },
  data () {
    return {
      type: '',
      panel: [],
      succeeded: false,
      failed: false,
      errors: {
        client: { value: '', validator: e => { return e?.id ? '' : 'This is required.' } },
        contact: { value: '', validator: () => { return '' } },
        parts: { value: '', validator: e => { return e.length > 0 ? '' : 'Need to add parts to this quote.' } },
        shipToAddress: {
          value: '',
          validator: e => e?.id ? '' : 'Required'
        },
        shipFromAddress: {
          value: '',
          validator: e => e?.id ? '' : 'Required'
        },
        purchaseAddress: {
          value: '',
          validator: e => e?.id ? '' : 'Required'
        }
      },
      saving: false,
      /**
       * Array of objects with keys: {
       ** part: partID,
       ** quantity,
       ** price_per_part,
       ** quote: quoteID
       * }
       */
      client: {},
      loadingClients: false,
      clientConfig: {
        label: 'Select a client'
      },
      contact: {},
      loadingContacts: false,
      contactConfig: {
        label: 'Select a contact'
      },
      shipToAddress: { id: '', street_1: '' },
      shipFromAddress: { id: '', street_1: '' },
      purchaseAddress: { id: '', street_1: '' }
    }
  },
  methods: {
    async save () {
      this.validateInfo()
      if (!this.hasErrors) {
        this.saving = true
        const quote = {
          client_id: this.clientID,
          contact_id: this.contactID,
          type: 'ST',
          purchaser_address_id: this.purchaseAddress.id,
          ship_from_address_id: this.shipFromAddress.id,
          ship_to_address_id: this.shipToAddress.id,
          internal_comment: '',
          contract_comment: ''
        }

        try {
          const quoteId = await this.createQuote(quote)
          const quoteReadyParts = []
          for (const part of this.parts) {
            const index = quoteReadyParts.findIndex(p => {
              return p.part_id === part.id
            })
            if (index > -1) {
              quoteReadyParts[index].qty++
            } else {
              quoteReadyParts.push({
                part_id: part.id,
                price_per_part: GetDjangoMoneyCompatibleInput(part.soldFor),
                qty: 1,
                quote_id: quoteId
              })
            }
          }
          this.$apollo.mutate({
            mutation: CREATE_QUOTE_PARTS,
            variables: { items: quoteReadyParts }
          }).then(() => {
            this.saving = false
            this.succeeded = true
          }).catch(() => {
            this.saving = false
            this.failed = true
          })
        } catch {
          this.failed = true
          this.saving = false
        }
      }
    },

    updateAddress ({ key, value }) {
      this[`${key}Address`] = value
      this.errors[`${key}Address`].value = ''
    },

    validateInfo () {
      ValidateSingleLevelObject(this.errors, this)
    },

    updateClient (obj) {
      this.client = obj.value
      this.errors.client.value = ''
    },

    updateContact (event) {
      this.contact = event.value
      this.errors.contact.value = ''
    },

    cancel () {
      this.$store.dispatch('grid/resetSheet')
      this.$store.dispatch('quotes/reset')
    }
  }
}
</script>
