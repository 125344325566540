import gql from 'graphql-tag'
import { FRAGMENT__MONEY } from '@/api/graphql/Constants/TERMS'
import { apolloClient as apollo } from '@/api/graphql/apollo'
import {
  Mutation, MutationUpdate__Invoices_ApInvoiceArgs, MutationUpdate__Invoices_ArPaymentArgs
} from '@/models/generated/graphql/ErpBackend'

/* FRAGMENTS */

export const FRAGMENT__ACCOUNTING_ACCOUNT__LIST = gql`
  fragment Accounting_Account__List on Accounting_Account {
    id
    account_number
    description
    name
  }
`

export const FRAGMENT__ACCOUNTING_ACCOUNT__GRID = gql`
  fragment Accounting_Account__Grid on Accounting_Account {
    ...Accounting_Account__List
    account_total { ...Money }
    account_type
    total_credit { ...Money }
    total_debit { ...Money }
  }
  ${FRAGMENT__ACCOUNTING_ACCOUNT__LIST}
  ${FRAGMENT__MONEY}
`

export const FRAGMENT__ACCOUNTING_TRANSACTION__GRID = gql`
  fragment Accounting_Transaction__Grid on Accounting_Transaction {
    creation_date
    creator { id full_name initials }
    id
    internal_reference
    is_balanced
    is_compound
    reference
  }
`

export const FRAGMENT__ACCOUNTING_ENTRY__GRID = gql`
  fragment Accounting_Entry__GRID on Accounting_Entry {
    account { id name account_number }
    amount { ...Money }
    creation_date
    creator { id full_name initials }
    entry_type
    id
    note
    transaction { id reference }
  }
  ${FRAGMENT__MONEY}
`

export const FRAGMENT__INVOICE_AR__FULL = gql`
  fragment Invoice_AR__FULL on Invoices_ARInvoice {
    amount { ...Money }
    creation_date
    creator { id initials }
    date_due
    date_sent
    id
    is_voided
    order {
      id
      rep { id initials }
      client { id name short_name }
    }
    status {
      id
      status
    }
    updated_date
  }
  ${FRAGMENT__MONEY}
`

export const FRAGMENT__INVOICE_AP__FULL = gql`
  fragment Invoice_AP__FULL on Invoices_APInvoice {
    amount { ...Money }
    creation_date
    creator { id initials }
    date_due
    date_sent
    id
    is_voided
    order {
      id
      rep { id initials }
      client { id name short_name }
    }
#    paid_not_posted { ...Money }
#    paid_posted { ...Money }
    status {
      id
      status
    }
#    total_paid { ...Money }
    updated_date
  }
  ${FRAGMENT__MONEY}
`

export const FRAGMENT__INVOICE_AR_PAYMENT__FULL = gql`
  fragment Invoice_AR_Payment__Full on Invoices_ARPayment {
    amount { ...Money }
    creation_date
    creator { id initials }
    id
    is_posted
    reference
    updated_date
  }
  ${FRAGMENT__MONEY}
`

export const FRAGMENT__INVOICE_AP_PAYMENT__FULL = gql`
  fragment Invoice_AP_Payment__Full on Invoices_APPayment {
    amount { ...Money }
    creation_date
    creator { id initials }
    id
    is_posted
    reference
    updated_date
  }
  ${FRAGMENT__MONEY}
`

/* QUERIES */

export const GET_ACCOUNTING_TRANSACTIONS__GRID = gql`
  query GetAccountingTransactions_Grid ($input: ManyQueryInput!) {
    accounting_transactions (input: $input) {
      annotations { name value }
      ...Accounting_Transaction__Grid
    }
  }
  ${FRAGMENT__ACCOUNTING_TRANSACTION__GRID}
`

export const GET_ACCOUNTING_ENTRIES__GRID = gql`
  query GetAccountingEntries_Grid ($input: ManyQueryInput!) {
    accounting_entrys (input: $input) {
      ...Accounting_Entry__GRID
      annotations { name value }
    }
  }
  ${FRAGMENT__ACCOUNTING_ENTRY__GRID}
`

export const GET_ACCOUNTING_ACCOUNTS__LIST = gql`
  query Get_Accounting_Accounts__List ($input: ManyQueryInput!) {
    accounting_accounts (input: $input) {
      ...Accounting_Account__List
    }
  }
  ${FRAGMENT__ACCOUNTING_ACCOUNT__LIST}
`

export const GET_ACCOUNTING_ACCOUNTS__GRID = gql`
  query Get_Accounting_Accounts__Grid ($input: ManyQueryInput!) {
    accounting_accounts (input: $input) {
      ...Accounting_Account__Grid
      annotations { name value }
    }
  }
  ${FRAGMENT__ACCOUNTING_ACCOUNT__GRID}
`

export const GET_ACCOUNTING_AP_INVOICES__FULL = gql`
  query Get_Accounting_AP_Invoices__Full ($input: ManyQueryInput!) {
    invoices_a_p_invoices (input: $input) {
      ...Invoice_AP__FULL
      annotations { name value }
    }
  }
  ${FRAGMENT__INVOICE_AP__FULL}
`

export const GET_ACCOUNTING_AR_INVOICES__FULL = gql`
  query Get_Accounting_AR_Invoices__Full ($input: ManyQueryInput!) {
    invoices_a_r_invoices (input: $input) {
      ...Invoice_AR__FULL
      annotations { name value }
    }
  }
  ${FRAGMENT__INVOICE_AR__FULL}
`

export const GET_ACCOUNTING_INVOICE_FOR_ST = gql`
  query Get_Accounting_Invoice_For_ST ($st: Any!) {
    invoices_a_r_invoices (input: { filters: [{ key: "order__id", value: $st }]}) { id }
  }
`

export const GET_INVOICE_PAYMENTS_FOR_AP_INVOICE__FULL = gql`
  query GetInvoicePaymentsForApInvoice ($input: ManyQueryInput!) {
    invoices: invoices_a_p_payments (input: $input) {
      ...Invoice_AP_Payment__Full
      annotations { name value }
    }
  }
  ${FRAGMENT__INVOICE_AP_PAYMENT__FULL}
`

export const GET_INVOICE_PAYMENTS_FOR_AR_INVOICE__FULL = gql`
  query GetInvoicePaymentsForArInvoice ($input: ManyQueryInput!) {
    invoices: invoices_a_r_payments (input: $input) {
      ...Invoice_AR_Payment__Full
      annotations { name value }
    }
  }
  ${FRAGMENT__INVOICE_AR_PAYMENT__FULL}
`

/* MUTATIONS */

export const CREATE_ACCOUNTING_ACCOUNT = gql`
  mutation Create_Accounting_Account ($input: Create__Accounting_Account__Input!) {
    Create__Accounting_Account (input: $input) {
      ...Accounting_Account__Grid
    }
  }
  ${FRAGMENT__ACCOUNTING_ACCOUNT__GRID}
`

export const UPDATE_ACCOUNTING_ACCOUNT = gql`
  mutation Update_Accounting_Account ($input: Update__Accounting_Account__Input!) {
    Update__Accounting_Account (input: $input) {
      ...Accounting_Account__Grid
    }
  }
  ${FRAGMENT__ACCOUNTING_ACCOUNT__GRID}
`

export const CREATE_ACCOUNTING_TRANSACTION = gql`
  mutation CreateAccountingTransaction ($input: Create__Accounting_Transaction__Input!) {
    Create__Accounting_Transaction (input: $input) {
      ...Accounting_Transaction__Grid
    }
  }
  ${FRAGMENT__ACCOUNTING_TRANSACTION__GRID}
`

export const CREATE_ACCOUNTING_ENTRY = gql`
  mutation CreateAccountingEntry ($input: Create__Accounting_Entry__Input!) {
    Create__Accounting_Entry (input: $input) {
      ...Accounting_Entry__GRID
    }
  }
  ${FRAGMENT__ACCOUNTING_ENTRY__GRID}
`

export const CREATE__ACCOUNTING_AR_INVOICE = gql`
  mutation CreateAccountingArInvoice ($input: Create__Invoices_ARInvoice__Input!) {
    Create__Invoices_ARInvoice (input: $input) {
      ...Invoice_AR__FULL
    }
  }
  ${FRAGMENT__INVOICE_AR__FULL}
`

export const UPDATE__AR_INVOICE = gql`
  mutation UpdateAccountingArInvoice ($input: Update__Invoices_ARInvoice__Input!) {
    Update__Invoices_ARInvoice (input: $input) {
      ...Invoice_AR__FULL
    }
  }
  ${FRAGMENT__INVOICE_AR__FULL}
`

export const CREATE__ACCOUNTING_AP_INVOICE = gql`
  mutation CreateAccountingApInvoice ($input: Create__Invoices_APInvoice__Input!) {
    Create__Invoices_APInvoice (input: $input) {
      ...Invoice_AP__FULL
    }
  }
  ${FRAGMENT__INVOICE_AP__FULL}
`

export const UPDATE__AP_INVOICE = gql`
  mutation UpdateAccountingApInvoice ($input: Update__Invoices_APInvoice__Input!) {
    Update__Invoices_APInvoice (input: $input) {
      ...Invoice_AP__FULL
    }
  }
  ${FRAGMENT__INVOICE_AR__FULL}
`

export const CREATE_INVOICE_AP_PAYMENT = gql`
  mutation CreateInvoiceAPPayment ($input: Create__Invoices_APPayment__Input!) {
    invoice: Create__Invoices_APPayment (input: $input) {
        ...Invoice_AP_Payment__Full
    }
  }
  ${FRAGMENT__INVOICE_AP_PAYMENT__FULL}
`

export const CREATE_INVOICE_AR_PAYMENT = gql`
  mutation CreateInvoiceARPayment ($input: Create__Invoices_ARPayment__Input!) {
    invoice: Create__Invoices_ARPayment (input: $input) {
      ...Invoice_AR_Payment__Full
    }
  }
  ${FRAGMENT__INVOICE_AR_PAYMENT__FULL}
`

export const UPDATE_AP_INVOICE_PAYMENT = gql`
  mutation UpdateInvoiceAPPayment ($input: Update__Invoices_APPayment__Input!) {
    invoice: Update__Invoices_APPayment (input: $input) {
        ...Invoice_AP_Payment__Full
    }
  }
  ${FRAGMENT__INVOICE_AP_PAYMENT__FULL}
`

export const UPDATE_AR_INVOICE_PAYMENT = gql`
  mutation UpdateInvoiceARPayment ($input: Update__Invoices_ARPayment__Input!) {
    invoice: Update__Invoices_ARPayment (input: $input) {
      ...Invoice_AR_Payment__Full
    }
  }
  ${FRAGMENT__INVOICE_AR_PAYMENT__FULL}
`

/* HELPERS */

export async function MarkPaymentAsPosted (id: string, posted: boolean, type: 'AR' | 'AP') {
  const mutation = type === 'AR' ? UPDATE_AR_INVOICE_PAYMENT : UPDATE_AP_INVOICE_PAYMENT
  try {
    await apollo.mutate<Mutation, MutationUpdate__Invoices_ArPaymentArgs>({
      mutation: mutation,
      variables: { input: { id: id, is_posted: !posted } }
    })
  } catch {
    throw new Error('Could not mark payment as posted')
  }
}

export async function VoidInvoice (id: string, type: 'AR' | 'AP', voided: boolean) {
  try {
    await apollo.mutate<Mutation, MutationUpdate__Invoices_ApInvoiceArgs>({
      mutation: type === 'AR' ? UPDATE__AR_INVOICE : UPDATE__AP_INVOICE,
      variables: { input: { id: id, is_voided: !voided } }
    })
  } catch {
    throw new Error('Could not void invoice')
  }
}
