<template>
  <v-autocomplete
    v-model="user"
    data-cy="user-field"
    :items="users"
    :loading="$apollo.loading"
    :error-messages="error"
    :placeholder="placeholder"
    item-text="title"
    item-value="id"
  >
    <template #prepend>
      <slot name="prepend-icon"></slot>
    </template>
    <template #message="{ message, key }">
      <span :key="key" v-html="message"/>
    </template>
    <template #label>
      <slot name="label">
        User
      </slot>
    </template>
    <template #item="{ item }"
    >
      <v-list-tile-avatar
        :color="item.profile.color"
        size="18"
      />
      <v-list-tile-content>
        <v-list-tile-title> {{ niceCaps(item['title']) }} </v-list-tile-title>
        <v-list-tile-sub-title>{{ item['subtitle'] }}</v-list-tile-sub-title>
      </v-list-tile-content>
    </template>
    <template
      #no-data
    >
      <v-list-tile v-if="$apollo.loading">
        <v-list-tile-title>
          Loading users...
        </v-list-tile-title>
      </v-list-tile>
      <v-list-tile v-else>
        <v-list-tile-title>
          No users found, this is most likely the result of an error.
        </v-list-tile-title>
      </v-list-tile>
    </template>
  </v-autocomplete>
</template>

<script>
import { GET_USERS_FOR_AUTOFILL } from '@/api/graphql/Constants/Users'
import { CapIt } from '@/lib/helpers'

export default {
  name: 'UserAutofill',
  props: {
    userId: {
      type: [String, Number],
      required: true
    },
    error: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    user: {
      get () {
        return this.userId
      },
      set (value) {
        this.$emit('updated', { value: value })
      }
    }
  },
  data () {
    return {
      users: []
    }
  },
  apollo: {
    users: {
      query: GET_USERS_FOR_AUTOFILL,
      fetchPolicy: 'cache-and-network'
    }
  },
  methods: {
    niceCaps (name) {
      return CapIt(name)
    }
  }
}
</script>
