import gql from 'graphql-tag'

/* FRAGMENTS */

export const FRAGMENT__TERMS__LIST = gql`
  fragment Terms_List on Clients_Terms {
    id
    name
    description
    days
    default_terms_for_company { name }
  }
`

export const FRAGMENT__MONEY = gql`
  fragment Money on Money {
    pretty
    amount
    currency { name numeric code }
  }
`

/* HELPERS */

/* QUERIES */

export const GET_TERMS__LIST = gql`
  query Terms_List {
    clients_terms (input: {}) {
      ...Terms_List
    }
  }
  ${FRAGMENT__TERMS__LIST}
`

export const TERMS = gql`
  query Terms {
    terms: clients_terms (input: {}) {
      ...Terms_List
    }
  }
  ${FRAGMENT__TERMS__LIST}
`

export const TERMS_FOR_AUTOCOMPLETE = gql`
  query TermsForAutoComplete {
    terms: clients_terms (input: { limit: 1000, order_by: "name" }) {
      id
      title: name
      subtitle: description
      default_terms_for_new_client { id }
    }
  }
`

/* MUTATIONS */

export const CREATE_TERM = gql`
  mutation CreateTerms ($name: String!, $description: String, $days: PositiveInteger) {
    term: Create__Clients_Terms (input: { name: $name, description: $description, days: $days }) {
      id
      days
      description
      name
    }
  }
`
export const UPDATE_TERM = gql`
  mutation UpdateTerm ($input: Update__Clients_Terms__Input!) {
    Update__Clients_Terms (input: $input) {
      id
    }
  }
`

export const DELETE_TERM = gql`
  mutation DeleteTerm ($id: ID!) {
    term: Delete__Clients_Terms (id: $id) {
      id
    }
  }
`
