<template>
  <new-data-form
    :errors="{
      panel0: infoErrors,
      panel1: false,
    }"
    :failed="failed"
    :succeeded="succeeded"
    :footer-message="footerMessage"
  >
    <template #header0> Part Info </template>
    <template #panel0>
      <v-container grid-list-xl>
        <v-form ref="newPart">
          <v-layout row wrap justify-start align-center>
            <!-- Part Number -->
            <v-flex xs6 md4>
              <v-text-field
                v-model="pn"
                box
                counter="30"
                label="Part Number"
                data-cy="part-number"
                hint="Will always be uppercase"
                :rules="partNumberRules"
                :error-messages="errors.pn.value"
                @blur="verifyPartNumber"
              ></v-text-field>
            </v-flex>
            <!-- Confirm Part Number -->
            <v-flex xs6 md4>
              <v-text-field
                v-model="pnConfirm"
                box
                counter="30"
                validate-on-blur
                label="Confirm Part Number"
                data-cy="part-number-confirm"
                hint="Will always be uppercase"
                :rules="partNumberConfirmRules"
                :error-messages="errors.pnConfirm.value"
                @blur="verifyPartNumber"
              ></v-text-field>
            </v-flex>
            <!-- Secondary Part Number -->
            <v-flex xs6 md4>
              <v-text-field
                box
                v-model="secondaryPN"
                data-cy="secondary-part-number"
                label="Secondary Part Number"
                placeholder="Optional"
                counter="30"
                @blur="verifyPartNumber"
              ></v-text-field>
            </v-flex>
            <!-- Description -->
            <v-flex xs6 md4>
              <v-text-field
                v-model="description"
                data-cy="part-description"
                box
                label="Description"
                :error-messages="errors.description.value"
              ></v-text-field>
            </v-flex>
            <!-- Has Battery -->
            <v-flex xs3>
              <v-checkbox
                v-model="battery"
                data-cy="battery-checkbox"
                label="Has Battery?"
                color="#2E7D32"
              ></v-checkbox>
            </v-flex>
            <!-- Brand -->
            <v-flex xs4 md3>
              <product-company-list :label="brandLabel" :error="errors.brandId.value" @updated="brandId = $event"/>
            </v-flex>
            <!-- Market -->
            <v-flex xs4 md3>
              <choice-list
                data-cy="market"
                :id="marketId"
                :error="errors.marketId.value"
                :config="marketConfig"
                @updated="marketId = $event.value"
              />
            </v-flex>
            <!-- Part Type -->
            <v-flex xs4 md3>
              <part-categories :types="partTypes" @updated="updatePartType"/>
            </v-flex>
          </v-layout>
        </v-form>
        <span v-if="isUnique === false" class="error--text">
          A part with the same <b>part number</b> AND <b>secondary part number</b> already exists. <br>
          Please enter in a different <b>part number</b> or <b>secondary part number</b>
        </span>
      </v-container>
    </template>

    <template #header1 v-if="twoParts"> {{ detailsComponentName }} Info</template>
    <template #panel1 v-if="twoParts">
      <v-container py-0>
        <v-scale-transition mode="out-in">
          <component
            :is="detailsComponent.component"
          >
            <template #actions><span style="display: none;"></span></template>
          </component>
        </v-scale-transition>
      </v-container>
    </template>

    <template #dataActions>
      <!-- Cancel -->
      <cancel-button :success="succeeded" @click="cancel"/>
      <!-- Submit -->
      <submit-button :loading="saving" :disabled="saving || succeeded" @click="submit"/>
    </template>
  </new-data-form>
</template>

<script>
import storage from '../templates/partTypes/storage'
import memory from '../templates/partTypes/memory'
import networkSwitch from '../templates/partTypes/networkSwitch'
import networkRouter from '../templates/partTypes/networkRouter'
import accessPoint from '../templates/partTypes/accessPoint'
import ipPhone from '../templates/partTypes/ipPhone'
import powerSupply from '../templates/partTypes/powerSupply'
import cpu from '../templates/partTypes/cpu'
import gpu from '../templates/partTypes/gpu'
import server from '../templates/partTypes/server'
import motherboard from '../templates/partTypes/motherboard'
import cable from '../templates/partTypes/cable'
import supplies from '../templates/partTypes/supplies'
import expansionCard from '../templates/partTypes/expansionCard'
import networkCard from '../templates/partTypes/networkCard'
import diskDrive from '../templates/partTypes/diskDrive'
import storageEnclosure from '../templates/partTypes/storageEnclosure'
import bezelAndRails from '../templates/partTypes/bezelAndRails'
import formTemplate from '@/components/forms/formTemplate'
import choices2 from '@/components/autocompletes/choices2'
import partTypes from '@/components/autocompletes/partTypes'
import productCompany from '@/components/autocompletes/productCompany'
import {
  CREATE_ACCESS_POINT,
  CREATE_BATTERY,
  CREATE_BEZEL,
  CREATE_BRACKET,
  CREATE_CABLE,
  CREATE_CACHE,
  CREATE_CPU,
  CREATE_DISK_DRIVE,
  CREATE_EXPANSION_CARD,
  CREATE_FAN,
  CREATE_GPU,
  CREATE_HEAT_SINK,
  CREATE_IO_MODULE,
  CREATE_MEMORY,
  CREATE_MOTHERBOARD,
  CREATE_NETWORK_CARD,
  CREATE_PHONE,
  CREATE_POWER_SUPPLY,
  CREATE_RAILS,
  CREATE_ROUTER,
  CREATE_SERVER,
  CREATE_STORAGE,
  CREATE_STORAGE_ENCLOSURE,
  CREATE_SUPPLIES, CREATE_SWITCH, GET_PARTS_FOR_AUTOFILL, MASTER_PARTS
} from '../../api/graphql/Constants/Inventory'
import { ValidateSingleLevelObject } from '../../lib/validation'
import CancelButton from '../buttons/CancelButton.vue'
import SubmitButton from '../buttons/SubmitButton.vue'

export default {
  name: 'newPart',
  components: {
    'new-data-form': formTemplate,
    'choice-list': choices2,
    'part-categories': partTypes,
    'product-company-list': productCompany,
    // part types
    'storage': storage,
    'memory': memory,
    'networkSwitch': networkSwitch,
    'networkRouter': networkRouter,
    'accessPoint': accessPoint,
    'ipPhone': ipPhone,
    'powerSupply': powerSupply,
    'cpu': cpu,
    'gpu': gpu,
    'server': server,
    'motherboard': motherboard,
    'cable': cable,
    'supplies': supplies,
    'expansionCard': expansionCard,
    'networkCard': networkCard,
    'diskDrive': diskDrive,
    'storageEnclosure': storageEnclosure,
    'bezelAndRails': bezelAndRails,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  computed: {
    detailsComponentName () {
      return this.detailsComponent?.name ?? ''
    },

    detailsComponent () {
      return this.partTypes.find(p => p.name === this.type) || { name: '', mutation: '', component: '' }
    },

    twoParts () {
      return false // this.submittableParts.indexOf(this.detailsComponent.component) === -1
    },

    infoErrors () {
      for (const key in this.errors) {
        if (this.errors[key].value) return true
      }
      return false
    }
  },
  watch: {
    pn (value) {
      this.pn = value.toUpperCase()
      this.isUnique = null
    },

    pnConfirm (value) {
      this.pnConfirm = value.toUpperCase()
      this.isUnique = null
    },

    secondaryPN (value) {
      this.secondaryPN = value.toUpperCase()
      this.isUnique = null
    }
  },
  data () {
    return {
      errors: {
        pn: { value: '', validator: e => e ? '' : 'Enter a part number' },
        pnConfirm: { value: '', validator: e => e === this.pn ? '' : 'Part Numbers must match!' },
        description: { value: '', validator: e => e ? '' : 'Enter a description' },
        brandId: {
          value: '',
          validator: e => {
            if (this.type !== 'Supplies') {
              return e ? '' : 'This is required'
            } else return ''
          }
        },
        marketId: { value: '', validator: e => e ? '' : 'This is required' },
        type: { value: '', validator: e => e ? '' : 'This is required' }
      },

      footerMessage: 'Create a New Part',
      panel: [],
      succeeded: false,
      failed: false,
      saving: false,
      baseDetails: {},
      subRoute: '',
      submittableParts: [
        'heatSink',
        'fan',
        'bracket',
        'cache',
        'battery',
        'ioModule',
        'misc'
      ],

      partTypes: [
        { name: 'Access Point', mutation: CREATE_ACCESS_POINT, component: 'accessPoint' },
        { name: 'Battery', mutation: CREATE_BATTERY, component: 'battery' },
        { name: 'Bezel', mutation: CREATE_BEZEL, component: 'bezelAndRails' },
        { name: 'Bracket', mutation: CREATE_BRACKET, component: 'bracket' },
        { name: 'Cable', mutation: CREATE_CABLE, component: 'cable' },
        { name: 'Cache', mutation: CREATE_CACHE, component: 'cache' },
        { name: 'CPU', mutation: CREATE_CPU, component: 'cpu' },
        { name: 'Disk Drive', mutation: CREATE_DISK_DRIVE, component: 'diskDrive' },
        { name: 'Expansion Card', mutation: CREATE_EXPANSION_CARD, component: 'expansionCard' },
        { name: 'Fan', mutation: CREATE_FAN, component: 'fan' },
        { name: 'GPU', mutation: CREATE_GPU, component: 'gpu' },
        { name: 'Heat Sink', mutation: CREATE_HEAT_SINK, component: 'heatSink' },
        { name: 'IO Module', mutation: CREATE_IO_MODULE, component: 'ioModule' },
        { name: 'Memory', mutation: CREATE_MEMORY, component: 'memory' },
        // { name: 'Miscellaneous', mutation: CREATE, component: 'misc' },
        { name: 'Motherboard', mutation: CREATE_MOTHERBOARD, component: 'motherboard' },
        { name: 'Network Card', mutation: CREATE_NETWORK_CARD, component: 'networkCard' },
        { name: 'IP Phone', mutation: CREATE_PHONE, component: 'ipPhone' },
        { name: 'Power Supply', mutation: CREATE_POWER_SUPPLY, component: 'powerSupply' },
        { name: 'Rails', mutation: CREATE_RAILS, component: 'bezelAndRails' },
        { name: 'Router', mutation: CREATE_ROUTER, component: 'networkRouter' },
        { name: 'Server', mutation: CREATE_SERVER, component: 'server' },
        { name: 'Storage', mutation: CREATE_STORAGE, component: 'storage' },
        { name: 'Storage Enclosure', mutation: CREATE_STORAGE_ENCLOSURE, component: 'storageEnclosure' },
        { name: 'Supplies', mutation: CREATE_SUPPLIES, component: 'supplies' },
        { name: 'Switch', mutation: CREATE_SWITCH, component: 'networkSwitch' }
      ],

      // generic part information
      pn: '',
      pnConfirm: '',
      secondaryPN: '',
      isUnique: null,

      partNumberRules: [
        e => !!e || 'Enter a part number'
      ],
      partNumberConfirmRules: [
        e => e === this.pn || 'Part Numbers must match'
      ],

      brandId: 0,
      brandLabel: 'Brands',

      marketId: 0,
      marketConfig: {
        label: 'Market',
        appName: 'parts',
        modelName: 'part',
        fieldName: 'market',
        name: 'marketId'
      },

      battery: false,

      type: '',

      description: ''
    }
  },
  methods: {
    async submit (details) {
      ValidateSingleLevelObject(this.errors, this)

      if (!this.infoErrors && this.isUnique) {
        this.saving = true
        const payload = {
          pn: this.pn,
          secondary_pn: this.secondaryPN,
          description: this.description,
          ...(this.brandId && { brand_id: this.brandId }),
          market_id: this.marketId,
          has_battery: this.battery,
          type: this.type,
          ...details
        }

        this.$apollo.mutate({
          mutation: this.detailsComponent.mutation,
          variables: { input: payload }
        })
          .then(({ data: { part } }) => {
            try {
              const data = this.$apollo.getClient().readQuery({
                query: MASTER_PARTS,
                variables: {
                  input: {
                    annotations: [],
                    excludes: [],
                    filters: [],
                    limit: 51,
                    offset: 0,
                    order_by: []
                  }
                }
              })
              data.parts.unshift(part)
              this.$apollo.getClient().writeQuery({
                query: MASTER_PARTS,
                variables: {
                  input: {
                    annotations: [],
                    excludes: [],
                    filters: [],
                    limit: 51,
                    offset: 0,
                    order_by: []
                  }
                },
                data: data
              })
            } catch {
              this.$store.dispatch('data/changeRefresh', { bool: true })
            } finally {
              this.footerMessage = 'Created part successfully.'
              this.succeeded = true
            }
          })
          .catch(() => {
            this.footerMessage = 'Could not create part, check info and try again.'
            this.failed = true
          })
          .finally(() => { this.saving = false })
      }
    },

    async verifyPartNumber () {
      if (this.pn && this.pnConfirm && this.isUnique === null && this.pn === this.pnConfirm) {
        const secondaryFilter = this.secondaryPN === ''
          ? { key: 'secondary_pn', value: this.secondaryPN, or: { key: 'secondary_pn__isnull', value: true } }
          : { key: 'secondary_pn', value: this.secondaryPN }
        const query = {
          query: GET_PARTS_FOR_AUTOFILL,
          variables: {
            filters: [{ key: 'pn', value: this.pn }, secondaryFilter]
          }
        }
        try {
          const response = await this.$apollo.query(query)
          const parts = response.data?.parts ?? []
          if (parts.length > 0) {
            this.isUnique = false
          } else {
            this.isUnique = true
          }
        } catch (error) {
          console.log(error)
        }
      }
    },

    cancel () {
      this.$store.commit('grid/resetSheet')
    },

    updatePartType (event) {
      this.type = event.value
    }
  }
}
</script>
