import store from '@/store/store'

export enum LOCAL_STORAGE_KEYS {
  accessToken = 'accessToken',
  dateFields = 'dateFields',
  defaultTenant = 'defaultTenant',
  errorCache = 'error-cache',
  expire = 'expire',
  isTesting = 'isTesting',
  lastActiveUrl = 'lastActiveUrl',
  localInitials = 'localInitials',
  refreshToken = 'refreshToken',
  tenant = 'tenant',
  tenants = 'tenants',
  types = 'types'
}

interface LocStoreState {
  persist: LOCAL_STORAGE_KEYS[] // used to store key's of cached data that needs to persist after logout
}

const state: LocStoreState = {
  persist: [
    LOCAL_STORAGE_KEYS.lastActiveUrl,
    LOCAL_STORAGE_KEYS.defaultTenant
  ]
}

function GrabTenantsAndSaveInVuex () {
  const tenants: string[] = locStore.get(LOCAL_STORAGE_KEYS.tenants) as string[]
  if (tenants !== undefined && tenants.length > 0) {
    store.dispatch('auth/updateState', { key: 'tenants', value: tenants })
  }
}

function GrabDateFieldsAndSaveInVuex () {
  const fields = locStore.get(LOCAL_STORAGE_KEYS.dateFields) as Array<any>
  if (fields !== undefined && fields.length > 0) {
    store.commit('data/setDateFields', { fields: fields })
  }
}

function Get (key: LOCAL_STORAGE_KEYS): any {
  const value = window.localStorage.getItem(key)
  if ((value?.indexOf('"') ?? -1) > -1) {
    return JSON.parse(localStorage[key])
  }
  return value ?? ''
}

function Set (key: LOCAL_STORAGE_KEYS, value: any, persist: boolean = false) {
  const v = key === 'tenant' ? value.toLowerCase() : value
  if (value !== null && value !== 'null') localStorage.setItem(key, JSON.stringify(v))
}

export const locStore = {
  get: (key: LOCAL_STORAGE_KEYS) => Get(key),
  set: (key: LOCAL_STORAGE_KEYS, value: any, persist: boolean = false) => Set(key, value, persist),
  remove: (key: string) => localStorage.removeItem(key),
  onMount: () => {
    GrabTenantsAndSaveInVuex()
    GrabDateFieldsAndSaveInVuex()
  },
  resetTokens: () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
  },
  clear: () => {
    for (const key in localStorage) {
      // @ts-ignore
      if (state.persist.indexOf(key) === -1) {
        localStorage.removeItem(key)
      }
    }
  }
}
