
























































































































































































































































































































































































import EnumAutocomplete from '@/components/autocompletes/EnumAutocomplete.vue'
import agDateRangePicker from '@/components/autocompletes/agDateRangePicker.vue'
import { computed, ComputedRef, defineComponent, reactive, Ref, ref, toRef, toRefs } from '@vue/composition-api'
import { fetchFurtherChildren, field, SELECTED_DATA_KEYS, UseReportBuilder } from '@/lib/UseReportBuilding'

export default defineComponent({
  name: 'Builder',
  components: {
    'enum-autocomplete': EnumAutocomplete,
    'date-range-picker': agDateRangePicker
  },
  // using REFS here is deprecated in vue3, using it here because in vue2 it is nice
  // @ts-ignore
  setup (_, { refs }) {
    const {
      domains,
      models,
      currentModel,
      shownModelFields,
      isLoadingModel,
      fields,
      activeFields,
      methods,
      filters,
      computedFilters,
      naturalLanguageDates,
      useNaturalDates,
      selected,
      noFilterConfigured,
      filterHalfConfigured,
      setSelectedData,
      setFilterField,
      resetSelectedData,
      resetSubSelectedData,
      type,
      message,
      allModels,
      canGenerateReport,
      canSaveReport,
      selectDomain,
      selectModel,
      selectMetric,
      setDatesFromPicker,
      setDatesFromNld,
      isLoadingThisModel,
      reportToBuild
    } = UseReportBuilder()
    const fieldSelect: Ref = ref(null)

    const selectedFieldName: ComputedRef<string[]> = computed(() => [selected.field.name ?? ''])
    const activeFieldName: Ref<string[]> = ref([])
    const shownFields: Ref<field[]> = computed(() => savedFields.value[0]?.children ?? [])
    const savedFields: Ref<field[]> = ref([])

    interface GuiState {
      breadcrumbs: any[],
      chartTypeHint: string,
      fieldSelectIsDisabled: boolean,
      filterHint: string,
      quickDates: boolean,
      chipText: string,
      tooltipHint: string,
      step: number,
      typeName: string
    }
    const guiState: GuiState = reactive({
      breadcrumbs: [],
      chartTypeHint: '<span class="error--text bold">Required</span>',
      fieldSelectIsDisabled: false,
      filterHint: '',
      quickDates: true,
      chipText: '',
      tooltipHint: '',
      step: 1,
      typeName: ''
    })
    // this is to make step reactive inside the setup function and to intentionally note
    // that it is part of GUI STATE
    const step = toRef(guiState, 'step')

    function incrementStep () {
      step.value++
      if (step.value === 3) {
        if (savedFields.value.length === 0) {
          savedFields.value = [{ children: shownModelFields.value, text: selected.model.text, name: selected.model.name }]
        }
      } else if (step.value === 4) {
        const fieldName = `${savedFields.value[0].name.replace(`${selected.model.name}__`, '')}__${selected.field.name}`
        selectMetric(fieldName, selected.method.name)
      }
    }

    function backAStep () {
      step.value--
      if (step.value < 3) {
        savedFields.value = []
        guiState.breadcrumbs = []
        guiState.fieldSelectIsDisabled = false
        resetSubSelectedData(SELECTED_DATA_KEYS.field)
        resetSubSelectedData(SELECTED_DATA_KEYS.method)
      } else if (step.value === 4) {
        type.value = ''
      }
    }

    function updateField (field: field) {
      if (field.children) {
        savedFields.value.unshift(field)
        resetSubSelectedData(SELECTED_DATA_KEYS.field)
      } else {
        guiState.fieldSelectIsDisabled = true
        // @ts-ignore
        if (refs.fieldSelect?.isMenuActive) {
          // @ts-ignore
          refs.fieldSelect.isMenuActive = false
          // @ts-ignore
          refs.fieldSelect.blur()
        } else {
          if ('activeElement' in document) {
            (document.activeElement as HTMLElement).blur()
          }
        }
        setSelectedData({ field: field })
        if (savedFields.value.length > 1) {
          guiState.filterHint = 'On ' + savedFields.value[0].text
        }
      }
      guiState.breadcrumbs.push({ ...field, disabled: true })
    }

    function removeCrumb (field: field) {
      const index = guiState.breadcrumbs.findIndex(f => f.name === field.name)
      if (index > -1) {
        guiState.breadcrumbs.splice(index, guiState.breadcrumbs.length)
        guiState.fieldSelectIsDisabled = false
        savedFields.value.splice(index + 1)
        resetSubSelectedData(SELECTED_DATA_KEYS.field)
      } else {
        savedFields.value = [{ children: shownModelFields.value, text: selected.model.text, name: selected.model.name }]
        guiState.breadcrumbs = []
      }
    }

    function setFilterToolTipHint (hint: string, text: string) {
      guiState.tooltipHint = hint
      guiState.chipText = text
    }

    function updateType (event: any) {
      type.value = event.value
    }

    return {
      savedFields,
      fieldSelect,
      shownFields,
      selectedFieldName,
      activeFieldName,
      // @ts-ignore
      ...toRefs(guiState),
      incrementStep,
      backAStep,
      updateField,
      removeCrumb,
      setFilterToolTipHint,
      updateType,
      fetchFurtherChildren,
      domains,
      models,
      currentModel,
      shownModelFields,
      isLoadingModel,
      fields,
      activeFields,
      methods,
      filters,
      computedFilters,
      naturalLanguageDates,
      useNaturalDates,
      selected,
      noFilterConfigured,
      filterHalfConfigured,
      setSelectedData,
      setFilterField,
      resetSelectedData,
      resetSubSelectedData,
      name,
      type,
      message,
      allModels,
      canGenerateReport,
      canSaveReport,
      selectDomain,
      selectModel,
      selectMetric,
      setDatesFromPicker,
      setDatesFromNld,
      isLoadingThisModel,
      reportToBuild
    }
  }
})
