<template>
  <date-range-picker :dates="dates" label="Picker" errors="''" @updated="setDate" @save="sendData">
  </date-range-picker>
</template>

<script>
import DateRangePicker from '@/components/autocompletes/agDateRangePicker'
import Vue from 'vue'
import { GetDatesFromStringSplitDates, GetEarliestDateFromList, GetLatestDateFromList } from '@/lib/Dates'
export default Vue.extend({
  name: 'DateFilter',
  components: {
    'date-range-picker': DateRangePicker
  },
  computed: {
    dateValue () {
      if (this.dates.length >= 2) {
        return `${GetEarliestDateFromList(this.dates)} - ${GetLatestDateFromList(this.dates)}`
      } else if (this.dates.length === 1) {
        return this.dates[0]
      }
      return ''
    }
  },
  data () {
    return {
      text: '',
      dates: [],
      label: 'Date Picker',
      filter: ''
    }
  },
  methods: {
    setModel (model) {
      if (model) {
        this.text = model.value
      }
    },

    getModel () {
      return { type: 'DATE' }
    },

    sendData (event) {
      this.filter = event.value
      this.params.parentFilterInstance(instance => {
        instance.onFloatingFilterChanged(event.value.grid, this.dateValue)
      })
    },

    onParentModelChanged (model) {
      this.dates = !model ? [] : GetDatesFromStringSplitDates(model.filter)
    },

    setDate (event) {
      this.dates = event.value
    }
  }
})
</script>
