<template>
    <v-list-tile v-ripple class="notification-border" :class="`notification-border--${color}`" @click="$emit('clicked')">
      <v-list-tile-content>
        <span>{{ details.title }}</span>
        <v-list-tile-sub-title>
          {{ details.info || 'Click here to learn more' }}
        </v-list-tile-sub-title>
      </v-list-tile-content>
      <v-list-tile-action>
        <v-list-tile-action-text>
          <v-btn
            icon
            @click.stop="$emit('read')"
          >
            <v-icon small color="primary">fal fa-times-circle</v-icon>
          </v-btn>
        </v-list-tile-action-text>
      </v-list-tile-action>
    </v-list-tile>
</template>

<script>
export default {
  name: 'Notification',
  props: {
    details: {
      /*
      {
        -title: the text of the notification,
        -info: details of the notification, usually a call to action
        -color: the color of the left border, should follow status colors
          i.e - success for good, primary for info, warning and error for those
        -query: primary key of data in component to search for,
        -action: the function to be executed on click,
        -type: the type of notification it is
      }
      */
      required: true,
      type: Object,
      validator: function (prop) {
        if (!prop.title) {
          return false
        }
        if (!prop.info) {
          return false
        }
        if (!prop.color) {
          prop.color = 'info'
        }
        if (!prop.route) {
          return false
        }
        return prop.query
      }
    }
  },
  computed: {
    color () {
      return this.details?.color ?? 'info'
    }
  }
}
</script>
