<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="600" height="100" viewBox="0 0 1845 209">
    <defs>
      <clipPath id="clip-path">
        <rect width="178" height="91" fill="none"/>
      </clipPath>
      <clipPath id="clip-Full_Status_Component_-Gradient">
        <rect width="1845" height="209"/>
      </clipPath>
    </defs>
    <g id="Full_Status_Component_-Gradient" data-name="Full Status Component -Gradient" clip-path="url(#clip-Full_Status_Component_-Gradient)">
      <rect width="1845" height="209" fill="none"/>
      <g id="Group_18" data-name="Group 18" transform="translate(-100 -82)">
        <g id="SaleStatus" transform="translate(-146 -354)">
          <circle id="saleAvatar" cx="104.5" cy="104.5" r="104.5" transform="translate(246 436)" :fill="avatarColor('sales')"/>
          <text id="saleName" transform="translate(286 562)" fill="#fff" font-size="60" text-anchor="middle" x="61" font-family="HelveticaNeue, Helvetica Neue">{{ avatarText('sales') }}</text>
        </g>
        <g id="csrStatus" transform="translate(-41 -354)">
          <circle id="csrAvatar" cx="104.5" cy="104.5" r="104.5" transform="translate(550 436)" :fill="avatarColor('csr')"/>
          <text id="csrName" transform="translate(594 562)" fill="#fff" font-size="60" font-family="HelveticaNeue, Helvetica Neue" text-anchor="middle" x="61">{{ avatarText('csr') }}</text>
        </g>
        <g id="ProcessStatus" transform="translate(59 -354)">
          <circle id="processAvatar" cx="104.5" cy="104.5" r="104.5" transform="translate(859 436)" :fill="avatarColor('process')"/>
          <text id="processName" transform="translate(902 562)" fill="#fff" font-size="60" font-family="HelveticaNeue, Helvetica Neue" text-anchor="middle" x="61">{{ avatarText('process') }}</text>
        </g>
        <g id="TechStatus" transform="translate(160 -354)">
          <circle id="techAvatar" cx="104.5" cy="104.5" r="104.5" transform="translate(1167 436)" :fill="avatarColor('tech')"/>
          <text id="techName" transform="translate(1211 562)" fill="#fff" font-size="60" font-family="HelveticaNeue, Helvetica Neue" text-anchor="middle" x="61">{{ avatarText('tech') }}</text>
        </g>
        <g id="ShipStatus" transform="translate(1491 -2)">
          <circle id="shipAvatar" cx="104.5" cy="104.5" r="104.5" transform="translate(245 84)" :fill="avatarColor('ship')"/>
          <text id="shipName" transform="translate(289 210)" fill="#fff" font-size="60" font-family="HelveticaNeue, Helvetica Neue" text-anchor="middle" x="61">{{ avatarText('ship') }}</text>
        </g>
        <g id="Repeat_Grid_1" data-name="Repeat Grid 1" transform="translate(320 141)" clip-path="url(#clip-path)">
          <g transform="translate(-330.5 -141.5)">
            <path id="Path_3" data-name="Path 3" d="M45.5-9.172,91,36,45.5,22.57,0,36Z" transform="translate(366.5 141.5) rotate(90)" :fill="chevColor1"/>
          </g>
          <g transform="translate(-264.5 -141.5)">
            <path id="Path_3-2" data-name="Path 3" d="M45.5-9.172,91,36,45.5,22.57,0,36Z" transform="translate(366.5 141.5) rotate(90)" :fill="chevColor2"/>
          </g>
          <g transform="translate(-198.5 -141.5)">
            <path id="Path_3-3" data-name="Path 3" d="M45.5-9.172,91,36,45.5,22.57,0,36Z" transform="translate(366.5 141.5) rotate(90)" :fill="chevColor3"/>
          </g>
        </g>
        <g id="Repeat_Grid_2" data-name="Repeat Grid 2" transform="translate(729 141)" clip-path="url(#clip-path)">
          <g transform="translate(-330.5 -141.5)">
            <path id="Path_3-4" data-name="Path 3" d="M45.5-9.172,91,36,45.5,22.57,0,36Z" transform="translate(366.5 141.5) rotate(90)" :fill="chevColors('process', '1')"/>
          </g>
          <g transform="translate(-264.5 -141.5)">
            <path id="Path_3-5" data-name="Path 3" d="M45.5-9.172,91,36,45.5,22.57,0,36Z" transform="translate(366.5 141.5) rotate(90)" :fill="chevColors('process', '2')"/>
          </g>
          <g transform="translate(-198.5 -141.5)">
            <path id="Path_3-6" data-name="Path 3" d="M45.5-9.172,91,36,45.5,22.57,0,36Z" transform="translate(366.5 141.5) rotate(90)" :fill="chevColors('process', '3')"/>
          </g>
        </g>
        <g id="Repeat_Grid_3" data-name="Repeat Grid 3" transform="translate(1138 141)" clip-path="url(#clip-path)">
          <g transform="translate(-330.5 -141.5)">
            <path id="Path_3-7" data-name="Path 3" d="M45.5-9.172,91,36,45.5,22.57,0,36Z" transform="translate(366.5 141.5) rotate(90)" :fill="chevColors('tech', '1')"/>
          </g>
          <g transform="translate(-264.5 -141.5)">
            <path id="Path_3-8" data-name="Path 3" d="M45.5-9.172,91,36,45.5,22.57,0,36Z" transform="translate(366.5 141.5) rotate(90)" :fill="chevColors('tech', '2')"/>
          </g>
          <g transform="translate(-198.5 -141.5)">
            <path id="Path_3-9" data-name="Path 3" d="M45.5-9.172,91,36,45.5,22.57,0,36Z" transform="translate(366.5 141.5) rotate(90)" :fill="chevColors('tech', '3')"/>
          </g>
        </g>
        <g id="Repeat_Grid_4" data-name="Repeat Grid 4" transform="translate(1547 141)" clip-path="url(#clip-path)">
          <g transform="translate(-330.5 -141.5)">
            <path id="Path_3-10" data-name="Path 3" d="M45.5-9.172,91,36,45.5,22.57,0,36Z" transform="translate(366.5 141.5) rotate(90)" :fill="chevColors('ship', '1')"/>
          </g>
          <g transform="translate(-264.5 -141.5)">
            <path id="Path_3-11" data-name="Path 3" d="M45.5-9.172,91,36,45.5,22.57,0,36Z" transform="translate(366.5 141.5) rotate(90)" :fill="chevColors('ship', '2')"/>
          </g>
          <g transform="translate(-198.5 -141.5)">
            <path id="Path_3-12" data-name="Path 3" d="M45.5-9.172,91,36,45.5,22.57,0,36Z" transform="translate(366.5 141.5) rotate(90)" :fill="chevColors('ship', '3')"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'orderStatus',
  /* props: {
    orderId: {
      type: Number,
      required: true
    }
  }, */
  data () {
    return {
      chevColor3: '#42A5F5',
      chevColor2: '#1976D2',
      chevColor1: '#0D47A1',
      sales: 'TJK',
      salesColor: '#004D40',
      csr: 'CFP',
      csrColor: '#00897B',
      process: 'AGL',
      processColor: '#C62828',
      tech: 'JTL',
      techColor: '#AD1457',
      ship: '',
      shipColor: '#FF8F00'
    }
  },
  methods: {
    chevColors (rep, gradient) {
      if (this[rep]) {
        const color = 'chevColor' + gradient
        return this[color]
      } else {
        return '#cfcfcf'
      }
    },

    avatarText (rep) {
      if (this[rep]) {
        return this[rep]
      } else {
        return '---'
      }
    },

    avatarColor (rep) {
      if (this[rep]) {
        return this[`${rep}Color`]
      } else {
        return '#BDBDBD'
      }
    }
  }
}
</script>

<style scoped>

</style>
