import { OrderFormObject, RequiredValidationRunner } from '@/composition/UseOrderInformation'
import { computed, reactive, Ref, watch } from '@vue/composition-api'

interface ContactFormObject extends OrderFormObject<string, undefined, string> {
  edited: (value: string) => boolean
}

export interface EditContactForm {
  [key: string]: ContactFormObject
  first_name: ContactFormObject,
  last_name: ContactFormObject,
  phone: ContactFormObject,
  email: ContactFormObject,
}

export function UseEditContact (validate: Ref<string>) {
  const form: EditContactForm = reactive({
    first_name: {
      value: '',
      message: '',
      setter: (value) => {
        form.first_name.value = value
        form.first_name.message = ''
      },
      validator: () => RequiredValidationRunner('PROCESS', form.first_name),
      edited: (value) => form.first_name.value !== value
    },
    last_name: {
      value: '',
      message: '',
      setter: (value) => {
        form.last_name.value = value
        form.last_name.message = ''
      },
      validator: () => RequiredValidationRunner('PROCESS', form.last_name),
      edited: (value) => form.last_name.value !== value
    },
    phone: {
      value: '',
      message: '',
      setter: (value) => {
        form.phone.value = value
        form.phone.message = ''
      },
      validator: () => RequiredValidationRunner('SAVE', form.phone),
      edited: (value) => form?.phone?.value !== value
    },
    email: {
      value: '',
      message: '',
      setter: (value) => {
        form.email.value = value
        form.email.message = ''
      },
      validator: () => {
        const email = form.email.value
        if (email) {
          if (/[A-Z0-9._%+-]+@[A-Z0-9.-]+[.]+.[a-z]{1,4}/igm.test(email)) {
            form.email.message = ''
          } else {
            form.email.message = 'This is not a valid email'
          }
        } else {
          form.email.message = ''
        }
      },
      edited: (value) => form?.email?.value !== value
    }
  })

  const hasErrors = computed(() => {
    for (const key of Object.keys(form)) {
      if (form[key].message !== '') return true
    } return false
  })

  watch(() => validate, () => {
    for (const key of Object.keys(form)) {
      form[key].validator('PROCESS')
    }
  })

  return {
    form,
    hasErrors
  }
}
