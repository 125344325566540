import { Address_Address, Create__Address_Address__Input, EasyPostAddress } from '@/models/generated/graphql/ErpBackend'

export function TranslateEasyPostAddressToGQLInput (address: EasyPostAddress): Create__Address_Address__Input {
  return {
    street_1: address.street1!,
    street_2: address.street2,
    city: address.city,
    state: address.state,
    // @ts-ignore
    country: address.country,
    zip_code: address.zip!,
    easypost_id: address.id
  }
}

export function TranslateGqlAddressToString (address: Address_Address): string {
  if (address === null || address === undefined) return ''
  return `${address.street_1}\n${address.street_2}\n${address.city}, ${address.state} ${address.country.code} ${address.zip_code}`
}
