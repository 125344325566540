import gql from 'graphql-tag'
import store from '@/store/store'
import { apolloClient as apollo } from '@/api/graphql/apollo'
import {
  Mutation,
  MutationUpdate__Profile_ProfileArgs
} from '@/models/generated/graphql/ErpBackend'

export interface UISettings {
  showColumnBorders: boolean | null
  defaultPrinterId: number | null
}

const userId = (): string => store?.state?.profile?.user?.id ?? '1'

// FRAGMENTS

export const FRAGMENT__PROFILE_PROFILE__FULL = gql`
  fragment Profile_Profile__Full on Profile_Profile {
    color
    column_config
    commission_percentage
    default_site { id name }
    hire_date
    id
    job_title
    last_review
    manager { id full_name initials }
    order_cache
    settings
    terminated
    terminated_date
    upcoming_review
    user { id full_name initials }
    wage
  }
`

// QUERIES

export const GET_LIVE_COMMENTS = gql`
  query GetLiveComments ($id: Any!) {
    templates: profile_live_templates (input: { filters: [{ key: "creator__id", value: $id }]}) {
      id
      name
      comment: template
      creator { id initials }
    }
  }
`

export const GET_LOGIN_DATA = gql`
  query LoginData {
    me {
      id
      date_joined
      email
      first_name
      full_name
      initials
      is_active
      is_staff
      is_superuser
      last_login
      last_name
      profile {
        ...Profile_Profile__Full
      }
      username
    }
    company_company {
      name
      short_name
    }
  }
  ${FRAGMENT__PROFILE_PROFILE__FULL}
`

export const GET_OTHER_LOGIN_DATA = gql`
  query otherLoginData {
    company_company {
      logo
      default_return_to_address { id }
    }
    statuses: choices_statuses (input: { limit: null }) {
      id
      app: app_name
      field: field_name
      model: model_name
      order
      status
      status_type
    }
    tags: choices_tags (input: { limit: null }) {
      id
      tag
      description
      color
    }
  }
`

// MUTATIONS

export const CHANGE_PROFILE_COLOR = gql`
  mutation ChangeProfileColor ($color: String, $id: ID!) {
    profile: Update__Profile_Profile (input: { id: $id, color: $color }) {
      id
      color
    }
  }
`

export const DELETE_TABLE_CONFIG = gql`
  mutation DeleteTableConfig ($id: ID!) {
    Delete__Profile_TableConfig (id: $id) { id }
  }
`

export const UPDATE_PROFILE_SETTINGS = gql`
  mutation UpdateProfileSettings ($input: Update__Profile_Profile__Input!) {
    profile: Update__Profile_Profile (input: $input) {
      ...Profile_Profile__Full
    }
  }
  ${FRAGMENT__PROFILE_PROFILE__FULL}
`

// HELPERS

export async function UpdateProfileSettings (id: string | number, settings: UISettings): Promise<void> {
  try {
    await apollo.mutate<Mutation, MutationUpdate__Profile_ProfileArgs>({
      mutation: UPDATE_PROFILE_SETTINGS,
      variables: { input: { id: `${id}`, settings: JSON.stringify(settings) } }
    })
  } catch (error) {
    throw new Error('Could not update profile settings on the server.')
  }
}
