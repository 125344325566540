
























































































































































































































































































































































































































import { apolloClient, apolloClient as apollo } from '@/api/graphql/apollo'
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  provide,
  reactive, ref,
  toRefs,
  watch
} from '@vue/composition-api'
import contacts from '../autocompletes/contacts.vue'
import companies from '../autocompletes/companies.vue'
import addPartsTemplate from '../templates/addPartsTemplate.vue'
import dynamicFieldEntry from '../templates/dynamicFieldEntry.vue'
import choices from '@/components/autocompletes/choices2.vue'
import formTemplate from '@/components/forms/formTemplate.vue'
import liveCommentArea from '@/components/templates/liveCommentArea.vue'
import { CreatePurchaseOrder, CreatePurchaseOrderItems, UpdatePurchaseOrder } from '@/api/graphql/Constants/Orders'
import { CreateShipmentOrder, UpdateShipmentOrder } from '@/api/graphql/Constants/Shipments'
import { GET_CLIENT__FULL } from '@/api/graphql/Constants/Clients'
import carrierServices from '@/components/autocompletes/carrierServices.vue'
import { COMPANY_INFO } from '@/api/graphql/Constants/Company'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import { getNewPurchasePartsInput, usePartsForOrder } from '@/composition/UsePartsForOrder.ts'
import { UseOrderInformation } from '@/composition/UseOrderInformation'
import { useShipping } from '@/composition/UseShipping'
import { INewPurchasePart } from '@/models/GuiPartsModels'
import store from '@/store/store'
import users from '@/components/autocompletes/users.vue'
import TermsAutofill from '@/components/autocompletes/TermsAutofill.vue'
import CarrierAccountsAutocomplete from '@/components/autocompletes/CarrierAccountsAutocomplete.vue'
import AddressesAutocomplete from '@/components/autocompletes/AddressesAutocomplete.vue'
import {
  Create__Purchases_Purchase__Input,
  Create__Purchases_PurchasedItems__Input,
  Create__Shipping_ShipmentOrder__Input,
  GetCompanyInfoQuery,
  Query,
  useGetPurchaseForEditQuery
} from '@/models/generated/graphql/ErpBackend'
import { QueryOptions } from 'apollo-client'
import { Sentry } from '@/lib/Sentry'
import { ApolloClients } from '@vue/apollo-composable'
import { useDynamicPrimitiveFieldEntry } from '@/composition/UseDynamicPrimitiveFieldEntry'
import { Severity } from '@sentry/types'
import Currency from '@/components/fields/Currency.vue'
import { GetDjangoMoneyCompatibleInput } from '@/lib/moneyHelpers'

// @ts-ignore
export default defineComponent({
  name: 'NewPurchase',
  components: {
    'add-part-set': addPartsTemplate,
    'addresses': AddressesAutocomplete,
    'cancel-button': CancelButton,
    'carrier-accounts': CarrierAccountsAutocomplete,
    'carrier-services': carrierServices,
    'choices': choices,
    'companies': companies,
    'contacts': contacts,
    // 'courier-selection': courierSelection,
    'dynamic-field-entry': dynamicFieldEntry,
    'live-comments-area': liveCommentArea,
    'new-data-form': formTemplate,
    'submit-button': SubmitButton,
    'terms': TermsAutofill,
    // @ts-ignore
    'users': users,
    'currency': Currency
  },
  setup () {
    provide(ApolloClients, {
      default: apolloClient
    })
    const orderId = computed(() => store.state.data.editOrder.id)
    const guiState = reactive({
      tabs: 0,
      footerColor: 'teal',
      footerMessage: 'New Purchase',
      order: 'purchase',
      inUseFields: [
        'hot',
        'we_pay',
        'purchase_date',
        'approval_date',
        'we_transport',
        'internal_comment',
        'contract_comment'
      ],
      inputObjectFields: [],
      extraFields: [],
      succeeded: false,
      failed: false,
      saving: false,
      close: () => store.dispatch('grid/resetSheet')
    })

    /* Order Info */
    const {
      form: orderForm,
      hasErrors: hasOrderErrors,
      ValidateOrder,
      apply: applyOrder
    } = UseOrderInformation()

    const conditionConfig = {
      appName: 'inventory',
      modelName: 'item',
      fieldName: 'condition',
      label: 'Condition',
      name: 'condition'
    }
    // watcher
    watch(() => orderForm.client.value, async (n, o) => {
      if (n !== o) {
        // grab client to figure out defaults
        const query: QueryOptions = {
          query: GET_CLIENT__FULL,
          variables: { input: { id: n } }
        }
        const response = await apollo.query<Query>(query)

        const client = response.data.clients_client
        // if client retrieved successfully, do defaults
        if (client) {
          const rep = client.internal_rep
          const terms = client.default_pt_terms
          const shipFrom = client.default_ship_from_address

          if (rep) orderForm.rep.setter({ value: rep.id })
          if (terms) orderForm.terms.setter({ value: terms.id })
          if (shipFrom && !isBlind.value) shippingForm.shipFrom.setter({ value: shipFrom })
        }
      }
    })

    /* Shipping Info */
    const {
      form: shippingForm,
      hasErrors: hasShippingErrors,
      ValidateShipping,
      apply: applyShipment
    } = useShipping()
    const isBlind = computed(() => shippingForm.blind.value !== 'NOT_BLIND')
    const showManualFreight = computed(() => !shippingForm.shipFrom.value)
    watch(showManualFreight, (value) => {
      const index = extra.inUse.findIndex(s => s === 'freight')
      if (value) {
        shippingForm.carrierAccount.setter({ value: undefined })
        shippingForm.carrierService.setter({ value: undefined })
        if (index > -1) {
          extra.inUse.splice(index, 1)
        }
      } else if (!value && index === -1) {
        extra.inUse.push('freight')
      }
    })

    const shipFromFilter = computed(() => {
      if (isBlind.value) { // if blind
        return { key: 'company__isnull', value: false }
      } else { // if not blind
        if (orderForm.client.value) return { key: 'client__id', value: orderForm.client.value }
        return null
      }
    })
    const shipToFilter = computed(() => {
      if (isBlind.value) { // if blind
        return null
      } else { // if not blind
        // if client is set
        return { key: 'company__isnull', value: false }// if not
      }
    })
    const billToFilter = { key: 'company__isnull', value: false }

    /* Parts Info */
    const {
      form: partsForm,
      hasErrors: hasPartErrors
    } = usePartsForOrder<INewPurchasePart>(() => null)

    /* Extra Fields */
    const { extra, apply: applyExtra } = useDynamicPrimitiveFieldEntry({ type: 'Create__Purchases_Purchase__Input', inUse: guiState.inUseFields })

    /* Inputs */
    // order
    const extraFields: ComputedRef = computed(() => {
      const x = {}
      for (const field of extra.selected) {
        // @ts-ignore
        if (field.value) {
          // @ts-ignore
          x[field.field] = field.value
        }
      }
      return x
    })
    const getFreight: ComputedRef<string | undefined> = computed(() => {
      if (extraFields.value.freight) return GetDjangoMoneyCompatibleInput(extraFields.value.freight)
      if (orderForm.freight) return GetDjangoMoneyCompatibleInput(orderForm.freight)
      return undefined
    })
    const orderInput: ComputedRef<Create__Purchases_Purchase__Input> = computed(() => ({
      client_id: orderForm.client.value || undefined,
      contact_id: orderForm.contact.value || undefined,
      contract_comment: orderForm.contractComment || undefined,
      condition_id: orderForm.condition.value || undefined,
      internal_comment: orderForm.internalComment || undefined,
      freight: getFreight.value,
      rep_id: orderForm.rep.value || undefined,
      shipment_order_id: shippingForm.shipmentId || undefined,
      status_id: '1',
      terms_id: orderForm.terms.value || undefined,
      ...extraFields.value
    }))

    // shipment
    const accountInput = computed(() => shippingForm.isLocalPickup.value ? undefined : (shippingForm.carrierAccount.value || undefined))
    const serviceInput = computed(() => shippingForm.isLocalPickup.value ? undefined : (shippingForm.carrierService.value || undefined))
    // @ts-ignore because it doesn't like undefined
    const shipmentInput: ComputedRef<Create__Shipping_ShipmentOrder__Input> = computed(() => ({
      account_id: accountInput.value,
      blind: shippingForm.blind.value,
      pickup_type: shippingForm.isLocalPickup.value ? 'CUSTOMER' : 'NOT_PICKUP',
      purchaser_address_id: shippingForm.billTo.value || undefined,
      purchaser_contact_id: shippingForm.contactBill.value || undefined,
      purchaser_rep_id: shippingForm.repBill.value || undefined,
      return_address_id: shippingForm.companyReturnToAddressId,
      service_id: serviceInput.value,
      ship_from_address_id: shippingForm.shipFrom.value || undefined,
      ship_from_contact_id: shippingForm.contactFrom.value || undefined,
      ship_from_rep_id: shippingForm.repFrom.value || undefined,
      ship_to_address_id: shippingForm.shipTo.value || undefined,
      ship_to_contact_id: shippingForm.contactTo.value || undefined,
      ship_to_rep_id: shippingForm.repTo.value || undefined,
      status_id: 56,
      we_pay: shippingForm.wePay.value
    }))

    // parts
    const partsInput: ComputedRef<Create__Purchases_PurchasedItems__Input[]> = computed(() => {
      return getNewPurchasePartsInput(partsForm.parts.value, orderForm.orderId)
    })

    const validate = () => {
      ValidateOrder('SAVE')
      ValidateShipping('SAVE')
      partsForm.parts.validator('SAVE')
    }

    const hasErrors = computed(() => {
      return hasOrderErrors.value || hasShippingErrors.value || hasPartErrors.value
    })

    const resultHandler = ({ message, type }: { message: string, type: string }): void => {
      if (type === 'success') {
        guiState.succeeded = true
      }
      guiState.footerColor = type
      guiState.footerMessage = message
      guiState.saving = false
    }

    const submit = async () => {
      enabled.value = false
      if (orderId.value) {
        await edit()
      } else {
        await create()
      }
    }
    const create = async () => {
      validate()
      if (hasErrors.value) {
        resultHandler({ message: 'Fix corrections to continue. Scroll to see more if needed.', type: 'warning' })
      } else {
        try {
          guiState.saving = true
          Sentry.setContext('shipment_input', shipmentInput.value)
          const shipment = await CreateShipmentOrder(shipmentInput.value)
          shippingForm.shipmentId = shipment!.id
          Sentry.addBreadcrumb({ category: 'purchase', message: `Successfully created shipment with id ${shipment?.id ?? 'none'}`, type: 'info' })
          Sentry.setContext('purchase_input', orderInput.value)
          const order = await CreatePurchaseOrder(orderInput.value)
          Sentry.addBreadcrumb({ category: 'purchase', message: `Successfully created sale with id ${order ?? 'none'}`, type: 'info' })
          orderForm.orderId = order!
          await CreatePurchaseOrderItems(order!, partsInput.value)
          Sentry.addBreadcrumb({ category: 'purchase', message: 'Successfully created sale items', type: 'info' })
          resultHandler({ message: 'Success', type: 'success' })
          if (window.location.href.includes('purchases/orders')) {
            store.dispatch('data/changeRefresh', { bool: true })
          }
        } catch (e) {
          Sentry.captureEvent(e, false)
          resultHandler({ message: e.message, type: 'error' })
        }
      }
    }

    const edit = async () => {
      validate()
      if (hasErrors.value) {
        resultHandler({ message: 'Fix corrections to continue. Scroll to see more if needed.', type: 'warning' })
      } else {
        try {
          Sentry.addBreadcrumb({ category: 'purchase', message: `Going to edit purchase ${orderId.value}`, type: 'info', level: Severity.Info })
          guiState.saving = true
          Sentry.setContext('shipment_input', shipmentInput.value)
          const shipment = await UpdateShipmentOrder({ ...shipmentInput.value, id: shippingForm.shipmentId })
          Sentry.addBreadcrumb({ category: 'purchase', message: `Successfully edited shipment with id ${shipment?.id ?? 'none'}`, type: 'info' })
          Sentry.setContext('purchase_input', orderInput.value)
          await UpdatePurchaseOrder({ ...orderInput.value, id: orderId.value })
          Sentry.addBreadcrumb({ category: 'purchase', message: 'Successfully edited sale', type: 'info' })
          resultHandler({ message: 'Success', type: 'success' })
          if (window.location.href.includes('purchases/orders')) {
            store.dispatch('data/changeRefresh', { bool: true })
          }
        } catch (e) {
          Sentry.captureEvent(e, false)
          resultHandler({ message: e.message, type: 'error' })
        }
      }
    }

    onBeforeMount(() => {
      Sentry.resetScope()
      const query: QueryOptions<GetCompanyInfoQuery> = {
        query: COMPANY_INFO
      }
      apollo.query<GetCompanyInfoQuery>(query)
        .then(r => {
          const c = r.data?.company
          if (c) {
            // @ts-ignore
            shippingForm.shipTo.setter({ value: c.default_ship_to_address })
            // @ts-ignore
            shippingForm.billTo.setter({ value: c.default_billing_address })
          }
        })
    })

    const enabled = ref(true)
    const {
      onResult,
      loading: loadingExisting
    } = useGetPurchaseForEditQuery(() => ({
      id: orderId.value
    }),
    () => ({
      enabled: orderId.value !== '' && enabled.value
    }))
    onResult(result => {
      const order = result.data?.purchases_purchase ?? undefined
      if (order) {
        applyOrder(order)
        !!order.shipment_order && applyShipment(order.shipment_order)
        const primitives = []
        for (const key in order) {
          // @ts-ignore
          if (typeof order[key] === typeof 'string' || typeof order[key] === typeof true) {
            // @ts-ignore
            primitives.push({ field: key, value: order[key] })
          }
        }
        applyExtra(primitives)
        guiState.footerMessage = 'Editing PT -- ' + orderId.value
      }
    })

    return {
      ...toRefs(guiState),
      showManualFreight,
      extra,
      loadingExisting,
      orderForm,
      hasOrderErrors,
      conditionConfig,
      shippingForm,
      hasShippingErrors,
      isBlind,
      shipFromFilter,
      shipToFilter,
      billToFilter,
      partsForm,
      hasPartErrors,
      // inputs
      orderInput,
      shipmentInput,
      partsInput,
      submit,
      orderId
    }
  }
})
