<template>
    <v-autocomplete
      v-model="localPrinter"
      outline
      hide-details
      item-text="name"
      item-value="id"
      :items="printers"
      :loading="loading"
      :disabled="disabled"
    >
      <template #append>
        <v-scale-transition group mode="out-in" leave-absolute>
          <v-icon
            v-if="printer > 0"
            style="margin-top: 2px;"
            key="clear"
            color="error"
            @click="localPrinter = 0"
          >fal fa-times</v-icon>
          <v-icon
            v-else
            color="primary"
            key="down"
          >fas fa-caret-down</v-icon>
        </v-scale-transition>
      </template>
      <template #item="{ item }">
          <v-list-tile-content>
            <v-list-tile-title>{{ item.name }}</v-list-tile-title>
            <v-list-tile-sub-title>{{ item.computer.name }}</v-list-tile-sub-title>
          </v-list-tile-content>
      </template>
      <template #label>
        <slot name="label">
          Select a printer
        </slot>
      </template>
    </v-autocomplete>
</template>

<script>
import { GET_PRINT_NODE_PRINTERS } from '@/api/graphql/Constants/printNode'

export default {
  name: 'printers',
  props: {
    printer: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    localPrinter: {
      get () {
        return this.printer
      },
      set (value) {
        this.$emit('updated', { value: value })
      }
    },

    defaultPrinterId () {
      return this.$store.state.data.userSettings.defaultPrinterId
    }
  },
  data () {
    return {
      printers: [],
      loading: false
    }
  },
  apollo: {
    printers: {
      query: GET_PRINT_NODE_PRINTERS,
      error () {
        this.$store.dispatch('notifications/createSnackbar', {
          message: 'Encountered an error getting printers. Please reach out to support.',
          color: 'error'
        })
      },
      update (data) {
        for (const printer of data.printers) {
          if (printer.id === this.defaultPrinterId) this.localPrinter = printer.id
        }
        return data.printers
      },
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
  }
}
</script>

<style scoped>

</style>
