import gql from 'graphql-tag'
import { FRAGMENT__CHOICES_STATUS } from '@/api/graphql/Constants/Choices'
import { BREAKDOWN_ITEM_STATUS, BREAKDOWN_STATUS } from '@/models/ExtraBackendModels'
import { FRAGMENT__MONEY } from '@/api/graphql/Constants/TERMS'

/* FRAGMENTS */

export const FRAGMENT__BREAKDOWN_LIST = gql`
    fragment BreakdownList on Breakdown_BreakDown {
      id
      assigned_to {
        id
        full_name
        initials
        email
      }
      creation_date
      creator {
        id
        initials
        email
      }
      status {
        id
        status
        status_type
      }
      system {
        id
        part {
          id
          pn
          description
        }
        purchases_items_details {
          id
          line_number
          transaction {
            id
          }
        }
        serial_number
      }
    }
`

export const FRAGMENT__BREAKDOWN = gql`
    fragment Breakdown on Breakdown_BreakDown {
      id
      assigned_to {
        id
        full_name
        initials
        email
      }
      creation_date
      creator {
        id
        initials
        email
      }
      status {
        id
        status
        status_type
      }
      system {
        id
        part {
          id
          pn
          description
        }
        purchases_items_details {
          id
          line_number
          current_cost { ...Money }
          original_cost { ...Money }
          transaction {
            id
          }
        }
        serial_number
      }
    }
    ${FRAGMENT__MONEY}
`

export const FRAGMENT__BREAKDOWN_ITEM = gql`
    fragment BreakdownItem on Breakdown_BreakDownItems {
      id
      item {
        id
        country_of_origin {
          name
          code
          unicode_flag
        }
        location {
          id
          site {
            id
            name
            short_name
          }
          name
        }
        part {
          id
          pn
        }
        purchases_items_details {
          id
          line_number
          original_cost { ...Money }
          current_cost { ...Money }
          transaction {
            id
          }
        }
        serial_number
      }
      status {
        id
        status
      }
    }
    ${FRAGMENT__MONEY}
`

/* QUERIES */

export const GET_BREAK_DOWN_ITEMS = gql`
  query GetBreakDownItems ($id: Any!) {
    breakDownItems: breakdown_break_down_items (input: { filters: [{ key: "break_down__id", value: $id }] }) {
        ...BreakdownItem
    }
  }
  ${FRAGMENT__BREAKDOWN_ITEM}
`

export const GRID__GET_BREAK_DOWN_ITEMS = gql`
  query GridGetBreakDownItems ($input: ManyQueryInput!) {
    breakDownItems: breakdown_break_down_items (input: $input) {
      ...BreakdownItem
    }
  }
  ${FRAGMENT__BREAKDOWN_ITEM}
`

export const GET_BREAK_DOWN = gql`
  query GetBreakDown ($id: ID!) {
    breakdown: breakdown_break_down (input: { id: $id }) {
      ...Breakdown
    }
  }
  ${FRAGMENT__BREAKDOWN}
`
export const GET_BREAK_DOWNS = gql`
  query GetBreakDowns ($input: ManyQueryInput!) {
    breakdowns: breakdown_break_downs (input: $input) {
      ...BreakdownList
      annotations {
        name
        value
      }
    }
  }
  ${FRAGMENT__BREAKDOWN_LIST}
`

export const GET_COUNT_OF_UNFINISHED_BREAK_DOWN_ITEMS_ON_BREAK_DOWN = gql`
  query GetCountOfUnfinishedBreakDownItemsOnBreakDown ($id: Any!) {
    breakdown_break_down_items (input: {
      filters: [{ key: "break_down__id", value: $id }],
      excludes: [{ key: "status_id", value: 72 }] }) {
      # selection
        id
    }
  }
`
// ${BREAKDOWN_ITEM_STATUS.BROKEN_OUT}

/* MUTATIONS */

export const UPDATE_BREAK_DOWN_STATUS = gql`
  mutation UpdateBreakDownStatus ($id: ID!, $status: ID!) {
    breakDown: Update__Breakdown_BreakDown (input: { id: $id, status_id: $status }) {
      id
      status {
        ...ChoicesStatus
      }
    }
  }
  ${FRAGMENT__CHOICES_STATUS}
`

export const UPDATE_BREAK_DOWN_ITEM_STATUS = gql`
  mutation UpdateBreakDownItemStatus ($input: [Update__Breakdown_BreakDownItems__Input!]!) {
    breakDownItem: Update__Breakdown_BreakDownItems (input: $input) {
      id
      status {
        ...ChoicesStatus
      }
    }
  }
  ${FRAGMENT__CHOICES_STATUS}
`

export const CREATE_BREAK_DOWN = gql`
  mutation CreateBreakDown($id: ID!) {
    breakDown: Create__Breakdown_BreakDown (input: {
      system_id: $id,
      status_id: 64
    }) {
      id
    }
  }
`

// ${BREAKDOWN_STATUS.CREATED}

export const CREATE_BREAK_DOWN_ITEMS = gql`
  mutation CreateBreakDownItems($input: [Create__Breakdown_BreakDownItems__Input!]!) {
    breakDownItems: Create__Breakdown_BreakDownItems (input: $input) {
      id
    }
  }
`

export const ASSIGN_BREAK_DOWN = gql`
  mutation AssignBreakDown ($id: ID!, $assignee: ID!) {
    breakDown: Update__Breakdown_BreakDown (input: { id: $id, assigned_to_id: $assignee }) {
      id
      assigned_to {
        id
        full_name
        initials
        email
      }
    }
  }
`
