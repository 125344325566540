// definitely ripped this from @apollo/vue-composable

export function useEventHook () {
  const functions: Function[] = []
  function on (fn: Function) {
    functions.push(fn)
    return {
      off: function () { return off(fn) }
    }
  }
  function off (fn: Function) {
    const index = functions.indexOf(fn)
    if (index !== -1) {
      functions.splice(index, 1)
    }
  }
  function trigger (param: any) {
    for (let _i = 0, fns_1 = functions; _i < fns_1.length; _i++) {
      const fn = fns_1[_i]
      fn(param)
    }
  }
  return {
    on: on,
    off: off,
    trigger: trigger
  }
}
