import VueApollo from 'vue-apollo'
import gql from 'graphql-tag'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink, from } from 'apollo-link'
import STORE from '../../store/store'
import possibleTypes from './possibleTypes.json'
import errorLink from '@/api/graphql/errorLink.ts'
import AuthorizationLink from '@/api/graphql/authLink.ts'
import WaitLink from '@/api/graphql/waitLink.ts'

// APOLLO Set up
const authLink = new AuthorizationLink()
const waitLink = new WaitLink()

const requests = []
let hasSentError = false

const httpLink = createHttpLink()

const successAfterWare = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const canPop = requests.length > 0
    canPop && requests.pop()
    if (requests.length === 0 && STORE.state.data.switchingTenants) {
      STORE.commit('data/setSwitchingTenants', { bool: false })
    }
    return response
  })
})

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network'
  },
  query: {
    fetchPolicy: 'network-only'
  },
  mutate: {
    // errorPolicy: 'all'
  }
}

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: from([
    errorLink,
    authLink,
    waitLink,
    successAfterWare,
    httpLink
  ]),
  cache: new InMemoryCache({
    possibleTypes,
    dataIdFromObject: o => {
      if (o !== undefined) {
        const typename = o?.__typename ?? null
        if (o.id) return `${typename}:${o.id}`
        if (typename === 'AnnotationObject') {
          return null // `${o.__typename}:${o.name}:${o.value}:${o}`
        } else if (o.__typename === '__InputValue') {
          return o.name
        } else {
          return null
        }
      }
      return null
    }
  }),
  defaultOptions,
  name: 'Web',
  version: '1.0'
})

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

export function logError (operation, response) {
  const email = STORE.state.profile.user.email
  const action = {
    body: operation.query?.loc?.source?.['body'] ?? 'COULD NOT RETRIEVE BODY',
    ...operation.variables,
    user: email,
    errors: response
  }
  if (operation.operationName !== 'AddFrontEndErrorLog' && !hasSentError) {
    apolloClient.mutate({
      mutation: gql`mutation AddFrontEndErrorLog ($op: String!, $desc: String!, $id: ID!) {
        Create__Logging_FrontEndError (input: { description: $desc, action: $op, user_id: $id }) {
          id
        }
      }`,
      variables: { desc: JSON.stringify(action), op: operation.operationName, id: STORE.state.profile.user.id }
    })
    hasSentError = true
    setTimeout(() => { hasSentError = false }, 1000)
  }
}

function getErrorResponses (errors) {
  const errorResponse = []
  for (const error of errors) {
    const validationError = error.extensions?.['INFO']?.['validation_errors'] ?? false
    if (validationError) {
      const message = validationError[Object.keys(validationError)[0]]
      errorResponse.push(message)
    }
  }
  return errorResponse
}

export function parseUserName (email) {
  const split = email.split('@')
  const user = split[0]
  const returned = {}
  returned.username = user
  returned.company = split[1].split('.')[0]
  return returned
}
